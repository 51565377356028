
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.RestrictUsage.Card', [])

/**
 * Component
 */
.component('cardSystemRestrictUsage', {
  templateUrl: 'admin/system/cards/restrict-usage.html',
  controller: 'CardSystemRestrictUsageCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    system: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSystemRestrictUsageCtrl', function(
  CardCloseReasons, Settings, Roles, moment
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set flags and options
    this.isSaving = false;
    this.Roles = Roles;

    //Model
    this.model = Settings.extract('system.usage');
    this.meta = {};

    //Extract times
    if (this.model.startDate) {
      this.meta.startTime = this.model.startDate.getTime();
    }
    if (this.model.endDate) {
      this.meta.endTime = this.model.endDate.getTime();
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;

    //Modify dates
    if (property === 'startTime') {
      this.model.startDate = this.model.startDate || moment();
      this.model.startDate.setTime(value);
    }
    if (property === 'endTime') {
      this.model.endDate = this.model.endDate || moment();
      this.model.endDate.setTime(value);
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Create settings subset
    const {model} = this;
    const settings = {system: {usage: model}};

    //Clear dates if disabled
    if (!model.isRestricted) {
      model.startDate = null;
      model.endDate = null;
    }

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event: {settings}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
