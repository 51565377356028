
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Edit.Controller', [])

/**
 * Controller
 */
.controller('AdminMembershipEditCtrl', function($state, $controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminMembershipCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Check if can edit
    this.canEdit = this.user.isAdmin();

    //Map usage
    this.membership.inUse = this.usage.inUse;
    this.membership.numMembers = this.usage.numMembers;

    //Determine system modes
    if (this.activities.length > 0) {
      this.systemModes = this.activities[0].modes;
    }
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    $state.go('admin.membership.overview');
  };

  /**
   * On saved
   */
  this.onSaved = function($event) {
    const {membership} = $event;
    this.page.setTitle(membership.nameWithSuffix);
  };
});
