
/**
 * Module definition and dependencies
 */
angular.module('Shared.Event.Notification.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('EventNotification', function(
  $baseModel, EventAudiences, EventNotificationDurations,
  NotificationThresholdTypes, EventNotificationTypes
) {

  /**
   * Defaults
   */
  const defaultData = {
    isEnabled: true,
    type: EventNotificationTypes.PROMOTION,
    audience: EventAudiences.MEMBERS,
    thresholdType: NotificationThresholdTypes.BEFORE,
    threshold: {
      amount: 1,
      unit: EventNotificationDurations.WEEKS,
    },
  };

  /**
   * Constructor
   */
  function EventNotification(data, event) {
    $baseModel.call(this, angular.extend({}, defaultData, data || {}), event);
  }

  /**
   * Extend base model
   */
  angular.extend(EventNotification.prototype, $baseModel.prototype);

  /**
   * Copy method
   */
  EventNotification.prototype.copy = function() {
    const {
      $parent, isEnabled, type, audience, groups,
      threshold, thresholdType, subject, text,
    } = this;
    return new EventNotification({
      isEnabled, type, audience, groups,
      threshold, thresholdType, subject, text,
    }, $parent);
  };

  //Return
  return EventNotification;
});
