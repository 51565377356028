
/**
 * Module definition and dependencies
 */
angular.module('Shared.EmailStatuses.Options', [])

/**
 * Constant definition
 */
.constant('EmailStatuses', [
  {
    value: 'processed',
    label: 'Processed',
  },
  {
    value: 'delivered',
    label: 'Delivered',
  },
  {
    value: 'opened',
    label: 'Opened',
  },
  {
    value: 'clicked',
    label: 'Clicked',
  },
  {
    value: 'deferred',
    label: 'Deferred',
  },
  {
    value: 'dropped',
    label: 'Dropped',
  },
  {
    value: 'bounced',
    label: 'Bounced',
  },
  {
    value: 'error',
    label: 'Error',
  },
]);
