
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.Override.Controller', [])

/**
 * Controller
 */
.controller('ModalOverrideCtrl', function(
  $controller, $modalInstance, $debounce, $interval, $timeout, $q,
  ServiceUnavailableError
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = async function() {

    //Bind override handler
    this.overrideHandler = $debounce(this.overrideCall, this, 1000);

    //Create toggling and override maps
    this.toggling = {};
    this.overrides = [];
    this.isLoading = true;

    //Prepare items and groups
    this.prepareItemsAndGroups();

    //Get state
    this.system
      .getState()
      .then(state => this.applyState(state))
      .finally(() => this.isLoading = false);

    //Setup interval to update state every second
    this.stateInterval = $interval(() => {
      this.system
        .getState()
        .then(state => this.applyState(state));
    }, 1000);
  };

  /**
   * Clear interval
   */
  this.$onDestroy = function() {
    $interval.cancel(this.stateInterval);
  };

  /**
   * Toggle item
   */
  this.toggle = function(item) {

    //Don't toggle if already loading
    if (this.isLoading) {
      return;
    }

    //Get data
    const {id, isInOverrideState} = item;
    const override = !isInOverrideState;

    //Clear errors
    this.isServiceUnavailableError = false;
    this.isGenericError = false;

    //Remove if in array already
    const i = this.overrides.findIndex(item => item.id === id);
    if (i !== -1) {
      this.overrides.splice(i, 1);
    }

    //Toggle and add to overrides
    this.toggling[id] = true;
    this.overrides.push({id, override});

    //Start debounced call
    this.overrideHandler();
  };

  /**
   * Override
   */
  this.overrideCall = function() {

    //Get data
    const {overrides, event, items} = this;
    if (overrides.length === 0) {
      return;
    }

    //Flag as loading
    this.isLoading = true;

    //Get more data
    const eventId = event ? event.id : null;
    const oldState = items.map(item => item.state);

    //Override
    this
      .overrideRequest(overrides, eventId)
      .then(() => this.checkIfStateChanged(oldState))
      .catch(error => {
        if (error instanceof ServiceUnavailableError) {
          this.isServiceUnavailableError = true;
        }
        else {
          this.isGenericError = true;
        }
      })
      .finally(() => {
        this.isLoading = false;
        this.toggling = {};
        this.overrides = [];
      });
  };

  /**
   * Check if state changed
   */
  this.checkIfStateChanged = function(oldState) {

    //Get items
    const {items} = this;

    //Wrap in promise
    return $q((resolve, reject) => {
      let interval, timeout;

      //Create interval to check if state has changed
      //Works in tandem with the interval that updates the state every second
      interval = $interval(() => {
        const newState = items.map(item => item.state);
        if (newState.some((state, i) => state !== oldState[i])) {
          $interval.cancel(interval);
          $timeout.cancel(timeout);
          resolve();
        }
      }, 100);

      //Create timeout to error if we can't get a change
      timeout = $timeout(() => {
        $interval.cancel(interval);
        reject(new Error(`Took too long`));
      }, 12500);
    });
  };
});
