
/**
 * Module definition and dependencies
 */
angular.module('Shared.Array.Distribute.Filter', [])

/**
 * Filter definitions
 */
.filter('distribute', function() {

  /**
   * Filter
   */
  return function(array, cols, threshold = 0) {

    //Not an array or not enough items
    if (!Array.isArray(array) || array.length <= threshold) {
      return array;
    }

    //Initialize new return array and compute data
    const ret = [];
    const prevCols = [];
    const num = array.length;
    const perCol = Math.floor(num / cols);
    const rest = num % cols;

    //Build up array with number of items in previous columns
    for (let c = 0; c < cols; c++) {
      if (c === 0) {
        prevCols.push(0);
      }
      else {
        const r = (c <= rest) ? 1 : 0;
        prevCols.push(perCol + prevCols[c - 1] + r);
      }
    }

    //Distribute items
    let l = 0;
    for (let i = 0; i <= perCol; i++) {
      for (let j = 0; j < cols; j++) {
        const k = i + prevCols[j];
        if (l < num && typeof array[k] !== 'undefined') {
          l++;
          ret.push(array[k]);
        }
      }
    }

    //Return
    return ret;
  };
});
