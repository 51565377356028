
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.Header.Component', [])

/**
 * Booking header component
 */
.component('bookingGridHeader', {
  template: `
    <header class="BookingGridHeader">
      <booking-grid-nav
        date="$ctrl.date"
        is-disabled-next-day="$ctrl.isDisabledNextDay"
        is-disabled-previous-day="$ctrl.isDisabledPreviousDay"
        on-next-day="$ctrl.onNextDay()"
        on-previous-day="$ctrl.onPreviousDay()"
        on-date-picker="$ctrl.onDatePicker()"
      ></booking-grid-nav>
      <booking-grid-controls
        date="$ctrl.date"
        has-light-control="$ctrl.hasLightControl"
        has-user="$ctrl.hasUser"
        is-showing-names="$ctrl.isShowingNames"
        is-showing-current="$ctrl.isShowingCurrent"
        is-disabled-today="$ctrl.isDisabledToday"
        is-disabled-date-picker="$ctrl.isDisabledDatePicker"
        on-toggle-names="$ctrl.onToggleNames()"
        on-toggle-current="$ctrl.onToggleCurrent()"
        on-today="$ctrl.onToday()"
        on-date-picker="$ctrl.onDatePicker()"
        on-calendar="$ctrl.onCalendar()"
      ></booking-grid-controls>
    </header>
    <div class="BookingGridActivities" ng-if="$ctrl.activities.length > 1">
      <span
        class="Pill pointer"
        ng-class="{
          'Pill--success': activity.id === $ctrl.activity.id,
          'Pill--default': activity.id !== $ctrl.activity.id,
        }"
        ng-repeat="activity in $ctrl.activities"
        ng-click="$ctrl.changeActivity(activity)"
      >
        {{activity.name}}
      </span>
    </div>
    <booking-grid-areas
      areas="$ctrl.areas"
      visible-area="$ctrl.visibleArea"
      on-next-area="$ctrl.onNextArea()"
      on-previous-area="$ctrl.onPreviousArea()"
    ></booking-grid-areas>
  `,
  bindings: {
    date: '<',
    areas: '<',
    activity: '<',
    activities: '<',
    visibleArea: '<',
    hasUser: '<',
    hasLightControl: '<',
    hasTimeRange: '<',
    isShowingNames: '<',
    isShowingCurrent: '<',
    isDisabledToday: '<',
    isDisabledNextDay: '<',
    isDisabledPreviousDay: '<',
    isDisabledDatePicker: '<',
    onNextArea: '&',
    onPreviousArea: '&',
    onToday: '&',
    onNextDay: '&',
    onPreviousDay: '&',
    onDatePicker: '&',
    onCalendar: '&',
    onToggleNames: '&',
    onToggleCurrent: '&',
  },
  controller($state, $storage) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Filter only bookable activities
      this.activities = this.activities.filter(a => a.isBookable);
    };

    /**
     * Change activity
     */
    this.changeActivity = function(activity) {

      //Get activity and date for params
      activity = activity.identifier;
      const date = this.date.format('YYYY-MM-DD');
      const route = $state.current.name;

      //Store
      $storage.set('bookings.activity', activity);

      //Transition to route for new activity, force reloading the page
      $state.go(route, {activity, date, bookingId: null}, {reload: true});
    };
  },
});
