
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Overview', [
  'App.Admin.People.Members.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.members.overview', {
    url: '',
    component: 'adminMemberOverviewRoute',
    params: {
      action: {
        value: null,
      },
    },
    resolve: {
      filter(Filters) {
        return Filters.get('members');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminMemberOverviewRoute', {
  controller: 'AdminMemberOverviewCtrl',
  templateUrl: 'admin/people/members/overview/overview.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    page: '<',
    filter: '<',
    module: '<',
    integrations: '<',
    transition: '<',
  },
});
