
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.AccountCredit', [
  'App.Admin.Finance.AccountCredit.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.finance.accountCredit', {
    url: '/account-credit',
    component: 'adminFinanceAccountCreditRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('accountCredit');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminFinanceAccountCreditRoute', {
  controller: 'AdminFinanceAccountCreditCtrl',
  templateUrl: 'admin/finance/account-credit/account-credit.html',
  bindings: {
    club: '<',
    filter: '<',
    page: '<',
    user: '<',
  },
});
