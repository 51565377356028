
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.SlotPeople.Component', [])

/**
 * Booking slot people component
 */
.component('bookingGridSlotPeople', {
  template: `
    <div class="SlotPeople" ng-class="{'with-names': $ctrl.withNames, 'with-many-people': $ctrl.hasManyPeople}">
      <div class="SlotPeopleNumber">
        <avatar size="m" text="$ctrl.numPeople" class="SlotMember-avatar SlotMember-avatar--icon"></avatar>
      </div>
      <div class="SlotPeopleNamesOnly">
        <span ng-repeat="person in $ctrl.people | limitTo: 4" ng-if="$ctrl.people">
          <span>{{person.name}}<span ng-if="!$last">,&nbsp;</span></span>
        </span>
        <span ng-repeat="member in $ctrl.members | limitTo: 4" ng-if="!$ctrl.people">
          <span>{{member.name}}<span ng-if="!$last">,&nbsp;</span></span>
        </span>
      </div>
      <div class="SlotMembers" ng-if="!$ctrl.hasPeople">
        <div class="SlotMember">
          <avatar size="m" icon="person" class="SlotMember-avatar SlotMember-avatar--icon"></avatar>
        </div>
      </div>
      <div class="SlotMembers {{$ctrl.slotMembersClass}}" ng-if="$ctrl.hasPeople">
        <div class="SlotMember" ng-repeat="member in $ctrl.members | limitTo:$ctrl.memberLimit">
          <avatar
            size="m"
            class="SlotMember-avatar"
            color="member.color"
            avatar="member.avatar"
            text="member.initials"
            title="{{member.name}}"
            special="{{member.isVaccinationValid && settings.member.trackVaccinationStatus ? 'health_and_safety' : ''}}"
          ></avatar>
          <span class="SlotMember-name">{{member.name}}</span>
        </div>
        <div class="SlotMember" ng-repeat="tag in $ctrl.serviceTags | limitTo:$ctrl.serviceTagLimit">
          <avatar size="m" icon="person" class="SlotMember-avatar SlotMember-avatar--icon" title="{{tag.name}}"></avatar>
          <span class="SlotMember-name">{{tag.name}}</span>
        </div>
        <div class="SlotMember" ng-repeat="visitor in $ctrl.visitors | limitTo:$ctrl.visitorLimit">
          <avatar size="m" icon="person" class="SlotMember-avatar SlotMember-avatar--icon" title="{{visitor.name || 'Visitor'}}"></avatar>
          <span class="SlotMember-name">{{visitor.name || 'Visitor'}}</span>
        </div>
        <div class="SlotMembers-excess" ng-if="$ctrl.excess > 1">
          <avatar size="m" text="$ctrl.excessLabel" class="SlotMember-avatar SlotMember-avatar--icon SlotMember-avatar--excess"></avatar>
        </div>
      </div>
    </div>
  `,
  bindings: {
    members: '<',
    visitors: '<',
    serviceTags: '<',
    withNames: '<',
    people: '<',
  },
  controller() {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Determine number of people
      this.numPeople = 0;
      const {members, visitors, serviceTags} = this;
      if (members) {
        this.numPeople += members.length;
      }
      if (visitors) {
        this.numPeople += visitors.length;
      }
      if (serviceTags) {
        this.numPeople += serviceTags.length;
      }

      //Determine other data
      this.excess = 0;
      this.excessLabel = '';
      this.slotMembersClass = '';
      this.hasPeople = (this.numPeople > 0);
      this.hasManyPeople = (this.numPeople > 2);

      //Determine excess
      if (this.numPeople > 4) {
        this.excess = this.numPeople - 4;
        this.excessLabel = `+${this.excess}`;
      }

      //Determine member limit
      this.memberLimit = 5;
      if (this.excess > 1) {
        this.memberLimit = 4;
      }

      //Determine service tag limit
      this.serviceTagLimit = 4;
      if (members) {
        this.serviceTagLimit -= members.length;
      }
      if (this.serviceTagLimit < 0) {
        this.serviceTagLimit = 0;
      }

      //Determine visitor limit
      this.visitorLimit = 4;
      if (members) {
        this.visitorLimit -= members.length;
      }
      if (serviceTags) {
        this.visitorLimit -= serviceTags.length;
      }
      if (this.visitorLimit < 0) {
        this.visitorLimit = 0;
      }

      //Slot member class
      if (this.numPeople === 4) {
        this.slotMembersClass = 'SlotMembers--num-4';
      }
      if (this.numPeople === 5) {
        this.slotMembersClass = 'SlotMembers--num-5';
      }
      if (this.numPeople >= 6) {
        this.slotMembersClass = 'SlotMembers--num-6plus';
      }
    };
  },
});
