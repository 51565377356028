
/**
 * Module definition and dependencies
 */
angular.module('Shared.Visibility.Filter', [])

/**
 * Filter definition
 */
.filter('visibility', function() {
  return function(bool) {
    return bool ?
      '<i class="Icon color-success">visibility</i>' :
      '<i class="Icon color-faded">visibility_off</i>';
  };
});
