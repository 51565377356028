
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.SlotBooking.Component', [])

/**
 * Slot booking component
 */
.component('bookingGridSlotBooking', {
  template: `
    <div
      class="SlotOverlay SlotBooking"
      ng-class="{
        'is-own': $ctrl.isOwn,
        'is-unpaid': $ctrl.isUnpaid,
        'is-peak': $ctrl.isPeak
      }"
      ng-style="{height: ($ctrl.height + 'px')}"
    >
      <booking-grid-slot-people
        people="$ctrl.people"
        members="$ctrl.members"
        visitors="$ctrl.visitors"
        with-names="$ctrl.isShowingNames"
      ></booking-grid-slot-people>

      <span class="Slot-text">{{$ctrl.time | time}}</span>

      <div
        class="Button Button--circle Button--success ButtonWaitingList"
        ng-if="$ctrl.isUpcoming && !$ctrl.isOwn && $ctrl.user"
        ng-click="$ctrl.waitingList($event)"
      >
        <i class="Icon">alarm</i>
      </div>
    </div>
  `,
  bindings: {
    user: '<',
    area: '<',
    time: '<',
    booking: '<',
    isPeak: '<',
    isUpcoming: '<',
    isShowingNames: '<',
    needsPayment: '<',
    onWaitingList: '&',
  },

  /**
   * Controller
   */
  controller(BookingGrid) {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Get data
      const {user, area, booking, needsPayment} = this;
      const {members, visitors, mode, people} = booking;
      const isOwn = booking.hasMember(user);
      const canManageBookings = user && user.hasRole('admin', 'eventManager');
      const duration = this.determineDuration();

      //Determine height
      this.height = BookingGrid.durationToHeight(duration, area.duration);

      //Check if own and if unpaid
      this.isOwn = isOwn;
      this.isUnpaid = (needsPayment && (canManageBookings || isOwn));

      //Determine members and visitors
      this.members = members;
      this.visitors = visitors;
      this.people = people;

      //Visitor mode, convert to visitors
      if (mode.minVisitors > 0 && this.visitors.length === 0) {
        while (this.visitors.length < mode.minVisitors) {
          this.visitors.push({name: 'Visitor'});
        }
      }

      //Check if there's anyone
      this.hasPeople = (this.members.length > 0 || this.visitors.length > 0);
    };

    /**
     * Determine booking duration
     */
    this.determineDuration = function() {

      //Get data
      const {booking, time} = this;
      let {duration} = booking;

      //If booking started before this time slot's time, e.g. the time may have
      //already passed, then we deduct the difference from the booking duration.
      if (booking.startTime < time) {
        duration -= (time - booking.startTime);
      }

      //Return duration
      return duration;
    };

    /**
     * Go on waiting list
     */
    this.waitingList = function($event) {

      //Stop propagation
      if ($event) {
        $event.stopPropagation();
      }

      //Get time and area
      const {startTime: time, area} = this.booking;

      //Propagate event
      this.onWaitingList({$event: {time, area}});
    };
  },
});
