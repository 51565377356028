
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.CouponTypeOptions.Component', [])

/**
 * CouponType options component
 */
.component('couponTypeOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="$ctrl.canEdit">
        <a ui-sref="admin.couponType.edit({couponTypeId: $ctrl.couponType.id})">Edit</a>
      </li>
      <li ng-if="$ctrl.couponType.isArchived">
        <a ng-click="$ctrl.toggleArchived()">Restore</a>
      </li>
      <li ng-if="!$ctrl.couponType.isArchived" class="Dropdown-item--danger">
        <a ng-click="$ctrl.toggleArchived()">Archive</a>
      </li>
      <li>
        <a ui-sref="admin.log.audit({model: 'CouponType', id: $ctrl.couponType.id})">View audit log</a>
      </li>
      <li ng-if="$ctrl.couponType.isArchived" class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    couponType: '<',
    canEdit: '<',
    onDelete: '&',
    onToggleArchived: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Delete
     */
    this.delete = function() {
      const {couponType} = this;
      this.onDelete({$event: {couponType}});
    };

    /**
     * Archive/restore
     */
    this.toggleArchived = function() {
      const {couponType} = this;
      const isArchived = !couponType.isArchived;
      this.onToggleArchived({$event: {couponType, isArchived}});
    };
  },
});
