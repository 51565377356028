
/**
 * Module definition and dependencies
 */
angular.module('Shared.ReAuthenticate.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('reAuthenticate', {
    templateUrl: 'modals/re-authenticate.html',
    controller: 'ModalReAuthenticateCtrl',
    rejectOnDismissal: true,
    resolve: {
      user($store) {
        'ngInject';
        return $store.user.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalReAuthenticateCtrl', function(
  $controller, $focus, $modalInstance, Auth
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Credentials and flag
    this.credentials = {};
    this.isAuthenticating = false;

    //Get username
    this.credentials.username = this.user.username;
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    $focus(this.form.password.$$element);
  };

  /**
   * Re authenticate
   */
  this.reAuthenticate = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Toggle flag
    this.isAuthenticating = true;

    //Reauthenticate
    Auth
      .reAuthenticate(this.credentials)
      .then(result => $modalInstance.resolve(result))
      .catch(error => {
        switch (error.code) {

          //These should not be happening, but if it does log the user out
          case 'USER_PENDING':
          case 'USER_ARCHIVED':
            return Auth.logoutAutomatically();

          //Invalid credentials is possible
          default:
            this.form.password.$setValidity('credentials', false);
            $focus(this.form.password.$$element);
            break;
        }
      })
      .finally(() => this.isAuthenticating = false);
  };

  /**
   * Validity reset for credentials error
   */
  this.resetCredentialsValidity = function() {
    this.form.password.$setValidity('credentials', true);
  };
});
