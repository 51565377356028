
/**
 * Module definition and dependencies
 */
angular.module('Shared.AccountCredit.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('accountCredit', {
    model: 'AccountCredit',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'accountCredits',
        isModel: true,
        isArray: true,
      },
      own: {
        url: 'own',
        method: 'GET',
        dataKey: 'accountCredits',
        isModel: true,
        isArray: true,
      },
      transfer: {
        url: 'transfer/:memberId',
        method: 'POST',
      },
      transferOwn: {
        url: 'transfer/own',
        method: 'POST',
      },
      balance: {
        url: 'balance/:memberId',
        method: 'GET',
      },
      balanceOwn: {
        url: 'balance/own',
        method: 'GET',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('accountCredits', {
    model: 'AccountCredit',
    dataKey: 'accountCredits',
  });
})

/**
 * Model definition
 */
.factory('AccountCredit', function($api, $baseModel, $sync, Intercom) {

  /**
   * Constructor
   */
  function AccountCredit(data) {

    //Call base model constructor
    $baseModel.call(this, data);

    //Check if debit
    Object.defineProperty(this, 'isDebit', {
      get() {
        return (this.adjustment < 0);
      },
    });

    //Check if credit
    Object.defineProperty(this, 'isCredit', {
      get() {
        return (this.adjustment > 0);
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(AccountCredit.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  AccountCredit.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('member', 'Member');

    //Return self
    return this;
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query account credit entries
   */
  AccountCredit.query = function(filter) {
    return $api.accountCredit.query(filter);
  };

  /**
   * Query own account credit entries
   */
  AccountCredit.own = function(filter) {
    return $api.accountCredit
      .own(filter)
      .then(data => data.accountCredits);
  };

  /**
   * Transfer account credit balance
   */
  AccountCredit.transfer = function(memberId, data) {
    return $api.accountCredit
      .transfer({memberId}, data)
      .then(data => data.accountCredit);
  };

  /**
   * Transfer own account credit balance
   */
  AccountCredit.transferOwn = function(circleMemberId, data) {
    return $api.accountCredit
      .transferOwn({circleMemberId}, data)
      .then(data => data.accountCredit);
  };

  /**
   * Query account credit balance for a member
   */
  AccountCredit.balance = function(filter) {
    return $api.accountCredit
      .balance(filter)
      .then(data => data.accountCredit);
  };

  /**
   * Query account credit balance for yourself
   */
  AccountCredit.balanceOwn = function(filter) {
    return $api.accountCredit
      .balanceOwn(filter)
      .then(data => data.accountCredit);
  };

  /**
   * Export account credit entries
   */
  AccountCredit.export = function(filter) {
    Intercom.event('Exported account credit entries');
    return $sync.get('accountCredit/export/csv', filter, 'Exporting...');
  };

  //Return
  return AccountCredit;
});
