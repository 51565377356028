
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.Fees.Controller', [])

/**
 * Controller
 */
.controller('AdminFinanceFeesCtrl', function(
  $filter, $modal, moment, ScrollPosition, Pagination, Intercom, Settings,
  Payment, PaymentMethods
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed fees');

    //Flags
    this.isSuper = this.user.isSuper();
    this.showPercentages = true;

    //Initialize totals so some data shows up
    this.totalPaid = 0;
    this.totalToClub = 0;
    this.totalFeesClub = 0;
    this.totalFeesMembers = 0;

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load payments
    this.loadPage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Set page title and crumb
    page.setTitle('Fees');
    page.addCrumb({sref: 'admin.finance.fees'});

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get data
    const {filter} = this;

    //Set filter defaults
    filter.setDefaults({
      search: '',
      fromDate: moment().subtract(1, 'month').startOf('day'),
      toDate: moment().endOf('day'),
      isCustom: false,
    });

    //Filter on change handler
    filter.onChange(key => {

      //Get data
      const {toDate, fromDate} = filter;
      const hasDates = fromDate && toDate;

      //From date changed
      if (hasDates && key === 'fromDate' && fromDate.isAfter(toDate)) {
        filter.toDate = fromDate.clone();
      }

      //To date changed
      else if (hasDates && key === 'toDate' && toDate.isBefore(fromDate)) {
        filter.fromDate = toDate.clone();
      }

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      method: PaymentMethods.filter(method => method.isOnline),
    };
  };

  /**
   * Load payments
   */
  this.loadPayments = function() {

    //Mark as loading and clear
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query payments
    return Payment
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Toggle percentages
   */
  this.togglePercentages = function() {
    this.showPercentages = !this.showPercentages;
  };

  /**
   * View payment
   */
  this.view = function($event) {

    //Get data
    const {payment} = $event;
    const {isSuper} = this;

    //Show modal
    $modal.open('basic', {
      templateUrl: 'admin/finance/modals/view-payment.html',
      locals: {payment, isSuper},
    });
  };
  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, payments} = data;

    //Set in scope
    this.payments = payments;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);

    //Sums
    Object.assign(this, meta.sums);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //Online payments only
    filter.isOnline = true;

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadPayments()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };
});
