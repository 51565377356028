
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.VerifyEmail', [
  'App.Portal.VerifyEmail.Controller',
])

/**
 * Config
 */
.config(($stateProvider, $transitionsProvider) => {

  //State definition
  $stateProvider.state('verifyEmail', {
    parent: 'portal',
    url: '/email/verify/:token',
    component: 'verifyEmailRoute',
  });

  //Transition hook
  $transitionsProvider.onBefore({
    to: 'verifyEmail',
  }, transition => {

    //Get params
    const params = transition.params();

    //Must have token param
    if (!params.token) {
      return transition.router.stateService.target('error', {
        type: 'page-not-found',
      });
    }
  });
})

/**
 * Route component
 */
.component('verifyEmailRoute', {
  controller: 'VerifyEmailCtrl',
  templateUrl: 'portal/verify-email/verify-email.html',
  bindings: {
    transition: '<',
    club: '<',
  },
});
