
/**
 * Module definition and dependencies
 */
angular.module('Shared.MatchesMemberConstraint.Service', [])

/**
 * Service definition
 */
.factory('matchesMemberConstraint', function(MembershipConstraints) {

  /**
   * Check if a user matches a given constraint for an item
   */
  return function(member, item) {

    //No member passed in
    if (!member) {
      return false;
    }

    //Get data from item to check
    const {constraint, memberships: specific} = item;
    const {
      ALL, WITH, WITHOUT, SPECIFIC, SPECIFIC_WITHOUT, GUEST,
    } = MembershipConstraints;

    //Check guest/all
    if (constraint === GUEST) {
      return false;
    }
    if (constraint === ALL) {
      return true;
    }

    //Get current subs
    const subs = member.subscriptions
      .filter(sub => sub.isCurrent || typeof sub.isCurrent === 'undefined');
    const memberships = subs.map(sub => sub.membership.id);

    //Check constraints
    const isSpecific = (
      constraint === SPECIFIC || constraint === SPECIFIC_WITHOUT
    );
    const isWithout = (
      constraint === WITHOUT || constraint === SPECIFIC_WITHOUT
    );

    //Members with a membership
    if (constraint === WITH && memberships.length > 0) {
      return true;
    }

    //Casuals without membership
    if (isWithout && memberships.length === 0) {
      return true;
    }

    //Specific memberships
    if (isSpecific && memberships.length > 0) {
      return specific.some(id => memberships.includes(id));
    }

    //Invalid
    return false;
  };
});
