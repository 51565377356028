
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area.Add', [
  'App.Admin.Area.Add.Controller',
  'App.Admin.Area.Add.Details.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.area.add', {
    url: '/add/:areaId',
    params: {
      areaId: null,
      squash: true,
    },
    component: 'adminAreaAddRoute',
    data: {
      roles: ['admin'],
    },
    resolve: {
      area(transition, $store, Area) {
        const params = transition.params();
        if (!params.areaId) {
          return new Area();
        }
        return $store.areas
          .findById(params.areaId)
          .then(area => area.clone(true));
      },
    },
  });
})

/**
 * Route component
 */
.component('adminAreaAddRoute', {
  controller: 'AdminAreaAddCtrl',
  templateUrl: 'admin/area/add/add.html',
  bindings: {
    club: '<',
    user: '<',
    area: '<',
    system: '<',
    module: '<',
    activities: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.area.add'}, () => {
    Page.setTitle(`Add area`);
    Page.addCrumb({sref: 'admin.area.add'});
  });
});
