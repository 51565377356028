
/**
 * Module definition and dependencies
 */
angular.module('Shared.PaymentLineItems.Service', [])

/**
 * Service definition
 */
.factory('PaymentLineItems', function() {

  /**
   * Payment line items generator class
   */
  class PaymentLineItems {

    /**************************************************************************
     * Line item generators
     ***/

    /**
     * Make membership line item
     */
    static addSubMembership(lineItems, sub) {

      //Get data
      const {fee, membership} = sub;
      const {nameWithSuffix} = membership;

      //Create label and suffix
      const label = `${nameWithSuffix} membership`;
      const amount = (fee && fee.base) ? fee.base : 0;

      //Add line item
      lineItems.push({label, amount});
    }

    /**
     * Make sub discount line item
     */
    static addSubDiscount(lineItems, sub) {

      //Anything to do?
      const {fee} = sub;
      if (!fee || !fee.discount) {
        return;
      }

      //Get label and amount
      const {discount} = fee;
      const label = discount.name;
      const amount = -1 * discount.amount;

      //Add line item
      lineItems.push({label, amount});
    }

    /**
     * Make sub pro ration line item
     */
    static addSubProRation(lineItems, sub) {

      //Anything to do?
      const {fee} = sub;
      if (!fee || !fee.proRation || !fee.proRation.deduction) {
        return;
      }

      //Create data
      const {deduction, numDays, termDays} = fee.proRation;
      const label = `Pro-rated for ${numDays} out of ${termDays} days`;
      const amount = -1 * deduction;

      //Add line item
      lineItems.push({label, amount});
    }

    /**
     * Add joining fee line item
     */
    static addJoiningFee(lineItems, fee) {

      //Anything to do
      if (!fee || !fee.amount) {
        return;
      }

      //Create data
      const {label, amount} = fee;

      //Add line item
      lineItems.push({label, amount});
    }

    /**************************************************************************
     * Macro generators
     ***/

    /**
     * Generate payment line items from subscriptions
     */
    static fromSubscriptions(subscriptions) {

      //Initialize
      const lineItems = [];
      const members = [];

      //Extract members
      for (const sub of subscriptions) {
        const {member} = sub;
        if (!members.some(m => m.id === member.id)) {
          members.push(member);
        }
      }

      //Now loop by member
      for (const member of members) {
        const subs = subscriptions.filter(sub => sub.member.id === member.id);
        const items = [];

        //Add subs
        for (const sub of subs) {
          this.addSubMembership(items, sub);
          this.addSubDiscount(items, sub);
          this.addSubProRation(items, sub);
        }

        //Add as line item
        lineItems.push({member, items});
      }

      //Return line items
      return lineItems;
    }
  }

  //Return class
  return PaymentLineItems;
});

