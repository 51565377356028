
/**
 * Module definition and dependencies
 */
angular.module('App.Error.ErrorTypes.Service', [])

/**
 * Base error
 */
.factory('BaseError', function() {

  /**
   * Constructor
   */
  function BaseError(code, message, data) {

    //Paremeter juggling
    if (typeof message === 'object') {
      data = message;
      message = '';
    }

    //Set properties
    this.code = code;
    this.message = message || '';
    this.data = data || {};
    this.stack = (new Error()).stack;
  }

  /**
   * Extend error prototype
   */
  BaseError.prototype = Object.create(Error.prototype);
  BaseError.prototype.constructor = BaseError;

  //Return class
  return BaseError;
})

/**
 * Generic response error
 */
.factory('ResponseError', function(BaseError) {

  /**
   * Constructor
   */
  function ResponseError(response) {

    //Validate response
    if (!response || typeof response !== 'object') {
      response = {};
    }

    //Set status
    if (response.status) {
      this.status = response.status;
    }

    //Set properties
    this.config = response.config || {};

    //Get error data and call parent constructor
    let error = response.data || {};
    BaseError.call(this, error.code, error.message, error.data);
  }

  /**
   * Extend error prototype
   */
  ResponseError.prototype = Object.create(BaseError.prototype);
  ResponseError.prototype.constructor = ResponseError;
  ResponseError.prototype.name = 'ResponseError';
  ResponseError.prototype.ignoreInSentry = true;

  //Return class
  return ResponseError;
})

/**
 * Generic client error
 */
.factory('ClientError', function(ResponseError) {
  function ClientError(response) {
    ResponseError.call(this, response);
    if (response && response.status) {
      this.status = response.status;
    }
  }
  ClientError.prototype = Object.create(ResponseError.prototype);
  ClientError.prototype.constructor = ClientError;
  ClientError.prototype.name = 'ClientError';
  ClientError.prototype.status = 400;
  return ClientError;
})

/**
 * Generic server error
 */
.factory('ServerError', function(ResponseError) {
  function ServerError(response) {
    ResponseError.call(this, response);
  }
  ServerError.prototype = Object.create(ResponseError.prototype);
  ServerError.prototype.constructor = ServerError;
  ServerError.prototype.name = 'ServerError';
  ServerError.prototype.status = 500;
  return ServerError;
})

/**
 * Network error
 */
.factory('NetworkError', function(ResponseError) {
  function NetworkError(response) {
    ResponseError.call(this, response);
  }
  NetworkError.prototype = Object.create(ResponseError.prototype);
  NetworkError.prototype.constructor = NetworkError;
  NetworkError.prototype.name = 'NetworkError';
  return NetworkError;
})

/**
 * Not authenticated error
 */
.factory('NotAuthenticatedError', function(ClientError) {
  function NotAuthenticatedError(response) {
    ClientError.call(this, response);
  }
  NotAuthenticatedError.prototype = Object.create(ClientError.prototype);
  NotAuthenticatedError.prototype.constructor = NotAuthenticatedError;
  NotAuthenticatedError.prototype.name = 'NotAuthenticatedError';
  NotAuthenticatedError.prototype.status = 401;
  return NotAuthenticatedError;
})

/**
 * Not authorized error
 */
.factory('NotAuthorizedError', function(ClientError) {
  function NotAuthorizedError(response) {
    ClientError.call(this, response);
  }
  NotAuthorizedError.prototype = Object.create(ClientError.prototype);
  NotAuthorizedError.prototype.constructor = NotAuthorizedError;
  NotAuthorizedError.prototype.name = 'NotAuthorizedError';
  NotAuthorizedError.prototype.status = 403;
  return NotAuthorizedError;
})

/**
 * Not found error
 */
.factory('NotFoundError', function(ClientError) {
  function NotFoundError(response) {
    ClientError.call(this, response);
  }
  NotFoundError.prototype = Object.create(ClientError.prototype);
  NotFoundError.prototype.constructor = NotFoundError;
  NotFoundError.prototype.name = 'NotFoundError';
  NotFoundError.prototype.status = 404;
  return NotFoundError;
})

/**
 * Exists error
 */
.factory('ExistsError', function(ClientError) {
  function ExistsError(response) {
    ClientError.call(this, response);
  }
  ExistsError.prototype = Object.create(ClientError.prototype);
  ExistsError.prototype.constructor = ExistsError;
  ExistsError.prototype.name = 'ExistsError';
  ExistsError.prototype.status = 409;
  return ExistsError;
})

/**
 * Too many requests error
 */
.factory('TooManyRequestsError', function(ClientError) {
  function TooManyRequestsError(response) {
    ClientError.call(this, response);
  }
  TooManyRequestsError.prototype = Object.create(ClientError.prototype);
  TooManyRequestsError.prototype.constructor = TooManyRequestsError;
  TooManyRequestsError.prototype.name = 'TooManyRequestsError';
  TooManyRequestsError.prototype.status = 429;
  return TooManyRequestsError;
})

/**
 * Validation error
 */
.factory('ValidationError', function(ClientError) {
  function ValidationError(response) {
    ClientError.call(this, response);
  }
  ValidationError.prototype = Object.create(ClientError.prototype);
  ValidationError.prototype.constructor = ValidationError;
  ValidationError.prototype.name = 'ValidationError';
  ValidationError.prototype.status = 422;
  ValidationError.prototype.setFieldsValidity = function(form) {
    if (!form) {
      return;
    }
    if (this.data && Array.isArray(this.data)) {
      for (const error of this.data) {
        if (error.type === 'user defined') {
          error.type = 'custom';
        }
        const {path, type, message} = error;
        if (path && typeof form[path] !== 'undefined') {
          form[path].$setValidity(type, false);
          form[path].errorMessage = message;
        }
      }
    }
  };
  return ValidationError;
})

/**
 * Timeout error
 */
.factory('TimeoutError', function(ResponseError) {
  function TimeoutError(response) {
    ResponseError.call(this, response);
  }
  TimeoutError.prototype = Object.create(ResponseError.prototype);
  TimeoutError.prototype.constructor = TimeoutError;
  TimeoutError.prototype.name = 'TimeoutError';
  TimeoutError.prototype.status = 504;
  return TimeoutError;
})

/**
 * Service unavailable error
 */
.factory('ServiceUnavailableError', function(ResponseError) {
  function ServiceUnavailableError(response) {
    ResponseError.call(this, response);
  }
  ServiceUnavailableError.prototype = Object.create(ResponseError.prototype);
  ServiceUnavailableError.prototype.constructor = ServiceUnavailableError;
  ServiceUnavailableError.prototype.name = 'ServiceUnavailableError';
  ServiceUnavailableError.prototype.status = 503;
  return ServiceUnavailableError;
});
