
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Log.Visitor', [
  'App.Admin.Log.Visitor.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.log.visitor', {
    url: '/visitor',
    component: 'adminLogVisitorRoute',
    data: {
      id: 'visitorLogs',
    },
    resolve: {
      filter(Filters) {
        return Filters.get('visitorLogs');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminLogVisitorRoute', {
  controller: 'AdminLogVisitorCtrl',
  templateUrl: 'admin/log/visitor/visitor.html',
  bindings: {
    club: '<',
    user: '<',
    filter: '<',
    page: '<',
  },
});
