
/**
 * Module definition and dependencies
 */
angular.module('Shared.EventStore.Service', [
  'Store.CollectionStore.Service',
])

/**
 * Model definition
 */
.factory('EventStore', function(
  $collectionStore, $q, $cacheFactory
) {

  //Get or create cache
  const cache = $cacheFactory.get('events') || $cacheFactory('events');
  let life = 60 * 1000;

  /**
   * Constructor
   */
  function EventStore(name, config) {
    $collectionStore.call(this, name, config);
  }

  /**
   * Extend prototype
   */
  angular.extend(EventStore.prototype, $collectionStore.prototype);

  /**
   * Set life
   */
  EventStore.prototype.setLife = function(seconds) {
    life = seconds * 1000;
  };

  /**
   * Query wrapper
   */
  EventStore.prototype.query = function(filter, refresh) {

    //Serialize filter into unique identifier
    const identifier = angular.toJson(filter);

    //Check if cached
    const data = cache.get(identifier);
    if (!refresh && data && (Date.now() - data.timestamp) < life) {
      return $q.resolve(data.items);
    }

    //Query from server
    return $collectionStore.prototype.query
      .call(this, filter)
      .then(items => {

        //Store in cache
        cache.put(identifier, {
          timestamp: Date.now(),
          items,
        });

        //Return items
        return items;
      });
  };

  /**
   * Save events (create or update)
   */
  EventStore.prototype.save = function(item, data, method) {
    return this.validateIsModel(item, true)
      .then(item => item.save(data, method))
      .then(item => this.add(item));
  };

  /**
   * Delete events
   */
  EventStore.prototype.delete = function(item, method) {
    return this.validateIsModel(item)
      .then(item => item.delete(method))
      .then(item => this.remove(item));
  };

  //Return
  return EventStore;
});
