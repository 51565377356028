
/**
 * Module definition and dependencies
 */
angular.module('Shared.RestrictionLimit.Filter', [])

/**
 * Returns duration amount and unit display
 */
.filter('restrictionLimit', function() {
  return function(limit, item, singular, none = '') {
    if (!limit || limit.unit === 'none') {
      return none;
    }
    const {amount, unit, isUpcoming} = limit;
    const upcoming = isUpcoming ? ' upcoming' : '';
    let label = '';
    switch (unit) {
      case 'day':
      case 'week':
      case 'month':
        label = ` per ${unit}`;
        break;
    }
    if (!item) {
      return label;
    }
    if (amount === 1) {
      item = singular || item;
    }
    return amount + upcoming + ' ' + item + label;
  };
});
