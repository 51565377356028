
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Add.System.Card', [])

/**
 * Component
 */
.component('cardMembershipAddSystem', {
  templateUrl: 'admin/membership/cards/system.html',
  controller: 'CardMembershipAddSystemCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    membership: '<',
    system: '<',
    modes: '<',
    onMerge: '&',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipAddSystemCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMembershipEditSystemCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Flags
    this.isEdit = false;
    this.isAdd = true;
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {model, membership} = this;
    this.onMerge({$event: {membership, model}});
    this.form.$setPristine();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previous();
  };
});
