
/**
 * Module definition and dependencies
 */
angular.module('Shared.IconPicker.Component', [])

/**
 * Icon Picker component
 */
.component('iconPicker', {
  template: `
    <div class="IconPickerContainer">
      <i class="Icon Icon--l IconPicker-toggle" ng-click="$ctrl.togglePicker()">{{$ctrl.model}}</i>
      <div class="IconPicker" ng-if="$ctrl.isChoosingIcon">
        <div class="IconPicker-inner">
          <div class="IconPicker-category" ng-repeat="item in $ctrl.PageIcons">
            <label class="Label">{{item.category}}</label>
            <div class="IconPicker-icon" ng-click="$ctrl.selectIcon(icon)" ng-class="{'IconPicker-icon--current': ($ctrl.model === icon)}" ng-repeat="icon in item.icons">
              <i class="Icon Icon--l">{{icon}}</i>
            </div>
          </div>
          <small class="IconPicker--notice">
            <a class="text-muted" href="https://fonts.google.com/icons?selected=Material+Icons" target="_blank" tooltip="Click here to view list of all available icons, then let us know which one you would like to use">Can’t find a suitable icon?</a>
          </small>
        </div>
      </div>
    </div>
  `,
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    model: '<ngModel',
    onChange: '&',
  },
  /**
   * Controller
   */
  controller($document, $timeout, Interface, PageIcons) {

    //Get controller
    const $ctrl = this;

    /**
     * On click handler to hide picker
     */
    function hidePicker(event) {

      //Clicks anywhere but on the help menu toggle close it
      if (!Interface.isTarget(['IconPicker-toggle'], event)) {
        $timeout(() => $ctrl.togglePicker(false));
      }
    }

    /**
     * On init
     */
    this.$onInit = function() {

      //Set icon data
      this.PageIcons = PageIcons;
      $document.find('body').on('click', hidePicker);
    };

    /**
     * On destroy
     */
    this.$onDestroy = function() {
      $document.find('body').off('click', hidePicker);
    };

    /**
     * Toggle choosing icon
     */
    this.togglePicker = function(show) {
      if (typeof show === 'undefined') {
        show = !this.isChoosingIcon;
      }
      this.isChoosingIcon = show;
    };

    /**
     * Select icon
     */
    this.selectIcon = function(icon) {

      //Propagate icon change
      if (this.onChange) {
        this.onChange({icon});
      }
    };
  },
});
