
/**
 * Module definition and dependencies
 */
angular.module('Shared.MembershipConstraints.Constant', [])

/**
 * Constant definition
 */
.constant('MembershipConstraints', {
  ALL: 'all',
  WITH: 'with',
  WITHOUT: 'without',
  SPECIFIC: 'specific',
  SPECIFIC_WITHOUT: 'specificWithout',
  GUEST: 'guest',
  GROUPS: 'groups',
});
