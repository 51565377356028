
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.Purchase', [
  'App.Subscription.Purchase.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('subscription.purchase', {
    url: '/purchase/:memberId',
    component: 'subscriptionPurchaseRoute',
    params: {
      memberId: {
        value: null,
        squash: true,
      },
    },
    resolve: {
      payment(PaymentFlow, PaymentTypes, user) {
        return new PaymentFlow(PaymentTypes.SUBSCRIPTION_PURCHASE, user);
      },
      outcome(PaymentOutcome) {
        return PaymentOutcome.check();
      },
      memberships($store) {
        return $store.memberships.query({isArchived: false});
      },
      circleMembers(Circle) {
        return Circle
          .queryOwnManagedMembers('memberships');
      },
      member(transition, user, circleMembers) {
        const {memberId} = transition.params();
        const member = circleMembers.find(member => member.id === memberId);
        if (member) {
          return member;
        }
        return user;
      },
      existing(member, Subscription) {
        return Subscription
          .own({circleMemberId: member.id})
          .then(subs => subs
            .filter(sub => sub.isCurrent)
            .reduce((map, sub) => {
              map[sub.membership.id] = sub;
              return map;
            }, {}));
      },
      integrations($store) {
        return $store.integrations.query(true);
      },
    },
  });
})

/**
 * Component
 */
.component('subscriptionPurchaseRoute', {
  controller: 'SubscriptionPurchaseCtrl',
  templateUrl: 'subscription/purchase/purchase.html',
  bindings: {
    club: '<',
    user: '<',
    member: '<',
    circleMembers: '<',
    integrations: '<',
    memberships: '<',
    subscriptions: '<',
    existing: '<',
    module: '<',
    transition: '<',
    payment: '<',
    outcome: '<',
  },
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'subscription.purchase'}, () => {
    Page.setTitle('Purchase memberships');
    Page.addCrumb({sref: 'subscription.purchase'});
  });
});
