
/**
 * Define values
 */
const PROMOTION = 'promotion';
const REMINDER = 'reminder';
const POST_EVENT = 'postEvent';

/**
 * Define array
 */
const EventNotificationTypes = [
  {
    label: 'Event promotion',
    value: PROMOTION,
  },
  {
    label: 'Event reminder',
    value: REMINDER,
  },
  {
    label: 'Post event email',
    value: POST_EVENT,
  },
];

/**
 * Set properties
 */
EventNotificationTypes.PROMOTION = PROMOTION;
EventNotificationTypes.REMINDER = REMINDER;
EventNotificationTypes.POST_EVENT = POST_EVENT;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.EventNotificationTypes.Options', [])
  .constant('EventNotificationTypes', EventNotificationTypes);
