
/**
 * Module definition and dependencies
 */
angular.module('App.Account.Pay.Controller', [
  'App.Account.Base.Controller',
])

/**
 * Controller
 */
.controller('AccountPayCtrl', function($controller, $state, $filter) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AccountBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Setup
   */
  this.setup = function() {

    //Always add yourself to the circle members
    this.circleMembers.unshift(this.user);

    //Load circles and set up transactions
    this.setupTransactions();
    this.stepSelectTransactions();
  };

  /**
   * Set up transactions
   */
  this.setupTransactions = function() {

    //Get data
    const all = this.unpaidTransactions;
    const {transactionIds} = this.transition.params();

    //Transaction ID's given? Toggle those transactions
    if (Array.isArray(transactionIds) && transactionIds.length > 0) {
      for (const trx of all) {
        if (transactionIds.includes(trx.id)) {
          trx.isToggled = true;
        }
      }
    }

    //Just one transaction? Toggle it
    else if (all.length === 1) {
      all[0].isToggled = true;
    }

    //Otherwise, toggle all due transactions by default
    else {
      for (const trx of all) {
        if (trx.isDue) {
          trx.isToggled = true;
        }
      }
    }

    //Store as reference and create array of transactions we're paying for
    this.determineToggledTransactions();
  };

  /**
   * Toggle transaction
   */
  this.toggleTransaction = function($event) {

    //Get transaction from event
    const {transaction} = $event;

    //Toggle it
    transaction.isToggled = !transaction.isToggled;
    this.determineToggledTransactions();
  };

  /**
   * Determine toggled transactions
   */
  this.determineToggledTransactions = function() {
    this.transactions = this.unpaidTransactions
      .filter(trx => trx.isToggled);
  };

  /**
   * Prepare payment
   */
  this.preparePayment = function() {

    //Prepare transactions for payment data
    const transactionIds = this.transactions.map(trx => trx.id);
    const {user} = this;

    //Create line items
    const lineItems = this.transactions.map(trx => ({
      label: $filter('label')(trx.type, 'TransactionTypes'),
      amount: trx.amount,
      suffix: (trx.member.id !== user.id) ? trx.member.name : '',
    }));

    //Set in payment flow
    this.payment.setLineItems(lineItems);
    this.payment.setExtraData({transactionIds});
    this.payment.setRedirectPath(`account/pay`);
  };

  /**************************************************************************
   * Navigation
   ***/

  /**
   * Select transactions step
   */
  this.stepSelectTransactions = function() {
    this.step = 'selectTransactions';
  };

  /**
   * Next from details
   */
  this.nextFromSelectTransactions = function() {

    //Check anything to do
    if (this.transactions.length === 0) {
      return;
    }

    //Prepare payment
    this.preparePayment();

    //Check if we selected transactions that are not payable with account credit
    const canPayWithAccountCredit = this.transactions
      .every(trx => trx.canPayWithAccountCredit);

    //Check if can pay with credit
    if (canPayWithAccountCredit && this.payment.canUseAccountCredit) {
      return this.stepUseCredit();
    }

    //Can't pay with account credit, disable flag
    this.payment.setIsUsingCredit(false);

    //Onwards
    this.nextFromUseCredit();
  };

  /**
   * Prev from select payment method
   */
  this.prevFromSelectPaymentMethod = function() {

    //Check if we selected transactions that are not payable with account credit
    const canPayWithAccountCredit = this.transactions.every(trx => (
      trx.canPayWithAccountCredit && trx.type !== 'accountCredit'
    ));

    //Check if can pay with credit
    if (canPayWithAccountCredit && this.payment.canUseAccountCredit) {
      return this.stepUseCredit();
    }

    //Backwards
    this.prevFromUseCredit();
  };

  /**
   * Prev from use account credit
   */
  this.prevFromUseCredit = function() {
    this.stepSelectTransactions();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    $state.go('account.overview');
  };
});
