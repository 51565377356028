
/**
 * Module definition and dependencies
 */
angular.module('App.Account.POLiPayment.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('poliPayment', {
    templateUrl: 'account/modals/poli-payment.html',
    controller: 'ModalPOLiPaymentCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalPOLiPaymentCtrl', function(
  $controller, $modalInstance
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Confirm
   */
  this.confirm = function() {
    this.isBusy = true;
    this
      .handler()
      .catch(() => this.isBusy = false);
  };
});
