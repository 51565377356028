
/**
 * Module definition and dependencies
 */
angular.module('Shared.SelectCheck.Component', [])

/**
 * Page selection component
 */
.component('selectCheck', {
  template: `
    <div
      class="SelectCheck"
      ng-class="{'is-selected': $ctrl.isSelected}"
      ng-click="$ctrl.toggle()"
    >
      <i
        class="Icon"
        ng-if="!$ctrl.isSelected"
      >{{$ctrl.unselectedIcon || 'check_box_outline_blank'}}</i>
      <i
        class="Icon"
        ng-if="$ctrl.isSelected"
      >{{$ctrl.selectedIcon || 'check_box'}}</i>
    </div>
  `,
  transclude: true,
  bindings: {
    isSelected: '<',
    onToggle: '&',
    onSelect: '&',
    onUnselect: '&',
    selectedIcon: '@',
    unselectedIcon: '@',
  },
  controller() {

    /**
     * Toggle
     */
    this.toggle = function() {
      if (this.isSelected) {
        this.onUnselect();
      }
      else {
        this.onSelect();
      }
      this.onToggle();
    };
  },
});
