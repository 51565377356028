
/**
 * Module definition and dependencies
 */
angular.module('App.Booking', [
  'App.Booking.View',
  'App.Booking.Overview',
  'App.Booking.Weekly',
  'App.Booking.Grid.Component',
  'App.Booking.ViewBooking.Modal',
  'App.Booking.CreateBooking.Modal',
  'App.Booking.RemoveBooking.Modal',
  'App.Booking.WaitingList.Modal',
  'App.Booking.ViewWaitingList.Modal',
  'App.Booking.MarkAsPaid.Modal',
  'App.Booking.BookingOptions.Component',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('booking', {
    parent: 'app',
    url: '',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'bookings',
    },
  });
});
