
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Edit', [
  'App.Admin.CouponType.Edit.Controller',
  'App.Admin.CouponType.Edit.Info.Card',
  'App.Admin.CouponType.Edit.Details.Card',
  'App.Admin.CouponType.Edit.Usage.Card',
  'App.Admin.CouponType.Edit.Restrictions.Card',
  'App.Admin.CouponType.Edit.Expiration.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.couponType.edit', {
    url: '/edit/:couponTypeId',
    component: 'adminCouponTypeEditRoute',
    params: {
      changeSponsor: {
        value: false,
      },
    },
    resolve: {
      couponType(transition, CouponType) {
        const params = transition.params();
        return CouponType.findById(params.couponTypeId);
      },
      memberships($store) {
        return $store.memberships.query();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminCouponTypeEditRoute', {
  controller: 'AdminCouponTypeEditCtrl',
  templateUrl: 'admin/coupon-type/edit/edit.html',
  bindings: {
    club: '<',
    user: '<',
    couponType: '<',
    page: '<',
    module: '<',
    activities: '<',
    memberships: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.couponType.edit'}, transition => {

    //Get couponType
    const couponType = transition.injector().get('couponType');

    //Set page title and crumb
    Page.setTitle(couponType.name);
    Page.addCrumb({
      sref: 'admin.couponType.edit',
      params: {
        couponTypeId: couponType.id,
      },
    });
  });
});
