
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.Purchase.Controller', [
  'App.Account.Base.Controller',
])

/**
 * Controller
 */
.controller('SubscriptionPurchaseCtrl', function(
  $controller, $state, $modal,
  Text, TextTypes, Subscription, PaymentLineItems, Membership
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AccountBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Setup
   */
  this.setup = function() {

    //Initialize
    this.selection = [];
    this.subData = {};
    this.isForSelf = (this.user === this.member);

    //Set initial step
    this.stepPickMemberships();

    //Check if personal details need to be shown
    this.checkPersonalDetails();

    //Load membership usage
    this.loadUsage();
  };

  /**
   * Check if personal details step should be shown
   */
  this.checkPersonalDetails = function() {

    //For now, only show if purchasing a sub for yourself
    this.showPersonalDetails = (
      this.isForSelf && this.user.needsReminder('checkPersonalDetails')
    );
  };

  /**
  * Select memberships to purchase
  */
  this.selectMemberships = function($event) {

    //Extract selection from event
    const {selection} = $event;

    //Set data
    this.selection = selection;
    this.hasAgreedToConditions = false;
    this.canPurchaseLinked = selection
      .filter(item => item.membership.hasNonArchivedLinked)
      .some(item => this.hasEligibleLinkedMemberships(item.membership));
    this.requiresPayment = this.selection
      .some(item => this.itemRequiresPayment(item));
  };

  /**
   * Load usage
   */
  this.loadUsage = function() {

    //Query usage
    return Membership
      .usage()
      .then(usage => this.usage = usage)
      .then(() => this.mapUsage());
  };

  /**
   * Map usage to memberships
   */
  this.mapUsage = function() {

    //Get data
    const {usage, memberships} = this;
    if (!usage || !memberships) {
      return;
    }

    //Map usage to memberships
    memberships.forEach(membership => {
      membership.numMembers = usage.numMembers[membership.id] || 0;
      membership.inUse = usage.inUse[membership.id] || false;
    });
  };

  /**
   * Load subscription data
   */
  this.loadSubscriptionData = function($event) {

    //Get data
    const {membership} = $event;
    const {member} = this;
    const selection = [{member, membership, linked: []}];

    //Load data
    return Subscription
      .getPurchaseData(selection)
      .then(data => {
        this.subData[membership.id] = data.subscriptions[0];
      });
  };

  /**
   * Check if requires payment
   */
  this.itemRequiresPayment = function(item) {
    return (
      (item.membership.fee && !item.membership.allowPayLater) ||
      (item.membership.autoAccountCredit > 0) ||
      (item.linked && item.linked.some(l => this.itemRequiresPayment(l)))
    );
  };

  /**
   * Check if we have memberships with conditions
   */
  this.hasMembershipsWithConditions = function() {
    return this.selection
      .some(item => item.membership.conditions || item.linked
        .some(linked => linked.membership.conditions));
  };

  /**
   * Check if we have eligible linked memberships
   */
  this.hasEligibleLinkedMemberships = function(primary) {
    return this.memberships
      .some(m => m.isLinked && m.canSelectForPurchase && m.isLinkedTo(primary));
  };

  /**
   * Load subscriptions data
   */
  this.loadSubscriptionsData = function() {

    //Get selection
    const {selection} = this;

    //Get data
    return Subscription
      .getPurchaseData(selection)
      .then(data => this.processSubscriptionsData(data));
  };

  /**
   * Process subscriptions data
   */
  this.processSubscriptionsData = function(data) {

    //Extract data
    const {meta, subscriptions: subs} = data;

    //Store subs and check if anything to pay
    this.subscriptions = subs.map(sub => new Subscription(sub));
    this.hasFees = meta.hasFee;
    this.totalFee = meta.totalFee;
    this.hasAutoAccountCredit = meta.hasAutoAccountCredit;
  };

  /**
   * Prepare payment
   */
  this.preparePayment = function() {

    //Get data
    const selection = Subscription.parseSelection(this.selection);
    const lineItems = PaymentLineItems.fromSubscriptions(this.subscriptions);

    //Make line items and set extra data
    this.payment.setLineItems(lineItems);
    this.payment.setExtraData({selection});
    this.payment.setRedirectPath(`subscription/purchase`);
  };

  /**
   * Set agreement
   */
  this.setAgreedToConditions = function($event) {
    this.hasAgreedToConditions = $event.hasAgreed;
  };

  /**
   * Purchase without payment or when paying later
   */
  this.purchase = function(isPayingLater = false) {

    //Get selection
    const {selection} = this;

    //Change
    return Subscription
      .purchase(selection)
      .then(() => this.onPurchased({isPayingLater}))
      .catch(error => this.onError({error}));
  };

  /**
   * Purchase now without payment
   */
  this.onPurchaseNoPayment = function() {
    return this.purchase();
  };

  /**
   * Pay later
   */
  this.onPayLater = function() {

    //Get data
    const {club, totalFee: amount} = this;
    const payingFor = `membership fee`;

    //Confirm
    return $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-pay-later.html',
        locals: {club, payingFor, amount},
        rejectOnDismissal: true,
      })
      .result
      .then(() => this.purchase(true))
      .catch(() => {});
  };

  /**
   * Paid success handler
   */
  this.onPaid = function($event) {
    this.onPurchased($event);
  };

  /**
   * On purchased handler
   */
  this.onPurchased = function($event) {

    //Get data
    const {isPayingLater, payment} = $event;
    const isPaid = (payment && payment.isPaid);
    const {club, totalFee: amount} = this;

    //Load text then open modal
    Text
      .getText(TextTypes.PAYMENT_INSTRUCTIONS)
      .then(paymentInstructions => {
        $modal
          .open('basic', {
            templateUrl: 'subscription/modals/purchased.html',
            locals: {
              club, isPaid, isPayingLater, amount, paymentInstructions,
            },
          })
          .closed
          .then(() => this.redirectForSubscriptions());
      });
  };

  /**
   * Process payment outcome
   */
  this.processPaymentOutcome = function(payment) {

    //Not paid, show generic modal
    if (!payment.isPaid) {
      return $base.processPaymentOutcome.call(this, payment);
    }

    //Paid, use our custom success handler
    this.onPurchased({payment});
  };

  /**
   * On error handler
   */
  this.onError = function($event) {

    //Get data
    const {error} = $event;
    const {subscriptions} = this;

    //Open modal
    $modal.open('cantPurchase', {locals: {error, subscriptions}});
  };

  /**************************************************************************
   * Navigation
   ***/

  /**
   * Pick memberships
   */
  this.stepPickMemberships = function() {
    this.step = 'pickMemberships';
  };

  /**
   * Add linked memberships
   */
  this.stepAddLinkedMemberships = function() {
    this.step = 'addLinkedMemberships';
  };

  /**
   * Agree to conditions step
   */
  this.stepAgreeConditions = function() {
    this.step = 'agreeConditions';
  };

  /**
   * Personal details step
   */
  this.stepPersonalDetails = function() {
    this.step = 'personalDetails';
  };

  /**
   * Purchase without payment step
   */
  this.stepPurchaseNoPayment = function() {
    this.step = 'purchaseNoPayment';
  };

  /**
   * Next from pick memberships
   */
  this.nextFromPickMemberships = function() {

    //Check if we can purchase linked memberships
    if (this.canPurchaseLinked) {
      return this.stepAddLinkedMemberships();
    }

    //Otherwise, carry on
    this.nextFromAddLinkedMemberships();
  };

  /**
   * Prev from add linked memberships
   */
  this.prevFromAddLinkedMemberships = function() {
    this.stepPickMemberships();
  };

  /**
   * Next from adding linked memberships
   */
  this.nextFromAddLinkedMemberships = function() {

    //Got membership conditions?
    if (this.hasMembershipsWithConditions()) {
      return this.stepAgreeConditions();
    }

    //Onwards
    this.nextFromConditions();
  };

  /**
   * Prev from conditions
   */
  this.prevFromConditions = function() {

    //Check if we can purchase linked memberships
    if (this.canPurchaseLinked) {
      return this.stepAddLinkedMemberships();
    }

    //Otherwise go back to start
    this.prevFromAddLinkedMemberships();
  };

  /**
   * Next from conditions
   */
  this.nextFromConditions = function() {

    //Need to check personal details?
    if (this.showPersonalDetails) {
      return this.stepPersonalDetails();
    }

    //Otherwise skip
    this.nextFromPersonalDetails();
  };

  /**
   * Prev from personal details
   */
  this.prevFromPersonalDetails = function() {

    //Got membership conditions?
    if (this.hasMembershipsWithConditions()) {
      return this.stepAgreeConditions();
    }

    //Backwards
    this.prevFromConditions();
  };

  /**
   * Next from personal details
   */
  this.nextFromPersonalDetails = function() {

    //Load and process subscriptions data first
    this
      .loadSubscriptionsData()
      .then(() => {

        //No fee?
        if (!this.hasFees) {
          return this.stepPurchaseNoPayment();
        }

        //Prepare payment
        this.preparePayment();

        //Has fee, check if can pay with credit
        if (this.payment.canUseAccountCredit && !this.hasAutoAccountCredit) {
          return this.stepUseCredit();
        }

        //Onwards
        this.nextFromUseCredit();
      });
  };

  /**
   * Prev from purchase without payment
   */
  this.prevFromPurchaseNoPayment = function() {

    //Got membership conditions?
    if (this.showPersonalDetails) {
      return this.stepPersonalDetails();
    }

    //Backwards
    this.prevFromPersonalDetails();
  };

  /**
   * Prev from use account credit
   */
  this.prevFromUseCredit = function() {
    this.prevFromPurchaseNoPayment();
  };

  /**
   * Prev from select payment method
   */
  this.prevFromSelectPaymentMethod = function() {

    //Check if can pay with credit
    if (this.payment.canUseAccountCredit && !this.hasAutoAccountCredit) {
      return this.stepUseCredit();
    }

    //Backwards
    this.prevFromUseCredit();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    $state.go('subscription.overview');
  };
});
