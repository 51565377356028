
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.RenewAssignee.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('renewAssignee', {
    templateUrl: 'admin/resource/modals/renew-assignee.html',
    controller: 'ModalRenewAssigneeCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalRenewAssigneeCtrl', function(
  $controller, $modalInstance
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get resource term details
    const {startDate, endDate} = this.assignee;
    const {unit, amount} = this.resource.term;

    //Create model
    this.model = {
      startDate: startDate.clone(),
      endDate: endDate.clone().add(amount, unit),
      meta: {
        createTransaction: this.resource.fee > 0,
        notifyByEmail: true,
        amount: this.resource.fee || '',
      },
    };
  };

  /**
   * Toggle notification
   */
  this.toggleNotify = function(notifyByEmail) {
    this.model.meta.notifyByEmail = notifyByEmail;
  };

  /**
   * Toggle transaction
   */
  this.toggleTransaction = function(createTransaction) {
    this.model.meta.createTransaction = createTransaction;
  };

  /**
   * Renew
   */
  this.renew = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Save
    this.isSaving = true;
    this
      .handler(this.assignee.id, this.model)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isSaving = false);
  };
});
