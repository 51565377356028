
/**
 * Module definition and dependencies
 */
angular.module('App.Account.Booking.Controller', [
  'App.Account.Base.Controller',
])

/**
 * Controller
 */
.controller('AccountBookingCtrl', function(
  $controller, $store, $state, $timeout, $q, Push, DateFormat, Booking, Coupon
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AccountBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Destroy
   */
  this.$onDestroy = function() {
    $timeout.cancel(this.paymentTimeout);
  };

  /**
   * Setup
   */
  this.setup = function() {

    //Nothing to do?
    if (!this.booking || this.booking.isRemoved) {
      return $state.go('bookings');
    }

    //Paid for booking
    if (this.booking.isPaid) {
      return this.redirectToBooking();
    }

    //Check if allowed to pay
    const hasMember = this.booking.hasMember(this.user);
    const isOwner = this.booking.isOwner(this.user);
    const {needsPayment, forOthers} = this.booking;
    const isAllowedToPay = ((hasMember && forOthers) || (isOwner && !forOthers));

    //Check if valid to pay for this booking
    if (!needsPayment || !isAllowedToPay) {
      return $state.go('bookings');
    }

    //Check coupons
    this.checkCoupons();

    //Create timeout
    this.paymentTimeout = $timeout(() => {
      this.cancel();
    }, 10 * 60 * 1000);

    //Set initial step
    this.stepPayForBooking();
  };

  /**
   * Reload bookings and coupons (used after partial coupon payment)
   */
  this.reloadBookingsAndCoupons = function() {

    //Must be for a booking series
    const {booking, user} = this;
    const {series} = booking;
    if (!series) {
      this.bookings = [];
      return;
    }

    //Prep data for filters
    const members = booking.members.map(member => member.id);
    const mode = booking.mode.id;
    const areUsable = true;
    const isPaid = false;

    //Add user to query if booking is made by a member and
    //the user is not in the booking.member array
    if (user && !booking.forOthers && !booking.hasMember(user)) {
      members.push(user.id);
    }

    //Load bookings that are still left unpaid
    return $q.all([
      Booking
        .query({series, isPaid})
        .then(data => this.bookings = data.bookings),
      Coupon
        .query({areUsable, mode, members})
        .then(coupons => this.coupons = coupons)
        .then(() => this.checkCoupons()),
    ]);
  };

  /**
   * Prepare payment
   */
  this.preparePayment = function() {

    //Get data
    const {id: bookingId, activity, fee, startDate} = this.booking;
    const num = this.bookings.length;
    const label = `${activity.name} booking${num > 1 ? ' ' + num + 'x' : ''}`;
    const sub = startDate.format(DateFormat.formats.standard);
    const amount = fee.amount * num;

    //Make line items and set extra data
    this.payment.setLineItems([{label, sub, amount}]);
    this.payment.setExtraData({bookingId});
    this.payment.setRedirectPath(`account/booking/${bookingId}`);
  };

  /**
   * Check coupons
   */
  this.checkCoupons = function() {

    //Initialize flag
    this.canPayWithCoupons = false;

    //Get data
    const {coupons, booking} = this;
    if (!booking || coupons.length === 0) {
      return;
    }

    //Check if there are at least enough sessions left for this booking
    this.canPayWithCoupons = booking.enoughCouponSessions(coupons);
  };

  /**
   * Pay with coupons
   */
  this.payWithCoupons = function($event) {
    const {coupons} = $event;
    return this.booking
      .useCouponsOwn(coupons)
      .then(() => this.reloadBookingsAndCoupons())
      .then(() => {

        //Clear bookings store
        $store.bookings.clear();

        //No bookings left to pay for? Go back to booking page
        if (this.bookings.length === 0) {
          const params = this.getBookingParams();
          $state.go('bookings', params);
        }
      });
  };

  /**************************************************************************
   * Navigation
   ***/

  /**
   * View booking step
   */
  this.stepPayForBooking = function() {
    this.step = 'payForBooking';
  };

  /**
   * Next from pay for booking
   */
  this.nextFromPayForBooking = function() {

    //Prepare payment
    this.preparePayment();

    //Check if can pay with credit
    if (this.payment.canUseAccountCredit) {
      return this.stepUseCredit();
    }

    //Onwards
    this.nextFromUseCredit();
  };

  /**
   * Prev from use account credit
   */
  this.prevFromUseCredit = function() {
    this.stepPayForBooking();
  };

  /**
   * Get booking params
   */
  this.getBookingParams = function() {

    //Get booking details
    const bookingId = this.booking.id;
    const activity = this.booking.activity.identifier;
    const date = this.booking.startDate.format('YYYY-MM-DD');

    //Return params
    return {activity, date, bookingId};
  };

  /**
   * Payment success handler
   */
  this.onPaymentSuccess = function() {

    //Clear timeout
    $timeout.cancel(this.paymentTimeout);

    //Clear bookings store
    $store.bookings.clear();

    //Redirect to booking
    this.redirectToBooking();
  };

  /**
   * Redirect to booking
   */
  this.redirectToBooking = function() {

    //Get data
    const {user} = this;
    const params = this.getBookingParams();

    //Go back to view booking
    $state
      .go('bookings', params)
      .then(() => Push.ask(user, 'booking'));
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {

    //Clear timeout
    $timeout.cancel(this.paymentTimeout);

    //Redirect back to booking page
    const params = this.getBookingParams();
    $state.go('bookings', params);
  };
});
