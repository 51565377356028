
/**
 * Module definition and dependencies
 */
angular.module('Shared.CustomField.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('customField', {
    model: 'CustomField',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'customFields',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('customFields', {
    model: 'CustomField',
    dataKey: 'customFields',
  });
})

/**
 * Model definition
 */
.factory('CustomField', function($api, $baseModel, CustomFieldTypes) {

  /**
   * Default data
   */
  const defaultData = {
    type: CustomFieldTypes.TEXT,
    options: '',
    isVisible: true,
    isEditable: false,
    isRequired: false,
  };

  /**
   * Constructor
   */
  function CustomField(data, parent) {
    $baseModel.call(this, angular.extend({}, defaultData, data || {}), parent);

    //Helper flags
    Object.defineProperty(this, 'isCustomField', {
      get() {
        return true;
      },
    });
    Object.defineProperty(this, 'isText', {
      get() {
        return (this.type === CustomFieldTypes.TEXT);
      },
    });
    Object.defineProperty(this, 'isParagraph', {
      get() {
        return (this.type === CustomFieldTypes.PARAGRAPH);
      },
    });
    Object.defineProperty(this, 'isDate', {
      get() {
        return (this.type === CustomFieldTypes.DATE);
      },
    });
    Object.defineProperty(this, 'isFile', {
      get() {
        return (this.type === CustomFieldTypes.FILE);
      },
    });
    Object.defineProperty(this, 'isDropdown', {
      get() {
        return (this.type === CustomFieldTypes.DROPDOWN);
      },
    });
    Object.defineProperty(this, 'isCheckboxes', {
      get() {
        return (this.type === CustomFieldTypes.CHECKBOXES);
      },
    });
    Object.defineProperty(this, 'isBoolean', {
      get() {
        return (this.type === CustomFieldTypes.BOOLEAN);
      },
    });
    Object.defineProperty(this, 'isUrl', {
      get() {
        return (this.type === CustomFieldTypes.URL);
      },
    });

    //Can we display this field in the member directory
    Object.defineProperty(this, 'canBeInDirectory', {
      get() {
        return !this.isFile;
      },
    });

    //Can we display this field in the member directory overview
    Object.defineProperty(this, 'canBeInDirectoryOverview', {
      get() {
        return (
          this.isText ||
          this.isDropdown ||
          this.isDate ||
          this.isUrl ||
          this.isBoolean
        );
      },
    });

    //Number of options
    Object.defineProperty(this, 'numOptions', {
      get() {
        if (Array.isArray(this.options)) {
          return this.options.length;
        }
        return this.options.split('\n').length;
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(CustomField.prototype, $baseModel.prototype);

  /**
   * To JSON converter
   */
  CustomField.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Strip data
    json.club = $baseModel.onlyId(json.club);

    //Return JSON
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save
   */
  CustomField.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.customField[method](data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  CustomField.prototype.patch = function(data) {

    //Get ID
    const {id} = this;

    //Call API
    return $api.customField
      .patch({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  CustomField.prototype.delete = function() {
    return $api.customField
      .delete(null, this).then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  CustomField.query = function(filter) {
    return $api.customField.query(filter);
  };

  /**
   * Find by ID
   */
  CustomField.findById = function(id) {
    return $api.customField.get({id});
  };

  //Return
  return CustomField;
});
