
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Circles.ManagePermissions.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('managePermissions', {
    templateUrl: 'admin/people/circles/modals/manage-permissions.html',
    controller: 'ModalManagePermissionsCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalManagePermissionsCtrl', function(
  $controller, $modalInstance, $modal, $notice
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Create model subset
    this.model = this.circleMember.extract([
      'permissions',
    ]);

    //Get all circle leads
    this.circleLeads = this.circle.members
      .filter(member => member.isLead);
  };

  /**
   * Update permissions
   */
  this.updatePermissions = function(property, value) {
    this.model.permissions[property] = value;
  };

  /**
   * Remove circle lead
   */
  this.removeAsLead = function() {

    //Get data
    const {circle, circleMember: member} = this;

    const handler = function() {
      return this.circle.patchOwn({isLead: false});
    };

    //Open modal
    $modal
    .open('basic', {
      templateUrl: 'admin/people/circles/modals/confirm-leave-circle-lead.html',
      locals: {circle, member, handler},
    })
    .result
    .then(
      result => $modalInstance.resolve(result)
    );
  };

  /**
   * Leave the circle
   */
  this.leaveCircle = function() {

    //Get data
    const {circle, circleMember: member} = this;

    //Define handler
    const handler = function() {
      return circle.deleteOwn();
    };

    //Open modal
    $modal
    .open('basic', {
      templateUrl: 'admin/people/circles/modals/confirm-leave-circle.html',
      locals: {circle, member, handler},
    })
    .result
    .then(
      result => $modalInstance.resolve(result),
      $notice.show(`Left circle`)
    );
  };

  /**
   * Save
   */
  this.save = function() {

    //Define handler
    this.handler = function(model) {
      return this.circle.patchOwn(model);
    };

    //Save
    this.isSaving = true;
    this
      .handler(this.model)
      .then(
        result => $modalInstance.resolve(result),
        $notice.show('Permissions updated')
      )
      .finally(() => this.isSaving = false);
  };
});
