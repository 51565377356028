
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event', [
  'App.Admin.Event.Add',
  'App.Admin.Event.Edit',
  'App.Admin.Event.Overview',
  'App.Admin.Event.Controller',
  'App.Admin.Event.Rules.Card',
  'App.Admin.Event.Questions.Card',
  'App.Admin.Event.Attendees.Card',
  'App.Admin.Event.Interested.Card',
  'App.Admin.Event.EditRule.Modal',
  'App.Admin.Event.EditQuestion.Modal',
  'App.Admin.Event.EditNotification.Modal',
  'App.Admin.Event.AddGuest.Modal',
  'App.Admin.Event.AddAttendees.Modal',
  'App.Admin.Event.EditAttendee.Modal',
  'App.Admin.Event.ViewAttendee.Modal',
  'App.Admin.Event.AddInterested.Modal',
  'App.Admin.Event.ConfirmUpdate.Modal',
  'App.Admin.Event.ConfirmDelete.Modal',
  'App.Admin.Event.ConfirmDeleteAttendee.Modal',
  'App.Admin.Event.PayWithMembership.Modal',
  'App.Admin.Event.EventOptions.Component',
  'App.Admin.Event.QuestionOptions.Component',
  'App.Admin.Event.NotificationOptions.Component',
  'App.Admin.Event.AttendeeOptions.Component',
  'App.Admin.Event.AttendanceOptions.Component',
  'App.Admin.Event.InterestOptions.Component',
  'App.Admin.Event.InterestedOptions.Component',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.event', {
    url: '/events',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'events',
    },
  });
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.event.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.event.overview', title});
  }, {priority: 10});
});
