
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Add.Term.Card', [])

/**
 * Component
 */
.component('cardMembershipAddTerm', {
  templateUrl: 'admin/membership/cards/term.html',
  controller: 'CardMembershipAddTermCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    membership: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipAddTermCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMembershipEditTermCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Flags
    this.isEdit = false;
    this.isAdd = true;

    //Defaults
    if (typeof this.model.isUnlimited === 'undefined') {
      this.model.isUnlimited = false;
    }
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {model, membership} = this;
    this.onMerge({$event: {membership, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge
    this.merge();

    //Go to next relevant step
    if (this.membership.isLinked || this.membership.isUnlimited) {
      this.card.next();
    }
    else {
      this.card.nextTab();
    }
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previous();
  };
});
