
/**
 * Define values
 */
const AVAILABLE = 'available';
const ASSIGNED = 'assigned';
const EXPIRING = 'expiring';
const EXPIRED = 'expired';

/**
 * Define array
 */
const ResourceStatus = [
  {
    value: AVAILABLE,
    label: 'Available',
  },
  {
    value: ASSIGNED,
    label: 'Assigned',
  },
  {
    value: EXPIRING,
    label: 'Expiring',
  },
  {
    value: EXPIRED,
    label: 'Expired',
  },
];

/**
 * Set properties
 */
ResourceStatus.AVAILABLE = AVAILABLE;
ResourceStatus.ASSIGNED = ASSIGNED;
ResourceStatus.EXPIRING = EXPIRING;
ResourceStatus.EXPIRED = EXPIRED;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.ResourceStatus.Options', [])
  .constant('ResourceStatus', ResourceStatus);
