
/**
 * Module definition and dependencies
 */
angular.module('App.Account.Credit.Controller', [])

/**
 * Controller
 */
.controller('AccountCreditCtrl', function(
  $controller, $state, $filter, $storage, Settings
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AccountBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Setup
   */
  this.setup = function() {

    //Set initial step
    this.stepSelectAmount();

    //Determine amounts
    this.determineMinimumAmount();
    this.determineAvailableAmounts();
    this.determineInitialAmount();

    //Set last, if available
    this.setLastAmount();
  };

  /**
   * Determine minimum amount
   */
  this.determineMinimumAmount = function() {
    this.minAmount = Settings.get('accountCredit.minTopUpAmount');
  };

  /**
   * Determine initial amount
   */
  this.determineInitialAmount = function() {
    if (this.minAmount > 50) {
      this.amount = this.amounts[0].value;
    }
    else {
      this.amount = 50;
    }
  };

  /**
   * Determine available amounts
   */
  this.determineAvailableAmounts = function() {

    //Get data
    const {minAmount} = this;
    const amounts = [10, 20, 50, 100, 200, 500];

    //Initialize
    this.amounts = [];

    //Add min amount unless it's lower than 10
    if (minAmount >= 10) {
      this.amounts.push({
        value: minAmount,
        label: $filter('currency')(minAmount),
      });
    }

    //Check pre-defined options
    for (const amount of amounts) {

      //Check if amount is valid
      if (amount > minAmount) {
        this.amounts.push({
          value: amount,
          label: $filter('currency')(amount),
        });
      }

      //Limit to the first four options
      if (this.amounts.length === 4) {
        break;
      }
    }

    //Lastly, add other option
    this.amounts.push({
      value: '',
      label: 'Other',
    });
  };

  /**
   * Set last amount
   */
  this.setLastAmount = function() {

    //Get data
    const lastAmount = $storage.get('payment.lastAccountCreditAmount');
    const lastOther = $storage.get('payment.lastAccountCreditOther');

    //Invalid amount
    if (!lastAmount || lastAmount < this.minAmount) {
      return;
    }

    //Set amount and flag
    this.isOtherAmount = !!lastOther;
    this.amount = lastAmount;
  };

  /**
   * Set amount
   */
  this.setAmount = function($event) {
    const {amount} = $event;
    $storage.set('payment.lastAccountCreditAmount', amount);
    $storage.set('payment.lastAccountCreditOther', false);
    this.amount = amount;
    this.isOtherAmount = (amount === '');
  };

  /**
   * Set other amount
   */
  this.setOtherAmount = function($event) {
    const {amount} = $event;
    $storage.set('payment.lastAccountCreditAmount', amount);
    $storage.set('payment.lastAccountCreditOther', true);
    this.amount = amount;
  };

  /**
   * Prepare payment
   */
  this.preparePayment = function() {

    //Get data
    const {amount} = this;
    const label = `Account credit`;

    //Make line items and set extra data
    this.payment.setLineItems([{label, amount}]);
    this.payment.setExtraData({amount});
    this.payment.setRedirectPath(`account/credit`);
    this.payment.setIsUsingCredit(false);
  };

  /**************************************************************************
   * Navigation
   ***/

  /**
   * Select amount step
   */
  this.stepSelectAmount = function() {
    this.step = 'selectAmount';
  };

  /**
   * Next from select amount
   */
  this.nextFromSelectAmount = function() {
    this.preparePayment();
    this.stepSelectPaymentMethod();
  };

  /**
   * Prev from select payment method
   */
  this.prevFromSelectPaymentMethod = function() {
    this.stepSelectAmount();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    $state.go('account.overview');
  };
});
