
/**
 * Module definition and dependencies
 */
angular.module('Shared.MemberPicker.Component', [])

/**
 * Member picker component
 */
.component('memberPicker', {
  templateUrl: 'shared/member-picker/member-picker.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    members: '<ngModel',
    visitors: '<*',
    user: '<',
    notSelf: '<',
    alwaysSelf: '<',
    includeStaff: '<',
    maxPeople: '<',
    minLength: '<',
    onChange: '&',
    onRemoveVisitor: '&',
  },

  /**
   * Component controller
   */
  controller($document, $element, $timeout, $debounce, $keyCodes, Member) {

    //Helper vars
    const $ctrl = this;
    let selectedIndex = -1;

    //Keep track of searches, prevent older searches overwriting newer ones
    let currentSearch = 0;
    let lastProcessedSearch = 0;

    /**
     * Prevent invalid input
     */
    function preventInvalidInput(event) {

      //Whitelisted characters
      const allowed = [
        $keyCodes.SPACE,
        $keyCodes.DASH,
        $keyCodes.COLON,
        $keyCodes.COMMA,
        $keyCodes.QUOTE,
        $keyCodes.COMBINED,
      ];

      //Check if control or alpha characters (including whitelisted ones)
      if (!$keyCodes.isControl(event) && !$keyCodes.isAlpha(event, allowed)) {
        event.preventDefault();
      }
    }

    /**
     * Check if input was control
     */
    function isControlInput(event) {
      let keys = [$keyCodes.UP, $keyCodes.DOWN, $keyCodes.ENTER, $keyCodes.ESC];
      return (keys.indexOf(event.keyCode) > -1);
    }

    /**
     * Hide results helper for document clicks
     */
    function hideResults(event) {
      const $input = $element.find('input');
      if ($input.length && !$input[0].contains(event.target)) {
        $ctrl.hideResults();
      }
    }

    /**
     * Init
     */
    this.$onInit = function() {

      //Propagate focus
      $element.attr('tabindex', -1);
      $element.on('focus', () => {
        const $input = $element.find('input');
        if ($input.length) {
          $input[0].focus();
        }
      });

      //Global click event handler
      $document.on('click', hideResults);

      //Initialize results and flags
      this.results = [];
      this.searchName = '';
      this.isSearching = false;
      this.isShowingResults = false;

      //No visitors given
      if (!this.visitors) {
        this.visitors = [];
      }

      //Override model options
      this.ngModel.$overrideModelOptions({
        allowInvalid: true,
      });

      //Empty check
      this.ngModel.$isEmpty = function() {
        return (!Array.isArray($ctrl.members) || $ctrl.members.length === 0);
      };

      //Debounced search handler
      this.searchDebounced = $debounce(this.doSearch, this, 250);
    };

    /**
     * Destroy
     */
    this.$onDestroy = function() {
      $document.off('click', hideResults);
    };

    /**
     * Change handler
     */
    this.$onChanges = function() {

      //Members must be an array
      if (!Array.isArray(this.members)) {
        this.members = [];
      }

      //Visitors must be an array
      if (!Array.isArray(this.visitors)) {
        this.visitors = [];
      }

      //Check if can add
      this.canAdd = (
        !this.maxPeople ||
        (this.members.length + this.visitors.length) < this.maxPeople
      );

      //Check validity
      this.ngModel.$validate();
      const $input = $element.find('input');
      if ($input.length) {
        $input[0].focus();
      }
    };

    /**
     * Key down handler
     */
    this.keydown = function(event) {

      //Prevent invalid input
      preventInvalidInput(event);

      //Arrows up/down move selection, enter confirms selection
      if (this.isShowingResults && isControlInput(event)) {
        event.preventDefault();
        if (event.keyCode === $keyCodes.UP) {
          this.moveSelectionUp();
        }
        else if (event.keyCode === $keyCodes.DOWN) {
          this.moveSelectionDown();
        }
        else if (event.keyCode === $keyCodes.ENTER) {
          this.confirmSelection();
        }
        else if (event.keyCode === $keyCodes.ESC) {
          this.hideResults();
        }
      }

      //Backspace removes last member if search name is empty
      else if (event.keyCode === $keyCodes.BACKSPACE) {
        if (this.searchName === '' && this.members.length > 0) {
          const index = this.members.length - 1;
          const member = this.members[index];
          if (this.alwaysSelf && this.user && this.user.isSame(member)) {
            if (this.members.length > 1) {
              this.removeMember(index - 1);
            }
            return;
          }
          this.removeMember(index);
        }
      }
    };

    /**
     * Key up handler
     */
    this.keyup = function(event) {

      //If control input, skip further handling
      if (isControlInput(event)) {
        return;
      }

      //Get name
      const name = (this.searchName || '').trim();

      //Should we search?
      if (!this.minLength || name.length >= this.minLength) {
        this.searchDebounced(name);
      }
      else if (this.hasResults()) {
        this.clearResults();
        this.clearSelection();
      }
    };

    /**************************************************************************
     * Search
     ***/

    /**
     * Actual search handler
     */
    this.doSearch = function(name) {

      //Toggle flag
      this.isSearching = true;

      //Number this search
      const thisSearch = ++currentSearch;
      const {includeStaff} = this;
      const filter = {name, includeStaff};

      //Do search now
      return Member
        .findByName(filter)

        //Check if we've gotten an old search back
        .then(members => {
          if (thisSearch > lastProcessedSearch) {
            return members;
          }
          throw new Error('Old search');
        })

        //Filter members
        .then(members => members.filter(member => {

          //If user given, ignore it if needed
          if (this.user && this.notSelf && this.user.isSame(member)) {
            return false;
          }

          //Already in members?
          if (this.members.find(existing => existing.id === member.id)) {
            return false;
          }

          //All good
          return true;
        }))

        //Process the results
        .then(members => {
          this.clearSelection();
          this.results = members;
          if (members && members.length > 0) {
            this.isShowingResults = true;
          }
          lastProcessedSearch = thisSearch;
        })

        //Handle errors
        .catch(error => {
          if (error.message === 'Old search') {
            return;
          }
          throw error;
        })

        //Done searching
        .finally(() => this.isSearching = false);
    };

    /**************************************************************************
     * Results navigation & handling
     ***/

    /**
     * Check if we have results
     */
    this.hasResults = function() {
      return (this.results && this.results.length > 0);
    };

    /**
     * Clear results
     */
    this.clearResults = function() {
      this.results = [];
      this.isShowingResults = false;
    };

    /**
     * Show results
     */
    this.showResults = function() {
      if (this.hasResults()) {
        this.isShowingResults = true;
      }
    };

    /**
     * Hide results
     */
    this.hideResults = function() {

      //Small timeout to allow click event to fire on mobile, which it doesn't
      //seem to do
      $timeout(() => {
        this.isShowingResults = false;
      }, 10);
    };

    /**
     * Select item
     */
    this.select = function(index) {
      selectedIndex = index;
    };

    /**
     * Check if selected
     */
    this.isSelected = function(index) {
      return (selectedIndex === index);
    };

    /**
     * Clear selection
     */
    this.clearSelection = function() {
      selectedIndex = undefined;
    };

    /**
     * Move selection up
     */
    this.moveSelectionUp = function() {
      if (typeof selectedIndex === 'undefined') {
        if (this.results.length > 0) {
          selectedIndex = this.results.length - 1;
        }
      }
      else if (selectedIndex > 0) {
        selectedIndex--;
      }
    };

    /**
     * Move selection down
     */
    this.moveSelectionDown = function() {
      if (typeof selectedIndex === 'undefined') {
        if (this.results.length > 0) {
          selectedIndex = 0;
        }
      }
      else if (selectedIndex < (this.results.length - 1)) {
        selectedIndex++;
      }
    };

    /**
     * Confirm selection
     */
    this.confirmSelection = function() {
      if (selectedIndex >= 0 && this.results[selectedIndex]) {
        this.addMember(this.results[selectedIndex]);
      }
    };

    /**************************************************************************
     * Member management
     ***/

    /**
     * Add member
     */
    this.addMember = function(member) {

      //Number of members
      const num = this.members.length + this.visitors.length;

      //Unable to add?
      if (this.maxPeople && num >= this.maxPeople) {
        return;
      }

      //Add to array
      this.members.push(member);
      this.changedMembers();

      //Clear search name
      this.searchName = '';

      //Clear results
      this.clearResults();
      this.clearSelection();
    };

    /**
     * Remove particular member
     */
    this.removeMember = function(index) {

      //Validate
      const member = this.members[index];
      if (!member ||
        (this.alwaysSelf && this.user && this.user.isSame(member))) {
        return;
      }

      //Remove member
      this.members.splice(index, 1);
      this.changedMembers();
    };

    /**
     * Remove particular visitor
     */
    this.removeVisitor = function(index) {

      //Validate
      const visitor = this.visitors[index];
      if (!visitor) {
        return;
      }

      //Call handler
      this.onRemoveVisitor({$event: {index}});
    };

    /**
     * Changed members
     */
    this.changedMembers = function() {

      //Mark model controller as dirty and indicate to validate
      //Validate is needed because the model change is not registered, on
      //account of the array still being the same object
      this.ngModel.$setDirty();
      this.ngModel.$validate();

      //Check if can add more
      this.canAdd = (
        !this.maxPeople ||
        (this.members.length + this.visitors.length) < this.maxPeople
      );

      //Propagate members array
      if (this.onChange) {
        this.onChange({
          members: this.members,
        });
      }

      //Blur input if max members reached, otherwise focus
      $timeout(() => {
        const $input = $element.find('input');
        if (!$input.length) {
          return;
        }
        if (this.maxPeople && this.members.length === this.maxPeople) {
          $input[0].blur();
        }
        else {
          $input[0].focus();
        }
      });
    };

    /**
     * Check if able to remove a certain member
     */
    this.canRemove = function(member) {
      return (!this.alwaysSelf || (this.user && !this.user.isSame(member)));
    };
  },
});
