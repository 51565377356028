
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Visitor.Done', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('visitor.done', {
    url: '/done',
    component: 'visitorDoneRoute',
  });
})

/**
 * Route component
 */
.component('visitorDoneRoute', {
  controller: 'VisitorDoneCtrl',
  templateUrl: 'portal/visitor/steps/done.html',
  bindings: {
    club: '<',
    visit: '<',
    isKiosk: '<',
  },
})

/**
 * Controller
 */
.controller('VisitorDoneCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('VisitorBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);
});
