
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.Stripe.Status.Card', [])

/**
 * Component
 */
.component('cardIntegrationsStripeStatus', {
  templateUrl: 'admin/integrations/stripe/status.html',
  controller: 'CardIntegrationsStripeStatusCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    isAvailable: '<',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsStripeStatusCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get data
    const {data} = this.integration;

    //Flags
    this.hasStrategy = !!data.cover;
    this.isSetupComplete = this.hasStrategy;
  };
});
