
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.XeroSignup.Controller', [])

 /**
  * Controller
  */
 .controller('XeroSignupCtrl', function(
   Xero
 ) {

   /**
    * On init
    */
   this.$onInit = function() {

     //Initiate Xero connection for sign up
     this.initiateXeroConnection();

   };

   /**
    * Initiate Xero connection
    */
   this.initiateXeroConnection = function() {
     return Xero
      .fromAppStore()
      .catch(error => this.error = error)
      .finally(() => this.isLoading = false);
   };
 });
