
/**
 * Module definition and dependencies
 */
angular.module('App.Home.Alerts.Card', [])

/**
 * Component
 */
.component('cardHomeAlerts', {
  templateUrl: 'home/cards/alerts.html',
  require: {
    card: '^^',
  },
  bindings: {
    alerts: '<',
  },
  controller($storage) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Get hidden alerts
      this.hidden = $storage.get('home.hiddenAlerts', {});

      //Filter out hidden alerts
      this.visible = this.alerts
        .filter(alert => !this.hidden[alert.id]);

      //Remove alerts from hidden that no longer exist
      for (const id in this.hidden) {
        if (this.hidden.hasOwnProperty(id)) {
          if (!this.alerts.some(alert => alert.id === id)) {
            delete this.hidden[id];
          }
        }
      }

      //Store
      $storage.set('home.hiddenAlerts', this.hidden);
    };

    /**
     * Hide an alert
     */
    this.hide = function(alert) {

      //Hide alert
      this.visible = this.visible.filter(a => a !== alert);
      this.hidden[alert.id] = true;

      //Store
      $storage.set('home.hiddenAlerts', this.hidden);
    };
  },
});
