
/**
 * Module definition and dependencies
 */
angular.module('Shared.PortalFooter.Component', [])

/**
 * Component
 */
.component('portalFooter', {
  template: `
  <footer class="PortalFooter">
    <a href="https://helloclub.com" target="_blank" title="{{::app.title}} website"><img alt="Logo" src="logo-text-frello.svg" class="PortalFooter-logo"></a>
    <small>
      <a href="https://helloclub.com/terms-of-service" target="_blank">Terms of service</a> •
      <a href="https://helloclub.com/privacy-policy" target="_blank">Privacy policy</a>
    </small>
  </footer>
  `,
});
