
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area.Edit', [
  'App.Admin.Area.Edit.Controller',
  'App.Admin.Area.Edit.Details.Card',
  'App.Admin.Area.Edit.Info.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.area.edit', {
    url: '/edit/:areaId',
    component: 'adminAreaEditRoute',
    params: {
      changeSponsor: {
        value: false,
      },
    },
    resolve: {
      area(transition, $store) {
        const params = transition.params();
        return $store.areas.findById(params.areaId);
      },
    },
  });
})

/**
 * Route component
 */
.component('adminAreaEditRoute', {
  controller: 'AdminAreaEditCtrl',
  templateUrl: 'admin/area/edit/edit.html',
  bindings: {
    club: '<',
    user: '<',
    area: '<',
    page: '<',
    module: '<',
    system: '<',
    activities: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.area.edit'}, transition => {

    //Get area
    const area = transition.injector().get('area');

    //Set page title and crumb
    Page.setTitle(area.name);
    Page.addCrumb({
      sref: 'admin.area.edit',
      params: {
        areaId: area.id,
      },
    });
  });
});
