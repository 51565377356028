
/**
 * Module definition and dependencies
 */
angular.module('Shared.Weekdays.Filter', [])

/**
 * Filter definition
 */
.filter('weekdays', function(moment) {

  /**
   * Helper to get weekday names
   */
  function getWeekdayNames(numDays) {
    if (numDays === 1) {
      return moment.wkDays();
    }
    if (numDays < 4) {
      return moment.wkDaysShort();
    }
    return moment.wkDaysMin();
  }

  //Filter
  return function(days, noDaysLabel = '') {

    //Invalid input
    if (!days || !Array.isArray(days)) {
      return '';
    }

    //No days selected?
    if (days.length === 0) {
      return noDaysLabel;
    }

    //All days selected?
    if (days.length === 7) {
      return 'Every day';
    }

    //Weekend selected?
    if (days.length === 2 && days.includes(6) && days.includes(7)) {
      return 'Weekend';
    }

    //Weekdays selected?
    if (
      days.length === 5 && days.includes(1) &&
      days.includes(2) && days.includes(3) &&
      days.includes(4) && days.includes(5)
    ) {
      return 'Weekdays';
    }

    //Get weekday names and sort days
    const names = getWeekdayNames(days.length);
    days.sort();

    //List days
    return days
      .map(day => names[day - 1])
      .join(', ');
  };
});
