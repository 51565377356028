
/**
 * Module definition and dependencies
 */
angular.module('Shared.DiscountOptions.Component', [])

/**
 * Access options component
 */
.component('discountOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li>
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    discount: '<',
    onEdit: '&',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Edit
     */
    this.edit = function() {
      const {discount} = this;
      this.onEdit({$event: {discount}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      const {discount} = this;
      this.onDelete({$event: {discount}});
    };
  },
});
