
/**
 * Module definition and dependencies
 */
angular.module('Shared.Help.Component', [])

/**
 * Component
 */
.component('help', {
  template: `
    <div class="Help Help--{{$ctrl.type || 'default'}}">
      <a ng-if="$ctrl.url" ng-href="{{$ctrl.url}}" target="_blank" ng-click="$event.stopPropagation()">
        <i class="Icon Icon--s" tooltip="{{$ctrl.tip || 'Click to view the help article'}}">{{$ctrl.icon || 'help'}}</i>
      </a>
      <i class="Icon Icon--s" tooltip="{{$ctrl.tip}}" ng-if="$ctrl.tip && !$ctrl.url">{{$ctrl.icon || 'help'}}</i>
    </div>
  `,
  bindings: {
    tip: '@',
    url: '@',
    icon: '@',
    type: '@',
  },
});
