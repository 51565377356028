
/**
 * Module definition and dependencies
 */
angular.module('Shared.CommentsList.Component', [
  'Shared.CommentsList.Item.Component',
  'Shared.CommentsList.Post.Component',
])

/**
 * Comments list component
 */
.component('commentsList', {
  template: `
    <div class="CommentsList">
      <div class="CommentsList-guide"></div>
      <comments-list-item
        ng-repeat="comment in $ctrl.comments track by comment.id"
        user="$ctrl.user"
        comment="comment"
        on-delete="$ctrl.onDelete({$event})"
      ></comments-list-item>
      <comments-list-post
        ng-if="$ctrl.canComment"
        user="$ctrl.user"
        on-save="$ctrl.onSave({$event})"
      ></comments-list-post>
    </div>
  `,
  bindings: {
    user: '<',
    comments: '<*',
    onSave: '&',
    onDelete: '&',
  },
  controller() {

    /**
     * On init
     */
    this.$onInit = function() {
      this.canComment = (this.user && !this.user.isSuper());
    };
  },
});
