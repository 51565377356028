
/**
 * Define values
 */
const NOT_VACCINATED = 'notVaccinated';
const VACCINATED = 'vaccinated';
const EXPIRED = 'expired';
const EXEMPT = 'exempt';
const UNKNOWN = 'unknown';

/**
 * Define array
 */
const VaccinationStatus = [
  {
    value: UNKNOWN,
    label: 'Unknown',
  },
  {
    value: VACCINATED,
    label: 'Vaccinated',
  },
  {
    value: NOT_VACCINATED,
    label: 'Not vaccinated',
  },
  {
    value: EXEMPT,
    label: 'Exempt',
  },
  {
    value: EXPIRED,
    label: 'Expired',
  },
];

/**
 * Set properties
 */
VaccinationStatus.NOT_VACCINATED = NOT_VACCINATED;
VaccinationStatus.VACCINATED = VACCINATED;
VaccinationStatus.EXPIRED = EXPIRED;
VaccinationStatus.EXEMPT = EXEMPT;
VaccinationStatus.UNKNOWN = UNKNOWN;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.VaccinationStatus.Options', [])
  .constant('VaccinationStatus', VaccinationStatus);
