
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Contacts.ContactOptions.Component', [])

/**
 * Contact options component
 */
.component('contactOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="$ctrl.canEdit">
        <a ui-sref="admin.people.contacts.edit({contactId: $ctrl.contact.id})">Edit</a>
      </li>
      <li ng-if="$ctrl.canDelete" class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-menu>
  `,
  bindings: {
    user: '<',
    contact: '<',
    canEdit: '<',
    canDelete: '<',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Delete
     */
    this.delete = function() {
      this.onDelete({$event: {contact: this.contact}});
    };
  },
});
