
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Report.Governing.Controller', [])

/**
 * Controller
 */
.controller('AdminReportGoverningCtrl', function(
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Assign params to self
    Object.assign(this, this.transition.params());

    //Check if comparing
    this.isComparing = Object.keys(this.compData).length > 0;

    //Get data
    const base = this.baseData;
    const comp = this.compData;

    //Totals
    this.totals(base);
    this.totals(comp);

    //By region and club
    this.showRegions = base.childClubDepth > 1;
    if (this.showRegions) {
      this.byRegion = this.combine(base, comp);
      this.byClub = this.combine(base, comp, true);
    }
    else {
      this.byClub = this.combine(base, comp);
    }
  };

  /**
   * Helper to combine base and comp data
   */
  this.combine = function(base, comp, nested = false) {
    const compChildren = this.children(comp, nested);
    return this.children(base, nested).map(item => {
      return {
        name: item.name,
        base: item,
        comp: compChildren?.find(c => c.name === item.name) ?? {},
      };
    });
  };

  /**
   * Helper to get children, optionally nested
   */
  this.children = function(item, nested = false) {
    if (!nested) {
      return item.childClubs;
    }
    return item.childClubs?.flatMap(child => child.childClubs ?? []);
  };

  /**
   * Helper to total
   */
  this.totals = function(item) {

    //Initialise own totals
    const props = ['total', 'male', 'female', 'diverse', 'hidden', 'unknown'];
    for (const key of props) {
      item[key] = 0;
    }

    //Process history
    item.history?.forEach(h => {
      item.total += h.count;
      if (h.gender) {
        item[h.gender] += h.count;
      }
    });

    //Add child totals
    item.childClubs?.forEach(child => {
      this.totals(child);
      for (const key of props) {
        item[key] += child[key];
      }
    });
  };
});
