
/**
 * Module definition and dependencies
 */
angular.module('Shared.Recurrence.Filter', [])

/**
 * Filter definition
 */
.filter('recurrence', function(moment) {

  /**
   * Helper to get base
   */
  function getBase(recurrence) {
    if (recurrence.frequency > 1) {
      return 'every ' + recurrence.frequency + ' ' + recurrence.interval;
    }
    if (recurrence.interval === 'days') {
      return 'daily';
    }
    if (recurrence.interval === 'weeks') {
      return 'weekly';
    }
    if (recurrence.interval === 'months') {
      return 'monthly';
    }
    if (recurrence.interval === 'years') {
      return 'annually';
    }
  }

  /**
   * Helper to get weekday names
   */
  function getWeekdayNames(weekdays) {
    const names = moment.wkDays();
    return weekdays.map(day => names[day - 1]);
  }

  /**
   * Helper to get the instance of a day of week in a month
   */
  function getMonthlyDayOf(checkDate) {

    //First, find the first date of the month that falls on this weekday
    let dayOfWeek = checkDate.isoWeekday();
    let date = checkDate.clone().startOf('month');
    while (date.isoWeekday() !== dayOfWeek) {
      date.add(1, 'day');
    }

    //Now determine which instance was picked and how many of these days
    //there are in the selected month
    let instance;
    let count = 0;
    while (date.isSame(checkDate, 'month')) {
      count++;
      if (date.isSame(checkDate, 'day')) {
        instance = count;
      }
      date.add(1, 'week');
    }

    //Determine friendly text of instance
    switch (instance) {
      case 1:
        instance = 'first';
        break;
      case 2:
        instance = 'second';
        break;
      case 3:
        instance = 'third';
        break;
      case 4:
        instance = 'fourth';
        break;
      case 5:
        instance = 'fifth';
    }

    //Return
    return instance;
  }

  /**
   * Filter function
   */
  return function(recurrence) {

    //Validate input
    if (!recurrence || !angular.isObject(recurrence)) {
      return '';
    }

    //Get base
    let base = getBase(recurrence);

    //Daily, done
    if (recurrence.interval === 'days') {
      return base;
    }

    //Weekly, append weekdays
    if (recurrence.interval === 'weeks') {
      if (!Array.isArray(recurrence.days) || recurrence.days.length === 0) {
        return base;
      }
      recurrence.days.sort();
      return `${base} on ${getWeekdayNames(recurrence.days).join(', ')}`;
    }

    //Get first date as moment
    let firstDate = moment(recurrence.firstDate);

    //Monthly
    if (recurrence.interval === 'months') {
      if (recurrence.monthlyDayOf === 'week') {
        let nth = getMonthlyDayOf(firstDate);
        return `${base} on the ${nth} ${firstDate.format('dddd')}`;
      }
      else if (recurrence.monthlyDayOf === 'month') {
        return `${base} on the ${firstDate.format('Do')}`;
      }
    }

    //Annually, append day of year
    if (recurrence.interval === 'years') {
      return `${base} on ${firstDate.format('MMMM Do')}`;
    }

    //Can't figure out
    return '';
  };
});
