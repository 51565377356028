
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.EditQuestion.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editEventQuestion', {
    templateUrl: 'admin/event/modals/edit-question.html',
    controller: 'ModalEventQuestionCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalEventQuestionCtrl', function(
  $controller, $modalInstance, $focus, CustomFieldTypes, ExistsError,
  MembershipConstraintLabels, MembershipConstraints
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set flags
    this.isSaving = false;
    this.isError = false;

    //Extract model
    this.model = this.question.extract([
      'label', 'question', 'type', 'options', 'isRequired', 'isVisible',
      'constraint', 'memberships',
    ]);

    //Services
    this.CustomFieldTypes = CustomFieldTypes
      .filter(type => type.value !== CustomFieldTypes.FILE);
    this.membershipConstraints = MembershipConstraintLabels;

    //Default constraint
    if (!this.model.constraint) {
      this.model.constraint = MembershipConstraints.ALL;
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    if (!this.isEdit) {
      $focus('questionLabel');
    }
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;
    if (property === 'isVisible' && !value) {
      this.model.isRequired = false;
    }
  };

  /**
   * Propagate label
   */
  this.propagateLabel = function() {
    if (this.model.label && !this.model.question) {
      this.model.question = this.model.label;
    }
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isErrorExists = false;
    this.isErrorOther = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(error => {
        if (error instanceof ExistsError) {
          this.isErrorExists = true;
        }
        else {
          this.isErrorOther = true;
        }
      })
      .finally(() => this.isSaving = false);
  };
});
