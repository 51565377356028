
/**
 * Module definition and dependencies
 */
angular.module('App.Event.SignUpViaSub.Controller', [])

/**
 * Controller
 */
.controller('EventSignUpViaSubCtrl', function(
  $state, $modal
) {

  //Get controllers
  const $ctrl = this;

  //Extend
  angular.extend($ctrl);

  /**
   * On init
   */
  this.$onInit = function() {

    //Check route is valid
    this.checkRouteValid();
  };

  /**
   * Check route is valid
   */
  this.checkRouteValid = function() {

    //Get data
    const {user, subscription: sub} = this;

    //Check subscription
    if (!sub || sub.isPast || !sub.canSignUpToEvents) {
      return this.goBack();
    }

    //If user is suspended, can't sign up to event
    if (user && user.isSuspended) {
      return $modal
        .open('basic', {
          templateUrl: 'modals/suspended.html',
          locals: {user},
        })
        .closed
        .then(() => this.goBack());
    }
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.goBack();
  };

  /**
   * Go back, either to view event, or to subscription overview
   */
  this.goBack = function() {
    $state.go('subscription.overview');
  };
});
