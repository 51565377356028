
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Edit.Anchor.Card', [])

/**
 * Component
 */
.component('cardMembershipEditAnchor', {
  templateUrl: 'admin/membership/cards/anchor.html',
  controller: 'CardMembershipEditAnchorCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    membership: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipEditAnchorCtrl', function(
  moment, AnchorPeriods, Weekdays, Months, Durations
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;
    this.Months = Months;
    this.Weekdays = Weekdays;
    this.Durations = Durations;

    //Create model subset
    this.model = this.membership.extract([
      'isAnchored', 'isProRated', 'anchor',
    ]);

    //Ensure we have an anchor object
    if (!this.model.anchor) {
      this.model.anchor = {};
    }

    //Ensure we have anchor threshold object
    if (!this.model.anchor.threshold) {
      this.model.anchor.threshold = {};
    }

    //Set flag
    this.showAnchorThreshold = (
      this.model.anchor.threshold &&
      this.model.anchor.threshold.amount > 0
    );

    //Initialize
    this.initialize();
  };

  /**
   * Initialize
   */
  this.initialize = function() {
    this.determineFilteredAnchorPeriods();
    this.determineDaysInMonth();
    this.validateAnchor();
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Toggle anchor threshold visibility
   */
  this.toggleAnchorThreshold = function(showAnchorThreshold) {
    this.showAnchorThreshold = showAnchorThreshold;
    if (!showAnchorThreshold) {
      this.model.anchor.threshold.amount = 0;
    }
    else {
      this.model.anchor.threshold.amount = 1;
      this.model.anchor.threshold.unit = Durations.WEEKS;
    }
  };

  /**
   * Validate anchor
   */
  this.validateAnchor = function(reset = false) {

    //Get anchor for easy access
    const {anchor} = this.model;

    //Ensure we have a period, use default period otherwise
    if (!anchor.period) {
      anchor.period = this.filteredAnchorPeriods[0].value;
    }

    //Always should have a day
    if (!anchor.day || reset) {
      anchor.day = 1;
    }
    else if (anchor.period === 'week' && anchor.day > 7) {
      anchor.day = 1;
    }
    else if (anchor.period === 'month' && anchor.day > 28) {
      anchor.day = 1;
    }

    //Should have a month if relevant
    if ((!anchor.month || reset) && anchor.period === 'year') {
      anchor.month = 1;
    }
    else if (anchor.period !== 'year') {
      anchor.month = undefined;
    }

    //Should have a threshold
    if ((this.showAnchorThreshold && !anchor.threshold) || reset) {
      anchor.threshold = {amount: 1, unit: 'days'};
    }
  };

  /**
   * Validate anchor threshold against membership term
   */
  this.validateThreshold = function() {

    //Get data
    const {term} = this.membership;
    const {isAnchored, anchor} = this.model;

    //Not anchored
    if (!isAnchored || !anchor) {
      this.isErrorInvalidThreshold = false;
      return;
    }

    //Create two timestamps
    const {threshold} = anchor;
    const now = moment();
    const termDate = now.clone().add(term.amount, term.unit);
    const thresholdDate = now.clone().add(threshold.amount, threshold.unit);

    //Can't have a threshold period longer than the membership term
    this.isErrorInvalidThreshold = thresholdDate.isAfter(termDate);
  };

  /**
   * Determine filtered anchor periods
   */
  this.determineFilteredAnchorPeriods = function() {

    //Get term
    const {term} = this.membership;
    if (!term) {
      return;
    }

    //Filter
    this.filteredAnchorPeriods = AnchorPeriods
      .filter(period => {
        if (term.unit === 'years') {
          return (period.value === 'year');
        }
        if (term.unit === 'months') {
          return (period.value === 'year' || period.value === 'month');
        }
        return true;
      });
  };

  /**
   * Helper to create days array for anchor
   */
  this.determineDaysInMonth = function() {

    //Initialize
    const {anchor} = this.model;

    //NOTE: We intentionally disallow the 29, 30 and 31st, unless it's a specific
    //month of the year with known number of days
    let num = 28;

    //Specific month
    if (anchor && anchor.period === 'month' && anchor.month) {
      num = moment().month(anchor.month - 1).daysInMonth();
    }

    //Fill array
    this.days = [];
    for (let d = 1; d <= num; d++) {
      this.days.push(d);
    }

    //Anchor present, fix day
    if (anchor && anchor.day > num) {
      this.model.anchor.day = num;
    }
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {

    //Determine target
    target = target || this.model;
    target[property] = value;

    //Initialize anchor
    if (property === 'isAnchored' && value) {
      this.validateAnchor();
    }

    //Period
    if (property === 'period') {
      this.validateAnchor(true);
    }

    //Month
    if (property === 'month') {
      this.determineDaysInMonth();
      this.validateAnchor();
    }

    //Reset error
    this.isErrorInvalidThreshold = false;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();

    //Validate threshold
    this.validateThreshold();

    //Check if form valid and no threshold error
    return (this.form.$valid && !this.isErrorInvalidThreshold);
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {membership, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {membership, model, isEdit}})
      .then(() => this.form.$setPristine())
      .finally(() => this.isSaving = false);
  };
});
