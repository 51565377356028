
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.LoginCredentials.Controller', [])

/**
 * Controller
 */
.controller('LoginCredentialsCtrl', function(
  $timeout, $focus, $notice, $log, $state, $location,
  Auth, Kiosk, OAuthProviders
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize credentials
    this.credentials = {};

    //Initialize flags
    this.isSubmitting = false;
    this.isPending = false;
    this.isArchived = false;
    this.isTrialEnded = false;
    this.isPlanExpired = false;
    this.isPlanCancelled = false;
    this.isInvalidMethod = false;
    this.isKiosk = Kiosk.isEnabled();

    //Check if HC flag is given
    //TODO: We will eventually need a better way to login as super user
    const {isHc} = $location.search();
    const {club} = this;

    //Check if we have oAuth providers
    this.hasOAuthProviders = OAuthProviders
      .some(provider => {
        return (
          !club || !club.settings ||
          club.settings.signIn.providers[provider.id].isEnabled);
      });

    //Check if sign in is enabled (avoid redirect loop by checking providers)
    if (club && !club.settings.signIn.canUseCredentials) {
      if (this.hasOAuthProviders && !isHc) {
        $state.go('login');
      }
    }
  };

  /**
   * Validity reset for credentials error
   */
  this.resetCredentialsValidity = function() {
    if (this.form.password) {
      this.form.password.$setValidity('credentials', true);
    }
  };

  /**
   * Reset controller state
   */
  this.reset = function() {

    //Reset form
    if (this.form) {
      this.form.$setPristine();
      this.form.$setUntouched();
    }

    //Reset credentials and flags
    //NOTE: This timeout is necessary to prevent flicker of the screen, because
    //momentarily the form will display with errors in it if we reset the
    //credentials
    $timeout(() => {
      this.credentials = {};
      this.cancelReason = null;
      this.isPending = false;
      this.isArchived = false;
      this.isTrialEnded = false;
      this.isPlanExpired = false;
      this.isPlanCancelled = false;
      this.isInvalidMethod = false;
    });
  };

  /**
   * Login
   */
  this.login = function() {

    //Reset credentials validity
    this.resetCredentialsValidity();

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Toggle flag
    this.isSubmitting = true;

    //Login with credentials
    Auth
      .loginWithCredentials(this.credentials)
      .then(() => this.reset())
      .catch(error => {
        switch (error.code) {
          case 'USER_PENDING':
            this.isPending = true;
            break;
          case 'USER_ARCHIVED':
            this.isArchived = true;
            break;
          case 'INVALID_AUTH_METHOD':
            this.isInvalidMethod = true;
            break;
          case 'TRIAL_ENDED':
            this.isTrialEnded = true;
            break;
          case 'PLAN_EXPIRED':
            this.isPlanExpired = true;
            break;
          case 'PLAN_CANCELLED':
            this.isPlanCancelled = true;
            if (error.data && error.data.reason) {
              this.cancelReason = error.data.reason;
            }
            break;
          case 'NOT_AUTHENTICATED':
            if (this.form && this.form.password) {
              this.form.password.$setValidity('credentials', false);
              $focus(this.form.password.$$element);
            }
            break;
          default:
            $log.error(error);
            $notice.show('Error logging in, please try again');
            break;
        }
      })
      .finally(() => this.isSubmitting = false);
  };
});
