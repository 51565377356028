
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Add.Activities.Card', [])

/**
 * Component
 */
.component('cardMemberAddActivities', {
  templateUrl: 'admin/people/members/cards/activities.html',
  controller: 'CardMemberAddActivitiesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    member: '<',
    activities: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberAddActivitiesCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMemberEditActivitiesCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {member, model} = this;
    this.onMerge({$event: {member, model}});
  };

  /**
   * Next step
   */
  this.next = function() {
    this.merge();
    this.card.next();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previous();
  };
});
