
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.General.Card', [])

/**
 * Component
 */
.component('cardSettingsGeneral', {
  templateUrl: 'admin/settings/cards/general.html',
  controller: 'CardSettingsGeneralCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSettingsGeneralCtrl', function(
  $controller, DateFormatOptions, TimeFormatOptions, PhoneFormatOptions
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'general';

    //Options for items per page
    this.ItemsPerPageOptions = [
      10, 25, 50, 100,
    ];

    //Options for date and time formatting
    this.dateFormatOptions = DateFormatOptions;
    this.timeFormatOptions = TimeFormatOptions;
    this.phoneFormatOptions = PhoneFormatOptions;

    //Call parent init
    $base.$onInit.call(this);
  };
});
