
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Flow.Notes.Component', [])

/**
 * Grid component
 */
.component('flowBookingNotes', {
  templateUrl: 'booking/flow/notes.html',
  bindings: {
    booking: '<',
    club: '<',
    activity: '<',
    onPrev: '&',
    onNext: '&',
    onClose: '&',
  },

  /**
   * Controller
   */
  controller($focus, ValidationError, $element) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Toggle multi line if there is more than one visitor with a name
      if (this.booking.visitors.filter(visitor => !!visitor.name).length > 1) {
        this.isMultiLine = true;
      }

      //Check if we have members
      this.hasMembers = (this.booking.numMembers > 0);
      this.hasVisitors = (this.booking.numVisitors > 0);

      //Focus on visitor name
      $focus($element.find('input')[0]);
    };

    /**
    * Toggle details multiline
    */
    this.toggleMultiLine = function() {
      this.isMultiLine = !this.isMultiLine;
    };

    /**
     * Post link
     */
    this.$postLink = function() {
      $focus('notes');
      $focus('name');
      $focus('visitorName0');
    };

    /**
     * Validate
     */
    this.validate = function() {

      //Flag form as submitted
      this.form.$setSubmitted();

      //Clear flags
      this.isErrorMissingVisitorNames = false;
      this.isErrorMissingVisitorName = false;

      //Get data
      const {hasMembers} = this;
      const {forOthers, numVisitors} = this.booking;
      const {requiresVisitorNames} = this.activity;

      //Ensure we have visitor names for every visitor if needed
      if (requiresVisitorNames && !forOthers) {
        if (this.booking.visitors.some(visitor => !visitor.name)) {
          this.isErrorMissingVisitorNames = true;
          return false;
        }
      }

      //Ensure we have at least the first visitor name if admin making a booking
      if (!hasMembers && forOthers && numVisitors) {
        if (this.booking.visitors.every(visitor => !visitor.name)) {
          this.isErrorMissingVisitorName = true;
          return false;
        }
      }

      //Check if form valid
      return this.form.$valid;
    };

    /**
     * Previous step
     */
    this.prev = function() {
      this.onPrev();
    };

    /**
     * Next step
     */
    this.next = function() {

      //Validate
      if (!this.validate()) {
        return;
      }

      //Next step
      this.isBusy = true;
      this
        .onNext()
        .catch(error => {
          if (error instanceof ValidationError) {
            return this.error = error;
          }
          throw error;
        })
        .finally(() => this.isBusy = false);
    };
  },
});
