
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.PayWithAccountCredit.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('payWithAccountCredit', {
    templateUrl: 'admin/finance/modals/pay-with-account-credit.html',
    controller: 'ModalPayWithAccountCreditCtrl',
    rejectOnDismissal: true,
  });
})

/**
 * Controller
 */
.controller('ModalPayWithAccountCreditCtrl', function(
  $controller, $modalInstance, moment, AccountCredit
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.isSaving = false;
    this.isError = false;

    //Create model
    this.model = {
      method: 'accountCredit',
      date: moment().startOf('day'),
      reference: '',
      avoidNegativeAccountCredit: false,
      member: null,
    };

    //Set paying member
    this.setMember(this.member || this.members[0]);
  };

  /**
   * Set member
   */
  this.setMember = function(member) {

    //Toggle flag and set paying member
    this.isCheckingBalance = true;
    this.model.member = member.id;
    this.member = member;

    //Check balance
    AccountCredit
      .balance({memberId: member.id})
      .then(accountCredit => {
        this.accountCredit = accountCredit;
        this.newBalance = this.accountCredit - this.amount;
      })
      .finally(() => this.isCheckingBalance = false);
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Get data and create meta object
    //TODO: Clean this up when migrating to VUE
    const {
      method, date, reference, avoidNegativeAccountCredit, member,
    } = this.model;
    const meta = {payment: {
      method, date, reference, avoidNegativeAccountCredit,
    }};

    //Add member if we can choose
    if (this.members && this.members.length > 0) {
      meta.member = member;
    }

    //Use save handler
    this
      .handler({meta})
      .then(result => $modalInstance.resolve(result))
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };
});
