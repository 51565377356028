
/**
 * Module definition and dependencies
 */
angular.module('App.Event.Signup.Questions.Card', [])

/**
 * Component
 */
.component('cardEventSignupQuestions', {
  templateUrl: 'event/cards/signup-questions.html',
  controller: 'CardEventSignupQuestionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    event: '<',
    answers: '<',
    member: '<',
    needsPayment: '<',
    isPayingWithCoupon: '<',
    onNext: '&',
    onPrev: '&',
    onCancel: '&',
    onSignUp: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventSignupQuestionsCtrl', function(matchesMemberConstraint) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Filter out eligible event questions
    this.questions = this.event.questions
      .filter(question => question.isVisible && this.isEligibleQuestion(question));

    //Set inital value of booleans
    this.questions
      .forEach(question => {
        if (question.isBoolean) {
          this.updateAnswer(question.id, false);
        }
      });
  };

  /**
   * Check if custom question should be shown
   */
  this.isEligibleQuestion = function(question) {
    return matchesMemberConstraint(this.member, question);
  };

  /**
   * Update answer
   */
  this.updateAnswer = function(questionId, answer) {
    this.answers[questionId] = answer;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Signup
   */
  this.signUp = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Toggle flag
    this.isSigningUp = true;

    //Signup
    this
      .onSignUp()
      .catch(error => {
        this.error = error;
        this.isSigningUp = false;
      });
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Onwards
    this.onNext();
  };

  /**
   * Previous step
   */
  this.prev = function() {
    this.onPrev();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
