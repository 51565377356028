
/**
 * Module definition and dependencies
 */
angular.module('Shared.OverrideToggle.Component', [])

/**
 * Page selection component
 */
.component('overrideToggle', {
  template: `
    <div
      class="OverrideToggle"
      ng-class="{
        warning: $ctrl.item.isInSpecialState,
        danger: $ctrl.item.isInOverrideState,
        'is-toggling': $ctrl.isToggling,
        'is-loading': $ctrl.isLoading,
      }"
    >
      <span class="OverrideToggle-name">{{$ctrl.item.name}}</span>
      <small>{{$ctrl.item.stateLabel}}</small>
      <span class="OverrideToggle-icon OverrideToggle-icon--faded" ng-if="$ctrl.item.isInactive && !$ctrl.isLoading">
        <i class="Icon {{$ctrl.iconClassInactive}}">{{$ctrl.item.iconInactive}}</i>
      </span>
      <span class="OverrideToggle-icon" ng-if="$ctrl.item.isActive && !$ctrl.isLoading">
        <i class="Icon {{$ctrl.iconClassActive}}">{{$ctrl.item.iconActive}}</i>
      </span>
      <span class="OverrideToggle-icon OverrideToggle-icon--faded" ng-if="$ctrl.item.isUnknown && !$ctrl.isLoading">
        <i class="Icon">help</i>
      </span>
      <span class="OverrideToggle-icon OverrideToggle-spinner" ng-if="$ctrl.isLoading" ng-class="{'OverrideToggle-spinner--light': $ctrl.item.hasSuccessState || $ctrl.item.isInSpecialState || $ctrl.item.isInOverrideState}">
        <spinner></spinner>
      </span>
    </div>
  `,
  bindings: {
    item: '<',
    isToggling: '<',
    isLoading: '<',
  },
});

