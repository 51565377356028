
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Weekly.Controller', [])

/**
 * Controller
 */
.controller('BookingWeeklyCtrl', function(
  $interval, $controller, $state, moment, Modules
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CalendarCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize properties
    this.hasBookingsModule = Modules.has('bookings');
    this.hasMultipleActivities = (this.activities.length > 1);
    this.canManageBookings = this.user.hasRole('admin', 'eventManager');
    this.period = 'week';
    this.loadOps = [];

    //Default activity
    if (this.activities) {
      this.defaultActivity = this.activities
        .find(activity => activity.isDefault);
    }

    //Setup filter and page
    this.setupFilter();
    this.setupPage();
    this.setupCrumbs();

    //Set today and validate filter
    this.setToday();
    this.validateFilter();

    //Determine info based on filter
    this.determinePeriodInfo();
    this.determineActivityInfo();
    this.determineDateInfo();
    this.determineTimesRange();
    this.createWeeksAndDays();
    this.createTimes();

    //Update URL and load initial data
    this.updateUrl('replace');
    this.loadData();

    //Filter on change handler
    this.filter.onChange(key => {

      //Changed activity is handled
      if (key === 'activity') {
        this.determineActivityInfo();
        this.determineTimesRange();
      }

      //Changed date
      if (key === 'date') {
        this.determineDateInfo();
        this.createWeeksAndDays();
        this.createTimes();
      }

      //Changed either
      this.updateUrl();
      this.clearData();
      this.loadData();
    });

    //Check if still on today and refresh data periodically
    this.interval = $interval(() => {
      this.checkToday();
      this.loadData();
    }, 300 * 1000);
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter, module, activities} = this;
    const {title} = module;
    const params = this.transition.params();

    //Get initial activity and date
    const activity = activities.find(a => (a.identifier === params.activity));
    const date = moment(params.date, 'YYYY-MM-DD').startOf('day');

    //Set page title
    page.setTitle(title);

    //Update period, date and activity
    filter.update('date', date);
    filter.update('period', 'week');
    filter.update('activity', activity ? activity.id : null);
  };

  /**
   * Set crumbs
   */
  this.setupCrumbs = function() {

    //Pop activity crumb
    if (this.page.crumbs.length > 2) {
      this.page.popCrumb();
    }

    //Find activity
    const activity = this.findActivity(this.filter.activity);

    //Add if needed
    if (activity) {
      const params = this.transition.params();
      const title = activity.name;
      this.page.addCrumb({sref: $state.current.name, params, title});
    }
  };

  /**
   * Determine times range
   */
  this.determineTimesRange = function() {

    //Get data
    this.startTime = null;
    this.endTime = null;

    //Determine the min/max start/end times needed
    this.activityAreas
      .forEach(area => {
        area.times.forEach(time => {
          if (!this.startTime || time.startTime < this.startTime) {
            this.startTime = time.startTime;
          }
          if (!this.endTime || time.endTime > this.endTime) {
            this.endTime = time.endTime;
          }
        });
      });

    //Round to whole hours
    this.startTime = Math.floor(this.startTime / 60) * 60;
    this.endTime = Math.ceil(this.endTime / 60) * 60;
  };

  /**
   * Get bookings filter
   */
  this.getBookingsFilter = function() {
    return this.getQueryFilter();
  };

  /**
   * Create times
   */
  this.createTimes = function() {

    //Initialize
    this.times = [];

    //Get data
    const {startTime, endTime} = this;
    if (!startTime || !endTime) {
      return;
    }

    //Create times
    for (let time = startTime; time < endTime; time += 60) {
      this.times.push(time);
    }
  };

  /**
   * Map data onto days
   */
  this.mapDataOntoDays = function() {

    //Get user
    const {user} = this;

    //Loop weeks and days
    for (const days of this.weeks) {
      for (const day of days) {

        //Find events and bookings
        day.events = this.filterEvents(day);
        day.bookings = this.filterBookings(day);
        day.isEmpty = (day.events.length + day.bookings.length === 0);

        //Map by time (for week view only)
        day.byTime = {};
        for (const time of this.times) {

          //Create parts for this time slot
          day.byTime[time] = [];

          //Each time slot is an hour, so we need 4x 15 minute slots
          for (let i = 0; i <= 3; i++) {

            //Placeholder for areas
            day.byTime[time][i] = [];

            //Loop areas
            for (const area of this.activityAreas) {

              //Determine times
              const start = time + i * 15;
              const end = start + 15;

              //Find bookings and events
              const bookings = this.filterBookings(day, start, end, area);
              const events = this.filterEvents(day, start, end, area);

              //Check if booking is our own
              const hasOwn = bookings.some(booking => booking.hasMember(user));

              //Determine tooltip
              let tooltip;
              if (events.length > 0) {
                tooltip = events[0].name;
              }
              else if (bookings.length > 0) {
                tooltip = `${bookings[0].activity.name} booking on ${area.name}`;
              }

              //Create entry
              day.byTime[time][i].push({
                bookings,
                events,
                hasOwn,
                tooltip,
              });
            }
          }
        }
      }
    }
  };
});
