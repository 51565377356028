
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.MergeMembers.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('mergeMembers', {
    templateUrl: 'admin/people/members/modals/merge-members.html',
    controller: 'ModalMergeMembersCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalMergeMembersCtrl', function(
  $controller, $modalInstance, Member, $notice,
  Modules
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Assign source member to model
    this.model = {
      sourceId: this.member.id,
    };

    //Set plural
    this.plural = Modules.plural('members');
  };

  /**
   * Convert mergeMember
   */
  this.convertMergeMember = function(members) {
    this.mergeMembers = members;
    this.mergeMember = members[0];
    this.model.targetId = this.mergeMember ? this.mergeMember.id : null;
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }
    this.isSaving = true;
    this.error = null;

    //Merge members
    Member
      .merge(this.model)
      .then(() => {
        $modalInstance.resolve(this.model.targetId);
        $notice.show(`Merge started in the background`);
      })
      .catch(error => this.error = error)
      .finally(() => this.isSaving = false);
  };
});
