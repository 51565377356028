
/**
 * Module definition and dependencies
 */
angular.module('Shared.Payment.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('payment', {
    model: 'Payment',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'payments',
        isModel: true,
        isArray: true,
      },
      own: {
        url: 'own',
        method: 'GET',
        dataKey: 'payments',
        isArray: true,
        isModel: true,
      },
      count: {
        url: 'count',
        method: 'GET',
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      findByToken: {
        url: '/findByToken',
        method: 'GET',
        isModel: true,
      },
      chargeAndFee: {
        url: '/chargeAndFee/:paymentMethod',
        method: 'POST',
        params: {
          paymentMethod: '@method',
        },
      },
      initiate: {
        url: '/:paymentMethod/pay/:paymentType',
        method: 'POST',
        params: {
          paymentType: '@type',
          paymentMethod: '@method',
        },
      },
      initiatePublic: {
        url: '/:paymentMethod/pay/public/:paymentType',
        method: 'POST',
        params: {
          paymentType: '@type',
          paymentMethod: '@method',
        },
      },
      update: {
        method: 'PUT',
      },
      delete: {
        method: 'DELETE',
      },
      fulfil: {
        url: '/:paymentMethod/fulfil/:paymentId',
        method: 'POST',
        params: {
          paymentId: '@paymentId',
          paymentMethod: '@method',
        },
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('payments', {
    model: 'Payment',
    dataKey: 'payments',
  });
})

/**
 * Model definition
 */
.factory('Payment', function(
  $api, $baseModel, $sync, Subscription, Transaction, Intercom
) {

  /**
   * Constructor
   */
  function Payment(data) {

    //Call base model
    $baseModel.call(this, data);

    //Is editable
    Object.defineProperty(this, 'isEditable', {
      get() {
        if (this.isOnline || this.isPaidByAccountCredit) {
          return false;
        }
        return true;
      },
    });

    //Check if payment is removable
    Object.defineProperty(this, 'isRemovable', {
      get() {
        if (this.isOnline || this.isCredit) {
          return false;
        }
        return true;
      },
    });

    //Is for subscription
    Object.defineProperty(this, 'isForSubscription', {
      get() {
        return (this.type === 'subscription');
      },
    });

    //Is for booking
    Object.defineProperty(this, 'isForBooking', {
      get() {
        return (this.type === 'booking');
      },
    });

    //Is for coupon
    Object.defineProperty(this, 'isForCoupon', {
      get() {
        return (this.type === 'coupon');
      },
    });

    //Is for event attendance
    Object.defineProperty(this, 'isForEvent', {
      get() {
        return (this.type === 'event');
      },
    });

    //Is for account credit
    Object.defineProperty(this, 'isForAccountCredit', {
      get() {
        return (this.type === 'accountCredit');
      },
    });

    //Check if payment of this transaction added account credit
    Object.defineProperty(this, 'hasMutatedAccountCredit', {
      get() {
        return (this.accountCreditAdjustment !== 0);
      },
    });

    //Check if payment of this transaction added account credit
    Object.defineProperty(this, 'hasAddedAccountCredit', {
      get() {
        return (this.accountCreditAdjustment > 0);
      },
    });

    //Check if payment of this transaction deducted account credit
    Object.defineProperty(this, 'hasDeductedAccountCredit', {
      get() {
        return (this.accountCreditAdjustment < 0);
      },
    });

    //Has failed check
    Object.defineProperty(this, 'hasFailed', {
      get() {
        return (this.isError || this.isTimedOut || this.isCancelled);
      },
    });

    //Is ambigious check
    Object.defineProperty(this, 'isAmbiguous', {
      get() {
        return (this.isPending || this.isUnverified);
      },
    });

    //Payment failure reason
    Object.defineProperty(this, 'failureReason', {
      get() {
        const {isError, data} = this;
        if (isError && data) {
          if (data.intent && data.intent.error) {
            return data.intent.error.message;
          }
          if (data.charge && data.charge.error) {
            return data.charge.error;
          }
        }
        return 'Failed';
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Payment.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Payment.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('member', 'Member');
    this.convertToModel('guest', 'Guest');
    this.convertToModel('transactions', 'Transaction', true);

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Payment.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Strip data to IDs only
    json.member = $baseModel.onlyId(json.member);
    json.transactions = $baseModel.onlyId(json.transactions);

    //Return JSON
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Update
   */
  Payment.prototype.update = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Update
    return $api.payment
      .update(data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Payment.prototype.delete = function() {
    return $api.payment
      .delete(null, this)
      .then(() => this);
  };

  /**
   * Download receipt
   */
  Payment.prototype.downloadReceipt = function() {
    return $sync
      .download(`payment/${this.id}/receipt`);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Get charge and fee for a payment
   */
  Payment.chargeAndFee = function(data) {
    return $api.payment.chargeAndFee(data);
  };

  /**
   * Count
   */
  Payment.count = function(filter) {
    return $api.payment
      .count(filter)
      .then(data => data.total || 0);
  };

  /**
   * Initiate payment
   */
  Payment.initiate = function(data, isPublic) {
    const method = isPublic ? 'initiatePublic' : 'initiate';
    return $api.payment[method](data)
      .then(data => Payment.process(data));
  };

  /**
   * Fulfil payment on the server
   */
  Payment.fulfil = function(data) {
    return $api.payment.fulfil(data);
  };

  /**
   * Process payment
   */
  Payment.process = function(data) {

    //Extract data
    const {payment, subscription, subscriptions, transactions} = data;

    //Convert data to models
    if (payment) {
      data.payment = new Payment(payment);
    }
    if (subscription) {
      data.subscription = new Subscription(subscription);
    }
    if (subscriptions) {
      data.subscriptions = subscriptions.map(sub => new Subscription(sub));
    }
    if (transactions) {
      data.transactions = transactions.map(trx => new Transaction(trx));
    }

    //Pass on data
    return data;
  };

  /**
   * Query payments
   */
  Payment.query = function(filter) {
    return $api.payment.query(filter);
  };

  /**
   * Query own payments
   */
  Payment.own = function(filter) {
    return $api.payment
      .own(filter)
      .then(data => data.payments);
  };

  /**
   * Find by ID
   */
  Payment.findById = function(id) {
    return $api.payment.get({id});
  };

  /**
   * Find payment by token
   */
  Payment.findByToken = function(token) {
    return $api.payment.findByToken({token});
  };

  /**
   * Export payments
   */
  Payment.export = function(filter) {
    Intercom.event('Exported payments');
    return $sync.get('payment/export/csv', filter, 'Exporting...');
  };

  //Return
  return Payment;
});
