
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.Payments', [
  'App.Admin.Finance.Payments.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.finance.payments', {
    url: '/payments',
    params: {
      payoutId: {
        value: null,
      },
    },
    component: 'adminFinancePaymentsRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('payments');
      },
      country(Country) {
        return Country.own();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminFinancePaymentsRoute', {
  controller: 'AdminFinancePaymentsCtrl',
  templateUrl: 'admin/finance/payments/payments.html',
  bindings: {
    club: '<',
    filter: '<',
    page: '<',
    user: '<',
    country: '<',
    transition: '<',
    integrations: '<',
  },
});
