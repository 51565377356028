
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Edit.Details.Card', [])

/**
 * Component
 */
.component('cardActivityEditDetails', {
  templateUrl: 'admin/activity/cards/details.html',
  controller: 'CardActivityEditDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    activity: '<',
    system: '<',
    club: '<',
    isSuper: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardActivityEditDetailsCtrl', function(
  $filter, CardCloseReasons
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set flags
    this.isSaving = false;
    this.isEdit = true;

    //Create model subset
    this.model = this.activity.extract([
      'name', 'identifier', 'duration', 'minDuration', 'maxDuration',
      'areaSingular', 'areaPlural', 'allowMultiSlotDuringPeakHours',
      'isDefault', 'isBookable', 'hasGrades', 'isOnSystem', 'isMultiSlotEnabled',
      'isAllowedInKiosk', 'hideMemberDetails', 'requiresVisitorNames',
      'allowRecurringBookings', 'allowChangingBookings', 'removeUnpaidBookings',
      'requiresVaccination',
    ]);
  };

  /**
   * Update model
   */
  this.updateModel = function(key, value) {
    this.model[key] = value;
    if (key === 'isMultiSlotEnabled') {
      if (!value) {
        this.model.allowMultiSlotDuringPeakHours = false;
      }
    }
    if (key === 'isBookable') {
      if (value) {
        this.model.isMultiSlotEnabled = true;
        this.model.allowMultiSlotDuringPeakHours = true;
        this.model.isAllowedInKiosk = true;
        this.model.allowRecurringBookings = true;
        this.model.allowChangingBookings = true;
        this.model.removeUnpaidBookings = true;
      }
      else {
        this.model.isMultiSlotEnabled = false;
        this.model.allowMultiSlotDuringPeakHours = false;
        this.model.isAllowedInKiosk = false;
        this.model.allowRecurringBookings = false;
        this.model.allowChangingBookings = false;
        this.model.removeUnpaidBookings = false;
        this.model.hideMemberDetails = false;
        this.model.requiresVisitorNames = false;
        this.model.requiresVaccination = false;
      }
    }
  };

  /**
   * Generate identifier
   */
  this.generateIdentifier = function() {
    this.model.identifier = $filter('identifier')(this.model.name);
  };

  /**
   * Generate plural area name
   */
  this.generatePlural = function() {
    if (this.model.areaSingular) {
      this.model.areaPlural = this.model.areaSingular + 's';
    }
    else {
      this.model.areaPlural = '';
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {activity, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {activity, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
