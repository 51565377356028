
/**
 * Module definition and dependencies
 */
angular.module('Shared.AddToCategories.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addToCategories', {
    templateUrl: 'modals/add-to-categories.html',
    controller: 'ModalAddToCategoriesCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalAddToCategoriesCtrl', function(
  $controller, $modal, $notice, $modalInstance
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set flags
    this.isConfirming = false;

    //Create model
    this.model = {};
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isConfirming = true;

    //Use handler
    this
      .handler(this.model.categories)
      .then(result => $modalInstance.resolve(result))
      .catch(() => $notice.showError('Failed to add to categories'))
      .finally(() => this.isConfirming = false);
  };

  /**
   * Manage groups
   */
  this.manageCategories = function() {

    //Get model & categories
    const {Model} = this;
    const singular = 'category';
    const plural = 'categories';

    //Open modal
    $modal
      .open('manageCategories', {locals: {
        Model, singular, plural,
      }})
      .result
      .then(categories => {

        //Call reloader
        if (this.reloader) {
          this.reloader();
        }

        //Update categories and initiate model if needed
        this.categories = categories;
        this.model.categories = this.model.categories || [];

        //Remove removed categories from model
        this.model.categories = this.model.categories
          .filter(id => this.categories.some(category => category.id === id));
      });
  };
});
