
/**
 * Module definition and dependencies
 */
angular.module('Shared.Activity.Fee.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('ActivityFee', function(
  $baseModel, FeePolicies, MembershipConstraints
) {

  /**
   * Defaults
   */
  const defaultData = {
    constraint: MembershipConstraints.ALL,
    modes: [],
    timeRange: {},
    policy: FeePolicies.IN_ADVANCE,
  };

  /**
   * Constructor
   */
  function ActivityFee(data) {
    $baseModel.call(this, angular.extend({}, defaultData, data || {}));

    //Fee policy helper properties
    Object.defineProperty(this, 'isPayableInAdvance', {
      get() {
        return (
          this.policy === FeePolicies.IN_ADVANCE ||
          this.policy === FeePolicies.IN_ADVANCE_REFUNDABLE
        );
      },
    });
    Object.defineProperty(this, 'isPayableAfterwards', {
      get() {
        return (
          this.policy === FeePolicies.AFTERWARDS ||
          this.policy === FeePolicies.WITH_CREDIT
        );
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(ActivityFee.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  ActivityFee.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('modes', null, true);
    this.convertToModel('timeRange', 'TimeRange');

    //Return self
    return this;
  };

  //Return
  return ActivityFee;
});
