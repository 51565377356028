
/**
 * Module definition and dependencies
 */
angular.module('App.Header.Crumb.Component', [])

/**
 * Component
 */
.component('crumb', {
  template: `
    <div class="Crumb-item" ng-repeat="crumb in $ctrl.crumbs">
      <a ui-sref="{{crumb.sref}}(crumb.params)" title="{{crumb.title || $ctrl.pageTitle}}">
        {{crumb.title || $ctrl.pageTitle}} <i class="Icon Crumb-icon" ng-if="!$last">chevron_right</i>
      </a>
    </div>
  `,
  bindings: {
    crumbs: '<',
    pageTitle: '<',
  },

  /**
   * Controller
   */
  controller($element) {

    /**
     * On init
     */
    this.$onInit = function() {
      $element.addClass('Crumb');
    };
  },
});
