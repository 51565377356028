
/**
 * Card header component
 */

/**
 * Module definition and dependencies
 */
angular.module('Shared.Cards.CardHeader.Component', [])

/**
 * Component
 */
.component('cardHeader', {
  template: `
    <header class="CardHeader" ng-class="{'is-closable': $ctrl.isClosable, 'is-open': ($ctrl.card.isOpen || $ctrl.card.isStatic), 'is-opening': $ctrl.card.isOpening}" ng-click="$ctrl.close()" ng-if="$ctrl.card.isOpen || $ctrl.card.isOpening || $ctrl.card.isStatic">
      <section>
        <h2 class="CardHeader-bar">
          <i class="CardHeader-close Icon" ng-if="$ctrl.isClosable">keyboard_arrow_up</i>
          <span class="CardHeader-contents" ng-transclude></span>
        </h2>
      </section>
      <div class="CardHeader-loader"></div>
    </header>
  `,
  transclude: true,
  require: {
    card: '^^',
  },
  bindings: {
    isClosable: '<',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Close handler
     */
    this.close = function() {
      if (this.isClosable) {
        this.card.close();
      }
    };
  },
});
