
/**
 * Module definition and dependencies
 */
angular.module('Shared.Text.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config($apiProvider => {

  //Register API endpoint
  $apiProvider.registerEndpoint('text', {
    model: 'Text',
    actions: {
      get: {
        url: '/:type',
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Text', function($api, $q, $baseModel) {

  /**
   * Constructor
   */
  function Text(data) {

    //Initialize
    this.contents = '';

    //Call base method
    $baseModel.call(this, data);

    //Model helper property for modifying the contents of this text
    let _model = this.contents;
    Object.defineProperty(this, 'model', {
      get() {
        return _model;
      },
      set(model) {
        _model = model;
      },
    });
  }

  //Initialize local cache
  const cache = new Map();

  /**
   * Extend base model
   */
  angular.extend(Text.prototype, $baseModel.prototype);

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Reset model
   */
  Text.prototype.resetModel = function() {
    this.model = this.contents;
  };

  /**
   * Save
   */
  Text.prototype.save = function() {

    //Get model
    const {type} = this;
    const contents = this.model;

    //Got an ID? must patch
    if (this.id) {
      return this.patch({contents});
    }

    //Create
    return $api.text
      .create({type, contents})
      .then(data => this.fromJSON(data))
      .then(text => Text.storeInCache(text));
  };

  /**
   * Patch
   */
  Text.prototype.patch = function(data) {
    const {id} = this;
    return $api.text
     .patch({id}, data)
     .then(data => this.fromJSON(data))
     .then(text => Text.storeInCache(text));
  };

  /**
   * Delete
   */
  Text.prototype.delete = function() {
    return $api.text
      .delete(null, this)
      .then(() => Text.removeFromCache(this));
  };

  /**
   * Check if text has been modified
   */
  Text.prototype.isModified = function() {
    return (this.model !== this.contents);
  };

  /**
   * Check if there is text
   */
  Text.prototype.hasText = function() {
    return (this.contents !== '');
  };

  /**
   * Check if there is text in the model
   */
  Text.prototype.hasTextInModel = function() {
    return (this.model !== '');
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Find by type
   */
  Text.findByType = function(type) {
    if (cache.has(type)) {
      const text = cache.get(type);
      return $q.resolve(text);
    }
    return $api.text
      .get({type})
      .then(text => text || new Text({type}))
      .then(text => this.storeInCache(text));
  };

  /**
   * Get text contents of a specific type
   */
  Text.getText = function(type) {

    //Must have type
    if (!type) {
      throw new Error(`Missing text type`);
    }

    //Query
    return this
      .findByType(type)
      .then(text => text.contents);
  };

  /**
   * Cache control
   */
  Text.storeInCache = function(text) {
    cache.set(text.type, text);
    return text;
  };
  Text.removeFromCache = function(text) {
    cache.remove(text.type);
    return text;
  };

  //Return
  return Text;
});
