
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.MailingList.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('mailingList', {
    templateUrl: 'admin/people/members/modals/mailing-list.html',
    controller: 'ModalMailingListCtrl',
    closeOnClick: false,
    resolve: {
      integrations($store) {
        'ngInject';
        return $store.integrations.query();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalMailingListCtrl', function(
  $controller, $notice, $modalInstance
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Initialize flag and get integration
    this.isLoading = true;
    this.integration = this.integrations.find(i => i.type === this.type);

    //Find integration and get default lists
    this.defaultLists = this.integration.data.defaultLists;

    //Load lists
    this.integration.service
      .getListsAndInterests()
      .then(lists => this.setLists(lists))
      .finally(() => this.isLoading = false);
  };

  /**
   * Set lists
   */
  this.setLists = function(lists) {
    this.lists = lists;
    if (this.defaultLists) {
      this.integration.service.toggleDefaults(this.lists, this.defaultLists);
    }
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Get data
    const {member, lists} = this;

    //Confirm
    this.isAdding = true;
    this.isError = false;

    //Add member (always force subscribe)
    this.integration.service
      .addMember(member, lists, true)
      .then(result => {
        $notice.show(`Added to ${this.integration.name}`);
        $modalInstance.resolve(result);
      })
      .catch(() => this.isError = true)
      .finally(() => this.isAdding = false);
  };
});
