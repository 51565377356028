
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Overview', [
  'App.Admin.Activity.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.activity.overview', {
    url: '',
    component: 'adminActivityOverviewRoute',
    resolve: {

      //Refresh activities
      activities($store) {
        return $store.activities.query();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminActivityOverviewRoute', {
  controller: 'AdminActivityOverviewCtrl',
  templateUrl: 'admin/activity/overview/overview.html',
  bindings: {
    club: '<',
    user: '<',
    page: '<',
    module: '<',
    activities: '<',
  },
})

/**
 * Run
 */
.run(($transitions, Intercom) => {

  //Page setup
  $transitions.onSuccess({
    to: 'admin.activity.overview',
  }, () => {

    //Track intercom event
    Intercom.event('Viewed areas');
  });
});
