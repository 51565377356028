
/**
 * Module definition and dependencies
 */
angular.module('Shared.PasswordCheck.Component', [])

/**
 * Time input component
 */
.component('passwordCheck', {
  templateUrl: 'shared/password-check/password-check.html',
  bindings: {
    password: '<',
    user: '<',
    field: '<',
    isStrong: '=',
    needsCases: '<',
    needsDigits: '<',
    needsSymbols: '<',
    minLength: '<',
    maxSequences: '<',
    maxRepeats: '<',
  },

  /**
   * Directive controller
   */
  controller(Password) {

    /**
     * Check if we have a password
     */
    this.hasPassword = function() {
      return (typeof this.password === 'string' && this.password.length > 0);
    };

    /**
     * On change handler
     */
    this.$onChanges = function() {

      //No password?
      let pass = this.password;
      if (!pass) {
        this.isStrong = false;
        this.field.$setValidity('strength', true);
        return;
      }

      //Initialize
      let userData = [];

      //User given?
      if (this.user) {
        userData = [
          this.user.email,
          this.user.username,
        ];
      }

      //Check length?
      if (this.minLength) {
        this.hasLength = Password.hasLength(pass, this.minLength);
      }

      //Check digits
      if (this.needsDigits) {
        this.hasDigits = Password.hasDigits(pass);
      }

      //Check symbols
      if (this.needsSymbols) {
        this.hasSymbols = Password.hasSymbols(pass);
      }

      //Check cases
      if (this.needsCases) {
        this.hasCases = Password.hasCases(pass);
      }

      //Check sequences
      if (this.maxSequences) {
        this.hasSequences = Password.hasSequences(pass, this.maxSequences);
      }

      //Check repeats
      if (this.maxRepeats) {
        this.hasRepeats = Password.hasRepeats(pass, this.maxRepeats);
      }

      //Get weak components
      this.weaknesses = Password.getWeakComponents(pass, userData);
      this.hasWeaknesses = this.weaknesses.length > 0;

      //Set strong flag
      this.isStrong = (
        (!this.minLength || this.hasLength) &&
        (!this.needsDigits || this.hasDigits) &&
        (!this.needsSymbols || this.hasSymbols) &&
        (!this.needsCases || this.hasCases) &&
        (!this.maxSequences || !this.hasSequences) &&
        (!this.maxRepeats || !this.hasRepeats) &&
        !this.hasWeaknesses
      );

      //Invalidate password
      if (this.field) {
        this.field.$setValidity('strength', this.isStrong);
      }
    };
  },
});
