
/**
 * Module definition and dependencies
 */
angular.module('Shared.IconStatus.Component', [])

/**
 * Status icon component
 */
.component('iconStatus', {
  template: `
    <i class="Icon {{$ctrl.iconClass}} color-faded" ng-if="!$ctrl.isBusy && !$ctrl.isError && !$ctrl.isWarning && !$ctrl.isSuccess && !$ctrl.isUnknown && !$ctrl.isSkipped">panorama_fish_eye</i>
    <i class="Icon {{$ctrl.iconClass}} color-faded u-Spinning--reverse" ng-if="$ctrl.isBusy">sync</i>
    <i class="Icon {{$ctrl.iconClass}} color-warning" ng-if="$ctrl.isWarning">error</i>
    <i class="Icon {{$ctrl.iconClass}} color-success" ng-if="$ctrl.isSuccess">check_circle</i>
    <i class="Icon {{$ctrl.iconClass}} color-faded" ng-if="$ctrl.isUnknown">help</i>
    <i class="Icon {{$ctrl.iconClass}} color-faded" ng-if="$ctrl.isSkipped">not_interested</i>
  `,
  bindings: {
    isBusy: '<',
    isError: '<',
    isWarning: '<',
    isSuccess: '<',
    isUnknown: '<',
    isSkipped: '<',
    iconClass: '@',
  },
});
