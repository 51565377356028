
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.Details.Card', [])

/**
 * Component
 */
.component('cardMemberEditDetails', {
  templateUrl: 'admin/people/members/cards/details.html',
  controller: 'CardMemberEditDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    member: '<',
    isViewerOnly: '<',
    onSave: '&',
    isOwn: '<',
  },
})

/**
 * Controller
 */
.controller('CardMemberEditDetailsCtrl', function(
  VaccinationStatus, Genders, CardCloseReasons
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;

    //Set available genders
    this.genders = Genders.filter(gender => gender.value !== 'unknown');
    this.VaccinationStatus = VaccinationStatus;

    //Create model subset
    this.model = this.member.extract([
      'firstName', 'lastName', 'legalName', 'dob', 'gender',
      'email', 'mobile', 'phone', 'address', 'postalAddress',
      'vaccination', 'meta',
    ]);

    //Ensure vaccination data is an object
    if (!this.model.vaccination) {
      this.model.vaccination = {
        status: VaccinationStatus.UNKNOWN,
      };
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving and get data
    this.isSaving = true;
    const {member, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {member, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
