
/**
 * Module definition and dependencies
 */
angular.module('Shared.Access.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('Access', function($baseModel) {

  /**
   * Defaults
   */
  const defaultData = {
    doors: [],
    timeRange: {},
  };

  /**
   * Constructor
   */
  function Access(data) {
    $baseModel.call(this, angular.extend({}, defaultData, data || {}));
  }

  /**
   * Extend base model
   */
  angular.extend(Access.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Access.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('doors', null, true);
    this.convertToModel('timeRange', 'TimeRange');

    //Return self
    return this;
  };

  //Return
  return Access;
});
