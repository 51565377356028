
/**
 * Module definition and dependencies
 */
angular.module('Shared.PaymentFeeStructure.Component', [])

/**
 * Fee structure component
 */
.component('paymentFeeStructure', {
  template: `<span>{{$ctrl.parts | join}}</span>`,
  bindings: {
    structure: '<',
    isCustom: '<',
    concise: '<',
  },
  controller($filter, PaymentFees) {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //No structure
      if (!this.structure) {
        return;
      }

      //Custom
      if (this.isCustom) {
        return this.parts = ['n/a'];
      }

      //Get data
      const {factor, fixed, max} = this.getData();
      const suffix = this.concise ? '' : ' per transaction';

      //Prepare parts
      this.parts = [];

      //Add parts
      if (factor && fixed) {
        const percentage = Math.round(factor * 100 * 10000) / 10000;
        const amount = $filter('currency')(fixed);
        this.parts.push(`${percentage}% + ${amount}${suffix}`);
      }
      else if (factor) {
        const percentage = factor * 100;
        this.parts.push(`${percentage}%${suffix}`);
      }
      else if (fixed) {
        const amount = $filter('currency')(fixed);
        this.parts.push(`${amount}${suffix}`);
      }
      else if (!max) {
        this.parts.push('no fee');
      }
      if (max) {
        const amount = $filter('currency')(max);
        this.parts.push(`max. ${amount}`);
      }
    };

    /**
     * Get data
     */
    this.getData = function() {

      //Get structure
      const {structure} = this;

      //Split in provider/platform
      if (structure.provider || structure.platform) {
        const {provider, platform} = structure;
        return PaymentFees.merge(provider, platform);
      }

      //As is
      return structure;
    };
  },
});
