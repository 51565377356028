
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Flow.View.Component', [])

/**
 * Grid component
 */
.component('flowBookingView', {
  templateUrl: 'booking/flow/view.html',
  bindings: {
    user: '<',
    booking: '<',
    isOwn: '<',
    isAdmin: '<',
    showActivity: '<',
    canManageBookings: '<',
    canPay: '<',
    canEdit: '<',
    canDelete: '<',
    onPay: '&',
    onEdit: '&',
    onDelete: '&',
    onClose: '&',
  },

  /**
   * Controller
   */
  controller($modal, $notice, ErrorCodes, NotAuthorizedError, CouponTypeUses) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Check if can view tags/members
      this.checkCanViewTags();
      this.checkCanViewMembers();
    };

    /**
     * Check if can view tags
     */
    this.checkCanViewTags = function() {

      //No user?
      if (!this.user) {
        this.canViewTags = false;
        return;
      }

      //Admin or event manager can view the tags
      if (this.booking.tags.length > 0 && this.canManageBookings) {
        this.canViewTags = true;
        return;
      }

      //Filter out tags for this member
      const tags = this.booking.tags
        .filter(tag => tag.member === this.user.id);

      //Check if anything there
      this.canViewTags = (tags.length > 0);
    };

    /**
     * Check if can view members
     */
    this.checkCanViewMembers = function() {
      this.canViewMembers = (
        this.user && this.user.hasRole('admin', 'viewer')
      );
    };

    /**
     * Delete
     */
    this.delete = function() {
      this
        .onDelete()
        .catch(error => {
          if (error instanceof NotAuthorizedError) {
            this.isDeleteError = true;
            return;
          }
          throw error;
        });
    };

    /**
     * Show tags
     */
    this.showTags = function() {

      //Get booking
      const {booking} = this;

      //Set as loading
      this.isLoadingTags = true;

      //Load tags
      booking
        .getTags()
        .then(tags => {
          if (tags.length > 0) {
            $modal.open('basic', {
              templateUrl: 'booking/modals/view-tags.html',
              locals: {tags, booking},
            });
          }
        })
        .finally(() => this.isLoadingTags = false);
    };

    /**
     * Pay with coupons
     */
    this.payWithCoupons = function() {

      //Get data
      const {booking} = this;
      const {members, activity, mode, coupons} = booking;
      const {sessionsNeeded} = coupons;
      const label = `${activity.name} booking (${mode.name})`;

      //Filter for eligible coupons
      const filter = {
        areUsable: true,
        usableFor: [CouponTypeUses.BOOKINGS],
        members: members.map(member => member.id),
        mode: mode.id,
      };

      //Define handler
      const handler = coupons => booking.useCoupons(coupons);

      //Open modal
      $modal
        .open('payWithCoupons', {
          locals: {label, sessionsNeeded, activity, filter, members, handler},
        })
        .result
        .then(() => $notice.show('Booking paid'))
        .catch(error => {
          if (error.code === ErrorCodes.paymentMethodInvalid) {
            $notice.showError('Not enough sessions to pay for booking');
          }
          else {
            $notice.showError('Booking payment failed');
          }
        });
    };

    /**
     * Pay with account credit
     */
    this.payWithAccountCredit = function() {

      //Get data
      const {booking} = this;
      const {members, activity, mode, fee} = booking;
      const {amount} = fee;
      const label = `${activity.name} booking (${mode.name})`;

      //Define handler
      const handler = data => booking.markPaid(data);

      //Open modal
      $modal
        .open('payWithAccountCredit', {
          locals: {label, amount, members, handler},
        })
        .result
        .then(() => $notice.show('Booking paid'))
        .catch(error => {
          if (error !== 'cancel') {
            $notice.showError('Booking payment failed');
          }
        });
    };

    /**
     * Mark as paid
     */
    this.markAsPaid = function() {

      //Get data
      const {booking} = this;
      const handler = data => booking.markPaid(data);

      //Open modal
      $modal
        .open('markBookingAsPaid', {
          locals: {booking, handler},
        })
        .result
        .then(() => $notice.show('Booking paid'))
        .catch(() => $notice.showError('Booking payment failed'));
    };
  },
});
