
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Edit.Notifications.Card', [])

/**
 * Component
 */
.component('cardEventEditNotifications', {
  templateUrl: 'admin/event/cards/notifications.html',
  controller: 'CardEventEditNotificationsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    event: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventEditNotificationsCtrl', function(
  $modal, $store, moment, EventNotification, EventNotificationDurations
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flags
    this.isSaving = false;

    //Mark as edit
    this.isEdit = true;
    this.isAdd = false;

    //Create model subset
    this.model = this.event.extract([
      'notifications',
    ]);

    //Load groups
    this.loadGroups();
  };

  /**
   * Load groups
   */
  this.loadGroups = function() {
    $store.memberGroups
      .query(true)
      .then(groups => this.groups = groups);
  };

  /**
   * Next
   */
  this.next = function() {
    this.card.nextTab();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.card.previous();
  };

  /**
   * Add notification
   */
  this.add = function(notification) {

    //Create notification and define handler
    const $ctrl = this;
    const {event, groups} = this;
    const {startDate} = event;
    const now = moment();
    const data = {};

    //Determine suitable threshold
    if (now.clone().add(1, 'day').isAfter(startDate)) {
      data.threshold = {
        amount: 1,
        unit: EventNotificationDurations.HOURS,
      };
    }
    else if (now.clone().add(1, 'week').isAfter(startDate)) {
      data.threshold = {
        amount: 1,
        unit: EventNotificationDurations.DAYS,
      };
    }
    else {
      data.threshold = {
        amount: 1,
        unit: EventNotificationDurations.WEEKS,
      };
    }

    //Create notification
    notification = notification || new EventNotification(data, event);

    //Create copy of notifications and define handler
    const notifications = event.extract('notifications');
    const handler = function(model) {
      notification.fromJSON(model);
      notifications.push(notification);
      return $ctrl.save(notifications);
    };

    //Show modal
    $modal.open('editEventNotification', {
      locals: {event, groups, notification, handler, isEdit: false},
    });
  };

  /**
   * Copy notification
   */
  this.copy = function($event) {

    //Get notification
    const {notification} = $event;

    //Create copy
    const copy = notification.copy();
    this.add(copy);
  };

  /**
   * Edit notification
   */
  this.edit = function($event) {

    //Get notification
    const {notification} = $event;
    const {event, groups} = this;

    //Find and validate index
    const index = event.notifications.findIndex(f => f === notification);
    if (index === -1) {
      return;
    }

    //Create copy of notifications and define handler
    const notifications = event.extract('notifications');
    const handler = (model) => {
      notifications[index].fromJSON(model);
      return this.save(notifications);
    };

    //Show modal
    $modal.open('editEventNotification', {
      locals: {notification, groups, event, handler, isEdit: true},
    });
  };

  /**
   * Delete notification
   */
  this.delete = function($event) {

    //Get notification
    const {notification} = $event;
    const {event, memberships} = this;

    //Find and validate index
    const index = event.notifications.findIndex(f => f === notification);
    if (index === -1) {
      return;
    }

    //Create copy of notifications and define handler
    const notifications = event.extract('notifications');
    const handler = () => {
      notifications.splice(index, 1);
      return this.save(notifications);
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'admin/event/modals/confirm-delete-notification.html',
        locals: {notification, event, memberships, handler},
      });
  };

  /**
   * Toggle notification enabled
   */
  this.toggleEnabled = function(notification, value) {

    //Update notification
    notification.isEnabled = value;

    //Update event
    const {notifications} = this.event;
    this.event.patch({notifications});
  };

  /**
   * Save event on server
   */
  this.save = function(notifications) {

    //Get data
    const {event, isEdit} = this;
    const model = {notifications};

    //When adding, dont save on server
    if (!isEdit) {
      return this.onMerge({$event: {event, model}});
    }

    //Get more data
    const {isRecurring, isMultiDay} = event;
    const method = (isRecurring || isMultiDay) ? 'series' : 'instance';

    //Save event
    return this
      .onSave({$event: {event, method, model, isEdit}});
  };
});
