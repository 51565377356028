
/**
 * Module definition and dependencies
 */
angular.module('Shared.DoorPositionStates.Constant', [])

/**
 * Constant definition
 */
.constant('DoorPositionStates', {
  OPEN: 'open',
  CLOSED: 'closed',
  UNKNOWN: 'unknown',
});
