
/**
 * Define values
 */
const HOURS = 'hours';
const DAYS = 'days';
const WEEKS = 'weeks';
const MONTHS = 'months';

/**
 * Constant definition
 */
const EventNotificationDurations = [
  {
    label: 'Hours',
    value: HOURS,
  },
  {
    label: 'Days',
    value: DAYS,
  },
  {
    label: 'Weeks',
    value: WEEKS,
  },
  {
    label: 'Months',
    value: MONTHS,
  },
];

/**
 * Set properties
 */
EventNotificationDurations.HOURS = HOURS;
EventNotificationDurations.DAYS = DAYS;
EventNotificationDurations.WEEKS = WEEKS;
EventNotificationDurations.MONTHS = MONTHS;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.EventNotificationDurations.Options', [])
  .constant('EventNotificationDurations', EventNotificationDurations);
