
/**
 * Module definition and dependencies
 */
angular.module('Shared.ContactClub.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('contactClub', {
    templateUrl: 'modals/contact-club.html',
    controller: 'ModalContactClubCtrl',
    closeOnClick: false,
    resolve: {
      club($store) {
        'ngInject';
        return $store.club.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalContactClubCtrl', function(
  $controller, $modalInstance, $focus
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get data
    const {subject, message} = this;

    //Flags
    this.isSending = false;
    this.hasSent = false;
    this.isError = false;

    //Model
    this.model = {subject, message};
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    if (!this.model.subject) {
      $focus('subject');
    }
    else {
      $focus('message');
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Send message
   */
  this.send = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as sending and reset error flag
    this.isSending = true;
    this.hasSent = false;
    this.isError = false;

    //Post now
    this.club
      .contact(this.model)
      .then(() => this.hasSent = true)
      .catch(() => this.isError = true)
      .finally(() => this.isSending = false);
  };
});
