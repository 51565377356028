
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Weekly', [
  'App.Booking.Weekly.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('booking.weekly', {
    url: '/bookings/weekly/:activity/:date',
    params: {
      activity: {
        value: null,
        squash: true,
        dynamic: true,
      },
      date: {
        value: null,
        squash: true,
        dynamic: true,
      },
    },
    component: 'bookingWeeklyRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('calendar');
      },
      areas($store) {
        return $store.areas.query();
      },
    },
  });
})

/**
 * Component
 */
.component('bookingWeeklyRoute', {
  controller: 'BookingWeeklyCtrl',
  templateUrl: 'booking/weekly/weekly.html',
  bindings: {
    club: '<',
    user: '<',
    page: '<',
    module: '<',
    filter: '<',
    areas: '<',
    activities: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, moment, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'booking.weekly'}, transition => {

    //Get data
    const {title} = transition.injector().get('module');
    const date = moment().format('YYYY-MM-DD');
    const params = {activity: 'all', date};

    //Set page title and crumb
    Page.setTitle(title);
    Page.addCrumb({sref: 'bookings', title, params});
    Page.addCrumb({sref: 'booking.weekly', title: 'Week view', params});
  }, {priority: 10});
});
