
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.AccountOwner.Card', [])

/**
 * Component
 */
.component('cardClubAccountOwner', {
  templateUrl: 'admin/club/cards/account-owner.html',
  controller: 'CardClubAccountOwnerCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    plans: '<',
    admins: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubAccountOwnerCtrl', function(CardCloseReasons) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;

    //Create model subset
    this.model = this.club.extract([
      'accountOwner',
    ]);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving and clear errors
    this.isSaving = true;

    //Get model
    const {model} = this;

    //Save
    this
      .onSave({$event: {model}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .catch(error => {

        //Capture exceptions
        if (error instanceof Error) {
          Sentry.captureException(error);
        }
      })
      .finally(() => this.isSaving = false);
  };
});
