
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.Xero.Status.Card', [])

/**
 * Component
 */
.component('cardIntegrationsXeroStatus', {
  templateUrl: 'admin/integrations/xero/status.html',
  controller: 'CardIntegrationsXeroStatusCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    isAvailable: '<',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsXeroStatusCtrl', function(
  $controller, $modal, $interval, $notice, Xero, moment
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get data
    const {data} = this.integration;

    //Flags
    this.isSyncing = data.isSyncing;
    this.hasSynced = !!data.lastSync;
    this.hasAccounts = !!data.accounts;
    this.hasContact = !!data.contact;
    this.hasSettings = (data.startDate && data.invoiceFrequency);

    //Check if sync end date passed
    if (data.endDate && moment().startOf('day').isAfter(data.endDate)) {
      this.hasSyncEndDatePassed = true;
    }

    //Check if ready for initial sync
    this.canSync = (
      this.hasAccounts &&
      this.hasContact &&
      this.hasSettings
    );

    //Check if setup is complete
    this.isSetupComplete = (
      this.hasAccounts &&
      this.hasContact &&
      this.hasSettings &&
      this.hasSynced
    );

    //Check status periodically
    this.statusInterval = $interval(() => {
      this.checkStatus();
    }, 10000);
  };

  /**
   * Clear interval
   */
  this.$onDestroy = function() {
    $interval.cancel(this.statusInterval);
  };

  /**
   * Connect
   */
  this.connect = function() {
    if (this.isConnecting || !this.isAvailable) {
      return;
    }
    $base.connect.call(this);
  };

  /**
   * Check status
   */
  this.checkStatus = function() {

    //Not connected?
    if (!this.isConnected) {
      return;
    }

    //Get status
    Xero
      .getStatus()
      .then(status => this.isSyncing = status.isSyncing);
  };

  /**
   * Sync manually
   */
  this.sync = function(isInitial = false) {

    //Can't sync yet
    if (!this.canSync) {
      $notice.showError('Please complete the other steps before syncing');
      return;
    }

    //Reset flags
    this.isSyncSuccess = false;
    this.isSyncError = false;

    //Create handler
    const handler = () => {

      //Sync now
      return Xero
        .sync()
        .then(() => {
          this.isSyncSuccess = true;
          this.isSyncing = true;
          this.hasSynced = true;
          this.integration.data.lastSync = moment();
        })
        .catch(error => {

          //Token rejected
          if (error.code === 'NOT_CONNECTED') {
            this.isConnected = false;
            this.hasSyncEndDatePassed = false;
            return this.noLongerConnected();
          }

          //Generic sync error
          this.isSyncError = true;
        });
    };

    //Show modal
    $modal.open('basic', {
      templateUrl: 'admin/integrations/xero/modals/sync.html',
      locals: {handler, isInitial},
    });
  };

  /**
   * Reset integration
   */
  this.reset = function() {

    //Get integration data
    const {data} = this.integration;

    //Create handler
    const handler = (fromDate, toDate) => {
      return Xero
        .reset(fromDate, toDate)
        .then(() => $notice.show('Data reset started'));
    };

    //Show modal
    $modal.open('xeroReset', {
      locals: {handler, data},
    });
  };
});
