
/**
 * Module definition and dependencies
 */
angular.module('Shared.Tag.Model', [
  'BaseModel.Service',
  'Shared.Tag.Validity.Model',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('tag', {
    model: 'Tag',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'tags',
        isArray: true,
        isModel: true,
      },
      queryOwn: {
        url: 'own',
        method: 'GET',
        dataKey: 'tags',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      findByData: {
        url: 'findByData',
        method: 'POST',
        isModel: true,
      },
      randomNumber: {
        url: 'randomNumber',
        method: 'GET',
      },
      create: {
        method: 'POST',
      },
      createPinForMember: {
        url: 'pin',
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('tags', {
    model: 'Tag',
    dataKey: 'tags',
  });
})

/**
 * Model definition
 */
.factory('Tag', function($api, $baseModel, moment, TagTypes) {

  //Defaults
  const defaults = {
    member: null,
    number: '',
    facility: '',
    name: '',
    validFor: 0,
    isEnabled: true,
  };

  /**
   * Constructor
   */
  function Tag(data) {

    //Call parent constructor
    $baseModel.call(this, angular.extend({}, defaults, data || {}));

    //Is on system
    Object.defineProperty(this, 'isOnSystem', {
      get() {
        return !!this.addedOn;
      },
    });

    //Check if valid
    Object.defineProperty(this, 'isValid', {
      get() {
        if (this.validFrom && moment().isBefore(this.validFrom)) {
          return false;
        }
        if (this.validTill && moment().isAfter(this.validTill)) {

          return false;
        }
        return true;
      },
    });

    //Valid from time virtual property
    Object.defineProperty(this, 'validFromTime', {
      get() {
        if (!this.validFrom) {
          return null;
        }
        return this.validFrom.getTime();
      },
      set(time) {
        if (this.validFrom) {
          this.validFrom.setTime(time);
          if (this.validTillTime !== null) {
            this.validTill = this.validFrom.clone().setTime(this.validTillTime);
          }
        }
      },
    });

    //Valid till time virtual property
    Object.defineProperty(this, 'validTillTime', {
      get() {
        if (!this.validTill) {
          return null;
        }
        return this.validTill.getTime(true);
      },
      set(time) {
        if (this.validFrom) {
          this.validTill = this.validFrom.clone().setTime(time);
        }
      },
    });

    //Icon
    Object.defineProperty(this, 'icon', {
      get() {
        switch (this.type) {
          case TagTypes.CARD:
            return 'credit_card';
          case TagTypes.PIN:
            return 'dialpad';
          case TagTypes.NFC:
            return 'phone_android';
          case TagTypes.TAG:
            return 'contactless';
          case TagTypes.IBUTTON:
          default:
            return 'vpn_key';
        }
      },
    });

    //Label
    Object.defineProperty(this, 'label', {
      get() {
        switch (this.type) {
          case TagTypes.CARD:
            return 'card';
          case TagTypes.PIN:
            return 'pin number';
          case TagTypes.NFC:
            return 'nfc device';
          case TagTypes.TAG:
          case TagTypes.IBUTTON:
          default:
            return 'tag';
        }
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Tag.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Tag.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    if (angular.isObject(this.member)) {
      this.convertToModel('member', 'Member', false, true);
    }

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Tag.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Only ID for member
    json.member = $baseModel.onlyId(json.member);

    //Delete redundant data
    delete json.validFor;

    //Return json
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save
   */
  Tag.prototype.save = function(data, overwrite) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);
    overwrite = overwrite || false;

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.tag[method]({overwrite}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Tag.prototype.delete = function() {
    return $api.tag.delete(null, this).then(() => this);
  };

  /**
   * Patch
   */
  Tag.prototype.patch = function(data) {
    const {id} = this;
    return $api.tag
      .patch({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Tag.query = function(filter) {
    return $api.tag.query(filter);
  };

  /**
   * Query own
   */
  Tag.queryOwn = function(filter) {
    return $api.tag.queryOwn(filter);
  };

  /**
   * Find by ID
   */
  Tag.findById = function(id) {
    return $api.tag.get({id});
  };

  /**
   * Find by data
   */
  Tag.findByData = function(data) {
    return $api.tag.findByData(data);
  };

  /**
   * Create pin for member
   */
  Tag.createPinForMember = function(member) {
    const memberId = member.id;
    return $api.tag.createPinForMember({memberId});
  };

  /**
   * Get random tag number
   */
  Tag.randomNumber = function() {
    return $api.tag
      .randomNumber()
      .then(data => data.number);
  };

  /**
   * Check if reader input is a valid iButton hex number
   */
  Tag.isValidReaderInput = function(input) {
    return input.match(/^[0-9a-f]{14}(01|02)$/);
  };

  /**
   * Convert serial to decimal
   */
  Tag.serialToDecimal = function(serial) {
    return String(parseInt(serial, 16));
  };

  /**
   * Convert serial to short HE600 tag number
   */
  Tag.serialToShort = function(serial) {

    //Extract portion and convert to decimal number
    const hex = serial.substring(6);
    const num = String(parseInt(hex, 16));
    const len = num.length;

    //Too long? Use last 6 digits
    if (len > 6) {
      return num.substring(len - 6);
    }

    //Too short? Pad with zeroes
    if (len < 6) {
      return String('0').repeat(6 - len) + num;
    }

    //Exact length
    return num;
  };

  /**
   * Convert USB tag reader hex code to tag number
   */
  Tag.fromReader = function(buffer) {

    //Validate buffer
    if (!buffer || buffer.length < 16) {
      return;
    }

    //Get last 16 characters from buffer
    const input = buffer.substring(buffer.length - 16).toLowerCase().trim();

    //Validate
    if (!Tag.isValidReaderInput(input)) {
      return null;
    }

    //Extract parts
    const crc = input.substring(0, 2);
    const serial = input.substring(2, 14);
    const family = input.substring(14, 16);
    const number = Tag.serialToDecimal(serial);
    const short = Tag.serialToShort(serial);
    const type = TagTypes.IBUTTON;

    //Return data
    return {type, serial, family, number, short, crc};
  };

  /**
   * Create tag or tags from given data
   */
  Tag.toInstance = function(data) {

    //Array given
    if (Array.isArray(data)) {
      return data
        .map(tag => Tag.toInstance(tag))
        .filter(tag => !!tag);
    }

    //No data
    if (!data) {
      return null;
    }

    //Already an instance of Tag?
    if (data instanceof Tag) {
      return data;
    }

    //Return new tag
    return new Tag(data);
  };

  //Return
  return Tag;
});
