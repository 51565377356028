
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Contacts.Overview', [
  'App.Admin.People.Contacts.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.contacts.overview', {
    url: '',
    component: 'adminContactOverviewRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('contacts');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminContactOverviewRoute', {
  controller: 'AdminContactOverviewCtrl',
  templateUrl: 'admin/people/contacts/overview/overview.html',
  bindings: {
    club: '<',
    user: '<',
    page: '<',
    filter: '<',
  },
});
