
/**
 * Module definition and dependencies
 */
angular.module('Shared.MoreOptions.Component', [])

/**
 * More options component
 */
.component('moreOptions', {
  template: `
    <dropdown>
      <spinner ng-if="$ctrl.isBusy"></spinner>
      <dropdown-toggle ng-if="!$ctrl.isBusy">
        <button class="Button Button--more"><i class="Icon">more_vert</i></button>
      </dropdown-toggle>
      <dropdown-menu menu-class="Dropdown-menu--right Dropdown-menu--higher Dropdown-menu--noMaxHeight">
        <ng-transclude></ng-transclude>
      </dropdown-menu>
    </dropdown>
  `,
  transclude: true,
  bindings: {
    isBusy: '<',
  },
});
