
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.ModeOptions.Component', [])

/**
 * Mode options component
 */
.component('modeOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li>
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li>
        <a ui-sref="admin.log.audit({model: 'Mode', id: $ctrl.mode.id})">View audit log</a>
      </li>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    mode: '<',
    onEdit: '&',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Edit
     */
    this.edit = function() {
      this.onEdit({$event: {mode: this.mode}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      this.onDelete({$event: {mode: this.mode}});
    };
  },
});
