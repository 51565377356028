
/**
 * Module definition and dependencies
 */
angular.module('Shared.CircleMember.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('CircleMember', function($baseModel, moment) {

  /**
   * Constructor
   */
  function CircleMember(data) {

    //Call parent constructor
    $baseModel.call(this, angular.extend({}, data || {}));

    //Full name
    Object.defineProperty(this, 'name', {
      get() {
        const {firstName, lastName} = this;
        return `${firstName || ''} ${lastName || ''}`.trim();
      },
    });

    //Initials
    Object.defineProperty(this, 'initials', {
      get() {
        let initials = '';
        if (this.firstName) {
          initials += this.firstName[0];
        }
        if (this.lastName) {
          let parts = this.lastName.split(' ');
          let part = parts.find(part => (part[0] === part[0].toUpperCase()));
          if (!part) {
            part = this.lastName;
          }
          initials += part[0];
        }
        return initials;
      },
    });

    //Age
    Object.defineProperty(this, 'age', {
      get() {
        if (!this.dob) {
          return null;
        }
        return moment().diff(this.dob, 'years');
      },
    });
  }

  /**
   * Extend prototype
   */
  angular.extend(CircleMember.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  CircleMember.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('avatar', 'Avatar');
    this.convertToModel('address', 'Address');
    this.convertToModel('subscriptions', 'Subscription', true);
    this.convertToModel('coupons', 'Coupon', true);

    //Convert DOB
    if (json.dob) {
      this.dob = moment.fromDob(json.dob);
    }

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  CircleMember.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Convert DOB
    if (json.dob) {
      json.dob = moment(json.dob).toDob();
    }

    //Remove redundant properties
    delete json.avatar;
    delete json.address;
    delete json.dob;
    delete json.subscriptions;
    delete json.coupons;

    //Return json
    return json;
  };

  /**
   * Get current memberships
   */
  CircleMember.prototype.getCurrentMemberships = function() {
    return this.subscriptions
      .filter(sub => sub.isCurrent)
      .map(sub => sub.membership);
  };

  //Return
  return CircleMember;
});
