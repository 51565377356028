
/**
 * Module definition and dependencies
 */
angular.module('Shared.Request.Service', [])

/**
 * Service definition
 */
.factory('Request', function($timeout, $injector) {

  //Placeholder vars
  let $http, Auth;

  /**
   * Service
   */
  return {

    /**
     * Check if a request is a template request
     */
    isTemplate(request) {
      return (request && request.url.indexOf('.html') !== -1);
    },

    /**
     * Helper to retry a http request
     */
    retry(request, delay = 0) {

      //Get service
      $http = $http || $injector.get('$http');

      //Make sure this retry is not captured by duplicate requests filters
      request.ignoreDuplicateRequest = true;

      //No delay?
      if (!delay) {
        return $http(request);
      }

      //Delay
      return $timeout(() => $http(request), delay);
    },

    /**
     * Append the access token to the headers of a http configuration object
     */
    appendAccessToken(request) {

      //Get auth service
      Auth = Auth || $injector.get('Auth');

      //Initialize headers if needed
      request.headers = request.headers || {};

      //Get access token and append to header if present
      let accessToken = Auth.getAccessToken();
      if (accessToken) {
        request.headers.Authorization = 'Bearer ' + accessToken;
      }

      //Return request config
      return request;
    },
  };
});
