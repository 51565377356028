
/**
 * Module definition and dependencies
 */
angular.module('Shared.Member.Model', [
  'BaseModel.Service',
  'Shared.Member.Activity.Model',
  'Shared.Member.Grade.Model',
])

/**
 * Config
 */
.config($apiProvider => {

  //Register endpoint
  $apiProvider.registerEndpoint('directory', {
    model: 'Member',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'members',
        isArray: true,
        isModel: true,
      },
    },
  });

  //Register endpoint
  $apiProvider.registerEndpoint('staff', {
    model: 'Member',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'members',
        isArray: true,
        isModel: true,
      },
      email: {
        url: 'email',
        method: 'POST',
      },
      archiveMany: {
        url: 'archiveMany',
        method: 'POST',
      },
      restoreMany: {
        url: 'restoreMany',
        method: 'POST',
      },
      suspendMany: {
        url: 'suspendMany',
        method: 'POST',
      },
      unsuspendMany: {
        url: 'unsuspendMany',
        method: 'POST',
      },
    },
  });

  //Register endpoint
  $apiProvider.registerEndpoint('member', {
    model: 'Member',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'members',
        isArray: true,
        isModel: true,
      },
      count: {
        url: 'count',
        method: 'GET',
      },
      limits: {
        url: 'limits',
        method: 'GET',
      },
      findForDisplay: {
        url: 'findForDisplay',
        method: 'GET',
        dataKey: 'members',
        isArray: true,
        isModel: true,
      },
      findByName: {
        url: 'findByName',
        method: 'GET',
        dataKey: 'members',
        isArray: true,
        isModel: true,
        withCredentials: true,
      },
      findForWelcomeEmails: {
        url: 'findForWelcomeEmails',
        method: 'GET',
        dataKey: 'members',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
      uploadAvatarFromWeb: {
        url: 'avatar/web',
        method: 'POST',
      },
      deleteAvatar: {
        url: 'avatar',
        method: 'DELETE',
      },
      deleteCustomFile: {
        url: 'file/:field/:filename',
        method: 'DELETE',
      },
      imports: {
        url: 'import',
        method: 'GET',
        isArray: true,
      },
      removeImport: {
        url: 'import',
        method: 'DELETE',
      },
      email: {
        url: 'email',
        method: 'POST',
      },
      sendWelcomeEmail: {
        url: 'welcomeEmail',
        method: 'POST',
      },
      getWelcomeLink: {
        url: 'welcomeLink',
        method: 'GET',
      },
      addToGroups: {
        url: 'addToGroups',
        method: 'POST',
      },
      removeFromGroups: {
        url: 'removeFromGroups',
        method: 'POST',
      },
      archiveMany: {
        url: 'archiveMany',
        method: 'POST',
      },
      restoreMany: {
        url: 'restoreMany',
        method: 'POST',
      },
      approveMany: {
        url: 'approveMany',
        method: 'POST',
      },
      rejectMany: {
        url: 'rejectMany',
        method: 'POST',
      },
      suspendMany: {
        url: 'suspendMany',
        method: 'POST',
      },
      unsuspendMany: {
        url: 'unsuspendMany',
        method: 'POST',
      },
      getDefaultPaymentSource: {
        url: 'paymentSource/:method',
        method: 'GET',
      },
      addPaymentSource: {
        url: 'paymentSource/:method/:sourceId',
        method: 'POST',
        params: {
          id: '@id',
          method: '@method',
          sourceId: '@sourceId',
        },
      },
      removePaymentSource: {
        url: 'paymentSource/:method/:sourceId',
        method: 'DELETE',
        params: {
          id: '@id',
          method: '@method',
          sourceId: '@sourceId',
        },
      },
      patchPaymentSource: {
        url: 'paymentSource/:method/:sourceId',
        method: 'PATCH',
        params: {
          id: '@id',
          method: '@method',
          sourceId: '@sourceId',
        },
      },
      uniqueUsername: {
        method: 'GET',
        url: 'uniqueUsername',
      },
      exists: {
        method: 'GET',
        url: 'exists/:type',
      },
      unsubscribe: {
        method: 'POST',
        url: 'unsubscribe',
      },
      verifyVaccinationStatus: {
        method: 'PUT',
        url: 'vaccination',
      },
      merge: {
        method: 'POST',
        url: 'merge',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Member', function(
  $api, $q, $baseModel, $injector, $httpParamSerializer, moment, Settings,
  Roles, Intercom, PaymentMethods, Config, Helpers,
  Modules, DateFormat, VaccinationStatus
) {

  /**
   * Constructor
   */
  function Member(data) {

    //Call parent constructor
    $baseModel.call(this, angular.extend({}, data || {}));

    //Full name
    Object.defineProperty(this, 'name', {
      get() {
        const {firstName, lastName} = this;
        if (!lastName || lastName === '.') {
          return firstName;
        }
        return `${firstName || ''} ${lastName || ''}`.trim();
      },
    });

    //Age
    Object.defineProperty(this, 'age', {
      set() {},
      get() {
        if (!this.dob) {
          return null;
        }
        return moment().diff(this.dob, 'years');
      },
    });

    //Has birthday
    Object.defineProperty(this, 'hasBirthday', {
      get() {
        if (!this.dob) {
          return false;
        }
        return (moment().format('DDMM') === this.dob.format('DDMM'));
      },
    });

    //Initials
    Object.defineProperty(this, 'initials', {
      set() {},
      get() {
        let initials = '';
        if (this.firstName) {
          initials += this.firstName[0];
        }
        if (this.lastName && this.lastName !== '.') {
          let parts = this.lastName.split(' ');
          let part = parts.find(part => (part[0] === part[0].toUpperCase()));
          if (!part) {
            part = this.lastName;
          }
          initials += part[0];
        }
        return initials;
      },
    });

    //Is in group flag
    Object.defineProperty(this, 'isInGroups', {
      get() {
        return (this.groups && this.groups.length > 0);
      },
    });

    //Last online or tag used
    Object.defineProperty(this, 'lastActive', {
      set() {
        //Ignore
      },
      get() {
        const dates = [
          this.lastOnline,
          this.lastBooking,
          this.lastEventAttendance,
          this.lastCheckIn,
          this.lastVisitLogged,
          this.lastTagUse,
        ].filter(date => !!date);
        if (dates.length === 0) {
          return null;
        }
        return moment.max(dates);
      },
    });

    //Last active via
    Object.defineProperty(this, 'isLastActiveVia', {
      get() {
        const {lastActive} = this;
        if (!lastActive) {
          return '';
        }
        if (lastActive.isSame(this.lastOnline)) {
          return 'used portal';
        }
        if (lastActive.isSame(this.lastBooking)) {
          return 'booking';
        }
        if (lastActive.isSame(this.lastEventAttendance)) {
          return 'attended event';
        }
        if (lastActive.isSame(this.lastCheckIn)) {
          return 'checked in';
        }
        if (lastActive.isSame(this.lastVisitLogged)) {
          return 'visit logged';
        }
        if (lastActive.isSame(this.lastTagUse)) {
          return 'used tag';
        }
        return 'unknown';
      },
    });

    //Member object check, to differentiate from visitors when iterating
    Object.defineProperty(this, 'isMemberObject', {
      get() {
        return true;
      },
    });

    //Is member
    Object.defineProperty(this, 'hasEmail', {
      get() {
        return this.email ? true : false;
      },
    });

    //Is member
    Object.defineProperty(this, 'isMember', {
      get() {
        return this.hasRoleStrict('member');
      },
    });

    //Is staff
    Object.defineProperty(this, 'isStaff', {
      get() {
        return this.roles.some(role => role !== 'member');
      },
    });

    //Noun for this member
    Object.defineProperty(this, 'noun', {
      get() {
        if (this.isMember) {
          return Modules.singular('members');
        }
        return 'staff member';
      },
    });

    //Has payment sources stored
    Object.defineProperty(this, 'hasPaymentSources', {
      get() {
        for (const method of PaymentMethods) {
          if (method.isOnline && typeof this[method.value] !== 'undefined') {
            const data = this[method.value];
            if (data.sources && data.sources.length > 0) {
              return true;
            }
          }
        }
        return false;
      },
    });

    //Vaccination
    Object.defineProperty(this, 'isVaccinationValid', {
      set() {},
      get() {
        if (!this.vaccination) {
          return false;
        }
        return [
          VaccinationStatus.VACCINATED,
          VaccinationStatus.EXEMPT,
        ].includes(this.vaccination.status);
      },
    });

    //Elevated roles
    Object.defineProperty(this, 'elevatedRoles', {
      get() {
        return this.roles.filter(role => role !== 'member');
      },
    });

    //Highest role
    Object.defineProperty(this, 'highestRole', {
      get() {
        const roles = Roles.map(role => role).reverse();
        const role = roles.find(role => this.roles.includes(role.value));
        return role ? role.value : null;
      },
    });

    //Has contact details
    Object.defineProperty(this, 'hasContactDetails', {
      get() {
        return !!(this.email || this.phone || this.mobile);
      },
    });

    //Has distinct gender
    Object.defineProperty(this, 'hasDistinctGender', {
      get() {
        return (
          this.gender && this.gender !== 'hidden' && this.gender !== 'unknown'
        );
      },
    });

    //Number of coupons
    Object.defineProperty(this, 'numCoupons', {
      get() {
        return this.coupons.length;
      },
    });

    //Number of coupon sessions available
    Object.defineProperty(this, 'numCouponSessionsLeft', {
      get() {
        return this.coupons
          .filter(coupon => coupon.isActive)
          .reduce((total, coupon) => total + coupon.numSessionsLeft, 0);
      },
    });

    //Info field
    Object.defineProperty(this, 'info', {
      get() {

        //Get the info field
        const field = Settings.get('member.infoField');
        if (!field) {
          return '';
        }

        //Get value
        const value = Helpers.get(this, field);
        if (!value) {
          return '';
        }

        //Specific fields
        if (moment.isMoment(value)) {
          return value.format(DateFormat.formats.standard);
        }
        if (field === 'age') {
          return `${value} years`;
        }

        //Generic
        return Array.isArray(value) ? value.join(', ') : value;
      },
    });

    //Meta data properties
    Object.defineProperty(this, 'meta', {
      writable: true,
    });
  }

  /**
   * Extend prototype
   */
  angular.extend(Member.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Member.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('address', 'Address');
    this.convertToModel('postalAddress', 'Address');
    this.convertToModel('tags', 'Tag', true);
    this.convertToModel('activities', 'MemberActivity', true);
    this.convertToModel('grades', 'MemberGrade', true);
    this.convertToModel('subscriptions', 'Subscription', true);
    this.convertToModel('coupons', 'Coupon', true);
    this.convertToModel('resources', 'Resource', true);
    this.convertToModel('roles', true);
    this.convertToModel('profiles', true);
    this.convertToModel('access', 'Access', true);
    this.convertToModel('avatar', 'Avatar');

    //Convert DOB
    if (json.dob) {
      this.dob = moment.fromDob(json.dob);
    }

    //Ensure custom fields are an object
    if (!this.customFields || typeof this.customFields !== 'object') {
      this.customFields = {};
    }
    if (!this.customFiles || typeof this.customFiles !== 'object') {
      this.customFiles = {};
    }

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Member.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Convert DOB
    if (json.dob) {
      json.dob = moment(json.dob).toDob();
    }

    //Strip data
    //TODO: When migrating to Vue, this should be made a lot smarter
    if (json.groups) {
      for (const group of json.groups) {
        delete group.club;
        delete group.createdAt;
        delete group.updatedAt;
      }
    }

    //Remove redundant/helper properties
    delete json.subscriptions;
    delete json.subscription;
    delete json.coupons;
    delete json.resources;
    delete json.avatar;
    delete json.tags;

    //Return json
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Link a new oAuth profile
   */
  Member.prototype.linkProfile = function(profile) {
    this.profiles.push(profile);
  };

  /**
   * Check if we have payment sources for a given method
   */
  Member.prototype.hasPaymentSourcesForMethod = function(method) {
    return (this[method] && this[method].sources.length > 0);
  };

  /**
   * Add a payment source
   */
  Member.prototype.addPaymentSource = function(method, sourceId) {
    const {id} = this;
    return $api.member
      .addPaymentSource({id, method, sourceId})
      .then(data => this[method] = data);
  };

  /**
   * Remove a payment source
   */
  Member.prototype.removePaymentSource = function(method, sourceId) {
    const {id} = this;
    return $api.member
      .removePaymentSource({id, method, sourceId})
      .then(data => this[method] = data);
  };

  /**
   * Make payment source the default
   */
  Member.prototype.makePaymentSourceDefault = function(method, sourceId) {
    const {id} = this;
    return $api.member
      .patchPaymentSource({id, method, sourceId}, {isDefault: true})
      .then(data => this[method] = data);
  };

  /**
   * Get an activity from given ID
   */
  Member.prototype.getActivity = function(activityId) {
    return this.activities.find(activity => activity.id === activityId);
  };

  /**
   * Update amount owing based on given transactions
   */
  Member.prototype.updateAmountOwing = function(transactions) {
    this.amountOwing = 0;
    transactions.forEach(transaction => {
      if (!transaction.isPaid) {
        this.amountOwing += transaction.amount;
      }
    });
  };

  /**
   * Check if we have a (specific) current membership
   */
  Member.prototype.hasCurrentMembership = function(membershipId) {
    return this.subscriptions.some(subscription => {
      if (!subscription.isCurrent) {
        return false;
      }
      if (!subscription.membership) {
        return false;
      }
      if (!membershipId) {
        return true;
      }
      return (subscription.membership.id === membershipId);
    });
  };

  /**
   * Check if we have any current subscriptions
   */
  Member.prototype.hasCurrentSubscriptions = function() {
    return this.subscriptions
      .some(sub => sub.isCurrent);
  };

  /**
   * Check if we have any upcoming subscriptions
   */
  Member.prototype.hasUpcomingSubscriptions = function() {
    return this.subscriptions
      .some(sub => sub.isUpcoming);
  };

  /**
   * Get current memberships
   */
  Member.prototype.getCurrentMemberships = function() {
    return this.subscriptions
      .filter(sub => sub.isCurrent)
      .map(sub => sub.membership);
  };

  /**
   * Get current and upcoming memberships
   */
  Member.prototype.getCurrentAndUpcomingMemberships = function() {
    return this.subscriptions
      .filter(sub => sub.isCurrent || sub.isUpcoming)
      .map(sub => sub.membership);
  };

  /**
   * Get subscriptions for a given date
   */
  Member.prototype.getSubscriptionsForDate = function(date) {
    return this.subscriptions
      .filter(sub => sub.isCurrentForDate(date));
  };

  /**
   * Get book ahead type for this member
   */
  Member.prototype.getBookAheadType = function() {
    if (this.isAdmin()) {
      return 'admin';
    }
    else if (this.hasRole('eventManager')) {
      return 'eventManager';
    }
    else if (this.canBookExtended) {
      return 'extended';
    }
    else if (this.hasCurrentMembership()) {
      return 'member';
    }
    else {
      return 'casual';
    }
  };

  /**
   * Check if a member has specific allowed modes
   */
  Member.prototype.hasAllowedModes = function(activityId) {

    //No activity ID supplied? Check for any activity
    //This is used for filtering members in the overview
    if (!activityId) {
      return this.activities.some(activity => activity.hasAllowedModes);
    }

    //Find activity
    const activity = this.activities
      .find(activity => activity.id === activityId);

    //Activity not found?
    if (!activity) {
      return false;
    }

    //Check if have them
    return activity.hasAllowedModes;
  };

  /**
   * Check if a member is allowed a specific mode
   */
  Member.prototype.isAllowedMode = function(mode) {

    //Find activity
    const activity = this.activities
      .find(activity => activity.id === mode.activity);

    //Activity not found or no specific modes? Allow, because in that case
    //we will check the playing/booking time restrictions instead which are
    //based on the member's membership.
    if (!activity || !activity.hasAllowedModes) {
      return true;
    }

    //Check if mode allowed
    return activity.isAllowedMode(mode);
  };

  /**
   * Check if we have a custom field value
   */
  Member.prototype.hasCustomFieldValue = function(prop) {
    const {customFields} = this;
    if (!customFields) {
      return false;
    }
    if (typeof customFields[prop] === 'undefined') {
      return false;
    }
    if (customFields[prop] === '' || customFields[prop] === null) {
      return false;
    }
    return true;
  };

  /**
   * Check if we have a certain role or roles
   */
  Member.prototype.hasRole = function(...roles) {

    //If suspended, remove elevated permissions
    if (this.isSuspended) {
      return false;
    }

    //Check roles
    return this.hasRoleStrict(...roles);
  };

  /**
   * Check if we have a certain role (ignoring suspended state)
   */
  Member.prototype.hasRoleStrict = function(...roles) {
    return (this.roles && this.roles.some(role => roles.includes(role)));
  };

  /**
   * Check if this member is an admin
   */
  Member.prototype.isAdmin = function() {
    return this.hasRole('admin');
  };

  /**
   * Check if this member is a super admin
   */
  Member.prototype.isSuper = function() {
    return this.hasRole('super');
  };

  /**
   * Check if member can be suspended
   */
  Member.prototype.canBeSuspended = function() {
    return (!this.hasRole('admin', 'super') && !this.isPending);
  };

  /**
   * Get custom file download URL
   */
  Member.prototype.getCustomFileUrl = function(field) {

    //Get data
    const {id, customFiles} = this;
    const {name} = customFiles[field];
    const Auth = $injector.get('Auth');
    const token = Auth.getAccessToken();
    const qs = $httpParamSerializer({access_token: token});
    const base = Config.api.baseUrl;

    //Build URL
    return `${base}/member/${id}/file/${field}/${name}?${qs}`;
  };

  /**************************************************************************
   * Instance API methods
   ***/

  /**
   * Save
   */
  Member.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Fix roles if primary role given
    if (data.role) {
      data.roles = ['member'];
      if (data.role !== 'member') {
        data.roles.push(data.role);
      }
      delete data.role;
    }

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.member[method](data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  Member.prototype.patch = function(data) {

    //Convert DOB
    if (data.dob && moment.isMoment(data.dob)) {
      data.dob = moment(data.dob).toDob();
    }

    //Patch
    const {id} = this;
    return $api.member
      .patch({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Member.prototype.delete = function(data) {
    return $api.member
      .delete(data, this)
      .then(() => this);
  };

  /**
   * Validate vaccination status
   */
  Member.prototype.verifyVaccinationStatus = function(data) {
    const {id} = this;
    return $api.member
      .verifyVaccinationStatus({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Upload avatar from web
   */
  Member.prototype.uploadAvatarFromWeb = function(url) {
    const {id} = this;
    return $api.member
      .uploadAvatarFromWeb({id}, {url})
      .then(data => this.avatar = data.avatar);
  };

  /**
   * Delete avatar
   */
  Member.prototype.deleteAvatar = function() {
    return $api.member
      .deleteAvatar(null, this)
      .then(() => this.avatar = null);
  };

  /**
   * Delete custom file
   */
  Member.prototype.deleteCustomFile = function(field, filename) {
    return $api.member
      .deleteCustomFile({field, filename}, this)
      .then(() => this.customFiles[field] = null);
  };

  /**
  * Get welcome link
  */
  Member.prototype.getWelcomeLink = function() {
    return $api.member
      .getWelcomeLink({id: this.id})
      .then(data => data.link);
  };

  /**
   * Send welcome mail
   */
  Member.prototype.sendWelcomeEmail = function() {
    return $api.member
      .sendWelcomeEmail({id: this.id})
      .then(() => Intercom.event('Sent welcome email'));
  };

  /**
   * Refresh account credit
   */
  Member.prototype.refreshAccountCredit = function() {
    return $api.accountCredit
      .balance({memberId: this.id})
      .then(({accountCredit}) => this.accountCredit = accountCredit);
  };

  /**
   * Add comment to this member
   */
  Member.prototype.addComment = function(comment) {

    //Append model and item ID
    comment.model = 'Member';
    comment.itemId = this.id;

    //Save comment
    return comment.save();
  };

  /**
   * Download badge
   */
  Member.prototype.downloadBadge = function(subscription) {
    const $sync = $injector.get('$sync');
    return $sync.download(`member/${this.id}/badge`, {subscription});
  };

  /**
   * Archive
   */
  Member.prototype.archive = function() {
    const {id} = this;
    return $api.member
      .archiveMany({ids: [id]})
      .then(() => this.isArchived = true);
  };

  /**
   * Restore
   */
  Member.prototype.restore = function() {
    const {id} = this;
    return $api.member
      .restoreMany({ids: [id]})
      .then(() => this.isArchived = false);
  };

  /**
   * Approve
   */
  Member.prototype.approve = function() {
    const {id} = this;
    return $api.member
      .approveMany({ids: [id]})
      .then(() => this.isPending = false);
  };

  /**
   * Reject
   */
  Member.prototype.reject = function(data) {
    const {id} = this;
    return $api.member
      .rejectMany({ids: [id]}, data)
      .then(() => {
        this.isRejected = true;
        this.isArchived = true;
      });
  };

  /**
   * Suspend
   */
  Member.prototype.suspend = function(data) {
    const {id} = this;
    return $api.member
      .suspendMany({ids: [id]}, data)
      .then(() => this.isSuspended = true);
  };

  /**
   * Unsuspend
   */
  Member.prototype.unsuspend = function() {
    const {id} = this;
    return $api.member
      .unsuspendMany({ids: [id]})
      .then(() => this.isSuspended = false);
  };

  /**
   * Disconnect oAuth profile
   */
  Member.prototype.disconnectProfile = function(profile) {
    const i = this.profiles.indexOf(profile);
    if (i !== -1) {
      const profiles = this.profiles.map(p => p);
      profiles.splice(i, 1);
      return this.patch({profiles});
    }
    return $q.reject();
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Count
   */
  Member.count = function(filter) {
    return $api.member
      .count(filter)
      .then(data => data.total || 0);
  };

  /**
   * Query
   */
  Member.query = function(filter) {
    return $api.member.query(filter);
  };

  /**
   * Directory query
   */
  Member.directory = function(filter) {
    return $api.directory.query(filter);
  };

  /**
   * Export
   */
  Member.export = function(filter) {
    Intercom.event('Exported members');
    return $injector
      .get('$sync')
      .get('member/export/csv', filter, 'Exporting...');
  };

  /**
   * Email
   */
  Member.email = function(filter, email) {
    Intercom.event('Emailed members');
    return $api.member.email(filter, email);
  };

  /**
   * Find by ID
   */
  Member.findById = function(id) {
    return $api.member.get({id});
  };

  /**
   * Find highlighted members
   */
  Member.findForDisplay = function(type) {
    return $api.member
      .findForDisplay({type})
      .then(data => data.members);
  };

  /**
   * Find members that will receive a welcome email
   */
  Member.findForWelcomeEmails = function(filter) {
    return $api.member
      .findForWelcomeEmails(filter)
      .then(data => data.members);
  };

  /**
   * Find members by name
   */
  Member.findByName = function(filter) {
    return $api.member
      .findByName(filter)
      .then(data => data.members);
  };

  /**
   * Send welcome emails to filtered eligible members
   */
  Member.sendWelcomeEmails = function(filter) {
    return $api.member
      .sendWelcomeEmail(filter, {})
      .then(() => Intercom.event('Sent bulk welcome emails'));
  };

  /**
   * Add members to groups
   */
  Member.addToGroups = function(filter, groups) {
    return $api.member.addToGroups(filter, {groups});
  };

  /**
   * Remove members from groups
   */
  Member.removeFromGroups = function(filter, groups) {
    return $api.member.removeFromGroups(filter, {groups});
  };

  /**
   * Archive many
   */
  Member.archiveMany = function(filter) {
    return $api.member.archiveMany(filter);
  };

  /**
   * Restore many
   */
  Member.restoreMany = function(filter) {
    return $api.member.restoreMany(filter);
  };

  /**
   * Approve many
   */
  Member.approveMany = function(filter) {
    return $api.member.approveMany(filter);
  };

  /**
   * Reject many
   */
  Member.rejectMany = function(filter, data) {
    return $api.member.rejectMany(filter, data);
  };

  /**
   * Suspend many
   */
  Member.suspendMany = function(filter, data) {
    return $api.member.suspendMany(filter, data);
  };

  /**
   * Unsuspend many
   */
  Member.unsuspendMany = function(filter) {
    return $api.member.unsuspendMany(filter);
  };

  /**
   * Get member limits
   */
  Member.getLimits = function() {
    return $api.member.limits();
  };

  /**
   * Get member imports
   */
  Member.getImports = function(filter) {
    return $api.member.imports(filter);
  };

  /**
   * Remove an import
   */
  Member.removeImport = function(date) {
    return $api.member.removeImport({date});
  };

  /**
   * Get default payment source for a given member
   */
  Member.getDefaultPaymentSource = function(id, method) {
    return $api.member
      .getDefaultPaymentSource({id, method});
  };

  /**
   * Exists check
   */
  Member.exists = function(type, data) {
    return $api.member
      .exists(Object.assign({type}, data))
      .then(data => !!data.exists);
  };

  /**
   * Unsubscribe
   */
  Member.unsubscribe = function(memberId, hash) {
    return $api.member.unsubscribe({memberId, hash});
  };

  /**
   * Merge members
   */
  Member.merge = function(data) {
    const {sourceId, targetId} = data;
    return $api.member.merge({sourceId, targetId});
  };

  /**************************************************************************
   * Staff static methods
   ***/

  /**
   * Staff query
   */
  Member.queryStaff = function(filter) {
    return $api.staff.query(filter);
  };

  /**
   * Email
   */
  Member.emailStaff = function(filter, email) {
    Intercom.event('Emailed staff members');
    return $api.staff.email(filter, email);
  };

  /**
   * Export
   */
  Member.exportStaff = function(filter) {
    Intercom.event('Exported staff members');
    return $injector
      .get('$sync')
      .get('staff/export/csv', filter, 'Exporting...');
  };

  /**
   * Archive many
   */
  Member.archiveManyStaff = function(filter) {
    return $api.staff.archiveMany(filter);
  };

  /**
   * Restore many
   */
  Member.restoreManyStaff = function(filter) {
    return $api.staff.restoreMany(filter);
  };

  /**
   * Suspend many
   */
  Member.suspendManyStaff = function(filter) {
    return $api.staff.suspendMany(filter);
  };

  /**
   * Unsuspend many
   */
  Member.unsuspendManyStaff = function(filter) {
    return $api.staff.unsuspendMany(filter);
  };

  /**************************************************************************
   * Staff instance methods
   ***/

  /**
   * Archive
   */
  Member.prototype.archiveStaff = function() {
    const {id} = this;
    return $api.staff
      .archiveMany({ids: [id]})
      .then(() => this.isArchived = true);
  };

  /**
   * Restore
   */
  Member.prototype.restoreStaff = function() {
    const {id} = this;
    return $api.staff
      .restoreMany({ids: [id]})
      .then(() => this.isArchived = false);
  };

  /**
   * Suspend
   */
  Member.prototype.suspendStaff = function(data) {
    const {id} = this;
    return $api.staff
      .suspendMany({ids: [id]}, data)
      .then(() => this.isSuspended = true);
  };

  /**
   * Unsuspend
   */
  Member.prototype.unsuspendStaff = function() {
    const {id} = this;
    return $api.staff
      .unsuspendMany({ids: [id]})
      .then(() => this.isSuspended = false);
  };

  //Return
  return Member;
});
