
/**
 * Module definition and dependencies
 */
angular.module('Shared.EventModes.Options', [])

/**
 * Constant definition
 */
.constant('EventModes', [
  {
    label: 'Single day',
    value: 'single',
  },
  {
    label: 'Multi-day',
    value: 'multi',
  },
  {
    label: 'Recurring',
    value: 'recurring',
  },
]);
