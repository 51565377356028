
/**
 * Module definition and dependencies
 */
angular.module('Shared.Calendar.Component', [])

/**
 * Calendar component
 */
.component('calendar', {
  templateUrl: 'shared/calendar/calendar.html',
  bindings: {
    date: '<',
    minDate: '<',
    maxDate: '<',
    titleFormat: '<',
    onChange: '&',
  },

  /**
   * Directive controller
   */
  controller(moment, Months) {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Ensure moment
      if (this.date && !moment.isMoment(this.date)) {
        this.date = null;
      }

      //Set month
      const monthOfDate = this.date || this.minDate || moment();
      this.monthDate = monthOfDate.clone().startOf('month');

      //Setup month and year selectors
      this.setupSelectors();

      //Update initially
      this.update();
    };

    /**
     * Update
     */
    this.update = function() {

      //Set weekdays
      this.weekdays = moment.wkDaysShort();

      //Check can browse
      this.canBrowseNextMonth = (
        !this.maxDate || (this.maxDate && this.monthDate &&
        this.monthDate.isBefore(this.maxDate, 'month'))
      );
      this.canBrowsePrevMonth = (
        !this.minDate || (this.minDate && this.monthDate &&
        this.monthDate.isAfter(this.minDate, 'month'))
      );

      //Generate days
      this.generateDays();
    };

    /**
     * Generate the days
     */
    this.generateDays = function() {

      //Initialize days
      this.days = [];

      //Get number of days and weekday of the first day
      const today = moment();
      const period = this.monthDate.format('MMYYYY');
      const noDays = this.monthDate.daysInMonth();
      const firstDay = this.monthDate.isoWeekday();

      //Filler days of previous month
      for (let n = 1; n < firstDay; n++) {
        this.days.push({
          id: period + '0' + n,
          label: '',
        });
      }

      //Actual days
      let date = this.monthDate.clone().subtract(1, 'day');
      for (let d = 1; d <= noDays; d++) {

        //Increment to next day and create day object
        date.add(1, 'day');
        let day = {
          id: period + d,
          date: date.clone(),
          label: d,
          isDisabled: false,
          isToday: today.isSame(date, 'day'),
        };

        //Check if disabled
        if (this.minDate && date.isBefore(this.minDate, 'day')) {
          day.isDisabled = true;
        }
        if (this.maxDate && date.isAfter(this.maxDate, 'day')) {
          day.isDisabled = true;
        }

        //Add day object
        this.days.push(day);
      }

      //Get weekday of last day
      let lastDay = date.isoWeekday();

      //Filler days of next month
      for (let m = lastDay + 1; m <= 7; m++) {
        this.days.push({
          id: period + '4' + m,
          label: '',
        });
      }
    };

    /**
     * Setup year selector
     */
    this.setupSelectors = function() {

      //Determine first and last year
      const {firstYear, lastYear} = this.determineYears();

      //Generate years array
      this.years = [];
      for (let year = lastYear; year >= firstYear; year--) {
        this.years.push({
          value: year,
          label: year,
        });
      }

      //Generate months array & convert
      this.months = Months.map(month => ({
        value: month.value - 1,
        label: month.label,
      }));

      //Set the current year
      const year = this.years.find(y => y.value === this.monthDate.year());
      if (year) {
        this.year = year.value;
      }

      //Set the current month
      const month = this.months.find(m => m.value === this.monthDate.month());
      if (month) {
        this.month = month.value;
      }
    };

    /**
     * Determine first and last years
     */
    this.determineYears = function() {

      const firstYearOptions = [1900];
      const lastYearOptions = [2100];

      //First year
      if (this.minDate) {
        firstYearOptions.push(moment(this.minDate).year());
      }
      if (this.date) {
        firstYearOptions.push(moment(this.date).year());
      }

      //Last year
      if (this.maxDate) {
        lastYearOptions.push(moment(this.maxDate).year());
      }
      if (this.date) {
        lastYearOptions.push(moment(this.date).year());
      }

      //Return min and max years
      const firstYear = Math.min(...firstYearOptions);
      const lastYear = Math.max(...lastYearOptions);
      return {firstYear, lastYear};
    };

    /**
     * Update model
     */
    this.updateSelector = function(key, value) {
      this[key] = value;

      //Set date
      this.monthDate[key](value);
      this.update();
    };

    /**
     * Select date
     */
    this.selectDate = function(date) {

      //No date?
      if (!date) {
        return;
      }

      //Check if possible
      if (this.minDate && date.isBefore(this.minDate, 'day')) {
        return;
      }
      if (this.maxDate && date.isAfter(this.maxDate, 'day')) {
        return;
      }

      //Set selected date
      this.date = date;

      //Call change handler
      this.onChange({$event: {date}});
    };

    /**
     * Check if a date is the selected date
     */
    this.isSelected = function(date) {
      if (!date || !this.date) {
        return false;
      }
      return this.date.isSame(date, 'day');
    };

    /**
     * Next month
     */
    this.nextMonth = function() {
      if (!this.canBrowseNextMonth) {
        return;
      }

      //Next month/ year
      if (this.month === 11) {
        this.year = this.year + 1;
        this.month = 0;
      }
      else {
        this.month = this.month + 1;
      }

      //Update the date
      this.monthDate.add(1, 'month');
      this.update();
    };

    /**
     * Previous month
     */
    this.prevMonth = function() {
      if (!this.canBrowsePrevMonth) {
        return;
      }

      //Prev month/ year
      if (this.month === 0) {
        this.year = this.year - 1;
        this.month = 11;
      }
      else {
        this.month = this.month - 1;
      }

      //Update the date
      this.monthDate.subtract(1, 'month');
      this.update();
    };
  },
});
