'use strict';

//https://developers.google.com/web/ilt/pwa/caching-files-with-service-worker

//Cached files
const cachedFiles = [
  '/',
  '/index.html',
  '%CACHED_FILES%'
];

//Ignored files
const ignoredFiles = [
  '/version.json',
  '/maintenance.json',
  '/service-worker.js',
  '/qr-scanner-worker.min.js',
  '/browser-sync/browser-sync-client.js',
  '/browser-sync/socket.io/',
];

//Cache name for this service worker
const cacheName = 'helloclub.%CACHE_SUFFIX%';

//Regexes
const rDomain = /helloclub\.com$/;
const rApiDomain = /api\.helloclub\.com$/;
const rApiPath = /^\/api\//;
const rStatic = /\.(jpg|jpeg|gif|png|svg|js|css|ico|woff|woff2|json|html)$/;
const rGet = /get/i;

//Helper to fetch and cache a successful response
async function fetchAndCache(event, cache, canCache = true) {

  //Get response
  const response = await fetch(event.request);

  //Cache 2xx responses. We are using waitUntil so we can return the response
  //without blocking while writing to cache. Cloning the response because a
  //response stream can only be consumed once.
  if (canCache && response.ok) {
    event.waitUntil(
      cache.put(event.request, response.clone())
    );
  }

  //Pass the response
  return response;
}

//Fetch handler for static assets
async function fetchStatic(event/*, url*/) {

  //Get and use cached response if available
  const cache = await caches.open(cacheName);
  const cachedResponse = await cache.match(event.request);
  if (cachedResponse) {
    return cachedResponse;
  }

  //Fetch will only fail when there is a network error
  try {
    return await fetchAndCache(event, cache);
  }
  catch (error) {
    return new Response('', {status: 500, statusText: 'offline'});
  }
}

//Fetch handler for API requests
async function fetchApi(event, url) {

  //Check if can cache this request
  //NOTE: Don't cache urls with query string params for now, as this could
  //inflate the cache for old booking query results etc.
  const canCache = !url.search;
  const cache = await caches.open(cacheName);

  //Fetch will only fail when there is a network error
  try {
    return await fetchAndCache(event, cache, canCache);
  }
  catch (error) {

    //Fall back to cached response
    if (canCache) {
      const cachedResponse = await cache.match(event.request);
      if (cachedResponse) {
        console.log('Serving API resource from cache', event.request.url);
        return cachedResponse;
      }
    }

    //Offline
    return new Response('', {status: 500, statusText: 'offline'});
  }
}

//Generic fetch handler
async function fetchHandler(event, url) {

  //Check if static
  const isRoot = (url.pathname === '/');
  const isStatic = url.pathname.match(rStatic);
  const isApi = (url.pathname.match(rApiPath) || url.host.match(rApiDomain));

  //Static asset
  if (isRoot || isStatic) {
    return fetchStatic(event, url);
  }

  //API requests
  if (isApi) {
    return fetchApi(event, url);
  }

  //Fall back to network fetch
  return fetch(event.request);
}

/**
 * Messages
 */
 self.addEventListener('message', function(event) {
  switch (event.data) {

    //Skip waiting
    case 'skipWaiting':
      self.skipWaiting();
  }
});

/**
 * Install event
 */
self.addEventListener('install', function(event) {
  event.waitUntil(
    caches
     .open(cacheName)
     .then(cache => cache.addAll(cachedFiles))
  );
});

/**
 * Activate event
 */
self.addEventListener('activate', function(event) {

  //Clear old caches
  event.waitUntil(
    caches
      .keys()
      .then(names => Promise.all(
        names
          .filter(name => name !== cacheName)
          .map(name => caches.delete(name))
      ))
      .then(() => {
        if (typeof clients !== 'undefined') {
          return clients.claim();
        }
      })
  );
});

/**
 * Fetch event
 */
self.addEventListener('fetch', (event) => {

  //Not a GET request
  if (!event.request.method.match(rGet)) {
    return;
  }

  //Parse URL
  const url = new URL(event.request.url);

  //Not same origin and not from Hello Club?
  if (url.origin !== location.origin && !url.host.match(rDomain)) {
    return;
  }

  //Ignored
  if (ignoredFiles.some(file => file === url.pathname)) {
    return;
  }

  //Run through fetch handler
  event.respondWith(fetchHandler(event, url));
});

/**
 * Push events
 */
self.addEventListener('push', function(event) {

  //No data
  if (!event.data) {
    return;
  }

  //Catch any errors (e.g. data may not be JSON)
  try {

    //Extract notification title and options
    const data = event.data.json();
    const {title} = data;

    //Merge options
    const options = Object.assign({
      icon: 'https://app.helloclub.com/icons/android-chrome-192x192.png',
      badge: 'https://app.helloclub.com/icons/favicon-32x32.png',
    }, data.options || {});

    //Show the notification
    const promiseChain = self.registration.showNotification(title, options);
    event.waitUntil(promiseChain);
  }
  catch (error) {
    //Ignore this message
  }
});

/**
 * Notification clicked event
 */
self.addEventListener('notificationclick', function(event) {

  //Get data from event
  const {action, notification} = event;
  const actions = ['cancelBooking', 'bookings', 'cancelAttendance', 'account', 'subscription'];

  //Invalid/unknown action?
  if (actions.indexOf(action) === -1) {
    return;
  }

  //Get data and close notification
  const {data} = notification;
  notification.close();

  //Process action
  processAction(event, action, data);
});

/**
 * Process an action
 */
function processAction(event, action, data) {
  event
    .waitUntil(clients.matchAll({
      type: 'window'
    })
    .then(function(clientList) {
      for (const client of clientList) {
        client.postMessage({action, data})
        if ('focus' in client) {
          client.focus();
        }
        return;
      }

      //Open new window instead
      if (clients.openWindow) {
        clients.openWindow(data.route);
      }
    }));
}
