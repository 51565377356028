
/**
 * Module definition and dependencies
 */
angular.module('Shared.RemoveFromGroups.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('removeFromGroups', {
    templateUrl: 'modals/remove-from-groups.html',
    controller: 'ModalRemoveFromGroupsCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalRemoveFromGroupsCtrl', function(
  $controller, $notice, $modalInstance
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set flags
    this.isConfirming = false;

    //Only non-dynamic groups
    this.groups = this.groups.filter(group => !group.isDynamic);

    //Create model
    this.model = {};

    //Load number of members if counter given
    if (this.counter) {
      this.countNumRemoving();
    }
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isConfirming = true;

    //Use handler
    this
      .handler(this.model.groups)
      .then(result => $modalInstance.resolve(result))
      .catch(() => $notice.showError('Failed to remove from groups'))
      .finally(() => this.isConfirming = false);
  };

  /**
   * Count number of members we are removing
   */
  this.countNumRemoving = function() {

    //Flag as loading
    this.isLoading = true;

    //Count
    this
      .counter()
      .then(num => this.numRemoving = num)
      .finally(() => this.isLoading = false);
  };
});
