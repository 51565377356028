
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription', [
  'App.Subscription.Overview',
  'App.Subscription.Renew',
  'App.Subscription.Change',
  'App.Subscription.Purchase',
  'App.Subscription.Stop',
  'App.Subscription.Summary.Card',
  'App.Subscription.List.Card',
  'App.Subscription.RenewalDetails.Card',
  'App.Subscription.PickMemberships.Card',
  'App.Subscription.AddLinkedMemberships.Card',
  'App.Subscription.ChangeMembership.Card',
  'App.Subscription.ChangeDetails.Card',
  'App.Subscription.PersonalDetails.Card',
  'App.Subscription.AgreeConditions.Card',
  'App.Subscription.PurchaseNoPayment.Card',
  'App.Subscription.RenewNoPayment.Card',
  'App.Subscription.ChangeNoPayment.Card',
  'App.Subscription.CantRenew.Modal',
  'App.Subscription.CantPurchase.Modal',
  'App.Subscription.UpForRenewal.Modal',
  'App.Subscription.ViewMembership.Modal',
  'App.Subscription.AddLinkedMember.Modal',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('subscription', {
    parent: 'app',
    url: '/subscription',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'subscription',
    },
  });
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'subscription.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'subscription.overview', title});
  }, {priority: 10});
});
