
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.Overview.Controller', [])

/**
 * Controller
 */
.controller('CouponOverviewCtrl', function($modal, $state, Coupon) {

  /**
   * On changes
   */
  this.$onChanges = function() {
    this.filterCoupons();
  };

  /**
   * Filter coupons into current, past and upcoming
   */
  this.filterCoupons = function() {

    //Active coupons
    this.activeCoupons = this.coupons
      .filter(coupon => !coupon.isSpent && !coupon.isExpired && coupon.isActive);

    //Inactive coupons
    this.inactiveCoupons = this.coupons
      .filter(coupon => coupon.isSpent || coupon.isExpired || !coupon.isActive);
  };

  /**
   * Reload coupons
   */
  this.reloadCoupons = function() {
    return Coupon
      .own()
      .then(coupons => this.coupons = coupons)
      .then(() => this.filterCoupons());
  };

  /**
   * Purchase
   */
  this.purchase = function() {
    return $state.go('coupon.purchase', {});
  };

  /**
   * View coupon details
   */
  this.view = function($event) {

    //Get coupon
    const {coupon} = $event;

    //Open modal
    $modal.open('viewCoupon', {
      locals: {coupon},
    });
  };
});
