
/**
 * Define values
 */
const DISCOUNT_WAIVE = 'waive';
const DISCOUNT_FIXED = 'fixed';
const DISCOUNT_PERCENTAGE = 'percentage';

/**
 * Define array
 */
const DiscountTypes = [
  {
    value: DISCOUNT_FIXED,
    label: 'Fixed amount',
  },
  {
    value: DISCOUNT_PERCENTAGE,
    label: 'Percentage',
  },
  {
    value: DISCOUNT_WAIVE,
    label: 'No charge',
  },
];

/**
 * Set properties
 */
DiscountTypes.WAIVE = DISCOUNT_WAIVE;
DiscountTypes.FIXED = DISCOUNT_FIXED;
DiscountTypes.PERCENTAGE = DISCOUNT_PERCENTAGE;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.DiscountTypes.Options', [])
  .constant('DiscountTypes', DiscountTypes);
