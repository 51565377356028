
/**
 * Module definition and dependencies
 */
angular.module('Shared.AuditLogModels.Options', [])

/**
 * Constant definition
 */
.constant('AuditLogModels', [
  {
    label: 'Activity',
    value: 'Activity',
    module: 'activities',
  },
  {
    label: 'Alert',
    value: 'Alert',
    module: 'club',
  },
  {
    label: 'Area',
    value: 'Area',
    module: 'areas',
  },
  {
    label: 'Booking',
    value: 'Booking',
    module: 'bookings',
  },
  {
    label: 'Contact',
    value: 'Contact',
    module: 'contacts',
  },
  {
    label: 'Contact group',
    value: 'ContactGroup',
    module: 'contacts',
  },
  {
    value: 'Coupon',
    module: 'coupons',
  },
  {
    value: 'CouponType',
    module: 'couponTypes',
  },
  {
    value: 'Club',
    module: 'club',
  },
  {
    label: 'Custom field',
    value: 'CustomField',
    module: 'customFields',
  },
  {
    label: 'Event',
    value: 'Event',
    module: 'events',
  },
  {
    label: 'Event attendee',
    value: 'EventAttendee',
    module: 'events',
  },
  {
    label: 'Integration',
    value: 'Integration',
    module: 'integrations',
  },
  {
    value: 'Member',
    module: 'members',
  },
  {
    label: 'Member group',
    value: 'MemberGroup',
    module: 'members',
  },
  {
    label: 'Membership type',
    value: 'Membership',
    module: 'memberships',
  },
  {
    label: 'Mode',
    value: 'Mode',
    module: 'activities',
  },
  {
    label: 'Payment',
    value: 'Payment',
    module: 'finance',
  },
  {
    label: 'Subscription',
    value: 'Subscription',
    module: 'subscription',
  },
  {
    label: 'System',
    value: 'System',
    module: 'system',
  },
  {
    label: 'Tag',
    value: 'Tag',
    module: 'system',
  },
  {
    label: 'Transaction',
    value: 'Transaction',
    module: 'finance',
  },
]);
