
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.ViewAttendee.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('viewAttendee', {
    templateUrl: 'admin/event/modals/view-attendee.html',
    controller: 'ModalViewAttendeeCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalViewAttendeeCtrl', function(
  $controller, $modal, $notice, $store, $modalInstance,
  $q, Member, Payment, ErrorCodes, CouponTypeUses,
  MembershipConstraints, matchesMemberConstraint
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Load data
    $q
      .all([
        this.loadEvent(),
        this.loadMember(),
      ])
      .then(() => {
        this.questions = this.event.questions
          .filter(question => this.isEligibleQuestion(question));
      });
  };

  /**
   * Filter questions
   */
  this.isEligibleQuestion = function(question) {

    //Is a member
    if (this.member) {
      return matchesMemberConstraint(this.member, question);
    }

    //Is a guest
    return (
      question.constraint === MembershipConstraints.ALL ||
      question.constraint === MembershipConstraints.GUEST
    );
  };

  /**
   * Load member
   */
  this.loadMember = function() {

    //No member to load or already loaded
    if (!this.attendee.member || this.member) {
      return;
    }

    //Get ID
    const {id} = this.attendee.member;
    this.isLoadingMember = true;

    //Load
    return Member
      .findById(id)
      .then(member => this.member = member)
      .finally(() => this.isLoadingMember = false);
  };

  /**
   * Load event
   */
  this.loadEvent = function() {

    //Already loaded/passed to modal
    if (this.event) {
      return $q.resolve();
    }

    //Get ID
    const {id} = this.attendee.event;
    this.isLoadingEvent = true;

    //Load
    return $store.events
      .findById(id)
      .then(event => this.event = event)
      .finally(() => this.isLoadingEvent = false);
  };

  /**
   * Pay with membership
   */
  this.payWithMembership = function() {

    //Get data
    const {attendee, event} = this;

    //Define handler
    const handler = sub => attendee.useSubscription(sub);

    //Open modal
    $modal
      .open('payWithMembership', {
        locals: {attendee, event, handler},
      })
      .result
      .then(() => $notice.show('Paid'))
      .catch(() => $notice.showError('Payment failed'));
  };

  /**
   * Pay with coupons
   */
  this.payWithCoupons = function() {

    //Get data
    const {attendee, event} = this;
    const members = [attendee.member];
    const sessionsNeeded = 1;
    const label = event.name;

    //Filter for eligible coupons
    const filter = {
      areUsable: true,
      usableFor: [CouponTypeUses.EVENTS],
      members: members.map(member => member.id),
    };

    //Define handler
    const handler = coupons => attendee.useCoupons(coupons);

    //Open modal
    $modal
      .open('payWithCoupons', {
        locals: {label, sessionsNeeded, filter, members, handler},
      })
      .result
      .then(() => {
        $notice.show('Paid');
        this.loadMember();
      })
      .catch(error => {
        if (error.code === ErrorCodes.paymentMethodInvalid) {
          $notice.showError('Not enough sessions to pay for event');
        }
        else {
          $notice.showError('Payment failed');
        }
      });
  };

  /**
   * Pay with account credit
   */
  this.payWithAccountCredit = function() {

    //Get data
    const {attendee, event} = this;
    const {member, rule: {fee: amount}} = attendee;
    const label = event.name;
    const handler = data => attendee.markPaid(data, event.id);

    //Open modal
    $modal
      .open('payWithAccountCredit', {
        locals: {label, amount, member, handler},
      })
      .result
      .then(() => {
        $notice.show('Paid with account credit');
        this.loadMember();
      })
      .catch(() => $notice.showError('Payment failed'));
  };

  /**
   * Mark as paid
   */
  this.markAsPaid = function() {

    //Get data
    const {attendee, event} = this;

    //Create payment and define handler
    const payment = new Payment();
    const handler = data => attendee.markPaid(data, event.id);

    //Open modal
    return $modal
      .open('editPayment', {locals: {payment, handler}})
      .result
      .then(() => {
        $notice.show('Marked as paid');
        this.loadMember();
      })
      .catch(() => $notice.showError('Payment failed'));
  };
});
