
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Add.Controller', [])

/**
 * Controller
 */
.controller('AdminMemberAddCtrl', function(
  $controller, $state, CardCloseReasons
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminMemberCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Exit handler
   */
  this.exit = function($event) {

    //Get reason
    const {reason} = $event;

    //If card was closed, go back to overview
    if (reason === CardCloseReasons.CLOSE) {
      $state.go('admin.people.members.overview');
    }
    else if (reason === CardCloseReasons.SAVED) {
      $state.go('admin.people.members.edit', {memberId: this.member.id});
    }
  };
});
