
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area.Overview.Controller', [])

/**
 * Controller
 */
.controller('AdminAreaOverviewCtrl', function(
  $controller, $q, $timeout, $modal, $state, $filter, Intercom,
  Settings, Area, Pagination, ScrollPosition
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminAreaCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed areas');

    //Initialize
    this.canEdit = this.user.isAdmin();
    this.isSuper = this.user.isSuper();
    this.statusTimeout = null;
    this.isLoading = true;
    this.isStatusLoading = false;
    this.isStatusError = false;

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load areas
    this.loadPage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {

    //Remove change handler
    this.filter.offChange();

    //Cancel timeout
    if (this.statusTimeout) {
      $timeout.cancel(this.statusTimeout);
    }
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const $ctrl = this;
    const {page, filter, system, user} = this;

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');

    //System options
    if (system && system.hasLightControl) {
      if (user.canOverrideLights) {
        page.addOption('overrideLights');
      }
      page.addOption({
        title: 'Refresh area status',
        icon: 'refresh',
        click() {

          //Ignore if already busy
          if (this.isLoading) {
            return;
          }

          //Mark as loading, update status
          this.isLoading = true;
          $ctrl
            .updateStatus()
            .finally(() => this.isLoading = false);
        },
      });
    }
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter, activities} = this;

    //Set filter defaults
    filter.setDefaults({
      search: '',
    });

    //Filter on change handler
    filter.onChange(() => {

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      activity: activities,
    };
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    this.loadAreas();
  };

  /**
   * Load areas
   */
  this.loadAreas = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query areas
    return Area
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, areas} = data;

    //Set in scope
    this.areas = areas;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadAreas()
      .then(() => {
        if (isInitial) {
          ScrollPosition.restore();
          this.updateStatus();
        }
      });
  };

  /**
   * Update status
   */
  this.updateStatus = function() {

    //Only when enabled
    if (!this.system) {
      return $q.resolve();
    }

    //Clear timeout
    if (this.statusTimeout) {
      $timeout.cancel(this.statusTimeout);
    }

    //Flags
    this.isStatusLoading = true;
    this.isStatusError = false;

    //Get status (return promise for refresh icon handler)
    return this.system
      .getAreaStates()
      .then(states => this.system.applyAreaStates(this.areas, states))
      .catch(() => this.isStatusError = true)
      .finally(() => {

        //Toggle flag
        this.isStatusLoading = false;

        //Check again in 30 seconds
        this.statusTimeout = $timeout(() => {
          this.statusTimeout = null;
          this.updateStatus();
        }, 30 * 1000);
      });
  };

  /**
   * View status
   */
  this.viewStatus = function(area) {

    //Get members and service tags
    const members = area.getMembers();
    const serviceTags = area.getServiceTags();
    const canViewMembers = true;
    const activity = this.activities
      .find(activity => activity.isSame(area.activity));

    //Show modal
    $modal.open('basic', {
      templateUrl: 'booking/modals/view-status.html',
      locals: {area, members, activity, serviceTags, canViewMembers},
    });
  };

  /**
   * Change sponsor shortcut
   */
  this.changeSponsor = function(area) {
    $state.go('admin.area.edit', {areaId: area.id, changeSponsor: true});
  };
});
