
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.MembershipOptions.Component', [])

/**
 * Membership options component
 */
.component('membershipOptions', {
  template: `
    <more-options>
      <li ng-if="$ctrl.canEdit">
        <a ui-sref="admin.membership.edit({membershipId: $ctrl.membership.id})">Edit</a>
      </li>
      <li>
        <a ui-sref="admin.membership.add({membershipId: $ctrl.membership.id})">Copy</a>
      </li>
      <li>
        <a ui-sref="admin.log.audit({model: 'Membership', id: $ctrl.membership.id})">View audit log</a>
      </li>
      <li>
        <a ng-click="$ctrl.toggleArchived()">{{$ctrl.membership.isArchived ? 'Restore' : 'Archive'}}</a>
      </li>
      <li class="Dropdown-item--danger" ng-if="$ctrl.membership.isArchived">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    membership: '<',
    canEdit: '<',
    onDelete: '&',
    onToggleArchived: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Delete
     */
    this.delete = function() {
      const {membership} = this;
      this.onDelete({$event: {membership}});
    };

    /**
     * Archive/restore
     */
    this.toggleArchived = function() {
      const {membership} = this;
      const isArchived = !membership.isArchived;
      this.onToggleArchived({$event: {membership, isArchived}});
    };
  },
});
