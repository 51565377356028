
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.Renew.Controller', [
  'App.Account.Base.Controller',
])

/**
 * Controller
 */
.controller('SubscriptionRenewCtrl', function(
  $controller, $state, $modal, $q,
  ValidationError, Text, TextTypes, Subscription
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AccountBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Setup
   */
  this.setup = function() {

    //Set initial step
    this.stepDetails();

    //Check if personal details need to be shown and if we can renew
    this.checkPersonalDetails();
    this.checkCanRenew();
  };

  /**
   * Check if personal details step should be shown
   */
  this.checkPersonalDetails = function() {
    this.showPersonalDetails = this.user.needsReminder('checkPersonalDetails');
  };

  /**
   * Change membership
   */
  this.change = function() {
    const {old} = this;
    $state.go('subscription.change', {subId: old.id});
  };

  /**
   * Stop subscription
   */
  this.stop = function() {
    const {old} = this;
    $state.go('subscription.stop', {subId: old.id});
  };

  /**
   * Check if can renew
   */
  this.checkCanRenew = function() {

    //Don't check if we returned here after payment with a redirect
    if (this.outcome) {
      return;
    }

    //Start checking
    this.isChecking = true;

    //Check if can renew
    return this.old
      .getRenewOwnData()
      .then(data => this.processData(data))
      .catch(error => {
        if (error instanceof ValidationError) {
          this.onError({error});
        }
        throw error;
      })
      .finally(() => this.isChecking = false);
  };

  /**
   * Process subscription renewal data
   */
  this.processData = function(data) {

    //Get data
    const {meta, subscriptions: subs} = data;

    //Set sub and full membership in request
    this.subscription = subs[0];
    this.membership = this.memberships
      .find(m => m.isSame(subs[0].membership));

    //Check if the subscription has a fee
    this.hasMembershipFee = meta.hasFee;
    this.hasAutoAccountCredit = meta.hasAutoAccountCredit;
    this.requiresPayment = meta.requiresPayment;
    this.meta = meta;

    //Auto agree when there are no conditions or when retrying
    this.hasMembershipConditions = !!this.membership.conditions;
    this.hasAgreedToConditions = !this.hasMembershipConditions;
  };

  /**
   * Prepare payment
   */
  this.preparePayment = function() {

    //Get data
    const {meta, membership, old: {id: subscriptionId}} = this;
    const label = `${membership.name} membership subscription`;
    const {proRation, discounts, totalBaseFee: amount} = meta;

    //Make line items and set extra data
    this.payment.setLineItems([{label, amount}]);
    this.payment.setExtraData({subscriptionId});
    this.payment.setRedirectPath(`subscription/renew/${subscriptionId}`);

    //Add discount line items
    for (const discount of discounts) {
      this.payment.addLineItem({
        label: discount.name,
        amount: -1 * discount.amount,
      });
    }

    //Add pro-ration line item
    if (proRation && proRation.deduction !== 0) {
      this.payment.addLineItem({
        label: `Pro-ration`,
        amount: -1 * proRation.deduction,
        suffix: `${proRation.numDays}/${proRation.termDays} days`,
      });
    }
  };

  /**
   * Set agreement
   */
  this.setAgreedToConditions = function($event) {
    this.hasAgreedToConditions = $event.hasAgreed;
  };

  /**
   * Renew handler
   */
  this.renew = function(isPayingLater = false) {

    //Renew
    return this.old
      .renewOwn()
      .then(() => this.onRenewed({isPayingLater}))
      .catch(error => this.onError({error}));
  };

  /**
   * Pay later, just renew
   */
  this.onPayLater = function() {

    //Get data
    const {club, subscription} = this;
    const payingFor = `membership fee`;
    const {amount} = subscription.fee;

    //Confirm
    return $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-pay-later.html',
        locals: {club, payingFor, amount},
        rejectOnDismissal: true,
      })
      .result
      .then(() => this.renew(true))
      .catch(() => {});
  };

  /**
   * Paid success handler
   */
  this.onPaid = function($event) {
    this.onRenewed($event);
  };

  /**
   * On renewed handler
   */
  this.onRenewed = function($event) {

    //Get data
    const {isPayingLater, payment} = $event;
    const isPaid = (payment && payment.isPaid);
    const {club, user, memberships, old} = this;
    const amount = this.payment.lineItemTotal;

    //Query own subscriptions
    const loadSubs = Subscription.own();
    const loadText = Text.getText(TextTypes.PAYMENT_INSTRUCTIONS);

    //Load data
    $q
      .all([loadSubs, loadText])
      .then(([subs, paymentInstructions]) => {

        //Find the new subscription
        const sub = subs.find(sub => sub.old === old.id);
        const membership = memberships.find(m => m.isSame(sub.membership));
        const {autoRenewal} = membership;

        //Check if we can auto renew. Auto renewal can be enabled if:
        //a) Membership auto renewal is enabled but not on by default
        //b) We paid and we have a payment source on file, or payment was not needed
        const canAutoRenew = (
          autoRenewal.isEnabled &&
          autoRenewal.canOptIn &&
          !autoRenewal.isDefault &&
          ((isPaid && user.hasPaymentSources) || sub.hasFee === false)
        );

        //Create handlers for auto renewal and redirection
        const handler = () => sub.toggleAutoRenew(true);

        //Open modal
        const modalInstance = $modal
          .open('basic', {
            templateUrl: 'subscription/modals/renewed.html',
            locals: {
              club, user,
              isPaid, isPayingLater, amount, handler,
              paymentInstructions, canAutoRenew,
            },
          });

        //Close and result handlers
        modalInstance.closed.then(() => this.redirectForSubscriptions());
        modalInstance.result.then(() => this.redirectForSubscriptions());
      });
  };

  /**
   * Process payment outcome
   */
  this.processPaymentOutcome = function(payment) {

    //Not paid, show generic modal
    if (!payment.isPaid) {
      return $base.processPaymentOutcome.call(this, payment);
    }

    //Paid, use our custom success handler
    this.onRenewed({payment});
  };

  /**
   * On error handler
   */
  this.onError = function($event) {

    //Get data
    const {error} = $event;
    const {old: subscription} = this;

    //Open modal
    $modal
      .open('cantRenew', {locals: {error, subscription}})
      .closed
      .then(() => $state.go('subscription.overview', {}, {reload: true}));
  };

  /**
   * Check route is valid
   */
  this.checkRouteValid = function() {

    //Get data
    const {old, outcome} = this;
    const {subId} = this.transition.params();

    //Ensure valid link clicked
    if (subId && (!old || old.id !== subId)) {
      return $state
        .go('subscription.overview')
        .then(() => {
          $modal.open('basic', {templateUrl: 'modals/invalid-link.html'});
        });
    }

    //Must have old sub to renew
    if (!old) {
      return $state.go('subscription.overview');
    }

    //Already renewed, not expiring and not here for the payment outcome?
    if ((old.isRenewed || !old.isExpiring) && !outcome) {
      return $state.go('subscription.overview');
    }
  };

  /**************************************************************************
   * Navigation
   ***/

  /**
   * Details step
   */
  this.stepDetails = function() {
    this.step = 'details';
  };

  /**
   * Personal details step
   */
  this.stepPersonalDetails = function() {
    this.step = 'personalDetails';
  };

  /**
   * Agree to conditions step
   */
  this.stepAgreeConditions = function() {
    this.step = 'agreeConditions';
  };

  /**
   * Renew without payment step
   */
  this.stepRenewNoPayment = function() {
    this.step = 'renewNoPayment';
  };

  /**
   * Next from details
   */
  this.nextFromDetails = function() {

    //Need to check personal details?
    if (this.showPersonalDetails) {
      this.stepPersonalDetails();
    }

    //Otherwise skip
    else {
      this.nextFromPersonalDetails();
    }
  };

  /**
   * Next from personal details
   */
  this.nextFromPersonalDetails = function() {

    //Re-check subscription, as address/dob may have been entered, which
    //could potentially apply a discount
    this.old
      .getRenewOwnData()
      .then(data => this.processData(data));

    //Got membership conditions?
    if (this.hasMembershipConditions) {
      return this.stepAgreeConditions();
    }

    //Onwards
    this.nextFromConditions();
  };

  /**
   * Prev from personal details
   */
  this.prevFromPersonalDetails = function() {
    this.stepDetails();
  };

  /**
   * Prev from conditions
   */
  this.prevFromConditions = function() {

    //Need to check personal details?
    if (this.showPersonalDetails) {
      this.stepPersonalDetails();
    }

    //Otherwise skip
    else {
      this.prevFromPersonalDetails();
    }
  };

  /**
   * Next from conditions
   */
  this.nextFromConditions = function() {

    //No fee?
    if (!this.hasMembershipFee) {
      return this.stepRenewNoPayment();
    }

    //Prepare payment
    this.preparePayment();

    //Has fee, check if can pay with credit
    if (this.payment.canUseAccountCredit && !this.hasAutoAccountCredit) {
      return this.stepUseCredit();
    }

    //Onwards
    this.nextFromUseCredit();
  };

  /**
   * Prev from renew without payment
   */
  this.prevFromRenewNoPayment = function() {

    //Got membership conditions?
    if (this.hasMembershipConditions) {
      return this.stepAgreeConditions();
    }

    //Backwards
    this.prevFromConditions();
  };

  /**
   * Prev from use account credit
   */
  this.prevFromUseCredit = function() {
    this.prevFromRenewNoPayment();
  };

  /**
   * Prev from select payment method
   */
  this.prevFromSelectPaymentMethod = function() {

    //Check if can pay with credit
    if (this.payment.canUseAccountCredit && !this.hasAutoAccountCredit) {
      return this.stepUseCredit();
    }

    //Backwards
    this.prevFromUseCredit();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    $state.go('subscription.overview');
  };
});
