
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Report.Financial.Controller', [])

/**
 * Controller
 */
.controller('AdminReportFinancialCtrl', function(
  PaymentMethods, TransactionTypes
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Assign params to self
    Object.assign(this, this.transition.params());

    //Check if comparing
    this.isComparing = Object.keys(this.compData).length > 0;

    //Get data
    const base = this.baseData;
    const comp = this.compData;

    //By payment methods
    this.byMethod = PaymentMethods.map(method => ({
      name: method.label,
      base: this.totalBy(base.payments, 'method', method.value),
      comp: this.totalBy(comp.payments, 'method', method.value),
    }));

    //By transaction types
    this.byType = TransactionTypes.map(type => ({
      name: type.label,
      base: this.totalBy(base.payments, 'type', type.value),
      comp: this.totalBy(comp.payments, 'type', type.value),
    }));

    //Totals
    this.totals = {
      base: this.total(base.payments),
      comp: this.total(comp.payments),
    };
  };

  /**
   * Helper to create total by given property
   */
  this.totalBy = function(items, property, value) {
    const set = items ? items.filter(item => item[property] === value) : [];
    return this.total(set);
  };

  /**
   * Helper to total
   */
  this.total = function(items) {

    //Init
    const init = this.initValues();
    if (!items) {
      return init;
    }

    //Get total
    const total = items.reduce((total, item) => {
      total.count += item.count;
      total.amount += item.amount;
      total.duration += (item.duration * item.count);
      return total;
    }, init);

    //Averages
    total.average = this.average(total.amount, total.count);
    total.duration = this.average(total.duration, total.count);

    //Return
    return total;
  };

  /**
   * Average helper
   */
  this.average = function(amount, count) {
    return count ? (amount / count) : 0;
  };

  /**
   * Helper to init item
   */
  this.initValues = function() {
    return {
      count: 0,
      amount: 0,
      average: 0,
      duration: 0,
    };
  };
});
