
/**
 * Module definition and dependencies
 */
angular.module('App.Public.Calendar.Controller', [])

/**
 * Controller
 */
.controller('PublicCalendarCtrl', function(
  Settings, $controller, $state, Modules
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CalendarCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);
  };

  /**
   * Setup properties & flags
   */
  this.setupProps = function() {

    //Initialize properties
    this.hasBookingsModule = Modules.has('bookings');
    this.hasMultipleActivities = (this.activities.length > 1);
    this.canManageBookings = false;
    this.loadOps = [];

    //Default activity and activity options
    if (this.activities) {
      this.defaultActivity = this.activities.find(activity => activity.isDefault);
      this.activityOptions = this.activities.map(a => a);
      this.activityOptions.unshift({name: 'All activities', id: null});
    }

    //Period options
    this.PeriodOptions = [
      {value: 'week', label: 'Weekly'},
      {value: 'month', label: 'Monthly'},
    ];

    //Event settings
    const overviewFields = Settings.get('event.overviewFields', []);

    //Determine fields to show
    this.showOrganisers = overviewFields.includes('organisers');
    this.showActivity = overviewFields.includes('activity');
  };

  /**
   * Get events filter
   */
  this.getEventsFilter = function() {

    //Get data
    const filter = this.getQueryFilter();
    const showAllEventsOnPublicCalendar = Settings
      .get('event.showAllEventsOnPublicCalendar');

    //Append hidden flag
    filter.isHidden = false;

    //Append category
    if (this.filter.category) {
      filter.category = this.filter.category;
    }

    //Append public flag
    if (!showAllEventsOnPublicCalendar) {
      filter.isPublic = true;
    }

    //Return filter
    return filter;
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const params = this.transition.params();

    //Default view
    this.isList = params.list || false;
  };

  /**
   * Toggle view
   */
  this.toggleListView = function() {
    this.isList = !this.isList;
    this.updateUrl();
  };

  /**
   * Helper to update URL based on current filter
   */
  this.updateUrl = function(location = true) {

    //Transition options
    const activity = this.activity ? this.activity.identifier : 'all';
    const date = this.date.format('YYYY-MM-DD');
    const {period, category} = this.filter;
    const list = this.isList ? 'list' : null;
    const {params: p} = $state;

    //Ignore if the same
    if (
      p.period === period && p.date === date &&
      p.activity === activity && p.list === list
      && p.category === category) {
      return;
    }

    //Transition
    $state
      .go($state.current.name, {
        period, activity, date, list, category,
      }, {location});
  };

  /**
   * Get event URL
   */
  this.getEventUrl = function(event) {
    return this.club.makeUrl(event.publicPath);
  };

  /**
   * No-ops
   */
  this.setupCrumbs = function() {};
  this.loadBookings = function() {};
});
