
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Welcome.Error', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('welcome.error', {
    url: '/error',
    component: 'welcomeErrorRoute',
  });
})

/**
 * Route component
 */
.component('welcomeErrorRoute', {
  controller: 'WelcomeErrorCtrl',
  templateUrl: 'portal/welcome/steps/error.html',
  bindings: {
    club: '<',
    welcome: '<',
  },
})

/**
 * Controller
 */
.controller('WelcomeErrorCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('WelcomeBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);
});
