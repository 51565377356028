
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.ApproveTransactions.Controller', [])

/**
 * Controller
 */
.controller('ApproveTransactionsCtrl', function(Transaction) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize flags
    this.isApproving = false;
    this.isApproved = false;
    this.isInvalidToken = false;

    //Initialize model
    this.model = {name: ''};
  };

  /**
   * Submit request
   */
  this.submit = function() {

    //Must be validated
    if (this.form.$invalid) {
      return;
    }

    //Get params
    const params = this.transition.params();
    const token = params.token;
    const {name} = this.model;

    //Mark as approving
    this.isApproving = true;

    //Send request
    Transaction
      .approve(token, name)
      .then(() => this.isApproved = true)
      .catch(error => {

        //Process errors
        if (
          error.code === 'INVALID_TOKEN' ||
          error.code === 'BAD_REQUEST' ||
          error.code === 'NOT_FOUND'
        ) {
          this.isInvalidToken = true;
          return;
        }
      })
      .finally(() => this.isApproving = false);
  };
});
