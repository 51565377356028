
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.Areas.Component', [])

/**
 * Booking areas component
 */
.component('bookingGridAreas', {
  template: `
    <div class="BookingGrid">
      <div
        class="BookingGrid-column"
        ng-repeat="area in $ctrl.areas track by area.id"
        ng-class="{'is-swiped': area.isSwiped}"
      >
        <div class="BookingGrid-cell BookingGridArea">
          <i
            class="Icon BookingGridArea-navIcon"
            ng-click="$ctrl.onPreviousArea()"
            ng-class="{disabled: !$ctrl.hasPreviousArea}"
          >chevron_left</i>
          <div class="BookingGridArea-name">
            {{area.name}}
          </div>
          <i
            class="Icon BookingGridArea-navIcon"
            ng-click="$ctrl.onNextArea()"
            ng-class="{disabled: !$ctrl.hasNextArea}"
          >chevron_right</i>
        </div>
      </div>
    </div>
  `,
  bindings: {
    areas: '<',
    visibleArea: '<',
    onNextArea: '&',
    onPreviousArea: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * On changes
     */
    this.$onChanges = function() {
      this.hasNextArea = this.areas.length > this.visibleArea;
      this.hasPreviousArea = this.visibleArea > 1;
    };
  },
});
