
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Visitor.Intro', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('visitor.intro', {
    url: '',
    component: 'visitorIntroRoute',
  });
})

/**
 * Route component
 */
.component('visitorIntroRoute', {
  controller: 'VisitorIntroCtrl',
  templateUrl: 'portal/visitor/steps/intro.html',
  bindings: {
    club: '<',
    user: '<',
    visit: '<',
    isKiosk: '<',
  },
})

/**
 * Controller
 */
.controller('VisitorIntroCtrl', function(
  $controller, $interval, Text, TextTypes, Auth, VisitorLog, Settings
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('VisitorBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flag as loading
    this.isLoading = true;
    this.currentVisitors = 0;

    //Load intro text
    Text
      .getText(TextTypes.VISITOR_INTRO)
      .then(text => this.intro = text)
      .finally(() => this.isLoading = false);

    //Check if we have terms and conditions
    Text
      .findByType(TextTypes.VISITOR_TERMS)
      .then(text => this.visit.setTermsAndConditions(text.hasText()));

    //Count current visitors
    if (Settings.get('visitorLog.showTotal')) {
      this.countVisitors();
      this.countInterval = $interval(() => this.countVisitors(), 60 * 1000);
    }
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    if (this.countInterval) {
      $interval.cancel(this.countInterval);
    }
  };

  /**
   * Count visitors
   */
  this.countVisitors = function() {
    VisitorLog
      .countByTime()
      .then(total => this.currentVisitors = total);
  };

  /**
   * Login
   */
  this.login = function() {
    Auth.goToLoginState('visitor.intro');
  };

  /**
   * Logout
   */
  this.logout = function() {

    //Logout
    Auth.logout();
    Auth.setRedirectState('visitor.intro');
  };
});
