
/**
 * Module definition and dependencies
 */
angular.module('App.Home.Events.Card', [])

/**
 * Component
 */
.component('cardHomeEvents', {
  templateUrl: 'home/cards/events.html',
  require: {
    card: '^^',
  },
  bindings: {
    user: '<',
    events: '<',
  },
});
