
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.Service', [])

/**
 * Service definition
 */
.factory('BookingGrid', function() {

  //Slot height (must match CSS)
  const slotHeight = 4.25 * 16;

  //Class definition
  class BookingGrid {

    /**
     * Duration to height converter (pixels)
     * NOTE: Not rounding, as it causes wrong positions
     */
    durationToHeight(duration, baseDuration) {
      return slotHeight * (duration / baseDuration);
    }
  }

  //Return instance
  return new BookingGrid();
});
