
/**
 * Module definition and dependencies
 */
angular.module('Shared.PaymentFlow.Service', [])

/**
 * Service definition
 */
.factory('PaymentFlow', function(
  $storage, $stripe, $timeout, $interval, $q, $sync,
  Integrations, PaymentMethods, Payment, Settings
) {

  /**
   * Payment flow helper class
   */
  class PaymentFlow {

    /**
     * Constructor
     */
    constructor(type, user) {

      //Providers, payment methods and payable line items
      this.providers = {};
      this.methods = [];
      this.lineItems = [];

      //Set user and payment type
      this.type = type;
      this.user = user;

      //Payment method and related flags
      this.method = null;
      this.sourceId = null;
      this.redirectPath = `account/pay`;
      this.extraData = {};
      this.isPublic = false;
      this.isStoringSource = false;
      this.isExistingSource = false;
      this.isUsingCredit = (user && user.accountCredit > 0);
    }

    /**
     * Check if we have any payment methods
     */
    get hasMethods() {
      return (this.methods.length > 0);
    }

    /**
     * Total of line items
     */
    get lineItemTotal() {
      return this.lineItems.reduce((total, item) => {
        if (item.items) {
          return total + item.items.reduce((subTotal, item) => {
            return subTotal + (item.amount || 0);
          }, 0);
        }
        return total + (item.amount || 0);
      }, 0);
    }

    /**
     * Account credit used for this payment
     */
    get usedCredit() {
      if (!this.isUsingCredit || !this.user) {
        return 0;
      }
      return Math.min(this.lineItemTotal, this.user.accountCredit);
    }

    /**
     * Subtotal payable (less fees)
     */
    get subtotal() {
      return this.lineItemTotal - this.usedCredit;
    }

    /**
     * Total amount payable
     */
    get total() {
      return this.subtotal + (this.fee || 0);
    }

    /**
     * Payment method data
     */
    get methodData() {

      //Get data
      const {method, sourceId, isStoringSource, isExistingSource} = this;

      //Stripe
      if (method && method.isStripe) {
        return {sourceId, isExistingSource, isStoringSource};
      }

      //No data
      return {};
    }

    /**
     * Check if we have any error
     */
    get hasError() {
      return (this.loadError || this.paymentError || this.cardError);
    }

    /**
     * Can use account credit
     */
    get canUseAccountCredit() {

      //Get data
      const {user, lineItemTotal} = this;
      const disallow = Settings.get(
        'accountCredit.disallowPartialPayments', false
      );

      //No user
      if (!user) {
        return false;
      }

      //Check if can use account credit
      return (
        (!disallow && Number(user.accountCredit) > 0) ||
        (disallow && Number(user.accountCredit) >= lineItemTotal)
      );
    }

    /**
     * Check if we need actual payment (in addition to any account credit used)
     */
    get needsPayment() {
      return (this.subtotal > 0);
    }

    /**
     * Has valid payment sources check
     */
    get hasValidSources() {
      return this.sources.some(source => source.card && !source.card.isExpired);
    }

    /**
     * Existing payment sources (for the selected method)
     */
    get sources() {

      //No user or no payment method selected
      if (!this.user || !this.method) {
        return [];
      }

      //No data for this payment method
      const method = this.method.value;
      if (!this.user[method] || !this.user[method].sources) {
        return [];
      }

      //Got sources
      return this.user[method].sources;
    }

    /**************************************************************************
     * Setters
     ***/

    /**
     * Set line items
     */
    setLineItems(lineItems) {
      this.lineItems = lineItems;
      this.checkCanUseAccountCredit();
    }

    /**
     * Add line item
     */
    addLineItem(lineItem) {
      this.lineItems.push(lineItem);
      this.checkCanUseAccountCredit();
    }

    /**
     * Clear line items
     */
    clearLineItems() {
      this.lineItems = [];
      this.checkCanUseAccountCredit();
    }

    /**
     * Set as public
     */
    setPublic(isPublic) {
      this.isPublic = isPublic;
    }

    /**
     * Set payment method
     */
    setMethod(method) {

      //Set method
      this.method = method;

      //Check sources
      this.checkSources();
    }

    /**
     * Clear payment method
     */
    clearMethod() {

      //Clear
      this.method = null;
      this.storeSource(false);
    }

    /**
     * Set extra payment data
     */
    setExtraData(data) {
      Object.assign(this.extraData, data);
    }

    /**
     * Store source
     */
    storeSource(isStoringSource) {
      this.isStoringSource = isStoringSource;
    }

    /**
     * Use existing source
     */
    useExistingSource(source) {
      this.sourceId = source.id;
      this.isExistingSource = true;
      this.isStoringSource = false;
    }

    /**
     * Use new source
     */
    useNewSource(source) {
      this.sourceId = source.id;
      this.isExistingSource = false;
    }

    /**
     * Clear existing source
     */
    clearExistingSource() {
      this.sourceId = null;
      this.isExistingSource = false;
      this.isStoringSource = false;
    }

    /**
     * Set is using credit
     */
    setIsUsingCredit(isUsingCredit) {
      this.isUsingCredit = isUsingCredit;
    }

    /**
     * Set redirect path
     */
    setRedirectPath(redirectPath) {
      this.redirectPath = redirectPath;
    }

    /**
     * Clear errors
     */
    clearErrors() {
      this.loadError = null;
      this.cardError = null;
      this.paymentError = null;
    }

    /**
     * Set user
     */
    setUser(user) {
      this.user = user;
    }

    /**
     * Update user data
     */
    refreshUser() {
      if (this.user && typeof this.user.refresh === 'function') {
        this.user.refresh();
      }
    }

    /**
     * Check if we can use account credit & reset
     */
    checkCanUseAccountCredit() {
      if (this.isUsingCredit && !this.canUseAccountCredit) {
        this.isUsingCredit = false;
      }
    }

    /**************************************************************************
     * Providers management
     ***/

    /**
     * Load payment providers
     */
    loadProviders() {

      //Load integrations
      return Integrations
        .load()
        .then(integrations => {

          //Make providers map and filter payment methods accordingly
          this.makeProvidersMap(integrations);

          //Filter methods and load last used one
          this.filterMethods();
          this.loadLastUsedMethod();
        });
    }

    /**
     * Check if we have a certain provider
     */
    hasProvider(type) {
      return !!this.providers[type];
    }

    /**
     * Make payment providers map
     */
    makeProvidersMap(integrations) {
      this.providers = integrations
        .filter(integration => integration.isOkForPayment)
        .reduce((map, integration) => {
          map[integration.type] = integration;
          return map;
        }, {});
    }

    /**
     * Setup providers
     */
    setupProviders() {

      //Setup Stripe if needed
      if (this.hasProvider('stripe')) {
        this.setupStripe();
      }
    }

    /**
     * Setup stripe
     */
    setupStripe() {

      //Get public key
      const {publicKey} = this.providers.stripe.data;

      //Flag as not ready yet
      this.isReady = false;

      //Load service
      try {

        //Initialize stripe service
        const stripe = $stripe.service(publicKey);

        //Create elements and create card
        const elements = stripe.elements();
        const card = elements.create('card');

        //Store card and stripe service
        this.card = card;
        this.stripe = stripe;

        //Apply listeners
        card.on('ready', () => {
          card.focus();
          $timeout(() => {
            this.isReady = true;
          });
        });
        card.on('change', ({error}) => {
          $timeout(() => {
            this.cardError = error ? error.message : null;
          });
        });

        //Mount element
        $timeout(() => {
          card.mount('#CardElement');
        }, 500);
      }
      catch (error) {
        this.loadError = error.message;
      }
    }

    /**************************************************************************
     * Payment processing
     ***/

    /**
     * Confirm payment method
     */
    confirmPaymentMethod() {

      //Clear errors
      this.clearErrors();

      //Stripe and not using an existing source?
      if (this.method.isStripe && !this.isExistingSource) {
        const promise = this.checkStripeCard();
        return $q.when(promise);
      }

      //All good
      return $q.resolve();
    }

    /**
     * Check Stripe card
     */
    async checkStripeCard() {

      //Generate Stripe payment method
      try {
        const {card, stripe} = this;
        const {paymentMethod: source, error} = await stripe
          .createPaymentMethod({
            type: 'card',
            card,
          });

        //Error
        if (error) {
          throw error;
        }

        //Use as new source
        this.useNewSource(source);
      }
      catch (error) {

        //Capture exceptions
        if (error instanceof Error) {
          Sentry.captureException(error);
        }

        //Set payment error
        if (!this.cardError) {
          this.paymentError = error;
        }
      }
    }

    /**
     * Load the charge and fee amounts
     */
    getChargeAndFee() {

      //Get data
      const {type, subtotal: amount, methodData} = this;

      //Paying with account credit?
      if (!this.method) {
        const charge = amount;
        const fee = 0;
        Object.assign(this, {charge, fee});
        return $q.resolve();
      }

      //Create data for request
      const method = this.method.value;
      const data = Object.assign({method, type, amount, [method]: methodData});

      //Load
      return Payment
        .chargeAndFee(data)
        .then(({charge, fee, error}) => {
          Object.assign(this, {charge, fee});
          if (error) {
            throw error;
          }
        })
        .catch(error => {
          this.paymentError = error;
        });
    }

    /**
     * Initiate payment
     */
    initiatePayment() {

      //Clear errors
      this.clearErrors();

      //Get data
      const {
        type, methodData, redirectPath, extraData,
        needsPayment, isPublic, isUsingCredit,
      } = this;

      //Determine method
      let method;
      if (isUsingCredit && !needsPayment) {
        method = 'accountCredit';
      }
      else if (this.method) {
        method = this.method.value;
      }
      else {
        return $q.reject();
      }

      //Create data
      const data = Object.assign({
        type, method, redirectPath, isUsingCredit, [method]: methodData,
      }, extraData || {});

      //Initiate payment
      return Payment
        .initiate(data, isPublic)
        .then(outcome => {

          //Redirecting?
          if (outcome.redirectUrl) {
            $sync.redirect(outcome.redirectUrl);
            outcome.isRedirecting = true;
            return outcome;
          }

          //Stripe
          if (this.method && this.method.isStripe) {
            const {publicKey} = this.providers.stripe.data;
            const {clientSecret} = outcome;
            return $stripe
              .service(publicKey)
              .confirmCardPayment(clientSecret)
              .then(result => {

                //Get data from result
                const {paymentIntent, error} = result;

                //Rethrow error (no need to fulfil on server, webhook will do it)
                if (error) {
                  throw error;
                }

                //Get ID's
                const {id: intentId} = paymentIntent;
                const paymentId = outcome.payment.id;

                //Fulfil payment
                return Payment.fulfil({method, paymentId, intentId});
              });
          }

          //Account credit
          return outcome;
        })
        .then(outcome => {
          this.refreshUser();
          this.refreshAccountCredit();
          return outcome;
        })
        .catch(error => {
          this.paymentError = error;
          throw error;
        });
    }

    /**************************************************************************
     * Payment method management
     ***/

    /**
     * Filter payment methods
     */
    filterMethods() {
      this.methods = PaymentMethods
        .filter(method => this.isValidForOnlinePayment(method));
    }

    /**
     * Check if a payment method is valid for online payment
     */
    isValidForOnlinePayment(method) {
      return (method.isOnline && this.providers[method.value]);
    }

    /**
     * Load last used payment method, fallback to first method available
     */
    async loadLastUsedMethod() {

      //Get last used payment method
      const last = $storage.get('payment.lastUsedMethod');
      const method = this.methods.find(m => m.value === last);

      //Check if found
      if (last && method) {
        return this.setMethod(method);
      }

      //Return first method otherwise
      return this.setMethod(this.methods[0]);
    }

    /**
     * Store last used payment method
     */
    async storeLastUsedMethod() {
      if (this.method) {
        $storage.set('payment.lastUsedMethod', this.method.value);
      }
    }

    /**************************************************************************
     * Source and account credit management
     ***/

    /**
     * Check sources
     */
    checkSources() {

      //No user or payment method
      if (!this.user || !this.method) {
        this.storeSource(false);
        return;
      }

      //Get data
      const method = this.method.value;
      const data = this.user[method];

      //Check if user has existing payment sources on file for Stripe
      if (!data || !data.sources || !data.sources.length) {
        this.clearExistingSource();
        return;
      }

      //Get valid sources
      const sources = data.sources.filter(s => !s.isExpired);
      const defaultSource = sources.find(s => s.isDefault);

      //Set
      this.useExistingSource(defaultSource || sources[0]);
    }

    /**
     * Remove an existing payment source
     */
    removeSource(source) {

      //No user or payment method
      if (!this.user || !this.method) {
        return;
      }

      //Flag as removing
      source.isRemoving = true;

      //Remove source
      return this.user
        .removePaymentSource(this.method.value, source.id)
        .then(() => this.checkSources())
        .finally(() => source.isRemoving = false);
    }

    /**
     * Refresh account credit
     */
    refreshAccountCredit() {

      //Only if we have a user with ID
      if (this.user && this.user.id) {
        if (typeof this.user.refreshAccountCredit === 'function') {
          this.user.refreshAccountCredit();
        }
      }
    }

    /**
     * Start refreshing account credit
     */
    startRefreshingAccountCredit() {

      //Initial refresh
      this.refreshAccountCredit();

      //Periodic refrecht
      this.accountCreditRefreshInterval = $interval(() => {
        this.refreshAccountCredit();
      }, 60 * 1000);
    }

    /**
     * Stop refreshing account credit
     */
    stopRefreshingAccountCredit() {
      $interval.cancel(this.accountCreditRefreshInterval);
    }
  }

  //Return class
  return PaymentFlow;
});

