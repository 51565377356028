
/**
 * Module definition and dependencies
 */
angular.module('Shared.CouponType.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('couponType', {
    model: 'CouponType',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'couponTypes',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('CouponType', function($api, $baseModel, CouponTypeUses) {

  /**
   * Constructor
   */
  function CouponType(data) {

    //Parent constructor
    $baseModel.call(this, data);

    //Name with status property
    Object.defineProperty(this, 'nameWithStatus', {
      get() {
        if (!this.isArchived) {
          return this.name;
        }
        return this.name + ' (Archived)';
      },
    });

    //Usable for text
    Object.defineProperty(this, 'usableForText', {
      get() {
        const {usableFor} = this;
        if (usableFor.length === 0) {
          return 'None';
        }
        return usableFor
          .map(use => CouponTypeUses.find(item => item.value === use))
          .map(use => use.label)
          .join(', ');
      },
    });

    /**
     * Activity specific session limits
     */
    Object.defineProperty(this, 'hasActivitySessionLimits', {
      get() {
        return this.activities.some(activity => activity.numSessions > 0);
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(CouponType.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  CouponType.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('activities', null, true);

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  CouponType.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Return JSON
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save
   */
  CouponType.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const {id} = this;

    //Update
    if (id) {
      return $api.couponType
        .update(data)
        .then(data => this.fromJSON(data));
    }

    //Create
    return $api.couponType
      .create(data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  CouponType.prototype.patch = function(data) {
    const {id} = this;
    return $api.couponType
      .patch({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  CouponType.prototype.delete = function() {
    return $api.couponType
      .delete(null, this)
      .then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  CouponType.query = function(filter) {
    return $api.couponType.query(filter);
  };

  /**
   * Find by ID
   */
  CouponType.findById = function(id) {
    return $api.couponType.get({id});
  };

  //Return
  return CouponType;
});
