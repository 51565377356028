
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.Edit', [
  'App.Admin.Resource.Edit.Controller',
  'App.Admin.Resource.Edit.Info.Card',
  'App.Admin.Resource.Edit.Details.Card',
  'App.Admin.Resource.Edit.Assignment.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.resource.edit', {
    url: '/edit/:resourceId',
    component: 'adminResourceEditRoute',
    params: {
      showAssignee: {
        value: false,
      },
    },
    resolve: {
      resource(transition, Resource) {
        const params = transition.params();
        return Resource.findById(params.resourceId);
      },
    },
  });
})

/**
 * Route component
 */
.component('adminResourceEditRoute', {
  controller: 'AdminResourceEditCtrl',
  templateUrl: 'admin/resource/edit/edit.html',
  bindings: {
    club: '<',
    user: '<',
    resource: '<',
    module: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {
  $transitions.onSuccess({
    to: 'admin.resource.edit',
  }, transition => {

    //Get resource
    const resource = transition.injector().get('resource');

    //Set page title and crumb
    Page.setTitle(resource.name);
    Page.addCrumb({
      sref: 'admin.resource.edit',
      params: {
        resourceId: resource.id,
      },
    });
  });
});
