/* eslint consistent-this: off */

/**
 * Module definition and dependencies
 */
angular.module('Shared.Subscription.Model', [
  'BaseModel.Service',
  'Shared.Subscription.Ref.Model',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('subscription', {
    model: 'Subscription',
    params: {
      id: '@id',
      subId: '@subId', //NOTE: To avoid id being in URL twice for get renew/change data
      action: '@action',
    },
    actions: {
      own: {
        url: 'own',
        method: 'GET',
        isArray: true,
        isModel: true,
        dataKey: 'subscriptions',
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      getData: {
        url: 'data/:action/:subId',
        method: 'POST',
      },
      query: {
        method: 'GET',
        isArray: true,
        isModel: true,
        dataKey: 'subscriptions',
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
      mute: {
        url: 'mute',
        method: 'PUT',
      },
      stop: {
        url: 'stop',
        method: 'PUT',
        isArray: true,
        isModel: true,
        dataKey: 'subscriptions',
      },
      pause: {
        url: 'pause',
        method: 'PUT',
        isArray: true,
        isModel: true,
        dataKey: 'subscriptions',
      },
      renew: {
        url: 'renew',
        method: 'PUT',
        isArray: true,
        isModel: true,
        dataKey: 'subscriptions',
      },
      change: {
        url: 'change',
        method: 'PUT',
        isArray: true,
        isModel: true,
        dataKey: 'subscriptions',
      },
      purchase: {
        url: 'purchase',
        method: 'POST',
        isArray: true,
        isModel: true,
        dataKey: 'subscriptions',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('subscriptions', {
    model: 'Subscription',
    dataKey: 'subscriptions',
  });
})

/**
 * Model definition
 */
.factory('Subscription', function($baseModel, $api, moment, DateFormat) {

  /**
   * Constructor
   */
  function Subscription(data) {
    $baseModel.call(this, data);

    /**
     * Virtual property for member name to use with label-by
     */
    Object.defineProperty(this, 'memberName', {
      get() {
        if (this.member) {
          return this.member.name;
        }
        return '';
      },
    });

    /**
     * Virtual property for membership name to use with label-by
     */
    Object.defineProperty(this, 'membershipName', {
      get() {
        return this.membership.nameWithSuffix;
      },
    });

    /**
     * Virtual property for membership name to use with label-by
     */
    Object.defineProperty(this, 'membershipNameWithDate', {
      get() {
        const {membershipName, startDate, endDate} = this;
        if (startDate && endDate) {
          const from = startDate.format(DateFormat.formats.standard);
          const to = endDate.format(DateFormat.formats.standard);
          return `${membershipName} (${from} – ${to})`;
        }
        return membershipName;
      },
    });

    /**
     * Virtual property for name and subscription start/end dates
     */
    Object.defineProperty(this, 'memberNameAndDates', {
      get() {
        let label = '';
        if (this.member) {
          label += this.member.name;
          label += ' (';
          label += this.startDate.format(DateFormat.formats.standard);
          label += ' - ';
          if (this.endDate) {
            label += this.endDate.format(DateFormat.formats.standard);
          }
          else {
            label += 'unlimited';
          }
          label += ')';
        }
        return label;
      },
    });

    //Check if current, upcoming or past
    Object.defineProperty(this, 'isCurrent', {
      get() {
        const now = moment();
        const startDate = this.startDate;
        const endDate = this.endDate || now.clone().add(1, 'day');
        return now.isBetween(startDate, endDate, 'day', '[]');
      },
    });
    Object.defineProperty(this, 'isUpcoming', {
      get() {
        const now = moment();
        const startDate = this.startDate;
        return now.isBefore(startDate, 'day');
      },
    });
    Object.defineProperty(this, 'isPast', {
      get() {
        const now = moment();
        const endDate = this.endDate || now.clone().add(1, 'day');
        return now.isAfter(endDate, 'day');
      },
    });

    //Check if has linked subs
    Object.defineProperty(this, 'hasLinked', {
      get() {
        return (this.linked && this.linked.length > 0);
      },
    });

    //Check if we received any benefits
    Object.defineProperty(this, 'hasReceivedBenefits', {
      get() {
        const {accountCreditReceived, couponTypesReceived, eventsAttended} = this;
        return (
          (accountCreditReceived > 0) ||
          (couponTypesReceived && couponTypesReceived.length > 0) ||
          (eventsAttended && eventsAttended.length > 0)
        );
      },
    });

    //Check if halted in any way
    Object.defineProperty(this, 'isHalted', {
      get() {
        return (
          this.isStopped || this.isPaused || this.isChanged || this.isRenewed
        );
      },
    });

    //Check if can sign up to events with this sub
    Object.defineProperty(this, 'canSignUpToEvents', {
      get() {
        return (
          !this.isStopped && !this.isPast &&
          this.membership.canUseForEvents &&
          this.eventsAttended.length < this.membership.numEventsLimit
        );
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Subscription.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Subscription.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('member', 'Member');
    this.convertToModel('membership', 'Membership');
    this.convertToModel('primary', 'RefSubscription');
    this.convertToModel('linked', 'RefSubscription', true);

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Subscription.prototype.toJSON = function(data) {

    //Call parent method
    let json = $baseModel.prototype.toJSON.call(this, data);

    //Strip data
    json.member = $baseModel.onlyId(json.member);
    json.primary = $baseModel.onlyId(json.primary);
    json.membership = $baseModel.onlyId(json.membership);

    //Don't send linked subs in data
    delete json.linked;

    //Return json
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Get the number of days this sub expires in (for current subs)
   */
  Subscription.prototype.expiresIn = function() {

    //No end date?
    if (!this.endDate) {
      return -1;
    }

    //Return days to/since expiration
    return this.endDate.diff(moment(), 'days');
  };

  /**
   * Check if can be stopped by the member
   */
  Subscription.prototype.canBeStopped = function() {

    //Must not be linked
    if (this.isLinked) {
      return false;
    }

    //Must not be halted in any way
    if (this.isHalted) {
      return false;
    }

    //Not expiring
    if (!this.isExpiring) {
      return false;
    }

    //Can be stopped
    return true;
  };

  /**
   * Check if can be changed by the member
   */
  Subscription.prototype.canBeChanged = function() {

    //Can't change if linked or if unlimited
    if (this.isLinked || this.isUnlimited) {
      return false;
    }

    //Must not halted in any way
    if (this.isHalted) {
      return false;
    }

    //Not expiring
    if (!this.isExpiring) {
      return false;
    }

    //Can be changed
    return true;
  };

  /**
   * Check if a subscription can be renewed by the member
   */
  Subscription.prototype.canBeRenewed = function() {

    //Must not be upcoming, linked, or unlimited
    if (this.isUpcoming || this.isLinked || this.isUnlimited) {
      return false;
    }

    //Must not be halted in any way
    if (this.isHalted) {
      return false;
    }

    //No longer linked to existing membership
    if (!this.membership.id) {
      return false;
    }

    //Not renewable or not expiring
    if (!this.membership.isRenewable || !this.isExpiring) {
      return false;
    }

    //Can be renewed
    return true;
  };

  /**
   * Check if can be auto renewed
   */
  Subscription.prototype.canAutoRenew = function() {

    //Must not be past, unlimited or linked
    if (this.isPast || this.isUnlimited || this.isLinked) {
      return false;
    }

    //Must not be halted in any way
    if (this.isHalted) {
      return false;
    }

    //Not auto renewable
    if (!this.membership.autoRenewal.isEnabled) {
      return false;
    }

    //Can be auto renewed
    return true;
  };

  /**
   * Check if a subscription can be edited by an admin
   */
  Subscription.prototype.canBeEditedByAdmin = function() {

    //Can't renew if linked
    if (this.isLinked) {
      return false;
    }

    //All good
    return true;
  };

  /**
   * Check if a subscription can be renewed by an admin
   */
  Subscription.prototype.canBeRenewedByAdmin = function() {

    //Must not be upcoming and not unlimited
    if (this.isUpcoming || this.isUnlimited) {
      return false;
    }

    //Can't renew if paused, renewed or changed
    if (this.isPaused || this.isRenewed || this.isChanged) {
      return false;
    }

    //Can't renew if linked
    if (this.isLinked) {
      return false;
    }

    //Can be renewed by an admin
    return true;
  };

  /**
   * Check if can be stopped by admin
   */
  Subscription.prototype.canBeStoppedByAdmin = function() {

    //Must not linked (can be past but not upcoming)
    if (this.isLinked || this.isUpcoming) {
      return false;
    }

    //Can be stopped
    return true;
  };

  /**
   * Check if can be paused
   */
  Subscription.prototype.canBePausedByAdmin = function() {

    //Must be current and not linked
    if (!this.isCurrent || this.isLinked) {
      return false;
    }

    //Can not be halted in any way
    if (this.isHalted) {
      return false;
    }

    //Can be paused
    return true;
  };

  /**
   * Check if subscription is current for a given date
   */
  Subscription.prototype.isCurrentForDate = function(date) {
    const startDate = this.startDate;
    const endDate = this.endDate || date.clone().add(1, 'day');
    return date.isBetween(startDate, endDate, 'day', '[]');
  };
  /**
   * Save
   */
  Subscription.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.subscription[method](data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Update
   */
  Subscription.prototype.update = function(data) {

    //Append essential data
    data.id = this.id;
    data.club = this.club;

    //Membership only ID
    data.membership = $baseModel.onlyId(data.membership);

    //Determine method and call API
    return $api.subscription.update(data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  Subscription.prototype.patch = function(data) {
    const {id} = this;
    return $api.subscription
      .patch({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Subscription.prototype.delete = function() {
    return $api.subscription
      .delete(null, this)
      .then(() => this);
  };

  /**
   * Stop
   */
  Subscription.prototype.stop = function(endDate) {
    const {id} = this;
    const asAdmin = true;
    return $api.subscription
      .stop({id}, {endDate, asAdmin})
      .then(data => this.fromJSON(data));
  };

  /**
   * Stop own
   */
  Subscription.prototype.stopOwn = function(stopReason) {
    const {id} = this;
    return $api.subscription
      .stop({id}, {stopReason})
      .then(data => this.fromJSON(data));
  };

  /**
   * Pause
   */
  Subscription.prototype.pause = function(endDate, startDate) {
    const {id} = this;
    const asAdmin = true;
    return $api.subscription
      .pause({id}, {endDate, startDate, asAdmin});
  };

  /**
   * Renew (for a member, by an admin)
   */
  Subscription.prototype.renew = function(data) {
    const {id} = this;
    data.asAdmin = true;
    return $api.subscription
      .renew({id}, data)
      .then(data => {
        this.isRenewed = true;
        return new Subscription(data);
      });
  };

  /**
   * Renew own subscription (without payment)
   */
  Subscription.prototype.renewOwn = function() {
    return $api.subscription
      .renew({id: this.id});
  };

  /**
   * Change own subscription (without payment)
   */
  Subscription.prototype.changeOwn = function(changes) {
    return $api.subscription
      .change({id: this.id}, {changes});
  };

  /**
   * Toggle auto renew
   */
  Subscription.prototype.toggleAutoRenew = function(autoRenews) {
    if (typeof autoRenews === 'undefined') {
      autoRenews = !this.autoRenews;
    }
    return this.patch({autoRenews});
  };

  /**
   * Get new subscription data for renewing a sub as admin
   */
  Subscription.prototype.getRenewData = function(startDate, endDate) {
    const {id: subId} = this;
    const action = 'renew';
    const asAdmin = true;
    return $api.subscription
      .getData({action, subId}, {startDate, endDate, asAdmin});
  };

  /**
   * Get new subscription data for renewing a sub as member
   */
  Subscription.prototype.getRenewOwnData = function() {
    const {id: subId} = this;
    const action = 'renew';
    return $api.subscription
      .getData({action, subId}, {});
  };

  /**
   * Get new subscription data for changing a sub as member
   */
  Subscription.prototype.getChangeOwnData = function(changes) {
    const {id: subId} = this;
    const action = 'change';
    return $api.subscription
      .getData({action, subId}, {changes});
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query subscriptions
   */
  Subscription.query = function(filter) {
    return $api.subscription
      .query(filter)
      .then(data => data.subscriptions);
  };

  /**
   * Query own subscriptions
   */
  Subscription.own = function(filter) {
    return $api.subscription
      .own(filter)
      .then(data => data.subscriptions);
  };

  /**
   * Get new subscription data for creating a new sub as admin
   */
  Subscription.getCreateData = function(membership, member, startDate, endDate) {
    const action = 'create';
    const asAdmin = true;
    return $api.subscription
      .getData({action}, {membership, member, startDate, endDate, asAdmin});
  };

  /**
   * Get new subscription data for purchasing a sub as member
   */
  Subscription.getPurchaseData = function(raw) {

    //Parse selection
    const selection = this.parseSelection(raw);

    //Get data
    const action = 'purchase';
    return $api.subscription
      .getData({action}, {selection});
  };

  /**
   * Purchase a selection of subscriptions (paying later)
   */
  Subscription.purchase = function(raw) {
    const selection = this.parseSelection(raw);
    return $api.subscription
      .purchase({selection})
      .then(data => data.subscriptions);
  };

  /**
   * Parse selection to contain only ID's
   */
  Subscription.parseSelection = function(selection) {
    return selection.map(item => ({
      member: $baseModel.onlyId(item.member),
      membership: $baseModel.onlyId(item.membership),
      linked: item.linked.map(other => ({
        member: $baseModel.onlyId(other.member),
        membership: $baseModel.onlyId(other.membership),
      })),
    }));
  };

  /**
   * Get new subscription data for registration (just for one membership)
   */
  Subscription.getRegisterData = function(membership, age, address) {
    const action = 'create';
    const member = {age, address};
    return $api.subscription
      .getData({action}, {membership, member});
  };

  /**
   * Find by ID
   */
  Subscription.findById = function(id) {
    return $api.subscription.get({id});
  };

  /**
   * Mute subscriptions
   */
  Subscription.mute = function(subscriptions) {
    const subscriptionIds = subscriptions.map(sub => sub.id);
    return $api.subscription.mute({subscriptionIds});
  };

  //Return
  return Subscription;
});
