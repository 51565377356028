
/**
 * Module definition and dependencies
 */
angular.module('Shared.MemberGroup.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('memberGroup', {
    model: 'MemberGroup',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'memberGroups',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('memberGroups', {
    model: 'MemberGroup',
    dataKey: 'memberGroups',
    cacheEmpty: true,
  });
})

/**
 * Model definition
 */
.factory('MemberGroup', function($api, $store, $baseModel) {

  /**
   * Constructor
   */
  function MemberGroup(data) {
    $baseModel.call(this, data);

    /**
   * Total number of rules
   */
    Object.defineProperty(this, 'numRules', {
      get() {
        if (!this.ruleSets.length) {
          return null;
        }
        return this.ruleSets
          .reduce((num, ruleSet) => num + ruleSet.rules.length, 0);
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(MemberGroup.prototype, $baseModel.prototype);

  /**
   * Copy method
   */
  MemberGroup.prototype.copy = function() {
    const {
      name, color, isDynamic, ruleSets,
    } = this;
    return new MemberGroup({
      name, color, isDynamic, ruleSets,
    });
  };

  /**
   * Set store reference
   */
  MemberGroup.store = $store.memberGroups;

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save new item
   */
  MemberGroup.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    return $api.memberGroup.create(data).then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  MemberGroup.prototype.patch = function(data) {
    const {id} = this;
    return $api.memberGroup
      .patch({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  MemberGroup.prototype.delete = function() {
    return $api.memberGroup.delete(null, this).then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  MemberGroup.query = function(filter) {
    return $api.memberGroup.query(filter);
  };

  /**
   * Find by ID
   */
  MemberGroup.findById = function(id) {
    return $api.memberGroup.get({id});
  };

  //Return
  return MemberGroup;
});
