
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.AddPaymentSource.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addPaymentSource', {
    templateUrl: 'admin/people/members/modals/add-payment-source.html',
    controller: 'ModalAddPaymentSourceCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalAddPaymentSourceCtrl', function(
  $controller, $modalInstance, $stripe, $timeout, Integration, PaymentMethods
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Initialize flags
    this.isSaving = false;
    this.isLoading = true;
    this.hasEnteredCard = false;
    this.paymentMethod = PaymentMethods.find(m => m.value === this.method);

    //Find integration and mount card element
    Integration
      .findByType(this.method)
      .then(integration => this.integration = integration)
      .then(() => this.mountCardElement())
      .finally(() => this.isLoading = false);
  };

  /**
   * Mount card element
   */
  this.mountCardElement = function() {

    //Wrap to capture errors as card errors
    try {

      //Get stripe service and create card
      const {publicKey} = this.integration.data;
      const stripe = $stripe.service(publicKey);
      const elements = stripe.elements();
      const card = elements.create('card');

      //Set stripe service for further use
      this.stripe = stripe;

      //Apply listener
      card.addEventListener('change', ({error, complete}) => {
        $timeout(() => {
          this.hasEnteredCard = complete;
          this.cardError = error ? error.message : '';
        });
      });

      //Mount element
      $timeout(() => card.mount('#CardElement'));

      //Store card element
      this.cardElement = card;
    }
    catch (error) {
      this.cardError = error.message;
    }
  };

  /**
   * Get card source
   */
  this.getCardSource = async function() {

    //Generate source
    try {
      const {paymentMethod: source, error} = await this.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.cardElement,
        });

      //Error
      if (error) {
        throw error;
      }

      //Return source
      return source;
    }
    catch (error) {

      //Capture exceptions
      if (error instanceof Error) {
        Sentry.captureException(error);
      }

      //Set card error
      this.cardError = error.message;
      throw error;
    }
  };

  /**
   * Confirm setup intent
   */
  this.confirmSetupIntent = async function(data) {

    //Confirm card setup
    try {
      const {clientSecret, sourceId} = data;
      const {setupIntent, error} = await this.stripe
        .confirmCardSetup(clientSecret, {
          payment_method: sourceId,
        });

      //Error
      if (error) {
        this.hasFailedAuthentication = true;
        this.retryData = data;
        throw error;
      }

      //Return setup intent
      return setupIntent;
    }
    catch (error) {

      //Capture exceptions
      if (error instanceof Error) {
        Sentry.captureException(error);
      }

      //Set card error
      throw error;
    }
  };

  /**
   * Save
   */
  this.save = function() {

    //Already saving
    if (this.isSaving) {
      return;
    }

    //Not entered card
    if (!this.hasEnteredCard) {
      return;
    }

    //Saving
    this.error = null;
    this.isSaving = true;

    //If we already have added the card, simply try to confirm the setup intent again
    if (this.hasFailedAuthentication) {
      return this
        .confirmSetupIntent(this.retryData)
        .then(() => $modalInstance.resolve())
        .catch(error => $timeout(() => this.error = error))
        .finally(() => $timeout(() => this.isSaving = false));
    }

    //Get card source
    this
      .getCardSource()
      .then(source => this.handler(source))
      .then(data => {
        const {meta} = data;
        delete data.meta;
        this.check();
        return this.confirmSetupIntent(meta);
      })
      .then(() => $modalInstance.resolve())
      .catch(error => $timeout(() => this.error = error))
      .finally(() => $timeout(() => this.isSaving = false));
  };
});
