
/**
 * Module definition and dependencies
 */
angular.module('Shared.System.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('system', {
    actions: {
      get: {
        url: 'own',
        method: 'GET',
        isModel: true,
        model: 'System',
        withCredentials: true,
      },
      patch: {
        url: 'own/:property',
        method: 'PATCH',
      },
      getState: {
        url: 'own/state',
        method: 'GET',
        withCredentials: true,
      },
      overrideLights: {
        url: 'overrideLights/:eventId',
        method: 'PUT',
      },
      overrideDoors: {
        url: 'overrideDoors/:eventId',
        method: 'PUT',
      },
      sync: {
        url: 'sync',
        method: 'GET',
      },
      purge: {
        url: 'purge',
        method: 'GET',
      },
      refresh: {
        url: 'refresh',
        method: 'GET',
      },
      reboot: {
        url: 'reboot',
        method: 'GET',
      },
      update: {
        url: 'update',
        method: 'POST',
      },
      restartController: {
        url: 'restartController',
        method: 'GET',
      },
      restartRemoteAccess: {
        url: 'restartRemoteAccess',
        method: 'POST',
      },
      installRemoteAccess: {
        url: 'installRemoteAccess',
        method: 'POST',
      },
      bulkAccessRulesUpdate: {
        url: 'bulkAccessRulesUpdate',
        method: 'POST',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('system', {
    model: 'System',
    service: '$instanceStore',
    cacheEmpty: true,
  });
})

/**
 * Model definition
 */
.factory('System', function($baseModel, $api) {

  /**
   * Constructor
   */
  function System(data) {

    //Parent constructor
    $baseModel.call(this, data);

    //Helper properties to determine functionality of system
    Object.defineProperty(this, 'isLimited', {
      get() {
        const {type} = this;
        const types = ['he600'];
        return types.includes(type);
      },
    });
    Object.defineProperty(this, 'isAdvanced', {
      get() {
        const {type} = this;
        const types = ['ict', 'dynalite'];
        return types.includes(type);
      },
    });

    //Helper properties to determine access control systems
    Object.defineProperty(this, 'hasPin', {
      get() {
        return this.tagTypes.includes('pin');
      },
    });
    Object.defineProperty(this, 'hasNfc', {
      get() {
        return this.tagTypes.includes('nfc');
      },
    });
    Object.defineProperty(this, 'hasCard', {
      get() {
        return this.tagTypes.includes('card');
      },
    });
    Object.defineProperty(this, 'hasTag', {
      get() {
        return this.tagTypes.includes('tag');
      },
    });
    Object.defineProperty(this, 'hasIButton', {
      get() {
        return this.tagTypes.includes('ibutton');
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(System.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  System.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('access', 'Access', true);
    this.convertToModel('doors', 'Door', true);
    this.convertToModel('tagTypes', null, true);

    //Return self
    return this;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Helper to apply area states
   */
  System.prototype.applyAreaStates = function(areas, states) {

    //Only if areas given
    if (areas) {
      areas.forEach(area => {
        area.setState(states.find(item => item.area === area.id));
      });
    }

    //Pass on states for promise chain
    return states;
  };

  /**
   * Helper to apply door states
   */
  System.prototype.applyDoorStates = function(doors, states) {

    //Only if doors given
    if (doors) {
      doors.forEach(door => {
        door.setState(states.find(item => item.door === door.id));
      });
    }

    //Pass on states for promise chain
    return states;
  };

  /**
   * Get state
   */
  System.prototype.getState = function() {
    return $api.system.getState();
  };

  /**
   * Get area states
   */
  System.prototype.getAreaStates = function() {
    return $api.system
      .getState()
      .then(state => state.areaStates);
  };

  /**
   * Get door states
   */
  System.prototype.getDoorStates = function() {
    return $api.system
      .getState()
      .then(state => state.doorStates);
  };

  /**
   * Override area lights
   */
  System.prototype.overrideLights = function(overrides, eventId) {
    return $api.system
      .overrideLights({eventId}, {overrides});
  };

  /**
   * Override doors
   */
  System.prototype.overrideDoors = function(overrides, eventId) {
    return $api.system
      .overrideDoors({eventId}, {overrides});
  };

  /**
   * Sync data
   */
  System.prototype.sync = function() {
    return $api.system.sync();
  };

  /**
   * Purge data
   */
  System.prototype.purge = function() {
    return $api.system.purge();
  };

  /**
   * Refresh screen
   */
  System.prototype.refresh = function() {
    return $api.system.refresh();
  };

  /**
   * Reboot system
   */
  System.prototype.reboot = function() {
    return $api.system.reboot();
  };

  /**
   * Update system
   */
  System.prototype.update = function() {
    return $api.system.update();
  };

  /**
   * Restart controller
   */
  System.prototype.restartController = function() {
    return $api.system.restartController();
  };

  /**
   * Restart remote access services
   */
  System.prototype.restartRemoteAccess = function(service) {
    return $api.system.restartRemoteAccess({service});
  };

  /**
   * Install remote access
   */
  System.prototype.installRemoteAccess = function(service) {
    return $api.system.installRemoteAccess({service});
  };

  /**
   * Bulk access rules update
   */
  System.prototype.bulkAccessRulesUpdate = function(data) {
    return $api.system
      .bulkAccessRulesUpdate(data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  System.prototype.patch = function(data) {
    return $api.system
      .patch(null, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Check if we have a tag type
   */
  System.prototype.hasTagType = function(type) {
    return this.tagTypes.includes(type);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Helper to get own system
   */
  System.get = function() {
    return $api.system.get();
  };

  //Return
  return System;
});
