
/**
 * Module definition and dependencies
 */
angular.module('Shared.Currency.Filter', [])

/**
 * Decorator
 */
.config($provide => {
  $provide.decorator('currencyFilter', function($delegate) {

    //Default currency symbol
    let defaultSymbol = '$';

    //Currency filter
    const currencyFilter = function(...args) {
      if (args.length === 1) {
        args.push(defaultSymbol);
      }
      if (args[1] === 'default') {
        args[1] = defaultSymbol;
      }
      return $delegate(...args);
    };

    //Helper to set default currency symbol
    currencyFilter.setDefaultSymbol = function(symbol) {
      defaultSymbol = symbol;
    };

    //Return filter
    return currencyFilter;
  });
});
