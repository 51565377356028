/**
 * Module definition and dependencies
 */
angular.module('App.Account.SelectTransactions.Card', [])

/**
 * Component
 */
.component('cardAccountSelectTransactions', {
  templateUrl: 'account/cards/select-transactions.html',
  controller: 'CardAccountSelectTransactionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    user: '<',
    transactions: '<',
    circleMembers: '<',
    hasSelectedTransactions: '<',
    onToggle: '&',
    onNext: '&',
    onCancel: '&',
  },
})

/**
 * Controller
 */
.controller('CardAccountSelectTransactionsCtrl', function(
  $modal, $notice, $storage, Transaction
) {

  /**
   * On init
   */
  this.$onInit = function() {
    this.checkHasTransactions();
    this.checkNeedsApproval();
    this.buildMemberTransactions();
  };

  /**
   * Check if we have any transactions
   */
  this.checkHasTransactions = function() {
    this.hasTransactions = (this.transactions.length > 0);
  };

  /**
   * Check if we need approval
   */
  this.checkNeedsApproval = function() {

    //No need
    if (!this.user.billing.requires2ndSignatory) {
      return this.needsApproval = false;
    }

    //Check if needed
    this.needsApproval = this.transactions
      .filter(trx => trx.member.id === this.user.id)
      .some(trx => !trx.approval || !trx.approval.isReceived);
  };

  /**
   * Build member transactions array
   */
  this.buildMemberTransactions = function() {
    const {transactions} = this;
    this.memberTransactions = this.circleMembers
      .map(member => ({
        member,
        transactions: transactions.filter(trx => trx.member.id === member.id),
      }))
      .filter(item => item.transactions.length > 0);
  };

  /**
   * Toggle transactions
   */
  this.toggleTransaction = function(transaction) {
    this.onToggle({$event: {transaction}});
    this.checkNeedsApproval();
  };

  /**
   * Next step
   */
  this.next = function() {
    this.onNext();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };

  /**
   * Request approval
   */
  this.requestApproval = function() {

    //Doesn't need approval
    if (!this.needsApproval) {
      return;
    }

    //Get relevant transactions
    const transactions = this.transactions
      .filter(trx => trx.isToggled && trx.member.id === this.user.id)
      .filter(trx => !trx.approval || !trx.approval.isReceived);

    //Initialize model
    const model = {
      name: $storage.get('2ndSignatoryName'),
      email: $storage.get('2ndSignatoryEmail'),
    };

    //Create handler
    const handler = (model) => {
      const {name, email} = model;
      $storage.set('2ndSignatoryName', name);
      $storage.set('2ndSignatoryEmail', email);
      return Transaction.requestApproval(transactions, name, email);
    };

    //Open modal
    $modal
      .open('basic', {
        templateUrl: 'account/modals/request-approval.html',
        locals: {model, handler},
      })
      .result
      .then(approval => {
        $notice.show('Approval requested');
        for (const trx of transactions) {
          trx.approval = approval;
        }
      });
  };
});
