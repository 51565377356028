
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.View.Controller', [])

/**
 * Controller
 */
.controller('BookingViewCtrl', function(
  $interval, $notice, $q, $store, $state, $modal, $document, $log, $window,
  $storage, $timeout, $location, moment, Settings, TimeRange, Booking, Auth,
  ScrollPosition, Modules
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize properties
    this.showEntireDay = false;
    this.refreshRate = 120;
    this.startDate = Settings.get('booking.startDate');
    this.hasMultipleActivities = (this.activities.length > 1);
    this.canBook = true;
    this.loadOps = [];
    this.status = null;
    this.hasLoadedData = false;
    this.isShowingNames = $storage.get('booking.showNames', false);
    this.isShowingCurrent = $storage.get(
      'booking.showCurrent', Modules.has('system')
    );

    //User permissions
    this.canManageBookings = this.user && this.user.hasRole(
      'admin', 'eventManager'
    );
    this.canViewMembers = this.user && this.user.hasRole(
      'admin', 'viewer'
    );
    this.canViewFurtherInPast = this.user && this.user.hasRole(
      'admin', 'eventManager', 'viewer'
    );

    //Default activity
    this.defaultActivity = this.activities.find(activity => activity.isDefault);

    //Initialize visible area
    this.visibleArea = 1;

    //Determine initial dates and validate filter
    this.setDates();

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Determine info based on filter
    this.determineActivityInfo();
    this.determineDateInfo();
    this.determineTimeRange();

    //Update URL and load initial data
    this.updateUrl();
    this.loadData();
    this.preloadFutureData();

    //View existing booking if present
    if (this.booking) {
      this.viewBooking({booking: this.booking});
    }

    //Check if still on today and refresh data periodically
    this.interval = $interval(() => {
      this.checkToday();
      this.determineTimeRange();
      this.loadData();
      this.preloadFutureData();
    }, this.refreshRate * 1000);

    //Bind
    this.boundPositioningCheck = this.checkPositioning.bind(this);

    //Apply scroll handler
    angular.element($window).on('scroll resize', this.boundPositioningCheck);
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {

    //Cancel interval
    $interval.cancel(this.interval);

    //Cancel preloads
    this.cancelPreloads();

    //Remove change handler
    this.filter.offChange();

    //Remove scroll handler
    angular.element($window).off('scroll resize', this.boundPositioningCheck);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    $timeout(() => this.checkPositioning());
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get data
    const {page, module, user, system} = this;
    const {title} = module;

    //Find activity
    const params = this.transition.params();
    const activity = this.activities
      .find(a => (a.identifier === params.activity && a.isBookable));

    //Determine if can override lights or doors
    const {canOverrideDoors, canOverrideLights} = user;

    //Set page title and crumb
    page.setTitle(title);
    page.addCrumb({sref: 'bookings'});

    //Activity found
    if (activity) {
      page.addCrumb({sref: 'bookings', params, title: activity.name});
    }

    //Doors and lights
    if (system && system.hasLightControl && canOverrideLights) {
      page.addOption('overrideLights');
    }
    if (system && system.hasDoorControl && canOverrideDoors) {
      page.addOption('overrideDoors');
    }
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get data
    const {filter, minDate, maxDate, today, defaultActivity, booking} = this;

    //Find activity
    const params = this.transition.params();
    const activity = this.activities
      .find(a => (a.identifier === params.activity && a.isBookable));

    //Set defaults
    filter.setDefaults({
      activity: defaultActivity.id,
      date: moment().startOf('day'),
    });

    //Activity found
    if (activity) {
      filter.update('activity', activity.id);
    }

    //Set initial date in filter
    filter.update('date', moment(params.date, 'YYYY-MM-DD').startOf('day'));

    //Viewing existing booking? Make sure activity/date match
    if (params.bookingId && booking) {
      filter.update('activity', booking.activity.id);
      filter.update('date', booking.startDate.clone().startOf('day'));
    }

    //Validate filter values
    if (!filter.activity || !this.findActivity(filter.activity)) {
      filter.update('activity', defaultActivity.id);
    }
    if (!filter.date || !filter.date.isValid()) {
      filter.update('date', today.clone());
    }
    else if (minDate.isAfter(maxDate)) {
      filter.update('date', minDate.clone().startOf('day'));
    }
    else if (filter.date.isBefore(minDate)) {
      filter.update('date', minDate.clone().startOf('day'));
    }
    else if (filter.date.isAfter(maxDate)) {
      filter.update('date', maxDate.clone().startOf('day'));
    }

    //Filter on change handler
    filter.onChange(key => {

      //No key
      if (!key) {
        return;
      }

      //Changed activity
      if (key === 'activity') {
        this.determineActivityInfo();
      }

      //Changed date
      if (key === 'date') {
        this.determineDateInfo();
        this.determineActivityInfo();
      }

      //Changed activity or date
      this.determineTimeRange();
      this.updateUrl();
      this.clearData();
      this.loadData();
      this.preloadFutureData();
    });
  };

  /**
   * Check position
   */
  this.checkPositioning = function() {

    //Find elements if needed
    if (!this.$header) {
      this.$header = angular
        .element($document[0].getElementById('FloatingBookingHeader'));
    }

    //Determine offset
    let offset = 0;
    if ($window.innerWidth >= 1280) {
      offset = 3 * 16;
    }
    else if ($window.innerWidth >= 768) {
      offset = 2 * 16;
    }

    //Set scroll flag
    //NOTE: Using add/remove class to avoid $timeout, as that makes it choppy
    if ($window.pageYOffset && $window.pageYOffset >= offset) {
      this.$header.addClass('is-visible');
    }
    else {
      this.$header.removeClass('is-visible');
    }
  };

  /**************************************************************************
   * Date navigation
   ***/

  /**
   * Show date picker
   */
  this.showDatePicker = function() {

    //Get data
    const date = this.date;
    const {minDate, maxDate} = this;
    const canClear = false;

    //Open calendar modal
    $modal
      .open('pickDate', {locals: {date, minDate, maxDate, canClear}})
      .result
      .then(date => {
        this.filter.update('date', date.startOf('day'));
      });
  };

  /**
   * Today
   */
  this.goToday = function() {
    this.filter.update('date', moment().startOf('day'));
  };

  /**
   * Next day
   */
  this.nextDay = function() {

    //Check if allowed
    if (this.isMaxDate) {
      return;
    }

    //Don't show the entire day
    this.showEntireDay = false;

    //Update filter
    this.filter.update('date', this.date.clone().add(1, 'day'));
  };

  /**
   * Previous day
   */
  this.previousDay = function() {

    //If we're on today and not showing the entire day, first show entire day
    if (this.isToday && !this.showEntireDay) {
      this.showEntireDay = true;
      this.canGoBack = !this.isMinDate;
      this.determineTimeRange();
      return;
    }

    //Min date, can't go back further
    if (this.isMinDate) {
      return;
    }

    //Otherwise, browse back
    this.showEntireDay = false;
    this.filter.update('date', this.date.clone().subtract(1, 'day'));
  };

  /**************************************************************************
   * Area navigation
   ***/

  /**
   * Next area
   */
  this.nextArea = function() {
    if (this.visibleArea < this.activityAreas.length) {
      this.visibleArea++;
      this.hasNextArea = (this.visibleArea < this.activityAreas.length);
    }
  };

  /**
   * Previous area
   */
  this.previousArea = function() {
    if (this.visibleArea > 1) {
      this.visibleArea--;
      this.hasPreviousArea = (this.visibleArea > 1);
    }
  };

  /**************************************************************************
   * Events and bookings handling
   ***/

  /**
   * View status
   */
  this.viewStatus = function($event) {

    //Get data
    const {area, booking} = $event;
    const {canManageBookings, canViewMembers} = this;
    const members = area.getMembers();
    const serviceTags = area.getServiceTags();
    const mode = this.findMode(area.mode);

    //Show modal
    $modal.open('basic', {
      templateUrl: 'booking/modals/view-status.html',
      locals: {
        area, members, serviceTags, booking, mode,
        canManageBookings, canViewMembers,
      },
    });
  };

  /**
   * View booking
   */
  this.viewBooking = function($event) {

    //Get data
    const {booking} = $event;
    const {user, activity} = this;

    //Open modal
    $modal
      .open('viewBooking', {locals: {booking, user, activity}})
      .result
      .then(() => this.loadBookings(true));
  };

  /**
   * Create booking
   */
  this.createBooking = function($event) {

    //Get data
    const {area, time, duration} = $event;
    const {club, user, activity, canBook} = this;
    const startDate = this.date.clone().setTime(time);
    const endDate = startDate.clone().add(duration, 'minutes');

    //No activity modes?
    if (activity.modes.length === 0) {
      $log.warn('No activity modes');
      return;
    }

    //Need to login to make a booking?
    if (!canBook) {
      return $modal
        .open('basic', {
          templateUrl: 'booking/modals/login-to-book.html',
        })
        .result
        .then(() => Auth.goToLoginState('bookings'));
    }

    //If user is suspended, can't make booking
    if (user && user.isSuspended) {
      return $modal.open('basic', {
        templateUrl: 'modals/suspended.html',
        locals: {user},
      });
    }

    //Get additional data
    const hasAllowedModes = user && user.hasAllowedModes(activity.id);
    const memberships = user ? user.getCurrentAndUpcomingMemberships() : [];
    const restrictions = activity.findRestrictions('bookingTimes', memberships);

    //Filter available modes
    const modes = activity.modes.filter(mode => {

      //Mode is allowed with others
      if (mode.allowOthers) {
        return true;
      }

      //Show all for kiosk mode, as we can only know the allowed modes
      //when we know the members
      if (!user || user.hasRole('admin', 'eventManager')) {
        return true;
      }

      //No member role?
      if (!user.hasRole('member')) {
        return false;
      }

      //Check specific allowed modes first if needed
      //NOTE: If a member doesn't have a membership, they might still have
      //allowed modes, so this needs to be handled because it might not be desirable
      if (hasAllowedModes) {
        return user.isAllowedMode(mode);
      }

      //Check restrictions
      return restrictions
        .some(r => r.modes.some(modeId => modeId === mode.id));
    });

    //No modes
    if (modes.length === 0) {
      return $modal.open('basic', {
        templateUrl: 'booking/modals/booking-not-allowed.html',
      });
    }

    //Initialize members for booking
    const members = [];
    if (user) {
      members.push(user);
    }

    //Create new booking instance
    const booking = new Booking({members, area, activity, startDate, endDate});

    //Open modal
    $modal
      .open('createBooking', {locals: {booking, club, user, activity, modes}})
      .result
      .then(booking => {

        //Show tags created for this booking and reload bookings
        this.showTags(booking);
        this.loadBookings(true);
      });
  };

  /**
   * Waiting list
   */
  this.waitingList = function($event) {

    //Extract data
    const {time} = $event;
    const {bookings, events, activity, user} = this;
    const {duration} = activity;
    const date = this.date.clone().setTime(time);

    //No user?
    if (!user) {
      return;
    }

    //If user is suspended, can't go on waiting list
    if (user && user.isSuspended) {
      return $modal.open('basic', {
        templateUrl: 'modals/suspended.html',
        locals: {user},
      });
    }

    //Any other area that is valid and available?
    const area = this.areas
      .filter(area => area.activity === activity.id)
      .filter(area => area.isValidTime(date, time, duration))
      .find(area => {
        const hasBooking = bookings
          .some(booking => booking.hasArea(area) && booking.isClashing(time));
        const hasEvent = events
          .some(event => event.hasArea(area) && event.isClashing(time));
        return (!hasBooking && !hasEvent);
      });

    //Anything available?
    if (area) {
      return $modal.open('basic', {
        templateUrl: 'booking/modals/area-available.html',
        locals: {area, activity, date},
      });
    }

    //Open modal
    $modal.open('waitingList', {locals: {user, activity, date}});
  };

  /**
   * View waiting list as admin
   */
  this.viewWaitingList = function() {

    //Not an admin
    if (!this.canManageBookings) {
      return;
    }

    //Get data
    const {activity} = this;
    const date = this.date.clone();

    //Open modal
    $modal.open('viewWaitingList', {locals: {activity, date}});
  };

  /**
   * Go to calendar
   */
  this.goToCalendar = function() {
    $state.go('booking.weekly', $state.params);
  };

  /**************************************************************************
   * Data handling
   ***/

  /**
   * Load events only
   */
  this.loadEvents = function() {

    //Create query filter
    const filter = this.getQueryFilter(this.date);

    //Query events
    return $store.events
      .query(filter)
      .then(events => {

        //If the filter has since changed, ignore this response
        if (this.hasFilterChanged(filter)) {
          return;
        }

        //Set events as copy to force changes
        return this.events = events.map(e => e);
      });
  };

  /**
   * Load bookings only
   */
  this.loadBookings = function(refresh = false) {

    //Create query filter
    const filter = this.getQueryFilter(this.date);

    //Query bookings
    return $store.bookings
      .query(filter, refresh)
      .then(bookings => {

        //If the filter has since changed, ignore this response
        if (this.hasFilterChanged(filter)) {
          return;
        }

        //Set bookings as copy to force changes
        return this.bookings = bookings.map(b => b);
      });
  };

  /**
   * Load data
   */
  this.loadData = function() {

    //Initialize load
    this.initializeLoad();

    //Clear status so there's no folly going on, e.g. it would show
    //the area status while bookings were loading if you browsed to another
    //day and back, because the area state is remembered on the area objects.
    this.status = null;

    //Load bookings and events for the day
    return $q
      .all([
        this.loadBookings(),
        this.loadEvents(),
      ])
      .then(() => this.hasLoadedData = true)
      .finally(() => this.finalizeLoad())
      .then(() => this.updateAreaStates());
  };

  /**
   * Clear bookings/events data
   */
  this.clearData = function() {
    this.bookings = [];
    this.events = [];
  };

  /**
   * Cancel preload timeouts
   */
  this.cancelPreloads = function() {
    if (this.preloadTimeouts) {
      for (const timeout of this.preloadTimeouts) {
        $timeout.cancel(timeout);
      }
    }
  };

  /**
   * Preload bookings and events for the following days
   */
  this.preloadFutureData = function() {

    //Only for today
    if (!this.isToday) {
      return;
    }

    //Make preload filters
    const preload = this.makePreloadFilters(3);
    let timeout = 2000;

    //Cancel existing preloads
    this.cancelPreloads();
    this.preloadTimeouts = [];

    //Process each filter
    for (const filter of preload) {

      //Setup queries in timeout
      const t = $timeout(() => {
        $store.bookings.query(filter);
        $store.events.query(filter);
      }, timeout);

      //Keep track of them
      this.preloadTimeouts.push(t);

      //Increment timeout for each filter
      timeout += 2000;
    }
  };

  /**************************************************************************
   * Filter helpers
   ***/

  /**
   * Create a query filter
   */
  this.getQueryFilter = function(date) {

    //Get activity
    const activity = this.activity.id;

    //Convert date to from/to dates
    const fromDate = date.clone().startOf('day');
    const toDate = date.clone().endOf('day');

    //Make filter
    return {activity, fromDate, toDate, isRemoved: false};
  };

  /**
   * Get preload filters for a given number of days
   */
  this.makePreloadFilters = function(noDays) {
    const preload = [];
    for (let n = 1; n <= noDays; n++) {
      const date = this.date.clone().add(n, 'days');
      const filter = this.getQueryFilter(date);
      preload.push(filter);
    }
    return preload;
  };

  /**
   * Check if filter has changed compared to a given filter
   */
  this.hasFilterChanged = function(filter) {
    const current = this.getQueryFilter(this.date);
    return (
      current.activity !== filter.activity ||
      !current.fromDate.isSame(filter.fromDate, 'day')
    );
  };

  /**
   * Find activity by ID
   */
  this.findActivity = function(id) {
    return this.activities.find(activity => activity.id === id);
  };

  /**
   * Determine activity info
   */
  this.determineActivityInfo = function() {

    //Find activity by ID
    const activity = this.findActivity(this.filter.activity);
    if (!activity) {
      return;
    }

    //Set activity and find areas
    this.activity = activity;
    this.activityAreas = this.areas
      .filter(area => area.activity === activity.id)
      .filter(area => area.containsDate(this.filter.date));

    //Check if there are area sponsors
    this.hasAreaSponsors = this.activityAreas
      .some(area => !!area.sponsor);
  };

  /**
   * Determine date info
   */
  this.determineDateInfo = function() {

    //Get date from filter
    const {date} = this.filter;

    //Set date and determine flags
    this.date = date;
    this.isToday = date.isSame(this.today, 'day');
    this.isMaxDate = date.isSame(this.maxDate, 'day');
    this.isMinDate = date.isSame(this.minDate, 'day');
    this.canReachToday = this.today.isSameOrAfter(this.minDate);
    this.canGoBack = (this.isToday && !this.showEntireDay) || !this.isMinDate;
  };

  /**
   * Determine time range for given activity and date
   */
  this.determineTimeRange = function() {

    //Get data
    const {date} = this;
    const {duration} = this.activity;
    const minTime = this.getMinTime();

    //Get time ranges from areas
    const timeRanges = this.activityAreas
      .reduce((timeRanges, area) => {
        return timeRanges.concat(area.times);
      }, []);

    //Combine into a single time range for the date
    this.timeRange = TimeRange.combine(timeRanges, date, minTime, duration);
  };

  /**
   * Get min time
   */
  this.getMinTime = function() {

    //Restricted?
    if (!this.showEntireDay && this.isToday) {
      const now = moment();
      return now.hours() * 60 + now.minutes();
    }

    //Not restricted
    return 0;
  };

  /**************************************************************************
   * Paid for booking return handler
   ***/

  /**
   * Process payment status
   */
  this.processPaymentStatus = function() {

    //Get data
    const {paymentId, status} = $location.search();

    //Clear query params
    $location.search('status', null);
    $location.search('paymentId', null);

    //Process error
    if (status && paymentId) {
      $modal.open('basic', {
        templateUrl: `account/modals/payment-${status}.html`,
        locals: {paymentId},
      }, true);
    }
  };

  /**************************************************************************
   * Other helpers
   ***/

  /**
   * Update area states
   */
  this.updateAreaStates = function() {

    //No system or not on the current day?
    if (!Modules.has('system') || !this.isToday || !this.system) {
      return $q.resolve();
    }

    //Flags
    this.isStatusLoading = true;
    this.isStatusError = false;

    //Get area states
    return this.system
      .getAreaStates()
      .then(states => this.system.applyAreaStates(this.areas, states))
      .then(states => this.status = states)
      .catch(() => this.isStatusError = true)
      .finally(() => this.isStatusLoading = false);
  };

  /**
   * Show tags for a booking
   */
  this.showTags = function(booking) {

    //No tags?
    if (!booking || !booking.tags || booking.tags.length === 0) {
      return;
    }

    //Not paid yet? Don't show tags
    if (booking.needsPayment) {
      return;
    }

    //Load tags
    booking
      .getTags()
      .then(tags => {
        if (tags.length > 0) {
          $modal.open('basic', {
            templateUrl: 'booking/modals/view-tags.html',
            locals: {tags, booking},
          });
        }
      });
  };

  /**
   * Initialize load
   */
  this.initializeLoad = function() {
    $notice.showLoading();
    this.loadOps.push(1);
  };

  /**
   * Finalize load
   */
  this.finalizeLoad = function() {

    //Pop last load op
    if (this.loadOps.length > 0) {
      this.loadOps.pop();
    }

    //Hide notice once all load ops are done
    if (this.loadOps.length === 0) {
      $notice.hideLoading();
    }
  };

  /**
   * Helper to update URL based on current filter
   */
  this.updateUrl = function() {

    //Transition options
    const activity = this.activity.identifier;
    const date = this.date.format('YYYY-MM-DD');
    const {params} = $state;

    //Ignore if the same
    if (params.date === date && params.activity === activity) {
      return;
    }

    //Transition
    $state
      .go($state.current.name, {
        date, activity, bookingId: null,
      }, {location: true})
      .then(() => {

        //Restore scroll position
        ScrollPosition.restore(null, true);

        //Setup page
        this.setupPage();
      });
  };

  /**
   * Set dates
   */
  this.setDates = function() {

    //Get data
    const {club, user} = this;
    const {timezone} = club;

    //Reset flag
    this.hasNoValidPeriod = false;

    //Get today
    this.today = moment().startOf('day');

    //Determine min/max dates
    this.maxDate = this.club.getMaxBookingDate(user, timezone).endOf('day');
    this.minDate = this.today.clone();

    //If admin, we can browse back a bit further
    if (this.canViewFurtherInPast) {
      this.minDate.subtract(3, 'months');
    }

    //Never before the start date
    if (this.startDate && this.minDate.isBefore(this.startDate)) {
      this.minDate = moment(this.startDate).clone();
    }

    //If min date falls behind the max date, we can't make bookings at all
    if (this.maxDate && this.maxDate.isBefore(this.minDate)) {
      this.hasNoValidPeriod = true;
    }
  };

  /**
   * Check if today is no longer today
   */
  this.checkToday = function() {

    //Get now
    const now = moment();
    const time = now.hours() * 60 + now.minutes();

    //If time is beyond the time range end time, advance to the next day
    if (this.isToday && this.timeRange && time > this.timeRange.endTime) {
      this.filter.update('date', now.clone().add(1, 'day').startOf('day'));
    }

    //If today is still the same, nothing needs to be done
    if (now.isSame(this.today, 'day')) {
      return;
    }

    //Set the new dates
    this.setDates();

    //If we are on today, we need to trigger a filter change
    if (this.isToday) {
      this.filter.update('date', this.today.clone());
    }
  };

  /**
   * Toggle names
   */
  this.toggleNames = function() {
    this.isShowingNames = !this.isShowingNames;
    $storage.set('booking.showNames', this.isShowingNames);
  };

  /**
   * Toggle current
   */
  this.toggleCurrent = function() {
    this.isShowingCurrent = !this.isShowingCurrent;
    $storage.set('booking.showCurrent', this.isShowingCurrent);
  };

  /**
   * Find mode in activities
   */
  this.findMode = function(id) {
    if (!id || !this.activities) {
      return null;
    }
    for (const activity of this.activities) {
      for (const mode of activity.modes) {
        if (mode.id === id) {
          return mode;
        }
      }
    }
  };
});
