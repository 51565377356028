
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Fees.Card', [])

/**
 * Component
 */
.component('cardFees', {
  templateUrl: 'admin/activity/cards/fees.html',
  controller: 'CardActivityFeesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    activity: '<',
    memberships: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardActivityFeesCtrl', function($modal, ActivityFee) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flags
    this.isSaving = false;
    this.checkFlags();
  };

  /**
   * Check flags
   */
  this.checkFlags = function() {
    this.hasFees = this.activity.fees.length > 0;
  };

  /**
   * Get the full mode for a given fee
   */
  this.getModeForFee = function(fee) {
    return this.activity.getMode(fee.mode);
  };

  /**
   * Save activity on server
   */
  this.save = function(fees) {

    //Get data
    const {activity} = this;
    const model = {fees};

    //Save activity
    return this
      .onSave({$event: {activity, model, isEdit: true}})
      .then(() => this.checkFlags());
  };

  /**
   * Add fee
   */
  this.add = function() {

    //Create fee and define handler
    const fee = new ActivityFee(null, this.event);
    const {activity} = this;
    const $ctrl = this;

    //Create copy of fees and define handler
    const fees = activity.extract('fees');
    const handler = function(model) {
      fee.fromJSON(model);
      fees.push(fee);
      return $ctrl.save(fees);
    };

    //Show modal
    $modal.open('editActivityFee', {locals: {fee, activity, handler}});
  };

  /**
   * Edit fee
   */
  this.edit = function($event) {

    //Get fee
    const {fee} = $event;
    const {activity} = this;
    const $ctrl = this;

    //Find and validate index
    const index = activity.fees.findIndex(f => f === fee);
    if (index === -1) {
      return;
    }

    //Create copy of fees and define handler
    const fees = activity.extract('fees');
    const handler = function(model) {
      fees[index].fromJSON(model);
      return $ctrl.save(fees);
    };

    //Show modal
    $modal.open('editActivityFee', {
      locals: {fee, activity, handler, isEdit: true},
    });
  };

  /**
   * Delete fee
   */
  this.delete = function($event) {

    //Get fee
    const {fee} = $event;
    const {activity, memberships} = this;
    const $ctrl = this;

    //Find and validate index
    const index = activity.fees.findIndex(f => f === fee);
    if (index === -1) {
      return;
    }

    //Create copy of fees and define handler
    const fees = activity.extract('fees');
    const handler = function() {
      fees.splice(index, 1);
      return $ctrl.save(fees);
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'admin/activity/modals/confirm-delete-fee.html',
        locals: {fee, activity, memberships, handler},
      });
  };
});
