
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.SignupClub.Contact', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('signupClub.contact', {
    url: '',
    component: 'signupContactRoute',
  });
})

/**
 * Route component
 */
.component('signupContactRoute', {
  controller: 'SignupClubContactCtrl',
  templateUrl: 'portal/signup-club/steps/contact.html',
  bindings: {
    club: '<',
  },
})

/**
 * Controller
 */
.controller('SignupClubContactCtrl', function(
  $state, $http, $store, $timeout, $modal, Config,
  ExistsError, RegexChinese, ValidationError
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Remove any club headers for the signup flow
    delete $http.defaults.headers.common['X-Club'];

    //Hello club test
    if ((this.club.isHc || Config.env === 'dev') &&
      !this.club.xeroAppStoreStateId) {
      const i = Math.floor(Math.random() * 100) + 1;
      this.club.contact = {
        firstName: 'Hello',
        lastName: `Tester ${i}`,
        email: `test+${i}@helloclub.com`,
      };
      this.club.contact.password = `test123`;
    }

    //Show username field if different to email
    if (this.club.contact.username &&
       (this.club.contact.email !== this.club.contact.username)
    ) {
      this.showUsernameField = true;
    }

    //Start faded
    this.fade = true;
    $timeout(() => this.fade = false);
  };

  /**
   * Back to previous step
   */
  this.back = function() {
    this.fade = true;
    $timeout(() => {
      //Reset oauth related params
      $state.go('signupClub.oauth', {error: null, provider: null, reason: null});
    }, 200);
  };

  /**
   * Continue
   */
  this.continue = function(isConfirmed = false) {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Block Chinese for now
    const {firstName, lastName} = this.club.contact;
    if (RegexChinese.test(firstName) || RegexChinese.test(lastName)) {
      this.isErrorChinese = true;
      return;
    }

    //Set club email by default to same email address
    if (!this.club.email) {
      this.club.email = this.club.contact.email;
    }

    //Set member username as email
    if (!this.club.contact.username) {
      this.club.contact.username = this.club.contact.email;
    }

    //Flags
    this.isBusy = true;
    this.fade = true;
    this.isErrorUsernameExists = false;

    //Initiate club sign up
    return this.club
      .initiate(isConfirmed)
      .then(() => $state.go('signupClub.type'))
      .catch(error => {

        //Existing member found?
        if (error instanceof ExistsError) {

          //Get data
          const {club, appUrl} = error.data;

          //Show modal
          $modal.open('basic', {
            templateUrl: 'portal/signup-club/modals/member-exists.html',
            locals: {club, appUrl},
            rejectOnDismissal: true,
          })
          .result
          .then(() => this.continue(true))
          .catch(() => $store.club.instance = null);

          //Reset flags
          this.isBusy = false;
          this.fade = false;
          return;
        }

        //Username in use
        else if (error instanceof ValidationError) {
          if (error.data.some(data => data.path === 'username')) {

            //Throw error
            this.showUsernameField = true;
            this.isErrorUsernameExists = true;

            //Reset flags
            this.isBusy = false;
            this.fade = false;
            return;
          }
        }

        //Ignore other errors for now
        else {
          $state.go('signupClub.type');
        }
      });
  };
});
