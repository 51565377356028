
/**
 * Module definition and dependencies
 */
angular.module('Shared.Comments.Card', [])

/**
 * Component
 */
.component('cardComments', {
  templateUrl: 'cards/comments.html',
  controller: 'CardCommentsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    user: '<',
    comments: '<',
    model: '@',
    item: '<',
  },
})

/**
 * Controller
 */
.controller('CardCommentsCtrl', function($modal) {

  /**
   * Delete comment
   */
  this.deleteComment = function($event) {

    //Get comment
    const {comment} = $event;
    const handler = () => comment.delete();

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-delete-comment.html',
        locals: {comment, handler},
      })
      .result
      .then(() => {
        this.comments = this.comments.filter(c => comment.id !== c.id);
      });
  };

  /**
   * Save comment
   */
  this.saveComment = function($event) {

    //Get comment and data
    const {comment} = $event;
    const {model, item} = this;
    const itemId = item.id;

    //Append model and item ID
    Object.assign(comment, {model, itemId});

    //Save comment
    return comment
      .save()
      .then(comment => {
        this.comments.push(comment);
        item.numComments++;
      });
  };
});
