
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.QueryTag.Card', [])

/**
 * Component
 */
.component('cardSystemQueryTag', {
  templateUrl: 'admin/system/cards/query-tag.html',
  controller: 'CardSystemQueryTagCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    system: '<',
  },
})

/**
 * Controller
 */
.controller('CardSystemQueryTagCtrl', function(
  $focus, $timeout, $element, $modal, Tag, TagTypes
) {

  /**
   * Init
   */
  this.$onInit = function() {

    //Flags
    this.isBusy = false;
    this.isError = false;

    //Filter tag types
    this.TagTypes = TagTypes;
    this.tagTypes = TagTypes.filter(type => this.system.hasTagType(type.value));

    //Set default type
    this.type = this.system.tagTypes[0];
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    $timeout(() => {
      $focus($element.find('input')[1]);
    });
  };

  /**
   * Update type
   */
  this.updateType = function(type) {
    this.type = type;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Query tag
   */
  this.queryTag = function() {

    //Set flags
    this.isError = false;

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get number
    const {system, type, number} = this;
    this.isBusy = true;

    //Send command
    Tag
      .findByData({type, number})
      .then(tag => {
        $modal.open('basic', {
          templateUrl: 'admin/system/modals/view-tag.html',
          locals: {tag, system},
        }, true);
        this.form.$setPristine();
      })
      .catch(() => this.isError = true)
      .finally(() => this.isBusy = false);
  };
});
