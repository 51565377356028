
/**
 * Module definition and dependencies
 */
angular.module('Shared.ResourceAssignee.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('ResourceAssignee', function($baseModel, moment) {

  /**
   * Constructor
   */
  function ResourceAssignee(data) {

    //Call parent constructor
    $baseModel.call(this, angular.extend({}, data || {}));

    //Is expired
    Object.defineProperty(this, 'isExpired', {
      get() {
        const {endDate} = this;
        const now = moment();
        return (endDate && now.isAfter(endDate));
      },
    });
  }

  /**
   * Extend prototype
   */
  angular.extend(ResourceAssignee.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  ResourceAssignee.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  ResourceAssignee.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Return json
    return json;
  };

  //Return
  return ResourceAssignee;
});
