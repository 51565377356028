
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.PlanExpiringSoon.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('planExpiringSoon', {
    templateUrl: 'admin/club/modals/plan-expiring-soon.html',
    controller: 'ModalPlanExpiredCtrl',
    closeOnClick: false,
    resolve: {
      club($store) {
        'ngInject';
        return $store.club.get();
      },
    },
  });
});
