
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Report.Membership', [
  'App.Admin.Report.Membership.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.report.membership', {
    url: '/membership',
    component: 'adminReportMembershipRoute',
    params: {
      basePeriod: null,
      compPeriod: null,
    },
    resolve: {
      memberships(Membership) {
        return Membership
          .query({fields: `name,suffix,minAge,maxAge,activities`})
          .then(data => data.memberships);
      },
      activities($store) {
        return $store.activities.query();
      },
      baseData(transition, $api, $q) {
        const {basePeriod: {fromDate, toDate}} = transition.params();
        if (fromDate && toDate) {
          return $api.report.membership({fromDate, toDate});
        }
        return $q.resolve([]);
      },
      compData(transition, $api, $q) {
        const {compPeriod: {fromDate, toDate}} = transition.params();
        if (fromDate && toDate) {
          return $api.report.membership({fromDate, toDate});
        }
        return $q.resolve([]);
      },
    },
  });
})

/**
 * Component
 */
.component('adminReportMembershipRoute', {
  controller: 'AdminReportMembershipCtrl',
  templateUrl: 'admin/report/membership/membership.html',
  bindings: {
    baseData: '<',
    compData: '<',
    page: '<',
    transition: '<',
    memberships: '<',
    activities: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Can't navigate here directly
  $transitions.onBefore({to: 'admin.report.membership'}, transition => {
    const {basePeriod} = transition.params();
    if (!basePeriod) {
      return transition.router.stateService.target('admin.report.overview');
    }
  });

  //Page setup
  $transitions.onSuccess({to: 'admin.report.membership'}, transition => {
    const params = transition.params();
    Page.setTitle(`Memberships report`);
    Page.addCrumb({
      sref: 'admin.report.membership',
      params,
    });
  });
});
