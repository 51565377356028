
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon', [
  'App.Coupon.Overview',
  'App.Coupon.Purchase',
  'App.Coupon.Summary.Card',
  'App.Coupon.List.Card',
  'App.Coupon.Coupon.Card',
  'App.Coupon.Details.Card',
  'App.Coupon.NoPayment.Card',
  'App.Coupon.AgreeConditions.Card',
  'App.Coupon.ViewCoupon.Modal',
  'App.Coupon.UseCouponSession.Modal',
  'App.Coupon.UpdateCouponExpiry.Modal',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('coupon', {
    parent: 'app',
    url: '/coupons',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'coupons',
    },
    resolve: {
      coupons(Coupon) {
        return Coupon.own();
      },
    },
  });
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'coupon.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'coupon.overview', title});
  }, {priority: 10});
});
