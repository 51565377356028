
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.Xero.Accounts.Card', [])

/**
 * Component
 */
.component('cardIntegrationsXeroAccounts', {
  templateUrl: 'admin/integrations/xero/accounts.html',
  controller: 'CardIntegrationsXeroAccountsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    country: '<',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsXeroAccountsCtrl', function(
  $controller, $modal, Xero, XeroAccounts
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.hasSettings = (
      this.integration.data.startDate &&
      this.integration.data.invoiceFrequency
    );

    //Filter relevant accounts
    this.filterAccounts();

    //Setup groups
    this.setupGroups();

    //Load accounts and contacts
    if (this.isConnected) {
      this.loadAccounts();
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Filter accounts
   */
  this.filterAccounts = function() {
    this.filteredXeroAccounts = XeroAccounts
      .filter(account => {
        if (account.value === 'stripe' && !this.country.stripe) {
          return false;
        }
        if (account.value === 'poli' && !this.country.poli) {
          return false;
        }
        return true;
      });
  };

  /**
   * Setup groups
   */
  this.setupGroups = function() {

    //Initialize
    this.groups = [];

    //Setup groups
    for (const account of this.filteredXeroAccounts) {

      //Initialize group
      if (!this.groups.some(group => group.label === account.group)) {
        this.groups.push({label: account.group, accounts: []});
      }

      //Get group and add account
      const group = this.groups.find(group => group.label === account.group);
      group.accounts.push(account);

      //Payments enabled?
      if (account.data.paymentsEnabled) {
        group.paymentsEnabled = true;
      }
      if (account.isClearing) {
        group.isClearing = true;
      }
    }
  };

  /**
   * Load accounts
   */
  this.loadAccounts = function() {

    //Flag
    this.isLoading = true;

    //Get accounts
    Xero
      .getAccounts()
      .then(accounts => this.accounts = accounts)
      .then(() => this.checkAccounts())
      .catch(error => {
        if (error.code === 'NOT_CONNECTED') {
          this.noLongerConnected();
        }
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Create account
   */
  this.createAccount = function(account) {

    //Extract data
    const {data} = account;
    const {code} = data;

    //Check if exists as a valid option
    const validExisting = account.options
      .find(account => Number(account.code) === Number(code));

    //Existing found
    if (validExisting) {
      this.updateAccountMapping(account, code);
      return;
    }

    //Ensure doesn't exist in list of accounts already, possibly as archived
    const existing = this.accounts
      .find(account => Number(account.code) === Number(code));

    //Existing found, but incorrect account type
    if (existing) {
      $modal.open('basic', {
        templateUrl: 'admin/integrations/modals/xero-account-exists.html',
        locals: {account: data, existing},
      });
      return;
    }

    //Flag
    this.isCreating = true;

    //Create account
    Xero
      .createAccount(data)
      .then(account => {

        //Add to accounts and initialise account mappings again
        this.accounts.push(account);
        this.checkAccounts();
      })
      .catch(error => {

        //Token rejected
        if (error.code === 'NOT_CONNECTED') {
          return this.noLongerConnected();
        }

        //Account exists error (archived accounts could exist)
        if (error.name === 'ExistsError') {
          $modal.open('basic', {
            templateUrl: 'admin/integrations/modals/xero-account-exists.html',
            locals: {account: data},
          });
          return;
        }

        //Throw on
        throw error;
      })
      .finally(() => this.isCreating = false);
  };

  /**
   * Check account mappings
   */
  this.checkAccounts = function() {

    //Prepare mapping data
    if (!this.data.accounts) {
      this.data.accounts = {};
    }

    //Loop Xero accounts
    for (const account of this.filteredXeroAccounts) {
      this.checkAccountMapping(account);
    }
  };

  /**
   * Check account mapping
   */
  this.checkAccountMapping = function(account) {

    //Get data
    const {type, value, data} = account;

    //Initialize type container
    if (typeof this.data.accounts[type] === 'undefined') {
      this.data.accounts[type] = {};
    }

    //Create options
    account.options = this.accounts
      .filter(account => account.status === 'ACTIVE')
      .filter(account => account.type.match(data.typeRegex))
      .filter(account => (!data.paymentsEnabled || account.paymentsEnabled))
      .sort((a, b) => Number(a.code) - Number(b.code));

    //Check existing mapping
    const code = this.data.accounts[type][value];

    //Already has a mapping?
    if (code) {

      //Check if valid
      if (this.accounts.some(account => account.code === code)) {
        account.isChoosing = true;
        return;
      }

      //Clear mapping
      delete this.data.accounts[type][value];
    }

    //Create regex for name and find match
    const regex = new RegExp(data.name, 'i');
    const match = account.options
      .find(account => (
        account.code === data.code || account.name.match(regex)
      ));

    //Set in mapping
    if (match) {
      this.updateAccountMapping(account, match.code);
    }
  };

  /**
   * Update account mapping
   */
  this.updateAccountMapping = function(account, code = null) {

    //Get type and value
    const {type, value} = account;

    //Initialize type container
    if (typeof this.data.accounts[type] === 'undefined') {
      this.data.accounts[type] = {};
    }

    //Update in type and flag as choosing
    this.data.accounts[type][value] = code;
    account.isChoosing = true;

    //Update behind the scenes payment method mapping for account credit
    if (type === 'transactionTypes' && value === 'accountCredit') {
      if (typeof this.data.accounts.paymentMethods === 'undefined') {
        this.data.accounts.paymentMethods = {};
      }
      this.data.accounts.paymentMethods.accountCredit = code;
    }
  };

  /**
   * Check if has missing accounts
   */
  this.checkMissingAccounts = function() {
    return this.filteredXeroAccounts
      .some(({value, type}) => !this.data.accounts[type][value]);
  };

  /**
   * Toggle account's choosing flag
   */
  this.markChoosing = function(account, isChoosing) {
    account.isChoosing = isChoosing;
  };

  /**
   * Validate
   */
  this.validate = function() {

    //Check for missing accounts
    if (this.checkMissingAccounts()) {
      this.hasMissingAccounts = true;
      return false;
    }

    //All good
    return true;
  };

  /**
   * On saved handler
   */
  this.onSaved = function() {
    if (this.hasSettings) {
      this.card.showTab('status');
    }
    else {
      this.card.showTab('settings');
    }
  };
});
