
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.ViewGrade.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('viewGrade', {
    templateUrl: 'admin/people/members/modals/view-grade.html',
    controller: 'ModalViewGradeCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalViewGradeCtrl', function(
  $controller, $modalInstance
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get this full grade
    this.fullGrade = this.activity.grades
      .find(g => (g.id === this.grade.id));

    //Determine the next grade
    this.nextGrade = this.activity.grades
      .find(g => g.sortOrder === this.fullGrade.sortOrder + 1);
  };
});
