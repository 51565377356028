
/**
 * Module definition and dependencies
 */
angular.module('App.Error', [
  'App.Error.Controller',
  'App.Error.Modal',
  'App.Error.Codes.Constant',
  'App.Error.Errors.Service',
  'App.Error.ErrorTypes.Service',
  'App.Error.ErrorInterceptor.Service',
])

/**
 * Configuration
 */
.config(($provide, $httpProvider, $stateProvider) => {

  //Error interceptor
  $httpProvider.interceptors.push('ErrorInterceptor');

  //Helper to extract string error from exception
  function extractString(exception) {
    if (typeof exception === 'string') {
      return exception;
    }
    if (typeof exception === 'object') {
      if (typeof exception.message === 'string') {
        return exception.message;
      }
    }
    return '';
  }

  //Exception handling
  $provide.decorator('$exceptionHandler', [
    '$log', 'Sentry',
    function($log, Sentry) {
      return (exception, cause) => {

        //Determine exception string
        const str = extractString(exception);

        //Filter out nudnik UI router errors
        //SEE: https://github.com/angular-ui/ui-router/issues/3309
        if (str.match(
          /^Possibly unhandled.+The transition (errored|has been superseded)/
        )) {
          return;
        }

        //UI router non authenticated errors
        if (
          typeof cause === 'object' &&
          typeof cause.detail === 'object' &&
          cause.message === `The transition errored` &&
          cause.detail.status === 401
        ) {
          return;
        }

        //Capture exception and log
        Sentry.captureException(exception);
        $log.error(exception);
      };
    }]);

  //Error parent state
  //NOTE: We cannot use the portal route, because it depends on the club
  //having resolved. If that resolve causes an error, we end up in an infinite
  //loop because the error state can't be loaded. So this parent state uses
  //the same template as portal, but doesn't have any resolve dependencies.
  $stateProvider.state('errorPortal', {
    url: '',
    abstract: true,
    templateUrl: 'portal/portal.html',
    data: {
      auth: false,
      noSpinner: true,
    },
  });

  //State definition
  $stateProvider.state('error', {
    parent: 'errorPortal',
    url: '/error/:type',
    component: 'errorRoute',
  });
})

/**
 * Component definition
 */
.component('errorRoute', {
  templateUrl: 'error/error.page.html',
  controller: 'ErrorCtrl',
  bindings: {
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, $state, Errors) => {

  //Default error handler
  $state.defaultErrorHandler(error => {
    Errors.process(error);
  });

  //Remember last state
  $transitions.onSuccess({
    to(state) {
      return (state.name !== 'error');
    },
  }, transition => {
    Errors.setLastState(transition.targetState());
  });
});
