
/**
 * Module definition and dependencies
 */
angular.module('App.Account.Overview', [
  'App.Account.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('account.overview', {
    url: '/:member',
    params: {
      member: {
        value: null,
        squash: true,
        dynamic: true,
      },
    },
    component: 'accountOverviewRoute',
    resolve: {
      circleMembers(Circle) {
        return Circle
          .queryOwnManagedMembers('finances');
      },
    },
  });
})

/**
 * Component
 */
.component('accountOverviewRoute', {
  controller: 'AccountOverviewCtrl',
  templateUrl: 'account/overview/overview.html',
  bindings: {
    club: '<',
    user: '<',
    module: '<',
    circleMembers: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'account'}, transition => {

    //Page setup
    const params = transition.params();
    Page.addCrumb({sref: 'account', params});
  });
});
