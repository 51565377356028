
/**
 * Module definition and dependencies
 */
angular.module('Shared.Push.Service', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config($apiProvider => {

  //Register endpoint
  $apiProvider.registerEndpoint('pushSubscription', {
    actions: {
      create: {
        url: '/',
        method: 'POST',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Push', function($api, $modal, $storage, Config) {

  /**
   * Define class
   */
  class Push {

    /**
     * Set PWA instance
     */
    static setPwa(pwa) {
      this.pwa = pwa;
    }

    /**
     * Check if push notifications are supported
     */
    static isSupported() {
      return ('Notification' in window);
    }

    /**
     * Check if permission was granted already
     */
    static hasPermission() {
      return ('Notification' in window && Notification.permission === 'granted');
    }

    /**
     * Check if push notification of given type was enabled on this device
     */
    static isEnabledOnDevice(type) {
      return !!$storage.get(`push.${type}`);
    }

    /**
     * Check if a push notification of give ntype was enabled for the user
     */
    static isEnabledForUser(user, type) {
      return (user && user.push && user.push[type] && user.push[type].isEnabled);
    }

    /**
     * Check if we have asked a user if they want to opt in to push notifications
     * of the give ntype
     */
    static hasAskedUser(user, type) {
      return (user && user.push && user.push[type]);
    }

    /**
     * Ask user if they want to enable push notifications
     */
    static ask(user, type) {

      //No need to ask
      if (!user || !this.shouldAsk(user, type)) {
        return;
      }

      //Open modal
      return $modal
        .open('enableNotifications', {locals: {type, user}})
        .result
        .then(snooze => user.snoozeReminder(type, snooze));
    }

    /**
     * Check if we need to ask a user to show push notifications
     */
    static shouldAsk(user, type) {

      //Not supported
      if (!this.isSupported()) {
        return;
      }

      //Already enabled on this device
      if (this.isEnabledOnDevice(type)) {
        return;
      }

      //If the reminder has been snoozed
      if (!user.needsReminder(type)) {
        return;
      }

      //Checks
      const hasAskedUser = this.hasAskedUser(user, type);
      const isEnabledForUser = this.isEnabledForUser(user, type);

      //Ask if we haven't asked yet, or if the user enabled it on another device
      return (!hasAskedUser || isEnabledForUser);
    }

    /**
     * Toggle notification type for a user
     */
    static toggleNotification(user, type, isEnabled) {

      //Enabling
      if (isEnabled) {

        //Ask for permission if needed
        if (this.pwa && !this.hasPermission()) {
          this.pwa.subscribeToPushNotifications();
        }
      }

      //Toggle on device and for user as needed
      this.toggleForUser(user, type, isEnabled);
      this.toggleOnDevice(type, isEnabled);
    }

    /**
     * Enable or disable notifications on this device
     */
    static toggleOnDevice(type, isEnabled) {
      $storage.set(`push.${type}`, isEnabled);
    }

    /**
     * Toggle for user
     */
    static toggleForUser(user, type, isEnabled) {

      //Not needed
      if (
        user.push && user.push[type] &&
        user.push[type].isEnabled === isEnabled
      ) {
        return;
      }

      //Toggle on user
      user.push = user.push || {};
      user.push[type] = user.push[type] || {};
      user.push[type].isEnabled = isEnabled;

      //Get patch data
      const {push} = user;

      //Update user
      return user.patch({push});
    }

    /**
     * Ask the user if they would like to receive push notifications
     */
    static requestPermission() {

      //Wrap in promise for browser compatibility
      const promise = new Promise((resolve, reject) => {
        const result = Notification.requestPermission(permission => {
          resolve(permission);
        });
        if (result) {
          result.then(resolve, reject);
        }
      });

      //Return the promise
      return promise
        .then(permission => {

          //Ensure the result is stored on the Notification object
          if (!('permission' in Notification)) {
            Notification.permission = permission;
          }

          //Throw error if permission wasn't granted
          if (permission !== 'granted') {
            throw new Error('Permission not granted');
          }
        });
    }

    /**
     * Subscribe to push notifications
     */
    static async subscribe(registration) {

      //No service worker registration given
      if (!registration || !registration.pushManager) {
        throw new Error(`No service worker registration or push not supported`);
      }

      //Subscribe
      const subscription = await registration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: Config.push.publicKey,
        });

      //Ensure a valid subscription is returned
      if (!subscription) {
        throw new Error(`No subscription result`);
      }

      //Extract data
      const {endpoint, expirationTime} = subscription;
      const data = {endpoint, expirationTime};

      //Add keys if supported
      if (typeof subscription.getKey === 'function') {
        const p256dh = Push.arrayBufferToBase64(subscription.getKey('p256dh'));
        const auth = Push.arrayBufferToBase64(subscription.getKey('auth'));
        data.keys = {p256dh, auth};
      }

      //Save subscription
      return $api.pushSubscription.create(data);
    }

    /**
     * Helper to convert array buffer to base 64 string
     */
    static arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }
  }

  //Return class
  return Push;
});
