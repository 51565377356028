
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.SlotEvent.Component', [])

/**
 * Slot event component
 */
.component('bookingGridSlotEvent', {
  template: `
    <a
      class="SlotOverlay SlotEvent"
      ng-style="{
        height: ($ctrl.height + 'px'),
        marginTop: ($ctrl.margin + 'px'),
        background: $ctrl.event.categories[0].color,
      }"
      ui-sref="{{$ctrl.getEventLink()}}"
      ng-class="{
        'is-short': $ctrl.isShort,
        'is-hidden': $ctrl.event.isHidden,
        'is-attending': $ctrl.event.isAttending,
        'is-not-allowed': $ctrl.isNotAllowed,
        'with-prev-area': $ctrl.prev,
        'with-next-area': $ctrl.next,
      }">
      <span ng-if="$ctrl.isNotAllowed">Unavailable</span>
      <span ng-if="!$ctrl.isNotAllowed">{{$ctrl.event.name}}</span>
      <small ng-if="!$ctrl.isNotAllowed">{{$ctrl.event.startDate | moment: dateFormat.timeOnly}} – {{$ctrl.event.endDate | moment: dateFormat.timeOnly}}</small>
    </a>
  `,
  bindings: {
    user: '<',
    area: '<',
    time: '<',
    gap: '<',
    isFirst: '<',
    timeRange: '<',
    event: '<',
  },

  /**
   * Controller
   */
  controller(BookingGrid) {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Get data
      const {user, area, event} = this;
      const offset = this.determineOffset();
      const duration = this.determineDuration();

      //Check if this is a short event and if hidden
      this.isShort = duration < area.duration;
      this.isNotAllowed = (event.isHidden && !event.isVisibleFor(user));

      //Check if this event is on the area before/after as well
      this.prev = area.prev && event.areas.some(c => c.id === area.prev.id);
      this.next = area.next && event.areas.some(c => c.id === area.next.id);

      //Translate offset and duration to margin and height
      this.margin = BookingGrid.durationToHeight(offset, area.duration);
      this.height = BookingGrid.durationToHeight(duration, area.duration);
    };

    /**
     * Get event link
     */
    this.getEventLink = function() {

      //Not allowed
      if (this.isNotAllowed) {
        return '-';
      }

      //All good
      return `event.view({eventId: '${this.event.id}'})`;
    };

    /**
     * Determine event duration
     */
    this.determineDuration = function() {

      //Get data
      const {event, time, gap, isFirst, timeRange} = this;
      let {duration} = event;

      //If event started before this time slot's time, e.g. the time may have
      //already passed, then we deduct the difference from the event duration.
      if (event.startTime < time) {
        duration -= (time - event.startTime);
      }

      //Started in gap before this time slot
      if (gap && event.startTime < time && event.startTime >= (time - gap)) {
        if (!isFirst) {
          duration += (time - event.startTime);
        }
      }

      //Fix duration for staggered areas, if the event start time was before
      //the time range start time
      if (isFirst && timeRange.startTime !== time) {
        if (event.startTime < timeRange.startTime) {
          const ref = Math.max(timeRange.startTime, event.startTime);
          duration += (time - ref);
        }
      }

      //Determine overflow
      const overflow = (event.endTime > timeRange.endTime) ?
        (event.endTime - timeRange.endTime) : 0;

      //Return duration less overflow
      return (duration - overflow);
    };

    /**
     * Determine event offset
     */
    this.determineOffset = function() {

      //Get data
      const {event, time, gap, isFirst, timeRange} = this;

      //If event is starting after this slot's time, e.g. 15 or 30 minutes
      //later, then that's the offset
      if (event.startTime > time) {
        return (event.startTime - time);
      }

      //First slot, and event is starting before this slot's time
      else if (isFirst && event.startTime < time) {

        //Only offset when the slot time is not the same as the time range time
        if (timeRange.startTime !== time) {
          const ref = Math.max(timeRange.startTime, event.startTime);
          return (ref - time);
        }
      }

      //Gap and event is starting before this slot's time
      else if (gap && event.startTime < time) {

        //Only offset if started in the gap
        if (event.startTime >= (time - gap)) {
          return (event.startTime - time);
        }
      }

      //All other cases there is no offset
      return 0;
    };
  },
});
