
/**
 * Module definition and dependencies
 */
angular.module('Shared.MigrationInfo.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('migrationInfo', {
    templateUrl: 'modals/migration-info.html',
    controller: 'ModalMigrationInfoCtrl',
    closeOnClick: false,
    resolve: {
      club($store) {
        'ngInject';
        return $store.club.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalMigrationInfoCtrl', function(
  $controller, $modalInstance
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Snooze
   */
  this.snooze = function() {
    $modalInstance.resolve([7, 'days']);
  };
});
