
/**
 * Module definition and dependencies
 */
angular.module('Shared.Notice.Service', [])

/**
 * Factory
 */
.factory('$notice', function(
  $document, $timeout, $animate, $compile, $rootScope
) {

  //Default options
  const defaults = {
    hideAutomatically: true,
    hideOnClick: true,
    hideAfter: 5000,
    classes: '',
    icon: '',
    spinner: false,
    replace: false,
    onClick: null,
  };

  //Get elements
  const body = $document.find('body').eq(0);
  const container = angular.element('<div></div>').attr({
    class: 'NoticeContainer',
  });

  //Loading notice placeholder
  let loadingNotice;

  //Append container to body
  body.append(container);

  /**
   * Helper show a notice
   */
  function showNotice(notice) {
    return $animate.enter(notice, container);
  }

  /**
   * Helper to hide a notice
   */
  function hideNotice(notice) {
    return $animate.leave(notice);
  }

  //Create interface
  const $notice = {

    /**
     * Show a notice
     */
    show(message, icon, options) {

      //Options given as second parameter?
      if (icon && typeof icon === 'object') {
        options = icon;
        icon = '';
      }

      //Extend options
      options = angular.extend({}, defaults, options || {});

      //Create notice
      let notice = angular.element('<notice></notice>').attr({
        message: 'message',
        icon: 'icon',
        spinner: 'spinner',
        classes: options.classes || '',
      });

      //Create scope for component
      const scope = $rootScope.$new();
      scope.message = message;
      scope.icon = options.icon || icon;
      scope.spinner = !!options.spinner;
      notice = $compile(notice)(scope);

      //Classes to append?
      if (options.hideOnClick) {
        notice.addClass('pointer');
      }

      //Click handler
      function clickHandler() {
        if (options.onClick) {
          options.onClick();
        }
        else if (options.hideOnClick) {
          hideNotice(notice);
          notice.off('click', clickHandler);
        }
      }

      //Show notice
      showNotice(notice)
        .then(() => {

          //Auto hide?
          if (options.hideAutomatically && options.hideAfter) {
            $timeout(() => {
              hideNotice(notice);
              notice.off('click', clickHandler);
            }, options.hideAfter);
          }

          //Click handler
          notice.on('click', clickHandler);
        });

      //Return API to close the notice
      return {
        scope,
        element: notice,
        hide() {
          hideNotice(notice);
        },
      };
    },

    /**
     * Show error notice
     */
    showError(message, options) {

      //Default options
      options = Object.assign({
        hideAfter: 20000,
        classes: 'danger',
        icon: 'error',
      }, options || {});

      //Show
      return this.show(message, options);
    },

    /**
     * Show default loading notice
     */
    showLoading() {
      if (!loadingNotice) {
        loadingNotice = $notice.show('Loading...', {
          hideAutomatically: false,
          spinner: true,
        });
      }
    },

    /**
     * Hide loading notice
     */
    hideLoading() {
      if (loadingNotice) {
        loadingNotice.hide();
        loadingNotice = null;
      }
    },
  };

  //Return
  return $notice;
});
