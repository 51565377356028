
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.CustomPages.Card', [
  'Shared.CustomPageOptions.Component',
])

/**
 * Component
 */
.component('cardSettingsCustomPages', {
  templateUrl: 'admin/settings/cards/custom-pages.html',
  controller: 'CardSettingsCustomPagesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    pages: '<',
    user: '<',
    memberships: '<',
    customFields: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSettingsCustomPagesCtrl', function(
  $controller, CustomPage, $modal, $notice
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Initialize flags
    this.hasPages = this.pages.length > 0;
    this.isSuper = this.user.isSuper();

    //Check if we can add more
    this.checkCanAddMore();
  };

  /**
   * Check if we can add more
   */
  this.checkCanAddMore = function() {
    this.canAddMore = (
      this.club.permissions.pageLimit === null ||
      this.club.permissions.pageLimit > this.pages.length
    );
  };

  /**
   * On added handler
   */
  this.onAdded = function($event) {

    //Get new page
    const {page} = $event;

    //Add to array
    this.pages.push(page);
    this.hasPages = true;

    //Check if we can add more
    this.checkCanAddMore();
  };

  /**
   * On deleted handler
   */
  this.onDeleted = function($event) {

    //Get page and find the index
    const {page} = $event;
    const {id} = page;
    const index = this.pages.findIndex(f => f.id === id);

    //Splice
    if (index > -1) {
      this.pages.splice(index, 1);
      this.hasPages = this.pages.length > 0;
    }

    //Check if we can add more
    this.checkCanAddMore();
  };

  /**
   * Toggle page visibility
   */
  this.togglePageVisibility = function(page) {
    page.patch({isVisible: !page.isVisible});
  };

  /**
   * Add custom page
   */
  this.add = function() {

    //Prepare data
    const isEdit = false;
    const {memberships, customFields} = this;

    //Get new custom page and define handler
    const page = new CustomPage();
    const handler = data => page.create(data);

    //Open modal
    $modal
      .open('editCustomPage', {
        locals: {
          page, memberships, customFields, isEdit, handler,
        },
      })
      .result
      .then(() => {
        $notice.show('Page created');
        this.onAdded({page});
        if (this.setupStep) {
          this.club.markStepComplete(this.setupStep, true);
        }
      });
  };

  /**
   * Edit page
   */
  this.edit = function($event) {

    //Get page
    const {page} = $event;
    const isEdit = true;
    const {memberships, customFields} = this;

    //API only
    if (page.isApiOnly) {
      return;
    }

    //Define handler
    const handler = data => page.patch(data);

    //Open modal
    $modal
      .open('editCustomPage', {
        locals: {
          page, memberships, customFields, isEdit, handler,
        },
      })
      .result
      .then(() => $notice.show('Page updated'));
  };

  /**
   * Delete page
   */
  this.delete = function($event) {

    //Get page
    const {page} = $event;

    //API only
    if (page.isApiOnly) {
      return;
    }

    //Define handler
    const handler = () => page.delete();

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-delete-custom-page.html',
        locals: {page, handler},
      })
      .result
      .then(() => {
        $notice.show('Page removed');
        this.onDeleted({page});
      });
  };
});
