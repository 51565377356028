
/**
 * Module definition and dependencies
 */
angular.module('Shared.Page.Service', [])

/**
 * Provider definition
 */
.factory('Page', function($window, Config) {

  //Defined options placeholder
  const definedOptions = {};

  //Page service
  const Page = {

    //Properties
    controller: null,
    titleSuffix: '',
    title: '',
    crumbs: [],
    options: [],
    search: false,
    filters: null,
    showFilters: false,
    data: {},

    /**
     * Reset to defaults
     */
    reset() {
      this.controller = null;
      this.title = '';
      this.crumbs = [];
      this.options = [];
      this.search = false;
      this.filters = null;
      this.showFilters = false;
      this.data = {};
    },

    /**
     * Set generic data
     */
    setData(data) {
      this.data = data;
    },

    /**
     * Define option
     */
    defineOption(name, option) {
      option.Page = this;
      definedOptions[name] = option;
    },

    /**
     * Add option
     */
    addOption(option, mergeSettings) {
      if (typeof option === 'string') {
        if (typeof definedOptions[option] === 'undefined') {
          throw new Error('Undefined page option ' + option);
        }
        option = Object.assign({}, definedOptions[option], mergeSettings || {});
      }
      this.options.unshift(option);
    },

    /**
     * Set controller
     */
    setController(controller) {
      this.controller = controller;
    },

    /**
     * Add crumb item
     */
    addCrumb(crumb) {
      this.crumbs.push(crumb);
    },

    /**
     * Clear crumbs
     */
    clearCrumbs() {
      this.crumbs = [];
    },

    /**
     * Pop last crumb item
     */
    popCrumb() {
      this.crumbs.pop();
    },

    /**
     * Set filters
     */
    setFilters(filters) {
      this.filters = filters;
    },

    /**
     * Toggle filters
     */
    toggleFilters(show) {
      if (typeof show === 'undefined') {
        show = !this.showFilters;
      }
      this.showFilters = show;
    },

    /**
     * Enable search
     */
    enableSearch() {
      this.search = true;
    },

    /**
     * Set page title suffix
     */
    setTitleSuffix(suffix) {
      this.titleSuffix = suffix;
      this.updateDocumentTitle();
    },

    /**
     * Set page title
     */
    setTitle(title) {
      this.title = title;
      this.updateDocumentTitle();
    },

    /**
     * Update document title
     */
    updateDocumentTitle() {
      $window.document.title = this.getFullTitle();
    },

    /**
     * Get full title
     */
    getFullTitle() {
      const parts = [];
      if (this.title) {
        parts.push(this.title);
      }
      if (this.titleSuffix) {
        parts.push(this.titleSuffix);
      }
      parts.push(Config.app.title);
      return parts.join(' | ');
    },
  };

  //Return
  return Page;
});
