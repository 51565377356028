
/**
 * Module definition and dependencies
 */
angular.module('App.Public.Event', [
  'App.Public.Event.View',
  'App.Public.Event.Signup',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('public.event', {
    url: '/event',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'events',
    },
  });
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'public.event.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
  }, {priority: 10});
});
