
/**
 * Module definition and dependencies
 */
angular.module('App.Header.SideMenu.Component', [])

/**
 * Selectbox component
 */
.component('sideMenu', {
  templateUrl: 'app/header/side-menu/side-menu.html',
  bindings: {
    isShowing: '<',
    club: '<',
    user: '<',
    pages: '<',
    toggle: '&',
  },

  /**
   * Component controller
   */
  controller($state, moment, Interface, Emitter, Auth, Modules) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Set year
      this.currentYear = moment().year();

      //Get emitter
      Emitter
        .get('menu')
        .on('rebuild', () => this.buildMenus());
    };

    /**
     * On changes
     */
    this.$onChanges = function(changes) {

      //Scroll to top if showing
      if (changes.isShowing && changes.isShowing.currentValue) {
        Interface.scrollToTop('SideMenu');
      }

      //Rebuild menus when user or club changes
      if (changes.user || changes.club) {
        this.buildMenus();
      }
    };

    /**
     * Build menus
     */
    this.buildMenus = function() {

      //Get data
      const {user} = this;

      //Initialize menus
      this.menu = {};

      //Build kiosk menu if no user present
      if (!user) {
        this.buildMenu('kiosk');
      }

      //Build user menu
      if (user) {
        this.buildMenu('user');
      }

      //Build admin menu
      if (user && user.hasRole('admin', 'viewer', 'eventManager')) {
        this.buildMenu('admin');
      }
    };

    /**
     * Build a menu
     */
    this.buildMenu = function(type) {

      //Initialize menu container
      this.menu[type] = [];

      //Get modules
      const modules = Modules.get();

      //Process
      for (const id in modules) {
        const module = modules[id];
        if (module.menu === type && this.shouldShow(module)) {
          this.menu[type].push(module);

          //HACK: Show bookings overview after events
          if (type === 'admin' && id === 'events') {
            if (Modules.has('bookings')) {
              this.menu[type].push({
                title: 'Bookings',
                icon: 'date_range',
                menu: 'admin',
                sref: 'booking.overview',
              });
            }
          }
        }
      }
    };

    /**
     * Check if a module should show
     */
    this.shouldShow = function(module) {

      //Get data
      const {user, roles} = module;

      //User presence check
      if ((user === false && this.user) || (user !== false && !this.user)) {
        return false;
      }

      //Roles check
      if (roles && roles.length) {
        if (!this.user || !this.user.hasRole(...roles)) {
          return false;
        }
      }

      //All good
      return true;
    };

    /**
     * Click handler
     */
    this.click = function() {
      this.toggle();
    };

    /**
     * Is active helper
     */
    this.isActive = function(item) {
      return (item.sref && $state.current.name === item.sref);
    };

    /**
     * Is on a custom page
     */
    this.isOnPage = function(page) {
      return ($state.current.name === 'page' && $state.params.pageId === page.id);
    };

    /**
     * Go to login
     */
    this.login = function(state) {
      Auth.goToLoginState(state);
    };

    /**
     * Logout
     */
    this.logout = function() {
      Auth.logout();
    };
  },
});
