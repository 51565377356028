
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.Controller', [])

/**
 * Controller
 */
.controller('AdminClubCtrl', function(
  $q, $modal, $notice, $store, Config, Upload, Settings, GlobalScope, Intercom,
  Member, Plan, Text, TextTypes, Country
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Get params
    const {openCard, openTab} = this.transition.params();
    const {club} = this;

    //Flags
    this.isSuper = this.user.isSuper();
    this.openCard = openCard;
    this.openTab = openTab;
    this.texts = [];

    //Show account card if trial has ended or grace period has expired
    if (club.hasTrialEnded || club.hasPlanExpired) {
      this.openCard = 'account';
    }

    //Visitor log fields
    this.visitorLogFields = [
      {
        label: 'Email address',
        prop: 'email',
      },
      {
        label: 'Phone number',
        prop: 'phone',
      },
      {
        label: 'Address',
        prop: 'address',
      },
      {
        label: 'Reason for visit',
        prop: 'reason',
      },
    ];

    //Pre-load texts and account data
    this.loadTexts();
    this.loadAccountData();
  };

  /**
   * Load texts
   */
  this.loadTexts = function() {

    //Already loaded
    if (this.texts.length > 0) {
      return $q.resolve();
    }

    //Types to load
    const types = [
      TextTypes.WELCOME_MESSAGE,
      TextTypes.WELCOME_EMAIL,
      TextTypes.REGISTRATION_INTRO,
      TextTypes.ACCOUNT_READY_EMAIL,
      TextTypes.VISITOR_INTRO,
      TextTypes.VISITOR_TERMS,
      TextTypes.INVITE_EMAIL,
      TextTypes.PAYMENT_INSTRUCTIONS,
      TextTypes.TERMS_AND_CONDITIONS,
    ];

    //Load
    return $q
      .all(types.map(type => Text.findByType(type)))
      .then(texts => this.texts = texts);
  };

  /**
   * Save texts
   */
  this.saveTexts = function($event, silent = false) {

    //Get text model
    const {texts, settings} = $event;
    const promises = [];

    //Process keys
    for (const text of texts) {
      if (text.isModified()) {
        promises.push(text.save());
      }
    }

    //Saving settings as well?
    if (settings) {
      promises.push(this.updateSettings($event, false));
    }

    //Save all
    return $q
      .all(promises)
      .then(() => {
        if (!silent) {
          $notice.show(`Text updated`);
        }
      });
  };

  /**
   * Save
   */
  this.save = function($event) {

    //Get model
    const {model, texts} = $event;
    const {singular} = this.module;

    //Save
    return this.club
      .patch(model)
      .then(() => {
        if (texts) {
          this.saveTexts({texts}, true);
        }
        Intercom.event('Updated club details');
        $notice.show(`${singular} details updated`);
      });
  };

  /**
   * Update privacy
   */
  this.updatePrivacy = function($event) {

    //Get model
    const {model} = $event;
    const {privacy} = model;

    //Save
    return this.club
      .patch({privacy})
      .then(() => {
        Intercom.event('Updated privacy details');
        $notice.show('Privacy details updated');
      });
  };

  /**
   * Update joining fees
   */
  this.updateJoiningFees = function($event) {

    //Get model
    const {model} = $event;
    const {joiningFees} = model;

    //Save
    return this.club
      .patch({joiningFees})
      .then(() => {
        Intercom.event('Updated joining fees');
        $notice.show('Joining fees updated');
      });
  };

  /**
   * Update subdomain
   */
  this.updateSubdomain = function($event) {

    //Get model
    const {model} = $event;
    const {subdomain} = model;

    //Save
    return this.club
      .patch({subdomain})
      .then(() => {
        Intercom.event('Updated subdomain');
        $notice.show('Subdomain updated');
      });
  };

  /**
   * Update welcome message
   */
  this.updateWelcomeMessage = function($event) {

    //Get model
    const {model} = $event;
    const {welcomeMessage} = model;

    //Save
    return this.club
      .patch({welcomeMessage})
      .then(() => {
        Intercom.event('Updated club welcome text');
        $notice.show('Welcome text updated');
      });
  };

  /**
   * Update welcome email
   */
  this.updateWelcomeEmail = function($event) {

    //Get model
    const {model} = $event;
    const {welcomeEmail} = model;

    //Save
    return this.club
      .patch({welcomeEmail})
      .then(() => {
        Intercom.event('Updated club welcome email');
        $notice.show('Welcome email updated');
      });
  };

  /**
   * Update terms and conditions
   */
  this.updateTermsAndConditions = function($event) {

    //Get model
    const {club} = this;
    const {model} = $event;
    const {mustReAgreeToTerms, termsAndConditions} = model;
    const hasTermsAndConditions = termsAndConditions.hasTextInModel();

    //Not modified
    if (!termsAndConditions.isModified()) {
      return $q.resolve();
    }

    //Create promises
    return $q
      .all([
        termsAndConditions.save(),
        club.patch({hasTermsAndConditions}, {mustReAgreeToTerms}),
      ])
      .then(() => {
        Intercom.event('Updated club terms and conditions');
        $notice.show('Terms and conditions updated');
      });
  };

  /**
   * Update plan
   */
  this.updatePlan = function($event) {

    //Get model
    const {model} = $event;
    const {billing} = model;
    const data = Object.assign({}, model, {billing: undefined});

    //Change plan
    return this.club
      .patch({billing})
      .then(() => this.club.changePlan(data))
      .then(() => {
        Intercom.event('Updated plan or payment details');
        $notice.show('Plan and payment details updated');
        this.loadAccountData(true);
      });
  };

  /**
   * Update billing
   */
  this.updateBilling = function($event) {

    //Get model
    const {model} = $event;

    //Save
    return this.club
      .patch(model)
      .then(() => {
        Intercom.event('Updated billing details');
        $notice.show('Billing details updated');
      });
  };

  /**
   * Update account owner
   */
  this.updateAccountOwner = function($event) {

    //Get model
    const {model} = $event;
    const accountOwner = model.accountOwner.id;

    //Save
    return this.club
      .patch({accountOwner})
      .then(() => {

        //Updated account owner
        if (accountOwner !== this.user.id) {
          this.user.isAccountOwner = false;
        }

        //Events
        Intercom.event('Updated account owner');
        $notice.show('Account owner updated');
      });
  };

  /**
   * Update social
   */
  this.updateSocial = function($event) {

    //Get model
    const {model} = $event;

    //Save
    return this.club
      .patch(model)
      .then(() => {
        Intercom.event('Updated social media details');
        $notice.show('Social media details updated');
      });
  };

  /**
   * Update settings
   */
  this.updateSettings = function($event, showNotice = true) {

    //Get data
    const {settings} = $event;

    //Update settings
    return this.club
      .patch({settings})
      .then(({settings}) => {

        //Update globally
        Settings.set(settings);
        GlobalScope.set({settings});

        //Show notice
        if (showNotice) {
          $notice.show('Settings updated');
        }
        return settings;
      });
  };

  /**
   * Update logo
   */
  this.updateLogo = function($event) {

    //Get data from event
    const {data} = $event;

    //Track in intercom
    Intercom.event('Updated club logo');

    //Set new logo
    this.club.logo = data.logo;
    return $q.resolve();
  };

  /**
   * Upload logo
   */
  this.uploadLogo = function($event) {

    //Get file
    const {file} = $event;
    const {baseUrl} = Config.api;

    //Upload
    return Upload
      .upload({
        url: `${baseUrl}/club/own/logo/`,
        data: {
          logo: file,
        },
      })
      .then(response => {
        this.club.markStepComplete('uploadLogo', true);
        return response;
      });
  };

  /**
   * Delete logo
   */
  this.deleteLogo = function() {

    //Define handler
    const club = this.club;
    const handler = function() {
      return club.deleteLogo();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/club/modals/confirm-delete-logo.html',
        locals: {handler},
      })
      .result;
  };

  /**
   * Update background
   */
  this.updateBackground = function($event) {

    //Get data from event
    const {data} = $event;
    const {background} = data;

    //Track in intercom and set in scope
    Intercom.event('Updated club background');
    GlobalScope.set({background});

    //Set new background
    this.club.background = background;
    return $q.resolve();
  };

  /**
   * Upload background
   */
  this.uploadBackground = function($event) {

    //Get file
    const {file} = $event;
    const {baseUrl} = Config.api;

    //Upload
    return Upload
      .upload({
        url: `${baseUrl}/club/own/background/`,
        data: {
          background: file,
        },
      })
      .then(response => {
        this.club.markStepComplete('uploadBackground', true);
        return response;
      });
  };

  /**
   * Delete background
   */
  this.deleteBackground = function() {

    //Define handler
    const club = this.club;
    const handler = function() {
      return club
        .deleteBackground()
        .then(() => GlobalScope.set({background: null}));
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/club/modals/confirm-delete-background.html',
        locals: {handler},
      })
      .result;
  };

  /**
   * Update logo header
   */
  this.updateLogoHeader = function($event) {

    //Get data from event
    const {data} = $event;
    const {logoHeader} = data;

    //Track in intercom and set in scope
    Intercom.event('Updated club header logo');
    GlobalScope.set({logoHeader});

    //Set new logoHeader
    this.club.logoHeader = logoHeader;
    return $q.resolve();
  };

  /**
   * Upload logo header
   */
  this.uploadLogoHeader = function($event) {

    //Get file
    const {file} = $event;
    const {baseUrl} = Config.api;

    //Upload
    return Upload
      .upload({
        url: `${baseUrl}/club/own/logoHeader/`,
        data: {
          logoHeader: file,
        },
      });
  };

  /**
   * Delete logo header
   */
  this.deleteLogoHeader = function() {

    //Define handler
    const club = this.club;
    const handler = function() {
      return club
        .deleteLogoHeader()
        .then(() => GlobalScope.set({logoHeader: null}));
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/club/modals/confirm-delete-header-logo.html',
        locals: {handler},
      })
      .result;
  };

  /**
   * Load alerts
   */
  this.loadAlerts = function() {
    return $store.alerts
      .query()
      .then(alerts => this.alerts = alerts);
  };

  /**
   * Load memberships
   */
  this.loadMemberships = function() {
    return $store.memberships
      .query()
      .then(memberships => this.memberships = memberships);
  };

  /**
   * Load country
   */
  this.loadCountry = function() {
    return Country
      .own()
      .then(country => this.country = country);
  };

  /**
   * Load account data
   */
  this.loadAccountData = function(reload = false) {

    //Already loaded?
    if (this.plans && this.admins && !reload) {
      return $q.resolve();
    }

    //Load now
    return $q
      .all([
        Member
          .query({
            role: 'admin',
            fields: 'firstName,lastName',
            isArchived: false,
          })
          .then(data => this.admins = data.members),
        Plan
          .query({includeHidden: true})
          .then(data => this.plans = data.plans),
      ]);
  };
});
