if (window.WebFont) {
  window.WebFont.load({
    google: {
      families: [
        'Open+Sans:300,400,400italic,600',
        'Material Icons',
      ],
    },
  });
}
