
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.BaseController', [])

/**
 * Controller
 */
.controller('CardIntegrationsBaseCtrl', function(
  $notice, $modal, Integration, ApiKey, ErrorCodes
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //No integration? Create blank
    if (!this.integration) {
      const {type} = this;
      this.integration = new Integration({type});
    }

    //Check if connected
    this.isConnected = this.integration.isConnected;

    //Create data model
    this.data = this.integration.extract('data') || {};
  };

  /**
   * Update model
   */
  this.updateModel = function(key, value, target) {
    target = target || this.data;
    target[key] = value;
  };

  /**
   * Connect integration
   */
  this.connect = function() {

    //Connecting flag
    this.status = null;
    this.isConnecting = true;
    this.isErrorPlanLimits = false;

    //Connect
    return this.integration
      .connect()
      .catch(error => {
        this.isConnecting = false;
        if (error.code === ErrorCodes.planLimits) {
          this.isErrorPlanLimits = true;
          this.integrationLimit = error.data.integrationLimit;
        }
        throw error;
      });
  };

  /**
   * Disconnect integration
   */
  this.disconnect = function() {

    //Get integration and disconnect handler
    const {integration} = this;
    const handler = () => this.integration.disconnect();

    //Flags
    this.status = null;
    this.isDisconnecting = true;

    //Open confirmation dialog
    const modalInstance = $modal
      .open('basic', {
        templateUrl: 'admin/integrations/modals/confirm-disconnect.html',
        locals: {integration, handler},
      });

    //Handle cancel/close
    modalInstance
      .closed
      .finally(() => this.isDisconnecting = false);

    //Handle result
    modalInstance
      .result
      .then(() => {
        this.isConnected = false;
        this.apiKey = null;
      })
      .catch(() => $notice.showError('Failed to disconnect'))
      .finally(() => this.isDisconnecting = false);
  };

  /**
   * Load API key for integration
   */
  this.loadApiKey = function() {

    //Must be connected
    if (!this.integration.isConnected) {
      return;
    }

    //Mark as loading
    this.isLoadingApiKey = true;

    //Load
    return ApiKey
      .findByIntegration(this.integration.type)
      .then(apiKey => this.apiKey = apiKey)
      .finally(() => this.isLoadingApiKey = false);
  };

  /**
   * No longer connected handler
   */
  this.noLongerConnected = function() {

    //Get integration and flag as no longer connected
    const {integration} = this;
    integration.isConnected = false;

    //Show modal
    $modal.open('basic', {
      templateUrl: 'admin/integrations/modals/no-longer-connected.html',
      locals: {integration},
    });

    //Open status tab
    this.card.showTab('status');
  };

  /**
   * Update integration
   */
  this.update = function() {

    //Flag
    this.status = null;
    this.isUpdating = true;

    //Get name and data
    const {name} = this.integration;
    const {data} = this;

    //Update
    return this.integration
      .update({data})
      .then(() => {
        $notice.show(`${name} integration saved`);
        this.onUpdated();
      })
      .finally(() => this.isUpdating = false);
  };

  /**
   * Save and back to status tab
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Set flag
    this.isSaving = true;

    //Update
    return this
      .update()
      .then(() => this.onSaved())
      .finally(() => this.isSaving = false);
  };

  /**
   * On saved handler
   */
  this.onSaved = function() {
    this.card.showTab('status');
  };

  /**
   * Validation
   */
  this.validate = function() {

    //Standard form validation by default
    if (this.form) {
      this.form.$setSubmitted();
      if (this.form.$invalid) {
        return false;
      }
    }

    //All good
    return true;
  };

  /**
   * On updated
   */
  this.onUpdated = function() {};
});
