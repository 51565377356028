
/**
 * Module definition and dependencies
 */
angular.module('App.Directory.Overview.Controller', [])

/**
 * Controller
 */
.controller('DirectoryOverviewCtrl', function(
  $filter, $modal, $store, ScrollPosition, Pagination, Member, Settings
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize
    this.isLoading = true;
    this.isAdmin = this.user.isAdmin();

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load custom fields & determine columns
    this
      .loadCustomFields()
      .then(() => this.determineContactColumn());

    //Load page
    this.loadPage();

    //Flags
    this.showPhone = this.club.settings.member.fields.phone.inDirectory;
    this.showEmail = this.club.settings.member.fields.email.inDirectory;
    this.showAddress = this.club.settings.member.fields.address.inDirectory;
  };

  /**
   * Determine contact column
   */
  this.determineContactColumn = async function() {

    //Only show if the amount of other fields is correct
    if (this.customFieldsForOverview.length > 2) {
      this.showContact = true;
    }
    else {
      this.showContact = false;
    }
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get data
    const {filter} = this;
    const {nameSortOption} = Settings.get('member');

    //Set defaults
    filter.setDefaults({
      search: '',
      sort: nameSortOption,
    });

    //Filter on change handler
    filter.onChange(() => {
      this.loadPage(1);
    });
  };

  /**
   * Load custom fields that are in the directory
   */
  this.loadCustomFields = function() {
    return $store.customFields
      .query({model: 'Member', inDirectory: true})
      .then(fields => {
        this.customFields = fields;
        this.customFieldsForOverview = fields
          .filter(field => field.canBeInDirectoryOverview);
      });
  };

  /**
   * Load members
   */
  this.loadMembers = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query members
    return Member
      .directory(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, members} = data;

    //Set members
    this.members = members;

    //Set in scope
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Selection active? Use as filter unless page specified
    if (!page && this.selection.size > 0) {
      return {ids: Array.from(this.selection.values())};
    }

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadMembers()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };

  //Open info modal for the member
  this.memberInfo = function(member) {

    //Get data
    const {isAdmin, customFields, club} = this;
    const directoryFields = club.settings.member.fields;

    //Show modal
    $modal
      .open('basic', {
        templateUrl: 'directory/modals/view-member.html',
        locals: {member, customFields, isAdmin, directoryFields},
      });
  };
});
