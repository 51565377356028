
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.RenewNoPayment.Card', [])

/**
 * Component
 */
.component('cardSubscriptionRenewNoPayment', {
  templateUrl: 'subscription/cards/renew-no-payment.html',
  controller: 'CardSubscriptionRenewNoPaymentCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    onRenew: '&',
    onPrev: '&',
    onCancel: '&',
  },
})

/**
 * Controller
 */
.controller('CardSubscriptionRenewNoPaymentCtrl', function() {

  /**
   * Renew
   */
  this.renew = function() {

    //Flag as renewing
    this.isRenewing = true;

    //Renew
    return this
      .onRenew()
      .finally(() => this.isRenewing = false);
  };

  /**
   * Go back
   */
  this.prev = function() {
    this.onPrev();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
