
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.ActivityOptions.Component', [])

/**
 * Activity options component
 */
.component('memberActivityOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li>
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
    </more-options>
  `,
  bindings: {
    activity: '<',
    onEdit: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Edit
     */
    this.edit = function() {
      this.onEdit({$event: {activity: this.activity}});
    };
  },
});
