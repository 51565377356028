
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Add.Permissions.Card', [])

/**
 * Component
 */
.component('cardMemberAddPermissions', {
  templateUrl: 'admin/people/members/cards/permissions.html',
  controller: 'CardMemberAddPermissionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    member: '<',
    system: '<',
    hasMembershipTypes: '<',
    isStaff: '<',
    onMerge: '&',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberAddPermissionsCtrl', function($controller, Roles) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMemberEditPermissionsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;

    //Filter out member role for staff module (only for adding)
    this.roles = Roles
      .filter(role => !this.isStaff || role.value !== 'member');

    //Set default roles
    if (this.model.roles.length === 0) {
      if (!this.isStaff) {
        this.model.roles.push('member');
      }
    }
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {member, model} = this;
    this.onMerge({$event: {member, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next card
    this.merge();
    this.card.nextTab();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previous();
  };
});
