
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations', [
  'App.Admin.Integrations.Controller',
  'App.Admin.Integrations.BaseController',
  'App.Admin.Integrations.Stripe',
  'App.Admin.Integrations.POLi',
  'App.Admin.Integrations.Xero',
  'App.Admin.Integrations.GoogleCalendar',
  'App.Admin.Integrations.Mailchimp',
  'App.Admin.Integrations.CampaignMonitor',
  'App.Admin.Integrations.ApiKeys',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.integrations', {
    url: '/integrations/:openCard/:openTab',
    component: 'adminIntegrationsRoute',
    params: {
      openCard: {
        value: null,
        squash: true,
      },
      openTab: {
        value: null,
        squash: true,
      },
    },
    data: {
      id: 'integrations',
    },
    resolve: {
      country(Country) {
        return Country.own();
      },
    },
  });
})

/**
 * Component
 */
.component('adminIntegrationsRoute', {
  controller: 'AdminIntegrationsCtrl',
  templateUrl: 'admin/integrations/integrations.html',
  bindings: {
    club: '<',
    user: '<',
    country: '<',
    module: '<',
    integrations: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page, Intercom) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.integrations'}, transition => {

    //Set page title
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.integrations'});

    //Track intercom event
    Intercom.event('Viewed integrations');
  });
});
