
/**
 * Module definition and dependencies
 */
angular.module('Shared.CurrencySymbol.Filter', [])

/**
 * Filter definition
 */
.filter('currencySymbol', function() {
  return function(code) {
    switch (code) {
      case 'AED':
        return 'د.إ';
      case 'AFN':
        return '؋';
      case 'ALL':
        return 'Lek';
      case 'AZN':
        return '₼';
      case 'BYN':
        return 'Br';
      case 'BZD':
        return 'BZ$';
      case 'BOB':
        return '$b';
      case 'BAM':
        return 'KM';
      case 'BWP':
        return 'P';
      case 'BRL':
        return 'R$';
      case 'BGN':
        return 'лв';
      case 'KHR':
        return '៛';
      case 'CNY':
        return '¥';
      case 'CRC':
        return '₡';
      case 'EUR':
        return '€';
      case 'HRK':
        return 'kn';
      case 'CUP':
        return '₱';
      case 'CZK':
        return 'Kč';
      case 'DOP':
        return 'RD$';
      case 'GHS':
        return '¢';
      case 'GTQ':
        return 'Q';
      case 'HNL':
        return 'L';
      case 'HUF':
        return 'Ft';
      case 'INR':
        return '₹';
      case 'IDR':
        return 'Rp';
      case 'ILS':
        return '₪';
      case 'JMD':
        return 'J$';
      case 'JPY':
        return '¥';
      case 'KZT':
        return 'лв';
      case 'KES':
        return 'KSh';
      case 'LAK':
        return '₭';
      case 'MKD':
        return 'ден';
      case 'MYR':
        return 'RM';
      case 'MNT':
        return '₮';
      case 'MZN':
        return 'MT';
      case 'NIO':
        return 'C$';
      case 'NGN':
        return '₦';
      case 'PAB':
        return 'B/.';
      case 'PYG':
        return 'Gs';
      case 'PEN':
        return 'S/.';
      case 'PLN':
        return 'zł';
      case 'RON':
        return 'lei';
      case 'RUB':
        return '₽';
      case 'RSD':
        return 'Дин.';
      case 'SOS':
        return 'S';
      case 'ZAR':
        return 'R';
      case 'KRW':
        return '₩';
      case 'CHF':
        return 'CHF';
      case 'TWD':
        return 'NT$';
      case 'THB':
        return '฿';
      case 'TTD':
        return 'TT$';
      case 'UAH':
        return '₴';
      case 'UYU':
        return '$U';
      case 'UZS':
        return 'лв';
      case 'VEF':
        return 'Bs';
      case 'VND':
        return '₫';
      case 'AWG':
      case 'ANG':
        return 'ƒ';
      case 'IRR':
      case 'QAR':
      case 'SAR':
      case 'YER':
        return '﷼';
      case 'NPR':
      case 'PKR':
      case 'SCR':
      case 'LKR':
        return '₨';
      case 'DKK':
      case 'ISK':
      case 'NOK':
      case 'SEK':
        return 'kr';
      case 'EGP':
      case 'FKP':
      case 'GIP':
      case 'IMP':
      case 'JEP':
      case 'LBP':
      case 'GBP':
        return '£';
      default:
        return '$';
    }
  };
});
