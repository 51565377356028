
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area.EditAreaTime.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editAreaTime', {
    templateUrl: 'admin/area/modals/edit-area-time.html',
    controller: 'ModalEditAreaTimeCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalEditAreaTimeCtrl', function(
  $controller, $modalInstance, Weekdays
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Clone model
    this.model = this.timeRange.clone();

    //Round down max gap
    this.maxGap = Math.floor((this.maxGap || 60) / 5) * 5;

    //Fill time range
    if (!this.isEdit && this.model.isEmpty()) {
      this.model.fillDays();
    }

    //Set flags
    this.isSaving = false;
    this.isError = false;

    //Set services
    this.Weekdays = Weekdays;
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };
});
