
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Edit.System.Card', [])

/**
 * Component
 */
.component('cardEventEditSystem', {
  templateUrl: 'admin/event/cards/system.html',
  controller: 'CardEventEditSystemCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    event: '<',
    system: '<',
    activities: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventEditSystemCtrl', function(
  $modal, CardCloseReasons,
  AutoLightsOnOptions, AutoLightsOffOptions,
  AutoDoorsOpenOptions, AutoDoorsCloseOptions, $storage
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Get data
    const {system, event, activities} = this;

    //Set data and flags
    this.isSaving = false;
    this.isEdit = true;
    this.canControlLights = event.hasLightControl(system, activities);
    this.canControlDoors = system.hasDoorControl;

    //Options
    this.AutoLightsOnOptions = AutoLightsOnOptions;
    this.AutoLightsOffOptions = AutoLightsOffOptions;
    this.AutoDoorsOpenOptions = AutoDoorsOpenOptions;
    this.AutoDoorsCloseOptions = AutoDoorsCloseOptions;

    //Create model subset
    this.model = event.extract([
      'autoLights', 'autoDoors',
    ]);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {

    //Update model
    target = target || this.model;
    target[property] = value;

    //Can't have auto doors close without open
    if (property === 'open') {
      if (value === null) {
        target.close = null;
      }
      else if (target.close === null) {
        target.close = '0:endDate';
      }
    }

    //Can't have auto lights turn off without turning on
    if (property === 'turnOn') {
      if (value === null) {
        target.turnOff = null;
      }
      else if (target.turnOff === null) {
        target.turnOff = '0:endDate';
      }
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save auto light/door settings in local storage
   */
  this.storeAutoValues = function() {
    if (this.model.autoLights) {
      $storage.set('event.autoLights', this.model.autoLights);
    }
    if (this.model.autoDoors) {
      $storage.set('event.autoDoors', this.model.autoDoors);
    }
  };

  /**
   * Save
   */
  this.save = function(method) {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Store auto light/door settings in local storage
    this.storeAutoValues();

    //Get data and prepare event
    const {event, model, isEdit} = this;
    const {isMultiDay, isRecurring} = event;
    const {startDate} = model;
    const $event = {event, model, method, isEdit};

    //Multi-day, use instance update
    if (isMultiDay) {
      method = 'instance';
    }

    //Confirm how to update if this was a recurring event
    if (isEdit && isRecurring && !method) {
      return $modal
        .open('confirmUpdateEvent', {locals: {event, startDate}})
        .result
        .then(method => this.save(method));
    }

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .catch(error => {
        if (error.name === 'ValidationError') {
          this.error = error;
        }
      })
      .finally(() => this.isSaving = false);
  };
});
