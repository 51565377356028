
/**
 * Module definition and dependencies
 */
angular.module('Shared.Email.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config($apiProvider => {

  //Register endpoint
  $apiProvider.registerEndpoint('email', {
    model: 'Email',
    url: 'email',
    actions: {
      deleteAttachment: {
        url: 'attachment/:id',
        method: 'DELETE',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Email', function($baseModel, $api) {

  /**
   * Constructor
   */
  function Email(data) {
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(Email.prototype, $baseModel.prototype);

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Delete attachment
   */
  Email.deleteAttachment = function(id) {
    return $api.email.deleteAttachment({id});
  };

  //Return
  return Email;
});
