
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Log.CheckIn', [
  'App.Admin.Log.CheckIn.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.log.checkIn', {
    url: '/check-in',
    component: 'adminLogCheckInRoute',
    data: {
      id: 'checkInLogs',
    },
    resolve: {
      filter(Filters) {
        return Filters.get('checkInLogs');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminLogCheckInRoute', {
  controller: 'AdminLogCheckInCtrl',
  templateUrl: 'admin/log/check-in/check-in.html',
  bindings: {
    club: '<',
    user: '<',
    filter: '<',
    page: '<',
    system: '<',
  },
});
