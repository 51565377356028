
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Overview.Controller', [])

/**
 * Controller
 */
.controller('AdminCouponTypeOverviewCtrl', function(
  $controller, $filter, Intercom, Settings, CouponType,
  ScrollPosition, Pagination, CouponTypeTypes
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminCouponTypeCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed coupon types');

    //Initialize
    this.canEdit = this.user.isAdmin();

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load coupon types now
    this.loadPage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter, activities, module} = this;
    const {plural} = module;

    //Set filter defaults
    filter.setDefaults({
      search: '',
      status: 'current',
      isArchived: false,
    });

    //Filter on change handler
    filter.onChange((key, value) => {

      //Status
      if (key === 'status') {
        filter.mapOptions(key, value, 'value');
      }

      //Reload first page
      this.loadPage(1);
    });

    //Filter options
    filter.options = {
      activities,
      type: CouponTypeTypes,
      status: [
        {
          label: `Current ${plural}`,
          value: 'current',
          isDefault: true,
          filter: {
            isArchived: false,
          },
        },
        {
          label: `Archived ${plural}`,
          value: 'archived',
          filter: {
            isArchived: true,
          },
        },
      ],
    };
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    this.loadCouponTypes();
  };

  /**
   * On patched
   */
  this.onPatched = function() {
    this.loadCouponTypes();
  };

  /**
   * Load coupon types
   */
  this.loadCouponTypes = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query
    return CouponType
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, couponTypes} = data;

    //Set in scope
    this.couponTypes = couponTypes;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //Remove status, which is just a helper filter item in the client
    delete filter.status;

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadCouponTypes()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };
});
