
/**
 * Module definition and dependencies
 */
angular.module('Shared.ToFixed.Directive', [])

/**
 * Directive
 */
.directive('toFixed', function() {

  const countDecimals = (value) => {
    if (Math.floor(value) === value) {
      return 0;
    }
    return value.toString().split('.')[1].length || 0;
  };

  return {
    restrict: 'A',
    require: [
      '?ngModel',
    ],
    //eslint-disable-next-line no-unused-vars
    link(scope, element, attrs, ngModel) {

      //Array?
      if (Array.isArray(ngModel)) {
        ngModel = ngModel[0];
      }

      //Discover number of decimals
      const numDecimals = attrs.step.split('.')[1].length;

      //Roll our own
      ngModel.$formatters.unshift(function(value) {
        if (!ngModel.$isEmpty(value)) {
          value = parseFloat(value);
          if (numDecimals === 2 || countDecimals(value) <= 2) {
            value = value.toFixed(2);
          }
          else {
            value = value.toFixed(numDecimals);
          }
        }
        return value;
      });
    },
  };
});
