
/**
 * Module definition and dependencies
 */
angular.module('Shared.GlobalScope.Service', [])

/**
 * Service definition
 */
.factory('GlobalScope', function($rootScope) {

  //Get prototype of root scope
  const protoScope = Object.getPrototypeOf($rootScope);

  //Return service
  return {

    /**
     * Map object properties onto root scope prototype
     */
    set(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          protoScope[key] = obj[key];
        }
      }
    },

    /**
     * Clear global scope properties
     */
    clear(...keys) {
      for (const key of keys) {
        protoScope[key] = null;
      }
    },
  };
});
