
/**
 * Module definition and dependencies
 */
angular.module('Shared.CommentsList.Item.Component', [])

/**
 * Comments item component
 */
.component('commentsListItem', {
  template: `
    <div class="CommentsList-item">
      <div class="Comment">
        <div class="Comment-avatar">
          <avatar
            size="m"
            avatar="$ctrl.comment.member.avatar"
            color="$ctrl.comment.member.color"
            text="$ctrl.comment.member.initials"
          ></avatar>
        </div>
        <div class="Comment-content">
          <p class="Comment-meta">
            <span class="Comment-author">{{$ctrl.comment.member.name}}</span>
            <span class="Comment-date">| <span title="{{$ctrl.comment.date | moment:dateFormat.withTime}}">{{$ctrl.comment.date | diffNow:true}}</span></span>
          </p>
          <p class="Comment-text" ng-bind-html="$ctrl.comment.text | nl2br"></p>
          <small class="Comment-options">
            <a ng-click="$ctrl.delete()"ng-if="$ctrl.canRemove">remove</a>
          </small>
        </div>
      </div>
    </div>
  `,
  bindings: {
    user: '<',
    comment: '<',
    onDelete: '&',
  },
  controller() {

    /**
     * On init
     */
    this.$onInit = function() {
      this.isOwn = this.user.isSame(this.comment.member);
      this.canRemove = this.isOwn || this.user.isAdmin();
    };

    /**
     * Delete handler
     */
    this.delete = function() {

      //Get comment
      const {comment} = this;
      return this.onDelete({$event: {comment}});
    };
  },
});
