
/**
 * Define values
 */
const COVER_NONE = 'none';
const COVER_PART = 'part';
const COVER_FULL = 'full';

/**
 * Define array
 */
const PaymentFeeCover = [
  {
    value: COVER_NONE,
    label: 'Don’t cover payment processing fees',
  },
  {
    value: COVER_FULL,
    label: 'Fully cover payment processing fees',
  },
  {
    value: COVER_PART,
    label: 'Partially cover payment processing fees',
  },
];

/**
 * Set properties
 */
PaymentFeeCover.NONE = COVER_NONE;
PaymentFeeCover.PART = COVER_PART;
PaymentFeeCover.FULL = COVER_FULL;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.PaymentFeeCover.Options', [])
  .constant('PaymentFeeCover', PaymentFeeCover);
