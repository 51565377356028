
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Circles.InviteCircleMembers.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('inviteCircleMembers', {
    templateUrl: 'admin/people/circles/modals/invite-circle-members.html',
    controller: 'ModalInviteCircleMembersCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalInviteCircleMembersCtrl', function(
  $controller, $modalInstance, $focus, $notice
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Create model subset
    this.model = {
      members: [],
      addDirectly: this.asAdmin,
      isLead: false,
      permissions: Object.assign({}, this.circle.permissions),
    };
  };

  /**
   * Focus
   */
  this.$postLink = function() {
    $focus('members');
  };

  /**
   * Update permissions
   */
  this.updatePermissions = function(property, value) {
    this.model.permissions[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Check if any added members already exist in the cirle
   */
  this.checkMembers = function() {

    //Reset error
    this.existsError = false;

    //Get any matches in the members
    this.existing = this.model.members
      .filter(member => this.circle.hasMember(member))
      .map(member => member.name);

    //Create error message
    if (this.existing.length > 0) {
      this.existsError = true;
    }
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Check if any added members already exist in the cirle
    this.checkMembers();
    if (this.existsError) {
      return;
    }

    //Get just member ids
    const members = this.model.members
      .map(member => member.id);

    //Get permissions and lead flag
    const {permissions, isLead} = this.model;

    //Define handler
    if (this.model.addDirectly) {
      this.handler = function() {
        return this.circle.createMember({members, permissions, isLead})
          .then(() => {
            $notice.show('Circle members added');
          });
      };
    }
    else {
      this.handler = function() {
        return this.circle.invite({members, permissions, isLead})
          .then(() => {
            $notice.show('Circle members invited');
          });
      };
    }

    //Save
    this.isSaving = true;
    this
      .handler([this.members])
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isSaving = false);
  };
});
