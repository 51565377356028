
/**
 * Module definition and dependencies
 */
angular.module('Shared.ConfirmUnsavedChanges.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('confirmUnsavedChanges', {
    templateUrl: 'modals/confirm/confirm-unsaved-changes.html',
    controller: 'ModalCtrl',
  });
});
