
/**
 * Module definition and dependencies
 */
angular.module('Shared.BookingView.Members.Component', [])

/**
 * Booking members component
 */
.component('bookingViewMembers', {
  templateUrl: 'shared/booking-view/members.html',
  bindings: {
    members: '<',
    serviceTags: '<',
    canViewMembers: '<',
    visitors: '<',
    minVisitors: '<',
  },
});
