
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.ChangeMembership.Card', [])

/**
 * Component
 */
.component('cardSubscriptionChangeMembership', {
  templateUrl: 'subscription/cards/change-membership.html',
  require: {
    card: '^^',
  },
  bindings: {
    user: '<',
    memberships: '<',
    subscription: '<',
    isChecking: '<',
    onNext: '&',
    onCancel: '&',
    onChange: '&',
  },
  controller() {

    /**
     * On init
     */
    this.$onInit = function() {

      //Initialize models
      this.membership = null;
      this.linked = {};

      //Get user's age and current memberships
      const {age} = this.user;
      const memberships = this.user.getCurrentMemberships();

      //Filter out memberships
      this.availableMemberships = this.memberships
        .filter(membership => membership.canSelectWhenChanging)
        .filter(membership => membership.appliesToMemberships(memberships))
        .filter(membership => membership.isValidForAge(age));

      //Filter out available primary memberships
      this.availablePrimaryMemberships = this.availableMemberships
        .filter(membership => !membership.isLinked);

      //Get current membership and check if still available
      const current = this.subscription.membership;
      const match = this.availableMemberships.find(m => m.isSame(current));

      //Select current membership if still available
      if (match) {
        this.changeMembership(match);
      }
    };

    /**
     * Notify controller
     */
    this.emitChange = function() {

      //Get data
      const {subscription, membership, linked} = this;

      //Create array of changes
      const changes = [{
        subscriptionId: subscription.id,
        membershipId: membership.id,
      }];

      //Process map
      for (const id in linked) {
        if (linked.hasOwnProperty(id)) {
          changes.push({
            subscriptionId: id,
            membershipId: linked[id] ? linked[id].id : null,
          });
        }
      }

      //Emit change
      this.onChange({$event: {changes}});
    };

    /**
     * Change membership
     */
    this.changeMembership = function(membership) {

      //Set the selected membership
      this.membership = membership;
      this.isErrorMembershipRequired = false;
      this.isErrorMembershipLimit = false;
      this.isErrorNoChange = false;
      this.availableLinkedMemberships = [];
      this.linked = {};

      //Determine the available linked memberships, if any
      if (!membership.isLinked) {
        this.availableLinkedMemberships = this.memberships
          .filter(m => m.isLinked && m.isLinkedTo(membership));
      }

      //Try to select appropriate membership for linked
      for (const sub of this.subscription.linked) {
        const {membership, id} = sub;

        //Loop available options
        if (membership) {
          for (const option of this.availableLinkedMemberships) {
            if (option.suffix === membership.suffix) {
              this.linked[id] = option;
              break;
            }
          }
        }

        //Still nothing?
        if (!this.linked[id]) {
          if (this.availableLinkedMemberships.length > 0) {
            this.linked[id] =
              this.availableLinkedMemberships[0];
          }
          else {
            this.linked[id] = null;
          }
        }
      }

      //Set membership limit error
      this.isErrorMembershipLimit = (membership.limit && (membership.numMembers >= membership.limit));

      //Emit change
      this.emitChange();
    };

    /**
     * Change linked
     */
    this.changeLinked = function(sub, membership) {

      //Set in map and emit change
      this.linked[sub.id] = membership;
      this.isErrorNoChange = false;
      this.emitChange();
    };

    /**
     * Check if we have changed anything
     */
    this.hasChangedAnything = function() {
      if (!this.subscription) {
        return false;
      }
      if (!this.membership) {
        return true;
      }
      if (this.membership.id !== this.subscription.membership.id) {
        return true;
      }
      if (this.subscription.linked.length === 0) {
        return false;
      }
      return this.subscription.linked.some(comp => {
        if (!comp || !comp.membership || !this.linked[comp.id]) {
          return true;
        }
        return this.linked[comp.id].id !== comp.membership.id;
      });
    };

    /**
     * Next step
     */
    this.next = function() {

      //Must have selected a membership
      if (!this.membership) {
        this.isErrorMembershipRequired = true;
        return;
      }

      //Must have changed something
      if (!this.hasChangedAnything()) {
        this.isErrorNoChange = true;
        return;
      }

      //Onwards
      this.onNext();
    };

    /**
     * Cancel flow
     */
    this.cancel = function() {
      this.onCancel();
    };
  },
});
