
/**
 * Module definition and dependencies
 */
angular.module('Shared.Mailchimp.Service', [])

/**
 * Config
 */
.config($apiProvider => {
  $apiProvider.registerEndpoint('mailchimp', {
    url: 'integration/mailchimp',
    actions: {
      getLists: {
        url: 'lists',
        method: 'GET',
        isArray: true,
      },
      getListInterests: {
        url: 'lists/:listId/interests',
        method: 'GET',
        isArray: true,
      },
      getListMergeFields: {
        url: 'lists/:listId/mergeFields',
        method: 'GET',
        isArray: true,
      },
      addListMember: {
        url: 'lists/:listId/member/:memberId',
        method: 'PUT',
      },
      addListMembers: {
        url: 'lists/:listId',
        method: 'PUT',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Mailchimp', function($api, $q) {

  //Create service
  const Mailchimp = {

    /**************************************************************************
     * Raw API calls
     ***/

    /**
     * Get lists
     */
    getLists() {
      return $api.mailchimp.getLists();
    },

    /**
     * Get list interests
     */
    getListInterests(listId) {
      return $api.mailchimp.getListInterests({listId});
    },

    /**
     * Get list merge fields
     */
    getListMergeFields(listId) {
      return $api.mailchimp.getListMergeFields({listId});
    },

    /**
     * Add list member
     */
    addListMember(listId, memberId, interests, forceSubscribe) {
      const data = {interests, forceSubscribe};
      const query = {listId, memberId};
      return $api.mailchimp.addListMember(query, data);
    },

    /**
     * Add list members in bulk
     */
    addListMembers(listId, filter, interests) {
      const data = {interests};
      const query = Object.assign({}, filter, {listId});
      return $api.mailchimp.addListMembers(query, data);
    },

    /**************************************************************************
     * Macro helpers
     ***/

    /**
     * Helper to subscribe a member easily to multiple lists
     */
    addMember(member, lists, forceSubscribe) {

      //No lists
      if (!lists) {
        return $q.resolve();
      }

      //Map lists to promises
      const promises = lists
        .filter(list => list.isChecked)
        .map(list => {

          //Get interests
          const interests = list.categories.reduce((combined, category) => {
            return category.interests
              .filter(interest => interest.isChecked)
              .map(interest => interest.id)
              .concat(combined);
          }, []);

          //Use actual API
          return this
            .addListMember(list.id, member.id, interests, forceSubscribe);
        });

      //Nothing to do?
      if (promises.length === 0) {
        return $q.resolve();
      }

      //Resolve all promises
      return $q.all(promises);
    },

    /**
     * Helper to subscribe members in bulk to multiple lists
     */
    addMembers(filter, lists) {

      //No lists
      if (!lists) {
        return $q.resolve();
      }

      //Map lists to promises
      const promises = lists
        .filter(list => list.isChecked)
        .map(list => {

          //Get interests
          const interests = list.categories.reduce((combined, category) => {
            return category.interests
              .filter(interest => interest.isChecked)
              .map(interest => interest.id)
              .concat(combined);
          }, []);

          //Use actual API
          return this.addListMembers(list.id, filter, interests);
        });

      //Nothing to do?
      if (promises.length === 0) {
        return $q.resolve();
      }

      //Resolve all promises
      return $q.all(promises);
    },

    /**
     * Get combined lists and interests
     */
    getListsAndInterests() {

      //Load lists
      return this
        .getLists()
        .then(lists => {

          //Load interests for each list
          const promises = lists.map(list => this.getListInterests(list.id));

          //Await promises
          return $q
            .all(promises)
            .then(results => {

              //Append to lists
              results.forEach((categories, i) => {
                lists[i].categories = categories;
              });

              //Return lists
              return lists;
            });
        });
    },

    /**
     * Get combined lists and merge fields
     */
    getListsAndMergeFields() {

      //Load lists
      return this
        .getLists()
        .then(lists => {

          //Load interests for each list
          const promises = lists.map(list => this.getListMergeFields(list.id));

          //Await promises
          return $q
            .all(promises)
            .then(results => {

              //Append to lists
              results.forEach((mergeFields, i) => {
                lists[i].mergeFields = mergeFields;
              });

              //Return lists
              return lists;
            });
        });
    },

    /**
     * Toggle defaults in a list
     */
    toggleDefaults(lists, defaultLists) {

      //No default lists? Nothing to do
      if (!Array.isArray(defaultLists) || defaultLists.length === 0) {
        return;
      }

      //Loop actual lists
      lists.forEach(list => {

        //Get list data and find match in defaults
        const {categories, id} = list;
        const dList = defaultLists.find(item => item.id === id);
        if (!dList) {
          list.isChecked = false;
          return;
        }

        //Copy checked flag
        list.isChecked = dList.isChecked;

        //Process categories
        categories.forEach(category => {

          //Get data and find match in defaults
          const {interests, id} = category;
          const dCategory = dList.categories.find(item => item.id === id);
          if (!dCategory) {
            return;
          }

          //Process interests
          interests.forEach(interest => {

            //Get data and find match in defaults
            const {id} = interest;
            const dInterest = dCategory.interests.find(item => item.id === id);
            if (!dInterest) {
              interest.isChecked = false;
              return;
            }

            //Copy checked flag
            interest.isChecked = dInterest.isChecked;
          });
        });
      });
    },
  };

  //Return service
  return Mailchimp;
});
