
/**
 * Define values
 */
const TAX_TYPE_GST = 'gst';
const TAX_TYPE_VAT = 'vat';
const TAX_TYPE_SALES = 'sales';
const TAX_TYPE_OTHER = 'other';
const TAX_TYPE_NONE = 'none';

/**
 * Define array
 */
const TaxTypes = [
  {
    value: TAX_TYPE_GST,
    label: 'GST',
  },
  {
    value: TAX_TYPE_VAT,
    label: 'VAT',
  },
  {
    value: TAX_TYPE_SALES,
    label: 'Sales tax',
  },
  {
    value: TAX_TYPE_OTHER,
    label: 'Other',
  },
  {
    value: TAX_TYPE_NONE,
    label: 'None',
  },
];

/**
 * Set properties
 */
TaxTypes.GST = TAX_TYPE_GST;
TaxTypes.VAT = TAX_TYPE_VAT;
TaxTypes.SALES = TAX_TYPE_SALES;
TaxTypes.OTHER = TAX_TYPE_OTHER;
TaxTypes.NONE = TAX_TYPE_NONE;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.TaxTypes.Options', [])
  .constant('TaxTypes', TaxTypes);
