
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.GoogleCalendar.Status.Card', [])

/**
 * Component
 */
.component('cardIntegrationsGoogleCalendarStatus', {
  templateUrl: 'admin/integrations/google-calendar/status.html',
  controller: 'CardIntegrationsGoogleCalendarStatusCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    isAvailable: '<',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsGoogleCalendarStatusCtrl', function(
  $controller
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.hasEnabledCalendars = (
      Array.isArray(this.integration.data.enabledCalendars) &&
      this.integration.data.enabledCalendars.length > 0
    );
    this.isSetupComplete = (this.hasEnabledCalendars);
  };
});
