
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Add.Events.Card', [])

/**
 * Component
 */
.component('cardMembershipAddEvents', {
  templateUrl: 'admin/membership/cards/events.html',
  controller: 'CardMembershipAddEventsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    membership: '<',
    couponTab: '<',
    onMerge: '&',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipAddEventsCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMembershipEditEventsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Flags
    this.isEdit = false;
    this.isAdd = true;

  };

  /**
   * Merge
   */
  this.merge = function() {
    const {model, membership} = this;
    this.onMerge({$event: {membership, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge
    this.merge();

    //Go to next relevant step
    this.card.next();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();

    //Check if coupons tab is enabled
    if (this.couponTab) {
      //Go to previous tab
      this.card.previousTab();
    }
    else {
      //Go to first tab
      this.card.firstTab();
    }
  };
});
