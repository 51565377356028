
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Controller', [])

/**
 * Base controller
 */
.controller('AdminCouponTypeCtrl', function(
  $q, $modal, $notice, Intercom
) {

  /**
   * Save
   */
  this.save = function($event) {

    //Get couponType and model
    const {couponType, model, isEdit} = $event;
    const {singular} = this.module;

    //Save
    return couponType
      .save(model)
      .then(couponType => {
        if (isEdit) {
          $notice.show(`${singular} updated`);
        }
        else {
          Intercom.event('Created coupon type');
          $notice.show(`${singular} added`);
          this.club.markStepComplete('setupCouponTypes', true);
        }
        this.onSaved({couponType});
      })
      .catch(error => {
        if (isEdit) {
          $notice.showError(`Failed to update ${singular}`);
        }
        else {
          $notice.showError(`Failed to add ${singular}`);
        }
        throw error;
      });
  };

  /**
   * Merge
   */
  this.merge = function($event) {

    //Get couponType and model
    const {couponType, model} = $event;
    this.isDirty = true;

    //Merge
    couponType.merge(model);
    return $q.resolve();
  };

  /**
   * Delete
   */
  this.delete = function($event) {

    //Get couponType and define handler
    const {couponType} = $event;
    const {singular} = this.module;
    const handler = () => couponType.delete();

    //Open confirmation dialog
    $modal
      .open('basic', {
        templateUrl: `admin/coupon-type/modals/confirm-delete-coupon-type.html`,
        locals: {couponType, handler},
      })
      .result
      .then(() => {
        $notice.show(`${singular} removed`);
        this.onDeleted({couponType});
      });
  };

  /**
   * Archive/restore
   */
  this.toggleArchived = function($event) {

    //Get membership and flag
    const {couponType, isArchived} = $event;
    const {singular} = this.module;

    //Define handler
    const handler = function() {
      return couponType.patch({isArchived});
    };

    //Show confirmation modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/coupon-type/modals/confirm-toggle-archived.html',
        locals: {couponType, handler},
      })
      .result
      .then(() => {
        $notice.show(isArchived ? `${singular} archived` : `${singular} restored`);
        this.onPatched({couponType, field: 'isArchived'});
      });
  };

  /**
   * No-ops (to extend as needed)
   */
  this.onSaved = function() {};
  this.onPatched = function() {};
  this.onDeleted = function() {};
});
