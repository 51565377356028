
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Add.Details.Card', [])

/**
 * Component
 */
.component('cardCouponTypeAddDetails', {
  templateUrl: 'admin/coupon-type/cards/details.html',
  controller: 'CardCouponTypeAddDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    couponType: '<',
    onSave: '&',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardCouponTypeAddDetailsCtrl', function(
  $controller, $element, $focus
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardCouponTypeEditDetailsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark flags
    this.isAdd = true;
    this.isEdit = false;

    //Focus on first input
    $focus($element.find('input')[0]);
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {couponType, model} = this;
    this.onMerge({$event: {couponType, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next card
    this.merge();
    this.card.next();
  };
});
