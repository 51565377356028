
/**
 * Module definition and dependencies
 */
angular.module('Shared.Avatar.Card', [])

/**
 * Component
 */
.component('cardAvatar', {
  templateUrl: 'cards/avatar.html',
  controller: 'CardAvatarCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    member: '<',
    onUpload: '&',
    onChange: '&',
    onDelete: '&',
  },
})

/**
 * Controller
 */
.controller('CardAvatarCtrl', function($controller, Colors, Config) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardImageCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Pretend we have a current
    this.current = this.member.avatar;

    //Default pattern and accept parameters
    this.accept = 'image/jpeg,image/jpg,image/png';
    this.pattern = '.jpg,.jpeg,.png';

    //Call parent init
    $base.$onInit.call(this);

    //Quality and size settings
    this.maxHeight = this.maxWidth = Config.image.avatar.maxSize;
    this.quality = Config.image.avatar.quality;
    this.centerCrop = true;
    this.fixedRatio = true;

    //Other vars
    this.avatarColor = this.member.color;

    //Flags
    this.isPickingColor = false;
    this.isUpdatingColor = false;
    this.isSelecting = false;
    this.isViewingCurrent = true;

    //Available colors
    this.colors = Colors;
  };

  /**
   * Change color
   */
  this.changeColor = function() {
    this.isPickingColor = true;
    this.isViewingCurrent = false;
  };

  /**
   * Preview a color
   */
  this.previewColor = function(color) {
    this.card.setDirty(true);
    this.avatarColor = color;
  };

  /**
   * Confirm color
   */
  this.confirmColor = function() {
    this.isUpdatingColor = true;
    this
      .onChange({$event: {color: this.avatarColor}})
      .then(() => {
        this.isUpdatingColor = false;
        this.viewCurrent();
      });
  };

  /**
   * View current
   */
  this.viewCurrent = function() {
    this.isPickingColor = false;
    $base.viewCurrent.call(this);
  };

  /**
   * Delete image
   */
  this.deleteImage = function() {
    this
      .onDelete({$event: {}})
      .then(() => this.viewCurrent());
  };

  /**
   * Cancel button
   */
  this.cancel = function() {

    //Were we picking a color?
    if (this.isPickingColor) {
      this.avatarColor = this.member.color;
    }

    //Call base controller method
    $base.cancel.call(this);
  };
});
