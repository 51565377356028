
/**
 * Module definition and dependencies
 */
angular.module('Shared.AnchorPeriods.Options', [])

/**
 * Constant definition
 */
.constant('AnchorPeriods', [
  {
    value: 'week',
    label: 'Fixed day of the week (e.g. Mondays)',
  },
  {
    value: 'month',
    label: 'Fixed day of the month (e.g. the 15th)',
  },
  {
    value: 'year',
    label: 'Fixed day of the year (e.g. 1 January)',
  },
]);
