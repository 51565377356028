
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.List.Card', [])

/**
 * Component
 */
.component('cardCouponList', {
  templateUrl: 'coupon/cards/list.html',
  controller: 'CardCouponListCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    coupons: '<',
    onView: '&',
  },
})

/**
 * Controller
 */
.controller('CardCouponListCtrl', function() {

  /**
   * View coupon
   */
  this.view = function(coupon) {
    this.onView({$event: {coupon}});
  };
});
