
/**
 * Module definition and dependencies
 */
angular.module('Shared.Coupon.Model', [
  'BaseModel.Service',
  'Shared.Coupon.Model',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('coupon', {
    model: 'Coupon',
    actions: {
      own: {
        url: 'own',
        method: 'GET',
        dataKey: 'coupons',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      query: {
        method: 'GET',
        dataKey: 'coupons',
        isArray: true,
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      useSessions: {
        url: 'useSessions',
        method: 'PUT',
      },
      refundSession: {
        url: 'refundSession',
        method: 'PUT',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
      purchase: {
        url: 'purchase',
        method: 'POST',
        isArray: true,
        isModel: true,
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('coupons', {
    model: 'Coupon',
    dataKey: 'coupons',
  });
})

/**
 * Model definition
 */
.factory('Coupon', function($baseModel, $api, moment, CouponTypeUses) {

  /**
   * Constructor
   */
  function Coupon(data) {
    $baseModel.call(this, data);

    /**
     * Number of sessions purchased virtual property
     */
    Object.defineProperty(this, 'numSessionsPurchased', {
      get() {
        return (this.numSessionsLeft + this.numSessionsUsed);
      },
    });

    /**
     * Activity specific session limits
     */
    Object.defineProperty(this, 'hasActivitySessionLimits', {
      get() {
        return this.activities.some(activity => activity.numSessions > 0);
      },
    });

    /**
     * Is spent virtual property
     */
    Object.defineProperty(this, 'isSpent', {
      get() {
        return (this.numSessionsLeft === 0);
      },
    });

    /**
     * Is expired virtual property
     */
    Object.defineProperty(this, 'isExpired', {
      get() {
        return (this.expiryDate && moment().isAfter(this.expiryDate));
      },
    });

    /**
     * Status virtual property
     */
    Object.defineProperty(this, 'status', {
      get() {
        if (this.isSpent) {
          return 'spent';
        }
        if (this.isExpired) {
          return 'expired';
        }
        if (!this.isActive) {
          return 'inactive';
        }
        return 'active';
      },
    });

    //Usable for text
    Object.defineProperty(this, 'usableForText', {
      get() {
        const {usableFor, numSessions} = this;
        if (numSessions === 0) {
          return 'One off purchase';
        }
        if (usableFor.length === 0) {
          return 'None';
        }
        return usableFor
          .map(use => CouponTypeUses.find(item => item.value === use))
          .map(use => use.label)
          .join(', ');
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Coupon.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Coupon.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('member', 'Member');
    this.convertToModel('sessions', null, true);

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Coupon.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Strip data
    json.member = $baseModel.onlyId(json.member);

    //Don't save sessions
    delete json.sessions;

    //Return json
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Mark used
   */
  Coupon.prototype.useSessions = function(data) {
    const {id} = this;
    return $api.coupon
      .useSessions({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Refund session
   */
  Coupon.prototype.refundSession = function(index) {
    const {id} = this;
    return $api.coupon
      .refundSession({id}, {index})
      .then(data => this.fromJSON(data));
  };

  /**
   * Save
   */
  Coupon.prototype.save = function(data, meta = {}) {

    //Only for new coupons
    if (this.id) {
      throw new Error(`Can only use save() for new coupons, use patch() instead`);
    }

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Call API
    return $api.coupon
      .create(meta, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  Coupon.prototype.patch = function(data) {

    //Initialize data
    const {id} = this;

    //Call API
    return $api.coupon
      .patch({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Coupon.prototype.delete = function() {
    return $api.coupon.delete(null, this)
      .then(() => this);
  };

  /**
   * Get coupon activity
   */
  Coupon.prototype.getActivity = function(activity) {
    if (!activity) {
      return null;
    }
    const activityId = (typeof activity === 'object') ? activity.id : activity;
    return this.activities.find(a => a.id === activityId);
  };

  /**
   * Get sessions left for activity
   */
  Coupon.prototype.getSessionsLeftForActivity = function(activity) {

    //Get data
    const {numSessionsLeft} = this;

    //No activity provided
    if (!activity) {
      return numSessionsLeft;
    }

    //Find the activity on the coupon
    const couponActivity = this.getActivity(activity);
    if (!couponActivity) {
      return numSessionsLeft;
    }

    //Number of sessions left on the coupon itself
    const values = [numSessionsLeft];

    //Is there a limit on the activity, consider that too
    if (couponActivity.numSessions > 0) {
      values.push(couponActivity.numSessionsLeft);
    }

    //Return the lowest value
    return Math.min(...values);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query coupons
   */
  Coupon.query = function(filter) {
    return $api.coupon
      .query(filter)
      .then(data => data.coupons);
  };

  /**
   * Query own coupons
   */
  Coupon.own = function(filter) {
    return $api.coupon
      .own(filter)
      .then(data => data.coupons);
  };

  /**
   * Find by ID
   */
  Coupon.findById = function(id) {
    return $api.coupon.get({id});
  };

  /**
   * Purchase
   */
  Coupon.purchase = function(selection) {
    return $api.coupon.purchase({selection});
  };

  //Return
  return Coupon;
});
