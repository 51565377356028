
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.ChangePlan.Card', [])

/**
 * Component
 */
.component('cardClubChangePlan', {
  templateUrl: 'admin/club/cards/change-plan.html',
  controller: 'CardClubChangePlanCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    plans: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubChangePlanCtrl', function(
  $timeout, $stripe, CardCloseReasons, Config, ErrorCodes
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;
    this.isSuper = this.user.isSuper();
    this.canMakeChanges = (this.isSuper || this.user.isAccountOwner);

    //Prepare model
    this.model = {};
    if (this.club.stripe && this.club.stripe.billingInterval) {
      this.toggleYearly(this.club.stripe.billingInterval === 'year');
    }
    else {
      this.toggleYearly(true);
    }

    //Set initial plan
    this.plan = this.plans.find(plan => plan.id === this.club.plan.id);
    this.model.planId = this.plan.id;

    //Determine currency and tax
    this.determineCurrency(this.plan);
    this.determineTax(this.plan);
  };

  /**
   * Post link
   */
  this.$postLink = function() {

    //Don't have a card yet? Mount card element
    if (!this.club.hasPaymentSources && !this.club.plan.isFree) {
      this.mountCardElement();
    }
  };

  /**
   * Update billing interval
   */
  this.updateBillingInterval = function(billingInterval) {
    this.model.billingInterval = billingInterval;
  };

  /**
   * Change plan
   */
  this.changePlan = function() {
    this.plan = null;
  };

  /**
   * Cancel changing plan
   */
  this.resetPlan = function() {
    if (!this.club.hasPaymentSources) {
      this.mountCardElement();
    }
    this.plan = this.plans.find(plan => plan.id === this.club.plan.id);
  };

  /**
   * Update plan
   */
  this.updatePlan = function(plan) {

    //String given
    if (typeof plan === 'string') {
      plan = this.plans.find(p => p.identifier === plan);
    }

    //No plan or free plan
    if (!plan || plan.isFree) {
      return;
    }

    //Check if plan changed
    this.hasPlanChanged = (this.club.plan.id !== plan.id);

    //Can't downgrade while paying annually for the time being
    if (this.club.isPayingYearly && plan.isDowngrade(this.club.plan)) {
      this.planDowngradeYearlyError = true;
      return;
    }

    //Mount card element if we have no payment methods yet
    if (!this.club.hasPaymentSources) {
      this.mountCardElement();
    }

    //Set in model and clear plan error
    this.plan = plan;
    this.planError = null;
    this.planDowngradeYearlyError = false;
    this.model.planId = plan.id;

    //Determine currency code and symbol
    this.determineCurrency(plan);
  };

  /**
   * Determine currency
   */
  this.determineCurrency = function(plan) {
    this.currencyCode = plan.pricing.currency.code;
    this.currencySymbol = plan.pricing.currency.symbol;
    if (this.currencyCode === this.currencySymbol) {
      this.currencyCode = '';
    }
  };

  /**
   * Determine tax
   */
  this.determineTax = function(plan) {
    this.hasTax = plan.pricing.hasTax;
    this.taxRate = plan.pricing.taxRate;
    this.taxName = plan.pricing.taxName;
  };

  /**
   * Extend trial
   */
  this.extendTrial = function() {

    //Already extending
    if (this.isExtending) {
      return;
    }

    //Set flag
    this.isExtending = true;

    //Extend trial
    this.club
      .extendTrial()
      .then(() => this.isTrialExtended = true)
      .finally(() => this.isExtending = false);
  };

  /**
   * Toggle yearly
   */
  this.toggleYearly = function(isYearly) {
    if (typeof isYearly === 'undefined') {
      isYearly = !this.isYearly;
    }
    this.isYearly = isYearly;
    this.model.billingInterval = (isYearly ? 'year' : 'month');
    this.hasTermChanged = (
      !this.club.stripe ||
      this.club.stripe.billingInterval !== this.model.billingInterval
    );
  };

  /**
   * Mount card element
   */
  this.mountCardElement = function() {

    //Wrap to capture errors as card errors
    try {

      //Get stripe service and create card
      const stripe = $stripe.service(Config.stripe.publicKey);
      const elements = stripe.elements();
      const card = elements.create('card');

      //Store stripe service for future use
      this.stripe = stripe;

      //Apply listener
      card.addEventListener('change', ({error, complete}) => {
        $timeout(() => {
          this.form.$setDirty();
          this.hasEnteredCard = complete;
          this.cardError = error ? error.message : '';
        });
      });

      //Mount element
      $timeout(() => card.mount('#CardElement'));

      //Store card element
      this.cardElement = card;
    }
    catch (error) {
      this.cardError = error.message;
    }
  };

  /**
   * Create billing setup intent
   */
  this.createSetupIntent = async function() {

    //Get data
    const {club, plan, hasEnteredCard, wantsToChangeCard} = this;

    //Not entered card and no existing sources? Check if payment is required
    if (!club.hasPaymentSources && !hasEnteredCard) {
      if (plan.isFree || club.isPayingByInvoice) {
        return null;
      }
      throw new Error(`Please enter your card details`);
    }

    //Not changing or adding card
    if (club.hasPaymentSources && !wantsToChangeCard) {
      return null;
    }

    //Get additional data
    const stripe = $stripe.service(Config.stripe.publicKey);
    const {cardElement} = this;

    //Create setup intent and confirm it
    const {clientSecret} = await club.createBillingSetupIntent();
    const {setupIntent, error} = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {card: cardElement},
    });

    //Failed
    if (error) {
      throw error;
    }

    //Extract the payment method ID now from the payment intent
    //NOTE: This is done because the card is not automatically set as the
    //default. So we obtain the payment method ID here so that we can pass
    //it to the server for it to set it as the default
    const {payment_method: sourceId} = setupIntent;

    //Success
    return sourceId;
  };

  /**
   * Change card
   */
  this.changeCard = function() {
    this.wantsToChangeCard = !this.wantsToChangeCard;
    if (this.wantsToChangeCard) {
      this.mountCardElement();
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Already saving
    if (this.isSaving) {
      return;
    }

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving and clear errors
    this.isSaving = true;
    this.cardError = null;
    this.planError = null;

    //Get data
    const {model} = this;

    //Save
    this
      .createSetupIntent()
      .then(sourceId => {
        model.sourceId = sourceId;
        return this.onSave({$event: {model, sourceId}});
      })
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .catch(error => {

        //Plan permission errors
        if (error.code === ErrorCodes.planLimits) {
          $timeout(() => {
            this.planError = error;

            //Supress plan warnings for an hour to give them a chance to
            //correct these plan downgrade errors. Setting this on config
            //for now, but when we refactor to Vue it should go in a global
            //session thingy
            Config.supressPlanWarnings = true;
            $timeout(() => {
              Config.supressPlanWarnings = false;
            }, 60 * 1000);
          });
          return;
        }

        //Capture exceptions
        if (error instanceof Error) {
          Sentry.captureException(error);
        }

        //Set card error
        $timeout(() => this.cardError = error.message);
      })
      .finally(() => this.isSaving = false);
  };
});
