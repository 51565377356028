
/**
 * Module definition and dependencies
 */
angular.module('Shared.AccessLog.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('accessLog', {
    model: 'AccessLog',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'accessLogs',
        isArray: true,
        isModel: true,
      },
    },
  });
})

/**
 * Model definition
 */
.factory('AccessLog', function($baseModel, $api, $sync) {

  /**
   * Constructor
   */
  function AccessLog(data) {
    $baseModel.call(this, data);

    //Unknown property
    Object.defineProperty(this, 'isUnknown', {
      get() {
        return (!this.event && !this.member && !this.tag);
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(AccessLog.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  AccessLog.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('tag', 'Tag', false);
    this.convertToModel('member', 'Member', false);

    //Return self
    return this;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Check if has member
   */
  AccessLog.prototype.hasMember = function(memberId) {
    return (this.member && this.member.id === memberId);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  AccessLog.query = function(filter) {
    return $api.accessLog.query(filter);
  };

  /**
   * Export
   */
  AccessLog.export = function(filter) {
    return $sync.get('accessLog/export/csv', filter, 'Exporting...');
  };

  //Return
  return AccessLog;
});
