
/**
 * Module definition and dependencies
 */
angular.module('Shared.PaymentFeeExample.Component', [])

/**
 * Fee example component
 */
.component('paymentFeeExample', {
  template: `
    <p ng-if="!$ctrl.isCustom">
      Fee: {{$ctrl.fee | currency}}<br>
      {{modules.members.singular | ucfirst}} pays: {{$ctrl.memberCharge | currency}}<br>
      Club
        <span ng-if="$ctrl.clubPayout >= 0">receives: {{$ctrl.clubPayout | currency}}</span>
        <span ng-if="$ctrl.clubPayout < 0">pays: {{$ctrl.clubPayout * -1 | currency}}</span>
    </p>
    <p ng-if="$ctrl.isCustom">N/A</p>
  `,
  bindings: {
    amount: '<',
    fees: '<',
    isCustom: '<',
    coverStrategy: '<',
    coverStructure: '<*',
  },
  controller(PaymentFees) {

    /**
     * On changes
     */
    this.$onChanges = function() {
      this.calculate();
    };

    /**
     * Calculate
     */
    this.calculate = function() {

      //Get data
      //NOTE: Need to build up cover because it won't be deep watched by Angular
      const {amount, coverStrategy, coverStructure} = this;
      const cover = {strategy: coverStrategy, structure: coverStructure};
      const fees = PaymentFees.merge(this.fees.provider, this.fees.platform);

      //Determine values
      const goal = PaymentFees.determineGoal(amount, fees, cover);
      const charge = PaymentFees.determineCharge(amount, goal, fees);
      const fee = PaymentFees.determineFee(charge, fees);

      //Set values
      this.clubPayout = goal;
      this.memberCharge = charge;
      this.fee = fee;
    };
  },
});
