
/**
 * Module definition and dependencies
 */
angular.module('Shared.DobInput.Component', [])

/**
 * Time input component
 */
.directive('dobInput', function(moment, $focus) {

  //Directive
  return {
    restrict: 'E',
    template: `
      <div class="DobInput">
        <span class="DobInput-input DobInput-input--day" ng-class="{'is-inverted': isInverted}"><input class="Input" ng-disabled="isDisabled" type="tel" ng-model="day" placeholder="DD"></span>
        <span class="DobInput-input DobInput-input--month" ng-class="{'is-inverted': isInverted}"><input class="Input" ng-disabled="isDisabled" type="tel" ng-model="month" placeholder="MM"></span>
        <span class="DobInput-input DobInput-input--year" ng-class="{'is-inverted': isInverted}"><input class="Input" ng-disabled="isDisabled" type="tel" ng-model="year" placeholder="YYYY"></span>
      </div>
    `,
    require: 'ngModel',
    scope: {
      dob: '=ngModel',
      isDisabled: '<ngDisabled',
      countryCode: '@',
    },

    /**
     * Link
     */
    link(scope, element, attrs, ctrl) {

      //Get input elements
      const $input = element.find('input');
      const $day = angular.element($input[0]);
      const $month = angular.element($input[1]);
      const $year = angular.element($input[2]);

      //Check if inverted
      const isInverted = (attrs.countryCode === 'US');
      scope.isInverted = isInverted;

      //Updated view trigger
      function updateView() {
        ctrl.$setViewValue([$day.val(), $month.val(), $year.val()]);
      }

      //Get value with prepended zero
      function prependZero(value) {
        value = Number(value);
        if (isNaN(value) || value === 0) {
          return '';
        }
        if (value && value < 10) {
          return '0' + String(value);
        }
        return String(value);
      }

      //Auto prepend input value with zero
      function autoPrependZero() {
        const $this = angular.element(this);
        $this.val(prependZero($this.val()));
        updateView();
      }

      //Auto prepend year with 19
      function fourDigitYear() {
        const $this = angular.element(this);
        const year = prependZero($this.val());
        if (year.length === 2) {
          const current = moment().year() - 2000;
          const prefix = (Number(year) > current) ? 19 : 20;
          $this.val(`${prefix}${year}`);
        }
        updateView();
      }

      //Advance to month field
      function advanceToDay() {
        const value = angular.element(this).val();
        if (value.length === 2) {
          $focus($day);
        }
      }

      //Advance to month field
      function advanceToMonth() {
        const value = angular.element(this).val();
        if (value.length === 2) {
          $focus($month);
        }
      }

      //Advance to year field
      function advanceToYear() {
        const value = angular.element(this).val();
        if (value.length === 2) {
          $focus($year);
        }
      }

      //Apply event handlers
      $day
        .on('change', autoPrependZero)
        .on('input', isInverted ? advanceToYear : advanceToMonth)
        .on('input', updateView);
      $month
        .on('change', autoPrependZero)
        .on('input', isInverted ? advanceToDay : advanceToYear)
        .on('input', updateView);
      $year
        .on('change', fourDigitYear)
        .on('input', updateView);

      //Clean up listeners on scope destroy
      scope.$on('$destroy', () => {
        $day
          .off('change', autoPrependZero)
          .off('input', advanceToMonth)
          .off('input', updateView);
        $month
          .off('change', autoPrependZero)
          .off('input', advanceToYear)
          .off('input', updateView);
        $year
          .off('change', fourDigitYear)
          .off('input', updateView);
      });

      //Create dob moment
      const today = moment().startOf('day');
      const dob = today.clone();
      const currentYear = today.year();

      //Watch individual values to run validators
      scope.$watchGroup(['day', 'month', 'year'], () => {
        ctrl.$validate();
      });

      //Formatter
      ctrl.$formatters.push(model => {

        //Set individual values in scope
        scope.day = model ? prependZero(model.date()) : '';
        scope.month = model ? prependZero(model.month() + 1) : '';
        scope.year = model ? model.year() : '';

        //Return view value
        return [scope.day, scope.month, scope.year];
      });

      //Custom required validator
      //eslint-disable-next-line no-unused-vars
      ctrl.$validators.required = function(modelValue, viewValue) {
        if (attrs.required) {
          const [day, month, year] = viewValue || ['', '', ''];
          return !(
            ctrl.$isEmpty(day) || ctrl.$isEmpty(month) || ctrl.$isEmpty(year)
          );
        }
        return true;
      };

      //Check if has entered anything
      function hasEnteredAnything(viewValue) {
        if (!Array.isArray(viewValue)) {
          return false;
        }
        return viewValue.some(value => value !== '');
      }

      //Day/month/year validators

      //eslint-disable-next-line no-unused-vars
      ctrl.$validators.day = function(modelValue, viewValue) {
        if (ctrl.$error.required) {
          return true;
        }
        if (!hasEnteredAnything(viewValue)) {
          return true;
        }
        const day = viewValue ? Number(viewValue[0]) : 0;
        return !(isNaN(day) || day < 1 || day > 31);
      };

      //eslint-disable-next-line no-unused-vars
      ctrl.$validators.month = function(modelValue, viewValue) {
        if (ctrl.$error.required) {
          return true;
        }
        if (!hasEnteredAnything(viewValue)) {
          return true;
        }
        const month = viewValue ? Number(viewValue[1]) : 0;
        return !(isNaN(month) || month < 1 || month > 12);
      };

      //eslint-disable-next-line no-unused-vars
      ctrl.$validators.year = function(modelValue, viewValue) {
        if (ctrl.$error.required) {
          return true;
        }
        if (!hasEnteredAnything(viewValue)) {
          return true;
        }
        const year = viewValue ? Number(viewValue[2]) : 0;
        return !(isNaN(year) || year < 1900 || year > currentYear);
      };

      //Parser and future validator
      ctrl.$parsers.unshift(([day, month, year]) => {

        //Reset validity
        ctrl.$setValidity('future', true);

        //Convert to numbers
        day = Number(day);
        month = Number(month);
        year = Number(year);

        //Check if valid (basic)
        if (
          !day || !month || !year ||
          isNaN(day) || isNaN(month) || isNaN(year)
        ) {
          return null;
        }

        //Setup moment
        dob.year(year).month(month - 1).date(day);

        //Check if not in future
        if (dob.isAfter(today)) {
          ctrl.$setValidity('future', false);
          return null;
        }

        //Return dob as moment
        return dob;
      });
    },
  };
});
