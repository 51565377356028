
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.MarkAsPaid.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('markBookingAsPaid', {
    templateUrl: 'booking/modals/mark-as-paid.html',
    controller: 'ModalMarkBookingAsPaidCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalMarkBookingAsPaidCtrl', function(
  $controller, $modalInstance, moment, PaymentMethods
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.isSaving = false;
    this.isError = false;

    //Filter payment methods
    this.paymentMethods = PaymentMethods
      .filter(method => !method.isOnline && !method.isAccountCredit);

    //Max date for paid date is today
    this.maxDate = moment().startOf('day');

    //Get paying member and initial date
    const date = moment().startOf('day');
    const member = this.booking.hasMembers ?
      this.booking.members[0].id : null;

    //Initialize model
    this.model = {date, member};
  };

  /**
   * Mark paid
   */
  this.markPaid = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Get data and create meta object
    //TODO: Clean this up when migrating to VUE
    const {method, date, member} = this.model;
    const meta = {member, payment: {method, date}};

    //Use save handler
    this
      .handler({meta})
      .then(booking => $modalInstance.resolve(booking))
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };
});
