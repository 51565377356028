
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.Nav.Component', [])

/**
 * Booking nav component
 */
.component('bookingGridNav', {
  template: `
    <h1 class="BookingGridNav">
      <button
        class="BookingGridNav-button Button Button--muted"
        ng-click="$ctrl.onPreviousDay()"
        ng-disabled="$ctrl.isDisabledPreviousDay"
      ><i class="Icon">chevron_left</i></button>
      <span class="BookingGridNav-date" ng-click="$ctrl.onDatePicker()">
        <strong>{{$ctrl.date | moment: 'dddd'}}</strong> <span class="BookingGridNav-month BookingGridNav-month--full">{{$ctrl.date | moment: 'D MMMM'}}</span><span class="BookingGridNav-month BookingGridNav-month--short">{{$ctrl.date | moment: 'D MMM'}}</span>
      </span>
      <button
        class="BookingGridNav-button Button Button--muted"
        ng-click="$ctrl.onNextDay()"
        ng-disabled="$ctrl.isDisabledNextDay"
      ><i class="Icon">chevron_right</i></button>
    </h1>
  `,
  bindings: {
    date: '<',
    isDisabledNextDay: '<',
    isDisabledPreviousDay: '<',
    onNextDay: '&',
    onPreviousDay: '&',
    onDatePicker: '&',
  },
});
