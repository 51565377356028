
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members', [
  'App.Admin.People.Members.Add',
  'App.Admin.People.Members.Edit',
  'App.Admin.People.Members.Overview',
  'App.Admin.People.Members.Controller',
  'App.Admin.People.Members.AddCoupon.Modal',
  'App.Admin.People.Members.AddGrade.Modal',
  'App.Admin.People.Members.AddPaymentSource.Modal',
  'App.Admin.People.Members.EditActivity.Modal',
  'App.Admin.People.Members.AddSubscription.Modal',
  'App.Admin.People.Members.EditSubscription.Modal',
  'App.Admin.People.Members.StopSubscription.Modal',
  'App.Admin.People.Members.PauseSubscription.Modal',
  'App.Admin.People.Members.RenewSubscription.Modal',
  'App.Admin.People.Members.MailingList.Modal',
  'App.Admin.People.Members.MailingListBulk.Modal',
  'App.Admin.People.Members.WelcomeEmail.Modal',
  'App.Admin.People.Members.WelcomeEmails.Modal',
  'App.Admin.People.Members.ViewGrade.Modal',
  'App.Admin.People.Members.ViewMember.Modal',
  'App.Admin.People.Members.MemberOptions.Component',
  'App.Admin.People.Members.Transactions.Card',
  'App.Admin.People.Members.Payments.Card',
  'App.Admin.People.Members.PaymentSources.Card',
  'App.Admin.People.Members.AccountCredit.Card',
  'App.Admin.People.Members.Subscriptions.Card',
  'App.Admin.People.Members.Coupons.Card',
  'App.Admin.People.Members.Resources.Card',
  'App.Admin.People.Members.MemberFields.Constant',
  'App.Admin.People.Members.EditCircle.Modal',
  'App.Admin.People.Circles.InviteCircleMembers.Modal',
  'App.Admin.People.Circles.ViewCircleMember.Modal',
  'App.Admin.People.Circles.ManagePermissions.Modal',
  'App.Admin.People.Members.MergeMembers.Modal',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.members', {
    url: '/members',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'members',
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.people.members.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.people.members.overview', title});
  }, {priority: 5});
});
