
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.Maintenance.Card', [])

/**
 * Component
 */
.component('cardSystemMaintenance', {
  templateUrl: 'admin/system/cards/maintenance.html',
  controller: 'CardSystemMaintenanceCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    system: '<',
    isSuper: '<',
  },
})

/**
 * Controller
 */
.controller('CardSystemMaintenanceCtrl', function($notice) {

  /**
   * Sync
   */
  this.sync = function() {

    //Set flag
    this.isSyncing = true;

    //Sync
    this.system
      .sync()
      .then(() => $notice.show(`Synchronisation started`))
      .catch(() => $notice.showError(`Failed to synchronise data`))
      .finally(() => this.isSyncing = false);
  };

  /**
   * Refresh
   */
  this.refresh = function() {

    //Set flag
    this.isRefreshingScreens = true;

    //Reboot
    this.system
      .refresh()
      .then(() => $notice.show(`Screens refreshed`))
      .catch(() => $notice.showError(`Failed to refresh screens`))
      .finally(() => this.isRefreshingScreens = false);
  };

  /**
   * Reboot
   */
  this.reboot = function() {

    //Set flag
    this.isRebootingPi = true;

    //Reboot
    this.system
      .reboot()
      .then(() => $notice.show(`Restarting controller...`))
      .catch(() => $notice.showError(`Failed to restart controller`))
      .finally(() => this.isRebootingPi = false);
  };

  /**
   * Update
   */
  this.update = function() {

    //Set flag
    this.isUpdating = true;

    //Update
    this.system
      .update()
      .then(() => $notice.show(`Updating controller...`))
      .catch(() => $notice.showError(`Failed to update controller`))
      .finally(() => this.isUpdating = false);
  };

  /**
   * Restart controller
   */
  this.restartController = function() {

    //Set flag
    this.isRestartingController = true;

    //Restart controller
    this.system
      .restartController()
      .then(() => $notice.show(`Restarting ICT controller...`))
      .catch(() => $notice.showError(`Failed to restart ICT controller`))
      .finally(() => this.isRestartingController = false);
  };

  /**
   * Purge data
   */
  this.purge = function() {

    //Set flag
    this.isPurging = true;

    //Purge all data
    this.system
      .purge()
      .then(() => $notice.show(`Purging data...`))
      .catch(() => $notice.showError(`Failed to purge data`))
      .finally(() => this.isPurging = false);
  };

  /**
   * Restart remote access
   */
  this.restartRemoteAccess = function(service) {

    //Set flag
    this.isRestartingRemoteAccess = true;

    //Restart remote access
    this.system
      .restartRemoteAccess(service)
      .then(() => $notice.show(`Restarting remote access service...`))
      .catch(() => $notice.showError(`Failed to restart remote access service`))
      .finally(() => this.isRestartingRemoteAccess = false);
  };

  /**
   * Install remote access
   */
  this.installRemoteAccess = function(service) {

    //Set flag
    this.isInstallingRemoteAccess = true;

    //Install remote access
    this.system
      .installRemoteAccess(service)
      .then(() => $notice.show(`Installing remote access service...`))
      .catch(() => $notice.showError(`Failed to install remote access service`))
      .finally(() => this.isInstallingRemoteAccess = false);
  };
});
