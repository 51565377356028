
/**
 * Module definition and dependencies
 */
angular.module('Shared.Door.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('Door', function(
  $baseModel, $filter, DoorStates, DoorLockStates, DoorPositionStates
) {

  /**
   * Constructor
   */
  function Door(data) {

    //Parent constructor
    $baseModel.call(this, data);

    //Reset state
    this.resetState();

    //Door states
    Object.defineProperty(this, 'isOpen', {
      get() {
        return (this.position === DoorPositionStates.OPEN);
      },
    });
    Object.defineProperty(this, 'isClosed', {
      get() {
        return (this.position === DoorPositionStates.CLOSED);
      },
    });

    //Lock states
    Object.defineProperty(this, 'isLocked', {
      get() {
        return (this.lock === DoorLockStates.LOCKED);
      },
    });
    Object.defineProperty(this, 'isUnlocked', {
      get() {
        return (this.lock === DoorLockStates.UNLOCKED);
      },
    });
    Object.defineProperty(this, 'isUnknown', {
      get() {
        return (this.lock === DoorLockStates.UNKNOWN);
      },
    });

    //System states
    Object.defineProperty(this, 'isInOverride', {
      get() {
        return (this.state === DoorStates.OVERRIDE);
      },
    });
    Object.defineProperty(this, 'isInSchedule', {
      get() {
        return (this.state === DoorStates.SCHEDULE);
      },
    });
    Object.defineProperty(this, 'hasEvent', {
      get() {
        return (this.state === DoorStates.EVENT);
      },
    });

    //States for override status
    Object.defineProperty(this, 'isInactive', {
      get() {
        return (this.isLocked && !this.isOpen);
      },
    });
    Object.defineProperty(this, 'isActive', {
      get() {
        return (this.isOpen || this.isUnlocked);
      },
    });
    Object.defineProperty(this, 'isInSpecialState', {
      get() {
        return (this.isOpen || this.isUnlocked);
      },
    });
    Object.defineProperty(this, 'isInOverrideState', {
      get() {
        return (this.hasEvent || this.isInOverride || this.isInSchedule);
      },
    });

    //Textual state
    Object.defineProperty(this, 'stateLabel', {
      get() {
        return $filter('doorState')(this);
      },
    });

    //Icons
    Object.defineProperty(this, 'iconInactive', {
      get() {
        return 'lock';
      },
    });
    Object.defineProperty(this, 'iconActive', {
      get() {
        return 'lock_open';
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Door.prototype, $baseModel.prototype);

  /**
   * To JSON converter
   */
  Door.prototype.toJSON = function(data) {

    //Call parent method
    let json = $baseModel.prototype.toJSON.call(this, data);

    //Remove state properties
    delete json.state;
    delete json.lock;
    delete json.position;

    //Return JSON
    return json;
  };

  /**
   * Set door state
   */
  Door.prototype.setState = function(data) {

    //Empty data, done
    if (!data) {
      this.resetState();
      return;
    }

    //Extract data
    const {state, lock, position} = data;

    //Set data
    this.state = state || DoorStates.DEFAULT;
    this.lock = lock || DoorLockStates.UNKNOWN;
    this.position = position || DoorPositionStates.UNKNOWN;
  };

  /**
   * Reset door state
   */
  Door.prototype.resetState = function() {
    this.state = DoorStates.DEFAULT;
    this.lock = DoorLockStates.UNKNOWN;
    this.position = DoorPositionStates.UNKNOWN;
  };

  //Return
  return Door;
});
