
/**
 * Module definition and dependencies
 */
angular.module('Shared.TagOrder.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('tagOrder', {
    model: 'TagOrder',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'tagOrders',
        isArray: true,
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
      getPrices: {
        url: 'getPrices',
        method: 'GET',
      },
      lastDeliveryAddress: {
        url: 'lastDeliveryAddress',
        method: 'GET',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('TagOrder', function($api, $baseModel, TagOrderTypes) {

  //Defaults
  const defaults = {
    type: TagOrderTypes.KEY_TAG_BLACK,
    amount: 100,
  };

  /**
   * Constructor
   */
  function TagOrder(data) {
    $baseModel.call(this, angular.extend({}, defaults, data || {}));
  }

  /**
   * Extend base model
   */
  angular.extend(TagOrder.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  TagOrder.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('orderedBy', 'Member');
    this.convertToModel('deliveryAddress', 'Address');

    //Return self
    return this;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Create
   */
  TagOrder.prototype.create = function() {
    const data = this.toJSON();
    delete data.orderedBy;
    return $api.tagOrder
        .create(data)
        .then(data => this.fromJSON(data));
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  TagOrder.query = function(filter) {
    return $api.tagOrder.query(filter);
  };

  /**
   * Get prices
   */
  TagOrder.getPrices = function(amount, type) {
    return $api.tagOrder.getPrices({amount, type});
  };

  /**
   * Get last delivery address
   */
  TagOrder.lastDeliveryAddress = function() {
    return $api.tagOrder.lastDeliveryAddress();
  };

  //Return
  return TagOrder;
});
