
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.CustomFields.Card', [])

/**
 * Component
 */
.component('cardMemberEditCustomFields', {
  templateUrl: 'admin/people/members/cards/custom-fields.html',
  controller: 'CardMemberEditCustomFieldsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    member: '<',
    groups: '<',
    customFields: '<',
    isViewerOnly: '<',
    onSave: '&',
    isOwn: '<',
    asAdmin: '<',
  },
})

/**
 * Controller
 */
.controller('CardMemberEditCustomFieldsCtrl', function(
  $modal, moment, CardCloseReasons, ExistsError, Config, Upload,
  MemberGroup, matchesMemberConstraint
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;

    //Create model subset
    this.model = this.member.extract([
      'groups', 'number', 'signUpDate', 'isUnsubscribed', 'customFields',
    ]);

    //Custom fields object
    if (!this.model.customFields) {
      this.model.customFields = {};
    }

    //Max date
    this.maxDate = moment().startOf('day');

    //Filter out eligible custom fields
    this.customFields = this.asAdmin ?
      this.customFields : (this.customFields || [])
        .filter(field => this.isEligibleField(field));

    //Filter out dynamic groups (NOTE: groups are not loaded in profile)
    if (Array.isArray(this.groups)) {
      this.groups = this.groups.filter(group => !group.isDynamic);
    }

    //Set default values
    for (const field of this.customFields) {
      const {defaultValue, prop} = field;
      if (typeof defaultValue !== 'undefined' &&
          typeof this.model.customFields[prop] === 'undefined') {
        this.model.customFields[prop] = defaultValue;
      }
    }

    //Ensure custom fields defined
    this.ensureCustomFieldsDefined();
    this.splitCustomFields();
  };

  /**
   * Split custom fields in top and bottom
   */
  this.splitCustomFields = function() {
    if (this.customFields) {
      this.customFieldsTop = this.customFields
        .filter(field => !field.isParagraph);
      this.customFieldsBottom = this.customFields
        .filter(field => field.isParagraph);
    }
  };

  /**
   * Ensure custom fields defined
   */
  this.ensureCustomFieldsDefined = function() {

    //Only once both custom fields and model are set
    if (!this.model || !this.customFields) {
      return;
    }

    //All custom fields must be defined if not set
    for (const field of this.customFields) {
      const {prop, type} = field;
      const isDate = (type === 'date');
      const value = this.model.customFields[prop];

      //Ensure a value is present
      if (typeof value === 'undefined') {
        this.model.customFields[prop] = isDate ? null : '';
      }

      //Ensure dates are valid
      if (isDate && value && !moment.isMoment(value)) {
        this.model.customFields[prop] = null;
      }
    }
  };

  /**
   * Check if custom field should be shown
   */
  this.isEligibleField = function(field) {
    return matchesMemberConstraint(this.member, field);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Upload file
   */
  this.uploadFile = function(field) {

    //Get data
    const {member} = this;
    const {id} = member;
    const {baseUrl} = Config.api;
    const {prop, label} = field;
    const title = `Upload ${label}`;

    //Upload handler
    const onUpload = file => Upload.upload({
      url: `${baseUrl}/member/${id}/file/${prop}`,
      data: {file},
    });

    //Show modal
    $modal
      .open('uploadFile', {
        locals: {title, onUpload},
      })
      .result
      .then(data => this.member.customFiles[prop] = data.file);
  };

  /**
   * Delete file
   */
  this.deleteFile = function(field) {

    //Get data
    const {member} = this;
    const {prop} = field;
    const file = member.customFiles[prop];

    //Create handler
    const handler = () => member.deleteCustomFile(prop, file.name);

    //Show modal
    $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-delete-file.html',
        locals: {handler, file},
      });
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get data
    const {member, model, isEdit} = this;

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event: {member, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .catch(error => {
        if (error instanceof ExistsError) {
          this.form.number.$setValidity('exists', false);
        }
        throw error;
      })
      .finally(() => this.isSaving = false);
  };

  /**
   * Manage groups
   */
  this.manageGroups = function() {

    //Get data
    const Model = MemberGroup;
    const singular = 'group';
    const plural = 'groups';

    //Open modal
    $modal
      .open('manageCategories', {locals: {
        Model, singular, plural,
      }})
      .result
      .then(groups => {

        //Update groups and initiate model if needed
        this.groups = groups.filter(group => !group.isDynamic);
        this.model.groups = this.model.groups || [];

        //Remove removed groups from model
        this.model.groups = this.model.groups
          .filter(id => this.groups.some(group => group.id === id));
      });
  };
});
