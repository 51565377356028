
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.PaymentRedirect', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.paymentRedirect', {
    url: '/payment-redirect',
    component: 'registerPaymentRedirectRoute',
  });
})

/**
 * Route component
 */
.component('registerPaymentRedirectRoute', {
  controller: 'RegisterPaymentRedirectCtrl',
  templateUrl: 'portal/register/steps/payment-redirect.html',
  bindings: {
    club: '<',
    outcome: '<',
    registration: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterPaymentRedirectCtrl', function($timeout, $location) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set outcome
    this.isErrorPaying = (!this.outcome || !this.outcome.isPaid);

    //Set requires approval condition
    const {requiresApproval} = $location.search();
    this.requiresApproval = requiresApproval;

    //Start faded
    this.fade = true;
    $timeout(() => this.fade = false);
  };
});
