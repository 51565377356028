
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Add', [
  'App.Admin.CouponType.Add.Controller',
  'App.Admin.CouponType.Add.Details.Card',
  'App.Admin.CouponType.Add.Usage.Card',
  'App.Admin.CouponType.Add.Restrictions.Card',
  'App.Admin.CouponType.Add.Expiration.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.couponType.add', {
    url: '/add',
    component: 'adminCouponTypeAddRoute',
    data: {
      roles: ['admin'],
    },
    resolve: {
      couponType(CouponType) {
        return new CouponType();
      },
      memberships($store) {
        return $store.memberships.query();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminCouponTypeAddRoute', {
  controller: 'AdminCouponTypeAddCtrl',
  templateUrl: 'admin/coupon-type/add/add.html',
  bindings: {
    club: '<',
    couponType: '<',
    module: '<',
    activities: '<',
    memberships: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.couponType.add'}, transition => {
    const module = transition.injector().get('module');
    Page.setTitle(`Add ${module.singular}`);
    Page.addCrumb({sref: 'admin.couponType.add'});
  });
});
