
/**
 * Define values
 */
const SPECIFIC = 'specific';
const SELECTION = 'selection';
const GROUPS = 'groups';
const ALL = 'all';

/**
 * Define array
 */
const MemberSelectionMethods = [
  {
    value: SPECIFIC,
    label: 'Find by name',
  },
  {
    value: SELECTION,
    label: 'Select from list',
  },
  {
    value: GROUPS,
    label: 'Specific groups',
  },
  {
    value: ALL,
    label: 'All active members',
  },
];

/**
 * Set properties
 */
MemberSelectionMethods.SPECIFIC = SPECIFIC;
MemberSelectionMethods.SELECTION = SELECTION;
MemberSelectionMethods.GROUPS = GROUPS;
MemberSelectionMethods.ALL = ALL;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.MemberSelectionMethods.Options', [])
  .constant('MemberSelectionMethods', MemberSelectionMethods);
