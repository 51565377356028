
/**
 * Module definition and dependencies
 */
angular.module('App.Controller', [])

/**
 * Main application controller
 */
.controller('AppCtrl', function(
  $controller, $modal, $modalStack, $state, $window, Config,
  moment, Transaction, TextTypes, Text, Subscription, Modules
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CoreCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Ensure club is present and loaded
    if (!this.club || !this.club.settings) {
      return $window.location.reload();
    }

    //Setup
    this.setupServices();
    this.setupSettings();
    this.setupModules();
    this.setupContactHandler();
    this.setupIntercom();
    this.setupKeyDownListeners();
    this.setupVersionCheck();
    this.setupPwa();

    //Show modals
    this.showModals();
  };

  /**
   * Show modals
   */
  this.showModals = function() {

    const {club} = this;

    //Don't show any other modals if club is frozen
    if (club.isMigratingToFrello) {
      return;
    }

    //Check if we need to agree to app terms and conditions
    if (this.mustAgreeToAppTermsAndConditions()) {
      return this.showAppTermsAndConditions();
    }

    //Check if we need to agree to club terms and conditions
    if (this.mustAgreeToClubTermsAndConditions()) {
      return this.showClubTermsAndConditions();
    }

    //If we have an action state param, we don't show any modals
    if (!$state.params.action && !$state.current.name.match(/^subscription/)) {
      this.showMigrationInfo();
      this.showSubRenewal();
      this.showPaymentsDue();
      this.askForFeedback();
    }

    //Advise the user if they are suspended
    this.showSuspended();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.removeKeyDownListeners();
    this.shutdownIntercom();
  };

  /**************************************************************************
   * Terms and conditions
   ***/

  /**
   * Check if must agree to application terms and conditions
   */
  this.mustAgreeToAppTermsAndConditions = function() {

    //Get data
    const {user, club} = this;

    //Check if this user is account owner
    if (!user.isAccountOwner) {
      return false;
    }

    //Find agreement
    const lastUpdated = moment(Config.app.termsLastUpdated, 'YYYY-MM-DD');
    const agreements = club.termsOfServiceAgreements;
    const agreement = agreements.find(item => item.member === user.id);

    //No agreement yet
    if (!agreement) {
      return true;
    }

    //Agreed before terms were updated
    if (lastUpdated.isAfter(agreement.date)) {
      return true;
    }

    //No need to agree
    return false;
  };

  /**
   * Check if must agree to club terms and conditions
   */
  this.mustAgreeToClubTermsAndConditions = function() {

    //Get data
    const {user, club} = this;

    //Check if there are terms and if already agreed or not
    return (
      !user.isSuper() &&
      club.hasTermsAndConditions &&
      !user.termsAndConditions.hasAgreed
    );
  };

  /**
   * Show application terms and conditions
   */
  this.showAppTermsAndConditions = function() {

    //Get data
    const {club, user} = this;
    const title = 'Terms of Service';
    const contact = 'support@helloclub.com';

    //Find agreement
    const lastUpdated = moment(Config.app.termsLastUpdated, 'YYYY-MM-DD');
    const agreements = club.termsOfServiceAgreements;
    const agreement = agreements.find(item => item.member === user.id);
    const haveChanged = agreement && lastUpdated.isAfter(agreement.date);

    //Create handler
    const handler = (hasAgreed) => club.agreeToTermsOfService(hasAgreed);

    //Show modal
    $modal
      .open('agreeToTerms', {
        locals: {
          handler, title, contact, haveChanged, lastUpdated,
          isForApp: true,
        },
      });
  };

  /**
   * Show club terms and conditions
   */
  this.showClubTermsAndConditions = function() {

    //Get data
    const {user, club} = this;
    const title = `${club.name} Terms and Conditions`;
    const contact = club.email;
    const lastUpdated = club.termsAndConditionsLastUpdated;
    const haveChanged = (user.termsAndConditions.agreementDates.length > 0);

    //Create handler
    const handler = (hasAgreed) => user.agreeToTermsAndConditions(hasAgreed);

    //Show modal
    Text
      .getText(TextTypes.TERMS_AND_CONDITIONS)
      .then(terms => {
        $modal
          .open('agreeToTerms', {
            locals: {
              handler, title, contact, terms, lastUpdated, haveChanged,
              isForClub: true,
            },
          })
          .closed
          .then(() => null);
      });
  };

  /**************************************************************************
   * Modals processing
   ***/

  /**
   * Show modals
   */
  this.showSubRenewal = async function() {

    //Other modals with higher priority
    const others = ['trialEnded', 'planExpired', 'planExpiringSoon'];

    //Don't show if other modals open
    if ($modalStack.isOpen(others)) {
      return;
    }

    //No memberships module
    if (!Modules.has('memberships')) {
      return;
    }

    //Get user
    const {user} = this;
    if (!user || !user.isMember || user.isPending) {
      return;
    }

    //Load subs
    const subscriptions = await Subscription
      .own()
      .then(subs => subs
        .filter(sub => sub.canBeRenewed() && !sub.autoRenews));

    //Check if anything to do
    if (subscriptions.length === 0) {
      return;
    }

    //Loop subscriptions
    for (const subscription of subscriptions) {
      const hasExpired = subscription.expiresIn() < 0;
      const {id, isLinked} = subscription;
      const condition = id + '-' + String(Number(hasExpired));

      //Check for linked
      if (isLinked) {
        if (user.needsReminder('subRenewalLinked', condition)) {
          $modal
            .open('basic', {
              templateUrl:
                'subscription/modals/up-for-renewal-linked.html',
              locals: {subscription, hasExpired},
            })
            .closed
            .then(() => user
              .suppressReminder('subRenewalLinked', condition));
        }
      }

      //Check for primary
      else if (user.needsReminder('subRenewal', condition)) {
        $modal
          .open('upForRenewal', {locals: {subscription}})
          .result
          .then(snooze => user
            .snoozeReminder('subRenewal', snooze, null, condition));
      }
    }
  };

  /**
   * Show payments due modal
   */
  this.showPaymentsDue = function() {

    //Other modals with higher priority
    const others = [
      'trialEnded', 'planExpired', 'planExpiringSoon',
      'subRenewal', 'subRenewalLinked',
    ];

    //Don't show if other modals open
    if ($modalStack.isOpen(others)) {
      return;
    }

    //Get user
    const {user} = this;

    //Alert the user if they have payments due
    if (!user.needsReminder('paymentsDue')) {
      return;
    }

    //Already on the account page?
    if ($state.current.name.match(/^account/)) {
      return;
    }

    //Find own transactions
    Transaction
      .own()
      .then(transactions => {

        //Filter transactions that are due and not muted
        transactions = transactions.filter(trx => {
          return (trx.isDue && !trx.isMuted && !trx.isCredit);
        });

        //No transactions to badger the user about?
        if (transactions.length === 0) {
          return;
        }

        //Determine total amount due
        const amount = transactions
          .reduce((total, trx) => total + trx.amount, 0);

        //Show modal
        $modal
          .open('paymentsDue', {locals: {amount, transactions}})
          .result
          .then(snooze => user.snoozeReminder('paymentsDue', snooze));
      });
  };

  /**
   * Show newsletter opt-in
   */
  // this.showNewsletterOptIn = function() {

  //   //Modal stack not empty
  //   if (!$modalStack.isEmpty()) {
  //     return;
  //   }

  //   //Get user
  //   const {user, club} = this;
  //   const {isSignedUpToNewsletter} = user;
  //   const {isTrial} = club;
  //   const isAdmin = user.hasRole('admin');
  //   const isEligible = (!isSignedUpToNewsletter && !isTrial && isAdmin);

  //   //Check if needed
  //   if (!isEligible || !user.needsReminder('newsletter')) {
  //     return;
  //   }

  //   //Show modal
  //   $modal.open('basic', {
  //     templateUrl: 'modals/newsletter.html',
  //     locals: {club, user, isAdmin},
  //   });
  // };

  /**
   * Show user suspended modal
   */
  this.showSuspended = function() {

    //Get data
    const {user} = this;
    if (!user.isSuspended) {
      return;
    }

    //Show modal
    $modal.open('basic', {
      templateUrl: 'modals/suspended.html',
      locals: {user},
    });
  };

  /**
   * Show feedback request modal
   */
  this.askForFeedback = function() {

    //Get user and club
    const {user, club} = this;
    const {askForFeedback} = club;
    const {isSuspended, firstOnline} = user;
    const threshold = moment().subtract(1, 'month');
    const isSuper = user.isSuper();
    const isAfter = threshold.isAfter(firstOnline);
    const isEligible = (askForFeedback && !isSuspended && isAfter && !isSuper);

    //Show modal
    if (isEligible && user.needsReminder('askForFeedback')) {
      $modal
        .open('feedback', {locals: {user, club, isAsking: true}})
        .result
        .then(snooze => user.snoozeReminder('askForFeedback', snooze));
    }
  };

  /**
   * Show migration info modal
   */
  this.showMigrationInfo = function() {

    //Get user and club
    const {user, club} = this;

    //Show modal
    if (club.willBeMigratedToFrello && user.needsReminder('migrationInfo')) {
      $modal
        .open('migrationInfo', {locals: {user, club, isAsking: true}})
        .result
        .then(snooze => user.snoozeReminder('migrationInfo', snooze));
    }
  };
});
