
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.View', [
  'App.Booking.View.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('bookings', {
    parent: 'booking',
    url: '/bookings/:activity/:date/:bookingId',
    params: {
      activity: {
        value: null,
        squash: true,
        dynamic: true,
      },
      date: {
        value: null,
        squash: true,
        dynamic: true,
      },
      bookingId: {
        value: null,
        squash: true,
        dynamic: true,
      },
    },
    component: 'bookingViewRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('bookings');
      },
      areas($store) {
        return $store.areas.query();
      },
      booking(transition, $store) {
        const params = transition.params();
        if (!params.bookingId) {
          return null;
        }
        return $store.bookings.findById(params.bookingId);
      },
    },
  });
})

/**
 * Component
 */
.component('bookingViewRoute', {
  controller: 'BookingViewCtrl',
  templateUrl: 'booking/view/view.html',
  bindings: {
    club: '<',
    user: '<',
    page: '<',
    module: '<',
    system: '<',
    filter: '<',
    booking: '<',
    areas: '<',
    activities: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run((moment, $transitions, $storage) => {

  //Ensure there's always an activity and date
  $transitions.onEnter({to: 'bookings'}, transition => {

    //Check if anything missing
    const existing = transition.params();
    if (existing.activity && existing.date) {
      return;
    }

    //Initialize params
    const params = {
      date: existing.date,
      activity: existing.activity,
      bookingId: existing.bookingId,
    };

    //No activity?
    if (!params.activity) {
      const lastActivity = $storage.get('bookings.activity');
      if (lastActivity) {
        params.activity = lastActivity;
      }
      else {
        const activities = transition.injector().get('activities');
        const activity = activities.find(activity => activity.isDefault);
        if (activity) {
          params.activity = activity.identifier;
        }
      }
    }

    //No date?
    if (!params.date) {
      params.date = moment().format('YYYY-MM-DD');
    }

    //Redirect
    return transition.router.stateService.target('bookings', params);
  });
});
