/* eslint no-undef:off */
'use strict';
(function() {
  let w = window;
  let d = document;
  let ic = w.Intercom;
  function l() {
    let s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://widget.intercom.io/widget/gudevjri';
    let x = d.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', intercomSettings);
  }
  else {
    let i = function() {
      i.c(arguments);
    };
    i.q = [];
    i.c = function(args) {
      i.q.push(args);
    };
    w.Intercom = i;
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    }
    else {
      w.addEventListener('load', l, false);
    }
  }
}());
