
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Contacts.Edit.Controller', [])

/**
 * Controller
 */
.controller('AdminContactEditCtrl', function($controller, $state, $q, $store) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminContactCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Get params
    const {showComments} = this.transition.params();

    //Check if can edit and system enabled
    this.canEdit = this.user.isAdmin();
    this.openCommentsCard = showComments;

    //Append recent subs
    this.contact.subscriptions = this.subscriptions;
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    $state.go('admin.people.contacts.overview');
  };

  /**
   * On saved
   */
  this.onSaved = function($event) {
    const {contact} = $event;
    this.page.setTitle(contact.name);
  };

  /**
   * Load items
   */
  this.loadItems = function(...types) {

    //Get contact ID and create promises
    const contact = this.contact.id;
    const promises = types
      .map(type => $store[type]
      .query({contact})
      .then(items => this[type] = items));

    //Resolve all data
    return $q.all(promises);
  };
});
