
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.Display.Card', [])

/**
 * Component
 */
.component('cardMemberEditDisplay', {
  templateUrl: 'admin/people/members/cards/display.html',
  controller: 'CardMemberEditDisplayCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    member: '<',
    isOwn: '<',
    title: '@',
    prefix: '@',
    field: '@',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberEditDisplayCtrl', function(CardCloseReasons) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;

    //Create model subset
    this.model = this.member.extract(this.field);

    //Ensure object
    if (!this.model) {
      this.model = {};
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(key, value) {
    this.model[key] = value;

    if (key === 'email' || key === 'phone') {
      this.form.$setDirty();
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Set isEditableByMember flag if staff
    if (this.member.isStaff) {
      this.model.isEditableByMember = this.model.isPublic;
    }

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving and get data
    this.isSaving = true;
    const {member, model, field, isEdit} = this;

    //Save
    this
      .onSave({$event: {member, model: {[field]: model}, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
