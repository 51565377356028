
/**
 * Module definition and dependencies
 */
angular.module('App.Kiosk', [
  'App.Kiosk.Controller',
  'App.Kiosk.KioskInterceptor.Service',
])

/**
 * Config
 */
.config(($httpProvider, $stateProvider) => {

  //Push kiosk interceptor
  $httpProvider.interceptors.push('KioskInterceptor');

  //Kiosk state
  $stateProvider.state('kiosk', {
    url: '/kiosk/:activity/:date',
    params: {
      activity: {
        value: null,
        squash: true,
        dynamic: true,
      },
      date: {
        value: null,
        squash: true,
        dynamic: true,
      },
    },
    data: {
      auth: false,
      id: 'kiosk',
    },
    component: 'kioskRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('bookings');
      },
      club($store) {
        return $store.club.get();
      },
      system($store) {
        return $store.system.get();
      },
      areas($store) {
        return $store.areas.query();
      },
      activities($store) {
        return $store.activities.query();
      },
    },
  });
})

/**
 * Component
 */
.component('kioskRoute', {
  controller: 'KioskCtrl',
  templateUrl: 'kiosk/kiosk.html',
  bindings: {
    club: '<',
    system: '<',
    page: '<',
    module: '<',
    filter: '<',
    areas: '<',
    activities: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run((Kiosk, Auth, $log, $transitions, moment) => {

  //Access check
  $transitions.onEnter({to: 'kiosk'}, transition => {

    //Prevent transition if not enabled
    if (!Kiosk.isEnabled()) {
      $log.log(`Kiosk not enabled`);
      return transition.router.stateService.target('home');
    }

    //For logged in users, go to bookings page instead
    if (Auth.isAuthenticated()) {
      return transition.router.stateService.target('bookings');
    }

    //Check if anything missing
    const existing = transition.params();
    if (existing.activity && existing.date) {
      return;
    }

    //Initialize params
    const params = {
      date: existing.date,
      activity: existing.activity,
    };

    //No activity?
    if (!params.activity) {
      const activities = transition.injector().get('activities');
      const activity = activities.find(activity => activity.isDefault);
      if (activity) {
        params.activity = activity.identifier;
      }
    }

    //No date?
    if (!params.date) {
      params.date = moment().format('YYYY-MM-DD');
    }

    //Redirect
    return transition.router.stateService.target('kiosk', params);
  });
});
