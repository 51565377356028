
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Add.AccountCredit.Card', [])

/**
 * Component
 */
.component('cardMembershipAddAccountCredit', {
  templateUrl: 'admin/membership/cards/account-credit.html',
  controller: 'CardMembershipAddAccountCreditCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    membership: '<',
    couponTab: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipAddAccountCreditCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMembershipEditAccountCreditCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {model, membership} = this;
    this.onMerge({$event: {membership, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge
    this.merge();

    //Check if coupons tab is enabled
    if (this.couponTab) {
      //Go to next relevant step
      this.card.nextTab();
    }
    else {
      //Go to last step
      this.card.lastTab();
    }
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previous();
  };
});
