
/**
 * Module definition and dependencies
 */
angular.module('Shared.ClubFrozen.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('clubFrozen', {
    templateUrl: 'modals/club-frozen.html',
    controller: 'ModalClubFrozenCtrl',
    closeOnClick: false,
    resolve: {
      club($store) {
        'ngInject';
        return $store.club.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalClubFrozenCtrl', function(
  $controller, $modalInstance, VersionCheck, $window
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data
    this.isMigrating = this.club.isMigratingToFrello && !this.club.hasMigratedToFrello;
    this.hasMigrated = this.club.hasMigratedToFrello;

    if (this.hasMigrated) {
      //Get the current url - a v2 welcome url will redirect to an error page so we need to preserve this
      const url = $window.location.href;
      //Perform an immediate version check
      VersionCheck.check(function() {
        //Update the service worker and reload the original url
        VersionCheck.updateServiceWorker();
        $window.location.href = url;
      });
    }
  };
});
