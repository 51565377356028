
/**
 * Module definition and dependencies
 */
angular.module('Shared.Weekdays.Service', [])

/**
 * Service definition
 */
.factory('Weekdays', function(moment) {

  //Get min/short values
  const short = moment.wkDaysShort();
  const min = moment.wkDaysMin();
  const letter = moment.wkDaysFirstLetter();

  return moment
    .wkDays()
    .map((day, index) => ({
      value: index + 1,
      label: day,
      labelShort: short[index],
      labelMin: min[index],
      labelLetter: letter[index],
    }));
});
