
/**
 * Module definition and dependencies
 */
angular.module('App.Calendar.Controller', [])

/**
 * Controller
 */
.controller('CalendarCtrl', function(
  $interval, $q, $notice, $state, $store, $storage, $modal, moment,
  ScrollPosition, Modules, Settings, Event, Booking
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Setup props & flags
    this.setupProps();

    //Setup filter and page
    this.setupFilter();
    this.setupPage();
    this.setupCrumbs();

    //Set today and validate filter
    this.setToday();
    this.validateFilter();

    //Determine info based on filter
    this.determinePeriodInfo();
    this.determineActivityInfo();
    this.determineDateInfo();
    this.createWeeksAndDays();

    //Update URL and load initial data
    this.updateUrl('replace');
    this.loadData();
    this.loadCategories();

    //Filter on change handler
    this.filter.onChange(key => {

      //Changed activity is handled
      if (key === 'activity') {
        this.determineActivityInfo();
      }

      //Changed date
      if (key === 'date') {
        this.determineDateInfo();
        this.createWeeksAndDays();
      }

      //Changed period
      if (key === 'period') {
        this.determinePeriodInfo();
        this.determineDateInfo();
        this.createWeeksAndDays();
      }

      //Changed either
      this.updateUrl();
      this.clearData();
      this.loadData();
    });

    //Check if still on today and refresh data periodically
    this.interval = $interval(() => {
      this.checkToday();
      this.loadData();
    }, 300 * 1000);
  };

  /**
   * Setup properties & flags
   */
  this.setupProps = function() {

    //Initialize properties
    this.hasBookingsModule = Modules.has('bookings');
    this.hasMultipleActivities = (this.activities.length > 1);
    this.canManageBookings = this.user.hasRole('admin', 'eventManager');
    this.loadOps = [];

    //Default activity and activity options
    if (this.activities) {
      this.defaultActivity = this.activities.find(activity => activity.isDefault);
      this.activityOptions = this.activities.map(a => a);
      this.activityOptions.unshift({name: 'All activities', id: null});
    }

    //Period options
    this.PeriodOptions = [
      {value: 'week', label: 'Weekly'},
      {value: 'month', label: 'Monthly'},
    ];

    //Event settings
    const overviewFields = Settings.get('event.overviewFields', []);

    //Determine fields to show
    this.showOrganisers = overviewFields.includes('organisers');
    this.showActivity = overviewFields.includes('activity');
  };

  /**
   * On destroy handler
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, module} = this;
    const {title} = module;
    const params = this.transition.params();

    //Set page title
    page.setTitle(title);

    //Default view
    this.isShowingHidden = $storage.get('calendar.showHidden', false);
    this.isList = (params.list || $storage.get('calendar.showList', false));
  };

  /**
   * Set crumbs
   */
  this.setupCrumbs = function() {

    //Pop activity crumb
    if (this.page.crumbs.length > 1) {
      this.page.popCrumb();
    }

    //Find activity
    const activity = this.findActivity(this.filter.activity);

    //Add if needed
    if (activity) {
      const params = this.transition.params();
      const title = activity.name;
      this.page.addCrumb({sref: $state.current.name, params, title});
    }
  };

  /**
   * Toggle hidden events
   */
  this.toggleHidden = function() {
    this.isShowingHidden = !this.isShowingHidden;
    $storage.set('calendar.showHidden', this.isShowingHidden);
    this.loadData();
  };

  /**
   * Toggle view
   */
  this.toggleListView = function() {
    this.isList = !this.isList;
    $storage.set('calendar.showList', this.isList);
    this.updateUrl();
  };

  /**************************************************************************
   * Filter handling
   ***/

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter, activities} = this;
    const params = this.transition.params();

    //Set filter defaults
    filter.setDefaults({
      period: Settings.get('event.defaultCalendarPeriod'),
      activity: null,
      date: moment().startOf('day'),
    });

    //Get initial data from params
    const {period, category} = params;
    const date = moment(params.date, 'YYYY-MM-DD').startOf('day');

    //Update period, date and activity
    filter.update('date', date);
    filter.update('period', period);
    filter.update('category', category);

    //Only update activity if more than 1 activity present
    if (activities.length > 1) {
      const activity = activities.find(a => (a.identifier === params.activity));
      filter.update('activity', activity ? activity.id : null);
    }
    else {
      filter.update('activity', null);
    }
  };

  /**
   * Check if filter has changed compared to a given filter
   */
  this.hasFilterChanged = function(filter) {
    return (
      (this.activity && this.activity.id !== filter.activity) ||
      !this.fromDate.isSame(filter.fromDate, 'day') ||
      !this.toDate.isSame(filter.toDate, 'day')
    );
  };

  /**
   * Validate the filter
   */
  this.validateFilter = function() {
    const {period, activity, date} = this.filter;
    if (!period) {
      this.filter.update('period', Settings.get('event.defaultCalendarPeriod'));
    }
    if (activity && !this.findActivity(activity)) {
      this.filter.update('activity', this.defaultActivity.id);
    }
    if (!date || !date.isValid()) {
      this.filter.update('date', this.today.clone());
    }
  };

  /**************************************************************************
   * Date navigation
   ***/

  /**
   * Show calendar
   */
  this.showCalendar = function() {

    //Get data
    const {date} = this;

    //Open calendar modal
    $modal
      .open('pickDate', {locals: {date, canClear: false}})
      .result
      .then(date => {
        this.filter.update('date', date.startOf('day'));
      });
  };

  /**
   * Today
   */
  this.goToday = function() {
    this.filter.update('date', moment().startOf('day'));
  };

  /**
   * Go to previous period
   */
  this.previousPeriod = function() {
    const {date} = this;
    const {period} = this.filter;
    const start = date.clone().startOf(period);
    const prev = start.subtract(1, period);
    this.filter.update('date', prev);
  };

  /**
   * Go to next period
   */
  this.nextPeriod = function() {
    const {date} = this;
    const {period} = this.filter;
    const start = date.clone().startOf(period);
    const next = start.add(1, period);
    this.filter.update('date', next);
  };

  /**
   * Go to bookings page for given date
   */
  this.goToBookings = function(day) {

    //No modules
    if (!this.hasBookingsModule) {
      return;
    }

    //Get date and activity
    const date = day.format('YYYY-MM-DD');
    const {activity} = $state.params;

    //Transition
    $state.go('bookings', {date, activity});
  };

  /**************************************************************************
   * Date and time helpers
   ***/

  /**
   * Check if today is no longer today
   */
  this.checkToday = function() {

    //Get now
    const now = moment();
    const time = now.hours() * 60 + now.minutes();

    //If time is beyond the time range end time, advance to the next day
    if (this.isToday && this.timeRange && time > this.timeRange.endTime) {
      this.filter.update('date', now.clone().add(1, 'day').startOf('day'));
    }

    //If today is still the same, nothing needs to be done
    if (now.isSame(this.today, 'day')) {
      return;
    }

    //Set today
    this.setToday();

    //If we are on today, we need to trigger a filter change
    if (this.isToday) {
      this.filter.update('date', this.today.clone());
    }
  };

  /**
   * Set today
   */
  this.setToday = function() {
    this.today = moment().startOf('day');
  };

  /**************************************************************************
   * Other helpers
   ***/

  /**
   * View item
   */
  this.viewItem = function(item, day) {

    //View event
    if (item.events.length > 0) {
      return $state.go('event.view', {eventId: item.events[0].id});
    }

    //View booking
    if (item.bookings.length > 0) {
      const booking = item.bookings[0];
      const date = day.format('YYYY-MM-DD');
      const activity = booking.activity.identifier;
      return $state.go('bookings', {date, activity, bookingId: booking.id});
    }
  };

  /**
   * Find activity by ID
   */
  this.findActivity = function(id) {
    if (!id) {
      return null;
    }
    return this.activities.find(activity => activity.id === id);
  };

  /**
   * Determine period info
   */
  this.determinePeriodInfo = function() {
    this.isWeekly = (this.filter.period === 'week');
    this.isMonthly = (this.filter.period === 'month');
  };

  /**
   * Determine activity info
   */
  this.determineActivityInfo = function() {

    //Find activity
    const activity = this.findActivity(this.filter.activity);

    //Set activity and find areas
    this.activity = activity;
    this.activityAreas = this.areas
      .filter(area => (!activity || area.activity === activity.id))
      .filter(area => area.containsDate(this.filter.date));
  };

  /**
   * Determine date info
   */
  this.determineDateInfo = function() {

    //Get data
    const {date, period} = this.filter;
    const {today} = this;

    //Set date and initialize days
    this.date = date;
    this.fromDate = date.clone().startOf(period);
    this.toDate = date.clone().endOf(period);
    this.isToday = date.isSame(today, 'day');
  };

  /**
   * Helper to update URL based on current filter
   */
  this.updateUrl = function(location = true) {

    //Transition options
    const activity = this.activity ? this.activity.identifier : 'all';
    const date = this.date.format('YYYY-MM-DD');
    const {period, category} = this.filter;
    const list = this.isList ? 'list' : null;
    const {params: p} = $state;

    //Set in storage
    $storage.set('calendar.activity', activity);
    $storage.set('calendar.period', period);
    $storage.set('calendar.showList', list);

    //Ignore if the same
    if (
      p.period === period && p.date === date &&
      p.activity === activity && p.list === list
      && p.category === category) {
      return;
    }

    //Transition
    $state
      .go($state.current.name, {
        period, activity, date, list, category,
      }, {location})
      .then(() => {
        ScrollPosition.restore(null, true);
        this.setupCrumbs();
      });
  };

  /**************************************************************************
   * Data handling
   ***/

  /**
   * Initialize load
   */
  this.initializeLoad = function() {
    $notice.showLoading();
    this.loadOps.push(1);
  };

  /**
   * Finalize load
   */
  this.finalizeLoad = function() {

    //Pop last load op
    if (this.loadOps.length > 0) {
      this.loadOps.pop();
    }

    //Hide notice once all load ops are done
    if (this.loadOps.length === 0) {
      $notice.hideLoading();
    }
  };

  /**
   * Load events
   */
  this.loadEvents = function() {

    //Create query filter
    const filter = this.getEventsFilter();
    const {user} = this;

    //Load events
    return Event
      .query(filter)
      .then(data => {

        //Get data
        const {meta: {total}, events} = data;

        //Determine how many requests are needed
        const numRequests = Math.ceil(total / 100);
        const promises = [
          $q.resolve(events),
        ];

        //Create promises (skip 0, aleady loaded)
        for (let i = 1; i < numRequests; i++) {
          const offset = i * 100;
          promises.push(
            Event
              .query(Object.assign({offset}, filter))
              .then(data => data.events)
          );
        }

        //Resolve promises
        return $q.all(promises);
      })
      .then(results => Array.prototype.concat(...results))
      .then(events => events.filter(event => event.isVisibleFor(user)))
      .then(events => {

        //If the filter has since changed, ignore this response
        if (this.hasFilterChanged(filter)) {
          return;
        }

        //Set events as copy to force changes
        return this.events = events.map(e => e);
      });
  };

  /**
   * Load bookings only
   */
  this.loadBookings = function() {

    //Don't have bookings module
    if (!this.hasBookingsModule) {
      this.bookings = [];
      return $q.resolve([]);
    }

    //Create query filter
    const filter = this.getBookingsFilter();

    //Load bookings
    return Booking
      .query(filter)
      .then(data => {

        //Get data
        const {meta: {total}, bookings} = data;

        //Determine how many requests are needed
        const numRequests = Math.ceil(total / 100);
        const promises = [
          $q.resolve(bookings),
        ];

        //Create promises (skip 0, aleady loaded)
        for (let i = 1; i < numRequests; i++) {
          const offset = i * 100;
          promises.push(
            Booking
              .query(Object.assign({offset}, filter))
              .then(data => data.bookings)
          );
        }

        //Resolve promises
        return $q.all(promises);
      })
      .then(results => Array.prototype.concat(...results))
      .then(bookings => {

        //If the filter has since changed, ignore this response
        if (this.hasFilterChanged(filter)) {
          return;
        }

        //Set bookings as copy to force changes
        return this.bookings = bookings.map(b => b);
      });
  };

  /**
   * Load categories
   */
  this.loadCategories = function() {
    return $store.eventCategories
      .query(true)
      .then(categories => this.categories = categories);
  };

  /**
   * Load data
   */
  this.loadData = function() {

    //Initialize load
    this.initializeLoad();

    //Load bookings and events for the day
    return $q
      .all([
        this.loadBookings(),
        this.loadEvents(),
      ])
      .then(() => this.mapDataOntoDays())
      .finally(() => this.finalizeLoad());
  };

  /**
   * Clear bookings/events data
   */
  this.clearData = function() {
    this.bookings = [];
    this.events = [];
  };

  /**
   * Create a query filter
   */
  this.getQueryFilter = function() {
    const {fromDate, toDate} = this;
    const activity = this.activity ? this.activity.id : null;
    return {fromDate, toDate, activity, isRemoved: false};
  };

  /**
   * Get bookings filter
   */
  this.getBookingsFilter = function() {

    //Get base filter
    const filter = this.getQueryFilter();

    //Append member filter unless admin, event manager or public calendar
    if (!this.canManageBookings) {
      filter.member = this.user.id;
    }

    //Return filter
    return filter;
  };

  /**
   * Get events filter
   */
  this.getEventsFilter = function() {

    //Get base filter
    const filter = this.getQueryFilter();

    //Append hidden flag
    if (!this.canManageBookings || !this.isShowingHidden) {
      filter.isHidden = false;
    }

    //Append category
    if (this.filter.category) {
      filter.category = this.filter.category;
    }

    //Return filter
    return filter;
  };

  /**
   * Filter items for given day and time
   */
  this.filterItems = function(items, day, startTime, endTime, area) {
    if (!items || !Array.isArray(items)) {
      return [];
    }
    return items
      .filter(item => {
        if (area) {
          if (item.area && item.area.id !== area.id) {
            return false;
          }
          else if (item.areas && !item.areas.some(a => a.id === area.id)) {
            return false;
          }
        }
        if (!day.isSame(item.startDate, 'day')) {
          return false;
        }
        if (!startTime && !endTime) {
          return true;
        }
        return (item.startTime < endTime && item.endTime > startTime);
      });
  };

  /**
   * Filter bookings
   */
  this.filterBookings = function(day, startTime, endTime, area) {
    return this.filterItems(this.bookings, day, startTime, endTime, area);
  };

  /**
   * Filter events
   */
  this.filterEvents = function(day, startTime, endTime, area) {
    return this.filterItems(this.events, day, startTime, endTime, area);
  };

  /**************************************************************************
   * Calendar generation helpers
   ***/

  /**
   * Create weeks
   */
  this.createWeeksAndDays = function() {

    //Initialize
    this.weeks = [];

    //Get data
    const {fromDate, toDate, today} = this;
    const ref = fromDate.clone().startOf('week');
    const end = toDate.clone().endOf('week');

    //Generate weeks
    while (!ref.isAfter(end)) {
      const days = [];

      //Create days for the week
      for (let i = 1; i <= 7; i++) {
        const day = ref.clone();

        //Determine flags
        day.isToday = day.isSame(today, 'day');
        day.isCurrent = day.isBetween(fromDate, toDate, 'day', '[]');
        day.isEmpty = true;

        //Add to list of days
        days.push(day);
        ref.add(1, 'day');
      }

      //Add to weeks
      this.weeks.push(days);
    }
  };

  /**
   * Map data onto days
   */
  this.mapDataOntoDays = function() {

    //Loop weeks and days
    for (const days of this.weeks) {
      for (const day of days) {

        //Find events and bookings
        day.events = this.filterEvents(day);
        day.bookings = this.filterBookings(day);
        day.isEmpty = (day.events.length + day.bookings.length === 0);
      }
    }
  };

  /**
   * Toggle activity
   */
  this.toggleActivity = function(activity) {
    this.filter.update('activity', activity.id || null);
  };
});
