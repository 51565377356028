
/**
 * Module definition and dependencies
 */
angular.module('Shared.Count.Filter', [])

/**
 * Filter definitions
 */
.filter('count', function() {
  return function count(num, singular, plural, max) {
    if (typeof num === 'undefined') {
      num = 0;
    }
    if (typeof plural === 'number') {
      max = plural;
      plural = undefined;
    }
    if (typeof plural === 'undefined') {
      plural = singular + 's';
    }
    if (num === 1) {
      return `${num} ${singular}`;
    }
    if (num === max) {
      return `${num}+ ${plural}`;
    }
    return `${num} ${plural}`;
  };
});
