
/**
 * Module definition and dependencies
 */
angular.module('Shared.DebitCredit.Options', [])

/**
 * Constant definition
 */
.constant('DebitCredit', [
  {
    value: false,
    label: 'Collect money',
    labelAlt: 'Account credit purchase',
  },
  {
    value: true,
    label: 'Refund money',
    labelAlt: 'Refund with account credit',
  },
]);
