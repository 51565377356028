
/**
 * Module definition and dependencies
 */
angular.module('Shared.Access.Card', [
  'Shared.AccessOptions.Component',
])

/**
 * Component
 */
.component('cardAccess', {
  templateUrl: 'cards/access.html',
  controller: 'CardAccessCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    memberships: '<',
    doors: '<',
    item: '<',
    itemType: '@',
    system: '<',
    access: '<',
    required: '<',
    isLimited: '<',
    isEdit: '<',
    withConstraint: '<',
    hasNext: '<',
    showBulkVaccination: '<',
    noAccessText: '@',
    onSave: '&',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardAccessCtrl', function($q, $modal, Access, TimeRange) {

  /**
   * On init
   */
  this.$onInit = function() {
    this.isSaving = false;
  };

  /**
   * On changes
   */
  this.$onChanges = function() {

    //Ensure item
    if (!this.item) {
      return;
    }

    //Ensure array
    if (!Array.isArray(this.item.access)) {
      this.item.access = [];
    }

    //Create copy
    this.access = this.item.extract('access');
    this.checkFlags();
  };

  /**
   * Check flags
   */
  this.checkFlags = function() {
    this.isErrorRequired = false;
    this.hasAccess = this.access.length > 0;
    this.canDelete = (this.access.length > 1 || !this.required);
    this.canAdd = (this.access.length < 1 || !this.isLimited);
  };

  /**
   * Confirm
   */
  this.confirm = function() {
    if (this.hasNext) {
      this.next();
    }
    else {
      this.save();
    }
  };

  /**
   * Save
   */
  this.save = function() {

    //Check if we have at least one access entry
    if (this.required && !this.hasAccess) {
      this.isErrorRequired = true;
      return;
    }

    //Get data
    const {access} = this;

    //Mark as saving
    this.isSaving = true;
    this.isErrorRequired = false;

    //Save
    this
      .persistItem(access)
      .then(() => this.card.close())
      .finally(() => this.isSaving = false);
  };

  /**
   * Merge
   */
  this.merge = function() {

    //Get data
    const {item, itemType, access} = this;

    //Merge
    this
      .onMerge({$event: {
        [itemType]: item,
        model: {access},
      }});
  };

  /**
   * Next
   */
  this.next = function() {
    this.merge();
    this.card.next();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previous();
  };

  /**
   * Persist item on server
   */
  this.persistItem = function(access) {

    //Get data
    const {isEdit, item, itemType} = this;

    //Persist
    return this
      .onSave({$event: {
        [itemType]: item,
        model: {access},
        isEdit,
      }})
      .then(() => {
        this.access = item.extract('access');
        this.checkFlags();
      });
  };

  /**
   * Bulk vaccination
   */
  this.bulkVaccination = function() {
    const {system} = this;
    $modal.open('bulkVaccination', {
      locals: {system},
    });
  };

  /**
   * Add access entry
   */
  this.add = function() {

    //Create access and define handler
    const entry = new Access();
    const {isEdit, isLimited, withConstraint, item, doors, memberships} = this;
    const $ctrl = this;

    //Determine access array to use
    const access = isEdit ?
      item.extract('access') : this.access;

    //Define handler
    const handler = function(model) {
      entry.fromJSON(model);
      access.push(entry);
      if (isEdit) {
        return $ctrl.persistItem(access);
      }
      return $q.resolve();
    };

    //If limited, initialize the time range for full access
    if (isLimited) {
      entry.timeRange = TimeRange.full();
    }

    //Show modal
    $modal
      .open('editAccess', {
        locals: {entry, isLimited, withConstraint, memberships, doors, handler},
      })
      .result
      .then(() => this.checkFlags());
  };

  /**
   * Edit access entry
   */
  this.edit = function($event) {

    //Get access entry
    const {entry} = $event;
    const {isEdit, isLimited, withConstraint, access, doors, memberships} = this;
    const $ctrl = this;

    //Define handler
    const handler = function(model) {
      entry.fromJSON(model);
      if (isEdit) {
        return $ctrl.persistItem(access);
      }
      return $q.resolve();
    };

    //Show modal
    $modal
      .open('editAccess', {
        locals: {
          entry, isEdit, isLimited, withConstraint, memberships, doors, handler,
        },
      });
  };

  /**
   * Delete access entry
   */
  this.delete = function($event) {

    //Get access entry
    const {entry} = $event;
    const {isEdit, item, doors} = this;
    const $ctrl = this;

    //Find index and determine access array to use
    const index = this.access.findIndex(a => a === entry);
    const access = isEdit ?
      item.extract('access') : this.access;

    //Validate index
    if (index === -1 || !access[index]) {
      return;
    }

    //Define handler
    const handler = function() {
      access.splice(index, 1);
      if (isEdit) {
        return $ctrl.persistItem(access);
      }
      return $q.resolve();
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-delete-access.html',
        locals: {entry, doors, handler},
      })
      .result
      .then(() => this.checkFlags());
  };
});
