
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.ViewCoupon.Modal', [])

 /**
  * Config
  */
 .config($modalProvider => {
   $modalProvider.modal('viewCoupon', {
     templateUrl: 'coupon/modals/view-coupon.html',
     controller: 'ModalViewCouponCtrl',
   });
 })

 /**
  * Controller
  */
 .controller('ModalViewCouponCtrl', function(
   $controller, $modal, $modalInstance
 ) {

   //Get controllers
   const $ctrl = this;
   const $base = $controller('ModalCtrl', {$modalInstance});

   //Extend
   angular.extend($ctrl, $base);

   /**
    * Refund session
    */
   this.refundSession = function(index) {

     //Get session
     const {coupon, canRefund} = this;
     const session = coupon.sessions[index];

     //Can't refund
     if (!canRefund) {
       return;
     }

     //Create handler
     const handler = () => coupon.refundSession(index);

     //Open modal
     $modal
       .open('basic', {
         templateUrl: 'coupon/modals/refund-coupon-session.html',
         locals: {coupon, index, session, handler},
       })
       .result
       .then(() => {
         if (coupon.sessions.length === 0) {
           $modalInstance.close();
         }
       });
   };
 });
