
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Report.Period', [])

/**
 * Component
 */
.component('reportPeriod', {
  template: `
    <p class="ReportPeriod">
      For the period
      <strong>{{::$ctrl.basePeriod.fromDate | moment}}</strong> to
      <strong>{{::$ctrl.basePeriod.toDate | moment}}</strong><br>
      <span ng-if="$ctrl.isComparing">
        Compared with
        <strong>{{::$ctrl.compPeriod.fromDate | moment}}</strong> to
        <strong>{{::$ctrl.compPeriod.toDate | moment}}</strong>
      </span>
    </p>
  `,
  bindings: {
    basePeriod: '<',
    compPeriod: '<',
    isComparing: '<',
  },
});
