
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Staff.Controller', [])

/**
 * Base controller
 */
.controller('AdminStaffCtrl', function(
  $q, $store, $modal, $notice, Member, ReplacementTags
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Load custom fields in the background
    this.loadCustomFields();
  };

  /**
   * Merge
   */
  this.merge = function($event) {

    //Get member and model
    const {member, model} = $event;
    this.isDirty = true;

    //Merge
    member.merge(model);
    return $q.resolve();
  };

  /**
   * Patch
   */
  this.patch = function($event) {

    //Get model
    const {member, model} = $event;

    //Patch
    return member
      .patch(model)
      .then(() => {
        $notice.show('Staff member updated');
        this.onPatched({member});
      })
      .catch(error => {
        $notice.showError('Failed to update staff member');
        throw error;
      });
  };

  /**
   * Save
   */
  this.save = function($event) {

    //Get data
    const {member, model, isEdit} = $event;
    const {meta} = member;

    //Save
    return member
      .save(model)
      .then(member => {
        this.onSaved({member});
        if (isEdit) {
          $notice.show('Staff member updated');
        }
        else {
          $notice.show('Staff member added');
        }
        if (member.staff && member.staff.isPublic) {
          this.club.markStepComplete('displayStaff', true);
        }
        if (!isEdit && member.email && meta) {
          const {sendWelcomeEmail} = meta;
          if (sendWelcomeEmail) {
            this.sendWelcomeEmailDirectly({member});
          }
        }
      })
      .catch(error => {
        if (isEdit) {
          $notice.showError('Failed to update staff member');
        }
        else {
          $notice.showError('Failed to add staff member');
        }
        throw error;
      });
  };

  /**
   * Delete
   */
  this.delete = function($event) {

    //Get member and create handler
    const {member} = $event;
    const handler = function(model) {
      return member.delete(model);
    };

    //Open confirmation dialog
    $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-delete-member.html',
        locals: {member, handler},
      })
      .result
      .then(() => {
        $notice.show('Staff member removed');
        this.onDeleted({member});
      });
  };

  /**
   * Suspend/unsuspend
   */
  this.toggleSuspended = function($event) {

    //Get member and flag
    const {member, isSuspended} = $event;
    const method = isSuspended ? 'suspendStaff' : 'unsuspendStaff';

    //Define handler
    const handler = (model) => member[method](model);

    //Show confirmation modal
    return $modal
      .open('basic', {
        templateUrl: `admin/people/members/modals/confirm-toggle-suspended.html`,
        locals: {member, handler},
      })
      .result
      .then(() => {
        $notice.show(
          isSuspended ? 'Staff member suspended' : 'Staff member un-suspended'
        );
        this.onPatched({member, field: 'isSuspended'});
      });
  };

  /**
   * Archive/restore
   */
  this.toggleArchived = function($event) {

    //Get member and flag
    const {member, isArchived} = $event;
    const method = isArchived ? 'archiveStaff' : 'restoreStaff';

    //Define handler
    const handler = () => member[method]();

    //Show confirmation modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-toggle-archived.html',
        locals: {member, handler},
      })
      .result
      .then(() => {
        $notice.show(
          isArchived ? 'Staff member archived' : 'Staff member restored'
        );
        this.onPatched({member, field: 'isArchived'});
      });
  };

  /**
   * Send welcome email
   */
  this.sendWelcomeEmail = function($event) {

    //Get member and open modal
    const {member} = $event;
    $modal.open('welcomeEmail', {locals: {member}});
  };

  /**
   * Send email to one member
   */
  this.sendEmail = function($event) {

    //Get member and define handler
    const {member} = $event;
    const {customFields} = this;
    const {id, name, email} = member;
    const filter = {ids: [id], hasEmail: true};
    const recipient = `${name} <${email}>`;
    const handler = email => Member.emailStaff(filter, email);
    const tags = ReplacementTags.member(customFields);

    //Open modal
    $modal.open('email', {locals: {handler, recipient, tags}});
  };

  /**
   * Send welcome email directly
   */
  this.sendWelcomeEmailDirectly = function($event) {

    //Get member and send welcome email
    const {member} = $event;
    member
      .sendWelcomeEmail()
      .then(() => $notice.show('Welcome email sent'))
      .catch(() => $notice.showError('Failed to send welcome email'));
  };

  /**
   * Load custom fields
   */
  this.loadCustomFields = function() {
    return $store.customFields
      .query({model: 'Member'})
      .then(fields => this.customFields = fields);
  };

  /**
   * No-ops (to extend as needed)
   */
  this.onSaved = function() {};
  this.onPatched = function() {};
  this.onDeleted = function() {};
});
