
/**
 * Module definition and dependencies
 */
angular.module('Shared.Helpers.Service', [])

/**
 * Service definition
 */
.factory('Helpers', function() {
  return {

    //Helper to escape a regex string
    escapeRegex(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },

    /**
     * Random picker helper
     */
    pickRandom(arr) {
      return arr[Math.floor(Math.random() * arr.length)];
    },

    /**
     * Lookup dotted path in an object
     */
    get(obj, path, defaultValue = undefined) {

      //Empty path, return object
      if (!path) {
        return obj;
      }

      //Split path in object keys to traverse
      const keys = path.split('.');
      for (let i = 0; i < keys.length && obj !== undefined; i++) {
        const key = keys[i];
        obj = (obj !== null) ? obj[key] : undefined;
      }

      //Return default value if undefined
      if (obj === undefined) {
        return defaultValue;
      }

      //Return reference
      return obj;
    },

    /**
     * Set dotted path value in an object
     */
    set(obj, path, value) {

      //Empty path
      if (!path) {
        return;
      }

      //Split path in object keys to traverse
      const keys = path.split('.');
      for (let i = 0; i < (keys.length - 1); i++) {
        const key = keys[i];
        if (!obj[key]) {
          obj[key] = {};
        }
        obj = obj[key];
      }

      //Get last key
      const prop = keys[keys.length - 1];
      obj[prop] = value;
    },

    /**
     * Change an option
     */
    changeOption(
      options, value, label, valueKey = 'value', labelKey = 'label'
    ) {

      //Create new options map
      return options
        .map(option => {
          const copy = Object.assign({}, option);
          if (copy[valueKey] === value) {
            copy[labelKey] = label;
          }
          return copy;
        });
    },

    /**
     * Remove an option with a given value
     */
    removeOption(options, value, key = 'value') {
      return options.filter(option => option[key] !== value);
    },
  };
});
