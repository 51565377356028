
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.CampaignMonitor.Status.Card', [])

/**
 * Component
 */
.component('cardIntegrationsCampaignMonitorStatus', {
  templateUrl: 'admin/integrations/campaign-monitor/status.html',
  controller: 'CardIntegrationsCampaignMonitorStatusCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    isAvailable: '<',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsCampaignMonitorStatusCtrl', function(
  $controller
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.hasDefaultLists = !!this.integration.data.defaultLists;
    this.isSetupComplete = (this.hasDefaultLists);
  };
});
