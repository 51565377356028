
/**
 * Module definition and dependencies
 */
angular.module('Shared.Papa.Service', [])

/**
 * Service definition
 */
.factory('Papa', function($window) {
  if (!$window.Papa) {
    throw new Error('Global `Papa` variable missing');
  }
  return $window.Papa;
});
