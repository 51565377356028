
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.SecondaryMembers', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.secondaryMembers', {
    url: '/linked-members',
    component: 'registerSecondaryMembersRoute',
  });
})

/**
 * Route component
 */
.component('registerSecondaryMembersRoute', {
  controller: 'RegisterSecondaryMembersCtrl',
  templateUrl: 'portal/register/steps/secondary-members.html',
  bindings: {
    club: '<',
    memberships: '<',
    registration: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterSecondaryMembersCtrl', function(
  $controller, $modal, Config
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('RegisterBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Toggle consent
    if (Config.env === 'dev') {
      this.hasConsent = true;
    }

    //Propagate details to existing linked members (with same household)
    this.registration.updateHouseholdDetails();
  };

  /**
   * Add secondary member
   */
  this.addSecondaryMember = function() {

    //Get data
    const {club} = this;
    const {primaryMember} = this.registration;
    const member = this.registration.createSecondaryMember();

    //Create handler
    const handler = (model) => {
      Object.assign(member, model);
      this.registration.addSecondaryMember(member);
    };

    //Show modal
    $modal.open('addSecondaryMember', {locals: {
      club, primaryMember, member, handler,
    }});
  };

  /**
   * Edit secondary member
   */
  this.editSecondaryMember = function(member) {

    //Get data
    const {club} = this;
    const {primaryMember} = this.registration;

    //Create handler
    const handler = (model) => {
      Object.assign(member, model);
    };

    //Show modal
    $modal.open('addSecondaryMember', {locals: {
      club, primaryMember, member, handler, isEdit: true,
    }});
  };

  /**
   * Toggle consent flag
   */
  this.toggleConsent = function(hasConsent) {
    this.hasConsent = hasConsent;
    this.isErrorConsent = false;
  };

  /**
   * Remove secondary member
   */
  this.removeSecondaryMember = function(member) {
    this.registration.removeSecondaryMember(member);
  };

  /**
   * Validate
   */
  this.validate = function() {

    //Validate consent
    if (!this.hasConsent) {
      this.isErrorConsent = true;
      return;
    }

    //All good
    this.continue();
  };
});
