
/**
 * Module definition and dependencies
 */
angular.module('Shared.EmailLog.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config($apiProvider => {

  //Register endpoint
  $apiProvider.registerEndpoint('emailLog', {
    model: 'EmailLog',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'emailLogs',
        isArray: true,
        isModel: true,
      },
    },
  });
})

/**
 * Model definition
 */
.factory('EmailLog', function($baseModel, $api, $sync) {

  /**
   * Constructor
   */
  function EmailLog(data) {
    $baseModel.call(this, data);

    //Success/error statuses
    const success = ['delivered', 'opened', 'clicked'];
    const error = ['bounced', 'dropped', 'error'];
    const warning = ['deferred', 'unknown'];

    //Success/error properties
    Object.defineProperty(this, 'isSuccess', {
      get() {
        return (success.includes(this.status));
      },
    });
    Object.defineProperty(this, 'isError', {
      get() {
        return (error.includes(this.status));
      },
    });
    Object.defineProperty(this, 'isWarning', {
      get() {
        return (warning.includes(this.status));
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(EmailLog.prototype, $baseModel.prototype);

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  EmailLog.query = function(filter) {
    return $api.emailLog.query(filter);
  };

  /**
   * Export
   */
  EmailLog.export = function(filter) {
    return $sync.get('emailLog/export/csv', filter, 'Exporting...');
  };

  //Return
  return EmailLog;
});
