
/**
 * Module definition and dependencies
 */
angular.module('Shared.Text.CutOff.Filter', [])

/**
 * Filter definitions
 */
.filter('cutOff', function() {
  return function(text, max, ending = '…') {

    //All good
    if (!text || text.length <= max) {
      return text;
    }

    //Split into parts
    const parts = String(text).split(' ');

    //Just one part? Must be a very long word
    if (parts.length === 1) {
      return text.substring(0, max) + ending;
    }

    //Create cut
    const cut = [];
    let length = 0;

    //Consume parts
    while (length < max && parts.length > 0) {
      const part = parts.shift();
      cut.push(part);
      length += part.length;
    }

    //If length now exceeds max, pop last part
    if (length > max) {
      cut.pop();
    }

    //Join and return
    return cut.join(' ') + ending;
  };
});
