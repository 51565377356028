
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.Permissions.Card', [])

/**
 * Component
 */
.component('cardMemberEditPermissions', {
  templateUrl: 'admin/people/members/cards/permissions.html',
  controller: 'CardMemberEditPermissionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    member: '<',
    system: '<',
    onSave: '&',
    isOwn: '<',
    isSelf: '<',
    isStaff: '<',
  },
})

/**
 * Controller
 */
.controller('CardMemberEditPermissionsCtrl', function(Roles, CardCloseReasons) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set flags and roles
    this.isEdit = true;
    this.isSaving = false;
    this.roles = Roles;

    //Fields to extract
    const fields = this.isOwn ?
      [] : [
        'roles', 'bypassBookingLimits',
        'canOverrideLights', 'canOverrideDoors', 'doorsAllowedToOverride',
        'canBookConsecutive', 'canBookConcurrent', 'canBookExtended',
        'isPending',
      ];

    //Disabled roles
    this.disabledRoles = this.member.isAccountOwner ? ['admin'] : null;

    //Create model subset
    this.model = this.member.extract(fields);

    //Check overrides
    if (!this.isOwn) {
      this.checkPermissions();
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {

    //Set new value
    this.model[property] = value;

    //Roles updated
    if (property === 'roles') {
      this.checkPermissions();
    }

    //Override doors changed
    if (property === 'canOverrideDoors') {
      if (this.model.canOverrideDoors) {
        this.model.doorsAllowedToOverride = this.system.doors
          .map(door => door.id);
      }
      else {
        this.model.doorsAllowedToOverride = [];
      }
    }
  };

  /**
   * Check permissions
   */
  this.checkPermissions = function() {
    if (this.model.roles.includes('admin')) {
      this.arePermissionsDisabled = true;
      this.model.canBookConsecutive = true;
      this.model.canBookConcurrent = true;
      this.model.canBookExtended = true;
      this.model.bypassBookingLimits = true;
    }
    else {
      this.arePermissionsDisabled = false;
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Extract data
    const {member, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {member, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
