
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.PaymentSourceOptions.Component', [])

/**
 * Payment source options component
 */
.component('paymentSourceOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="!$ctrl.source.isDefault && !$ctrl.source.card.isExpired">
        <a ng-click="$ctrl.makeDefault()">Make default</a>
      </li>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.remove()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    source: '<',
    onRemove: '&',
    onMakeDefault: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Delete
     */
    this.remove = function() {
      const {source} = this;
      this.onRemove({$event: {source}});
    };

    /**
     * Toggle active
     */
    this.makeDefault = function() {
      const {source} = this;
      this.isBusy = true;
      this
        .onMakeDefault({$event: {source}})
        .finally(() => this.isBusy = false);
    };
  },
});
