
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Log', [
  'App.Admin.Log.Audit',
  'App.Admin.Log.Email',
  'App.Admin.Log.Access',
  'App.Admin.Log.Activity',
  'App.Admin.Log.CheckIn',
  'App.Admin.Log.Visitor',
  'App.Admin.Log.Options.Component',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.log', {
    url: '/log',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'logs',
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, $notice, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.log.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.log.audit', title});
  }, {priority: 10});

  //Pre-define generic option to refresh logs
  Page.defineOption('refreshLogs', {
    title: 'Refresh logs',
    icon: 'refresh',
    click() {

      //Already busy, ignore
      if (this.isLoading) {
        return;
      }

      //Mark as loading, refresh access logs
      this.isLoading = true;
      Page.controller
        .loadLogs()
        .then(() => $notice.show('Logs refreshed'))
        .catch(() => $notice.showError('Failed to refresh logs'))
        .finally(() => this.isLoading = false);
    },
  });
});
