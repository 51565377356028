
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Contacts.Add', [
  'App.Admin.People.Contacts.Add.Controller',
  'App.Admin.People.Contacts.Add.Details.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.contacts.add', {
    url: '/add',
    component: 'adminContactAddRoute',
    data: {
      roles: ['admin'],
    },
    resolve: {
      contact(Contact) {
        return new Contact();
      },
      groups($store) {
        return $store.contactGroups.query();
      },
      customFields($store) {
        return $store.customFields.query({model: 'Contact'});
      },
    },
  });
})

/**
 * Route component
 */
.component('adminContactAddRoute', {
  controller: 'AdminContactAddCtrl',
  templateUrl: 'admin/people/contacts/add/add.html',
  bindings: {
    club: '<',
    user: '<',
    contact: '<',
    groups: '<',
    customFields: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.people.contacts.add'}, () => {
    Page.setTitle(`Add contact`);
    Page.addCrumb({sref: 'admin.people.contacts.add'});
  });
});
