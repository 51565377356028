
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.ViewBooking.Modal', [
  'App.Booking.Flow.View.Component',
  'App.Booking.Flow.Edit.Component',
])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('viewBooking', {
    templateUrl: 'booking/modals/view-booking.html',
    controller: 'ModalViewBookingCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalViewBookingCtrl', function(
  $controller, $modalInstance, $modal, $notice, $store, $state,
  Settings, moment
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get data
    const {booking, user, activity} = this;

    //Get information to determine modes
    const hasAllowedModes = user && user.hasAllowedModes(activity.id);
    const memberships = user ? user.getCurrentAndUpcomingMemberships() : [];

    //Get restrictions
    const restrictions = activity.findRestrictions('bookingTimes', memberships);

    //Filter available modes
    this.modes = activity.modes.filter(mode => {

      //Show all for kiosk mode, as we can only know the allowed modes
      //when we know the members
      if (user) {
        return true;
      }

      //Check specific allowed modes first if needed
      //NOTE: If a member doesn't have a membership, they might still have
      //allowed modes, so this needs to be handled because it might not be desirable
      if (hasAllowedModes) {
        return user.isAllowedMode(mode);
      }

      //Check restrictions
      return restrictions
        .some(r => r.modes.some(modeId => modeId === mode.id));
    });

    //Initialize flags
    this.isOwn = false;
    this.isEditing = false;
    this.canPay = false;
    this.canEdit = false;
    this.canDelete = false;

    //Check if user present
    if (user) {
      this.isOwner = booking.isOwner(user);
      this.hasMember = booking.hasMember(user);
      this.isOwn = this.isOwner || this.hasMember;
      this.isAdmin = user.hasRole('admin');
      this.canManageBookings = user.hasRole('admin', 'eventManager');
    }

    //Check flags
    this.checkFlags();
  };

  /**
   * Check flags
   */
  this.checkFlags = function() {

    //Get data
    const {booking, isOwner, hasMember, canManageBookings, activity} = this;
    const {
      needsPayment, isRecurring, forOthers, isPast, isRemoved, startDate,
    } = booking;
    this.isInvolved = (hasMember || (isOwner && !forOthers));
    const isAllowedToPay = ((hasMember && forOthers) || (isOwner && !forOthers));
    const {isInvolved} = this;
    const {amount, unit} = Settings.get('booking.changeThreshold');
    const thresholdAllowed = startDate.isAfter(moment().add(amount, unit));
    const {allowChangingBookings} = activity;

    //NOTE: We disallow any member to pay for a booking if it hasn't been created
    //by an admin, probably because we don't want a 2nd member to accidentally
    //pay for it while the first member is still completing their payment.
    //But this is also a problem for bookings created by an admin, as both members
    //can in theory pay for the booking and pay at the same time, resulting in
    //a double up of payments. Also they would receive the email with a pay now
    //button, which they might be inclined to click on while the member that made
    //the booking is still paying for it. But that could be solved by delaying the booking /
    //confirmation email.

    //Set flags
    this.canEdit = (!isRemoved && !isRecurring) &&
                   (canManageBookings ||
                   (hasMember && !forOthers &&
                    thresholdAllowed && allowChangingBookings));
    this.canDelete = (!isRemoved && (isInvolved || canManageBookings));
    this.canPay = (!isRemoved && isAllowedToPay && needsPayment && !isPast);
  };

  /**
   * Edit booking
   */
  this.edit = function() {

    //Re-check flags
    this.checkFlags();

    //Check if still possible
    if (this.canEdit) {
      this.isEditing = true;
    }
  };

  /**
   * View booking
   */
  this.view = function() {
    this.isEditing = false;
  };

  /**
   * Pay for booking
   */
  this.pay = function() {
    const {booking} = this;
    $state.go('account.booking', {bookingId: booking.id});
  };

  /**
   * Changed booking
   */
  this.changed = function($event) {
    const {booking} = $event;
    $modalInstance.resolve(booking);
  };

  /**
   * Remove booking
   */
  this.delete = function() {

    //Define handler
    const {user, booking} = this;
    const handler = data => $store.bookings.delete(booking, data);

    //Open modal
    return $modal
      .open('removeBooking', {locals: {booking, user, handler}})
      .result
      .then(() => {

        //Refresh user's account credit
        if (booking.isOwner(user) || booking.hasMember(user)) {
          user.refreshAccountCredit();
        }

        //If the booking was recurring, clear stores
        if (booking.isRecurring) {
          $store.bookings.clear();
        }

        //Show notice
        $notice.show('Booking removed');
        $modalInstance.resolve(null);
      });
  };
});
