
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.ChangeNoPayment.Card', [])

/**
 * Component
 */
.component('cardSubscriptionChangeNoPayment', {
  templateUrl: 'subscription/cards/change-no-payment.html',
  controller: 'CardSubscriptionChangeNoPaymentCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    onChange: '&',
    onPrev: '&',
    onCancel: '&',
  },
})

/**
 * Controller
 */
.controller('CardSubscriptionChangeNoPaymentCtrl', function() {

  /**
   * Change
   */
  this.change = function() {

    //Flag as changing
    this.isChanging = true;

    //Change
    return this
      .onChange()
      .finally(() => this.isChanging = false);
  };

  /**
   * Go back
   */
  this.prev = function() {
    this.onPrev();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
