
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Log.Email', [
  'App.Admin.Log.Email.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.log.email', {
    url: '/email',
    component: 'adminLogEmailRoute',
    data: {
      id: 'emailLogs',
    },
    resolve: {
      filter(Filters) {
        return Filters.get('emailLogs');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminLogEmailRoute', {
  controller: 'AdminLogEmailCtrl',
  templateUrl: 'admin/log/email/email.html',
  bindings: {
    filter: '<',
    club: '<',
    user: '<',
    page: '<',
    system: '<',
  },
});
