
/**
 * Module definition and dependencies
 */
angular.module('Shared.Event.Attendee.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('eventAttendee', {
    model: 'EventAttendee',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'attendees',
        isModel: true,
        isArray: true,
      },
      count: {
        url: 'count',
        method: 'GET',
      },
      get: {
        method: 'GET',
        isModel: true,
      },

      //Admin creation of attendees
      create: {
        method: 'POST',
        dataKey: 'attendees',
        isModel: true,
        isArray: true,
      },
      createGuest: {
        url: 'guest',
        method: 'POST',
        dataKey: 'attendees',
        isModel: true,
        isArray: true,
      },
      createMany: {
        url: 'many',
        method: 'POST',
        dataKey: 'attendees',
        isArray: true,
        isModel: true,
      },

      //Create attendance for logged in member or guests for public events
      createOwn: {
        url: 'own',
        method: 'POST',
      },
      createOwnGuest: {
        url: 'own/guest',
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        url: ':method',
        method: 'DELETE',
      },
      markPaid: {
        url: 'markPaid',
        method: 'POST',
      },
      markNotPaid: {
        url: 'markNotPaid',
        method: 'POST',
      },
      useCoupon: {
        url: 'useCoupon',
        method: 'POST',
      },
      useSubscription: {
        url: 'useSubscription',
        method: 'POST',
      },
      email: {
        url: 'email',
        method: 'POST',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('eventAttendees', {
    dataKey: 'attendees',
    model: 'EventAttendee',
  });
})

/**
 * Model definition
 */
.factory('EventAttendee', function(
  $baseModel, $api, $sync, moment, DateFormat, Intercom
) {

  /**
   * Constructor
   */
  function EventAttendee(data) {
    $baseModel.call(this, angular.extend({}, data || {}));

    //Status text
    Object.defineProperty(this, 'status', {
      get() {
        if (this.isRemoved) {
          return 'Cancelled';
        }
        if (this.hasAttended) {
          return 'Attended';
        }
        return 'Going';
      },
    });

    //Status icon
    Object.defineProperty(this, 'statusIcon', {
      get() {
        if (this.isRemoved) {
          return 'block';
        }
        if (this.hasAttended) {
          return 'how_to_reg';
        }
        return 'person';
      },
    });

    //Fee can still be refunded
    Object.defineProperty(this, 'canStillBeRefunded', {
      get() {
        return (
          this.isPaid && !this.isGuest &&
          this.rule.isRefundable && this.rule.refundableUntil &&
          moment().isBefore(this.rule.refundableUntil)
        );
      },
    });

    //Answers map
    Object.defineProperty(this, 'answersMap', {
      get() {
        const map = this.answers.reduce((map, item) => {
          const {question, answer} = item;
          map[question] = answer;

          //Array
          if (Array.isArray(answer)) {
            map[question] = answer.join(', ');
          }

          //Moment
          else if (moment.isMoment(answer)) {
            map[question] = answer.format(DateFormat.formats.standard);
          }

          //Return map
          return map;
        }, {});
        return map;
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(EventAttendee.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  EventAttendee.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('answers', null, true);
    this.convertToModel('member', 'Member');
    this.convertToModel('guest', 'Guest');

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  EventAttendee.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Strip data
    json.club = $baseModel.onlyId(json.club);
    json.event = $baseModel.onlyId(json.event);
    json.guest = $baseModel.onlyId(json.guest);
    json.member = $baseModel.onlyId(json.member);

    //Remove if empty
    if (!json.member) {
      delete json.member;
    }
    if (!json.guest) {
      delete json.guest;
    }

    //Return
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save (update only)
   */
  EventAttendee.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Save attendee
    return $api.eventAttendee
      .update(null, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Create guest
   */
  EventAttendee.prototype.createGuest = function(data) {

    //Extend data with given data
    data = this.toJSON(data);

    //Create attendee
    return $api.eventAttendee
      .createGuest(data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Create own
   */
  EventAttendee.prototype.createOwn = function(circleMemberId) {

    //Get data
    const data = this.toJSON();

    //Create attendee
    return $api.eventAttendee
      .createOwn(circleMemberId, data);
  };

  /**
   * Delete
   */
  EventAttendee.prototype.delete = function(data) {
    const {method, refund} = data;
    const params = {id: this.id, method, refund};
    return $api.eventAttendee
      .delete(params)
      .then(() => this);
  };

  /**
   * Update attended state
   */
  EventAttendee.prototype.updateAttended = function(hasAttended) {
    const {id} = this;
    return $api.eventAttendee
      .patch({id}, {hasAttended})
      .then(data => this.fromJSON(data));
  };

  /**
   * Mark as paid
   */
  EventAttendee.prototype.markPaid = function(data) {
    return $api.eventAttendee
      .markPaid({id: this.id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Mark as not paid
   */
  EventAttendee.prototype.markNotPaid = function() {
    return $api.eventAttendee
      .markNotPaid({id: this.id})
      .then(data => this.fromJSON(data));
  };

  /**
   * Get answers map (copy)
   */
  EventAttendee.prototype.getAnswersMap = function() {
    return this.answers.reduce((map, answer) => {
      map[answer.question] = answer.answer;
      return map;
    }, {});
  };

  /**
   * Set answers map
   */
  EventAttendee.prototype.setAnswersMap = function(map) {
    this.answers = EventAttendee.convertAnswersMap(map);
  };

  /**
   * Use coupon to pay for this event attendance (as admin)
   */
  EventAttendee.prototype.useCoupon = function(coupon) {
    const params = {id: this.id};
    return $api.eventAttendee
      .useCoupon(params, {coupon})
      .then(data => this.fromJSON(data));
  };

  /**
   * Use membership to pay for this event attendance (as admin)
   */
  EventAttendee.prototype.useSubscription = function(subscription) {
    const params = {id: this.id};
    return $api.eventAttendee
      .useSubscription(params, {subscription})
      .then(data => this.fromJSON(data));
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Create many attendees
   */
  EventAttendee.createMany = function(data) {

    //Only ID
    data.event = $baseModel.onlyId(data.event);
    if (data.meta.members) {
      data.meta.members = $baseModel.onlyId(data.meta.members);
    }

    //Call API
    return $api.eventAttendee.createMany(data);
  };

  /**
   * Create own guest attendees for public events
   */
  EventAttendee.createOwnGuest = function(data) {
    return $api.eventAttendee
      .createOwnGuest(data);
  };

  /**
   * Query
   */
  EventAttendee.query = function(filter) {
    return $api.eventAttendee
      .query(filter);
  };

  /**
   * Export
   */
  EventAttendee.export = function(filter) {
    Intercom.event('Exported event attendees');
    return $sync.get('eventAttendee/export/csv', filter, 'Exporting...');
  };

  /**
   * Count
   */
  EventAttendee.count = function(filter) {
    return $api.eventAttendee
      .count(filter)
      .then(data => data.total || 0);
  };

  /**
   * Find by ID
   */
  EventAttendee.findById = function(id) {
    return $api.eventAttendee.get({id});
  };

  /**
   * Cancel own attendance
   */
  EventAttendee.cancelOwn = function(id, circleMemberId) {
    return $api.eventAttendee.delete({id, method: 'own', circleMemberId});
  };

  /**
   * Email
   */
  EventAttendee.email = function(filter, email) {
    return $api.eventAttendee.email(filter, email);
  };

  /**
   * Convert answers map to array
   */
  EventAttendee.convertAnswersMap = function(map) {
    const answers = [];
    for (const id in map) {
      if (map.hasOwnProperty(id)) {
        answers.push({question: id, answer: map[id]});
      }
    }
    return answers;
  };

  //Return
  return EventAttendee;
});
