
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Log.Access', [
  'App.Admin.Log.Access.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.log.access', {
    url: '/access',
    component: 'adminLogAccessRoute',
    data: {
      id: 'accessLogs',
    },
    resolve: {
      filter(Filters) {
        return Filters.get('accessLogs');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminLogAccessRoute', {
  controller: 'AdminLogAccessCtrl',
  templateUrl: 'admin/log/access/access.html',
  bindings: {
    club: '<',
    user: '<',
    filter: '<',
    page: '<',
    system: '<',
  },
});
