
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.Modules.Card', [])

/**
 * Component
 */
.component('cardSettingsModules', {
  templateUrl: 'admin/settings/cards/modules.html',
  controller: 'CardSettingsModulesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
  },
})

/**
 * Controller
 */
.controller('CardSettingsModulesCtrl', function(
  $controller, ModuleDefaults, Modules, GlobalScope, Emitter
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'general';
    this.setupStep = 'setupModules';

    //Call parent init
    $base.$onInit.call(this);

    //Generate modules
    this.generateModules();
  };

  /**
   * Generate modules
   */
  this.generateModules = function() {

    //Modules that can be toggled
    this.modules = [];
    for (const id in ModuleDefaults) {
      const module = ModuleDefaults[id];
      const isDisabled = !Modules.has(id);
      if (module.canToggle) {
        this.modules.push(Object.assign({id, isDisabled}, module));
      }
    }

    //Sort by title
    this.modules.sort((a, b) => (a.title > b.title) ? 1 : -1);
  };

  /**
   * Patch module
   */
  this.patchModule = function(module, prop, value) {
    this.club
      .patchModule(module.id, {[prop]: value})
      .then(() => {

        //Get modules
        const custom = this.club.modules;
        const modules = Modules.set(custom);

        //Set in global scope
        GlobalScope.set({modules});

        //Regenerate modules
        this.generateModules();

        //Mark step as completed
        this.club.markStepComplete('chooseModules', true);

        //Rebuild menu
        Emitter
          .get('menu')
          .emit('rebuild');
      });
  };
});
