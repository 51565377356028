
/**
 * Module definition and dependencies
 */
angular.module('Shared.EditAccess.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editAccess', {
    templateUrl: 'modals/edit-access.html',
    controller: 'ModalEditAccessCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalEditAccessCtrl', function(
  $controller, $modalInstance, $filter, MembershipConstraintLabels, Weekdays
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set labels
    this.MembershipConstraintLabels = MembershipConstraintLabels
      .filter(item => !item.isGuest);

    //Distribute memberships
    if (this.memberships) {
      this.memberships = $filter('distribute')(this.memberships, 3);
    }

    //Extract model
    this.model = this.entry.extract([
      'doors', 'timeRange', 'limit',
      'constraint', 'memberships', 'requiresVaccination',
    ]);

    //Fill time range
    if (!this.isEdit && this.model.timeRange.isEmpty()) {
      this.model.timeRange.fillDays();
      this.model.timeRange.fillTime();
    }

    //NOTE: Needed otherwise times don't populate. No idea what's going on here.
    setTimeout(() => {
      this.model = this.entry.extract([
        'doors', 'timeRange', 'limit',
        'constraint', 'memberships', 'requiresVaccination',
      ]);
      if (!this.isEdit && this.model.timeRange.isEmpty()) {
        this.model.timeRange.fillDays();
        this.model.timeRange.fillTime();
      }
    }, 10);

    //Set flags
    this.isSaving = false;
    this.isError = false;

    //Services
    this.Weekdays = Weekdays;
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };
});
