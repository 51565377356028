
/**
 * Module definition and dependencies
 */
angular.module('App.Home.Controller', [])

/**
 * Controller
 */
.controller('HomeCtrl', function(
  $timeout, $store, $state, $q, $modal, moment,
  Modules, Booking, Event, Member, Settings
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize flags
    this.hasAlerts = false;
    this.hasBookings = false;
    this.hasHighlighted = false;
    this.hasStaff = false;
    this.hasEvents = false;

    //Other data
    this.isAdmin = this.user.hasRole('admin');
    this.canViewHiddenEvents = this.user.hasRole('admin', 'eventManager');

    //Show invalid user modal
    if ($state.params.invalidUser) {
      $modal.open('basic', {
        templateUrl: 'modals/invalid-link.html',
        locals: {invalidUser: true},
      });
    }

    //Show already logged in modal
    if ($state.params.alreadyLoggedIn) {
      $modal.open('basic', {
        templateUrl: 'portal/register/modals/already-logged-in.html',
      });
    }

    //Load stuff
    this.loadAlerts();
    this.loadHighlighted();
    this.loadStaff();
    if (Modules.has('bookings')) {
      this.loadBookings();
    }
    if (Modules.has('events')) {
      this.loadEvents();
    }

    //Pre-load bookings and events
    $timeout(() => {
      this.preLoadBookingsAndEvents();
    }, 100);
  };

  /**
   * Load alerts
   */
  this.loadAlerts = function() {
    $store.alerts
      .query({isRelevant: true})
      .then(alerts => {
        this.alerts = alerts;
        this.hasAlerts = alerts.length > 0;
      });
  };

  /**
   * Load bookings
   */
  this.loadBookings = function() {
    Booking
      .own({
        fromDate: moment().subtract(30, 'minutes'),
        isRemoved: false,
        limit: 4,
      })
      .then(bookings => {
        this.bookings = bookings;
        this.hasBookings = bookings.length > 0;
      });
  };

  /**
   * Load highlighted members
   */
  this.loadHighlighted = function() {
    Member
      .findForDisplay('highlight')
      .then(highlighted => {
        this.highlighted = highlighted;
        this.hasHighlighted = highlighted.length > 0;
      });
  };

  /**
   * Load staff
   */
  this.loadStaff = function() {
    Member
      .findForDisplay('staff')
      .then(staff => {
        this.staff = staff;
        this.hasStaff = staff.length > 0;
      });
  };

  /**
   * Load events
   */
  this.loadEvents = function() {

    //Create filter
    const filter = {
      fromDate: moment().startOf('day'),
      toDate: moment().add(1, 'year').endOf('day'),
      forHomepage: true,
      limit: Settings.get('event.numEventsOnHomepage', 4),
    };

    //Don't include hidden events
    if (!this.canViewHiddenEvents) {
      filter.isHidden = false;
    }

    //Query events
    Event
      .query(filter)
      .then(data => {
        this.events = data.events;
        this.hasEvents = data.events.length > 0;
      });
  };

  /**
   * Pre-load bookings and events for the day
   */
  this.preLoadBookingsAndEvents = function() {

    //Create dates
    const fromDate = moment().startOf('day');
    const toDate = moment().endOf('day');

    //Create query filter
    const filter = {fromDate, toDate};
    const promises = [];

    //Bookings and events module check
    if (Modules.has('bookings')) {
      promises.push($store.bookings.query(filter, true));
    }
    if (Modules.has('events')) {
      promises.push($store.events.query(filter, true));
    }

    //Load all
    return $q.all(promises);
  };

  /**
   * Remove demo data
   */
  this.removeDemoData = function() {

    //Create handler
    const handler = () => this.club.removeDemoData();

    //Open modal
    $modal.open('basic', {
      templateUrl: 'admin/setup/modals/confirm-delete-mock-data.html',
      locals: {handler},
    });
  };
});
