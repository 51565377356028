
/**
 * Module definition and dependencies
 */
angular.module('Shared.BookingView.Details.Component', [])

/**
 * Booking details component
 */
.component('bookingViewDetails', {
  templateUrl: 'shared/booking-view/details.html',
  bindings: {
    mode: '<',
    booking: '<',
    showActivity: '<',
    showBookedBy: '<',
    showRemovedBy: '<',
  },
});
