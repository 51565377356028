
/**
 * Module definition and dependencies
 */
angular.module('Shared.MembershipConstraint.Filter', [])

/**
 * Filter definition
 */
.filter('membershipConstraint', function(MembershipConstraints) {
  return function(item, memberships, limit = 2) {

    //Get constraint
    const {constraint} = item;

    //All members
    if (constraint === MembershipConstraints.ALL) {
      return 'Everyone';
    }

    //All members
    if (constraint === MembershipConstraints.WITH) {
      return 'Members with any valid membership';
    }

    //Casuals
    if (constraint === MembershipConstraints.WITHOUT) {
      return 'Casuals without membership';
    }

    //Guests or visitors
    if (constraint === MembershipConstraints.GUEST) {
      return 'Guests and visitors';
    }

    //Groups
    if (constraint === MembershipConstraints.GROUPS) {
      return 'Specific member groups';
    }

    //No memberships passed
    if (!Array.isArray(memberships)) {
      if (constraint === MembershipConstraints.SPECIFIC_WITHOUT) {
        return 'Casuals and specific memberships';
      }
      return 'Specific memberships';
    }

    //No membership selected
    if (item.memberships.length === 0) {
      if (constraint === MembershipConstraints.SPECIFIC_WITHOUT) {
        return `Casuals without membership`;
      }
      return `No one`;
    }

    //Specific memberships
    const num = item.memberships.length;
    const slice = (limit && num > limit + 1) ? limit : num;
    const display = item.memberships
      .map(id => memberships.find(membership => membership.isSame(id)))
      .filter(membership => !!membership)
      .map(membership => membership.nameWithSuffix)
      .slice(0, slice);

    //Suffix needed?
    const diff = (num - display.length);
    const suffix = diff ? ` + ${diff} more` : '';

    //Casuals too
    if (constraint === MembershipConstraints.SPECIFIC_WITHOUT) {
      display.unshift('Casuals');
    }

    //Return
    return display.join(', ') + suffix;
  };
});
