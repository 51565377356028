
/**
 * Module definition and dependencies
 */
angular.module('Shared.WaitingList.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config($apiProvider => {
  $apiProvider.registerEndpoint('waitingList', {
    model: 'WaitingList',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'waitingLists',
        isArray: true,
        isModel: true,
      },
      add: {
        method: 'POST',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('WaitingList', function($api, $baseModel) {

  /**
   * Constructor
   */
  function WaitingList(data) {
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(WaitingList.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  WaitingList.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('member', 'Member');

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  WaitingList.prototype.toJSON = function(data) {

    //Call parent method
    let json = $baseModel.prototype.toJSON.call(this, data);

    //Only ID
    json.member = $baseModel.onlyId(json.member);
    json.activity = $baseModel.onlyId(json.activity);

    //Return json
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Delete
   */
  WaitingList.prototype.delete = function() {
    return $api.waitingList
      .delete({id: this.id})
      .then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query list
   */
  WaitingList.query = function(filter) {
    return $api.waitingList.query(filter);
  };

  /**
   * Add item to waiting list
   */
  WaitingList.add = function(data) {

    //Only ID
    const item = new WaitingList(data);
    const json = item.toJSON();

    //Add to list
    return $api.waitingList.add(json);
  };

  //Return
  return WaitingList;
});
