
/**
 * Module definition and dependencies
 */
angular.module('Shared.Confetti.Service', [])

/**
 * Service definition
 */
.factory('Confetti', function($q) {

  //Defaults
  const defaults = {
    origin: {y: 0.85},
    gravity: 0.3,
    zIndex: 10001,
  };

  //Service class
  class Confetti {

    /**
     * Fire many
     */
    fire(count = 200, options) {

      //Unavailable
      if (typeof confetti === 'undefined') {
        return;
      }

      //Helper
      function fireOne(count, ratio, sub) {
        return confetti(Object.assign({}, defaults, options || {}, sub, {
          particleCount: Math.floor(count * ratio),
        }));
      }

      //Fire many
      return $q.all([
        fireOne(count, 0.25, {
          spread: 26,
          startVelocity: 55,
        }),
        fireOne(count, 0.2, {
          spread: 60,
        }),
        fireOne(count, 0.35, {
          spread: 100,
          decay: 0.91,
        }),
        fireOne(count, 0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
        }),
        fireOne(count, 0.1, {
          spread: 120,
          startVelocity: 45,
        }),
      ]);
    }
  }

  //Return instance
  return new Confetti();
});
