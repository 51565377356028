
/**
 * Module definition and dependencies
 */
angular.module('Shared.Plan.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('plan', {
    model: 'Plan',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'plans',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('plans', {
    model: 'Plan',
  });
})

/**
 * Model definition
 */
.factory('Plan', function($api, $baseModel) {

  /**
   * Constructor
   */
  function Plan(data) {
    $baseModel.call(this, data);

    /**
     * Name with info virtual
     */
    Object.defineProperty(this, 'nameWithInfo', {
      get() {
        const {name, info} = this;
        return `${name} – ${info}`;
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Plan.prototype, $baseModel.prototype);

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Check if plan is a downgrade
   */
  Plan.prototype.isDowngrade = function(current) {
    const order = ['free', 'entry', 'standard', 'premium', 'business'];
    const i = order.indexOf(this.identifier);
    const j = order.indexOf(current.identifier);
    return (i < j);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Plan.query = function(filter) {
    return $api.plan.query(filter);
  };

  /**
   * Find by ID
   */
  Plan.findById = function(id) {
    return $api.plan.get({id});
  };

  //Return
  return Plan;
});
