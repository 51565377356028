
/**
 * Card options component
 */

/**
 * Module definition and dependencies
 */
angular.module('Shared.Cards.CardOptions.Component', [])

/**
 * Component
 */
.component('cardOptions', {
  template: `<div class="CardOptions" ng-transclude></div>`,
  transclude: true,
});
