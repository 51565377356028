
/**
 * Module definition and dependencies
 */
angular.module('Shared.PageSelection.Component', [])

/**
 * Page selection component
 */
.component('pageSelection', {
  template: `
    <div class="PageSelection">
      <div class="PageSelection-summary">
        <strong>{{$ctrl.num | count:$ctrl.singular:$ctrl.plural}} selected</strong>
      </div>
      <div class="PageSelection-options" ng-transclude></div>
    </div>
  `,
  transclude: true,
  bindings: {
    num: '<',
    singular: '@',
    plural: '@',
  },
});
