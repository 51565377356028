
/**
 * Module definition and dependencies
 */
angular.module('App.Account.ConfirmAndPay.Card', [])

/**
 * Component
 */
.component('cardAccountConfirmAndPay', {
  templateUrl: 'account/cards/confirm-and-pay.html',
  controller: 'CardAccountConfirmAndPayCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    payment: '<',
    canPayLater: '<',
    onPay: '&',
    onPayLater: '&',
    onPaid: '&',
    onPrev: '&',
    onCancel: '&',
  },
})

/**
 * Controller
 */
.controller('CardAccountConfirmAndPayCtrl', function() {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flag as loading
    this.isLoading = true;

    //Get data
    this.payment
      .getChargeAndFee()
      .finally(() => this.isLoading = false);
  };

  /**
   * Pay now
   */
  this.pay = function() {

    //Set flags
    this.isPaying = true;
    this.isRedirecting = false;

    //Confirm payment
    return this
      .onPay()
      .then(outcome => {

        //Redirecting?
        if (outcome.isRedirecting) {
          this.isRedirecting = true;
          return;
        }

        //Done paying
        this.onPaid({$event: outcome});
      })
      .catch(error => {
        this.isRedirecting = false;

        //Ignore card errors
        if (typeof error === 'object' && error.type === 'card_error') {
          return;
        }

        //Ignore cancel error
        if (typeof error === 'string' && error === 'cancel') {
          return;
        }

        //Rethrow other errors
        throw error;
      })
      .finally(() => this.isPaying = false);
  };

  /**
   * Pay later
   */
  this.payLater = function() {

    //Set flags
    this.isPayingLater = true;

    //Process
    this
      .onPayLater()
      .finally(() => this.isPayingLater = false);
  };

  /**
   * Previous step
   */
  this.prev = function() {
    this.payment.clearErrors();
    this.onPrev();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
