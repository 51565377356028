
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Visitor', [
  'App.Portal.Visitor.Base.Controller',
  'App.Portal.Visitor.Intro',
  'App.Portal.Visitor.Conditions',
  'App.Portal.Visitor.Details',
  'App.Portal.Visitor.Done',
])

/**
 * Config
 */
.config($stateProvider => {

  //State definition
  $stateProvider.state('visitor', {
    parent: 'portal',
    abstract: true,
    template: '<ui-view/>',
    url: '/visitor',
    resolve: {
      isKiosk(Kiosk) {
        return Kiosk.isEnabled();
      },
      user(Auth, isKiosk, $store) {
        if (!isKiosk && Auth.isAuthenticated()) {
          return $store.user.get();
        }
        return null;
      },
      visit(VisitorFlow, club, user, isKiosk) {
        return new VisitorFlow(club, user, isKiosk);
      },
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, $http, $log, Domain) => {

  //Guard against entering flow on a generic domain with no club
  $transitions.onBefore({
    to: 'visitor.*',
  }, transition => {
    if (Domain.isGeneric && !$http.defaults.headers.common['X-Club']) {
      $log.log(`On generic domain and no club headers present, redirecting to login`);
      return transition.router.stateService.target('login');
    }
  });

  //Guard against going directly to the next steps
  $transitions.onBefore({
    from: '',
    to: 'visitor.*',
  }, transition => {

    //Get params
    const to = transition.to();
    const from = transition.from();
    const params = transition.params();
    const allowed = ['visitor.intro'];

    //If going to allowed state or coming from another state, all good
    if (from.name || allowed.includes(to.name)) {
      return;
    }

    //Go to first step
    return transition.router.stateService.target('visitor.intro', params);
  });

  //Success handler
  $transitions.onSuccess({
    to: 'visitor.*',
  }, transition => {

    //Get params
    const to = transition.to();
    const visit = transition.injector().get('visit');
    const step = to.name.replace(/visitor\./, '');

    //Set the visit flow step
    visit.setStep(step);
  });
});
