/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.AddMembership.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addMembership', {
    templateUrl: 'portal/register/modals/add-membership.html',
    controller: 'ModalAddMembershipCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalAddMembershipCtrl', function(
  $controller, $modalInstance, Subscription
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set default membership
    const defaultMembership = this.memberships.find(m => m.isDefault);
    const firstLinked = this.memberships.find(m => m.isLinked);
    const first = this.memberships[0];

    //Set as appropriate
    this.setMembership(firstLinked || defaultMembership || first);
  };

  /**
   * Set membership
   */
  this.setMembership = function(membership) {

    //The same membership selected?
    if (membership === this.membership) {
      return;
    }

    //Clear error flags
    this.isErrorAgeRestriction = false;
    this.isErrorSameHousehold = false;
    this.isErrorMembershipLimit = false;

    //Load subscription data
    this.loadSubscriptionData(membership);
    this.checkMembershipLimit(membership);
  };

  /**
   * Load subscription data
   */
  this.loadSubscriptionData = function(membership) {

    //Get data
    const {age, address} = this.member;

    //Load data
    this.isLoading = true;
    return Subscription
      .getRegisterData(membership.id, age, address)
      .then(data => {
        this.membership = membership;
        this.subscription = data.subscriptions[0];
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Check membership limit
   */
  this.checkMembershipLimit = async function(membership) {

    //Get current number of subscriptions
    const num = await membership.getNumCurrentSubs();
    this.isErrorMembershipLimit = (membership.limit && (num >= membership.limit));
  };

  /**
   * Validate
   */
  this.validate = function() {

    //Clear error
    this.isErrorAgeRestriction = false;

    //Initialize valid state
    let isValid = true;

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      isValid = false;
    }

    //Get data
    const {membership, member} = this;

    //Check age if age restriction is set
    if (membership && membership.hasAgeRestriction && (member.age !== null || typeof member.age !== 'undefined')) {
      if (!membership.isValidForAge(member.age)) {
        this.isErrorAgeRestriction = true;
        isValid = false;
      }
    }

    //Check if same household is enforced
    if (membership && membership.enforceSameHousehold) {
      if (!member.isSameHousehold) {
        this.isErrorSameHousehold = true;
        isValid = false;
      }
    }

    //Return valid state
    return isValid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Use save handler
    this.handler(this.membership, this.subscription);
    this.close();
  };
});
