
/**
 * Module definition and dependencies
 */
angular.module('Shared.Kiosk.Service', [])

/**
 * Service definition
 */
.factory('Kiosk', function($storage, $state) {

  //Local placeholder for value
  let isEnabled = false;

  //Service
  return {

    /**
     * Set enabled
     */
    setEnabled(enabled) {
      isEnabled = !!enabled;
    },

    /**
     * Check if kiosk mode is enabled
     */
    isEnabled() {
      return isEnabled;
    },

    /**
     * Get default page
     */
    getDefaultPage() {
      return $storage.get('kiosk.defaultPage') || 'kiosk';
    },

    /**
     * Set default page
     */
    setDefaultPage(page) {
      $storage.set('kiosk.defaultPage', page);
    },

    /**
     * Redirect to default kiosk page
     */
    redirect() {
      const state = this.getDefaultPage();
      $state.go(state);
    },
  };
});
