
/**
 * Module definition and dependencies
 */
angular.module('Shared.Time.Filter', [])

/**
 * Filter definition
 */
.filter('time', function(moment, DateFormat) {
  return function(time) {
    if (time === null || time === undefined) {
      return '';
    }
    time = Number(time);
    let hours = Math.floor(time / 60);
    let minutes = time - (hours * 60);

    //Special handling for midnight
    if (hours === 24 && minutes === 0) {
      switch (DateFormat.formats.timeOnly) {
        case 'HH:mm':
          return '24:00';
        case 'HH.mm':
          return '24.00';
        // case 'h:mma':
        // case 'h.mma':
        //   return 'Midnight';
      }
    }

    return moment()
      .hours(hours)
      .minutes(minutes)
      .format(DateFormat.formats.timeOnly);
  };
});
