
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.Sponsors.Component', [])

/**
 * Booking grid sponsors component
 */
.component('bookingGridSponsors', {
  template: `
    <div class="BookingGrid">
      <div
        class="BookingGrid-column"
        ng-repeat="area in $ctrl.areas track by area.id"
        ng-class="{'is-swiped': area.isSwiped}"
      >
        <div class="BookingGrid-cell BookingGridSponsor">
          <img
            ng-if="area.sponsor"
            ng-srcset="{{area.sponsor.url}}?h=80&auto=format&dpr=1 1x, {{area.sponsor.url}}?h=80&auto=format&dpr=2 2x"
            ng-src="{{area.sponsor.url}}?h=80&auto=format"
            alt=""
          >
        </div>
      </div>
    </div>
  `,
  bindings: {
    areas: '<',
  },
});
