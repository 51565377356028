
/**
 * Module definition and dependencies
 */
angular.module('Shared.DurationFromMinutes.Filter', [])

/**
 * Parse duration expressed in minutes
 */
.filter('durationFromMinutes', function() {
  return function(minutes) {
    if (minutes === null || minutes === undefined) {
      return '';
    }

    //Parse
    minutes = Math.round(Number(minutes));

    //No duration
    if (minutes === 0) {
      return '0 minutes';
    }

    //Determine hours
    const hours = Math.floor(minutes / 60);
    minutes -= hours * 60;

    //Build string
    let str = '';
    if (hours > 1) {
      str += `${hours} hours`;
    }
    else if (hours === 1) {
      str += `1 hour`;
    }
    if (minutes > 1) {
      str += ` ${minutes} minutes`;
    }
    else if (minutes === 1) {
      str += ` 1 minute`;
    }

    //Return trimmed string
    return str.trim();
  };
});
