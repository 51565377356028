
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Add.Details.Card', [])

/**
 * Component
 */
.component('cardEventAddDetails', {
  templateUrl: 'admin/event/cards/details.html',
  controller: 'CardEventAddDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    event: '<',
    areas: '<',
    activities: '<',
    isCopy: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventAddDetailsCtrl', function(
  $controller, $element, $focus, Modules
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardEventEditDetailsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;

    //Mark all areas unless copying event
    if (!this.isCopy && this.model.areas.length === 0) {
      this.markAllAreas();
    }

    //Unmark all areas & clear activities if the modules are disabled
    if (!Modules.has('areas')) {
      this.model.areas = [];
    }
    if (!Modules.has('activities')) {
      this.model.activity = null;
    }

    //Show on homepage by default
    this.model.showOnHomepage = true;
    this.model.isInterestEnabled = true;
    this.model.showAttendeeNames = true;
    this.model.showAttendanceNumbers = true;

    //Focus on first input
    $focus($element.find('input')[0]);
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {event, model} = this;
    const $event = {event, model};
    this.onMerge({$event});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next card tab
    this.merge();
    this.card.nextTab();
  };
});
