
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Welcome.Details', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('welcome.details', {
    url: '/details',
    component: 'welcomeDetailsRoute',
    params: {
      profile: null,
    },
  });
})

/**
 * Route component
 */
.component('welcomeDetailsRoute', {
  controller: 'WelcomeDetailsCtrl',
  templateUrl: 'portal/welcome/steps/details.html',
  bindings: {
    club: '<',
    welcome: '<',
    transition: '<',
  },
})

/**
 * Controller
 */
.controller('WelcomeDetailsCtrl', function($controller, Genders, Modules) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('WelcomeBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Setup
   */
  this.setup = function() {

    //Set available genders
    this.genders = Genders.filter(gender => gender.value !== 'unknown');

    //Apply user profile
    const {profile} = this.transition.params();
    if (profile) {
      this.welcome.applyUserProfile(profile);
    }
  };

  /**
   * Update directory settings
   */
  this.toggleDirectory = function(isPublic) {

    //Get club
    const {club} = this;

    //Initialize to default values if needed
    this.user.directory = this.user.directory || {
      isPublic: Modules.has('directory') &&
      club.settings.registration.optInToDirectoryByDefault,
      fields: {
        email: club.settings.member.fields.email.inDirectory,
        mobile: club.settings.member.fields.phone.inDirectory,
        phone: club.settings.member.fields.phone.inDirectory,
      },
    };

    //Toggle flag
    this.user.directory.isPublic = isPublic;
  };
});
