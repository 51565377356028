
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Report.Overview', [
  'App.Admin.Report.Overview.Controller',
])

/**
 * Config
 */
.config(($stateProvider) => {
  $stateProvider.state('admin.report.overview', {
    url: '',
    component: 'adminReportOverviewRoute',
  });
})

/**
 * Component
 */
.component('adminReportOverviewRoute', {
  controller: 'AdminReportOverviewCtrl',
  templateUrl: 'admin/report/overview/overview.html',
  bindings: {
    club: '<',
    system: '<',
    page: '<',
    module: '<',
    activities: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Intercom) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.report.overview'}, () => {

    //Track intercom event
    Intercom.event('Viewed reports');
  });
});
