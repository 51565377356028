
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Report.Area.Controller', [])

/**
 * Controller
 */
.controller('AdminReportAreaCtrl', function($filter) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Assign params to self
    Object.assign(this, this.transition.params());

    //Check if comparing
    this.isComparing = Object.keys(this.compData).length > 0;
    this.showPercentage = true;

    //Get data
    const base = this.baseData.usage;
    const comp = this.compData.usage;

    //Prepare "modes", which includes override and event
    this.modes = this.activity.modes.map(mode => mode);
    this.modes.push({
      id: 'event',
      name: 'Event',
    });
    this.modes.push({
      id: 'override',
      name: 'Override',
    });

    //Init
    this.byMode = {base: {}, comp: {}};
    this.totals = {};

    //Determine time reference in minutes
    const ref = {
      base: this.periodSeconds(this.basePeriod),
      comp: this.periodSeconds(this.compPeriod),
    };

    //Compute per area
    this.items = this.areas.map(area => {

      //Prepare item
      const item = {
        name: area.name,
        base: {},
        comp: {},
      };

      //Process each mode
      this.modes.forEach(mode => {
        item.base[mode.id] = this.count(base, ref.base, false, mode, area);
        item.comp[mode.id] = this.count(comp, ref.comp, false, mode, area);
      });

      //Compute totals
      item.base.total = this.total(item.base, ref.base, false);
      item.comp.total = this.total(item.comp, ref.comp, false);

      //Return item
      return item;
    });

    //Compute totals per mode and data for chart
    this.modes.forEach(mode => {
      this.byMode.base[mode.id] = this.count(base, ref.base, true, mode);
      this.byMode.comp[mode.id] = this.count(comp, ref.comp, true, mode);
    });

    //Compute grand totals
    this.totals.base = this.total(this.byMode.base, ref.base, true);
    this.totals.comp = this.total(this.byMode.comp, ref.comp, true);

    //Per mode chart data
    this.byModeChartData = this.modes.map(mode => {
      const count = this.byMode.base[mode.id].duration;
      const total = this.totals.base.duration;
      return {
        label: mode.name,
        count: $filter('durationFromSeconds')(count, 0, true),
        percentage: this.divide(count, total),
      };
    });

    //Per area chart data
    this.byAreaChartData = this.areas.map((area, i) => {
      const count = this.items[i].base.total.duration;
      const total = this.totals.base.duration;
      return {
        label: area.name,
        count: $filter('durationFromSeconds')(count, 0, true),
        percentage: this.divide(count, total),
      };
    });
  };

  /**
   * Get period seconds
   */
  this.periodSeconds = function(period) {
    if (!period || !period.toDate || !period.fromDate) {
      return 0;
    }
    return period.toDate.diff(period.fromDate, 'seconds');
  };

  /**
   * Init values helper
   */
  this.initValues = function() {
    return {
      count: 0,
      duration: 0,
      average: 0,
    };
  };

  /**
   * Divide helper
   */
  this.divide = function(a, b) {
    return b ? a / b : 0;
  };

  /**
   * Average helper
   */
  this.average = function(item) {
    item.average = item.count ? Math.round(item.duration / item.count) : 0;
  };

  /**
   * Usage helper
   */
  this.usage = function(item, reference, adjust) {

    //Multiply reference by number of areas for totals
    if (adjust) {
      reference *= this.areas.length;
    }

    //Compute usage
    item.usage = item.duration / reference;
    if (item.usage === Infinity) {
      item.usage = 0;
    }
  };

  /**
   * Daily helper
   */
  this.daily = function(item, reference) {
    const days = Math.max(1, reference / 86400);
    item.daily = Math.round(item.duration / days);
  };

  /**
   * Count helper
   */
  this.count = function(items, reference, adjust, mode, area) {

    //Initialize
    const init = this.initValues();

    //Validate items
    if (!items || items.length === 0) {
      return init;
    }

    //Create totals object
    const total = items
      .filter(item => !area || item.area === area.id)
      .filter(item => (
        (item.mode === mode.id) ||
        (mode.id === 'event' && item.state === 'event') ||
        (mode.id === 'override' && item.state === 'override')
      ))
      .reduce((total, item) => {
        total.count += item.count;
        total.duration += item.duration;
        return total;
      }, init);

    //Compute average and usage
    this.average(total);
    this.usage(total, reference, adjust);
    this.daily(total, reference);

    //Return
    return total;
  };

  /**
   * Total helper
   */
  this.total = function(item, reference, adjust) {

    //Initialize
    const total = this.initValues();

    //Loop per mode
    for (const mode in item) {
      if (item.hasOwnProperty(mode)) {
        total.count += item[mode].count;
        total.duration += item[mode].duration;
      }
    }

    //Compute average
    this.average(total);
    this.usage(total, reference, adjust);
    this.daily(total, reference);

    //Return
    return total;
  };

  /**
   * Toggle percentage
   */
  this.togglePercentage = function() {
    this.showPercentage = !this.showPercentage;
  };
});
