
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Log.Activity.Controller', [])

/**
 * Controller
 */
.controller('AdminLogActivityCtrl', function(
  $filter, $modal, $state, moment, ScrollPosition, Pagination, ActivityLog,
  Intercom, Settings, AreaStates, Booking
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed activity logs');

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Determine minimum date that logs can be viewed
    if (!this.user.isSuper()) {
      const {logVisibility} = this.club.permissions;
      this.minDate = moment().subtract(logVisibility || 1, 'months');
    }

    //Load logs
    this.loadPage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Enable search and set filters
    page.enableSearch();
    page.setController(this);
    page.setFilters(filter);

    //Filter and refresh logs
    page.addOption('filter');
    page.addOption('refreshLogs');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter, activities} = this;
    const {booking} = $state.params;

    //Set filter defaults
    filter.setDefaults({
      search: '',
      minDuration: 1,
      member: null,
      fromDate: moment().startOf('day').subtract(7, 'days'),
      toDate: moment().endOf('day'),
      states: null,
    });

    //Filter on change handler
    filter.onChange((key, value) => {

      //From date changed
      if (key === 'fromDate') {
        if (filter.fromDate.isAfter(filter.toDate)) {
          filter.toDate = filter.fromDate.clone().endOf('day');
        }
      }

      //To date changed
      else if (key === 'toDate') {
        if (filter.toDate.isBefore(filter.fromDate)) {
          filter.fromDate = filter.toDate.clone().startOf('day');
        }
      }

      //Activity changed, set modes to filter on
      else if (key === 'activity') {
        if (value) {
          const activity = filter.options.activity.find(a => a.id === value);
          filter.options.modes = activity.modes.map(mode => mode);
        }
        else {
          filter.options.modes = [];
        }
      }

      //Mode
      else if (key === 'modes') {
        filter.states = null;
      }

      //Index based merge
      else if (key === 'other') {
        filter.mapOptions(key, value);
      }

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      activity: activities,
      modes: [],
      states: [
        {
          label: 'Event',
          value: AreaStates.EVENT,
        },
        {
          label: 'Override',
          value: AreaStates.OVERRIDE,
        },
        {
          label: 'Warm-up',
          value: AreaStates.WARM_UP,
        },
      ],
    };

    //Set default activity if only one present
    if (activities.length === 1) {
      filter.update('activity', activities[0].id);
    }

    //Set booking
    if (booking) {
      this.booking = booking;
      filter.fromDate = booking.startDate.clone().startOf('day');
      filter.toDate = booking.endDate.clone().endOf('day');
      filter.activity = booking.activity.id;
      filter.minDuration = 0;
      filter.update('booking', booking.id);
    }
  };

  /**
   * Load logs
   */
  this.loadLogs = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query logs
    return ActivityLog
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, activityLogs} = data;

    //Set in scope
    this.logs = activityLogs;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //Convert min duration to seconds
    if (filter.minDuration) {
      filter.minDuration *= 60;
    }

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadLogs()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };

  /**
   * Export logs
   */
  this.export = function() {

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club: this.club, action: 'Exporting activity logs'},
      });
    }

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'activity logs', action: 'export'},
      });
    }

    //Get filter and export
    const filter = this.makeFilter();
    return ActivityLog.export(filter);
  };

  /**
   * View booking
   */
  this.viewBooking = function(bookingId) {

    //Load booking
    Booking
      .findById(bookingId)
      .then(booking => {

        //Get data
        const {user, activities} = this;
        const showActivity = activities.length > 1;
        const activity = activities.find(a => a.isSame(booking.activity.id));

        //Open modal
        $modal
          .open('viewBooking', {locals: {booking, user, activity, showActivity}});
      });
  };
});
