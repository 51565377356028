
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.EditAlert.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editAlert', {
    templateUrl: 'admin/club/modals/edit-alert.html',
    controller: 'ModalEditAlertCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalEditAlertCtrl', function(
  $controller, $modalInstance, $notice, AlertTypes
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Init
    this.alertTypes = AlertTypes;
    this.isEdit = !!this.alert.id;
    this.isSaving = false;
    this.model = this.alert.extract([
      'title', 'type', 'message', 'startDate', 'endDate',
    ]);
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => $notice.showError('Failed to save alert'))
      .finally(() => this.isSaving = false);
  };
});
