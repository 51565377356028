
/**
 * Module definition and dependencies
 */
angular.module('Shared.Emitter.Service', [])

/**
 * Service definition
 */
.factory('Emitter', function() {

  //Emitters
  const emitters = {};

  /**
   * Class
   */
  class Emitter {

    /**
     * Setup
     */
    constructor() {
      this.listeners = {};
    }

    /**
     * Register listener
     */
    on(event, listener) {

      //Initialize
      if (!Array.isArray(this.listeners[event])) {
        this.listeners[event] = [];
      }

      //Push listener
      this.listeners[event].push(listener);
      return this;
    }

    /**
     * Remove listener
     */
    off(event, listener) {
      if (Array.isArray(this.listeners[event])) {
        const i = this.listeners[event].indexOf(listener);
        if (i > -1) {
          this.listeners[event].splice(i, 1);
        }
      }
      return this;
    }

    /**
     * Emit event to listeners
     */
    emit(event, ...args) {
      if (Array.isArray(this.listeners[event])) {
        this.listeners[event].forEach(listener => listener.apply(this, args));
      }
      return this;
    }
  }

  //Return helper
  return {
    get(type) {

      //Instantiate
      if (!emitters[type]) {
        emitters[type] = new Emitter(type);
      }

      //Return
      return emitters[type];
    },
  };
});
