
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.PayWithMembership.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('payWithMembership', {
    templateUrl: 'admin/event/modals/pay-with-membership.html',
    controller: 'ModalPayWithMembershipCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalPayWithMembershipCtrl', function(
  $controller, $modalInstance, Subscription
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.isSaving = false;
    this.isError = false;
    this.hasSubscriptions = false;
    this.isLoadingSubscriptions = true;
    this.model = {sub: null};

    //Get attendee data
    const {member} = this.attendee;
    const {startDate} = this.attendee.event;
    const filter = {
      member: member.id,
      currentAt: startDate,
    };

    // //Membership type rule
    // if (rule.type === EventRuleTypes.MEMBERSHIP) {
    //   filter.memberships = rule.memberships;
    // }

    //Load available subscriptions
    Subscription
      .query(filter)
      .then(subs => {
        this.hasSubscriptions = (subs.length > 0);
        this.subs = subs;
        if (subs.length > 0) {
          this.model.sub = subs[0].id;
        }
      })
      .finally(() => this.isLoadingSubscriptions = false);
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Reset flags
    this.isError = false;

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Set flag
    this.isSaving = true;

    //Use save handler
    this
      .handler(this.model.sub)
      .then(() => $modalInstance.resolve())
      .catch((error) => console.error(error))
      .finally(() => this.isSaving = false);
  };
});
