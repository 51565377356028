
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Add.Subscriptions.Card', [])

/**
 * Component
 */
.component('cardMemberAddSubscriptions', {
  templateUrl: 'admin/people/members/cards/subscriptions.add.html',
  controller: 'CardMemberAddSubscriptionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    member: '<',
    onMerge: '&',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberAddSubscriptionsCtrl', function(
  moment, Membership, Subscription, CardCloseReasons, ExistsError
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize flags
    this.isLoadingMemberships = true;
    this.isSaved = false;
    this.isSaving = false;
    this.showMinAgeWarning = false;
    this.showMaxAgeWarning = false;
    this.hasManuallyChangedEndDate = false;

    //Set data
    this.membership = null;

    //Create model subset
    this.addSubscription = (!!this.member.subscription);
    this.model = this.member.subscription || {};

    //Append transaction data
    if (!this.model.meta) {
      this.model.meta = {
        createTransaction: false,
        amount: '',
      };
    }

    //Start date to default to today
    this.updateModel('startDate', moment().startOf('day'));

    //Load memberships
    Membership
      .query({isArchived: false})
      .then(data => this.memberships = data.memberships)
      .finally(() => this.isLoadingMemberships = false);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;
    if (property === 'startDate') {
      this.hasManuallyChangedStartDate = true;
      this.loadSubscriptionData();
    }
    if (property === 'endDate') {
      this.hasManuallyChangedEndDate = true;
      this.loadSubscriptionData();
    }
  };

  /**
   * Toggle include past
   */
  this.toggleIncludePast = function(includePast) {
    this.includePast = includePast;
  };

  /**
   * Changed membership
   */
  this.changedMembership = function(membership) {

    //Set selection in sub
    this.model.membership = membership;
    this.membership = membership ?
      this.memberships.find(m => membership.id === m.id) : null;

    //Reset age warning flags
    this.showMinAgeWarning = false;
    this.showMaxAgeWarning = false;

    //Clear primary
    this.model.primary = null;

    //Check if anything else to do
    if (!this.membership) {
      return;
    }

    //Model meta data
    this.model.meta = {
      createTransaction: this.membership.fee > 0,
      amount: this.membership.fee,
    };

    //Check if age warning needs to display
    let age = this.member.age;
    if (age !== null) {
      if (this.membership.minAge && age < this.membership.minAge) {
        this.showMinAgeWarning = true;
      }
      if (this.membership.maxAge && age > this.membership.maxAge) {
        this.showMaxAgeWarning = true;
      }
    }

    //Linked membership? Reset dates
    if (this.membership.isLinked) {
      this.model.startDate = this.model.endDate = null;
    }

    //Load subscription data
    this.loadSubscriptionData();
  };

  /**
   * Changed primary subscription
   */
  this.changedPrimarySub = function(sub) {

    //No sub
    if (!sub) {
      return;
    }

    //Set primary ID
    this.model.primary = sub.id;

    //Clone dates
    this.model.startDate = sub.startDate ? sub.startDate.clone() : null;
    this.model.endDate = sub.endDate ? sub.endDate.clone() : null;
  };

  /**
   * Load subscription data
   */
  this.loadSubscriptionData = function() {

    //No membership?
    if (!this.membership) {
      return;
    }

    //Get start/end dates
    let startDate, endDate;
    if (this.hasManuallyChangedStartDate) {
      startDate = this.model.startDate;
    }
    if (this.hasManuallyChangedEndDate) {
      endDate = this.model.endDate;
    }

    //Load data
    this.isLoading = true;
    Subscription
      .getCreateData(this.membership.id, this.member.id, startDate, endDate)
      .then(data => {

        //Get meta data
        const {meta} = data;

        //Set dates
        this.model.startDate = moment(meta.startDate);
        this.model.endDate = moment(meta.endDate);

        //Set fee
        this.model.meta.createTransaction = meta.hasFee;
        this.model.meta.amount = meta.totalFee;
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Toggle transaction
   */
  this.toggleTransaction = function(createTransaction) {
    this.model.meta.createTransaction = createTransaction;
  };

  /**
   * Toggle add subscription
   */
  this.toggleAddSubscription = function(addSubscription) {
    this.addSubscription = addSubscription;
  };

  /**
   * Merge
   */
  this.merge = function() {

    //Create subscription
    const subscription = this.addSubscription ? this.model : null;

    //Merge
    this.onMerge({$event: {
      member: this.member,
      model: {subscription},
    }});

    //Mark form pristine
    this.form.$setPristine();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previousTab();
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Create subscription
    const {member} = this;
    const subscription = this.addSubscription ?
      new Subscription(this.model) : null;

    //Create model
    const model = {subscription};

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event: {member, model}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .catch(error => {
        if (error instanceof ExistsError && error.message.match(/^(Duplicate number)/)) {
          this.showMemberNumberError = true;
        }
      })
      .finally(() => this.isSaving = false);
  };
});
