
/**
 * Module definition and dependencies
 */
angular.module('App.Profile', [
  'App.Profile.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('profile', {
    parent: 'app',
    //NOTE: Open card param has been added to the URL, otherwise it doesn't work
    //when creating a link with ui-sref in a tooltip (e.g. in the custom fields card,
    //member number tooltip)
    url: '/profile/:openCard',
    component: 'profileRoute',
    params: {
      openCard: {
        value: null,
        squash: true,
      },
    },
    data: {
      id: 'profile',
    },
    resolve: {
      user($store) {
        return $store.user.get();
      },
    },
  });
})

/**
 * Route component
 */
.component('profileRoute', {
  controller: 'ProfileCtrl',
  templateUrl: 'profile/profile.html',
  bindings: {
    club: '<',
    user: '<',
    module: '<',
    system: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'profile'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'profile'});
  });
});
