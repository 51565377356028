
/**
 * Module definition and dependencies
 */
angular.module('App.Event.Signup', [
  'App.Event.Signup.Controller',
  'App.Event.Signup.Details.Card',
  'App.Event.Signup.Questions.Card',
  'App.Event.Signup.Guests.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('event.signUp', {
    url: '/signup/:eventId/:ruleType/:subId',
    params: {
      eventId: {
        value: null,
      },
      circleMemberId: {
        value: null,
      },
      ruleType: {
        value: null,
        squash: true,
      },
      subId: {
        value: null,
        squash: true,
      },
    },
    data: {
      roles: [],
    },
    component: 'eventSignupRoute',
    resolve: {
      payment(PaymentFlow, PaymentTypes, user) {
        return new PaymentFlow(PaymentTypes.EVENT, user);
      },
      outcome(PaymentOutcome) {
        return PaymentOutcome.check();
      },
      event(transition, $store) {
        const {eventId} = transition.params();
        return $store.events.findById(eventId);
      },
      attendance(transition, Event) {
        const {eventId, circleMemberId, subId} = transition.params();
        return Event.getOwnAttendance(eventId, circleMemberId, subId);
      },
      circleMembers(Circle) {
        return Circle
          .queryOwnManagedMembers('events');
      },
    },
  });
})

/**
 * Component
 */
.component('eventSignupRoute', {
  controller: 'EventSignupCtrl',
  templateUrl: 'event/signup/signup.html',
  bindings: {
    club: '<',
    user: '<',
    module: '<',
    event: '<',
    attendance: '<',
    payment: '<',
    outcome: '<',
    circleMembers: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run((Page, EventRuleTypes, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'event.signUp'}, transition => {

    //Get transition details
    const params = transition.params();
    const event = transition.injector().get('event');
    const {ruleType, subId} = params;

    //Setup page
    Page.setTitle('Sign up for event');
    Page.clearCrumbs();

    //Crumbs depend on flow
    if (ruleType === EventRuleTypes.MEMBERSHIP) {
      Page.addCrumb({sref: 'subscription.overview', title: 'Memberships'});
      Page.addCrumb({
        sref: 'event.signUpViaSub',
        params: {subId},
        title: 'Select event',
      });
    }
    else {
      Page.addCrumb({sref: 'calendar', title: 'Calendar'});
      Page.addCrumb({sref: 'event.view', params, title: event.name});
    }
    Page.addCrumb({sref: 'event.signUp', params, title: 'Sign up'});
  });
});
