
/**
 * Module definition and dependencies
 */
angular.module('Shared.CouponSessions.Component', [])

/**
 * Coupon sessions component
 */
.component('couponSessions', {
  template: `
    <div class="CouponSessions">
      <div
        ng-repeat="session in $ctrl.sessions"
        ng-click="$ctrl.interact($index, $event)"
        ng-class="{'is-used': session.isUsed}"
        class="CouponSession {{$ctrl.classes}}"
      >
        <i class="Icon text-muted pointer" ng-if="!session.isUsed">panorama_fish_eye</i>
        <i class="Icon text-success pointer" ng-if="session.isUsed">check_circle</i>
      </div>
    </div>
  `,
  bindings: {
    classes: '@',
    sessionsCount: '<',
    sessionsUsed: '<',
    canUseSessions: '<',
    onUseSessions: '&',
    onView: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * On init
     */
    this.$onChanges = function() {

      //Get data
      const {sessionsCount, sessionsUsed} = this;

      //Prepare sessions
      this.sessions = [];
      for (let i = 0; i < sessionsCount; i++) {
        const isUsed = (i < sessionsUsed);
        this.sessions.push({isUsed});
      }
    };

    /**
     * Interact with coupon
     */
    this.interact = function(index, event) {

      //Check if modifier key was pressed
      const hasModifier = event.altKey;

      //Use sessions
      if (this.canUseSessions && index >= this.sessionsUsed) {
        const num = hasModifier ? 1 : (index + 1 - this.sessionsUsed);
        return this.onUseSessions({$event: {num, hasModifier}});
      }

      //View coupon
      this.onView();
    };
  },
});
