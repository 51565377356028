
/**
 * Module definition and dependencies
 */
angular.module('Shared.UploadFile.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('uploadFile', {
    templateUrl: 'modals/upload-file.html',
    controller: 'ModalUploadFileCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalUploadFileCtrl', function(
  $controller, $modalInstance, Upload
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set vars
    this.error = null;
    this.file = null;
    this.progress = 0;

    //Flags
    this.isPreviewing = false;
    this.isSelecting = true;
    this.isUploading = false;
    this.hasErrorFileType = false;
    this.hasErrorUploading = false;
  };

  /**
   * Change file
   */
  this.changeFile = function() {
    this.isPreviewing = false;
    this.isSelecting = true;
    this.file = null;
  };

  /**
   * Preview file
   */
  this.previewFile = function() {
    this.isSelecting = false;
    this.isPreviewing = true;
  };

  /**
   * Selected file
   */
  this.selectedFile = function(file) {

    //Reset error flags
    this.hasErrorFileType = false;
    this.hasErrorUploading = false;

    //No file?
    if (!file || !Upload.isFile(file)) {
      this.hasErrorFileType = true;
      return;
    }

    //Set file
    this.file = file;
    if (this.instant) {
      this.uploadFile();
    }
    else {
      this.previewFile();
    }
  };

  /**
   * Upload
   */
  this.uploadFile = function() {

    //Mark as uploading in progress
    this.isPreviewing = false;
    this.isSelecting = false;
    this.isUploading = true;
    this.error = null;

    //Upload
    this
      .onUpload(this.file)
      .then(
        response => $modalInstance.resolve(response.data),
        error => {
          this.error = error;
          this.previewFile();
        },
        event => {
          this.progress = parseInt(100.0 * event.loaded / event.total);
        }
      )
      .catch(() => {})
      .finally(() => this.isUploading = false);
  };
});
