
/**
 * Module definition and dependencies
 */
angular.module('Shared.AutoRenewalPaymentStrategies.Options', [])

/**
 * Constant definition
 */
.constant('AutoRenewalPaymentStrategies', {
  REQUIRE_PAYMENT: 'requirePayment',
  ATTEMPT_PAYMENT: 'attemptPayment',
  NO_PAYMENT: 'noPayment',
});
