
/**
 * Module definition and dependencies
 */
angular.module('Shared.Password.Service', [])

/**
 * Weak password components
 */
.constant('WeakPasswordComponents', [
  'password',
  'passw0rd',
  '1qaz2wsx',
  'abc123',
  'qwerty',
])

/**
 * Service definition
 */
.factory('Password', function(WeakPasswordComponents) {
  return {

    /**
     * Length check
     */
    hasLength(password, minLength) {
      return (password.length >= minLength);
    },

    /**
     * Consistency checks
     */
    hasLowerCase(password) {
      return password.match(/[a-z]/);
    },
    hasUpperCase(password) {
      return password.match(/[A-Z]/);
    },
    hasCases(password) {
      return (this.hasLowerCase(password) && this.hasUpperCase(password));
    },
    hasDigits(password) {
      return password.match(/[0-9]/);
    },
    hasSymbols(password) {
      return password.match(/[^a-zA-Z0-9 ]/);
    },

    /**
     * Repeats check
     */
    hasRepeats(password, threshold) {

      //Helpers
      let repeats = 0;
      let longest = 0;

      //Loop characters
      for (let i = 0; i < password.length; i++) {

        //Get character and it's code
        let char = password.charAt(i);

        //Check repeats (next to each other)
        if (i > 0) {
          let pChar = password.charAt(i - 1);
          if (char === pChar) {
            repeats++;
            if (repeats > longest) {
              longest = repeats;
            }
          }
          else {
            repeats = 0;
          }
        }
      }

      //Return result (equals check because we don't count the first char)
      return (longest >= threshold);
    },

    /**
     * Sequences check
     */
    hasSequences(password, threshold) {

      //Helpers
      let sequencesUp = 0;
      let sequencesDown = 0;
      let longest = 0;

      //Loop characters
      for (let i = 0; i < password.length; i++) {

        //Get character and it's code
        let code = password.charCodeAt(i);

        //Check sequences and repeats (next to each other)
        if (i > 0) {
          let pCode = password.charCodeAt(i - 1);
          if ((code - pCode) === 1) {
            sequencesUp++;
            if (sequencesUp > longest) {
              longest = sequencesUp;
            }
          }
          else {
            sequencesUp = 0;
          }
          if ((code - pCode) === -1) {
            sequencesDown++;
            if (sequencesDown > longest) {
              longest = sequencesDown;
            }
          }
          else {
            sequencesDown = 0;
          }
        }
      }

      //Return result (equals check because we don't count the first char)
      return (longest >= threshold);
    },

    /**
     * Get weak components
     */
    getWeakComponents(password, userData) {

      //Get components
      let components = WeakPasswordComponents
        .concat(
          userData
            .filter(component => !!component)
            .map(component => component.replace(/\s/g))
        )
        .map(component => {
          if (password.match(new RegExp(component, 'i'))) {
            return component.toLowerCase();
          }
          return null;
        })
        .filter(weak => !!weak);

      //Ensure unique
      let unique = new Set(components);
      return Array.from(unique.values());
    },
  };
});
