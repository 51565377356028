
/**
 * Module definition and dependencies
 */
angular.module('Shared.Newsletter.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config($apiProvider => {
  $apiProvider.registerEndpoint('newsletter', {
    model: 'Newsletter',
    actions: {
      subscribe: {
        method: 'POST',
        ignoreServiceUnavailableError: true,
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Newsletter', ($api, $baseModel) => {

  /**
   * Constructor
   */
  function Newsletter(data) {
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(Newsletter.prototype, $baseModel.prototype);

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Subscribe
   */
  Newsletter.subscribe = function(list, email) {
    return $api.newsletter.subscribe({list, email});
  };

  //Return
  return Newsletter;
});
