/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.EditRule.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editEventRule', {
    templateUrl: 'admin/event/modals/edit-rule.html',
    controller: 'ModalEditEventRuleCtrl',
    closeOnClick: false,
    resolve: {
      memberships($store) {
        'ngInject';
        return $store.memberships
          .query({isArchived: false});
      },
      couponTypes(CouponType, CouponTypeUses) {
        'ngInject';
        return CouponType
          .query({
            isArchived: false,
            usableFor: [CouponTypeUses.EVENTS],
            fields: 'name',
          })
          .then(data => data.couponTypes);
      },
      groups($store) {
        'ngInject';
        return $store.memberGroups
          .query(true);
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalEditEventRuleCtrl', function(
  $controller, $modalInstance, $filter, ChangeThresholds, Settings,
  EventRuleTypes, MembershipConstraintLabels, MembershipConstraints,
  MemberGroup, $modal
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Get constraints
  const {SPECIFIC, GUEST, ALL, GROUPS} = MembershipConstraints;
  const {FEE, FREE, COUPON, MEMBERSHIP} = EventRuleTypes;

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set initial step
    this.setStep('basics');

    //Extract model
    this.model = this.rule.extract([
      'type', 'constraint', 'memberships', 'couponTypes', 'fee',
      'isSeries', 'isPayableInAdvance', 'isRefundable', 'label',
      'validFrom', 'validTill', 'refundThreshold', 'requiresVaccination',
      'groups',
    ]);

    //Set flags
    this.isSaving = false;
    this.isError = false;
    this.EventRuleTypes = EventRuleTypes;
    this.ChangeThresholds = ChangeThresholds;

    //Recurrence and multi-day handling
    this.recurrenceHandling = [
      {
        value: true,
        label: 'Attendees sign up for the whole event series',
      },
      {
        value: false,
        label: 'Attendees sign up for each individual event separately',
      },
    ];
    this.multiDayHandling = [
      {
        value: true,
        label: 'Attendees sign up for the whole event',
      },
      {
        value: false,
        label: 'Attendees sign up for each day of the event separately',
      },
    ];

    //Set first type if needed
    if (!this.model.type) {
      this.model.type = EventRuleTypes[0].value;
    }

    //Set default recurrence
    if (typeof this.model.isSeries === 'undefined') {
      if (this.event.isLimitedSeries) {
        this.model.isSeries = true;
      }
      else {
        this.model.isSeries = false;
      }
    }

    //If the constraint is changed reload the membership constraints
    if (this.model.constraint === 'guest') {
      this.model.isPayableInAdvance = true;
      this.model.isRefundable = false;
    }

    //Enabled by default
    if (typeof this.rule.isEnabled === 'undefined') {
      this.model.isEnabled = true;
    }

    //Pre-filter memberships
    const allMemberships = this.memberships;
    const eventMemberships = this.memberships
      .filter(membership => membership.canUseForEvents === true);

    //Distribute in 3 columns
    this.allMemberships = $filter('distribute')(allMemberships, 3);
    this.eventMemberships = $filter('distribute')(eventMemberships, 3);

    //Determine which membership constraints are shown
    this.changedType(this.model.type);
  };

  /**
   * Update model
   */
  this.updateModel = function(key, value, target) {
    target = target || this.model;
    target[key] = value;

    //If the type is changed reload the membership constraints
    if (key === 'type') {
      this.changedType(value);
    }

    //Made refundable
    if (key === 'isRefundable') {
      if (value) {
        this.model.refundThreshold = Object
          .assign({}, Settings.get('event.refundThreshold'));
      }
      else {
        this.model.refundThreshold = null;
      }
    }

    //If the constraint is changed reload the membership constraints
    if (key === 'constraint' && value === 'guest') {
      this.model.isPayableInAdvance = true;
      this.model.isRefundable = false;
    }
  };

  /**
   * Changed type handling
   */
  this.changedType = function(type) {

    //Reset
    this.constraints = MembershipConstraintLabels.map(c => c);
    this.memberships = this.allMemberships;

    //Append group option
    this.constraints.splice(1, 0, {
      value: GROUPS,
      label: 'Specific member groups',
    });

    //If the rule type is coupon
    if (type === COUPON) {
      if (this.model.constraint === GUEST) {
        this.model.constraint = ALL;
      }
      this.model.isSeries = false;
      this.constraints = MembershipConstraintLabels
        .filter(constraint => !constraint.isGuest);
    }

    //If the rule type is membership, set specific constraint and filter memberships
    else if (type === MEMBERSHIP) {
      this.model.constraint = SPECIFIC;
      this.memberships = this.eventMemberships;
      this.constraints = MembershipConstraintLabels
        .filter(constraint => constraint.value === SPECIFIC);
    }

    //Free
    if (type === FREE && !this.model.label) {
      this.model.label = 'Free entry';
      this.model.isRefundable = false;
    }

    //Fee, default payable in advance
    if (type === FEE && !this.isEdit) {
      this.model.isPayableInAdvance = true;
    }
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };

  /**
   * Manage groups
   */
  this.manageGroups = function() {

    //Get model
    const Model = MemberGroup;
    const singular = 'group';
    const plural = 'groups';

    //Open modal
    $modal
      .open('manageCategories', {locals: {
        Model, singular, plural,
      }})
      .result
      .then(groups => this.groups = groups);
  };

  /**
   * Next step
   */
  this.next = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Set pristine and go to next step
    this.form.$setPristine();
    this.setStep('options');
  };
});
