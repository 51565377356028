
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Staff', [
  'App.Admin.People.Staff.Add',
  'App.Admin.People.Staff.Edit',
  'App.Admin.People.Staff.Overview',
  'App.Admin.People.Staff.Controller',
  'App.Admin.People.Staff.StaffOptions.Component',
  'App.Admin.People.Members.MailingList.Modal',
  'App.Admin.People.Members.WelcomeEmail.Modal',
  'App.Admin.People.Members.Transactions.Card',
  'App.Admin.People.Members.Payments.Card',
  'App.Admin.People.Members.AccountCredit.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.staff', {
    url: '/staff',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'staff',
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.people.staff.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.people.staff.overview', title});
  }, {priority: 5});
});
