
/**
 * Module definition and dependencies
 */
angular.module('App.Error.Codes.Constant', [])

/**
 * Constant
 */
.constant('ErrorCodes', {

  //Not found
  notFound: 'NOT_FOUND',

  //Date and time related
  dateInPast: 'DATE_IN_PAST',
  dateTooFarInFuture: 'DATE_TOO_FAR_IN_FUTURE',
  dateNotFarEnoughInFuture: 'DATE_NOT_FAR_ENOUGH_IN_FUTURE',
  startTimeInvalid: 'START_TIME_INVALID',
  endTimeInvalid: 'END_TIME_INVALID',
  maxDuration: 'MAX_DURATION',
  minDuration: 'MIN_DURATION',

  //Attendance related
  attendeeLimit: 'ATTENDEE_LIMIT',
  membershipBenefitLimit: 'MEMBERSHIP_BENEFIT_LIMIT',

  //Area related
  areaConflict: 'AREA_CONFLICT',
  areaExistingBooking: 'AREA_EXISTING_BOOKING',
  areaExistingEvent: 'AREA_EXISTING_EVENT',

  //Member related
  memberAgesInvalid: 'MEMBER_AGES_INVALID',
  memberConsecutiveBooking: 'MEMBER_CONSECUTIVE_BOOKING',
  memberConcurrentBooking: 'MEMBER_CONCURRENT_BOOKING',
  memberBookingTimes: 'MEMBER_BOOKING_TIMES',
  memberPeakTimes: 'MEMBER_PEAK_TIMES',
  memberLimitExceeded: 'MEMBER_LIMIT_EXCEEDED',
  memberPeakLimitExceeded: 'MEMBER_PEAK_LIMIT_EXCEEDED',
  memberMissing: 'MEMBER_MISSING',
  memberModeInvalid: 'MEMBER_MODE_INVALID',
  memberPending: 'MEMBER_PENDING',
  memberSuspended: 'MEMBER_SUSPENDED',
  memberArchived: 'MEMBER_ARCHIVED',
  memberDisallowed: 'MEMBER_DISALLOWED',
  memberNotVaccinated: 'MEMBER_NOT_VACCINATED',
  peopleTooMany: 'PEOPLE_TOO_MANY',
  peopleTooFew: 'PEOPLE_TOO_FEW',
  visitorsNotAllowed: 'VISITORS_NOT_ALLOWED',
  visitorsTooMany: 'VISITORS_TOO_MANY',
  visitorsTooFew: 'VISITORS_TOO_FEW',

  //Renewal related
  renewalInvalid: 'RENEWAL_INVALID',
  renewalTooLate: 'RENEWAL_TOO_LATE',
  renewalTooEarly: 'RENEWAL_TOO_EARLY',

  //Payment related
  paymentMethodInvalid: 'PAYMENT_METHOD_INVALID',
  paymentMethodUnavailable: 'PAYMENT_METHOD_UNAVAILABLE',
  paymentSourceInvalid: 'PAYMENT_SOURCE_INVALID',
  paymentRequired: 'PAYMENT_REQUIRED',
  paymentNotRequired: 'PAYMENT_NOT_REQUIRED',
  paymentFailed: 'PAYMENT_FAILED',
  paymentMinAmount: 'PAYMENT_MIN_AMOUNT',
  paymentInsufficientCredit: 'PAYMENT_INSUFFICIENT_CREDIT',
  paymentService: 'PAYMENT_SERVICE',
  stripeError: 'STRIPE_ERROR',
  poliError: 'POLI_ERROR',

  //Plan limits
  planLimits: 'PLAN_LIMITS',

  //Membership related
  membershipLimitExceeded: 'MEMBERSHIP_LIMIT_EXCEEDED',
});
