
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.ViewWaitingList.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('viewWaitingList', {
    templateUrl: 'booking/modals/view-waiting-list.html',
    controller: 'ModalViewWaitingListCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalViewWaitingListCtrl', function(
  $controller, $modalInstance, $notice, WaitingList
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Mark as loading
    this.isLoading = true;

    //Get data
    const {activity: {id: activity}, date} = this;
    const fromDate = date.clone().startOf('day');
    const toDate = date.clone().endOf('day');

    //Load list
    WaitingList
      .query({activity, fromDate, toDate})
      .then(data => this.list = data.waitingLists)
      .finally(() => this.isLoading = false);
  };

  /**
   * Remove
   */
  this.remove = function(item) {

    //Flag as removing
    this.isRemoving = true;

    //Remove item
    item
      .delete()
      .then(() => $notice.show('Removed from waiting list'))
      .finally(() => this.isRemoving = false);
  };
});
