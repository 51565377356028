
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Add.DateTime.Card', [])

/**
 * Component
 */
.component('cardEventAddDateTime', {
  templateUrl: 'admin/event/cards/date-time.html',
  controller: 'CardEventAddDateTimeCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    event: '<',
    googleCalendars: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventAddDateTimeCtrl', function($controller, EventModes) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardEventEditDateTimeCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;

    //Event modes
    this.EventModes = EventModes;

    //Parts for multi-day
    this.model.parts = this.event.parts ?
      this.event.extract('parts') : [];

    //Determine mode
    if (this.model.isRecurring) {
      this.mode = 'recurring';
    }
    else if (this.model.isMultiDay) {
      this.mode = 'multi';
      if (this.model.parts.length === 0) {
        this.addInitialDays();
      }
    }
    else {
      this.mode = 'single';
    }

    //If date is in the past (for example after copying a past event), fix
    if (this.model.date && this.model.date.isBefore(this.minDate)) {
      this.model.date = this.minDate.clone();
    }

    //Ensure google calendar is an object
    if (!this.model.googleCalendar) {
      this.model.googleCalendar = {
        calendars: [],
        calendarEvents: [],
      };
    }
  };

  /**
   * Add initial days
   */
  this.addInitialDays = function() {
    const {startDate, endDate, startTime, endTime} = this.model;
    this.addDay({
      startDate: startDate.clone().add(1, 'day').setTime(startTime),
      endDate: endDate.clone().add(1, 'day').setTime(endTime),
    });
  };

  /**
   * Update mode
   */
  this.updateMode = function(mode) {

    //Update mode
    this.mode = mode;

    //Check if we were multi-day before making changes
    const wasMultiDay = this.model.isMultiDay;

    //Determine flags
    switch (mode) {
      case 'multi':
        this.model.isRecurring = false;
        this.model.isMultiDay = true;
        break;
      case 'recurring':
        this.model.isRecurring = true;
        this.model.isMultiDay = false;
        break;
      default:
        this.model.isRecurring = false;
        this.model.isMultiDay = false;
        break;
    }

    //Changed?
    if (wasMultiDay !== this.model.isMultiDay) {
      this.changedMultiDay();
    }
  };

  /**
   * Changed multi-day
   */
  this.changedMultiDay = function() {

    //Set form pristine
    this.form.$setPristine();

    //Setting as multi-day
    if (this.model.isMultiDay) {
      this.addInitialDays();
    }

    //Setting as single day
    else {
      this.model.parts = [];
    }
  };

  /**
   * Merge
   */
  this.merge = function() {

    //Get data
    const {event, model} = this;

    //Merge
    this.onMerge({$event: {event, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //If recurrence has already been set (e.g. we browsed back),
    //update the firstDate and weekdays
    if (this.event.recurrence) {
      const weekday = this.model.startDate.isoWeekday();
      if (!this.event.recurrence.days.includes(weekday)) {
        if (this.event.recurrence.days.length === 1) {
          this.event.recurrence.days = [weekday];
        }
        else {
          this.event.recurrence.days.push(weekday);
        }
      }
      this.event.recurrence.firstDate = this.model.startDate.clone();
    }

    //Merge
    this.merge();

    //Go to recurrence if recurring
    if (this.event.isRecurring) {
      this.card.nextTab();
    }
    else {
      this.card.showTab('notifications');
    }
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previous();
  };
});
