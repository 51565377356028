
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Grades.Card', [])

/**
 * Component
 */
.component('cardGrades', {
  templateUrl: 'admin/activity/cards/grades.html',
  controller: 'CardActivityGradesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    activity: '<',
    memberships: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardActivityGradesCtrl', function(
  $modal, ActivityGrade, GradeColors
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flags
    this.isSaving = false;
    this.checkFlags();
    this.choosingColorId = null;

    //Set services
    this.gradeColors = GradeColors;
  };

  /**
   * Check flags
   */
  this.checkFlags = function() {
    this.hasGrades = this.activity.grades.length > 0;
  };

  /**
   * Save activity on server
   */
  this.save = function() {

    //Get data
    const {activity} = this;
    const {grades} = activity;
    const model = {grades};

    //Save activity
    return this
      .onSave({$event: {activity, model, isEdit: true}})
      .then(() => this.checkFlags());
  };

  /**
   * Add grade
   */
  this.add = function() {

    //Create grade and define handler
    const color = GradeColors.random();
    const grade = new ActivityGrade({color});
    const {activity} = this;
    const $ctrl = this;

    //Define handler
    const handler = function(model) {
      grade.fromJSON(model);
      activity.grades.push(grade);
      $ctrl.fixGradesOrder(model);
      return $ctrl.save();
    };

    //Show modal
    $modal.open('editActivityGrade', {locals: {grade, activity, handler}});
  };

  /**
   * Edit grade
   */
  this.edit = function($event) {

    //Get grade
    const {grade} = $event;
    const {activity} = this;
    const $ctrl = this;

    //Find and validate index
    const index = activity.grades.findIndex(g => g === grade);
    if (index === -1) {
      return;
    }

    //Define handler
    const handler = function(model) {
      grade.fromJSON(model);
      $ctrl.fixGradesOrder(model);
      return $ctrl.save();
    };

    //Show modal
    $modal.open('editActivityGrade', {
      locals: {grade, activity, handler, isEdit: true},
    });
  };

  /**
   * Delete grade
   */
  this.delete = function($event) {

    //Get grade
    const {grade} = $event;
    const {activity} = this;
    const $ctrl = this;

    //Find and validate index
    const index = activity.grades.findIndex(f => f === grade);
    if (index === -1) {
      return;
    }

    //Create copy of grades and define handler
    const handler = function() {
      activity.grades.splice(index, 1);
      $ctrl.fixGradesOrder();
      return $ctrl.save();
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'admin/activity/modals/confirm-delete-grade.html',
        locals: {grade, activity, handler},
      });
  };

  /**
   * Re-order grades which are higher than the new sortOrder
   * to close gaps in order
   */
  this.fixGradesOrder = function(model) {

    //Get grades
    const {grades} = this.activity;

    //Ensure there are no double ups by increasing the order of higher grades
    if (model) {
      const {id, sortOrder} = model;
      grades
        .filter(grade => grade.sortOrder >= sortOrder && grade.id !== id)
        .forEach(grade => grade.sortOrder++);
    }

    //Sort by
    grades
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .forEach((grade, index) => grade.sortOrder = index + 1);
  };

  /**
   * Choose color
   */
  this.toggleChooseColor = function(grade) {
    if (this.choosingColorId === grade.id) {
      this.choosingColorId = null;
    }
    else {
      this.choosingColorId = grade.id;
    }
  };

  /**
   * Set color
   */
  this.setColor = function(grade, color) {

    //Check if changed
    if (!color || grade.color === color) {
      this.choosingColorId = null;
      return;
    }

    //Update color
    this.choosingColorId = null;
    this.activity.grades.forEach(g => {
      if (g.id === grade.id) {
        g.color = color;
      }
    });

    //Save
    this.save();
  };
});
