
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.Billing.Card', [])

/**
 * Component
 */
.component('cardClubBilling', {
  templateUrl: 'admin/club/cards/billing.html',
  controller: 'CardClubBillingCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    plans: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubBillingCtrl', function(CardCloseReasons) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;

    //Create model subset
    this.model = this.club.extract([
      'billing',
    ]);

    //Ensure submodel
    if (!this.model.billing) {
      this.model.billing = {};
    }

    //If empty billing entity, populate with club details
    if (!this.model.billing.entity) {
      this.model.billing.entity = this.club.name;
    }
    if (!this.model.billing.address) {
      this.model.billing.address = this.club.address;
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateBilling = function(property, value) {
    this.model.billing[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving and clear errors
    this.isSaving = true;

    //Get model
    const {model} = this;

    //Save
    this
      .onSave({$event: {model}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .catch(error => {

        //Capture exceptions
        if (error instanceof Error) {
          Sentry.captureException(error);
        }
      })
      .finally(() => this.isSaving = false);
  };
});
