
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Add.Details.Card', [])

/**
 * Component
 */
.component('cardMemberAddDetails', {
  templateUrl: 'admin/people/members/cards/details.html',
  controller: 'CardMemberAddDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    member: '<',
    system: '<',
    isStaff: '<',
    integrations: '<',
    sendWelcomeEmail: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberAddDetailsCtrl', function(
  $controller, $element, $focus, $modal, Settings, Member
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMemberEditDetailsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;

    //Get meta data flags
    const {sendWelcomeEmail, system} = this;
    const isEnabled = Settings.get('system.autoPinForRegistration.isEnabled');
    const canGeneratePin = (system && system.hasPin && isEnabled);

    //Flag
    this.canGeneratePin = canGeneratePin;

    //Get mailing integrations
    this.mailingIntegrations = this.integrations
      .filter(int => int.isConnected && int.category === 'mailing');

    //Set default meta data if not set
    if (!this.model.meta) {

      //Create map of integrations to add
      const addToMailingList = this.mailingIntegrations
        .reduce((lists, int) => {
          lists[int.type] = true;
          return lists;
        }, {});

      //Set meta data
      this.model.meta = {
        generatePin: canGeneratePin,
        sendWelcomeEmail,
        addToMailingList,
      };
    }

    //Focus on first input
    $focus($element.find('input')[0]);
  };

  /**
   * Add to a mailing list
   */
  this.addToMailingList = function(type, add) {
    this.model.meta.addToMailingList[type] = add;
  };

  /**
   * Update directory settings
   */
  this.toggleDirectory = function(isPublic) {

    //Initialize to default values if needed
    this.model.directory = this.model.directory || {
      isPublic: false,
      fields: {
        email: true,
        mobile: true,
        phone: true,
      },
    };

    //Toggle flag
    this.model.directory.isPublic = isPublic;
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {member, model} = this;
    this.onMerge({$event: {member, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Flag as checking, get names
    this.isChecking = true;
    const {isStaff} = this;
    const {firstName, lastName} = this.model;

    //If no last name entered, don't check if member exists
    if (!lastName) {
      this.merge();
      this.card.nextTab();
      return;
    }

    //Check if a member with the same name exists
    Member
      .query({firstName, lastName, role: 'any'})
      .then(({members}) => {
        if (members.length > 0) {
          return $modal
            .open('basic', {
              templateUrl: 'admin/people/members/modals/confirm-same-name.html',
              locals: {members, isStaff},
              rejectOnDismissal: true,
            })
            .result;
        }
      })
      .then(() => {
        this.merge();
        this.card.nextTab();
      })
      .catch(reason => {
        if (reason !== 'cancel') {
          throw reason;
        }
      })
      .finally(() => this.isChecking = false);
  };
});
