
/**
 * Module definition and dependencies
 */
angular.module('Shared.Feedback.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('feedback', {
    templateUrl: 'modals/feedback.html',
    controller: 'ModalFeedbackCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalFeedbackCtrl', function(
  $controller, $modalInstance, $focus, Feedback
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Initialize static data
    const data = {
      whoFor: 'club',
      sentiment: '',
    };

    //Asking for feedback?
    if (this.isAsking) {
      data.whoFor = 'app';
    }

    //Create new feedback
    this.feedback = new Feedback(data);

    //Flags
    this.isPosting = false;
    this.hasPosted = false;
    this.isError = false;

    //Sentiments
    this.sentiments = [
      '😩', '😠', '🙁', '🤔', '🙂', '😀', '😍',
    ];
  };

  /**
   * Post link (for when asking for feedback)
   */
  this.$postLink = function() {
    $focus('feedback');
  };

  /**
   * Set feedback for
   */
  this.setFeedbackFor = function(whoFor) {
    this.feedback.whoFor = whoFor;
    if (whoFor) {
      $focus('feedback');
    }
  };

  /**
	 * Set sentiment
	 */
  this.setSentiment = function(sentiment) {
    this.isErrorSentiment = false;
    this.feedback.sentiment = sentiment;
  };

  /**
   * Check if sentiment is selected
   */
  this.isSelected = function(sentiment) {
    return (sentiment === this.feedback.sentiment);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Post feedback
   */
  this.post = function() {

    //No sentiment?
    if (!this.feedback.sentiment) {
      this.isErrorSentiment = true;
      this.validate();
      return;
    }

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as posting and reset error flag
    this.isErrorSentiment = false;
    this.isPosting = true;
    this.hasPosted = false;
    this.isError = false;

    //Post now
    this.feedback
      .post()
      .then(() => this.hasPosted = true)
      .catch(() => this.isError = true)
      .finally(() => this.isPosting = false);
  };

  /**
   * Snooze
   */
  this.snooze = function(n) {

    //If the feedback is for the app, then we snooze the reminder
    if (this.feedback.isForApp) {
      $modalInstance.resolve([n, 'months']);
    }

    //Otherwise just close
    this.close();
  };
});
