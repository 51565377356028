
/**
 * Module definition and dependencies
 */
angular.module('App.Home.Highlight.Card', [])

/**
 * Component
 */
.component('cardHomeHighlight', {
  templateUrl: 'home/cards/highlight.html',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    highlighted: '<',
  },
});
