
/**
 * Module definition and dependencies
 */
angular.module('Shared.Months.Service', [])

/**
 * Service definition
 */
.factory('Months', function(moment) {
  return moment
    .months()
    .map((month, index) => ({
      value: index + 1,
      label: month,
    }));
});
