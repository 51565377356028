
/**
 * Module definition and dependencies
 */
angular.module('Shared.PieChart.Component', [])

/**
 * Pie chart component
 */
.component('pieChart', {
  template: `
    <div class="Chart">
      <div class="ChartGraphic ChartGraphic--pie"><svg></svg></div>
      <div class="ChartLegend" ng-if="$ctrl.legend">
        <div class="ChartLegend-item" ng-repeat="item in $ctrl.data" ng-if="item.percentage > 0">
          <div class="ChartLegend-color no-{{$index + 1}}"></div>
          <div class="ChartLegend-label">
            {{item.label}}
          </div>
          <div class="ChartLegend-suffix pointer" ng-click="$ctrl.onTogglePercentage()">
            <small ng-if="$ctrl.showPercentage">{{item.percentage | percentage}}</small>
            <small ng-if="!$ctrl.showPercentage">{{item.count}}</small>
          </div>
        </div>
      </div>
    </div>
  `,
  bindings: {
    data: '<',
    size: '<',
    cutOut: '<',
    legend: '<',
    showPercentage: '<',
    onTogglePercentage: '&',
  },
  controller($element) {

    /**
     * On change
     */
    this.$onChanges = function() {

      //Determine radius
      this.radius = this.size / 2;

      //Draw chart
      this.draw();
    };

    /**
     * Draw
     */
    this.draw = function() {

      //No data?
      if (!this.data || !Array.isArray(this.data)) {
        return;
      }

      //Sort data in place
      this.data.sort((a, b) => {
        if (a.percentage < b.percentage) {
          return 1;
        }
        if (a.percentage > b.percentage) {
          return -1;
        }
        return 0;
      });

      //Init
      const NS = 'http://www.w3.org/2000/svg';
      const origin = [this.radius, this.radius];
      const $svg = $element.find('svg');
      const svg = $svg[0];

      //Keep track of angle sum
      let angleSum = 0;

      //Clear existing child elements
      $svg.children().remove();

      //Set size and namespace
      svg.setAttribute('viewBox', '0 0 ' + this.size + ' ' + this.size);
      svg.setAttribute('xmlns', NS);

      //Draw slices
      this.data.forEach((slice, i) => {

        //Guard against NaN
        if (isNaN(slice.percentage) || slice.percentage === 0) {
          return;
        }

        //100% case
        if (slice.percentage === 1) {
          const circle = document.createElementNS(NS, 'circle');
          circle.setAttribute('class', 'ChartGraphic-slice no-' + (i + 1));
          if (slice.color) {
            circle.setAttribute('fill', slice.color);
          }
          circle.setAttribute('cx', this.radius);
          circle.setAttribute('cy', this.radius);
          circle.setAttribute('r', this.radius);
          svg.appendChild(circle);
          return;
        }

        //Create path
        const path = document.createElementNS(NS, 'path');
        path.setAttribute('class', 'ChartGraphic-slice no-' + (i + 1));
        if (slice.color) {
          path.setAttribute('fill', slice.color);
        }

        //Get start coordinates
        const angleSlice = slice.percentage * Math.PI * 2;
        const start = this.getCoords(angleSum);
        const end = this.getCoords(angleSum + angleSlice);
        const largeArc = (slice.percentage > 0.5) ? 1 : 0;

        //Instructions
        const instructions = [

          //Move to origin
          'M' + origin.join(' '),

          //Draw line to edge of chart
          'L' + start.join(' '),

          //Arc along edge of chart
          'A' + origin.join(' ') + ' 0 ' + largeArc + ' 1 ' + end.join(' '),

          //Return
          'z',
        ];

        //Increment sum
        angleSum += angleSlice;

        //Draw and add to SVG
        path.setAttribute('d', instructions.join(' '));
        svg.appendChild(path);
      });

      //Draw cut out
      if (this.cutOut) {
        const circle = document.createElementNS(NS, 'circle');
        circle.setAttribute('class', 'ChartGraphic-cutOut');
        circle.setAttribute('cx', this.radius);
        circle.setAttribute('cy', this.radius);
        circle.setAttribute('r', this.radius * this.cutOut);
        svg.appendChild(circle);
      }
    };

    /**
     * Helper to get coordinates from origin for a given angle
     */
    this.getCoords = function(angle) {
      const R = this.size / 2;
      const x = Math.sin(angle) * R;
      const y = Math.cos(angle) * R;
      return this.shiftCoords([x, y]);
    };

    /**
     * Helper to shift coordinates from origin of circle to SVG bounding box
     */
    this.shiftCoords = function(coords) {
      const R = this.size / 2;
      coords[0] = R + coords[0];
      coords[1] = R - coords[1];
      return coords;
    };
  },
});
