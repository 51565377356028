
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.EditMode.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editMode', {
    templateUrl: 'admin/activity/modals/edit-mode.html',
    controller: 'ModalEditModeCtrl',
    closeOnClick: false,
    resolve: {
      club($store) {
        'ngInject';
        return $store.club.get();
      },
      system($store) {
        'ngInject';
        return $store.system.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalEditModeCtrl', function(
  $controller, $modalInstance, FeeTypes, FeeHandlingMethods, Modules,
  LightControlModes
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set initial step
    this.setStep('basics');

    //Extract model
    this.model = this.mode.extract([
      'name', 'description', 'type', 'bookingAgreement', 'systemConfirmation',
      'minPeople', 'maxPeople', 'minVisitors', 'maxVisitors',
      'feeType', 'feeHandling', 'allowOthers', 'allowWarmUp',
      'allMembersMustCheckIn',
    ]);

    //Set flags
    this.isSaving = false;
    this.isError = false;

    //Set services
    this.FeeHandlingMethods = FeeHandlingMethods;
    this.FeeTypes = FeeTypes.map(type => {
      const {value, label} = type;
      if (type.value === FeeTypes.PER_AREA) {
        return {value, label: `Per ${this.activity.areaSingular}`};
      }
      return {value, label};
    });

    //Initial fee type
    if (!this.model.feeType) {
      this.model.feeType = FeeTypes.PER_AREA;
    }

    //Initial people and visitor numbers
    if (typeof this.model.minPeople === 'undefined') {
      this.model.minPeople = 1;
    }
    if (typeof this.model.maxPeople === 'undefined') {
      this.model.maxPeople = 2;
    }
    if (typeof this.model.minVisitors === 'undefined') {
      this.model.minVisitors = 0;
    }
    if (typeof this.model.maxVisitors === 'undefined') {
      this.model.maxVisitors = 0;
    }

    //Set flags
    this.hasSystem = Modules.has('system');
    this.isFlexible = (
      this.club.settings.system.lightControlMode === LightControlModes.FLEXIBLE
    );
    this.isAutomatic = (
      this.club.settings.system.lightControlMode === LightControlModes.AUTOMATIC
    );

    //If not flexible system mode or no system, can only charge fees per session
    if (!this.hasSystem || !this.isFlexible || !this.model.feeHandling) {
      this.model.feeHandling = FeeHandlingMethods.PER_SESSION;
    }
  };

  /**
   * Next step
   */
  this.next = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Set pristine and go to next step
    this.form.$setPristine();
    this.setStep('options');
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };
});
