
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Welcome.OAuth', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('welcome.oauth', {
    url: '',
    component: 'welcomeOAuthRoute',
  });
})

/**
 * Route component
 */
.component('welcomeOAuthRoute', {
  controller: 'WelcomeOAuthCtrl',
  templateUrl: 'portal/welcome/steps/oauth.html',
  bindings: {
    club: '<',
    welcome: '<',
    transition: '<',
  },
})

/**
 * Controller
 */
.controller('WelcomeOAuthCtrl', function($controller, Auth) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('WelcomeBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Login with oAuth provider
   */
  this.login = function(provider) {

    //Set provider and flag
    this.provider = provider;
    this.isLoggingIn = true;

    //Create state
    const {id} = this.user;
    const {token} = this.welcome;
    const club = this.club.identifier;
    const state = {club, id, token};

    //Login (using registration flow)
    Auth.loginWithOAuthProvider(provider, 'welcome', state);
  };

  /**
   * Skip oAuth flow
   */
  this.skipOAuth = function() {
    this.welcome.skipOAuth();
    this.continue();
  };
});
