
/**
 * Module definition and dependencies
 */
angular.module('Shared.ActivityLog.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('activityLog', {
    model: 'ActivityLog',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'activityLogs',
        isArray: true,
        isModel: true,
      },
    },
  });
})

/**
 * Model definition
 */
.factory('ActivityLog', function($baseModel, $api, $sync) {

  /**
   * Constructor
   */
  function ActivityLog(data) {
    $baseModel.call(this, data);

    //Unknown property
    Object.defineProperty(this, 'isUnknown', {
      get() {
        return (
          !this.event &&
          this.members.length === 0 &&
          this.tags.length === 0
        );
      },
    });

    //Any tags?
    Object.defineProperty(this, 'hasTags', {
      get() {
        return (this.tags.length > 0);
      },
    });

    //Any members?
    Object.defineProperty(this, 'hasMembers', {
      get() {
        return (this.members.length > 0);
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(ActivityLog.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  ActivityLog.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('tags', 'Tag', true);
    this.convertToModel('members', 'Member', true);

    //Return self
    return this;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Check if has member
   */
  ActivityLog.prototype.hasMember = function(memberId) {
    return this.members.some(member => member.id === memberId);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  ActivityLog.query = function(filter) {
    return $api.activityLog.query(filter);
  };

  /**
   * Export
   */
  ActivityLog.export = function(filter) {
    return $sync.get('activityLog/export/csv', filter, 'Exporting...');
  };

  //Return
  return ActivityLog;
});
