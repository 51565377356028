
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.SlotStatus.Component', [])

/**
 * Slot status component
 */
/* <span ng-if="$ctrl.area.isUnknown && !$ctrl.hasTags">
    {{$ctrl.time | time}}
    <br>
  </span> */
.component('bookingGridSlotStatus', {
  template: `
    <div
      class="SlotOverlay SlotStatus"
    >
      <booking-grid-slot-people
        members="$ctrl.members"
        visitors="$ctrl.visitors"
        service-tags="$ctrl.serviceTags"
        with-names="$ctrl.isShowingNames"
        ng-if="$ctrl.hasTags"
      ></booking-grid-slot-people>
      <span class="Slot-text">
        <span ng-if="!$ctrl.hasTags">
          <area-state
            state="$ctrl.area.state"
            mode="$ctrl.area.mode"
            activities="$ctrl.activities"
            show-unknown="true"
          ></area-state>
          <br>
        </span>
        <small>on now</small>
      </span>
    </div>
  `,
  bindings: {
    time: '<',
    area: '<',
    activities: '<',
    hasTags: '<',
    isShowingNames: '<',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Set members etc.
      this.visitors = [];
      this.members = this.area.getMembers();
      this.serviceTags = this.area.getServiceTags();
    };
  },
});
