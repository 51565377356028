
/**
 * Module definition and dependencies
 */
angular.module('App.Event.Signup.Details.Card', [])

/**
 * Component
 */
.component('cardEventSignupDetails', {
  templateUrl: 'event/cards/signup-details.html',
  controller: 'CardEventSignupDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<', //Club
    event: '<', //Event
    member: '<', //Member we are signing up
    isPublic: '<', //We're in the public signup flow
    option: '<', //Selected option (series vs. instance)
    options: '<', //Available options (series vs. instance)
    type: '<', //Selected payment method/type
    rule: '<', //Determined rule that will be used
    dates: '<', //Selected dates
    coupon: '<', //Selected coupon
    coupons: '<', //Usable coupons
    subscription: '<', //Selected subscription
    subscriptions: '<', //Usable subscriptions
    isMembershipFlow: '<', //Are we in the membership flow
    numCouponSessions: '<', //Number of coupon sessions to use
    numGuests: '<', //Number of guests to add
    isLoading: '<', //Loading flag
    isAlreadyAttending: '<', //Member is already an attendee
    onNext: '&',
    onCancel: '&',
    onSignUp: '&',
    onSetOption: '&',
    onSetType: '&',
    onSelectDate: '&',
    onSetNumGuests: '&',
    onSelectCoupon: '&',
    onSelectSubscription: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventSignupDetailsCtrl', function(EventRuleTypes, User) {

  /**
   * Init
   */
  this.$onInit = function() {

    //No spaces left
    if (!this.event.hasSpacesLeft) {
      return this.cancel();
    }

    //Determine number of guests option
    const num = Math.min(10, this.event.numSpacesLeft);
    this.numGuestOptions = [];
    for (let i = 1; i <= num; i++) {
      this.numGuestOptions.push(i);
    }
  };

  /**
   * Reset error
   */
  this.$onChanges = function() {

    //Reset errors
    this.resetErrors();

    //Check if this is ourselves and if we can sign up
    this.isSelf = (this.member instanceof User);
    this.canSignUp = (this.options.length > 0);

    //Set flag for coupon
    this.hasSelectedOwnCoupon = (
      this.coupon && this.coupon.member.id === this.member.id
    );

    //Check if we must buy coupons
    this.mustBuyCoupons = (
      this.type === EventRuleTypes.COUPON &&
      this.coupons.length === 0
    );
  };

  /**
   * Reset errors
   */
  this.resetErrors = function() {
    this.isErrorNoDates = false;
    this.isErrorNoCoupon = false;
  };

  /**
   * Validate
   */
  this.validate = function() {

    //Reset errors
    this.resetErrors();

    //Get data
    const {dates, option, type, coupon} = this;

    //Validate dates
    if (option.method === 'dates' && dates.length === 0) {
      this.isErrorNoDates = true;
      return false;
    }

    //Validate coupon
    if (type === EventRuleTypes.COUPON && !coupon) {
      this.isErrorNoCoupon = true;
      return false;
    }

    //All good
    return true;
  };

  /**
   * Signup
   */
  this.signUp = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Toggle flag
    this.isSigningUp = true;

    //Signup
    this
      .onSignUp()
      .catch(error => {
        this.error = error;
        this.isSigningUp = false;
      });
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Next
    this.onNext();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
