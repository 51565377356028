
/**
 * Module definition and dependencies
 */
angular.module('Shared.AreaTypes.Options', [])

/**
 * Constant definition
 */
.constant('AreaTypes', [
  {
    areaSingular: 'Area',
    areaPlural: 'Areas',
  },
  {
    areaSingular: 'Course',
    areaPlural: 'Courses',
  },
  {
    areaSingular: 'Court',
    areaPlural: 'Courts',
  },
  {
    areaSingular: 'Field',
    areaPlural: 'Fields',
  },
  {
    areaSingular: 'Hall',
    areaPlural: 'Halls',
  },
  {
    areaSingular: 'Lane',
    areaPlural: 'Lanes',
  },
  {
    areaSingular: 'Pitch',
    areaPlural: 'Pitches',
  },
  {
    areaSingular: 'Range',
    areaPlural: 'Ranges',
  },
  {
    areaSingular: 'Rink',
    areaPlural: 'Rinks',
  },
  {
    areaSingular: 'Room',
    areaPlural: 'Rooms',
  },
  {
    areaSingular: 'Table',
    areaPlural: 'Tables',
  },
]);
