
/**
 * Module definition and dependencies
 */
angular.module('Shared.Activity.Restriction.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('Restriction', function($baseModel, MembershipConstraints) {

  /**
   * Defaults
   */
  const defaultData = {
    constraint: MembershipConstraints.ALL,
    modes: [],
    timeRange: {},
    limit: {
      amount: '',
      unit: 'none',
    },
  };

  /**
   * Constructor
   */
  function Restriction(data) {
    $baseModel.call(this, angular.extend({}, defaultData, data || {}));
  }

  /**
   * Extend base model
   */
  angular.extend(Restriction.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Restriction.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('modes', null, true);
    this.convertToModel('timeRange', 'TimeRange');

    //Return self
    return this;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Check if empty
   */
  Restriction.prototype.isEmpty = function() {
    if (this.modes.length > 0) {
      return false;
    }
    if (this.limit && (this.limit.amount || this.limit.unit)) {
      return false;
    }
    return this.timeRange.isEmpty();
  };

  /**
   * Remove a mode
   */
  Restriction.prototype.removeMode = function(id) {
    if (this.modes.length > 0) {
      const i = this.modes.findIndex(mode => mode === id);
      if (i > -1) {
        this.modes.splice(i, 1);
      }
    }
  };

  //Return
  return Restriction;
});
