
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Edit.Info.Card', [])

/**
 * Component
 */
.component('cardCouponTypeInfo', {
  templateUrl: 'admin/coupon-type/cards/info.html',
  require: {
    card: '^^',
  },
  bindings: {
    couponType: '<',
    activities: '<',
  },
});
