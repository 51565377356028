
/**
 * Module definition and dependencies
 */
angular.module('App.Account.Base.Controller', [])

/**
 * Controller
 */
.controller('AccountBaseCtrl', function(
  $q, $state, PaymentOutcome, Subscription
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Check route is valid (return value indicates a problem)
    if (this.checkRouteValid()) {
      return;
    }

    //Check payment outcome and setup flow
    this.checkPaymentOutcome();
    this.setup();

    //Start refreshing account credit
    this.payment.startRefreshingAccountCredit();
  };

  /**
   * Stop refreshing account credit
   */
  this.$onDestroy = function() {
    this.payment.stopRefreshingAccountCredit();
  };

  /**
   * Helper to validate if a route is valid before doing any further setup work
   * To be implemented in controllers as needed
   */
  this.checkRouteValid = function() {};

  /**************************************************************************
   * Common navigation steps and handling
   ***/

  /**
   * Use account credit step
   */
  this.stepUseCredit = function() {
    this.step = 'useCredit';
  };

  /**
   * Choose payment method step
   */
  this.stepSelectPaymentMethod = function() {
    this.step = 'selectPaymentMethod';
  };

  /**
   * Confirm and pay step
   */
  this.stepConfirmAndPay = function() {
    this.step = 'confirmAndPay';
  };

  /**
   * Next from use account credit
   */
  this.nextFromUseCredit = function() {

    //Needs payment?
    if (this.payment.needsPayment) {
      return this.stepSelectPaymentMethod();
    }

    //Clear method
    this.payment.clearMethod();

    //Otherwise we can go straight to confirmation
    this.stepConfirmAndPay();
  };

  /**
   * Prev from select payment method
   */
  this.prevFromSelectPaymentMethod = function() {

    //Check if can pay with credit
    if (this.payment.canUseAccountCredit) {
      return this.stepUseCredit();
    }

    //Backwards
    this.prevFromUseCredit();
  };

  /**
   * Next from select payment method
   */
  this.nextFromSelectPaymentMethod = function() {
    this.stepConfirmAndPay();
  };

  /**
   * Prev from confirm and pay
   */
  this.prevFromConfirmAndPay = function() {

    //Needs payment?
    if (this.payment.needsPayment) {
      return this.stepSelectPaymentMethod();
    }

    //Backwards
    this.prevFromSelectPaymentMethod();
  };

  /**************************************************************************
   * Payment handling
   ***/

  /**
   * Default payment handler
   */
  this.onPay = function() {
    return this
      .beforePayment()
      .catch(error => {
        this.payment.paymentError = error;
        throw error;
      })
      .then(() => this.payment.initiatePayment());
  };

  /**
   * On paid handler for direct payment flows
   */
  this.onPaid = function($event) {
    const {payment} = $event;
    this.processPaymentOutcome(payment);
  };

  /**
   * Check payment outcome for redirect based flows
   */
  this.checkPaymentOutcome = function() {
    if (this.outcome) {
      this.processPaymentOutcome(this.outcome);
    }
  };

  /**
   * Process payment outcome
   */
  this.processPaymentOutcome = function(payment) {

    //Show modal
    PaymentOutcome
      .showModal(
        payment,
        () => this.onPaymentSuccess(),
        () => this.onPaymentFailed()
      );
  };

  /**
   * Payment success handler
   */
  this.onPaymentSuccess = function() {
    const state = $state.current.name.split('.')[0];
    $state.go(`${state}.overview`);
  };

  /**
   * Payment failed handler (can implement in controllers)
   */
  this.onPaymentFailed = function() {};

  /**
   * Prepare payment (to implement in controllers)
   */
  this.preparePayment = function() {};

  /**
   * Before payment
   */
  this.beforePayment = function() {
    return $q.resolve();
  };

  /**************************************************************************
   * Shared flow helpers
   ***/

  /**
   * Redirect handler for subscriptions, which decides whether to redirect
   * to the subscription overview page, or the signup via sub page for events
   */
  this.redirectForSubscriptions = function() {

    //Load own subs
    Subscription
      .own()
      .then(subs => {

        //Check if member has subscriptions for which they can sign up to events
        const sub = subs.find(sub => sub.canSignUpToEvents);

        //Redirect
        if (sub) {
          $state.go('event.signUpViaSub', {subId: sub.id});
        }
        else {
          $state.go('subscription.overview', {}, {reload: true});
        }
      })
      .catch(() => $state.go('subscription.overview', {}, {reload: true}));
  };
});
