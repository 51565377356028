
/**
 * Module definition and dependencies
 */
angular.module('Shared.ReportTypes.Options', [])

/**
 * Constant definition
 */
.constant('ReportTypes', [
  {
    label: 'Memberships',
    value: 'membership',
  },
  {
    label: 'Financial',
    value: 'financial',
  },
  {
    label: 'Area usage',
    value: 'area',
    isSystem: true,
  },
  {
    label: 'Governing',
    value: 'governing',
    isParentClub: true,
  },
]);
