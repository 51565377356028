
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Contacts', [
  'App.Admin.People.Contacts.Add',
  'App.Admin.People.Contacts.Edit',
  'App.Admin.People.Contacts.Overview',
  'App.Admin.People.Contacts.Controller',
  'App.Admin.People.Contacts.ContactFields.Constant',
  'App.Admin.People.Contacts.ContactOptions.Component',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.contacts', {
    url: '/contacts',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'contacts',
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.people.contacts.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.people.contacts.overview', title});
  }, {priority: 5});
});
