
/**
 * Module definition and dependencies
 */
angular.module('Shared.ButtonBar.Component', [])

/**
 * Button bar component
 */
.component('buttonBar', {
  templateUrl: 'shared/button-bar/button-bar.html',
  transclude: true,
  bindings: {

    //Transclusion for secondary button button-bar
    transclude: '<',

    //Cancel button
    showCancel: '<',
    isDisabledCancel: '<',
    labelCancel: '@',
    onCancel: '&',

    //Confirm button
    showConfirm: '<',
    isDisabledConfirm: '<',
    hasSpinnerConfirm: '<',
    classConfirm: '@',
    labelConfirm: '@',
    iconConfirm: '@',
    onConfirm: '&',

    //Back button
    showBack: '<',
    isDisabledBack: '<',
    labelBack: '@',
    onBack: '&',
  },

  /**
   * Directive controller
   */
  controller($attrs) {

    /**
     * On change handler
     */
    this.$onChanges = function() {

      //If handlers given, show buttons
      this.showCancel = $attrs.onCancel && (this.showCancel !== false);
      this.showConfirm = $attrs.onConfirm && (this.showConfirm !== false);
      this.showBack = $attrs.onBack && (this.showBack !== false);

      //Default labels
      this.labelCancel = this.labelCancel || 'Cancel';
      this.labelConfirm = this.labelConfirm || 'Confirm';
      this.labelBack = this.labelBack || 'Back';

      //Default classes
      this.classConfirm = this.classConfirm || 'success';
    };

    /**
     * Cancel handler
     */
    this.cancel = function() {
      this.onCancel();
    };

    /**
     * Confirm handler
     */
    this.confirm = function() {
      this.onConfirm();
    };

    /**
     * Back handler
     */
    this.back = function() {
      this.onBack();
    };
  },
});
