
/**
 * Module definition and dependencies
 */
angular.module('Shared.DoorStates.Constant', [])

/**
 * Constant definition
 */
.constant('DoorStates', {
  TAG: 'tag',
  EVENT: 'event',
  OVERRIDE: 'override',
  SCHEDULE: 'schedule',
  DEFAULT: 'default',
});
