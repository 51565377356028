
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Unsubscribe', [
  'App.Portal.Unsubscribe.Controller',
])

/**
 * Config
 */
.config(($stateProvider) => {

  //State definition
  $stateProvider.state('unsubscribe', {
    parent: 'portal',
    url: '/unsubscribe',
    component: 'unsubscribeRoute',
  });
})

/**
 * Route component
 */
.component('unsubscribeRoute', {
  controller: 'UnsubscribeCtrl',
  templateUrl: 'portal/unsubscribe/unsubscribe.html',
  bindings: {
    transition: '<',
    club: '<',
  },
});
