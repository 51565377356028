
/**
 * Module definition and dependencies
 */
angular.module('Shared.EditDynamicGroupRules.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editDynamicGroupRules', {
    templateUrl: 'modals/edit-dynamic-group-rules.html',
    controller: 'ModalEditDynamicGroupRulesCtrl',
    closeOnClick: false,
    resolve: {
      activities($store) {
        'ngInject';
        return $store.activities.query();
      },
      memberships($store) {
        'ngInject';
        return $store.memberships.query();
      },
      groups($store) {
        'ngInject';
        return $store.memberGroups.query();
      },
      customFields($store) {
        'ngInject';
        return $store.customFields.query({model: 'Member'});
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalEditDynamicGroupRulesCtrl', function(
  $controller, $modalInstance, $filter, GroupRuleConditions, GroupRuleTypes,
  CustomFieldTypes, Genders, getGroupRuleConditions
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Get constants
  const {
    NUMBER, TEXT, LIST, ARRAY, DATE,
    DOB, AGE, GROUP, MEMBERSHIP, GRADE, GRADE_DATE,
  } = GroupRuleTypes;

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Create model subset from rulesets
    this.model.ruleSets = this.group.extract('ruleSets') || [];

    //No rules yet
    if (this.model.ruleSets.length === 0) {
      this.addRuleSet();
    }

    //Determine available props
    this.determineConditions();
    this.determineAvailableProps();

    //Initialize rules with options and conditions
    for (const ruleSet of this.model.ruleSets) {
      for (const rule of ruleSet.rules) {
        this.initializeRule(rule);
      }
    }
  };

  /**
   * Determine prop options
   */
  this.determineAvailableProps = function() {

    //Get data
    const {memberships, activities} = this;
    const groups = this.groups.filter(group => group.id !== this.group.id);
    const gradedActivities = activities.filter(a => a.hasGrades);
    const grades = gradedActivities.reduce((grades, activity) => {
      return grades.concat(activity.grades.map(grade => ({
        id: grade.id,
        name: gradedActivities.length > 1 ? `${activity.name} – ${grade.name}` : grade.name,
      })));
    }, []);

    //Available props
    this.props = [
      {
        prop: 'number',
        label: 'Number',
        type: TEXT,
      },
      {
        prop: 'fullName',
        label: 'Full name',
        type: TEXT,
      },
      {
        prop: 'dob',
        label: 'Date of birth',
        type: DOB,
      },
      {
        prop: 'age',
        label: 'Age',
        type: AGE,
      },
      {
        prop: 'gender',
        label: 'Gender',
        type: LIST,
        options: Genders,
      },
      {
        prop: 'email',
        label: 'Email',
        type: TEXT,
      },
      {
        prop: 'mobile',
        label: 'Mobile',
        type: TEXT,
      },
      {
        prop: 'phone',
        label: 'Phone',
        type: TEXT,
      },
      {
        prop: 'address.suburb',
        label: 'Suburb',
        type: TEXT,
      },
      {
        prop: 'address.postalCode',
        label: 'Postal code',
        type: TEXT,
      },
      {
        prop: 'address.city',
        label: 'City',
        type: TEXT,
      },
      {
        prop: 'address.state',
        label: 'State',
        type: TEXT,
      },
      {
        prop: 'address.country',
        label: 'Country',
        type: TEXT,
      },
      {
        prop: 'signUpDate',
        label: 'Member since',
        type: DATE,
      },
      {
        prop: 'accountCredit',
        label: 'Account credit balance',
        type: NUMBER,
      },
      {
        prop: 'amountOwing',
        label: 'Amount owing',
        type: NUMBER,
      },
      {
        prop: 'groups',
        label: 'Group(s)',
        type: GROUP,
        options: $filter('distribute')(groups, 3),
      },
    ];

    //Memberships
    if (memberships.length > 0) {
      this.props.push({
        prop: 'membership',
        label: 'Membership',
        type: MEMBERSHIP,
        options: $filter('distribute')(memberships, 3),
      });
    }

    //Grades
    if (grades.length > 0) {
      this.props.push(...[
        {
          prop: 'grade',
          label: 'Grade',
          type: GRADE,
          options: $filter('distribute')(grades, 3),
        },
        {
          prop: 'gradeAchieved',
          label: 'Grade achieved',
          type: GRADE_DATE,
        },
      ]);
    }

    //Add and map all custom fields
    this.customFields
      .filter(field => this.shouldAddCustomField(field))
      .map(field => this.convertCustomFieldToRule(field))
      .forEach(rule => this.props.push(rule));
  };

  /**
   * Determine conditions
   */
  this.determineConditions = function() {
    for (const ruleSet of this.model.ruleSets) {
      for (const rule of ruleSet.rules) {
        rule.conditions = getGroupRuleConditions(rule.type);
      }
    }
  };

  /**
   * Check if we should add custom field
   */
  this.shouldAddCustomField = function(field) {
    const {FILE, URL} = CustomFieldTypes;
    return (field.type !== FILE && field.type !== URL);
  };

  /**
   * Convert custom field to rule
   */
  this.convertCustomFieldToRule = function(field) {
    const {label, key: prop} = field;
    const type = this.getCustomFieldType(field);
    const options = this.getCustomFieldOptions(field);
    return {label, prop, type, options};
  };

  /**
   * Get custom field options
   */
  this.getCustomFieldOptions = function(field) {
    const {DROPDOWN, CHECKBOXES} = CustomFieldTypes;
    const {type, options} = field;
    if (type === DROPDOWN || type === CHECKBOXES) {
      return options
        .split('\n')
        .map(value => ({label: value, value}));
    }
    return null;
  };

  /**
   * Determine custom field type
   */
  this.getCustomFieldType = function(field) {
    const {DROPDOWN, CHECKBOXES, PARAGRAPH} = CustomFieldTypes;
    const {type} = field;
    if (type === DROPDOWN) {
      return LIST;
    }
    if (type === CHECKBOXES) {
      return ARRAY;
    }
    if (type === PARAGRAPH) {
      return TEXT;
    }
    return type;
  };

  /**
   * Initialize rule
   */
  this.initializeRule = function(rule) {
    const prop = this.props.find(prop => prop.prop === rule.prop);
    if (prop) {
      rule.options = prop.options;
      rule.conditions = getGroupRuleConditions(rule.type);
    }
  };

  /**
   * Update rule
   */
  this.updateRule = function(rule, prop, value) {
    if (prop === 'prop') {
      rule.prop = value.prop;
      rule.type = value.type;
      rule.options = value.options;
      rule.value = null;
      rule.conditions = getGroupRuleConditions(rule.type);
      if (!rule.condition) {
        rule.condition = rule.conditions[0].value;
      }
    }
    else if (prop === 'condition') {
      rule.condition = value;
      rule.value = null;
    }
    else {
      rule[prop] = value;
    }
  };

  /**
   * Add rule to current ruleSet
   */
  this.addRule = function(ruleSet) {
    ruleSet.rules.push({
      type: GroupRuleTypes.TEXT,
    });
  };

  /**
   * Add rule set
   */
  this.addRuleSet = function() {
    this.model.ruleSets.push({
      rules: [{}],
    });
  };

  /**
   * Remove a rule
   */
  this.removeRule = function(ruleSet, rule) {
    const i = ruleSet.rules.indexOf(rule);
    if (i !== -1) {
      ruleSet.rules.splice(i, 1);
      if (ruleSet.rules.length === 0) {
        const j = this.model.ruleSets.indexOf(ruleSet);
        if (j !== -1) {
          this.model.ruleSets.splice(j, 1);
          if (this.model.ruleSets.length === 0) {
            this.addRuleSet();
          }
        }
      }
    }
  };

  /**
   * Check if rule needs to have a value input
   */
  this.hasValue = function(rule) {

    //Get type and condition
    const {type, condition} = rule;
    const validTypes = [
      GroupRuleTypes.TEXT,
      GroupRuleTypes.NUMBER,
      GroupRuleTypes.AGE,
    ];
    const validConditions = [
      GroupRuleConditions.EQUAL,
      GroupRuleConditions.NOT_EQUAL,
      GroupRuleConditions.CONTAINS,
      GroupRuleConditions.NOT_CONTAINS,
      GroupRuleConditions.LESS_THAN,
      GroupRuleConditions.MORE_THAN,
    ];
    const dateConditions = [
      GroupRuleConditions.EQUAL,
      GroupRuleConditions.LESS_THAN,
      GroupRuleConditions.MORE_THAN,
      GroupRuleConditions.LESS_THAN_DAYS_AGO,
      GroupRuleConditions.MORE_THAN_DAYS_AGO,
      GroupRuleConditions.LESS_THAN_DAYS_AWAY,
      GroupRuleConditions.MORE_THAN_DAYS_AWAY,
    ];

    //Date type is special
    if (
      type === GroupRuleTypes.DOB ||
      type === GroupRuleTypes.DATE ||
      type === GroupRuleTypes.GRADE_DATE
    ) {
      return dateConditions.includes(condition);
    }

    //Check other types
    return (validTypes.includes(type) && validConditions.includes(condition));
  };
});
