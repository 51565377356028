
/**
 * Module definition and dependencies
 */
angular.module('Shared.IfBlank.Filter', [])

/**
 * Filter definition
 */
.filter('ifBlank', function() {
  return function(value, ifBlank = '–') {
    if (value === null || value === undefined || value === '' || value === 0) {
      return ifBlank;
    }
    return value;
  };
});
