
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Circles.Overview', [
  'App.Admin.People.Circles.Overview.Controller',
  'App.Admin.People.Circles.CircleOptions.Component',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.circles.overview', {
    url: '',
    component: 'adminCirclesOverviewRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('circles');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminCirclesOverviewRoute', {
  controller: 'AdminCirclesOverviewCtrl',
  templateUrl: 'admin/people/circles/overview/overview.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    page: '<',
    filter: '<',
    integrations: '<',
  },
});
