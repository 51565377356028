
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Controller', [])

/**
 * Base controller
 */
.controller('AdminActivityCtrl', function($q, $notice, $modal, $store) {

  /**
   * Merge
   */
  this.merge = function($event) {

    //Get activity and model
    const {activity, model} = $event;

    //Merge
    activity.merge(model);
    return $q.resolve();
  };

  /**
   * Save
   */
  this.save = function($event) {

    //Get activity and model
    const {activity, model, isEdit} = $event;

    //Save
    return $store.activities
      .save(activity, model)
      .then(activity => {
        if (isEdit) {
          $notice.show(`Activity updated`);
        }
        else {
          $notice.show(`Activity added`);
        }
        $store.activities.clear();
        this.club.markStepComplete('setupActivities', true);
        this.onSaved({activity});
      })
      .catch(error => {
        if (isEdit) {
          $notice.showError(`Failed to update activity`);
        }
        else {
          $notice.showError(`Failed to add activity`);
        }
        throw error;
      });
  };

  /**
   * Delete
   */
  this.delete = function($event) {

    //Get activity and define handler
    const {activity} = $event;
    const handler = function() {
      return $store.activities.delete(activity);
    };

    //Can't remove default activity
    if (activity.isDefault) {
      return $notice.showError(`Cannot remove default activity`);
    }

    //Open confirmation dialog
    $modal
      .open('basic', {
        templateUrl: 'admin/activity/modals/confirm-delete-activity.html',
        locals: {activity, handler},
      })
      .result
      .then(() => {
        $notice.show(`Activity removed`);
        this.onDeleted({activity});
      });
  };

  /**
   * No-ops (to extend as needed)
   */
  this.onSaved = function() {};
  this.onDeleted = function() {};
  this.onMadeDefault = function() {};
});
