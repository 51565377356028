
/**
 * Module definition and dependencies
 */
angular.module('Shared.CommentStore.Service', [
  'Store.CollectionStore.Service',
])

/**
 * Model definition
 */
.factory('CommentStore', function($collectionStore) {

  /**
   * Constructor
   */
  function CommentStore(name, config) {
    $collectionStore.call(this, name, config);
  }

  /**
   * Extend prototype
   */
  angular.extend(CommentStore.prototype, $collectionStore.prototype);

  /**
   * Query wrapper
   */
  CommentStore.prototype.query = function(filter) {

    //Modify filter if needed
    const known = ['model', 'itemId'];
    const actualFilter = {};

    //Loop properties, copy known ones and turn unknowns into model/itemId
    for (const key in filter) {
      if (known.includes(key)) {
        actualFilter[key] = filter[key];
      }
      else if (typeof actualFilter.model === 'undefined') {
        actualFilter.model = key[0].toUpperCase() + key.substring(1);
        actualFilter.itemId = filter[key];
      }
    }

    //Query from server
    return $collectionStore.prototype.query.call(this, actualFilter);
  };

  //Return
  return CommentStore;
});
