
/**
 * Module definition and dependencies
 */
angular.module('Shared.Text.UCFirst.Filter', [])

/**
 * Filter definition
 */
.filter('ucfirst', function() {
  return function(str) {
    if (typeof str === 'number') {
      return String(str);
    }
    else if (typeof str !== 'string') {
      return '';
    }
    if ((str = String(str)) === '') {
      return '';
    }
    return str[0].toUpperCase() + str.substring(1);
  };
});
