
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.AddAttendees.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addAttendees', {
    templateUrl: 'admin/event/modals/add-attendees.html',
    controller: 'ModalAddAttendeesCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalAddAttendeesCtrl', function(
  $controller, $modalInstance, $notice, $filter, $store, $q, moment, Helpers,
  Member, MemberSelectionMethods, FeeMethods, EventSeriesHandling, DateFormat
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Get constants
  const {RULES, FIXED, NONE} = FeeMethods;
  const {SERIES, INSTANCE, RANGE} = EventSeriesHandling;
  const {SPECIFIC, ALL, GROUPS, SELECTION} = MemberSelectionMethods;

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set flag
    this.isLoading = true;

    //Get data
    const {event} = this;
    const {removeOption, changeOption} = Helpers;
    const {maxAttendees, hasRules, startDate, firstDate, lastDate} = event;
    const now = moment();

    //Prepare model
    this.model = {
      event,
      feeMethod: RULES,
      hasAttended: false,
      isRefundable: false,
      seriesHandling: null,
      fromDate: null,
      toDate: null,
      meta: {
        whoFor: SPECIFIC,
        members: [],
        groups: [],
        notifyByEmail: true,
      },
    };

    //Initialize
    this.members = [];
    this.max = maxAttendees;
    this.numGoing = this.attendees
      .filter(attendee => !attendee.isRemoved)
      .length;

    //Existing members ID map
    this.existing = this.attendees
      .filter(attendee => !attendee.isGuest && !attendee.isRemoved)
      .map(attendee => attendee.member.id)
      .reduce((map, id) => {
        map[id] = true;
        return map;
      }, {});

    //Constants
    this.feeMethods = FeeMethods;
    this.memberSelectionMethods = MemberSelectionMethods;

    //Limited series
    if (this.event.isLimitedSeries && lastDate) {

      //Set model data
      this.model.seriesHandling = SERIES;
      this.model.fromDate = moment.max(firstDate, now);
      this.model.toDate = lastDate;

      //Change option
      const date = startDate.format(DateFormat.formats.standard);
      const from = firstDate.format(DateFormat.formats.standard);
      const to = lastDate.format(DateFormat.formats.standard);
      this.eventSeriesHandling =
        changeOption(changeOption(EventSeriesHandling,
          SERIES, `The whole event series (${from} – ${to})`),
        INSTANCE, `This event instance only (${date})`);
    }

    //No default rules?
    if (!hasRules) {
      this.model.feeMethod = NONE;
      this.feeMethods = removeOption(FeeMethods, RULES);
    }

    //If member was passed, we are adding them via QR code
    if (this.memberId) {

      //Set step
      this.setStep('details');

      //Set has attendeed flag
      this.model.hasAttended = true;

      //Load member and add to array
      Member
        .findById(this.memberId)
        .then((data) => this.model.members.push(data));
    }
    else {
      this.setStep('members');
    }

    //Load groups and count members
    $q
      .all([
        this.loadGroups(),
        this.countMembers(),
      ])
      .then(() => {
        if (this.groups.length === 0) {
          this.memberSelectionMethods
            = removeOption(MemberSelectionMethods, GROUPS);
        }
        if (this.memberCount > 300) {
          this.memberSelectionMethods
            = removeOption(MemberSelectionMethods, SELECTION);
        }
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Set selection method
   */
  this.setSelectionMethod = function(method) {
    this.model.meta.whoFor = method;
    if (method === SELECTION) {
      this.loadMembers();
    }
  };

  /**
   * Load members
   */
  this.loadMembers = function() {

    //Already loaded
    if (this.members.length > 0) {
      return;
    }

    //Get data
    const {existing} = this;

    //Initialize members
    this.isLoadingMembers = true;

    //Query
    Member
      .query({
        isArchived: false,
        fields: 'firstName,lastName',
      })
      .then(data => data.members.filter(m => !existing[m.id]))
      .then(members => $filter('distribute')(members, 3, 6))
      .then(members => this.members = members)
      .finally(() => this.isLoadingMembers = false);
  };

  /**
   * Count members
   */
  this.countMembers = function() {
    return Member
      .count({isArchived: false, isPending: false})
      .then(members => this.memberCount = members);
  };

  /**
   * Load groups
   */
  this.loadGroups = function() {
    return $store.memberGroups
      .query()
      .then(group => this.groups = group);
  };

  /**
   * Next from members
   */
  this.nextFromMembers = function() {

    //Get data
    const {existing, model: {meta}} = this;

    //Check if some of the selected members are already in the list of attendees
    const removed = meta.members.filter(m => existing[m.id]);
    meta.members = meta.members.filter(m => !existing[m.id]);

    //Existing members found?
    if (removed.length > 0) {
      this.isErrorAlreadyAttending = true;
      return;
    }

    //Clear flag
    this.isErrorAlreadyAttending = false;

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Flag as pristine again
    this.form.$setPristine();

    //Go to details
    this.setStep('details');
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Get meta data
    const {meta, feeMethod} = this.model;

    //Clear members
    if (meta.whoFor === ALL || meta.whoFor === GROUPS) {
      delete meta.members;
    }

    //Clear groups
    if (meta.whoFor !== GROUPS) {
      delete meta.groups;
    }

    //Remove flag
    if (feeMethod !== FIXED) {
      delete this.model.isRefundable;
    }

    //Sanitise
    if (this.model.seriesHandling !== RANGE) {
      delete this.model.fromDate;
      delete this.model.toDate;
    }

    //Reset flags
    this.isSaving = true;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => $notice.showError('Failed to add attendees'))
      .finally(() => this.isSaving = false);
  };
});
