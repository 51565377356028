
/**
 * Module definition and dependencies
 */
angular.module('App.Account.SelectPaymentMethod.Card', [])

/**
 * Component
 */
.component('cardAccountSelectPaymentMethod', {
  templateUrl: 'account/cards/select-payment-method.html',
  controller: 'CardAccountSelectPaymentMethodCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    payment: '<',
    canPayLater: '<',
    onPrev: '&',
    onNext: '&',
    onCancel: '&',
    onPayLater: '&',
  },
})

/**
 * Controller
 */
.controller('CardAccountSelectPaymentMethodCtrl', function(Text, TextTypes) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set flags
    this.isLoading = true;
    this.isAdmin = (this.user && this.user.isAdmin());

    //Clear any errors
    this.payment.clearErrors();

    //Load payment providers
    this.payment
      .loadProviders()
      .then(() => this.payment.setupProviders())
      .finally(() => this.isLoading = false);

    //Load text for the clubs that don't have payments enabled
    Text
      .getText(TextTypes.PAYMENT_INSTRUCTIONS)
      .then(instructions => this.paymentInstructions = instructions);
  };

  /**
   * Pay later
   */
  this.payLater = function() {

    //Set flags
    this.isPayingLater = true;

    //Process
    this
      .onPayLater()
      .finally(() => this.isPayingLater = false);
  };

  /**
   * Confirm payment method
   */
  this.next = function() {

    //Error?
    if (this.payment.hasError) {
      return;
    }

    //Set flag
    this.isConfirming = true;

    //Confirm payment method
    this.payment
      .confirmPaymentMethod()
      .then(() => {

        //Only proceed if there's no error
        if (!this.payment.hasError) {
          this.onNext();
        }
      })
      .finally(() => this.isConfirming = false);
  };

  /**
   * Previous step
   */
  this.prev = function() {
    this.onPrev();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
