
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.AddLinkedMemberships.Card', [])

/**
 * Component
 */
.component('cardSubscriptionAddLinkedMemberships', {
  templateUrl: 'subscription/cards/add-linked-memberships.html',
  require: {
    card: '^^',
  },
  bindings: {
    isOwn: '<',
    user: '<',
    member: '<',
    members: '<',
    memberships: '<',
    selection: '<',
    onNext: '&',
    onPrev: '&',
    onCancel: '&',
    onSelect: '&',
  },
  controller($modal) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Filter out bundled memberships from chosen selection that have
      this.bundled = this.selection
        .filter(item => item.membership.hasNonArchivedLinked)
        .filter(item => (
          item.linked.length > 0 ||
          this.hasEligibleLinkedMemberships(item)
        ));
    };

    /**
     * Check if we have eligible linked memberships
     */
    this.hasEligibleLinkedMemberships = function(item) {
      const linked = this.getEligibleLinkedMemberships(item);
      return (linked.length > 0);
    };

    /**
     * Get eligible linked memberships
     */
    this.getEligibleLinkedMemberships = function(item) {
      return this.memberships
        .filter(m => m.isLinked && m.canSelectForPurchase)
        .filter(m => m.isLinkedTo(item.membership))
        .filter(m => !this.hasReachedBundleLimit(item, m));
    };

    /**
     * Get eligible linked members
     */
    this.getEligibleLinkedMembers = function(item) {

      //Get eligible circle members
      const linked = this.members
        .filter(m => this.isOwn || this.member.id !== m.id)
        .filter(m => !item.linked.some(e => e.member.id === m.id));

      //Add self if purchasing sub for someone else
      if (!this.isOwn) {
        linked.unshift(this.user);
      }

      //Pass on
      return linked;
    };

    /**
     * Check if we can add more linked members to an item
     */
    this.hasEligibleLinkedMembers = function(item) {
      const linked = this.getEligibleLinkedMembers(item);
      return (linked.length > 0);
    };

    /**
     * Check if has reached bundle limit
     */
    this.hasReachedBundleLimit = function(item, membership) {

      //Check bundle limit
      const {id, bundleLimit} = membership;
      if (!bundleLimit) {
        return false;
      }

      //Count existing
      const num = item.linked
        .filter(linked => linked.membership.id === id)
        .length;

      //Check if reached
      return (num >= bundleLimit);
    };

    /**
     * Check if we can add a member
     */
    this.canAddMember = function(item) {
      return (
        this.hasEligibleLinkedMembers(item) &&
        this.hasEligibleLinkedMemberships(item)
      );
    };

    /**
     * Add a linked member
     */
    this.addLinkedMember = function(item) {

      //Get eligible linked memberships and members
      const memberships = this.getEligibleLinkedMemberships(item);
      const members = this.getEligibleLinkedMembers(item);
      const {member} = this;

      //Handler
      const handler = linked => item.linked.push(linked);

      //Open modal
      $modal.open('addLinkedMember', {
        locals: {
          primary: member,
          members,
          memberships,
          handler,
        },
      });
    };

    /**
     * Remove a linked member
     */
    this.removeLinkedMember = function(item, linked) {
      const i = item.linked.indexOf(linked);
      if (i !== -1) {
        item.linked.splice(i, 1);
      }
    };

    /**
     * Toggle select handler
     */
    this.updateSelection = function() {
      const {selection} = this;
      this.onSelect({$event: {selection}});
    };

    /**
     * Next step
     */
    this.next = function() {
      this.onNext();
    };

    /**
     * Prev step
     */
    this.prev = function() {
      this.onPrev();
    };

    /**
     * Cancel flow
     */
    this.cancel = function() {
      this.onCancel();
    };
  },
});
