
/**
 * Module definition and dependencies
 */
angular.module('App.Directory', [
  'App.Directory.Overview',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('directory', {
    parent: 'app',
    url: '/directory',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'directory',
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'directory.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'directory.overview', title});
  }, {priority: 5});
});
