
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.ViewMembership.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('viewMembership', {
    templateUrl: 'subscription/modals/view-membership.html',
    controller: 'ModalViewMembershipCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalViewMembershipCtrl', function(
  $controller, $modalInstance, $store
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set membership shortcut
    this.membership = this.subscription.membership;

    //Get activities
    $store.activities
      .query()
      .then(activities => this.activities = activities);
  };
});
