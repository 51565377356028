
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.AddAccountCredit.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addAccountCredit', {
    templateUrl: 'admin/finance/modals/add-account-credit.html',
    controller: 'ModalAddAccountCreditCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalAddAccountCreditCtrl', function(
  $controller, $modalInstance, $focus, moment,
  TransactionTypes, PaymentMethods, DebitCredit
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});
  const ACCOUNT_CREDIT = 'accountCredit';

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set data
    this.isSaving = false;
    this.DebitCredit = DebitCredit;
    this.TransactionTypes = TransactionTypes;

    //Initialize model
    this.model = {
      isCredit: false,
      type: ACCOUNT_CREDIT,
      amount: '',
      dueDate: moment().startOf('day'),
      meta: {
        notifyByEmail: true,
        payment: {
          method: null,
          date: moment().startOf('day'),
          reference: '',
        },
      },
    };

    //Filter payment methods and transaction types
    this.paymentMethods = PaymentMethods
      .filter(method => !method.isOnline && !method.isAccountCredit);
    this.transactionTypes = TransactionTypes
      .filter(type => type.value !== ACCOUNT_CREDIT);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {

    //Set value
    this.model[property] = value;

    //Changed credit? Sanitise underlying data
    if (property === 'isCredit') {
      if (!value) {
        this.model.type = ACCOUNT_CREDIT;
        this.model.meta.payment.method = null;
      }
      else {
        this.model.type = null;
        this.model.meta.payment.method = ACCOUNT_CREDIT;
      }
    }
  };

  /**
   * Update payment meta
   */
  this.updatePaymentMeta = function(property, value) {
    this.model.meta.payment[property] = value;
  };

  /**
   * Postlink
   */
  this.$postLink = function() {
    $focus('details', true);
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => {})
      .finally(() => this.isSaving = false);
  };
});
