
/**
 * Module definition and dependencies
 */
angular.module('Shared.IntegrationTypes.Options', [])

/**
 * Constant definition
 */
.constant('IntegrationTypes', [
  {
    label: 'Stripe',
    value: 'stripe',
  },
  {
    label: 'POLi',
    value: 'poli',
  },
  {
    label: 'Mailchimp',
    value: 'mailchimp',
  },
  {
    label: 'Campaign Monitor',
    value: 'campaignMonitor',
  },
  {
    label: 'Xero',
    value: 'xero',
  },
]);
