
/**
 * Module definition and dependencies
 */
angular.module('Shared.YesNo.Options', [])

/**
 * Constant definition
 */
.constant('YesNo', [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
]);
