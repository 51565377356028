
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Overview', [
  'App.Booking.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('booking.overview', {
    url: '/bookings/overview',
    component: 'bookingOverviewRoute',
    data: {
      roles: ['admin', 'eventManager'],
    },
    resolve: {
      filter(Filters) {
        return Filters.get('bookingsOverview');
      },
      areas($store) {
        return $store.areas.query();
      },
    },
  });
})

/**
 * Route component
 */
.component('bookingOverviewRoute', {
  controller: 'BookingOverviewCtrl',
  templateUrl: 'booking/overview/overview.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    activities: '<',
    areas: '<',
    page: '<',
    filter: '<',
    module: '<',
  },
});
