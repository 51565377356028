
/**
 * Module definition and dependencies
 */
angular.module('App.Event.View.Controller', [])

/**
 * Controller
 */
.controller('EventViewCtrl', function(
  $modal, $store, EventInterest, EventAttendee, Push,
  $q, Event, Config
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flags
    this.canEdit = this.user.hasRole('admin', 'eventManager');
    this.canView = this.user.hasRole('admin', 'viewer', 'eventManager');
    this.isOrganiser = this.event.isOrganiser(this.user);
    this.canSignUp = (this.attendance.options.length > 0);
    this.defaultBanner = `https://${Config.domains.images}/${Config.app.defaultBanner}`;

    //Set attendance data
    this.setAttendanceData();

    //Ask for push notifications if we are attending
    if (this.attendee) {
      Push.ask(this.user, 'attendee');
    }

    //Create list of available members
    this.members = [this.user].concat(this.circleMembers);

    //Get initial member
    const member = this.determineInitialMember();

    //Set selected member
    this.setMember(member);
  };

  /**
   * Set member
   */
  this.setMember = function(member) {
    this.member = member;
    this.isSelf = member === this.user;
    this.loadData(member);
  };

  /**
   * Select member event handler
   */
  this.onSelectMember = function(member) {
    this.setMember(member);
  };

  /**
   * Determine initial member
   */
  this.determineInitialMember = function() {

    //Check if valid circle member ID given in params
    const {member: memberId} = this.transition.params();
    if (memberId) {
      const member = this.circleMembers.find(m => m.id === memberId);
      if (member) {
        return member;
      }
    }

    //Return ourselves
    return this.user;
  };

  /**
   * Toggle interest
   */
  this.toggleInterest = function() {

    //Remove interest
    if (this.interest) {
      return EventInterest
        .cancelOwn(this.interest.id, this.member.id)
        .finally(() => this.interest = null);
    }

    //Create interest
    const event = this.event.id;
    const interest = new EventInterest({event});

    //Create interest
    return interest
      .createOwn({circleMemberId: this.member.id})
      .then(interest => this.interest = interest);
  };

  /**
   * Cancel attendance
   */
  this.cancelAttendance = function() {

    //Get data
    const {attendee, event, member} = this;
    const handler = () => EventAttendee.cancelOwn(attendee.id, member.id);

    //Open modal
    $modal
      .open('basic', {
        templateUrl: `event/modals/confirm-cancel-attendance.html`,
        locals: {attendee, event, handler},
      })
      .result
      .finally(() => {
        this.attendee = null;
        $store.events.clear();
        $store.events
          .findById(this.event.id)
          .then(event => this.event = event);
      });
  };

  /**************************************************************************
   * Circles handling
   ***/

  /**
   * Load data
   */
  this.loadData = function(member) {

    //Flag as loading
    this.isLoading = true;

    //Load
    $q
      .all([
        this.loadAttendance(member),
      ])
      .finally(() => this.isLoading = false);
  };

  /**
   * Load the selected members attendance
   */
  this.loadAttendance = function(member) {

    //Clear
    this.attendance = {};

    //Self
    if (member === this.user) {
      return Event
        .getOwnAttendance(this.event.id)
        .then(attendance => this.attendance = attendance)
        .then(() => this.setAttendanceData());
    }

    //Circle member
    return Event
      .getOwnAttendance(this.event.id, member.id)
      .then(attendance => this.attendance = attendance)
      .then(() => this.setAttendanceData());
  };

  /**
   * Set flag and extract attenance data
   */
  this.setAttendanceData = function() {
    this.canSignUp = (this.attendance.options.length > 0);
    Object.assign(this, this.attendance);
  };
});
