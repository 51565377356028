
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.OverrideDoors.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('overrideDoors', {
    templateUrl: 'admin/system/modals/override.html',
    controller: 'ModalOverrideDoorsCtrl',
    resolve: {
      system($store) {
        'ngInject';
        return $store.system.get();
      },
      club($store) {
        'ngInject';
        return $store.club.get();
      },
      user($store) {
        'ngInject';
        return $store.user.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalOverrideDoorsCtrl', function(
  $controller, $modalInstance, Settings
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalOverrideCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Prepare items and groups
   */
  this.prepareItemsAndGroups = function() {

    //Get doors
    const {system, user, event} = this;
    const eventDoors = Settings.get('event.organiserDoors.doors');
    const userDoors = user.doorsAllowedToOverride || [];
    const isSuper = user.isSuper();
    const doors = system.doors.filter(door => {
      if (isSuper) {
        return true;
      }
      if (event && eventDoors.includes(door.id)) {
        return true;
      }
      return userDoors.includes(door.id);
    });

    //Set type
    this.type = `doors`;

    //Make items and groups
    this.items = doors;
    this.groups = [{
      name: `Doors`,
      items: doors,
    }];
  };

  /**
   * Apply state
   */
  this.applyState = function(state) {
    this.system.applyDoorStates(this.items, state.doorStates);
  };

  /**
   * Perform actual override request
   */
  this.overrideRequest = function(...args) {
    return this.system.overrideDoors(...args);
  };
});
