
/**
 * Module definition and dependencies
 */
angular.module('Shared.TagValidityOptions.Options', [])

/**
 * Constant definition
 */
.constant('TagValidityOptions', [
  {
    value: 'unlimited',
    label: 'Unlimited',
  },
  {
    value: 'fixed',
    label: 'Fixed duration',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
]);
