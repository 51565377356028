
/**
 * Module definition and dependencies
 */
angular.module('Shared.DateFormat.Options', [])

/**
 * Constant definition
 */
.constant('DateFormatOptions', [
  {
    label: '30 Jan 2020',
    value: 'D MMM YYYY',
  },
  {
    label: 'Jan 30, 2020',
    value: 'MMM D, YYYY',
  },
  {
    label: '30/01/2020',
    value: 'DD/MM/YYYY',
  },
  {
    label: '01/30/2020',
    value: 'MM/DD/YYYY',
  },
  {
    label: '2020/01/30',
    value: 'YYYY/MM/DD',
  },
  {
    label: '30-01-2020',
    value: 'DD-MM-YYYY',
  },
  {
    label: '01-30-2020',
    value: 'MM-DD-YYYY',
  },
  {
    label: '2020-01-30',
    value: 'YYYY-MM-DD',
  },
  {
    label: '30.01.2020',
    value: 'DD.MM.YYYY',
  },
]);
