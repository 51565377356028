
/**
 * Module definition and dependencies
 */
angular.module('Shared.QrScanner.Service', [])

/**
 * Service
 */
.factory('QrScanner', function($window) {

  //Get object from window
  if (!$window.QrScanner) {
    throw new Error('Missing QrScanner library');
  }

  //Set worker path
  $window.QrScanner.WORKER_PATH = `/qr-scanner-worker.min.js`;

  //Return library
  return $window.QrScanner;
});
