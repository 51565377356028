/* eslint max-len: off */

/**
 * Module definition and dependencies
 */
angular.module('Shared.Input.Directive', [])

/**
 * Directive
 */
.directive('input', function() {
  return {
    require: [
      '?ngModel',
    ],
    //eslint-disable-next-line no-unused-vars
    link(scope, element, attrs, ngModel) {

      //Array?
      if (Array.isArray(ngModel)) {
        ngModel = ngModel[0];
      }

      //URL regex
      const URL_REGEX = /^((?:http)s?:\/\/)(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[/?]\S+)$/i;

      //Fake email domains regex
      const FAKE_EMAIL_REGEX = /mailinator\.com|throwawaymail\.com|forgetmail\.com|guerillamail\.com|trashmail\.com/;

      /**
       * Prefix with http
       */
      function prefixWithHttp(url) {
        if (url && !URL_REGEX.test(url) && URL_REGEX.test('http://' + url)) {
          return 'http://' + url;
        }
        else {
          return url;
        }
      }

      /**
       * Helper to apply URL handling
       */
      function applyUrlHandling() {

        //Silently prefixes schemeless URLs with 'http://' when
        //converting a view value to model value.
        ngModel.$parsers.unshift(prefixWithHttp);

        //Set up validator
        ngModel.$validators.url = function(url) {
          return ngModel.$isEmpty(url) || URL_REGEX.test(url);
        };
      }

      /**
       * Helper to apply email handling
       */
      function applyEmailHandling() {

        //Setup validator
        ngModel.$validators.fake = function(email) {
          return ngModel.$isEmpty(email) || !FAKE_EMAIL_REGEX.test(email);
        };
      }

      //Apply for URL type input fields
      if (ngModel && attrs.type === 'url') {
        applyUrlHandling();
      }
      if (ngModel && attrs.type === 'email') {
        applyEmailHandling();
      }
    },
  };
});
