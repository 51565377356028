/* eslint new-cap: off */

/**
 * Module definition and dependencies
 */
angular.module('Shared.Intercom.Service', [])

/**
 * Service
 */
.factory('Intercom', function($window, $convert, Config) {

  //Get Intercom object from window
  if (!$window.Intercom) {
    throw new Error('Missing Intercom library');
  }

  //Check if enabled
  const isEnabled = !!Config.intercom;

  //Return interface
  return {

    /**
     * Boot
     */
    boot(user, club, system) {

      //Not enabled
      if (!isEnabled) {
        return;
      }

      //Make data
      const data = this.makeData(user, club, system);

      //Setup
      $window.Intercom('boot', data);
      $window.Intercom('update', {
        custom_launcher_selector: '.IntercomLauncher',
        hide_default_launcher: true,
      });
    },

    /**
     * Update
     */
    update(data) {

      //Not enabled
      if (!isEnabled) {
        return;
      }

      //Parse data
      data = this.parseData(data);

      //Update
      $window.Intercom('update', data);
    },

    /**
     * Event
     */
    event(type, meta) {

      //Not enabled
      if (!isEnabled) {
        return;
      }

      //Parse meta data
      meta = this.parseData(meta);

      //Track
      $window.Intercom('trackEvent', type, meta);
    },

    /**
     * Shutdown
     */
    shutdown() {

      //Not enabled
      if (!isEnabled) {
        return;
      }

      //Shutdown
      $window.Intercom('shutdown');
    },

    /**
     * Make data
     */
    makeData(user, club, system) {

      //Initialize data
      const data = {
        appId: Config.intercom.id,
        environment: Config.env,
      };

      //Append other data
      this.appendUserData(user, club, data);
      this.appendClubData(club, data);
      this.appendSystemData(system, data);

      //Return data
      return this.parseData(data);
    },

    /**
     * Append user data
     */
    appendUserData(user, club, data) {

      //No user
      if (!user) {
        return;
      }

      //Append properties that exist
      if (user.id) {
        data.userId = user.id;
      }
      if (user.intercomHash) {
        data.userHash = user.intercomHash;
      }
      if (user.name) {
        data.name = user.name;
      }
      if (user.email) {
        data.email = user.email;
      }
      if (user.mobile || user.phone) {
        data.phone = user.mobile || user.phone;
      }
      if (user.avatar) {
        data.avatar = this.parseData({
          type: 'avatar',
          imageUrl: `${user.avatar.url}?w=500&h=500&fit=crop&q=75`,
        });
      }
      if (user.roles) {
        data.roles = user.roles.filter(role => role !== 'member').join(', ');
      }
      if (user.firstOnline) {
        data.createdAt = user.firstOnline.unix();
      }
      if (typeof user.isAccountOwner !== 'undefined') {
        data.isAccountOwner = !!user.isAccountOwner;
      }

      //Club data appended to users
      if (!club) {
        return;
      }
      if (club.name) {
        data.clubName = club.name;
      }
      if (club.type) {
        data.clubType = club.type;
      }
      if (club.country) {
        data.clubCountry = club.country;
      }
      if (club.timezone) {
        data.clubTimezone = club.timezone;
      }
      if (!data.phone && club.phone) {
        data.phone = club.phone;
      }
      if (club.approxMembers) {
        data.clubApproxMembers = club.approxMembers;
      }
      if (club.source) {
        data.clubSource = club.source;
      }
      if (club.website) {
        data.clubWebsite = club.website;
      }
      if (club.appUrl) {
        data.clubAppUrl = club.appUrl;
      }
      if (club.setup) {
        data.clubSetupProgress = club.setup.progress || 0;
      }
      if (club.engagement) {
        data.clubEngagementScore = club.engagement || 0;
      }
      if (club.trialEndDate) {
        data.clubTrialEndDate = club.trialEndDate.format('YYYY-MM-DD');
      }

      //Club identifier and Stripe ID
      data.identifier = club.identifier;
      data.isPartnerAccount = !!club.isPartnerAccount;
      if (club.setup) {
        data.setupProgress = club.setup.progress;
      }
      if (club.stripe && club.stripe.customerId) {
        data.stripe_id = club.stripe.customerId;
      }
    },

    /**
     * Append club data
     */
    appendClubData(club, data) {

      //No club
      if (!club) {
        return;
      }

      //Create company sub data
      const company = {};

      //Club properties
      if (club.id) {
        company.id = club.id;
      }
      if (club.name) {
        company.name = club.name;
      }
      if (club.plan) {
        company.plan = club.plan.identifier;
        company.planName = club.plan.name;
      }
      if (club.timezone) {
        company.timezone = club.timezone;
      }
      if (typeof club.isTrial !== 'undefined') {
        company.isTrial = !!club.isTrial;
      }
      if (typeof club.isLive !== 'undefined') {
        company.isLive = !!club.isLive;
      }
      if (club.signUpDate) {
        company.createdAt = club.signUpDate.unix();
        company.remoteCreatedAt = club.signUpDate.unix();
      }
      if (club.appUrl) {
        company.website = club.appUrl;
      }
      if (club.identifier) {
        company.identifier = club.identifier;
      }
      if (club.type) {
        company.type = club.type;
        company.typeUC = club.type[0].toUpperCase() + club.type.substring(1);
      }
      if (club.setup) {
        company.setupProgress = club.setup.progress || 0;
      }

      //Append company data
      data.company = this.parseData(company);

      //Also set app URL on user data for easy use in articles etc.
      if (club.appUrl) {
        data.appUrl = club.appUrl;
      }
    },

    /**
     * Append system data
     */
    appendSystemData(system, data) {

      //No system
      if (!system) {
        data.system = '';
        return;
      }

      //System properties
      if (system.type) {
        data.system = system.type;
      }
    },

    /**
     * Parse data
     */
    parseData(data) {
      if (!data || typeof data !== 'object') {
        return undefined;
      }
      return $convert.object.keysToSnakeCase(data);
    },
  };
});
