
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.Info.Card', [])

/**
 * Component
 */
.component('cardMemberInfo', {
  templateUrl: 'admin/people/members/cards/info.html',
  controller: 'CardMemberInfoCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    isStaff: '<',
    club: '<',
    member: '<',
    groups: '<',
    coupons: '<',
    activities: '<',
    system: '<',
    isOwn: '<',
    showQr: '<',
    canEdit: '<',
    canVerifyVaccinationStatus: '<',
    onVerifyVaccinationStatus: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberInfoCtrl', function() {

  /**
   * On init
   */
  this.$onInit = function() {
    this.url = this.club.makeUrl(`admin/people/members/edit/${this.member.id}`);
    this.grades = [];
  };

  /**
   * On changes
   */
  this.$onChanges = function() {
    this.determineGrades();
  };

  /**
   * Determine grades
   */
  this.determineGrades = function() {

    //Get data
    const {member, activities} = this;

    //No member, activities or grades
    if (!activities || !member || !member.grades) {
      return;
    }

    //Determine graded activities
    const gradedActivities = activities
      .filter(activity => activity.hasGrades)
      .map(activity => activity.id);

    //Get current grades
    this.grades = member.grades
      .filter(grade =>
        grade.isCurrent &&
        gradedActivities.includes(grade.activity.id)
      );
  };

  /**
   * Change avatar
   */
  this.changeAvatar = function() {
    if (this.isOwn) {
      this.card.open('profile.avatar');
    }
    else {
      this.card.open('member.avatar');
    }
  };
});
