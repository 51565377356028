
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.ForgotPassword.Controller', [])

/**
 * Controller
 */
.controller('ForgotPasswordCtrl', function(
  $state, User, NotFoundError
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize model
    this.model = {
      username: '',
    };

    //Initialize flags
    this.isSubmitting = false;
    this.isSubmitted = false;

    //Check if sign in is enabled
    if (this.club && !this.club.settings.signIn.canUseCredentials) {
      $state.go('login');
    }
  };

  /**
   * Submit request
   */
  this.submit = function() {

    //Must be validated
    if (this.form.$invalid) {
      return;
    }

    //Mark as submitting
    this.isSubmitting = true;
    this.isUnknown = false;

    //Send request
    User
      .forgotPassword(this.model)
      .then(() => {
        this.isSubmitted = true;
        this.model = {};
      })
      .catch(error => {
        if (error instanceof NotFoundError) {
          this.isUnknown = true;
        }
        throw error;
      })
      .finally(() => this.isSubmitting = false);
  };
});
