
/**
 * Card tabs component
 */

/**
 * Module definition and dependencies
 */
angular.module('Shared.Cards.CardTab.Component', [])

/**
 * Component
 */
.component('cardTab', {
  template: `<a class="CardTab" ng-click="$ctrl.show()" ng-class="{'is-active': $ctrl.card.isTabActive($ctrl.tab), 'is-disabled': ($ctrl.isDisabled || !$ctrl.cardTabs.canNavigate)}" ng-transclude></a>`,
  transclude: true,
  require: {
    card: '^^',
    cardTabs: '^^',
  },
  bindings: {
    tab: '@',
    isActive: '<',
    isHidden: '<',
    isDisabled: '<',
  },
  controller() {

    /**
     * On init
     */
    this.$onInit = function() {

      //Push into card tabs
      if (!this.isHidden) {
        this.card.addTab(this.tab);
      }

      //Make active if explicitly requested or if there's no active tab yet
      if (this.isActive || !this.card.activeTab) {
        this.card.showTab(this.tab);
      }
    };

    /**
     * Show tab
     */
    this.show = function() {

      //Can't navigate (e.g. in a flow) or disabled
      //TODO: Refine this later so we can go back but not forward etc.
      if (!this.cardTabs.canNavigate || this.isDisabled) {
        return;
      }

      //Show tab
      this.card.showTab(this.tab);
    };
  },
});
