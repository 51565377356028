
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area.Controller', [])

/**
 * Base controller
 */
.controller('AdminAreaCtrl', function($store, $modal, $notice, $q) {

  /**
   * Merge
   */
  this.merge = function($event) {

    //Get area and model
    const {area, model} = $event;
    this.isDirty = true;

    //Merge
    area.merge(model);
    return $q.resolve();
  };

  /**
   * Save
   */
  this.save = function($event) {

    //Get area and model
    const {area, model, isEdit} = $event;

    //Save
    return $store.areas
      .save(area, model)
      .then(area => {
        if (isEdit) {
          $notice.show(`Area updated`);
        }
        else {
          $notice.show(`Area added`);
        }
        this.club.markStepComplete('setupAreas', true);
        this.onSaved({area});
      })
      .catch(error => {
        if (isEdit) {
          $notice.showError(`Failed to update area`);
        }
        else {
          $notice.showError(`Failed to add area`);
        }
        throw error;
      });
  };

  /**
   * Delete
   */
  this.delete = function($event) {

    //Get area and define handler
    const {area} = $event;
    const handler = function() {
      return $store.areas.delete(area);
    };

    //Open confirmation dialog
    $modal
      .open('basic', {
        templateUrl: 'admin/area/modals/confirm-delete-area.html',
        locals: {area, handler},
      })
      .result
      .then(() => {
        $notice.show(`Area removed`);
        this.onDeleted({area});
      });
  };

  /**
   * No-ops (to extend as needed)
   */
  this.onSaved = function() {};
  this.onDeleted = function() {};
});
