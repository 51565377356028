
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.ManageDirectoryFields.Card', [])

/**
 * Component
 */
.component('cardSettingsManageDirectoryFields', {
  templateUrl: 'admin/settings/cards/manage-directory-fields.html',
  controller: 'CardSettingsManageDirectoryFieldsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    fields: '<',
    onSave: '&',
    onSaveCustomField: '&',
  },
})

/**
 * Controller
 */
.controller('CardSettingsManageDirectoryFieldsCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Toggle field
   */
  this.toggleField = function(field, prop) {

    //Get model and determine new value
    const model = this.model.fields[field.prop];
    const value = !model[prop];

    //Modify field model
    this.modifyFieldModel(model, prop, value);

    //Make settings submodel
    const settings = {[this.type]: this.model};
    const hideNotice = true;

    //Save
    this
      .onSave({$event: {settings, hideNotice}})
      .catch(() => this.modifyFieldModel(model, prop, !value));
  };

  /**
   * Toggle custom field
   */
  this.toggleCustomField = function(field, prop) {

    //Determine new value
    const value = !field[prop];
    const model = field.extract(['inDirectory', 'inDirectoryByDefault']);

    //Modify field model
    this.modifyFieldModel(model, prop, value);
    Object.assign(field, model);

    //Save
    this
      .onSaveCustomField({$event: {field, model}})
      .catch(() => {
        this.modifyFieldModel(model, prop, !value);
        Object.assign(field, model);
      });
  };

  /**
   * Modify a field model
   */
  this.modifyFieldModel = function(model, prop, value) {

    //Set the new prop value
    model[prop] = value;

    //Get data
    const {inDirectory, inDirectoryByDefault} = model;

    //Can't be in directory by default if not in directory
    if (prop === 'inDirectory' && !inDirectory && inDirectoryByDefault) {
      model.inDirectoryByDefault = false;
    }

    //Must be in directory if in directory by default
    if (prop === 'inDirectoryByDefault' && inDirectoryByDefault && !inDirectory) {
      model.inDirectory = true;
    }
  };
});
