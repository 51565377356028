
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.Overview.Controller', [])

/**
 * Controller
 */
.controller('SubscriptionOverviewCtrl', function(
  $modal, $notice, $state, $store, $location, Subscription,
  Push
) {

  /**
   * Init
   */
  this.$onInit = function() {

    //Initialize
    this.subscriptions = [];

    //Create list of available members
    this.members = [this.user].concat(this.circleMembers);

    //Get initial member
    const member = this.determineInitialMember();

    //Set selected member
    this.setMember(member);

    //Mute subscriptions
    this.muteSubscriptions();
  };

  /**
   * Determine initial member
   */
  this.determineInitialMember = function() {

    //Check if valid circle member ID given in params
    const {member: memberId} = this.transition.params();
    if (memberId) {
      const member = this.circleMembers.find(m => m.id === memberId);
      if (member) {
        return member;
      }
    }

    //Return ourselves
    return this.user;
  };

  /**
   * Select member event handler
   */
  this.onSelectMember = function($event) {
    const {member} = $event;
    return this.setMember(member);
  };

  /**
   * Purchase new sub event handler
   */
  this.onPurchase = function() {
    return this.purchase();
  };

  /**
   * Set member
   */
  this.setMember = function(member) {
    this.member = member;
    this.loadSubs(member);
  };

  /**
   * Load subs
   */
  this.loadSubs = function(member) {

    //Flag as loading
    this.isLoadingSubs = true;

    //Create filter
    const filter = {};
    if (member !== this.user) {
      filter.circleMemberId = member.id;
    }

    //Query payments
    return Subscription
      .own(filter)
      .then(subscriptions => {
        this.subscriptions = subscriptions;
        if (subscriptions.length > 0 && member === this.user) {
          Push.ask(this.user, 'subRenewalReminder');
        }
      })
      .finally(() => this.isLoadingSubs = false);
  };

  /**
   * Purchase sub
   */
  this.purchase = function() {
    const {member} = this;
    return $state.go('subscription.purchase', {memberId: member.id});
  };

  /**
   * Renew
   */
  this.renew = function($event) {
    const {subscription} = $event;
    return $state.go('subscription.renew', {subId: subscription.id});
  };

  /**
   * Change
   */
  this.change = function($event) {
    const {subscription} = $event;
    return $state.go('subscription.change', {subId: subscription.id});
  };

  /**
   * Stop
   */
  this.stop = function($event) {
    const {subscription} = $event;
    return $state.go('subscription.stop', {subId: subscription.id});
  };

  /**
   * Toggle auto renewal
   */
  this.toggleAutoRenew = function($event) {
    const {subscription, autoRenews} = $event;
    return subscription.toggleAutoRenew(autoRenews);
  };

  /**
   * View subscription details
   */
  this.view = function($event) {

    //Get subscription
    const {subscription} = $event;
    const canViewMembers = this.user.hasRole('admin', 'viewer');

    //Show loading notice
    $notice.showLoading();

    //Load full subscription with full populated membership
    $store.subscriptions
      .findById(subscription.id, true)
      .then(subscription => {
        $modal.open('viewMembership', {locals: {subscription, canViewMembers}});
      })
      .finally(() => $notice.hideLoading());
  };

  /**
   * Mute subscriptions
   */
  this.muteSubscriptions = function() {

    //Get data
    const {mute} = $location.search();
    if (!mute) {
      return;
    }

    //Get subscriptions to mute
    const subscriptionId = (mute !== 'all') ? mute : null;
    const subscriptions = this.subscriptions
      .filter(sub => {
        if (subscriptionId) {
          return (sub.id === subscriptionId);
        }
        return (sub.isDue && !sub.isMuted);
      });

    //Any?
    if (subscriptions.length === 0) {
      return;
    }

    //Mute
    Subscription
      .mute(subscriptions)
      .then(() => {
        $modal.open('basic', {
          templateUrl: 'subscription/modals/muted.html',
          locals: {model: {subscriptions}},
        });
      });
  };
});
