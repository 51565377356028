
/**
 * Module definition and dependencies
 */
angular.module('App.Account.UseCredit.Card', [])

/**
 * Component
 */
.component('cardAccountUseCredit', {
  templateUrl: 'account/cards/use-credit.html',
  controller: 'CardAccountUseCreditCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    payment: '<',
    onNext: '&',
    onPrev: '&',
    onCancel: '&',
  },
})

/**
 * Controller
 */
.controller('CardAccountUseCreditCtrl', function() {

  /**
   * Previous step
   */
  this.prev = function() {
    this.onPrev();
  };

  /**
   * Next step
   */
  this.next = function() {
    this.onNext();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
