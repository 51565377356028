
/**
 * Module definition and dependencies
 */
angular.module('App.Public', [
  'App.Public.Event',
  'App.Public.Calendar',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('public', {
    url: '/public',
    abstract: true,
    component: 'publicRoute',
    data: {
      auth: false,
    },
    resolve: {
      club($store) {
        return $store.club.get();
      },
      isWide() {
        return true;
      },
    },
  });
})

/**
 * Route component
 */
.component('publicRoute', {
  templateUrl: 'public/public.html',
  controller: 'PortalCtrl',
  bindings: {
    club: '<',
    isWide: '<',
  },
});
