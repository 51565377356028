
/**
 * Module definition and dependencies
 */
angular.module('Shared.CommentsList.Post.Component', [])

/**
 * Comments item component
 */
.component('commentsListPost', {
  template: `
    <div class="CommentsListPost">
      <div class="CommentsListPost-avatar">
        <avatar
          size="m"
          avatar="$ctrl.user.avatar"
          color="$ctrl.user.color"
          text="$ctrl.user.initials"
        ></avatar>
      </div>
      <form name="$ctrl.form" novalidate class="CommentsListPost-form">
        <div class="CommentsListPost-input">
          <textarea class="Input" name="comment" placeholder="Add a new comment" ng-model="$ctrl.comment.text" rows="1" ng-maxlength="inputLimit.maxMultiLine" required autogrow max-rows="10"></textarea>
          <div class="InputHint" ng-show="$ctrl.form.$submitted" ng-messages="$ctrl.form.comment.$error">
            <p ng-message="required">Enter a comment</p>
            <p ng-message="maxlength">Maximum {{inputLimit.maxMultiLine}} characters</p>
          </div>
        </div>
        <button class="Button Button--info CommentsListPost-button" ng-click="$ctrl.save()" ng-disabled="$ctrl.isSaving">
          Add
          <spinner></spinner>
        </button>
      </form>
    </div>
  `,
  bindings: {
    user: '<',
    onSave: '&',
  },
  controller(Comment) {

    /**
     * On init
     */
    this.$onInit = function() {
      this.initModel();
    };

    /**
     * Create new comment
     */
    this.initModel = function() {
      this.comment = new Comment();
    };

    /**
     * Validate
     */
    this.validate = function() {
      this.form.$setSubmitted();
      return this.form.$valid;
    };

    /**
     * Save
     */
    this.save = function() {

      //Validate form
      if (!this.validate()) {
        return;
      }

      //Get comment
      const comment = this.comment;

      //Mark as saving
      this.isSaving = true;

      //Save
      this
        .onSave({$event: {comment}})
        .then(() => {
          this.form.$setPristine();
          this.initModel();
        })
        .finally(() => this.isSaving = false);
    };
  },
});
