
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.InterestOptions.Component', [])

/**
 * Interest options component
 */
.component('eventInterestOptions', {
  template: `
    <more-options>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    interest: '<',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Delete
     */
    this.delete = function() {
      const {interest} = this;
      this.onDelete({$event: {interest}});
    };
  },
});
