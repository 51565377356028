
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Add.Type.Card', [])

/**
 * Component
 */
.component('cardMembershipAddType', {
  templateUrl: 'admin/membership/cards/type.html',
  controller: 'CardMembershipAddTypeCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    membership: '<',
    memberships: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipAddTypeCtrl', function() {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;

    //Create model subset
    this.model = this.membership.extract([
      'primary', 'isLinked',
    ]);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Merge
   */
  this.merge = function() {

    //Get data
    const {membership, model} = this;

    //Linked?
    if (model.isLinked) {

      //Get ID only (if copying membership)
      if (typeof model.primary === 'object') {
        model.primary = model.primary.id;
      }

      //Find primary
      const id = model.primary;
      const primary = this.memberships.find(primary => primary.id === id);

      //Extract data to copy
      if (primary) {
        const {name, description} = primary;
        Object.assign(model, {name, description});
      }
      else {
        model.primary = null;
      }
    }
    else {
      model.primary = null;
    }

    //Merge data into instance and mark form as pristine
    this.onMerge({$event: {membership, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next card
    this.merge();
    this.card.next();
  };
});
