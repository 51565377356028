
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Log.Access.Controller', [])

/**
 * Controller
 */
.controller('AdminLogAccessCtrl', function(
  $filter, $modal, moment, ScrollPosition, Pagination, AccessLog,
  Intercom, Settings
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed access logs');

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Determine minimum date that logs can be viewed
    if (!this.user.isSuper()) {
      const {logVisibility} = this.club.permissions;
      this.minDate = moment().subtract(logVisibility || 1, 'months');
    }

    //Load logs
    this.loadPage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Enable search and set filters
    page.enableSearch();
    page.setController(this);
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
    page.addOption('refreshLogs');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter, system} = this;

    //Set filter defaults
    filter.setDefaults({
      search: '',
      member: null,
      fromDate: moment().startOf('day').subtract(7, 'days'),
      toDate: moment().endOf('day'),
    });

    //Filter on change handler
    filter.onChange(key => {

      //From date changed
      if (key === 'fromDate') {
        if (filter.fromDate.isAfter(filter.toDate)) {
          filter.toDate = filter.fromDate.clone().endOf('day');
        }
      }

      //To date changed
      else if (key === 'toDate') {
        if (filter.toDate.isBefore(filter.fromDate)) {
          filter.fromDate = filter.toDate.clone().startOf('day');
        }
      }

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      door: system.doors,
    };
  };

  /**
   * Load logs
   */
  this.loadLogs = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query logs
    return AccessLog
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, accessLogs} = data;

    //Set in scope
    this.logs = accessLogs;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadLogs()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };

  /**
   * Export logs
   */
  this.export = function() {

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club: this.club, action: 'Exporting access logs'},
      });
    }

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'access logs', action: 'export'},
      });
    }

    //Get filter and export
    const filter = this.makeFilter();
    return AccessLog.export(filter);
  };
});
