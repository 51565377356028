
/**
 * Module definition and dependencies
 */
angular.module('Shared.KeyDown.Service', [])

/**
 * Service definition
 */
.factory('KeyDown', function($document, $debounce) {

  /**
   * Key down service class
   */
  class KeyDown {

    /**
     * Constructor
     */
    constructor() {

      //Init
      this.listeners = [];
      this.buffer = '';

      //Create global listener bound to self
      this.boundHandler = this.handler.bind(this);
      this.debouncedClearBuffer = $debounce(this.clearBuffer, this, 100);
      this.debouncedNotifyListeners = $debounce(
        this.notifyListeners, this, 2500, true
      );
    }

    /**
     * Clear buffer
     */
    clearBuffer() {
      this.buffer = '';
    }

    /**
     * Append to buffer
     */
    appendBuffer(key) {
      this.buffer += key;
    }

    /**
     * Register a listener
     */
    addListener(listener) {

      //No listeners yet? Apply global listener
      if (this.listeners.length === 0) {
        $document.on('keydown', this.boundHandler);
      }

      //Push listener
      this.listeners.push(listener);
    }

    /**
     * Remove listener
     */
    removeListener(listener) {

      //Remove listener
      const i = this.listeners.indexOf(listener);
      if (i >= 0) {
        this.listeners.splice(i, 1);
      }

      //No listeners left? Remove global listener
      if (this.listeners.length === 0) {
        $document.off('keydown', this.boundHandler);
      }
    }

    /**
     * Handler for events
     */
    handler(event) {

      //Check if we have any listeners
      if (this.listeners.length === 0) {
        return;
      }

      //Clear buffer
      this.debouncedClearBuffer();

      //Get event data
      const {key, which, keyCode} = event;
      const isEnter = (which || keyCode) === 13;
      const isShift = (which || keyCode) === 16;

      //Shift, ignore but keep adding to buffer
      if (isShift) {
        return;
      }

      //Enter key, notify listeners
      if (isEnter) {
        return this.debouncedNotifyListeners(event, this.buffer);
      }

      //Keep appending to buffer
      this.appendBuffer(key);
    }

    /**
     * Notify listeners
     */
    notifyListeners(event, buffer) {

      //Run through listeners in reverse order
      for (let i = this.listeners.length - 1; i >= 0; i--) {
        if (this.listeners[i](buffer)) {
          event.preventDefault();
          break;
        }
      }
    }
  }

  //Return instance
  return new KeyDown();
});
