
/**
 * Module definition and dependencies
 */
angular.module('App.Account.PayForBooking.Card', [])

/**
 * Component
 */
.component('cardAccountPayForBooking', {
  templateUrl: 'account/cards/pay-for-booking.html',
  controller: 'CardAccountPayForBookingCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    booking: '<',
    bookings: '<',
    coupons: '<',
    user: '<',
    canPayWithCoupons: '<',
    onNext: '&',
    onCancel: '&',
    onUseCoupons: '&',
  },
})

/**
 * Controller
 */
.controller('CardAccountPayForBookingCtrl', function() {

  /**
   * Create coupon map
   */
  this.$onInit = function() {

    //Get data
    const {user, booking} = this;
    const {activity} = booking;

    //Flags
    this.canUseMoreCoupons = true;
    this.userNotInBooking = (!user || !booking.hasMember(user));

    //Sessions needed
    this.sessionsNeeded = this.bookings
      .reduce((num, booking) => num + booking.coupons.sessionsNeeded, 0);

    //Total fee
    this.totalFee = this.bookings
      .reduce((fee, booking) => fee + booking.fee.amount, 0);

    //Prepare coupon map
    this.couponMap = this.coupons
      .filter(coupon => coupon.getSessionsLeftForActivity(activity) > 0)
      .reduce((coupons, coupon) => {
        if (typeof coupons[coupon.member.id] === 'undefined') {
          coupons[coupon.member.id] = [];
        }
        coupons[coupon.member.id].push(coupon);
        return coupons;
      }, {});
  };

  /**
   * Toggle coupons usage
   */
  this.toggleCoupons = function(isUsingCoupons) {
    this.isUsingCoupons = isUsingCoupons;
    this.isErrorUsingCoupons = false;
  };

  /**
   * Use a coupon
   */
  this.useCoupon = function(coupon, isSelected) {

    //Get data
    const {booking, coupons, sessionsNeeded} = this;
    const {activity} = booking;

    //Mark as selected
    coupon.isSelected = isSelected;

    //Get selected coupons
    const selected = coupons.filter(coupon => coupon.isSelected);
    const numSelected = selected.length;

    //Count num sessions available on selected coupons
    const numAvailable = selected
      .map(coupon => coupon.getSessionsLeftForActivity(activity))
      .reduce((total, num) => num + total, 0);

    //Determine flags
    this.canUseMoreCoupons = numSelected < sessionsNeeded;
    this.canUseOtherCoupons = numSelected < coupons.length;
    this.shouldUseMoreCoupons = numAvailable < sessionsNeeded;
    this.mustUseMoreCoupons = !booking.enoughCouponSessions(selected);
    this.isUsingCoupons = numSelected > 0;
  };

  /**
   * Use coupon
   */
  this.useCoupons = function() {

    //Clear errors
    this.isErrorUsingCoupons = false;

    //Get coupons to use
    const coupons = this.coupons
      .filter(coupon => coupon.isSelected)
      .map(coupon => coupon.id);

    //No coupons, couldn't have gotten in this method
    if (coupons.length === 0) {
      return;
    }

    //Toggle flag
    this.isPaying = true;

    //Use coupons
    return this
      .onUseCoupons({$event: {coupons}})
      .catch(error => {
        this.error = error;
        this.isErrorUsingCoupons = true;
      })
      .finally(() => this.isPaying = false);
  };

  /**
   * Next step
   */
  this.next = function() {

    //Using coupons? Pay now, then see what's left to pay
    if (this.isUsingCoupons) {
      return this
        .useCoupons()
        .then(() => {

          //Clear flags
          this.isUsingCoupons = false;
          this.shouldUseMoreCoupons = false;
          this.mustUseMoreCoupons = false;

          //Any bookings left to pay for?
          if (this.bookings.length > 0) {
            this.onNext();
          }
        });
    }

    //Go to payment step
    this.onNext();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
