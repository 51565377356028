
/**
 * Module definition and dependencies
 */
angular.module('Shared.BookingView.Current.Component', [])

/**
 * Booking details component
 */
.component('bookingViewCurrent', {
  templateUrl: 'shared/booking-view/current.html',
  bindings: {
    area: '<',
    mode: '<',
    members: '<',
  },
});
