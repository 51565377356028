
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.AccountCredit.Card', [])

/**
 * Component
 */
.component('cardSettingsAccountCredit', {
  templateUrl: 'admin/settings/cards/account-credit.html',
  controller: 'CardSettingsAccountCreditCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSettingsAccountCreditCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'accountCredit';

    //Call parent init
    $base.$onInit.call(this);
  };
});
