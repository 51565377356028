
/**
 * Module definition and dependencies
 */
angular.module('Shared.AutoDoorsOpen.Options', [])

/**
 * Constant definition
 */
.constant('AutoDoorsOpenOptions', [
  {
    label: '1 hour before the event',
    value: '-60:startDate',
  },
  {
    label: '45 minutes before the event',
    value: '-45:startDate',
  },
  {
    label: '30 minutes before the event',
    value: '-30:startDate',
  },
  {
    label: '15 minutes before the event',
    value: '-15:startDate',
  },
  {
    label: '10 minutes before the event',
    value: '-10:startDate',
  },
  {
    label: '5 minutes before the event',
    value: '-5:startDate',
  },
  {
    label: 'At the start of the event',
    value: '0:startDate',
  },
  {
    label: '5 minutes after start of event',
    value: '5:startDate',
  },
  {
    label: '10 minutes after start of event',
    value: '10:startDate',
  },
  {
    label: '15 minutes after start of event',
    value: '15:startDate',
  },
  {
    label: '30 minutes after start of event',
    value: '30:startDate',
  },
  {
    label: '45 minutes after start of event',
    value: '45:startDate',
  },
  {
    label: '1 hour after start of event',
    value: '60:startDate',
  },
]);
