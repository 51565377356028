
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.POLi.Status.Card', [])

/**
 * Component
 */
.component('cardIntegrationsPoliStatus', {
  templateUrl: 'admin/integrations/poli/status.html',
  controller: 'CardIntegrationsPoliStatusCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsPoliStatusCtrl', function(
  $controller
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get data
    const {data} = this.integration;

    //Flags
    this.hasStrategy = !!data.cover;
    this.isSetupComplete = (this.hasStrategy);
  };
});
