
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Log.Activity', [
  'App.Admin.Log.Activity.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.log.activity', {
    url: '/activity',
    component: 'adminLogActivityRoute',
    data: {
      id: 'activityLogs',
    },
    params: {
      booking: {
        value: null,
      },
    },
    resolve: {
      areas($store) {
        return $store.areas.query();
      },
      filter(Filters) {
        return Filters.get('activityLogs');
      },
      modes(activities) {
        return activities.reduce((modes, activity) => {
          return modes.concat(activity.modes);
        }, []);
      },
    },
  });
})

/**
 * Route component
 */
.component('adminLogActivityRoute', {
  controller: 'AdminLogActivityCtrl',
  templateUrl: 'admin/log/activity/activity.html',
  bindings: {
    club: '<',
    user: '<',
    filter: '<',
    page: '<',
    system: '<',
    areas: '<',
    activities: '<',
    modes: '<',
  },
});
