
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.Xero.Settings.Card', [])

/**
 * Component
 */
.component('cardIntegrationsXeroSettings', {
  templateUrl: 'admin/integrations/xero/settings.html',
  controller: 'CardIntegrationsXeroSettingsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsXeroSettingsCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Options for invoice frequency settings
    this.invoiceFrequencyOptions = [
      {
        value: 'day',
        label: 'Daily',
      },
      {
        value: 'week',
        label: 'Weekly',
      },
      {
        value: 'month',
        label: 'Monthly',
      },
    ];

    //Sync type options
    this.syncTypeOptions = [
      {
        value: 'individual',
        label: 'Individual invoices',
      },
      {
        value: 'summary',
        label: 'Summary invoices',
      },
    ];

    //No sync type set
    if (typeof this.data.syncType === 'undefined') {
      this.data.syncType = 'individual';
    }

    //No invoice frequency yet?
    if (!this.data.invoiceFrequency) {
      this.data.invoiceFrequency = 'week';
    }
  };

  /**
   * Save and back to status tab
   */
  this.save = function() {

    //If creating individual invoices, use day frequency
    if (this.data.syncType === 'individual') {
      this.data.invoiceFrequency = 'day';
    }

    //Parent method
    return $base.save.call(this);
  };
});
