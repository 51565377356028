
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Welcome.Credentials', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('welcome.credentials', {
    url: '/credentials',
    component: 'welcomeCredentialsRoute',
  });
})

/**
 * Route component
 */
.component('welcomeCredentialsRoute', {
  controller: 'WelcomeCredentialsCtrl',
  templateUrl: 'portal/welcome/steps/credentials.html',
  bindings: {
    club: '<',
    welcome: '<',
  },
})

/**
 * Controller
 */
.controller('WelcomeCredentialsCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('WelcomeBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.setup = function() {

    //Set initial username
    this.initialUsername = this.user.username;
  };
});
