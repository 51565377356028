
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.LoginCredentials', [
  'App.Portal.LoginCredentials.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('loginCredentials', {
    parent: 'portal',
    url: '/login/credentials',
    component: 'loginCredentialsRoute',
  });
})

/**
 * Route component
 */
.component('loginCredentialsRoute', {
  controller: 'LoginCredentialsCtrl',
  templateUrl: 'portal/login-credentials/login-credentials.html',
  bindings: {
    transition: '<',
    club: '<',
  },
})

/**
 * Run logic
 */
.run(function($transitions, $log, Auth, Page) {

  /**
   * Prevent going to login page if authenticated
   */
  $transitions.onBefore({to: 'loginCredentials'}, transition => {
    if (Auth.isAuthenticated()) {
      $log.log(`Already logged in`);
      return transition.router.stateService.target('home');
    }
  });

  /**
   * Success handler
   */
  $transitions.onSuccess({to: 'loginCredentials'}, () => {
    Page.setTitle('Sign in');
  });
});
