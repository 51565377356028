
/**
 * Module definition and dependencies
 */
angular.module('Shared.Toggle.Filter', [])

/**
 * Filter definition
 */
.filter('toggle', function() {
  return function(bool) {
    return bool ?
      '<i class="Icon Icon--l color-success">toggle_on</i>' :
      '<i class="Icon Icon--l color-faded">toggle_off</i>';
  };
});
