
/**
 * Module definition and dependencies
 */
angular.module('Shared.Filters.Service', [])

/**
 * Service definition
 */
.factory('Filters', function(moment) {

  //Save defaults
  let defaults = {};

  /**
   * Filter mini service
   */
  function Filter(type) {

    //Options property
    Object.defineProperty(this, 'options', {
      writable: true,
    });

    //Has defaults property
    Object.defineProperty(this, 'hasDefaults', {
      writable: true,
    });

    //Save type and initialize on change handlers
    this._type = type;
    this._onChangeHandlers = [];
  }

  /**
   * Get defaults
   */
  Filter.prototype.getDefaults = function() {
    return defaults[this._type];
  };

  /**
   * Set multiple default values
   */
  Filter.prototype.setDefaults = function(values, overwrite = false) {

    //Don't overwrite if already set
    if (defaults[this._type] && !overwrite) {
      return;
    }

    //Initialize
    defaults[this._type] = defaults[this._type] || {};

    //Set default values
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        defaults[this._type][key] = values[key];
      }
    }

    //Reset now
    this.reset();
  };

  /**
   * Change or set a default value
   */
  Filter.prototype.setDefault = function(key, value) {
    defaults[this._type][key] = value;
  };

  /**
   * Ensure a key is present in default values, if not, add it with null value
   */
  Filter.prototype.ensureInDefaults = function(key) {
    if (!defaults[this._type]) {
      throw new Error(`Defaults of type ${this._type} not set up yet`);
    }
    if (typeof defaults[this._type][key] === 'undefined') {
      this.setDefault(key, null);
    }
  };

  /**
   * Check if has all default values set
   */
  Filter.prototype.checkHasDefaults = function() {
    for (const key in defaults[this._type]) {
      if (defaults[this._type].hasOwnProperty(key)) {
        if (moment.isMoment(this[key])) {
          if (!this[key].isSame(defaults[this._type][key])) {
            return (this.hasDefaults = false);
          }
        }
        else if (defaults[this._type][key] !== this[key]) {
          return (this.hasDefaults = false);
        }
      }
    }
    return (this.hasDefaults = true);
  };

  /**
   * Resetter
   */
  Filter.prototype.reset = function(silent) {
    angular.forEach(defaults[this._type], (value, key) => {
      if (moment.isMoment(value)) {
        this[key] = value.clone();
      }
      else {
        this[key] = value;
      }
    });
    this.checkHasDefaults();
    this.triggerChange(null, null, silent);
  };

  /**
   * Updater
   */
  Filter.prototype.update = function(key, value, silent, forceChange = false) {
    const isChanged = (this[key] !== value);
    this[key] = value;
    if (isChanged || forceChange) {
      this.wasUpdated(key, silent);
    }
  };

  /**
   * Indicate an update happened
   */
  Filter.prototype.wasUpdated = function(key, silent) {
    const value = this[key];
    this.ensureInDefaults(key);
    this.checkHasDefaults();
    this.triggerChange(key, value, silent);
  };

  /**
   * Map options
   */
  Filter.prototype.mapOptions = function(key, value, valueKey = null) {

    //Get options for given key
    const options = this.options[key];

    //Find default option and prepare base
    const def = options.find(option => option.isDefault);
    const base = def ? def.filter : {};

    //Find option, either using value key or index
    const option = valueKey ?
      options.find(o => o[valueKey] === value) :
      options[value];

    //Create new values
    const values = Object.assign({}, base, option.filter);

    //Update all silently
    angular.forEach(values, (value, key) => {
      this.update(key, value, true);
    });
  };

  /**
   * Add on change handler
   */
  Filter.prototype.onChange = function(handler) {
    if (this._onChangeHandlers.indexOf(handler) === -1) {
      this._onChangeHandlers.push(handler);
    }
  };

  /**
   * Remove on change handler (last one if none given)
   */
  Filter.prototype.offChange = function(handler) {
    if (handler) {
      const i = this._onChangeHandlers.indexOf(handler);
      if (i > -1) {
        this._onChangeHandlers.splice(i, 1);
      }
    }
    else if (this._onChangeHandlers.length > 0) {
      this._onChangeHandlers.pop();
    }
  };

  /**
   * Trigger change handlers
   */
  Filter.prototype.triggerChange = function(key, value, silent) {
    if (silent || this._onChangeHandlers.length === 0) {
      return;
    }
    this._onChangeHandlers.forEach(handler => {
      handler(key, value);
    });
  };

  /**
   * To JSON converter
   */
  Filter.prototype.toJSON = function(data) {

    //Initialize json
    const json = (data && typeof data === 'object') ? data : {};

    //Process properties
    for (const key in this) {

      //Check type
      const isUndefined = (typeof this[key] === 'undefined');
      const isFunction = (typeof this[key] === 'function');
      const isObject = (typeof this[key] === 'object');
      const isPrivate = (key[0] === '_');

      //Ignore if invalid
      if (!this.hasOwnProperty(key) || isFunction || isPrivate || isUndefined) {
        continue;
      }

      //Ignore null values
      if (this[key] === null) {
        continue;
      }

      //Process
      if (isObject && typeof this[key].toJSON === 'function') {
        json[key] = this[key].toJSON();
      }
      else {
        json[key] = this[key];
      }
    }
    return json;
  };

  /**
   * Wrapper service
   */
  const Filters = {

    /**
     * Get a type of filter, initializes if not set
     */
    get(type) {
      if (!this[type]) {
        this[type] = new Filter(type);
      }
      return this[type];
    },

    /**
     * Reset all filters
     */
    reset() {
      for (const type in this) {
        if (this[type] instanceof Filter) {
          this[type].reset();
        }
      }
    },
  };

  //Export
  return Filters;
});
