
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Coupons.Card', [
  'App.Admin.People.Members.CouponOptions.Component',
])

/**
 * Component
 */
.component('cardMemberCoupons', {
  templateUrl: 'admin/people/members/cards/coupons.html',
  controller: 'CardMemberCouponsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    member: '<',
    activities: '<',
    couponTypes: '<',
    coupons: '<',
  },
})

/**
 * Controller
 */
.controller('CardMemberCouponsCtrl', function(
  $modal, $notice, $storage, moment, Coupon, Modules
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize
    this.hasCoupons = this.coupons.length > 0;
  };

  /**
   * On added handler
   */
  this.onAdded = function($event) {

    //Get new coupon
    const {coupon} = $event;

    //Add to array
    this.coupons.unshift(coupon);
    this.hasCoupons = true;
  };

  /**
   * On deleted handler
   */
  this.onDeleted = function($event) {

    //Get coupon and find the index
    const {coupon} = $event;
    const index = this.coupons.findIndex(s => s.id === coupon.id);

    //Splice
    if (index > -1) {
      this.coupons.splice(index, 1);
      this.hasCoupons = this.coupons.length > 0;
    }
  };

  /**
   * Mark coupon as used
   */
  this.useSessions = function(coupon, $event) {

    //Get data and define handler
    const {num, hasModifier} = $event;
    const handler = data => coupon.useSessions(data);

    //If clicked with modifier, skip confirmation modal
    if (hasModifier) {

      //Get defaults
      const date = moment();
      const description = $storage
        .get('coupon.sessionDescription', 'Ticked off by administrator');

      //Run handler
      return handler({description, date, num});
    }

    //Open modal
    $modal.open('useCouponSession', {
      locals: {coupon, num, handler},
    });
  };

  /**
   * View coupon
   */
  this.view = function(coupon) {
    $modal.open('viewCoupon', {
      locals: {coupon, canRefund: true},
    });
  };

  /**
   * Add coupon
   */
  this.add = function() {

    //No coupon types
    if (!this.couponTypes.length) {
      $modal.open('basic', {
        templateUrl: 'admin/coupon-type/modals/no-coupon-types.html',
      });
      return;
    }

    //Prepare new coupon
    const {member, couponTypes} = this;
    const coupon = new Coupon({member});
    const {singular} = Modules.find('coupons');

    //Define handler
    const handler = function(data, meta) {
      return coupon
        .save(data, meta)
        .then(() => {
          if (data.transaction && data.transaction.create) {
            member.amountOwing += data.transaction.amount;
          }
        });
    };

    //Open modal
    $modal
      .open('addCoupon', {
        locals: {member, coupon, couponTypes, handler},
      })
      .result
      .then(() => {
        $notice.show(`${singular} added`);
        this.onAdded({coupon});
      });
  };

  /**
   * Remove coupon
   */
  this.delete = function($event) {

    //Get coupon
    const {coupon} = $event;
    const {singular} = Modules.find('coupons');

    //Define handler
    const handler = function() {
      return coupon.delete();
    };

    //Open modal
    $modal
      .open('basic', {
        templateUrl: `admin/people/members/modals/confirm-delete-coupon.html`,
        locals: {coupon, handler},
      })
      .result
      .then(() => {
        $notice.show(`${singular} removed`);
        this.onDeleted({coupon});
      });
  };

  /**
   * Toggle active
   */
  this.toggleActive = function($event) {

    //Get coupon and flag
    const {coupon, isActive} = $event;
    const {singular} = Modules.find('coupons');

    //Patch
    return coupon
      .patch({isActive})
      .then(() => {
        $notice.show(isActive ? `${singular} activated` : `${singular} deactivated`);
      });
  };

  /**
   * Update expiry
   */
  this.updateExpiry = function($event) {

    //Get data
    const {couponTypes} = this;
    const {coupon} = $event;
    const {singular} = Modules.find('coupons');

    //Define handler
    const handler = function(data) {
      const {expiryDate} = data;
      return coupon
        .patch({expiryDate});
    };

    //Find coupon type
    const couponType = couponTypes
      .filter(type => type.id === coupon.type);

    //Open modal
    $modal
    .open('updateCouponExpiry', {
      locals: {coupon, couponType, handler},
    })
    .result
    .then(() => {
      $notice.show(`${singular} updated`);
    });
  };
});
