
/**
 * Module definition and dependencies
 */
angular.module('App.Auth', [
  'App.Auth.Service',
  'App.Auth.Token.Model',
  'App.Auth.AuthInterceptor.Service',
])

/**
 * Config
 */
.config(($httpProvider) => {

  //Add auth interceptor (must be before the error interceptor)
  $httpProvider.interceptors.unshift('AuthInterceptor');
})

/**
 * Run logic
 */
.run(($log, $transitions, Auth, Kiosk, Domain) => {

  /**
   * Authentication guard
   */
  $transitions.onBefore({
    to(state) {
      return (state.data && state.data.auth === true);
    },
  }, transition => {

    //Get data
    const to = transition.to();
    const target = transition.targetState();

    //Not authenticated?
    if (!Auth.isAuthenticated()) {
      $log.log('State', to.name, 'requires authentication.');
      return Auth
        .refresh()
        .then(() => target)
        .catch(() => {
          if (Kiosk.isEnabled() && !Domain.isGeneric) {
            const state = Kiosk.getDefaultPage();
            return transition.router.stateService.target(state);
          }
          Auth.goToLoginState(target);
          return false; //Cancel transition so we don't run the other hooks
        });
    }
  }, {priority: 100});

  /**
   * Remember last successful transition to take you back after you've logged out
   */
  $transitions.onSuccess({}, transition => {

    //This method checks if it's ok to remember the last state or not
    Auth.setLastState(transition.targetState());
  });
});
