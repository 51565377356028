
/**
 * Module definition and dependencies
 */
angular.module('App.OAuth.Callback', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('oAuthCallback', {
    url: '/oauth/callback',
    component: 'oAuthCallbackRoute',
    data: {
      auth: false,
    },
  });
})

/**
 * Route component
 */
.component('oAuthCallbackRoute', {
  template: `
    <div class="Preloader"><img src="/logo-animated.gif" alt=""><span>Loading...</span></div>
  `,
  controller: 'oAuthCallbackCtrl',
})

/**
 * Controller
 */
.controller('oAuthCallbackCtrl', function($location, $state, Auth) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Get query params
    const query = $location.search();
    const {
      access_token: accessToken, action, error, provider, workspace, reason,
    } = query;

    //Error
    if (error) {
      if (action === 'register') {
        return $state.go(`register.oauth`, {error, provider, workspace, reason});
      }
      if (action === 'welcome') {
        return $state.go(`welcome.oauth`, {error, provider, workspace, reason});
      }
      if (action === 'signup') {
        return $state.go(`signupClub.oauth`, {error, provider, reason});
      }
      else {
        return $state.go(`login`, {error, provider, workspace, reason});
      }
    }

    //Got access token, login
    if (accessToken) {

      //Connecting existing user? Go to profile
      if (action === 'connect') {
        Auth.setRedirectState({
          name: 'profile',
          params: {openCard: 'credentials'},
        });
      }

      //Just logging in
      return Auth.loginWithToken(accessToken);
    }

    //Extract more data from query, and assume profile data is present there
    const {club, token} = query;
    const profile = query;

    //Action given, profile data from oAuth registration/welcome flow
    if (action === 'register') {
      return $state.go(`register.details`, {profile});
    }
    if (action === 'welcome') {
      return $state.go(`welcome.details`, {profile, club, token});
    }
    if (action === 'signup') {
      return $state.go(`signupClub.type`, {profile});
    }

    //All other cases, go back to login state
    Auth.goToLoginState();
  };
});
