
/**
 * Module definition and dependencies
 */
angular.module('Shared.MembershipNotifications.Options', [])

/**
 * Constant definition
 */
.constant('MembershipNotifications', [
  {
    value: false,
    label: 'All',
  },
  {
    value: true,
    label: 'Specific memberships',
  },
]);
