
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.UseCouponSession.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('useCouponSession', {
    templateUrl: 'coupon/modals/use-coupon-session.html',
    controller: 'ModalUseCouponSessionCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalUseCouponSessionCtrl', function(
  $controller, $focus, $storage, $modalInstance, moment
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get data
    const {num, coupon} = this;
    const {numSessionsLeft} = coupon;
    const date = moment();
    const description = $storage.get('coupon.sessionDescription');

    //Sessions used
    this.sessionsUsed = [];
    for (let i = 1; i <= numSessionsLeft; i++) {
      this.sessionsUsed.push(i);
    }

    //Initialize model
    this.model = {num, date, description};
    this.today = moment();

    //Set default activity
    if (coupon.activities.length > 0) {
      this.model.activity = coupon.activities[0].id;
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    $focus('description');
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Clear error
    this.error = null;

    //Validate
    if (!this.validate()) {
      return;
    }

    //Save
    this.isSaving = true;
    this
      .handler(this.model)
      .then(result => {
        $storage.set('coupon.sessionDescription', this.model.description);
        $modalInstance.resolve(result);
      })
      .catch(error => this.error = error)
      .finally(() => this.isSaving = false);
  };
});
