
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area.Overview', [
  'App.Admin.Area.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.area.overview', {
    url: '',
    component: 'adminAreaRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('areas');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminAreaRoute', {
  controller: 'AdminAreaOverviewCtrl',
  templateUrl: 'admin/area/overview/overview.html',
  bindings: {
    filter: '<',
    page: '<',
    user: '<',
    club: '<',
    system: '<',
    activities: '<',
    module: '<',
  },
});
