
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.ChangeDetails.Card', [])

/**
 * Component
 */
.component('cardSubscriptionChangeDetails', {
  templateUrl: 'subscription/cards/change-details.html',
  require: {
    card: '^^',
  },
  bindings: {
    meta: '<',
    membership: '<',
    subscription: '<',
    old: '<',
    onNext: '&',
    onPrev: '&',
    onCancel: '&',
    onChange: '&',
  },
  controller() {

    /**
     * On init
     */
    this.$onInit = function() {

      //Cancelled subs
      this.cancelled = [];

      //Determine who will have their subscription cancelled
      for (const sub of this.old.linked) {

        //Do we have a new sub for this member?
        const hasNew = this.subscription.linked
          .some(newSub => newSub.member.id === sub.member.id);

        //No new sub, so cancelled
        if (!hasNew) {
          this.cancelled.push(sub.member);
        }
      }
    };

    /**
     * Previous step
     */
    this.prev = function() {
      this.onPrev();
    };

    /**
     * Next step
     */
    this.next = function() {
      this.onNext();
    };

    /**
     * Cancel flow
     */
    this.cancel = function() {
      this.onCancel();
    };

    /**
     * Change membership
     */
    this.change = function() {
      this.onChange();
    };
  },
});
