
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.CheckInRules.Card', [
  'App.Admin.Settings.CheckInRuleOptions.Component',
])

/**
 * Component
 */
.component('cardCheckInRules', {
  templateUrl: 'admin/settings/cards/check-in-rules.html',
  controller: 'CardCheckInRulesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    memberships: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardCheckInRulesCtrl', function($controller, $modal, CheckInRule) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'checkInRules';

    //Call parent init
    $base.$onInit.call(this);
  };

  /**
   * Add access entry
   */
  this.add = function() {

    //Create rule and define handler
    const {memberships} = this;
    const rule = new CheckInRule();
    const handler = data => {
      this.model.push(data);
      return this.save();
    };

    //Show modal
    $modal
      .open('editCheckInRule', {
        locals: {rule, memberships, handler},
      })
      .result
      .then(() => this.checkFlags());
  };

  /**
   * Edit rule
   */
  this.edit = function($event) {

    //Get rule
    const rule = new CheckInRule($event.rule);
    const {memberships} = this;
    const handler = data => {
      Object.assign($event.rule, data);
      return this.save();
    };

    //Show modal
    $modal
      .open('editCheckInRule', {
        locals: {
          rule, memberships, handler,
        },
      });
  };

  /**
   * Delete rule
   */
  this.delete = function($event) {

    //Get rule
    const {rule} = $event;

    //Find index
    const index = this.model.findIndex(r => r === rule);
    if (index === -1) {
      return;
    }

    //Define handler
    const handler = () => {
      this.model.splice(index, 1);
      return this.save();
    };

    //Show confirmation
    $modal
      .open('basic', {
        templateUrl: 'admin/settings/modals/remove-check-in-rule.html',
        locals: {rule, handler},
      });
  };
});
