
/**
 * Module definition and dependencies
 */
angular.module('Shared.Subscription.Ref.Model', [])

/**
 * Model definition
 */
.factory('RefSubscription', function($baseModel) {

  /**
   * Constructor
   */
  function RefSubscription(data) {
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(RefSubscription.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  RefSubscription.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('member', 'Member');
    this.convertToModel('membership', 'Membership');

    //Return self
    return this;
  };

  //Return
  return RefSubscription;
});
