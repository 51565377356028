
/**
 * Module definition and dependencies
 */
angular.module('Shared.XeroAccounts.Options', [])

/**
 * Constant definition
 */
.constant('XeroAccounts', [
  {
    type: 'transactionTypes',
    group: 'Revenue accounts',
    value: 'subscription',
    label: 'Membership fee',
    data: {
      code: '210',
      name: 'Membership fees',
      type: 'REVENUE',
      typeRegex: /REVENUE|SALES|OTHERINCOME|CURRLIAB/,
      description: 'Income from membership subscriptions',
    },
  },
  {
    type: 'transactionTypes',
    group: 'Revenue accounts',
    value: 'coupon',
    label: 'Coupon purchase',
    data: {
      code: '211',
      name: 'Coupon purchases',
      type: 'REVENUE',
      typeRegex: /REVENUE|SALES|OTHERINCOME|CURRLIAB/,
      description: 'Income from coupon purchases',
    },
  },
  {
    type: 'transactionTypes',
    group: 'Revenue accounts',
    value: 'booking',
    label: 'Booking fee',
    data: {
      code: '212',
      name: 'Booking fees',
      type: 'REVENUE',
      typeRegex: /REVENUE|SALES|OTHERINCOME|CURRLIAB/,
      description: 'Income from booking fees',
    },
  },
  {
    type: 'transactionTypes',
    group: 'Revenue accounts',
    value: 'activity',
    label: 'Activity fee',
    data: {
      code: '213',
      name: 'Activity fees',
      type: 'REVENUE',
      typeRegex: /REVENUE|SALES|OTHERINCOME|CURRLIAB/,
      description: 'Income from activity fees',
    },
  },
  {
    type: 'transactionTypes',
    group: 'Revenue accounts',
    value: 'resource',
    label: 'Resource fee',
    data: {
      code: '214',
      name: 'Resource fees',
      type: 'REVENUE',
      typeRegex: /REVENUE|SALES|OTHERINCOME|CURRLIAB/,
      description: 'Income from resource rental fees',
    },
  },
  {
    type: 'transactionTypes',
    group: 'Revenue accounts',
    value: 'event',
    label: 'Event entry fee',
    data: {
      code: '220',
      name: 'Event entry fees',
      type: 'REVENUE',
      typeRegex: /REVENUE|SALES|OTHERINCOME|CURRLIAB/,
      description: 'Income from event entry fees',
    },
  },
  {
    type: 'transactionTypes',
    group: 'Revenue accounts',
    value: 'interclub',
    label: 'Interclub fee',
    data: {
      code: '220',
      name: 'Event entry fees',
      type: 'REVENUE',
      typeRegex: /REVENUE|SALES|OTHERINCOME|CURRLIAB/,
      description: 'Income from event entry fees',
    },
  },
  {
    type: 'transactionTypes',
    group: 'Revenue accounts',
    value: 'purchase',
    label: 'Purchase',
    data: {
      code: '200',
      name: 'Sales',
      type: 'REVENUE',
      typeRegex: /REVENUE|OTHERINCOME/,
      description: 'Income from sales of goods',
    },
  },
  {
    type: 'transactionTypes',
    group: 'Revenue accounts',
    value: 'other',
    label: 'Other',
    data: {
      code: '260',
      name: 'Other revenue',
      type: 'REVENUE',
      typeRegex: /REVENUE|SALES|OTHERINCOME|CURRLIAB/,
      description: 'Other revenue',
    },
  },
  {
    type: 'transactionTypes',
    group: 'Liability accounts',
    value: 'deposit',
    label: 'Deposit',
    data: {
      code: '810',
      name: 'Deposits',
      type: 'CURRLIAB',
      typeRegex: /CURRLIAB/,
      paymentsEnabled: true,
      description: 'Temporary deposits',
    },
  },
  {
    type: 'transactionTypes',
    group: 'Liability accounts',
    value: 'accountCredit',
    label: 'Account credit',
    data: {
      code: '811',
      name: 'Account credit',
      type: 'CURRLIAB',
      typeRegex: /CURRLIAB/,
      paymentsEnabled: true,
      description: 'Account credit',
    },
  },
  {
    type: 'paymentMethods',
    group: 'Clearing accounts for received payments',
    value: 'stripe',
    label: 'Hello Club (Stripe)',
    isClearing: true,
    data: {
      code: '650',
      name: 'Online payments (Stripe)',
      type: 'CURRENT',
      typeRegex: /CURRENT/,
      paymentsEnabled: true,
      description: 'Stripe card payments processed through Hello Club',
    },
  },
  {
    type: 'paymentMethods',
    group: 'Clearing accounts for received payments',
    value: 'poli',
    label: 'Hello Club (POLi)',
    isClearing: true,
    data: {
      code: '651',
      name: 'POLi payments',
      type: 'CURRENT',
      typeRegex: /CURRENT/,
      paymentsEnabled: true,
      description: 'POLi payments processed through Hello Club',
    },
  },
  {
    type: 'paymentMethods',
    group: 'Clearing accounts for received payments',
    value: 'bankTransfer',
    label: 'Bank transfer',
    isClearing: true,
    data: {
      code: '660',
      name: 'Bank transfers',
      type: 'CURRENT',
      typeRegex: /CURRENT/,
      paymentsEnabled: true,
      description: 'Bank transfer payments clearing account',
    },
  },
  {
    type: 'paymentMethods',
    group: 'Clearing accounts for received payments',
    value: 'cash',
    label: 'Cash',
    isClearing: true,
    data: {
      code: '661',
      name: 'Cash payments',
      type: 'CURRENT',
      typeRegex: /CURRENT/,
      paymentsEnabled: true,
      description: 'Cash payments clearing account',
    },
  },
  {
    type: 'paymentMethods',
    group: 'Clearing accounts for received payments',
    value: 'cheque',
    label: 'Cheque',
    isClearing: true,
    data: {
      code: '662',
      name: 'Cheque payments',
      type: 'CURRENT',
      typeRegex: /CURRENT/,
      paymentsEnabled: true,
      description: 'Cheque payments clearing account',
    },
  },
  {
    type: 'paymentMethods',
    group: 'Clearing accounts for received payments',
    value: 'creditCard',
    label: 'Credit card',
    isClearing: true,
    data: {
      code: '663',
      name: 'Credit card payments',
      type: 'CURRENT',
      typeRegex: /CURRENT/,
      paymentsEnabled: true,
      description: 'Credit card payments clearing account',
    },
  },
  {
    type: 'paymentMethods',
    group: 'Clearing accounts for received payments',
    value: 'directDebit',
    label: 'Direct debit',
    isClearing: true,
    data: {
      code: '664',
      name: 'Direct debit payments',
      type: 'CURRENT',
      typeRegex: /CURRENT/,
      paymentsEnabled: true,
      description: 'Direct debit payments clearing account',
    },
  },
  {
    type: 'paymentMethods',
    group: 'Clearing accounts for received payments',
    value: 'eftpos',
    label: 'EFTPOS',
    isClearing: true,
    data: {
      code: '665',
      name: 'EFTPOS payments',
      type: 'CURRENT',
      typeRegex: /CURRENT/,
      paymentsEnabled: true,
      description: 'EFTPOS payments clearing account',
    },
  },
  {
    type: 'paymentMethods',
    group: 'Clearing accounts for received payments',
    value: 'other',
    label: 'Other',
    isClearing: true,
    data: {
      code: '666',
      name: 'Other payments',
      type: 'CURRENT',
      typeRegex: /CURRENT/,
      paymentsEnabled: true,
      description: 'Other payments clearing account',
    },
  },
  {
    type: 'expenses',
    group: 'Expense/overhead accounts',
    value: 'paymentProcessingFees',
    label: 'Payment fees',
    data: {
      code: '410',
      name: 'Payment fees',
      type: 'OVERHEADS',
      typeRegex: /EXPENSE|OVERHEADS/,
      description: `Payment processing fees on online payments processed through Hello Club`,
    },
  },
  {
    type: 'expenses',
    group: 'Expense/overhead accounts',
    value: 'paymentProcessingFeesTaxExempt',
    label: 'Payment fees (tax exempt)',
    data: {
      code: '411',
      name: 'Payment fees (tax exempt)',
      taxExempt: true,
      type: 'OVERHEADS',
      typeRegex: /EXPENSE|OVERHEADS/,
      description: `Payment processing fees on online payments processed through Hello Club that are tax exempt (e.g. no GST/VAT)`,
    },
  },
]);
