
/**
 * Module definition and dependencies
 */
angular.module('Shared.Alert.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('alert', {
    model: 'Alert',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'alerts',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('alerts', {
    model: 'Alert',
    dataKey: 'alerts',
    cacheEmpty: true,
  });
})

/**
 * Model definition
 */
.factory('Alert', function($api, $baseModel) {

  //Defaults
  const defaults = {
    title: '',
    message: '',
    type: null,
    startDate: null,
    endDate: null,
  };

  /**
   * Constructor
   */
  function Alert(data) {
    $baseModel.call(this, angular.extend({}, defaults, data || {}));
  }

  /**
   * Extend base model
   */
  angular.extend(Alert.prototype, $baseModel.prototype);

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save
   */
  Alert.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.alert[method](data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Alert.prototype.delete = function() {
    return $api.alert
      .delete(null, this)
      .then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Alert.query = function(filter) {
    return $api.alert.query(filter);
  };

  /**
   * Find by ID
   */
  Alert.findById = function(id) {
    return $api.alert.get({id});
  };

  //Return
  return Alert;
});
