
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.OAuth', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.oauth', {
    url: '/oauth',
    component: 'registerOAuthRoute',
  });
})

/**
 * Route component
 */
.component('registerOAuthRoute', {
  controller: 'RegisterOAuthCtrl',
  templateUrl: 'portal/register/steps/oauth.html',
  bindings: {
    club: '<',
    registration: '<',
    transition: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterOAuthCtrl', function($controller, Auth) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('RegisterBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  this.$onInit = function() {

    //Get params
    this.params = this.transition.params();
  };

  /**
   * Login with oAuth provider
   */
  this.login = function(provider) {

    //Set provider and flag
    this.provider = provider;
    this.isLoggingIn = true;

    //Create state
    const club = this.club.identifier;
    const state = {club};

    //Login (using registration flow)
    Auth.loginWithOAuthProvider(provider, 'register', state);
  };
});
