
/**
 * Module definition and dependencies
 */
angular.module('Shared.AlertTypes.Options', [])

/**
 * Constant definition
 */
.constant('AlertTypes', [
  {
    value: 'help',
    label: 'Blue',
  },
  {
    value: 'success',
    label: 'Green',
  },
  {
    value: 'warning',
    label: 'Yellow',
  },
  {
    value: 'danger',
    label: 'Red',
  },
  {
    value: 'info',
    label: 'Gray',
  },
  {
    value: 'black',
    label: 'Black',
  },
]);
