
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.Details.Card', [])

/**
 * Component
 */
.component('cardCouponDetails', {
  templateUrl: 'coupon/cards/details.html',
  require: {
    card: '^^',
  },
  bindings: {
    user: '<',
    couponTypes: '<',
    selection: '<',
    onSelect: '&',
    onNext: '&',
    onCancel: '&',
  },
  controller($modal, matchesMemberConstraint) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Get user
      const {user} = this;

      //Filter out eligible coupon types
      this.eligibleCouponTypes = this.couponTypes
        .filter(type => user.isSuper() || matchesMemberConstraint(user, type));

      //No coupon types available for purchase?
      if (this.eligibleCouponTypes.length === 0) {
        return $modal
          .open('basic', {
            templateUrl: 'coupon/modals/no-coupons-available.html',
          })
          .closed
          .then(() => this.onCancel());
      }

      //Initialize amounts
      for (const couponType of this.eligibleCouponTypes) {
        if (!couponType.amount) {
          couponType.amount = 1;
        }
      }
    };

    /**
     * Select type
     */
    this.selectType = function(couponType) {

      //Manipulate array
      if (this.selection.includes(couponType)) {
        const i = this.selection.indexOf(couponType);
        this.selection.splice(i, 1);
      }
      else {
        this.selection.push(couponType);
        if (!couponType.amount) {
          couponType.amount = 1;
        }
      }

      //Update selection
      this.updateSelection();
    };

    /**
     * Toggle select handler
     */
    this.updateSelection = function() {
      const {selection} = this;
      this.onSelect({$event: {selection}});
    };

    /**
     * Next step
     */
    this.next = function() {
      this.onNext();
    };

    /**
     * Cancel flow
     */
    this.cancel = function() {
      this.onCancel();
    };
  },
});
