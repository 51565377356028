
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.SignupClub.Interests', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('signupClub.interests', {
    url: '/interests',
    component: 'signupInterestsRoute',
  });
})

/**
 * Route component
 */
.component('signupInterestsRoute', {
  controller: 'SignupClubInterestsCtrl',
  templateUrl: 'portal/signup-club/steps/interests.html',
  bindings: {
    club: '<',
  },
})

/**
 * Controller
 */
.controller('SignupClubInterestsCtrl', function(
  $state, $timeout, ValidationError
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Start faded
    this.fade = true;
    $timeout(() => this.fade = false);

    //Set club interests
    if (!this.club.interests) {
      this.club.interests = {
        autoRenewal: false,
        registration: false,
        onlinePayments: false,
        checkInLogs: false,
        events: false,
        classes: false,
        bookings: false,
        access: false,
        integrations: false,
        other: null,
      };
    }

    //Set other checkbox
    if (this.club.interests.other) {
      this.showOtherField = true;
    }
    else {
      this.showOtherField = false;
    }
  };

  /**
   * Back to previous step
   */
  this.back = function() {
    this.fade = true;
    $timeout(() => {
      $state.go('signupClub.details');
    }, 200);
  };

  /**
   * Update club
   */
  this.updateClub = function(property, value) {
    this.club.interests[property] = value;
  };

  /**
   * Update club
   */
  this.toggleOtherField = function(value) {
    this.showOtherField = value;
  };

  /**
   * Agree to terms
   */
  this.setAgreedToTerms = function(hasAgreedToTerms) {
    this.isErrorAgreeTerms = false;
    this.club.contact.hasAgreedToTerms = hasAgreedToTerms;
  };

  /**
   * Sign up
   */
  this.signUp = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Not agreed to terms
    if (!this.club.contact.hasAgreedToTerms) {
      this.isErrorAgreeTerms = true;
      return;
    }

    //Already signed up?
    if (this.club.id) {
      return $state.go('signupClub.contact');
    }

    //Flags
    this.isSigningUp = true;
    this.isError = false;

    //Create club now
    this.club
      .create()
      .then(() => {
        this.fade = true;
        $timeout(() => {
          $state.go('signupClub.creating');
        }, 200);
      })
      .catch(error => {
        if (error instanceof ValidationError) {
          error.setFieldsValidity(this.form);
        }
        this.isError = true;
        this.isSigningUp = false;
      });
  };
});
