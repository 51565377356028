
/**
 * Module definition and dependencies
 */
angular.module('Shared.PortalLogo.Component', [])

/**
 * Portal logo component
 */
.component('portalLogo', {
  template: `
    <div class="PortalLogo">
      <img
        class="PortalLogo-club"
        alt=""
        ng-if="$ctrl.logo"
        ng-srcset="{{$ctrl.logo.url}}?h=125&auto=format&dpr=1 1x, {{$ctrl.logo.url}}?h=125&auto=format&dpr=2 2x"
        ng-src="{{$ctrl.logo.url}}?h=125&auto=format"
      >
      <a href="https://helloclub.com" target="_blank" title="{{app.title}} website" ng-if="!$ctrl.logo"><img alt="Logo" class="PortalLogo-app" src="logo.svg"></a>
    </div>
  `,
  bindings: {
    logo: '<',
  },
});
