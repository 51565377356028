
/**
 * Module definition and dependencies
 */
angular.module('Shared.Club.Billing.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('ClubBilling', function($baseModel) {

  /**
   * Constructor
   */
  function ClubBilling(data) {
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(ClubBilling.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  ClubBilling.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('address', 'Address');

    //Return self
    return this;
  };

  //Return
  return ClubBilling;
});
