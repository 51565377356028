
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Setup.Step.Component', [])

/**
 * Setup step component
 */
.component('setupStep', {
  template: `
    <div class="SetupStep" ng-class="{'is-complete': $ctrl.status.isComplete}">
      <icon-status
        icon-class="Icon--l"
        is-skipped="$ctrl.status.isSkipped"
        is-success="$ctrl.status.isComplete"
      ></icon-status>
      <span class="SetupStepText">
        {{$ctrl.step}}
        <br>
        <small class="text-muted" ng-if="!$ctrl.status.isComplete && !$ctrl.status.isSkipped">{{$ctrl.text}}</small>
        <small class="text-muted" ng-if="$ctrl.status.isSkipped">Skipped</small>
        <small class="text-success" ng-if="$ctrl.status.isComplete">Done!</small>
      </span>
      <i class="Icon SetupStepIcon" ng-class="{'color-success': $ctrl.status.isComplete}">{{$ctrl.icon}}</i>
    </div>
  `,
  transclude: true,
  bindings: {
    icon: '@',
    step: '@',
    text: '@',
    status: '<',
  },
});
