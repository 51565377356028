
/**
 * Module definition and dependencies
 */
angular.module('Shared.GenericOptions.Component', [])

/**
 * Generic fee and rule options component
 */
.component('genericOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li>
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    type: '@',
    item: '<',
    onEdit: '&',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Edit
     */
    this.edit = function() {
      const $event = {
        [this.type]: this.item,
      };
      this.onEdit({$event});
    };

    /**
     * Delete
     */
    this.delete = function() {
      const $event = {
        [this.type]: this.item,
      };

      this.onDelete({$event});
    };
  },
});
