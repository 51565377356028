
/**
 * Module definition and dependencies
 */
angular.module('Shared.ApiKey.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('apiKey', {
    model: 'ApiKey',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'apiKeys',
        isArray: true,
        isModel: true,
      },
      findByIntegration: {
        url: 'integration/:type',
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('ApiKey', function($api, $baseModel) {

  /**
   * Constructor
   */
  function ApiKey(data) {
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(ApiKey.prototype, $baseModel.prototype);

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save
   */
  ApiKey.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.apiKey[method](data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  ApiKey.prototype.patch = function(data) {
    const {id} = this;
    return $api.apiKey
      .patch({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  ApiKey.prototype.delete = function() {
    return $api.apiKey
      .delete(null, this)
      .then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  ApiKey.query = function(filter) {
    return $api.apiKey.query(filter);
  };

  /**
   * Find by integration
   */
  ApiKey.findByIntegration = function(type) {
    return $api.apiKey.findByIntegration({type});
  };

  //Return
  return ApiKey;
});
