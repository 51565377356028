
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.QuestionOptions.Component', [])

/**
 * Event question options component
 */
.component('eventQuestionOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li>
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li>
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    question: '<',
    onEdit: '&',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Edit
     */
    this.edit = function() {
      const {question} = this;
      this.onEdit({$event: {question}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      const {question} = this;
      this.onDelete({$event: {question}});
    };
  },
});
