
/**
 * Module definition and dependencies
 */
angular.module('App.Public.Event.Signup.Controller', [
])

/**
 * Controller
 */
.controller('PublicEventSignupCtrl', function(
  $controller, $state, $store, $modal, $q, Guest,
  DateFormat, EventAttendee, EventRuleTypes, Config
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AccountBaseCtrl', {});

  //Constants
  const {FEE, FREE} = EventRuleTypes;

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Initialize
    this.guests = [];
    this.numGuests = 1;
    this.options = this.attendance.options;
    this.defaultBanner = `https://${Config.domains.images}/${Config.app.defaultBanner}`;

    //Load providers in advance, to check if anyone can pay
    this.payment.loadProviders();

    //Parent init
    $base.$onInit.call(this);
  };

  /**
   * Setup
   */
  this.setup = function() {

    //Get data
    const {event, options} = this;

    //Nothing to do?
    if (!event.hasSpacesLeft || options.length === 0) {
      return this.goBack();
    }

    //Set initial step and option
    this.stepDetails();
    this.setOption(this.options[0]);
  };

  /**
   * Set option
   */
  this.setOption = function(option) {

    //Set option
    this.option = option;
    this.dates = [];

    //Set first rule type
    this.setType(option.types[0]);
  };

  /**
   * Set number of guests
   */
  this.setNumGuests = function(num) {
    this.numGuests = num;
  };

  /**
   * Remove guest
   */
  this.removeGuest = function(i) {
    if (this.guests.length > i) {
      this.guests.splice(i, 1);
      this.numGuests = this.guests.length;
    }
  };

  /**
   * Set type
   */
  this.setType = function(type) {

    //Set type
    this.type = type;

    //Reset coupon/subscription
    this.rule = null;

    //Fee or free, use best rule
    if (type === FEE || type === FREE) {
      this.rule = this.option.bestByType[type];
    }
  };

  /**
   * Check route is valid
   */
  this.checkRouteValid = function() {

    //Get data
    const {event} = this;

    //Nothing to do?
    if (!event) {
      return this.goBack();
    }

    //Already attending and we didn't just pay for this?
    if (!this.outcome && this.attendee) {
      return this.onPaymentSuccess();
    }
  };

  /**
   * Select date
   */
  this.selectDate = function(date) {

    //Select date
    const i = this.dates.indexOf(date);
    if (i === -1) {
      this.dates.push(date);
    }
    else {
      this.dates.splice(i, 1);
    }
  };

  /**
   * Prepare payment
   */
  this.preparePayment = function() {

    //Get data
    const {rule, event, dates, option, numGuests} = this;
    const {name} = event;
    const amount = rule.fee * numGuests;

    //Selection of multiple dates
    if (option.method === 'dates') {
      for (const date of dates) {
        const label = `${numGuests}x entry fee for ${name} – ${date.format(DateFormat.formats.withTime)}`;
        this.payment.addLineItem({label, amount});
      }
    }

    //Instance or series
    else {
      const label = `${numGuests}x entry fee for ${name}`;
      this.payment.setLineItems([{label, amount}]);
    }

    //Make line items and set extra data
    this.payment.setExtraData({eventId: event.id});
    this.payment.setRedirectPath(`public/event/signup/${event.id}`);
    this.payment.setIsUsingCredit(false);
    this.payment.setPublic(true);
  };

  /**
   * Before payment
   */
  this.beforePayment = function() {
    return this
      .createAttendees()
      .then(data => {

        //Set payment data
        const {guest} = this;
        const transactionIds = data.transactions.map(trx => trx.id);
        this.payment.setExtraData({transactionIds, guestId: guest.id});
      });
  };

  /**
   * Sign up without payment
   */
  this.signUp = function() {

    //Get data
    const {rule, option, dates} = this;

    //No rule
    if (!rule) {
      return $q.reject();
    }

    //No dates
    if (option.method === 'dates' && dates.length === 0) {
      return $q.reject();
    }

    //Create attendees
    return this
      .createAttendees()
      .then(() => $store.events.clear())
      .then(() => this.onSignedUp());
  };

  /**
   * Create attendees
   */
  this.createAttendees = function() {

    //Get data
    const {event, rule, dates, guests} = this;

    //Missing guest?
    if (guests.length === 0) {
      throw new Error(`No guests selected`);
    }

    //Create guest first
    const {firstName, lastName, email, phone} = guests[0];
    const guest = new Guest({firstName, lastName, email, phone});
    return guest
      .save()
      .then(() => {

        //Store guest
        this.guest = guest;

        //Create data for attendee creation
        const data = {
          event: event.id,
          guest: guest.id,
          rule: rule.id,
          dates,
        };

        //Append attendees
        data.attendees = guests.map(item => {
          return {
            answers: EventAttendee.convertAnswersMap(item.answers),
          };
        });

        //Create
        return EventAttendee.createOwnGuest(data);
      });
  };

  /**************************************************************************
   * Navigation
   ***/

  /**
   * Details step
   */
  this.stepDetails = function() {
    this.step = 'details';
  };

  /**
   * Guests step
   */
  this.stepGuests = function() {
    this.step = 'guests';
  };

  /**
   * Next from view event details
   */
  this.nextFromDetails = function() {

    //No dates
    if (this.option.method === 'dates' && this.dates.length === 0) {
      return;
    }

    //Add or remove guests
    while (this.guests.length < this.numGuests) {
      this.guests.push({answers: {}});
    }
    while (this.guests.length > this.numGuests) {
      this.guests.pop();
    }

    //To guests
    this.stepGuests();
  };

  /**
   * Next from guests
   */
  this.nextFromGuests = async function() {
    this.preparePayment();
    this.nextFromUseCredit();
  };

  /**
   * Prev from guests
   */
  this.prevFromGuests = function() {
    this.stepDetails();
  };

  /**
   * Prev from use account credit
   */
  this.prevFromUseCredit = function() {
    this.stepGuests();
  };

  /**
   * Get event params
   */
  this.getEventParams = function() {
    const eventId = this.event.id;
    return {eventId};
  };

  /**
   * Signed up without payment
   */
  this.onSignedUp = function() {

    //Get data
    const {event, rule, club} = this;

    //Show confirmation
    $modal
      .open('basic', {
        templateUrl: 'event/modals/confirm-attendance.html',
        locals: {event, rule, club},
      })
      .closed
      .then(() => this.goBack());
  };

  /**
   * Payment success handler
   */
  this.onPaymentSuccess = function() {
    this.goBack();
  };

  /**
   * Payment failed handler
   */
  this.onPaymentFailed = function() {
    this.goBack();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.goBack();
  };

  /**
   * Go back, either to view event, or to subscription overview
   */
  this.goBack = function() {
    const params = this.getEventParams();
    $state.go('public.event.view', params);
  };
});
