
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit', [
  'App.Admin.People.Members.Edit.Controller',
  'App.Admin.People.Members.Edit.Info.Card',
  'App.Admin.People.Members.Edit.Billing.Card',
  'App.Admin.People.Members.Edit.Grading.Card',
  'App.Admin.People.Members.Edit.Details.Card',
  'App.Admin.People.Members.Edit.Display.Card',
  'App.Admin.People.Members.Edit.Directory.Card',
  'App.Admin.People.Members.Edit.CustomFields.Card',
  'App.Admin.People.Members.Edit.Circles.Card',
  'App.Admin.People.Members.Edit.Activities.Card',
  'App.Admin.People.Members.Edit.Bookings.Card',
  'App.Admin.People.Members.Edit.AccessLogs.Card',
  'App.Admin.People.Members.Edit.ActivityLogs.Card',
  'App.Admin.People.Members.Edit.SignInDetails.Card',
  'App.Admin.People.Members.Edit.Permissions.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.members.edit', {
    url: '/edit/:memberId',
    component: 'adminMemberEditRoute',
    params: {
      showComments: {
        value: false,
      },
      showSubscriptions: {
        value: false,
      },
      showCoupons: {
        value: false,
      },
      showFinances: {
        value: false,
      },
      showPaymentSources: {
        value: false,
      },
    },
    resolve: {
      member(transition, Member) {
        const params = transition.params();
        return Member.findById(params.memberId);
      },
      groups($store) {
        return $store.memberGroups.query();
      },
      activeCoupons(transition, $store) {
        const params = transition.params();
        return $store.coupons.query({
          member: params.memberId,
          areUsable: true,
        });
      },
    },
  });
})

/**
 * Route component
 */
.component('adminMemberEditRoute', {
  controller: 'AdminMemberEditCtrl',
  templateUrl: 'admin/people/members/edit/edit.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    member: '<',
    groups: '<',
    activities: '<',
    activeCoupons: '<',
    transactions: '<',
    transition: '<',
    page: '<',
    module: '<',
    integrations: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.people.members.edit'}, transition => {

    //Get data
    const member = transition.injector().get('member');

    //Set page title and crumb
    Page.setTitle(member.name);
    Page.addCrumb({
      sref: 'admin.people.members.edit',
      params: {
        memberId: member.id,
      },
    });
  });
});
