
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.Overview', [
  'App.Admin.Resource.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.resource.overview', {
    url: '',
    component: 'adminResourceOverviewRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('resources');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminResourceOverviewRoute', {
  controller: 'AdminResourceOverviewCtrl',
  templateUrl: 'admin/resource/overview/overview.html',
  bindings: {
    club: '<',
    user: '<',
    page: '<',
    filter: '<',
    module: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions) => {

  //Page setup
  $transitions.onSuccess({
    to: 'admin.resource.overview',
  }, () => {
  });
});
