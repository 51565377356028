
/**
 * Module definition and dependencies
 */
angular.module('Shared.MemberActivity.Component', [])

/**
 * Member activity component
 */
.component('memberActivity', {
  template: `
    <div class="MemberActivity">
      <div class="MemberActivity-item">
        <span class="MemberActivity-label">Event attendance</span>
        <span class="MemberActivity-amount">{{$ctrl.totalAttendees}}</span>
      </div>
      <div class="MemberActivity-item">
        <span class="MemberActivity-label">Bookings</span>
        <span class="MemberActivity-amount">{{$ctrl.totalBookings}}</span>
      </div>
      <div class="MemberActivity-item" ng-if="$ctrl.hasSystem">
        <span class="MemberActivity-label">Access logs</span>
        <span class="MemberActivity-amount">{{$ctrl.totalAccessLogs}}</span>
      </div>
      <div class="MemberActivity-item" ng-if="$ctrl.hasSystem">
        <span class="MemberActivity-label">Activity logs</span>
        <span class="MemberActivity-amount">{{$ctrl.totalActivityLogs}}</span>
      </div>
    </div>
  `,
  bindings: {
    hasSystem: '<',
    totalAttendees: '<',
    totalBookings: '<',
    totalAccessLogs: '<',
    totalActivityLogs: '<',
  },
});
