
/**
 * Module definition and dependencies
 */
angular.module('App.Event.Signup.Guests.Card', [])

/**
 * Component
 */
.component('cardEventSignupGuests', {
  templateUrl: 'event/cards/signup-guests.html',
  controller: 'CardEventSignupGuestsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    event: '<',
    guests: '<',
    numGuests: '<',
    isPublic: '<',
    needsPayment: '<',
    onNext: '&',
    onPrev: '&',
    onCancel: '&',
    onSignUp: '&',
    onRemoveGuest: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventSignupGuestsCtrl', function(
  MembershipConstraints
) {

  /**
   * Init
   */
  this.$onInit = function() {

    //Filter out eligible event questions
    this.questions = this.event.questions
      .filter(question => question.isVisible && this.isEligibleQuestion(question));
  };

  /**
   * Check if question is eligible
   */
  this.isEligibleQuestion = function(question) {
    if (question.constraint === MembershipConstraints.ALL ||
        question.constraint === MembershipConstraints.GUEST) {
      return true;
    }
    return false;
  };

  /**
   * Update answer
   */
  this.updateAnswer = function(guest, questionId, answer) {
    guest.answers[questionId] = answer;
  };

  /**
   * Update guest
   */
  this.updateGuest = function(guest, prop, value) {
    guest[prop] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Signup
   */
  this.signUp = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Toggle flag
    this.isSigningUp = true;

    //Signup
    this
      .onSignUp()
      .catch(error => {
        this.error = error;
        this.isSigningUp = false;
      });
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Onwards
    this.onNext();
  };

  /**
   * Previous step
   */
  this.prev = function() {
    this.onPrev();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
