
/**
 * Module definition and dependencies
 */
angular.module('Shared.PickTime.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('pickTime', {
    templateUrl: 'modals/input/pick-time.html',
    controller: 'ModalPickTimeCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalPickTimeCtrl', function(
  $controller, $modalInstance
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Update time
   */
  this.updateTime = function(time) {
    this.time = time;
  };

  /**
   * Confirm
   */
  this.confirm = function() {
    $modalInstance.resolve(this.time);
  };
});
