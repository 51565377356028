
/**
 * Module definition and dependencies
 */
angular.module('App.Header.Controller', [])

/**
 * Component
 */
.controller('AppHeaderCtrl', function(
  $state, $modal, $document, $timeout, $transitions, $debounce,
  Interface, Auth, Page, Kiosk, Config, Emitter
) {

  //Get controller
  const $ctrl = this;

  /**
   * On click handler to hide user menu
   */
  function hideUserMenu(event) {

    //Clicks anywhere but on the user menu toggle and user menu itself close it
    if (!Interface.isTarget(['UserMenu-toggle', 'UserMenu'], event)) {
      $timeout(() => $ctrl.toggleUserMenu(false));
    }
  }

  /**
   * On click handler to hide help menu
   */
  function hideHelpMenu(event) {

    //Clicks anywhere but on the help menu toggle close it
    if (!Interface.isTarget(['HelpMenu-toggle'], event)) {
      $timeout(() => $ctrl.toggleHelpMenu(false));
    }
  }

  /**
   * On init
   */
  this.$onInit = function() {

    //Services
    this.Page = Page;

    //Get emitter
    Emitter
      .get('header')
      .on('showHelp', (event) => {
        const {withOverlay} = event;
        $timeout(() => {
          this.toggleHelpMenu(true, withOverlay);
        }, 100);
      });

    //Flags
    this.isShowingSideMenu = false;
    this.isShowingUserMenu = false;
    this.isShowingHelpMenu = false;
    this.isShowingOverlay = false;
    this.isKiosk = Kiosk.isEnabled();

    //Debounced search handler
    this.searchDebounced = $debounce(this.search, this, 500);

    //Check if user is admin
    this.isAdmin = (
      this.user && this.user.isAdmin()
    );

    //Show trial indicator
    this.showTrialIndicator = (
      this.user && (this.user.isAccountOwner || this.user.isSuper())
    );

    //Show account setup
    this.showAccountSetup = (
      this.user && this.user.isAdmin()
    );

    //Check if we have live support
    this.showLiveSupport = (
      Config.intercom && this.club && this.club.permissions &&
      this.club.permissions.liveSupport &&
      this.user && this.user.isAdmin()
    );

    //Hide side menu and user menu on route change
    $transitions.onStart({}, () => {
      this.toggleSideMenu(false);
      this.toggleUserMenu(false);
      this.toggleHelpMenu(false);
    });

    //Hide menu's on click of body
    $document.find('body').on('click', hideUserMenu);
    $document.find('body').on('click', hideHelpMenu);
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    $document.find('body').off('click', hideUserMenu);
    $document.find('body').off('click', hideHelpMenu);
  };

  /**
   * Click handler for logout
   */
  this.logout = function(toggleUserMenu = false) {
    if (toggleUserMenu) {
      this.toggleUserMenu();
    }
    Auth.logout();
  };

  /**
   * Click handler for login
   */
  this.login = function() {
    Auth.goToLoginState('bookings');
  };

  /**
   * Toggle for side menu
   */
  this.toggleSideMenu = function(show) {
    if (typeof show !== 'boolean') {
      show = !this.isShowingSideMenu;
    }
    this.isShowingSideMenu = show;
  };

  /**
   * Toggle for user menu
   */
  this.toggleUserMenu = function(show) {
    if (typeof show !== 'boolean') {
      show = !this.isShowingUserMenu;
    }
    this.isShowingUserMenu = show;
  };

  /**
   * Toggle for help menu
   */
  this.toggleHelpMenu = function(show, withOverlay) {
    if (typeof show !== 'boolean') {
      show = !this.isShowingHelpMenu;
    }
    this.isShowingHelpMenu = show;
    this.isShowingOverlay = (show && withOverlay);
  };

  /**
   * Change avatar shortcut
   */
  this.changeAvatar = function() {
    $state.go('profile', {openCard: 'avatar'});
    this.toggleUserMenu(false);
  };

  /**
   * Search (simply flag filter param as updated)
   */
  this.search = function() {
    if (Page.filters) {
      Page.filters.wasUpdated('search');
    }
  };

  /**
   * Place feedback
   */
  this.feedback = function() {

    //Get data
    const {user, club} = this;

    //Open feedback modal and handle snooze reminder if resolved
    $modal
      .open('feedback', {locals: {user, club}})
      .result
      .then(snooze => user.snoozeReminder('askForFeedback', snooze));
  };
});
