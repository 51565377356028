
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Payments.Card', [
  'App.Admin.People.Members.PaymentOptions.Component',
])

/**
 * Component
 */
.component('cardMemberPayments', {
  templateUrl: 'admin/people/members/cards/payments.html',
  controller: 'CardMemberPaymentsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    isOwn: '<',
    user: '<',
    member: '<',
    limit: '<',
    payments: '<',
    canLoadMore: '<',
    onReload: '&',
    onLoadMore: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberPaymentsCtrl', function(
  $modal, $notice, $timeout
) {

  /**
   * On init
   */
  this.$onInit = function() {
    this.isSuper = (this.user && this.user.isSuper());
    this.labelProp = (this.isOwn ? 'labelAlt' : 'label');
  };

  /**
   * On changes
   */
  this.$onChanges = function() {
    this.hasPayments = this.payments.length > 0;
    this.hasMore = this.payments.length > this.limit;
  };

  /**
   * Show all
   */
  this.showAll = function() {
    this.hasMore = false;
    this.limit = undefined;
  };

  /**
   * Load more
   */
  this.loadMore = function() {
    this.isLoadingMore = true;
    this.limit = undefined;
    this
      .onLoadMore()
      .finally(() => this.isLoadingMore = false);
  };

  /**
   * View payment
   */
  this.view = function($event) {

    //Get data
    const {payment} = $event;
    const {isSuper, isOwn} = this;

    //Open modal
    $modal.open('basic', {
      templateUrl: 'admin/finance/modals/view-payment.html',
      locals: {payment, isOwn, isSuper},
    });
  };

  /**
   * Edit payment
   */
  this.edit = function($event) {

    //Get payment and define handler
    const {payment} = $event;
    const handler = data => payment.update(data);

    //Open modal
    return $modal
      .open('editPayment', {locals: {payment, handler}})
      .result
      .then(() => {
        $notice.show('Payment updated');
        this.onReload();
      });
  };

  /**
   * Remove payment
   */
  this.delete = function($event) {

    //Get payment and define handler
    const {payment} = $event;
    const handler = function() {
      return payment.delete();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-delete-payment.html',
        locals: {payment, handler},
      })
      .result
      .then(() => {
        $notice.show('Payment removed');
        this.onReload();
      });
  };

  /**
   * Download receipt
   */
  this.receipt = function(payment) {
    payment.isBusy = true;
    payment
      .downloadReceipt()
      .finally(() => {

        //Remove spinner after this arbitrary delay of 4 seconds, which
        //approxmately coincides with when the download of the PDF starts
        //If one would feel so inclined, one could maybe try to detect when
        //this event occurs and then set the flag accordingly.
        $timeout(() => {
          payment.isBusy = false;
        }, 4000);
      });
  };
});
