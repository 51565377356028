
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Attendees.Card', [])

/**
 * Component
 */
.component('cardEventAttendees', {
  templateUrl: 'admin/event/cards/attendees.html',
  controller: 'CardEventAttendeesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    event: '<',
    attendees: '<',
    groups: '<',
    member: '<',
    isForMember: '<', //NOTE: If on the member edit page
    hasMore: '<',
    onLoadMore: '&',
    onReload: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventAttendeesCtrl', function(
  $modal, $notice, EventAttendee, Guest, Payment, ErrorCodes,
  KeyDown, CouponTypeUses, ReplacementTags, MemberGroup, Member,
  $store
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.toggling = {};

    //QR code scanner
    this.boundQrCodeListener = this.qrCodeListener.bind(this);
    KeyDown.addListener(this.boundQrCodeListener);

    //Load groups
    this.loadGroups();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    if (this.boundQrCodeListener) {
      KeyDown.removeListener(this.boundQrCodeListener);
    }
  };

  /**
   * On changes
   */
  this.$onChanges = function() {
    this.hasAttendees = this.attendees.length > 0;
  };

  /**
   * Add attendees
   */
  this.add = function() {

    //Get data and define handler
    const {event, attendees, groups} = this;
    const handler = data => EventAttendee.createMany(data);

    //Open modal
    $modal
      .open('addAttendees', {locals: {handler, event, attendees, groups}})
      .result
      .then(attendees => {
        const s = (attendees.length > 1) ? 's' : '';
        $notice.show(`Attendee${s} added`);
        this.onReload();
      });
  };

  /**
   * Add guest
   */
  this.addGuest = function() {

    //Get data
    const {event, attendees} = this;

    //Define handler
    const handler = async(guestData, attendeeData) => {

      //Create guest and attendee
      const guest = new Guest(guestData);
      const attendee = new EventAttendee(attendeeData);

      //Link event and guest
      attendee.event = event;
      attendee.guest = guest;

      //Save guest first, then create guest attendee
      await guest.save();
      await attendee.createGuest();
    };

    //Open modal
    $modal
      .open('addGuest', {locals: {handler, event, attendees}})
      .result
      .then(() => {
        $notice.show(`Guest added`);
        this.onReload();
      });
  };

  /**
   * Edit attendee
   */
  this.edit = function($event) {

    //Get attendee
    const {attendee} = $event;
    const {event, member} = this;

    //Define handler
    const handler = async(model) => {

      //Update guest first
      if (model.guest) {
        const guest = new Guest(model.guest);
        await guest.save();
      }

      //Save attendee now
      await attendee.save(model);
    };

    //Open modal
    return $modal
      .open('editAttendee', {
        locals: {event, attendee, member, handler},
      })
      .result
      .then(() => $notice.show('Attendee updated'))
      .catch(() => $notice.showError('Failed to update attendee'));
  };

  /**
   * Remove attendee
   */
  this.delete = function($event) {

    //Get data and define handler
    const {attendee} = $event;
    const {event} = this;
    const handler = data => attendee.delete(data);

    //Open confirmation dialog
    return $modal
      .open('confirmDeleteAttendee', {locals: {attendee, event, handler}})
      .result
      .then(() => {
        $notice.show('Attendee removed');
        const i = this.attendees.indexOf(attendee);
        if (i !== -1) {
          this.attendees.splice(i, 1);
        }
        this.hasAttendees = this.attendees.length > 0;
        this.onReload();
      });
  };

  /**
   * Toggle attended
   */
  this.toggleAttended = function($event) {

    //Get data
    const {attendee, hasAttended} = $event;
    const newValue = (typeof hasAttended === 'boolean') ?
      hasAttended : !attendee.hasAttended;

    //Toggle attended
    return attendee
      .updateAttended(newValue);
  };

  /**
   * View attendee
   */
  this.view = function($event) {

    //Get data
    const {attendee} = $event;
    const {event, member, isForMember} = this;

    //Open modal
    $modal.open('viewAttendee', {
      locals: {event, member, attendee, isForMember},
    });
  };

  /**
   * Load groups
   */
  this.loadGroups = function() {
    $store.memberGroups
      .query(true)
      .then(groups => this.groups = groups);
  };

  /**
   * Add attendees to groups
   */
  this.addToGroups = function() {

    //Get filter
    const {groups} = this;
    const Model = MemberGroup;

    //Get all member ids
    const ids = this.attendees
      .filter(attendee => attendee.member)
      .map(attendee => attendee.member.id);

    //Define handler
    const handler = (groups) => Member.addToGroups({ids}, groups);
    const reloader = () => this.loadGroups();

    //Open modal
    $modal
      .open('addToGroups', {locals: {Model, groups, handler, reloader}})
      .result
      .then(() => {
        $notice.show(`Added attendees to groups`);
      });
  };

  /**
   * Export
   */
  this.export = function() {

    //Get data
    const {club, event: {id: event}} = this;

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club, action: `Exporting event attendees`},
      });
    }

    //Check if anything to export
    if (!this.hasAttendees) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'event attendees', action: 'export'},
      });
    }

    //Export
    return EventAttendee.export({event});
  };

  /**
   * Download PDF
   */
  this.pdf = function() {

    //Get data
    const {event} = this;

    //Check if anything to download
    if (!this.hasAttendees) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'event attendees', action: 'download'},
      });
    }

    //Download
    return event.downloadAttendancePdf();
  };

  /**
   * Email all event attendees
   */
  this.email = function() {

    //Check if anything to do
    if (!this.hasAttendees) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'event attendees', action: 'email'},
      });
    }

    //Define handlers
    const {event: {id: event}, customFields} = this;
    const filter = {event, isRemoved: false};
    const handler = email => EventAttendee.email(filter, email);
    const counter = () => EventAttendee.count(filter);
    const tags = ReplacementTags.member(customFields);

    //Open modal
    $modal.open('email', {locals: {handler, counter, tags}});
  };

  /**
   * Pay with coupons
   */
  this.payWithCoupons = function($event) {

    //Get data
    const {attendee} = $event;
    const {event} = attendee;
    const members = [attendee.member];
    const sessionsNeeded = 1;
    const label = event.name;

    //Filter for eligible coupons
    const filter = {
      areUsable: true,
      usableFor: [CouponTypeUses.EVENTS],
      members: members.map(member => member.id),
    };

    //Define handler
    const handler = coupons => attendee.useCoupon(coupons[0]);

    //Open modal
    $modal
      .open('payWithCoupons', {
        locals: {label, sessionsNeeded, filter, members, handler},
      })
      .result
      .then(() => $notice.show('Paid'))
      .catch(error => {
        if (error.code === ErrorCodes.paymentMethodInvalid) {
          $notice.showError('Not enough sessions to pay for event');
        }
        else {
          $notice.showError('Payment failed');
        }
      });
  };

  /**
   * Pay with membership
   */
  this.payWithMembership = function($event) {

    //Get data
    const {attendee} = $event;
    const {event} = attendee;

    //Define handler
    const handler = sub => attendee.useSubscription(sub);

    //Open modal
    $modal
      .open('payWithMembership', {
        locals: {attendee, event, handler},
      })
      .result
      .then(() => $notice.show('Paid'))
      .catch(() => $notice.showError('Payment failed'));
  };

  /**
   * Pay with account credit
   */
  this.payWithAccountCredit = function($event) {

    //Get data
    const {attendee} = $event;
    const {event} = attendee;
    const {member, rule: {fee: amount}} = attendee;
    const label = event.name;
    const handler = data => attendee.markPaid(data);

    //Open modal
    $modal
      .open('payWithAccountCredit', {
        locals: {label, amount, member, handler},
      })
      .result
      .then(() => $notice.show('Paid with account credit'))
      .catch(() => $notice.showError('Payment failed'));
  };

  /**
   * Mark attendee paid
   */
  this.markPaid = function($event) {

    //Create payment and define handler
    const {attendee} = $event;
    const payment = new Payment();
    const handler = data => attendee.markPaid(data);

    //Open modal
    return $modal
      .open('editPayment', {locals: {payment, handler}})
      .result
      .then(() => $notice.show('Marked as paid'))
      .catch(() => $notice.showError('Payment failed'));
  };

  /**
   * Load more
   */
  this.loadMore = function() {
    this.isLoadingMore = true;
    this
      .onLoadMore()
      .finally(() => this.isLoadingMore = false);
  };

  /**
   * QR code listener
   */
  this.qrCodeListener = function(buffer) {

    //Check buffer for known matches
    const memberMatch = buffer.match(/\/admin\/people\/members\/edit\/([a-f0-9]{24})/);
    if (!memberMatch) {
      return;
    }

    //Member ID
    if (memberMatch) {

      //Get member ID
      const memberId = memberMatch[1];
      const attendee = this.attendees
        .find(attendee => attendee.member.id === memberId);

      //Member is an attendee
      if (attendee) {

        //Get data
        const {event, isForMember} = this;
        document.getElementById('audioCheckIn').play();
        this.toggleAttended({attendee});

        //Open modal
        $modal
          .open('viewAttendee', {
            locals: {event, attendee, isForMember},
          }, true);
      }
      else {

        //Get data and define handler
        const {event, attendees, groups} = this;
        const handler = data => EventAttendee.createMany(data);

        //Open modal
        $modal
          .open('addAttendees', {locals: {
            handler, event, memberId, attendees, groups,
          }})
          .result
          .then(attendees => {
            const s = (attendees.length > 1) ? 's' : '';
            $notice.show(`Attendee${s} added`);
            this.onReload();
          });
      }

      //Return true to stop further processing
      return true;
    }

    //Return true to stop further processing
    return true;
  };
});
