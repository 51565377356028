
/**
 * Define values
 */
const IN_ADVANCE = 'inAdvance';
const FLEXIBLE = 'flexible';
const AFTER_APPROVAL = 'afterApproval';

/**
 * Define array
 */
const PaymentOptions = [
  {
    label: 'Collect payment in advance during registration',
    value: IN_ADVANCE,
  },
  {
    label: 'Allow payment later after registration',
    value: FLEXIBLE,
  },
  {
    label: 'Only collect payment after approval',
    value: AFTER_APPROVAL,
  },
];

/**
 * Set properties
 */
PaymentOptions.IN_ADVANCE = IN_ADVANCE;
PaymentOptions.FLEXIBLE = FLEXIBLE;
PaymentOptions.AFTER_APPROVAL = AFTER_APPROVAL;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.PaymentOptions.Options', [])
  .constant('PaymentOptions', PaymentOptions);
