
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.AccountCredit.Controller', [])

/**
 * Controller
 */
.controller('AdminFinanceAccountCreditCtrl', function(
  $filter, $modal, moment, ScrollPosition, Pagination,
  AccountCredit, Settings, Intercom
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed account credit');

    //Flags
    this.isSuper = this.user.isSuper();
    this.showPercentages = true;

    //Initialize totals
    this.totalAccountCredit = 0;

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load account credit
    this.loadPage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Set page title and crumb
    page.setTitle('Account credit');
    page.addCrumb({sref: 'admin.finance.account credit'});

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter} = this;

    //Set filter defaults
    filter.setDefaults({
      search: '',
      member: null,
      fromDate: moment().subtract(1, 'month').startOf('day'),
      toDate: moment().endOf('day'),
    });

    //Filter on change handler
    filter.onChange(key => {

      //Get data
      const {toDate, fromDate} = filter;
      const hasDates = fromDate && toDate;

      //From date changed
      if (hasDates && key === 'fromDate' && fromDate.isAfter(toDate)) {
        filter.toDate = fromDate.clone();
      }

      //To date changed
      else if (hasDates && key === 'toDate' && toDate.isBefore(fromDate)) {
        filter.fromDate = toDate.clone();
      }

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
    };
  };

  /**
   * Load account credit
   */
  this.loadAccountCredit = function() {

    //Mark as loading and clear
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query account credit
    return AccountCredit
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Export account credit
   */
  this.export = function() {

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club: this.club, action: 'Exporting account credit entries'},
      });
    }

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'account credit entries', action: 'export'},
      });
    }

    //Create filter and export
    const filter = this.makeFilter();
    return AccountCredit.export(filter);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, sums, accountCredits} = data;

    //Set in scope
    this.entries = accountCredits;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);

    //Sums
    Object.assign(this, sums);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadAccountCredit()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };
});
