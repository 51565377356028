
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Controller', [])

/**
 * Portal controller
 */
.controller('PortalCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CoreCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Club present
    if (this.club) {
      this.setupServices();
      this.setupSettings();
      this.setupModules();
    }
  };
});
