
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.Terms.Card', [])

/**
 * Component
 */
.component('cardClubTerms', {
  templateUrl: 'admin/club/cards/terms.html',
  controller: 'CardClubTermsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    texts: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubTermsCtrl', function(CardCloseReasons, TextTypes) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;

    //Create model
    this.model = {
      termsAndConditions: this.texts
        .find(text => text.type === TextTypes.TERMS_AND_CONDITIONS),
    };

    //Reset text model
    if (this.model.termsAndConditions) {
      this.model.termsAndConditions.resetModel();
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get model
    const {model} = this;

    //Save
    this
      .onSave({$event: {model}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
        this.club.markStepComplete('setupTerms', true);
      })
      .finally(() => this.isSaving = false);
  };
});
