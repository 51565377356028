
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Edit', [
  'App.Admin.Membership.Edit.Controller',
  'App.Admin.Membership.Edit.Info.Card',
  'App.Admin.Membership.Edit.Details.Card',
  'App.Admin.Membership.Edit.Conditions.Card',
  'App.Admin.Membership.Edit.Term.Card',
  'App.Admin.Membership.Edit.Renewal.Card',
  'App.Admin.Membership.Edit.Anchor.Card',
  'App.Admin.Membership.Edit.System.Card',
  'App.Admin.Membership.Edit.AccountCredit.Card',
  'App.Admin.Membership.Edit.Coupons.Card',
  'App.Admin.Membership.Edit.Events.Card',
  'App.Admin.Membership.Edit.Registration.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.membership.edit', {
    url: '/edit/:membershipId',
    component: 'adminMembershipEditRoute',
    resolve: {
      usage(transition, Membership) {
        const params = transition.params();
        const id = params.membershipId;
        return Membership
          .usage(id)
          .then(usage => ({
            inUse: usage.inUse[id] || false,
            numMembers: usage.numMembers[id] || 0,
          }));
      },
      memberships($store) {
        return $store.memberships.query();
      },
      membership(transition, Membership) {
        const params = transition.params();
        return Membership.findById(params.membershipId);
      },
      couponTypes(CouponType) {
        return CouponType
          .query({
            isArchived: false,
            fields: 'name',
          })
          .then(data => data.couponTypes);
      },
    },
  });
})

/**
 * Route component
 */
.component('adminMembershipEditRoute', {
  controller: 'AdminMembershipEditCtrl',
  templateUrl: 'admin/membership/edit/edit.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    activities: '<',
    memberships: '<',
    membership: '<',
    usage: '<',
    page: '<',
    module: '<',
    couponTypes: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {
  $transitions.onSuccess({
    to: 'admin.membership.edit',
  }, transition => {

    //Get membership
    const membership = transition.injector().get('membership');

    //Set page title and crumb
    Page.setTitle(membership.nameWithSuffix);
    Page.addCrumb({
      sref: 'admin.membership.edit',
      params: {
        membershipId: membership.id,
      },
    });
  });
});
