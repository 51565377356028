
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.ResourceAssigneeOptions.Component', [])

/**
 * Resource options component
 */
.component('resourceAssigneeOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li>
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li>
        <a ng-click="$ctrl.renew()">Renew</a>
      </li>
      <li>
        <a ng-click="$ctrl.toggleIsActive()">
          {{$ctrl.assignee.isActive ? 'Make inactive' : 'Make active'}}
        </a>
      </li>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    resource: '<',
    assignee: '<',
    onEdit: '&',
    onRenew: '&',
    onToggleIsActive: '&',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Edit
     */
    this.edit = function() {
      const {resource, assignee} = this;
      this.onEdit({$event: {resource, assignee}});
    };

    /**
     * Renew
     */
    this.renew = function() {
      const {resource, assignee} = this;
      this.onRenew({$event: {resource, assignee}});
    };

    /**
     * Toggle isActive
     */
    this.toggleIsActive = function() {
      const {resource, assignee} = this;
      this.onToggleIsActive({$event: {resource, assignee}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      const {resource, assignee} = this;
      this.onDelete({$event: {resource, assignee}});
    };
  },
});
