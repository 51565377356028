
/**
 * Module definition and dependencies
 */
angular.module('Shared.PrivacyToggles.Component', [])

/**
 * Page selection component
 */
.component('privacyToggles', {
  template: `
    <check-box
      ng-repeat="field in $ctrl.fields"
      ng-model="$ctrl.checks[field.key]"
      on-change="$ctrl.toggle(field.key, value)"
    >
      {{field.label}}
      <small class="text-muted" ng-if="$ctrl.values[field.key]">({{$ctrl.values[field.key]}})</small>
      <small class="text-warning" ng-if="$ctrl.blanks[field.key]">(not entered)</small>
    </check-box>
  `,
  transclude: true,
  bindings: {
    fields: '<',
    model: '<',
    source: '<',
  },
  controller(Helpers) {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Initialize maps
      this.checks = {};
      this.values = {};
      this.blanks = {};

      //Flat values map
      for (const field of this.fields) {
        const {key, isParagraph, isCheckboxes} = field;
        const value = Helpers.get(this.source, key);
        this.values[key] = (isParagraph || isCheckboxes) ? null : value;
        this.blanks[key] = (typeof value === 'undefined' || value === '');
        this.checks[key] = !!Helpers.get(this.model, key);
      }
    };

    /**
     * Toggle
     */
    this.toggle = function(key, value) {
      this.checks[key] = value;
      Helpers.set(this.model, key, value);
    };
  },
});
