
/**
 * Module definition and dependencies
 */
angular.module('Shared.NotificationThresholdTypes.Constant', [])

/**
 * Constant definition
 */
.constant('NotificationThresholdTypes', {
  BEFORE: 'before',
  AFTER: 'after',
});
