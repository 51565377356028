
/**
 * Module definition and dependencies
 */
angular.module('Shared.ValidationError.Component', [])

/**
 * Validation error
 */
.component('validationError', {
  templateUrl: 'shared/validation-error/validation-error.html',
  bindings: {
    error: '<',
    club: '<',
    user: '<',
    booking: '<',
    activity: '<',
    event: '<',
    isEdit: '<',
    type: '@',
    isPayment: '<',
  },

  /**
   * Directive controller
   */
  controller(ErrorCodes, Member) {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Find error code
      for (const key in ErrorCodes) {
        if (this.error.code === ErrorCodes[key]) {
          this.errorKey = key;
          break;
        }
      }

      //No data?
      if (!this.error.data) {
        return;
      }

      //Set member and limit in scope
      if (this.error.data.member) {
        this.member = new Member(this.error.data.member);
        this.isOwn = this.user && this.member.isSame(this.user);
      }
      if (this.error.data.limit) {
        this.limit = this.error.data.limit;
      }
      if (this.error.data.events) {
        this.events = this.error.data.events;
      }
      if (this.error.data.duration) {
        this.duration = this.error.data.duration;
      }
    };
  },
});
