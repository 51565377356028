
/**
 * Module definition and dependencies
 */
angular.module('Shared.Payout.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('payout', {
    model: 'Payout',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'payouts',
        isModel: true,
        isArray: true,
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Payout', function($api, $baseModel) {

  /**
   * Constructor
   */
  function Payout(data) {

    //Call base model
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(Payout.prototype, $baseModel.prototype);

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query payouts
   */
  Payout.query = function(filter) {
    return $api.payout.query(filter);
  };

  //Return
  return Payout;
});
