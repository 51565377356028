
/**
 * Module definition and dependencies
 */
angular.module('App.Account.Pay', [
  'App.Account.Pay.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('account.pay', {
    url: '/pay',
    params: {
      transactionIds: {
        value: null,
      },
    },
    component: 'accountPayRoute',
    resolve: {
      payment(PaymentFlow, PaymentTypes, user) {
        return new PaymentFlow(PaymentTypes.TRANSACTIONS, user);
      },
      outcome(PaymentOutcome) {
        return PaymentOutcome.check();
      },
      circleMembers(Circle) {
        return Circle
          .queryOwnManagedMembers('finances');
      },
      unpaidTransactions(Transaction) {
        return Transaction
          .own({isPaid: false, includeCircles: true});
      },
    },
  });
})

/**
 * Component
 */
.component('accountPayRoute', {
  controller: 'AccountPayCtrl',
  templateUrl: 'account/pay/pay.html',
  bindings: {
    club: '<',
    user: '<',
    module: '<',
    transition: '<',
    payment: '<',
    outcome: '<',
    circleMembers: '<',
    unpaidTransactions: '<',
  },
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'account.pay'}, transition => {
    const params = transition.params();
    Page.setTitle('Make a payment');
    Page.addCrumb({sref: 'account.pay', params});
  });
});
