
/**
 * Module definition and dependencies
 */
angular.module('Shared.DurationDays.Filter', [])

/**
 * Filter definition
 */
.filter('durationDays', function() {
  return function(time) {
    if (time === null || time === undefined) {
      return '';
    }
    time = Math.round(Number(time));
    const days = Math.round(time / 1440);
    return String(days) + 'd';
  };
});
