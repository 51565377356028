
/**
 * Module definition and dependencies
 */
angular.module('Shared.ScrollPosition.Service', [])

/**
 * Service definition
 */
.factory('ScrollPosition', function($window, $timeout) {

  //Service
  const ScrollPosition = {

    //Init
    state: null,
    position: 0,
    positions: {},
    isBlocked: false,

    /**
     * Set position
     */
    setPosition(position) {
      this.position = position;
    },

    /**
     * Set state
     */
    setState(state) {
      this.state = state;
    },

    /**
     * Remember a scroll position
     */
    remember(state) {
      state = state || this.state;
      if (state) {
        this.positions[state] = this.position;
      }
    },

    /**
     * Restore position of certain type
     */
    restore(state, force, behavior = 'auto') {

      //Use current state if none given
      state = state || this.state;

      //Check if can
      if (!state || typeof this.positions[state] === 'undefined') {
        return;
      }

      //Check if blocked
      if (this.isBlocked && !force) {
        return;
      }

      //Restore
      $timeout(() => {
        if ('scrollBehavior' in document.documentElement.style) {
          window.scrollTo({top: this.positions[state], behavior});
        }
        else {
          window.scrollTo(0, this.positions[state]);
        }
      });
    },

    /**
     * Block from restoring
     */
    block() {
      this.isBlocked = true;
    },

    /**
     * Unblock
     */
    unblock() {
      this.isBlocked = false;
    },
  };

  //Listen for scroll events and remember position
  angular.element($window).on('scroll resize', () => {
    ScrollPosition.setPosition($window.pageYOffset);
  });

  //Listen for pop state events (back/forward browsing) and restore position
  $window.addEventListener('popstate', () => {
    ScrollPosition.unblock();
    ScrollPosition.restore();
  });

  //Return the service
  return ScrollPosition;
});
