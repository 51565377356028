
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.SplitTransaction.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('splitTransaction', {
    templateUrl: 'admin/finance/modals/split-transaction.html',
    controller: 'ModalSplitTransactionCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalSplitTransactionCtrl', function(
  $controller, $modalInstance, $focus, $element
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set flags
    this.isSplitting = false;
    this.isError = false;

    //Initialize model
    this.model = {
      period: 1,
      unit: 'month',
    };

    //Options for time units
    this.units = [
      {
        value: 'day',
        label: 'day(s)',
      },
      {
        value: 'week',
        label: 'week(s)',
      },
      {
        value: 'month',
        label: 'month(s)',
      },
    ];

    //Focus on first input
    $focus($element.find('input')[0]);
  };

  /**
   * Update parts
   */
  this.updateParts = function() {

    //Get data and validate
    const {num, period, unit} = this.model;
    if (!num || !period || !unit) {
      return;
    }

    //Determine amount per part
    const amount = this.transaction.amount;
    const perPart = Math.floor(amount * 100 / num) / 100;
    const rest = amount - (perPart * num);

    //Too little?
    if (perPart < 1) {
      return;
    }

    //Determine initial due date
    const initialDate = this.transaction.dueDate.clone();

    //Create parts
    this.parts = [];
    for (let i = 1; i <= num; i++) {

      //Determine amount for part
      let amount = perPart;
      if (i === num) {
        amount = Math.round((amount + rest) * 100) / 100;
      }

      //Determine due date and increment for next cycle
      const dueDate = initialDate.clone();
      initialDate.add(period, unit);

      //Determine details
      const details = this.transaction.details + ' (' + i + ' of ' + num + ')';

      //Add part
      this.parts.push({amount, dueDate, details});
    }
  };

  /**
   * Update due date
   */
  this.updateDueDate = function(part, date) {
    part.dueDate = date;
  };

  /**
   * Check total
   */
  this.checkTotal = function() {
    const total = this.parts.reduce((total, part) => {
      return Math.round((total + Number(part.amount)) * 100) / 100;
    }, 0);
    this.isInvalidTotal = (total !== this.transaction.amount);
  };

  /**
   * Split
   */
  this.split = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid || this.isInvalidTotal) {
      return;
    }

    //Reset flags
    this.isSplitting = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.parts)
      .then(result => $modalInstance.resolve(result))
      .catch(() => this.isError = true)
      .finally(() => this.isSplitting = false);
  };
});
