
/**
 * Module definition and dependencies
 */
angular.module('Shared.ReplacementTags.Service', [])

/**
 * Service definition
 */
.factory('ReplacementTags', function() {

  /**
   * Class
   */
  class ReplacementTags {

    /**
     * Return merged tags
     */
    merged(basic, customFields, prefix = '') {

      //Generate custom field tags
      const custom = customFields
        .filter(field => field.type !== 'file' && field.type !== 'boolean')
        .map(field => `${prefix}${field.tag}`);

      //Merge with basic tags
      return basic.concat(custom);
    }

    /**
     * Get member tags
     */
    member(customFields = []) {
      return this.merged([
        'NAME',
        'FIRST_NAME',
        'LAST_NAME',
        'EMAIL',
        'PHONE',
        'MOBILE',
        'NUMBER',
        'AMOUNT_OWING',
        'ACCOUNT_CREDIT',
      ], customFields);
    }

    /**
     * Get contact tags
     */
    contact(customFields = []) {
      return this.merged([
        'NAME',
        'EMAIL',
        'PHONE',
        'MOBILE',
      ], customFields);
    }

    /**
     * Get event attendee tags
     */
    eventAttendee(customFields = []) {
      return this.merged([
        'FIRST_NAME',
        'LAST_NAME',
        'CLUB_NAME',
        'EVENT_NAME',
        'EVENT_DATE',
        'EVENT_TIME',
        'EVENT_URL',
        'EVENT_MEETING_URL',
      ], customFields);
    }
  }

  //Return instance
  return new ReplacementTags();
});
