
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.POLi.Strategy.Card', [])

/**
 * Component
 */
.component('cardIntegrationsPoliStrategy', {
  templateUrl: 'admin/integrations/poli/strategy.html',
  controller: 'CardIntegrationsPoliStrategyCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    country: '<',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsPoliStrategyCtrl', function(
  $controller, $modal, PaymentFeeCover
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set services
    this.exampleAmount = 100;
    this.hasAccount = (this.data.merchantCode && this.data.authCode);
    this.PaymentFeeCover = PaymentFeeCover;

    //Initialize cover data
    this.initializeCover();
    this.updateNonProfitStatus(this.data.isNonProfit || false, true);
  };

  /**
   * Initialize cover
   */
  this.initializeCover = function() {
    this.data.cover = this.data.cover || {};
    const {cover} = this.data;
    if (!cover.domestic) {
      cover.domestic = {
        strategy: PaymentFeeCover.NONE,
        structure: {},
      };
    }
    if (!cover.domesticNonProfit) {
      cover.domesticNonProfit = {
        strategy: PaymentFeeCover.NONE,
        structure: {},
      };
    }
  };

  /**
   * Update non-profit status
   */
  this.updateNonProfitStatus = function(isNonProfit, calledOnInit = false) {
    this.data.isNonProfit = isNonProfit;
    this.feeType = isNonProfit ?
      'domesticNonProfit' :
      'domestic';
    if (isNonProfit && !calledOnInit) {
      $modal.open('basic', {
        templateUrl: 'admin/integrations/modals/confirm-non-profit-rates.html',
        locals: {method: 'poli'},
      });
    }
  };

  /**
   * Validation
   */
  this.validate = function() {

    //Parent validation
    if (!$base.validate.call(this)) {
      return false;
    }

    //Clear flags
    this.isMissingCover = false;

    //Get data
    const {feeType} = this;
    const cover = this.data.cover[feeType];

    //Ensure structure is always present
    if (!cover.structure) {
      cover.structure = {};
    }

    //If fee strategy is partial, must have at least some values
    if (cover.strategy === PaymentFeeCover.PART) {
      if (!cover.structure.factor && !cover.structure.fixed) {
        this.isMissingCover = true;
      }
    }

    //Check if missing
    return !this.isMissingCover;
  };

  /**
   * On saved handler
   */
  this.onSaved = function() {
    if (this.isConnected) {
      this.card.showTab('status');
    }
    else {
      this.card.showTab('account');
    }
  };
});
