
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.Slot.Component', [
  'App.Booking.Grid.SlotPeople.Component',
  'App.Booking.Grid.SlotBooking.Component',
  'App.Booking.Grid.SlotStatus.Component',
  'App.Booking.Grid.SlotEvent.Component',
])

/**
 * Booking slot component
 */
.component('bookingGridSlot', {
  template: `
    <div class="SlotWrapper">
      <booking-grid-slot-status
        ng-if="$ctrl.slot.hasStatus && $ctrl.slot.isCurrent && $ctrl.isShowingCurrent && !$ctrl.hasEvents"
        ng-click="$ctrl.viewStatus($event)"
        time="$ctrl.slot.time"
        area="$ctrl.slot.area"
        activities="$ctrl.activities"
        has-tags="$ctrl.slot.area.hasTags"
        is-showing-names="$ctrl.isShowingNames"
      ></booking-grid-slot-status>

      <booking-grid-slot-booking
        ng-if="$ctrl.slot.booking && (!$ctrl.slot.isCurrent || !$ctrl.isShowingCurrent) && !$ctrl.hasEvents"
        ng-click="$ctrl.viewBooking($event)"
        user="$ctrl.user"
        time="$ctrl.slot.time"
        area="$ctrl.slot.area"
        booking="$ctrl.slot.booking"
        needs-payment="$ctrl.slot.booking.needsPayment"
        is-peak="$ctrl.slot.isPeak"
        is-upcoming="$ctrl.slot.isUpcoming"
        is-showing-names="$ctrl.isShowingNames"
        on-waiting-list="$ctrl.onWaitingList({$event})"
      ></booking-grid-slot-booking>

      <booking-grid-slot-event
        ng-repeat="event in $ctrl.slot.events"
        user="$ctrl.user"
        area="$ctrl.slot.area"
        gap="$ctrl.slot.gap"
        time="$ctrl.slot.time"
        time-range="$ctrl.timeRange"
        is-first="$ctrl.slot.isFirst"
        event="event"
      ></booking-grid-slot-event>

      <span class="Slot-text" ng-if="!$ctrl.slot.isDisabled">
        {{$ctrl.slot.time | time}}
        <br>
        <small ng-if="$ctrl.slot.isPeak">peak time</small>
      </span>
    </div>
  `,
  bindings: {
    slot: '<',
    user: '<',
    activities: '<',
    timeRange: '<',
    isShowingNames: '<',
    isShowingCurrent: '<',
    onViewBooking: '&',
    onViewStatus: '&',
    onWaitingList: '&',
  },
  controller() {

    /**
     * On changes
     */
    this.$onChanges = function() {
      const {events} = this.slot;
      this.hasEvents = (events && events.length > 0);
    };

    /**
     * Click handler for bookings
     */
    this.viewBooking = function($event) {

      //Stop propagation
      if ($event) {
        $event.stopPropagation();
      }

      //Get booking
      const {booking} = this.slot;

      //View booking
      this.onViewBooking({$event: {booking}});
    };

    /**
     * Click handler for status
     */
    this.viewStatus = function($event) {

      //Stop propagation
      if ($event) {
        $event.stopPropagation();
      }

      //Get booking and area
      const {area, booking} = this.slot;

      //View status
      this.onViewStatus({$event: {area, booking}});
    };
  },
});
