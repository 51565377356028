
/**
 * Module definition and dependencies
 */
angular.module('Shared.Modal.Controller', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('basic', {
    controller: 'ModalCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalCtrl', function($modalInstance) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flags
    this.isConfirmed = false;
    this.isConfirming = false;

    //Ensure we have a model
    if (!this.model || typeof this.model !== 'object') {
      this.model = {};
    }

    //Map model properties
    for (const key in this.model) {
      if (this.model.hasOwnProperty(key)) {
        this[key] = this.model[key];
      }
    }
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target = this.model) {
    target[property] = value;
  };

  /**
   * Update meta data
   */
  this.updateMeta = function(property, value) {
    this.model.meta[property] = value;
  };

  /**
   * Set confirmed
   */
  this.setConfirmed = function(isConfirmed) {
    this.isConfirmed = isConfirmed;
  };

  /**
   * Set step
   */
  this.setStep = function(step) {
    this.step = step;
  };

  /**
   * Validate
   */
  this.validate = function() {
    if (this.form) {
      this.form.$setSubmitted();
      return this.form.$valid;
    }
  };

  /**
   * Confirm
   */
  this.confirm = function(...args) {

    //Validate
    if (this.form && !this.validate()) {
      return;
    }

    //Clear error
    this.error = null;

    //Pass model to arguments by default
    if (args.length === 0) {
      args.push(this.model);
    }

    //Async handler?
    if (this.handler && angular.isFunction(this.handler)) {
      this.isConfirming = true;
      return this
        .handler(...args)
        .then(result => $modalInstance.resolve(result))
        .catch(error => this.error = error)
        .finally(() => this.isConfirming = false);
    }

    //Sync
    $modalInstance.resolve(this.model);
  };

  /**
   * Resolve with a static value
   */
  this.resolve = function(value) {
    $modalInstance.resolve(value);
  };

  /**
   * Close
   */
  this.close = this.cancel = function() {
    $modalInstance.close('cancel');
  };
});
