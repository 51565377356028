
/**
 * Module definition and dependencies
 */
angular.module('Shared.BookingStore.Service', [
  'Store.CollectionStore.Service',
])

/**
 * Model definition
 */
.factory('BookingStore', function(
  $collectionStore, $q, $cacheFactory
) {

  //Get or create cache
  const cache = $cacheFactory.get('bookings') || $cacheFactory('bookings');
  let life = 60 * 1000;

  /**
   * Constructor
   */
  function BookingStore(name, config) {
    $collectionStore.call(this, name, config);
  }

  /**
   * Extend prototype
   */
  angular.extend(BookingStore.prototype, $collectionStore.prototype);

  /**
   * Set life
   */
  BookingStore.prototype.setLife = function(seconds) {
    life = seconds * 1000;
  };

  /**
   * Identifier generator based on date
   */
  BookingStore.prototype.makeIdentifier = function(activity, date) {
    return angular.toJson({activity, date});
  };

  /**
   * Save item
   */
  BookingStore.prototype.save = function(item, data) {
    return this.validateIsModel(item, true)
      .then(item => item.save(data))
      .then(item => this.add(item))
      .then(item => {

        //Make identifier
        const identifier = this.makeIdentifier(item.activity.id, item.date);

        //Add to cached array
        const data = cache.get(identifier);
        if (data) {
          const i = data.items.findIndex(i => i.isSame(item));
          if (i >= 0) {
            data.items[i] = item;
          }
          else {
            data.items.push(item);
          }
        }

        //Pass on item
        return item;
      });
  };

  /**
   * Delete item
   */
  BookingStore.prototype.delete = function(item, ...args) {
    return this
      .validateIsModel(item)
      .then(item => item.delete(...args))
      .then(item => this.remove(item))
      .then(item => {

        //Make identifier
        const identifier = this.makeIdentifier(item.activity.id, item.date);

        //Remove from cached array
        const data = cache.get(identifier);
        if (data) {
          const i = data.items.findIndex(i => i.isSame(item));
          if (i >= 0) {
            data.items.splice(i, 1);
          }
        }

        //Pass on item
        return item;
      });
  };

  /**
   * Query wrapper
   */
  BookingStore.prototype.query = function(filter, refresh) {

    //Make identifier
    const identifier = this.makeIdentifier(filter.activity, filter.fromDate);

    //Check if cached
    const data = cache.get(identifier);
    if (!refresh && data && (Date.now() - data.timestamp) < life) {
      return $q.resolve(data.items);
    }

    return this.queryAll(filter).then(items => {
      //Save in cache
      cache.put(identifier, {
        timestamp: Date.now(),
        items,
      });

      //Return items
      return items;
    });
  };

  BookingStore.prototype.queryAll = function(filter, offset = 0, prevItems = []) {
    const limit = 100;
    // Adjust filter for pagination
    const paginatedFilter = Object.assign({}, filter, { offset, limit });
    // Use call to ensure this refers to the correct context
    return $collectionStore.prototype.query.call(this, paginatedFilter)
      .then(bookings => {
        // Accumulate bookings
        const accumulatedBookings = prevItems.concat(bookings);

        // Check if we should make another request
        if (bookings.length === limit) {
          // Make another request with updated offset
          return this.queryAll(filter, offset + limit, accumulatedBookings);
        }
        else {
          // We've fetched all bookings, return them
          return accumulatedBookings;
        }
      });
  };

  /**
   * Clear all cached entries
   */
  BookingStore.prototype.clear = function() {

    //Empty cache
    cache.removeAll();

    //Call parent method
    return $collectionStore.prototype.clear.call(this);
  };

  //Return
  return BookingStore;
});
