
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Controller', [])

/**
 * Base controller
 */
.controller('AdminEventCtrl', function(
  $q, $store, $modal, $notice, ErrorCodes, Intercom,
  Integration, GoogleCalendar
) {

  /**
   * On init
   */
  this.$onInit = function() {
    const {event} = this;
    this.setSystemControl(event);
  };

  /**
   * Check if event has system contorl
   */
  this.setSystemControl = function(event) {
    const {system, activities} = this;
    this.hasSystemControl = event.hasSystemControl(system, activities);
  };

  /**
   * Load google calendar integration
   */
  this.loadGoogleCalendars = function() {
    return Integration
      .findByType('googleCalendar')
      .then(integration => {
        if (integration && integration.isConnected) {
          this.googleCalendars = integration.data.enabledCalendars;
          this.isGoogleCalendarConnected = true;
        }
      });
  };

  /**
   * Merge
   */
  this.merge = function($event) {

    //Get data
    const {event, model} = $event;

    //Set flags
    this.isDirty = true;

    //Set flag
    this.setSystemControl(event);

    //Merge
    event.merge(model);
    return $q.resolve();
  };

  /**
   * Save event
   */
  this.save = function($event, isConfirmed = false) {

    //Get model and event
    const {event, model, method, isEdit} = $event;

    //Set flag
    this.setSystemControl(event);

    //Edit
    if (isEdit) {
      return this.patch($event, method);
    }

    //Append confirmed flag
    if (isConfirmed) {
      model.isConfirmed = true;
    }

    //Add
    return $store.events
      .save(event, model)
      .then(event => this.syncToCalendars(event))
      .then(event => {
        Intercom.event('Created event');
        $notice.show('Event created');
        this.club.markStepComplete('setupEvents', true);
        this.onSaved({event});
      })
      .catch(error => {
        if (error.code === ErrorCodes.areaExistingBooking) {
          const {bookings} = error.data;
          return $modal
            .open('basic', {
              templateUrl: 'admin/event/modals/existing-bookings.html',
              locals: {bookings},
              rejectOnDismissal: true,
            })
            .result
            .then(() => this.save($event, true));
        }
        else if (error.code === ErrorCodes.areaExistingEvent) {
          const {events} = error.data;
          return $modal
            .open('basic', {
              templateUrl: 'admin/event/modals/existing-events.html',
              locals: {events},
              rejectOnDismissal: true,
            })
            .result
            .then(() => this.save($event, true));
        }
        else {
          $notice.showError('Failed to create event');
        }
        throw error;
      });
  };

  /**
   * Patch
   */
  this.patch = function($event, method, isConfirmed) {

    //Get model and event
    const {event, model, notice} = $event;

    //Clear the store in case we need to refresh items due to recurrence
    $store.events.clear();

    //Append confirmed flag
    if (isConfirmed) {
      model.isConfirmed = true;
    }

    //Save
    return event
      .patch(model, method)
      .then(event => this.syncToCalendars(event))
      .then((event) => {
        if (notice) {
          $notice.show('Event updated');
        }
        this.onSaved({event});
      })
      .catch(error => {
        if (error.code === ErrorCodes.areaExistingBooking) {
          const {bookings} = error.data;
          return $modal
            .open('basic', {
              templateUrl: 'admin/event/modals/existing-bookings.html',
              locals: {bookings},
              rejectOnDismissal: true,
            })
            .result
            .then(() => this.patch($event, method, true));
        }
        else if (error.code === ErrorCodes.areaExistingEvent) {
          const {events} = error.data;
          return $modal
            .open('basic', {
              templateUrl: 'admin/event/modals/existing-events.html',
              locals: {events},
              rejectOnDismissal: true,
            })
            .result
            .then(() => this.patch($event, method, true));
        }
        else {
          $notice.showError('Failed to update event');
        }
        throw error;
      });
  };

  /**
   * Update recurrence only
   */
  this.updateRecurrence = function($event, isConfirmed) {

    //Get model and event
    const {event, model, notice} = $event;

    //Clear the store in case we need to refresh items due to recurrence
    $store.events.clear();

    //Append confirmed flag
    if (isConfirmed) {
      model.isConfirmed = true;
    }

    //Save
    return event
      .updateRecurrence(model)
      .then(() => {
        if (notice) {
          $notice.show('Event updated');
        }
        this.onSaved({event});
      })
      .catch(error => {
        if (error.code === ErrorCodes.areaExistingBooking) {
          const {bookings} = error.data;
          return $modal
            .open('basic', {
              templateUrl: 'admin/event/modals/existing-bookings.html',
              locals: {bookings},
              rejectOnDismissal: true,
            })
            .result
            .then(() => this.updateRecurrence($event, true));
        }
        else if (error.code === ErrorCodes.areaExistingEvent) {
          const {events} = error.data;
          return $modal
            .open('basic', {
              templateUrl: 'admin/event/modals/existing-events.html',
              locals: {events},
              rejectOnDismissal: true,
            })
            .result
            .then(() => this.updateRecurrence($event, true));
        }
        else {
          $notice.showError('Failed to update event');
        }
        throw error;
      });
  };

  /**
   * Delete
   */
  this.delete = function($event) {

    //Get event and define handler
    const {event} = $event;
    const handler = method => $store.events.delete(event, method);

    //Open confirmation dialog
    $modal
      .open('confirmDeleteEvent', {locals: {event, handler}})
      .result
      .then(event => {
        $notice.show('Event removed');
        this.onDeleted({event});
      });
  };

  /**
   * Sync to calendars
   */
  this.syncToCalendars = function(event) {

    //Google calendar connected
    if (this.isGoogleCalendarConnected) {
      return GoogleCalendar
        .sync(event.id)
        .then(data => Object.assign(event, data))
        .catch(() => $notice.showError(`Failed to sync changes to Google Calendar`));
    }

    //Pass event
    return event;
  };

  /**
   * No-ops (to extend as needed)
   */
  this.onSaved = function() {};
  this.onDeleted = function() {};
});
