
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Edit', [
  'App.Admin.Activity.Edit.Controller',
  'App.Admin.Activity.Edit.Info.Card',
  'App.Admin.Activity.Edit.Details.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.activity.edit', {
    url: '/edit/:activityId',
    component: 'adminActivityEditRoute',
    resolve: {
      activity(transition, $store) {
        const {activityId} = transition.params();
        return $store.activities.findById(activityId);
      },
      memberships($store) {
        return $store
          .memberships
          .query();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminActivityEditRoute', {
  controller: 'AdminActivityEditCtrl',
  templateUrl: 'admin/activity/edit/edit.html',
  bindings: {
    club: '<',
    user: '<',
    activity: '<',
    memberships: '<',
    system: '<',
    page: '<',
    module: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({
    to: 'admin.activity.edit',
  }, transition => {

    //Get activity
    const activity = transition.injector().get('activity');

    //Set page title and crumb
    Page.setTitle(activity.name);
    Page.addCrumb({
      sref: 'admin.activity.edit',
      params: {
        activityId: activity.id,
      },
    });
  });
});
