
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.Xero', [
  'App.Admin.Integrations.Xero.Status.Card',
  'App.Admin.Integrations.Xero.Settings.Card',
  'App.Admin.Integrations.Xero.Contact.Card',
  'App.Admin.Integrations.Xero.Accounts.Card',
  'App.Admin.Integrations.Xero.Reset.Modal',
]);
