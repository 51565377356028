
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.Stop', [
  'App.Subscription.Stop.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('subscription.stop', {
    url: '/stop/:subId',
    component: 'subscriptionStopRoute',
    params: {
      subId: {
        value: null,
        squash: true,
      },
    },
    resolve: {
      subscriptions(Subscription) {
        return Subscription.own();
      },
      current(transition, subscriptions) {
        const params = transition.params();
        if (params.subId) {
          return subscriptions.find(sub => sub.id === params.subId);
        }
        return subscriptions.find(sub => sub.canBeStopped());
      },
    },
  });
})

/**
 * Component
 */
.component('subscriptionStopRoute', {
  controller: 'SubscriptionStopCtrl',
  templateUrl: 'subscription/stop/stop.html',
  bindings: {
    user: '<',
    current: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'subscription.stop'}, transition => {
    const params = transition.params();
    Page.setTitle('Stop subscription');
    Page.addCrumb({sref: 'subscription.stop', params});
  });
});
