
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.TransactionOptions.Component', [])

/**
 * Transaction options component
 */
.component('transactionOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="!$ctrl.transaction.isPaid">
        <a ng-click="$ctrl.markPaid()">Mark as paid</a>
      </li>
      <li ng-if="!$ctrl.transaction.isPaid && $ctrl.transaction.type !== 'accountCredit' && $ctrl.transaction.member">
        <a ng-click="$ctrl.payWithAccountCredit()">Pay with account credit</a>
      </li>
      <li ng-if="!$ctrl.transaction.isPaid && $ctrl.transaction.member">
        <a ng-click="$ctrl.payWithDefaultSource()">Pay with card</a>
      </li>
      <li ng-if="!$ctrl.transaction.isPaid">
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li ng-if="!$ctrl.transaction.isPaid">
        <a ng-click="$ctrl.split()">Split</a>
      </li>
      <li ng-if="$ctrl.transaction.isPaid && $ctrl.transaction.payment.isEditable">
        <a ng-click="$ctrl.editPayment()">Edit payment</a>
      </li>
      <li ng-if="$ctrl.transaction.isPaid && !$ctrl.transaction.isCredit && $ctrl.transaction.payment.isRemovable">
        <a ng-click="$ctrl.markNotPaid()">Mark as not paid</a>
      </li>
      <li>
        <a ng-click="$ctrl.invoice()">Download invoice</a>
      </li>
      <li ng-if="$ctrl.transaction.isPaid">
        <a ng-click="$ctrl.receipt()">Download receipt</a>
      </li>
      <li ng-if="$ctrl.hasEmail && !$ctrl.transaction.isPaid">
        <a ng-click="$ctrl.email()">Notify by email</a>
      </li>
      <li>
        <a ui-sref="admin.log.audit({model: 'Transaction', id: $ctrl.transaction.id})">View audit log</a>
      </li>
      <li ng-if="!$ctrl.transaction.isPaid || !$ctrl.transaction.payment.isOnline" class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    transaction: '<',
    hasEmail: '<',
    onEdit: '&',
    onSplit: '&',
    onEmail: '&',
    onEditPayment: '&',
    onDelete: '&',
    onMarkPaid: '&',
    onMarkNotPaid: '&',
    onPayWithAccountCredit: '&',
    onPayWithDefaultSource: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Edit
     */
    this.edit = function() {
      const {transaction} = this;
      this.onEdit({$event: {transaction}});
    };

    /**
     * Split
     */
    this.split = function() {
      const {transaction} = this;
      this.onSplit({$event: {transaction}});
    };

    /**
     * Edit payment
     */
    this.editPayment = function() {
      const {transaction} = this;
      this.onEditPayment({$event: {transaction}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      const {transaction} = this;
      this.onDelete({$event: {transaction}});
    };

    /**
     * Mark paid
     */
    this.markPaid = function() {
      const {transaction} = this;
      this.onMarkPaid({$event: {transaction}});
    };

    /**
     * Mark not paid
     */
    this.markNotPaid = function() {
      const {transaction} = this;
      this.onMarkNotPaid({$event: {transaction}});
    };

    /**
     * Email
     */
    this.email = function() {
      const {transaction} = this;
      this.isBusy = true;
      this
        .onEmail({$event: {transaction}})
        .finally(() => this.isBusy = false);
    };

    /**
     * Pay with account credit
     */
    this.payWithAccountCredit = function() {
      const {transaction} = this;
      this.isBusy = true;
      this
        .onPayWithAccountCredit({$event: {transaction}})
        .finally(() => this.isBusy = false);
    };

    /**
     * Pay with default source
     */
    this.payWithDefaultSource = function() {
      const {transaction} = this;
      this.isBusy = true;
      this
        .onPayWithDefaultSource({$event: {transaction}})
        .finally(() => this.isBusy = false);
    };

    /**
     * Download invoice
     */
    this.invoice = function() {
      this.isBusy = true;
      this.transaction
        .downloadInvoice()
        .finally(() => this.isBusy = false);
    };

    /**
     * Download receipt
     */
    this.receipt = function() {
      this.isBusy = true;
      this.transaction.payment
        .downloadReceipt()
        .finally(() => this.isBusy = false);
    };
  },
});
