
/**
 * Module definition and dependencies
 */
angular.module('App.Home.Bookings.Card', [])

/**
 * Component
 */
.component('cardHomeBookings', {
  templateUrl: 'home/cards/bookings.html',
  controller: 'CardHomeBookingsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    bookings: '<',
    user: '<',
    activities: '<',
    reloadBookings: '&',
  },
})

/**
 * Controller
 */
.controller('CardHomeBookingsCtrl', function($modal) {

  /**
   * On init
   */
  this.$onInit = function() {
    this.hasMultipleActivities = (this.activities.length > 1);
  };

  /**
   * View booking
   */
  this.viewBooking = function(booking) {

    //Get data
    const {user, activities} = this;
    const showActivity = this.hasMultipleActivities;
    const activity = activities.find(a => a.isSame(booking.activity.id));

    //Open modal
    $modal
      .open('viewBooking', {locals: {booking, user, activity, showActivity}})
      .result
      .then(() => this.reloadBookings());
  };
});
