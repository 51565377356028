
/**
 * Module definition and dependencies
 */
angular.module('Shared.MailingLists.Component', [])

/**
 * Fee example component
 */
.component('mailingLists', {
  template: `
    <div class="MailingLists">
      <div class="MailingList" ng-repeat="list in $ctrl.lists">
        <check-box ng-model="list.isChecked" on-change="$ctrl.toggle(list, value)">
          <strong>{{list.name}}</strong>
        </check-box>
        <div class="MailingList-category" ng-repeat="category in list.categories" ng-if="list.isChecked">
          <span class="text-underline">{{category.name}}</span>
          <check-box ng-repeat="interest in category.interests" ng-model="interest.isChecked" on-change="$ctrl.toggle(interest, value)">
            {{interest.name}}
          </check-box>
        </div>
      </div>
    </div>
  `,
  bindings: {
    lists: '<',
  },
  controller() {

    /**
     * Toggle item
     */
    this.toggle = function(item, isChecked) {
      item.isChecked = isChecked;
    };
  },
});
