
/**
 * Define values
 */
const BOOKINGS = 'bookings';
const EVENTS = 'events';

/**
 * Define array
 */
const CouponTypeUses = [
  {
    label: 'Bookings',
    value: BOOKINGS,
  },
  {
    label: 'Events & classes',
    value: EVENTS,
  },
];

/**
 * Set properties
 */
CouponTypeUses.BOOKINGS = BOOKINGS;
CouponTypeUses.EVENTS = EVENTS;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.CouponTypeUses.Options', [])
  .constant('CouponTypeUses', CouponTypeUses);
