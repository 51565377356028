
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area.AreaOptions.Component', [])

/**
 * Area options component
 */
.component('areaOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="$ctrl.canEdit">
        <a ui-sref="admin.area.edit({areaId: $ctrl.area.id})">Edit</a>
      </li>
      <li>
        <a ui-sref="admin.area.add({areaId: $ctrl.area.id})">Copy</a>
      </li>
      <li>
        <a ui-sref="admin.log.audit({model: 'Area', id: $ctrl.area.id})">View audit log</a>
      </li>
      <li ng-if="$ctrl.canDelete" class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    area: '<',
    canEdit: '<',
    canDelete: '<',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Delete
     */
    this.delete = function() {
      const {area} = this;
      this.onDelete({$event: {area}});
    };
  },
});
