
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.Xero.Reset.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('xeroReset', {
    templateUrl: 'admin/integrations/xero/modals/reset.html',
    controller: 'ModalXeroResetCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalXeroResetCtrl', function(
  $controller, $modalInstance
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Update from date
   */
  this.updateFromDate = function(date) {

    //Set in model
    this.model.fromDate = date;

    //Ensure start of day
    this.fromDate = date.clone().startOf('day');

    //Determine start period based on invoice frequency
    const {invoiceFrequency} = this.data;
    const startPeriod = date.clone().startOf(invoiceFrequency);

    //Ensure from date falls within period
    if (!date.isSame(startPeriod, 'day')) {
      this.fromDate = startPeriod;
    }
  };

  /**
   * Update to date
   */
  this.updateToDate = function(date) {

    //Set in model
    this.model.toDate = date;

    //Ensure end of day
    this.toDate = date.clone().endOf('day');

    //Determine start period based on invoice frequency
    const {invoiceFrequency} = this.data;
    const endPeriod = date.clone().endOf(invoiceFrequency);

    //Ensure to date falls within period
    if (!date.isSame(endPeriod, 'day')) {
      this.toDate = endPeriod;
    }
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Ensure valid
    this.form.$setSubmitted();
    if (!this.form.$valid) {
      return;
    }

    //Toggle flags
    this.isConfirming = true;
    this.isError = false;

    //Get dates
    const {fromDate, toDate} = this;

    //Call handler
    this
      .handler(fromDate, toDate)
      .then(() => $modalInstance.close())
      .catch(() => this.isError = true)
      .finally(() => this.isConfirming = false);
  };
});
