
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.Done', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.done', {
    url: '/done',
    component: 'registerDoneRoute',
  });
})

/**
 * Route component
 */
.component('registerDoneRoute', {
  controller: 'RegisterDoneCtrl',
  templateUrl: 'portal/register/steps/done.html',
  bindings: {
    club: '<',
    registration: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterDoneCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('RegisterBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);
  };
});
