
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Edit.System.Card', [])

/**
 * Component
 */
.component('cardMembershipEditSystem', {
  templateUrl: 'admin/membership/cards/system.html',
  controller: 'CardMembershipEditSystemCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    system: '<',
    modes: '<',
    membership: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipEditSystemCtrl', function(CardCloseReasons) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Mark as edit
    this.isEdit = true;

    //Create model subset
    this.model = this.membership.extract('he600') || {};

    //Set non playing zones
    this.nonPlayingZones = this.system.he600.nonPlayingZones;
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get data
    const {membership, model: he600, isEdit} = this;

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event: {
        membership,
        model: {he600},
        isEdit,
      }})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
