
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.Activities.Card', [
  'App.Admin.People.Members.ActivityOptions.Component',
])

/**
 * Component
 */
.component('cardMemberEditActivities', {
  templateUrl: 'admin/people/members/cards/activities.html',
  controller: 'CardMemberEditActivitiesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    member: '<',
    activities: '<',
    onSave: '&',
    isOwn: '<',
  },
})

/**
 * Controller
 */
.controller('CardMemberEditActivitiesCtrl', function($modal) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set flags
    this.isEdit = true;
    this.isSaving = false;
    this.isSuper = this.user.isSuper();

    //Extract activities model
    this.model = this.member.extract(['activities']);
  };

  /**
   * Get full activity
   */
  this.fullActivity = function(id) {
    return this.activities.find(activity => activity.id === id);
  };

  /**
   * Edit activity
   */
  this.edit = function($event) {

    //Get activity
    const {activity} = $event;
    const {model, member, isEdit} = this;
    const full = this.fullActivity(activity.id);
    const {modes} = full;

    //Define handler
    const eventHandler = isEdit ? this.onSave : this.onMerge;
    const handler = function(data) {
      activity.fromJSON(data);
      return eventHandler({$event: {member, model, isEdit}});
    };

    //Show edit activity modal
    $modal
      .open('editMemberActivity', {
        locals: {activity, modes, handler, isEdit: true},
      });
  };
});
