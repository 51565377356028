
/**
 * Module definition and dependencies
 */
angular.module('Shared.DoorState.Filter', [])

/**
 * Filter definition
 */
.filter('doorState', function() {
  return function(door) {

    //Override/event
    if (door.isInOverride) {
      return 'Override';
    }
    if (door.isInSchedule) {
      return 'Schedule';
    }
    if (door.hasEvent) {
      return 'Event';
    }

    //Door states
    if (door.isOpen || door.isUnlocked) {
      return 'Open';
    }
    else if (door.isLocked) {
      return 'Closed';
    }

    //Unknown
    return 'Unknown';
  };
});
