
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Overview.Controller', [])

/**
 * Controller
 */
.controller('BookingOverviewCtrl', function(
  $filter, $modal, $storage, $notice, moment, ScrollPosition, Pagination, Booking,
  Settings, ErrorCodes, Intercom, YesNo
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed bookings overview');

    //Flags
    this.canEdit = this.user.hasRole('admin', 'bookingManager');
    this.hasMultipleActivities = (this.activities.length > 1);

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load bookings
    this.loadPage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter, module} = this;
    const {title} = module;

    //Set page title and crumb
    page.setTitle(title);
    page.addCrumb({sref: 'bookings'});
    page.addCrumb({sref: 'booking.overview', title: 'Overview'});

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter, activities} = this;
    const includeRemoved = $storage.get('bookings.includeRemoved', false);

    //Set filter defaults
    filter.setDefaults({
      search: '',
      member: null,
      isRemoved: includeRemoved ? null : false,
      includeRemoved: includeRemoved,
      fromDate: moment().startOf('day'),
      toDate: moment().startOf('day').add(1, 'month'),
    });

    //Filter on change handler
    filter.onChange((key, value) => {

      //From date changed
      if (key === 'fromDate') {
        if (filter.fromDate.isAfter(filter.toDate)) {
          filter.toDate = filter.fromDate.clone().endOf('day');
        }
      }

      //To date changed
      else if (key === 'toDate') {
        if (filter.toDate.isBefore(filter.fromDate)) {
          filter.fromDate = filter.toDate.clone().startOf('day');
        }
      }

      //Activity changed, set modes to filter on
      else if (key === 'activity') {
        filter.area = null;
        filter.mode = null;
        if (value) {
          const activity = filter.options.activity.find(a => a.id === value);
          filter.options.mode = activity.modes.map(mode => mode);
          filter.options.area = this.areas
            .filter(area => area.activity === value);
        }
        else {
          filter.options.mode = [];
          filter.options.area = [];
        }
      }

      //Include hidden
      if (key === 'includeRemoved') {
        $storage.set('bookings.includeRemoved', value);
        if (value) {
          filter.isRemoved = null;
        }
        else {
          filter.isRemoved = false;
        }
      }

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      activity: activities,
      isPaid: YesNo,
      mode: [],
      area: [],
    };

    //Only one activity? Set it
    if (activities.length === 1) {
      filter.activity = activities[0].id;
      filter.options.mode = activities[0].modes.map(mode => mode);
      filter.options.area = this.areas
        .filter(area => area.activity === activities[0].id);
    }
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    this.loadBookings();
  };

  /**
   * Export bookings
   */
  this.export = function() {

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club: this.club, action: `Exporting bookings`},
      });
    }

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'bookings', action: 'export'},
      });
    }

    //Get filter and export
    const filter = this.makeFilter();
    return Booking.export(filter);
  };

  /**
   * View booking
   */
  this.view = function($event) {

    //Get data
    const {booking} = $event;
    const {user, activities} = this;
    const showActivity = this.hasMultipleActivities;
    const activity = activities.find(a => a.isSame(booking.activity.id));

    //Open modal
    $modal
      .open('viewBooking', {locals: {booking, user, activity, showActivity}})
      .result
      .then(() => this.loadBookings());
  };

  /**
   * Pay with coupons
   */
  this.payWithCoupons = function($event) {

    //Get data
    const {booking} = $event;
    const {members, activity, mode, coupons} = booking;
    const {sessionsNeeded} = coupons;
    const label = `${activity.name} booking (${mode.name})`;

    //Filter for eligible coupons
    const filter = {
      areUsable: true,
      usableFor: ['bookings'],
      members: members.map(member => member.id),
      mode: mode.id,
    };

    //Define handler
    const handler = function(coupons) {
      return booking.useCoupons(coupons);
    };

    //Open modal
    $modal
      .open('payWithCoupons', {
        locals: {label, sessionsNeeded, activity, filter, members, handler},
      })
      .result
      .then(() => $notice.show('Booking paid'))
      .catch(error => {
        if (error.code === ErrorCodes.paymentMethodInvalid) {
          $notice.showError('Not enough sessions to pay for booking');
        }
        else {
          $notice.showError('Booking payment failed');
        }
      });
  };

  /**
   * Pay with account credit
   */
  this.payWithAccountCredit = function($event) {

    //Get data
    const {booking} = $event;
    const {members, activity, mode, fee} = booking;
    const {amount} = fee;
    const label = `${activity.name} booking (${mode.name})`;

    //Define handler
    const handler = data => booking.markPaid(data);

    //Open modal
    $modal
      .open('payWithAccountCredit', {
        locals: {label, amount, members, handler},
      })
      .result
      .then(() => $notice.show('Booking paid'))
      .catch((error) => {
        if (error !== 'cancel') {
          $notice.showError('Booking payment failed');
        }
      });
  };

  /**
   * Mark as paid
   */
  this.markAsPaid = function($event) {

    //Get data
    const {booking} = $event;

    //Define handler
    const handler = function(data) {
      return booking.markPaid(data);
    };

    //Open modal
    $modal
      .open('markBookingAsPaid', {
        locals: {booking, handler},
      })
      .result
      .then(() => $notice.show('Booking paid'))
      .catch(() => $notice.showError('Booking payment failed'));
  };

  /**
   * Remove booking
   */
  this.delete = function($event) {

    //Define handler
    const {booking} = $event;
    const {user} = this;
    const handler = data => booking.delete(data);

    //Open modal
    return $modal
      .open('removeBooking', {locals: {booking, user, handler}})
      .result
      .then(() => {

        //Refresh user's account credit
        if (booking.isOwner(user) || booking.hasMember(user)) {
          user.refreshAccountCredit();
        }

        //Show notice
        $notice.show('Booking removed');
        this.loadBookings();
      });
  };

  /**
   * Load bookings
   */
  this.loadBookings = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query bookings
    return Booking
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, bookings} = data;

    //Set in scope
    this.bookings = bookings;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //Remove helper flag
    delete filter.includeRemoved;

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadBookings()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };
});
