
/**
 * Module definition and dependencies
 */
angular.module('Shared.AuditLogTypes.Options', [])

/**
 * Constant definition
 */
.constant('AuditLogTypes', [
  {
    value: 'create',
    label: 'Create',
  },
  {
    value: 'update',
    label: 'Update',
  },
  {
    value: 'remove',
    label: 'Remove',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'integration',
    label: 'Integration',
  },
  {
    value: 'system',
    label: 'System',
    isSystem: true,
  },
  {
    value: 'other',
    label: 'Other',
  },
]);
