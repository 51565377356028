
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Overview', [
  'App.Admin.CouponType.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.couponType.overview', {
    url: '',
    component: 'adminCouponTypeRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('couponTypes');
      },
      memberships($store) {
        return $store.memberships.query();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminCouponTypeRoute', {
  controller: 'AdminCouponTypeOverviewCtrl',
  templateUrl: 'admin/coupon-type/overview/overview.html',
  bindings: {
    filter: '<',
    page: '<',
    user: '<',
    activities: '<',
    memberships: '<',
    module: '<',
  },
});
