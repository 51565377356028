
/**
 * Card footer component
 */

/**
 * Module definition and dependencies
 */
angular.module('Shared.Cards.CardFooter.Component', [])

/**
 * Component
 */
.component('cardFooter', {
  template: `<footer class="CardFooter" ng-transclude></footer>`,
  transclude: true,
});
