
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.Xero.Contact.Card', [])

/**
 * Component
 */
.component('cardIntegrationsXeroContact', {
  templateUrl: 'admin/integrations/xero/contact.html',
  controller: 'CardIntegrationsXeroContactsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsXeroContactsCtrl', function(
  $controller, Xero, Config
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Default contact name
    this.name = Config.app.title;
    this.isChoosing = !!this.data.contact;

    //Flags
    this.hasAccounts = !!this.integration.data.accounts;

    //Load contacts
    if (this.isConnected) {
      this.loadContacts();
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Load contacts
   */
  this.loadContacts = function() {

    //Flag
    this.isLoading = true;

    //Get contacts
    Xero
      .getContacts()
      .then(contacts => {

        //Set contacts
        this.contacts = contacts;

        //Find matching contact
        if (!this.isChoosing) {
          const match = contacts.find(contact => contact.name === this.name);
          if (match) {
            this.updateContact(match);
            this.isChoosing = true;
          }
        }
      })
      .catch(error => {
        if (error.code === 'NOT_CONNECTED') {
          this.noLongerConnected();
        }
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Choose contact
   */
  this.chooseContact = function() {
    this.isChoosing = true;
  };

  /**
   * Reset errors
   */
  this.resetErrorFlags = function() {
    this.nameRequired = false;
    this.nameExists = false;
  };

  /**
   * Create contact
   */
  this.createContact = function() {

    //Choosing? Go back first
    if (this.isChoosing) {
      this.isChoosing = false;
      return;
    }

    //Reset error flags
    this.resetErrorFlags();

    //Get name
    const {name} = this;
    if (!name) {
      this.nameRequired = true;
      return;
    }

    //Check if already exists
    const existing = this.contacts.find(contact => contact.name === name);
    if (existing) {
      this.nameExists = true;
      return;
    }

    //Flag
    this.isCreating = true;

    //Create contact and save integration
    Xero
      .createContact({name})
      .then(contact => {
        this.updateContact(contact);
        this.contacts.push(contact);
        this.isChoosing = true;
      })
      .catch(error => {
        if (error.code === 'NOT_CONNECTED') {
          this.noLongerConnected();
        }
      })
      .finally(() => this.isCreating = false);
  };

  /**
   * Update contact
   */
  this.updateContact = function(contact) {
    this.data.contact = contact;
  };

  /**
   * On saved handler
   */
  this.onSaved = function() {
    if (this.hasAccounts) {
      this.card.showTab('status');
    }
    else {
      this.card.showTab('accounts');
    }
  };
});
