
/**
 * Module definition and dependencies
 */
angular.module('App.Error.ErrorInterceptor.Service', [])

/**
 * Interceptor service
 */
.factory('ErrorInterceptor', function(
  $injector, Errors, Request,
  NetworkError, TimeoutError, ServerError, ServiceUnavailableError
) {

  //Placeholders
  let $notice, networkNotice;

  //Network error count
  let networkErrorCount = 0;

  //Threshold for number of network errors to occur before we show a notice
  const networkErrorCountThreshold = 3;

  /**
   * Interceptor
   */
  return {

    /**
     * Successful response
     */
    response(response) {

      //Ignore template requests
      if (Request.isTemplate(response.config)) {
        return response;
      }

      //Reset network error count
      networkErrorCount = 0;

      //Hide network notice
      if (networkNotice) {
        networkNotice.hide();
        networkNotice = null;
      }

      //Pass on response
      return response;
    },

    /**
     * Response error handling
     */
    responseError(response) {

      //Determine error class and create new error
      const ErrorClass = Errors.getClass(response);
      const error = new ErrorClass(response);

      //Network errors
      if (error instanceof NetworkError) {

        //Increment network error count
        networkErrorCount++;

        //If threshold reached, show notice
        if (networkErrorCount >= networkErrorCountThreshold) {
          if (!networkNotice) {
            $notice = $notice || $injector.get('$notice');
            $notice.hideLoading();
            networkNotice = $notice.showError('No network connection', {
              icon: 'warning',
              hideAutomatically: false,
            });
          }
        }

        //Retry request once if it's a GET
        if (response.config.method === 'GET') {
          if (!response.config.ignoreNetworkError) {
            response.config.ignoreNetworkError = true;
            return Request.retry(response.config, 1000);
          }
        }
      }

      //Timeout errors
      else if (error instanceof TimeoutError) {
        if (!response.config.ignoreTimeoutError) {
          Errors.process(error);
        }
      }

      //Service unavailable errors
      else if (error instanceof ServiceUnavailableError) {
        //Fall through so it's not considered a server error
      }

      //Server errors
      else if (error instanceof ServerError) {
        if (!response.config.ignoreServerError) {
          Errors.process(error);
        }
      }

      //Rethrow
      throw error;
    },
  };
});
