
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.CouponOptions.Component', [])

/**
 * Coupon options component
 */
.component('memberCouponOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="$ctrl.coupon.isActive && $ctrl.coupon.numSessions">
        <a ng-click="$ctrl.toggleActive(false)">Deactivate</a>
      </li>
      <li ng-if="!$ctrl.coupon.isActive && $ctrl.coupon.numSessions">
        <a ng-click="$ctrl.toggleActive(true)">Activate</a>
      </li>
      <li ng-if="$ctrl.coupon.expiryDate">
        <a ng-click="$ctrl.updateExpiry()">Update expiry date</a>
      </li>
      <li>
        <a ui-sref="admin.log.audit({model: 'Coupon', id: $ctrl.coupon.id})">View audit log</a>
      </li>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    coupon: '<',
    onDelete: '&',
    onToggleActive: '&',
    onUpdateExpiry: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Delete
     */
    this.delete = function() {
      const {coupon} = this;
      this.onDelete({$event: {coupon}});
    };

    /**
     * Toggle active
     */
    this.toggleActive = function(isActive) {
      const {coupon} = this;
      this.isBusy = true;
      this
        .onToggleActive({$event: {coupon, isActive}})
        .finally(() => this.isBusy = false);
    };

    /**
     * Toggle active
     */
    this.updateExpiry = function() {
      const {coupon} = this;
      this.onUpdateExpiry({$event: {coupon}});
    };
  },
});
