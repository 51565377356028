/**
 * Module definition and dependencies
 */
angular.module('Shared.Debounce.Service', [])

/**
 * Service definition
 */
.factory('$debounce', function($timeout) {

  /**
   * Debounce call
   */
  return function $debounce(fn, ctx, wait, immediate) {
    if (typeof ctx === Number) {
      immediate = wait;
      wait = ctx;
      ctx = null;
    }
    let timeout, result;
    function debounce(...args) {
      const later = function() {
        timeout = null;
        if (!immediate) {
          result = fn.call(ctx, ...args);
        }
      };
      const callNow = immediate && !timeout;
      if (timeout) {
        $timeout.cancel(timeout);
      }
      timeout = $timeout(later, wait);
      if (callNow) {
        result = fn.call(ctx, ...args);
      }
      return result;
    }
    debounce.cancel = function() {
      $timeout.cancel(timeout);
      timeout = null;
    };
    return debounce;
  };
});
