
/**
 * Module definition and dependencies
 */
angular.module('Shared.Guest.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config($apiProvider => {

  //Register API endpoint
  $apiProvider.registerEndpoint('guest', {
    model: 'Guest',
    actions: {
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Guest', function($baseModel, $api) {

  /**
   * Constructor
   */
  function Guest(data) {

    //Call parent constructor
    $baseModel.call(this, angular.extend({}, data || {}));

    //Full name
    Object.defineProperty(this, 'name', {
      set() {},
      get() {
        return String(
          (this.firstName || '') + ' ' + (this.lastName || '')
        ).trim();
      },
    });

    //Initials
    Object.defineProperty(this, 'initials', {
      get() {
        let initials = '';
        if (this.firstName) {
          initials += this.firstName[0];
        }
        if (this.lastName) {
          let parts = this.lastName.split(' ');
          let part = parts.find(part => (part[0] === part[0].toUpperCase()));
          if (!part) {
            part = this.lastName;
          }
          initials += part[0];
        }
        return initials;
      },
    });
  }

  /**
   * Extend prototype
   */
  angular.extend(Guest.prototype, $baseModel.prototype);

  /**
   * Save
   */
  Guest.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.guest[method](data)
      .then(data => this.fromJSON(data));
  };

  //Return
  return Guest;
});
