
/**
 * Module definition and dependencies
 */
angular.module('Shared.Integration.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('integration', {
    model: 'Integration',
    params: {
      type: '@type',
      method: '@method',
    },
    actions: {
      query: {
        method: 'GET',
        dataKey: 'integrations',
        isArray: true,
        isModel: true,
      },
      get: {
        url: '/:type',
        method: 'GET',
        isModel: true,
      },
      update: {
        url: '/:type',
        method: 'PUT',
      },
      setCustom: {
        url: '/:type/isCustom',
        method: 'PUT',
      },
      connect: {
        url: '/:type',
        method: 'POST',
      },
      disconnect: {
        url: '/:type',
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('integrations', {
    model: 'Integration',
    dataKey: 'integrations',
    cacheEmpty: true,
  });
})

/**
 * Model definition
 */
.factory('Integration', function($baseModel, $api, $window, $injector) {

  /**
   * Default data
   */
  const defaults = {
    data: {},
    settings: {},
  };

  /**
   * Constructor
   */
  function Integration(data) {

    //Base constructor
    $baseModel.call(this, angular.extend({}, defaults, data || {}));

    //Category property
    Object.defineProperty(this, 'category', {
      get() {
        switch (this.type) {
          case 'stripe':
          case 'poli':
            return 'payments';
          case 'mailchimp':
          case 'campaignMonitor':
            return 'mailing';
          case 'xero':
            return 'finance';
          default:
            return 'other';
        }
      },
    });

    //Service property
    Object.defineProperty(this, 'service', {
      get() {
        const serviceClass = this.type[0].toUpperCase() + this.type.substring(1);
        return $injector.get(serviceClass);
      },
    });

    //Helper property to indicate an integration is for payments and has been
    //connected and configured
    Object.defineProperty(this, 'isOkForPayment', {
      get() {
        return (
          this.category === 'payments' &&
          this.isConnected &&
          this.data && this.data.cover
        );
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Integration.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Integration.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Return self
    return this;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Update
   */
  Integration.prototype.update = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Update
    return $api.integration
      .update(data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Toggle custom flag
   */
  Integration.prototype.setCustom = function(isCustom) {

    //Get type
    const {type} = this;

    //Update
    return $api.integration
      .setCustom({type}, {isCustom})
      .then(data => this.fromJSON(data));
  };

  /**
   * Connect integration
   */
  Integration.prototype.connect = function(data) {
    const {type} = this;
    return $api.integration
      .connect({type}, data)
      .then(data => {
        if (data.redirect) {
          return $window.location.href = data.redirect;
        }
        return this.fromJSON(data);
      });
  };

  /**
   * Disconnect integration
   */
  Integration.prototype.disconnect = function() {
    const {type} = this;
    return $api.integration
      .disconnect(null, {type})
      .then(() => {
        this.isConnected = false;
        return this;
      });
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Integration.query = function() {
    return $api.integration.query();
  };

  /**
   * Find by type
   */
  Integration.findByType = function(type) {
    return $api.integration.get({type});
  };

  //Return
  return Integration;
});
