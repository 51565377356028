
/**
 * Module definition and dependencies
 */
angular.module('Shared.Stripe.Service', [])

/**
 * Service definition
 */
.factory('$stripe', function($window) {

  //Placeholder for already instantiated services
  const instances = {};

  //Service wrapper
  return {

    //Token
    token: null,

    /**
     * Get the underlying service, instantiates if needed
     */
    service(publicKey) {

      //Must have global Stripe variable at this stage
      if (!$window.Stripe) {
        throw new Error('Stripe failed to load, please refresh the page');
      }

      //Must have public key
      if (!publicKey) {
        throw new Error('No public key specified');
      }

      //Already instantiated
      if (typeof instances[publicKey] !== 'undefined') {
        return instances[publicKey];
      }

      //Instantiate service
      return (instances[publicKey] = $window.Stripe(publicKey));
    },
  };
});
