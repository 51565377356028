
/**
 * Define values
 */
const PER_SESSION = 'perSession';
const PER_MINUTE = 'perMinute';

/**
 * Define array
 */
const FeeHandlingMethods = [
  {
    value: PER_SESSION,
    label: 'Fee is charged per session',
  },
  {
    value: PER_MINUTE,
    label: 'Fee is charged per minute of actual time',
  },
];

/**
 * Set properties
 */
FeeHandlingMethods.PER_SESSION = PER_SESSION;
FeeHandlingMethods.PER_MINUTE = PER_MINUTE;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.FeeHandlingMethods.Options', [])
  .constant('FeeHandlingMethods', FeeHandlingMethods);
