
/**
 * Module definition and dependencies
 */
angular.module('Shared.Mode.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('mode', {
    model: 'Mode',
    actions: {
      query: {
        method: 'GET',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('modes', {
    model: 'Mode',
    cacheEmpty: true,
  });
})

/**
 * Model definition
 */
.factory('Mode', function($baseModel, $api, FeeTypes, FeeHandlingMethods) {

  /**
   * Constructor
   */
  function Mode(data) {
    $baseModel.call(this, data);

    //Fee handling method helper properties
    Object.defineProperty(this, 'isFeePerSession', {
      get() {
        return (this.feeHandling === FeeHandlingMethods.PER_SESSION);
      },
    });
    Object.defineProperty(this, 'isFeePerMinute', {
      get() {
        return (this.feeHandling === FeeHandlingMethods.PER_MINUTE);
      },
    });

    //Fee type helper properties
    Object.defineProperty(this, 'isFeePerPerson', {
      get() {
        return (this.feeType === FeeTypes.PER_PERSON);
      },
    });
    Object.defineProperty(this, 'isFeePerArea', {
      get() {
        return (this.feeType === FeeTypes.PER_AREA);
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Mode.prototype, $baseModel.prototype);

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * To bare mode
   */
  Mode.prototype.toBare = function() {
    const {id, name} = this;
    return {id, name};
  };

  /**
   * Save
   */
  Mode.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.mode[method](data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Mode.prototype.delete = function() {
    return $api.mode
      .delete(null, this)
      .then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Mode.query = function() {
    return $api.mode.query();
  };

  /**
   * Find by ID
   */
  Mode.findById = function(id) {
    return $api.mode.get({id});
  };

  //Return
  return Mode;
});
