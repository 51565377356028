
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.Transactions.Controller', [])

/**
 * Controller
 */
.controller('AdminFinanceTransactionsCtrl', function(
  $notice, $filter, $modal, moment, ScrollPosition, Pagination, Settings,
  Payment, Transaction, Intercom, TransactionTypes, Member, Integration
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed transactions');

    //Initialize
    this.isLoading = true;
    this.canEdit = this.user.isAdmin();
    this.isSuper = this.user.isSuper();

    //Setup filter and page
    this.setupFilter();
    this.setupPage();
    this.checkHasDatesFilter();
    this.loadStripeIntegration();

    //Load transactions
    this.loadPage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Set page title and crumb
    page.setTitle('Transactions');
    page.addCrumb({sref: 'admin.finance.transactions'});

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter} = this;

    //Set filter defaults
    filter.setDefaults({
      search: '',
      view: 0,
      member: null,
      includeArchived: false,
    });

    //Filter on change handler
    filter.onChange((key, value) => {

      //Keep dates in check
      const {toDate, fromDate} = filter;
      const hasDates = toDate && fromDate;

      //Searching? Must not be on recently paid and now due
      if (key === 'search' && value && filter.view === 0) {
        this.page.showFilters = true;
        filter.view = 1;
        filter.mapOptions('view', 1);
      }

      //Merge
      if (key === 'view') {
        filter.mapOptions(key, value);
      }

      //No dates, set default
      if (!hasDates) {
        filter.fromDate = moment().subtract(1, 'months').startOf('day');
        filter.toDate = moment().endOf('day');
      }

      //From date changed
      if (hasDates && key === 'fromDate' && fromDate.isAfter(toDate)) {
        filter.toDate = fromDate.clone();
      }

      //To date changed
      else if (hasDates && key === 'toDate' && toDate.isBefore(fromDate)) {
        filter.fromDate = toDate.clone();
      }

      //Check if we have a date filter
      this.checkHasDatesFilter();

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      type: TransactionTypes,
      view: [
        {
          label: 'Recently paid and now due',
          filter: {
            isPaid: null,
            isCredit: null,
            dateField: null,
          },
        },
        {
          label: 'All transactions',
          filter: {
            isPaid: null,
            isCredit: null,
            dateField: 'date',
          },
        },
        {
          label: 'Credit transactions',
          filter: {
            isPaid: null,
            isCredit: true,
            dateField: 'date',
          },
        },
        {
          label: 'Paid transactions',
          filter: {
            isPaid: true,
            isCredit: false,
            dateField: 'date',
          },
        },
        {
          label: 'Unpaid transactions',
          filter: {
            isPaid: false,
            isCredit: false,
            dateField: 'date',
          },
        },
      ],
    };
  };

  /**
   * Check if we have a dates filter
   */
  this.checkHasDatesFilter = function() {
    const {fromDate, toDate} = this.filter;
    this.hasDatesFilter = (fromDate && toDate);
  };

  /**
   * Load stripe integration
   */
  this.loadStripeIntegration = function() {
    Integration
      .findByType('stripe')
      .then(integration => {
        if (integration && integration.isConnected) {
          this.hasStripeIntegration = true;
        }
      });
  };

  /**
   * Load transactions
   */
  this.loadTransactions = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query transactions (refresh even if not using filter)
    return Transaction
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Create transaction
   */
  this.add = function() {

    //Create new transaction and define handler
    const transaction = new Transaction();
    const handler = (data) => transaction.saveMany(data);

    //Open modal
    $modal
      .open('createTransaction', {locals: {
        transaction, handler,
        isForMultipleMembers: true,
      }})
      .result
      .then(transactions => {
        const s = (transactions.length > 1) ? 's' : '';
        $notice.show(`Transaction${s} created`);
        this.loadTransactions();
      });
  };

  /**
   * Edit transaction
   */
  this.edit = function($event) {

    //Get transaction and create handler
    const {transaction} = $event;
    const handler = function(data) {
      return transaction.save(data);
    };

    //Open modal
    $modal
      .open('editTransaction', {locals: {transaction, handler}})
      .result
      .then(() => {
        $notice.show('Transaction updated');
        this.loadTransactions();
      });
  };

  /**
   * Split transaction
   */
  this.split = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const handler = parts => transaction.split(parts);

    //Can't split
    if (!transaction.canSplit) {
      return $modal.open('basic', {
        templateUrl: 'admin/finance/modals/cant-split-transaction.html',
      });
    }

    //Open modal
    $modal
      .open('splitTransaction', {locals: {transaction, handler}})
      .result
      .then(() => {
        $notice.show('Transaction split');
        this.loadTransactions();
      });
  };

  /**
   * Notify by email
   */
  this.notifyByEmail = function($event) {

    //Get transaction
    const {transaction} = $event;

    //Notify by email
    return transaction
      .notifyByEmail()
      .then(() => $notice.show('Email sent'))
      .catch(() => $notice.showError('Email not sent'));
  };

  /**
   * View payment
   */
  this.viewPayment = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const {payment} = transaction;
    const {isSuper} = this;

    //Show notice
    $notice.showLoading();

    //Load payment
    Payment
      .findById(payment.id)
      .then(payment => {
        $modal.open('basic', {
          templateUrl: 'admin/finance/modals/view-payment.html',
          locals: {payment, isSuper},
        });
      })
      .finally(() => $notice.hideLoading());
  };

  /**
   * Edit payment
   */
  this.editPayment = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const {payment} = transaction;
    const handler = function(data) {
      return transaction.updatePayment(data);
    };

    //Open modal
    $modal
      .open('editPayment', {locals: {transaction, payment, handler}})
      .result
      .then(() => $notice.show('Transaction payment updated'));
  };

  /**
   * Remove transaction
   */
  this.delete = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const handler = function() {
      return transaction.delete();
    };

    //Open modal
    $modal
      .open('basic', {
        templateUrl: `admin/people/members/modals/confirm-delete-transaction.html`,
        locals: {transaction, handler},
      })
      .result
      .then(() => {
        $notice.show('Transaction removed');
        this.loadTransactions();
      });
  };

  /**
   * Mark transaction as paid
   */
  this.markPaid = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const handler = function(data) {
      return transaction.markPaid(data);
    };

    //Create new payment
    const payment = new Payment();

    //Open modal
    $modal
      .open('editPayment', {locals: {transaction, payment, handler}})
      .result
      .then(() => {
        $notice.show('Transaction marked as paid');
        this.loadTransactions();
      });
  };

  /**
   * Mark transaction as not paid
   */
  this.markNotPaid = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const handler = function() {
      return transaction.markNotPaid();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: `admin/people/members/modals/confirm-not-paid-transaction.html`,
        locals: {transaction, handler},
      })
      .result
      .then(() => {
        $notice.show('Transaction marked as not paid');
        this.loadTransactions();
      });
  };

  /**
   * Pay with account credit
   */
  this.payWithAccountCredit = function($event) {

    //Get transaction
    const {transaction} = $event;
    const {member, amount, details} = transaction;
    const type = $filter('label')(transaction.type, 'TransactionTypes');
    const label = `${type} (${details})`;

    //No member
    if (!member) {
      return;
    }

    //Define handler
    const handler = data => transaction.markPaid(data);

    //Open modal
    return $modal
      .open('payWithAccountCredit', {
        locals: {label, amount, handler, member},
      })
      .result
      .then(payment => {
        transaction.paymentDate = payment.date;
        $notice.show('Transaction paid with account credit');
        this.loadTransactions();
      });
  };

  /**
   * Pay with card
   */
  this.payWithDefaultSource = function($event) {

    //Get transaction
    const {transaction} = $event;
    const {member} = transaction;
    const method = 'stripe';

    //Not a member transaction
    if (!member) {
      return;
    }

    //No Stripe
    if (!this.hasStripeIntegration) {
      return $modal
        .open('basic', {
          templateUrl: 'admin/people/members/modals/no-stripe-integration.html',
        })
        .opened;
    }

    //Load default payment source
    return Member
      .getDefaultPaymentSource(member.id, method)
      .then(source => {

        //No source
        if (!source) {
          return $modal
            .open('basic', {
              templateUrl: 'admin/people/members/modals/no-default-source.html',
              locals: {member},
            });
        }

        //Define handler
        const handler = () => transaction.payWithSource(method, source.id);

        //Confirm
        $modal
          .open('basic', {
            templateUrl: `admin/people/members/modals/confirm-pay-with-source.html`,
            locals: {member, source, transaction, handler},
          })
          .result
          .then(payment => {
            transaction.paymentDate = payment.date;
            $notice.show('Transaction paid with card');
            this.loadTransactions();
          });
      });
  };

  /**
   * View transaction
   */
  this.view = function($event) {
    const {transaction} = $event;
    const {isSuper} = this;
    $modal.open('basic', {
      templateUrl: 'admin/finance/modals/view-transaction.html',
      locals: {transaction, isSuper},
    });
  };

  /**
   * Export transactions
   */
  this.export = function() {

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club: this.club, action: 'Exporting transactions'},
      });
    }

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'transactions', action: 'export'},
      });
    }

    //Get filter and export
    const filter = this.makeFilter();
    return Transaction.export(filter);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, transactions} = data;

    //Set in scope
    this.transactions = transactions;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);

    //Sums
    Object.assign(this, meta.sums);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Ensure start/end of day
    if (this.filter.fromDate) {
      this.filter.fromDate.startOf('day');
    }
    if (this.filter.toDate) {
      this.filter.toDate.endOf('day');
    }

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //Remove unnecessary filter properties
    delete filter.view;

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //No date field
    if (!filter.dateField) {
      delete filter.fromDate;
      delete filter.toDate;
    }

    //If one type selected, change prop
    if (Array.isArray(filter.types) && filter.types.length === 1) {
      filter.type = filter.types[0];
      delete filter.types;
    }

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadTransactions()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };
});
