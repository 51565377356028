
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Rules.Card', [])

/**
 * Component
 */
.component('cardEventRules', {
  templateUrl: 'admin/event/cards/rules.html',
  controller: 'CardEventRulesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    event: '<',
    memberships: '<',
    isEdit: '<',
    onSave: '&',
    onMerge: '&',
    onReload: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventRulesCtrl', function(
  $modal, EventRule
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flags
    this.isSaving = false;
  };

  /**
   * Next
   */
  this.next = function() {
    this.card.nextTab();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.card.previous();
  };

  /**
   * Save event on server
   */
  this.save = function(rules) {

    //Get data
    const {event, isEdit} = this;
    const model = {rules};

    //When adding, dont save on server
    if (!isEdit) {
      return this.onMerge({$event: {event, model}});
    }

    //Get more data
    const {isRecurring, isMultiDay} = event;
    const method = (isRecurring || isMultiDay) ? 'series' : 'instance';

    //Save event
    return this
      .onSave({$event: {event, method, model, isEdit}});
  };

  /**
   * Add rule
   */
  this.add = function() {

    //Create rule and define handler
    const {event} = this;
    const rule = new EventRule(null, event);
    const $ctrl = this;

    //Create copy of rules and define handler
    const rules = event.extract('rules');
    const handler = function(model) {
      rule.fromJSON(model);
      rules.push(rule);
      return $ctrl.save(rules);
    };

    //Show modal
    $modal.open('editEventRule', {locals: {rule, event, handler, isEdit: false}});
  };

  /**
   * Edit rule
   */
  this.edit = function($event) {

    //Get rule
    const {rule} = $event;
    const {event} = this;

    //Find and validate index
    const index = event.rules.findIndex(f => f === rule);
    if (index === -1) {
      return;
    }

    //Create copy of rules and define handler
    const rules = event.extract('rules');
    const handler = (model) => {
      rules[index].fromJSON(model);
      return this.save(rules);
    };

    //Show modal
    $modal.open('editEventRule', {locals: {rule, event, handler, isEdit: true}});
  };

  /**
   * Delete rule
   */
  this.delete = function($event) {

    //Get rule
    const {rule} = $event;
    const {event, memberships} = this;

    //Find and validate index
    const index = event.rules.findIndex(f => f === rule);
    if (index === -1) {
      return;
    }

    //Create copy of rules and define handler
    const rules = event.extract('rules');
    const handler = () => {
      rules.splice(index, 1);
      return this.save(rules);
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'admin/event/modals/confirm-delete-rule.html',
        locals: {rule, event, memberships, handler},
      });
  };

  /**
   * Patch rule
   */
  this.patchRule = function(rule, value) {

    const {rules} = this.event;
    rule.isEnabled = value;

    this.event
      .patch({rules});
  };
});
