
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.Summary.Card', [])

/**
 * Component
 */
.component('cardCouponSummary', {
  templateUrl: 'coupon/cards/summary.html',
  controller: 'CardCouponSummaryCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    coupons: '<',
    onPurchase: '&',
  },
})

/**
 * Controller
 */
.controller('CardCouponSummaryCtrl', function() {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flags
    this.isPurchasing = false;

    //Count active coupons
    this.numActiveCoupons = this.coupons
      .filter(coupon => !coupon.isSpent && coupon.isActive)
      .length;
  };

  /**
   * View coupon
   */
  this.view = function() {
    const {coupon} = this;
    this.onView({$event: {coupon}});
  };

  /**
   * Purchase coupon
   */
  this.purchase = function() {
    const {coupon} = this;
    this.isPurchasing = true;
    this
      .onPurchase({$event: {coupon}})
      .finally(() => this.isPurchasing = false);
  };

  /**
   * Stop coupon
   */
  this.stop = function() {
    const {coupon} = this;
    this.onStop({$event: {coupon}});
  };
});
