
/**
 * Define values
 */
const SESSION_BASED = 'sessionBased';
const SINGLE_PURCHASE = 'singlePurchase';

/**
 * Define array
 */
const CouponTypeTypes = [
  {
    label: 'Session/usage based',
    value: SESSION_BASED,
  },
  {
    label: 'One off purchase',
    value: SINGLE_PURCHASE,
  },
];

/**
 * Set properties
 */
CouponTypeTypes.SESSION_BASED = SESSION_BASED;
CouponTypeTypes.SINGLE_PURCHASE = SINGLE_PURCHASE;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.CouponTypeTypes.Options', [])
  .constant('CouponTypeTypes', CouponTypeTypes);
