
/**
 * Module definition and dependencies
 */
angular.module('Shared.ActivityTypes.Options', [])

/**
 * Constant definition
 */
.constant('ActivityTypes', [

  //Sports
  {
    category: 'sport',
    type: 'archery',
    name: 'Archery',
    background: 'backgrounds/default/archery.jpg',
    areaSingular: 'Range',
    areaPlural: 'Ranges',
    hasBookings: true,
    hasPractice: true,
  },
  {
    category: 'sport',
    type: 'badminton',
    name: 'Badminton',
    background: 'backgrounds/default/badminton.jpg',
    areaSingular: 'Court',
    areaPlural: 'Courts',
    hasBookings: true,
    hasPlay: true,
    hasPractice: true,
    hasVisitor: true,
  },
  {
    category: 'sport',
    type: 'basketball',
    name: 'Basketball',
    background: 'backgrounds/default/basketball.jpg',
    areaSingular: 'Court',
    areaPlural: 'Courts',
    hasBookings: true,
    hasPlay: true,
    hasPractice: true,
    hasVisitor: true,
  },
  {
    category: 'sport',
    type: 'beachVolleyball',
    name: 'Beach volleyball',
    background: 'backgrounds/default/beach-volleyball.jpg',
    areaSingular: 'Court',
    areaPlural: 'Courts',
    hasBookings: true,
    hasPlay: true,
    hasVisitor: true,
  },
  {
    category: 'sport',
    type: 'boating',
    name: 'Boating & Fishing',
    background: 'backgrounds/default/sailing.jpg',
  },
  {
    category: 'sport',
    type: 'bowling',
    name: 'Bowling',
    background: 'backgrounds/default/bowling.jpg',
    areaSingular: 'Lane',
    areaPlural: 'Lanes',
    hasBookings: true,
    hasPlay: true,
    hasPractice: true,
    hasVisitor: true,
  },
  {
    category: 'sport',
    type: 'bowls',
    name: 'Bowls',
    background: 'backgrounds/default/bowls.jpg',
    areaSingular: 'Rink',
    areaPlural: 'Rinks',
    hasBookings: true,
    hasPlay: true,
    hasPractice: true,
    hasVisitor: true,
  },
  {
    category: 'sport',
    type: 'bridge',
    name: 'Bridge',
    background: 'backgrounds/default/cards.jpg',
  },
  {
    category: 'sport',
    type: 'cardPlaying',
    name: 'Card playing',
    background: 'backgrounds/default/cards.jpg',
  },
  {
    category: 'sport',
    type: 'cricket',
    name: 'Cricket',
    background: 'backgrounds/default/cricket.jpg',
    areaSingular: 'Field',
    areaPlural: 'Fields',
  },
  {
    category: 'sport',
    type: 'croquet',
    name: 'Croquet',
    background: 'backgrounds/default/croquet.jpg',
    areaSingular: 'Field',
    areaPlural: 'Fields',
  },
  {
    category: 'sport',
    type: 'cycling',
    name: 'Cycling',
    background: 'backgrounds/default/cycling.jpg',
  },
  {
    category: 'sport',
    type: 'drones',
    name: 'Drone racing',
    background: 'backgrounds/default/drones.jpg',
  },
  {
    category: 'sport',
    type: 'eSports',
    name: 'E-Sports',
    background: 'backgrounds/default/community.jpg',
  },
  {
    category: 'sport',
    type: 'fencing',
    name: 'Fencing',
    background: 'backgrounds/default/fencing.jpg',
  },
  {
    category: 'sport',
    type: 'fieldHockey',
    name: 'Field hockey',
    background: 'backgrounds/default/field-hockey.jpg',
    areaSingular: 'Field',
    areaPlural: 'Fields',
  },
  {
    category: 'sport',
    type: 'fitness',
    name: 'Fitness',
    background: 'backgrounds/default/gym.jpg',
  },
  {
    category: 'sport',
    type: 'football',
    name: 'Football',
    background: 'backgrounds/default/football.jpg',
    areaSingular: 'Field',
    areaPlural: 'Fields',
  },
  {
    category: 'sport',
    type: 'golf',
    name: 'Golf',
    background: 'backgrounds/default/golf.jpg',
    areaSingular: 'Course',
    areaPlural: 'Courses',
    hasBookings: true,
    hasPlay: true,
    hasPractice: true,
    hasVisitor: true,
  },
  {
    category: 'sport',
    type: 'gym',
    name: 'Gym',
    background: 'backgrounds/default/gym.jpg',
  },
  {
    category: 'sport',
    type: 'iceHockey',
    name: 'Ice hockey',
    background: 'backgrounds/default/ice-hockey.jpg',
    areaSingular: 'Rink',
    areaPlural: 'Rinks',
  },
  {
    category: 'sport',
    type: 'iceSkating',
    name: 'Ice skating',
    background: 'backgrounds/default/ice-skating.jpg',
    areaSingular: 'Rink',
    areaPlural: 'Rinks',
  },
  {
    category: 'sport',
    type: 'kayaking',
    name: 'Kayaking',
    background: 'backgrounds/default/kayak.jpg',
  },
  {
    category: 'sport',
    type: 'martialArts',
    name: 'Martial arts',
    background: 'backgrounds/default/martial-arts.jpg',
  },
  {
    category: 'sport',
    type: 'motorCycling',
    name: 'Motorcycling',
    background: 'backgrounds/default/motorcycle.jpg',
  },
  {
    category: 'sport',
    type: 'netball',
    name: 'Netball',
    background: 'backgrounds/default/netball.jpg',
    areaSingular: 'Court',
    areaPlural: 'Courts',
    hasBookings: true,
    hasPlay: true,
    hasPractice: true,
    hasVisitor: true,
  },
  {
    category: 'sport',
    type: 'pickleball',
    name: 'Pickleball',
    background: 'backgrounds/default/pickleball.jpg',
    areaSingular: 'Court',
    areaPlural: 'Courts',
    hasBookings: true,
    hasPlay: true,
    hasPractice: true,
    hasVisitor: true,
  },
  {
    category: 'sport',
    type: 'pilates',
    name: 'Pilates',
    background: 'backgrounds/default/pilates.jpg',
  },
  {
    category: 'sport',
    type: 'poleDancing',
    name: 'Pole dancing',
    background: 'backgrounds/default/pole-dancing.jpg',
  },
  {
    category: 'sport',
    type: 'rowing',
    name: 'Rowing',
    background: 'backgrounds/default/rowing.jpg',
  },
  {
    category: 'sport',
    type: 'rugby',
    name: 'Rugby',
    background: 'backgrounds/default/rugby.jpg',
    areaSingular: 'Field',
    areaPlural: 'Fields',
  },
  {
    category: 'sport',
    type: 'running',
    name: 'Running',
    background: 'backgrounds/default/running.jpg',
  },
  {
    category: 'sport',
    type: 'sailing',
    name: 'Sailing',
    background: 'backgrounds/default/sailing.jpg',
  },
  {
    category: 'sport',
    type: 'scouting',
    name: 'Scouting',
    background: 'backgrounds/default/scouting.jpg',
  },
  {
    category: 'sport',
    type: 'shooting',
    name: 'Shooting',
    background: 'backgrounds/default/gun-range.jpg',
    areaSingular: 'Range',
    areaPlural: 'Ranges',
    hasBookings: true,
    hasPractice: true,
  },
  {
    category: 'sport',
    type: 'soccer',
    name: 'Soccer',
    background: 'backgrounds/default/soccer.jpg',
    areaSingular: 'Field',
    areaPlural: 'Fields',
  },
  {
    category: 'sport',
    type: 'squash',
    name: 'Squash',
    background: 'backgrounds/default/squash.jpg',
    areaSingular: 'Court',
    areaPlural: 'Courts',
    hasBookings: true,
    hasPlay: true,
    hasPractice: true,
    hasVisitor: true,
  },
  {
    category: 'sport',
    type: 'surfLifeSaving',
    name: 'Surf life saving',
    background: 'backgrounds/default/surf-life-saving.jpg',
  },
  {
    category: 'sport',
    type: 'surfing',
    name: 'Surfing',
    background: 'backgrounds/default/surfing.jpg',
  },
  {
    category: 'sport',
    type: 'swimming',
    name: 'Swimming',
    background: 'backgrounds/default/swimming.jpg',
  },
  {
    category: 'sport',
    type: 'tableTennis',
    name: 'Table tennis',
    background: 'backgrounds/default/table-tennis.jpg',
    areaSingular: 'Table',
    areaPlural: 'Tables',
    hasBookings: true,
    hasPlay: true,
    hasPractice: true,
    hasVisitor: true,
  },
  {
    category: 'sport',
    type: 'tennis',
    name: 'Tennis',
    background: 'backgrounds/default/tennis.jpg',
    areaSingular: 'Court',
    areaPlural: 'Courts',
    hasBookings: true,
    hasPlay: true,
    hasPractice: true,
    hasVisitor: true,
  },
  {
    category: 'sport',
    type: 'triathlon',
    name: 'Triathlon',
    background: 'backgrounds/default/running.jpg',
  },
  {
    category: 'sport',
    type: 'volleyball',
    name: 'Volleyball',
    background: 'backgrounds/default/volleyball.jpg',
    areaSingular: 'Court',
    areaPlural: 'Courts',
    hasBookings: true,
    hasPlay: true,
    hasPractice: true,
    hasVisitor: true,
  },
  {
    category: 'sport',
    type: 'weightlifting',
    name: 'Weightlifting',
    background: 'backgrounds/default/weights.jpg',
  },
  {
    category: 'sport',
    type: 'windSurfing',
    name: 'Wind surfing',
    background: 'backgrounds/default/windsurfing.jpg',
  },
  {
    category: 'sport',
    type: 'yachting',
    name: 'Yachting',
    background: 'backgrounds/default/sailing.jpg',
  },
  {
    category: 'sport',
    type: 'yoga',
    name: 'Yoga',
    background: 'backgrounds/default/yoga.jpg',
  },

  //Arts
  {
    category: 'arts',
    type: 'arts',
    name: 'Art',
    background: 'backgrounds/default/photography.jpg',
  },
  {
    category: 'arts',
    type: 'dancing',
    name: 'Dance',
    background: 'backgrounds/default/dance.jpg',
  },
  {
    category: 'arts',
    type: 'music',
    name: 'Music',
    background: 'backgrounds/default/music.jpg',
  },
  {
    category: 'arts',
    type: 'photography',
    name: 'Photography',
    background: 'backgrounds/default/photography.jpg',
  },
  {
    category: 'arts',
    type: 'theatre',
    name: 'Theatre',
    background: 'backgrounds/default/theatre.jpg',
  },
  {
    category: 'arts',
    type: 'wineTasting',
    name: 'Wine tasting',
    background: 'backgrounds/default/wine.jpg',
  },
  {
    category: 'arts',
    type: 'woodworking',
    name: 'Wood working',
    background: 'backgrounds/default/wood.jpg',
  },

  //Venue
  {
    category: 'venue',
    type: 'areaHire',
    name: 'Area hire',
    areaSingular: 'Area',
    areaPlural: 'Areas',
    background: 'backgrounds/default/venue.jpg',
    hasBookings: true,
    hasRoomHire: true,
  },

  //Other
  {
    type: 'other',
    name: 'Other',
  },
]);
