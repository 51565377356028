
/**
 * Color constants
 */
const CRIMSON = '#c30d45';
const AMARANTH = '#de0050';
const RED = '#ff153d';
const SALMON = '#ff9999';
const ORANGE = '#ff7400';
const GOLD = '#ffb300';
const YELLOW = '#ffe500';
const FOREST = '#087b4d';
const GREEN = '#00ae28';
const LIME = '#53e000';
const AQUAMARINE = '#00e0a4';
const JADE = '#00a77a';
const VIRIDIAN = '#007570';
const NAVY = '#07228c';
const BLUE = '#0e3ae6';
const SKY = '#009eec';
const CYAN = '#01c6d2';
const MOONSTONE = '#00a1ab';
const CERULEAN = '#007094';
const VIOLET = '#7f00ff';
const PURPLE = '#bf00ff';
const MAGENTA = '#cc0c98';
const CHARCOAL = '#6a6a6a';
const SLATE = '#333333';
const MERCURY = '#e4e3e3';
const BROWN = '#8a5111';

//Base colors (e.g. for event categories)
const BaseColors = [
  {
    value: CRIMSON,
    label: 'Red',
  },
  {
    value: ORANGE,
    label: 'Orange',
  },
  {
    value: GOLD,
    label: 'Gold',
  },
  {
    value: LIME,
    label: 'Lime',
  },
  {
    value: GREEN,
    label: 'Green',
  },
  {
    value: CHARCOAL,
    label: 'Charcoal',
  },
  {
    value: MAGENTA,
    label: 'Magenta',
  },
  {
    value: VIOLET,
    label: 'Violet',
  },
  {
    value: BLUE,
    label: 'Blue',
  },
  {
    value: MOONSTONE,
    label: 'Moonstone',
  },
  {
    value: CYAN,
    label: 'Cyan',
  },
  {
    value: SLATE,
    label: 'Slate',
  },
];

//Grade colours
const GradeColors = [
  {
    value: MERCURY,
    label: 'White',
  },
  {
    value: YELLOW,
    label: 'Yellow',
  },
  {
    value: ORANGE,
    label: 'Orange',
  },
  {
    value: GREEN,
    label: 'Green',
  },
  {
    value: BLUE,
    label: 'Blue',
  },
  {
    value: BROWN,
    label: 'Brown',
  },
  {
    value: SALMON,
    label: 'Pink',
  },
  {
    value: RED,
    label: 'Red',
  },
  {
    value: MAGENTA,
    label: 'Magenta',
  },
  {
    value: VIOLET,
    label: 'Violet',
  },
  {
    value: CYAN,
    label: 'Cyan',
  },
  {
    value: SLATE,
    label: 'Black',
  },
];

//All colors (e.g. for avatars)
const Colors = [
  {
    value: AMARANTH,
    label: 'Amaranth',
  },
  {
    value: RED,
    label: 'Red',
  },
  {
    value: SALMON,
    label: 'Salmon',
  },
  {
    value: YELLOW,
    label: 'Yellow',
  },
  {
    value: GOLD,
    label: 'Gold',
  },
  {
    value: ORANGE,
    label: 'Orange',
  },
  {
    value: VIRIDIAN,
    label: 'Viridian',
  },
  {
    value: JADE,
    label: 'Jade',
  },
  {
    value: AQUAMARINE,
    label: 'Aquamarine',
  },
  {
    value: LIME,
    label: 'Lime',
  },
  {
    value: GREEN,
    label: 'Green',
  },
  {
    value: FOREST,
    label: 'Forest',
  },
  {
    value: NAVY,
    label: 'Navy',
  },
  {
    value: BLUE,
    label: 'Blue',
  },
  {
    value: SKY,
    label: 'Sky',
  },
  {
    value: CYAN,
    label: 'Cyan',
  },
  {
    value: MOONSTONE,
    label: 'Moonstone',
  },
  {
    value: CERULEAN,
    label: 'Cerulean',
  },
  {
    value: VIOLET,
    label: 'Violet',
  },
  {
    value: PURPLE,
    label: 'Purple',
  },
  {
    value: MAGENTA,
    label: 'Magenta',
  },
  {
    value: BROWN,
    label: 'Brown',
  },
  {
    value: CHARCOAL,
    label: 'Charcoal',
  },
  {
    value: SLATE,
    label: 'Slate',
  },
];

/**
 * Helper to get a random color
 */
function randomGenerator(arr) {
  return function(asObject = false) {
    const i = Math.floor(Math.random() * arr.length);
    return asObject ? arr[i] : arr[i].value;
  };
}

//Apply random generators
Colors.random = randomGenerator(Colors);
BaseColors.random = randomGenerator(BaseColors);
GradeColors.random = randomGenerator(GradeColors);

/**
 * Module definition and dependencies
 */
angular.module('Shared.Colors.Constant', [])

/**
 * Constant definition
 */
.constant('Colors', Colors)
.constant('BaseColors', BaseColors)
.constant('GradeColors', GradeColors);
