
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Add.Display.Card', [])

/**
 * Component
 */
.component('cardMemberAddDisplay', {
  templateUrl: 'admin/people/members/cards/display.html',
  controller: 'CardMemberAddDisplayCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    member: '<',
    isOwn: '<',
    title: '@',
    prefix: '@',
    field: '@',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberAddDisplayCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMemberEditDisplayCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {member, model, field} = this;
    this.onMerge({$event: {member, model: {[field]: model}}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next card
    this.merge();
    this.card.nextTab();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previousTab();
  };
});
