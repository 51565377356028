
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.SignupClub.Creating', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('signupClub.creating', {
    url: '/creating',
    component: 'signupCreatingRoute',
  });
})

/**
 * Route component
 */
.component('signupCreatingRoute', {
  controller: 'SignupClubCreatingCtrl',
  templateUrl: 'portal/signup-club/steps/creating.html',
  bindings: {
    club: '<',
  },
})

/**
 * Controller
 */
.controller('SignupClubCreatingCtrl', function(
  $interval, $timeout, $state, $store, Auth, Config
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Missing club data ID?
    if (!this.club.clubDataId) {
      return $state.go('signupClub.contact');
    }

    //Track conversion
    this.trackConversion();

    //Flags
    this.isCreating = true;
    this.isLoggingIn = false;

    //Check periodically if we're done creating
    this.interval = $interval(() => this.checkCreated(), 2000);

    //Start faded
    this.fade = true;
    $timeout(() => this.fade = false);
  };

  /**
   * Check if created
   */
  this.checkCreated = function() {
    this.club
      .checkCreated()
      .then(({isCreated, auth}) => {
        if (isCreated) {
          $interval.cancel(this.interval);
          this.login(auth);
        }
      })
      .catch(error => {
        $interval.cancel(this.interval);
        throw error;
      });
  };

  /**
   * Login
   */
  this.login = function(auth) {

    //Set flags
    this.isCreating = false;
    this.isLoggingIn = true;

    //Clear store instance so that we refresh the proper club data with modules etc.
    $store.club.instance = null;

    //Get login details
    const {username, password} = this.club.contact;

    //Forget last state and redirect to proper page
    Auth.setLastState(null);
    Auth.setRedirectState({name: 'admin.setup', params: {isFirstVisit: true}});

    //Log user in if access token was provided
    if (auth) {
      return Auth
        .loginWithToken(auth.access_token || auth.accessToken)
        .catch(() => {
          this.isErrorLoggingIn = true;
          this.isLoggingIn = false;
        });
    }

    //Otherwise try logging in with username/password
    else if (username && password) {
      return Auth
        .loginWithCredentials({username, password})
        .catch(() => {
          this.isErrorLoggingIn = true;
          this.isLoggingIn = false;
        });
    }

    //Otherwise just redirect to login
    else {
      Auth.goToLoginState();
    }
  };

  /**
   * Track conversions
   */
  this.trackConversion = function() {
    this.trackCapterraConversion();
  };

  /**
   * Track Capterra conversion
   */
  this.trackCapterraConversion = function() {

    //Not enabled?
    if (!Config.capterra.isEnabled) {
      return;
    }

    //Get config and create script
    const {url, id, key} = Config.capterra;
    const script = document.createElement('script');

    //Set script properties
    script.async = true;
    script.type = 'text/javascript';
    script.src = `${url}?vid=${id}&vkey=${key}`;

    //Insert element
    const first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
  };
});
