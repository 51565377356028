
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area.Add.Details.Card', [])

/**
 * Component
 */
.component('cardAreaAddDetails', {
  templateUrl: 'admin/area/cards/details.html',
  controller: 'CardAreaAddDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    area: '<',
    activities: '<',
    system: '<',
    isSuper: '<',
    hasBookings: '<',
    onMerge: '&',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardAreaAddDetailsCtrl', function(
  $controller, $element, $focus
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardAreaEditDetailsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark flags
    this.isAdd = true;
    this.isEdit = false;

    //Focus on first input
    $focus($element.find('input')[0]);
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {area, model} = this;
    this.onMerge({$event: {area, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next card
    this.merge();
    this.card.next();
  };
});
