
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.Payouts.Controller', [])

/**
 * Controller
 */
.controller('AdminFinancePayoutsCtrl', function(
  $filter, moment, ScrollPosition, Pagination, Intercom, Settings,
  Payout, PaymentMethods
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed payouts');

    //Flags
    this.isSuper = this.user.isSuper();

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load payouts
    this.loadPage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Set page title and crumb
    page.setTitle('Payouts');
    page.addCrumb({sref: 'admin.finance.payouts'});

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get data
    const {filter} = this;

    //Set filter defaults
    filter.setDefaults({
      search: '',
      fromDate: moment().subtract(1, 'month').startOf('day'),
      toDate: moment().endOf('day'),
    });

    //Filter on change handler
    filter.onChange(key => {

      //Get data
      const {toDate, fromDate} = filter;
      const hasDates = fromDate && toDate;

      //From date changed
      if (hasDates && key === 'fromDate' && fromDate.isAfter(toDate)) {
        filter.toDate = fromDate.clone();
      }

      //To date changed
      else if (hasDates && key === 'toDate' && toDate.isBefore(fromDate)) {
        filter.fromDate = toDate.clone();
      }

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      method: PaymentMethods
        .filter(method => method.isOnline && this.country[method.value]),
    };
  };

  /**
   * Load payouts
   */
  this.loadPayouts = function() {

    //Mark as loading and clear
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query payouts
    return Payout
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, payouts} = data;

    //Set in scope
    this.payouts = payouts;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
    this.hasStripe = payouts.some(payout => payout.provider === 'stripe');

    //Sums
    Object.assign(this, meta.sums);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadPayouts()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };
});
