
/**
 * Module definition and dependencies
 */
angular.module('Shared.GoogleMaps.Service', [])

/**
 * Config
 */
.config($apiProvider => {
  $apiProvider.registerEndpoint('googleMaps', {
    url: 'googleMaps',
    actions: {
      placesAutoComplete: {
        url: 'placesAutoComplete',
        method: 'GET',
        isArray: true,
      },
      placeDetails: {
        url: 'placeDetails',
        method: 'GET',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('GoogleMaps', function($api) {

  //Create service
  const GoogleMaps = {

    /**
     * Places autocomplete
     */
    placesAutoComplete(input, types) {
      return $api.googleMaps.placesAutoComplete({input, types});
    },

    /**
     * Place details
     */
    placeDetails(placeId, formatted) {
      return $api.googleMaps.placeDetails({placeId, formatted});
    },
  };

  //Return service
  return GoogleMaps;
});
