
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Edit.Conditions.Card', [])

/**
 * Component
 */
.component('cardMembershipEditConditions', {
  templateUrl: 'admin/membership/cards/conditions.html',
  controller: 'CardMembershipEditConditionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    memberships: '<',
    membership: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipEditConditionsCtrl', function(
  CardCloseReasons, MembershipConstraintLabels
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;

    //Set services
    this.MembershipConstraintLabels = MembershipConstraintLabels
      .filter(item => !item.isGuest);

    //Filter out current membership
    this.memberships = this.memberships.filter(m => m.id !== this.membership.id);

    //Create model subset
    this.model = this.membership.extract([
      'conditions', 'fee', 'minAge', 'maxAge',
      'canSelectWhenChanging', 'canSelectForPurchase',
      'bundleLimit', 'enforceSameHousehold', 'allowPayLater',
      'constraint', 'memberships', 'limit',
    ]);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {membership, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {membership, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
