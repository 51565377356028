
/**
 * Module definition and dependencies
 */
angular.module('Shared.Percentage.Directive', [])

/**
 * Directive
 */
.directive('percentage', function() {
  return {
    restrict: 'A',
    require: [
      '?ngModel',
    ],
    //eslint-disable-next-line no-unused-vars
    link(scope, element, attrs, ngModel) {

      //Array?
      if (Array.isArray(ngModel)) {
        ngModel = ngModel[0];
      }

      //Parser
      ngModel.$parsers.push(function(value) {
        if (!ngModel.$isEmpty(value)) {
          return value / 100;
        }
        return value;
      });

      //Formatter
      ngModel.$formatters.unshift(function(value) {
        if (!ngModel.$isEmpty(value)) {
          return value * 100;
        }
        return value;
      });
    },
  };
});
