
/**
 * Module definition and dependencies
 */
angular.module('Shared.Counter.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config($apiProvider => {

  //Register API endpoint
  $apiProvider.registerEndpoint('counter', {
    model: 'Counter',
    params: {
      counterType: '@type',
    },
    actions: {
      get: {
        url: '/:counterType',
        method: 'GET',
        isModel: true,
      },
      update: {
        url: '/:counterType',
        method: 'PUT',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Counter', function($api, $baseModel) {

  /**
   * Constructor
   */
  function Counter(data) {
    $baseModel.call(this, data);

    /**
     * Check if changed
     */
    Object.defineProperty(this, 'isChanged', {
      get() {
        return (this.sequence !== this.unchangedSequence);
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Counter.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Counter.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Increase sequence for clarity
    this.sequence++;
    this.unchangedSequence = this.sequence;

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Counter.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Decrease sequence again
    json.sequence--;
    delete json.unchangedSequence;

    //Return json
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save
   */
  Counter.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    return $api.counter.update(data)
      .then(data => this.fromJSON(data));
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Find by type
   */
  Counter.findByType = function(type) {
    return $api.counter.get(null, {type});
  };

  //Return
  return Counter;
});
