
/**
 * Module definition and dependencies
 */
angular.module('Shared.AddressInput.Component', [])

/**
 * Address input component
 */
.component('addressInput', {
  template: `
    <div class="Group" has-error="$ctrl.ngModel">
      <label class="Label" ng-class="{'is-optional': ($ctrl.isOptional && $ctrl.showOptional)}">{{$ctrl.label || 'Address'}}</label>
      <div class="InputWrapper">
        <type-ahead
          ng-model="$ctrl.address"
          label-by="formatted"
          track-by="placeId"
          min-length="3"
          debounce="500"
          as-object="true"
          clear-input="$ctrl.clearInput"
          placeholder="\uFEFF"
          on-change="$ctrl.selectedPlace(value)"
          on-query="$ctrl.typedAddress()"
          on-search="$ctrl.search(value)"
          ng-disabled="$ctrl.isLookingUp || $ctrl.isDisabled"
        ></type-ahead>
        <a class="Input-innerSuffix" ng-click="$ctrl.onCopy()" ng-if="$ctrl.canCopy">
          Copy address
        </a>
        <spinner class="Spinner--input" ng-if="$ctrl.isLookingUp"></spinner>
        <div class="Input-aboveSuffix" ng-if="!$ctrl.onlyRegions && !$ctrl.isSearching && !$ctrl.isLookingUp && !$ctrl.isDisabled">
          <a class="text-muted" ng-click="$ctrl.enterManually()">Edit address manually</a>
        </div>
      </div>
      <div class="InputHint" ng-if="$ctrl.ngModel.$$parentForm.$submitted" ng-messages="$ctrl.ngModel.$error">
        <p ng-message="required">Start typing address and then select one from the list</p>
      </div>
    </div>
  `,
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    address: '<ngModel',
    isDisabled: '<ngDisabled',
    showOptional: '<',
    clearInput: '<',
    canCopy: '<',
    onChange: '&',
    onCopy: '&',
    types: '@',
    label: '@',
  },

  /**
   * Directive controller
   */
  controller($attrs, $modal, $timeout, Address, GoogleMaps) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Get controller
      const $ctrl = this;

      //Custom required validator
      //eslint-disable-next-line no-unused-vars
      this.ngModel.$validators.required = function(modelValue, viewValue) {
        if ($attrs.required || $ctrl.inputValue) {

          //Check if address actually selected
          if ($ctrl.inputValue && !$ctrl.hasSelected) {
            return false;
          }
        }
        return true;
      };
    };

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Get form and set address input value
      this.inputValue = this.address ? this.address.formatted : '';
      this.hasSelected = !!this.inputValue;
      this.onlyRegions = (this.types === '(regions)');
    };

    /**
     * Post link
     */
    this.$postLink = function() {
      $timeout(() => {
        this.isOptional = !$attrs.required;
      });
    };

    /**
     * Search
     */
    this.search = function(input) {

      //Get types
      const {types} = this;
      this.isSearching = true;

      //Search
      return GoogleMaps
        .placesAutoComplete(input, types)
        .finally(() => this.isSearching = false);
    };

    /**
     * Place selected
     */
    this.selectedPlace = function(place) {

      //No place
      if (!place) {
        return;
      }

      //Get place ID and load place
      const {placeId, formatted} = place;
      this.isLookingUp = true;

      //Get place address data
      GoogleMaps
        .placeDetails(placeId, formatted)
        .then(data => new Address(data))
        .then(address => this.confirmAddress(address))
        .finally(() => this.isLookingUp = false);
    };

    /**
     * Typed in the address field
     */
    this.typedAddress = function() {

      //Clear address selection
      this.hasSelected = false;
      this.ngModel.$setDirty();
      this.onChange({address: null});

      //Set validity of required field in case they typed but didn't select
      //an address. This will trigger irrespective of whether or not the
      //address is required, as they need to be warned against doing this.
      //Timeout appears necessary otherwise it doesn't always register.
      $timeout(() => {
        if (this.inputValue) {
          this.ngModel.$setValidity('required', false);
        }
        else if (!$attrs.required) {
          this.ngModel.$setValidity('required', true);
        }
      });
    };

    /**
     * Confirm address
     */
    this.confirmAddress = function(address) {

      //Set model validity and remember input value
      $timeout(() => {
        this.ngModel.$setValidity('required', true);
        this.ngModel.$setDirty();

        //Remember input value and mark as selected
        this.inputValue = address.formatted;
        this.hasSelected = true;

        //Propagate
        this.onChange({address});
      });
    };

    /**
     * Enter manually
     */
    this.enterManually = function() {
      const {address} = this;
      $modal
        .open('enterAddress', {locals: {address}})
        .result
        .then(address => {
          this.confirmAddress(address);
        });
    };
  },
});
