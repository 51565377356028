
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.Mailchimp.MergeFields.Card', [])

/**
 * Component
 */
.component('cardIntegrationsMailchimpMergeFields', {
  templateUrl: 'admin/integrations/mailchimp/merge-fields.html',
  controller: 'CardIntegrationsMailchimpMergeFieldsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsMailchimpMergeFieldsCtrl', function(
  $controller, $store, Mailchimp, MemberFields
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Address fields
    this.addressFields = [
      {
        prop: 'address',
        label: 'Address',
      },
      {
        prop: 'postalAddress',
        label: 'Postal address',
      },
    ];

    //Phone fields
    this.phoneFields = [
      {
        prop: 'mobile',
        label: 'Mobile',
      },
      {
        prop: 'phone',
        label: 'Phone',
      },
    ];

    //DOB fields
    this.dobFields = [
      {
        prop: 'dob',
        label: 'Date of birth',
      },
    ];

    //Make copy of merge fields
    this.mergeFields = Object.assign({}, this.integration.data.mergeFields || {});

    //Load lists if connected
    if (this.isConnected) {
      this
      .loadCustomFields()
      .then(() => this.determineAvailableFields())
      .then(() => this.loadFields());
    }
  };

  /**
   * Load fields
   */
  this.loadFields = function() {

    //Toggle flag
    this.isLoading = true;

    //Load fields
    Mailchimp
      .getListsAndMergeFields()
      .then(lists => {

        //Set lists
        this.lists = lists;

        //Initialize merge fields container for lists
        for (const list of lists) {
          if (!this.mergeFields[list.id]) {
            this.mergeFields[list.id] = {};
          }
        }
      })
      .catch(error => {

        //Not connected?
        if (error.code === 'NOT_CONNECTED') {
          this.integration.isConnected = false;
          this.card.showTab('status');
        }
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Load custom fields
   */
  this.loadCustomFields = function() {

    //Set flag
    this.isLoadingCustomFields = true;
    this.customFields = [];

    //Load
    return $store.customFields
      .query({model: 'Member'})
      .then(customFields => {
        for (const field of customFields) {
          if (!field.isParagraph && !field.isFile) {
            this.customFields.push(field);
          }
        }
      })
      .finally(() => this.isLoadingCustomFields = false);
  };

  /**
   * Determine member fields
   */
  this.determineAvailableFields = function() {

    //Exclude some fields for the generic text
    const exclude = [
      'membership',
      'startDate',
      'endDate',
      'primaryMember',
      'circle',
      'isLead',
      'accountCredit',
      'amountOwing',
    ];

    //Initialize fields available for generic merge fields
    this.fields = MemberFields
      .filter(field => !exclude.includes(field.prop));

    //Add subscriptions
    this.fields.push({
      prop: 'memberships',
      label: 'Membership(s)',
    });

    //Add custom fields
    for (const field of this.customFields) {
      const {prop, label} = field;
      this.fields.push({
        prop: `customFields.${prop}`,
        label,
      });
    }
  };

  /**
   * Set merge fields
   */
  this.setMergeField = function(listId, fieldTag, prop) {
    this.mergeFields[listId][fieldTag] = prop;
  };

  /**
   * Save integration settings
   */
  this.save = function() {

    //Set in data and toggle flag
    this.data.mergeFields = this.mergeFields;
    this.isSaving = true;

    //Update integration
    this
      .update()
      .then(() => this.card.showTab('status'))
      .finally(() => this.isSaving = false);
  };
});
