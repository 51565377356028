
/**
 * Module definition and dependencies
 */
angular.module('Shared.Import.Modal', [
  'Shared.Flow.ImportSelectFile.Component',
  'Shared.Flow.ImportPreview.Component',
  'Shared.Flow.ImportUpload.Component',
])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('import', {
    templateUrl: 'modals/import.html',
    controller: 'ModalImportCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalImportCtrl', function(
  $controller, $modalInstance, $timeout, Config, Papa, Upload, Helpers
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Always select file as first step
    this.stepSelectFile();
  };

  /**
   * Select file step
   */
  this.stepSelectFile = function() {
    this.step = 'selectFile';
  };

  /**
   * Preview step
   */
  this.stepPreview = function() {
    this.step = 'preview';
  };

  /**
   * Upload step
   */
  this.stepUpload = function() {
    this.step = 'upload';
  };

  /**
   * Selected file
   */
  this.selectedFile = function($event) {

    //Get file from event
    const {file} = $event;

    //Reset error flags
    this.hasErrorFileType = false;
    this.hasErrorNoData = false;

    //No file?
    if (!file || !Upload.isFile(file)) {
      this.hasErrorFileType = true;
      return;
    }

    //Remember file for upload
    this.file = file;

    //Process with Papa
    Papa.parse(file, {
      dynamicTyping: false,
      skipEmptyLines: true,
      preview: 21,
      complete: (results) => $timeout(() => this.processResults(results)),
    });
  };

  /**
   * Process CSV parser results
   */
  this.processResults = function(results) {

    //Get data
    const {data, meta, errors} = results;
    if (data.length === 0) {
      this.hasErrorNoData = true;
      return;
    }

    //Check if first line contains headers
    let matches = 0;
    for (const value of data[0]) {

      //No value
      if (!value || typeof value !== 'string') {
        continue;
      }

      //Create regex
      const header = Helpers.escapeRegex(value).replace(/ /g, '\\s?');
      const regex = new RegExp(header, 'i');
      const lower = header.toLowerCase();
      const hasMatch = this.fields
        .some(({prop, label}) => (
          lower === prop.toLowerCase() ||
          lower === label.toLowerCase() ||
          prop.match(regex) ||
          label.match(regex)
        ));

      //Match found?
      if (hasMatch) {
        matches++;
      }

      //Skip first row
      if (matches >= 3) {
        this.skipFirstRow = true;
        this.fileHeaders = data[0];
        data.shift();
        break;
      }
    }

    //Sort data based on how many fields there are with values
    data.sort((a, b) => {
      const numA = a.filter(value => !!value).length;
      const numB = b.filter(value => !!value).length;
      if (numA > numB) {
        return -1;
      }
      if (numA < numB) {
        return 1;
      }
      return 0;
    });

    //Set values and move to next step
    Object.assign(this, {data, meta, errors});
    this.stepPreview();
  };

  /**
   * Confirm headers
   */
  this.confirmHeaders = function($event) {

    //Get headers and go to upload step
    this.headers = $event.headers;
    this.stepUpload();
  };

  /**
   * Upload file
   */
  this.uploadFile = function() {

    //Get file
    const {path, headers, skipFirstRow} = this;
    const {baseUrl} = Config.api;

    //Force file type of text/csv for windows
    let {file} = this;
    if (file.type !== 'text/csv') {
      file = file.slice(0, file.size, 'text/csv');
    }

    //Upload
    return Upload.upload({
      url: `${baseUrl}/${path}`,
      data: {file, headers, skipFirstRow},
    });
  };

  /**
   * Done
   */
  this.done = function() {
    $modalInstance.resolve();
  };
});
