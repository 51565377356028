
/**
 * Module definition and dependencies
 */
angular.module('Shared.Notice.Component', [])

/**
 * Notice component
 */
.component('notice', {
  templateUrl: 'shared/notice/notice.html',
  bindings: {
    message: '<',
    icon: '<',
    spinner: '<',
    classes: '@',
  },
});
