
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Add.Renewal.Card', [])

/**
 * Component
 */
.component('cardMembershipAddRenewal', {
  templateUrl: 'admin/membership/cards/renewal.html',
  controller: 'CardMembershipAddRenewalCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    membership: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipAddRenewalCtrl', function(
  $controller, AutoRenewalPaymentStrategies, moment
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMembershipEditRenewalCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Flags
    this.isEdit = false;
    this.isAdd = true;

    //Get model
    const {model, club} = this;
    const threshold = this.determineRenewalThreshold();

    //Defaults;
    if (!model.beforeExpiry || !model.beforeExpiry.unit) {
      model.beforeExpiry = threshold;
    }
    if (!model.afterExpiry || !model.afterExpiry.unit) {
      model.afterExpiry = threshold;
    }
    if (!model.autoRenewal) {
      const flag = !!club.permissions.autoRenewal;
      model.autoRenewal = {
        isEnabled: flag,
        isDefault: flag,
        canOptIn: flag,
        canOptOut: flag,
        daysInAdvance: this.getAutoRenewDaysInAdvance(),
        paymentStrategy: AutoRenewalPaymentStrategies.REQUIRE_PAYMENT,
      };
    }
  };

  /**
   * Get default expiry before and after threshold
   */
  this.determineRenewalThreshold = function() {
    const {term} = this.membership;
    const termDate = moment().add(term.amount, term.unit);

    //6 months or more
    if (termDate > moment().startOf('day').add(6, 'months')) {
      return {amount: 1, unit: 'months'};
    }
    //1 - 6 months
    else if (termDate > moment().startOf('day').add(1, 'months')) {
      return {amount: 2, unit: 'weeks'};
    }
    //Fortnightly
    else if (termDate > moment().startOf('day').add(2, 'weeks')) {
      return {amount: 1, unit: 'weeks'};
    }
    //Weekly
    else if (termDate > moment().startOf('day').add(1, 'weeks')) {
      return {amount: 3, unit: 'days'};
    }
    //Default
    return {amount: 1, unit: 'days'};
  };

  /**
   * Get default renewal days in advance
   */
  this.getAutoRenewDaysInAdvance = function() {
    const {term} = this.membership;
    const max = 10;
    switch (term.unit) {

      //Days: one day for every 7 days of the term (rounded up)
      case 'days':
        return Math.min(Math.ceil(term.amount / 10), max);

      //Weeks: one day for every week
      case 'weeks':
        return Math.min(term.amount, max);

      //Months: three days for every month
      case 'months':
        return Math.min(term.amount * 3, max);

      //Years: 10 days
      case 'years':
        return Math.min(10, max);

      //Unknown
      default:
        return 1;
    }
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {model, membership} = this;
    this.onMerge({$event: {membership, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next card
    this.merge();
    this.card.nextTab();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previousTab();
  };
});
