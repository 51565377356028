
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Edit.Controller', [])

/**
 * Controller
 */
.controller('AdminActivityEditCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminActivityCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {
    const {isOnSystem} = this.activity;

    this.isSuper = this.user.isSuper();
    this.canDelete = !isOnSystem || this.isSuper;
  };
});
