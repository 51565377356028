
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.Troubleshooting.Card', [])

/**
 * Component
 */
.component('cardSystemTroubleshooting', {
  templateUrl: 'admin/system/cards/troubleshooting.html',
  controller: 'CardSystemTroubleshootingCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    text: '<',
    system: '<',
    isSuper: '<',
  },
})

/**
 * Controller
 */
.controller('CardSystemTroubleshootingCtrl', function($notice, Config) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Editing flag
    this.isEditing = false;

    //Reset model and make replacements
    if (this.text) {
      this.text.resetModel();
      this.makeReplacements();
    }
  };

  /**
   * Make replacements
   */
  this.makeReplacements = function() {
    this.contents = this.text.contents
      .replace(/\[APP_NAME\]/g, Config.app.title)
      .replace(/\[LINK_TO_TROUBLESHOOTING\]/g, `https://help.helloclub.com/en/articles/5337613-troubleshooting-system-problems`)
      .replace(/\[SYSTEM_NAME\]/g, this.system.name)
      .replace(/\[SYSTEM_TYPE\]/g, this.system.type)
      .replace(/\[FACILITY_NUMBER\]/g, this.system.facilityNumber);
  };

  /**
   * Edit
   */
  this.edit = function() {
    if (this.isSuper) {
      this.isEditing = true;
      setTimeout(() => {
        this.card.dirtyCheck(this.form);
      });
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Not modified
    if (!this.text.isModified()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Save
    this.text
      .save()
      .then(() => {
        this.form.$setPristine();
        this.makeReplacements();
        this.isEditing = false;
        $notice.show(`Text updated`);
      })
      .finally(() => this.isSaving = false);
  };
});
