
/**
 * Module definition and dependencies
 */
angular.module('Shared.Text.Markdown.Filter', [])

/**
 * Filter definitions
 */
.filter('markdown', function($window, $sce) {
  return function(text, isInline) {
    if (typeof $window.marked !== 'function') {
      return text;
    }
    if (!text) {
      return '';
    }

    // //Get markdown
    // const {marked} = $window;
    // const renderer = new marked.Renderer();

    // //Link opens in new window
    // renderer.link = () => {
    //   const link = marked.Renderer.prototype.link.apply(this, arguments);
    //   return link.replace('<a', '<a target="_blank"');
    // };

    //Inline, need to preserve newlines
    if (isInline) {
      text = text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>');
      text = $window.marked.inlineLexer(text, []);
    }
    else {
      text = $window.marked(text, {
        gfm: true,
        breaks: true,
        headerIds: false,
      });
    }

    //Links open in new window
    text = text.replace(/(<a)/g, '<a target="_blank"');

    //Trust as HTML
    return $sce.trustAsHtml(text);
  };
});
