
/**
 * Module definition and dependencies
 */
angular.module('Shared.EmailPicker.Component', [])

/**
 * Email picker component
 */
.component('emailPicker', {
  templateUrl: 'shared/email-picker/email-picker.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    emails: '<ngModel',
    maxEmails: '<',
    onChange: '&',
  },

  /**
   * Component controller
   */
  controller($element, $timeout, $keyCodes) {

    //Helper vars
    const $ctrl = this;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    /**
     * Check if input was control
     */
    function isControlInput(event) {
      const keys = [
        $keyCodes.SPACE,
        $keyCodes.ENTER,
        $keyCodes.ESC,
        $keyCodes.COMMA,
      ];
      return (keys.indexOf(event.keyCode) > -1);
    }

    /**
     * Init
     */
    this.$onInit = function() {

      //Initialize entry
      this.entry = '';

      //Propagate focus
      $element.attr('tabindex', -1);
      $element.on('focus', () => {
        const $input = $element.find('input');
        if ($input.length) {
          $input[0].focus();
        }
      });

      //Override model options
      this.ngModel.$overrideModelOptions({
        allowInvalid: true,
      });

      //Empty check
      this.ngModel.$isEmpty = function() {
        return (!Array.isArray($ctrl.emails) || $ctrl.emails.length === 0);
      };
    };

    /**
     * Change handler
     */
    this.$onChanges = function() {

      //Emails must be an array
      if (!Array.isArray(this.emails)) {
        this.emails = [];
      }

      //Check if can add
      this.canAdd = (!this.maxEmails || this.emails.length < this.maxEmails);

      //Check validity
      this.ngModel.$validate();
      const $input = $element.find('input');
      if ($input.length) {
        $input[0].focus();
      }
    };

    /**
     * Post link
     */
    this.$postLink = function() {

      //Add blur handler
      $timeout(() => {
        const $input = $element.find('input');
        if ($input.length) {
          angular.element($input[0]).on('blur', () => {
            this.addEmail();
          });
        }
      });
    };

    /**
     * Key down handler
     */
    this.keydown = function(event) {

      //Get key code
      const {keyCode} = event;

      //Enter adds email
      if (isControlInput(event)) {
        event.preventDefault();
        if (
          keyCode === $keyCodes.ENTER ||
          keyCode === $keyCodes.SPACE ||
          keyCode === $keyCodes.COMMA
        ) {
          this.addEmail();
        }
        else if (keyCode === $keyCodes.ESC) {
          this.entry = '';
        }
      }

      //Backspace removes last email if email input is empty
      else if (keyCode === $keyCodes.BACKSPACE) {
        if (this.entry === '' && this.emails.length > 0) {
          const index = this.emails.length - 1;
          this.removeEmail(index);
        }
      }
    };

    /**
     * Reset validity
     */
    this.resetValidity = function() {
      this.ngModel.$setValidity('email', true);
      this.ngModel.$setValidity('exists', true);
    };

    /**************************************************************************
     * Email management
     ***/

    /**
     * Add member
     */
    this.addEmail = function() {

      //Unable to add?
      if (this.maxEmails && this.emails.length >= this.maxEmails) {
        return;
      }

      //No entry?
      if (!this.entry) {
        return;
      }

      //Get email
      const email = this.entry
        .toLowerCase()
        .trim();

      //No email
      if (!email) {
        return;
      }

      //Already exists?
      if (this.emails.includes(email)) {
        this.ngModel.$setValidity('exists', false);
        return;
      }

      //Invalid
      if (!email.match(emailRegex)) {
        this.ngModel.$setValidity('email', false);
        return;
      }

      //Add to array
      this.emails.push(email);
      this.changedEmails();

      //Clear entry input
      this.entry = '';
    };

    /**
     * Remove particular email
     */
    this.removeEmail = function(index) {

      //Validate
      const email = this.emails[index];
      if (!email) {
        return;
      }

      //Remove email
      this.emails.splice(index, 1);
      this.changedEmails();
    };

    /**
     * Changed emails
     */
    this.changedEmails = function() {

      //Mark model controller as dirty and indicate to validate
      //Validate is needed because the model change is not registered, on
      //account of the array still being the same object
      this.ngModel.$setDirty();
      this.ngModel.$validate();

      //Check if can add
      this.canAdd = (!this.maxEmails || this.emails.length < this.maxEmails);

      //Propagate emails array
      this.onChange({
        emails: this.emails,
      });

      //Blur input if max emails reached, otherwise focus
      $timeout(() => {
        const $input = $element.find('input');
        if (!$input.length) {
          return;
        }
        if (this.maxEmails && this.emails.length === this.maxEmails) {
          $input[0].blur();
        }
        else {
          $input[0].focus();
        }
      });
    };
  },
});
