
/**
 * Module definition and dependencies
 */
angular.module('Shared.PaymentOutcome.Service', [])

/**
 * Service definition
 */
.factory('PaymentOutcome', function($location, $q, $modal, $timeout, Payment) {

  /**
   * Payment outcome helper class
   */
  class PaymentOutcome {

    /**
     * Check payment outcome for redirect based payment flows
     */
    check() {

      //Get payment outcome token from query params
      const {token} = $location.search();
      if (!token) {
        return $q.resolve(null);
      }

      //Clear now
      //The long timeout appears necessary because we resolve this in UI router
      //which seems to be outside of Angular's handling. Short timeout didn't work
      $timeout(() => {
        $location.search('token', null);
      }, 1000);

      //Find payment by token
      return Payment.findByToken(token);
    }

    /**
     * Show modal
     */
    showModal(payment, successHandler, failureHandler) {

      //Open modal
      return $modal
        .open('basic', {
          templateUrl: 'account/modals/payment-outcome.html',
          locals: {payment},
        }, true)
        .closed
        .then(() => {
          if (payment.isPaid && successHandler) {
            successHandler();
          }
          else if (!payment.isPaid && failureHandler) {
            failureHandler();
          }
        });
    }
  }

  //Return instance
  return new PaymentOutcome();
});

