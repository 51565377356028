/**
 * Module definition and dependencies
 */
angular.module('Shared.S3Background.Component', [])

/**
 * S3 image component
 */
.component('s3Background', {
  template: '',
  bindings: {
    src: '<',
  },
  controller($element, $document, Config) {

    /**
     * Observe changes
     */
    this.$onChanges = function() {

      //Get file
      if (!this.src) {
        return $element.css({
          'background-image': null,
        });
      }

      //Get source and make image element
      const url = this.getSourceUrl();
      const img = $document[0].createElement('img');

      //Create image element
      img.onload = function() {
        $element.css({
          'background-image': `url(${url})`,
        });
      };

      //Set image source URL now
      img.src = url;
    };

    /**
     * Get source URL
     */
    this.getSourceUrl = function() {

      //Initialize
      let url = (typeof this.src === 'string') ? this.src : this.src.url;

      //Prefix if needed
      if (url && !url.match(/^http(s?):\/\//)) {
        url = `https://${Config.domains.images}/${url}`;
      }

      //Return
      return url;
    };
  },
});
