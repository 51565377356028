
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.AgreeConditions.Card', [])

/**
 * Component
 */
.component('cardSubscriptionAgreeConditions', {
  templateUrl: 'subscription/cards/agree-conditions.html',
  controller: 'CardSubscriptionAgreeConditionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    membership: '<',
    selection: '<',
    hasAgreed: '<',
    onChange: '&',
    onNext: '&',
    onPrev: '&',
    onCancel: '&',
  },
})

/**
 * Controller
 */
.controller('CardSubscriptionAgreeConditionsCtrl', function() {

  /**
   * Init
   */
  this.$onInit = function() {

    //Filter out memberships that we must agree to (for selection)
    if (this.selection) {
      this.memberships = [];
      for (const item of this.selection) {
        this.addMembership(item.membership);
        for (const linked of item.linked) {
          this.addMembership(linked.membership);
        }
      }
    }
  };

  /**
   * Add membership to list of memberships with conditions
   */
  this.addMembership = function(membership) {
    if (membership.conditions) {
      const exists = this.memberships.some(m => m.id === membership.id);
      if (!exists) {
        this.memberships.push(membership);
      }
    }
  };

  /**
   * Change value
   */
  this.change = function(hasAgreed) {
    this.onChange({$event: {hasAgreed}});
  };

  /**
   * Previous step
   */
  this.prev = function() {
    this.onPrev();
  };

  /**
   * Next step
   */
  this.next = function() {
    if (this.validate()) {
      this.onNext();
    }
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };
});
