
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.SignupClub', [
  'App.Portal.SignupClub.OAuth',
  'App.Portal.SignupClub.Contact',
  'App.Portal.SignupClub.Type',
  'App.Portal.SignupClub.Details',
  'App.Portal.SignupClub.Activity',
  'App.Portal.SignupClub.Interests',
  'App.Portal.SignupClub.Creating',
])

/**
 * Config
 */
.config(($stateProvider, $transitionsProvider) => {

  //State definition
  $stateProvider.state('signupClub', {
    parent: 'portal',
    abstract: true,
    template: '404',
    url: '/signup-club',
    params: {
      error: null,
      provider: null,
      reason: null,
    },
    resolve: {
      club(Club, $location, $store) {
        const {
          plan, source, referrer, isHc, isDirect, isDirectToFrello,
          xeroAppStoreStateId,
        } = $location.search();
        $store.club.instance = new Club({
          type: 'club',
          category: 'other',
          plan, source, referrer,
          isHc: isHc ? true : false,
          isDirect: isDirect ? true : false,
          isDirectToFrello: isDirectToFrello ? true : false,
          contact: {},
        });

        //Assign Xero data if from Xero sign up
        if (xeroAppStoreStateId) {
          const {name, firstName, lastName, email, username}
            = $location.search();

          $store.club.instance.xeroAppStoreStateId = xeroAppStoreStateId;
          $store.club.instance.name = name;
          $store.club.instance.contact = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            username: username,
          };
        }
        return $store.club.instance;
      },
      countries($store) {
        return $store.countries.query();
      },
    },
  });

  //Guard against going back in the flow
  $transitionsProvider.onBefore({
    to: 'signupClub.creating',
  }, transition => {

    //Get data
    const from = transition.from();

    //Only if going forward in the steps
    if (from.name === 'signupClub.interests') {
      return;
    }

    //Go to first step
    return transition.router.stateService.target('signupClub.oauth');
  });

  //Guard against going directly to the next steps
  $transitionsProvider.onBefore({
    from: '',
    to: 'signupClub.*',
  }, transition => {

    //Get data
    const to = transition.to();
    const from = transition.from();
    const params = transition.params();

    //If going to loading page or coming from another state, all good
    if (
      from.name ||
      to.name === 'signupClub.oauth' ||
      to.name === 'signupClub.contact'
    ) {
      return;
    }

    //Direct to Frello, skip oauth step
    const urlParams = new URLSearchParams(window.location.search);
    const isDirectToFrello = urlParams.get('isDirectToFrello');
    if (isDirectToFrello) {
      return transition.router.stateService.target('signupClub.contact', params);
    }

    //Go to first step
    return transition.router.stateService.target('signupClub.oauth', params);
  });
});
