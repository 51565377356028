
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.CantRenew.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('cantRenew', {
    templateUrl: 'subscription/modals/cant-renew.html',
    controller: 'ModalCantRenewSubscriptionCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalCantRenewSubscriptionCtrl', function(
  $controller, $modalInstance, ErrorCodes, Member
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Init
    this.canDiscuss = false;

    //Get data from error
    const {code, data} = this.error;

    //Find error code
    for (const key in ErrorCodes) {
      if (code === ErrorCodes[key]) {
        this.errorKey = key;
        break;
      }
    }

    //Check if can discuss
    if ([
      'renewalTooLate', 'memberAgesInvalid', 'paymentNotEnabled',
      'membershipLimitExceeded',
    ].indexOf(this.errorKey) > -1) {
      this.canDiscuss = true;
    }

    //Find members
    if (data && data.members) {
      this.members = data.members.map(member => new Member(member));
    }
  };
});
