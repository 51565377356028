
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.Coupon.Card', [])

/**
 * Component
 */
.component('cardCoupon', {
  templateUrl: 'coupon/cards/coupon.html',
  controller: 'CardCouponCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    coupon: '<',
    onView: '&',
    onPurchase: '&',
  },
})

/**
 * Controller
 */
.controller('CardCouponCtrl', function() {

  /**
   * View coupon
   */
  this.view = function() {
    const {coupon} = this;
    this.onView({$event: {coupon}});
  };

  /**
   * Purchase coupon
   */
  this.purchase = function() {
    const {coupon} = this;
    this.isPurchasing = true;
    this
      .onPurchase({$event: {coupon}})
      .finally(() => this.isPurchasing = false);
  };

  /**
   * Stop coupon
   */
  this.stop = function() {
    const {coupon} = this;
    this.onStop({$event: {coupon}});
  };
});
