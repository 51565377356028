
/**
 * Module definition and dependencies
 */
angular.module('Shared.GroupRuleTypes.Constant', [])

/**
 * Constant definition
 */
.constant('GroupRuleTypes', {

  //Generic types
  NUMBER: 'number',
  TEXT: 'text',
  LIST: 'list',
  ARRAY: 'array',
  DATE: 'date',
  BOOLEAN: 'boolean',

  //Specific types
  DOB: 'dob',
  AGE: 'age',
  GROUP: 'group',
  MEMBERSHIP: 'membership',
  GRADE: 'grade',
  GRADE_DATE: 'gradeDate',
});
