
/**
 * Module definition and dependencies
 */
angular.module('Shared.Notification.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config($apiProvider => {

  //Register endpoint
  $apiProvider.registerEndpoint('notification', {
    model: 'Notification',
    url: 'notification',
    actions: {
      saveSubscription: {
        url: 'saveSubscription',
        method: 'POST',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Notification', function($baseModel, $api) {

  /**
   * Constructor
   */
  function Notification(data) {
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(Notification.prototype, $baseModel.prototype);

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Save subscription to push notifications
   */
  Notification.saveSubscription = function(subscription) {
    return $api.notification.saveSubscription(subscription);
  };

  //Return
  return Notification;
});
