
/**
 * Define values
 */
const NZ_GST = 'nz_gst';
const AU_ABN = 'au_abn';
const AU_ACN = 'au_acn';
const EU_VAT = 'eu_vat';
const TAX_OTHER = 'other';
const TAX_NONE = 'none';

/**
 * Define array
 */
const TaxNumberTypes = [
  {
    value: NZ_GST,
    label: 'NZ GST',
  },
  {
    value: AU_ABN,
    label: 'AU ABN',
  },
  {
    value: AU_ACN,
    label: 'AU ACN',
  },
  {
    value: EU_VAT,
    label: 'EU VAT',
  },
  {
    value: TAX_OTHER,
    label: 'Other',
  },
  {
    value: TAX_NONE,
    label: 'None',
  },
];

/**
 * Set properties
 */
TaxNumberTypes.NZ_GST = NZ_GST;
TaxNumberTypes.AU_ABN = AU_ABN;
TaxNumberTypes.AU_ACN = AU_ACN;
TaxNumberTypes.EU_VAT = EU_VAT;
TaxNumberTypes.OTHER = TAX_OTHER;
TaxNumberTypes.NONE = TAX_NONE;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.TaxNumberTypes.Options', [])
  .constant('TaxNumberTypes', TaxNumberTypes);
