
/**
 * Card tabs component
 */

/**
 * Module definition and dependencies
 */
angular.module('Shared.Cards.CardTabs.Component', [])

/**
 * Component
 */
.component('cardTabs', {
  template: `<section class="CardTabs" ng-if="$ctrl.card.isOpen || $ctrl.card.isStatic" ng-transclude></section>`,
  transclude: true,
  require: {
    card: '^^',
  },
  bindings: {
    canNavigate: '<',
  },
  controller() {

    /**
     * On init
     */
    this.$onInit = function() {
      this.card.addClass('Card--withTabs');
    };
  },
});
