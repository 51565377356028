
/**
 * Module definition and dependencies
 */
angular.module('Shared.Dropdown.Toggle.Component', [])

/**
 * Component
 */
.component('dropdownToggle', {
  template: `
    <div class="Dropdown-toggle" ng-transclude ng-click="$ctrl.dropdown.toggle()"></div>
  `,
  transclude: true,
  require: {
    dropdown: '^^',
  },
  controller($document, $element) {

    //Click handler
    const $ctrl = this;
    const clickHandler = function(event) {
      let {target} = event;
      while (target) {
        if (target === $element[0]) {
          return;
        }
        target = target.parentNode;
      }
      $ctrl.dropdown.close();
    };

    /**
     * On init
     */
    this.$onInit = function() {
      $document.on('click', clickHandler);
    };

    /**
     * On destroy
     */
    this.$onDestroy = function() {
      $document.off('click', clickHandler);
    };
  },
});
