
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Overview.Controller', [])

/**
 * Controller
 */
.controller('AdminActivityOverviewCtrl', function($controller, $store) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminActivityCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Determine flags
    this.canEdit = this.user.isAdmin();
    this.isSuper = this.user.isSuper();
    this.numItems = this.activities.length;

    //Check limit
    this.checkLimit();
  };

  /**
   * On made default
   */
  this.onMadeDefault = function() {
    this.loadActivities();
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    this.loadActivities();
  };

  /**
   * On saved
   */
  this.onSaved = function() {
    this.loadActivities();
  };

  /**
   * Load activities
   */
  this.loadActivities = function() {

    //Mark as loading
    this.isLoading = true;
    this.hasAny = false;

    //Query activities
    return $store.activities
      .query(true)
      .then(activities => {
        this.activities = activities;
        this.numItems = this.activities.length;
        this.checkLimit();
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Check limit
   */
  this.checkLimit = function() {

    //Get data
    const {numItems} = this;
    const {activityLimit} = this.club.permissions;

    //Check limit
    this.hasReachedLimit = (activityLimit && numItems >= activityLimit);
  };
});
