
/**
 * Module definition and dependencies
 */
angular.module('Shared.Absolute.Filter', [])

/**
 * Filter definition
 */
.filter('abs', function() {
  return function(value) {
    if (value === null || value === undefined) {
      return '';
    }
    return Math.abs(value);
  };
});
