
/**
 * Module definition and dependencies
 */
angular.module('Shared.Text.Nl2br.Filter', [])

/**
 * Filter definitions
 */
.filter('nl2br', function($sce) {
  return function(text) {
    if (typeof text === 'number') {
      return String(text);
    }
    if (typeof text !== 'string') {
      return '';
    }
    if ((text = String(text)) === '') {
      return '';
    }
    text = text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>');
    return $sce.trustAsHtml(text);
  };
})

.filter('trustAsResourceUrl', ['$sce', function($sce) {
  return function(val) {
    return $sce.trustAsResourceUrl(val);
  };
}]);
