
/**
 * Module definition and dependencies
 */
angular.module('Shared.CampaignMonitor.Service', [])

/**
 * Config
 */
.config($apiProvider => {
  $apiProvider.registerEndpoint('campaignMonitor', {
    url: 'integration/campaignMonitor',
    actions: {
      getLists: {
        url: 'lists',
        method: 'GET',
        isArray: true,
      },
      getListInterests: {
        url: 'lists/:listId/interests',
        method: 'GET',
        isArray: true,
      },
      addListMember: {
        url: 'lists/:listId/member/:memberId',
        method: 'PUT',
      },
      addListMembers: {
        url: 'lists/:listId',
        method: 'PUT',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('CampaignMonitor', function($api, $q) {

  //Create service
  const CampaignMonitor = {

    /**************************************************************************
     * Raw API calls
     ***/

    /**
     * Get lists
     */
    getLists() {
      return $api.campaignMonitor.getLists();
    },

    /**
     * Get list interests
     */
    getListInterests(listId) {
      return $api.campaignMonitor.getListInterests({listId});
    },

    /**
     * Add list member
     */
    addListMember(listId, memberId, forceSubscribe) {
      const data = {forceSubscribe};
      const query = {listId, memberId};
      return $api.campaignMonitor.addListMember(query, data);
    },

    /**
     * Add list members in bulk
     */
    addListMembers(listId, filter) {
      const query = Object.assign({}, filter, {listId});
      return $api.campaignMonitor.addListMembers(query, {});
    },

    /**************************************************************************
     * Macro helpers
     ***/

    /**
     * Helper to subscribe a member easily to multiple lists
     */
    addMember(member, lists, forceSubscribe) {

      //No lists
      if (!lists) {
        return $q.resolve();
      }

      //Map lists to promises
      const promises = lists
        .filter(list => list.isChecked)
        .map(list => this.addListMember(list.id, member.id, forceSubscribe));

      //Nothing to do?
      if (promises.length === 0) {
        return $q.resolve();
      }

      //Resolve all promises
      return $q.all(promises);
    },

    /**
     * Helper to subscribe members to multiple lists
     */
    addMembers(filter, lists) {

      //No lists
      if (!lists) {
        return $q.resolve();
      }

      //Map lists to promises
      const promises = lists
        .filter(list => list.isChecked)
        .map(list => this.addListMembers(list.id, filter));

      //Nothing to do?
      if (promises.length === 0) {
        return $q.resolve();
      }

      //Resolve all promises
      return $q.all(promises);
    },

    /**
     * Get combined lists and interests
     */
    getListsAndInterests() {
      return this.getLists();
    },

    /**
     * Toggle defaults in a list
     */
    toggleDefaults(lists, defaultLists) {

      //No default lists? Nothing to do
      if (!Array.isArray(defaultLists) || defaultLists.length === 0) {
        return;
      }

      //Loop actual lists
      lists.forEach(list => {

        //Get list data and find match in defaults
        const {id} = list;
        const dList = defaultLists.find(item => item.id === id);
        if (!dList) {
          list.isChecked = false;
          return;
        }

        //Copy checked flag
        list.isChecked = dList.isChecked;
      });
    },
  };

  //Return service
  return CampaignMonitor;
});
