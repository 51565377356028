
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.AddGuest.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addGuest', {
    templateUrl: 'admin/event/modals/add-guest.html',
    controller: 'ModalAddGuestCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalAddGuestCtrl', function(
  $controller, $modalInstance, $notice, $filter, moment, Helpers, FeeMethods,
  EventSeriesHandling, DateFormat
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Get constants
  const {RULES, NONE} = FeeMethods;
  const {SERIES, INSTANCE, RANGE} = EventSeriesHandling;

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get data
    const {removeOption, changeOption} = Helpers;
    const {maxAttendees, startDate, firstDate, lastDate} = this.event;
    const guestRule = this.event.getGuestRule();
    const now = moment();

    //Prepare models
    this.guest = {};
    this.model = {
      hasAttended: false,
      seriesHandling: null,
      fromDate: null,
      toDate: null,
      feeMethod: NONE,
      meta: {
        notifyByEmail: false,
      },
    };

    //Event series handling
    if (this.event.isLimitedSeries && lastDate) {
      const date = startDate.format(DateFormat.formats.standard);
      const from = firstDate.format(DateFormat.formats.standard);
      const to = lastDate.format(DateFormat.formats.standard);
      this.eventSeriesHandling =
        changeOption(changeOption(EventSeriesHandling,
          SERIES, `The whole event series (${from} – ${to})`),
        INSTANCE, `This event instance only (${date})`);
    }

    //Guest rule available
    if (guestRule) {

      //Make new label
      const amount = $filter('currency')(guestRule.fee || 0);
      const label = `Default guest fee (${amount})`;

      //Update model
      this.model.feeMethod = RULES;
      this.model.fee = guestRule.fee || 0;

      //Change option
      this.feeMethods = changeOption(FeeMethods, RULES, label);
    }
    else {
      this.feeMethods = removeOption(FeeMethods, RULES);
    }

    //Limited series
    if (this.event.isLimitedSeries) {
      this.model.seriesHandling = SERIES;
      this.model.fromDate = moment.max(firstDate, now);
      this.model.toDate = lastDate;
    }

    //Count number already going
    this.max = maxAttendees;
    this.numGoing = this.attendees
      .filter(attendee => !attendee.isRemoved)
      .length;
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;

    //Sanitise
    if (this.model.seriesHandling !== RANGE) {
      delete this.model.fromDate;
      delete this.model.toDate;
    }

    //Use save handler
    this
      .handler(this.guest, this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => $notice.showError('Failed to add guest'))
      .finally(() => this.isSaving = false);
  };
});
