
/**
 * Module definition and dependencies
 */
angular.module('Shared.Filesize.Filter', [])

/**
 * Filter definition
 */
.filter('filesize', function() {
  return function(size) {
    if (typeof size === 'undefined' || size === '') {
      return '';
    }
    if (size >= (1024 * 1024 * 1024 * 1024)) {
      return Math.round(size / (1024 * 1024 * 1024 * 1024), 1) + 'Tb';
    }
    else if (size >= (1024 * 1024 * 1024)) {
      return Math.round(size / (1024 * 1024 * 1024), 1) + 'Gb';
    }
    else if (size >= (1024 * 1024)) {
      return Math.round(size / (1024 * 1024), 1) + 'Mb';
    }
    else if (size >= 1024) {
      return Math.round(size / 1024) + 'Kb';
    }
    else {
      return Math.round(size) + 'b';
    }
  };
});
