
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.Change.Controller', [
  'App.Account.Base.Controller',
])

/**
 * Controller
 */
.controller('SubscriptionChangeCtrl', function(
  $controller, $state, $modal, ValidationError, Text, TextTypes,
  Membership
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AccountBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Setup
   */
  this.setup = function() {

    //Set initial step
    this.stepChangeMembership();

    //Load usage
    this.loadUsage();

    //Check if personal details need to be shown
    this.checkPersonalDetails();
  };

  /**
   * Load usage
   */
  this.loadUsage = function() {

    //Query usage
    return Membership
      .usage()
      .then(usage => this.usage = usage)
      .then(() => this.mapUsage());
  };

  /**
   * Map usage to memberships
   */
  this.mapUsage = function() {

    //Get data
    const {usage, memberships} = this;

    if (!usage || !memberships) {
      return;
    }

    //Map usage to memberships
    memberships.forEach(membership => {
      membership.numMembers = usage.numMembers[membership.id] || 0;
      membership.inUse = usage.inUse[membership.id] || false;
    });
  };

  /**
   * Check if personal details step should be shown
   */
  this.checkPersonalDetails = function() {
    this.showPersonalDetails = this.user.needsReminder('checkPersonalDetails');
  };

  /**
   * Change of membership
   */
  this.changedMembership = function($event) {
    this.changes = $event.changes;
  };

  /**
   * Check if can change
   */
  this.checkCanChange = function() {

    //Get details
    const {changes} = this;

    //Start checking
    this.isChecking = true;

    //Check if can change
    return this.old
      .getChangeOwnData(changes)
      .then(data => this.processData(data))
      .catch(error => {
        if (error instanceof ValidationError) {
          this.onError({error});
        }
        throw error;
      })
      .finally(() => this.isChecking = false);
  };

  /**
   * Process subscription data
   */
  this.processData = function(data) {

    //Get data
    const {meta, subscriptions: subs} = data;

    //Set sub and full membership in request
    this.subscription = subs[0];
    this.membership = this.memberships
      .find(m => m.isSame(subs[0].membership));

    //Check if the subscription has a fee
    this.hasMembershipFee = meta.hasFee;
    this.hasAutoAccountCredit = meta.hasAutoAccountCredit;
    this.requiresPayment = meta.requiresPayment;
    this.meta = meta;

    //Auto agree when there are no conditions or when retrying
    this.hasMembershipConditions = !!this.membership.conditions;
    this.hasAgreedToConditions = !this.hasMembershipConditions;
  };

  /**
   * Prepare payment
   */
  this.preparePayment = function() {

    //Get data
    const {meta, membership, old: {id: subscriptionId}, changes} = this;
    const label = `${membership.name} membership subscription`;
    const {proRation, discounts, totalBaseFee: amount} = meta;

    //Make line items and set extra data
    this.payment.setLineItems([{label, amount}]);
    this.payment.setExtraData({subscriptionId, changes});
    this.payment.setRedirectPath(`subscription/change/${subscriptionId}`);

    //Add discount line items
    for (const discount of discounts) {
      this.payment.addLineItem({
        label: discount.name,
        amount: -1 * discount.amount,
      });
    }

    //Add pro-ration line item
    if (proRation && proRation.deduction !== 0) {
      this.payment.addLineItem({
        label: `Pro-ration`,
        amount: -1 * proRation.deduction,
        suffix: `${proRation.numDays}/${proRation.termDays} days`,
      });
    }
  };

  /**
   * Set agreement
   */
  this.setAgreedToConditions = function($event) {
    this.hasAgreedToConditions = $event.hasAgreed;
  };

  /**
   * Change handler
   */
  this.change = function(isPayingLater = false) {

    //Get changes
    const {changes} = this;

    //Change
    return this.old
      .changeOwn(changes)
      .then(() => this.onChanged({isPayingLater}))
      .catch(error => this.onError({error}));
  };

  /**
   * Pay later, just change
   */
  this.onPayLater = function() {

    //Get data
    const {club} = this;
    const payingFor = `membership fee`;
    const amount = this.payment.lineItemTotal;

    //Confirm
    return $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-pay-later.html',
        locals: {club, payingFor, amount},
        rejectOnDismissal: true,
      })
      .result
      .then(() => this.change(true))
      .catch(() => {});
  };

  /**
   * Paid success handler
   */
  this.onPaid = function($event) {
    this.onChanged($event);
  };

  /**
   * On changed handler
   */
  this.onChanged = function($event) {

    //Get data
    const {isPayingLater, payment} = $event;
    const isPaid = (payment && payment.isPaid);
    const {club} = this;
    const amount = this.payment.lineItemTotal;

    //Load text then open modal
    Text
      .getText(TextTypes.PAYMENT_INSTRUCTIONS)
      .then(paymentInstructions => {
        $modal
          .open('basic', {
            templateUrl: 'subscription/modals/changed.html',
            locals: {
              club, isPaid, isPayingLater, amount, paymentInstructions,
            },
          })
          .closed
          .then(() => this.redirectForSubscriptions());
      });
  };

  /**
   * Process payment outcome
   */
  this.processPaymentOutcome = function(payment) {

    //Not paid, show generic modal
    if (!payment.isPaid) {
      return $base.processPaymentOutcome.call(this, payment);
    }

    //Paid, use our custom success handler
    this.onChanged({payment});
  };

  /**
   * On error handler
   */
  this.onError = function($event) {

    //Get data
    const {error} = $event;
    const {old: subscription} = this;
    const isChange = true;

    //Open modal
    $modal.open('cantRenew', {locals: {error, subscription, isChange}});
  };

  /**
   * Check route is valid
   */
  this.checkRouteValid = function() {

    //Get data
    const {old, outcome} = this;
    const {subId} = this.transition.params();

    //Ensure valid link clicked
    if (subId && (!old || old.id !== subId)) {
      return $state
        .go('subscription.overview')
        .then(() => {
          $modal.open('basic', {templateUrl: 'modals/invalid-link.html'});
        });
    }

    //Must have old sub to renew
    if (!old) {
      return $state.go('subscription.overview');
    }

    //Already renewed, not expiring and not here for the payment outcome?
    if ((old.isRenewed || !old.isExpiring) && !outcome) {
      return $state.go('subscription.overview');
    }
  };

  /**************************************************************************
   * Navigation
   ***/

  /**
   * Change membership
   */
  this.stepChangeMembership = function() {
    this.step = 'changeMembership';
  };

  /**
   * View details of new membership
   */
  this.stepDetails = function() {
    this.step = 'details';
  };

  /**
   * Personal details step
   */
  this.stepPersonalDetails = function() {
    this.step = 'personalDetails';
  };

  /**
   * Agree to conditions step
   */
  this.stepAgreeConditions = function() {
    this.step = 'agreeConditions';
  };

  /**
   * Change without payment step
   */
  this.stepChangeNoPayment = function() {
    this.step = 'changeNoPayment';
  };

  /**
   * Next from change membership
   */
  this.nextFromChangeMembership = function() {

    //Check if can change
    this
      .checkCanChange()
      .then(() => this.stepDetails());
  };

  /**
   * Next from details
   */
  this.nextFromDetails = function() {

    //Need to check personal details?
    if (this.showPersonalDetails) {
      this.stepPersonalDetails();
    }

    //Otherwise skip
    else {
      this.nextFromPersonalDetails();
    }
  };

  /**
   * Prev from details
   */
  this.prevFromDetails = function() {
    this.stepChangeMembership();
  };

  /**
   * Next from personal details
   */
  this.nextFromPersonalDetails = function() {

    //Get details
    const {changes} = this;

    //Re-check subscription, as address/dob may have been entered, which
    //could potentially apply a discount
    this.old
      .getChangeOwnData(changes)
      .then(data => this.processData(data));

    //Got membership conditions?
    if (this.hasMembershipConditions) {
      return this.stepAgreeConditions();
    }

    //Onwards
    this.nextFromConditions();
  };

  /**
   * Prev from personal details
   */
  this.prevFromPersonalDetails = function() {
    this.stepDetails();
  };

  /**
   * Prev from conditions
   */
  this.prevFromConditions = function() {

    //Need to check personal details?
    if (this.showPersonalDetails) {
      this.stepPersonalDetails();
    }

    //Otherwise skip
    else {
      this.prevFromPersonalDetails();
    }
  };

  /**
   * Next from conditions
   */
  this.nextFromConditions = function() {

    //No fee?
    if (!this.hasMembershipFee) {
      return this.stepChangeNoPayment();
    }

    //Prepare payment
    this.preparePayment();

    //Has fee, check if can pay with credit
    if (this.payment.canUseAccountCredit && !this.hasAutoAccountCredit) {
      return this.stepUseCredit();
    }

    //Onwards
    this.nextFromUseCredit();
  };

  /**
   * Prev from change without payment
   */
  this.prevFromChangeNoPayment = function() {

    //Got membership conditions?
    if (this.hasMembershipConditions) {
      return this.stepAgreeConditions();
    }

    //Backwards
    this.prevFromConditions();
  };

  /**
   * Prev from use account credit
   */
  this.prevFromUseCredit = function() {
    this.prevFromChangeNoPayment();
  };

  /**
   * Prev from select payment method
   */
  this.prevFromSelectPaymentMethod = function() {

    //Check if can pay with credit
    if (this.payment.canUseAccountCredit && !this.hasAutoAccountCredit) {
      return this.stepUseCredit();
    }

    //Backwards
    this.prevFromUseCredit();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    $state.go('subscription.overview');
  };
});
