
/**
 * Module definition and dependencies
 */
angular.module('App.Profile.Controller', [])

/**
 * Controller
 */
.controller('ProfileCtrl', function(
  $q, $modal, $notice, $timeout, $store, Config, Upload,
  Intercom, Tag
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Get params
    const {openCard} = this.transition.params();

    //Set data and flags
    this.isSaving = false;
    this.openCard = openCard;
    this.customFields = [];

    //Load custom fields in the background
    this.loadCustomFields();
    this.loadActivities();
  };

  /**
   * Show QR code
   */
  this.showQr = function() {
    this.isShowingQr = true;
  };

  /**
   * Save
   */
  this.save = function($event) {

    //Get model
    const {model} = $event;

    //Save
    return this.user
      .patch(model)
      .then(() => {
        Intercom.event('Updated profile');
        $notice.show('Details updated');
      })
      .catch(error => {
        $notice.showError('Failed to update details');
        throw error;
      });
  };

  /**
   * Update credentials
   */
  this.updateCredentials = function($event) {

    //Get model
    const {model} = $event;

    //Save
    return this.user
      .changeCredentials(model)
      .then(() => {
        Intercom.event('Updated credentials');
        $notice.show('Sign in details updated');
      });
  };

  /**
   * Update avatar
   */
  this.updateAvatar = function($event) {

    //Get data from event
    const {data, color} = $event;

    //Track in intercom
    Intercom.event('Updated avatar');

    //Avatar changed?
    if (data && data.avatar) {

      //This nonsense is necessary because for some reason the avatar changes
      //won't propagate via the s3-image directive otherwise
      this.user.avatar = null;
      $timeout(() => this.user.avatar = angular.copy(data.avatar));
      return $q.resolve();
    }

    //Color changed?
    else if (color) {
      return this.user.patch({color});
    }
  };

  /**
   * Upload avatar
   */
  this.uploadAvatar = function($event) {

    //Get file
    const {file} = $event;
    const {id} = this.user;
    const {baseUrl} = Config.api;

    //Upload
    return Upload.upload({
      url: `${baseUrl}/member/${id}/avatar`,
      data: {
        avatar: file,
      },
    });
  };

  /**
   * Delete avatar
   */
  this.deleteAvatar = function() {

    //Get user and define handler
    const user = this.user;
    const handler = function() {
      return user.deleteAvatar();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'profile/modals/confirm-delete-avatar.html',
        locals: {user, handler},
      })
      .result
      .then(() => {
        this.user.avatar = null;
      });
  };

  /**
   * Ensure not suspended
   */
  this.ensureNotSuspended = function() {

    //Get data
    const {user} = this;

    //Check if suspended
    if (user.isSuspended) {
      $modal.open('basic', {
        templateUrl: 'modals/suspended.html',
        locals: {user},
      });
      return $q.reject();
    }
  };

  /**
   * Load custom fields
   */
  this.loadCustomFields = function() {
    return $store.customFields
      .query({model: 'Member'})
      .then(fields => fields.filter(field => field.isVisible))
      .then(fields => this.customFields = fields);
  };

  /**
   * Load tags
   */
  this.loadTags = function() {
    return Tag
      .queryOwn()
      .then(data => this.tags = data.tags);
  };

  /**
   * Load activities
   */
  this.loadActivities = function() {
    return $store.activities
      .query(true)
      .then(activities => {
        this.activities = activities;
        this.hasGrades = this.activities.some(a => a.hasGrades);
      });
  };

  /**
   * Update vaccination status
   */
  this.verifyVaccinationStatus = function() {
    if (!this.club.canVerifyVaccinationStatus) {
      return;
    }
    $modal.open('verifyVaccinationStatus', {
      locals: {
        club: this.club,
        member: this.user,
        isOwn: true,
      },
    });
  };
});
