
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.SignIn.Card', [])

/**
 * Component
 */
.component('cardSettingsSignIn', {
  templateUrl: 'admin/settings/cards/sign-in.html',
  controller: 'CardSettingsSignInCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSettingsSignInCtrl', function(
  $controller, CardCloseReasons
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'signIn';

    //Call parent init
    $base.$onInit.call(this);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
    this.isErrorSignIn = false;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Error if no methods are enabled
    if (
      !this.model.canUseCredentials &&
      !this.model.providers.google.isEnabled &&
      !this.model.providers.facebook.isEnabled
    ) {
      this.isErrorSignIn = true;
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Create settings data from model
    const settings = {[this.type]: this.model};

    //Save
    this
      .onSave({$event: {settings}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
