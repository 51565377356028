
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.Details.Card', [])

/**
 * Component
 */
.component('cardClubDetails', {
  templateUrl: 'admin/club/cards/details.html',
  controller: 'CardClubDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    country: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubDetailsCtrl', function(moment) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;

    //Create model subset
    this.model = this.club.extract([
      'name', 'shortName', 'email', 'phone', 'website', 'address',
      'timezone',
    ]);

    //Set country data
    this.setCountryData();
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Set country data
   */
  this.setCountryData = function() {

    //Find country and get current timezone
    const {country} = this;
    const {timezone} = this.model;

    //Set available country timezones and country code
    this.timezones = country.timezones;
    this.country = country;

    //Set timezone if not set or invalid
    if (!timezone || !this.timezones.includes(timezone)) {
      this.model.timezone = country.timezones[0];
    }
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event: {model: this.model}})
      .then(() => {
        moment.tz.setDefault(this.model.timezone);
        this.form.$setPristine();
        this.club.markStepComplete('checkContactDetails', true);
      })
      .finally(() => this.isSaving = false);
  };
});
