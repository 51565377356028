
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.Add', [
  'App.Admin.Resource.Add.Controller',
  'App.Admin.Resource.Add.Details.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.resource.add', {
    url: '/create/:resourceId',
    params: {
      resourceId: null,
    },
    component: 'adminResourceAddRoute',
    data: {
      roles: ['admin'],
    },
    resolve: {
      resources(Resource) {
        return Resource.query();
      },
      resource(transition, Resource) {
        const params = transition.params();
        if (!params.resourceId) {
          return new Resource();
        }
        return Resource
          .findById(params.resourceId)
          .then(resource => resource.clone(true));
      },
    },
  });
})

/**
 * Route component
 */
.component('adminResourceAddRoute', {
  controller: 'AdminResourceAddCtrl',
  templateUrl: 'admin/resource/add/add.html',
  bindings: {
    club: '<',
    resource: '<',
    module: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.resource.add'}, transition => {
    const module = transition.injector().get('module');
    Page.setTitle(`Add ${module.singular}`);
    Page.addCrumb({sref: 'admin.resource.add'});
  });
});
