
/**
 * Define values
 */
const KEY_TAG_BLACK = 'PRX-TAG-MF-B';
const KEY_TAG_WHITE = 'PRX-TAG-MF-W';
const CARD = 'PRX-ISO-MF';
const WRISTBAND_SMALL = 'PRX-SWB-MF-B-S';
const WRISTBAND_MEDIUM = 'PRX-SWB-MF-B-M';
const WRISTBAND_LARGE = 'PRX-SWB-MF-B-L';

/**
 * Define array
 */
const TagOrderTypes = [
  {
    value: KEY_TAG_BLACK,
    label: 'Key Tag (Black)',
  },
  {
    value: KEY_TAG_WHITE,
    label: 'Key Tag (White)',
  },
  {
    value: CARD,
    label: 'ISO Graphic Printable Card',
  },
  {
    value: WRISTBAND_SMALL,
    label: 'Silicone Wristband (Black, S, 152mm)',
  },
  {
    value: WRISTBAND_MEDIUM,
    label: 'Silicone Wristband (Black, M, 180mm)',
  },
  {
    value: WRISTBAND_LARGE,
    label: 'Silicone Wristband (Black, L, 190mm)',
  },
];

/**
 * Set properties
 */
TagOrderTypes.KEY_TAG_BLACK = KEY_TAG_BLACK;
TagOrderTypes.KEY_TAG_WHITE = KEY_TAG_WHITE;
TagOrderTypes.CARD = CARD;
TagOrderTypes.WRISTBAND_SMALL = WRISTBAND_SMALL;
TagOrderTypes.WRISTBAND_MEDIUM = WRISTBAND_MEDIUM;
TagOrderTypes.WRISTBAND_LARGE = WRISTBAND_LARGE;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.TagOrderTypes.Options', [])
  .constant('TagOrderTypes', TagOrderTypes);
