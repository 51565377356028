
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.Conditions', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.conditions', {
    url: '/conditions',
    component: 'registerConditionsRoute',
  });
})

/**
 * Route component
 */
.component('registerConditionsRoute', {
  controller: 'RegisterConditionsCtrl',
  templateUrl: 'portal/register/steps/conditions.html',
  bindings: {
    club: '<',
    registration: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterConditionsCtrl', function($controller, Text, TextTypes) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('RegisterBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Load terms
    this.loadTerms();
  };

  /**
   * Load terms
   */
  this.loadTerms = function() {

    //Flag as loading
    this.isLoading = true;

    //Load intro text
    Text
      .getText(TextTypes.TERMS_AND_CONDITIONS)
      .then(text => this.termsAndConditions = text)
      .finally(() => this.isLoading = false);
  };

  /**
   * Agree
   */
  this.toggleAgreement = function(hasAgreed) {
    this.hasAgreed = hasAgreed;
    this.isErrorMustAgree = false;
  };

  /**
   * Validate
   */
  this.validate = function() {

    //Not agreed to conditions?
    if (!this.hasAgreed) {
      this.isErrorMustAgree = true;
      return;
    }

    //Clear error and continue
    this.isErrorMustAgree = false;
    this.continue();
  };
});
