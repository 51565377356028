
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Overview', [
  'App.Admin.Membership.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.membership.overview', {
    url: '',
    component: 'adminMembershipOverviewRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('memberships');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminMembershipOverviewRoute', {
  controller: 'AdminMembershipOverviewCtrl',
  templateUrl: 'admin/membership/overview/overview.html',
  bindings: {
    page: '<',
    filter: '<',
    user: '<',
    club: '<',
    activities: '<',
    module: '<',
  },
});
