
/**
 * Module definition and dependencies
 */
angular.module('App.Kiosk.KioskInterceptor.Service', [])

/**
 * Interceptor service
 */
.factory('KioskInterceptor', function(Kiosk) {

  /**
   * Determine if this is a HTML template request
   */
  function isTemplateRequest(request) {
    return (request && request.url.indexOf('.html') !== -1);
  }

  /**
   * Check kiosk mode enabled
   */
  function checkKioskEnabled(response) {

    //No headers to get?
    if (!response || typeof response.headers !== 'function') {
      return;
    }

    //Check if enabled
    //NOTE: Only requests with cookies will be able to return a header
    if (response.config.withCredentials) {
      Kiosk.setEnabled(!!response.headers('X-Kiosk'));
    }
  }

  /**
   * Interceptor
   */
  return {

    /**
     * Response handling
     */
    response(response) {
      if (!isTemplateRequest(response.config)) {
        checkKioskEnabled(response);
      }
      return response;
    },

    /**
     * Response error handling
     */
    responseError(response) {
      if (!isTemplateRequest(response.config)) {
        checkKioskEnabled(response);
      }
      throw response;
    },
  };
});
