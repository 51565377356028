
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.EditAssignee.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editAssignee', {
    templateUrl: 'admin/resource/modals/edit-assignee.html',
    controller: 'ModalEditAssigneeCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalEditAssigneeCtrl', function(
  $controller, $modalInstance
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Create model subset
    this.model = this.assignee.extract([
      'member', 'startDate', 'endDate', 'isActive',
    ]);
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Save
    this.isSaving = true;
    this
      .handler(this.assignee.id, this.model)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isSaving = false);
  };
});
