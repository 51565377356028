
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Add.System.Card', [])

/**
 * Component
 */
.component('cardEventAddSystem', {
  templateUrl: 'admin/event/cards/system.html',
  controller: 'CardEventAddSystemCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    event: '<',
    system: '<',
    activities: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventAddSystemCtrl', function(
  $controller, CardCloseReasons, $storage
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardEventEditSystemCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;

    //Check if auto light/door settings are in local storage
    if (this.canControlLights && $storage.get('event.autoLights')) {
      this.model.autoLights = $storage.get('event.autoLights');
    }
    if (this.canControlDoors && $storage.get('event.autoDoors')) {
      this.model.autoDoors = $storage.get('event.autoDoors');
    }
  };

  /**
   * Merge
   */
  this.merge = function() {

    //Get data
    const {event, model} = this;

    //Merge
    this.onMerge({$event: {event, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Store auto light/door settings in local storage
    this.storeAutoValues();

    //Merge
    this.merge();

    //Open next card
    if (!this.event.isHidden) {
      this.card.open('event.attendance', CardCloseReasons.OPENED_NEXT);
    }
    else {
      this.card.open('event.organisers', CardCloseReasons.OPENED_NEXT);
    }
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previousTab();
  };
});
