
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Visitor.Base.Controller', [])

/**
 * Controller
 */
.controller('VisitorBaseCtrl', function($timeout) {

  /**
   * Init
   */
  this.$onInit = function() {

    //Create shortcut
    this.data = this.visit.data;

    //Start faded
    this.fade = true;
    $timeout(() => this.fade = false);
  };

  /**
   * Update data
   */
  this.updateData = function(property, value) {
    this.data[property] = value;
  };

  /**
   * Validate form
   */
  this.validate = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Continue
    this.continue();
  };

  /**
   * Continue to the next step
   */
  this.continue = function() {

    //At last step?
    if (this.visit.isAtLastStep) {

      //Toggle flag
      this.isLoggingVisit = true;

      //Visitor
      this.visit
        .logVisit()
        .then(() => this.postLogVisit())
        .finally(() => this.isLoggingVisit = false);
    }

    //Go to next step
    else {
      this.fade = true;
      this.visit.goToNextStep();
    }
  };

  /**
   * Back to the previous step
   */
  this.back = function() {

    //Go to previous step
    this.fade = true;
    this.visit.goToPreviousStep();
  };

  /**
   * Post log visit handler
   */
  this.postLogVisit = function() {

    //Finish the flow
    this.visit.storeData();
    this.visit.finish();
    this.visit.initializeData();
  };
});
