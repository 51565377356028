
/**
 * Module definition and dependencies
 */
angular.module('Shared.Sentry.Service', [])

/**
 * Service
 */
.factory('Sentry', function($window, $injector, Config) {

  //Get Sentry object from window
  const Sentry = $window.Sentry || null;
  if (!Sentry) {
    throw new Error('Missing Sentry library');
  }

  //Get config and check if Sentry enabled
  const {env: environment, sentry: config} = Config;
  const isSentryEnabled = (config.dsn && typeof Sentry !== 'undefined');

  //Initialize sentry
  if (isSentryEnabled) {
    Sentry.init(Object.assign({environment}, config));
  }

  //Return interface
  return {

    /**
     * Capture exception
     */
    captureException(exception) {

      //Not enabled or invalid?
      if (!isSentryEnabled || !exception || exception.ignoreInSentry) {
        return;
      }

      //Wrap in scope
      Sentry.withScope(scope => {

        //Wrap in try block so we don't generate additional errors here
        try {

          //Get location and store services
          const $location = $injector.get('$location');
          const $store = $injector.get('$store');
          const member = $store.user.instance || null;
          const club = $store.club.instance || null;

          //Prepare tags and extras
          const tags = {};
          const extras = {
            clientUrl: $location.absUrl(),
          };

          //Member ID
          if (member) {
            extras.memberId = member.id;
          }

          //Club data
          if (club) {
            extras.clubId = club.id;
            extras.clubName = club.name;
            tags.identifier = club.identifier;
          }

          //Set extras and tags
          scope.setExtras(extras);
          scope.setTags(tags);
        }
        catch (e) {
          //Pass through
        }

        //Capture exception
        Sentry.captureException(exception);
      });
    },
  };
});
