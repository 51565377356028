
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.EditPayment.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editPayment', {
    templateUrl: 'admin/finance/modals/edit-payment.html',
    controller: 'ModalEditPaymentCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalEditPaymentCtrl', function(
  $controller, $modalInstance, moment, PaymentMethods
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.isEdit = !!this.payment.id;
    this.isSaving = false;
    this.isError = false;

    //Filter payment methods
    this.paymentMethods = PaymentMethods
      .filter(method => !method.isOnline);

    //If payment was not paid by account credit, don't allow to select it
    if (!this.payment.isPaidByAccountCredit) {
      this.paymentMethods = this.paymentMethods
        .filter(method => !method.isAccountCredit);
    }

    //Max date for paid date is today
    this.maxDate = moment().startOf('day');

    //Extract model
    this.model = this.payment.extract([
      'method', 'date', 'reference',
    ]);

    //No date?
    if (!this.model.date) {
      this.model.date = moment().startOf('day');
    }
  };

  /**
   * Mark paid
   */
  this.markPaid = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //TODO: Split into separate modals (for marking as paid and actually editing payment) when migrating to VUE
    let data = this.model;
    if (!this.isEdit) {

      //Get data and create meta object
      //TODO: Clean this up when migrating to VUE
      const {method, date, reference} = this.model;
      const meta = {payment: {method, date, reference}};
      data = {meta};
    }

    //Use save handler
    this
      .handler(data)
      .then(payment => {

        //Update related transaction if given
        if (this.transaction) {
          this.transaction.paymentDate = payment.date;
        }

        //Close modal
        $modalInstance.resolve(payment);
      })
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };
});
