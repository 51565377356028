
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.Controls.Component', [])

/**
 * Booking controls component
 */
.component('bookingGridControls', {
  template: `
    <div class="BookingGridControls">
      <button
        class="BookingGridControl BookingGridControl--current Button Button--icon Button--muted Button--circle"
        tooltip="{{$ctrl.isShowingCurrent ? 'Hide' : 'Show'}} current status"
        ng-click="$ctrl.onToggleCurrent()"
        ng-disabled="!$ctrl.isDisabledToday"
        ng-if="$ctrl.hasLightControl"
      ><i class="Icon">{{$ctrl.isShowingCurrent ? 'visibility_off' : 'visibility'}}</i></button>
      <button
        class="BookingGridControl BookingGridControl--names Button Button--icon Button--muted Button--circle"
        tooltip="Show {{$ctrl.isShowingNames ? 'avatars' : 'member names'}}"
        ng-click="$ctrl.onToggleNames()"
      ><i class="Icon">{{$ctrl.isShowingNames ? 'group' : 'format_list_bulleted'}}</i></button>
      <button
        class="BookingGridControl BookingGridControl--weekView Button Button--icon Button--muted Button--circle"
        tooltip="Week view"
        ng-if="$ctrl.hasUser"
        ng-click="$ctrl.onCalendar()"
      ><i class="Icon">view_week</i></button>
      <button
        class="BookingGridControl Button Button--icon Button--circle"
        tooltip="Choose date"
        ng-click="$ctrl.onDatePicker()"
        ng-disabled="$ctrl.isDisabledDatePicker"
      ><i class="Icon">event</i></button>
      <button
        class="BookingGridControl BookingGridControl--today Button Button--narrow Button--ellipse"
        ng-click="$ctrl.onToday()"
        ng-disabled="$ctrl.isDisabledToday"
      >Today</button>
    </div>
  `,
  bindings: {
    date: '<',
    hasUser: '<',
    hasLightControl: '<',
    isShowingNames: '<',
    isShowingCurrent: '<',
    isDisabledToday: '<',
    isDisabledDatePicker: '<',
    onToggleNames: '&',
    onToggleCurrent: '&',
    onToday: '&',
    onDatePicker: '&',
    onCalendar: '&',
  },
});
