
/**
 * Module definition and dependencies
 */
angular.module('App.Error.Errors.Service', [])

/**
 * Errors service
 */
.factory('Errors', function(
  ResponseError, NetworkError, ServerError, ClientError, ValidationError,
  NotAuthenticatedError, NotAuthorizedError, NotFoundError, TimeoutError,
  ExistsError, ServiceUnavailableError, TooManyRequestsError,
  Config, $injector, $q
) {

  //Services
  let $modal, $state, $log, Sentry, lastState, lastError;

  /**
   * Error store interface
   */
  return {

    /**
     * Remember last state
     */
    setLastState(state) {
      lastState = state;
    },

    /**
     * Get last state
     */
    getLastState() {
      return lastState;
    },

    /**
     * Remember last error
     */
    setLastError(error) {
      lastError = error;
    },

    /**
     * Get last error
     */
    getLastError() {
      return lastError;
    },

    /**
     * Process an error
     */
    process(error, hasPage) {

      //If the error is an ui-router rejection, get error from detail
      if (error.detail && error.detail instanceof Error) {
        error = error.detail;
      }

      //Ignore authentication errors, as they are handled elsewhere
      if (
        error instanceof NotAuthenticatedError ||
        error instanceof NotAuthorizedError
      ) {
        return;
      }

      //Process response errors
      if (error instanceof ResponseError) {
        this.processResponseError(error, hasPage);
      }

      //Log and process javascript errors
      else if (error instanceof Error) {
        this.processInternalError(error);
      }
    },

    /**
     * Process internal errors
     */
    processInternalError(error) {

      //Get services
      $log = $log || $injector.get('$log');
      Sentry = Sentry || $injector.get('Sentry');

      //Log and capture exception
      $log.error(error);
      Sentry.captureException(error);
    },

    /**
     * Process response error
     */
    processResponseError(error) {

      //Get services
      $state = $state || $injector.get('$state');
      $modal = $modal || $injector.get('$modal');

      //Remember last error
      this.setLastError(error);

      //Get data
      let type = this.getType(error);
      const canShowModal = (
        $state.current && $state.current.name &&
        $state.current.name !== 'error'
      );

      //Process with sentry if validation error
      if (error instanceof ValidationError) {
        Sentry.captureException(error);
      }

      //Open modal if we can
      if (canShowModal) {

        //Only open if not already open
        if ($modal.isOpen('error')) {
          return $q.resolve();
        }

        //Open modal
        return $modal.open('error', {locals: {type, error}});
      }

      //Go to error page instead
      return $state.go('error', {type});
    },

    /**
     * Get error type for given error
     */
    getType(error) {
      if (error instanceof ServerError) {
        return 'server';
      }
      else if (error instanceof NetworkError) {
        return 'network';
      }
      else if (error instanceof TimeoutError) {
        return 'timeout';
      }
      else if (error instanceof NotFoundError) {
        if (error.code === 'CLUB_NOT_FOUND') {
          return 'club-not-found';
        }
        else {
          return 'page-not-found';
        }
      }
      return 'other';
    },

    /**
     * Get error class for given response
     */
    getClass(response) {

      //Get data
      const {status, statusText, data} = response;
      const isDevServer = (Config.env === 'dev' && (!data || !data.code));

      //Network error
      if (
        status === -1 || //Angular/browser
        (status === 504 && isDevServer) || //Proxy
        (status === 500 && statusText.match(/offline/)) //Service worker
      ) {
        return NetworkError;
      }

      //Unauthenticated errors
      else if (status === 401) {
        return NotAuthenticatedError;
      }

      //Unauthorized errors
      else if (status === 403) {
        return NotAuthorizedError;
      }

      //Not found errors
      else if (status === 404) {
        return NotFoundError;
      }

      //Exists errors
      else if (status === 409) {
        return ExistsError;
      }

      //Validation errors
      else if (status === 422) {
        return ValidationError;
      }

      //Too many requests errors
      else if (status === 429) {
        return TooManyRequestsError;
      }

      //Service unavailable errors
      else if (status === 503) {
        return ServiceUnavailableError;
      }

      //Request timeout
      else if (status === 408 || status === 504) {
        return TimeoutError;
      }

      //Generic client errors
      else if (status >= 400 && status <= 499) {
        return ClientError;
      }

      //Server errors
      else if (status >= 500 && status <= 599) {
        return ServerError;
      }

      //Generic errors
      return ResponseError;
    },
  };
});
