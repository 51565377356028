
/**
 * Module definition and dependencies
 */
angular.module('Shared.OpenGraph.Service', [])

/**
 * Service definition
 */
.factory('OpenGraph', function($location) {
  class OpenGraph {

    /**
     * Set title
     */
    setTitle(title) {
      document
        .querySelector('meta[property="og:title"]')
        .setAttribute('content', title);
    }

    /**
     * Set description
     */
    setDescription(description) {
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute('content', description);
    }

    /**
     * Set URL
     */
    setUrl(url) {
      url = url || $location.absUrl();
      document
        .querySelector('meta[property="og:url"]')
        .setAttribute('content', url);
    }

    /**
     * Set image
     */
    setImage(image, query = '') {
      if (image) {
        if (typeof image === 'object') {
          image = image.url;
        }
        document
          .querySelector('meta[property="og:image"]')
          .setAttribute('content', `${image}${query}`);
        document
          .querySelector('meta[property="og:image:secure_url"]')
          .setAttribute('content', `${image}${query}`);
      }
    }
  }

  //Return instance
  return new OpenGraph();
});
