
/**
 * Module definition and dependencies
 */
angular.module('Shared.Alert.Component', [])

/**
 * Alert component
 */
.component('alert', {
  template: `
    <div class="Alert Alert--{{$ctrl.type}}" ng-if="!$ctrl.isHidden">
      <div class="AlertContents">
        <div class="Alert-iconWrapper">
          <i class="Icon Icon--l Alert-icon">{{$ctrl.icon}}</i>
        </div>
        <div class="Alert-message" ng-transclude>
          <p ng-bind-html="$ctrl.message | nl2br"></p>
        </div>
      </div>
      <div class="Alert-dismiss" ng-if="$ctrl.canDismiss">
        <i class="Icon" ng-click="$ctrl.dismiss()">close</i>
      </div>
    </div>
  `,
  transclude: true,
  bindings: {
    id: '@',
    type: '@',
    message: '<',
    canDismiss: '<',
    onDismiss: '&',
  },

  /**
   * Controller
   */
  controller($storage) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Init
      this.icon = this.determineIcon();
      this.isHidden = this.id && $storage.get(`hint.${this.id}`);
    };

    /**
     * Determine icon
     */
    this.determineIcon = function() {
      switch (this.type) {
        case 'success':
          return 'check_circle';
        case 'warning':
          return 'error';
        case 'danger':
          return 'cancel';
        case 'info':
          return 'info';
        case 'help':
          return 'help';
      }
    };

    /**
     * Dismiss
     */
    this.dismiss = function() {

      //Set hidden flag
      this.isHidden = true;

      //Permanently dismiss if we got an ID
      if (this.id) {
        $storage.set(`hint.${this.id}`, 1);
        this.onDismiss({$event: {id: this.id}});
      }
    };
  },
});
