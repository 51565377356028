
/**
 * Module definition and dependencies
 */
angular.module('Shared.EventTypes.Options', [])

/**
 * Constant definition
 */
.constant('EventTypes', [
  {
    label: 'Local',
    value: 'local',
  },
  {
    label: 'Another venue',
    value: 'external',
  },
  {
    label: 'Online',
    value: 'virtual',
  },
]);
