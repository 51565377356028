
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Add.Organisers.Card', [])

/**
 * Component
 */
.component('cardEventAddOrganisers', {
  templateUrl: 'admin/event/cards/organisers.html',
  controller: 'CardEventAddOrganisersCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    user: '<',
    event: '<',
    onMerge: '&',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventAddOrganisersCtrl', function(
  $controller, CardCloseReasons
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardEventEditOrganisersCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;

    //Add user as organiser if there are none
    if (this.model.organisers.length === 0 && !this.user.isSuper()) {
      this.model.organisers.push(this.user);
    }
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {event, model} = this;
    this.onMerge({$event: {event, model}});
    this.form.$setPristine();
  };

  /**
   * Previous step
   */
  this.previous = function() {

    //Merge
    this.merge();

    //Open previous card
    if (!this.event.isHidden) {
      this.card.open('event.attendance', CardCloseReasons.OPENED_NEXT);
    }
    else {
      this.card.open('event.dateTime', CardCloseReasons.OPENED_PREV);
    }
  };
});
