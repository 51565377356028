
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Staff.StaffOptions.Component', [])

/**
 * Staff options component
 */
.component('staffOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="$ctrl.canEdit">
        <a ui-sref="admin.people.members.edit({memberId: $ctrl.member.id})">Edit</a>
      </li>
      <li ng-if="$ctrl.canSendEmail && $ctrl.member.email">
        <a ng-click="$ctrl.sendEmail()">Send email</a>
      </li>
      <li ng-if="$ctrl.canSendWelcomeEmail && !$ctrl.member.isArchived">
        <a ng-click="$ctrl.sendWelcomeEmail()">Send welcome email</a>
      </li>
      <li ng-if="$ctrl.isSuspendable" ng-class="{'Dropdown-item--danger': !$ctrl.member.isSuspended}">
        <a ng-click="$ctrl.toggleSuspended()">{{$ctrl.member.isSuspended ? 'Un-suspend' : 'Suspend'}}</a>
        </li>
      <li>
      <li ng-class="{'Dropdown-item--danger': !$ctrl.member.isArchived}">
        <a ng-click="$ctrl.toggleArchived()">{{$ctrl.member.isArchived ? 'Restore' : 'Archive'}}</a>
      </li>
      <li ng-if="$ctrl.canDelete" class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-menu>
  `,
  bindings: {
    user: '<',
    member: '<',
    integrations: '<',
    canEdit: '<',
    canSendEmail: '<',
    canSendWelcomeEmail: '<',
    onDelete: '&',
    onToggleSuspended: '&',
    onToggleArchived: '&',
    onSendEmail: '&',
    onSendWelcomeEmail: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * On init
     */
    this.$onInit = function() {
    };

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Set data
      this.isSuspendable = this.member.canBeSuspended();
      this.canDelete = this.user.isSuper();

      //Get mailing integrations
      this.mailingIntegrations = this.integrations
        .filter(int => int.isConnected && int.category === 'mailing');
    };

    /**
     * Delete
     */
    this.delete = function() {
      this.onDelete({$event: {member: this.member}});
    };

    /**
     * Suspend/unsuspend
     */
    this.toggleSuspended = function() {
      const {member} = this;
      const isSuspended = !member.isSuspended;
      this.onToggleSuspended({$event: {member, isSuspended}});
    };

    /**
     * Archive/restore
     */
    this.toggleArchived = function() {
      const {member} = this;
      const isArchived = !member.isArchived;
      this.onToggleArchived({$event: {member, isArchived}});
    };

    /**
     * Send welcome email
     */
    this.sendWelcomeEmail = function() {
      const {member} = this;
      this.onSendWelcomeEmail({$event: {member}});
    };

    /**
     * Send email
     */
    this.sendEmail = function() {
      const {member} = this;
      this.onSendEmail({$event: {member}});
    };
  },
});
