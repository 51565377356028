
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.PaymentSources.Card', [
  'App.Admin.People.Members.PaymentSourceOptions.Component',
])

/**
 * Component
 */
.component('cardMemberPaymentSources', {
  templateUrl: 'admin/people/members/cards/payment-sources.html',
  controller: 'CardMemberPaymentSourcesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    isOwn: '<',
    hasIntegration: '<',
    member: '<',
  },
})

/**
 * Controller
 */
.controller('CardMemberPaymentSourcesCtrl', function(
  $modal, $notice, $q, PaymentMethods
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize
    this.paymentMethods = PaymentMethods
      .filter(method => method.canStore)
      .map(method => method.value);

    //Check if we have sources
    this.checkHasSources();
  };

  /**
   * Check if we have any sources
   */
  this.checkHasSources = function() {
    for (const method of this.paymentMethods) {
      if (this.member.hasPaymentSourcesForMethod(method)) {
        return this.hasSources = true;
      }
    }
    return this.hasSources = false;
  };

  /**
   * Remove
   */
  this.remove = function(method, source) {

    //Create handler
    const {member, isOwn} = this;
    const handler = () => member.removePaymentSource(method, source.id);

    //Show confirmation
    $modal
      .open('basic', {
        locals: {handler, source, isOwn},
        templateUrl: `admin/people/members/modals/confirm-delete-payment-source.html`,
      })
      .result
      .then(() => {
        $notice.show('Card removed');
        this.checkHasSources();
      });
  };

  /**
   * Make default
   */
  this.makeDefault = function(method, source) {

    //Already default
    if (source.isDefault) {
      return $q.resolve();
    }

    //Make default
    return this.member.makePaymentSourceDefault(method, source.id);
  };

  /**
   * Add account credit
   */
  this.add = function(method) {

    //Get flag
    const {isOwn} = this;

    //Default method
    if (!method) {
      method = this.paymentMethods[0];
    }

    //Create handler
    const handler = source => this.member.addPaymentSource(method, source.id);
    const check = () => this.checkHasSources();

    //Open modal
    $modal
      .open('addPaymentSource', {locals: {method, handler, isOwn, check}})
      .result
      .then(() => {
        $notice.show('Card added');
        this.checkHasSources();
      });
  };
});
