
/**
 * Module definition and dependencies
 */
angular.module('Shared.Flow.ImportUpload.Component', [])

/**
 * Grid component
 */
.component('flowImportUpload', {
  templateUrl: 'modals/flow/import-upload.html',
  bindings: {
    item: '<',
    onUpload: '&',
    onClose: '&',
    onDone: '&',
    onRestart: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Upload on init
     */
    this.$onInit = function() {

      //Initialize flags
      this.isUploading = true;
      this.isError = false;
      this.isSuccess = false;

      //Upload
      this
        .onUpload()
        .then(
          response => {
            this.isSuccess = true;
            Object.assign(this, response.data);
          },
          error => {
            this.error = error;
            this.isError = true;
          },
          event => {
            this.progress = parseInt(100.0 * event.loaded / event.total);
          }
        )
        .catch(() => {})
        .finally(() => this.isUploading = false);
    };
  },
});
