
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.BookingOptions.Component', [])

/**
 * Booking options component
 */
.component('bookingOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="$ctrl.booking.needsPayment && $ctrl.booking.hasMembers">
        <a ng-click="$ctrl.payWithCoupons()">Pay with {{modules.coupons.plural}}</a>
      </li>
      <li ng-if="$ctrl.booking.needsPayment && $ctrl.booking.hasMembers">
        <a ng-click="$ctrl.payWithAccountCredit()">Pay with account credit</a>
      </li>
      <li ng-if="$ctrl.booking.needsPayment">
        <a ng-click="$ctrl.markAsPaid()">Mark as paid</a>
      </li>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-menu>
  `,
  bindings: {
    icon: '@',
    booking: '<',
    onDelete: '&',
    onPayWithCoupons: '&',
    onPayWithAccountCredit: '&',
    onMarkAsPaid: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Pay with coupons
     */
    this.payWithCoupons = function() {
      const {booking} = this;
      this.onPayWithCoupons({$event: {booking}});
    };

    /**
     * Pay with account credit
     */
    this.payWithAccountCredit = function() {
      const {booking} = this;
      this.onPayWithAccountCredit({$event: {booking}});
    };

    /**
     * Mark as paid
     */
    this.markAsPaid = function() {
      const {booking} = this;
      this.onMarkAsPaid({$event: {booking}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      const {booking} = this;
      this.onDelete({$event: {booking}});
    };
  },
});
