
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Staff.Edit.Controller', [])

/**
 * Controller
 */
.controller('AdminStaffEditCtrl', function(
  $controller, $state, $modal, $q, $store, $timeout, Config, Upload
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminStaffCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get params
    const {showComments} = this.transition.params();

    //Check if can edit and system enabled
    this.canEdit = this.user.isAdmin();
    this.openCommentsCard = showComments;
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    $state.go('admin.people.members.overview');
  };

  /**
   * On saved
   */
  this.onSaved = function($event) {
    const {member} = $event;
    this.page.setTitle(member.name);
  };

  /**
   * Update avatar
   */
  this.updateAvatar = function($event) {

    //Get data from event
    const {data, color} = $event;

    //Avatar changed?
    if (data && data.avatar) {

      //Check if we edited ourselves
      const isUser = this.user.isSame(this.member);

      //This nonsense is necessary because for some reason the avatar changes
      //won't propagate via the s3-image directive otherwise
      this.member.avatar = null;
      if (isUser) {
        this.user.avatar = null;
      }
      $timeout(() => {
        if (isUser) {
          this.user.avatar = angular.copy(data.avatar);
        }
        this.member.avatar = angular.copy(data.avatar);
      });

      //Done
      return $q.resolve();
    }

    //Color changed?
    else if (color) {
      return this.member
        .patch({color})
        .then(() => {
          if (this.user.isSame(this.member)) {
            this.user.color = color;
          }
        });
    }
  };

  /**
   * Upload avatar
   */
  this.uploadAvatar = function($event) {

    //Get file
    const {file} = $event;
    const {id} = this.member;
    const {baseUrl} = Config.api;

    //Upload
    return Upload.upload({
      url: `${baseUrl}/member/${id}/avatar`,
      data: {
        avatar: file,
      },
    });
  };

  /**
   * Delete avatar
   */
  this.deleteAvatar = function() {

    //Get member and define handler
    const member = this.member;
    const handler = function() {
      return member.deleteAvatar();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-delete-avatar.html',
        locals: {member, handler},
      })
      .result
      .then(() => {
        this.member.avatar = null;
        if (this.user.isSame(this.member)) {
          this.user.avatar = null;
        }
      });
  };

  /**
   * Load items
   */
  this.loadItems = function(...types) {

    //Get member ID and create promises
    const member = this.member.id;
    const promises = types
      .map(type => $store[type]
      .query({member})
      .then(items => this[type] = items));

    //Resolve all data
    return $q.all(promises);
  };

  /**
   * Load financials
   */
  this.loadFinancials = async function() {

    //Load financials
    await this.loadItems('transactions', 'payments', 'accountCredits');

    //Refresh member credit
    this.member.refreshAccountCredit();
    this.member.updateAmountOwing(this.transactions);

    //Update on the user as well if the same member
    if (this.user.isSame(this.member)) {
      this.user.refreshAccountCredit();
      this.user.amountOwing = this.member.amountOwing;
    }
  };
});
