
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.ActivityOptions.Component', [])

/**
 * Activity options component
 */
.component('activityOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="$ctrl.canEdit">
        <a ui-sref="admin.activity.edit({activityId: $ctrl.activity.id})">Edit</a>
      </li>
      <li>
        <a ui-sref="admin.log.audit({model: 'Activity', id: $ctrl.activity.id})">View audit log</a>
      </li>
      <li ng-if="$ctrl.canDelete" class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    activity: '<',
    canEdit: '<',
    canDelete: '<',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Delete
     */
    this.delete = function() {
      const {activity} = this;
      this.onDelete({$event: {activity}});
    };
  },
});
