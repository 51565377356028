
/**
 * Module definition and dependencies
 */
angular.module('App.Calendar', [
  'App.Calendar.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('calendar', {
    parent: 'app',
    url: '/calendar/:period/:activity/:date/:list/:category',
    params: {
      period: {
        value: null,
        squash: true,
        dynamic: true,
      },
      activity: {
        value: null,
        squash: true,
        dynamic: true,
      },
      date: {
        value: null,
        squash: true,
        dynamic: true,
      },
      list: {
        value: null,
        squash: true,
        dynamic: true,
      },
      category: {
        value: null,
        squash: true,
        dynamic: true,
      },
    },
    data: {
      id: 'calendar',
    },
    component: 'calendarRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('calendar');
      },
      areas($store) {
        return $store.areas.query();
      },
    },
  });
})

/**
 * Component
 */
.component('calendarRoute', {
  controller: 'CalendarCtrl',
  templateUrl: 'calendar/calendar.html',
  bindings: {
    club: '<',
    user: '<',
    page: '<',
    module: '<',
    filter: '<',
    areas: '<',
    activities: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, $storage, moment, Settings, Page) => {

  //Before enter
  $transitions.onBefore({to: 'calendar'}, transition => {

    //Get params
    const params = Object.assign({}, transition.params());
    let hasChanged = false;

    //Set defaults
    if (!params.date) {
      params.date = moment().format('YYYY-MM-DD');
      hasChanged = true;
    }
    if (!params.period) {
      const defaultPeriod = Settings.get('event.defaultCalendarPeriod');
      params.period = $storage.get('calendar.period', defaultPeriod);
      hasChanged = true;
    }
    if (!params.activity) {
      params.activity = $storage.get('calendar.activity', 'all');
      hasChanged = true;
    }

    //Transition if changed
    if (hasChanged) {
      return transition.router.stateService.target('calendar', params);
    }
  });

  //Page setup
  $transitions.onSuccess({to: 'calendar'}, transition => {

    //Get data
    const {title} = transition.injector().get('module');
    const params = transition.params();

    //Set page title and crumb
    Page.setTitle(title);
    Page.addCrumb({sref: 'calendar', title, params});
  }, {priority: 10});
});
