
/**
 * Define values
 */
const NONE = 'none';
const PAYMENT = 'payment';
const ALWAYS = 'always';

/**
 * Define array
 */
const ApprovalOptions = [
  {
    label: 'Not required',
    value: NONE,
  },
  {
    label: 'Only required when payment fails',
    value: PAYMENT,
  },
  {
    label: 'Always required',
    value: ALWAYS,
  },
];

/**
 * Set properties
 */
ApprovalOptions.NONE = NONE;
ApprovalOptions.PAYMENT = PAYMENT;
ApprovalOptions.ALWAYS = ALWAYS;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.ApprovalOptions.Options', [])
  .constant('ApprovalOptions', ApprovalOptions);
