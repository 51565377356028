
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Resources.Card', [])

/**
 * Component
 */
.component('cardMemberResources', {
  templateUrl: 'admin/people/members/cards/resources.html',
  controller: 'CardMemberResourcesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    member: '<',
    resources: '<',
  },
})

/**
 * Controller
 */
.controller('CardMemberResourcesCtrl', function() {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize
    this.hasResources = this.resources.length > 0;
  };
});
