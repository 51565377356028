
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.TransferAccountCredit.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('transferAccountCredit', {
    templateUrl: 'admin/finance/modals/transfer-account-credit.html',
    controller: 'ModalTransferAccountCreditCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalTransferAccountCreditCtrl', function(
  $controller, $modalInstance, $focus, AccountCredit, Circle
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set data
    this.isTransferring = false;

    //Initialize model
    this.model = {
      recipients: [],
      amount: '',
      reference: '',
    };

    //Load balance and circle members
    this.loadBalance();
    this.loadCircleMembers();
  };

  /**
   * Check balance
   */
  this.loadBalance = function() {

    //Get member and determine query
    const {member, isOwn} = this;
    const query = isOwn ?
      AccountCredit.balanceOwn({circleMemberId: member.id}) :
      AccountCredit.balance({memberId: member.id});

    //Check balance
    query
      .then(balance => this.balance = balance);
  };

  /**
   * Load circle members
   */
  this.loadCircleMembers = function() {

    //Get member and determine query
    const {member, isOwn} = this;
    const query = isOwn ?
      Circle.queryOwnMembers({circleMemberId: member.id}) :
      Circle.queryMembers({memberId: member.id});

    //Execute
    query
      .then(members => {
        this.circleMembers = members;
        this.determineAvailableCircleMembers();
      });
  };

  /**
   * Check amounts
   */
  this.checkAmounts = function() {
    const {balance, model} = this;
    const {amount, recipients} = model;
    this.deduction = amount * recipients.length;
    this.newBalance = balance - this.deduction;
    this.isNegative = this.newBalance < 0;
  };

  /**
   * Determine available circle members
   */
  this.determineAvailableCircleMembers = function() {
    this.availableCircleMembers = this.circleMembers
      .filter(member => !this.hasRecipient(member));
  };

  /**
   * Add recipient
   */
  this.addRecipient = function(member) {
    if (!this.hasRecipient(member)) {
      this.model.recipients = this.model.recipients.map(m => m);
      this.model.recipients.push(member);
      this.determineAvailableCircleMembers();
      this.checkAmounts();
    }
  };

  /**
   * Has recipient check
   */
  this.hasRecipient = function(member) {
    return this.model.recipients.some(m => m.id === member.id);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;
  };

  /**
   * Recipients changed
   */
  this.onRecipientsChanged = function() {
    this.checkAmounts();
    this.determineAvailableCircleMembers();
  };

  /**
   * Postlink
   */
  this.$postLink = function() {
    $focus('recipients', true);
  };

  /**
   * Transfer
   */
  this.transfer = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Clean up data
    const {recipients, amount, reference} = this.model;
    const data = {
      recipientMemberIds: recipients.map(member => member.id),
      amount, reference,
    };

    //Reset flags
    this.isTransferring = true;
    this.error = null;

    //Use handler
    this
      .handler(data)
      .then(result => $modalInstance.resolve(result))
      .catch(error => this.error = error)
      .finally(() => this.isTransferring = false);
  };
});
