
/**
 * Module definition and dependencies
 */
angular.module('Shared.Discount.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('discount', {
    model: 'Discount',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'discounts',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('discounts', {
    model: 'Discount',
    dataKey: 'discounts',
  });
})

/**
 * Model definition
 */
.factory('Discount', function($baseModel, $api, DiscountTypes) {

  /**
   * Defaults
   */
  const defaultData = {
    type: 'fixed',
    constraint: 'with',
  };

  /**
   * Constructor
   */
  function Discount(data) {
    $baseModel.call(this, angular.extend({}, defaultData, data || {}));

    //Types
    Object.defineProperty(this, 'isFixed', {
      get() {
        return (this.type === DiscountTypes.FIXED);
      },
    });
    Object.defineProperty(this, 'isPercentage', {
      get() {
        return (this.type === DiscountTypes.PERCENTAGE);
      },
    });
    Object.defineProperty(this, 'isWaived', {
      get() {
        return (this.type === DiscountTypes.WAIVE);
      },
    });

    //Ages
    Object.defineProperty(this, 'ages', {
      get() {
        const {minAge, maxAge} = this;
        if (minAge && maxAge) {
          return `${minAge}–${maxAge}`;
        }
        if (minAge) {
          return `${minAge}+`;
        }
        if (maxAge) {
          return `<${maxAge + 1}`;
        }
        return '';
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Discount.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Discount.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('member', 'Member');

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Discount.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Strip data
    json.member = $baseModel.onlyId(json.member);

    //Return json
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save
   */
  Discount.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.discount[method](data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Discount.prototype.delete = function() {
    return $api.discount
      .delete(null, this).then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Discount.query = function(filter) {
    return $api.discount.query(filter);
  };

  /**
   * Find by ID
   */
  Discount.findById = function(id) {
    return $api.discount.get({id});
  };

  //Return
  return Discount;
});
