
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.Social.Card', [])

/**
 * Component
 */
.component('cardClubSocial', {
  templateUrl: 'admin/club/cards/social.html',
  controller: 'CardClubSocialCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubSocialCtrl', function() {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;

    //Create model subset
    this.model = this.club.extract([
      'socialMedia',
    ]);

    //Ensure have an object as social media container
    if (!this.model.socialMedia) {
      this.model.socialMedia = {};
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event: {model: this.model}})
      .then(() => {
        this.form.$setPristine();
        this.club.markStepComplete('linkSocialMedia', true);
      })
      .finally(() => this.isSaving = false);
  };
});
