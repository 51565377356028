
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.Calendar.Card', [])

/**
 * Component
 */
.component('cardSettingsCalendar', {
  templateUrl: 'admin/settings/cards/calendar.html',
  controller: 'CardSettingsCalendarCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSettingsCalendarCtrl', function(
  $controller
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'event';

    //Period options
    this.PeriodOptions = [
      {value: 'month', label: 'Month'},
      {value: 'week', label: 'Week'},
    ];

    //Call parent init
    $base.$onInit.call(this);
  };
});
