
/**
 * Module definition and dependencies
 */
angular.module('Shared.PaymentFees.Service', [])

/**
 * Service definition
 */
.factory('PaymentFees', function(PaymentFeeCover) {
  return {

    /**
     * Determine the covered amount
     */
    determineCover(amount, fees, cover) {

      //Determine base fee based on base amount
      const baseFee = this.portion(amount, fees);

      //Determine how much is covered
      switch (cover.strategy) {

        //Club covers a part, according to the cover structure
        case PaymentFeeCover.PART:
          return Math.min(baseFee, this.portion(amount, cover.structure));

        //Club covers it all, according to the fee structure
        case PaymentFeeCover.FULL:
          return baseFee;

        //Member pays it all, nothing gets covered
        case PaymentFeeCover.NONE:
        default:
          return 0;
      }
    },

    /**
     * Determine the club payout
     */
    determineGoal(amount, fees, cover) {

      //Determine how much the club will cover
      const coverAmount = this.determineCover(amount, fees, cover);

      //Return the difference between the amount
      return (amount - coverAmount);
    },

    /**
     * Determine the member charge
     */
    determineCharge(amount, goal, fees) {

      //No fee structure?
      if (!fees) {
        return amount;
      }

      //Get fee structure data and determine charge needed to reach goal
      const {factor, fixed, max} = fees;
      const base = goal + (fixed || 0);
      const charge = Math.round(base / (1 - factor) * 100) / 100;
      const fee = (charge - goal);

      //Check if total fee (different between charge and goal) is too large
      if (max && fee > max) {
        return (goal + max);
      }

      //Return the charge (never less than the amount to pay)
      return Math.max(amount, charge);
    },

    /**
     * Determine fee
     */
    determineFee(amount, structure) {
      return this.portion(amount, structure);
    },

    /**
     * Helper to calculate a portion of amount based on given structure
     */
    portion(amount, structure) {

      //No structure or amount
      if (!structure || !amount) {
        return 0;
      }

      //Get structure params
      let {factor, fixed, max} = structure;
      if (typeof factor !== 'number' || factor < 0) {
        factor = 0;
      }
      if (typeof fixed !== 'number' || fixed < 0) {
        fixed = 0;
      }
      if (typeof max !== 'number' || max < 0) {
        max = 0;
      }

      //Calculate portion
      const portion = Math.round((factor * amount + fixed) * 100) / 100;
      if (max && portion > max) {
        return max;
      }
      return portion;
    },

    /**
     * Helper to merge fee structures
     */
    merge(s1, s2) {
      return {
        factor: (s1.factor || 0) + (s2.factor || 0),
        fixed: (s1.fixed || 0) + (s2.fixed || 0),
        max: Math.max((s1.max || 0), (s2.max || 0)),
      };
    },
  };
});
