
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.PersonalDetails.Card', [])

/**
 * Component
 */
.component('cardSubscriptionPersonalDetails', {
  templateUrl: 'subscription/cards/personal-details.html',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    onPrev: '&',
    onNext: '&',
    onCancel: '&',
  },
  controller($modal, Genders) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Set data and flags
      this.isSaving = false;
      this.Genders = Genders;

      //Create model subset
      this.model = this.user.extract([
        'firstName', 'lastName', 'email', 'mobile', 'phone', 'address',
      ]);
    };

    /**
     * Previous step
     */
    this.prev = function() {

      //Dirty check
      if (this.form && this.form.$dirty) {
        return $modal
          .open('confirmUnsavedChanges')
          .result
          .then(() => this.onPrev());
      }

      //Go back
      this.onPrev();
    };

    /**
     * Cancel flow
     */
    this.cancel = function() {
      this.onCancel();
    };

    /**
     * Update model
     */
    this.updateModel = function(property, value, target) {
      target = target || this.model;
      target[property] = value;
    };

    /**
     * Validate
     */
    this.validate = function() {
      this.form.$setSubmitted();
      return this.form.$valid;
    };

    /**
     * Save / next step
     */
    this.next = function() {

      //Validate
      if (!this.validate()) {
        return;
      }

      //Mark as saving and get data
      this.isSaving = true;

      //Save
      this.user
        .patch(this.model)
        .then(() => {
          this.user.snoozeReminder('checkPersonalDetails', 6, 'months');
          this.form.$setPristine();
          this.onNext();
        })
        .finally(() => this.isSaving = false);
    };
  },
});
