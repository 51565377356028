
/**
 * Module definition and dependencies
 */
angular.module('Shared.ClubRegexes.Options', [])

/**
 * Constant definition
 */
.constant('ClubRegexes', [
  {
    regex: /\b(archer)/i,
    activity: 'archery',
    background: 'backgrounds/default/archery.jpg',
  },
  {
    regex: /\b(badminton)\b/i,
    activity: 'badminton',
    background: 'backgrounds/default/badminton.jpg',
  },
  {
    regex: /\b(basketball)\b/i,
    activity: 'basketball',
    background: 'backgrounds/default/basketball.jpg',
  },
  {
    regex: /\b(beach volley)/i,
    activity: 'beachVolleyball',
    background: 'backgrounds/default/beach-volleyball.jpg',
  },
  {
    regex: /\b(croquet)\b/i,
    activity: 'croquet',
    background: 'backgrounds/default/croquet.jpg',
  },
  {
    regex: /\b(radio)\b/i,
    background: 'backgrounds/default/radio.jpg',
  },
  {
    regex: /\b(book|books|reading|library)\b/i,
    background: 'backgrounds/default/books.jpg',
  },
  {
    regex: /\b(bowling)\b/i,
    activity: 'bowling',
    background: 'backgrounds/default/bowling.jpg',
  },
  {
    regex: /\b(bowls)\b/i,
    activity: 'bowls',
    background: 'backgrounds/default/bowls.jpg',
  },
  {
    regex: /\b(bridge)\b/i,
    activity: 'bridge',
    background: 'backgrounds/default/cards.jpg',
  },
  {
    regex: /\b(cricket)\b/i,
    activity: 'cricket',
    background: 'backgrounds/default/cricket.jpg',
  },
  {
    regex: /\b(car|cars|jeep|off road)\b/i,
    background: 'backgrounds/default/cars.jpg',
  },
  {
    regex: /\b(church)\b/i,
    background: 'backgrounds/default/church.jpg',
  },
  {
    regex: /\b(cycling|bicycle|cycle)\b/i,
    activity: 'cycling',
    background: 'backgrounds/default/cycling.jpg',
  },
  {
    regex: /\b(motorcycle|motorcycling|motor|harley|biker|bikers)\b/i,
    activity: 'motorCycling',
    background: 'backgrounds/default/motorcycle.jpg',
  },
  {
    regex: /\b(dance|dancing)\b/i,
    activity: 'dance',
    background: 'backgrounds/default/dance.jpg',
  },
  {
    regex: /\b(ice hockey)\b/i,
    activity: 'iceHockey',
    background: 'backgrounds/default/ice-hockey.jpg',
  },
  {
    regex: /\b(field hockey|hockey)\b/i,
    activity: 'fieldHockey',
    background: 'backgrounds/default/field-hockey.jpg',
  },
  {
    regex: /\b(football)\b/i,
    activity: 'football',
    background: 'backgrounds/default/football.jpg',
  },
  {
    regex: /\b(golf)\b/i,
    activity: 'golf',
    background: 'backgrounds/default/golf.jpg',
  },
  {
    regex: /\b(fencing|fence)\b/i,
    activity: 'fencing',
    background: 'backgrounds/default/fencing.jpg',
  },
  {
    regex: /\b(ice skating)\b/i,
    activity: 'iceSkating',
    background: 'backgrounds/default/ice-skating.jpg',
  },
  {
    regex: /\b(kayak)/i,
    activity: 'kayaking',
    background: 'backgrounds/default/kayak.jpg',
  },
  {
    regex: /\b(martial arts|judo|karate|akido|kendo|taekwondo|jujitsu|jujutsu|sumo)\b/i,
    activity: 'martialArts',
    background: 'backgrounds/default/martial-arts.jpg',
  },
  {
    regex: /\b(music)/i,
    activity: 'music',
    background: 'backgrounds/default/music.jpg',
  },
  {
    regex: /\b(netball|net ball)\b/i,
    activity: 'netball',
    background: 'backgrounds/default/netball.jpg',
  },
  {
    regex: /\b(pigeon|poultry|chicken|chickens|bird)\b/i,
    background: 'backgrounds/default/poultry.jpg',
  },
  {
    regex: /\b(pickleball)\b/i,
    activity: 'pickleball',
    background: 'backgrounds/default/pickleball.jpg',
  },
  {
    regex: /\b(pilates)\b/i,
    activity: 'pilates',
    background: 'backgrounds/default/pilates.jpg',
  },
  {
    regex: /\b(pole dancing|pole)\b/i,
    activity: 'poleDancing',
    background: 'backgrounds/default/pole-dancing.jpg',
  },
  {
    regex: /\b(pottery|potters)\b/i,
    activity: 'pottery',
    background: 'backgrounds/default/pottery.jpg',
  },
  {
    regex: /\b(photo)/i,
    activity: 'photography',
    background: 'backgrounds/default/photography.jpg',
  },
  {
    regex: /\b(rugby)\b/i,
    activity: 'rugby',
    background: 'backgrounds/default/rugby.jpg',
  },
  {
    regex: /\b(running)\b/i,
    activity: 'running',
    background: 'backgrounds/default/running.jpg',
  },
  {
    regex: /\b(sailing)\b/i,
    activity: 'sailing',
    background: 'backgrounds/default/sailing.jpg',
  },
  {
    regex: /\b(shooting|gun range|guns|gun)\b/i,
    activity: 'shooting',
    background: 'backgrounds/default/gun-range.jpg',
  },
  {
    regex: /\b(soccer)\b/i,
    activity: 'soccer',
    background: 'backgrounds/default/soccer.jpg',
  },
  {
    regex: /\b(scout)\b/i,
    activity: 'scouting',
    background: 'backgrounds/default/scouting.jpg',
  },
  {
    regex: /\b(squash)\b/i,
    activity: 'squash',
    background: 'backgrounds/default/squash.jpg',
  },
  {
    regex: /\b(wind surf|windsurf)/i,
    activity: 'windSurfing',
    background: 'backgrounds/default/windsurfing.jpg',
  },
  {
    regex: /\b(surfing|surf)\b/i,
    activity: 'surfing',
    background: 'backgrounds/default/surfing.jpg',
  },
  {
    regex: /\b(swimming|swim)\b/i,
    activity: 'swimming',
    background: 'backgrounds/default/swimming.jpg',
  },
  {
    regex: /\b(rowing)\b/i,
    activity: 'rowing',
    background: 'backgrounds/default/rowing.jpg',
  },
  {
    regex: /\b(table tennis)\b/i,
    activity: 'tableTennis',
    background: 'backgrounds/default/table-tennis.jpg',
  },
  {
    regex: /\b(tennis)\b/i,
    activity: 'tennis',
    background: 'backgrounds/default/tennis.jpg',
  },
  {
    regex: /\b(triathlon)\b/i,
    activity: 'triathlon',
    background: 'backgrounds/default/running.jpg',
  },
  {
    regex: /\b(volley)/i,
    activity: 'volleyball',
    background: 'backgrounds/default/volleyball.jpg',
  },
  {
    regex: /\b(weight)/i,
    activity: 'weightlifting',
    background: 'backgrounds/default/weights.jpg',
  },
  {
    regex: /\b(yachting)\b/i,
    activity: 'yachting',
    background: 'backgrounds/default/sailing.jpg',
  },
  {
    regex: /\b(yoga)\b/i,
    activity: 'yoga',
    background: 'backgrounds/default/yoga.jpg',
  },
  {
    regex: /\b(card|cards)\b/i,
    activity: 'cardPlaying',
    background: 'backgrounds/default/cards.jpg',
  },
  {
    regex: /\b(gym|fitness)\b/i,
    activity: 'gym',
    background: 'backgrounds/default/gym.jpg',
  },
  {
    regex: /\b(wine)\b/i,
    activity: 'wineTasting',
    background: 'backgrounds/default/wine.jpg',
  },
  {
    regex: /\b(theat)/i,
    activity: 'theatre',
    background: 'backgrounds/default/theatre.jpg',
  },
  {
    regex: /\b(school|teach)/i,
    background: 'backgrounds/default/school.jpg',
  },
  {
    regex: /\b(wood)/i,
    background: 'backgrounds/default/woodworking.jpg',
  },
  {
    regex: /\b(drone)/i,
    activity: 'drones',
    background: 'backgrounds/default/drones.jpg',
  },
  {
    regex: /\b(community|coop|co-op|society|charity)/i,
    background: 'backgrounds/default/community.jpg',
  },
  {
    regex: /\b(venue)\b/i,
    background: 'backgrounds/default/venue.jpg',
  },
]);
