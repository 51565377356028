
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Contacts.Edit', [
  'App.Admin.People.Contacts.Edit.Controller',
  'App.Admin.People.Contacts.Edit.Info.Card',
  'App.Admin.People.Contacts.Edit.Details.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.contacts.edit', {
    url: '/edit/:contactId',
    component: 'adminContactEditRoute',
    params: {
      showComments: {
        value: false,
      },
    },
    resolve: {
      contact(transition, $store) {
        const params = transition.params();
        return $store.contacts.findById(params.contactId);
      },
      groups($store) {
        return $store.contactGroups.query();
      },
      customFields($store) {
        return $store.customFields.query({model: 'Contact'});
      },
    },
  });
})

/**
 * Route component
 */
.component('adminContactEditRoute', {
  controller: 'AdminContactEditCtrl',
  templateUrl: 'admin/people/contacts/edit/edit.html',
  bindings: {
    club: '<',
    user: '<',
    contact: '<',
    groups: '<',
    customFields: '<',
    transition: '<',
    page: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.people.contacts.edit'}, transition => {

    //Get data
    const contact = transition.injector().get('contact');

    //Set page title and crumb
    Page.setTitle(contact.name);
    Page.addCrumb({
      sref: 'admin.people.contacts.edit',
      params: {
        contactId: contact.id,
      },
    });
  });
});
