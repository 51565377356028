
/**
 * Module definition and dependencies
 */
angular.module('App.Event.SignUpViaSub', [
  'App.Event.SignUpViaSub.Controller',
  'App.Event.Selection.Card',
  'App.Event.Signup.Questions.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('event.signUpViaSub', {
    url: '/signup-via-sub/:subId',
    params: {
      subId: {
        value: null,
      },
    },
    data: {
      roles: [],
    },
    component: 'eventSignUpViaSubRoute',
    resolve: {
      subscription(transition, Subscription) {
        const {subId} = transition.params();
        if (!subId) {
          return null;
        }
        return Subscription.findById(subId);
      },
      activities($store) {
        return $store.activities.query();
      },
      categories($store) {
        return $store.eventCategories.query();
      },
    },
  });
})

/**
 * Component
 */
.component('eventSignUpViaSubRoute', {
  controller: 'EventSignUpViaSubCtrl',
  templateUrl: 'event/signup-via-sub/signup-via-sub.html',
  bindings: {
    club: '<',
    user: '<',
    module: '<',
    activities: '<',
    categories: '<',
    subscription: '<',
  },
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'event.signUpViaSub'}, transition => {

    //Get transition details
    const params = transition.params();

    //Setup page
    Page.setTitle('Sign up for event');
    Page.clearCrumbs();
    Page.addCrumb({sref: 'subscription.overview', title: 'Memberships'});
    Page.addCrumb({sref: 'event.signUpViaSub', params, title: 'Select event'});
  });
});
