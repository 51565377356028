
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Report.Financial', [
  'App.Admin.Report.Financial.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.report.financial', {
    url: '/financial',
    component: 'adminReportFinancialRoute',
    params: {
      basePeriod: null,
      compPeriod: null,
    },
    resolve: {
      baseData(transition, $api, $q) {
        const {basePeriod: {fromDate, toDate}} = transition.params();
        if (fromDate && toDate) {
          return $api.report.financial({fromDate, toDate});
        }
        return $q.resolve({});
      },
      compData(transition, $api, $q) {
        const {compPeriod: {fromDate, toDate}} = transition.params();
        if (fromDate && toDate) {
          return $api.report.financial({fromDate, toDate});
        }
        return $q.resolve({});
      },
    },
  });
})

/**
 * Component
 */
.component('adminReportFinancialRoute', {
  controller: 'AdminReportFinancialCtrl',
  templateUrl: 'admin/report/financial/financial.html',
  bindings: {
    baseData: '<',
    compData: '<',
    page: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Can't navigate here directly
  $transitions.onBefore({to: 'admin.report.financial'}, transition => {
    const {basePeriod} = transition.params();
    if (!basePeriod) {
      return transition.router.stateService.target('admin.report.overview');
    }
  });

  //Page setup
  $transitions.onSuccess({to: 'admin.report.financial'}, transition => {
    const params = transition.params();
    Page.setTitle(`Financial report`);
    Page.addCrumb({
      sref: 'admin.report.financial',
      params,
    });
  });
});
