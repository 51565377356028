
/**
 * Module definition and dependencies
 */
angular.module('Shared.Recurrence.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('Recurrence', function($baseModel) {

  /**
   * Defaults
   */
  let defaultData = {
    interval: 'weeks',
    frequency: 1,
    days: [],
    ends: 'never',
    monthlyDayOf: 'week',
  };

  /**
   * Constructor
   */
  function Recurrence(data) {
    $baseModel.call(this, angular.extend({}, defaultData, data || {}));
  }

  /**
   * Extend base model
   */
  angular.extend(Recurrence.prototype, $baseModel.prototype);

  //Return
  return Recurrence;
});
