
/**
 * Module definition and dependencies
 */
angular.module('App.Account.Booking', [
  'App.Account.Booking.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('account.booking', {
    url: '/booking/:bookingId',
    params: {
      bookingId: {
        value: null,
      },
    },
    component: 'accountBookingRoute',
    resolve: {
      payment(PaymentFlow, PaymentTypes, user) {
        return new PaymentFlow(PaymentTypes.BOOKING, user);
      },
      outcome(PaymentOutcome) {
        return PaymentOutcome.check();
      },
      booking(transition, Booking) {
        const {bookingId} = transition.params();
        if (!bookingId) {
          return null;
        }
        return Booking.findById(bookingId);
      },
      bookings(booking, Booking) {
        const {series, isRecurring} = booking;
        if (!isRecurring || !series) {
          return [booking];
        }
        return Booking
          .query({series, isPaid: false})
          .then(data => data.bookings);
      },
      coupons(Coupon, booking, user) {
        if (!booking) {
          return [];
        }
        const members = booking.members.map(member => member.id);
        const mode = booking.mode.id;
        const areUsable = true;

        //Add user to mode if booking is made by a member and
        //the user is not in the booking.member array
        if (!booking.forOthers && user && !booking.hasMember(user)) {
          members.push(user.id);
        }
        return Coupon.query({areUsable, mode, members});
      },
    },
  });
})

/**
 * Component
 */
.component('accountBookingRoute', {
  controller: 'AccountBookingCtrl',
  templateUrl: 'account/booking/booking.html',
  bindings: {
    club: '<',
    user: '<',
    module: '<',
    payment: '<',
    booking: '<',
    bookings: '<',
    outcome: '<',
    coupons: '<',
  },
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'account.booking'}, transition => {
    const params = transition.params();
    Page.setTitle('Pay for booking');
    Page.addCrumb({sref: 'account.booking', params});
  });
});
