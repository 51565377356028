
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.ApiKeys.ApiKeyOptions.Component', [])

/**
 * Member options component
 */
.component('apiKeyOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-menu>
  `,
  bindings: {
    apiKey: '<',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Delete
     */
    this.delete = function() {
      const {apiKey} = this;
      this.onDelete({$event: {apiKey}});
    };
  },
});
