
/**
 * Module definition and dependencies
 */
angular.module('Shared.Flow.ImportSelectFile.Component', [])

/**
 * Grid component
 */
.component('flowImportSelectFile', {
  templateUrl: 'modals/flow/import-select-file.html',
  bindings: {
    help: '<',
    item: '<',
    icon: '<',
    onFile: '&',
    onClose: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Selected file
     */
    this.selectedFile = function(file) {
      this.onFile({$event: {file}});
    };
  },
});
