
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.Mailchimp.Lists.Card', [])

/**
 * Component
 */
.component('cardIntegrationsMailchimpLists', {
  templateUrl: 'admin/integrations/mailchimp/lists.html',
  controller: 'CardIntegrationsMailchimpListsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsMailchimpListsCtrl', function(
  $controller, Mailchimp
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Load lists if connected
    if (this.isConnected) {
      this.loadLists();
    }
  };

  /**
   * Load lists
   */
  this.loadLists = function() {

    //Get default lists
    const {defaultLists} = this.integration.data;

    //Toggle flag
    this.isLoading = true;

    //Load lists
    Mailchimp
      .getListsAndInterests()
      .then(lists => {
        this.lists = lists;
        Mailchimp.toggleDefaults(lists, defaultLists);
      })
      .catch(error => {

        //Not connected?
        if (error.code === 'NOT_CONNECTED') {
          this.integration.isConnected = false;
          this.card.showTab('status');
        }
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Save integration settings
   */
  this.save = function() {

    //Set lists in data and toggle flag
    this.isSaving = true;
    this.data.defaultLists = this.lists.filter(list => list.isChecked);

    //Update integration
    this
      .update()
      .then(() => {
        if (this.data.mergeFields) {
          this.card.showTab('status');
        }
        else {
          this.card.showTab('fields');
        }
      })
      .finally(() => this.isSaving = false);
  };
});
