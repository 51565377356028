
/**
 * Module definition and dependencies
 */
angular.module('Shared.CategoryOptions.Component', [])

/**
 * Category options component
 */
.component('categoryOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li>
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li ng-if="$ctrl.canEditRules">
        <a ng-click="$ctrl.editRules()">Edit rules</a>
      </li>
      <li>
        <a ng-click="$ctrl.copy()">Copy</a>
      </li>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    item: '<',
    onEdit: '&',
    onDelete: '&',
    onEditRules: '&',
    onCopy: '&',
    canEditRules: '<',
    //canCopy: '<',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Edit
     */
    this.edit = function() {
      const {item} = this;
      this.onEdit({$event: {item}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      const {item} = this;
      this.onDelete({$event: {item}});
    };

    /**
     * Edit rule
     */
    this.editRules = function() {
      const {item} = this;
      this.onEditRules({$event: {item}});
    };

    /**
     * Copy
     */
    this.copy = function() {
      const {item} = this;
      this.onCopy({$event: {item}});
    };
  },
});
