
/**
 * Module definition and dependencies
 */
angular.module('Shared.IconTrend.Component', [])

/**
 * Status icon component
 */
.component('iconTrend', {
  template: `
    <i class="Icon color-success" ng-if="$ctrl.base.count > $ctrl.comp.count">trending_up</i>
    <i class="Icon color-danger" ng-if="$ctrl.base.count < $ctrl.comp.count">trending_down</i>
  `,
  bindings: {
    base: '<',
    comp: '<',
  },
});
