
/**
 * Module definition and dependencies
 */
angular.module('Shared.SubscriptionSearch.Component', [])

/**
 * Subscription search component
 */
.component('subscriptionSearch', {
  templateUrl: 'shared/subscription-search/subscription-search.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    subscription: '<ngModel',
    membership: '<',
    notMember: '<',
    includePast: '<',
    onChange: '&',
  },

  /**
   * Component controller
   */
  controller(Subscription) {

    /**
     * Init
     */
    this.$onInit = function() {
      this.clearInput = 0;
    };

    /**
     * Changes
     */
    this.$onChanges = function(changes) {
      if (changes.membership) {
        this.setSubscription(null);
        this.clearInput++;
      }
    };

    /**
     * Search
     */
    this.search = function(memberName) {
      const {membership, notMember, includePast} = this;
      const isPast = (includePast ? undefined : false);
      const filter = {memberName, membership, isPast};
      return Subscription
        .query(filter)
        .then(subscriptions => {
          return subscriptions
            .filter(sub => !notMember || !notMember.isSame(sub.member));
        });
    };

    /**
     * Set subscription
     */
    this.setSubscription = function(subscription) {
      this.subscription = subscription;
      this.onChange({subscription});
    };
  },
});
