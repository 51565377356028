
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Contacts.Overview.Controller', [])

/**
 * Controller
 */
.controller('AdminContactOverviewCtrl', function(
  $controller, $filter, $modal, $notice, $store, ContactFields, ReplacementTags,
  ContactGroup, ScrollPosition, Pagination, Contact, Settings, Intercom
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminContactCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed contacts');

    //Initialize
    this.isLoading = true;
    this.isAdmin = this.user.isAdmin();

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load groups and contacts
    this.loadPage();
    this.loadGroups();
    this.loadCustomFields();
    this.loadLastImport();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter} = this;

    //Initialize selection
    this.selection = new Set();

    //Set filter defaults
    filter.setDefaults({
      search: '',
    });

    //Filter on change handler
    filter.onChange(() => {

      //Reload first page
      this.loadPage(1);
    });
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    this.loadContacts();
  };

  /**
   * Load last import
   */
  this.loadLastImport = function() {
    Contact
      .getImports({isRemovable: true})
      .then(imports => {
        if (imports.length) {
          this.lastImport = imports.pop();
        }
        else {
          this.lastImport = null;
        }
      });
  };

  /**
   * Load custom fields
   */
  this.loadCustomFields = function() {
    $store.customFields
      .query({model: 'Contact'})
      .then(customFields => this.customFields = customFields);
  };

  /**
   * Load groups
   */
  this.loadGroups = function() {
    $store.contactGroups
      .query(true)
      .then(groups => this.groups = groups);
  };

  /**
   * Load contacts
   */
  this.loadContacts = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query contacts
    return Contact
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Export contacts
   */
  this.export = function() {

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club: this.club, action: 'Exporting contacts'},
      });
    }

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'contacts', action: 'export'},
      });
    }

    //Get filter and export
    const filter = this.makeFilter();
    return Contact.export(filter);
  };

  /**
   * Import contacts
   */
  this.import = function() {

    //Initialize fields available for importing
    const fields = ContactFields.slice(0);
    const isAllowedCustomFields = (this.club.permissions.customFieldLimit !== 0);
    const requiresConsent = true;

    //Has mock data
    if (this.club.hasDemoData) {
      return this.removeDemoData();
    }

    //Add custom fields
    for (const field of this.customFields) {
      const {prop, label, type} = field;
      if (type === 'file') {
        continue;
      }
      fields.push({
        prop: `customFields.${prop}`,
        label,
        isMultiLine: field.isParagraph,
        isDate: field.isDate,
        isArray: field.isCheckboxes,
      });
    }

    //Open import dialog
    $modal
      .open('import', {
        locals: {
          path: 'contact/import',
          item: 'contacts',
          icon: 'group_add',
          fields, isAllowedCustomFields, requiresConsent,
        },
      })
      .result
      .then(() => {
        this.loadPage(1);
        this.loadGroups();
        this.loadLastImport();
        Intercom.event('Imported contacts');
      });
  };

  /**
   * Remove demo data
   */
  this.removeDemoData = function() {

    //Create handler
    const handler = () => this.club.removeDemoData();

    //Open modal
    $modal.open('basic', {
      templateUrl: 'admin/setup/modals/confirm-delete-mock-data.html',
      locals: {handler, isImporting: true},
    });
  };

  /**
   * Email contacts
   */
  this.email = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'contacts', action: 'email'},
      });
    }

    //Define handlers
    const {customFields} = this;
    const filter = this.makeFilter(null, {hasEmail: true});
    const handler = email => Contact.email(filter, email);
    const counter = () => Contact.count(filter);
    const tags = ReplacementTags.contact(customFields);

    //Open modal
    $modal.open('email', {locals: {handler, counter, tags}});
  };

  /**
   * Add contacts to groups
   */
  this.addToGroups = function() {

    //Get filter
    const {groups} = this;
    const plural = 'contacts';
    const singular = 'contact';
    const filter = this.makeFilter();
    const Model = ContactGroup;

    //Define handler & counter
    const handler = groups => Contact.addToGroups(filter, groups);
    const counter = () => Contact.count(filter);
    const reloader = () => {
      this.loadGroups();
      this.loadContacts();
    };

    //Open modal
    $modal
      .open('addToGroups', {locals: {
        Model, plural, singular, groups, handler, counter, reloader,
      }})
      .result
      .then(() => {
        $notice.show('Added contacts to groups');
        this.loadContacts();
      });
  };

  /**
   * Remove contacts from groups
   */
  this.removeFromGroups = function() {

    //Get filter
    const {groups} = this;
    const plural = 'contacts';
    const singular = 'contact';
    const filter = this.makeFilter();
    const Model = ContactGroup;

    //Define handler
    const handler = groups => Contact.removeFromGroups(filter, groups);
    const counter = () => Contact.count(filter);
    const reloader = () => {
      this.loadGroups();
      this.loadContacts();
    };

    //Open modal
    $modal
      .open('removeFromGroups', {locals: {
        Model, plural, singular, groups, handler, counter, reloader,
      }})
      .result
      .then(() => {
        $notice.show('Removed contacts from groups');
        this.loadContacts();
      });
  };

  /**
   * Remove bulk contacts
   */
  this.deleteMany = function() {

    //Get filter and define handler
    const numContacts = this.selection.size || this.numItems;
    const filter = this.makeFilter();
    const handler = () => Contact.removeMany(filter);

    //Open modal
    $modal
    .open('basic', {
      templateUrl: 'admin/people/contacts/modals/confirm-delete-many.html',
      locals: {numContacts, handler},
    })
    .result
    .then(() => {
      $notice.show('Contacts removed');
      this.selection.clear();
      this.loadContacts();
    });
  };

  /**
   * Remove import
   */
  this.removeImport = function(item) {

    //Get data
    const {count, date} = item;

    //Create handler
    const handler = () => Contact.removeImport(date);

    //Show confirmation modal
    $modal
      .open('basic', {
        templateUrl: 'admin/people/contacts/modals/confirm-remove-import.html',
        locals: {count, date, handler},
      })
      .result
      .then(() => {
        this.loadPage(1);
        this.loadLastImport();
      });
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, contacts} = data;

    //Set in scope
    this.contacts = contacts;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
  };

  /**
   * Toggle
   */
  this.toggle = function(id) {
    if (this.selection.has(id)) {
      this.selection.delete(id);
    }
    else {
      this.selection.add(id);
    }
  };

  /**
   * Select all
   */
  this.selectAll = function() {
    for (const contact of this.contacts) {
      const {id} = contact;
      if (!this.selection.has(id)) {
        this.selection.add(id);
      }
    }
  };

  /**
   * Unselect all
   */
  this.unselectAll = function() {
    return this.selection.clear();
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Selection active? Use as filter unless page specified
    if (!page && this.selection.size > 0) {
      return {ids: Array.from(this.selection.values())};
    }

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //If one group selected, change prop
    if (Array.isArray(filter.groups) && filter.groups.length === 1) {
      filter.group = filter.groups[0];
      delete filter.groups;
    }

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadContacts()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };
});
