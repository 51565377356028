
/**
 * Module definition and dependencies
 */
angular.module('App.Account', [
  'App.Account.Base.Controller',
  'App.Account.Overview',
  'App.Account.Pay',
  'App.Account.Booking',
  'App.Account.Credit',
  'App.Account.Summary.Card',
  'App.Account.SelectTransactions.Card',
  'App.Account.PayForBooking.Card',
  'App.Account.SelectAmount.Card',
  'App.Account.UseCredit.Card',
  'App.Account.SelectPaymentMethod.Card',
  'App.Account.ConfirmAndPay.Card',
  'App.Account.PaymentsDue.Modal',
  'App.Account.POLiPayment.Modal',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('account', {
    parent: 'app',
    url: '/account',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'account',
    },
  });
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'account.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'account.overview', title});
  }, {priority: 10});
});
