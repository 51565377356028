
/**
 * Module definition and dependencies
 */
angular.module('App.Page.Controller', [])

/**
 * Controller
 */
.controller('PageCtrl', function(moment, DateFormat, Helpers) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Generate contents
    this.generateContents();
  };

  /**
   * Generate contents
   */
  this.generateContents = function() {

    //Get data
    const {page, user} = this;
    const {contents} = page;

    //Set contents
    this.contents = this.substituteMemberData(contents, user);
  };

  /**
   * Substitute member data
   */
  this.substituteMemberData = function(text, member) {

    //No text
    if (!text) {
      return '';
    }

    //Basic substitutions
    const substitutions = {
      NAME: member.name || '',
      FIRST_NAME: member.firstName || '',
      LAST_NAME: member.lastName || '',
      EMAIL: member.email || '',
      NUMBER: member.number || '',
    };

    //Tokens
    substitutions[`TOKEN.EXTERNAL_LINK`] = this.externalLinkToken || '';

    //Append custom field substitutions
    for (const customField of this.customFields) {

      //Get data
      const {type, key, tag} = customField;
      if (type === 'file') {
        continue;
      }

      //Set in substitutions
      substitutions[tag] = this.getSubstitutionValue(member, key);
    }

    //Substitute
    return this.substitute(text, substitutions);
  };

  /**
   * Make substitutions in text
   */
  this.substitute = function(text, substitutions) {

    //Make substitutions
    for (const key in substitutions) {

      //Build regex and get value
      const value = substitutions[key];
      const regex = new RegExp(`\\[${key}\\]`, 'g');

      //Make substitution
      text = text.replace(regex, value);
    }

    //Return substituted text
    return text;
  };

  /**
   * Get substitution value
   */
  this.getSubstitutionValue = function(object, prop) {

    //Get value
    const value = Helpers.get(object, prop);
    if (!value) {
      return '';
    }

    //Date
    if (moment.isMoment(value)) {
      return moment(value).format(DateFormat.formats.standard);
    }

    //Boolean
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    //Return as is
    return value;
  };
});
