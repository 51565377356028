
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.Overview', [
  'App.Coupon.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('coupon.overview', {
    url: '',
    component: 'couponOverviewRoute',
  });
})

/**
 * Component
 */
.component('couponOverviewRoute', {
  controller: 'CouponOverviewCtrl',
  templateUrl: 'coupon/overview/overview.html',
  bindings: {
    club: '<',
    user: '<',
    module: '<',
    coupons: '<',
  },
});
