
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Edit.Expiration.Card', [])

/**
 * Component
 */
.component('cardCouponTypeEditExpiration', {
  templateUrl: 'admin/coupon-type/cards/expiration.html',
  controller: 'CardCouponTypeEditExpirationCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    couponType: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardCouponTypeEditExpirationCtrl', function(
  CardCloseReasons, Durations
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;
    this.Durations = Durations;

    //Create model subset
    this.model = this.couponType.extract([
      'expiration',
    ]);

    //Check flag
    this.hasExpiration = !!this.model.expiration;
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Set expiration
   */
  this.setExpiration = function(hasExpiration) {

    //Toggles the checkbox
    this.hasExpiration = hasExpiration;

    //Nulls or sets component to default
    if (this.hasExpiration) {
      this.model.expiration = {
        amount: 1,
        unit: 'years',
      };
    }
    else {
      this.model.expiration = null;
    }
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {couponType, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {couponType, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
