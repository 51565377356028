
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.MultiDayOptions.Constant', [])

/**
 * Constant definition
 */
.constant('EventMultiDayOptions', [
  {
    value: 'instance',
    label: 'This event only',
  },
  {
    value: 'series',
    label: 'All events of the multi-day series',
  },
]);
