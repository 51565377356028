
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.ChoosePlan.Card', [])

/**
 * Component
 */
.component('cardClubChoosePlan', {
  templateUrl: 'admin/club/cards/choose-plan.html',
  controller: 'CardClubChoosePlanCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    plans: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubChoosePlanCtrl', function(
  $timeout, $modal, $stripe, CardCloseReasons,
  Config, ErrorCodes
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;
    this.isSuper = this.user.isSuper();

    //Create model subset
    this.model = this.club.extract([
      'billing',
    ]);

    //Determine currency code and symbol
    if (this.plans && this.plans[0]) {
      this.determineCurrency(this.plans[0]);
      this.determineTax(this.plans[0]);
    }

    //Toggle yearly by default
    this.toggleYearly(true);
  };

  /**
   * Determine currency
   */
  this.determineCurrency = function(plan) {
    this.currencyCode = plan.pricing.currency.code;
    this.currencySymbol = plan.pricing.currency.symbol;
    if (this.currencyCode === this.currencySymbol) {
      this.currencyCode = '';
    }
  };

  /**
   * Determine tax
   */
  this.determineTax = function(plan) {
    this.hasTax = plan.pricing.hasTax;
    this.taxRate = plan.pricing.taxRate;
    this.taxName = plan.pricing.taxName;
  };

  /**
   * Choose plan
   */
  this.choosePlan = function(identifier) {

    //Select plan
    this.plan = this.plans.find(plan => plan.identifier === identifier);
    this.planError = null;
    this.form.$setPristine();

    //Set in model
    if (this.plan) {
      this.model.planId = this.plan.id;
      this.mountCardElement();
    }
  };

  /**
   * Clear plan
   */
  this.clearPlan = function() {
    this.plan = null;
    this.planError = null;
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, model = this.model) {
    model[property] = value;
  };

  /**
   * Update plan
   */
  this.updatePlan = function(plan) {

    //Set in model and clear plan error
    this.plan = plan;
    this.model.planId = plan.id;
    this.planError = null;
  };

  /**
   * Toggle yearly
   */
  this.toggleYearly = function(isYearly) {
    if (typeof isYearly === 'undefined') {
      isYearly = !this.isYearly;
    }
    this.isYearly = isYearly;
    this.model.billingInterval = (isYearly ? 'year' : 'month');
    this.determineDiscount();
  };

  /**
   * Enter promo code
   */
  this.enterPromoCode = function() {
    this.showPromoCode = true;
  };

  /**
   * Extend trial
   */
  this.extendTrial = function() {

    //Already extending
    if (this.isExtending) {
      return;
    }

    //Set flag
    this.isExtending = true;

    //Extend trial
    this.club
      .extendTrial()
      .then(() => this.isTrialExtended = true)
      .finally(() => this.isExtending = false);
  };

  /**
   * Mount card element
   */
  this.mountCardElement = function() {

    //Wrap to capture errors as card errors
    try {

      //Get stripe service and create card
      const stripe = $stripe.service(Config.stripe.publicKey);
      const elements = stripe.elements();
      const card = elements.create('card');

      //Apply listener
      card.addEventListener('change', ({error, complete}) => {
        $timeout(() => {
          this.form.$setDirty();
          this.hasEnteredCard = complete;
          this.cardError = error ? error.message : '';
        });
      });

      //Mount element
      $timeout(() => card.mount('#CardElement'));

      //Store card element
      this.cardElement = card;
    }
    catch (error) {
      this.cardError = error.message;
    }
  };

  /**
   * Create billing setup intent
   */
  this.createSetupIntent = async function() {

    //Get data
    const {club, hasEnteredCard} = this;

    //Not entered card
    if (!hasEnteredCard) {
      if (club.isPayingByInvoice) {
        return null;
      }
      throw new Error(`Please enter your card details`);
    }

    //Get additional data
    const stripe = $stripe.service(Config.stripe.publicKey);
    const {cardElement} = this;

    //Create setup intent and confirm it
    const {clientSecret} = await club.createBillingSetupIntent();
    const {setupIntent, error} = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {card: cardElement},
    });

    //Failed
    if (error) {
      throw error;
    }

    //Extract the payment method ID now from the payment intent
    //NOTE: This is done because the card is not automatically set as the
    //default. So we obtain the payment method ID here so that we can pass
    //it to the server for it to set it as the default
    const {payment_method: sourceId} = setupIntent;

    //Success
    return sourceId;
  };
  /**
   * Check promo code
   */
  this.checkPromoCode = function() {

    //Clear flags
    this.isInvalidPromo = false;

    //Get code
    const {code} = this.model;
    if (!code) {
      return;
    }

    //Set flag
    this.isCheckingPromo = true;

    //Check promo code
    this.club
      .checkPromoCode(code)
      .then(promo => {

        //Not valid
        if (!promo.active || !promo.coupon) {
          return (this.isInvalidPromo = true);
        }

        //Currency mismatch
        if (promo.coupon.currency &&
          this.plan.pricing.currency.code.toUpperCase()
            !== promo.coupon.currency.toUpperCase()
        ) {
          return (this.isInvalidPromo = true);
        }

        //Set coupon
        this.coupon = promo.coupon;

        //Determine discount
        this.determineDiscount();
      })
      .catch(() => this.isInvalidPromo = true)
      .finally(() => this.isCheckingPromo = false);
  };

  /**
   * Determine discount from promo code
   */
  this.determineDiscount = function() {

    //No coupon or plan
    const {coupon, plan, isYearly} = this;
    if (!coupon || !plan) {
      return this.discount = 0;
    }

    //Monthly
    if (!isYearly) {
      if (coupon.amount_off) {
        return this.discount = (coupon.amount_off / 100);
      }
      if (coupon.percent_off) {
        return this.discount = (coupon.percent_off / 100 * plan.pricing.monthly);
      }
      return this.discount = 0;
    }

    //Yearly
    if (coupon.duration === 'repeating') {

      //At most 1 year, even if the coupon applies for longer
      const numMonths = Math.min(coupon.duration_in_months, 12);
      if (coupon.amount_off) {
        return this.discount = (
          coupon.amount_off / 100 * numMonths
        );
      }
      if (coupon.percent_off) {
        return this.discount = (
          coupon.percent_off / 100 * plan.pricing.yearly
          / 12 * numMonths
        );
      }
      return this.discount = 0;
    }

    //Not repeating
    if (coupon.amount_off) {
      return this.discount = (coupon.amount_off / 100);
    }
    if (coupon.percent_off) {
      return this.discount = (coupon.percent_off / 100 * plan.pricing.yearly);
    }
    return this.discount = 0;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Already saving
    if (this.isSaving) {
      return;
    }

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving and clear errors
    this.isSaving = true;
    this.cardError = null;
    this.planError = null;

    //Get data
    const {model, plan} = this;

    //Save
    this
      .createSetupIntent()
      .then(sourceId => {
        model.sourceId = sourceId;
        return this.onSave({$event: {model, sourceId}});
      })
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
        $modal.open('basic', {
          templateUrl: 'admin/club/modals/plan-confirmed.html',
          locals: {plan},
        });
      })
      .catch(error => {

        //Plan permission errors
        if (error.code === ErrorCodes.planLimits) {
          $timeout(() => {
            this.planError = error;

            //Supress plan warnings for an hour to give them a chance to
            //correct these plan downgrade errors. Setting this on config
            //for now, but when we refactor to Vue it should go in a global
            //session thingy
            Config.supressPlanWarnings = true;
            $timeout(() => {
              Config.supressPlanWarnings = false;
            }, 60 * 1000);
          });
          return;
        }

        //Capture exceptions
        if (error instanceof Error) {
          Sentry.captureException(error);
        }

        //Set card error
        $timeout(() => this.cardError = error.message);
      })
      .finally(() => this.isSaving = false);
  };
});
