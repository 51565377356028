
/**
 * Module definition and dependencies
 */
angular.module('App.Error.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('error', {
    templateUrl: 'error/error.modal.html',
    controller: 'ModalCtrl',
  });
});
