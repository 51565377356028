
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.Payments.Controller', [])

/**
 * Controller
 */
.controller('AdminFinancePaymentsCtrl', function(
  $filter, $modal, $notice, moment, ScrollPosition, Pagination,
  Payment, Settings, PaymentMethods, TransactionTypes, Intercom
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed payments');

    //Flags
    this.isSuper = this.user.isSuper();
    this.showPercentages = true;
    this.hasXero = this.integrations.some(int => int.type === 'xero');

    //Initialize totals
    this.totalTransactions = 0;
    this.totalCredited = 0;
    this.totalOnline = 0;
    this.totalOffline = 0;
    this.totalCredited = 0;
    this.totalAccountCredit = 0;
    this.totalToClub = 0;
    this.totalFeesClub = 0;
    this.totalFeesMembers = 0;

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load payments
    this.loadPage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Set page title and crumb
    page.setTitle('Payments');
    page.addCrumb({sref: 'admin.finance.payments'});

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter} = this;
    const {payoutId} = this.transition.params();

    //Set filter defaults
    filter.setDefaults({
      search: '',
      payoutId: '',
      xeroId: '',
      member: null,
      fromDate: moment().subtract(1, 'month').startOf('day'),
      toDate: moment().endOf('day'),
      status: 0,
    });

    //Set payout ID if present
    if (payoutId) {
      this.singleFilter('payoutId', payoutId);
    }

    //Filter on change handler
    filter.onChange((key, value) => {

      //Get data
      const {toDate, fromDate} = filter;
      const hasDates = fromDate && toDate;

      //From date changed
      if (hasDates && key === 'fromDate' && fromDate.isAfter(toDate)) {
        filter.toDate = fromDate.clone();
      }

      //To date changed
      else if (hasDates && key === 'toDate' && toDate.isBefore(fromDate)) {
        filter.fromDate = toDate.clone();
      }

      //Index based merge
      if (key === 'status') {
        filter.mapOptions(key, value);
      }

      //Payout or Xero ID change
      if ((key === 'payoutId' || key === 'xeroId') && value) {
        this.singleFilter(key, value);
      }
      else {
        this.resetFilter();
      }

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      method: PaymentMethods,
      provider: PaymentMethods.filter(method => method.isOnline),
      transactionType: TransactionTypes,
      isCredit: [
        {
          label: 'Payments',
          value: false,
        },
        {
          label: 'Credits and refunds',
          value: true,
        },
      ],
      status: [
        {
          label: '...',
          isDefault: true,
          filter: {
            isPaid: null,
            isPending: null,
            isCancelled: null,
            isError: null,
            isUnverified: null,
            isTimedOut: null,
            isAutomatic: null,
          },
        },
        {
          label: 'Paid',
          filter: {
            isPaid: true,
          },
        },
        {
          label: 'Not paid',
          filter: {
            isPaid: false,
          },
        },
        {
          label: 'Pending',
          filter: {
            isPending: true,
          },
        },
        {
          label: 'Cancelled',
          filter: {
            isCancelled: true,
          },
        },
        {
          label: 'Failed',
          filter: {
            isError: true,
          },
        },
        {
          label: 'Unverified',
          filter: {
            isUnverified: true,
          },
        },
        {
          label: 'Timed out',
          filter: {
            isTimedOut: true,
          },
        },
        {
          label: 'Automatic',
          filter: {
            isAutomatic: true,
          },
        },
      ],
    };
  };

  /**
   * Helper to setup single filter for a given Xero or Payout ID
   */
  this.singleFilter = function(key, value) {

    //Get filter
    const {filter} = this;

    //Set rest of filter properties
    filter.payoutId = '';
    filter.xeroId = '';
    filter.method = null;
    filter.fromDate = null;
    filter.toDate = null;
    filter.isCredit = null;
    filter.member = null;
    filter.search = '';

    //Set value
    filter[key] = value;
  };

  /**
   * Helper to reset filter again
   */
  this.resetFilter = function() {

    //Get filter
    const {filter} = this;

    //Clear payout ID
    filter.payoutId = '';

    //Ensure has dates
    if (!filter.fromDate) {
      filter.fromDate = moment().subtract(1, 'month').startOf('day');
    }
    if (!filter.toDate) {
      filter.toDate = moment().endOf('day');
    }
  };

  /**
   * Load payments
   */
  this.loadPayments = function() {

    //Mark as loading and clear
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query payments
    return Payment
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Toggle percentages
   */
  this.togglePercentages = function() {
    this.showPercentages = !this.showPercentages;
  };

  /**
   * View payment
   */
  this.view = function($event) {

    //Get data
    const {payment} = $event;
    const {isSuper} = this;

    //Open modal
    $modal.open('basic', {
      templateUrl: 'admin/finance/modals/view-payment.html',
      locals: {payment, isSuper},
    });
  };

  /**
   * Edit payment
   */
  this.edit = function($event) {

    //Get payment and define handler
    const {payment} = $event;
    const handler = data => payment.update(data);

    //Open modal
    $modal
      .open('editPayment', {locals: {payment, handler}})
      .result
      .then(() => $notice.show('Payment updated'));
  };

  /**
   * Remove payment
   */
  this.delete = function($event) {

    //Get payment and define handler
    const {payment} = $event;
    const handler = function() {
      return payment.delete();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-delete-payment.html',
        locals: {payment, handler},
      })
      .result
      .then(() => {
        $notice.show('Payment removed');
        this.loadPayments();
      });
  };

  /**
   * Export payments
   */
  this.export = function() {

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club: this.club, action: 'Exporting payments'},
      });
    }

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'payments', action: 'export'},
      });
    }

    //Create filter and export
    const filter = this.makeFilter();
    return Payment.export(filter);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, payments} = data;

    //Set in scope
    this.payments = payments;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);

    //Sums
    Object.assign(this, meta.sums);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //If one method selected, change prop
    if (Array.isArray(filter.methods) && filter.methods.length === 1) {
      filter.method = filter.methods[0];
      delete filter.methods;
    }

    //If one transaction type selected, change prop
    if (
      Array.isArray(filter.transactionTypes) &&
      filter.transactionTypes.length === 1
    ) {
      filter.transactionType = filter.transactionTypes[0];
      delete filter.transactionTypes;
    }

    //Delete status
    delete filter.status;

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadPayments()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };
});
