
/**
 * Module definition and dependencies
 */
angular.module('Shared.AgeCondition.Filter', [])

/**
 * Age condition filter
 */
.filter('ageCondition', function() {
  return function(membership) {
    const {minAge, maxAge} = membership;
    if (!minAge && !maxAge) {
      return 'All ages';
    }
    if (minAge && maxAge) {
      return `Between ${minAge}–${maxAge} years`;
    }
    if (minAge) {
      return `${minAge} years and over`;
    }
    if (maxAge) {
      return `Up to ${maxAge} years`;
    }
  };
});
