
/**
 * Module definition and dependencies
 */
angular.module('Shared.NumPages.Filter', [])

/**
 * Filter definitions
 */
.filter('numPages', function(Settings) {
  return function(items, perPage) {
    perPage = perPage || Settings.get('general.itemsPerPage', 50);
    return Math.ceil(items / perPage);
  };
});
