
/**
 * Module definition and dependencies
 */
angular.module('Shared.Event.Interest.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('eventInterest', {
    model: 'EventInterest',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'interests',
        isArray: true,
        isModel: true,
      },
      count: {
        url: 'count',
        method: 'GET',
      },
      get: {
        method: 'GET',
        isModel: true,
      },

      //Admin creation of interested members
      createMany: {
        url: 'many',
        method: 'POST',
        dataKey: 'interests',
        isArray: true,
        isModel: true,
      },

      //Create interest for logged in member
      createOwn: {
        url: 'own',
        method: 'POST',
      },
      delete: {
        url: ':method',
        method: 'DELETE',
        params: {
          id: '@id',
          method: '@method',
        },
      },
      email: {
        url: 'email',
        method: 'POST',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('eventInterest', {
    dataKey: 'interests',
    model: 'EventInterest',
  });
})

/**
 * Model definition
 */
.factory('EventInterest', function($baseModel, $api, $sync) {

  /**
   * Constructor
   */
  function EventInterest(data) {
    $baseModel.call(this, angular.extend({}, data || {}));
  }

  /**
   * Extend base model
   */
  angular.extend(EventInterest.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  EventInterest.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('member', 'Member');

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  EventInterest.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Strip data
    json.event = $baseModel.onlyId(json.event);
    json.member = $baseModel.onlyId(json.member);

    //Remove if empty
    if (!json.member) {
      delete json.member;
    }

    //Return
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save
   */
  EventInterest.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine type and call API
    const method = this.id ? 'update' : 'create';
    return $api.eventInterest[method](data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Create own
   */
  EventInterest.prototype.createOwn = function(circleMemberId) {

    //Get data
    const data = this.toJSON();

    //Create attendee
    return $api.eventInterest
      .createOwn(circleMemberId, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  EventInterest.prototype.delete = function() {
    return $api.eventInterest
      .delete(null, this)
      .then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  EventInterest.query = function(filter) {
    return $api.eventInterest.query(filter);
  };

  /**
   * Export
   */
  EventInterest.export = function(filter) {
    return $sync.get('eventInterest/export/csv', filter, 'Exporting...');
  };

  /**
   * Count
   */
  EventInterest.count = function(filter) {
    return $api.eventInterest
      .count(filter)
      .then(data => data.total || 0);
  };

  /**
   * Create many interests
   */
  EventInterest.createMany = function(data) {

    //Only ID
    data.event = $baseModel.onlyId(data.event);
    if (data.meta.members) {
      data.meta.members = $baseModel.onlyId(data.meta.members);
    }

    //Call API
    return $api.eventInterest.createMany(data);
  };

  /**
   * Find by ID
   */
  EventInterest.findById = function(id) {
    return $api.eventInterest.get({id});
  };

  /**
   * Cancel interest
   */
  EventInterest.cancelOwn = function(id, circleMemberId) {
    return $api.eventInterest.delete(null, {id, method: 'own', circleMemberId});
  };

  /**
   * Email
   */
  EventInterest.email = function(filter, email) {
    return $api.eventInterest.email(filter, email);
  };

  //Return
  return EventInterest;
});
