
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.Email.Card', [])

/**
 * Components
 */
.component('cardSettingsEmailGeneral', {
  templateUrl: 'admin/settings/cards/email-general.html',
  controller: 'CardSettingsEmailCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    texts: '<',
    onSave: '&',
  },
})
.component('cardSettingsEmailSummaries', {
  templateUrl: 'admin/settings/cards/email-summaries.html',
  controller: 'CardSettingsEmailCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    texts: '<',
    onSave: '&',
  },
})
.component('cardSettingsEmailReminders', {
  templateUrl: 'admin/settings/cards/email-reminders.html',
  controller: 'CardSettingsEmailCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    texts: '<',
    onSave: '&',
  },
})
.component('cardSettingsEmailNotifications', {
  templateUrl: 'admin/settings/cards/email-notifications.html',
  controller: 'CardSettingsEmailCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    memberships: '<',
    texts: '<',
    onSave: '&',
  },
})
.component('cardSettingsEmailTemplates', {
  templateUrl: 'admin/settings/cards/email-templates.html',
  controller: 'CardSettingsEmailCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    texts: '<',
    customFields: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSettingsEmailCtrl', function(
  $controller, Weekdays, Months, TextTypes, $q,
  $notice, EmailTemplateTypes, ReplacementTags,
  MembershipNotifications
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'email';
    this.setupStep = 'reviewEmailSettings';

    //Set services
    this.Weekdays = Weekdays;
    this.Months = Months;
    this.MembershipNotifications = MembershipNotifications;
    this.emailTemplateTypes = EmailTemplateTypes;
    this.tags = ReplacementTags.member(this.customFields);

    //Set valid schedule period options
    this.SchedulePeriodOptions = [
      {value: 'week', label: 'Weekly'},
      {value: 'month', label: 'Monthly'},
      {value: 'quarter', label: 'Quarterly'},
      {value: 'year', label: 'Annually'},
    ];

    //Ensure texts are loaded
    if (this.texts && this.texts.length > 0) {

      //Find relevant texts
      this.birthdayEmail = this.texts
        .find(text => text.type === TextTypes.BIRTHDAY_EMAIL);
      this.bookingCreatedEmail = this.texts
        .find(text => text.type === TextTypes.BOOKING_CREATED_EMAIL);
      this.eventAttendeeEmail = this.texts
        .find(text => text.type === TextTypes.EVENT_ATTENDEE_EMAIL);
      this.unpaidTrxReminderEmail = this.texts
        .find(text => text.type === TextTypes.UNPAID_TRX_REMINDER_EMAIL);
      this.unpaidBookingReminderEmail = this.texts
        .find(text => text.type === TextTypes.UNPAID_BOOKING_REMINDER_EMAIL);
      this.membershipCreationEmail = this.texts
        .find(text => text.type === TextTypes.MEMBERSHIP_CREATION_EMAIL);
      this.membershipPurchaseEmail = this.texts
        .find(text => text.type === TextTypes.MEMBERSHIP_PURCHASE_EMAIL);
      this.membershipChangeEmail = this.texts
        .find(text => text.type === TextTypes.MEMBERSHIP_CHANGE_EMAIL);
      this.membershipRenewalEmail = this.texts
        .find(text => text.type === TextTypes.MEMBERSHIP_RENEWAL_EMAIL);
      this.membershipRenewalReminderEmail = this.texts
        .find(text => text.type === TextTypes.MEMBERSHIP_RENEWAL_REMINDER_EMAIL);

      //Reset their models
      if (this.birthdayEmail) {
        this.birthdayEmail.resetModel();
      }
      if (this.bookingCreatedEmail) {
        this.bookingCreatedEmail.resetModel();
      }
      if (this.eventAttendeeEmail) {
        this.eventAttendeeEmail.resetModel();
      }
      if (this.unpaidTrxReminderEmail) {
        this.unpaidTrxReminderEmail.resetModel();
      }
      if (this.unpaidBookingReminderEmail) {
        this.unpaidBookingReminderEmail.resetModel();
      }
      if (this.membershipCreationEmail) {
        this.membershipCreationEmail.resetModel();
      }
      if (this.membershipPurchaseEmail) {
        this.membershipPurchaseEmail.resetModel();
      }
      if (this.membershipChangeEmail) {
        this.membershipChangeEmail.resetModel();
      }
      if (this.membershipRenewalEmail) {
        this.membershipRenewalEmail.resetModel();
      }
      if (this.membershipRenewalReminderEmail) {
        this.membershipRenewalReminderEmail.resetModel();
      }
    }

    //Set first email type
    this.setEmailType(EmailTemplateTypes[0]);

    //Call parent init
    $base.$onInit.call(this);
  };

  /**
   * Set email type
   */
  this.setEmailType = function(type) {
    this.emailType = type;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save texts
   */
  this.saveTexts = function(silent = false) {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get text model
    const {texts} = this;
    const promises = [];

    //Process keys
    for (const text of texts) {
      if (text.isModified()) {
        promises.push(text.save());
      }
    }

    //Flag as saving
    this.isSavingTexts = true;

    //Save all
    return $q
      .all(promises)
      .then(() => {
        if (!silent) {
          $notice.show(`Text updated`);
        }
      })
      .finally(() => this.isSavingTexts = false);
  };

  /**
   * Update period
   */
  this.updatePeriod = function(period, setting) {

    //Set defaults
    setting.period = period;

    //Set weekday/month as needed
    if (period === 'week') {
      setting.weekday = 1;
      setting.month = null;
    }
    else if (period === 'year') {
      setting.weekday = null;
      setting.month = 1;
    }
    else {
      setting.weekday = null;
      setting.month = null;
    }
  };
});
