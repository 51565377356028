
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Report.Area', [
  'App.Admin.Report.Area.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.report.area', {
    url: '/area',
    component: 'adminReportAreaRoute',
    params: {
      activityId: null,
      basePeriod: null,
      compPeriod: null,
    },
    resolve: {
      areas(transition, $store) {
        const {activityId} = transition.params();
        return $store.areas
          .query()
          .then(areas => areas.filter(area => area.activity === activityId));
      },
      activity(transition, $store) {
        const {activityId} = transition.params();
        return $store.activities.findById(activityId);
      },
      baseData(transition, $api, $q) {
        const {basePeriod: {fromDate, toDate}} = transition.params();
        if (fromDate && toDate) {
          return $api.report.area({fromDate, toDate});
        }
        return $q.resolve({});
      },
      compData(transition, $api, $q) {
        const {compPeriod: {fromDate, toDate}} = transition.params();
        if (fromDate && toDate) {
          return $api.report.area({fromDate, toDate});
        }
        return $q.resolve({});
      },
    },
  });
})

/**
 * Component
 */
.component('adminReportAreaRoute', {
  controller: 'AdminReportAreaCtrl',
  templateUrl: 'admin/report/area/area.html',
  bindings: {
    areas: '<',
    activity: '<',
    baseData: '<',
    compData: '<',
    page: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Can't navigate here directly
  $transitions.onBefore({to: 'admin.report.area'}, transition => {
    const {activityId, basePeriod} = transition.params();
    if (!activityId || !basePeriod) {
      return transition.router.stateService.target('admin.report.overview');
    }
  });

  //Page setup
  $transitions.onSuccess({to: 'admin.report.area'}, transition => {
    const params = transition.params();
    const activity = transition.injector().get('activity');
    const title = `${activity.name} ${activity.areaPlural} usage report`;
    Page.setTitle(title);
    Page.addCrumb({
      sref: 'admin.report.area',
      params,
    });
  });
});
