
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Add.Conditions.Card', [])

/**
 * Component
 */
.component('cardMembershipAddConditions', {
  templateUrl: 'admin/membership/cards/conditions.html',
  controller: 'CardMembershipAddConditionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    memberships: '<',
    membership: '<',
    system: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipAddConditionsCtrl', function(
  $controller, CardCloseReasons, MembershipConstraints
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMembershipEditConditionsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Flags
    this.isEdit = false;
    this.isAdd = true;

    //Default constraint
    if (!this.model.constraint) {
      this.model.constraint = MembershipConstraints.ALL;
    }
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {model, membership} = this;
    this.onMerge({$event: {membership, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next tab
    this.merge();
    this.card.nextTab();
  };

  /**
   * Previous step
   */
  this.previous = function() {

    //Merge
    this.merge();

    //Next card
    if (this.membership.isLinked) {
      this.card.open('membership.details', CardCloseReasons.OPENED_PREV);
    }
    else {
      this.card.previous();
    }
  };
});
