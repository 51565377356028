
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings', [
  'App.Admin.Settings.Controller',
  'App.Admin.Settings.Card.BaseController',
  'App.Admin.Settings.General.Card',
  'App.Admin.Settings.Branding.Card',
  'App.Admin.Settings.Homepage.Card',
  'App.Admin.Settings.Modules.Card',
  'App.Admin.Settings.Booking.Card',
  'App.Admin.Settings.Event.Card',
  'App.Admin.Settings.EventSystem.Card',
  'App.Admin.Settings.Calendar.Card',
  'App.Admin.Settings.Email.Card',
  'App.Admin.Settings.Member.Card',
  'App.Admin.Settings.SignIn.Card',
  'App.Admin.Settings.CheckInRules.Card',
  'App.Admin.Settings.ManageFields.Card',
  'App.Admin.Settings.ManageDirectoryFields.Card',
  'App.Admin.Settings.Payment.Card',
  'App.Admin.Settings.AccountCredit.Card',
  'App.Admin.Settings.CustomPages.Card',
  'App.Admin.Settings.EditCheckInRule.Modal',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.settings', {
    //NOTE: Open card param has been added to the URL, otherwise it doesn't work
    //when creating a link with ui-sref in a tooltip (e.g. in the custom fields card,
    //member number tooltip)
    url: '/settings/:openCard/:openTab',
    component: 'adminSettingsRoute',
    params: {
      openCard: {
        value: null,
        squash: true,
      },
      openTab: {
        value: null,
        squash: true,
      },
    },
    data: {
      id: 'settings',
    },
  });
})

/**
 * Component
 */
.component('adminSettingsRoute', {
  controller: 'AdminSettingsCtrl',
  templateUrl: 'admin/settings/settings.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    pages: '<',
    module: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page, Intercom) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.settings'}, transition => {

    //Set page title
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.settings'});

    //Track intercom event
    Intercom.event('Viewed settings');
  });
});
