
/**
 * Module definition and dependencies
 */
angular.module('Shared.Avatar.Component', [])

/**
 * Avatar component
 */
.component('avatar', {
  templateUrl: 'shared/avatar/avatar.html',
  transclude: true,
  bindings: {
    avatar: '<',
    text: '<',
    role: '<',
    color: '<',
    icon: '@',
    size: '@',
    special: '@',
  },

  /**
   * Controller
   */
  controller($element) {

    /**
     * Initialization
     */
    this.$onInit = function() {

      //Add class
      $element.addClass('AvatarComponent');

      //Flag as not loaded
      this.isAvatarLoaded = false;
    };

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Set default size
      if (!this.size) {
        this.size = 'm';
      }

      //Get avatar
      const {avatar} = this;
      if (!avatar) {
        this.src = '';
        this.srcSet = '';
        return;
      }

      //Get pixel size and generate base URL
      const size = this.getPixelSize();
      const base = `${avatar.url}?w=${size}&h=${size}&fit=crop&auto=format`;

      //Set source
      this.src = `${base}&q=60`;
      this.srcSet = `${base}&dpr=1&q=60 1x, ${base}&dpr=2&q=40 2x`;
    };

    /**
     * Set avatar loaded
     */
    this.setAvatarLoaded = function() {
      this.isAvatarLoaded = true;
    };

    /**
     * Get pixel size
     */
    this.getPixelSize = function() {
      switch (this.size) {
        case 'xxl':
          return 160; //10rem
        case 'xl':
          return 120; //7.5rem
        case 'l':
          return 60; //3.75rem
        case 's':
          return 32; //2rem
        case 'xs':
          return 24; //1.5rem
        case 'xxs':
          return 16; //1rem
        case 'm':
        default:
          return 40; //2.5rem
      }
    };
  },
});
