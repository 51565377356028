
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People', [
  'App.Admin.People.Members',
  'App.Admin.People.Contacts',
  'App.Admin.People.Staff',
  'App.Admin.People.Circles',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people', {
    url: '/people',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'people',
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, Page, Modules) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.people.**'}, () => {
    const {title} = Modules.find('people');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.people.members.overview', title});
  }, {priority: 10});
});
