
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.List.Card', [])

/**
 * Component
 */
.component('cardSubscriptionList', {
  templateUrl: 'subscription/cards/list.html',
  controller: 'CardSubscriptionListCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    isOwn: '<',
    member: '<',
    subscriptions: '<',
    activities: '<',
    isLoading: '<',
    type: '@',
    onView: '&',
    onRenew: '&',
    onStop: '&',
    onChange: '&',
    onToggleAutoRenew: '&',
  },
})

/**
 * Controller
 */
.controller('CardSubscriptionListCtrl', function() {

  /**
   * On changes
   */
  this.$onChanges = function() {

    //Set flags
    this.showPast = false;
    this.hasPast = this.subscriptions
      .some(sub => sub.isPast && !sub.isExpiring);
    this.hasCurrent = this.subscriptions
      .some(sub => sub.isCurrent || (sub.isPast && sub.isExpiring));
    this.hasUpcoming = this.subscriptions
      .some(sub => sub.isUpcoming);
    this.hasCurrentOrUpcoming = (this.hasCurrent || this.hasUpcoming);

    //If we don't have any current or upcoming subs, show past instead
    if (!this.hasCurrentOrUpcoming) {
      this.showPast = true;
    }
  };

  /**
   * Toggle past
   */
  this.togglePast = function() {
    this.showPast = !this.showPast;
  };

  /**
   * Toggle auto renewal
   */
  this.toggleAutoRenew = function(subscription, autoRenews) {
    subscription.isTogglingAutoRenew = true;
    this
      .onToggleAutoRenew({$event: {subscription, autoRenews}})
      .finally(() => delete subscription.isTogglingAutoRenew);
  };

  /**
   * View subscription
   */
  this.view = function(subscription) {
    this.onView({$event: {subscription}});
  };

  /**
   * Renew subscription
   */
  this.renew = function(subscription) {
    subscription.isRenewing = true;
    this
      .onRenew({$event: {subscription}})
      .finally(() => delete subscription.isRenewing);
  };

  /**
   * Change membership
   */
  this.change = function(subscription) {
    subscription.isChanging = true;
    this
      .onChange({$event: {subscription}})
      .finally(() => delete subscription.isChanging);
  };

  /**
   * Stop subscription
   */
  this.stop = function(subscription) {
    this.onStop({$event: {subscription}});
  };
});
