
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.Controller', [])

/**
 * Controller
 */
.controller('AdminSystemCtrl', function(
  $notice, $interval, $q, $store, Settings, GlobalScope, TextTypes, Text
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Check if super user and initialize checking status flag
    this.isSuper = this.user.isSuper();
    this.isCheckingState = true;
    this.memberships = [];

    //Create interval to check if system connected
    this.stateInterval = $interval(() => this.getState(), 5000);

    //Check initialy
    this
      .getState()
      .finally(() => this.isCheckingState = false);
  };

  /**
   * Clear interval
   */
  this.$onDestroy = function() {
    $interval.cancel(this.stateInterval);
  };

  /**
   * Get state
   */
  this.getState = function() {

    //No system
    if (!this.system) {
      return $q.resolve();
    }

    //Get status
    return this.system
      .getState()
      .then(state => this.state = state);
  };

  /**
   * Patch
   */
  this.patch = function($event) {

    //Get model
    const {model} = $event;

    //Patch
    return this.system
      .patch(model)
      .then(() => $notice.show('System updated'))
      .catch(error => {
        $notice.showError('Failed to update system');
        throw error;
      });
  };

  /**
   * Update settings
   */
  this.updateSettings = function($event) {

    //Get data
    const {settings} = $event;

    //Update settings
    return this.club
      .patch({settings})
      .then(({settings}) => {

        //Update globally
        Settings.set(settings);
        GlobalScope.set({settings});

        //Show notice
        $notice.show('Settings updated');
        return settings;
      });
  };

  /**
   * Load memberships
   */
  this.loadMemberships = function() {
    return $store.memberships
      .query()
      .then(memberships => this.memberships = memberships);
  };

  /**
   * Load tags
   */
  this.loadTags = function() {
    return $store.tags
      .query({isService: true})
      .then(tags => this.tags = tags);
  };

  /**
   * Load troubleshooting text
   */
  this.loadTroubleshootingText = function() {
    return Text
      .findByType(TextTypes.SYSTEM_TROUBLESHOOTING)
      .then(text => this.troubleshootingText = text);
  };
});
