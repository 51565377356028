
/**
 * Module definition and dependencies
 */
angular.module('Shared.EditDiscount.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editDiscount', {
    templateUrl: 'modals/edit-discount.html',
    controller: 'ModalEditDiscountCtrl',
    closeOnClick: false,
    resolve: {
      memberships($store) {
        'ngInject';
        return $store.memberships.query();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalEditDiscountCtrl', function(
  $controller, $modalInstance, $filter, DiscountTypes,
  MembershipConstraintLabels, Modules
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set initial step
    this.setStep('details');

    //Extract model
    this.model = this.discount.extract([
      'name', 'type', 'amount', 'percentage',
      'constraint', 'memberships', 'member',
      'minAge', 'maxAge', 'region',
    ]);

    //Set flags
    this.isSaving = false;
    this.isError = false;

    //Set services
    this.DiscountTypes = DiscountTypes;

    //Determine constraint labels
    this.determineConstraintLabels();

    //Distribute memberships
    this.memberships = $filter('distribute')(this.memberships, 3);
  };

  /**
   * Determine constraint labels
   */
  this.determineConstraintLabels = function() {

    //Set services
    this.membershipConstraintLabels = MembershipConstraintLabels
      .filter(item => !!item.labelAlt);

    //Set null label
    const {singular, plural} = Modules.find('members');
    this.nullLabel = `A specific ${singular}`;

    //Get module names & replace if needed
    if (plural !== 'members') {
      this.membershipConstraintLabels = this.membershipConstraintLabels
        .map(option => {
          if (option.label.match('Members')) {
            const p = plural[0].toUpperCase() + plural.substring(1);
            return option.label.replace('Members', p);
          }
          return option.label;
        });
    }
  };

  /**
   * Next step
   */
  this.next = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Go to next step
    this.form.$setPristine();
    this.setStep('constraint');
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };
});
