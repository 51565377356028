
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.Payouts', [
  'App.Admin.Finance.Payouts.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.finance.payouts', {
    url: '/payouts',
    component: 'adminFinancePayoutsRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('payouts');
      },
      country(Country) {
        return Country.own();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminFinancePayoutsRoute', {
  controller: 'AdminFinancePayoutsCtrl',
  templateUrl: 'admin/finance/payouts/payouts.html',
  bindings: {
    filter: '<',
    page: '<',
    user: '<',
    club: '<',
    country: '<',
  },
});
