
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.Stripe.Strategy.Card', [])

/**
 * Component
 */
.component('cardIntegrationsStripeStrategy', {
  templateUrl: 'admin/integrations/stripe/strategy.html',
  controller: 'CardIntegrationsStripeStrategyCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    isSuper: '<',
    club: '<',
    country: '<',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsStripeStrategyCtrl', function(
  $controller, $modal, PaymentFeeCover
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set services
    this.exampleAmount = 100;
    this.canEdit = (this.isSuper || !this.integration.isCustom);
    this.PaymentFeeCover = PaymentFeeCover;

    //Initialize cover data
    this.initializeCover();
    this.updateNonProfitStatus(this.data.isNonProfit || false, true);
  };

  /**
   * Initialize cover
   */
  this.initializeCover = function() {
    this.data.cover = this.data.cover || {};
    const {cover} = this.data;
    if (!cover.domestic) {
      cover.domestic = {
        strategy: PaymentFeeCover.NONE,
        structure: {},
      };
    }
    if (!cover.domesticNonProfit) {
      cover.domesticNonProfit = {
        strategy: PaymentFeeCover.NONE,
        structure: {},
      };
    }
    if (!cover.international) {
      cover.international = {
        strategy: PaymentFeeCover.NONE,
        structure: {},
      };
    }
    if (!cover.internationalNonProfit) {
      cover.internationalNonProfit = {
        strategy: PaymentFeeCover.NONE,
        structure: {},
      };
    }
  };

  /**
   * Update non-profit status
   */
  this.updateNonProfitStatus = function(isNonProfit, calledOnInit = false) {
    this.data.isNonProfit = isNonProfit;
    this.feeTypeItl = isNonProfit ?
      'internationalNonProfit' :
      'international';
    this.feeTypeDom = isNonProfit ?
      'domesticNonProfit' :
      'domestic';
    if (isNonProfit && !calledOnInit) {
      $modal.open('basic', {
        templateUrl: 'admin/integrations/modals/confirm-non-profit-rates.html',
        locals: {method: 'stripe'},
      });
    }
  };

  /**
   * Validation
   */
  this.validate = function() {

    //Parent validation
    if (!$base.validate.call(this)) {
      return false;
    }

    //Clear flags
    this.isMissingDomesticCover = false;
    this.isMissingInternationalCover = false;

    //Get data
    const {domestic, international} = this.data.cover;

    //Ensure structure is always present
    if (!domestic.structure) {
      domestic.structure = {};
    }
    if (!international.structure) {
      international.structure = {};
    }

    //If fee strategy is partial, must have at least some values
    if (domestic.strategy === PaymentFeeCover.PART) {
      if (!domestic.structure.factor && !domestic.structure.fixed) {
        this.isMissingDomesticCover = true;
      }
    }
    if (international.strategy === PaymentFeeCover.PART) {
      if (!international.structure.factor && !international.structure.fixed) {
        this.isMissingInternationalCover = true;
      }
    }

    //All good
    return (!this.isMissingInternationalCover && !this.isMissingDomesticCover);
  };

  /**
   * On saved handler
   */
  this.onSaved = function() {
    this.card.showTab('status');
    this.club.markStepComplete('chooseFeeStrategy', true);
  };
});
