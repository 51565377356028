
/**
 * Module definition and dependencies
 */
angular.module('App.Kiosk.Controller', [])

/**
 * Controller
 */
.controller('KioskCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('BookingViewCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Kiosk mode overrides
    this.isAdmin = false;
    this.canBook = this.activity.isAllowedInKiosk;
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get data
    const {page} = this;

    //Set page title and crumb
    page.setTitle('');
    page.addCrumb({sref: 'kiosk'});
  };
});
