
/**
 * Module definition and dependencies
 */
angular.module('Shared.TimeAdverb.Filter', [])

/**
 * Filter definition
 */
.filter('timeAdverb', function() {
  return function(str) {
    switch (str) {
      case 'day':
      case 'days':
        return 'daily';
      case 'week':
      case 'weeks':
        return 'weekly';
      case 'month':
      case 'months':
        return 'monthly';
      case 'year':
      case 'years':
        return 'annually';
      default:
        return str;
    }
  };
});
