
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Welcome.Base.Controller', [])

/**
 * Controller
 */
.controller('WelcomeBaseCtrl', function($state, $timeout) {

  /**
   * Init
   */
  this.$onInit = function() {

    //Set step in welcome flow
    const step = $state.current.name.replace(/welcome\./, '');
    this.welcome.setStep(step);

    //Set user shortcut
    this.user = this.welcome.user;

    //Set params
    if (this.transition) {
      this.params = this.transition.params();
    }

    //Setup
    this.setup();

    //Start faded
    this.fade = true;
    $timeout(() => this.fade = false);
  };

  /**
   * Update user
   */
  this.updateUser = function(property, value) {
    this.user[property] = value;
  };

  /**
   * Validate form
   */
  this.validate = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Continue
    this.continue();
  };

  /**
   * Continue to the next step
   */
  this.continue = function() {

    //At last step?
    if (this.welcome.isAtLastStep) {

      //Toggle flag
      this.isSettingUp = true;

      //Welcome
      this.welcome
        .setup()
        .then(() => this.welcome.finish())
        .catch(() => this.isSettingUp = false);
    }

    //Go to next step
    else {
      this.fade = true;
      this.welcome.goToNextStep();
    }
  };

  /**
   * Back to the previous step
   */
  this.back = function() {

    //Go to previous step
    this.fade = true;
    this.welcome.goToPreviousStep();
  };

  /**
   * Setup step
   */
  this.setup = function() {};
});
