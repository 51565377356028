
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.ViewMember.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('viewMember', {
    templateUrl: 'admin/people/members/modals/view-member.html',
    controller: 'ModalViewMemberCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalViewMemberCtrl', function(
  $controller, $modalInstance, CheckInLog, $notice
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set flag
    this.checkingInMember = false;
  };

  /**
   * Check in member
   */
  this.checkInMember = function() {

    //Set flag
    this.checkingInMember = true;

    //Set data
    const data = {
      member: this.member.id,
      wasCheckedIn: true,
      type: 'Check-in',
      reason: 'QR code scanned',
    };

    //Create check in log
    return CheckInLog
      .create(data)
      .then(() => {
        this.checkingInMember = false;
        $notice.show(`Member checked in`);
        $modalInstance.close();
      });
  };
});
