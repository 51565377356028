
/**
 * Module definition and dependencies
 */
angular.module('Shared.History.Service', [])

/**
 * Service definition
 */
.factory('History', function($storage) {
  class History {

    /**
     * Constructor
     */
    constructor(type, persist = false) {
      this.type = type;
      this.persist = persist;
      this.history = [];
      this.index = 0;

      //Load history
      this.load();
    }

    /**
     * Load from storage
     */
    load() {
      if (this.persist) {
        this.history = $storage.get('history.' + this.type, []);
        this.index = this.history.length;
      }
    }

    /**
     * Save to storage
     */
    save() {
      if (this.persist) {
        $storage.set('history.' + this.type, this.history);
      }
    }

    /**
     * Check if we have any history
     */
    has() {
      return this.history.length > 0;
    }

    /**
     * Check if we have a previous history item
     */
    hasPrevious() {
      return (this.index > 0);
    }

    /**
     * Check if we have a next history item
     */
    hasNext() {
      return (this.index < this.history.length);
    }

    /**
     * Get current history item
     */
    current() {
      return this.history[this.index];
    }

    /**
     * Get previous history item
     */
    previous() {
      if (this.index > 0) {
        this.index--;
      }
      return this.current();
    }

    /**
     * Get next history item
     */
    next() {
      if (this.index < this.history.length) {
        this.index++;
      }
      return this.current();
    }

    /**
     * Add item to history
     */
    add(item, unique = false) {

      //Remove item from history if already exists
      if (unique) {
        this.remove(item);
      }

      //Add item to history now
      this.history.push(item);
      this.index = this.history.length;
      this.save();
    }

    /**
     * Remove item from history
     */
    remove(item) {
      const i = this.history.indexOf(item);
      if (i > -1) {
        this.history.splice(i, 1);
      }
      this.save();
    }
  }

  //Return class
  return History;
});
