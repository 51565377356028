
/**
 * Module definition and dependencies
 */
angular.module('Shared.TimeRange.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('TimeRange', function($baseModel) {

  /**
   * Constructor
   */
  function TimeRange(data) {

    //Initialize properties
    this.days = [];
    this.gap = 0;
    this.startTime = null;
    this.endTime = null;

    //Call parent constructor
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(TimeRange.prototype, $baseModel.prototype);

  /**
   * Check if empty
   */
  TimeRange.prototype.isEmpty = function() {
    return (this.days.length === 0 && !this.startTime && !this.endTime);
  };

  /**
   * Fill days
   */
  TimeRange.prototype.fillDays = function() {
    this.days = [1, 2, 3, 4, 5, 6, 7];
  };

  /**
   * Fill time
   */
  TimeRange.prototype.fillTime = function() {
    this.startTime = 0;
    this.endTime = 24 * 60;
  };

  /**
   * Check if contains a certain date only
   */
  TimeRange.prototype.containsDate = function(date) {
    const day = date.isoWeekday();
    return this.containsDay(day);
  };

  /**
   * Check if contains a certain day only
   */
  TimeRange.prototype.containsDay = function(day) {
    return this.days.includes(day);
  };

  /**
   * Check if a given day of the week and time are in this time range
   */
  TimeRange.prototype.containsDayAndTime = function(day, time) {
    if (this.containsDay(day)) {
      return (this.startTime <= time && this.endTime > time);
    }
    return false;
  };

  /**
   * Check if a given date and time are in this time range
   */
  TimeRange.prototype.containsDateAndTime = function(date, time) {

    //Use date time if none given
    if (time === null || typeof time === 'undefined') {
      time = date.getTime();
    }

    //Determine day of the week
    const day = date.isoWeekday();

    //Use other helper
    return this.containsDayAndTime(day, time);
  };

  /**
   * Check if a given time is a valid start time within time range
   */
  TimeRange.prototype.isValidStartTime = function(time, duration) {
    for (let i = this.startTime; i <= this.endTime; i += duration) {
      if (i === time) {
        return true;
      }
      if (this.gap) {
        i += this.gap;
      }
    }
    return false;
  };

  /**
   * Get start time, constrained by a min time
   */
  TimeRange.prototype.getStartTime = function(minTime, duration) {

    //Remember start/end times
    let {startTime, endTime, gap} = this;

    //Min time greater than end time?
    if (minTime > endTime) {
      return minTime;
    }

    //Find new start time
    while (startTime < minTime) {
      startTime += (duration + gap);
    }

    //Overflown? Backtrack one duration, unless at very beginning
    if (startTime > minTime && startTime !== this.startTime) {
      startTime -= (duration + gap);
    }

    //Return
    return startTime;
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Combine multiple time ranges into one for a given day of the week
   */
  TimeRange.combine = function(timeRanges, date, minTime, duration) {

    //Nothing to do?
    if (!timeRanges || timeRanges.length === 0) {
      return null;
    }

    //Get day of week and initialize new start/end times
    const dayOfWeek = date.isoWeekday();
    let startTime, endTime;

    //Loop time ranges
    timeRanges.forEach(range => {
      if (range.days.indexOf(dayOfWeek) > -1) {

        //Get first allowed start time for the range
        const rangeStartTime = range.getStartTime(minTime, duration);

        //Compare
        if (!startTime || rangeStartTime < startTime) {
          startTime = rangeStartTime;
        }
        if (!endTime || range.endTime > endTime) {
          endTime = range.endTime;
        }
      }
    });

    //Validate
    if (
      startTime === null || startTime === undefined ||
      endTime === null || endTime === undefined ||
      startTime > endTime
    ) {
      return null;
    }

    //Return as combined new time range
    return new TimeRange({startTime, endTime});
  };

  /**
   * Get a full time range
   */
  TimeRange.full = function() {
    return new TimeRange({
      days: [1, 2, 3, 4, 5, 6, 7],
      startTime: 0,
      endTime: (24 * 60) - 1,
    });
  };

  //Return
  return TimeRange;
});
