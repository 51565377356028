
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.XeroSignup', [
  'App.Portal.XeroSignup.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('xeroSignup', {
    parent: 'portal',
    url: '/xerosignup',
    component: 'xeroSignupRoute',
  });
})

/**
 * Route component
 */
.component('xeroSignupRoute', {
  controller: 'XeroSignupCtrl',
  templateUrl: 'portal/xero-signup-club/xero-signup.html',
  bindings: {},
});
