
/**
 * Module definition and dependencies
 */
angular.module('Shared.Event.Part.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('EventPart', function($baseModel) {

  /**
   * Defaults
   */
  const defaultData = {
    startDate: null,
    endDate: null,
  };

  /**
   * Constructor
   */
  function EventPart(data) {

    //Base model constructor
    $baseModel.call(this, angular.extend({}, defaultData, data || {}));

    //Duration property
    Object.defineProperty(this, 'duration', {
      get() {
        if (!this.startDate || !this.endDate) {
          return 0;
        }
        return this.endDate.diff(this.startDate, 'minutes');
      },
    });

    //Start time virtual property
    Object.defineProperty(this, 'startTime', {
      get() {
        if (!this.startDate) {
          return null;
        }
        return this.startDate.getTime();
      },
      set(time) {
        if (this.startDate) {
          this.startDate.setTime(time);
          if (this.endTime !== null) {
            this.endDate = this.startDate.clone().setTime(this.endTime);
          }
        }
      },
    });

    //End time virtual property
    Object.defineProperty(this, 'endTime', {
      get() {
        if (!this.endDate) {
          return null;
        }
        return this.endDate.getTime(true);
      },
      set(time) {
        if (time === 0) {
          time = 1440;
        }
        if (this.startDate) {
          this.endDate = this.startDate.clone().setTime(time);
        }
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(EventPart.prototype, $baseModel.prototype);

  //Return
  return EventPart;
});
