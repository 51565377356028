
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register', [
  'App.Portal.Register.Base.Controller',
  'App.Portal.Register.Intro',
  'App.Portal.Register.Conditions',
  'App.Portal.Register.OAuth',
  'App.Portal.Register.Details',
  'App.Portal.Register.SecondaryMembers',
  'App.Portal.Register.Memberships',
  'App.Portal.Register.MembershipConditions',
  'App.Portal.Register.CustomFields',
  'App.Portal.Register.Credentials',
  'App.Portal.Register.PaymentMethod',
  'App.Portal.Register.PaymentSummary',
  'App.Portal.Register.PaymentRedirect',
  'App.Portal.Register.Confirmation',
  'App.Portal.Register.Done',
  'App.Portal.Register.AddSecondaryMember.Modal',
  'App.Portal.Register.AddMembership.Modal',
])

/**
 * Config
 */
.config($stateProvider => {

  //State definition
  $stateProvider.state('register', {
    parent: 'portal',
    abstract: true,
    template: '<ui-view/>',
    url: '/register',
    params: {
      error: null,
      provider: null,
      workspace: null,
      reason: null,
    },
    resolve: {
      isKiosk(Kiosk) {
        return Kiosk.isEnabled();
      },
      activities($store) {
        return $store.activities.query();
      },
      customFields($store) {
        return $store.customFields
          .query({model: 'Member', isEditable: true, isApiOnly: false});
      },
      memberships(Membership) {
        return Membership
          .query({isArchived: false})
          .then(data => data.memberships);
      },
      payment(PaymentFlow, PaymentTypes) {
        return new PaymentFlow(PaymentTypes.REGISTRATION);
      },
      outcome(PaymentOutcome) {
        return PaymentOutcome.check();
      },
      registration(RegistrationFlow, club, memberships, payment, customFields) {
        return new RegistrationFlow(club, memberships, payment, customFields);
      },
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, $http, $log, Auth, Page, Domain, Config) => {

  //Guard against being logged in while going to registration
  $transitions.onBefore({
    to: 'register.intro',
  }, () => {
    if (Auth.isAuthenticated()) {
      $log.log(`Already logged in, logging out passively`);
      Auth.logoutPassively();
    }
  });

  //Guard against entering registration on a generic domain with no club
  $transitions.onBefore({
    to: 'register.*',
  }, transition => {
    if (Config.env !== 'dev') {
      if (Domain.isGeneric && !$http.defaults.headers.common['X-Club']) {
        $log.log(`On generic domain and no club headers present, redirecting to login`);
        return transition.router.stateService.target('login');
      }
    }
  });

  //Guard against going to the next steps in the registration
  //from somewhere else
  $transitions.onBefore({
    to: 'register.*',
  }, transition => {

    //Get params
    const to = transition.to();
    const from = transition.from();
    const params = transition.params();
    const allowed = [
      'register.intro', 'register.oauth', 'register.paymentRedirect',
    ];

    //If going to allowed state or coming from another registration state, all good
    if (from.name.match(/^register/) || allowed.includes(to.name)) {
      return;
    }

    //If going to details and we have oAuth profile data, all good
    if (to.name === 'register.details' && params.profile) {
      return;
    }

    //Go to first step
    return transition.router.stateService.target('register.intro', params);
  });

  //Entry guard for when already registered
  $transitions.onEnter({
    to: 'register.*',
  }, async(transition) => {

    //Get params
    const to = transition.to();
    const registration = await transition.injector().getAsync('registration');

    //If registration is already complete, don't allow to go back
    if (registration.hasRegistered && !to.name.match(/(payment|done)/)) {
      if (registration.requiresPayment && registration.payment.hasMethods) {
        return transition.router.stateService.target('register.paymentMethod');
      }
      else {
        return transition.router.stateService.target('register.done');
      }
    }
  });

  //Entry guard to remove oauth errors
  $transitions.onEnter({
    to: 'register.oauth',
  }, async(transition) => {

    //Get data
    const params = transition.params();
    const to = transition.to();
    const from = transition.from();

    //If navigating to oauth from the details step
    if (to.name === 'register.oauth' && from.name === 'register.details'
        && params.error) {

      //Reset params
      const newParams = angular.extend({}, params, {
        error: null, provider: null, reason: null, workspace: null,
      });
      return transition.router.stateService.target('register.oauth', newParams);
    }
  });

  //Success handler
  $transitions.onSuccess({
    to: 'register.*',
  }, transition => {

    //Set page title
    Page.setTitle('Register');

    //Get params
    const to = transition.to();
    const registration = transition.injector().get('registration');
    const step = to.name.replace(/register\./, '');

    //Set the registration step
    registration.setStep(step);
  });
});
