
/**
 * Module definition and dependencies
 */
angular.module('Shared.AgreeToTerms.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('agreeToTerms', {
    templateUrl: 'modals/agree-to-terms.html',
    controller: 'ModalAgreeToTermsCtrl',
    closeOnClick: false,
    closeOnEsc: false,
  });
})

/**
 * Controller
 */
.controller('ModalAgreeToTermsCtrl', function(
  $controller, $modal, $modalInstance, Interface, Auth
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.hasAgreed = false;
    this.isErrorAgreement = false;
  };

  /**
   * Toggle agreed
   */
  this.toggleAgreed = function(hasAgreed) {
    this.hasAgreed = hasAgreed;
    this.isErrorAgreement = false;
  };

  /**
   * Decline
   */
  this.close = this.cancel = this.decline = function() {
    const {title, contact} = this;
    $modal
      .open('basic', {
        templateUrl: 'modals/must-agree-to-terms.html',
        locals: {title, contact},
        closeOnClick: false,
        closeOnEsc: false,
      })
      .result
      .then(() => Auth.logout());
  };

  /**
   * Submit agreement
   */
  this.agree = function() {

    //Not agreed
    if (!this.hasAgreed) {
      this.isErrorAgreement = true;
      Interface.scrollIntoView('ModalBottom', {top: 1, topOffset: -16});
      return;
    }

    //Mark as agreeing and reset error flag
    this.isErrorAgreement = false;
    this.isAgreeing = true;
    this.isError = false;

    //Get data
    const {hasAgreed} = this;

    //Call handler
    this
      .handler(hasAgreed)
      .then(() => $modalInstance.close())
      .catch(() => this.isError = true)
      .finally(() => this.isAgreeing = false);
  };
});
