
/**
 * Module definition and dependencies
 */
angular.module('Shared.Tick.Filter', [])

/**
 * Filter definition
 */
.filter('tick', function() {
  return function(bool) {
    return bool ?
      '<i class="Icon color-success">check</i>' :
      '<i class="Icon color-danger">close</i>';
  };
});
