/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.AddSecondaryMember.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addSecondaryMember', {
    templateUrl: 'portal/register/modals/add-secondary-member.html',
    controller: 'ModalAddSecondaryMemberCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalAddSecondaryMemberCtrl', function(
  $controller, $modalInstance, Genders
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Set available genders
    this.genders = Genders.filter(gender => gender.value !== 'unknown');

    //Set flags
    this.isSaving = false;
    this.isError = false;
    this.clearInput = 0;

    //Extract model
    this.model = this.member.extract([
      'firstName', 'lastName', 'gender', 'dob', 'email',
      'phone', 'mobile', 'address', 'postalAddress',
      'directory', 'isSameHousehold',
    ]);

    //Set initial address/phone number
    this.setPhoneAndAddress();
  };

  /**
   * Toggle the same household checkbox
   */
  this.toggleSameHousehold = function(isSameHousehold) {
    this.model.isSameHousehold = isSameHousehold;
    this.setPhoneAndAddress();
  };

  /**
   * Set phone and address values depending on same household checkbox
   */
  this.setPhoneAndAddress = function() {

    //Get data
    const {model, primaryMember} = this;

    //Check house hold status
    if (model.isSameHousehold) {
      model.phone = primaryMember.phone;
      if (primaryMember.address) {
        model.address = primaryMember.address.clone();
      }
      if (primaryMember.postalAddress) {
        model.postalAddress = primaryMember.postalAddress.clone();
      }
    }
    else {
      model.phone = null;
      model.address = null;
      model.postalAddress = null;
      this.clearInput++; //NOTE: Small hack to get the address field to clear
    }
  };

  /**
   * Copy a property from the primary member
   */
  this.copy = function(property) {
    this.model[property] = this.primaryMember[property];
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Use save handler
    this.handler(this.model);
    this.close();
  };
});
