
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.TrialEnded.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('trialEnded', {
    templateUrl: 'admin/club/modals/trial-ended.html',
    controller: 'ModalTrialEndedCtrl',
    closeOnClick: false,
    resolve: {
      club($store) {
        'ngInject';
        return $store.club.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalTrialEndedCtrl', function(
  $controller, $modalInstance, $state
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Continue
   */
  this.continue = function() {
    $state.go('admin.club', {openCard: 'account'});
    this.close();
  };
});
