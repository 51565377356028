
/**
 * Module definition and dependencies
 */
angular.module('Shared.Resource.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('resource', {
    model: 'Resource',
    params: {
      id: '@id',
      assigneeId: '@assigneeId',
    },
    actions: {
      query: {
        method: 'GET',
        dataKey: 'resources',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
      deleteCustomFile: {
        url: 'file/:field/:filename',
        method: 'DELETE',
      },
      createAssignee: {
        url: 'assignee',
        method: 'POST',
      },
      patchAssignee: {
        url: 'assignee/:assigneeId',
        method: 'PATCH',
      },
      deleteAssignee: {
        url: 'assignee/:assigneeId',
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('resources', {
    model: 'Resource',
    dataKey: 'resources',
  });
})

/**
 * Model definition
 */
.factory('Resource', function(
  $api, $baseModel, Intercom, $sync, $injector,
  $httpParamSerializer, Config
) {

  /**
   * Constructor
   */
  function Resource(data) {

    //Parent constructor
    $baseModel.call(this, data);

    //Status icon
    Object.defineProperty(this, 'icon', {
      get() {
        if (this.hasExpired) {
          return 'block';
        }
        if (this.isExpiring) {
          return 'schedule';
        }
        if (this.isAssigned) {
          return 'person';
        }
        return 'crop_free';
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Resource.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Resource.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Members and invited members
    this.convertToModel('assignees', 'ResourceAssignee', true);

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Resource.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Strip data
    json.member = $baseModel.onlyId(json.member);

    //Return JSON
    return json;
  };

  /**
   * Clone
   */
  Resource.prototype.clone = function(stripId) {
    const clone = $baseModel.prototype.clone.call(this, stripId);

    //Strip unneeded data and virtuals
    delete clone.club;
    delete clone.customFiles;
    delete clone.status;
    delete clone.hasExpired;
    delete clone.isExpiring;
    delete clone.isAssigned;
    delete clone.activeAssignee;
    delete clone.createdAt;
    delete clone.updatedAt;

    //Empty assignees array
    clone.assignees = [];

    //Return the clone
    return clone;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Get custom file download URL
   */
  Resource.prototype.getCustomFileUrl = function(field) {

    //Get data
    const {id, customFiles} = this;
    const {name} = customFiles[field];
    const Auth = $injector.get('Auth');
    const token = Auth.getAccessToken();
    const qs = $httpParamSerializer({access_token: token});
    const base = Config.api.baseUrl;

    //Build URL
    return `${base}/resource/${id}/file/${field}/${name}?${qs}`;
  };

  /**
   * Save
   */
  Resource.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.resource[method](data).then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Resource.prototype.delete = function() {
    return $api.resource
      .delete(null, this)
      .then(() => this);
  };

  /**
   * Delete custom file
   */
  Resource.prototype.deleteCustomFile = function(field, filename) {
    return $api.resource
        .deleteCustomFile({field, filename}, this)
        .then(() => this.customFiles[field] = null);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Resource.query = function(filter) {
    return $api.resource.query(filter);
  };

  /**
   * Export
   */
  Resource.export = function(filter) {
    Intercom.event('Exported resources');
    return $sync.get('resource/export/csv', filter, 'Exporting...');
  };

  /**
   * Find by ID
   */
  Resource.findById = function(id) {
    return $api.resource.get({id});
  };

  /**************************************************************************
   * Resource assignee instance methods
   ***/

  /**
   * Add assignee directly to a resource
   */
  Resource.prototype.createAssignee = function(assignee) {
    const {id} = this;
    return $api.resource
      .createAssignee({id}, assignee)
      .then(data => this.fromJSON(data));
  };

  /**
   * Patch assignee
   */
  Resource.prototype.patchAssignee = function(assigneeId, assignee) {
    const {id} = this;
    const {startDate, endDate, isActive, meta} = assignee;
    return $api.resource
      .patchAssignee({id, assigneeId}, {startDate, endDate, isActive, meta})
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete assignees from resource
   */
  Resource.prototype.deleteAssignee = function(assignee) {
    const {id} = this;
    const assigneeId = assignee.id;
    return $api.resource
      .deleteAssignee({id, assigneeId})
      .then(() => {
        const i = this.assignees.indexOf(assignee);
        if (i !== -1) {
          this.assignees.splice(i, 1);
        }
      });
  };

  //Return
  return Resource;
});
