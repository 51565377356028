
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Edit.Term.Card', [])

/**
 * Component
 */
.component('cardMembershipEditTerm', {
  templateUrl: 'admin/membership/cards/term.html',
  controller: 'CardMembershipEditTermCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    membership: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipEditTermCtrl', function(
  MembershipLimitations, Durations
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;
    this.MembershipLimitations = MembershipLimitations;
    this.Durations = Durations;

    //Create model subset
    this.model = this.membership.extract([
      'term', 'isUnlimited',
    ]);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {

    //Determine target
    target = target || this.model;
    target[property] = value;

    //Is limited flag
    if (property === 'isUnlimited') {
      if (!value) {
        this.model.term = this.model.term || {};
        if (!this.model.term.unit) {
          this.model.term.unit = 'years';
        }
        if (!this.model.term.amount) {
          this.model.term.amount = 1;
        }
      }
      else {
        this.model.term = null;
        this.model.anchor = null;
        this.model.isRenewable = false;
        this.model.isAnchored = false;
        this.model.isProRated = false;
        this.model.allowPayLater = false;
        this.model.sendReminder = false;
      }
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {membership, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {membership, model, isEdit}})
      .then(() => this.form.$setPristine())
      .finally(() => this.isSaving = false);
  };
});
