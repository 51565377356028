
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.SignupClub.Type', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('signupClub.type', {
    url: '/type',
    component: 'signupTypeRoute',
    params: {
      profile: null,
    },
  });
})

/**
 * Route component
 */
.component('signupTypeRoute', {
  controller: 'SignupClubTypeCtrl',
  templateUrl: 'portal/signup-club/steps/type.html',
  bindings: {
    club: '<',
    countries: '<',
    transition: '<',
  },
})

/**
 * Controller
 */
.controller('SignupClubTypeCtrl', function(
  $state, $timeout, Config, ClubTypes, ClubRegexes, ActivityTypes,
  GlobalScope, $http, ExistsError, $modal, $store
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set club types & activity
    this.ClubTypes = ClubTypes;

    //Category options
    this.categoryOptions = [
      {
        value: 'other',
        label: 'Association / Business',
      },
      {
        value: 'sport',
        label: 'Sports / Games',
      },
      {
        value: 'arts',
        label: 'Arts',
      },
      {
        value: 'venue',
        label: 'Venue / Events',
      },
    ];

    //Number of members choices
    this.approxMembersOptions = [
      `0-50`,
      `50-100`,
      `100-300`,
      `300-500`,
      `500-1000`,
      `1000-2500`,
      `2500-5000`,
      `5000+`,
      `Not sure yet`,
    ];

    //Hello club test
    if ((Config.env === 'dev' || this.club.isHc) && !this.club.xeroAppStoreStateId) {
      const i = Math.floor(Math.random() * 1000) + 1;
      if (Config.env !== 'dev') {
        this.namePattern = /^hello\sclub\stest\s[A-Za-z0-9\s-]+/i;
      }
      this.club.name = `Hello Club Test ${i}`;
      this.club.phone = `0800 123 456`;
      this.club.approxMembers = `100-300`;
    }
    else {
      this.namePattern = /^((?!hello\sclub).)*$/i;
    }

    //Get social media profile
    const {profile} = this.transition.params();

    //Apply profile
    if (profile) {
      this.applyProfile(profile);
    }

    //Set country if present, otherwise set default and then geo locate
    if (this.club.countryCode) {
      this.setCountry(this.club.countryCode);
    }
    else {
      this.setCountry('NZ');
      this.geoLocate();
    }

    //Filter activities
    this.filterActivities();

    //Start faded
    this.fade = true;
    $timeout(() => this.fade = false);
  };

  /**
   * Apply profile
   */
  this.applyProfile = function(profile, isConfirmed = false) {

    //Get data from profile
    const {email} = profile;
    const firstName = profile.firstName || 'Unknown';
    const lastName = profile.lastName || 'Unknown';
    const password = '';

    //Apply profile
    this.club.contact = {
      firstName, lastName, email, profile, password,
    };

    //Flags
    this.isBusy = true;
    this.fade = true;

    //Initiate club sign up
    return this.club
      .initiate(isConfirmed)
      .catch(error => {

        //Existing member found?
        if (error instanceof ExistsError) {

          //Get data
          const {club, appUrl} = error.data;

          //Show modal
          $modal
            .open('basic', {
              templateUrl: 'portal/signup-club/modals/member-exists.html',
              locals: {club, appUrl},
              rejectOnDismissal: true,
            })
            .result
            .then(() => this.continue(true))
            .catch(() => $store.club.instance = null);
          return;
        }
      })
      .finally(() => {
        this.isBusy = false;
        this.fade = false;
      });
  };

  /**
   * Check club name
   */
  this.checkName = function() {

    //No name
    if (!this.club.name) {
      return;
    }

    //Get chosen type and find match by club name
    const match = ClubRegexes.find(item => this.club.name.match(item.regex));

    //If match found, set data
    if (match) {
      if (match.background) {
        this.setBackground(match.background);
      }
      if (match.activity) {
        const activity = ActivityTypes.find(a => a.type === match.activity);
        if (activity) {
          this.setActivity(activity);
        }
      }
    }
  };

  /**
   * Geo locate
   */
  this.geoLocate = function() {

    //Geolocating
    this.isGeoLocating = true;

    //Lookup IP
    $http
      .get('https://extreme-ip-lookup.com/json/', {
        ignoreServerError: true,
        ignoreTimeoutError: true,
        skipAccessToken: true,
        headers: {
          'X-Club': undefined,
          'X-Hostname': undefined,
          'X-Version': undefined,
        },
      })
      .then(response => {
        if (response.data && response.data.countryCode) {
          this.setCountry(response.data.countryCode);
        }
      })
      .catch(() => {})
      .finally(() => this.isGeoLocating = false);
  };

  /**
   * Update club
   */
  this.updateClub = function(property, value) {
    this.club[property] = value;
  };

  /**
   * Filter activities
   */
  this.filterActivities = function() {
    this.activityOptions = ActivityTypes
      .filter(item => !item.category || item.category === this.club.category);
  };

  /**
   * Set type
   */
  this.setType = function(type) {

    //Set on club
    this.club.type = type;

    //Find in known types to set background
    const knownType = ClubTypes.find(type => type.value === type);
    if (knownType && knownType.background) {
      this.setBackground(knownType.background);
    }
  };

  /**
   * Set background
   */
  this.setBackground = function(background) {
    this.club.background = background;
    if (background) {
      GlobalScope.set({background});
    }
  };

  /**
   * Set category
   */
  this.setCategory = function(category) {

    //Set category
    this.club.category = category;

    //Filter available activities
    this.filterActivities();

    //Check if we have valid selected activity
    const {activity} = this.club;
    const isValid = this.activityOptions.some(item => item.type === activity);

    //No valid activity selected?
    if (!isValid) {

      //Avoid selecting "Other"
      if (this.activityOptions.length > 1) {
        this.setActivity(this.activityOptions[0]);
      }
      else {
        this.setActivity(null);
      }
    }
  };

  /**
   * Set activity
   */
  this.setActivity = function(activity = null) {

    //No activity
    if (activity === null) {
      this.club.activity = null;
      this.club.hasBookings = false;
      return;
    }

    //Get type and name
    const {type, category, background, hasBookings} = activity;

    //Create default activity
    this.club.activity = Object.assign({}, activity);
    this.club.activity.numAreas = hasBookings ? 3 : 0;

    //Remove redundant flags from activity data now
    delete this.club.activity.background;
    delete this.club.activity.hasBookings;

    //Check if has bookings
    if (!this.club.hasBookings) {
      this.club.hasBookings = !!hasBookings;
    }

    //Set category if different
    if (category && category !== this.club.category) {
      this.setCategory(category);
    }

    //Set background
    if (background) {
      this.setBackground(background);
    }

    //Clear activity name
    if (type === 'other') {
      this.club.activity.name = '';
    }
  };

  /**
   * Set country
   */
  this.setCountry = function(code) {

    //Get country
    const country = this.countries.find(country => country.code === code);
    const {timezone} = this.club;

    //Set in scope and update country code on club
    this.country = country;
    this.club.countryCode = code;

    //Determine timezone, with fallback
    if (!timezone || !country.timezones.includes(timezone)) {
      this.determineTimezone(country.timezones[0]);
    }
  };

  /**
   * Try to determine timezone
   */
  this.determineTimezone = function(fallback) {

    //Auto detect timezone
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (this.country.timezones.includes(timezone)) {
        return this.setTimezone(timezone);
      }
    }
    catch (error) {
      //Pass through
    }

    //Set fallback
    this.setTimezone(fallback);
  };

  /**
   * Set timezone
   */
  this.setTimezone = function(timezone) {
    this.club.timezone = timezone;
  };

  /**
   * Continue
   */
  this.continue = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Double check that a name has been entered
    if (!this.club.name) {
      return;
    }

    //Flags
    this.isBusy = true;
    this.fade = true;

    //Go to next step
    $timeout(() => {
      $state.go('signupClub.details');
    }, 200);
  };

  /**
   * Back to previous step
   */
  this.back = function() {
    this.fade = true;
    $timeout(() => {
      if (this.club.contact.profile) {
        //Reset oauth related params
        $state.go('signupClub.oauth', {
          error: null, provider: null, reason: null,
        });
      }
      else {
        $state.go('signupClub.contact');
      }
    }, 200);
  };
});
