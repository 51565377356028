
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Restrictions.Card', [])

/**
 * Component
 */
.component('cardRestrictions', {
  templateUrl: 'admin/activity/cards/restrictions.html',
  controller: 'CardRestrictionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    activity: '<',
    system: '<',
    memberships: '<',
    prop: '@',
    type: '@',
    singular: '@',
    plural: '@',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardRestrictionsCtrl', function($modal, Restriction) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flags
    this.isSaving = false;
    this.checkFlags();
  };

  /**
   * Check flags
   */
  this.checkFlags = function() {
    const {prop} = this;
    this.hasRestrictions = this.activity[prop].length > 0;
  };

  /**
   * Save activity on server
   */
  this.save = function(restrictions) {

    //Get data
    const {activity, prop} = this;
    const model = {[prop]: restrictions};

    //Save activity
    return this
      .onSave({$event: {activity, model, isEdit: true}})
      .then(() => this.checkFlags());
  };

  /**
   * Add restriction
   */
  this.add = function() {

    //Create restriction and define handler
    const restriction = new Restriction();
    const {activity, prop, type, plural} = this;
    const $ctrl = this;

    //Create copy of restrictions and define handler
    const restrictions = activity.extract(prop);
    const handler = function(model) {
      restriction.fromJSON(model);
      restrictions.push(restriction);
      return $ctrl.save(restrictions);
    };

    //Show modal
    $modal
      .open('editRestriction', {locals: {
        restriction, type, plural, activity, handler,
      }});
  };

  /**
   * Edit restriction
   */
  this.edit = function($event) {

    //Get restriction
    const {restriction} = $event;
    const {activity, prop, type, plural} = this;
    const $ctrl = this;

    //Find and validate index
    const index = activity[prop].findIndex(r => r === restriction);
    if (index === -1) {
      return;
    }

    //Create copy of restrictions and define handler
    const restrictions = activity.extract(prop);
    const handler = function(model) {
      restrictions[index].fromJSON(model);
      return $ctrl.save(restrictions);
    };

    //Show modal
    $modal
      .open('editRestriction', {
        locals: {
          restriction, type, plural, activity, handler, isEdit: true,
        },
      });
  };

  /**
   * Delete restriction
   */
  this.delete = function($event) {

    //Get restriction
    const {restriction} = $event;
    const {activity, memberships, prop, type} = this;
    const $ctrl = this;

    //Find and validate index
    const index = activity[prop].findIndex(r => r === restriction);
    if (index === -1) {
      return;
    }

    //Create copy of restrictions and define handler
    const restrictions = activity.extract(prop);
    const handler = function() {
      restrictions.splice(index, 1);
      return $ctrl.save(restrictions);
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'admin/activity/modals/confirm-delete-restriction.html',
        locals: {restriction, type, activity, memberships, handler},
      });
  };
});
