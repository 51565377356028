
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Edit.Controller', [])

/**
 * Controller
 */
.controller('AdminCouponTypeEditCtrl', function($controller, $state) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminCouponTypeCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Check if can edit
    this.canEdit = this.user.isAdmin();
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    $state.go('admin.couponType.overview');
  };

  /**
   * On saved
   */
  this.onSaved = function($event) {

    //Get couponType
    const {couponType} = $event;

    //Update page title
    this.page.setTitle(couponType.name);
  };
});
