
/**
 * Module definition and dependencies
 */
angular.module('Shared.Address.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('Address', function($baseModel) {

  /**
   * Constructor
   */
  function Address(data) {
    $baseModel.call(this, data);

    //Virtual parts property
    Object.defineProperty(this, 'parts', {
      get() {
        return this.makeParts();
      },
    });

    //Virtual parts property
    Object.defineProperty(this, 'partsLocation', {
      get() {
        return this.makeParts([
          'line1',
          'suburb',
          'city',
        ]);
      },
    });

    //If no formatted value, generate it
    if (!this.formatted) {
      this.generateFormatted();
    }
  }

  /**
   * Extend base model
   */
  angular.extend(Address.prototype, $baseModel.prototype);

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Make address parts
   */
  Address.prototype.makeParts = function(mask) {

    //Initialize parts
    const parts = [];
    const {line1, line2, suburb, city, postalCode, state, country} = this;

    //Include all parts by default
    if (!Array.isArray(mask) || mask.length === 0) {
      mask = [
        'line1', 'line2', 'suburb', 'city', 'postalCode', 'state', 'country',
      ];
    }

    //Add values
    if (line1 && mask.includes('line1')) {
      parts.push(line1);
    }
    if (line2 && mask.includes('line2')) {
      parts.push(line2);
    }
    if (suburb && suburb !== city && mask.includes('suburb')) {
      parts.push(suburb);
    }
    if (city && mask.includes('city')) {
      if (postalCode && mask.includes('postalCode')) {
        parts.push(`${city} ${postalCode}`);
      }
      else {
        parts.push(city);
      }
    }
    else if (postalCode && mask.includes('postalCode')) {
      parts.push(postalCode);
    }
    if (state && state !== city && mask.includes('state')) {
      parts.push(state);
    }
    if (country && mask.includes('country')) {
      parts.push(country);
    }

    //Return collected parts
    return parts;
  };

  /**
   * Generate formatted value
   */
  Address.prototype.generateFormatted = function() {
    const {line1, line2, suburb, city, country} = this;
    this.formatted = [line1, line2, suburb, city, country]
      .filter(item => !!item)
      .join(', ');
  };

  /**
   * Check if this address matches another
   * Empty fields are not compared, and this address is used as a base, so
   * if the address to compare against has more detail, it can still match.
   */
  Address.prototype.matches = function(address) {

    //No address
    if (!address) {
      return false;
    }

    //Get data
    const {line1, suburb, city, postalCode, state, country} = this;
    const check = {line1, suburb, city, postalCode, state, country};

    //Check each item
    for (const key in check) {
      if (check[key] && check[key] !== address[key]) {
        return false;
      }
    }
    return true;
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Get all address component names
   */
  Address.components = function() {
    return [
      'line1', 'line2',
      'suburb', 'city', 'postalCode',
      'state', 'country',
    ];
  };

  //Return
  return Address;
});
