
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.Booking.Card', [])

/**
 * Component
 */
.component('cardSettingsBooking', {
  templateUrl: 'admin/settings/cards/booking.html',
  controller: 'CardSettingsBookingCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    setupStep: '@',
    club: '<',
    user: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSettingsBookingCtrl', function($controller, ChangeThresholds) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type and change thresholds
    this.type = 'booking';
    this.setupStep = 'reviewBookingSettings';
    this.ChangeThresholds = ChangeThresholds;

    //Call parent init
    $base.$onInit.call(this);
  };
});
