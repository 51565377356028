
/**
 * Module definition and dependencies
 */
angular.module('Shared.AreaStates.Constant', [])

/**
 * Constant definition
 */
.constant('AreaStates', {
  MODE: 'mode',
  EVENT: 'event',
  EMPTY: 'empty',
  OVERRIDE: 'override',
  WARM_UP: 'warmUp',
});
