
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Edit.Controller', [])

/**
 * Controller
 */
.controller('AdminEventEditCtrl', function(
  $q, $controller, $state, $modal, $store, Config, Upload,
  EventInterest, Event, EventAttendee, Interface, $notice
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminEventCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {
    $base.$onInit.call(this);

    //Get params
    const {showAttendees, showComments, changeBanner} = this.transition.params();

    //Flags
    this.canEdit = this.user.hasRole('admin', 'eventManager');
    this.openCommentsCard = showComments;
    this.openBannerCard = changeBanner;
    this.openAttendeesCard = showAttendees;
    this.defaultBanner = `https://${Config.domains.images}/${Config.app.defaultBanner}`;

    //Initialize attendees
    this.attendees = [];

    //Count interested and load calendars
    this.countInterested();
    this.loadGoogleCalendars();
  };

  /**
   * On delete
   */
  this.onDeleted = function() {
    $state.go('admin.event.overview');
  };

  /**
   * On saved
   */
  this.onSaved = function($event) {

    //Get data
    const {event} = $event;

    //Update page title
    this.page.setTitle(event.name);
  };

  /**
   * Load items (comments)
   */
  this.loadItems = function(type) {
    return $store[type]
      .query({event: this.event.id})
      .then(items => this[type] = items);
  };

  /**
   * Update banner
   */
  this.updateBanner = function($event) {

    //Get data from event
    const {data} = $event;

    //Set new banner
    this.event.banner = data.banner;
    return $q.resolve();
  };

  /**
   * Upload banner
   */
  this.uploadBanner = function($event) {

    //Get file
    const {file} = $event;
    const {event} = this;
    const {baseUrl} = Config.api;

    //Upload
    return Upload.upload({
      url: `${baseUrl}/event/${event.id}/banner`,
      data: {
        banner: file,
      },
    });
  };

  /**
   * Delete banner
   */
  this.deleteBanner = function() {

    //Define handler
    const {event} = this;
    const handler = function() {
      return event.deleteBanner('series');
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/event/modals/confirm-delete-banner.html',
        locals: {event, handler},
      })
      .result;
  };

  /**
   * Reload after editing attendees
   */
  this.reload = function() {

    //Load attendees
    this.loadAttendees(true);

    //Reload event
    Event
      .findById(this.event.id)
      .then(event => this.event = event);
  };

  /**
   * Load attendees
   */
  this.loadAttendees = function(reset = false) {

    //Reset
    if (reset) {
      this.attendees = [];
      this.batchNumAttendees = 0;
    }

    //Create filter
    const event = this.event.id;
    const limit = 100;
    const offset = this.batchNumAttendees * limit;
    const filter = {event, limit, offset};

    //Load attendees
    return EventAttendee
      .query(filter)
      .then(data => {
        this.attendees = this.attendees.concat(data.attendees);
        this.totalNumAttendees = data.meta.total;
        this.batchNumAttendees++;
        this.hasMoreAttendees = (this.totalNumAttendees > this.attendees.length);
      });
  };

  /**
   * Load interested
   */
  this.loadInterested = function() {

    //Get event
    const event = this.event.id;

    //Load interested
    return $store.eventInterest
      .query({event})
      .then(interested => this.interested = interested);
  };

  /**
   * Load attendee and interested
   */
  this.loadAttendeesAndInterested = function() {
    return $q.all([
      this.loadAttendees(true),
      this.loadInterested(),
    ]);
  };

  /**
   * Count interested
   */
  this.countInterested = function() {
    EventInterest
      .count({event: this.event.id})
      .then(count => this.numInterested = count);
  };

  /**
   * Copy event link to clipboard
   */
  this.copyLinkToClipboard = function() {
    const {event} = this;
    const link = this.club.makeUrl(event.publicPath);
    Interface
      .copyToClipboard(link)
      .then(() => $notice.show(`Event link copied to clipboard`));
  };
});
