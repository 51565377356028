
/**
 * Module definition and dependencies
 */
angular.module('Shared.WeekdayPicker.Component', [])

/**
 * Week Days component
 */
.component('weekdayPicker', {
  template: `
    <button
      type="button"
      class="WeekDay"
      ng-repeat="day in $ctrl.days"
      ng-class="{'is-toggled': day.isToggled, 'is-disabled': day.isDisabled}"
      ng-click="$ctrl.toggleDay(day)"
    >
      {{day.label}}
    </button>
  `,
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    model: '<ngModel',
    mandatoryDays: '<',
    disallowedDays: '<',
    onChange: '&',
  },

  /**
   * Controller
   */
  controller(Weekdays) {

    //Helper vars
    const $ctrl = this;

    /**
     * Set weekdays service and create model
     */
    this.$onInit = function() {

      //Empty check
      this.ngModel.$isEmpty = function() {
        return !$ctrl.days.some(day => day.isToggled);
      };
    };

    /**
     * Change handler
     */
    this.$onChanges = function() {

      //Must be an array
      if (!Array.isArray(this.model)) {
        this.model = [];
      }

      //Create map of days
      this.days = Weekdays
        .map(day => {

          //Check flags
          const isMandatory = this.isMandatory(day);
          const isDisallowed = this.isDisallowed(day);
          const isDisabled = (isMandatory || isDisallowed);
          const isToggled = (
            isMandatory ||
            (!isDisallowed && this.model.includes(day.value))
          );

          //Return day object
          return {
            value: day.value,
            label: day.labelLetter,
            isDisabled,
            isToggled,
          };
        });

      //Check validity
      this.ngModel.$validate();
    };

    /**
     * Check if a day is mandatory
     */
    this.isMandatory = function(day) {

      //Get mandatory and disallowed days
      const {mandatoryDays} = this;

      //Disabled, because mandatory
      if (Array.isArray(mandatoryDays) && mandatoryDays.includes(day.value)) {
        return true;
      }

      //Not disabled
      return false;
    };

    /**
     * Check if a day is disallowed
     */
    this.isDisallowed = function(day) {

      //Get mandatory and disallowed days
      const {disallowedDays} = this;

      //Disallowed
      if (Array.isArray(disallowedDays) && disallowedDays.includes(day.value)) {
        return true;
      }

      //Not disabled
      return false;
    };

    /**
     * Toggle day
     */
    this.toggleDay = function(day) {

      //Mandatory days can't be toggled
      if (day.isDisabled) {
        return;
      }

      //Toggle the day
      day.isToggled = !day.isToggled;

      //Create map
      const days = this.days
        .filter(day => day.isToggled)
        .map(day => day.value);

      //Propagate days array
      if (this.onChange) {
        this.onChange({days});
      }
    };
  },
});
