
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.Edit.Info.Card', [])

/**
 * Component
 */
.component('cardResourceInfo', {
  templateUrl: 'admin/resource/cards/info.html',
  require: {
    card: '^^',
  },
  bindings: {
    resource: '<',
  },
})

/**
 * Controller
 */
.controller('CardResourceInfoCtrl', function() {});
