
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.Billing.Card', [])

/**
 * Component
 */
.component('cardMemberEditBilling', {
  templateUrl: 'admin/people/members/cards/billing.html',
  controller: 'CardMemberEditBillingCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    member: '<',
    isOwn: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberEditBillingCtrl', function(
  CardCloseReasons
) {

  /**
   * Init
   */
  this.$onInit = function() {

    //Extract model
    this.model = this.member.extract([
      'billing',
    ]);
  };

  /**
   * Update model
   */
  this.updateModel = function(key, value) {
    this.model.billing[key] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Extract data
    const {member, model} = this;
    const $event = {member, model, isEdit: true};

    //Save
    this
      .onSave({$event})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
