
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.Edit.Details.Card', [])

/**
 * Component
 */
.component('cardResourceEditDetails', {
  templateUrl: 'admin/resource/cards/details.html',
  controller: 'CardResourceEditDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    resource: '<',
    customFields: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardResourceEditDetailsCtrl', function(
  Durations, CardCloseReasons, Config, Upload, $modal
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;
    this.Durations = Durations;

    //Create model subset
    this.model = this.resource.extract([
      'name', 'description', 'term', 'fee',
      'stopsAutomatically', 'customFields',
    ]);

    //Custom fields object
    if (!this.model.customFields) {
      this.model.customFields = {};
    }
    //All custom fields must be defined if not set
    else {
      this.customFields.forEach(field => {
        const {prop} = field;
        if (typeof this.model.customFields[prop] === 'undefined') {
          this.model.customFields[prop] = '';
        }
      });
    }

    //Split in top/bottom fields
    this.customFieldsTop = this.customFields
      .filter(field => !field.isParagraph);
    this.customFieldsBottom = this.customFields
      .filter(field => field.isParagraph);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Upload file
   */
  this.uploadFile = function(field) {

    //Get data
    const {resource} = this;
    const {id} = resource;
    const {baseUrl} = Config.api;
    const {prop, label} = field;
    const title = `Upload ${label}`;

    //Upload handler
    const onUpload = file => Upload.upload({
      url: `${baseUrl}/resource/${id}/file/${prop}`,
      data: {file},
    });

    //Show modal
    $modal
        .open('uploadFile', {
          locals: {title, onUpload},
        })
        .result
        .then(data => this.resource.customFiles[prop] = data.file);
  };

  /**
   * Delete file
   */
  this.deleteFile = function(field) {

    //Get data
    const {resource} = this;
    const {prop} = field;
    const file = resource.customFiles[prop];

    //Create handler
    const handler = () => resource.deleteCustomFile(prop, file.name);

    //Show modal
    $modal
        .open('basic', {
          templateUrl: 'modals/confirm/confirm-delete-file.html',
          locals: {handler, file},
        });
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {

    //Determine target
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {resource, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {resource, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
