
/**
 * Module definition and dependencies
 */
angular.module('Shared.Xero.Service', [])

/**
 * Config
 */
.config($apiProvider => {
  $apiProvider.registerEndpoint('xero', {
    url: 'integration/xero',
    actions: {
      getOrganisation: {
        url: 'organisation',
        method: 'GET',
      },
      getAccounts: {
        url: 'accounts',
        method: 'GET',
        isArray: true,
      },
      getContacts: {
        url: 'contacts',
        method: 'GET',
        isArray: true,
      },
      getStatus: {
        url: 'status',
        method: 'GET',
      },
      createAccount: {
        url: 'accounts',
        method: 'POST',
      },
      createContact: {
        url: 'contacts',
        method: 'POST',
      },
      sync: {
        url: 'sync',
        method: 'POST',
      },
      reset: {
        url: 'reset',
        method: 'POST',
      },
      fromAppStore: {
        url: 'fromAppStore',
        method: 'POST',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Xero', function($api, $window) {

  //Create service
  const Xero = {

    /**
     * Request token
     */
    requestToken() {
      return $api.xero.requestToken();
    },

    /**
     * Get organisation
     */
    getOrganisation() {
      return $api.xero.getOrganisation();
    },

    /**
     * Get accounts
     */
    getAccounts() {
      return $api.xero.getAccounts();
    },

    /**
     * Get contacts
     */
    getContacts() {
      return $api.xero.getContacts();
    },

    /**
     * Get status
     */
    getStatus() {
      return $api.xero.getStatus();
    },

    /**
     * Create account
     */
    createAccount(data) {
      return $api.xero.createAccount(data);
    },

    /**
     * Create contact
     */
    createContact(data) {
      return $api.xero.createContact(data);
    },

    /**
     * Sync
     */
    sync() {
      return $api.xero.sync();
    },

    /**
     * Reset
     */
    reset(fromDate, toDate) {
      return $api.xero.reset({fromDate, toDate});
    },

    /**
     * Sign up
     */
    fromAppStore() {
      return $api.xero
        .fromAppStore()
        .then(data => {
          if (data.redirect) {
            return $window.location.href = data.redirect;
          }
          return this.fromJSON(data);
        });
    },
  };

  //Return service
  return Xero;
})

/**
 * Filter to get type name
 */
.filter('xeroTypeName', function() {
  return function(type) {
    switch (type) {
      case 'payment':
        return 'Payment';
      case 'invoice':
        return 'Invoice';
      case 'creditNote':
        return 'Credit note';
      default:
        return '';
    }
  };
})

/**
 * Filter to get deep link
 */
.filter('xeroDeepLink', function() {
  return function(id, type, shortCode) {

    //Deep linking base path
    const basePath = `https://go.xero.com/organisationlogin/default.aspx`;

    //Determine path
    let path;
    switch (type) {
      case 'payment':
        path = `/Bank/ViewTransaction.aspx?bankTransactionID=`;
        break;
      case 'invoice':
        path = `/AccountsReceivable/View.aspx?InvoiceID=`;
        break;
      case 'creditNote':
        path = `/AccountsPayable/ViewCreditNote.aspx?creditNoteID=`;
        break;
    }

    //Return full path
    return `${basePath}?shortcode=${shortCode}&redirecturl=${path}${id}`;
  };
});
