
/**
 * Module definition and dependencies
 */
angular.module('Shared.AddToGroups.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addToGroups', {
    templateUrl: 'modals/add-to-groups.html',
    controller: 'ModalAddToGroupsCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalAddToGroupsCtrl', function(
  $controller, $modal, $notice, $modalInstance
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set flags
    this.isConfirming = false;

    //Only non-dynamic groups
    this.groups = this.groups.filter(group => !group.isDynamic);

    //Create model
    this.model = {};

    //Load number of recipients if counter given
    if (this.counter) {
      this.countNumAdding();
    }
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isConfirming = true;

    //Use handler
    this
      .handler(this.model.groups)
      .then(result => $modalInstance.resolve(result))
      .catch(() => $notice.showError('Failed to add to groups'))
      .finally(() => this.isConfirming = false);
  };

  /**
   * Manage groups
   */
  this.manageGroups = function() {

    //Get model
    const {Model} = this;
    const singular = 'group';
    const plural = 'groups';

    //Open modal
    $modal
      .open('manageCategories', {locals: {
        Model, singular, plural,
      }})
      .result
      .then(groups => {

        //Call reloader
        if (this.reloader) {
          this.reloader();
        }

        //Update groups and initiate model if needed
        this.groups = groups.filter(group => !group.isDynamic);
        this.model.groups = this.model.groups || [];

        //Remove removed groups from model
        this.model.groups = this.model.groups
          .filter(id => this.groups.some(group => group.id === id));
      });
  };

  /**
   * Count number of members we are adding
   */
  this.countNumAdding = function() {

    //Flag as loading
    this.isLoading = true;

    //Count
    this
      .counter()
      .then(num => this.numAdding = num)
      .finally(() => this.isLoading = false);
  };
});
