
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.PauseSubscription.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('pauseSubscription', {
    templateUrl: 'admin/people/members/modals/pause-subscription.html',
    controller: 'ModalPauseSubscriptionCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalPauseSubscriptionCtrl', function(
  $controller, $modalInstance, moment
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.isConfirming = false;
    this.endDate = moment().endOf('day');
    this.startDate = this.endDate.clone().add(1, 'month');

    //Min and max dates
    this.minDate = this.subscription.startDate;
    this.maxDate = this.subscription.endDate || null;
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Get data
    const {endDate, startDate} = this;

    //Flag as confirming
    this.isConfirming = true;

    //Confirm
    this
      .handler(endDate, startDate)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isConfirming = false);
  };

  /**
   * Set end date
   */
  this.setEndDate = function(endDate) {
    this.endDate = endDate;
  };

  /**
   * Set start date
   */
  this.setStartDate = function(startDate) {
    this.startDate = startDate;
  };
});
