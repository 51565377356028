
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.VisitorLogSettings.Card', [])

/**
 * Component
 */
.component('cardVisitorLogSettings', {
  templateUrl: 'admin/club/cards/visitor-log-settings.html',
  controller: 'CardVisitorLogSettingsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    integrations: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardVisitorLogSettingsCtrl', function(
  $notice, $modal, CardCloseReasons, Settings, Interface
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;

    //Models
    this.visitorLog = Settings.extract('visitorLog');
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.visitorLog;
    target[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Create settings subset
    const {visitorLog} = this;
    const settings = {visitorLog};

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event: {settings}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };

  /**
   * Show QR code
   */
  this.showQRCode = function() {
    const url = this.club.visitorLogUrl;
    const title = `Visitor log`;
    $modal.open('basic', {
      templateUrl: 'admin/club/modals/qr-code.html',
      locals: {title, url},
    });
  };

  /**
   * Copy registration link to clipboard
   */
  this.copyLinkToClipboard = function() {
    Interface
      .copyToClipboard(this.club.visitorLogUrl)
      .then(() => $notice.show(`Visitor log link copied to clipboard`));
  };
});
