
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Report.Overview.Controller', [])

/**
 * Controller
 */
.controller('AdminReportOverviewCtrl', function(
  $state, moment, ReportTypes, Intercom
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Init
    this.type = null;

    //Periods
    this.basePeriod = {
      period: null,
      fromDate: null,
      toDate: null,
    };
    this.compPeriod = {
      period: null,
      fromDate: null,
      toDate: null,
    };

    //Predefined periods
    this.periods = [
      {
        label: moment().format('MMMM YYYY'),
        unit: 'month',
        diff: 0,
      },
      {
        isDefault: true,
        label: moment().subtract(1, 'month').format('MMMM YYYY'),
        unit: 'month',
        diff: -1,
      },
      {
        label: moment().subtract(2, 'month').format('MMMM YYYY'),
        unit: 'month',
        diff: -2,
      },
      {
        label: moment().subtract(3, 'month').format('MMMM YYYY'),
        unit: 'month',
        diff: -3,
      },
      {
        label: moment().subtract(4, 'month').format('MMMM YYYY'),
        unit: 'month',
        diff: -4,
      },
      {
        label: moment().subtract(5, 'month').format('MMMM YYYY'),
        unit: 'month',
        diff: -5,
      },
      {
        label: moment().subtract(6, 'month').format('MMMM YYYY'),
        unit: 'month',
        diff: -6,
      },
      {
        label: 'This week',
        unit: 'week',
        diff: 0,
      },
      {
        label: 'Last week',
        unit: 'week',
        diff: -1,
      },
      {
        label: moment().format('YYYY'),
        unit: 'year',
        diff: 0,
      },
      {
        label: moment().subtract(1, 'year').format('YYYY'),
        unit: 'year',
        diff: -1,
      },
      {
        label: moment().subtract(2, 'year').format('YYYY'),
        unit: 'year',
        diff: -2,
      },
    ];

    //Report types
    this.types = ReportTypes
      .filter(type => {
        if (type.isSystem) {
          return !!this.system;
        }
        if (type.isParentClub) {
          return this.club.hasChildClubs;
        }
        return true;
      });

    //Default activity
    const activity = this.activities.find(activity => activity.isDefault);
    if (activity) {
      this.activityId = activity.id;
    }

    //Set defaults
    const i = this.periods.findIndex(period => period.isDefault);
    this.setBasePeriod(i);
    this.setType(this.types[0].value);
  };

  /**
   * Set type
   */
  this.setType = function(type) {
    this.type = type;
  };

  /**
   * Set activity
   */
  this.setActivity = function(activityId) {
    this.activityId = activityId;
  };

  /**
   * Set from date
   */
  this.setFromDate = function(model, date) {
    model.period = null;
    model.fromDate = date;
    if (model.toDate && model.toDate.isBefore(model.fromDate)) {
      model.toDate = model.fromDate.clone();
    }
    if (model === this.basePeriod) {
      this.makeComparisonPeriods();
    }
  };

  /**
   * Set to date
   */
  this.setToDate = function(model, date) {
    model.period = null;
    model.toDate = date;
    if (model.fromDate && model.fromDate.isAfter(model.toDate)) {
      model.fromDate = model.toDate.clone();
    }
    if (model === this.basePeriod) {
      this.makeComparisonPeriods();
    }
  };

  /**
   * Filtered periods
   */
  this.makeComparisonPeriods = function() {

    //Get base data
    const {period, fromDate} = this.basePeriod;

    //Reset
    this.comparisonPeriods = [{
      label: 'None',
      unit: null,
      diff: null,
    }];

    //Custom period
    if (period === null) {
      return;
    }

    //Get unit
    const {unit, diff} = this.periods[period];

    //Create comparison periods
    if (unit === 'week') {
      this.comparisonPeriods.push({
        label: (diff === 0) ?
          'Last week' : String((diff - 1) * -1) + ' weeks ago',
        unit: 'week',
        diff: diff - 1,
      });
      this.comparisonPeriods.push({
        label: String((diff - 2) * -1) + ' weeks ago',
        unit: 'week',
        diff: diff - 2,
      });
      this.comparisonPeriods.push({
        label: String((diff - 3) * -1) + ' weeks ago',
        unit: 'week',
        diff: diff - 3,
      });
      this.comparisonPeriods.push({
        label: String((diff - 4) * -1) + ' weeks ago',
        unit: 'week',
        diff: diff - 4,
      });
    }
    if (unit === 'month') {
      for (let i = 1; i <= 12; i++) {
        this.comparisonPeriods.push({
          label: fromDate.clone().subtract(i, 'months').format('MMMM YYYY'),
          unit: 'month',
          diff: diff - i,
        });
      }
      this.comparisonPeriods.push({
        label: fromDate.clone().subtract(2, 'year').format('MMMM YYYY'),
        unit: 'month',
        diff: diff - 24,
      });
      this.comparisonPeriods.push({
        label: fromDate.clone().subtract(3, 'year').format('MMMM YYYY'),
        unit: 'month',
        diff: diff - 36,
      });
    }
    else if (unit === 'year') {
      this.comparisonPeriods.push({
        label: fromDate.clone().subtract(1, 'year').format('YYYY'),
        unit: 'year',
        diff: diff - 1,
      });
      this.comparisonPeriods.push({
        label: fromDate.clone().subtract(2, 'year').format('YYYY'),
        unit: 'year',
        diff: diff - 2,
      });
      this.comparisonPeriods.push({
        label: fromDate.clone().subtract(3, 'year').format('YYYY'),
        unit: 'year',
        diff: diff - 3,
      });
    }
  };

  /**
   * Helper to clear period
   */
  this.clearPeriod = function(model) {
    model.period = null;
    model.fromDate = null;
    model.toDate = null;
  };

  /**
   * Helper to set period
   */
  this.setPeriod = function(model, index, periods) {

    //Clear if needed
    if (index === null || periods.length === 0) {
      this.clearPeriod(model);
      return;
    }

    //Set index
    model.period = index;

    //Get data
    const {unit, diff} = periods[index];

    //Manipulate reference
    if (unit) {
      const yesterday = moment().subtract(1, 'day').endOf('day');
      const ref = moment();
      if (diff) {
        ref.add(diff, unit);
      }
      model.fromDate = ref.clone().startOf(unit);
      model.toDate = ref.clone().endOf(unit);
      if (model.toDate.isAfter(yesterday)) {
        model.toDate = yesterday;
      }
    }

    //No dates (e.g. "None")
    else {
      model.fromDate = null;
      model.toDate = null;
    }
  };

  /**
   * Set base period
   */
  this.setBasePeriod = function(index) {
    this.setPeriod(this.basePeriod, index, this.periods);
    this.clearPeriod(this.compPeriod);
    this.makeComparisonPeriods();
    this.setComparisonPeriod(0);
  };

  /**
   * Set comparison period
   */
  this.setComparisonPeriod = function(index) {
    this.setPeriod(this.compPeriod, index, this.comparisonPeriods);
  };

  /**
   * Generate report
   */
  this.generate = function() {

    //Validate
    this.form.$setSubmitted();
    if (!this.form.$valid) {
      return;
    }

    //Set flag
    this.isGenerating = true;

    //Get data
    const {type, basePeriod, compPeriod, activityId} = this;

    //Track in intercom
    Intercom.event('Generated a report');

    //Go to route
    $state
      .go(`admin.report.${type}`, {
        basePeriod, compPeriod, activityId,
      })
      .finally(() => this.isGenerating = false);
  };
});
