
/**
 * Module definition and dependencies
 */
angular.module('Shared.Event.Rule.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('EventRule', function(
  $baseModel, $filter, MembershipConstraints, Modules, EventRuleTypes,
  DateFormat
) {

  //Extract constants
  const {
    ALL, WITHOUT, WITH, SPECIFIC, SPECIFIC_WITHOUT, GUEST, GROUPS
  } = MembershipConstraints;
  const {FREE, COUPON, MEMBERSHIP} = EventRuleTypes;

  /**
   * Defaults
   */
  const defaultData = {
    type: FREE,
    constraint: ALL,
  };

  /**
   * Constructor
   */
  function EventRule(data, event) {
    $baseModel.call(this, angular.extend({}, defaultData, data || {}), event);

    //Fee label
    Object.defineProperty(this, 'feeLabel', {
      get() {

        //Get data
        const {fee, type, label} = this;

        //Coupon type
        if (type === COUPON) {
          return Modules.plural('coupons');
        }

        //Membership type
        if (type === MEMBERSHIP) {
          return `Membership benefit`;
        }

        //Free
        if (type === FREE) {
          return label || `Free entry`;
        }

        //Fee
        return $filter('currency')(fee || 0);
      },
    });

    //Fee label
    Object.defineProperty(this, 'seriesLabel', {
      get() {

        //Get data
        const {isSeries} = this;
        const {isRecurring, isMultiDay} = this.$parent;

        //Recurring
        if (isRecurring) {
          if (isSeries) {
            return `for whole event series`;
          }
          else {
            return `per individual event`;
          }
        }

        //Multi-day
        if (isMultiDay) {
          if (isSeries) {
            return `for whole event`;
          }
          else {
            return `per event part`;
          }
        }

        //No
        return '';
      },
    });

    //Validity label
    Object.defineProperty(this, 'validityLabel', {
      get() {

        //Get data
        const {validFrom, validTill} = this;
        const {standard} = DateFormat.formats;

        //Only start date
        if (validFrom && !validTill) {
          return `Valid from ${validFrom.format(standard)}`;
        }

        //Only end date
        if (!validFrom && validTill) {
          return `Valid until ${validTill.format(standard)}`;
        }

        //Both start and end dates
        if (validFrom && validTill) {
          return `Valid from ${validFrom.format(standard)} until ${validTill.format(standard)}`;
        }

        //No dates
        return 'Valid anytime';
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(EventRule.prototype, $baseModel.prototype);

  /**
   * Find matching guest rules
   */
  EventRule.prototype.findGuestRules = function() {
    return this.rules
      .filter(rule => rule.appliesToGuest());
  };

  /**
   * Check if fee applies to members with specific membership
   */
  EventRule.prototype.appliesToMemberships = function(memberships) {

    //Get data
    const {constraint, memberships: ids} = this;

    //Check
    if (constraint === GUEST) {
      return false;
    }
    if (constraint === ALL) {
      return true;
    }
    if (constraint === WITHOUT && memberships.length === 0) {
      return true;
    }
    if (constraint === WITH && memberships.length > 0) {
      return true;
    }
    if (memberships.length > 0) {
      return ids.some(id => memberships.some(m => m.id === id));
    }
    return false;
  };

  /**
   * Check if fee applies to members in specific groups
   */
  EventRule.prototype.appliesToGroups = function(groups) {

    //Get data
    const {constraint, groups: ids} = this;

    //Check
    if (constraint === GROUPS) {
      return ids.some(id => groups.some(m => m.id === id));
    }
    return false;
  };

  /**
   * Find rule for members
   */
  EventRule.prototype.appliesToMember = function() {
    return (
      this.constraint === ALL ||
      this.constraint === WITHOUT ||
      this.constraint === WITH ||
      this.constraint === SPECIFIC ||
      this.constraint === SPECIFIC_WITHOUT
    );
  };

  /**
   * Find rule for guests
   */
  EventRule.prototype.appliesToGuest = function() {
    return (this.constraint === GUEST || this.constraint === ALL);
  };

  //Return
  return EventRule;
});
