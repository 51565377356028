
/**
 * Module definition and dependencies
 */
angular.module('App.Account.Overview.Controller', [])

/**
 * Controller
 */
.controller('AccountOverviewCtrl', function(
  $q, $state, $location, $modal, $notice, Integration,
  Transaction, Payment, AccountCredit, Push
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Get data
    const {user} = this;

    //Initialize
    this.transactions = [];
    this.payments = [];
    this.accountCredits = [];
    this.accountCreditBalance = 0;

    //Create list of available members
    this.members = [user].concat(this.circleMembers);

    //Get initial member
    const member = this.determineInitialMember();

    //Set selected member
    this.setMember(member);

    //Mute transactions
    this.loadStripeIntegration();
    this.muteTransactions();

    //As user if they want to receive push notifications
    Push.ask(user, 'unpaidTransaction');
  };

  /**
   * Load stripe integration
   */
  this.loadStripeIntegration = function() {

    //TODO: Ideally, when we migrate to Vue, we can revisit how the cards are
    //managed, so that even if someone disconnects the Stripe integration after
    //having used it for a while, the member will be able to remove their
    //existing cards on file, instead of hiding the cards tab altogether.
    Integration
      .findByType('stripe')
      .then(integration => {
        if (integration && integration.isConnected) {
          this.hasStripeIntegration = true;
        }
      });
  };

  /**
   * Determine initial member
   */
  this.determineInitialMember = function() {

    //Check if valid circle member ID given in params
    const {member: memberId} = this.transition.params();
    if (memberId) {
      const member = this.circleMembers.find(m => m.id === memberId);
      if (member) {
        return member;
      }
    }

    //Return ourselves
    return this.user;
  };

  /**
   * Select member event handler
   */
  this.onSelectMember = function($event) {
    const {member} = $event;
    this.setMember(member);
  };

  /**
   * Set member
   */
  this.setMember = function(member) {
    this.member = member;
    this.isSelf = member === this.user;
    this.loadData();
  };

  /**
   * On payment handler
   */
  this.onPay = function($event) {

    //Get data
    const {transaction} = $event;
    const transactionIds = transaction ? [transaction.id] : [];

    //Redirect to payment page
    return $state.go('account.pay', {transactionIds});
  };

  /**
   * Transfer account credit handler
   */
  this.onTransfer = function() {

    //Get member
    const {member, user} = this;
    const isSelf = (member.id === user.id);
    const isOwn = true;

    //Define handler
    const handler = data => AccountCredit.transferOwn(member.id, data);

    //Open modal
    $modal
      .open('transferAccountCredit', {locals: {member, handler, isOwn, isSelf}})
      .result
      .then(balance => {
        $notice.show('Account credit transferred');
        this.accountCreditBalance = balance;
        this.loadData();
      });
  };

  /**
   * Mute transactions
   */
  this.muteTransactions = function() {

    //Get data
    const {mute} = $location.search();
    if (!mute) {
      return;
    }

    //Get transactions to mute
    const transactionId = (mute !== 'all') ? mute : null;
    const transactions = this.transactions
      .filter(trx => {
        if (transactionId) {
          return (trx.id === transactionId);
        }
        return (trx.isDue && !trx.isMuted);
      });

    //Any?
    if (transactions.length === 0) {
      return;
    }

    //Mute
    Transaction
      .mute(transactions)
      .then(() => {
        $modal.open('basic', {
          templateUrl: 'account/modals/payments-due-muted.html',
          locals: {model: {transactions}},
        });
      });
  };

  /**************************************************************************
   * Circles handling
   ***/

  /**
   * Make query filter
   */
  this.makeQueryFilter = function(member) {
    const {user} = this;
    const filter = {};
    if (member !== user) {
      filter.circleMemberId = member.id;
    }
    return filter;
  };

  /**
   * Load data
   */
  this.loadData = function() {

    //Flag as loading
    this.isLoading = true;
    const {member} = this;

    //Load
    $q
      .all([
        this.loadTransactions(member),
        this.loadPayments(member),
        this.loadAccountCredits(member),
        this.loadAccountCreditBalance(member),
      ])
      .finally(() => this.isLoading = false);
  };

  /**
   * Load the selected members transactions
   */
  this.loadTransactions = function(member) {

    //Clear
    this.transactions = [];

    //Create filter
    const filter = this.makeQueryFilter(member);

    //Query transactions
    return Transaction
      .own(filter)
      .then(transactions => this.transactions = transactions);
  };

  /**
   * Load the selected members payments
   */
  this.loadPayments = function(member) {

    //Clear
    this.payments = [];

    //Create filter
    const filter = this.makeQueryFilter(member);

    //Query payments
    return Payment
      .own(filter)
      .then(payments => this.payments = payments);
  };

  /**
   * Load the selected members account credit
   */
  this.loadAccountCredits = function(member) {

    //Clear
    this.accountCredits = [];

    //Create filter
    const filter = this.makeQueryFilter(member);

    //Query payments
    return AccountCredit
      .own(filter)
      .then(accountCredits => this.accountCredits = accountCredits);
  };

  /**
   * Load the selected members account credit
   */
  this.loadAccountCreditBalance = function(member) {

    //Clear
    this.accountCreditBalance = 0;

    //Create filter
    const filter = this.makeQueryFilter(member);

    //Load balance
    return AccountCredit
      .balanceOwn(filter)
      .then(balance => this.accountCreditBalance = balance);
  };
});
