
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.PastOrders.Card', [])

/**
 * Component
 */
.component('cardSystemPastOrders', {
  templateUrl: 'admin/system/cards/past-orders.html',
  controller: 'CardSystemPastOrdersCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    system: '<',
    tagOrders: '<',
  },
})

/**
 * Controller
 */
.controller('CardSystemPastOrdersCtrl', function(TagOrder) {

  /**
   * Init
   */
  this.$onInit = function() {
    this.loadTagOrders();
  };

  /**
   * Load tag orders
   */
  this.loadTagOrders = function() {
    this.isLoading = true;
    return TagOrder
      .query()
      .then(data => {
        this.tagOrders = data.tagOrders;
        this.hasOrders = data.tagOrders.length > 0;
      })
      .finally(() => this.isLoading = false);
  };
});
