
/**
 * Module definition and dependencies
 */
angular.module('Shared.Array.Join.Filter', [])

/**
 * Filter definitions
 */
.filter('join', function() {

  /**
   * Helper to convert item
   */
  function convert(item, prop) {
    if (typeof item === 'object') {
      if (item === null) {
        return '';
      }
      if (!prop) {
        if (typeof item.name !== 'undefined') {
          return item.name;
        }
        if (typeof item.label !== 'undefined') {
          return item.label;
        }
        return '';
      }
      if (typeof item[prop] === 'undefined') {
        return '';
      }
      return item[prop];
    }
    return item;
  }

  /**
   * Join filter
   */
  return function(array, glue = ', ', glueLast = null, prop = '') {

    //String, split into array
    if (typeof array === 'string') {
      array = array.split(',');
    }

    //Not an array or no items, return blank string
    if (!Array.isArray(array) || array.length === 0) {
      return '';
    }

    //Only one item
    if (array.length === 1) {
      return convert(array[0], prop);
    }

    //Fix up glue
    if (typeof glue !== 'string') {
      glue = '';
    }
    if (typeof glueLast !== 'string') {
      glueLast = glue;
    }

    //Create new array and pop last item
    const newArray = array.map(item => convert(item, prop));
    const last = newArray.pop();

    //Join up with glue
    return newArray.join(glue) + glueLast + last;
  };
});
