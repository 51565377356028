
/**
 * Module definition and dependencies
 */
angular.module('Shared.Integrations.Service', [])

/**
 * Service definition
 */
.factory('Integrations', function(Integration, $interval) {

  /**
   * Integrations provider class
   */
  class Integrations {

    /**
     * Constructor
     */
    constructor() {

      //Initialize
      this.integrations = [];

      //Load integrations
      this.load();

      //Re-load integrations on a regular basis
      $interval(() => this.load(), 60 * 1000);
    }

    /**
     * Load or reload integrations
     */
    load() {

      //Load integrations
      return Integration
        .query()
        .then(data => this.integrations = data.integrations);
    }

    /**
     * Get a filtered list of integrations
     */
    filter(category, connectedOnly = true) {
      return this.integrations
        .filter(integration => (!connectedOnly || integration.isConnected))
        .filter(integration => integration.category === category);
    }
  }

  //Return instance
  return new Integrations();
});

