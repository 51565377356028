
/**
 * Module definition and dependencies
 */
angular.module('Shared.OAuthProviders.Options', [])

/**
 * Constant definition
 */
.constant('OAuthProviders', [
  {
    id: 'google',
    name: 'Google',
  },
  {
    id: 'facebook',
    name: 'Facebook',
  },
]);
