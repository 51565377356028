
/**
 * Module definition and dependencies
 */
angular.module('Shared.Form.Directive', [])

/**
 * Directive
 */
.directive('form', function($focus, $timeout) {
  return {
    restrict: 'E',
    link(scope, element) {
      scope.$watch('$ctrl.form.$submitted', isSubmitted => {
        if (isSubmitted) {
          $timeout(() => {
            let fields = element[0].getElementsByClassName('has-error');
            if (fields.length) {
              $focus(fields[0], 0, true);
            }
          });
        }
      });
    },
  };
});
