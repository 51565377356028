
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.AddCoupon.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addCoupon', {
    templateUrl: 'admin/people/members/modals/add-coupon.html',
    controller: 'ModalAddCouponCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalAddCouponCtrl', function($controller, $modalInstance) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Initialize flags
    this.isSaved = false;
    this.isSaving = false;

    //Create model subset
    this.model = this.coupon.extract([
      'type',
    ]);

    //Meta data
    this.meta = {
      notifyByEmail: !!this.member.email,
      createTransaction: false,
      amount: '',
    };

    //Select first coupon type
    this.updateType(this.couponTypes[0]);
  };

  /**
   * Update type
   */
  this.updateType = function(type) {

    //Update type
    this.model.type = type.id;

    //Check if archived
    this.isTypeArchived = type.isArchived;

    //Update meta data
    this.meta.createTransaction = (type.price > 0);
    this.meta.amount = type.price;
  };

  /**
   * Toggle transaction
   */
  this.toggleTransaction = function(createTransaction) {
    this.meta.createTransaction = createTransaction;
  };

  /**
   * Toggle notification
   */
  this.toggleNotify = function(notifyByEmail) {
    this.meta.notifyByEmail = notifyByEmail;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Save
    this.isSaving = true;
    this
      .handler(this.model, this.meta)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isSaving = false);
  };
});
