
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.Purchase.Controller', [
  'App.Account.Base.Controller',
])

/**
 * Controller
 */
.controller('CouponPurchaseCtrl', function(
  $controller, $state, $modal, Coupon
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AccountBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Setup
   */
  this.setup = function() {

    //Initialize
    this.selection = [];
    this.paymentItems = [];

    //Set initial step
    this.stepDetails();
  };

  /**
   * Prepare payment
   */
  this.preparePayment = function() {

    //Prepare selection for payment data
    const selection = this.selection.map(({id, amount}) => ({
      couponTypeId: id,
      amount,
    }));

    //Create line items
    const lineItems = this.selection.map(type => ({
      label: type.name,
      suffix: `${type.amount}x`,
      amount: type.price * type.amount,
    }));

    //Set in payment flow
    this.payment.setLineItems(lineItems);
    this.payment.setExtraData({selection});
    this.payment.setRedirectPath(`coupons/purchase`);
  };

  /**
   * Set agreement
   */
  this.setAgreedToConditions = function($event) {
    this.hasAgreedToConditions = $event.hasAgreed;
  };

  /**
   * Select coupon types to purchase
   */
  this.selectCouponTypes = function($event) {

    //Extract coupon type selection from event
    const {selection} = $event;

    //Set data
    this.selection = selection;
    this.hasConditions = selection.some(type => type.conditions);
    this.hasAgreedToConditions = !this.hasConditions;
  };

  /**
   * Purchase without payment
   */
  this.onPurchaseWithoutPayment = function() {

    //Prepare selection for payment data
    const selection = this.selection.map(({id, amount}) => ({
      couponTypeId: id,
      amount,
    }));

    //Purchase
    return Coupon
      .purchase(selection)
      .then(() => this.onPurchased({}));
  };

  /**
   * Paid success handler
   */
  this.onPaid = function($event) {
    this.onPurchased($event);
  };

  /**
   * On purchased handler
   */
  this.onPurchased = function($event) {

    //Get data
    const {payment} = $event;
    const isPaid = (payment && payment.isPaid);
    const {club} = this;
    const num = this.selection.reduce((total, type) => total + type.amount, 0);

    //Success
    $modal
      .open('basic', {
        templateUrl: 'coupon/modals/purchased.html',
        locals: {club, num, isPaid},
      })
      .closed
      .then(() => $state.go('coupon.overview', {}, {reload: true}));
  };

  /**************************************************************************
   * Navigation
   ***/

  /**
   * Details step
   */
  this.stepDetails = function() {
    this.step = 'details';
  };

  /**
   * Agree to conditions step
   */
  this.stepAgreeConditions = function() {
    this.step = 'agreeConditions';
  };

  /**
   * No payment step
   */
  this.stepNoPayment = function() {
    this.step = 'noPayment';
  };

  /**
   * Next from details
   */
  this.nextFromDetails = function() {

    //Nothing selected
    if (this.selection.length === 0) {
      return;
    }

    //Got coupon conditions?
    if (this.hasConditions) {
      return this.stepAgreeConditions();
    }

    //Onwards
    this.nextFromConditions();
  };

  /**
   * Prev from conditions
   */
  this.prevFromConditions = function() {
    this.stepDetails();
  };

  /**
   * Next from conditions
   */
  this.nextFromConditions = function() {

    //Prepare payment
    this.preparePayment();

    //Only free coupons selected
    if (this.payment.lineItemTotal === 0) {
      return this.stepNoPayment();
    }

    //Check if can pay with credit
    if (this.payment.canUseAccountCredit) {
      return this.stepUseCredit();
    }

    //Onwards
    this.nextFromUseCredit();
  };

  /**
   * Prev from use account credit
   */
  this.prevFromUseCredit = function() {

    //Got coupon conditions?
    if (this.hasConditions) {
      return this.stepAgreeConditions();
    }

    //Backwards
    this.prevFromConditions();
  };

  /**
   * Prev from no payment
   */
  this.prevFromNoPayment = function() {
    this.prevFromUseCredit();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    $state.go('coupon.overview');
  };
});
