
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.Bookings.Card', [])

/**
 * Component
 */
.component('cardMemberBookings', {
  templateUrl: 'admin/people/members/cards/bookings.html',
  controller: 'CardMemberBookingsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    member: '<',
    user: '<',
    bookings: '<',
    activities: '<',
    hasMore: '<',
    onLoadMore: '&',
    onReload: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberBookingsCtrl', function(
  $modal, $notice, $store, ErrorCodes, CouponTypeUses
) {

  /**
   * On init
   */
  this.$onInit = function() {
    this.isSaving = false;
    this.hasAny = (this.bookings.length > 0);
  };

  /**
   * View booking
   */
  this.view = function($event) {

    //Get data
    const {booking} = $event;
    const {activities} = this;
    const showActivity = this.activities.length > 1;
    const activity = activities.find(a => a.isSame(booking.activity.id));

    //Open modal
    $modal
      .open('viewBooking', {locals: {
        booking, activity, showActivity, canManageBookings: true,
      }})
      .result;
  };

  /**
   * Pay with coupons
   */
  this.payWithCoupons = function($event) {

    //Get data
    const {booking} = $event;
    const {members, activity, mode, coupons} = booking;
    const {sessionsNeeded} = coupons;
    const label = `${activity.name} booking (${mode.name})`;

    //Filter for eligible coupons
    const filter = {
      areUsable: true,
      usableFor: [CouponTypeUses.BOOKINGS],
      members: members.map(member => member.id),
      mode: mode.id,
    };

    //Define handler
    const handler = function(coupons) {
      return booking.useCoupons(coupons);
    };

    //Open modal
    $modal
      .open('payWithCoupons', {
        locals: {label, sessionsNeeded, activity, filter, members, handler},
      })
      .result
      .then(() => $notice.show('Booking paid'))
      .catch(error => {
        if (error.code === ErrorCodes.paymentMethodInvalid) {
          $notice.showError('Not enough sessions to pay for booking');
        }
        else {
          $notice.showError('Booking payment failed');
        }
      });
  };
  /**
   * Pay with account credit
   */
  this.payWithAccountCredit = function($event) {

    //Get data
    const {booking} = $event;
    const {members, activity, mode, fee} = booking;
    const {amount} = fee;
    const label = `${activity.name} booking (${mode.name})`;

    //Define handler
    const handler = data => booking.markPaid(data);

    //Open modal
    $modal
      .open('payWithAccountCredit', {
        locals: {label, amount, members, handler},
      })
      .result
      .then(() => $notice.show('Booking paid'))
      .catch(error => {
        if (error !== 'cancel') {
          $notice.showError('Booking payment failed');
        }
      });
  };

  /**
   * Mark as paid
   */
  this.markAsPaid = function($event) {

    //Get data
    const {booking} = $event;

    //Define handler
    const handler = function(data) {
      return booking.markPaid(data);
    };

    //Open modal
    $modal
      .open('markBookingAsPaid', {
        locals: {booking, handler},
      })
      .result
      .then(() => $notice.show('Booking paid'))
      .catch(() => $notice.showError('Booking payment failed'));
  };

  /**
   * Remove booking
   */
  this.delete = function($event) {

    //Define handler
    const {booking} = $event;
    const {user} = this;
    const handler = data => $store.bookings.delete(booking, data);

    //Open modal
    return $modal
      .open('removeBooking', {locals: {booking, user, handler}})
      .result
      .then(() => {

        //Refresh user's account credit
        if (booking.isOwner(user) || booking.hasMember(user)) {
          user.refreshAccountCredit();
        }

        //Show notice
        $notice.show('Booking removed');
        this.onReload();
      });
  };

  /**
   * Load more
   */
  this.loadMore = function() {
    this.isLoadingMore = true;
    this
      .onLoadMore()
      .finally(() => this.isLoadingMore = false);
  };
});
