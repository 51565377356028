
/**
 * Module definition and dependencies
 */
angular.module('Shared.Percentage.Filter', [])

/**
 * Filter definition
 */
.filter('percentage', function() {
  return function(value, defaultValue = '') {
    if (value === null || value === undefined || Number.isNaN(value)) {
      return defaultValue;
    }
    return String(Math.round(value * 100 * 10) / 10) + '%';
  };
});
