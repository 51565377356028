
/**
 * Module definition and dependencies
 */
angular.module('Shared.Premium.Component', [])

/**
 * Component
 */
.component('premium', {
  template: `
  <help
    tip="{{$ctrl.tip}}"
    icon="auto_awesome"
    type="warning"
    ng-click="$event.stopPropagation()"
  ></help>
  `,
  // SEE: https://github.com/helloclub/issues/issues/5977
  // ui-sref="admin.club({openCard: 'account'})"
  bindings: {
    isEnabled: '<',
    isPlural: '<',
    feature: '@',
    system: '@',
    plan: '@',
  },
  controller() {
    this.$onChanges = function() {
      const {isEnabled, isPlural, plan, system, feature} = this;
      if (system) {
        const feat = feature || `This feature`;
        const verb = (feature && isPlural) ? 'are' : 'is';
        this.tip = `${feat} ${verb} available for ${system} systems only`;
      }
      else if (isEnabled) {
        const feat = feature || `This feature`;
        const verb = (feature && isPlural) ? 'are' : 'is';
        this.tip = `${feat} ${verb} available from the ${plan} plan onwards`;
      }
      else {
        const feat = feature || `this feature`;
        this.tip = `Upgrade to the ${plan} plan to enable ${feat}`;
      }
    };
  },
});
