
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.Transactions', [
  'App.Admin.Finance.Transactions.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.finance.transactions', {
    url: '/transactions',
    component: 'adminTransactionRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('transactions');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminTransactionRoute', {
  controller: 'AdminFinanceTransactionsCtrl',
  templateUrl: 'admin/finance/transactions/transactions.html',
  bindings: {
    club: '<',
    filter: '<',
    page: '<',
    user: '<',
  },
});
