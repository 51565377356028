
/**
 * Module definition and dependencies
 */
angular.module('App.Admin', [
  'App.Admin.Setup',
  'App.Admin.System',
  'App.Admin.Club',
  'App.Admin.Activity',
  'App.Admin.Area',
  'App.Admin.Resource',
  'App.Admin.Event',
  'App.Admin.People',
  'App.Admin.Membership',
  'App.Admin.CouponType',
  'App.Admin.Finance',
  'App.Admin.Report',
  'App.Admin.Log',
  'App.Admin.Settings',
  'App.Admin.Integrations',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin', {
    parent: 'app',
    url: '/admin',
    abstract: true,
    template: '<ui-view/>',
  });
});
