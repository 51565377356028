
/**
 * Module definition and dependencies
 */
angular.module('App.Header.Component', [
  'App.Header.Controller',
  'App.Header.SideMenu.Component',
  'App.Header.Crumb.Component',
])

/**
 * Component
 */
.component('appHeader', {
  templateUrl: 'app/header/header.html',
  controller: 'AppHeaderCtrl',
  bindings: {
    user: '<',
    club: '<',
    pages: '<',
    canAddToHomeScreen: '<',
    onAddToHomeScreen: '&',
  },
});
