
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.BulkVaccination.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('bulkVaccination', {
    templateUrl: 'admin/system/modals/bulk-vaccination.html',
    controller: 'ModalBulkVaccinationCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalBulkVaccinationCtrl', function(
  $controller, $modalInstance, $notice
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Set vaccination requirement
   */
  this.setRequiresVaccination = function(requiresVaccination) {

    //Set flag
    this.isSettingVaccination = true;
    this.isEnablingVaccination = !!requiresVaccination;
    this.isDisablingVaccination = !requiresVaccination;

    //Restart remote access
    this.system
      .bulkAccessRulesUpdate({requiresVaccination})
      .then(() => $notice.show(`Access rules updated to ${requiresVaccination ? '' : 'not'} require vaccination`))
      .catch(() => $notice.showError(`Failed to update access rules`))
      .finally(() => {
        this.isSettingVaccination = false;
        this.isEnablingVaccination = false;
        this.isDisablingVaccination = false;
      });
  };
});
