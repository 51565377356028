
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Edit.Organisers.Card', [])

/**
 * Component
 */
.component('cardEventEditOrganisers', {
  templateUrl: 'admin/event/cards/organisers.html',
  controller: 'CardEventEditOrganisersCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    event: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventEditOrganisersCtrl', function($modal, CardCloseReasons) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;
    this.isEdit = true;

    //Create model subset
    this.model = this.event.extract([
      'organisers', 'notifyOrganisers',
    ]);
  };

  /**
   * Toggle notify organisers checkbox
   */
  this.toggleNotifyOrganisers = function(value) {
    this.model.notifyOrganisers = value;
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function(method) {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get data and prepare event
    const {event, model, isEdit} = this;
    const {isMultiDay, isRecurring} = event;
    const {startDate} = model;
    const $event = {event, model, method, isEdit};

    //Confirm how to update if this was a multiday or recurring event
    if (isEdit && (isMultiDay || isRecurring) && !method) {
      return $modal
        .open('confirmUpdateEvent', {locals: {event, startDate}})
        .result
        .then(method => this.save(method));
    }

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
