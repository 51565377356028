
/**
 * Module definition and dependencies
 */
angular.module('Shared.MembershipInfo.Component', [])

/**
 * Component
 */
.component('membershipInfo', {
  templateUrl: 'shared/membership-info/membership-info.html',
  bindings: {
    membership: '<',
    subscription: '<',
  },

  /**
   * Controller
   */
  controller(Months, Weekdays) {

    /**
     * Init
     */
    this.$onInit = function() {

      //Set services
      this.Months = Months;
      this.Weekdays = Weekdays;
    };
  },
});
