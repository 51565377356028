/**
 * Module definition and dependencies
 */
angular.module('Shared.Text.Inflect.Filter', [])

/**
 * Filter definition
 */
.filter('inflect', function() {

  //Helper to get noun string
  function getString(num, str) {

    //If no string given
    if (!str || num === 1) {
      return str;
    }

    //Special case
    if (str.match(/[^aeiou]y$/)) {
      return str.substring(0, str.length - 1) + 'ies';
    }

    //Append an S
    return `${str + 's'}`;
  }

  //Filter function
  return function(num, str, prefixNum = true) {

    //Make noun string
    const nounString = getString(num, str);

    //Add with prefix
    if (prefixNum) {
      return `${num} ${nounString}`;
    }

    //Return as is
    return nounString;
  };
});
