
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.OrderTags.Card', [])

/**
 * Component
 */
.component('cardSystemOrderTags', {
  templateUrl: 'admin/system/cards/order-tags.html',
  controller: 'CardSystemOrderTagsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    system: '<',
    club: '<',
  },
})

/**
 * Controller
 */
.controller('CardSystemOrderTagsCtrl', function(
  $modal, TagOrderTypes, TagOrder,
  CardCloseReasons, $notice, Address
) {

  /**
   * Init
   */
  this.$onInit = function() {

    //Flags
    this.isBusy = false;
    this.isError = false;

    //Filter tag types
    this.tagOrderTypes = TagOrderTypes;
    this.tagAmounts = [
      //NOTE: Must match array as defined on server
      10,
      25,
      50,
      100,
      150,
      200,
      300,
      400,
      500,
      750,
      1000,
      1250,
      1500,
      1750,
      2000,
    ];

    //Create new order
    this.order = new TagOrder();
    this.order.deliveryName = this.club.name;
    this.order.deliveryAddress = new Address();

    //Load previous tag order for address
    this.getLastDeliveryAddress();
    this.loadPrices();
  };

  /**
   * Update order
   */
  this.updateOrder = function(property, value) {
    this.order[property] = value;
    this.loadPrices();
  };

  /**
   * Get last tag delivery address and assign delivery address
   */
  this.getLastDeliveryAddress = async function() {
    return TagOrder
      .lastDeliveryAddress()
      .then(lastDeliveryAddress => {
        if (lastDeliveryAddress) {
          this.order.deliveryAddress = new Address(lastDeliveryAddress);
        }
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Load prices
   */
  this.loadPrices = async function() {
    this.loadingPrices = true;
    const {amount, type} = this.order;
    return TagOrder
      .getPrices(amount, type)
      .then(data => {
        this.pricePerTag = data.pricePerTag;
        this.totalPrice = data.totalPrice;
        this.loadingPrices = false;
      });
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Submit order
   */
  this.submitOrder = function() {

    //Set flags
    this.isError = false;

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get data
    const {order, club, pricePerTag, totalPrice} = this;
    this.isBusy = true;

    //Re-generate formatted address
    order.deliveryAddress.generateFormatted();

    //Create handler
    const handler = () => order.create();

    //Open modal
    $modal.open('basic', {
      templateUrl: 'admin/system/modals/tag-order-confirmation.html',
      locals: {handler, order, pricePerTag, totalPrice, club},
    })
    .result
    .then(() => {
      this.form.$setPristine();
      this.card.close(CardCloseReasons.SAVED);
      $notice.show(`Order placed`);
    })
    .catch(error => {
      $notice.showError(`Failed to place order`);
      throw error;
    })
    .finally(() => this.isBusy = false);
  };
});
