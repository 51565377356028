
/**
 * Module definition and dependencies
 */
angular.module('Shared.EnableNotifications.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('enableNotifications', {
    templateUrl: 'modals/enable-notifications.html',
    controller: 'ModalEnableNotificationsCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalEnableNotificationsCtrl', function(
  $controller, $modalInstance, Push
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Toggle push notifications
   */
  this.toggle = function(isEnabled) {
    const {user, type} = this;
    Push.toggleNotification(user, type, isEnabled);
    $modalInstance.close();
  };

  /**
   * Snooze
   */
  this.snooze = function() {
    $modalInstance.resolve([1, 'week']);
  };
});
