
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.OverrideLights.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('overrideLights', {
    templateUrl: 'admin/system/modals/override.html',
    controller: 'ModalOverrideLightsCtrl',
    resolve: {
      system($store) {
        'ngInject';
        return $store.system.get();
      },
      areas($store) {
        'ngInject';
        return $store.areas.query();
      },
      activities($store) {
        'ngInject';
        return $store.activities.query();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalOverrideLightsCtrl', function($controller, $modalInstance) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalOverrideCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Prepare items and groups
   */
  this.prepareItemsAndGroups = function() {

    //Get data
    const {activities, areas} = this;

    //Set type
    this.type = `lights`;

    //Make items and groups
    this.groups = activities
      .filter(activity => activity.isOnSystem)
      .map(({id, name, areaPlural}) => ({
        name: `${name} ${areaPlural}`,
        items: areas.filter(area => area.activity === id),
      }));

    //Filter out relevant items
    this.items = this.groups.reduce((items, group) => {
      items.push(...group.items);
      return items;
    }, []);
  };

  /**
   * Apply state
   */
  this.applyState = function(state) {
    this.system.applyAreaStates(this.items, state.areaStates);
  };

  /**
   * Perform actual override request
   */
  this.overrideRequest = function(...args) {
    return this.system.overrideLights(...args);
  };
});
