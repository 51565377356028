
/**
 * Module definition and dependencies
 */
angular.module('Shared.Identifier.Filter', [])

/**
 * Generate identifier
 */
.filter('identifier', function() {
  return function(str) {
    if (!str) {
      return '';
    }
    return String(str)
      .toLowerCase()
      .trim()
      .replace(/['"`‘’]+/g, '')
      .replace(/[\W_]+/g, '-');
  };
});
