
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Contacts.Add.Details.Card', [])

/**
 * Component
 */
.component('cardContactAddDetails', {
  templateUrl: 'admin/people/contacts/cards/details.html',
  controller: 'CardContactAddDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    contact: '<',
    groups: '<',
    customFields: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardContactAddDetailsCtrl', function(
  $controller, $element, $focus
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardContactEditDetailsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;

    //Focus on first input
    $focus($element.find('input')[0]);
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {contact, model} = this;
    this.onMerge({$event: {contact, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and onwards
    this.merge();
    this.card.nextTab();
  };
});
