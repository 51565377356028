
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Setup.Progress.Card', [])

/**
 * Component
 */
.component('cardSetupProgress', {
  templateUrl: 'admin/setup/cards/progress.html',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    isFirstVisit: '<',
  },
});
