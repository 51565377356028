
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Log.Options.Component', [])

/**
 * Generic log options component
 */
.component('logOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li>
        <a ng-click="$ctrl.viewDetails()">View details</a>
      </li>
    </more-options>
  `,
  bindings: {
    log: '<',
    onViewDetails: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * View details
     */
    this.viewDetails = function() {
      this.onViewDetails({$event: {log: this.log}});
    };
  },
});
