
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Contacts.Controller', [])

/**
 * Base controller
 */
.controller('AdminContactCtrl', function(
  $q, $store, $modal, $notice
) {

  /**
   * Merge
   */
  this.merge = function($event) {

    //Get contact and model
    const {contact, model} = $event;
    this.isDirty = true;

    //Merge
    contact.merge(model);
    return $q.resolve();
  };

  /**
   * Patch
   */
  this.patch = function($event) {

    //Get model
    const {contact, model} = $event;

    //Patch
    return contact
      .patch(model)
      .then(() => {
        $notice.show('Contact updated');
        this.onPatched({contact});
      })
      .catch(error => {
        $notice.showError('Failed to update contact');
        throw error;
      });
  };

  /**
   * Save
   */
  this.save = function($event) {

    //Get data
    const {contact, model, isEdit} = $event;

    //Save
    return $store.contacts
      .save(contact, model)
      .then(contact => {
        this.onSaved({contact});
        if (isEdit) {
          $notice.show('Contact updated');
        }
        else {
          $notice.show('Contact added');
        }
      })
      .catch(error => {
        if (isEdit) {
          $notice.showError('Failed to update contact');
        }
        else {
          $notice.showError('Failed to add contact');
        }
        throw error;
      });
  };

  /**
   * Delete
   */
  this.delete = function($event) {

    //Get contact and create handler
    const {contact} = $event;
    const handler = function() {
      return $store.contacts.delete(contact);
    };

    //Open confirmation dialog
    $modal
      .open('basic', {
        templateUrl: 'admin/people/contacts/modals/confirm-delete-contact.html',
        locals: {contact, handler},
      })
      .result
      .then(() => {
        $notice.show('Contact removed');
        this.onDeleted({contact});
      });
  };

  /**
   * No-ops (to extend as needed)
   */
  this.onSaved = function() {};
  this.onPatched = function() {};
  this.onDeleted = function() {};
});
