
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Edit.Recurrence.Card', [])

/**
 * Component
 */
.component('cardEventEditRecurrence', {
  templateUrl: 'admin/event/cards/recurrence.html',
  controller: 'CardEventEditRecurrenceCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    event: '<',
    onUpdateRecurrence: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventEditRecurrenceCtrl', function(
  moment, Recurrence, Durations,
  DayOfRecurrences, CardCloseReasons
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;
    this.isEdit = true;
    this.Durations = Durations;
    this.dayOfRecurrences = DayOfRecurrences;
    this.maxDate = moment().add(2, 'years').startOf('day');

    //Create model subset
    this.model = this.event.extract([
      'recurrence',
    ]);

    //Create recurrence if none set
    if (!this.model.recurrence) {
      const firstDate = this.event.startDate.clone();
      const days = [this.event.startDate.isoWeekday()];
      this.model.recurrence = new Recurrence({firstDate, days});
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    this.form.$setDirty(); //Needed for ending by
    if (target) {
      target[property] = value;

      //Special handling for edit
      if (this.isEdit) {

        //If updating "ends" property, remove amount if going to never/date
        if (property === 'ends' && value !== 'amount') {
          target.amount = '';
        }
      }
    }
    else {
      this.model[property] = value;
    }
    this.determineDayOfRecurrenceLabels();
  };

  /**
   * Determine dynamic labels for day of recurrence
   */
  this.determineDayOfRecurrenceLabels = function() {
    const {recurrence} = this.model;
    const {interval, firstDate} = recurrence;
    if (interval === 'months') {
      const nth = this.getMonthlyDayOf(firstDate);
      this.dayOfRecurrences = [
        {
          value: 'week',
          label: `On the ${nth} ${firstDate.format('dddd')}`,
        },
        {
          value: 'month',
          label: `On the ${firstDate.format('Do')}`,
        },
      ];
    }
  };

  /**
   * Helper to get the instance of a day of week in a month
   */
  this.getMonthlyDayOf = function(checkDate) {

    //First, find the first date of the month that falls on this weekday
    let dayOfWeek = checkDate.isoWeekday();
    let date = checkDate.clone().startOf('month');
    while (date.isoWeekday() !== dayOfWeek) {
      date.add(1, 'day');
    }

    //Now determine which instance was picked and how many of these days
    //there are in the selected month
    let instance;
    let count = 0;
    while (date.isSame(checkDate, 'month')) {
      count++;
      if (date.isSame(checkDate, 'day')) {
        instance = count;
      }
      date.add(1, 'week');
    }

    //Determine friendly text of instance
    switch (instance) {
      case 1:
        instance = 'first';
        break;
      case 2:
        instance = 'second';
        break;
      case 3:
        instance = 'third';
        break;
      case 4:
        instance = 'fourth';
        break;
      case 5:
        instance = 'fifth';
    }

    //Return
    return instance;
  };

  /**
   * Day of week mismatch checker
   */
  this.isDayOfWeekMismatch = function() {
    if (!this.model.isRecurring) {
      return false;
    }
    if (this.model.recurrence.interval !== 'weeks') {
      return false;
    }
    if (this.model.recurrence.days.length === 0) {
      return false;
    }
    let day = this.event.startDate.isoWeekday();
    return (this.model.recurrence.days.indexOf(day) === -1);
  };

  /**
   * Day of week new date determination
   */
  this.dayOfWeekDate = function() {
    if (this.model.recurrence.days.length === 0) {
      return null;
    }
    this.model.recurrence.days.sort();
    let day = this.event.startDate.isoWeekday();
    let first = this.model.recurrence.days[0];
    let diff = first - day;
    if (diff < 0) {
      diff += 7;
    }
    return this.event.startDate.clone().add(diff, 'days');
  };

  /**
   * Ends helper
   */
  this.isEndingBy = function(type) {
    return (this.model.recurrence.ends === type);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get data and prepare event
    const {event, model} = this;
    const $event = {event, model};

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onUpdateRecurrence({$event})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .catch(error => {
        if (error.name === 'ValidationError') {
          this.error = error;
        }
      })
      .finally(() => this.isSaving = false);
  };
});
