
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Add', [
  'App.Admin.People.Members.Add.Controller',
  'App.Admin.People.Members.Add.Details.Card',
  'App.Admin.People.Members.Add.CustomFields.Card',
  'App.Admin.People.Members.Add.Display.Card',
  'App.Admin.People.Members.Add.Activities.Card',
  'App.Admin.People.Members.Add.Subscriptions.Card',
  'App.Admin.People.Members.Add.Permissions.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.members.add', {
    url: '/add',
    component: 'adminMemberAddRoute',
    data: {
      roles: ['admin'],
    },
    resolve: {
      member(Member) {
        return new Member();
      },
      groups($store) {
        return $store.memberGroups.query();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminMemberAddRoute', {
  controller: 'AdminMemberAddCtrl',
  templateUrl: 'admin/people/members/add/add.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    member: '<',
    groups: '<',
    module: '<',
    activities: '<',
    integrations: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.people.members.add'}, transition => {
    const module = transition.injector().get('module');
    Page.setTitle(`Add ${module.singular}`);
    Page.addCrumb({sref: 'admin.people.members.add'});
  });
});
