
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.AlertOptions.Component', [])

/**
 * Alert options component
 */
.component('alertOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li>
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    alert: '<',
    onEdit: '&',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Edit
     */
    this.edit = function() {
      this.onEdit({$event: {alert: this.alert}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      this.onDelete({$event: {alert: this.alert}});
    };
  },
});
