
/**
 * Module definition and dependencies
 */
angular.module('Shared.TimeFormat.Options', [])

/**
 * Constant definition
 */
.constant('TimeFormatOptions', [
  {
    label: '16:30',
    value: 'HH:mm',
  },
  {
    label: '16.30',
    value: 'HH.mm',
  },
  {
    label: '4:30pm',
    value: 'h:mma',
  },
  {
    label: '4.30pm',
    value: 'h.mma',
  },
]);
