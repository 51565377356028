
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.EditJoiningFee.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editJoiningFee', {
    templateUrl: 'admin/club/modals/edit-joining-fee.html',
    controller: 'ModalEditJoiningFeeCtrl',
    closeOnClick: false,
    resolve: {
      club($store) {
        'ngInject';
        return $store.club.get();
      },
      memberships($store) {
        'ngInject';
        return $store.memberships.query();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalEditJoiningFeeCtrl', function(
  $controller, $modalInstance, $filter, $focus, $timeout,
  MembershipConstraintLabels
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Extract model
    this.model = this.fee.extract([
      'constraint', 'memberships', 'amount', 'label',
      'minAge', 'maxAge', 'region',
    ]);

    //Set flags
    this.isSaving = false;
    this.isError = false;

    //Set default label
    if (!this.model.label) {
      this.model.label = this.label || `Joining fee`;
    }

    //Set services
    this.MembershipConstraintLabels = MembershipConstraintLabels
      .filter(item => !item.isGuest);

    //Distribute memberships
    this.memberships = $filter('distribute')(this.memberships, 3);
  };

  /**
   * Focus
   */
  this.$postLink = function() {
    $focus('label');
  };

  /**
   * Save
   */
  this.save = function() {

    //Make zero
    if (!this.model.amount && this.model.amount !== 0) {
      this.model.amount = 0;
      return $timeout(() => this.save());
    }

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };
});
