
/**
 * Module definition and dependencies
 */
angular.module('Shared.ManageCategories.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('manageCategories', {
    templateUrl: 'modals/manage-categories.html',
    controller: 'ModalManageCategoriesCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalManageCategoriesCtrl', function(
  $controller, $notice, $focus, $modalInstance, $modal, $timeout, BaseColors
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Initialize
    this.name = '';
    this.names = {};
    this.isBusy = {};
    this.isEditing = {};
    this.choosingColorId = null;

    //Colors
    this.colors = BaseColors;

    //Load items
    this.Model.store
      .query()
      .then(items => {
        this.items = items;
        for (const item of items) {
          this.names[item.id] = item.name;
        }
      });
  };

  /**
   * Add item
   */
  this.toggleAddItem = function() {
    this.isAddingItem = !this.isAddingItem;
    this.name = '';
    this.color = BaseColors.random();
    if (this.isAddingItem) {
      $focus('newItem');
    }
  };

  /**
   * Edit item
   */
  this.toggleEditItem = function(item) {
    const {id} = item;
    this.isEditing[id] = true;
  };

  /**
   * Choose color
   */
  this.toggleChooseColor = function(item) {

    //New item
    if (!item) {
      this.isChoosingNewColor = !this.isChoosingNewColor;
      this.choosingColorId = null;
      return;
    }

    //Existing item
    this.isChoosingNewColor = false;
    if (this.choosingColorId === item.id) {
      this.choosingColorId = null;
    }
    else {
      this.choosingColorId = item.id;
    }
  };

  /**
   * Set color for existing item
   */
  this.setColor = function(item, color) {

    //No item
    if (!item) {
      this.color = color;
      this.isChoosingNewColor = false;
      $focus('newItem');
      return;
    }

    //Check if changed
    if (!color || item.color === color) {
      this.choosingColorId = null;
      return;
    }

    //Save
    this.saveExistingItem(item, {color});
  };

  /**
   * Set name for exiting item
   */
  this.setName = function(item, name) {

    //Get new name
    const {id} = item;

    //Check if changed
    if (!name || item.name === name) {
      this.isEditing[id] = false;
      return;
    }

    //Save
    this.saveExistingItem(item, {name});
  };

  /**
   * Save existing catgory
   */
  this.saveExistingItem = function(item, data) {

    //Get ID
    const {id} = item;
    const {singular} = this;

    //Already busy
    if (this.isBusy[id]) {
      return;
    }

    //Flag as busy
    this.isBusy[id] = true;

    //Patch
    item
      .patch(data)
      .then(() => {
        this.isEditing[id] = false;
        this.choosingColorId = null;
        $focus('blurTarget');
      })
      .catch(() => $notice.showError(`Failed to update ${singular}`))
      .finally(() => this.isBusy[id] = false);
  };

  /**
   * Save a new item
   */
  this.saveNewItem = function(isConfirmed) {

    //Not confirmed, wrap in a timeout to allow the app to set the colour
    if (!isConfirmed) {
      return $timeout(() => this.saveNewItem(true), 100);
    }

    //Alreay saving or picking colour?
    if (this.isSavingNewItem || this.isChoosingNewColor) {
      return;
    }

    //No name, abort
    if (!this.name) {
      return this.toggleAddItem();
    }

    //Create new group
    const {name, color, singular, Model} = this;
    const item = new Model({name, color});

    //Flag as saving
    this.isSavingNewItem = true;

    //Save group
    this.Model.store
      .save(item)
      .then(() => {
        this.name = '';
        this.items.push(item);
        this.names[item.id] = item.name;
        this.isAddingItem = false;
      })
      .catch(() => $notice.showError(`Failed to add ${singular}`))
      .finally(() => this.isSavingNewItem = false);
  };

  /**
   * Remove item
   */
  this.removeItem = function(item) {

    //Get ID
    const {id, name} = item;
    const {singular} = this;

    //Already busy
    if (this.isBusy[id]) {
      return;
    }

    //Flag as busy
    this.isBusy[id] = true;

    //Handler
    const handler = () => this.Model.store.delete(item);

    //Remove
    $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-remove-generic.html',
        locals: {name, singular, handler},
      })
      .result
      .then(() => {

        //Remove item from items array
        const i = this.items.findIndex(item => item.id === id);
        if (i !== -1) {
          this.items.splice(i, 1);
        }

        //Remove from names
        delete this.names[id];
      })
      .catch(() => $notice.showError(`Failed to remove ${singular}`))
      .finally(() => this.isBusy[id] = false);
  };

  /**
   * Close
   */
  this.close = function() {
    $modalInstance.resolve(this.items);
  };
});
