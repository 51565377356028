
/**
 * Module definition and dependencies
 */
angular.module('Shared.Modal.Confirm.Component', [])

/**
 * Confirmation modal component
 */
.component('modalConfirm', {
  template: `
    <div class="Modal">
      <div class="Modal-header {{$ctrl.type || 'warning'}}">
        <h1>{{$ctrl.header || 'Confirm'}}</h1>
        <div class="Modal-close" ng-click="$ctrl.modal.close()">
          <i class="Icon">close</i>
        </div>
      </div>
      <div class="Modal-body" ng-transclude ng-class="{'overflowing': $ctrl.isOverflowing}"></div>
      <div class="Modal-alerts" ng-transclude="alerts"></div>
      <div class="Modal-footer">
        <button-bar
          on-cancel="$ctrl.modal.cancel()"
          on-confirm="$ctrl.modal.confirm()"
          is-disabled-cancel="$ctrl.modal.isConfirming"
          is-disabled-confirm="$ctrl.modal.isConfirming || ($ctrl.withConfirmation && !$ctrl.modal.isConfirmed) || $ctrl.isDisabledConfirm"
          has-spinner-confirm="$ctrl.modal.isConfirming"
          class-confirm="{{$ctrl.type || 'warning'}}"
          label-cancel="{{$ctrl.labelCancel || 'Cancel'}}"
          label-confirm="{{$ctrl.labelConfirm || 'Confirm'}}"
        ></button-bar>
      </div>
    </div>
  `,
  transclude: {
    alerts: '?modalAlerts',
  },
  bindings: {
    modal: '<',
    isOverflowing: '<',
    withConfirmation: '<',
    isDisabledConfirm: '<',
    header: '@',
    type: '@',
    labelConfirm: '@',
    labelCancel: '@',
  },
});
