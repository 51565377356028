
/**
 * Module definition and dependencies
 */
angular.module('App.Page', [
  'App.Page.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('page', {
    parent: 'app',
    url: '/page/:pageId/:slug',
    component: 'pageRoute',
    params: {
      pageId: {
        value: null,
      },
      slug: {
        value: '',
        squash: true,
      },
    },
    resolve: {
      page(transition, CustomPage) {
        const params = transition.params();
        const id = params.pageId;
        return CustomPage.findById(id);
      },
      customFields($store) {
        return $store.customFields.query({model: 'Member'});
      },
      memberships($store) {
        return $store.memberships.query();
      },
      externalLinkToken(user) {
        return user.getToken('externalLink');
      },
    },
  });
})

/**
 * Route component
 */
 .component('pageRoute', {
   controller: 'PageCtrl',
   templateUrl: 'page/page.html',
   bindings: {
     club: '<',
     user: '<',
     page: '<',
     memberships: '<',
     customFields: '<',
     externalLinkToken: '<',
   },
 })

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'page'}, transition => {
    const {id, title} = transition.injector().get('page');
    Page.setTitle(title);
    Page.addCrumb({sref: 'page', title, params: {pageId: id}});
  }, {priority: 5});
});
