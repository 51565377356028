
/**
 * Module definition and dependencies
 */
angular.module('Shared.OnEnter.Directive', [])

/**
 * Directive
 */
.directive('onEnter', function($timeout) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      element.on('keydown keypress', event => {

        //Check event
        const key = event.keyCode || event.which;
        const isEnter = (key === 13);
        const isTextarea = (event.target.tagName === 'TEXTAREA');

        //Handle event
        if (isEnter && !isTextarea && !event.defaultPrevented) {
          event.preventDefault();
          $timeout(() => {
            scope.$eval(attrs.onEnter);
          });
        }
      });
    },
  };
});
