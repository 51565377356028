
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Add.Registration.Card', [])

/**
 * Component
 */
.component('cardMembershipAddRegistration', {
  templateUrl: 'admin/membership/cards/registration.html',
  controller: 'CardMembershipAddRegistrationCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    memberships: '<',
    membership: '<',
    system: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipAddRegistrationCtrl', function(
  $controller, CardCloseReasons, PaymentOptions
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMembershipEditRegistrationCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Flags
    this.isEdit = false;
    this.isAdd = true;

    //Never start as default (e.g. for copying)
    this.model.isDefault = false;

    //Default option
    if (!this.model.canSelectWhenRegistering) {
      this.model.canSelectWhenRegistering = true;
    }

    //Use global setting to set default
    if (!this.model.requiresApproval) {
      this.model.requiresApproval = this.globalRequiresApproval;
    }

    //Default setting for payment
    if (!this.model.registrationPayment) {
      this.model.registrationPayment = PaymentOptions.IN_ADVANCE;
    }
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {model, membership} = this;
    this.onMerge({$event: {membership, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next card
    this.merge();
    this.card.next();
  };

  /**
   * Previous step
   */
  this.previous = function() {

    //Merge
    this.merge();

    //Next card
    if (this.membership.isLinked) {
      this.card.open('membership.details', CardCloseReasons.OPENED_PREV);
    }
    else {
      this.card.previousTab();
    }
  };
});
