
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource', [
  'App.Admin.Resource.Add',
  'App.Admin.Resource.Edit',
  'App.Admin.Resource.Overview',
  'App.Admin.Resource.Controller',
  'App.Admin.Resource.Settings.Modal',
  'App.Admin.Resource.AddAssignee.Modal',
  'App.Admin.Resource.EditAssignee.Modal',
  'App.Admin.Resource.RenewAssignee.Modal',
  'App.Admin.Resource.ResourceOptions.Component',
  'App.Admin.Resource.ResourceAssigneeOptions.Component',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.resource', {
    url: '/resources',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'resources',
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.resource.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.resource.overview', title});
  }, {priority: 5});
});
