
/**
 * Module definition and dependencies
 */
angular.module('Shared.Timezone.Filter', [])

/**
 * Filter definition
 */
.filter('timezone', function() {
  return function(timezone) {
    if (!timezone || typeof timezone !== 'string') {
      return timezone;
    }
    return timezone
      .replace(/\//g, ' / ')
      .replace(/_/g, ' ');
  };
});
