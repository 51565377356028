
/**
 * Module definition and dependencies
 */
angular.module('App.Account.SelectAmount.Card', [])

/**
 * Component
 */
.component('cardAccountSelectAmount', {
  templateUrl: 'account/cards/select-amount.html',
  controller: 'CardAccountSelectAmountCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    user: '<',
    amount: '<',
    amounts: '<',
    minAmount: '<',
    isOther: '<',
    onSetAmount: '&',
    onSetOtherAmount: '&',
    onNext: '&',
    onCancel: '&',
  },
})

/**
 * Controller
 */
.controller('CardAccountSelectAmountCtrl', function($focus) {

  /**
   * On changes
   */
  this.$onChanges = function() {
    if (this.isOther) {
      this.selectedAmount = '';
      this.otherAmount = this.amount;
      $focus('OtherAmount');
    }
    else {
      this.selectedAmount = this.amount;
    }
  };

  /**
   * Set amount
   */
  this.setAmount = function(amount) {
    this.onSetAmount({$event: {amount}});
  };

  /**
   * Set other amount
   */
  this.setOtherAmount = function() {
    const amount = this.otherAmount;
    this.onSetOtherAmount({$event: {amount}});
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Next step
    this.onNext();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
