
/**
 * Module definition and dependencies
 */
angular.module('Shared.Ordinal.Filter', [])

/**
 * Filter definitions
 */
.filter('ordinal', function() {
  return function ordinal(number) {
    if (!number) {
      return '';
    }
    number = Number(number);
    const lastDigit = number % 10;
    if (lastDigit === 1) {
      return `${number}st`;
    }
    else if (lastDigit === 2) {
      return `${number}nd`;
    }
    else if (lastDigit === 3) {
      return `${number}rd`;
    }
    else {
      return `${number}th`;
    }
  };
});
