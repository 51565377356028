
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Report', [
  'App.Admin.Report.Overview',
  'App.Admin.Report.Membership',
  'App.Admin.Report.Financial',
  'App.Admin.Report.Area',
  'App.Admin.Report.Period',
  'App.Admin.Report.Governing',
])

/**
 * Config
 */
.config(($stateProvider, $apiProvider) => {

  //State definition
  $stateProvider.state('admin.report', {
    url: '/report',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'reports',
    },
  });

  //Register endpoint
  $apiProvider.registerEndpoint('report', {
    actions: {
      membership: {
        url: 'membership',
        method: 'POST',
        isArray: true,
      },
      area: {
        url: 'area',
        method: 'POST',
      },
      financial: {
        url: 'financial',
        method: 'POST',
      },
      governing: {
        url: 'governing',
        method: 'POST',
      },
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.report.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.report.overview', title});
  }, {priority: 10});
});
