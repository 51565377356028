
/**
 * Module definition and dependencies
 */
angular.module('Shared.Area.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('area', {
    model: 'Area',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'areas',
        isArray: true,
        isModel: true,
        withCredentials: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      delete: {
        method: 'DELETE',
      },
      deleteSponsorLogo: {
        url: 'sponsor',
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('areas', {
    model: 'Area',
    dataKey: 'areas',
    cacheEmpty: true,
  });
})

/**
 * Model definition
 */
.factory('Area', function(
  $api, $baseModel, $filter, AreaStates, OutputStates, Tag
) {

  /**
   * Constructor
   */
  function Area(data) {

    //Parent constructor
    $baseModel.call(this, data);

    //Initialize state properties
    this.resetState();

    //Check if output state is on or off
    Object.defineProperty(this, 'isOn', {
      get() {
        return (this.output === OutputStates.ON);
      },
    });
    Object.defineProperty(this, 'isOff', {
      get() {
        return (this.output === OutputStates.OFF);
      },
    });
    Object.defineProperty(this, 'isUnknown', {
      get() {
        return (this.output === OutputStates.UNKNOWN);
      },
    });

    //Check area state
    Object.defineProperty(this, 'isEmpty', {
      get() {
        return (this.state === AreaStates.EMPTY);
      },
    });
    Object.defineProperty(this, 'isInOverride', {
      get() {
        return (this.state === AreaStates.OVERRIDE);
      },
    });
    Object.defineProperty(this, 'isInWarmUp', {
      get() {
        return (this.state === AreaStates.WARM_UP);
      },
    });
    Object.defineProperty(this, 'hasMode', {
      get() {
        return (this.state === AreaStates.MODE);
      },
    });
    Object.defineProperty(this, 'hasEvent', {
      get() {
        return (this.state === AreaStates.EVENT);
      },
    });

    //Check if area has tags
    Object.defineProperty(this, 'hasTags', {
      get() {
        return (this.tags.length > 0);
      },
    });

    //Previous/next areas
    let prevArea, nextArea;
    Object.defineProperty(this, 'prev', {
      set(prev) {
        prevArea = prev;
      },
      get() {
        return prevArea;
      },
    });
    Object.defineProperty(this, 'next', {
      set(next) {
        nextArea = next;
      },
      get() {
        return nextArea;
      },
    });

    //States for override status
    Object.defineProperty(this, 'isInactive', {
      get() {
        return this.isOff;
      },
    });
    Object.defineProperty(this, 'isActive', {
      get() {
        return this.isOn;
      },
    });
    Object.defineProperty(this, 'isInSpecialState', {
      get() {
        return (this.hasMode || this.isInWarmUp);
      },
    });
    Object.defineProperty(this, 'isInOverrideState', {
      get() {
        return (this.hasEvent || this.isInOverride);
      },
    });

    //Textual state
    Object.defineProperty(this, 'stateLabel', {
      get() {
        return $filter('areaState')(this);
      },
    });

    //Icons
    Object.defineProperty(this, 'iconInactive', {
      get() {
        return 'power_off';
      },
    });
    Object.defineProperty(this, 'iconActive', {
      get() {
        return 'wb_sunny';
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Area.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Area.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('times', 'TimeRange', true);

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Area.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Remove state properties
    delete json.state;
    delete json.output;
    delete json.tags;
    delete json.mode;
    delete json.event;
    delete json.startTime;
    delete json.endTime;

    //Remove booking page appended properties
    delete json.isSwiped;
    delete json.duration;

    //Return JSON
    return json;
  };

  /**
   * Clone
   */
  Area.prototype.clone = function(stripId) {
    const clone = $baseModel.prototype.clone.call(this, stripId);

    //Strip unneeded data
    delete clone.club;
    delete clone.sponsor;
    delete clone.isOnSystem;
    delete clone.createdAt;
    delete clone.updatedAt;

    //Return the clone
    return clone;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Set area state
   */
  Area.prototype.setState = function(data) {

    //Empty data, done
    if (!data) {
      this.resetState();
      return;
    }

    //Extract data
    const {state, output, tags, mode, event, startTime, endTime} = data;

    //Set data
    this.state = state || AreaStates.EMPTY;
    this.output = output || OutputStates.UNKNOWN;
    this.mode = mode || null;
    this.event = event || null;
    this.startTime = startTime || null;
    this.endTime = endTime || null;

    //Set tags
    if (tags) {
      this.tags = Tag.toInstance(tags);
    }
  };

  /**
   * Reset area state
   */
  Area.prototype.resetState = function() {
    this.state = AreaStates.EMPTY;
    this.output = OutputStates.UNKNOWN;
    this.tags = [];
    this.mode = null;
    this.event = null;
    this.startTime = null;
    this.endTime = null;
  };

  /**
   * Get members currently active on area
   */
  Area.prototype.getMembers = function() {
    return this.tags
      .filter(tag => !!tag.member)
      .map(tag => tag.member);
  };

  /**
   * Get service tags currently on area
   */
  Area.prototype.getServiceTags = function() {
    return this.tags
      .filter(tag => tag.isService);
  };

  /**
   * Check if given date/time is a valid area start time
   */
  Area.prototype.isValidTime = function(date, time, duration) {
    return this.times
      .some(timeRange =>
        timeRange.containsDateAndTime(date, time) &&
        timeRange.isValidStartTime(time, duration)
      );
  };

  /**
   * Check if this area contains a certain date
   */
  Area.prototype.containsDate = function(date) {
    return this.times.some(timeRange => timeRange.containsDate(date));
  };

  /**
   * Save
   */
  Area.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.area[method](data).then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Area.prototype.delete = function() {
    return $api.area
      .delete(null, this)
      .then(() => this);
  };

  /**
   * Delete sponsor logo
   */
  Area.prototype.deleteSponsorLogo = function() {
    return $api.area
      .deleteSponsorLogo(null, this)
      .then(() => this.sponsor = null);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Area.query = function(filter) {
    return $api.area
      .query(filter)
      .then(data => {
        Area.mapPreviousAndNext(data.areas);
        return data;
      });
  };

  /**
   * Find by ID
   */
  Area.findById = function(id) {
    return $api.area.get({id});
  };

  /**
   * Map the previous and next areas
   */
  Area.mapPreviousAndNext = function(areas) {
    for (const area of areas) {
      area.prev = Area.findPrevious(area, areas);
      area.next = Area.findNext(area, areas);
    }
  };

  /**
   * Find the previous area
   */
  Area.findPrevious = function(area, areas) {
    //eslint-disable-next-line no-unused-vars
    return areas.find((other, i) => {
      const next = areas[i + 1];
      return (next && next.id === area.id);
    });
  };

  /**
   * Find the next area
   */
  Area.findNext = function(area, areas) {
    //eslint-disable-next-line no-unused-vars
    return areas.find((other, i) => {
      const previous = areas[i - 1];
      return (previous && previous.id === area.id);
    });
  };

  //Return
  return Area;
});
