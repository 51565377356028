
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.Add.Details.Card', [])

/**
 * Component
 */
.component('cardResourceAddDetails', {
  templateUrl: 'admin/resource/cards/details.html',
  controller: 'CardResourceAddDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    resource: '<',
    customFields: '<',
    onSave: '&',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardResourceAddDetailsCtrl', function(
  $controller, $focus, $element
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardResourceEditDetailsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark flags
    this.isAdd = true;
    this.isEdit = false;

    //Set default term
    if (!this.model.term) {
      this.model.term = {
        amount: 1,
        unit: 'years',
      };
    }

    //Focus on first input
    $focus($element.find('input')[0]);
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {resource, model} = this;
    this.onMerge({$event: {resource, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next card
    this.merge();
    this.card.next();
  };
});
