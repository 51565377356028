
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.Component', [
  'App.Booking.Grid.Service',
  'App.Booking.Grid.Nav.Component',
  'App.Booking.Grid.Slot.Component',
  'App.Booking.Grid.Areas.Component',
  'App.Booking.Grid.Header.Component',
  'App.Booking.Grid.Footer.Component',
  'App.Booking.Grid.Controls.Component',
  'App.Booking.Grid.Sponsors.Component',
])

/**
 * Grid component
 */
.component('bookingGrid', {
  template: `
    <div
      class="BookingGrid"
      ng-class="{'is-booking': !!$ctrl.booking}"
      ng-swipe-left="$ctrl.onNextArea()"
      ng-swipe-right="$ctrl.onPreviousArea()"
    >
      <div
        class="BookingGrid-column"
        ng-repeat="area in $ctrl.areas track by area.id"
        ng-class="{'is-swiped': area.isSwiped}"
      >
        <booking-grid-slot
          class="BookingGrid-cell Slot {{slot.classes | spaceDelimited}}"
          ng-class="{
            'is-selected': slot.isSelected,
            'is-selectable': slot.isSelectable,
            'is-primed': slot.isPrimed,
            'can-expand-up': slot.isFirstSelected && !slot.isFirstSelectable,
            'can-expand-down': slot.isLastSelected && !slot.isLastSelectable
          }"
          ng-repeat="slot in $ctrl.slots[$index] track by $index"
          ng-click="$ctrl.clickSlot(slot)"
          style="{{slot.height ? ('height: ' + slot.height + 'px') : ''}}"
          ng-mouseenter="$ctrl.mouseEnterSlot(slot)"
          ng-mouseleave="$ctrl.mouseLeaveSlot(slot)"
          slot="slot"
          user="$ctrl.user"
          activities="$ctrl.activities"
          time-range="$ctrl.timeRange"
          is-showing-names="$ctrl.isShowingNames"
          is-showing-current="$ctrl.isShowingCurrent"
          on-view-booking="$ctrl.onViewBooking({$event})"
          on-view-status="$ctrl.onViewStatus({$event})"
          on-waiting-list="$ctrl.onWaitingList({$event})"
        ></booking-grid-slot>
      </div>
    </div>
  `,
  bindings: {
    user: '<',
    date: '<',
    minDate: '<',
    maxDate: '<',
    areas: '<',
    status: '<',
    duration: '<',
    timeRange: '<',
    bookings: '<',
    events: '<',
    activity: '<',
    activities: '<',
    hasLightControl: '<',
    isDisabled: '<',
    isShowingNames: '<',
    isShowingCurrent: '<',
    visibleArea: '<',
    onNextArea: '&',
    onPreviousArea: '&',
    onViewStatus: '&',
    onViewBooking: '&',
    onCreateBooking: '&',
    onWaitingList: '&',
  },

  /**
   * Controller
   */
  controller(moment, Settings, MembershipConstraints, BookingGrid) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Get settings
      this.allowCurrent = Settings.get('booking.allowCurrent');
      this.isAdmin = this.user && this.user.hasRole('admin', 'eventManager');

      //Check if all slots need to be disabled
      if (this.date.isBefore(this.minDate) || this.date.isAfter(this.maxDate)) {
        this.allDisabled = true;
      }
    };

    /**
     * Change handler
     */
    this.$onChanges = function(changes) {

      //Making a booking? Cancel it
      if (this.booking) {
        this.clearBooking();
      }

      //Generate slots and update current slots with actual status
      this.generateSlots();
      this.updateCurrentSlots();

      //Check which areas are swiped
      if (changes.visibleArea || changes.areas) {
        this.areas.forEach((area, i) => {
          area.isSwiped = (this.visibleArea > (i + 1));
        });
      }
    };

    /**************************************************************************
     * Control
     ***/

    /**
     * Click handler for empty slots
     */
    this.clickSlot = function(slot) {

      //Get data
      const {isAvailable, isGap, isSelected, isSelectable} = slot;
      const {isDisabled, booking} = this;

      //Disabled or gap
      if (isDisabled || isGap) {
        return;
      }

      //Not booking yet and slot is available
      if (isAvailable && !booking) {
        this.initializeBooking(slot);
      }

      //No booking
      if (!booking) {
        return;
      }

      //Confirm the booking if clicking on an already selected slot
      if (isSelected) {
        this.confirmBooking();
      }

      //Expand the booking if slot is selectable, and confirm right after
      else if (isSelectable) {
        this.selectSlot(slot);
        this.confirmBooking();
      }

      //Clear the booking in progress if clicked anywhere else
      else {
        this.clearBooking();
      }
    };

    /**
     * Enter hover over a slot
     */
    this.mouseEnterSlot = function(slot) {

      //Not booking, or slot not selectable, ignore
      if (!this.booking || !slot.isSelectable) {
        return;
      }

      //Get slot index
      const {slots, startIndex, endIndex} = this.booking;
      const j = slots.indexOf(slot);

      //Get from/to indices
      const start = Math.min(j, startIndex, endIndex);
      const end = Math.max(j, startIndex, endIndex);

      //Mark all slots in between as primed
      for (let k = start; k <= end; k++) {
        slots[k].isPrimed = true;
        slots[k].isFirstSelected = (k === start);
        slots[k].isLastSelected = (k === end);
      }
    };

    /**
     * Leave hover over a slot
     */
    this.mouseLeaveSlot = function(slot) {

      //Not booking, or slot not selectable, ignore
      if (!this.booking || !slot.isSelectable) {
        return;
      }

      //Get data
      const {slots, startIndex, endIndex} = this.booking;

      //Clear flags on all slots
      slots.forEach(slot => {
        slot.isPrimed = false;
        slot.isFirstSelected = false;
        slot.isLastSelected = false;
      });

      //Flag actual first/last selected
      slots[startIndex].isFirstSelected = true;
      slots[endIndex].isLastSelected = true;
    };

    /**
     * Get first available slot
     */
    this.getFirstAvailableSlot = function(slots, ref) {
      const j = slots.indexOf(ref);
      let first = j;
      for (let k = j - 1; k >= 0; k--) {
        if (!this.isSlotAvailable(slots[k], ref)) {
          break;
        }
        first = k;
      }
      return first;
    };

    /**
     * Get last available slot
     */
    this.getLastAvailableSlot = function(slots, ref) {
      const j = slots.indexOf(ref);
      let last = j;
      for (let k = j + 1; k < slots.length; k++) {
        if (!this.isSlotAvailable(slots[k], ref)) {
          break;
        }
        last = k;
      }
      return last;
    };

    /**
     * Initialize booking
     */
    this.initializeBooking = function(slot) {

      //Get area and gap data
      const {area, gap} = slot;

      //Get min/max duration and determine corresponding min/max number of slots
      const {activity} = this;
      const {minDuration, maxDuration} = activity;
      const minSlots = minDuration / area.duration;
      const maxSlots = maxDuration / area.duration;
      let numSlots = 1;

      //Get relevant slots, find the index of the slot we clicked on
      const i = this.areas.indexOf(area);
      const slots = this.slots[i];
      const j = slots.indexOf(slot);

      //Initialize booking properties
      this.booking = {
        area, gap, slots,
        startIndex: j,
        endIndex: j,
      };

      //Select first slot
      this.selectSlot(slot);

      //Get indices of first and last available slots
      let first = this.getFirstAvailableSlot(slots, slot);
      let last = this.getLastAvailableSlot(slots, slot);

      //If we have a minimum duration, try to select additional slots
      let n = j + 1;
      let p = j - 1;
      while (numSlots < minSlots && n <= last) {
        this.selectSlot(slots[n++]);
        numSlots++;
      }
      while (numSlots < minSlots && p >= first) {
        this.selectSlot(slots[p--]);
        numSlots++;
      }

      //Now contract the boundaries if there is a max number of slots
      if (maxSlots) {
        first = Math.max(first, this.booking.endIndex - (maxSlots - 1));
        last = Math.min(last, this.booking.startIndex + (maxSlots - 1));
      }

      //Flag the first and last selectable slots now
      slots[first].isFirstSelectable = true;
      slots[last].isLastSelectable = true;

      //Mark all selectable slots in between
      for (let k = first; k <= last; k++) {
        slots[k].isSelectable = true;
      }

      //Check how many slots are selectable
      const numSelectable = (last - first + 1);
      const {allowMultiSlotDuringPeakHours, isMultiSlotEnabled} = activity;

      //Multi slot not enabled or only one selectable slot? Confirm booking
      if (
        !isMultiSlotEnabled || numSelectable === 1 ||
        (slot.isPeak && !allowMultiSlotDuringPeakHours)
      ) {
        this.confirmBooking();
      }
    };

    /**
     * Mark a slot as selected
     */
    this.selectSlot = function(slot) {

      //Get slot index
      const {slots, startIndex, endIndex, area} = this.booking;
      const j = slots.indexOf(slot);

      //Validate slot
      if (j === -1 || slot.area !== area) {
        return;
      }

      //Get start and end indices
      const first = Math.min(j, startIndex, endIndex);
      const last = Math.max(j, startIndex, endIndex);

      //Flag slots appropriately
      for (let k = first; k <= last; k++) {
        slots[k].isSelected = true;
        slots[k].isFirstSelected = (k === first);
        slots[k].isLastSelected = (k === last);
      }

      //Update start/end indices in booking
      this.booking.startIndex = first;
      this.booking.endIndex = last;
    };

    /**
     * Confirm booking
     */
    this.confirmBooking = function() {

      //Get data and determine duration of booking
      const {area, gap, slots, startIndex, endIndex} = this.booking;
      const {time} = slots[startIndex];
      const div = gap ? 2 : 1;
      const num = (endIndex / div - startIndex / div) + 1;
      const duration = num * (area.duration + gap) - gap;

      //Call create booking handler
      this.onCreateBooking({$event: {area, time, duration}});
      this.clearBooking();
    };

    /**
     * Clear the booking in progress
     */
    this.clearBooking = function() {

      //Not actually booking?
      if (!this.booking) {
        return;
      }

      //Clear slots
      for (const slot of this.booking.slots) {
        this.clearSlot(slot);
      }

      //Clear booking
      this.booking = null;
    };

    /**
     * Clear a slot
     */
    this.clearSlot = function(slot) {
      slot.isSelectable = false;
      slot.isSelected = false;
      slot.isPrimed = false;
      slot.isFirstSelected = false;
      slot.isLastSelected = false;
      slot.isFirstSelectable = false;
      slot.isLastSelectable = false;
    };

    /**************************************************************************
     * Slot generation
     ***/

    /**
     * Create a single slot
     */
    this.createSlot = function(area, time, gap, bookings, events) {

      //Check if has booking or events
      const hasBookings = (bookings.length > 0);
      const hasEvents = (events.length > 0);
      const {duration} = area;
      const isFirst = (time === this.startTime);
      const endTime = time + duration;

      //Initialize slot
      const slot = {
        time,
        area,
        gap,
        isFirst,
        events: [],
        classes: [],
      };

      //Check if currently in progress
      if (this.isInProgress(time, endTime, gap)) {
        slot.classes.push('current');
        slot.isCurrent = true;
      }

      //Check if in future
      else if (this.isUpcoming(time)) {
        slot.isUpcoming = true;
      }

      //Check if peak hours
      if (this.isPeak(time)) {
        slot.classes.push('peak');
        slot.isPeak = true;
      }

      //Check if slot needs to be disabled
      if (hasBookings || hasEvents || this.allDisabled) {

        //Disable slot
        slot.classes.push('disabled');
        slot.isDisabled = true;

        //Find slot events
        if (hasEvents) {
          slot.events = this.findSlotEvents(events, time, endTime, gap);
        }

        //Find slot booking
        else if (hasBookings) {
          slot.booking = this.findSlotBooking(bookings, time, duration);
        }
      }

      //Upcoming/available
      else if (
        slot.isUpcoming || (slot.isCurrent && this.allowCurrent) ||
        this.isAdmin
      ) {
        slot.classes.push('available');
        slot.isAvailable = true;
      }

      //Return slot
      return slot;
    };

    /**
     * Create slots for a area
     */
    this.createSlots = function(area) {

      //Map duration onto area
      //TODO: Temporary, until we move duration from activity to areas
      area.duration = this.activity.duration;

      //Initialize slots and get bookings and events for this area
      const slots = [];
      const areaBookings = this.filterByArea(this.bookings, area);
      const areaEvents = this.filterByArea(this.events, area);

      //Get duration and time range for this area
      const {date} = this;
      const {duration, times} = area;
      const timeRange = times.find(timeRange => timeRange.containsDate(date));

      //No time range for this day?
      if (!timeRange) {
        return slots;
      }

      //Get gap
      const {gap} = timeRange;

      //Determine start and end time that fits within global time range
      this.startTime = timeRange.startTime;
      this.endTime = timeRange.endTime;

      //Adjust start time if the time range found starts before the global time range
      while (this.startTime < this.timeRange.startTime) {
        this.startTime += (duration + gap);
      }

      //Compute offsets compared to global time range
      const startOffset = this.startTime - this.timeRange.startTime;
      const endOffset = this.timeRange.endTime - this.endTime;

      //Create offset slot if needed
      if (startOffset) {
        const height = BookingGrid.durationToHeight(startOffset, duration);
        slots.push({height, classes: ['fill']});
      }

      //Loop over times
      let time = this.startTime;
      while ((time + duration) <= this.endTime) {

        //Filter relevant events/bookings by time
        const events = this.filterByTime(areaEvents, time, time + duration);
        const bookings = this.filterByTime(areaBookings, time, time + duration);

        //Create slot
        const slot = this.createSlot(area, time, gap, bookings, events);

        //Add to array
        slots.push(slot);

        //Add duration
        time += (duration + gap);

        //Only add gap slot if this would be the last slot
        if (gap && (time + duration) <= this.endTime) {
          const height = BookingGrid.durationToHeight(gap, duration);
          slots.push({height, classes: ['fill', 'gap'], isGap: true});
        }
      }

      //Create offset slot if needed
      if (endOffset) {
        const height = BookingGrid.durationToHeight(endOffset, duration);
        slots.push({height, classes: ['fill']});
      }

      //Return
      return slots;
    };

    /**
     * Generate slots
     */
    this.generateSlots = function() {

      //Initialize slots
      this.slots = [];

      //If no time range, no slots to generate
      if (!this.timeRange) {
        return;
      }

      //Create slots for each area
      this.slots = this.areas.map(area => this.createSlots(area));
    };

    /**
     * Update current slots
     */
    this.updateCurrentSlots = function() {

      //No light control or not on the current day?
      if (!this.hasLightControl || !this.date.isSame(moment(), 'day')) {
        return;
      }

      //No slots or not viewing current status?
      if (!this.slots || !this.isShowingCurrent) {
        return;
      }

      //Find relevant current slots
      this.slots
        .forEach(slots => {
          slots
            .filter(slot => slot.isCurrent && !slot.event)
            .forEach(slot => {
              slot.hasStatus = true;
              slot.classes.push('has-status');
            });
        });
    };

    /**************************************************************************
     * Helpers
     ***/

    /**
     * Check if a given time falls in peak hours
     */
    this.isPeak = function(time) {
      return this.activity.peakTimes
        .some(times => {
          return (
            times.constraint === MembershipConstraints.ALL &&
            times.timeRange.containsDateAndTime(this.date, time)
          );
        });
    };

    /**
     * Helper to check if a time is currently in progress
     */
    this.isInProgress = function(startTime, endTime, gap) {
      startTime = this.date.clone().setTime(startTime);
      endTime = this.date.clone().setTime(endTime + gap);
      return moment().isBetween(startTime, endTime, undefined, '[]');
    };

    /**
     * Check if a time is upcoming
     */
    this.isUpcoming = function(time) {
      time = this.date.clone().setTime(time);
      return moment().isBefore(time);
    };

    /**
     * Filter a given set of items by area
     */
    this.filterByArea = function(items, area) {
      if (!Array.isArray(items) || !items.length) {
        return [];
      }
      return items.filter(item => {
        if (item.area) {
          return (item.area.id === area.id);
        }
        else if (item.areas) {
          return item.areas.some(c => area.id === c.id);
        }
      });
    };

    /**
     * Filter a given set of items for a time range
     */
    this.filterByTime = function(items, startTime, endTime) {
      if (!Array.isArray(items) || !items.length) {
        return [];
      }
      return items.filter(item => {
        return (item.endTime > startTime && item.startTime < endTime);
      });
    };

    /**
     * Find slot booking
     */
    this.findSlotBooking = function(bookings, startTime, duration) {

      //Check if this is the first or second slot
      const isFirst = (startTime === this.startTime);
      const isSecond = (startTime === this.startTime + duration);

      //Find matching bookings
      return bookings
        .find(booking => {

          //Booking started before slot?
          if (booking.startTime < startTime) {

            //First slot
            if (isFirst) {
              return true;
            }

            //Second slot, and showing actual status?
            if (isSecond) {
              return this.isShowingCurrent;
            }
          }

          //Booking starts on the start time
          if (booking.startTime === startTime) {
            return true;
          }
        });
    };

    /**
     * Find slot events
     */
    this.findSlotEvents = function(events, startTime, endTime, gap) {

      //Check if this is the first slot
      const isFirst = (startTime === this.startTime);

      //Find matching events
      return events
        .filter(event => {

          //Event started before slot?
          if (event.startTime < startTime) {

            //First slot
            if (isFirst) {
              return true;
            }

            //Event started in the gap before the slot
            if (gap && event.startTime >= (startTime - gap)) {
              return true;
            }
          }

          //Event starts in the slot
          if (event.startTime >= startTime && event.startTime <= endTime) {
            return true;
          }
        });
    };

    /**
     * Check if a slot is available, based on comparison to reference slot
     */
    this.isSlotAvailable = function(slot, ref) {
      if (!slot.isAvailable && !slot.isGap) {
        return false;
      }
      if (slot.isPeak !== ref.isPeak && !slot.isGap) {
        return false;
      }
      return true;
    };
  },
});
