
/**
 * Module definition and dependencies
 */
angular.module('Shared.Cards.Component', [
  'Shared.Cards.Card.Component',
  'Shared.Cards.CardOpener.Component',
  'Shared.Cards.CardContents.Component',
  'Shared.Cards.CardHeader.Component',
  'Shared.Cards.CardFooter.Component',
  'Shared.Cards.CardTabs.Component',
  'Shared.Cards.CardTab.Component',
  'Shared.Cards.CardOptions.Component',
  'Shared.Cards.CloseReasons.Constant',
])

/**
 * Component
 */
.component('cards', {
  template: `<div class="Cards {{$ctrl.classes}}" ng-transclude></div>`,
  transclude: true,
  bindings: {
    onOpen: '&',
    onClose: '&',
    classes: '@',
  },

  /**
   * Controller
   */
  controller(
    $q, $timeout, $document, $transitions,
    Interface, CardCloseReasons
  ) {

    /**
     * Helper to close cards on body click
     */
    this.closeOnBodyClick = function(event) {
      if (!event.defaultPrevented) {
        if (Interface.isTarget('AppContents', event) &&
          !Interface.isTarget('Card', event)) {
          $timeout(() => this.close(CardCloseReasons.BODY));
        }
      }
    };

    /**
     * On init
     */
    this.$onInit = function() {

      //Initialize cards map
      this.cards = new Map();

      //Close cards on body click
      this.boundClickHandler = this.closeOnBodyClick.bind(this);
      $document.on('click', this.boundClickHandler);

      //Prevent navigation if cards are open
      this.transitionHookUnbind = $transitions.onBefore({}, transition => {
        if (this.hasOpen()) {

          //If the transition is ignored, we don't need to check
          //E.g. going to the same page
          if (transition.ignored()) {
            return;
          }

          //Check where we want to go
          const to = transition.to();

          //If going to login page, we are likely logged out, so just close
          if (to.name === 'login') {
            return;
          }

          //Try to close the card and try again transition again
          return this
            .close(CardCloseReasons.TRANSITION)
            .then(() => {
              //NOTE: Cannot return this from the promise, or it will not
              //navigate to the page
              transition.router.stateService.target(to);
            });
        }
      });
    };

    /**
     * On destroy
     */
    this.$onDestroy = function() {

      //Remove click handler
      $document.off('click', this.boundClickHandler);

      //Unbind transition hook
      this.transitionHookUnbind();
    };

    /**
     * Add a card
     */
    this.add = function(id, card) {
      this.cards.set(id, card);
    };

    /**
     * Remove a card
     */
    this.remove = function(id) {
      this.cards.delete(id);
    };

    /**
     * Open a card
     */
    this.open = function(id, reason) {

      //Validate
      if (!id || !this.cards.has(id)) {
        throw new Error('Unknown card: ' + id);
      }

      //Close opened card first
      if (this.opened) {
        if (this.opened.id === id) {
          return $q.reject(CardCloseReasons.ALREADY_OPEN);
        }
        return this
          .close(reason || CardCloseReasons.OPENED_OTHER)
          .then(() => this.open(id));
      }

      //Get card and run before open
      const card = this.cards.get(id);
      card.setIsOpening(true);

      //Run before open handler, then proceed
      return this
        .runHandler(card, 'beforeOpen')
        .then(() => {
          card.doOpen();
          this.opened = card;
          this.onOpen({$event: {card}});
        })
        .catch(() => {
          card.setIsOpening(false);
        });
    };

    /**
     * Close open card
     */
    this.close = function(reason = CardCloseReasons.CLOSE) {

      //Must have an opened card
      if (!this.opened) {
        return $q.resolve();
      }

      //Get card
      const card = this.opened;

      //Don't close this card on body clicks if configured as such
      if (reason === CardCloseReasons.BODY && card.preventBodyClose) {
        return $q.resolve();
      }

      //Call card handlers
      return this
        .runHandler(card, 'beforeClose', reason)
        .then(() => {
          card.doClose(reason);
          card.scrollIntoView();
          this.opened = null;
          this.onClose({$event: {card, reason}});
        });
    };

    /**
     * Open next card
     */
    this.next = function() {

      //Must have open card
      if (!this.opened) {
        return $q.reject('No open card');
      }

      //Get card IDs
      const ids = Array.from(this.cards.keys());

      //Find next one
      const i = ids.findIndex(id => id === this.opened.id) + 1;
      if (i < ids.length) {
        return this.open(ids[i], CardCloseReasons.OPENED_NEXT);
      }

      //Not found
      return $q.reject('No next card found');
    };

    /**
     * Open previous card
     */
    this.previous = function() {

      //Must have open card
      if (!this.opened) {
        return $q.reject('No open card');
      }

      //Get card IDs
      const ids = Array.from(this.cards.keys());

      //Find previous one
      for (let i = ids.length - 1; i >= 0; i--) {
        if (ids[i] === this.opened.id) {
          i--;
          if (i >= 0) {
            return this.open(ids[i], CardCloseReasons.OPENED_PREV);
          }
          break;
        }
      }

      //Not found
      return $q.reject('No previous card found');
    };

    /**
     * Check if a card is open
     */
    this.isOpen = function(id) {
      if (!id || !this.opened) {
        return false;
      }
      return (this.opened.id === id);
    };

    /**
     * Check if we have any opened cards
     */
    this.hasOpen = function() {
      return !!this.opened;
    };

    /**
     * Run before/after open/close handlers
     */
    this.runHandler = function(card, handler, reason) {
      if (card && angular.isFunction(card[handler])) {
        const result = card[handler].call(card, reason);
        return result || $q.resolve();
      }
      return $q.resolve();
    };
  },
});
