
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.AgreeConditions.Card', [])

/**
 * Component
 */
.component('cardCouponAgreeConditions', {
  templateUrl: 'coupon/cards/agree-conditions.html',
  controller: 'CardCouponAgreeConditionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    selection: '<',
    hasAgreed: '<',
    onChange: '&',
    onNext: '&',
    onPrev: '&',
    onCancel: '&',
  },
})

/**
 * Controller
 */
.controller('CardCouponAgreeConditionsCtrl', function() {

  /**
   * Change value
   */
  this.change = function(hasAgreed) {
    this.onChange({$event: {hasAgreed}});
  };

  /**
   * Previous step
   */
  this.prev = function() {
    this.onPrev();
  };

  /**
   * Next step
   */
  this.next = function() {
    if (this.validate()) {
      this.onNext();
    }
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };
});
