
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Edit.Usage.Card', [])

/**
 * Component
 */
.component('cardCouponTypeEditUsage', {
  templateUrl: 'admin/coupon-type/cards/usage.html',
  controller: 'CardCouponTypeEditUsageCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    couponType: '<',
    activities: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardCouponTypeEditUsageCtrl', function(
  CardCloseReasons, Modules, CouponTypeUses, CouponTypeTypes, Helpers
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;
    this.CouponTypeTypes = CouponTypeTypes;
    this.couponTypeUses = CouponTypeUses;

    //Filter out relevant activities
    this.activities = this.activities
      .filter(activity => activity.modes.length > 0);

    //Create model subset
    this.model = this.couponType.extract([
      'type', 'numSessions', 'usableFor', 'activities',
    ]);

    //Remove bookings option if module not enabled
    if (!Modules.has('bookings')) {
      this.couponTypeUses
        = Helpers.removeOption(CouponTypeUses, CouponTypeUses.BOOKINGS);
    }

    //Check flags
    this.checkUsableForBookings();
    this.checkSessionBased();
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Check if usable for bookings
   */
  this.checkUsableForBookings = function() {
    this.usableForBookings = (
      this.model.usableFor &&
      this.model.usableFor.some(item => item === CouponTypeUses.BOOKINGS)
    );
  };
  /**
   * Check if session based
   */
  this.checkSessionBased = function() {
    this.isSessionBased = (
      this.model.type === CouponTypeTypes.SESSION_BASED
    );
  };

  /**
   * Set type and check flag
   */
  this.setType = function(type) {
    this.model.type = type;
    if (type === CouponTypeTypes.SESSION_BASED) {
      if (!this.model.numSessions) {
        this.model.numSessions = 1;
      }
    }
    else {
      this.model.numSessions = 0;
      this.setUsableFor([]);
    }
    this.checkSessionBased();
  };

  /**
   * Set usable for
   */
  this.setUsableFor = function(usableFor) {
    this.isErrorNoActivities = false;
    this.model.usableFor = usableFor;
    if (!usableFor.some(value => value === CouponTypeUses.BOOKINGS)) {
      this.model.activities = [];
    }
    this.checkUsableForBookings();
  };

  /**
   * Has activity model
   */
  this.hasActivityModel = function(activity) {
    return this.model.activities.some(a => a.id === activity.id);
  };

  /**
   * Get activity model
   */
  this.getActivityModel = function(activity) {
    return this.model.activities.find(a => a.id === activity.id);
  };

  /**
   * Toggle mode
   */
  this.toggleMode = function(activity, modeIds) {

    //Clear error
    this.isErrorNoActivities = false;

    //Get activities from model
    const {activities} = this.model;

    //Find activity in model and create if needed
    const i = activities.findIndex(a => a.id === activity.id);
    if (i === -1) {
      activities.push({
        id: activity.id,
        modes: [],
      });
    }

    //No modes left, remove activity
    if (modeIds.length === 0) {
      activities.splice(i, 1);
      return;
    }

    //Get activity model now and set modes
    const activityModel = activities.find(a => a.id === activity.id);
    activityModel.modes = modeIds;
  };

  /**
   * Validate
   */
  this.validate = function() {

    //Reset errors
    this.isErrorNoActivities = false;

    //Check if form valid
    this.form.$setSubmitted();
    if (!this.form.$valid) {
      return false;
    }

    //If creating a coupon type for bookings, must have at least one activity
    if (this.usableForBookings && this.model.activities.length === 0) {
      this.isErrorNoActivities = true;
      return false;
    }

    //All good
    return true;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {couponType, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {couponType, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
