
/**
 * Module definition and dependencies
 */
angular.module('Shared.EventCategory.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('eventCategory', {
    model: 'EventCategory',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'eventCategories',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('eventCategories', {
    model: 'EventCategory',
    dataKey: 'eventCategories',
    cacheEmpty: true,
  });
})

/**
 * Model definition
 */
.factory('EventCategory', function($api, $store, $baseModel) {

  /**
   * Constructor
   */
  function EventCategory(data) {
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(EventCategory.prototype, $baseModel.prototype);

  /**
   * Copy method
   */
  EventCategory.prototype.copy = function() {
    const {
      name, color,
    } = this;
    return new EventCategory({
      name, color,
    });
  };

  /**
   * Set store reference
   */
  EventCategory.store = $store.eventCategories;

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save new item
   */
  EventCategory.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    return $api.eventCategory.create(data).then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  EventCategory.prototype.patch = function(data) {
    const {id} = this;
    return $api.eventCategory
      .patch({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  EventCategory.prototype.delete = function() {
    return $api.eventCategory.delete(null, this).then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  EventCategory.query = function(filter) {
    return $api.eventCategory.query(filter);
  };

  /**
   * Find by ID
   */
  EventCategory.findById = function(id) {
    return $api.eventCategory.get({id});
  };

  //Return
  return EventCategory;
});
