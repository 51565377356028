
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.WelcomeText.Card', [])

/**
 * Component
 */
.component('cardClubWelcomeText', {
  templateUrl: 'admin/club/cards/welcome-text.html',
  controller: 'CardClubWelcomeTextCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    texts: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubWelcomeTextCtrl', function(
  CardCloseReasons, TextTypes, Settings
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Options for text alignment
    this.textAlignmentOptions = [
      {
        label: 'Center',
        value: 'center',
      },
      {
        label: 'Left',
        value: 'left',
      },
      {
        label: 'Right',
        value: 'right',
      },
    ];

    //Model for homepage settings
    this.homepage = Settings.extract('homepage');

    //Find relevant texts
    this.welcomeMessage = this.texts
      .find(text => text.type === TextTypes.WELCOME_MESSAGE);
    this.welcomeEmail = this.texts
      .find(text => text.type === TextTypes.WELCOME_EMAIL);

    //Reset their models
    if (this.welcomeMessage) {
      this.welcomeMessage.resetModel();
    }
    if (this.welcomeEmail) {
      this.welcomeEmail.resetModel();
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.homepage;
    target[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get model
    const {texts, homepage} = this;
    const settings = {homepage};

    //Save
    this
      .onSave({$event: {texts, settings}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
        this.club.markStepComplete('changeWelcomeText', true);
      })
      .finally(() => this.isSaving = false);
  };
});
