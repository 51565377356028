
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area.Add.Controller', [])

/**
 * Controller
 */
.controller('AdminAreaAddCtrl', function(
  $controller, $state, CardCloseReasons
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminAreaCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {
    this.isSuper = this.user.isSuper();
  };

  /**
   * Exit handler
   */
  this.exit = function($event) {

    //Get reason
    const {reason} = $event;

    //If card was closed, go back to overview
    if (reason === CardCloseReasons.CLOSE) {
      $state.go('admin.area.overview');
    }
    else if (reason === CardCloseReasons.SAVED) {
      $state.go('admin.area.edit', {areaId: this.area.id});
    }
  };
});
