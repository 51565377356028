
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.AccessLogs.Card', [])

/**
 * Component
 */
.component('cardMemberAccessLogs', {
  templateUrl: 'admin/people/members/cards/access-logs.html',
  controller: 'CardMemberAccessLogsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    logs: '<',
    hasMore: '<',
    onLoadMore: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberAccessLogsCtrl', function() {

  /**
   * On init
   */
  this.$onInit = function() {
    this.isSaving = false;
    this.hasAny = this.logs.length ? true : false;
  };

  /**
   * Load more
   */
  this.loadMore = function() {
    this.isLoadingMore = true;
    this
      .onLoadMore()
      .finally(() => this.isLoadingMore = false);
  };
});
