
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.Card.BaseController', [])

/**
 * Controller
 */
.controller('CardSettingsBaseCtrl', function(Settings, Durations) {

  /**
   * Init
   */
  this.$onInit = function() {

    //Must have type defined
    if (!this.type) {
      return;
    }

    //Set options
    this.Durations = Durations;

    //Model
    this.model = Settings.extract(this.type);

    //Flags
    this.isSuper = (this.user && this.user.isSuper());
    this.isSaving = false;
    this.isError = false;
    this.isSuccess = false;
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    if (this.form) {
      this.form.$setSubmitted();
      if (this.form.$invalid) {
        return;
      }
    }

    //Set flags
    this.isSaving = true;
    this.isError = false;

    //Create settings subset
    const {model} = this;
    const settings = {[this.type]: model};

    //Save
    return this
      .onSave({$event: {settings}})
      .then(() => {
        if (this.form) {
          this.form.$setPristine();
          this.card.close();
        }
        this.onSaved();
      })
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };

  /**
   * On saved handler
   */
  this.onSaved = function() {
    if (this.setupStep) {
      this.club.markStepComplete(this.setupStep, true);
    }
  };
});
