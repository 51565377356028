
/**
 * Module definition and dependencies
 */
angular.module('Shared.Event.Model', [
  'BaseModel.Service',
  'Shared.Recurrence.Model',
  'Shared.Event.Part.Model',
  'Shared.Event.Rule.Model',
  'Shared.Event.Question.Model',
  'Shared.Event.Attendee.Model',
  'Shared.Event.Interest.Model',
  'Shared.Event.Notification.Model',
  'Shared.EventStore.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('event', {
    model: 'Event',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'events',
        isArray: true,
        isModel: true,
        withCredentials: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        url: ':method',
        method: 'PUT',
      },
      patch: {
        url: ':method',
        method: 'PATCH',
      },
      updateRecurrence: {
        url: 'recurrence',
        method: 'PUT',
      },
      delete: {
        url: ':method',
        method: 'DELETE',
      },
      removeMany: {
        url: 'removeMany',
        method: 'POST',
      },
      addToCategories: {
        url: 'addToCategories',
        method: 'POST',
      },
      deleteCustomFile: {
        url: 'file/:field/:filename',
        method: 'DELETE',
      },
      deleteBanner: {
        url: 'banner',
        method: 'DELETE',
      },
      getOwnAttendance: {
        url: 'attendance/own',
        method: 'GET',
      },
      getAttendance: {
        url: 'attendance',
        method: 'GET',
      },
      getPublicAttendance: {
        url: 'attendance/public',
        method: 'GET',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('events', {
    model: 'Event',
    dataKey: 'events',
    service: 'EventStore',
  });
})

/**
 * Model definition
 */
.factory('Event', function(
  $api, $sync, moment, $baseModel, $injector, $httpParamSerializer,
  Config, Intercom, MembershipConstraints, EventRuleTypes, EventAttendee,
  EventInterest, $storage
) {

  /**
   * Constants
   */
  const {
    FREE,
  } = EventRuleTypes;
  const {
    ALL,
    GUEST,
  } = MembershipConstraints;

  /**
   * Constructor
   */
  function Event(data) {

    //Get default date
    const defaultDate = moment();
    if (defaultDate.hour() >= 20) {
      defaultDate.add(1, 'day').hour(9);
    }
    defaultDate.add(1, 'hour').startOf('hour');

    //Default data (dynamic, because of date)
    const defaultData = {
      type: 'local',
      areas: [],
      organisers: [],
      activity: null,
      recurrence: null,
      isMultiDay: false,
      isRecurring: false,
      maxAttendees: null,
      startDate: defaultDate.clone(),
      endDate: defaultDate.clone().add(1, 'hour'),
    };

    //Base model constructor
    $baseModel.call(this, angular.extend({}, defaultData, data || {}));

    //Duration property
    Object.defineProperty(this, 'duration', {
      get() {
        if (!this.startDate || !this.endDate) {
          return 0;
        }
        return this.endDate.diff(this.startDate, 'minutes');
      },
      set() {},
    });

    //Start time virtual property
    Object.defineProperty(this, 'startTime', {
      get() {
        if (!this.startDate) {
          return null;
        }
        return this.startDate.getTime();
      },
      set(time) {
        if (this.startDate) {
          this.startDate.setTime(time);
          if (this.endTime !== null) {
            this.endDate = this.startDate.clone().setTime(this.endTime);
          }
        }
      },
    });

    //End time virtual property
    Object.defineProperty(this, 'endTime', {
      get() {
        if (!this.endDate) {
          return null;
        }
        return this.endDate.getTime(true);
      },
      set(time) {
        if (time === 0) {
          time = 1440;
        }
        if (this.startDate) {
          this.endDate = this.startDate.clone().setTime(time);
        }
      },
    });

    //Weekday of this event
    Object.defineProperty(this, 'weekday', {
      get() {
        if (!this.startDate) {
          return null;
        }
        return this.startDate.isoWeekday();
      },
    });

    //Local
    Object.defineProperty(this, 'isLocal', {
      get() {
        return (this.type === 'local');
      },
      set() {},
    });

    //External
    Object.defineProperty(this, 'isExternal', {
      get() {
        return (this.type === 'external');
      },
      set() {},
    });

    //Virtual
    Object.defineProperty(this, 'isVirtual', {
      get() {
        return (this.type === 'virtual');
      },
      set() {},
    });

    //Is past
    Object.defineProperty(this, 'isPast', {
      get() {
        return moment().isAfter(this.endDate);
      },
      set() {},
    });

    //Is upcoming
    Object.defineProperty(this, 'isUpcoming', {
      get() {
        return moment().isBefore(this.startDate);
      },
      set() {},
    });

    //Is in progress
    Object.defineProperty(this, 'isInProgress', {
      get() {
        return !this.isPast && !this.isUpcoming;
      },
      set() {},
    });

    //First date of the event (if series)
    Object.defineProperty(this, 'firstDate', {
      get() {
        if (this.isMultiDay) {
          return this.multiDay.firstDate;
        }
        if (this.isRecurring) {
          return this.recurrence.firstDate;
        }
        return this.startDate;
      },
    });

    //Last date of the event (if series)
    Object.defineProperty(this, 'lastDate', {
      get() {
        if (this.isMultiDay) {
          return this.multiDay.lastDate;
        }
        if (this.isRecurring) {
          return this.recurrence.lastDate;
        }
        return this.endDate;
      },
    });

    //Check if event has questions
    Object.defineProperty(this, 'hasQuestions', {
      get() {
        return (this.questions.length > 0);
      },
      set() {},
    });

    //Check if event has public questions
    Object.defineProperty(this, 'hasPublicQuestions', {
      get() {
        return (this.questions.filter(q => q.isVisible).length > 0);
      },
      set() {},
    });

    //Check if event has rules
    Object.defineProperty(this, 'hasRules', {
      get() {
        return (this.rules.length > 0);
      },
      set() {},
    });

    //Check if event has enabled member rules
    Object.defineProperty(this, 'hasEnabledMemberRules', {
      get() {
        return this.rules
          .filter(rule => rule.isEnabled)
          .some(rule => rule.constraint !== MembershipConstraints.GUEST);
      },
      set() {},
    });

    //Check if event has areas selected
    Object.defineProperty(this, 'hasAreas', {
      get() {
        return (this.areas.length > 0);
      },
      set() {},
    });

    //Check if event has multiple areas selected
    Object.defineProperty(this, 'hasMultipleAreas', {
      get() {
        return (this.areas.length > 1);
      },
      set() {},
    });

    //Check if event has organisers
    Object.defineProperty(this, 'hasOrganisers', {
      enumerable: false,
      get() {
        return (this.organisers.length > 0);
      },
      set() {},
    });

    //Check if event has multiple organisers
    Object.defineProperty(this, 'hasMultipleOrganisers', {
      get() {
        return (this.organisers.length > 1);
      },
      set() {},
    });

    //Check if event has limited recurrence
    Object.defineProperty(this, 'hasLimitedRecurrence', {
      get() {
        return (this.isRecurring && this.recurrence.ends !== 'never');
      },
      set() {},
    });

    //Ages
    Object.defineProperty(this, 'hasAgeRestriction', {
      get() {
        const {minAge, maxAge} = this;
        return !!(minAge || maxAge);
      },
    });

    //Ages
    Object.defineProperty(this, 'ageRestriction', {
      get() {
        const {minAge, maxAge} = this;
        if (minAge && maxAge) {
          return `between ${minAge} and ${maxAge} years`;
        }
        if (minAge) {
          return `${minAge} years or over`;
        }
        if (maxAge) {
          return `${maxAge} years or under`;
        }
        return '';
      },
    });

    //Category names
    Object.defineProperty(this, 'categoryNames', {
      get() {
        return this.categories
          .map(category => category.name)
          .join(', ');
      },
    });

    //Ages
    Object.defineProperty(this, 'ages', {
      get() {
        const {minAge, maxAge} = this;
        if (minAge && maxAge) {
          return `${minAge}–${maxAge}`;
        }
        if (minAge) {
          return `${minAge}+`;
        }
        if (maxAge) {
          return `<${maxAge + 1}`;
        }
        return '';
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Event.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Event.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('recurrence', 'Recurrence');
    this.convertToModel('organisers', 'Member', true);
    this.convertToModel('address', 'Address');
    this.convertToModel('rules', 'EventRule', true);
    this.convertToModel('questions', 'EventQuestion', true);
    this.convertToModel('notifications', 'EventNotification', true);
    this.convertToModel('lastAttendees', 'Member', true);

    //Default auto light/door data
    if (!this.autoLights) {
      this.autoLights = {turnOn: null, turnOff: null};
    }
    if (!this.autoDoors) {
      this.autoDoors = {open: null, close: null};
    }

    //Convert auto time properties to string value
    this.autoLights.turnOn = this.autoTimeToString(this.autoLights.turnOn);
    this.autoLights.turnOff = this.autoTimeToString(this.autoLights.turnOff);
    this.autoDoors.open = this.autoTimeToString(this.autoDoors.open);
    this.autoDoors.close = this.autoTimeToString(this.autoDoors.close);

    //Remove parts
    delete this.parts;

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Event.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Remove recurrence if not recurring
    if (!json.isRecurring && json.recurrence) {
      json.recurrence = null;
    }

    //Remove parts if not multi-day
    if (!json.isMultiDay) {
      delete json.parts;
    }

    //No max attendees
    if (!json.maxAttendees) {
      json.maxAttendees = null;
    }

    //Convert auto time properties to string value
    json.autoLights.turnOn = this.autoTimeFromString(json.autoLights.turnOn);
    json.autoLights.turnOff = this.autoTimeFromString(json.autoLights.turnOff);
    json.autoDoors.open = this.autoTimeFromString(json.autoDoors.open);
    json.autoDoors.close = this.autoTimeFromString(json.autoDoors.close);

    //Strip data
    json.activity = $baseModel.onlyId(json.activity);
    json.areas = $baseModel.onlyId(json.areas);
    json.organisers = $baseModel.onlyId(json.organisers);
    json.categories = $baseModel.onlyId(json.categories);

    //Strip data
    delete json.hasLimitedRecurrence;
    delete json.lastAttendees;
    delete json.startTime;
    delete json.endTime;

    //Return
    return json;
  };

  /**
   * Clone
   */
  Event.prototype.clone = function(stripId) {
    const clone = $baseModel.prototype.clone.call(this, stripId);

    //Strip unneeded data
    delete clone.series;
    delete clone.club;
    delete clone.timezone;
    delete clone.multiDay;
    delete clone.isOnGoogleCalendar;
    delete clone.numComments;
    delete clone.numAttendees;
    delete clone.numAttended;
    delete clone.numInterested;
    delete clone.numSpacesLeft;
    delete clone.hasSpacesLeft;
    delete clone.hasLimitedSpacesLeft;
    delete clone.isLimitedSeries;
    delete clone.lastAttendees;
    delete clone.customFiles;
    delete clone.notification;
    delete clone.createdAt;
    delete clone.updatedAt;
    delete clone.isAttending;
    delete clone.isInterested;
    delete clone.isDemo;

    //Strip google calendar object if there is one
    if (clone.googleCalendar && clone.googleCalendar.calendarEvents) {
      delete clone.googleCalendar.calendarEvents;
    }

    //Delete notification due and sent dates
    if (Array.isArray(clone.notifications)) {
      for (const notification of clone.notifications) {
        delete notification.id;
        delete notification.dueDate;
        delete notification.sentDate;
        delete notification.isSent;
      }
    }

    //Return the clone
    return clone;
  };

  /**
   * Convert auto time value to string
   */
  Event.prototype.autoTimeToString = function(autoTime) {

    //No data
    if (!autoTime) {
      return null;
    }

    //Already a string
    if (typeof autoTime === 'string') {
      return autoTime;
    }

    //Get data
    const {minutes, relativeTo} = autoTime;
    return `${minutes}:${relativeTo}`;
  };

  /**
   * Convert auto time value from string
   */
  Event.prototype.autoTimeFromString = function(autoTime) {

    //No data
    if (!autoTime) {
      return null;
    }

    //Already an object
    if (typeof autoTime === 'object') {
      return autoTime;
    }

    //Get data
    const parts = autoTime.split(':');
    return {
      minutes: Number(parts[0]),
      relativeTo: parts[1],
    };
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Get custom file download URL
   */
  Event.prototype.getCustomFileUrl = function(field) {

    //Get data
    const {id, customFiles} = this;
    const {name} = customFiles[field];
    const Auth = $injector.get('Auth');
    const token = Auth.getAccessToken();
    const qs = $httpParamSerializer({access_token: token});
    const base = Config.api.baseUrl;

    //Build URL
    return `${base}/event/${id}/file/${field}/${name}?${qs}`;
  };

  /**
   * Save
   */
  Event.prototype.save = function(data, method = 'instance') {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Don't need method for create op
    if (!this.id) {
      method = undefined;
    }

    //Determine type and call API
    const type = this.id ? 'update' : 'create';
    return $api.event[type]({method}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  Event.prototype.patch = function(data, method = 'instance') {

    //Get ID
    const {id} = this;

    //Convert auto time properties to string value
    if (data.autoLights) {
      data.autoLights.turnOn = this.autoTimeFromString(data.autoLights.turnOn);
      data.autoLights.turnOff = this.autoTimeFromString(data.autoLights.turnOff);
    }
    if (data.autoDoors) {
      data.autoDoors.open = this.autoTimeFromString(data.autoDoors.open);
      data.autoDoors.close = this.autoTimeFromString(data.autoDoors.close);
    }

    //Strip data
    if (data.activity) {
      data.activity = $baseModel.onlyId(data.activity);
    }
    if (data.areas) {
      data.areas = $baseModel.onlyId(data.areas);
    }
    if (data.organisers) {
      data.organisers = $baseModel.onlyId(data.organisers);
    }
    if (data.categories) {
      data.categories = $baseModel.onlyId(data.categories);
    }

    //Delete helper data
    delete data.hasLimitedRecurrence;
    delete data.lastAttendees;
    delete data.startTime;
    delete data.endTime;

    //Call API
    return $api.event
      .patch({id, method}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Update recurrence
   */
  Event.prototype.updateRecurrence = function(model) {
    const {id} = this;
    const {isConfirmed, recurrence} = model;
    return $api.event
      .updateRecurrence({id}, {isConfirmed, recurrence})
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete event
   */
  Event.prototype.delete = function(method = 'instance') {
    return $api.event
      .delete({method}, this)
      .then(() => this);
  };

  /**
   * Delete banner
   */
  Event.prototype.deleteBanner = function(method) {
    return $api.event
      .deleteBanner({method}, this)
      .then(() => this.banner = null);
  };

  /**
   * Delete custom file
   */
  Event.prototype.deleteCustomFile = function(field, filename) {
    return $api.event
      .deleteCustomFile({field, filename}, this)
      .then(() => this.customFiles[field] = null);
  };

  /**
   * Check if event clashes with given time
   */
  Event.prototype.isClashing = function(time) {
    return (this.startTime <= time && this.endTime > time);
  };

  /**
   * Check if event is on (+/- minutes before/after)
   */
  Event.prototype.isOn = function(before, after) {
    const startDate = this.startDate.clone();
    const endDate = this.endDate.clone();
    if (before) {
      startDate.subtract(before, 'minutes');
    }
    if (after) {
      endDate.add(after, 'minutes');
    }
    return moment().isBetween(startDate, endDate, undefined, '[]');
  };

  /**
   * Check if we have a specific area
   */
  Event.prototype.hasArea = function(area) {
    return this.areas.some(c => area.isSame(c));
  };

  /**
   * Add organiser
   */
  Event.prototype.addOrganiser = function(member) {
    this.organisers.push(member);
  };

  /**
   * Check if a member is an organiser of this event
   */
  Event.prototype.isOrganiser = function(member) {
    if (!member) {
      return false;
    }
    return this.organisers.some(organiser => organiser.isSame(member));
  };

  /**
   * Check if a member can sign up to an event
   */
  Event.prototype.hasMatchingRules = function(member) {

    //Get data
    const {startDate, rules} = this;

    //No member
    if (!member) {
      return false;
    }

    //Get memberships for event date
    const memberships = member
      .getSubscriptionsForDate(startDate)
      .map(sub => sub.membership);

    //Get groups
    const {groups} = member;

    //Check rules
    return rules
      .filter(rule => rule.isEnabled && rule.isValid)
      .some(rule => rule.appliesToMemberships(memberships) || rule.appliesToGroups(groups));
  };

  /**
   * Check if a member can sign up to an event
   */
  Event.prototype.canSignUp = function(member) {

    //Get data
    const {
      isUpcoming, hasSpacesLeft, isAttending,
    } = this;

    //Check if we can sign up
    if (!isUpcoming || !hasSpacesLeft || isAttending) {
      return false;
    }

    //Check if we have matching rules
    return this.hasMatchingRules(member);
  };

  /**
   * Get matching guest rules
   */
  Event.prototype.getGuestRule = function() {

    //Filter guest rules
    const rules = this.rules.filter(rule => rule.appliesToGuest());
    const constraints = [GUEST, ALL];

    //Process in order of constraints
    for (const constraint of constraints) {

      //Find candidate rules
      const candidates = rules.filter(rule => rule.constraint === constraint);

      //No candidates found? Check next constraints
      if (candidates.length === 0) {
        continue;
      }

      //Find the best fee of the lot
      return candidates.reduce((best, rule) => {
        if (!best || rule.type === FREE || rule.fee < best.fee) {
          return rule;
        }
        return best;
      }, null);
    }

    //No rule
    return null;
  };

  /**
   * Check if an event is visible for a given member
   */
  Event.prototype.isVisibleFor = function(member) {

    //Not hidden
    if (!this.isHidden) {
      return true;
    }

    //No member
    if (!member) {
      return false;
    }

    //Check flags
    const isOrganiser = this.isOrganiser(member);
    const canView = member.hasRole('admin', 'eventManager', 'viewer');

    //Return check
    return (isOrganiser || canView);
  };

  /**
   * Has system control
   */
  Event.prototype.hasSystemControl = function(system, activities) {
    if (!this.isLocal || !system) {
      return false;
    }
    if (system.hasDoorControl) {
      return true;
    }
    return this.hasLightControl(system, activities);
  };

  /**
   * Has light control check
   */
  Event.prototype.hasLightControl = function(system, activities) {
    if (!this.isLocal || !system) {
      return false;
    }
    if (system.hasLightControl && activities.length > 0 && this.hasAreas) {
      return activities
        .some(a => a.id === this.activity.id && a.isOnSystem);
    }
    return false;
  };

  /**
   * Download attendance PDF list
   */
  Event.prototype.downloadAttendancePdf = function() {
    return $sync.download(`event/${this.id}/attendance/pdf`);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query events
   */
  Event.query = function(filter) {
    return $api.event.query(filter);
  };

  /**
   * Export
   */
  Event.export = function(filter) {
    Intercom.event('Exported events');
    return $sync.get('event/export/csv', filter, 'Exporting...');
  };

  /**
   * Find by ID
   */
  Event.findById = function(id, club) {

    //If club is passed, set the timezone (for viewing events)
    if (club) {
      const {timezone} = club;
      moment.tz.setDefault(timezone);
    }

    return $api.event.get({id});
  };

  /**
   * Remove multiple events
   */
  Event.removeMany = function(filter) {
    return $api.event.removeMany(filter);
  };

  /**
   * Add to categories
   */
  Event.addToCategories = function(filter, categories) {
    return $api.event.addToCategories(filter, {categories});
  };

  /**
   * Get own attendance for event
   */
  Event.getOwnAttendance = function(id, circleMemberId, subscriptionId) {
    if (!id) {
      throw new Error(`Missing event ID`);
    }
    return $api.event
      .getOwnAttendance({id, circleMemberId, subscriptionId})
      .then(data => {
        const options = data.options.map(option => new $baseModel(option));
        const attendee = data.attendee ? new EventAttendee(data.attendee) : null;
        const interest = data.interest ? new EventInterest(data.interest) : null;
        return {options, attendee, interest};
      });
  };

  /**
   * Get members attendance for event
   */
  Event.getAttendance = function(id, memberId, subscriptionId) {
    if (!id) {
      throw new Error(`Missing event ID`);
    }
    return $api.event
      .getAttendance({id, memberId, subscriptionId})
      .then(data => {
        const options = data.options.map(option => new $baseModel(option));
        const attendee = data.attendee ? new EventAttendee(data.attendee) : null;
        const interest = data.interest ? new EventInterest(data.interest) : null;
        return {options, attendee, interest};
      });
  };

  /**
   * Get public attendance for event
   */
  Event.getPublicAttendance = function(id) {
    return $api.event
      .getPublicAttendance({id})
      .then(data => {
        const options = data.options.map(option => new $baseModel(option));
        return {options};
      });
  };

  //Return
  return Event;
});
