
/**
 * Define values
 */
const IBUTTON = 'ibutton';
const CARD = 'card';
const TAG = 'tag';
const PIN = 'pin';
const NFC = 'nfc';

/**
 * Define array
 */
const TagTypes = [
  {
    value: IBUTTON,
    label: 'iButton',
  },
  {
    value: CARD,
    label: 'RFID card',
  },
  {
    value: TAG,
    label: 'RFID tag',
  },
  {
    value: PIN,
    label: 'Pin number',
  },
  {
    value: NFC,
    label: 'NFC',
  },
];

/**
 * Set properties
 */
TagTypes.IBUTTON = IBUTTON;
TagTypes.CARD = CARD;
TagTypes.TAG = TAG;
TagTypes.PIN = PIN;
TagTypes.NFC = NFC;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.TagTypes.Options', [])
  .constant('TagTypes', TagTypes);
