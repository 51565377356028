
/**
 * Module definition and dependencies
 */
angular.module('Shared.ProgressBar.Component', [])

/**
 * Alert component
 */
.component('progressBar', {
  template: `
    <div class="ProgressBar">
      <div class="ProgressBar-details">
        <span>{{$ctrl.label || 'Your progress'}}</span>
        <strong>{{$ctrl.progress}}%</strong>
      </div>
      <div class="ProgressBar-bg">
        <div class="ProgressBar-fill" ng-style="{width: $ctrl.progress + '%'}"></div>
      </div>
    </div>
  `,
  transclude: true,
  bindings: {
    title: '@',
    progress: '<',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * On init
     */
    this.$onInit = function() {

    };
  },
});
