
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.MemberOptions.Component', [])

/**
 * Member options component
 */
.component('memberOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="$ctrl.member.isPending && !$ctrl.member.isArchived && $ctrl.canApprove">
        <a ng-click="$ctrl.approve()">Approve</a>
      </li>
      <li ng-if="$ctrl.member.isPending && !$ctrl.member.isArchived && $ctrl.canReject" class="Dropdown-item--danger">
      <a ng-click="$ctrl.reject()">Reject</a>
      </li>
      <li ng-if="$ctrl.canEdit">
        <a ui-sref="admin.people.members.edit({memberId: $ctrl.member.id})">Edit</a>
      </li>
      <li ng-if="$ctrl.canSendEmail && $ctrl.member.email">
        <a ng-click="$ctrl.sendEmail()">Send email</a>
      </li>
      <li ng-if="$ctrl.canSendWelcomeEmail && !$ctrl.member.isArchived">
        <a ng-click="$ctrl.sendWelcomeEmail()">Send welcome email</a>
      </li>
      <li ng-repeat="integration in $ctrl.mailingIntegrations" ng-if="$ctrl.member.email && $ctrl.canAddToMailingList">
        <a ng-click="$ctrl.addToMailingList(integration.type)">Add to {{integration.name}}</a>
      </li>
      <li ng-if="$ctrl.canDownloadBadge">
        <a ng-click="$ctrl.downloadBadge()">Download badge</a>
      </li>
      <li>
        <a ui-sref="admin.log.audit({model: 'Member', id: $ctrl.member.id})">View audit log</a>
      </li>
      <li ng-if="!$ctrl.isSelf && $ctrl.canMerge && !$ctrl.member.isAccountOwner" class="Dropdown-item--warning">
        <a ng-click="$ctrl.merge()">Merge</a>
      </li>
      <li ng-if="!$ctrl.isSelf && $ctrl.isSuspendable && $ctrl.canSuspend" ng-class="{'Dropdown-item--danger': !$ctrl.member.isSuspended}">
        <a ng-click="$ctrl.toggleSuspended()">{{$ctrl.member.isSuspended ? 'Un-suspend' : 'Suspend'}}</a>
        </li>
      <li>
      <li ng-if="!$ctrl.member.isArchived && !$ctrl.isSelf && !$ctrl.member.isAccountOwner && $ctrl.canArchive" class="Dropdown-item--danger">
        <a ng-click="$ctrl.toggleArchived()">Archive</a>
      </li>
      <li ng-if="$ctrl.member.isArchived && !$ctrl.isSelf && $ctrl.canRestore">
        <a ng-click="$ctrl.toggleArchived()">Restore</a>
      </li>
      <li ng-if="!$ctrl.isSelf && $ctrl.canDelete && $ctrl.member.isArchived" class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-menu>
  `,
  bindings: {
    user: '<',
    member: '<',
    integrations: '<',
    canEdit: '<',
    canDelete: '<',
    canArchive: '<',
    canRestore: '<',
    canApprove: '<',
    canSuspend: '<',
    canReject: '<',
    canMerge: '<',
    canAddToMailingList: '<',
    canSendEmail: '<',
    canSendWelcomeEmail: '<',
    canDownloadBadge: '<',
    onDelete: '&',
    onApprove: '&',
    onReject: '&',
    onToggleSuspended: '&',
    onToggleArchived: '&',
    onSendEmail: '&',
    onSendWelcomeEmail: '&',
    onAddToMailingList: '&',
    onDownloadBadge: '&',
    onMerge: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Set data
      this.isSelf = this.user.isSame(this.member);
      this.isSuspendable = this.member.canBeSuspended();

      //Get mailing integrations
      this.mailingIntegrations = this.integrations
        .filter(int => int.isConnected && int.category === 'mailing');
    };

    /**
     * Delete
     */
    this.delete = function() {
      const {member} = this;
      this.onDelete({$event: {member}});
    };

    /**
     * Approve/disapprove
     */
    this.approve = function() {
      const {member} = this;
      this.onApprove({$event: {member}});
    };

    /**
     * Reject
     */
    this.reject = function() {
      const {member} = this;
      this.onReject({$event: {member}});
    };

    /**
     * Suspend/unsuspend
     */
    this.toggleSuspended = function() {
      const {member} = this;
      const isSuspended = !member.isSuspended;
      this.onToggleSuspended({$event: {member, isSuspended}});
    };

    /**
     * Archive/restore
     */
    this.toggleArchived = function() {
      const {member} = this;
      const isArchived = !member.isArchived;
      this.onToggleArchived({$event: {member, isArchived}});
    };

    /**
     * Merge
     */
    this.merge = function() {
      const {member} = this;
      this.onMerge({$event: {member}});
    };

    /**
     * Send welcome email
     */
    this.sendWelcomeEmail = function() {
      const {member} = this;
      this.onSendWelcomeEmail({$event: {member}});
    };

    /**
     * Send email
     */
    this.sendEmail = function() {
      const {member} = this;
      this.onSendEmail({$event: {member}});
    };

    /**
     * Download badge
     */
    this.downloadBadge = function() {
      const {member} = this;
      this.onDownloadBadge({$event: {member}});
    };

    /**
     * Add to mailing list
     */
    this.addToMailingList = function(type) {
      const {member} = this;
      this.onAddToMailingList({$event: {type, member}});
    };
  },
});
