
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.ManageFields.Card', [])

/**
 * Component
 */
.component('cardSettingsManageFields', {
  templateUrl: 'admin/settings/cards/manage-fields.html',
  controller: 'CardSettingsManageFieldsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    fields: '<',
    showEnabled: '<',
    showVisible: '<',
    showRequired: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSettingsManageFieldsCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Toggle field
   */
  this.toggleField = function(field, prop) {

    //Get model and determine new value
    const model = this.model.fields[field.prop];
    const value = !model[prop];

    //Update value
    this.modifyFieldModel(model, prop, value);

    //Create settings subset
    const settings = {[this.type]: this.model};
    const hideNotice = true;

    //Save
    this
      .onSave({$event: {settings, hideNotice}})
      .catch(() => this.modifyFieldModel(model, prop, !value));
  };

  /**
   * Modify a field model
   */
  this.modifyFieldModel = function(model, prop, value) {

    //Set the new prop value
    model[prop] = value;

    //Get data
    const {showEnabled} = this;
    const {isEnabled, isVisible, isRequired, inDirectory} = model;

    //Can't be required or in directory if not visible
    if (prop === 'isVisible' && !isVisible && isRequired) {
      model.isRequired = false;
    }
    if (prop === 'isVisible' && !isVisible && inDirectory) {
      model.inDirectory = false;
      model.inDirectoryByDefault = false;
    }

    //Can't be hidden if required
    if (prop === 'isRequired' && !isVisible && isRequired) {
      model.isVisible = true;
    }

    //Not managing enabled flag
    if (!showEnabled) {
      return;
    }

    //Can't be required, visible or in directory if not enabled
    if (prop === 'isEnabled' && !isEnabled && isRequired) {
      model.isRequired = false;
    }
    if (prop === 'isEnabled' && !isEnabled && isVisible) {
      model.isVisible = false;
    }
    if (prop === 'isEnabled' && !isEnabled && inDirectory) {
      model.inDirectory = false;
      model.inDirectoryByDefault = false;
    }

    //Can't be disabled if visible or required
    if (prop === 'isVisible' && isVisible && !isEnabled) {
      model.isEnabled = true;
    }
    if (prop === 'isRequired' && isRequired && !isEnabled) {
      model.isEnabled = true;
    }
  };
});
