
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.VerifyEmail.Controller', [])

/**
 * Controller
 */
.controller('VerifyEmailCtrl', function($store, User, Auth) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Get params and token
    const params = this.transition.params();
    const token = params.token;

    //Flags
    this.isVerified = false;
    this.isInvalid = false;
    this.isSendingVerificationMail = false;
    this.hasSentVerificationMail = false;

    //Get user instance if authenticated
    this.user = null;
    if (Auth.isAuthenticated()) {
      $store.user
        .get()
        .then(user => (this.user = user));
    }

    //Verify token
    User
      .verifyEmail(token)
      .then(() => {
        this.isVerified = true;
        if (this.user) {
          this.user.isEmailVerified = true;
        }
      })
      .catch(() => this.isInvalid = true);
  };

  /**
   * Resend verification email
   */
  this.resendVerificationEmail = function() {
    this.isSendingVerificationMail = true;
    this.user
      .sendVerificationEmail()
      .then(() => this.hasSentVerificationMail = true)
      .finally(() => this.isSendingVerificationMail = false);
  };
});
