
/**
 * Module definition and dependencies
 */
angular.module('Shared.Pagination.Component', [])

/**
 * Pagination component
 */
.component('pagination', {
  templateUrl: 'shared/pagination/pagination.html',
  bindings: {
    page: '<',
    numPages: '<',
    onChange: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Change handler
     */
    this.$onChanges = function() {

      //Create pages array
      if (this.numPages) {
        this.pages = [...Array(this.numPages + 1).keys()].slice(1);
      }
      else {
        this.pages = [];
      }

      //Reset page if invalid
      if (this.page > this.numPages || this.page < 1) {
        this.setPage(1);
      }

      //Set flags
      this.isLastPage = (this.page === this.numPages);
      this.isFirstPage = (this.page === 1);
    };

    /**
     * Set page
     */
    this.setPage = function(page) {
      this.onChange({page});
    };

    /**
     * Next page
     */
    this.nextPage = function() {
      if (this.page < this.numPages) {
        this.setPage(this.page + 1);
      }
    };

    /**
     * Previous page
     */
    this.previousPage = function() {
      if (this.page > 1) {
        this.setPage(this.page - 1);
      }
    };
  },
});
