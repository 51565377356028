
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.CheckIn.Controller', [])

/**
 * Controller
 */
.controller('CheckInCtrl', function($timeout, KeyDown, CheckInLog, Member) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set flags
    this.isCheckingIn = false;
    this.isCheckedIn = false;
    this.isError = false;

    //QR code scanner
    this.boundQrCodeListener = this.qrCodeListener.bind(this);
    KeyDown.addListener(this.boundQrCodeListener);
  };

  /**
   * QR code listener
   */
  this.qrCodeListener = function(buffer) {

    //Check buffer for known matches
    const memberMatch = buffer.match(/\/admin\/people\/members\/edit\/([a-f0-9]{24})/);
    if (!memberMatch) {
      return;
    }

    //Get member ID
    const memberId = memberMatch[1];

    //Toggle flag
    this.isCheckingIn = true;

    //Set data
    const data = {
      member: memberId,
      wasCheckedIn: true,
      type: 'Check-in',
      reason: 'QR code scanned',
    };

    //Create check in log
    CheckInLog
      .create(data)
      .then(log => {
        document.getElementById('audioCheckIn').play();
        this.isCheckedIn = true;
        this.member = log.member;
        $timeout(() => {
          this.isCheckedIn = false;
          this.member = null;
        }, 5000);
      })
      .catch(error => {
        document.getElementById('audioError').play();
        this.isError = true;
        this.error = error;
        this.member = (error.data && error.data.member) ?
          new Member(error.data.member) : null;
        $timeout(() => {
          this.isError = false;
          this.error = null;
          this.member = null;
        }, 5000);
      })
      .finally(() => this.isCheckingIn = false);

    //Return true to stop further processing
    return true;
  };
});
