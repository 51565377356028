
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Flow.SelectMembers.Component', [])

/**
 * Grid component
 */
.component('flowBookingSelectMembers', {
  templateUrl: 'booking/flow/select-members.html',
  bindings: {
    user: '<',
    club: '<',
    booking: '<',
    activity: '<',
    circleMembers: '<',
    canManageBookings: '<',
    onPrev: '&',
    onNext: '&',
    onClose: '&',
  },

  /**
   * Controller
   */
  controller($focus, Kiosk, ValidationError, Booking) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Easy access mode
      this.mode = this.booking.mode;

      //Set flags
      this.errorNoMembers = false;
      this.errorMinPeople = false;
      this.errorMaxPeople = false;
      this.errorMinVisitors = false;
      this.errorMaxVisitors = false;
      this.onlyOne = (this.mode.maxPeople === 1);
      this.oneOrMore = (this.mode.minPeople === 1 && this.mode.maxPeople > 1);
      this.onlyMore = (this.mode.minPeople > 1);
      this.recentMembersMap = new Map();

      //Load recent members if there is a user
      if (this.user && !this.booking.forOthers) {
        this.loadRecentMembers(this.user);
        this.determineAvailableCircleMembers();
      }
    };

    /**
     * Determine available circle members
     */
    this.determineAvailableCircleMembers = function() {
      this.availableCircleMembers = this.circleMembers
        .filter(member => !this.booking.hasMember(member));

      //Add user to circle member if we need to
      const showUser = !this.booking.hasMember(this.user);
      if (showUser && this.availableCircleMembers.length > 0) {
        this.availableCircleMembers.unshift(this.user);
      }
    };

    /**
     * Post link
     */
    this.$postLink = function() {
      $focus('members');
    };

    /**
     * Members changed
     */
    this.onMembersChanged = function() {

      //Get data
      const {booking} = this;
      const {forOthers, members} = booking;

      //Clear errors
      this.error = null;
      this.errorNoMembers = false;
      this.errorMaxPeople = false;
      this.errorMaxVisitors = false;
      this.preValidationError = null;

      //Check if there are enough members now
      if (booking.hasEnoughPeople()) {
        this.errorMinPeople = false;
      }
      if (booking.hasEnoughVisitors()) {
        this.errorMinVisitors = false;
      }

      //Pre-validate
      if (forOthers) {
        this.isBusy = true;
        booking
          .preValidate()
          .catch(error => {
            this.preValidationError = error;
          })
          .finally(() => this.isBusy = false);
      }

      //Load recent members
      this.loadRecentMembers(members[0]);

      //Determine circle members
      this.determineAvailableCircleMembers();
    };

    /**
     * Set members
     */
    this.setMembers = function(members) {
      this.booking.setMembers(...members);
      this.onMembersChanged();
    };

    /**
     * Add member
     */
    this.addMember = function(member) {
      const {mode} = this.booking;
      const {maxPeople, minVisitors} = mode;
      const canOnlyHaveOneMember = (minVisitors === 1 && maxPeople === 2) || (maxPeople === 1);
      const isCircleMember = this.circleMembers ? this.circleMembers.some(m => m.id === member.id) : false;
      const isUser = this.user ? this.user.id === member.id : false;

      //Reset current booking members if there can only be one member in the booking,
      //the booking is at it's max members and the member they are trying to add is
      //either a circle member or yourself
      if (canOnlyHaveOneMember && this.booking.hasMaxPeople() && (isCircleMember || isUser)) {
        this.booking.members = [];
      }

      //Check if we cannot add
      if (!canOnlyHaveOneMember && this.booking.hasMaxPeople()) {
        this.errorMaxPeople = true;
        return;
      }

      //Add member
      this.booking.addMember(member);
      this.onMembersChanged();
    };

    /**
     * Add visitor
     */
    this.addVisitor = function() {

      //Check if can add
      if (this.booking.hasMaxPeople()) {
        this.errorMaxPeople = true;
        return;
      }

      //Check if can add
      if (this.booking.hasMaxVisitors()) {
        this.errorMaxVisitors = true;
        return;
      }

      //Add visitor
      this.booking.addVisitor();
      this.onMembersChanged();
    };

    /**
     * Remove visitor
     */
    this.removeVisitor = function($event) {

      //Get index
      const {index} = $event;

      //Check if can remove
      if (this.booking.numVisitors === this.booking.mode.minVisitors) {
        this.errorMinVisitors = true;
        return;
      }

      //Remove visitor
      this.booking.removeVisitor(index);
      this.onMembersChanged();
    };

    /**
     * Determine recently picked
     */
    this.loadRecentMembers = function(target) {

      //No target member
      if (!target) {
        this.recentMembers = [];
        return;
      }

      //Already loaded?
      if (this.recentMembersMap.has(target.id)) {
        this.recentMembers = this.recentMembersMap.get(target.id);
        this.filterRecentMembers();
        return;
      }

      //Flag as loading
      this.isLoadingRecentMembers = true;

      //Get data
      const memberId = target.id;
      const activityId = this.booking.activity.id;

      //Load
      Booking
        .getRecentMembers(memberId, activityId)
        .then(members => {
          this.recentMembersMap.set(memberId, members);
          this.recentMembers = members;
          this.filterRecentMembers();
        })
        .finally(() => this.isLoadingRecentMembers = false);
    };

    /**
     * Filter recent members
     */
    this.filterRecentMembers = function() {
      this.recentMembers = this.recentMembers
        .filter(member => !this.booking.hasMember(member));
    };

    /**
     * Check if booking has members
     */
    this.checkBookingHasMembers = function() {
      return this.booking.members.length > 0;
    };

    /**
     * Previous step
     */
    this.prev = function() {
      this.onPrev();
    };

    /**
     * Next step
     */
    this.next = function() {

      //Ensure we have enough members
      if (!this.booking.hasEnoughPeople()) {
        this.errorMinPeople = true;
        return $focus('members');
      }

      //Ensure we have at least one member if in kiosk mode
      if (Kiosk.isEnabled() && !this.checkBookingHasMembers()) {
        this.errorNoMembers = true;
        return $focus('members');
      }

      //Next step
      this.isBusy = true;
      this
        .onNext()
        .catch(error => {
          if (error instanceof ValidationError) {
            return this.error = error;
          }
          throw error;
        })
        .finally(() => this.isBusy = false);
    };
  },
});
