
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Circles.Controller', [])

/**
 * Base controller
 */
.controller('AdminCirclesCtrl', function(
) {

});
