
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.EditRestriction.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editRestriction', {
    templateUrl: 'admin/activity/modals/edit-restriction.html',
    controller: 'ModalEditRestrictionCtrl',
    closeOnClick: false,
    resolve: {
      memberships($store) {
        'ngInject';
        return $store.memberships.query();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalEditRestrictionCtrl', function(
  $controller, $modalInstance, $filter,
  Weekdays, LimitUnits, MembershipConstraintLabels
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set initial step
    this.setStep('constraint');

    //Playing type restrictions have some limitations
    this.isForPlaying = (this.type === 'playing');

    //Extract model
    this.model = this.restriction.extract([
      'constraint', 'memberships', 'modes', 'timeRange', 'limit',
      'allowWithOthers', 'allowConsecutiveWithOthers',
      'disallowConsecutive', 'disallowConcurrent',
    ]);

    //Fill time range
    if (!this.isEdit && this.model.timeRange.isEmpty()) {
      this.model.timeRange.fillDays();
      this.model.timeRange.fillTime();
    }

    //Set flags
    this.isSaving = false;
    this.isError = false;

    //Filter memberships on activity
    this.memberships = this.memberships
      .filter(m => m.activities.some(id => id === this.activity.id));

    //Distribute memberships
    this.memberships = $filter('distribute')(this.memberships, 3);

    //Services
    this.Weekdays = Weekdays;
    this.LimitUnits = LimitUnits;
    this.membershipConstraints = MembershipConstraintLabels
      .filter(constraint => !constraint.isGuest);
  };

  /**
   * Next step
   */
  this.next = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Go to next step
    this.form.$setPristine();
    this.setStep('limit');
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };
});
