
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.Controller', [])

/**
 * Controller
 */
.controller('AdminMemberEditCtrl', function(
  $controller, $state, $modal, $q, $store, $timeout, $notice,
  Config, Upload, Member, EventAttendee, CouponType, Modules,
  Integration, Booking, moment, AccessLog, ActivityLog, CheckInLog,
  Transaction, Payment, AccountCredit
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminMemberCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get params
    const {
      showComments, showSubscriptions,
      showCoupons, showFinances, showPaymentSources,
    } = this.transition.params();

    //Check if can edit and system enabled
    this.isAdmin = this.user.isAdmin();
    this.isViewerOnly = !this.isAdmin && this.user.hasRole('viewer');
    this.isSelf = (this.user.id === this.member.id);
    this.hasGrades = this.activities.some(a => a.hasGrades);
    this.openCommentsCard = showComments;
    this.openSubsCard = showSubscriptions || showCoupons;
    this.openFinanceCard = showPaymentSources || showFinances;
    if (showCoupons) {
      this.openSubsTab = 'coupons';
    }
    if (showPaymentSources) {
      this.openFinanceTab = 'paymentSources';
    }
    this.currentBatchNum = 0;

    //Initialize data
    this.attendees = [];
    this.bookings = [];
    this.accessLogs = [];
    this.activityLogs = [];
    this.transactions = [];
    this.payments = [];
    this.accountCredits = [];
    this.batchNumAttendees = 0;
    this.batchNumBookings = 0;
    this.batchNumAccessLogs = 0;
    this.batchNumActivityLogs = 0;
    this.batchNumTransactions = 0;
    this.batchNumPayments = 0;
    this.batchNumAccountCredits = 0;

    //Check limits and load coupon types
    this.checkLimits();
    this.loadCouponTypes();
    this.loadStripeIntegration();

    //Determine subscription, coupon, resource card title
    this.determineSubscriptionCardItems();
  };

  /**
   * Load stripe integration
   */
  this.loadStripeIntegration = function() {
    Integration
      .findByType('stripe')
      .then(integration => {
        if (integration && integration.isConnected) {
          this.hasStripeIntegration = true;
        }
      });
  };

  /**
   * Load coupon types
   */
  this.loadCouponTypes = function() {
    CouponType
      .query({fields: 'name,price,isArchived'})
      .then(data => this.couponTypes = data.couponTypes);
  };

  /**
   * Determine the title for the subscriptions/coupon/resource card
   */
  this.determineSubscriptionCardItems = function() {

    //Get data
    const hasMemberships = Modules.has('memberships');
    const hasCoupons = Modules.has('coupons');
    const hasResources = Modules.has('resources');
    const names = [];

    //Get module names
    if (hasMemberships) {
      names.push(`Memberships`);
    }
    if (hasCoupons) {
      const {plural} = Modules.find('coupons');
      names.push(plural);
    }
    if (hasResources) {
      const {plural} = Modules.find('resources');
      names.push(plural);
    }

    //Set title
    this.subscriptionCardItems = names;
  };

  /**
   * Check limits
   */
  this.checkLimits = function() {
    Member
      .getLimits()
      .then(({hasReachedSoftLimit, hasReachedHardLimit}) => {
        this.hasReachedSoftLimit = hasReachedSoftLimit;
        this.hasReachedHardLimit = hasReachedHardLimit;
      });
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    $state.go('admin.people.members.overview');
  };

  /**
   * On saved
   */
  this.onSaved = function($event) {
    const {member} = $event;
    this.page.setTitle(member.name);
  };

  /**
   * On approved
   */
  this.onApproved = function() {
    this.member.isPending = false;
  };

  /**
   * On rejected
   */
  this.onRejected = function() {
    this.member.isRejected = true;
    this.member.isArchived = true;
  };

  /**
   * Update avatar
   */
  this.updateAvatar = function($event) {

    //Get data from event
    const {data, color} = $event;

    //Avatar changed?
    if (data && data.avatar) {

      //Check if we edited ourselves
      const isUser = this.user.isSame(this.member);

      //This nonsense is necessary because for some reason the avatar changes
      //won't propagate via the s3-image directive otherwise
      this.member.avatar = null;
      if (isUser) {
        this.user.avatar = null;
      }
      $timeout(() => {
        if (isUser) {
          this.user.avatar = angular.copy(data.avatar);
        }
        this.member.avatar = angular.copy(data.avatar);
      });

      //Done
      return $q.resolve();
    }

    //Color changed?
    else if (color) {
      return this.member
        .patch({color})
        .then(() => {
          if (this.user.isSame(this.member)) {
            this.user.color = color;
          }
        });
    }
  };

  /**
   * Upload avatar
   */
  this.uploadAvatar = function($event) {

    //Get file
    const {file} = $event;
    const {id} = this.member;
    const {baseUrl} = Config.api;

    //Upload
    return Upload.upload({
      url: `${baseUrl}/member/${id}/avatar`,
      data: {
        avatar: file,
      },
    });
  };

  /**
   * Delete avatar
   */
  this.deleteAvatar = function() {

    //Get member and define handler
    const member = this.member;
    const handler = function() {
      return member.deleteAvatar();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-delete-avatar.html',
        locals: {member, handler},
      })
      .result
      .then(() => {
        this.member.avatar = null;
        if (this.user.isSame(this.member)) {
          this.user.avatar = null;
        }
      });
  };

  /**
   * Load items
   */
  this.loadItems = function(...types) {

    //Get member ID and create promises
    const member = this.member.id;
    const promises = types
      .map(type => $store[type]
      .query({member})
      .then(items => {
        this[type] = items;
        if (type === 'subscriptions') {
          this.member.subscriptions = items.map(sub => sub);
        }
      }));

    //Resolve all data
    return $q.all(promises);
  };

  /**
   * Load financials
   */
  this.loadFinancials = function() {

    //Promises to load
    const promises = [
      this.loadTransactions(true),
      this.loadPayments(true),
      this.loadAccountCredits(true),
    ];

    return $q.all(promises);
  };

  /**
   * Load transactions
   */
  this.loadTransactions = function(reset = false) {

    //Reset
    if (reset) {
      this.transactions = [];
      this.batchNumTransactions = 0;
    }

    //Create filter
    const member = this.member.id;
    const limit = 50;
    const offset = this.batchNumTransactions * limit;
    const filter = {member, limit, offset};

    //Load transactions
    return Transaction
      .query(filter)
      .then(data => {
        this.transactions = this.transactions.concat(data.transactions);
        this.totalNumTransactions = data.meta.total;
        this.batchNumTransactions++;
        this.hasMoreTransactions = (
          this.totalNumTransactions > this.transactions.length
        );

        //Refresh member amounts
        this.member.amountOwing = data.meta.sums.totalUnpaid;
        if (this.user.isSame(this.member)) {
          this.user.amountOwing = this.member.amountOwing;
        }
      });
  };

  /**
   * Load payments
   */
  this.loadPayments = function(reset = false) {

    //Reset
    if (reset) {
      this.payments = [];
      this.batchNumPayments = 0;
    }

    //Create filter
    const member = this.member.id;
    const limit = 50;
    const sort = '-date';
    const offset = this.batchNumPayments * limit;
    const filter = {member, sort, limit, offset};

    //Load payments
    return Payment
      .query(filter)
      .then(data => {
        this.payments = this.payments.concat(data.payments);
        this.totalNumPayments = data.meta.total;
        this.batchNumPayments++;
        this.hasMorePayments = (this.totalNumPayments > this.payments.length);
      });
  };

  /**
   * Load account credits
   */
  this.loadAccountCredits = function(reset = false) {

    //Reset
    if (reset) {
      this.accountCredits = [];
      this.batchNumAccountCredits = 0;
    }

    //Create filter
    const member = this.member.id;
    const limit = 50;
    const sort = '-date';
    const offset = this.batchNumAccountCredits * limit;
    const filter = {member, sort, limit, offset};

    //Load account credits
    return AccountCredit
      .query(filter)
      .then(data => {
        this.accountCredits = this.accountCredits.concat(data.accountCredits);
        this.totalNumAccountCredits = data.meta.total;
        this.batchNumAccountCredits++;
        this.hasMoreAccountCredits = (
          this.totalNumAccountCredits > this.accountCredits.length
        );

        //Refresh member amounts
        this.member.accountCredit = data.sums.totalSum;
        if (this.user.isSame(this.member)) {
          this.user.accountCredit = this.member.accountCredit;
        }
      });
  };

  /**
   * Load activity items
   */
  this.loadActivityItems = function() {

    //Promises to load
    const promises = [
      this.loadAttendees(true),
      this.loadBookings(true),
    ];

    //Add more if we have a system
    if (this.system) {
      promises.push(this.loadAccessLogs(true));
      promises.push(this.loadActivityLogs(true));
    }

    //Load all
    return $q.all(promises);
  };

  /**
   * Load attendees
   */
  this.loadAttendees = function(reset = false) {

    //Reset
    if (reset) {
      this.attendees = [];
      this.batchNumAttendees = 0;
    }

    //Create filter
    //NOTE: Sorting by sign up date gives problems because MongoDB can return
    //results in an inconsistent order when there are multiple sign up dates
    //which are the same
    const member = this.member.id;
    const sort = '-event.startDate';
    const limit = 100; //NOTE increased from 25 due to #6893
    const offset = this.batchNumAttendees * limit;
    const filter = {member, sort, limit, offset};

    //Load attendees
    return EventAttendee
      .query(filter)
      .then(data => {
        this.attendees = this.attendees.concat(data.attendees);
        this.totalNumAttendees = data.meta.total;
        this.batchNumAttendees++;
        this.hasMoreAttendees = (this.totalNumAttendees > this.attendees.length);
      });
  };

  /**
   * Load bookings
   */
  this.loadBookings = function(reset = false) {

    //Reset
    if (reset) {
      this.bookings = [];
      this.batchNumBookings = 0;
    }

    //Create filter
    const member = this.member.id;
    const fromDate = moment().startOf('day').subtract(1, 'year');
    const toDate = moment().endOf('day').add(1, 'year');
    const limit = 100; //NOTE increased from 25 due to #6893
    const offset = this.batchNumBookings * limit;
    const sort = '-startDate';
    const filter = {member, fromDate, toDate, offset, limit, sort};

    //Load bookings
    return Booking
      .query(filter)
      .then(data => {
        this.bookings = this.bookings.concat(data.bookings);
        this.totalNumBookings = data.meta.total;
        this.batchNumBookings++;
        this.hasMoreBookings = (this.totalNumBookings > this.bookings.length);
      });
  };

  /**
   * Load access logs
   */
  this.loadAccessLogs = function(reset = false) {

    //Reset
    if (reset) {
      this.accessLogs = [];
      this.batchNumAccessLogs = 0;
    }

    //Create filter
    const member = this.member.id;
    const fromDate = moment().startOf('day').subtract(1, 'year');
    const toDate = moment().endOf('day').add(1, 'year');
    const limit = 100; //NOTE increased from 25 due to #6893
    const offset = this.batchNumAccessLogs * limit;
    const filter = {member, fromDate, toDate, offset, limit};

    //Load access logs
    return AccessLog
      .query(filter)
      .then(data => {
        this.accessLogs = this.accessLogs.concat(data.accessLogs);
        this.totalNumAccessLogs = data.meta.total;
        this.batchNumAccessLogs++;
        this.hasMoreAccessLogs = (
          this.totalNumAccessLogs > this.accessLogs.length
        );
      });
  };

  /**
   * Load activity logs
   */
  this.loadActivityLogs = function(reset = false) {

    //Reset
    if (reset) {
      this.activityLogs = [];
      this.batchNumActivityLogs = 0;
    }

    //Create filter
    const member = this.member.id;
    const fromDate = moment().startOf('day').subtract(1, 'year');
    const toDate = moment().endOf('day').add(1, 'year');
    const limit = 100; //NOTE increased from 25 due to #6893
    const offset = this.batchNumActivityLogs * limit;
    const filter = {member, fromDate, toDate, offset, limit};

    //Load activity logs
    return ActivityLog
      .query(filter)
      .then(data => {
        this.activityLogs = this.activityLogs.concat(data.activityLogs);
        this.totalNumActivityLogs = data.meta.total;
        this.batchNumActivityLogs++;
        this.hasMoreActivityLogs = (
          this.totalNumActivityLogs > this.activityLogs.length
        );
      });
  };

  /**
   * Check in member
   */
  this.checkInMember = function() {

    //Set data
    const data = {
      member: this.member.id,
      wasCheckedIn: true,
      type: 'Check-in',
      reason: 'Manual check-in',
    };

    //Create check in log
    return CheckInLog
      .create(data)
      .then(() => $notice.show(`Member checked in`));
  };

  /**
   * Toggle QR code
   */
  this.toggleQr = function() {
    this.isShowingQr = !this.isShowingQr;
  };

  /**
   * Update vaccination status
   */
  this.verifyVaccinationStatus = function() {
    if (!this.club.canVerifyVaccinationStatus) {
      return;
    }
    $modal.open('verifyVaccinationStatus', {
      locals: {
        club: this.club,
        member: this.member,
        isOwn: false,
      },
    }, true);
  };
});
