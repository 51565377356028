
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.Fees', [
  'App.Admin.Finance.Fees.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.finance.fees', {
    url: '/fees',
    component: 'adminFinanceFeesRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('fees');
      },
      country(Country) {
        return Country.own();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminFinanceFeesRoute', {
  controller: 'AdminFinanceFeesCtrl',
  templateUrl: 'admin/finance/fees/fees.html',
  bindings: {
    club: '<',
    filter: '<',
    page: '<',
    user: '<',
    country: '<',
  },
});
