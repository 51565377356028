
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.Purchase', [
  'App.Coupon.Purchase.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('coupon.purchase', {
    url: '/purchase',
    component: 'couponPurchaseRoute',
    resolve: {
      payment(PaymentFlow, PaymentTypes, user) {
        return new PaymentFlow(PaymentTypes.COUPON, user);
      },
      outcome(PaymentOutcome) {
        return PaymentOutcome.check();
      },
      couponTypes(CouponType) {
        return CouponType
          .query({isArchived: false, canPurchase: true})
          .then(data => data.couponTypes);
      },
    },
  });
})

/**
 * Component
 */
.component('couponPurchaseRoute', {
  controller: 'CouponPurchaseCtrl',
  templateUrl: 'coupon/purchase/purchase.html',
  bindings: {
    club: '<',
    user: '<',
    integrations: '<',
    module: '<',
    transition: '<',
    payment: '<',
    outcome: '<',
    couponType: '<',
    couponTypes: '<',
  },
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'coupon.purchase'}, transition => {
    const params = transition.params();
    const module = transition.injector().get('module');
    Page.setTitle(`Purchase ${module.plural}`);
    Page.addCrumb({sref: 'coupon.purchase', params});
  });
});
