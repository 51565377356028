
/**
 * Module definition and dependencies
 */
angular.module('Shared.Cards.CloseReasons.Constant', [])

/**
 * Constant definition
 */
.constant('CardCloseReasons', {
  CLOSE: 'close',
  BODY: 'body',
  TRANSITION: 'transition',
  OPENED_NEXT: 'openedNext',
  OPENED_PREV: 'openedPrev',
  OPENED_OTHER: 'openedOther',
  ALREADY_OPEN: 'alreadyOpen',
  SAVED: 'saved',
});
