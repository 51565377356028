/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.AddLinkedMember.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addLinkedMember', {
    templateUrl: 'subscription/modals/add-linked-member.html',
    controller: 'ModalAddLinkedMemberCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalAddLinkedMemberCtrl', function(
  $controller, $modalInstance, Subscription
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Set flags
    this.isSaving = false;
    this.isError = false;

    //Set initial data
    this.setMember(this.members[0]);
    this.setMembership(this.memberships[0]);
  };

  /**
   * Set member
   */
  this.setMember = function(member) {
    this.member = member;
    this.isErrorAgeRestriction = false;
    this.isErrorSameHousehold = false;
  };

  /**
   * Set membership
   */
  this.setMembership = function(membership) {

    //The same membership selected?
    if (membership === this.membership) {
      return;
    }

    //Clear error flags
    this.isErrorAgeRestriction = false;
    this.isErrorSameHousehold = false;

    //Load subscription data
    this.loadSubscriptionData(membership);
  };

  /**
   * Load subscription data
   */
  this.loadSubscriptionData = function(membership) {

    //Get data
    const {member} = this;
    const selection = [{member, membership, linked: []}];

    //Load data
    this.isLoading = true;
    return Subscription
      .getPurchaseData(selection)
      .then(data => {
        this.membership = membership;
        this.subscription = data.subscriptions[0];
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Validate
   */
  this.validate = function() {

    //Clear error
    this.isErrorAgeRestriction = false;

    //Initialize valid state
    let isValid = true;

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      isValid = false;
    }

    //Get data
    const {membership, member, primary} = this;
    const {address} = primary;

    //Check age if age restriction is set
    if (membership && membership.hasAgeRestriction && member.age) {
      if (!membership.isValidForAge(member.age)) {
        this.isErrorAgeRestriction = true;
        isValid = false;
      }
    }

    //Check if same household is enforced
    if (membership && membership.enforceSameHousehold && address) {
      if (!address.matches(member.address)) {
        this.isErrorSameHousehold = true;
        isValid = false;
      }
    }

    //Return valid state
    return isValid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate form
    if (!this.validate()) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;
    this.isErrorAgeRestriction = false;
    this.isErrorSameHousehold = false;

    //Create model
    const {member, membership} = this;
    const linked = {member, membership};

    //Use save handler
    this.handler(linked);
    this.close();
  };
});
