
/**
 * Module definition and dependencies
 */
angular.module('App.Home', [
  'App.Home.Controller',
  'App.Home.Welcome.Card',
  'App.Home.Alerts.Card',
  'App.Home.Bookings.Card',
  'App.Home.Events.Card',
  'App.Home.Highlight.Card',
  'App.Home.Staff.Card',
  'App.Home.ClubDetails.Card',
  'App.Home.ActionButton.Component',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('home', {
    parent: 'app',
    url: '/',
    component: 'homeRoute',
    params: {
      invalidUser: {
        value: false,
      },
      alreadyLoggedIn: {
        value: false,
      },
    },
    data: {
      id: 'home',
    },
  });
})

/**
 * Route component
 */
.component('homeRoute', {
  controller: 'HomeCtrl',
  templateUrl: 'home/home.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    pages: '<',
    activities: '<',
    module: '<',
  },
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'home'}, transition => {

    //Get user and system
    const user = transition.injector().get('user');
    const system = transition.injector().get('system');

    //Determine if can override lights or doors
    const {canOverrideLights, canOverrideDoors} = user;

    //Set title and crumb
    Page.setTitle('');
    Page.addCrumb({sref: 'home'});

    //Doors and lights
    if (system && system.hasLightControl && canOverrideLights) {
      Page.addOption('overrideLights', {class: 'PageOption--secondary'});
    }
    if (system && system.hasDoorControl && canOverrideDoors) {
      Page.addOption('overrideDoors', {class: 'PageOption--secondary'});
    }
  });
});
