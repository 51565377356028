
/**
 * Define values
 */
const EQUAL = 'equal';
const NOT_EQUAL = 'notEqual';
const ONE_OF = 'oneOf';
const NOT_ONE_OF = 'notOneOf';
const CONTAINS = 'contains';
const NOT_CONTAINS = 'notContains';
const LESS_THAN = 'lessThan';
const MORE_THAN = 'moreThan';
const LESS_THAN_DAYS_AGO = 'lessThanDaysAgo';
const MORE_THAN_DAYS_AGO = 'moreThanDaysAgo';
const LESS_THAN_DAYS_AWAY = 'lessThanDaysAway';
const MORE_THAN_DAYS_AWAY = 'moreThanDaysAway';
const UNKNOWN = 'unknown';
const NONE = 'none';
const ANY = 'any';

/**
 * Module definition and dependencies
 */
angular.module('Shared.GroupRuleConditions.Constant', [])

/**
 * Constant definitions
 */
.constant('GroupRuleConditions', {
  EQUAL,
  NOT_EQUAL,
  ONE_OF,
  NOT_ONE_OF,
  CONTAINS,
  NOT_CONTAINS,
  LESS_THAN,
  MORE_THAN,
  LESS_THAN_DAYS_AGO,
  MORE_THAN_DAYS_AGO,
  LESS_THAN_DAYS_AWAY,
  MORE_THAN_DAYS_AWAY,
  UNKNOWN,
  ANY,
})

/**
 * Helper to get rule conditions based on type
 */
.factory('getGroupRuleConditions', function(GroupRuleTypes) {

  //Get constants
  const {
    NUMBER, TEXT, LIST, ARRAY, DATE, BOOLEAN,
    DOB, AGE, GROUP, MEMBERSHIP, GRADE, GRADE_DATE,
  } = GroupRuleTypes;

  //Export helper
  return function getGroupRuleConditions(type) {

    //No type
    if (!type) {
      return null;
    }

    //Check type
    switch (type) {
      case DOB:
        return [
          {
            value: MORE_THAN,
            label: 'is after',
          },
          {
            value: LESS_THAN,
            label: 'is before',
          },
          {
            value: UNKNOWN,
            label: `is unknown`,
          },
          {
            value: ANY,
            label: 'has any',
          },
        ];
      case AGE:
        return [
          {
            value: MORE_THAN,
            label: 'is equal or over',
          },
          {
            value: LESS_THAN,
            label: 'is under',
          },
          {
            value: UNKNOWN,
            label: `is unknown`,
          },
        ];
      case GROUP:
      case GRADE:
        return [
          {
            value: ONE_OF,
            label: 'is one of',
          },
          {
            value: NOT_ONE_OF,
            label: 'is not one of',
          },
          {
            value: NONE,
            label: `doesn't have any`,
          },
          {
            value: ANY,
            label: 'has any',
          },
        ];
      case GRADE_DATE:
        return [
          {
            value: EQUAL,
            label: 'is on',
          },
          {
            value: MORE_THAN,
            label: 'is after',
          },
          {
            value: LESS_THAN,
            label: 'is before',
          },
          {
            value: MORE_THAN_DAYS_AGO,
            label: 'is more than',
          },
          {
            value: LESS_THAN_DAYS_AGO,
            label: 'is less than',
          },
        ];
      case MEMBERSHIP:
        return [
          {
            value: ONE_OF,
            label: 'is one of',
          },
          {
            value: NOT_ONE_OF,
            label: 'is not one of',
          },
          {
            value: NONE,
            label: `doesn't have any`,
          },
          {
            value: ANY,
            label: 'has any',
          },
        ];
      case NUMBER:
        return [
          {
            value: EQUAL,
            label: 'is',
          },
          {
            value: NOT_EQUAL,
            label: 'is not',
          },
          {
            value: MORE_THAN,
            label: 'is more than',
          },
          {
            value: LESS_THAN,
            label: 'is less than',
          },
          {
            value: UNKNOWN,
            label: 'is unknown',
          },
          {
            value: ANY,
            label: 'has any value',
          },
        ];
      case TEXT:
        return [
          {
            value: EQUAL,
            label: 'is',
          },
          {
            value: NOT_EQUAL,
            label: 'is not',
          },
          {
            value: CONTAINS,
            label: 'contains',
          },
          {
            value: NOT_CONTAINS,
            label: 'does not contain',
          },
          {
            value: UNKNOWN,
            label: 'is unknown',
          },
          {
            value: ANY,
            label: 'has any value',
          },
        ];
      case LIST:
        return [
          {
            value: ONE_OF,
            label: 'is one of',
          },
          {
            value: NOT_ONE_OF,
            label: 'is not one of',
          },
          {
            value: UNKNOWN,
            label: 'is unknown',
          },
          {
            value: ANY,
            label: 'has any value',
          },
        ];
      case ARRAY:
        return [
          {
            value: ONE_OF,
            label: 'is one of',
          },
          {
            value: NOT_ONE_OF,
            label: 'is not one of',
          },
          {
            value: UNKNOWN,
            label: 'is unknown',
          },
          {
            value: ANY,
            label: 'has any value',
          },
          {
            value: NONE,
            label: 'has no value',
          },
        ];
      case DATE:
        return [
          {
            value: EQUAL,
            label: 'is on',
          },
          {
            value: MORE_THAN,
            label: 'is after',
          },
          {
            value: LESS_THAN,
            label: 'is before',
          },
          {
            value: MORE_THAN_DAYS_AGO,
            label: 'is more than',
          },
          {
            value: LESS_THAN_DAYS_AGO,
            label: 'is less than',
          },
          // {
          //   value: MORE_THAN_DAYS_AWAY,
          //   label: 'is more than',
          // },
          // {
          //   value: LESS_THAN_DAYS_AWAY,
          //   label: 'is less than',
          // },
          {
            value: UNKNOWN,
            label: 'is unknown',
          },
          {
            value: ANY,
            label: 'has any value',
          },
        ];
      case BOOLEAN:
        return [
          {
            value: EQUAL,
            label: 'is yes',
          },
          {
            value: NOT_EQUAL,
            label: 'is no',
          },
          {
            value: UNKNOWN,
            label: 'is unknown',
          },
          {
            value: ANY,
            label: 'has any value',
          },
        ];
    }
  };
});
