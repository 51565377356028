
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Add.Details.Card', [])

/**
 * Component
 */
.component('cardActivityAddDetails', {
  templateUrl: 'admin/activity/cards/details.html',
  controller: 'CardActivityAddDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    activity: '<',
    system: '<',
    isSuper: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardActivityAddDetailsCtrl', function(
  $controller, $focus, $element, $timeout, $convert
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardActivityEditDetailsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;

    //Focus on name input
    const $name = $element.find('input');
    $focus($name[0]);

    //Auto generate identifier
    //TODO: Move this to server
    $name.on('input', () => {
      $timeout(() => {
        if (this.model.name) {
          this.model.identifier = $convert.string
            .toDasherized(this.model.name.toLowerCase());
        }
        else {
          this.model.identifier = '';
        }
      });
    });
  };
});
