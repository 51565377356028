
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.PayWithCoupons.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('payWithCoupons', {
    templateUrl: 'admin/finance/modals/pay-with-coupons.html',
    controller: 'ModalPayWithCouponsCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalPayWithCouponsCtrl', function(
  $controller, $modalInstance, Coupon
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.isSaving = false;
    this.isError = false;
    this.hasCoupons = false;
    this.isLoadingCoupons = true;
    this.canHaveMore = true;

    //Get filter data
    const {filter, activity} = this;

    //Load available coupons for members
    Coupon
      .query(filter)
      .then(coupons => {
        this.hasCoupons = false;
        this.coupons = coupons;
        this.couponMap = coupons
          .filter(coupon =>
            !activity || coupon.getSessionsLeftForActivity(activity) > 0)
          .reduce((coupons, coupon) => {
            if (typeof coupons[coupon.member.id] === 'undefined') {
              coupons[coupon.member.id] = [];
            }
            this.hasCoupons = true;
            coupons[coupon.member.id].push(coupon);
            return coupons;
          }, {});
      })
      .finally(() => this.isLoadingCoupons = false);
  };

  /**
   * Use a coupon
   */
  this.useCoupon = function(coupon, isSelected) {

    //Get activity
    const {activity} = this;

    //Mark as used
    coupon.isSelected = isSelected;

    //Count num selected
    const numSelected = this.coupons
      .filter(coupon => coupon.isSelected)
      .length;

    //Count num sessions available on selected coupons
    const numAvailable = this.coupons
      .filter(coupon => coupon.isSelected)
      .map(coupon => coupon.getSessionsLeftForActivity(activity))
      .reduce((total, num) => num + total, 0);

    //Check if we can have more or not
    this.canHaveMore = numSelected < this.sessionsNeeded;
    this.mustHaveMore = numAvailable < this.sessionsNeeded;

    //Clear errors
    this.isErrorRequired = false;
    this.isErrorMore = false;
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Reset flags
    this.isErrorRequired = false;
    this.isErrorMore = false;
    this.isError = false;
    this.error = null;

    //Get coupons to use
    const coupons = this.coupons
      .filter(coupon => coupon.isSelected)
      .map(coupon => coupon.id);

    //Flag form as submitted and validate
    this.form.$setSubmitted();

    //No coupons
    if (coupons.length === 0) {
      this.isErrorRequired = true;
      return;
    }

    //Not enough sessions
    if (this.mustHaveMore) {
      this.isErrorMore = true;
      return;
    }

    //Set flag
    this.isSaving = true;

    //Use save handler
    this
      .handler(coupons)
      .then(coupons => $modalInstance.resolve(coupons))
      .catch(error => {
        this.isError = true;
        this.error = error;
      })
      .finally(() => this.isSaving = false);
  };
});
