
/**
 * Module definition and dependencies
 */
angular.module('Shared.Member.Activity.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('MemberActivity', function($baseModel) {

  /**
   * Constructor
   */
  function MemberActivity(data) {
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(MemberActivity.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  MemberActivity.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('allowedModes', null, true);

    //Ensure boolean
    if (!this.hasAllowedModes) {
      this.hasAllowedModes = false;
    }

    //Return self
    return this;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Check if a certain mode is allowed
   */
  MemberActivity.prototype.isAllowedMode = function(mode) {
    return this.allowedModes.some(modeId => modeId === mode.id);
  };

  //Return
  return MemberActivity;
});
