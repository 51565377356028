
/**
 * Module definition and dependencies
 */
angular.module('App.ApiVersionInterceptor.Service', [])

/**
 * Interceptor service
 */
.factory('ApiVersionInterceptor', function($window, $log, $injector, Config) {

  //Flag to only reload once
  let isReloading = false;
  let Pwa;

  /**
   * Determine if this is a HTML template request
   */
  function isTemplateRequest(request) {
    return (request && request.url.indexOf('.html') !== -1);
  }

  /**
   * Normalize version
   */
  function normalizeVersion(version) {
    if (!version) {
      return 0;
    }
    return Number(version.replace(/-/g, ''));
  }

  /**
   * Helper to see if we should reload
   */
  function shouldReload(api, client) {

    //No version or the same version
    if (!client || !api || (client === api)) {
      return false;
    }

    //Normalize versions
    client = normalizeVersion(client);
    api = normalizeVersion(api);

    //Only reload if the API version is larger than the client
    return (api > client);
  }

  /**
   * Check API version
   */
  function checkApiVersion(response) {

    //No headers to get?
    if (!response || typeof response.headers !== 'function') {
      return;
    }

    //Already reloading
    if (isReloading) {
      return;
    }

    //Get server API version
    const api = response.headers('X-Api-Version');
    const client = Config.api.version;

    //Compare
    if (shouldReload(api, client)) {

      //Flag as reloading
      isReloading = true;

      //Get Pwa service
      Pwa = Pwa || $injector.get('Pwa');

      //Log
      $log.warn(`API version mismatch: API is on ${api}, client is on ${client}`);

      //Unregister service workers and reload page
      Pwa
        .unregisterAll()
        .then(() => {
          setTimeout(() => {
            $window.location.reload();
          }, 250);
        });
    }
  }

  /**
   * Interceptor
   */
  return {

    /**
     * Response handling
     */
    response(response) {
      if (!isTemplateRequest(response.config)) {
        checkApiVersion(response);
      }
      return response;
    },

    /**
     * Response error handling
     */
    responseError(response) {
      if (!isTemplateRequest(response.config)) {
        checkApiVersion(response);
      }
      throw response;
    },
  };
});
