
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Add', [
  'App.Admin.Activity.Add.Controller',
  'App.Admin.Activity.Add.Details.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.activity.add', {
    url: '/add',
    component: 'adminActivityAddRoute',
    data: {
      roles: ['admin'],
    },
    resolve: {
      activity(Activity) {
        return new Activity();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminActivityAddRoute', {
  controller: 'AdminActivityAddCtrl',
  templateUrl: 'admin/activity/add/add.html',
  bindings: {
    club: '<',
    user: '<',
    activity: '<',
    activities: '<',
    system: '<',
    module: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, $log, Page) => {

  //Guard
  $transitions.onEnter({to: 'admin.activity.add'}, transition => {

    //Get data
    const club = transition.injector().get('club');
    const user = transition.injector().get('user');
    const activities = transition.injector().get('activities');

    //Get activity limit for plan
    const {activityLimit} = club.permissions;
    const numActivities = activities.length;
    const isSuper = user.isSuper();

    //Check if we can't be here
    if (!isSuper && activityLimit && numActivities >= activityLimit) {
      $log.log(`Can’t add activities`);
      return transition.router.stateService.target('admin.activity.overview');
    }
  });

  //Page setup
  $transitions.onSuccess({to: 'admin.activity.add'}, () => {
    Page.setTitle(`Add activity`);
    Page.addCrumb({sref: 'admin.activity.add'});
  });
});
