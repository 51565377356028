
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.WelcomeEmail.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('welcomeEmail', {
    templateUrl: 'admin/people/members/modals/welcome-email.html',
    controller: 'ModalWelcomeEmailCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalWelcomeEmailCtrl', function(
  $controller, $modalInstance, $notice, $modal, TooManyRequestsError, Interface
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Get welcome link
   */
  this.getWelcomeLink = function() {

    //Get member
    const {member} = this;

    //Set flag
    this.isGettingLink = true;

    //Get link
    member
      .getWelcomeLink()
      .then(link => {
        $modalInstance.close();
        Interface
          .copyToClipboard(link)
          .then(() => $notice.show(`Welcome link copied to clipboard`))
          .catch(() => {
            $modal.open('basic', {
              templateUrl: 'admin/people/members/modals/welcome-link.html',
              locals: {member, link},
            });
          });
      })
      .catch(() => $notice.showError('Failed to get welcome link'))
      .finally(() => this.isGettingLink = true);
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Mark as sending
    this.isConfirming = true;
    this.isError = false;

    //Send
    this.member
      .sendWelcomeEmail()
      .then(() => {
        $notice.show('Welcome email sent');
        $modalInstance.resolve();
      })
      .catch(error => {

        //Too many requests
        if (error instanceof TooManyRequestsError) {
          const {limit, count, isTrial} = error.data;

          //Set flag and limit/count
          this.isRateLimitError = true;
          this.isTrial = isTrial;
          this.limit = limit;
          this.count = count;
          return;
        }

        //Generic error
        this.isError = true;
      })
      .finally(() => this.isConfirming = false);
  };
});
