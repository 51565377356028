
/**
 * Module definition and dependencies
 */
angular.module('App.Event.Selection.Card', [])

/**
 * Component
 */
.component('cardEventSelection', {
  templateUrl: 'event/cards/selection.html',
  controller: 'CardEventSelectionCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    activities: '<',
    categories: '<',
    subscription: '<',
    onCancel: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventSelectionCtrl', function(
  $store, $modal, moment, Settings, EventRuleTypes
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Event settings
    const overviewFields = Settings.get('event.overviewFields', []);

    //Determine fields to show
    this.hasMultipleActivities = (this.activities.length > 1);
    this.showOrganisers = overviewFields.includes('organisers');
    this.showActivity = overviewFields.includes('activity');

    //Set flags
    this.isLoading = true;
    this.activity = null;

    //Load events
    this.loadEvents();
  };

  /**
   * Load events
   */
  this.loadEvents = function() {

    //Get data
    const {membership, startDate, endDate} = this.subscription;
    const now = moment();
    const fromDate = moment.max(now, startDate);
    const toDate = endDate ? endDate : moment().add(1, 'year');

    //Set up filter
    const filter = {
      hasSpacesLeft: true,
      isHidden: false,
      ruleType: EventRuleTypes.MEMBERSHIP,
      membership: membership.id,
      fromDate,
      toDate,
    };

    //Add activity and category
    if (this.activity) {
      filter.activity = this.activity;
    }
    if (this.category) {
      filter.category = this.category;
    }

    //Load the events
    return $store.events.query(filter)
      .then(events => this.events = events)
      .then(this.isLoading = false);
  };

  /**
   * Update the activity
   */
  this.setActivity = function(activity) {
    this.activity = activity;
    this.loadEvents();
  };

  /**
   * Update the category
   */
  this.setCategory = function(category) {
    this.category = category;
    this.loadEvents();
  };

  /**
   * View event
   */
  this.view = function(event) {

    //If has description
    if (event.description) {
      $modal.open('basic', {
        templateUrl: 'event/modals/view-event.html',
        locals: {event},
      });
      return;
    }
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
