
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.PurchaseNoPayment.Card', [])

/**
 * Component
 */
.component('cardSubscriptionPurchaseNoPayment', {
  templateUrl: 'subscription/cards/purchase-no-payment.html',
  controller: 'CardSubscriptionPurchaseNoPaymentCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    onPurchase: '&',
    onPrev: '&',
    onCancel: '&',
  },
})

/**
 * Controller
 */
.controller('CardSubscriptionPurchaseNoPaymentCtrl', function() {

  /**
   * Purchase
   */
  this.purchase = function() {

    //Flag as renewing
    this.isPurchasing = true;

    //Purchase
    return this
      .onPurchase()
      .finally(() => this.isPurchasing = false);
  };

  /**
   * Go back
   */
  this.prev = function() {
    this.onPrev();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
