
/**
 * Module definition and dependencies
 */
angular.module('Shared.PickDate.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('pickDate', {
    templateUrl: 'modals/input/pick-date.html',
    controller: 'ModalPickDateCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalPickDateCtrl', function(
  $controller, $modalInstance, moment
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Validate min/max date
    if (this.minDate && this.maxDate && this.maxDate.isBefore(this.minDate)) {
      this.maxDate = this.minDate.clone();
    }

    //Set min/max dates
    this.minDate = this.minDate || null;
    this.maxDate = this.maxDate || null;

    //Set can set today
    this.canSetToday = (
      (!this.minDate || moment().isSameOrAfter(this.minDate, 'day')) &&
      (!this.maxDate || moment().isSameOrBefore(this.maxDate, 'day'))
    );
  };

  /**
   * Set today
   */
  this.today = function() {
    const date = moment().startOf('minute');
    this.confirm({date});
  };

  /**
   * Clear
   */
  this.clear = function() {
    $modalInstance.resolve(null);
  };

  /**
   * Confirm
   */
  this.confirm = function($event) {

    //Get new date from event
    const {date} = $event;

    //Maintain time
    if (this.date) {
      const hour = this.date.hour();
      const minute = this.date.minute();
      date.hour(hour).minute(minute);
    }

    //Set new date
    this.date = date;
    this.isClosing = true;

    //Close modal
    $modalInstance.resolve(this.date);
  };
});
