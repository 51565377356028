
/**
 * Module definition and dependencies
 */
angular.module('Shared.Feedback.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config($apiProvider => {
  $apiProvider.registerEndpoint('feedback', {
    model: 'Feedback',
    actions: {
      create: {
        method: 'POST',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Feedback', function($api, $baseModel) {

  /**
   * Constructor
   */
  function Feedback(data) {
    $baseModel.call(this, data);

    //Who feedback is for
    Object.defineProperty(this, 'isForClub', {
      get() {
        return (this.whoFor === 'club');
      },
    });
    Object.defineProperty(this, 'isForApp', {
      get() {
        return (this.whoFor === 'app');
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Feedback.prototype, $baseModel.prototype);

  /**
   * To JSON converter
   */
  Feedback.prototype.toJSON = function(data) {

    //Call parent method
    let json = $baseModel.prototype.toJSON.call(this, data);

    //Only ID for member
    json.member = $baseModel.onlyId(json.member);

    //Return json
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Post feedback
   */
  Feedback.prototype.post = function() {
    return $api.feedback.create(this.toJSON());
  };

  //Return
  return Feedback;
});
