
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.Info.Card', [])

/**
 * Component
 */
.component('cardClubInfo', {
  templateUrl: 'admin/club/cards/info.html',
  controller: 'CardClubInfoCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    isSuper: '<',
    isAccountOwner: '<',
  },
})

/**
 * Controller
 */
.controller('CardClubInfoCtrl', function($notice, Interface) {

  /**
   * Copy link to clipboard
   */
  this.copyLinkToClipboard = function() {
    Interface
      .copyToClipboard(this.club.appUrl)
      .then(() => $notice.show(`Portal link copied to clipboard`));
  };
});
