
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Setup', [
  'App.Admin.Setup.Controller',
  'App.Admin.Setup.Progress.Card',
  'App.Admin.Setup.Step.Component',
  'App.Admin.Setup.InviteAdmins.Modal',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.setup', {
    url: '/setup/:openCard',
    component: 'adminSetupRoute',
    params: {
      isFirstVisit: {
        value: false,
      },
      openCard: {
        value: null,
        squash: true,
      },
    },
    data: {
      roles: ['admin'],
    },
  });
})

/**
 * Route component
 */
.component('adminSetupRoute', {
  controller: 'AdminSetupCtrl',
  templateUrl: 'admin/setup/setup.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    activities: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page, Intercom) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.setup'}, () => {

    //Set page title and crumb
    Page.setTitle('Setup guide');
    Page.addCrumb({sref: 'admin.setup'});

    //Track intercom event
    Intercom.event('Viewed setup');
  });
});
