
/**
 * Module definition and dependencies
 */
angular.module('Shared.DateFormat.Service', [])

/**
 * Service definition
 */
.factory('DateFormat', function(GlobalScope) {

  //Defaults
  const defaults = {
    standard: '[DATE]',
    withTime: '[DATE] [TIME]',
    timeOnly: '[TIME]',
    dayAndTime: 'ddd, [TIME]',
    upcoming: 'ddd D MMM',
    long: 'dddd D MMMM',
  };

  /**
   * Class
   */
  class DateFormat {

    /**
     * Constructor
     */
    constructor() {
      this.set();
    }

    /**
     * Set date/ time formats
     */
    set(dateFormat, timeFormat) {

      //Ensure there are values
      dateFormat = dateFormat || 'D MMM YYYY';
      timeFormat = timeFormat || 'HH:mm';

      //Reset formats to defaults
      this.formats = {};

      //Add the correct format to each default
      for (const property in defaults) {

        //Replace placeholders
        this.formats[property] = defaults[property]
          .replace('[DATE]', dateFormat)
          .replace('[TIME]', timeFormat);
      }

      //Set in global scope
      GlobalScope.set({dateFormat: this.formats});
    }

    /**
     * Get all formats
     */
    get() {
      return this.formats;
    }
  }

  //Return instance
  return new DateFormat();
});
