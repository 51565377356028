
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.VisitorLogText.Card', [])

/**
 * Component
 */
.component('cardVisitorLogText', {
  templateUrl: 'admin/club/cards/visitor-log-text.html',
  controller: 'CardVisitorLogTextCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    texts: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardVisitorLogTextCtrl', function(
  CardCloseReasons, TextTypes, Modules
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Find relevant texts
    this.visitorLogIntro = this.texts
      .find(text => text.type === TextTypes.VISITOR_INTRO);
    this.visitorLogTerms = this.texts
      .find(text => text.type === TextTypes.VISITOR_TERMS);

    //Reset their models
    if (this.visitorLogIntro) {
      this.visitorLogIntro.resetModel();
    }
    if (this.visitorLogTerms) {
      this.visitorLogTerms.resetModel();
    }

    //Default text
    if (this.visitorLogIntro && !this.visitorLogIntro.hasText()) {
      const members = Modules.plural('members');
      this.visitorLogIntro.model = `Welcome! All ${members} and visitors are required to check-in and log their visit when entering the facility.`;
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get texts
    const {texts} = this;

    //Save
    this
      .onSave({$event: {texts}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
