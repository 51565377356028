
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.Overview.Controller', [])

/**
 * Controller
 */
.controller('AdminResourceOverviewCtrl', function(
  $controller, $filter, $state, ScrollPosition, Pagination,
  Resource, Settings, $storage, $modal, GlobalScope
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminResourceCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize
    this.isLoading = true;
    this.canEdit = this.user.isAdmin();

    //Default view
    this.isGrid = false;

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load page
    this.loadPage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page, filter and params
    const {page, filter} = this;
    const params = this.transition.params();

    //Enable search and set filters
    page.enableSearch();
    page.setController(this);
    page.setFilters(filter);

    //Get params
    const {grid} = params;
    this.isGrid = (grid || $storage.get('resource.showGrid', false));

    //Filter and refresh logs
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get data
    const {filter} = this;

    //Set defaults
    filter.setDefaults({
      search: '',
      member: null,
    });

    //Filter on change handler
    filter.onChange((key, value) => {

      //Index based merge
      if (key === 'status') {
        filter.mapOptions(key, value);
      }

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      status: [
        {
          label: 'Assigned',
          filter: {
            hasActiveAssignee: true,
          },
        },
        {
          label: 'Available',
          filter: {
            hasActiveAssignee: false,
          },
        },
      ],
    };
  };

  /**
   * Load resources
   */
  this.loadResources = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query members
    return Resource
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, resources} = data;

    //Set resources
    this.resources = resources;

    //Set in scope
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);

    //Get settings
    const displayOrder = Settings.get('resource.displayOrder');

    //Grid settings
    if (displayOrder === 'col') {
      this.numRows = Settings.get('resource.numRows');
      this.numCols = Math.ceil(this.numItems / this.numRows);
    }
    else {
      this.numCols = Settings.get('resource.numCols');
      this.numRows = Math.ceil(this.numItems / this.numCols);
    }
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load resources
    this
      .loadResources()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };

  /**
   * Toggle view
   */
  this.toggleView = function() {
    this.isGrid = !this.isGrid;
    $storage.set('resource.showGrid', this.isGrid);
  };

  /**
   * Export resources
   */
  this.export = function() {

    //Get plural
    const {plural} = this.module;
    const {club} = this;

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club, action: `Exporting ${plural}`},
      });
    }

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: plural, action: 'export'},
      });
    }

    //Get filter and export
    const filter = this.makeFilter();
    return Resource.export(filter);
  };

  /**
   * Show settings
   */
  this.showSettings = function() {

    //Get data
    const {club} = this;
    const handler = settings => club
      .patch({settings})
      .then(({settings}) => {

        //Update globally
        //TODO: need a central place to do this/update settings
        Settings.set(settings);
        GlobalScope.set({settings});
        return $state.go('admin.resource.overview', {}, {reload: true});
      });

    //Open modal
    return $modal.open('resourceSettings', {
      locals: {type: 'resource', handler},
    });
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    this.loadResources();
  };
});
