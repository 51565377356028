
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.EditGrade.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editActivityGrade', {
    templateUrl: 'admin/activity/modals/edit-grade.html',
    controller: 'ModalEditActivityGradeCtrl',
    closeOnClick: false,
    resolve: {
      memberships($store) {
        'ngInject';
        return $store.memberships.query();
      },
      club($store) {
        'ngInject';
        return $store.club.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalEditActivityGradeCtrl', function(
  $controller, $modalInstance, GradeColors
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set initial step
    this.setStep('constraint');

    //Extract model
    this.model = this.grade.extract([
      'id', 'name', 'description', 'color', 'sortOrder',
    ]);

    //Set flags & data
    this.isSaving = false;
    this.isError = false;
    this.isChoosingColor = false;
    this.comesAfter = null;
    this.GradeColors = GradeColors;

    //Determine eligible activity grades
    this.determineEligibleActivityGrades();

    //Determine where this grade comes after
    if (this.model.sortOrder > 1) {
      this.comesAfter = this.model.sortOrder - 1;
    }

    //Set prev grade if needed & adding grade
    if (!this.isEdit && this.eligibleActivityGrades.length > 0) {
      const index = this.eligibleActivityGrades.length - 1;
      this.comesAfter = this.eligibleActivityGrades[index].sortOrder;
    }
  };

  /**
   * Determine eligible activity grades
   */
  this.determineEligibleActivityGrades = function() {
    this.eligibleActivityGrades = this.activity.grades
      .filter(grade => grade.id !== this.model.id)
      .map(({name, sortOrder}) => ({name, sortOrder}))
      .sort((a, b) => a.sortOrder - b.sortOrder);
  };

  /**
   * Choose color
   */
  this.toggleChooseColor = function() {
    this.isChoosingColor = !this.isChoosingColor;
  };

  /**
   * Set color
   */
  this.setColor = function(color) {

    //Get grade
    this.isChoosingColor = false;

    //Check if changed
    if (!color || this.model.color === color) {
      return;
    }

    //Update
    this.model.color = color;
  };

  /**
   * Update prev/sort order
   */
  this.updateComesAfter = function(value) {
    this.comesAfter = value;
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Determine sort order
    this.model.sortOrder = this.comesAfter + 1;

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };
});
