
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance', [
  'App.Admin.Finance.Transactions',
  'App.Admin.Finance.Payments',
  'App.Admin.Finance.Payouts',
  'App.Admin.Finance.Fees',
  'App.Admin.Finance.AccountCredit',
  'App.Admin.Finance.EditPayment.Modal',
  'App.Admin.Finance.EditTransaction.Modal',
  'App.Admin.Finance.CreateTransaction.Modal',
  'App.Admin.Finance.SplitTransaction.Modal',
  'App.Admin.Finance.AddAccountCredit.Modal',
  'App.Admin.Finance.TransferAccountCredit.Modal',
  'App.Admin.Finance.PayWithAccountCredit.Modal',
  'App.Admin.Finance.PayWithCoupons.Modal',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.finance', {
    url: '/finance',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'finance',
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.finance.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.finance.transactions', title});
  }, {priority: 10});
});
