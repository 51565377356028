
/**
 * Define values
 */
const PER_AREA = 'perArea';
const PER_PERSON = 'perPerson';

/**
 * Define array
 */
const FeeTypes = [
  {
    value: PER_AREA,
    label: 'Per area',
  },
  {
    value: PER_PERSON,
    label: 'Per person',
  },
];

/**
 * Set properties
 */
FeeTypes.PER_AREA = PER_AREA;
FeeTypes.PER_PERSON = PER_PERSON;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.FeeTypes.Options', [])
  .constant('FeeTypes', FeeTypes);
