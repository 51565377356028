
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Unsubscribe.Controller', [])

/**
 * Controller
 */
.controller('UnsubscribeCtrl', function($location, Member) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set flags
    this.isInvalid = false;
    this.isUnsubscribing = false;
    this.isUnsubscribed = false;

    //Get params and token
    const {member, hash} = $location.search();

    //If no member or hash
    if (!member || !hash) {
      this.isInvalid = true;
    }
    else {

      //Set flag
      this.isUnsubscribing = true;

      //Unsubscribe
      Member
        .unsubscribe(member, hash)
        .then(() => this.isUnsubscribed = true)
        .catch(() => this.isInvalid = true)
        .finally(() => this.isUnsubscribing = false);
    }
  };
});
