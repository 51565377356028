
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Welcome', [
  'App.Portal.Welcome.Base.Controller',
  'App.Portal.Welcome.Error',
  'App.Portal.Welcome.OAuth',
  'App.Portal.Welcome.Details',
  'App.Portal.Welcome.CustomFields',
  'App.Portal.Welcome.Credentials',
  'App.Portal.Welcome.Done',
])

/**
 * Config
 */
.config(($stateProvider, $transitionsProvider) => {

  //State definition
  $stateProvider.state('welcome', {
    parent: 'portal',
    abstract: true,
    template: '<ui-view/>',
    url: '/welcome/:token?club',
    params: {
      error: null,
      provider: null,
      workspace: null,
      reason: null,
    },
    resolve: {
      token(transition) {
        const params = transition.params();
        return params.token;
      },
      user(User, token) {
        return User
          .findByToken(token)
          .catch(error => error);
      },
      customFields($store, user, matchesMemberConstraint) {
        if (!user) {
          return [];
        }
        return $store.customFields
          .query({model: 'Member', isEditable: true, isApiOnly: false})
          .then(fields => fields.filter(field =>
            matchesMemberConstraint(user, field)));
      },
      welcome(WelcomeFlow, club, user, token, customFields) {
        return new WelcomeFlow(club, user, token, customFields);
      },
    },
  });

  //Transition hooks
  $transitionsProvider.onBefore({
    to: 'welcome.*',
  }, transition => {

    //Get params
    const params = transition.params();

    //Must have token param
    if (!params.token) {
      return transition.router.stateService.target('login');
    }
  });

  //Guard against going directly to the next steps or coming here without club
  $transitionsProvider.onBefore({
    from: '',
    to: 'welcome.*',
  }, transition => {

    //Get params
    const to = transition.to();
    const from = transition.from();
    const params = transition.params();

    //Must have club
    if (!params.club) {
      return transition.router.stateService.target('login');
    }

    //If going to details and we have oAuth profile data, all good
    if (to.name === 'welcome.details' && params.profile) {
      return;
    }

    //If coming from another state or going to oauth state, all good
    if (from.name || to.name === 'welcome.oauth') {
      return;
    }

    //Go to first step
    return transition.router.stateService.target('welcome.oauth', params);
  });

  //Guard against going to steps when already setup
  $transitionsProvider.onEnter({
    to: 'welcome.*',
  }, async(transition) => {

    //Get data
    const to = transition.to();
    const from = transition.from();
    const params = transition.params();
    const user = await transition.injector().getAsync('user');
    const welcome = await transition.injector().getAsync('welcome');

    //No user
    if ((!user || user instanceof Error) && !to.name.match(/(error)/)) {
      return transition.router.stateService.target('welcome.error');
    }

    //Already completed?
    if (welcome.hasFinished && !to.name.match(/(done)/)) {
      return transition.router.stateService.target('welcome.done');
    }

    //If navigating to oauth from the details step
    if (to.name === 'welcome.oauth' && from.name === 'welcome.details'
    && params.error) {
      //Reset params
      const newParams = angular.extend({}, params, {
        error: null, provider: null, reason: null, workspace: null,
      });
      return transition.router.stateService.target('welcome.oauth', newParams);
    }
  });
});
