
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.EventOptions.Component', [])

/**
 * Event options component
 */
.component('eventOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="$ctrl.canEdit">
        <a ui-sref="admin.event.edit({eventId: $ctrl.event.id})">Edit</a>
      </li>
      <li>
        <a ui-sref="admin.event.add({eventId: $ctrl.event.id})">Copy</a>
      </li>
      <li ng-if="$ctrl.canView">
        <a ui-sref="event.view({eventId: $ctrl.event.id})">View event</a>
      </li>
      <li>
        <a ui-sref="admin.log.audit({model: 'Event', id: $ctrl.event.id})">View audit log</a>
      </li>
      <li class="Dropdown-item--danger" ng-if="$ctrl.canDelete">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    event: '<',
    canEdit: '<',
    canDelete: '<',
    canView: '<',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Delete
     */
    this.delete = function() {
      this.onDelete({$event: {event: this.event}});
    };
  },
});
