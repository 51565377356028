
/**
 * Module definition and dependencies
 */
angular.module('Shared.EditCustomField.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editCustomField', {
    templateUrl: 'modals/edit-custom-field.html',
    controller: 'ModalEditCustomFieldCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalEditCustomFieldCtrl', function(
  $controller, $modalInstance, CustomFieldTypes, ExistsError,
  MembershipConstraintLabels, MembershipConstraints
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set flags
    this.isSaving = false;
    this.isError = false;

    //Extract model
    this.model = this.field.extract([
      'label', 'prop', 'question', 'defaultValue', 'type', 'options',
      'isRequired', 'isVisible', 'isEditable', 'inDirectory',
      'constraint', 'memberships',
    ]);

    //Check if can be in directory
    this.checkCanBeInDirectory();
    this.updateDefaultOptions();

    //Services
    this.CustomFieldTypes = CustomFieldTypes;
    this.membershipConstraints = MembershipConstraintLabels
      .filter(constraint => !constraint.isGuest);

    //Default constraint
    if (!this.model.constraint) {
      this.model.constraint = MembershipConstraints.ALL;
    }
  };

  /**
   * Update model
   */
  this.updateModel = function(prop, value) {
    this.model[prop] = value;

    //Hidden fields can't be editable or required
    if (prop === 'isVisible' && !value) {
      this.model.isEditable = false;
      this.model.isRequired = false;
      this.model.inDirectory = false;
    }

    //Editable or required fields can't be hidden
    if ((
      prop === 'isEditable' ||
      prop === 'isRequired' ||
      prop === 'inDirectory'
    ) && value) {
      this.model.isVisible = true;
    }

    //Non-editable fields can't be required
    if (prop === 'isEditable' && !value) {
      this.model.isRequired = false;
    }

    //Type edited
    if (prop === 'type') {

      //File field can't be required
      if (value === CustomFieldTypes.FILE) {
        this.model.isRequired = false;
      }

      //Default value
      if (value === CustomFieldTypes.BOOLEAN) {
        this.model.defaultValue = false;
      }
      else {
        this.model.defaultValue = null;
      }

      //Update default options
      if (value === CustomFieldTypes.DROPDOWN) {
        this.updateDefaultOptions();
      }
    }

    //Check if can be in directory
    this.checkCanBeInDirectory();
  };

  /**
   * Update default options
   */
  this.updateDefaultOptions = function() {
    if (this.model.options) {
      this.defaultOptions = this.model.options.split('\n');
    }
    else {
      this.defaultOptions = [];
    }
    if (this.model.defaultValue &&
        this.model.type === CustomFieldTypes.DROPDOWN &&
        !this.defaultOptions.includes(this.model.defaultValue)) {
      this.model.defaultValue = null;
    }
  };

  /**
   * Check if can be in directory
   */
  this.checkCanBeInDirectory = function() {
    this.canBeInDirectory = (this.model.type !== CustomFieldTypes.FILE);
  };

  /**
   * Propagate label
   */
  this.propagateLabel = function() {
    if (this.model.label && !this.model.question) {
      this.model.question = this.model.label;
    }
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isErrorExists = false;
    this.isErrorOther = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(error => {
        if (error instanceof ExistsError) {
          this.isErrorExists = true;
        }
        else {
          this.isErrorOther = true;
        }
      })
      .finally(() => this.isSaving = false);
  };
});
