
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.Controller', [])

/**
 * Controller
 */
.controller('AdminSettingsCtrl', function(
  $store, $q, $notice, Settings, GlobalScope, DateFormat,
  TextTypes, Text, CustomPage
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Get params
    const {openCard, openTab} = this.transition.params();

    //Set data
    this.isSuper = this.user.isSuper();
    this.discounts = {};
    this.memberships = [];
    this.openCard = openCard;
    this.openTab = openTab;
    this.texts = [];

    //Reset fields
    this.resetCustomFields();
    this.resetDirectoryFields();

    //Member fields
    this.memberFields = [
      {
        label: 'Gender',
        prop: 'gender',
      },
      {
        label: 'Date of birth',
        prop: 'dob',
      },
      {
        label: 'Phone or mobile number',
        prop: 'phone',
      },
      {
        label: 'Address',
        prop: 'address',
      },
      {
        label: 'Postal address',
        prop: 'postalAddress',
      },
    ];
  };

  /**
   * Save custom field
   */
  this.saveCustomField = function($event) {
    const {field, model} = $event;
    return field.patch(model);
  };

  /**
   * Save settings
   */
  this.save = function($event) {

    //Get data
    const {settings, hideNotice} = $event;

    //Update settings
    return this.club
      .patch({settings})
      .then(({settings}) => {

        //Update globally
        Settings.set(settings);
        GlobalScope.set({settings});

        //Set date and time formats
        const {dateFormat, timeFormat} = settings.general;
        DateFormat.set(dateFormat, timeFormat);

        //Reset directory fields
        this.resetDirectoryFields();
        this.appendCustomDirectoryFields();

        //Show notice unless hidden
        if (!hideNotice) {
          $notice.show('Settings updated');
        }
        return settings;
      });
  };

  /**
   * Reset custom fields
   */
  this.resetCustomFields = function() {
    this.customFields = {
      Member: [],
      Contact: [],
      Event: [],
      Resource: [],
    };
  };

  /**
   * Reset directory fields
   */
  this.resetDirectoryFields = function() {
    this.directoryFields = [{
      label: 'Email',
      prop: 'email',
      isVisible: Settings.get('member.fields.email.isVisible'),
    }];
    if (Settings.get('member.fields.phone.isVisible')) {
      this.directoryFields.push({
        label: 'Phone or mobile number',
        prop: 'phone',
      });
    }
    if (Settings.get('member.fields.address.isVisible')) {
      this.directoryFields.push({
        label: 'Address',
        prop: 'address',
      });
    }
  };

  /**
   * Append custom directory fields
   */
  this.appendCustomDirectoryFields = function() {
    for (const field of this.customFields.Member) {
      if (field.isVisible && field.canBeInDirectory) {
        this.directoryFields.push(field);
      }
    }
  };

  /**
   * Load items for custom fields card
   */
  this.loadCustomFieldItems = function() {

    //Promises to load
    const promises = [
      this.loadCustomFields(),
      this.loadMemberships(),
    ];

    //Load all
    return $q.all(promises);
  };

  /**
   * Load items for custom pages card
   */
  this.loadCustomPageItems = function() {

    //Promises to load
    const promises = [
      this.loadCustomPages(),
      this.loadCustomFields(),
      this.loadMemberships(),
    ];

    //Load all
    return $q.all(promises);
  };

  /**
   * Load items for email cards
   */
  this.loadEmailItems = function() {

    //Promises to load
    const promises = [
      this.loadTexts(),
      this.loadCustomFields(),
      this.loadMemberships(),
    ];

    //Load all
    return $q.all(promises);
  };

  /**
   * Load items for members card
   */
  this.loadMemberItems = function() {

    //Promises to load
    const promises = [
      this.loadCustomFields(),
      this.loadMemberships(),
      this.loadMemberGroups(),
      this.loadActivities(),
    ];

    //Load all
    return $q.all(promises);
  };

  /**
   * Load custom fields
   */
  this.loadCustomFields = function() {

    //Reset custom field containers
    this.resetCustomFields();
    this.resetDirectoryFields();

    //Query
    return $store.customFields
      .query()
      .then(fields => {
        for (const field of fields) {
          this.customFields[field.model].push(field);
        }
      })
      .then(() => this.appendCustomDirectoryFields());
  };

  /**
   * Load custom pages
   */
  this.loadCustomPages = function() {

    //Reset custom field containers
    this.customPages = [];

    //Query
    return CustomPage
      .query()
      .then(data => this.customPages = data.pages);
  };

  /**
   * Load discounts
   */
  this.loadDiscounts = function() {
    return $q.all([
      $store.discounts
        .query({model: 'Membership'})
        .then(fields => this.discounts.Membership = fields),
      $store.memberships
        .query()
        .then(memberships => this.memberships = memberships),
    ]);
  };

  /**
   * Load memberships
   */
  this.loadMemberships = function() {
    return $store.memberships
      .query()
      .then(memberships => this.memberships = memberships);
  };

  /**
   * Load activities
   */
  this.loadActivities = function() {
    return $store.activities
        .query()
        .then(activities => this.activities = activities);
  };

  /**
   * Load member groups
   */
  this.loadMemberGroups = function() {
    return $store.memberGroups
      .query()
      .then(memberGroups => this.memberGroups = memberGroups);
  };

  /**
   * Load event categories
   */
  this.loadEventCategories = function() {
    return $store.eventCategories
      .query()
      .then(eventCategories => this.eventCategories = eventCategories);
  };

  /**
   * Load areas for system
   */
  this.loadAreas = function() {
    $store.areas
      .query()
      .then(areas => this.areas = areas);
  };

  /**
   * Load texts
   */
  this.loadTexts = function() {

    //Already loaded
    if (this.texts.length > 0) {
      return $q.resolve();
    }

    //Types to load
    const types = [
      TextTypes.BIRTHDAY_EMAIL,
      TextTypes.BOOKING_CREATED_EMAIL,
      TextTypes.EVENT_ATTENDEE_EMAIL,
      TextTypes.UNPAID_TRX_REMINDER_EMAIL,
      TextTypes.UNPAID_BOOKING_REMINDER_EMAIL,
      TextTypes.MEMBERSHIP_CREATION_EMAIL,
      TextTypes.MEMBERSHIP_PURCHASE_EMAIL,
      TextTypes.MEMBERSHIP_CHANGE_EMAIL,
      TextTypes.MEMBERSHIP_RENEWAL_EMAIL,
      TextTypes.MEMBERSHIP_RENEWAL_REMINDER_EMAIL,
    ];

    //Load
    return $q
      .all(types.map(type => Text.findByType(type)))
      .then(texts => {
        for (const text of texts) {
          this.texts.push(text);
        }
      });
  };
});
