
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Flow.SelectMode.Component', [])

/**
 * Grid component
 */
.component('flowBookingSelectMode', {
  templateUrl: 'booking/flow/select-mode.html',
  bindings: {
    user: '<',
    club: '<',
    modes: '<',
    booking: '<',
    canManageBookings: '<',
    canMakeOwnBookings: '<',
    onNext: '&',
    onClose: '&',
  },

  /**
   * Controller
   */
  controller(ValidationError, Settings) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Get data
      const {duration, mode, activity, isPast, isCurrent} = this.booking;
      const {canManageBookings, canMakeOwnBookings} = this;
      const allowCurrent = Settings.get('booking.allowCurrent');

      //Set data
      this.mode = mode;
      this.isSuper = (this.user && this.user.isSuper());

      //Set flag
      if (!canManageBookings) {
        this.booking.forOthers = false;
      }
      if (canManageBookings && (
        !canMakeOwnBookings || isPast || (isCurrent && !allowCurrent)
      )) {
        this.booking.forOthers = true;
      }

      //Check number of sessions we're booking
      this.numSessions = Math.ceil(duration / activity.duration);
    };

    /**
     * Set for others flag
     */
    this.setForOthers = function(forOthers) {

      //Clear error and set in booking
      this.error = null;
      this.booking.forOthers = forOthers;

      //Set notes to undefined if not for others and clear the recurrence flag
      //if not allowed for regular members
      if (!forOthers) {
        this.booking.notes = undefined;
        if (this.booking.isRecurring &&
            !this.booking.activity.allowRecurringBookings) {
          this.booking.isRecurring = false;
        }
      }

      //Update members
      this.updateBookingMembers();
    };

    /**
     * Set the mode
     */
    this.setMode = function(mode) {

      //Clear error and set in booking
      this.error = null;
      this.booking.mode = mode;

      //Update members
      this.updateBookingMembers();
    };

    /**
     * Set or clear booking members as necessary
     */
    this.updateBookingMembers = function() {

      //Clear members and visitors
      this.booking.clearMembers();
      this.booking.clearVisitors();

      //Add user as member
      if (this.user && !this.booking.forOthers) {
        this.booking.setMembers(this.user);
      }
    };

    /**
     * Set recurring checkbox value
     */
    this.setRecurring = function(isRecurring) {
      this.booking.isRecurring = isRecurring;
    };

    /**
     * Next step
     */
    this.next = function() {

      //As a super user, can only make bookings for others
      if (this.user && this.user.isSuper()) {
        this.setForOthers(true);
      }

      //Update members
      this.updateBookingMembers();

      //Next step
      this.isBusy = true;
      this
        .onNext()
        .catch(error => {
          if (error instanceof ValidationError) {
            return this.error = error;
          }
          throw error;
        })
        .finally(() => this.isBusy = false);
    };
  },
});
