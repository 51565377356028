
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.TagSettings.Card', [])

/**
 * Component
 */
.component('cardSystemTagSettings', {
  templateUrl: 'admin/system/cards/tag-settings.html',
  controller: 'CardSystemTagSettingsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    system: '<',
    memberships: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSystemTagSettingsCtrl', function(
  $controller, $filter, MembershipConstraintLabels) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'system';

    //Set services
    this.MembershipConstraintLabels = MembershipConstraintLabels
      .filter(item => !item.isGuest);

    //Distribute memberships
    this.memberships = $filter('distribute')(this.memberships, 3);

    //Call parent init
    $base.$onInit.call(this);
  };
});
