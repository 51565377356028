
/**
 * Module definition and dependencies
 */
angular.module('Shared.Dropdown.Component', [
  'Shared.Dropdown.Toggle.Component',
  'Shared.Dropdown.Menu.Component',
])

/**
 * Dropdown component
 */
.component('dropdown', {
  template: `
    <div class="Dropdown" ng-class="{'is-open': $ctrl.isOpen}" ng-transclude></div>
  `,
  transclude: true,
  bindings: {
    isOpen: '<',
  },
  controller() {

    /**
     * Toggle
     */
    this.toggle = function() {
      this.isOpen = !this.isOpen;
    };

    /**
     * Close
     */
    this.close = function() {
      this.isOpen = false;
    };
  },
});
