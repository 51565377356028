
/**
 * Module definition and dependencies
 */
angular.module('Shared.Dropdown.Menu.Component', [])

/**
 * Component
 */
.component('dropdownMenu', {
  template: `
    <menu class="Dropdown-menu {{$ctrl.menuClass}}" ng-class="{'is-open': $ctrl.dropdown.isOpen}" ng-click="$ctrl.dropdown.close();" ng-transclude></menu>
  `,
  transclude: true,
  require: {
    dropdown: '^^',
  },
  bindings: {
    menuClass: '@',
  },
});
