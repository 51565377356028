
/**
 * Module definition and dependencies
 */
angular.module('Shared.Spinner.Component', [])

/**
 * Spinner component
 */
.component('spinner', {
  templateUrl: 'shared/spinner/spinner.html',

  /**
   * Component controller
   */
  controller($element) {

    /**
     * Initialization
     */
    this.$onInit = function() {

      //Propagate classes
      this.classes = $element[0].className;
      $element[0].className = 'SpinnerComponent';
    };
  },
});
