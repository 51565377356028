
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.Base.Controller', [])

/**
 * Controller
 */
.controller('RegisterBaseCtrl', function($timeout) {

  /**
   * Init
   */
  this.$onInit = function() {

    //Create shortcuts
    this.user = this.registration.primaryMember;

    //Setup
    this.setup();

    //Start faded
    this.fade = true;
    $timeout(() => this.fade = false);
  };

  /**
   * Update user
   */
  this.updateUser = function(property, value) {
    this.user[property] = value;
  };

  /**
   * Validate form
   */
  this.validate = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Continue
    this.continue();
  };

  /**
   * Continue to the next step
   */
  this.continue = function() {

    //At last step?
    if (this.registration.isAtLastStep) {

      //Toggle flag
      this.isRegistering = true;

      //Register
      this.registration
        .register()
        .then(() => this.postRegistration())
        .finally(() => this.isRegistering = false);
    }

    //Go to next step
    else {
      this.fade = true;
      this.registration.goToNextStep();
    }
  };

  /**
   * Back to the previous step
   */
  this.back = function() {

    //Go to previous step
    this.fade = true;
    this.registration.goToPreviousStep();
  };

  /**
   * Post registration handler
   */
  this.postRegistration = function() {
    this.registration.finish();
  };

  /**
   * Setup step
   */
  this.setup = function() {};
});
