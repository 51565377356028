
/**
 * Define values
 */
const DAYS = 'days';
const WEEKS = 'weeks';
const MONTHS = 'months';
const YEARS = 'years';

/**
 * Constant definition
 */
const Durations = [
  {
    value: DAYS,
    label: 'Days',
    labelAlt: 'Daily',
  },
  {
    value: WEEKS,
    label: 'Weeks',
    labelAlt: 'Weekly',
  },
  {
    value: MONTHS,
    label: 'Months',
    labelAlt: 'Monthly',
  },
  {
    value: YEARS,
    label: 'Years',
    labelAlt: 'Yearly',
  },
];

/**
 * Set properties
 */
Durations.DAYS = DAYS;
Durations.WEEKS = WEEKS;
Durations.MONTHS = MONTHS;
Durations.YEARS = YEARS;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.Durations.Options', [])
  .constant('Durations', Durations);
