
/**
 * Module definition and dependencies
 */
angular.module('Shared.Notifications.Card', [])

/**
 * Component
 */
.component('cardNotifications', {
  templateUrl: 'cards/notifications.html',
  controller: 'CardNotificationsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    member: '<',
  },
})

/**
 * Controller
 */
.controller('CardNotificationsCtrl', function(Push, Modules) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Types
    this.types = [
      {
        type: 'attendee',
        label: 'Events you are attending',
      },
      {
        type: 'unpaidTransaction',
        label: 'Transactions that are due',
      },
    ];

    //Bookings
    if (Modules.has('bookings')) {
      this.types.push({
        type: 'booking',
        label: 'Upcoming bookings',
      }, {
        type: 'bookingWaitingList',
        label: 'Booking waiting list notifications',
      });
    }

    //Memberships
    if (Modules.has('memberships')) {
      this.types.push({
        type: 'subRenewalReminder',
        label: 'Membership renewal reminders',
      });
    }

    //Check if types are enabled
    this.model = {};
    const {member} = this;
    for (const item of this.types) {
      const {type} = item;
      this.model[type] = (
        Push.isEnabledForUser(member, type) && Push.isEnabledOnDevice(type)
      );
    }
  };

  /**
   * Update model
   */
  this.toggle = function(type, isEnabled) {
    const {member} = this;
    this.model[type] = isEnabled;
    Push.toggleNotification(member, type, isEnabled);
  };
});
