
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Staff.Overview.Controller', [])

/**
 * Controller
 */
.controller('AdminStaffOverviewCtrl', function(
  $controller, $filter, $modal, $notice, Roles, ReplacementTags,
  ScrollPosition, Pagination, Member, Settings, Intercom
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminStaffCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Track intercom event
    Intercom.event('Viewed staff');

    //Initialize
    this.isLoading = true;
    this.isAdmin = this.user.isAdmin();

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load members
    this.loadPage();
    this.checkLimits();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter} = this;

    //Initialize selection
    this.selection = new Set();

    //Set filter defaults
    filter.setDefaults({
      search: '',
      isArchived: false,
      status: 'current',
    });

    //Filter on change handler
    filter.onChange((key, value) => {

      //Status
      if (key === 'status') {
        const option = filter.options[key].find(o => o.value === value);
        angular.forEach(option.filter, (value, key) => {
          filter.update(key, value, true);
        });
      }

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      status: [
        {
          label: 'Current staff members',
          value: 'current',
          isDefault: true,
          filter: {
            isArchived: false,
          },
        },
        {
          label: 'Archived staff members',
          value: 'archived',
          filter: {
            isArchived: true,
          },
        },
      ],
      roles: Roles.filter(role => role.value !== 'member'),
    };
  };

  /**
   * Check limits
   */
  this.checkLimits = function() {
    Member
      .getLimits()
      .then(({hasReachedSoftLimit, hasReachedHardLimit}) => {
        this.hasReachedSoftLimit = hasReachedSoftLimit;
        this.hasReachedHardLimit = hasReachedHardLimit;
      });
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    this.loadMembers();
  };

  /**
   * On patched
   */
  this.onPatched = function() {
    this.loadMembers();
  };

  /**
   * Load members
   */
  this.loadMembers = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query members
    return Member
      .queryStaff(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Archive multiple members
   */
  this.archiveMany = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'staff members', action: 'archive'},
      });
    }

    //Get data
    const numMembers = this.selection.size || this.numItems;

    //Get filter and define handler
    const filter = this.makeFilter();
    const handler = () => Member.archiveManyStaff(filter);

    //Show confirmation modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-archive-many.html',
        locals: {numMembers, handler, isStaff: true},
      })
      .result
      .then(() => {
        $notice.show(`Staff members archived`);
        this.selection.clear();
        this.loadMembers();
      });
  };

  /**
   * Restore multiple members
   */
  this.restoreMany = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'staff members', action: 'restore'},
      });
    }

    //Get data
    const numMembers = this.selection.size || this.numItems;

    //Get filter and define handler
    const filter = this.makeFilter();
    const handler = () => Member.restoreManyStaff(filter);

    //Show confirmation modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-restore-many.html',
        locals: {numMembers, handler, isStaff: true},
      })
      .result
      .then(() => {
        $notice.show(`Staff members restored`);
        this.selection.clear();
        this.loadMembers();
      });
  };

  /**
   * Export members
   */
  this.export = function() {

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club: this.club, action: 'Exporting staff'},
      });
    }

    //Get filter
    const filter = this.makeFilter();

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'staff members', action: 'export'},
      });
    }

    //Export
    return Member.exportStaff(filter);
  };

  /**
   * Email members
   */
  this.email = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'staff members', action: 'email'},
      });
    }

    //Define handlers
    const {customFields} = this;
    const filter = this.makeFilter(null, {hasEmail: true});
    const handler = email => Member.emailStaff(filter, email);
    const counter = () => Member.count(filter);
    const tags = ReplacementTags.member(customFields);

    //Open modal
    $modal.open('email', {locals: {handler, counter, tags}});
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, members} = data;

    //Set in scope
    this.members = members;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
  };

  /**
   * Toggle
   */
  this.toggle = function(id) {
    if (this.selection.has(id)) {
      this.selection.delete(id);
    }
    else {
      this.selection.add(id);
    }
  };

  /**
   * Select all
   */
  this.selectAll = function() {
    for (const member of this.members) {
      const {id} = member;
      if (!this.selection.has(id)) {
        this.selection.add(id);
      }
    }
  };

  /**
   * Unselect all
   */
  this.unselectAll = function() {
    return this.selection.clear();
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Selection active? Use as filter unless page specified
    if (!page && this.selection.size > 0) {
      return {ids: Array.from(this.selection.values())};
    }

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Remove unneeded
    delete filter.status;

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadMembers()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };
});
