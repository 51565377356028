
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.UpForRenewal.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('upForRenewal', {
    templateUrl: 'subscription/modals/up-for-renewal.html',
    controller: 'ModalUpForRenewalCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalUpForRenewalCtrl', function(
  $controller, $modalInstance, $state
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Check when expires
    this.days = this.subscription.expiresIn();

    //Set data in scope
    this.hasExpired = (this.days < 0);

    //Determine warning level
    this.level = 'warning';
    if (this.days <= 3) {
      this.level = 'danger';
    }
  };

  /**
   * Helper to determine snooze interval
   */
  this.getSnoozeInterval = function() {

    //Remind in 1 week if more than 2 weeks away
    if (this.days > 14) {
      return 7;
    }

    //Remind on 1 week if more than 1 week away
    if (this.days > 7) {
      return (this.days - 7);
    }

    //Remind two days later if more than 3 days away
    if (this.days > 3) {
      return 2;
    }

    //Remind one day later around the 0 mark
    if (this.days > -3) {
      return 1;
    }

    //Remind on 1 week out if less than 1 week out
    if (this.days > -7) {
      return (7 + this.days);
    }

    //Keep reminding every week
    return 7;
  };

  /**
   * Renew
   */
  this.renew = function() {
    const {subscription} = this;
    if (subscription.membership.isRenewable) {
      $state.go('subscription.renew', {subId: subscription.id});
    }
    else {
      $state.go('subscription.change', {subId: subscription.id});
    }
  };

  /**
   * Stop subscription
   */
  this.stop = function() {
    const {subscription} = this;
    $state.go('subscription.stop', {subId: subscription.id});
  };

  /**
   * Snooze
   */
  this.snooze = function() {
    const snooze = this.getSnoozeInterval();
    $modalInstance.resolve([snooze, 'days']);
  };
});
