
/**
 * Module definition and dependencies
 */
angular.module('Shared.AreaState.Filter', [])

/**
 * Filter definition
 */
.filter('areaState', function() {
  return function(area, simple = true) {
    if (area.isInOverride) {
      return simple ? 'Override' : 'is in override';
    }
    if (area.isInWarmUp) {
      return simple ? 'Warm-up' : 'is in warm-up';
    }
    if (area.hasEvent) {
      return simple ? 'Event' : 'has an event';
    }
    if (area.hasMode) {
      return simple ? 'In use' : 'is in use';
    }

    //Empty
    if (area.isEmpty && area.isOff) {
      return simple ? 'Empty' : 'is empty';
    }

    //Lights on but unknown state
    if (area.isOn) {
      return simple ? 'Lights on' : 'has lights on';
    }

    //Unknown
    return simple ? 'Unknown' : 'status is unknown';
  };
});
