
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area.Edit.Details.Card', [])

/**
 * Component
 */
.component('cardAreaEditDetails', {
  templateUrl: 'admin/area/cards/details.html',
  controller: 'CardAreaEditDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    area: '<',
    activities: '<',
    system: '<',
    isSuper: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardAreaEditDetailsCtrl', function(CardCloseReasons) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;

    //Create model subset
    this.model = this.area.extract([
      'name', 'activity', 'isOnSystem',
    ]);

    //Default activity
    if (!this.model.activity) {
      const activity = this.activities.find(activity => activity.isDefault);
      if (activity) {
        this.model.activity = activity.id;
      }
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {area, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {area, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
