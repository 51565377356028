
/**
 * Module definition and dependencies
 */
angular.module('Shared.Club.JoiningFee.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('JoiningFee', function(
  $baseModel, MembershipConstraints
) {

  //Extract constants
  const {
    ALL, WITHOUT, WITH, GUEST, SPECIFIC, SPECIFIC_WITHOUT,
  } = MembershipConstraints;

  //Defaults
  const defaultData = {
    constraint: MembershipConstraints.ALL,
  };

  /**
   * Constructor
   */
  function JoiningFee(data) {
    $baseModel.call(this, angular.extend({}, defaultData, data || {}));

    //Ages
    Object.defineProperty(this, 'ages', {
      get() {
        const {minAge, maxAge} = this;
        if (minAge && maxAge) {
          return `${minAge}–${maxAge}`;
        }
        if (minAge) {
          return `${minAge}+`;
        }
        if (maxAge) {
          return `<${maxAge + 1}`;
        }
        return '';
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(JoiningFee.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  JoiningFee.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('region', 'Address');

    //Return self
    return this;
  };

  /**
   * Check if fee applies to specific memberships
   */
  JoiningFee.prototype.appliesToMemberships = function(memberships) {

    //Ensure array
    if (!Array.isArray(memberships)) {
      memberships = memberships ? [memberships] : [];
    }

    //Get data
    const {constraint, memberships: specific} = this;

    //Check guest/all
    if (constraint === GUEST) {
      return false;
    }
    if (constraint === ALL) {
      return true;
    }

    //Check constraints
    const isSpecific = (
      constraint === SPECIFIC || constraint === SPECIFIC_WITHOUT
    );
    const isWithout = (
      constraint === WITHOUT || constraint === SPECIFIC_WITHOUT
    );

    //Members with a membership
    if (constraint === WITH && memberships.length > 0) {
      return true;
    }

    //Casuals without membership
    if (isWithout && memberships.length === 0) {
      return true;
    }

    //Specific memberships
    if (isSpecific && memberships.length > 0) {
      return memberships.some(membership => specific.includes(membership.id));
    }

    //Invalid
    return false;
  };

  /**
   * Check if fee applies to a given age
   */
  JoiningFee.prototype.appliesToAge = function(age) {
    const {minAge, maxAge} = this;
    if ((minAge || maxAge) && !age) {
      return false;
    }
    if (minAge && age < minAge) {
      return false;
    }
    if (maxAge && age > maxAge) {
      return false;
    }
    return true;
  };

  /**
   * Check if fee applies to a given address
   */
  JoiningFee.prototype.appliesToAddress = function(address) {
    const {region} = this;
    if (!region) {
      return true;
    }
    if (!address) {
      return false;
    }
    return region.matches(address);
  };

  //Return
  return JoiningFee;
});
