
/**
 * Module definition and dependencies
 */
angular.module('Shared.Sort.Component', [])

/**
 * Sort component
 */
.component('sort', {
  template: `
    <span class="Sort" ng-click="$ctrl.toggleSort()">
      <span ng-transclude></span>
      <i class="Icon" ng-if="$ctrl.isField && !$ctrl.isDesc">arrow_drop_up</i>
      <i class="Icon" ng-if="$ctrl.isField && $ctrl.isDesc">arrow_drop_down</i>
    </span>
  `,
  transclude: true,
  bindings: {
    sort: '<',
    field: '@',
    defaultDirection: '@',
    onSort: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * On init
     */
    this.$onInit = function() {
      this.defaultDirection = this.defaultDirection || '';
    };

    /**
     * On changes
     */
    this.$onChanges = function() {
      this.isField = (this.sort.replace(/^-/, '') === this.field);
      this.isDesc = (this.isField && this.sort.match(/^-/));
    };

    /**
     * Toggle sort
     */
    this.toggleSort = function() {

      //Prepare sort string
      const dir = this.getDir();
      const sort = this.field
        .split(',')
        .map(field => dir + field)
        .join(',');

      //Emit event
      this.onSort({sort});
    };

    /**
     * Determine direction
     */
    this.getDir = function() {

      //This field is not currently selected, use the default direction
      if (!this.isField) {
        return this.defaultDirection;
      }

      //Check if currently descending
      return this.isDesc ? '' : '-';
    };
  },
});
