
/**
 * Module definition and dependencies
 */
angular.module('App.MaintenanceCheck.Init', [])

/**
 * Version check
 */
.run(($window, $modal, $notice, $timeout, $http, Kiosk) => {

  //Modal instance
  let modalInstance;

  //Check periodically
  setInterval(() => {

    //Query maintenance file
    $http
      .get('/maintenance.json', {
        ignoreServerError: true,
        ignoreTimeoutError: true,
      })
      .then(response => {

        //Invalid response
        if (!response.data || typeof response.data === 'string') {
          return;
        }

        //Extract data
        const {maintenance, reason, window} = response.data;

        //No maintenance
        if (!maintenance) {
          return;
        }

        //Already showing a modal
        if (modalInstance) {
          return;
        }

        //If Kiosk enabled, speed up process
        if (Kiosk.isEnabled()) {
          $notice.show('Kiosk going offline for maintenance...', {
            hideAutomatically: false,
            spinner: true,
          });
          return $timeout(() => $window.location.reload(), 10 * 1000);
        }

        //Show modal
        modalInstance = $modal.open('basic', {
          templateUrl: 'modals/maintenance.html',
          locals: {reason, window},
        });

        //Auto reload after two minutes
        $timeout(() => $window.location.reload(), 120 * 1000);
      });
  }, 120 * 1000);
});
