
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.RegistrationSettings.Card', [])

/**
 * Component
 */
.component('cardClubRegistrationSettings', {
  templateUrl: 'admin/club/cards/registration-settings.html',
  controller: 'CardClubRegistrationSettingsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    integrations: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubRegistrationSettingsCtrl', function(
  $notice, $modal, CardCloseReasons, Settings, Interface
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;

    //Get mailing integrations
    this.mailingIntegrations = this.integrations
      .filter(int => int.isConnected && int.category === 'mailing');

    //Set approval options
    this.approvalOptions = [
      {
        value: 'none',
        label: 'Not required',
      },
      {
        value: 'payment',
        label: 'Only required when payment fails',
      },
      {
        value: 'always',
        label: 'Always required',
      },
    ];

    //Models
    this.registration = Settings.extract('registration');
    this.email = Settings.extract('email');
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.registration;
    target[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Create settings subset
    const {registration, email} = this;
    const settings = {registration, email};

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event: {settings}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
        this.club.markStepComplete('enableRegistration', true);
      })
      .finally(() => this.isSaving = false);
  };

  /**
   * Show QR code
   */
  this.showQRCode = function() {
    const url = this.club.registrationUrl;
    const title = `Registration page`;
    $modal.open('basic', {
      templateUrl: 'admin/club/modals/qr-code.html',
      locals: {title, url},
    });
  };

  /**
   * Copy registration link to clipboard
   */
  this.copyLinkToClipboard = function() {
    Interface
      .copyToClipboard(this.club.registrationUrl)
      .then(() => $notice.show(`Registration page link copied to clipboard`));
  };
});
