
/**
 * Module definition and dependencies
 */
angular.module('App.Core.Controller', [])

/**
 * Core controller
 */
.controller('CoreCtrl', function(
  $log, $filter, $http, $store, $modal, $notice, moment, Settings, Modules,
  Page, GlobalScope, Kiosk, VersionCheck, Pwa, Push, Intercom, Tag, Member,
  KeyDown
) {

  /**
   * Setup services
   */
  this.setupServices = function() {

    //Get club data
    const {club, integrations} = this;
    const {
      name, identifier, currencyCode,
      serverTime, timezone, background,
    } = club;

    //Set page service
    this.Page = Page;

    //Get matching currency symbol
    const symbol = $filter('currencySymbol')(currencyCode);

    //Set server time and timezone
    moment.setBaseTime(serverTime);
    moment.tz.setDefault(timezone);

    //Set page title suffix
    Page.setTitleSuffix(name);

    //Set default currency symbol
    $filter('currency').setDefaultSymbol(symbol);

    //Set headers
    $http.defaults.headers.common['X-Club'] = identifier;

    //Setup global scope
    GlobalScope.set({background, currencyCode, integrations});
  };

  /**
   * Setup settings
   */
  this.setupSettings = function() {

    //Get settings
    const {settings} = this.club;
    if (!settings) {
      return;
    }

    //Set settings
    Settings.set(settings);
    GlobalScope.set({settings});

    //Setup stores
    $store.events.setLife(90); //Under the 120 booking page refresh rate
    $store.bookings.setLife(90);
  };

  /**
   * Setup modules
   */
  this.setupModules = function() {

    //Get modules
    const modules = Modules.get();

    //Set in global scope
    GlobalScope.set({modules});
  };

  /**
   * Setup contact club handler
   */
  this.setupContactHandler = function() {

    //Contact club handler
    const contactClub = locals => $modal.open('contactClub', {locals});

    //Set in global scope
    GlobalScope.set({contactClub});
  };

  /**
   * Setup keydown listeners
   */
  this.setupKeyDownListeners = function() {

    //Get data
    const {user, system} = this;

    //Not an admin
    if (!user.isAdmin()) {
      return;
    }

    //QR code scanner
    this.boundQrCodeListener = this.qrCodeListener.bind(this);
    KeyDown.addListener(this.boundQrCodeListener);

    //System listener if applicable
    if (system && system.hasIButton) {
      this.boundUsbTagListener = this.usbTagListener.bind(this);
      KeyDown.addListener(this.boundUsbTagListener);
    }
  };

  /**
   * Remove keydown listeners
   */
  this.removeKeyDownListeners = function() {

    //QR code listener
    if (this.boundQrCodeListener) {
      KeyDown.removeListener(this.boundQrCodeListener);
    }

    //USB tag reader listener
    if (this.boundUsbTagListener) {
      KeyDown.removeListener(this.boundUsbTagListener);
    }
  };

  /**
   * Setup intercom
   */
  this.setupIntercom = function() {

    //Get data
    const {user, club, system} = this;

    //Only for clubs that re not migrating directly to Frello
    if (club.isDirectToFrello || club.isMigratingToFrello) {
      return;
    }

    //Only for admins
    if (!user.hasRoleStrict('admin') || user.isSuper()) {
      return;
    }

    //NOTE: We don't check permissions, because loading it allows us to talk
    //to the users and show them other things, even if they don't get live
    //support themselves.

    //Setup intercom
    Intercom.boot(user, club, system);
  };

  /**
   * Shutdown intercom
   */
  this.shutdownIntercom = function() {
    Intercom.shutdown();
  };

  /**
   * Setup version check
   */
  this.setupVersionCheck = function() {

    //Start checking automatically
    VersionCheck.startAutoCheck();

    //Set auto reload
    if (Kiosk.isEnabled()) {
      VersionCheck.setAutoReload(true);
    }
  };

  /**
   * Setup PWA
   */
  this.setupPwa = function() {

    //Generate URL
    const {appUrl} = this.club;
    const url = `${appUrl}/service-worker.js`;
    const options = {};

    //Instantiate new PWA instance
    this.pwa = new Pwa(url, options);

    //Set in push service
    Push.setPwa(this.pwa);

    //Before install prompt listener
    this.pwa.on('beforeinstallprompt', () => {
      this.canAddToHomeScreen = true;
      this.showAddToHomeScreen = true;
    });

    //App installed
    this.pwa.on('appinstalled', () => {
      $log.log('App installed to homescreen');
      this.canAddToHomeScreen = false;
      this.showAddToHomeScreen = false;
    });

    //After install prompt
    this.pwa.on('afterinstallprompt', result => {
      if (result.outcome === 'accepted') {
        $log.log('Accepted A2HS prompt');
      }
      else {
        $log.log('Dismissed A2HS prompt');
      }
      this.canAddToHomeScreen = false;
      this.showAddToHomeScreen = false;
    });

    //Service worker event listeners
    this.pwa.on('registered', registration => {
      $log.log('Service worker has been registered');
      VersionCheck.setServiceWorker(registration);
    });
    this.pwa.on('updated', registration => {
      VersionCheck.setServiceWorker(registration);
      VersionCheck.updateAvailable();
    });
    this.pwa.on('error', error => {
      $log.error('Error during service worker registration');
      $log.error(error);
    });

    //Setup now
    this.pwa.setup();
  };

  /**
   * Listener for QR code input
   */
  this.qrCodeListener = function(buffer) {

    //Check buffer for known matches
    const memberMatch = buffer.match(/\/admin\/people\/members\/edit\/([a-f0-9]{24})/);
    if (!memberMatch) {
      return true; //Return true to prevent default
    }

    //Get member ID
    const memberId = memberMatch[1];

    //Show loading notice
    $notice.showLoading();

    //Load member
    Member
      .findById(memberId)
      .then(member => {
        document.getElementById('audioCheckIn').play();
        $modal.open('viewMember', {
          locals: {member},
        }, true);
      })
      .catch(() => $notice.showError(`Invalid QR code`))
      .finally(() => $notice.hideLoading());

    //Return true to stop further processing of event
    return true;
  };

  /**
   * Listener for USB tag scanner input
   */
  this.usbTagListener = function(buffer) {

    //Get data
    const {system} = this;
    const data = Tag.fromReader(buffer);

    //Validate
    if (!data) {
      return;
    }

    //Show loading notice
    $notice.showLoading();

    //Find by data
    Tag
      .findByData(data)
      .then(tag => $modal.open('basic', {
        templateUrl: 'admin/system/modals/view-tag.html',
        locals: {tag, system},
      }, true))
      .catch(() => $notice.showError('Failed to read tag'))
      .finally(() => $notice.hideLoading());

    //Return true to stop further processing
    return true;
  };

  /**
   * Handler to close add to homescreen option
   */
  this.closeAddToHomeScreen = function(event) {
    if (event) {
      event.stopPropagation();
    }
    this.showAddToHomeScreen = false;
  };

  /**
   * Handler for adding to homescreen
   */
  this.addToHomeScreen = function() {
    this.showAddToHomeScreen = false;
    this.pwa.promptAddToHomeScreen();
  };
});
