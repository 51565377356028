
/**
 * Module definition and dependencies
 */
angular.module('Shared.PortalErrors.Component', [])

/**
 * Route component
 */
.component('portalErrors', {
  templateUrl: 'shared/portal-errors/portal-errors.html',
  bindings: {
    status: '<',
    error: '<',
    action: '@',
    provider: '<',
    reason: '<',
    workspace: '<',
    club: '<',
  },

  /**
   * Component controller
   */
  controller(OAuthProviders) {

    /**
     * Init
     */
    this.$onInit = function() {

      //Initialize flags
      this.isLoggingIn = false;
      this.isPending = false;
      this.isArchived = false;
      this.isUnknown = false;
      this.isExisting = false;
      this.isTrialEnded = false;
      this.isPlanExpired = false;
      this.isPlanCancelled = false;
      this.isInvalidMethod = false;
      this.isInvalidWorkspace = false;
      this.isError = false;
      this.isSessionExpired = false;

      //Process oAuth errors
      this.processError();
    };

    /**
     * Process error
     */
    this.processError = function() {

      //Get data
      const {error, provider, workspace, reason} = this;

      //Set provider
      if (provider) {
        this.provider = OAuthProviders.find(p => p.id === provider);
      }

      //Handle errors
      switch (error) {
        case 'archived':
          this.isArchived = true;
          break;
        case 'pending':
          this.isPending = true;
          break;
        case 'unknown':
          this.isUnknown = true;
          break;
        case 'invalidMethod':
          this.isInvalidMethod = true;
          break;
        case 'invalidWorkspace':
          this.isInvalidWorkspace = true;
          if (workspace) {
            this.workspace = workspace;
          }
          break;
        case 'trialEnded':
          this.isTrialEnded = true;
          break;
        case 'expired':
          this.isPlanExpired = true;
          break;
        case 'sessionExpired':
          this.isSessionExpired = true;
          break;
        case 'cancelled':
          this.isPlanCancelled = true;
          if (reason) {
            this.cancelReason = reason;
          }
          break;
        case 'exists':
          this.isExisting = true;
          break;
        case 'error':
          this.isError = true;
          break;
      }
    };
  },
});
