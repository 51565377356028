
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Log.Audit.Controller', [])

/**
 * Controller
 */
.controller('AdminLogAuditCtrl', function(
  $state, $filter, $modal, moment, ScrollPosition, Pagination, AuditLog,
  Intercom, AuditLogModels, AuditLogTypes, Settings, Modules
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed audit logs');

    //Check if super
    this.isSuper = this.user.isSuper();

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Determine minimum date that logs can be viewed
    if (!this.isSuper) {
      const {logVisibility} = this.club.permissions;
      this.minDate = moment().subtract(logVisibility || 1, 'months');
    }

    //Load logs
    this.loadPage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Enable search and set filters
    page.enableSearch();
    page.setController(this);
    page.setFilters(filter);

    //Filter and refresh logs
    page.addOption('filter');
    page.addOption('refreshLogs');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter, system, minDate} = this;
    const {id, model} = this.transition.params();

    //Set filter defaults
    filter.setDefaults({
      search: '',
      member: null,
      fromDate: moment().startOf('day').subtract(7, 'days'),
      toDate: moment().endOf('day'),
    });

    //Filter on model/ID
    if (model && id) {
      filter.update('model', model);
      filter.update('id', id);
      if (minDate) {
        filter.update('fromDate', minDate);
      }
      else {
        filter.update('fromDate', moment().subtract(1, 'year').startOf('day'));
      }
    }

    //Filter on change handler
    filter.onChange(key => {

      //From date changed
      if (key === 'fromDate') {
        if (filter.fromDate.isAfter(filter.toDate)) {
          filter.toDate = filter.fromDate.clone().endOf('day');
        }
      }

      //To date changed
      else if (key === 'toDate') {
        if (filter.toDate.isBefore(filter.fromDate)) {
          filter.fromDate = filter.toDate.clone().startOf('day');
        }
      }

      //Reload first page
      this.loadPage(1);
    });

    //Filter types
    const types = AuditLogTypes
      .filter(type => {
        return (!type.isSystem || (system && type.isSystem));
      });

    //Filter models
    const models = AuditLogModels
      .filter(model => Modules.has(model.module));

    //Append model labels if missing
    for (const model of models) {
      if (!model.label) {
        const singular = Modules.singular(model.module);
        model.label = $filter('ucfirst')(singular);
      }
    }

    //Set filter options
    filter.options = {
      type: types,
      model: models,
    };
  };

  /**
   * Load logs
   */
  this.loadLogs = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query logs
    return AuditLog
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Get link for audit log item
   */
  this.getLink = function(item) {

    //Init
    let state, params;
    const type = item.model[0].toLowerCase() + item.model.substring(1);

    //Determine state
    switch (item.model) {
      case 'Alert':
      case 'Club':
        state = 'admin.club';
        params = {};
        break;
      case 'Integration':
        state = 'admin.integrations';
        params = {};
        break;
      case 'Booking':
        state = `bookings`;
        params = {[`${type}Id`]: item.id};
        break;
      case 'Member':
      case 'Contact':
        state = `admin.people.${type}s.edit`;
        params = {[`${type}Id`]: item.id};
        break;
      default:
        state = `admin.${type}.edit`;
        params = {[`${type}Id`]: item.id};
        break;
    }

    //Return link
    return $state.href(state, params);
  };

  /**
   * View details
   */
  this.viewDetails = function($event) {

    //Get log
    const {log} = $event;

    //Open modal
    $modal.open('basic', {
      templateUrl: 'admin/log/audit/modals/audit-log-details.html',
      locals: {log},
    });
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, auditLogs} = data;

    //Set in scope
    this.logs = auditLogs;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Blank ID string is not validated
    if (!filter.id) {
      filter.id = undefined;
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadLogs()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };

  /**
   * Export logs
   */
  this.export = function() {

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club: this.club, action: 'Exporting audit logs'},
      });
    }

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'audit logs', action: 'export'},
      });
    }

    //Get filter and export
    const filter = this.makeFilter();
    return AuditLog.export(filter);
  };
});
