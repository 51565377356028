
/**
 * Module definition and dependencies
 */
angular.module('Shared.DiffNow.Filter', [])

/**
 * Filter definition
 */
.filter('diffNow', function(moment) {
  return function(date, fineGrained = false) {
    if (!moment.isMoment(date)) {
      date = moment(date);
    }
    if (!date.isValid()) {
      return '';
    }

    //Fine grained?
    if (fineGrained) {
      return date.from(moment());
    }

    //Check if today
    const today = moment();
    if (date.isSame(today, 'day')) {
      return 'today';
    }

    //Check if yesterday
    const yesterday = today.clone().subtract(1, 'day');
    if (date.isSame(yesterday, 'day')) {
      return 'yesterday';
    }

    //Use relative time
    return date.from(today);
  };
});
