
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.GoogleCalendar.Calendars.Card', [])

/**
 * Component
 */
.component('cardIntegrationsGoogleCalendarCalendars', {
  templateUrl: 'admin/integrations/google-calendar/calendars.html',
  controller: 'CardIntegrationsGoogleCalendarCalendarsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    isAvailable: '<',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsGoogleCalendarCalendarsCtrl', function(
  $controller, GoogleCalendar
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Load calendars if connected
    if (this.isConnected) {
      this.loadCalendars();
    }
  };

  /**
   * Load calendars
   */
  this.loadCalendars = function() {

    //Toggle flag and initialize calendars
    this.isLoading = true;
    this.calendars = [];

    //Load lists
    GoogleCalendar
      .getCalendars()
      .then(calendars => {
        this.calendars = calendars || [];
        this.toggleEnabled();
      })
      .catch(error => {

        //Not connected?
        if (error.code === 'NOT_CONNECTED') {
          this.integration.isConnected = false;
          this.card.showTab('status');
        }
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Toggle calendar
   */
  this.toggle = function(item, isEnabled) {
    item.isEnabled = isEnabled;
  };

  /**
   * Toggle enabled calendars
   */
  this.toggleEnabled = function() {

    //Get data
    const {calendars} = this;
    const {enabledCalendars} = this.integration.data;

    //No default calendars? Nothing to do
    if (!Array.isArray(enabledCalendars) || enabledCalendars.length === 0) {
      return;
    }

    //Loop actual calendars
    for (const calendar of calendars) {
      const {id} = calendar;
      calendar.isEnabled = enabledCalendars.some(cal => cal.id === id);
    }
  };

  /**
   * Save integration settings
   */
  this.save = function() {

    //Set lists in data and toggle flag
    this.isSaving = true;
    this.data.enabledCalendars = this.calendars
      .filter(cal => cal.isEnabled)
      .map(cal => {
        const {id, name, timezone, isPrimary} = cal;
        return {id, name, timezone, isPrimary};
      });

    //Update integration
    this
      .update()
      .then(() => this.card.showTab('status'))
      .finally(() => this.isSaving = false);
  };
});
