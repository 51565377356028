
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.EditFee.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editActivityFee', {
    templateUrl: 'admin/activity/modals/edit-fee.html',
    controller: 'ModalEditActivityFeeCtrl',
    closeOnClick: false,
    resolve: {
      memberships($store) {
        'ngInject';
        return $store.memberships.query();
      },
      club($store) {
        'ngInject';
        return $store.club.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalEditActivityFeeCtrl', function(
  $controller, $modalInstance, $filter, Modules,
  Weekdays, FeePolicies, MembershipConstraintLabels,
  LightControlModes, Helpers
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set initial step
    this.setStep('constraint');

    //Extract model
    this.model = this.fee.extract([
      'constraint', 'memberships', 'mode', 'timeRange',
      'amount', 'policy', 'discountedRate', 'discountedMinDuration',
    ]);

    //Fill time range
    if (!this.isEdit && this.model.timeRange.isEmpty()) {
      this.model.timeRange.fillDays();
      this.model.timeRange.fillTime();
    }

    //Set flags
    this.isSaving = false;
    this.isError = false;
    this.canHaveDiscountedRate = (
      !Modules.has('system') &&
      this.activity.isBookable && this.activity.isMultiSlotEnabled
    );

    //Set services
    this.Weekdays = Weekdays;
    this.MembershipConstraintLabels = MembershipConstraintLabels;

    //Default mode if none given yet
    if (!this.model.mode && this.activity.modes.length > 0) {
      this.model.mode = this.activity.modes[0].id;
    }

    //Find mode
    this.mode = this.activity.modes.find(m => m.id === this.model.mode);

    //Distribute memberships
    this.memberships = $filter('distribute')(this.memberships, 3);

    //Fee policies depend on mode method
    this.setFeePolicies();
  };

  /**
   * Set fee policies
   */
  this.setFeePolicies = function() {

    //Get data
    const {AFTERWARDS, WITH_CREDIT} = FeePolicies;

    //Check if can set in advance
    const canOnlyAdvance = (
      !Modules.has('system') ||
      (Modules.has('system') && !this.activity.isOnSystem)
    );
    const canOnlyAfter = !Modules.has('bookings');

    //Filter policies
    this.feePolicies = FeePolicies
      .filter(policy => (
        (!canOnlyAdvance || policy.isInAdvance) &&
        (!canOnlyAfter || !policy.isInAdvance)
      ));

    //Remove after options
    if (this.club.settings.system.lightControlMode
        !== LightControlModes.FLEXIBLE) {
      this.feePolicies = Helpers.removeOption(this.feePolicies, AFTERWARDS);
      this.feePolicies = Helpers.removeOption(this.feePolicies, WITH_CREDIT);
    }

    //Validate chosen policy
    if (!this.feePolicies.some(p => p.value === this.model.policy)) {
      this.model.policy = this.feePolicies[0].value;
    }
  };

  /**
   * Change mode
   */
  this.changeMode = function(mode) {
    this.model.mode = mode;
    this.mode = this.activity.modes.find(m => m.id === mode);
  };

  /**
   * Next step
   */
  this.next = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Go to next step
    this.form.$setPristine();
    this.setStep('fee');
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };
});
