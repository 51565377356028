
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Edit.Details.Card', [])

/**
 * Component
 */
.component('cardCouponTypeEditDetails', {
  templateUrl: 'admin/coupon-type/cards/details.html',
  controller: 'CardCouponTypeEditDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    couponType: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardCouponTypeEditDetailsCtrl', function(CardCloseReasons) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;

    //Create model subset
    this.model = this.couponType.extract([
      'name', 'price', 'description', 'conditions',
    ]);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {couponType, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {couponType, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
