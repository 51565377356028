
/**
 * Module definition and dependencies
 */
angular.module('Shared.GoogleCalendar.Service', [])

/**
 * Config
 */
.config($apiProvider => {
  $apiProvider.registerEndpoint('googleCalendar', {
    url: 'integration/googleCalendar',
    actions: {
      getCalendars: {
        url: 'calendars',
        method: 'GET',
        isArray: true,
      },
      sync: {
        url: 'sync/:eventId',
        method: 'POST',
        params: {
          eventId: '@eventId',
        },
      },
    },
  });
})

/**
 * Model definition
 */
.factory('GoogleCalendar', function($api) {

  //Create service
  const GoogleCalendar = {

    /**
     * Get lists
     */
    getCalendars() {
      return $api.googleCalendar.getCalendars();
    },

    /**
     * Sync
     */
    sync(eventId) {
      return $api.googleCalendar.sync({eventId}, {});
    },
  };

  //Return service
  return GoogleCalendar;
});
