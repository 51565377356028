
/**
 * Module definition and dependencies
 */
angular.module('Shared.TagOptions.Component', [])

/**
 * Tag options component
 */
.component('tagOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li>
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li ng-if="$ctrl.tag.isEnabled">
        <a ng-click="$ctrl.disable()">Disable</a>
      </li>
      <li ng-if="!$ctrl.tag.isEnabled">
        <a ng-click="$ctrl.enable()">Enable</a>
      </li>
      <li>
        <a ui-sref="admin.log.audit({model: 'Tag', id: $ctrl.tag.id})">View audit log</a>
      </li>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    tag: '<',
    canAddToSystem: '<',
    onAddToSystem: '&',
    onEdit: '&',
    onDelete: '&',
    onEnable: '&',
    onDisable: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Edit
     */
    this.edit = function() {
      this.onEdit({$event: {tag: this.tag}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      this.onDelete({$event: {tag: this.tag}});
    };

    /**
     * Enable
     */
    this.enable = function() {
      this.isBusy = true;
      this
        .onEnable({$event: {tag: this.tag}})
        .finally(() => this.isBusy = false);
    };

    /**
     * Disable
     */
    this.disable = function() {
      this.onDisable({$event: {tag: this.tag}});
    };
  },
});
