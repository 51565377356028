
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.Change', [
  'App.Subscription.Change.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('subscription.change', {
    url: '/change/:subId',
    component: 'subscriptionChangeRoute',
    params: {
      subId: {
        value: null,
        squash: true,
      },
    },
    resolve: {
      subscriptions(Subscription) {
        return Subscription.own();
      },
      payment(PaymentFlow, PaymentTypes, user) {
        return new PaymentFlow(PaymentTypes.SUBSCRIPTION_CHANGE, user);
      },
      outcome(PaymentOutcome) {
        return PaymentOutcome.check();
      },
      memberships($store) {
        return $store.memberships.query({isArchived: false});
      },
      integrations($store) {
        return $store.integrations.query(true);
      },
      old(transition, subscriptions) {
        const params = transition.params();
        return subscriptions.find(sub => sub.id === params.subId);
      },
    },
  });
})

/**
 * Component
 */
.component('subscriptionChangeRoute', {
  controller: 'SubscriptionChangeCtrl',
  templateUrl: 'subscription/change/change.html',
  bindings: {
    club: '<',
    user: '<',
    integrations: '<',
    memberships: '<',
    subscriptions: '<',
    old: '<',
    module: '<',
    transition: '<',
    payment: '<',
    outcome: '<',
  },
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'subscription.change'}, transition => {
    const params = transition.params();
    Page.setTitle('Change membership');
    Page.addCrumb({sref: 'subscription.change', params});
  });
});
