
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.PickMemberships.Card', [])

/**
 * Component
 */
.component('cardSubscriptionPickMemberships', {
  templateUrl: 'subscription/cards/pick-memberships.html',
  require: {
    card: '^^',
  },
  bindings: {
    isOwn: '<',
    member: '<',
    memberships: '<',
    subscriptions: '<',
    existing: '<',
    selection: '<',
    onNext: '&',
    onCancel: '&',
    onSelect: '&',
    onLoadSub: '&',
  },
  controller($modal, $focus) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Loading flags
      this.isLoading = {};

      //Filter memberships
      this.filterMemberships();

      //No memberships available for purchase?
      if (this.availableMemberships.length === 0) {
        return $modal
          .open('basic', {
            templateUrl: 'subscription/modals/no-memberships-available.html',
          })
          .closed
          .then(() => this.onCancel());
      }
    };

    /**
     * Filter memberships
     */
    this.filterMemberships = function() {

      //Get selected and current memberships
      const selected = this.selection.map(item => item.membership);
      const current = this.member.getCurrentMemberships();
      const combined = [...current, ...selected];
      const {age} = this.member;

      //Filter out available memberships
      this.availableMemberships = this.memberships
        .filter(membership => membership.canSelectForPurchase)
        .filter(membership => !membership.isLinked)
        .filter(membership => membership.isValidForAge(age))
        .filter(membership => {

          //Get selection without this membership and combine
          //with current memberships
          const set = selected
            .filter(m => m.id !== membership.id)
            .concat(current);

          //Membership valid for all combined memberships
          //or it was valid when we hadn't selected it yet
          return (
            membership.appliesToMemberships(combined) ||
            membership.appliesToMemberships(set)
          );
        });
    };

    /**
     * Add a membership to selection
     */
    this.addMembership = function(membership) {

      //Get member
      const {member} = this;

      //Add to selection
      this.selection.push({member, membership, linked: []});
      this.filterMemberships();
      this.checkRemaining();
    };

    /**
     * Remove a membership from selection
     */
    this.removeMembership = function(membership) {

      //Get index
      const i = this.selection.findIndex(s => s.membership === membership);
      if (i === -1) {
        return;
      }

      //Remove the membership from selection and filter available memberships now
      this.selection.splice(i, 1);
      this.filterMemberships();
      this.checkRemaining();
    };

    /**
     * Check validity of remaining memberships
     */
    this.checkRemaining = function() {

      //Go over remaining memberships, and if no allowed anymore, remove as well
      for (const item of this.selection) {
        const remaining = item.membership;
        if (!this.availableMemberships.some(m => m.id === remaining.id)) {
          return this.removeMembership(remaining);
        }
      }
    };

    /**
     * Select membership
     */
    this.selectMembership = function(membership) {

      //Cannot select membership if limit is reached
      if (membership.limit && (membership.numMembers >= membership.limit)) {
        return;
      }

      //Check if already selected
      if (this.isSelected(membership)) {
        this.removeMembership(membership);
        this.updateSelection();
        return;
      }

      //Flag as loading
      this.isLoading[membership.id] = true;

      //Load subscription data
      this
        .onLoadSub({$event: {membership}})
        .then(() => {
          this.addMembership(membership);
          this.updateSelection();
        })
        .finally(() => this.isLoading[membership.id] = false);
    };

    /**
     * Check if in selection
     */
    this.isSelected = function(membership) {
      return this.selection.some(s => s.membership === membership);
    };

    /**
     * Toggle select handler
     */
    this.updateSelection = function() {
      const {selection} = this;
      this.onSelect({$event: {selection}});
    };

    /**
     * Verify age
     */
    this.verifyAge = function() {

      //Check dob
      if (!this.dob) {
        return;
      }

      //Set flag
      this.isVerifyingAge = true;
      const dob = this.dob.toDob();
      const num = this.selection.length;

      //Update member
      this.member
        .patch({dob})
        .then(() => {

          //Show flags and update selection
          this.showAgeVerification = false;
          this.selection = this.selection
            .filter(s => s.membership.isValidForAge(this.member.age));

          //Filter memberships again
          this.filterMemberships();

          //Some were removed
          if (this.selection.length < num) {
            this.isAgeError = true;
          }
          else {
            this.next();
          }
        })
        .finally(() => this.isVerifyingAge = false);
    };

    /**
     * Next step
     */
    this.next = function() {

      //Get age data
      const {age} = this.member;
      const hasAgeRestrictions = this.selection
        .some(s => s.membership.hasAgeRestriction);

      //Check if validation is needed
      if (!age && hasAgeRestrictions) {
        this.showAgeVerification = true;
        $focus('dob');
        return;
      }

      //Onwards
      this.onNext();
    };

    /**
     * Cancel flow
     */
    this.cancel = function() {
      this.onCancel();
    };
  },
});
