
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.Homepage.Card', [])

/**
 * Component
 */
.component('cardSettingsHomepage', {
  templateUrl: 'admin/settings/cards/homepage.html',
  controller: 'CardSettingsHomepageCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    pages: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSettingsHomepageCtrl', function(
  $controller, Modules
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'homepage';
    this.setupStep = 'changeButtons';

    //Button numbers
    this.buttons = [1, 2, 3, 4];

    //Modules
    const modules = [
      'account',
      'bookings',
      'calendar',
      'coupons',
      'directory',
      'profile',
      'subscription',
      'feedback',
      'invite',
    ];

    //Options for action buttons
    this.ActionButtonOptions = modules
      .filter(module => Modules.has(module))
      .map(module => ({
        label: Modules.title(module),
        value: module,
      }));

    //Colors for action buttons
    this.ActionButtonColors = [
      {
        label: 'Green',
        value: '#19a083',
      },
      {
        label: 'Blue',
        value: '#009fd2',
      },
      {
        label: 'Yellow',
        value: '#efaa07',
      },
      {
        label: 'Red',
        value: '#c30d45',
      },
      {
        label: 'Black',
        value: '#000000',
      },
    ];

    //Add primary brand colour
    const {branding} = this.club.settings;
    if (branding.primaryColor) {
      this.ActionButtonColors.push({
        label: 'Brand colour',
        value: branding.primaryColor,
      });
    }

    //Add custom pages
    for (const page of this.pages) {
      this.ActionButtonOptions.push({
        label: page.title,
        value: `page:${page.id}`,
      });
    }

    //Call parent init
    $base.$onInit.call(this);

    //Transform action button options for pages
    const {model} = this;
    for (const i of this.buttons) {
      const {option, pageId} = model[`actionButton${i}`];
      if (option === 'page') {
        this.updateButton(i, 'option', `page:${pageId}`);
      }
    }
  };

  /**
   * Update button
   */
  this.updateButton = function(i, prop, value) {
    this.model[`actionButton${i}`][prop] = value;
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Split action button values into option and page ID again
    const {model} = this;
    for (const i of this.buttons) {
      const {option} = model[`actionButton${i}`];
      const match = option.match(/^page:([a-f0-9]+)$/);
      if (match) {
        const pageId = match[1];
        this.updateButton(i, 'pageId', pageId);
        this.updateButton(i, 'option', 'page');
      }
      else {
        this.updateButton(i, 'pageId', null);
      }
    }

    //Parent method
    $base.save.call(this);
  };
});
