
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.AccountCredit.Card', [])

/**
 * Component
 */
.component('cardMemberAccountCredit', {
  templateUrl: 'admin/people/members/cards/account-credit.html',
  controller: 'CardMemberAccountCreditCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    isOwn: '<',
    member: '<',
    limit: '<',
    accountCredits: '<',
    canLoadMore: '<',
    onReload: '&',
    onLoadMore: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberAccountCreditCtrl', function(
  $modal, $notice, Transaction, AccountCredit
) {

  /**
   * On changes
   */
  this.$onChanges = function() {
    this.hasAccountCredits = this.accountCredits.length > 0;
    this.hasMore = this.accountCredits.length > this.limit;
  };

  /**
   * Show all
   */
  this.showAll = function() {
    this.hasMore = false;
    this.limit = undefined;
  };

  /**
   * Load more
   */
  this.loadMore = function() {
    this.isLoadingMore = true;
    this.limit = undefined;
    this
      .onLoadMore()
      .finally(() => this.isLoadingMore = false);
  };

  /**
   * Add account credit
   */
  this.add = function() {

    //Get member, create transaction
    const {member} = this;
    const transaction = new Transaction({member});

    //Define handler
    const handler = data => transaction.save(data);

    //Open modal
    $modal
      .open('addAccountCredit', {locals: {member, transaction, handler}})
      .result
      .then(() => {
        $notice.show('Account credit added');
        this.onReload();
      });
  };

  /**
   * Transfer account credit
   */
  this.transfer = function() {

    //Get member
    const {member} = this;
    const allowNegative = true;

    //Define handler
    const handler = data => AccountCredit.transfer(member.id, data);

    //Open modal
    $modal
      .open('transferAccountCredit', {locals: {member, allowNegative, handler}})
      .result
      .then(() => {
        $notice.show('Account credit transferred');
        this.onReload();
      });
  };
});
