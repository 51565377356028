
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Report.Membership.Controller', [])

/**
 * Controller
 */
.controller('AdminReportMembershipCtrl', function(Membership) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Assign params to self
    Object.assign(this, this.transition.params());

    //Check if comparing
    this.isComparing = Object.keys(this.compData).length > 0;
    this.showPercentage = true;

    //Get base and comparison data
    const base = this.baseData;
    const comp = this.compData;

    //Get memberships
    const {memberships} = this;
    memberships.push(new Membership({id: null, name: 'None'}));

    //Create age group data
    const ageGroups = memberships.reduce((items, membership) => {

      //Initialize
      const {id, ages} = membership;
      const label = ages || 'Any';
      if (!items.has(ages)) {
        items.set(ages, {label, count: 0});
      }

      //Get item and add count
      const item = items.get(ages);
      item.count += this.total(base, 'count', {membership: id});
      return items;
    }, new Map());

    //Create activities data
    const activities = memberships.reduce((items, membership) => {

      //Get data
      const {id, activities: ids} = membership;
      const key = (ids.length > 2) ? 'multi' : ids.join('.');
      const label = this.makeActivitiesLabel(ids);
      // console.log({
      //   name: membership.name,
      //   activities: membership.activities,
      //   key, label,
      // });

      //Initialize
      if (!items.has(key)) {
        items.set(key, {label, count: 0});
      }

      //Get item and add count
      const item = items.get(key);
      item.count += this.total(base, 'count', {membership: id});
      return items;
    }, new Map());

    // console.log('Activities data', activities);

    //Generate list by membership
    this.byMembership = memberships
      .map(membership => ({
        name: membership.nameWithSuffix || 'Casuals',
        isCasual: !membership.id,
        base: {
          total: this.total(base, 'count', {
            membership: membership.id,
          }),
          male: this.total(base, 'count', {
            membership: membership.id,
            gender: 'male',
          }),
          female: this.total(base, 'count', {
            membership: membership.id,
            gender: 'female',
          }),
          diverse: this.total(base, 'count', {
            membership: membership.id,
            gender: 'diverse',
          }),
          hidden: this.total(base, 'count', {
            membership: membership.id,
            gender: 'hidden',
          }),
          unknown: this.total(base, 'count', {
            membership: membership.id,
            gender: 'unknown',
          }),
        },
        comp: {
          total: this.total(comp, 'count', {
            membership: membership.id,
          }),
          male: this.total(comp, 'count', {
            membership: membership.id,
            gender: 'male',
          }),
          female: this.total(comp, 'count', {
            membership: membership.id,
            gender: 'female',
          }),
          diverse: this.total(base, 'count', {
            membership: membership.id,
            gender: 'diverse',
          }),
          hidden: this.total(base, 'count', {
            membership: membership.id,
            gender: 'hidden',
          }),
          unknown: this.total(comp, 'count', {
            membership: membership.id,
            gender: 'unknown',
          }),
        },
      }))
      .filter(data => data.base.total || data.comp.total);

    //Totals
    this.totals = {
      base: {
        total: this.total(base, 'count'),
        male: this.total(base, 'count', {gender: 'male'}),
        female: this.total(base, 'count', {gender: 'female'}),
        diverse: this.total(base, 'count', {gender: 'diverse'}),
        hidden: this.total(base, 'count', {gender: 'hidden'}),
        unknown: this.total(base, 'count', {gender: 'unknown'}),
      },
      comp: {
        total: this.total(comp, 'count'),
        male: this.total(comp, 'count', {gender: 'male'}),
        female: this.total(comp, 'count', {gender: 'female'}),
        diverse: this.total(base, 'count', {gender: 'diverse'}),
        hidden: this.total(base, 'count', {gender: 'hidden'}),
        unknown: this.total(comp, 'count', {gender: 'unknown'}),
      },
    };

    //Gender chart data
    this.genderChartData = [
      {
        label: 'Male',
        count: this.totals.base.male,
        percentage:
          this.divide(this.totals.base.male, this.totals.base.total),
      },
      {
        label: 'Female',
        count: this.totals.base.female,
        percentage:
          this.divide(this.totals.base.female, this.totals.base.total),
      },
      {
        label: 'Non-binary',
        count: this.totals.base.diverse,
        percentage:
          this.divide(this.totals.base.diverse, this.totals.base.total),
      },
      {
        label: 'Rather not say',
        count: this.totals.base.hidden,
        percentage:
          this.divide(this.totals.base.hidden, this.totals.base.total),
      },
      {
        label: 'Unknown',
        count: this.totals.base.unknown,
        percentage:
          this.divide(this.totals.base.unknown, this.totals.base.total),
      },
    ];

    //Totals
    const {total} = this.totals.base;

    //With/without membership
    this.totalWithout = {
      base: this.total(base, 'count', {membership: null}),
      comp: this.total(comp, 'count', {membership: null}),
    };
    this.totalWith = {
      base: this.totals.base.total - this.totalWithout.base,
      comp: this.totals.comp.total - this.totalWithout.comp,
    };

    //Activity chart data
    this.activityChartData = Array
      .from(activities.values())
      .filter(data => data.count > 0)
      .map(data => {
        const {count, label} = data;
        return {
          label, count,
          percentage: this.divide(count, total),
        };
      });

    //Age group chart data
    this.ageGroupChartData = Array
      .from(ageGroups.values())
      .filter(data => data.count > 0)
      .map(data => {
        const {count, label} = data;
        return {
          label, count,
          percentage: this.divide(count, total),
        };
      });

    //Membership chart data
    this.membershipChartData = [
      {
        label: 'Memberships',
        count: this.totalWith.base,
        percentage: this.divide(this.totalWith.base, total),
      },
      {
        label: 'Casuals',
        count: this.totalWithout.base,
        percentage: this.divide(this.totalWithout.base, total),
      },
    ];
  };

  /**
   * Make activities label helper
   */
  this.makeActivitiesLabel = function(ids) {

    //None
    if (ids.length === 0) {
      return 'None';
    }

    //Many
    if (ids.length > 2) {
      return 'Multiple';
    }

    //Get activities
    const activities = this.activities
      .filter(activity => ids.includes(activity.id))
      .map(activity => activity.name);

    //Concatenate
    return activities.join(' & ');
  };

  /**
   * Divide helper
   */
  this.divide = function(a, b) {
    return b ? a / b : 0;
  };

  /**
   * Helper to total
   */
  this.total = function(items, prop, filter) {

    //No items?
    if (!items) {
      return 0;
    }

    //Filter present?
    if (filter) {
      const {membership, gender} = filter;
      items = items
        .filter(item => {
          if (membership === null && item.membership !== null) {
            return false;
          }
          if (membership) {
            if (!item.membership || item.membership !== membership) {
              return false;
            }
          }
          if (gender === null && item.gender !== null) {
            return false;
          }
          if (gender) {
            if (!item.gender || item.gender !== gender) {
              return false;
            }
          }
          return true;
        });
    }

    //Total
    return items.reduce((total, item) => total + item[prop], 0);
  };

  /**
   * Toggle percentage
   */
  this.togglePercentage = function() {
    this.showPercentage = !this.showPercentage;
  };
});
