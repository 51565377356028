
/**
 * Module definition and dependencies
 */
angular.module('Shared.Moment.Service', [])

/**
 * Service
 */
.factory('moment', function($window) {

  //Get moment object from window
  const moment = $window.moment || null;
  if (!moment) {
    throw new Error('Missing moment library. Did you load it in your page?');
  }

  //Get prototype
  const proto = Object.getPrototypeOf(moment());

  /**
   * Get minutes since midnight
   * NOTE: This doesn't work properly for a day on which DST comes into effect
   * at say 2am, because it will determine the offset for that day to be 60 minutes,
   * however it will also apply it at midnight or at 1 am, even though the offset
   * should only be applied after DST came into effect, so after 3 am.
   * Ideally when migrating to Luxon, we won't use getTime/setTime anymore
   */
  proto.getTime = function(zeroIsMidnight = false) {
    const offset = this.getTimeOffset();
    const time = this.diff(this.clone().startOf('day'), 'minutes') - offset;
    if (zeroIsMidnight && time === 0) {
      return 1440;
    }
    return time;
  };

  /**
   * Set time as minutes since midnight
   */
  proto.setTime = function(time) {
    const offset = this.getTimeOffset();
    return this.startOf('day').add(time + offset, 'minutes');
  };

  /**
   * Check a day's time offset due to DST
   */
  proto.getTimeOffset = function() {
    const start = this.clone().startOf('day');
    const end = this.clone().endOf('day');
    return end.diff(start, 'minutes') - (24 * 60) + 1;
  };

  /**
   * Get number of weeks in the year of the moment
   */
  proto.weeksInYear = function() {
    const a = this.clone().endOf('year');
    const b = a.clone().subtract(1, 'week');
    return Math.max(a.isoWeek(), b.isoWeek());
  };

  /**
   * Debug helper
   */
  proto.debug = function() {
    return this.format('DD-MM-YYYY HH:mm');
  };

  /**
   * Set base time
   */
  moment.setBaseTime = function(baseTime) {
    const offset = new Date(baseTime).getTime() - Date.now();
    moment.now = function() {
      return Date.now() + offset;
    };
  };

  /**
   * Get number of weeks in given year
   */
  moment.weeksInYear = function(year) {
    if (typeof year !== 'number') {
      throw new Error(`Missing or invalid year: ${year}`);
    }
    const a = moment().year(year).endOf('year');
    const b = a.clone().subtract(1, 'week');
    return Math.max(a.isoWeek(), b.isoWeek());
  };

  /**
   * Redefine weekdays functions
   */
  moment.wkDays = function() {
    const days = moment.weekdays();
    days.push(days.splice(0, 1)[0]);
    return days;
  };
  moment.wkDaysShort = function() {
    const days = moment.weekdaysShort();
    days.push(days.splice(0, 1)[0]);
    return days;
  };
  moment.wkDaysMin = function() {
    const days = moment.weekdaysMin();
    days.push(days.splice(0, 1)[0]);
    return days;
  };
  moment.wkDaysFirstLetter = function() {
    const days = moment.weekdaysMin()
      .map(day => day.charAt(0));
    days.push(days.splice(0, 1)[0]);
    return days;
  };

  /**
   * Use iso weeks by default
   */
  const {startOf, endOf} = proto;
  proto.startOf = function(period) {
    if (period === 'week') {
      period = 'isoWeek';
    }
    return startOf.call(this, period);
  };
  proto.endOf = function(period) {
    if (period === 'week') {
      period = 'isoWeek';
    }
    return endOf.call(this, period);
  };

  /**
   * To DOB helper
   */
  proto.toDob = function() {
    const dob = this.format('YYYYMMDD');
    return Number(dob);
  };

  /**
   * From DOB helper
   */
  moment.fromDob = function(dob) {
    if (moment.isMoment(dob)) {
      return dob;
    }
    if (dob && typeof dob === 'number') {
      return moment(dob, 'YYYYMMDD');
    }
    return null;
  };

  //Return moment
  return moment;
});
