
/**
 * Module definition and dependencies
 */
angular.module('Shared.Notes.Card', [])

/**
 * Component
 */
.component('cardNotes', {
  templateUrl: 'cards/notes.html',
  controller: 'CardNotesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    item: '<',
    itemType: '@',
    isEdit: '<',
    hasNext: '<',
    onSave: '&',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardNotesCtrl', function(CardCloseReasons) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;
    this.isAdd = !this.isEdit;

    //Create model subset
    this.model = this.item.extract(['notes']);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {item, itemType, model} = this;
    const $event = {[itemType]: item, model};
    this.onMerge({$event});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next card
    this.merge();
    this.card.next();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previousTab();
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get data
    const {isEdit, item, itemType, model} = this;
    const $event = {[itemType]: item, model, isEdit};

    //Mark as saving
    this.isSaving = true;

    //Persist
    return this
      .onSave({$event})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
