
/**
 * Define values
 */
const MEMBERS = 'members';
const GROUPS = 'groups';
const ATTENDEES = 'attendees';
const INTERESTED = 'interested';
const ORGANISERS = 'organisers';
const NO_SHOWS = 'noShows';

/**
 * Define array
 */
const EventAudiences = [
  {
    label: 'All members',
    value: MEMBERS,
  },
  {
    label: 'Specific member groups',
    value: GROUPS,
  },
  {
    label: 'Interested members',
    value: INTERESTED,
  },
  {
    label: 'All attendees',
    value: ATTENDEES,
  },
  {
    label: 'No-show attendees',
    value: NO_SHOWS,
  },
  {
    label: 'Organisers',
    value: ORGANISERS,
  },
];

/**
 * Set properties
 */
EventAudiences.MEMBERS = MEMBERS;
EventAudiences.GROUPS = GROUPS;
EventAudiences.ATTENDEES = ATTENDEES;
EventAudiences.INTERESTED = INTERESTED;
EventAudiences.ORGANISERS = ORGANISERS;
EventAudiences.NO_SHOWS = NO_SHOWS;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.EventAudiences.Options', [])
  .constant('EventAudiences', EventAudiences);
