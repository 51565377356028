
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.SignupClub.Details', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('signupClub.details', {
    url: '/details',
    component: 'signupDetailsRoute',
  });
})

/**
 * Route component
 */
.component('signupDetailsRoute', {
  controller: 'SignupClubDetailsCtrl',
  templateUrl: 'portal/signup-club/steps/details.html',
  bindings: {
    club: '<',
    countries: '<',
  },
})

/**
 * Controller
 */
.controller('SignupClubDetailsCtrl', function(
  $state, $timeout, $http, $modal, ExistsError, RegexChinese
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set country if present, otherwise set default and then geo locate
    if (this.club.countryCode) {
      this.setCountry(this.club.countryCode);
    }
    else {
      this.setCountry('NZ');
      this.geoLocate();
    }

    //Start faded
    this.fade = true;
    $timeout(() => this.fade = false);
  };

  /**
   * Geo locate
   */
  this.geoLocate = function() {

    //Geolocating
    this.isGeoLocating = true;

    //Lookup IP
    $http
      .get('https://extreme-ip-lookup.com/json/', {
        ignoreServerError: true,
        ignoreTimeoutError: true,
        skipAccessToken: true,
        headers: {
          'X-Club': undefined,
          'X-Hostname': undefined,
          'X-Version': undefined,
        },
      })
      .then(response => {
        if (response.data && response.data.countryCode) {
          this.setCountry(response.data.countryCode);
        }
      })
      .catch(() => {})
      .finally(() => this.isGeoLocating = false);
  };

  /**
   * Update club
   */
  this.updateClub = function(property, value) {
    this.club[property] = value;
  };

  /**
   * Set country
   */
  this.setCountry = function(code) {

    //Get country
    const country = this.countries.find(country => country.code === code);
    const {timezone} = this.club;

    //Set in scope and update country code on club
    this.country = country;
    this.club.countryCode = code;

    //Determine timezone, with fallback
    if (!timezone || !country.timezones.includes(timezone)) {
      this.determineTimezone(country.timezones[0]);
    }
  };

  /**
   * Try to determine timezone
   */
  this.determineTimezone = function(fallback) {

    //Auto detect timezone
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (this.country.timezones.includes(timezone)) {
        return this.setTimezone(timezone);
      }
    }
    catch (error) {
      //Pass through
    }

    //Set fallback
    this.setTimezone(fallback);
  };

  /**
   * Set timezone
   */
  this.setTimezone = function(timezone) {
    this.club.timezone = timezone;
  };

  /**
   * Continue
   */
  this.continue = function(isConfirmed = false) {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Block Chinese for now
    if (RegexChinese.test(this.club.name)) {
      this.isErrorChinese = true;
      return;
    }

    //Flags
    this.isBusy = true;
    this.fade = true;

    //Check club exists
    this.club
      .checkExists(isConfirmed)
      .then(() => {
        if (this.club.activity) {
          $state.go('signupClub.activity');
        }
        else {
          $state.go('signupClub.interests');
        }
      })
      .catch(error => {

        //Existing club found?
        if (error instanceof ExistsError) {

          //Get data
          const {club, appUrl, isRegistrationEnabled} = error.data;
          const {name, email} = this.club;
          const isExactMatch = (
            club.email.toLowerCase() === email.toLowerCase() ||
            club.name.toLowerCase() === name.toLowerCase()
          );

          //Show modal
          $modal.open('basic', {
            templateUrl: 'portal/signup-club/modals/club-exists.html',
            locals: {club, appUrl, isRegistrationEnabled, isExactMatch, name},
            rejectOnDismissal: true,
          })
          .result
          .then(() => this.continue(true));

          //Reset flags
          this.isBusy = false;
          this.fade = false;
          return;
        }

        //Send back to type if we get another error. It could be the infamous
        //issue with the "no club name" (see #4217), so let's send them back
        //so they can enter a name for now
        else {
          $state.go('signupClub.type');
        }
      });
  };

  /**
   * Back to previous step
   */
  this.back = function() {
    this.fade = true;
    $timeout(() => {
      $state.go('signupClub.type');
    }, 200);
  };
});
