
/**
 * Module definition and dependencies
 */
angular.module('Shared.Cards.Card.Component', [])

/**
 * Component
 */
.component('card', {
  template: `
    <div class="Card {{$ctrl.classes}}" ng-class="{'is-open': $ctrl.isOpen, 'is-opening': $ctrl.isOpening, 'is-hidden': $ctrl.isHidden, 'is-static': $ctrl.isStatic}" ng-transclude tabindex="-1">
    </div>
    `,
  transclude: true,
  require: {
    cards: '^^',
  },
  bindings: {
    id: '@',
    classes: '@',
    isDirty: '<',
    isStatic: '<',
    isOpenable: '<',
    preventBodyClose: '<',
    startOpen: '<',
    startOpenTab: '<',
    hideWhenClosed: '<',
    beforeOpen: '&',
    onOpen: '&',
    onClose: '&',
  },

  /**
   * Controller
   */
  controller(
    $focus, $element, $modal, $timeout, $document, $window, $q, CardCloseReasons
  ) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Register card
      if (this.id) {
        this.cards.add(this.id, this);
      }

      //Tabs
      this.tabs = [];

      //Flags
      this.isOpen = false;
      this.isHidden = false;

      //Hide when closed?
      if (this.hideWhenClosed) {
        this.isHidden = true;
      }
    };

    /**
     * On destroy
     */
    this.$onDestroy = function() {

      //Unregister card
      if (this.id) {
        this.cards.remove(this.id);
      }
    };

    /**
     * Post link
     */
    this.$postLink = function() {

      //Add class
      $element.addClass('CardComponent');

      //If openable and starting open, open now
      if (this.isOpenable && this.startOpen) {
        this
          .open()
          .then(() => {
            if (this.startOpenTab) {
              this.showTab(this.startOpenTab);
            }
          });
      }
    };

    /**
     * Open this (or another) card
     */
    this.open = function(id, reason) {
      id = id || this.id;
      return this.cards
        .open(id, reason)
        .then(() => this.scrollIntoView(id));
    };

    /**
     * Close this card
     */
    this.close = function(reason) {
      return this.cards
        .close(reason)
        .then(() => this.scrollIntoView());
    };

    /**
     * Open next card
     */
    this.next = function() {
      return this.cards.next();
    };

    /**
     * Open previous card
     */
    this.previous = function() {
      return this.cards.previous();
    };

    /**
     * Hide (don't remove from DOM)
     */
    this.hide = function() {
      this.isHidden = true;
    };

    /**
     * Show
     */
    this.show = function() {
      this.isHidden = false;
    };

    /**
     * Scroll into view
     */
    this.scrollIntoView = function(id) {

      //Get ID
      id = id || this.id;
      if (!id) {
        return;
      }

      //Scroll into view after a timeout
      $timeout(() => {

        //Find element
        const element = $document[0].getElementById(id);
        if (!element) {
          return;
        }

        //Determine heights and positions
        const windowHeight = Math.round($window.innerHeight / 2);
        const cardHeight = Math.round(element.children[0].clientHeight / 2);
        const topPos = element.offsetTop + 16;
        const centerPos = topPos - windowHeight + cardHeight + 54;
        if ('scrollBehavior' in document.documentElement.style) {
          $window.scrollTo({
            top: Math.min(topPos, centerPos),
            behavior: 'smooth',
          });
        }
        else {
          $window.scrollTo(0, Math.min(topPos, centerPos));
        }
      }, 10);
    };

    /**
     * Pre open handler
     */
    this.setIsOpening = function(isOpening) {
      this.isOpening = isOpening;
    };

    /**
     * Open handler
     */
    this.doOpen = function() {
      this.isOpening = false;
      this.isOpen = true;
      this.activeTab = null;
      if (this.hideWhenClosed) {
        this.isHidden = false;
      }
      $focus($element);
      this.onOpen({$event: {card: this}});
    };

    /**
     * Close handler
     */
    this.doClose = function(reason) {
      this.isOpen = false;
      this.isOpening = false;
      if (this.hideWhenClosed) {
        this.isHidden = true;
      }
      this.onClose({$event: {card: this, reason}});
    };

    /**
     * Set dirty
     */
    this.setDirty = function(isDirty) {
      this.isDirty = isDirty;
    };

    /**
     * Setup standard dirty check
     */
    this.dirtyCheck = function(form) {

      //Extract reasons
      const validReasons = [
        CardCloseReasons.SAVED,
        CardCloseReasons.OPENED_NEXT,
        CardCloseReasons.OPENED_PREV,
      ];

      //Apply before close handler
      this.beforeClose = function(reason) {

        //Allow with valid reason
        if (validReasons.includes(reason)) {
          return $q.resolve();
        }

        //Check if dirty
        if (this.isDirty || (form && form.$dirty)) {
          return $modal.open('confirmUnsavedChanges').result;
        }
      };
    };

    /**
     * Add tab
     */
    this.addTab = function(tab) {
      if (!this.tabs.includes(tab)) {
        this.tabs.push(tab);
      }
    };

    /**
     * Show tab
     */
    this.showTab = function(tab) {
      if (tab) {
        this.activeTab = tab;
      }
    };

    /**
     * Show next tab
     */
    this.nextTab = function() {

      //Must have active tab
      if (!this.activeTab) {
        return this.next();
      }

      //Get index of current tab
      const i = this.tabs.indexOf(this.activeTab);
      if (i !== -1 && (i + 1) < this.tabs.length) {
        return this.showTab(this.tabs[i + 1]);
      }

      //Open next card instead
      return this.next();
    };

    /**
     * Show previous tab
     */
    this.previousTab = function() {

      //Must have active tab
      if (!this.activeTab) {
        return this.previous();
      }

      //Get index of current tab
      const i = this.tabs.indexOf(this.activeTab);
      if (i !== -1 && i > 0) {
        return this.showTab(this.tabs[i - 1]);
      }

      //Open previous card instead
      return this.previous();
    };

    /**
     * Show first tab
     */
    this.firstTab = function() {
      if (this.tabs.length > 0) {
        this.showTab(this.tabs[0]);
      }
    };

    /**
     * Show last tab
     */
    this.lastTab = function() {
      if (this.tabs.length > 0) {
        this.showTab(this.tabs[this.tabs.length - 1]);
      }
    };

    /**
     * Check if tab is active
     */
    this.isTabActive = function(tab) {
      return (this.activeTab === tab);
    };

    /**
     * Add class
     */
    this.addClass = function(className) {
      this.classes = this.classes || '';
      this.classes += ` ${className}`;
      this.classes.trim();
    };
  },
});
