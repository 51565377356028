
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.ConfirmDeleteAttendee.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('confirmDeleteAttendee', {
    templateUrl: 'admin/event/modals/confirm-delete-attendee.html',
    controller: 'ModalConfirmDeleteAttendeeCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalConfirmDeleteAttendeeCtrl', function(
  $controller, $modalInstance, EventRecurrenceOptions, EventMultiDayOptions
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set flags
    this.isSaving = false;
    this.isError = false;

    //Set method
    this.method = 'instance';
    this.refund = this.attendee.canStillBeRefunded;

    //Set options
    this.recurrenceOptions = EventRecurrenceOptions;
    this.multiDayOptions = EventMultiDayOptions;
  };

  /**
   * Update method
   */
  this.updateMethod = function(method) {
    this.method = method;
  };

  /**
   * Toggle refund
   */
  this.toggleRefund = function(refund) {
    this.refund = refund;
  };

  /**
   * Save
   */
  this.save = function() {

    //Reset flags
    this.isSaving = true;

    //Make data
    const {method, refund} = this;

    //Use save handler
    this
      .handler({method, refund})
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isSaving = false);
  };
});
