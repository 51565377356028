
/**
 * Module definition and dependencies
 */
angular.module('Shared.MembershipStore.Service', [
  'Store.CollectionStore.Service',
])

/**
 * Model definition
 */
.factory('MembershipStore', function($collectionStore) {

  /**
   * Constructor
   */
  function MembershipStore(name, config) {
    $collectionStore.call(this, name, config);
  }

  /**
   * Extend prototype
   */
  angular.extend(MembershipStore.prototype, $collectionStore.prototype);

  /**
   * Save membership
   */
  MembershipStore.prototype.save = function(item, data) {
    return this.validateIsModel(item, true)
      .then(item => item.save(data))
      .then(item => this.add(item))
      .then(item => {

        //Update any linked memberships
        if (item.hasLinked) {
          const memberships = Array.from(this.collection.values());
          memberships
            .filter(membership => (
              membership.primary && membership.primary.id === item.id
            ))
            .forEach(membership => {
              membership.updateFrom(item);
            });
        }

        //Return item
        return item;
      });
  };

  //Return
  return MembershipStore;
});
