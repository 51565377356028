
/**
 * Module definition and dependencies
 */
angular.module('Shared.TagValidityUnits.Options', [])

/**
 * Constant definition
 */
.constant('TagValidityUnits', [
  {
    value: 'minutes',
    label: 'Minutes',
  },
  {
    value: 'hours',
    label: 'Hours',
  },
  {
    value: 'days',
    label: 'Days',
  },
  {
    value: 'weeks',
    label: 'Weeks',
  },
  {
    value: 'months',
    label: 'Months',
  },
]);
