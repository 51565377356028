
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Add.CustomFields.Card', [])

/**
 * Component
 */
.component('cardMemberAddCustomFields', {
  templateUrl: 'admin/people/members/cards/custom-fields.html',
  controller: 'CardMemberAddCustomFieldsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    member: '<',
    groups: '<',
    customFields: '<',
    asAdmin: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberAddCustomFieldsCtrl', function(
  $controller, $timeout, $element, $focus
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMemberEditCustomFieldsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent method
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;
  };

  /**
   * Post link
   */
  this.$postLink = function() {

    //Call parent method
    $base.$postLink.call(this);

    //Focus on input
    $timeout(() => {
      $focus($element.find('input')[0]);
    });
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {member, model} = this;
    this.onMerge({$event: {member, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next tab
    this.merge();
    this.card.nextTab();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previousTab();
  };
});
