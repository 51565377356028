
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.Alerts.Card', [])

/**
 * Component
 */
.component('cardSystemAlerts', {
  templateUrl: 'admin/system/cards/alerts.html',
  controller: 'CardSystemAlertsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    system: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSystemAlertsCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'system';
    this.thresholdOptions = [
      {
        label: '1 minute',
        value: 1,
      },
      {
        label: '5 minutes',
        value: 5,
      },
      {
        label: '10 minutes',
        value: 10,
      },
      {
        label: '15 minutes',
        value: 15,
      },
      {
        label: '30 minutes',
        value: 30,
      },
      {
        label: '1 hour',
        value: 60,
      },
      {
        label: '2 hours',
        value: 120,
      },
      {
        label: '3 hours',
        value: 180,
      },
      {
        label: '6 hours',
        value: 360,
      },
      {
        label: '12 hours',
        value: 720,
      },
      {
        label: '24 hours',
        value: 1440,
      },
    ];

    //Call parent init
    $base.$onInit.call(this);

    //Ensure alerts set in model
    const alertTypes = [
      'emergencyExit',
      'powerFault',
      'serviceFault',
      'commsFault',
      'tamper',
      'offline',
      'generic',
    ];

    //Check set
    for (const type of alertTypes) {
      if (typeof this.model.alert[type] === 'undefined') {
        this.model.alert[type] = {
          isEnabled: false,
        };
      }
    }
  };
});
