
/**
 * Module definition and dependencies
 */
angular.module('Shared.ModuleDefaults.Constant', [])

/**
 * Constant definition
 */
.constant('ModuleDefaults', {
  kiosk: {
    title: 'Kiosk',
    icon: 'home',
    sref: 'kiosk',
    menu: 'kiosk',
    user: false,
  },
  home: {
    title: 'Home',
    icon: 'home',
    sref: 'home',
    menu: 'user',
  },
  bookings: {
    title: 'Bookings',
    icon: 'date_range',
    menu: 'user',
    sref: 'bookings',
    canToggle: true,
  },
  calendar: {
    title: 'Calendar',
    icon: 'today',
    sref: 'calendar',
    menu: 'user',
  },
  directory: {
    title: 'Directory',
    icon: 'people',
    sref: 'directory.overview',
    menu: 'user',
    canToggle: true,
  },
  profile: {
    title: 'Profile',
    icon: 'person',
    sref: 'profile',
    menu: 'user',
  },
  subscription: {
    title: 'Memberships',
    icon: 'bookmark',
    sref: 'subscription.overview',
    menu: 'user',
    roles: ['member'],
    canToggle: true,
  },
  coupons: {
    title: 'Coupons',
    icon: 'loyalty',
    singular: 'coupon',
    plural: 'coupons',
    sref: 'coupon.overview',
    menu: 'user',
    roles: ['member'],
    canToggle: true,
  },
  account: {
    title: 'Account',
    icon: 'payment',
    sref: 'account.overview',
    menu: 'user',
  },
  club: {
    title: 'Club',
    icon: 'account_balance',
    singular: 'club',
    sref: 'admin.club',
    menu: 'admin',
    roles: ['admin'],
  },
  people: {
    title: 'People',
    icon: 'group',
    sref: 'admin.people.members.overview',
    menu: 'admin',
    roles: ['admin', 'viewer'],
  },
  members: {
    title: 'Members',
    singular: 'member',
    plural: 'members',
    sref: 'admin.people.members.overview',
    roles: ['admin', 'viewer'],
  },
  staff: {
    title: 'Staff',
    sref: 'admin.people.staff.overview',
    roles: ['admin', 'viewer'],
  },
  contacts: {
    title: 'Contacts',
    sref: 'admin.people.contacts.overview',
    roles: ['admin', 'viewer'],
  },
  circles: {
    title: 'Circles',
    sref: 'admin.people.circles.overview',
    roles: ['admin', 'viewer'],
  },
  events: {
    title: 'Events',
    icon: 'event',
    sref: 'admin.event.overview',
    menu: 'admin',
    roles: ['admin', 'viewer', 'eventManager'],
  },
  memberships: {
    title: 'Membership types',
    icon: 'bookmarks',
    singular: 'membership type',
    plural: 'membership types',
    sref: 'admin.membership.overview',
    menu: 'admin',
    roles: ['admin', 'viewer'],
    canToggle: true,
  },
  couponTypes: {
    title: 'Coupon types',
    icon: 'local_offer',
    singular: 'coupon type',
    plural: 'coupon types',
    sref: 'admin.couponType.overview',
    menu: 'admin',
    roles: ['admin', 'viewer'],
    canToggle: true,
  },
  resources: {
    title: 'Resources',
    icon: 'inventory',
    singular: 'resource',
    plural: 'resources',
    sref: 'admin.resource.overview',
    menu: 'admin',
    roles: ['admin', 'viewer'],
    canToggle: true,
  },
  activities: {
    title: 'Activities',
    icon: 'fiber_smart_record',
    sref: 'admin.activity.overview',
    menu: 'admin',
    roles: ['admin'],
    canToggle: true,
  },
  grades: {
    title: 'Grades',
    icon: 'grade',
    singular: 'grade',
    plural: 'grades',
  },
  areas: {
    title: 'Areas',
    icon: 'border_all',
    sref: 'admin.area.overview',
    menu: 'admin',
    roles: ['admin', 'viewer'],
    canToggle: true,
  },
  finance: {
    title: 'Finance',
    icon: 'attach_money',
    sref: 'admin.finance.transactions',
    menu: 'admin',
    roles: ['admin', 'viewer'],
  },
  transactions: {
    title: 'Transactions',
    sref: 'admin.finance.transactions',
    roles: ['admin', 'viewer'],
  },
  payments: {
    title: 'Payments',
    sref: 'admin.finance.payments',
    roles: ['admin', 'viewer'],
  },
  payouts: {
    title: 'Payouts',
    sref: 'admin.finance.payouts',
    roles: ['admin', 'viewer'],
  },
  accountCredit: {
    title: 'Account credit',
    sref: 'admin.finance.accountCredit',
    roles: ['admin', 'viewer'],
  },
  fees: {
    title: 'Fees',
    sref: 'admin.finance.fees',
    roles: ['admin', 'viewer'],
  },
  reports: {
    title: 'Reports',
    icon: 'equalizer',
    sref: 'admin.report.overview',
    menu: 'admin',
    roles: ['admin', 'viewer'],
  },
  logs: {
    title: 'Logs',
    icon: 'playlist_add_check',
    sref: 'admin.log.audit',
    menu: 'admin',
    roles: ['admin', 'viewer'],
  },
  auditLogs: {
    title: 'Audit log',
    sref: 'admin.log.audit',
    roles: ['admin', 'viewer'],
  },
  accessLogs: {
    title: 'Access log',
    sref: 'admin.log.access',
    roles: ['admin', 'viewer'],
  },
  activityLogs: {
    title: 'Activity log',
    sref: 'admin.log.activity',
    roles: ['admin', 'viewer'],
  },
  checkInLogs: {
    title: 'Check in log',
    sref: 'admin.log.checkIn',
    roles: ['admin', 'viewer'],
  },
  visitorLogs: {
    title: 'Visitor log',
    sref: 'admin.log.visitor',
    roles: ['admin', 'viewer'],
  },
  emailLogs: {
    title: 'Email log',
    sref: 'admin.log.email',
    roles: ['admin', 'viewer'],
  },
  system: {
    title: 'System',
    icon: 'dvr',
    sref: 'admin.system',
    menu: 'admin',
    roles: ['admin'],
  },
  integrations: {
    title: 'Integrations',
    icon: 'dashboard_customize',
    sref: 'admin.integrations',
    menu: 'admin',
    roles: ['admin'],
  },
  settings: {
    title: 'Settings',
    icon: 'settings',
    sref: 'admin.settings',
    menu: 'admin',
    roles: ['admin'],
  },
  customFields: {
    title: 'Custom fields',
  },
  feedback: {
    title: 'Feedback',
  },
  invite: {
    title: 'Invite a friend',
  },
});
