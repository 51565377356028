
/**
 * Module definition and dependencies
 */
angular.module('Shared.Avatar.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('Avatar', function($baseModel) {

  /**
   * Constructor
   */
  function Avatar(data) {

    //Call parent method
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(Avatar.prototype, $baseModel.prototype);

  //Return
  return Avatar;
});
