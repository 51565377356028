
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.SignupClub.OAuth', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('signupClub.oauth', {
    url: '/oauth',
    component: 'signupClubOAuthRoute',
  });
})

/**
 * Route component
 */
.component('signupClubOAuthRoute', {
  controller: 'SignupClubOAuthCtrl',
  templateUrl: 'portal/signup-club/steps/oauth.html',
  bindings: {
    club: '<',
    transition: '<',
  },
})

/**
 * Controller
 */
.controller('SignupClubOAuthCtrl', function(
  Auth, OAuthProviders, $state
) {

  this.$onInit = function() {

    //Get params
    this.params = this.transition.params();

    //Available oAuth providers
    this.providers = OAuthProviders;
  };

  /**
   * Login with oAuth provider
   */
  this.login = function(provider) {

    //Set provider and flag
    this.provider = provider;
    this.isLoggingIn = true;

    //Create state
    const club = this.club.identifier;
    const state = {club};

    //Login (using registration flow)
    Auth.loginWithOAuthProvider(provider, 'signup', state);
  };

  /**
   * Continue
   */
  this.continue = function() {

    //Go to next step
    $state.go('signupClub.contact');
  };
});
