
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Overview.Controller', [])

/**
 * Controller
 */
.controller('AdminEventOverviewCtrl', function(
  $controller, $filter, $store, $modal, $storage, $notice, moment, Interface,
  ScrollPosition, Pagination, Event, Settings, Intercom, EventCategory
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminEventCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed events');

    //Flags
    this.canEdit = this.user.hasRole('admin', 'eventManager');
    this.hasMultipleActivities = (this.activities.length > 1);

    //Get overview fields
    const overviewFields = Settings.get('event.overviewFields', []);

    //Determine fields to show
    this.showAttendance = overviewFields.includes('attendance');
    this.showOrganisers = overviewFields.includes('organisers');
    this.showActivity = overviewFields.includes('activity');

    //Filter out custom fields
    this.customOverviewFields = overviewFields
      .filter(field => field.match(/^customFields\./))
      .map(field => field.replace(/^customFields\./, ''));

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load events
    this.loadPage();
    this.loadCategories();
    this.loadCustomFields();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter, activities} = this;
    const includeHidden = $storage.get('events.includeHidden', false);

    //Initialize selection
    this.selection = new Set();

    //Set filter defaults
    filter.setDefaults({
      search: '',
      fromDate: moment().startOf('day'),
      toDate: moment().startOf('day').add(1, 'month'),
      organiser: null,
      isHidden: includeHidden ? null : false,
      includeHidden: includeHidden,
    });

    //Filter on change handler
    filter.onChange((key, value) => {

      //Keep dates in check
      const {toDate, fromDate} = filter;
      const hasDates = toDate && fromDate;

      //From date changed
      if (hasDates && key === 'fromDate' && fromDate.isAfter(toDate)) {
        filter.toDate = fromDate.clone();
      }

      //To date changed
      else if (hasDates && key === 'toDate' && toDate.isBefore(fromDate)) {
        filter.fromDate = toDate.clone();
      }

      //Include hidden
      if (key === 'includeHidden') {
        $storage.set('events.includeHidden', value);
        if (value) {
          filter.isHidden = null;
        }
        else {
          filter.isHidden = false;
        }
      }

      //Activity changed
      if (key === 'activity') {
        filter.area = null;
        filter.options.area = this.areas.filter(area => area.activity === value);
      }

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      activity: activities,
      area: [],
      hasAttendees: [
        {
          label: 'Has attendees',
          value: true,
        },
        {
          label: 'Hasn’t any attendees',
          value: false,
        },
      ],
      isRecurring: [
        {
          label: 'Recurring series',
          value: true,
        },
        {
          label: 'One-time events',
          value: false,
        },
      ],
      isMultiDay: [
        {
          label: 'Multi-day series',
          value: true,
        },
        {
          label: 'Single day events',
          value: false,
        },
      ],
    };
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    this.loadEvents();
  };

  /**
   * Load events
   */
  this.loadEvents = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query events
    return Event
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, events} = data;

    //Set in scope
    this.events = events;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Selection active? Use as filter unless page specified
    if (!page && this.selection.size > 0) {
      return {ids: Array.from(this.selection.values())};
    }

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //Remove includeHidden flag
    delete filter.includeHidden;

    //If one category selected, change prop
    if (Array.isArray(filter.categories) && filter.categories.length === 1) {
      filter.category = filter.categories[0];
      delete filter.categories;
    }

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadEvents()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };

  /**
   * Load custom fields
   */
  this.loadCustomFields = function() {
    return $store.customFields
      .query({model: 'Event'})
      .then(fields => this.customFields = fields);
  };

  /**
   * Get custom field title
   */
  this.getCustomFieldTitle = function(prop) {
    if (!this.customFields) {
      return prop;
    }
    const field = this.customFields.find(field => field.prop === prop);
    if (!field) {
      return prop;
    }
    return field.label;
  };

  /**
   * Load categories
   */
  this.loadCategories = function() {
    return $store.eventCategories
      .query(true)
      .then(categories => {
        this.categories = categories;
        this.showCategoriesFilter = (categories.length <= 5);
      });
  };

  /**
   * Export events
   */
  this.export = function() {

    //Get club
    const {club} = this;

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club, action: `Exporting events`},
      });
    }

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'events', action: 'export'},
      });
    }

    //Get filter and export
    const filter = this.makeFilter();
    return Event.export(filter);
  };

  /**
   * Toggle
   */
  this.toggle = function(id) {
    if (this.selection.has(id)) {
      this.selection.delete(id);
    }
    else {
      this.selection.add(id);
    }
  };

  /**
   * Select all
   */
  this.selectAll = function() {
    for (const event of this.events) {
      const {id} = event;
      if (!this.selection.has(id)) {
        this.selection.add(id);
      }
    }
  };

  /**
   * Unselect all
   */
  this.unselectAll = function() {
    return this.selection.clear();
  };

  /**
   * Remove multiple events
   */
  this.removeMany = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'events', action: 'remove'},
      });
    }

    //Get data
    const numEvents = this.selection.size || this.numItems;

    //Get filter and define handler
    const filter = this.makeFilter();
    const handler = () => Event.removeMany(filter);

    //Show confirmation modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/event/modals/confirm-delete-many.html',
        locals: {numEvents, handler},
      })
      .result
      .then(() => {
        $notice.show(`Events removed`);
        this.selection.clear();
        this.loadEvents();
      });
  };

  /**
   * Add events to categories
   */
  this.addToCategory = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'events', action: 'add to category'},
      });
    }

    //Get filter
    const {categories} = this;
    const type = 'events';
    const filter = this.makeFilter();
    const Model = EventCategory;

    //Define handler
    const handler = (categories) => Event.addToCategories(filter, categories);
    const reloader = () => {
      this.loadCategories();
      this.loadEvents();
    };

    //Open modal
    $modal
      .open('addToCategories', {locals: {
        Model, type, categories, handler, reloader,
      }})
      .result
      .then(() => {
        $notice.show('Added to categories');
        this.loadEvents();
      });
  };

  /**
   * Copy registration link to clipboard
   */
  this.copyLinkToClipboard = function(event) {
    const link = this.club.makeUrl(event.publicPath);
    Interface
      .copyToClipboard(link)
      .then(() => $notice.show(`Event link copied to clipboard`));
  };
});
