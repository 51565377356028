
/**
 * Module definition and dependencies
 */
angular.module('Shared.Invite.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('invite', {
    templateUrl: 'modals/invite.html',
    controller: 'ModalInviteCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalInviteCtrl', function(
  $controller, $modalInstance, $notice, $focus, Interface
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Reset
    this.reset();
  };

  /**
   * Focus
   */
  this.$postLink = function() {
    $focus('inviteName');
  };

  /**
   * Reset
   */
  this.reset = function() {

    //Initialize model
    this.model = {
      name: '',
      email: '',
    };

    //Flags
    this.isInviting = false;
    this.hasInvited = false;
    this.isError = false;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Share link
   */
  this.share = function() {

    //Get data
    const {name, registrationUrl: url} = this.club;

    //Share natively
    if (typeof navigator.share === 'function') {
      return navigator
        .share({
          url,
          title: `You’re invited to join ${name}!`,
          // text: `I thought you might be interested to join me at ${name}`,
        })
        .catch(() => {});
    }

    //Copy link to clipboard
    Interface
      .copyToClipboard(url)
      .then(() => $notice.show(`Link copied to clipboard`));
  };

  /**
   * Invite
   */
  this.invite = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as posting and reset error flag
    this.isInviting = true;
    this.hasInvited = false;
    this.isError = false;

    //Get model
    const {model} = this;

    //Invite now
    this.club
      .invite(model)
      .then(() => this.hasInvited = true)
      .catch(() => this.isError = true)
      .finally(() => this.isInviting = false);
  };
});
