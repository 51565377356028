
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area.Times.Card', [])

/**
 * Component
 */
.component('cardAreaTimes', {
  templateUrl: 'admin/area/cards/times.html',
  controller: 'CardAreaTimesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    area: '<',
    activities: '<',
    isEdit: '<',
    onMerge: '&',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardAreaTimesCtrl', function(
  $modal, $q, TimeRange, CardCloseReasons
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Find activity for this area
    this.activity = this.activities
      .find(activity => activity.isSame(this.area.activity));

    //Set saving flag and prepare model
    this.isSaving = false;
    this.model = this.area.extract(['times']);

    //Create empty array if no value
    if (!this.model.times) {
      this.model.times = [];
    }
  };

  /**
   * Save
   */
  this.save = function() {

    //Create event
    const {area, model, isEdit} = this;
    const $event = {area, model, isEdit};

    //Mark as saving
    this.isSaving = true;

    //Save
    return this
      .onSave({$event})
      .then(() => {

        //Close card only if we were adding
        if (!isEdit) {
          this.card.close(CardCloseReasons.SAVED);
        }
      })
      .finally(() => this.isSaving = false);
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {area, model} = this;
    this.onMerge({$event: {area, model}});
  };

  /**
   * Add time range
   */
  this.add = function() {

    //Create time range and define handler
    const timeRange = new TimeRange();
    const {model, isEdit, activity} = this;
    const maxGap = activity ? activity.duration : 0;

    //Create copy of area times and define handler
    const handler = data => {
      timeRange.fromJSON(data);
      model.times.push(timeRange);

      //Save immediately if editing
      if (isEdit) {
        return this.save();
      }
      return $q.resolve();
    };

    //Show modal
    $modal.open('editAreaTime', {locals: {timeRange, handler, maxGap}});
  };

  /**
   * Edit time range
   */
  this.edit = function($event) {

    //Get time range
    const {timeRange} = $event;
    const {model, isEdit} = this;
    const {duration: maxGap} = this.activity;

    //Find and validate index
    const index = model.times.findIndex(t => t === timeRange);
    if (index === -1) {
      return;
    }

    //Create copy of booking times and define handler
    const handler = data => {
      model.times[index].fromJSON(data);

      //Save immediately if editing
      if (isEdit) {
        return this.save();
      }
      return $q.resolve();
    };

    //Show modal
    $modal.open('editAreaTime', {locals: {
      timeRange, handler, maxGap, isEdit: true,
    }});
  };

  /**
   * Delete time range
   */
  this.delete = function($event) {

    //Get time range
    const {timeRange} = $event;
    const {area, model, isEdit} = this;
    const $ctrl = this;

    //Find and validate index
    const index = model.times.findIndex(t => t === timeRange);
    if (index === -1) {
      return;
    }

    //Immediately remove if not editing
    if (!isEdit) {
      model.times.splice(index, 1);
      return;
    }

    //Create copy of booking times and define handler
    const handler = function() {
      model.times.splice(index, 1);

      //Save immediately if editing
      if (isEdit) {
        return $ctrl.save();
      }
      return $q.resolve();
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'admin/area/modals/confirm-delete-area-time.html',
        locals: {timeRange, area, handler},
      });
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previous();
  };
});
