
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Add', [
  'App.Admin.Membership.Add.Controller',
  'App.Admin.Membership.Add.Type.Card',
  'App.Admin.Membership.Add.Details.Card',
  'App.Admin.Membership.Add.Conditions.Card',
  'App.Admin.Membership.Add.Term.Card',
  'App.Admin.Membership.Add.Renewal.Card',
  'App.Admin.Membership.Add.Anchor.Card',
  'App.Admin.Membership.Add.System.Card',
  'App.Admin.Membership.Add.AccountCredit.Card',
  'App.Admin.Membership.Add.Coupons.Card',
  'App.Admin.Membership.Add.Events.Card',
  'App.Admin.Membership.Add.Registration.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.membership.add', {
    url: '/add/:membershipId',
    component: 'adminMembershipAddRoute',
    params: {
      membershipId: null,
      squash: true,
    },
    data: {
      roles: ['admin'],
    },
    resolve: {
      memberships($store) {
        //NOTE: Need full query, to copy props for linked memberships
        return $store.memberships.query();
      },
      membership(transition, Membership) {
        const params = transition.params();
        if (!params.membershipId) {
          return new Membership({
            canSelectWhenRegistering: true,
            canSelectForPurchase: true,
            canSelectWhenChanging: true,
            isRenewable: true,
            allowPayLater: true,
            sendReminder: true,
            isDefault: false,
            isUnlimited: false,
            term: {
              amount: 1,
              unit: 'years',
            },
          });
        }
        return Membership
          .findById(params.membershipId)
          .then(membership => membership.clone(true, {
            isDefault: false,
          }));
      },
      couponTypes(CouponType) {
        return CouponType
          .query({
            isArchived: false,
            fields: 'name',
          })
          .then(data => data.couponTypes);
      },
    },
  });
})

/**
 * Route component
 */
.component('adminMembershipAddRoute', {
  controller: 'AdminMembershipAddCtrl',
  templateUrl: 'admin/membership/add/add.html',
  bindings: {
    club: '<',
    system: '<',
    activities: '<',
    membership: '<',
    memberships: '<',
    module: '<',
    couponTypes: '<',
    coupons: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {
  $transitions.onSuccess({
    to: 'admin.membership.add',
  }, () => {
    Page.setTitle('Create membership');
    Page.addCrumb({sref: 'admin.membership.add'});
  });
});
