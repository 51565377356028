
/**
 * Module definition and dependencies
 */
angular.module('Shared.Domain.Service', [])

/**
 * Service definition
 */
.factory('Domain', function($window, Config) {
  class Domain {

    /**
     * Constructor
     */
    constructor() {

      //No domains config
      if (!Config.domains) {
        return;
      }

      //Get location details
      const {hostname} = $window.location;
      const {generic} = Config.domains;

      //Check if generic
      this.isGeneric = (hostname === generic);
    }
  }

  //Return instance
  return new Domain();
});
