
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.RegistrationText.Card', [])

/**
 * Component
 */
.component('cardClubRegistrationText', {
  templateUrl: 'admin/club/cards/registration-text.html',
  controller: 'CardClubRegistrationTextCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    texts: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubRegistrationTextCtrl', function(
  CardCloseReasons, TextTypes
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Find relevant texts
    this.registrationIntro = this.texts
      .find(text => text.type === TextTypes.REGISTRATION_INTRO);
    this.accountReadyEmail = this.texts
      .find(text => text.type === TextTypes.ACCOUNT_READY_EMAIL);
    this.inviteEmail = this.texts
      .find(text => text.type === TextTypes.INVITE_EMAIL);

    //Reset their models
    if (this.registrationIntro) {
      this.registrationIntro.resetModel();
    }
    if (this.accountReadyEmail) {
      this.accountReadyEmail.resetModel();
    }
    if (this.inviteEmail) {
      this.inviteEmail.resetModel();
    }

    //Default text
    if (this.registrationIntro && !this.registrationIntro.hasText()) {
      this.registrationIntro.model = `Thank you for your interest in signing up! These steps will guide you through the sign up process.`;
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get texts
    const {texts} = this;

    //Save
    this
      .onSave({$event: {texts}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
