
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.Circles.Card', [])

/**
 * Component
 */
.component('cardMemberCircles', {
  templateUrl: 'admin/people/members/cards/circles.html',
  controller: 'CardMemberCirclesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    member: '<',
    onSave: '&',
    isOwn: '<',
    asAdmin: '<',
  },
})

/**
 * Controller
 */
.controller('CardMemberCirclesCtrl', function(
  $location, CardCloseReasons, Circle, Colors, $modal, $notice
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isLoading = true;
    this.isSaving = false;
    this.Colors = Colors;
    this.isChoosingColor = {};

    //Load the users circles
    this.loadCircles();

    //Load invited circles
    this
      .loadInvitedCircles()
      .then(() => this.checkIfInvited());
  };

  /**
   * Choose color
   */
  this.toggleChooseColor = function(circle) {
    const {id} = circle;
    this.isChoosingColor[id] = !this.isChoosingColor[id];
  };

  /**
   * Set color
   */
  this.setColor = function(circle, color) {

    //Get circle ID
    const {id} = circle;
    this.isChoosingColor[id] = false;

    //Check if changed
    if (!color || circle.color === color) {
      return;
    }

    //Save
    circle.color = color;
    circle.save({color});
  };

  /**
   * Query circles
   */
  this.loadCircles = function() {

    //Prepare filter data
    const memberId = this.member.id;
    const {isOwn} = this;
    const promise = isOwn ? Circle.own() : Circle.query({memberId});

    //Load
    return promise
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {member} = this;
    const {meta, circles} = data;

    //Set in scope
    this.circles = circles;
    this.numCircles = meta.total;

    //Sort the circle members
    this.circles.map(circle => circle.members
      .sort((a, b) => a.id === member.id ? -1 : b.value === member.id ? 1 : 0)
      .sort((a, b) => a.isLead === true ? -1 : b.isLead === true ? 1 : 0));
  };

  /**
   * Load invoted circles
   */
  this.loadInvitedCircles = function() {

    //For yourself
    if (this.isOwn) {
      return Circle
        .own({isInvitee: true})
        .then(data => this.invitedCircles = data.circles);
    }

    //For other member
    return Circle
      .query({invitedMemberId: this.member.id})
      .then(data => this.invitedCircles = data.circles);
  };

  /**
   * Check if url params match
   */
  this.checkIfInvited = function() {

    //Must have invited circles and a member
    if (!this.invitedCircles || !this.member) {
      return;
    }

    //Get data from url
    const {action, id} = $location.search();

    //If url had data
    if (action && id) {

      //Get circle from url
      const circle = this.invitedCircles
        .find(circle => circle.id === id);

      //Invalid circle
      if (!circle) {
        return;
      }

      //Check if member is invited to that circle
      const isInvited = circle.invited
        .some(member => member && member.id === this.member.id);

      //Accept or decline the invite
      if (action === 'accept' && isInvited && circle) {
        this.accept(circle);
      }
      if (action === 'decline' && isInvited && circle) {
        this.decline(circle);
      }
    }
  };

  /**
   * Create a new circle
   */
  this.createCircle = function() {

    //Create circle and define handler
    const {member, asAdmin} = this;
    const circle = new Circle();
    const handler = data => {
      if (asAdmin) {
        return circle.save(data);
      }
      return circle.createOwn(data);
    };

    //Show modal
    $modal
      .open('editCircle', {
        locals: {circle, member, asAdmin, handler},
      })
      .result
      .then(() => {
        $notice.show('Circle created');
        this.loadCircles();
      });
  };

  /**
   * Delete circle
   */
  this.delete = function($event) {

    //Get data and define handler
    const {asAdmin} = this;
    const {circle} = $event;
    const handler = () => circle.delete();

    //Open confirmation dialog
    $modal
    .open('basic', {
      templateUrl: 'admin/people/circles/modals/confirm-delete-circle.html',
      locals: {circle, asAdmin, handler},
    })
    .result
    .then(() => {
      $notice.show(`Circle removed`);
      this.loadCircles();
    });
  };

  /**
   * Edit circle
   */
  this.editCircle = function($event) {

    //Get data and define handler
    const {circle} = $event;
    const {member, asAdmin} = this;
    const isEdit = true;
    const handler = data => circle.save(data);

    //Show modal
    $modal
      .open('editCircle', {
        locals: {circle, member, asAdmin, isEdit, handler},
      })
      .result
      .then(() => {
        $notice.show('Circle updated');
        this.loadCircles();
      });
  };

  /**
   * Invite to circle
   */
  this.invite = function($event) {

    //Get data and define handler
    const {circle} = $event;
    const {member, asAdmin} = this;

    //Show modal
    $modal
      .open('inviteCircleMembers', {
        locals: {circle, member, asAdmin},
      })
      .result
      .then(() => {
        this.loadCircles();
      });
  };

  /**
   * Manage own permissions
   */
  this.managePermissions = function($event) {

    //Get data and define handler
    const {circle} = $event;
    const circleMember = circle.members
      .find(member => member.id === this.member.id);

    //Show modal
    $modal
      .open('managePermissions', {
        locals: {circle, circleMember},
      })
      .result
      .then(() => {
        this.loadCircles();
      });
  };

  /**
   * Can view member check
   */
  this.canViewMember = function(member, circle) {
    return (
      !member.isArchived &&
      (this.asAdmin || circle.isLead || member.id !== this.member.id)
    );
  };

  /**
   * View member
   */
  this.viewMember = function(member, circle, isInvited) {

    //Can't view
    if (!this.canViewMember(member, circle)) {
      return;
    }

    //Own permissions
    if (!this.asAdmin && member.id === this.member.id) {
      return this.managePermissions({circle});
    }

    //Get data
    const {asAdmin, member: user} = this;

    //Show modal
    $modal
      .open('viewCircleMember', {
        locals: {circle, member, user, asAdmin, isInvited},
      })
      .result
      .then(() => {
        this.loadCircles();
        this.loadInvitedCircles();
      });
  };

  /**
   * Accept circle invitation
   */
  this.accept = function(circle) {

    //Define handler
    const handler = function() {
      return circle.acceptInvite();
    };

    //Open confirmation dialog
    $modal
      .open('basic', {
        templateUrl: 'admin/people/circles/modals/confirm-accept-invite.html',
        locals: {circle, handler},
      })
      .result
      .then(() => {
        $notice.show('Invitation accepted');
        this.loadCircles();
        this.loadInvitedCircles();
      });
  };

  /**
   * Decline circle invitation
   */
  this.decline = function(circle) {

    //Define handler
    const handler = function() {
      return circle.declineInvite();
    };

    //Open confirmation dialog
    $modal
      .open('basic', {
        templateUrl: 'admin/people/circles/modals/confirm-decline-invite.html',
        locals: {circle, handler},
      })
      .result
      .then(() => {
        $notice.show('Invitation declined');
        this.loadCircles();
        this.loadInvitedCircles();
      });
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model.directory;
    target[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving and get data
    this.isSaving = true;
    const {member, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {member, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
