
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.Member.Card', [])

/**
 * Component
 */
.component('cardSettingsMember', {
  templateUrl: 'admin/settings/cards/member.html',
  controller: 'CardSettingsMemberCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSettingsMemberCtrl', function(
  $controller, $store, Genders, Counter, ValidationError
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'member';
    this.Genders = Genders;

    //Sort fields
    this.nameSortOptions = [
      {
        label: 'First name',
        value: 'fullName',
      },
      {
        label: 'Last name',
        value: 'lastName,firstName',
      },
    ];

    //Badge format options
    this.badgeFormatOptions = [
      {
        label: 'Default',
        value: 'default',
      },
      {
        label: 'Basic',
        value: 'basic',
      },
    ];

    //Info fields
    this.infoFields = [
      {
        label: 'Number',
        value: 'number',
      },
      {
        label: 'Date of birth',
        value: 'dob',
      },
      {
        label: 'Age',
        value: 'age',
      },
      {
        label: 'Member since',
        value: 'signUpDate',
      },
    ];

    //Call parent init
    $base.$onInit.call(this);

    //Get member number counter
    this.getMemberNumberCounter();
    this.loadCustomFields();
  };

  /**
   * Load custom fields
   */
  this.loadCustomFields = function() {

    //Set flag
    this.isLoadingCustomFields = true;

    //Load
    $store.customFields
      .query({model: 'Member'})
      .then(customFields => {
        for (const field of customFields) {
          if (!field.isParagraph && !field.isFile) {
            this.infoFields.push({
              label: field.label,
              value: `customFields.${field.prop}`,
            });
          }
        }
      })
      .finally(() => this.isLoadingCustomFields = false);
  };

  /**
   * Get member number counter
   */
  this.getMemberNumberCounter = function() {

    //Set flag
    this.isGettingCounter = true;

    //Get counter
    Counter
      .findByType('memberNumber')
      .then(counter => this.memberNumber = counter)
      .finally(() => this.isGettingCounter = false);
  };

  /**
   * Update member number counter
   */
  this.updateMemberNumberCounter = function() {

    //Already updating
    if (this.isUpdatingCounter) {
      return;
    }

    //Toggle flag
    this.isUpdatingCounter = true;
    this.form.memberNumberSequence.$setValidity('invalid', true);

    //Save
    this.memberNumber
      .save()
      .catch(error => {

        //Validation error
        if (error instanceof ValidationError) {
          return error.setFieldsValidity(this.form);
        }

        //Rethrow error
        throw error;
      })
      .finally(() => this.isUpdatingCounter = false);
  };

  /**
   * Save
   */
  this.save = async function() {

    //Updating counter
    if (this.isUpdatingCounter) {
      return;
    }

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Clear vaccination settings
    if (!this.model.trackVaccinationStatus) {
      this.model.showVaccinationStatus = false;
      this.model.autoExpireVaccination = false;
      this.model.autoExemptVaccination = false;
    }

    //Remove vaccination age if setting not enabled
    if (!this.model.autoExemptVaccination) {
      this.model.vaccinationExemptionAge = null;
    }

    //Call parent method
    $base.save.call(this);
  };
});
