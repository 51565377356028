
/**
 * Module definition and dependencies
 */
angular.module('Shared.PaymentTypes.Constant', [])

/**
 * Constant definition
 */
.constant('PaymentTypes', {
  EVENT: 'event',
  CREDIT: 'credit',
  COUPON: 'coupon',
  BOOKING: 'booking',
  REGISTRATION: 'registration',
  TRANSACTIONS: 'transactions',
  ACCOUNT_CREDIT: 'accountCredit',
  SUBSCRIPTION_PURCHASE: 'subscriptionPurchase',
  SUBSCRIPTION_RENEWAL: 'subscriptionRenewal',
  SUBSCRIPTION_CHANGE: 'subscriptionChange',
});
