
/**
 * Module definition and dependencies
 */
angular.module('App.Home.Welcome.Card', [])

/**
 * Component
 */
.component('cardHomeWelcome', {
  templateUrl: 'home/cards/welcome.html',
  controller: 'CardHomeWelcomeCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    pages: '<',
  },
})

/**
 * Controller
 */
.controller('CardHomeWelcomeCtrl', function($modal, Text, TextTypes) {

  /**
   * Init
   */
  this.$onInit = function() {

    //Set buttons
    this.buttons = [
      this.club.settings.homepage.actionButton1,
      this.club.settings.homepage.actionButton2,
      this.club.settings.homepage.actionButton3,
      this.club.settings.homepage.actionButton4,
    ];

    //Set defaults
    this.defaults = [
      {option: 'calendar', color: '#19a083'},
      {option: 'account', color: '#009fd2'},
      {option: 'profile', color: '#efaa07'},
      {option: 'feedback', color: '#c30d45'},
    ];

    //Load welcome message
    this.loadWelcomeMessage();
  };

  /**
   * Load welcome message
   */
  this.loadWelcomeMessage = function() {
    Text
      .getText(TextTypes.WELCOME_MESSAGE)
      .then(text => this.welcomeMessage = text);
  };

  /**
   * On action
   */
  this.onAction = function(action) {
    if (action === 'feedback') {
      this.feedback();
    }
    if (action === 'invite') {
      this.invite();
    }
  };

  /**
   * Place feedback
   */
  this.feedback = function() {

    //Get data
    const {user, club} = this;

    //Open feedback modal and handle snooze reminder if resolved
    $modal
      .open('feedback', {locals: {user, club}})
      .result
      .then(snooze => user.snoozeReminder('askForFeedback', snooze));
  };

  /**
   * Invite a friend
   */
  this.invite = function() {

    //Get data
    const {user, club} = this;

    //Open feedback modal and handle snooze reminder if resolved
    $modal
      .open('invite', {locals: {user, club}});
  };
});
