
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.Stop.Controller', [])

/**
 * Controller
 */
.controller('SubscriptionStopCtrl', function($state, $modal) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Check route is valid
    if (this.checkRouteValid()) {
      return;
    }

    //Set membership for easy access
    this.membership = this.current.membership;
    this.reason = '';
  };

  /**
   * Stop handler
   */
  this.stop = function() {

    //Mark as stopping
    this.isStopping = true;

    //Stop subscription
    return this.current
      .stopOwn(this.reason)
      .then(() => this.onStopped())
      .finally(() => this.isStopping = false);
  };

  /**
   * Change membership instead
   */
  this.change = function() {
    const subscription = this.current;
    return $state.go('subscription.change', {subId: subscription.id});
  };

  /**
   * Reconsider
   */
  this.reconsider = function() {
    $state.go('subscription.overview');
  };

  /**
   * On stopped handler
   */
  this.onStopped = function() {

    //Get data
    const {current: subscription} = this;

    //Show modal
    $modal
      .open('basic', {
        templateUrl: 'subscription/modals/stopped.html',
        locals: {subscription},
      })
      .closed
      .then(() => $state.go('subscription.overview', {}, {reload: true}));
  };

  /**
   * Check route is valid
   */
  this.checkRouteValid = function() {

    //Get data
    const {current} = this;
    const {subId} = this.transition.params();

    //Ensure valid link clicked
    if (subId && (!current || current.id !== subId)) {
      return $state
        .go('subscription.overview')
        .then(() => {
          $modal.open('basic', {templateUrl: 'modals/invalid-link.html'});
        });
    }

    //Must have current sub to stop
    if (!current) {
      return $state.go('subscription.overview');
    }
  };
});
