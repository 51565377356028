
/**
 * Module definition and dependencies
 */
angular.module('Shared.Comment.Model', [
  'BaseModel.Service',
  'Shared.CommentStore.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('comment', {
    model: 'Comment',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'comments',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('comments', {
    model: 'Comment',
    dataKey: 'comments',
    service: 'CommentStore',
  });
})

/**
 * Model definition
 */
.factory('Comment', function($api, $baseModel) {

  /**
   * Constructor
   */
  function Comment(data) {
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(Comment.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Comment.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Models
    this.convertToModel('member', 'Member');

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Comment.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Remove member property
    delete json.member;

    //Strip data
    json.replyTo = $baseModel.onlyId(json.replyTo);

    //Return json
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save
   */
  Comment.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.comment[method](data).then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Comment.prototype.delete = function() {
    return $api.comment.delete(null, this).then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Comment.query = function(filter) {
    return $api.comment.query(filter);
  };

  /**
   * Find by ID
   */
  Comment.findById = function(id) {
    return $api.comment.get({id});
  };

  //Return
  return Comment;
});
