
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.CreateTransaction.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('createTransaction', {
    templateUrl: 'admin/finance/modals/create-transaction.html',
    controller: 'ModalCreateTransactionCtrl',
    closeOnClick: false,
    resolve: {
      club($store) {
        'ngInject';
        return $store.club.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalCreateTransactionCtrl', function(
  $controller, $modalInstance, $filter, $notice, $store, $q, moment, Member,
  Modules, Settings, Helpers, DebitCredit, TransactionTypes, PaymentMethods,
  MemberSelectionMethods
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});
  const ACCOUNT_CREDIT = 'accountCredit';

  //Get constants
  const {ALL, SPECIFIC, GROUPS, SELECTION} = MemberSelectionMethods;

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get data
    const {changeOption, removeOption} = Helpers;
    const {plural} = Modules.find('members');
    const {amount, unit} = Settings.get('payment.dueThreshold');

    //Initialize
    this.isSaving = false;
    this.DebitCredit = DebitCredit;
    this.TransactionTypes = TransactionTypes;
    this.memberSelectionMethods = changeOption(
      MemberSelectionMethods, ALL, `All active ${plural}`
    );

    //Create model subset
    this.model = this.transaction.extract([
      'dueDate', 'type', 'details', 'amount',
      'isCredit', 'autoCollect', 'tax',
    ]);

    //Append creation meta data
    this.model.meta = {
      notifyByEmail: true,
      payment: {
        method: null,
        date: moment().startOf('day'),
        reference: '',
        avoidNegativeAccountCredit: false,
      },
    };

    //Set additional meta data
    if (this.isForMultipleMembers) {
      this.model.meta.whoFor = SPECIFIC;
      this.model.meta.groups = [];
      this.model.meta.members = [];
    }

    //Set default due date
    this.model.dueDate = moment().startOf('day').add(amount, unit);

    //Tax
    if (!this.model.tax) {
      this.model.tax = {
        percentage: this.club.tax.percentage,
      };
    }

    //Filter payment methods
    this.filterPaymentMethodsAndTypes();

    //Set initial step
    if (this.isForMultipleMembers) {
      this.setStep('members');
    }
    else {
      this.setStep('details');
    }

    //Load groups and count members
    $q
      .all([
        this.loadGroups(),
        this.countMembers(),
      ])
      .then(() => {
        if (this.groups.length === 0) {
          this.memberSelectionMethods
            = removeOption(MemberSelectionMethods, GROUPS);
        }
        if (this.memberCount > 300) {
          this.memberSelectionMethods
            = removeOption(MemberSelectionMethods, SELECTION);
        }
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Set selection method
   */
  this.setSelectionMethod = function(method) {
    this.model.meta.whoFor = method;
    if (method === SELECTION) {
      this.loadMembers();
    }
  };

  /**
   * Set payment type
   */
  this.setPaymentType = function(type) {

    //Set the type
    this.paymentType = type;

    //Update payment method
    if (type === ACCOUNT_CREDIT) {
      this.model.meta.payment.method = ACCOUNT_CREDIT;
    }
    else {
      this.model.meta.payment.method = null;
    }

    //Clear auto collect flag
    if (type) {
      this.model.autoCollect = false;
    }

    //Check account credit change
    this.checkAccountCreditChange();
  };

  /**
   * Toggle details multiline
   */
  this.toggleMultiLine = function() {
    this.isMultiLine = !this.isMultiLine;
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target = this.model) {

    //Set value
    target[property] = value;

    //Changed credit? Clear type and method to avoid confusion
    if (property === 'isCredit') {
      this.model.type = null;
      this.model.meta.payment.method = null;
      if (value) {
        this.model.autoCollect = false;
      }
    }

    //If type is account credit
    if (this.model.type === ACCOUNT_CREDIT) {

      //Don't allow method to also be account credit
      if (this.paymentType === ACCOUNT_CREDIT) {
        this.paymentType = 'other';
        this.model.meta.payment.method = null;
      }
    }

    //Clear value of checkboxes if needed
    if (this.model.isCredit || !this.model.meta.payment.method) {
      this.model.meta.payment.avoidNegativeAccountCredit = false;
    }

    //Filter payment methods and handle account credit
    this.filterPaymentMethodsAndTypes();
    this.checkAccountCreditChange();
  };

  /**
   * Check account credit change
   */
  this.checkAccountCreditChange = function() {

    //Get model data
    const {isCredit, type, meta: {payment: {method}}} = this.model;

    //Check if we are adding account credit
    this.isAddingAccountCredit =
      (isCredit && method === ACCOUNT_CREDIT) ||
      (!isCredit && type === ACCOUNT_CREDIT && method);

    //Check if we are deducting account credit
    this.isDeductingAccountCredit =
      (!isCredit && method === ACCOUNT_CREDIT) ||
      (isCredit && type === ACCOUNT_CREDIT && method);
  };

  /**
   * Filter payment methods
   */
  this.filterPaymentMethodsAndTypes = function() {

    //Get type
    const {isCredit, type} = this.model;

    //Filter payment methods
    this.paymentMethods = PaymentMethods
      .filter(method => !method.isOnline)
      .filter(method => {
        if (method.isAccountCredit) {
          return (isCredit && type !== ACCOUNT_CREDIT);
        }
        return true;
      });

    //Define payment types
    this.paymentTypes = [
      {
        label: 'Already paid',
        value: 'other',
      },
    ];

    //Deduct from account credit only possible if type is not account credit
    if (type !== ACCOUNT_CREDIT) {
      this.paymentTypes.push({
        label: 'Deduct from account credit',
        value: ACCOUNT_CREDIT,
      });
    }
  };

  /**
   * Load members
   */
  this.loadMembers = function() {

    //Initialize members
    this.members = [];
    this.isLoadingMembers = true;

    //Query
    Member
      .query({
        isArchived: false,
        fields: 'firstName,lastName',
      })
      .then(data => $filter('distribute')(data.members, 3, 6))
      .then(members => this.members = members)
      .finally(() => this.isLoadingMembers = false);
  };

  /**
   * Count members
   */
  this.countMembers = function() {
    return Member
      .count({isArchived: false, isPending: false})
      .then(members => this.memberCount = members);
  };

  /**
   * Load groups
   */
  this.loadGroups = function() {
    return $store.memberGroups
      .query()
      .then(group => this.groups = group);
  };

  /**
   * Next from members
   */
  this.nextFromMembers = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Flag as pristine again
    this.form.$setPristine();

    //Go to details
    this.setStep('details');
  };

  /**
   * Update payment meta
   */
  this.updatePaymentMeta = function(property, value) {
    this.model.meta.payment[property] = value;
    this.checkAccountCreditChange();
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;

    //Get model copy to avoid breaking things when we delete properties
    const model = angular.copy(this.model);
    const {meta} = model;

    //Clear members
    if (meta.whoFor === ALL || meta.whoFor === GROUPS) {
      delete meta.members;
    }

    //Clear groups
    if (meta.whoFor !== GROUPS) {
      delete meta.groups;
    }

    //Not paid
    if (meta.payment && !meta.payment.method) {
      delete meta.payment;
    }

    //Use save handler
    this
      .handler(model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => $notice.showError('Failed to save transaction'))
      .finally(() => this.isSaving = false);
  };
});
