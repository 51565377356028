
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.WaitingList.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('waitingList', {
    templateUrl: 'booking/modals/waiting-list.html',
    controller: 'ModalWaitingListCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalWaitingListCtrl', function(
  $controller, $modalInstance, $notice, WaitingList,
  Push
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Mark as loading
    this.isLoading = true;
    this.isAdmin = this.user.isAdmin();

    //Load waiting list
    this.loadList();

    //Check if push notification is enabled
    this.setInitialPushNotification();
  };

  /**
   * Load waiting list
   */
  this.loadList = function() {

    //Get data
    const {activity: {id: activity}, date} = this;
    const fromDate = date;
    const toDate = date;

    //Query list
    WaitingList
      .query({activity, fromDate, toDate})
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Process data
   */
  this.processData = function(data) {
    this.list = data.waitingLists;
    this.own = this.list.find(item => item.member.isSame(this.user));
    this.isOnList = !!this.own;
  };

  /**
   * Set initial push notification
   */
  this.setInitialPushNotification = function() {
    const {user} = this;
    this.bookingWaitingListNotification
      = Push.isEnabledForUser(user, 'bookingWaitingList')
      && Push.isEnabledOnDevice('bookingWaitingList');
  };

  /**
   * Toggle waiting list push notification
   */
  this.togglePushNotification = function(isEnabled) {
    const {user} = this;
    this.bookingWaitingListNotification = isEnabled;
    Push.toggleNotification(user, 'bookingWaitingList', isEnabled);
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Get data and create waiting list item
    const {user: member, activity, date, time} = this;

    //Flag as saving
    this.isSaving = true;

    //Add to waiting list
    WaitingList
      .add({member, activity, date, time})
      .then(() => {
        this.close();
        $notice.show('Added to waiting list');
      })
      .finally(() => this.isSaving = false);
  };

  /**
   * Remove
   */
  this.remove = function() {

    //Flag as removing
    this.isRemoving = true;

    //Remove yourself from waiting list
    this.own
      .delete()
      .then(() => {
        this.close();
        $notice.show('Removed from waiting list');
      })
      .finally(() => this.isRemoving = false);
  };
});
