
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Edit.Coupons.Card', [])

/**
 * Component
 */
.component('cardMembershipEditCoupons', {
  templateUrl: 'admin/membership/cards/coupons.html',
  controller: 'CardMembershipEditCouponsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    membership: '<',
    couponTypes: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipEditCouponsCtrl', function(
  CardCloseReasons, Modules
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;
    this.couponIsSelected = false;
    this.expiryOptions = [
      {
        label: `As per the ${Modules.singular('couponTypes')}`,
        value: 'defined',
      },
      {
        label: 'On subscription end date',
        value: 'subscription',
      },
    ];

    //Create model subset
    this.model = this.membership.extract([
      'couponTypes',
    ]);

    //Set hasCoupons checkbox
    const {couponTypes} = this.membership;
    if (!couponTypes || couponTypes.length === 0) {
      this.model.couponTypes = [];
      this.hasCoupons = false;
    }
    else {
      this.hasCoupons = true;
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Add blank coupon to model
   */
  this.addBlankCoupon = function() {
    this.model.couponTypes.push({
      id: '',
      name: '',
      amount: 1,
      expiryType: this.expiryOptions[0].value,
    });
  };

  /**
   * Toggle show coupons
   */
  this.toggleHasCoupons = function(value) {
    this.hasCoupons = value;
    this.model.couponTypes = [];
    if (value === true && this.couponTypes.length > 0) {
      this.addBlankCoupon();
    }
  };

  /**
   * Toggle coupons
   */
  this.toggleCoupon = function(index, type) {

    //Create coupon
    const coupon = {
      id: type.id,
      name: type.name,
      amount: 1,
      expiryType: this.expiryOptions[0].value,
    };

    if (!this.model.couponTypes[0].id) {
      this.model.couponTypes[0] = coupon;
    }
    else {
      this.model.couponTypes[index] = coupon;
    }
    this.form.$setDirty();
  };

  /**
   * Add expiry to coupon
   */
  this.toggleCouponExpiry = function(coupon, value) {
    coupon.expiryType = value;
  };

  /**
   * Remove a coupon
   */
  this.removeCoupon = function(index) {
    this.model.couponTypes.splice(index, 1);
    this.form.$setDirty();
  };

  /**
   * Remove empty coupons if there are any
   */
  this.removeBlanks = function() {
    this.model.couponTypes = this.model.couponTypes
      .filter(coupon => coupon.id !== '');
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return false;
    }
    return true;
  };

  /**
   * Save
   */
  this.save = function() {

    //Remove any blank rows
    this.removeBlanks();

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {membership, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {membership, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => {
        this.isSaving = false;
      });
  };
});
