
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.PaymentOptions.Component', [])

/**
 * Payment options component
 */
.component('memberPaymentOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li>
        <a ng-click="$ctrl.view()">View details</a>
      </li>
      <li ng-if="$ctrl.payment.isEditable">
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li ng-if="$ctrl.payment.isPaid">
        <a ng-click="$ctrl.receipt()">Receipt</a>
      </li>
      <li ng-if="!$ctrl.payment.isOnline || ($ctrl.payment.isOnline && $ctrl.payment.isByAdmin)">
        <a ui-sref="admin.log.audit({model: 'Payment', id: $ctrl.payment.id})">View audit log</a>
      </li>
      <li ng-if="$ctrl.payment.isRemovable" class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    payment: '<',
    onView: '&',
    onEdit: '&',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Edit
     */
    this.view = function() {
      const {payment} = this;
      this.onView({$event: {payment}});
    };

    /**
     * Edit
     */
    this.edit = function() {
      const {payment} = this;
      this.onEdit({$event: {payment}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      const {payment} = this;
      this.onDelete({$event: {payment}});
    };

    /**
     * Download receipt
     */
    this.receipt = function() {
      this.isBusy = true;
      this.payment
        .downloadReceipt()
        .finally(() => this.isBusy = false);
    };
  },
});
