
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Flow.Edit.Component', [])

/**
 * Grid component
 */
.component('flowBookingEdit', {
  templateUrl: 'booking/flow/edit.html',
  bindings: {
    user: '<',
    booking: '<',
    activity: '<',
    canManageBookings: '<',
    isInvolved: '<',
    onView: '&',
    onClose: '&',
    onChanged: '&',
    onValidate: '&',
  },

  /**
   * Controller
   */
  controller($notice, $store, $filter, moment) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Initialize flags
      this.isSaving = false;
      this.today = moment();
      this.step = 'details';

      //Find mode
      this.mode = this.activity.modes
      .find(m => m.id === this.booking.mode.id);
      this.onlyOne = (this.mode.maxPeople === 1);
      this.oneOrMore = (this.mode.minPeople === 1 && this.mode.maxPeople > 1);
      this.onlyMore = (this.mode.minPeople > 1);

      //Toggle multi line if there is more than one visitor with a name
      if (this.booking.visitors.filter(visitor => !!visitor.name).length > 1) {
        this.isMultiLine = true;
      }

      //Load areas
      this.loadAreas();

      //Create model
      this.model = this.booking.clone();
    };

    /**
     * Load areas
     */
    this.loadAreas = function() {
      $store.areas
        .query()
        .then(areas => this.areas = areas
        .filter(area => area.activity === this.activity.id))
        .then(() => {
          this.determineStartTimes();
          this.determineEndTimes();
        });
    };

    /**
    * Toggle details multiline
    */
    this.toggleMultiLine = function() {
      this.isMultiLine = !this.isMultiLine;
    };

    /**
     * Determine start times
     */
    this.determineStartTimes = function() {

      //Clear flag and initialize times
      this.noBookingTimesForDate = false;
      this.startTimes = [];

      //Get area
      const area = this.areas.find(area => area.isSame(this.model.area));
      const {startDate} = this.model;
      const {duration} = this.activity;

      //Get time range
      const timeRange = area.times.find(time => time.containsDate(startDate));
      if (!timeRange) {
        this.noBookingTimesForDate = true;
        return;
      }

      //Get start/end times
      const {startTime, endTime} = timeRange;

      //Create times
      for (let time = startTime; time <= endTime; time += duration) {
        this.startTimes.push({
          time,
          label: $filter('time')(time),
        });
      }
    };

    /**
     * Determine end times
     */
    this.determineEndTimes = function() {

      //Get first valid end time
      const {startTime} = this.model;
      const {duration} = this.activity;
      const validEndTime = startTime + duration;

      //Create end times
      this.endTimes = this.startTimes
        .filter(time => time.time >= validEndTime);
    };

    /**
     * Change area
     */
    this.changeArea = function(area) {
      this.model.area = area;
      this.determineStartTimes();
      this.determineEndTimes();
    };

    /**
     * Change date
     */
    this.changeDate = function(date) {
      this.model.startDate = date;
      this.model.endDate = date.clone().setTime(this.model.endTime);
      this.determineStartTimes();
      this.determineEndTimes();
    };

    /**
     * Change start time
     */
    this.changeStartTime = function(startTime) {
      const {duration} = this.booking;
      this.model.startTime = startTime;
      this.model.endTime = startTime + duration;
      this.determineEndTimes();
    };

    /**
     * Change end time
     */
    this.changeEndTime = function(endTime) {
      const {startTime} = this.model;
      const {duration} = this.activity;
      if (endTime < (startTime + duration)) {
        return;
      }
      this.model.endTime = endTime;
    };

    /**
     * Set members
     */
    this.setMembers = function(members) {
      this.model.setMembers(...members);
      this.onMembersChanged();
    };

    /**
     * Members changed
     */
    this.onMembersChanged = function() {

      //Get data
      const {model} = this;

      //Clear errors
      this.error = null;
      this.errorNoMembers = false;
      this.errorMaxPeople = false;
      this.errorMaxVisitors = false;
      this.preValidationError = null;

      //Check if there are enough members now
      if (model.hasEnoughPeople()) {
        this.errorMinPeople = false;
      }
      if (model.hasEnoughVisitors()) {
        this.errorMinVisitors = false;
      }

      //Set form as dirty
      this.form.$setDirty();
    };

    /**
     * Add visitor
     */
    this.addVisitor = function() {

      //Check if can add
      if (this.model.hasMaxPeople()) {
        this.errorMaxPeople = true;
        return;
      }

      //Check if can add
      if (this.model.hasMaxVisitors()) {
        this.errorMaxVisitors = true;
        return;
      }

      //Add visitor
      this.model.addVisitor();
      this.onMembersChanged();
    };

    /**
     * Remove visitor
     */
    this.removeVisitor = function($event) {

      //Get index
      const {index} = $event;

      //Check if can remove
      if (this.model.numVisitors === this.model.mode.minVisitors) {
        this.errorMinVisitors = true;
        return;
      }

      //Remove visitor
      this.model.removeVisitor(index);
      this.onMembersChanged();
    };

    /**
     * Validate visitor names
     */
    this.validateVisitorNames = function() {

      //Flag form as submitted
      this.form.$setSubmitted();

      //Clear flags
      this.isErrorMissingVisitorNames = false;
      this.isErrorMissingVisitorName = false;

      //Get data
      const {hasMembers} = this;
      const {forOthers, numVisitors} = this.model;
      const {requiresVisitorNames} = this.activity;

      //Ensure we have visitor names for every visitor if needed
      if (requiresVisitorNames && !forOthers) {
        if (this.model.visitors.some(visitor => !visitor.name)) {
          this.isErrorMissingVisitorNames = true;
          return false;
        }
      }

      //Ensure we have at least the first visitor name if admin making a booking
      if (!hasMembers && forOthers && numVisitors) {
        if (this.model.visitors.every(visitor => !visitor.name)) {
          this.isErrorMissingVisitorName = true;
          return false;
        }
      }

      //Check if form valid
      return this.form.$valid;
    };

    /**
     * Set step
     */
    this.nextStep = function(step) {
      return this
        .validateBooking()
        .then(() => {
          if (!this.error && !this.errorFeeMismatch) {
            this.step = step;
          }
        });
    };

    /**
     * Previous step
     */
    this.prevStep = function(step) {
      this.step = step;
    };

    /**
     * Validate booking
     */
    this.validateBooking = function() {

      //Set flags
      this.error = null;
      this.errorFeeMismatch = null;
      this.isValidating = true;

      //Validate
      const {booking} = this;
      const model = this.model.toJSON(); //NOTE: needs to be refactored in Vue
      return booking
        .validateChange(model)
        .then(meta => {
          //Check if fee is still the same
          if (
            (booking.fee && booking.fee.amount &&
            (booking.fee.amount === meta.fee)) ||
            (booking.fee === null && meta.fee === 0)
          ) {
            this.errorFeeMismatch = false;
          }
          else {
            this.errorFeeMismatch = true;
          }
        })
        .catch(error => {
          if (error.name === 'ValidationError') {
            this.error = error;
          }
        })
        .finally(() => this.isValidating = false);
    };

    /**
     * Save booking
     */
    this.save = function() {

      //Validate visitor names if we need to
      const {activity} = this;
      if (activity.requiresVisitorNames && this.model.numVisitors > 0) {
        if (!this.validateVisitorNames()) {
          return;
        }
      }

      //Get data
      const {booking} = this;
      const model = this.model.toJSON(); //NOTE: needs to be refactored in Vue

      //Validate and save
      return this
        .validateBooking()
        .then(() => {
          if (this.error || this.errorFeeMismatch) {
            return;
          }
          this.isSaving = true;
          booking
            .patch(model)
            .then(() => {
              $notice.show('Booking updated');
              this.onChanged({$event: {booking}});
              $store.bookings.clear();
            })
            .catch(error => {
              if (error.name === 'ValidationError') {
                this.error = error;
              }
            })
            .finally(() => this.isSaving = false);
        });
    };
  },
});
