
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Settings.Event.Card', [])

/**
 * Component
 */
.component('cardSettingsEvent', {
  templateUrl: 'admin/settings/cards/event.html',
  controller: 'CardSettingsEventCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSettingsEventCtrl', function(
  $controller, $store, Modules, ChangeThresholds
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'event';
    this.ChangeThresholds = ChangeThresholds;

    //Calendar period options
    this.PeriodOptions = [
      {value: 'week', label: 'Week view'},
      {value: 'month', label: 'Month view'},
    ];

    //Events on home page options
    this.numEventsOptions = [
      {value: 4},
      {value: 8},
      {value: 12},
    ];

    //Optional overview fields
    this.overviewFields = [
      {
        label: 'Attendance',
        value: 'attendance',
      },
      {
        label: 'Organisers',
        value: 'organisers',
      },
    ];

    //Activity module
    if (Modules.has('activities')) {
      this.overviewFields.push({
        label: 'Activity',
        value: 'activity',
      });
    }

    //Call parent init
    $base.$onInit.call(this);

    //Load custom fields
    this.loadCustomFields();
  };

  /**
   * Load custom fields
   */
  this.loadCustomFields = function() {

    //Set flag
    this.isLoadingCustomFields = true;

    //Load
    $store.customFields
      .query({model: 'Event'})
      .then(customFields => {
        for (const field of customFields) {
          if (!field.isParagraph && !field.isFile) {
            this.overviewFields.push({
              label: field.label,
              value: `customFields.${field.prop}`,
            });
          }
        }
      })
      .finally(() => this.isLoadingCustomFields = false);
  };
});
