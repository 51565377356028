
/**
 * Module definition and dependencies
 */
angular.module('Shared.CustomFields.Card', [
  'Shared.CustomFieldOptions.Component',
])

/**
 * Component
 */
.component('cardCustomFields', {
  templateUrl: 'cards/custom-fields.html',
  controller: 'CardCustomFieldsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    fields: '<',
    memberships: '<',
    showOptions: '<',
    showDirectory: '<',
    showConstraints: '<',
    model: '@',
    type: '<',
    setupStep: '@',
  },
})

/**
 * Controller
 */
.controller('CardCustomFieldsCtrl', function($modal, $notice, CustomField) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize flags
    this.hasFields = this.fields.length > 0;
    this.isSuper = this.user.isSuper();

    //Check if we can add more
    this.checkCanAddMore();
  };

  /**
   * Check if we can add more
   */
  this.checkCanAddMore = function() {
    this.canAddMore = (
      this.club.permissions.customFieldLimit === null ||
      this.club.permissions.customFieldLimit > this.fields.length
    );
  };

  /**
   * On added handler
   */
  this.onAdded = function($event) {

    //Get new field
    const {field} = $event;

    //Add to array
    this.fields.push(field);
    this.hasFields = true;

    //Check if we can add more
    this.checkCanAddMore();
  };

  /**
   * On deleted handler
   */
  this.onDeleted = function($event) {

    //Get field and find the index
    const {field} = $event;
    const {model, prop, id} = field;
    const index = this.fields.findIndex(f => f.id === id);
    const {settings} = this.club;

    //Splice
    if (index > -1) {
      this.fields.splice(index, 1);
      this.hasFields = this.fields.length > 0;
    }

    //Check if we can add more
    this.checkCanAddMore();

    //Remove from member settings
    if (model === 'Member') {
      if (settings.member.infoField === `customFields.${prop}`) {
        settings.member.infoField = null;
      }
    }

    //Remove from event settings
    if (model === 'Event') {
      const i = settings.event.overviewFields.indexOf(`customFields.${prop}`);
      if (i !== -1) {
        settings.event.overviewFields.splice(i, 1);
      }
    }
  };

  /**
   * Toggle field
   */
  this.toggleField = function(field, prop) {

    //Get new value and toggle prop
    const value = !field[prop];
    field[prop] = value;

    //Get data
    const {isVisible, isEditable, isRequired} = field;

    //Can't be required if not visible
    if (prop === 'isVisible' && !isVisible && isRequired) {
      field.isRequired = false;
    }

    //Can't be editable if not visible
    if (prop === 'isVisible' && !isVisible && isEditable) {
      field.isEditable = false;
    }

    //Can't be hidden if required
    if (prop === 'isRequired' && !isVisible && isRequired) {
      field.isVisible = true;
    }

    //Can't be hidden if editable
    if (prop === 'isEditable' && !isVisible && isEditable) {
      field.isVisible = true;
    }

    //Can't be required if not editable
    if (prop === 'isEditable' && isRequired && !isEditable) {
      field.isRequired = false;
    }

    //Can't be not editable if required
    if (prop === 'isRequired' && isRequired && !isEditable) {
      field.isEditable = true;
    }

    //Update field
    field
      .patch({[prop]: value})
      .catch(() => field[prop] = !field[prop]);
  };

  /**
   * Add field
   */
  this.add = function() {

    //Prepare data
    const {
      model, memberships, showOptions, showDirectory, showConstraints,
    } = this;
    const data = {model};
    const isEdit = false;
    const isSuper = this.user.isSuper();

    //Get new field and define handler
    const field = new CustomField(data);
    const handler = data => field.save(data);

    //Open modal
    $modal
      .open('editCustomField', {
        locals: {
          field, isEdit, isSuper, memberships, showOptions,
          showDirectory, showConstraints, handler,
        },
      })
      .result
      .then(() => {
        $notice.show('Custom field created');
        this.onAdded({field});
        if (this.setupStep) {
          this.club.markStepComplete(this.setupStep, true);
        }
      });
  };

  /**
   * Edit field
   */
  this.edit = function($event) {

    //Get field
    const {field} = $event;
    const isEdit = true;
    const {memberships, showOptions, showDirectory, showConstraints} = this;
    const isSuper = this.user.isSuper();

    //API only
    if (field.isApiOnly) {
      return;
    }

    //Define handler
    const handler = data => field.save(data);

    //Open modal
    $modal
      .open('editCustomField', {
        locals: {
          field, isEdit, isSuper, memberships, showOptions,
          showDirectory, showConstraints, handler,
        },
      })
      .result
      .then(() => $notice.show('Custom field updated'));
  };

  /**
   * Delete field
   */
  this.delete = function($event) {

    //Get field
    const {field} = $event;

    //API only
    if (field.isApiOnly) {
      return;
    }

    //Define handler
    const handler = () => field.delete();

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-delete-custom-field.html',
        locals: {field, handler},
      })
      .result
      .then(() => {
        $notice.show('Custom field removed');
        this.onDeleted({field});
      });
  };
});
