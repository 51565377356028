
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.Details', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.details', {
    url: '/details',
    component: 'registerDetailsRoute',
    params: {
      profile: null,
      squash: true,
    },
  });
})

/**
 * Route component
 */
.component('registerDetailsRoute', {
  controller: 'RegisterDetailsCtrl',
  templateUrl: 'portal/register/steps/details.html',
  bindings: {
    club: '<',
    registration: '<',
    transition: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterDetailsCtrl', function($controller, Genders) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('RegisterBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Setup
   */
  this.setup = function() {

    //Set available genders
    this.genders = Genders.filter(gender => gender.value !== 'unknown');

    //Apply user profile
    const {profile} = this.transition.params();
    if (profile) {
      this.registration.applyProfile(profile);
    }
  };

  /**
   * Validate
   */
  this.validate = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Flag as checking
    this.isChecking = true;

    //Check if a member with the same name exists
    this.registration
      .checkMemberExists()
      .then(() => {
        this.continue();
        this.registration.findUniqueUsername();
      })
      .catch(reason => {
        if (reason !== 'cancel') {
          throw reason;
        }
      })
      .finally(() => this.isChecking = false);
  };
});
