
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Add.Controller', [])

/**
 * Controller
 */
.controller('AdminEventAddCtrl', function($controller, $state, CardCloseReasons) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminEventCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {
    $base.$onInit.call(this);
    const params = this.transition.params();
    this.isCopy = !!params.eventId;

    //Load calendars
    this.loadGoogleCalendars();
  };

  /**
   * Exit handler
   */
  this.exit = function($event) {

    //Get reason
    const {reason} = $event;

    //If card was closed, go back to overview
    if (reason === CardCloseReasons.CLOSE) {
      $state.go('admin.event.overview');
    }
    else if (reason === CardCloseReasons.SAVED) {
      $state.go('admin.event.edit', {eventId: this.event.id});
    }
  };
});
