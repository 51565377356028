
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Interested.Card', [])

/**
 * Component
 */
.component('cardEventInterested', {
  templateUrl: 'admin/event/cards/interested.html',
  controller: 'CardEventInterestedCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    event: '<',
    limit: '<',
    interested: '<',
    onReload: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventInterestedCtrl', function(
  $modal, $notice, EventInterest, Modules, ReplacementTags,
  $store, MemberGroup, Member
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.toggling = {};

    //Load groups
    this.loadGroups();
  };

  /**
   * On changes
   */
  this.$onChanges = function() {
    this.hasInterested = this.interested.length > 0;
    this.hasMore = this.interested.length > this.limit;
  };

  /**
   * Add interested
   */
  this.add = function() {

    //Get data and define handler
    const {event, interested, groups} = this;
    const handler = data => EventInterest.createMany(data);
    const {plural, singular} = Modules.find('members');

    //Open modal
    $modal
      .open('addInterested', {locals: {handler, event, interested, groups}})
      .result
      .then(interested => {
        const s = (interested.length > 1) ? plural : singular;
        $notice.show(`Interested ${s} added`);
        this.onReload();
      });
  };

  /**
   * Show all interested
   */
  this.showAll = function() {
    this.hasMore = false;
    this.limit = undefined;
  };

  /**
   * Remove interest
   */
  this.delete = function($event) {

    //Get interest and define handler
    const {interest} = $event;
    const {singular} = Modules.find('members');
    const handler = function() {
      return interest.delete();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: `admin/event/modals/confirm-delete-interest.html`,
        locals: {interest, handler},
      })
      .result
      .then(() => {
        $notice.show(`Interested ${singular} removed`);
        const i = this.interested.indexOf(interest);
        if (i !== -1) {
          this.interested.splice(i, 1);
        }
        this.hasInterested = this.interested.length > 0;
      });
  };

  /**
   * Export
   */
  this.export = function() {

    //Get data
    const {club, event: {id: event}} = this;
    const {plural} = Modules.find('members');

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club, action: `Exporting interested ${plural}`},
      });
    }

    //Check if anything to export
    if (!this.hasInterested) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: `interested ${plural}`, action: 'export'},
      });
    }

    //Export
    return EventInterest.export({event});
  };

  /**
   * Email all interested
   */
  this.email = function() {

    //Get plural
    const {plural} = Modules.find('members');

    //Check if anything to do
    if (!this.hasInterested) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: `interested ${plural}`, action: 'email'},
      });
    }

    //Define handlers
    const {event: {id: event}, customFields} = this;
    const filter = {event};
    const handler = email => EventInterest.email(filter, email);
    const counter = () => EventInterest.count(filter);
    const tags = ReplacementTags.member(customFields);

    //Open modal
    $modal.open('email', {locals: {handler, counter, tags}});
  };

  /**
   * Load groups
   */
  this.loadGroups = function() {
    $store.memberGroups
      .query(true)
      .then(groups => this.groups = groups);
  };

  /**
   * Add members to groups
   */
  this.addToGroups = function() {

    //Get filter
    const {groups} = this;
    const Model = MemberGroup;
    const {plural, singular} = Modules.find('members');

    //Get all member ids
    const ids = this.interested
      .map(interest => interest.member.id);

    //Define handler
    const handler = (groups) => Member.addToGroups({ids}, groups);
    const reloader = () => this.loadGroups();

    //Open modal
    $modal
      .open('addToGroups', {locals: {Model, groups, handler, reloader}})
      .result
      .then(() => {
        const s = (ids.length > 1) ? plural : singular;
        $notice.show(`Added interested ${s} to groups`);
      });
  };
});
