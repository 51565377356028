
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.Alerts.Card', [
  'App.Admin.Club.EditAlert.Modal',
  'App.Admin.Club.AlertOptions.Component',
])

/**
 * Component
 */
.component('cardClubAlerts', {
  templateUrl: 'admin/club/cards/alerts.html',
  controller: 'CardClubAlertsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    alerts: '<',
  },
})

/**
 * Controller
 */
.controller('CardClubAlertsCtrl', function($modal, $notice, $store, Alert) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize flags
    this.hasAlerts = this.alerts.length > 0;
  };

  /**
   * On added handler
   */
  this.onAdded = function($event) {

    //Get new alert
    const {alert} = $event;

    //Add to array
    this.alerts.push(alert);
    this.hasAlerts = true;
  };

  /**
   * On deleted handler
   */
  this.onDeleted = function($event) {

    //Get alert and find the index
    const {alert} = $event;
    const index = this.alerts.findIndex(a => a.id === alert.id);

    //Splice
    if (index > -1) {
      this.alerts.splice(index, 1);
      this.hasAlerts = this.alerts.length > 0;
    }
  };

  /**
   * Add alert
   */
  this.add = function() {

    //Create alert and define handler
    const alert = new Alert();
    const handler = function(model) {
      return $store.alerts.save(alert, model);
    };

    //Open modal
    return $modal
      .open('editAlert', {locals: {alert, handler}})
      .result
      .then(() => {
        $notice.show('Alert created');
        this.onAdded({alert});
        this.club.markStepComplete('setupAlerts', true);
      });
  };

  /**
   * Edit alert
   */
  this.edit = function($event) {

    //Get alert and define handler
    const {alert} = $event;
    const handler = function(model) {
      return $store.alerts.save(alert, model);
    };

    //Show modal
    return $modal
      .open('editAlert', {locals: {alert, handler}})
      .result
      .then(() => $notice.show('Alert updated'));
  };

  /**
   * Delete alert
   */
  this.delete = function($event) {

    //Get alert and define handler
    const {alert} = $event;
    const handler = function() {
      return $store.alerts.delete(alert);
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'admin/club/modals/confirm-delete-alert.html',
        locals: {alert, handler},
      })
      .result
      .then(() => {
        $notice.show('Alert removed');
        this.onDeleted({alert});
      });
  };
});
