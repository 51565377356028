
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.ResetPassword.Controller', [])

/**
 * Controller
 */
.controller('ResetPasswordCtrl', function(User) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Get params
    const params = this.transition.params();
    const token = params.token;

    //Initialize credentials
    this.credentials = {token};

    //Initialize flags
    this.isSubmitting = false;
    this.isSubmitted = false;
    this.isInvalidToken = false;

    //Find user by token
    User
      .findByToken(token)
      .then(user => this.user = user)
      .catch(() => this.isInvalidToken = true);
  };

  /**
   * Submit request
   */
  this.submit = function() {

    //Must be validated
    if (this.form.$invalid) {
      return;
    }

    //Mark as submitting
    this.isSubmitting = true;

    //Send request
    User
      .resetPassword(this.credentials)
      .then(() => {
        this.isSubmitted = true;
        this.credentials = {};
      })
      .catch(error => {

        //Process invalid token errors
        if (error.code === 'INVALID_TOKEN' || error.code === 'BAD_REQUEST') {
          this.isInvalidToken = true;
          return;
        }
      })
      .finally(() => this.isSubmitting = false);
  };
});
