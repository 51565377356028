
/**
 * Module definition and dependencies
 */
angular.module('Shared.LimitUnits.Options', [])

/**
 * Constant definition
 */
.constant('LimitUnits', [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'timeRange',
    label: 'Per time range',
  },
  {
    value: 'day',
    label: 'Per day',
  },
  {
    value: 'week',
    label: 'Per week',
  },
  {
    value: 'month',
    label: 'Per month',
  },
]);
