
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.CreateBooking.Modal', [
  'App.Booking.Flow.SelectMode.Component',
  'App.Booking.Flow.SelectMembers.Component',
  'App.Booking.Flow.Notes.Component',
  'App.Booking.Flow.Recurrence.Component',
  'App.Booking.Flow.Confirm.Component',
])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('createBooking', {
    templateUrl: 'booking/modals/create-booking.html',
    controller: 'ModalCreateBookingCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalCreateBookingCtrl', function(
  $controller, $modal, $modalInstance, $notice, $q, ErrorCodes, Intercom,
  moment, Circle
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get data
    const {booking, user, club} = this;

    //Check if can manage bookings and if can make own bookings
    this.canManageBookings = user && user.hasRole('admin', 'eventManager');
    this.canMakeOwnBookings = (!user || user.hasRole('member'));
    this.circleMembers = [];

    //Set confirmation email flag
    booking.sendConfirmationEmail = true;

    //Set initial mode
    if (!booking.mode) {
      booking.mode = this.modes[0];
    }

    //Ensure we have the user as a member if none present
    if (user && this.checkBookingHasMembers()) {
      booking.setMembers(user);
    }

    //Refresh user's account credit
    if (user) {
      user.refreshAccountCredit();
    }

    //Load circle members
    if (user) {
      this.loadCircleMembers();
    }

    //Set max book ahead date
    const type = user ? user.getBookAheadType() : 'casual';
    const {amount, unit} = club.settings.booking.maxAhead[type];
    this.maxDate = moment().add({[unit]: amount});

    //Always select mode as first step
    this.stepSelectMode();
  };

  /**
   * Load circle members
   */
  this.loadCircleMembers = function() {
    Circle
      .queryOwnManagedMembers('bookings')
      .then(members => {
        this.circleMembers = members;
      });
  };

  /**
   * Select mode step
   */
  this.stepSelectMode = function() {
    this.step = 'selectMode';
  };

  /**
   * Select members step
   */
  this.stepSelectMembers = function() {
    this.step = 'selectMembers';
    return $q.resolve();
  };

  /**
   * Notes step
   */
  this.stepNotes = function() {
    this.step = 'notes';
    return $q.resolve();
  };

  /**
   * Recurrence step
   */
  this.stepRecurrence = function() {
    this.step = 'recurrence';
    return $q.resolve();
  };

  /**
   * Confirm booking step
   */
  this.stepConfirmBooking = function() {
    this.step = 'confirmBooking';
    return $q.resolve();
  };

  /**
   * Validate booking
   */
  this.validateBooking = function(includeRecurrence = false) {
    return this.booking
      .validate(includeRecurrence)
      .catch(error => {

        //Not booking for someone else? Just rethrow
        if (!this.booking.forOthers) {
          throw error;
        }

        //Existing bookings
        if (error.code === ErrorCodes.areaExistingBooking) {
          const {bookings} = error.data;
          return $modal
            .open('basic', {
              templateUrl: 'booking/modals/existing-bookings.html',
              locals: {bookings},
              rejectOnDismissal: true,
            })
            .result;
        }
        else if (error.code === ErrorCodes.areaExistingEvent) {
          const {events} = error.data;
          return $modal
            .open('basic', {
              templateUrl: 'booking/modals/existing-events.html',
              locals: {events},
              rejectOnDismissal: true,
            })
            .result;
        }

        //Rethrow error
        throw error;
      })
      .then(meta => this.meta = meta);
  };

  /**
   * Go to next step from selecting mode
   */
  this.nextFromMode = function() {

    //Check flags
    const {forOthers, members, mode} = this.booking;
    const {maxPeople, minVisitors} = mode;
    const onlyVisitor = (minVisitors === 1 && maxPeople === 2);
    const hasNoMembers = (members.length === 0);
    const needsPeople = (maxPeople > 1 && !onlyVisitor);
    const canAddCircleMembers = (this.circleMembers.length > 0);

    //Set minimum amount of visitors
    while (this.booking.numVisitors < minVisitors) {
      this.booking.addVisitor();
    }

    //Select members
    if (forOthers || hasNoMembers || needsPeople || canAddCircleMembers) {
      return this.stepSelectMembers();
    }

    //Next step
    return this.nextFromMembers();
  };

  /**
   * Go to previous step from selecting members
   */
  this.prevFromMembers = function() {

    //Get data
    const {user, booking} = this;

    //Set user as member again if we removed everyting
    if (user && !user.isSuper() && !this.checkBookingHasMembers()) {
      booking.setMembers(user);
    }

    //Select mode
    this.stepSelectMode();
  };

  /**
   * Go to next step from selecting members
   */
  this.nextFromMembers = function() {

    //Get data
    const {activity, booking} = this;
    const {numVisitors, forOthers} = booking;
    const shouldAddNames = (activity.requiresVisitorNames && numVisitors > 0);

    //Go to recurrence
    if (this.booking.isRecurring) {
      return this
        .validateBooking()
        .then(() => this.stepRecurrence());
    }

    //Go to notes
    if (forOthers || shouldAddNames) {
      return this
        .validateBooking()
        .then(() => this.stepNotes());
    }

    //Confirm booking
    return this
      .validateBooking()
      .then(() => this.stepConfirmBooking());
  };

  /**
   * Go to previous step from recurrence
   */
  this.prevFromRecurrence = function() {
    return this.stepSelectMembers();
  };

  /**
   * Go to next step from recurrence
   */
  this.nextFromRecurrence = function() {

    //Get data
    const {activity, booking} = this;
    const {numVisitors, forOthers} = booking;
    const shouldAddNames = (activity.requiresVisitorNames && numVisitors > 0);

    //Go to notes
    if (forOthers || shouldAddNames) {
      return this
        .validateBooking(true)
        .then(() => this.stepNotes());
    }
    //Confirm booking
    return this
      .validateBooking(true)
      .then(() => this.stepConfirmBooking());
  };

  /**
   * Go to previous step from notes
   */
  this.prevFromNotes = function() {

    //Check flags
    const {forOthers, mode, isRecurring} = this.booking;
    const {maxPeople, minVisitors} = mode;
    const onlyVisitor = (minVisitors === 1 && maxPeople === 2);
    const needsPeople = (maxPeople > 1 && !onlyVisitor);
    const canAddCircleMembers = (this.circleMembers.length > 0);

    //Go to recurrence
    if (isRecurring) {
      return this.stepRecurrence();
    }
    //Go to select members
    if (forOthers || needsPeople || canAddCircleMembers) {
      return this.stepSelectMembers();
    }

    //Select mode
    this.stepSelectMode();
  };

  /**
   * Go to next step from notes
   */
  this.nextFromNotes = function() {
    return this.stepConfirmBooking();
  };

  /**
   * Go to previous step from confirming booking
   */
  this.prevFromConfirm = function() {

    //Get data
    const {activity, booking} = this;
    const {numVisitors, forOthers, isRecurring} = booking;
    const shouldAddNames = (activity.requiresVisitorNames && numVisitors > 0);

    //Check which step to go to
    if (forOthers || shouldAddNames) {
      this.stepNotes();
    }
    else if (isRecurring) {
      this.stepRecurrence();
    }
    else {
      this.prevFromNotes();
    }
  };

  /**
   * Check if booking has members
   */
  this.checkBookingHasMembers = function() {
    return this.booking.members.length > 0;
  };

  /**
   * Created booking
   */
  this.onCreatedBooking = function($event) {

    //Get booking
    const {booking, needsPayment} = $event;

    //Show corresponding message
    if (needsPayment) {
      $notice.show('Booking reserved');
    }
    else {
      $notice.show('Booking created');
    }

    //Close modal
    Intercom.event('Created booking');
    $modalInstance.resolve(booking);
  };
});
