
/**
 * Module definition and dependencies
 */
angular.module('Shared.Circle.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config($apiProvider => {
  $apiProvider.registerEndpoint('circle', {
    model: 'Circle',
    params: {
      id: '@id',
      memberId: '@memberId',
      attendeeId: '@attendeeId',
    },
    actions: {
      query: {
        method: 'GET',
        dataKey: 'circles',
        isArray: true,
        isModel: true,
      },
      own: {
        url: 'own',
        method: 'GET',
        dataKey: 'circles',
        isModel: true,
        isArray: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      createOwn: {
        url: 'own',
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      delete: {
        method: 'DELETE',
      },
      createMember: {
        url: 'member',
        method: 'POST',
      },
      updateMember: {
        url: 'member/:memberId',
        method: 'PUT',
      },
      deleteMember: {
        url: 'member/:memberId',
        method: 'DELETE',
      },
      patchOwn: {
        url: 'member/own',
        method: 'PATCH',
      },
      deleteOwn: {
        url: 'member/own',
        method: 'DELETE',
      },
      queryMembers: {
        url: 'members',
        method: 'GET',
      },
      queryOwnMembers: {
        url: 'members/own',
        method: 'GET',
      },
      queryOwnManagedMembers: {
        url: 'members/own/managed',
        method: 'GET',
      },
      invite: {
        url: 'invite',
        method: 'POST',
      },
      acceptInvite: {
        url: 'invite/accept',
        method: 'POST',
      },
      declineInvite: {
        url: 'invite/decline',
        method: 'POST',
      },
      deleteInvite: {
        url: 'invite/:memberId',
        method: 'DELETE',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('Circle', function($api, $baseModel, CircleMember) {

  /**
   * Constructor
   */
  function Circle(data) {
    $baseModel.call(this, data);

    //Initials
    Object.defineProperty(this, 'initial', {
      get() {
        return this.name[0].toUpperCase();
      },
    });

    //Has permissions check
    Object.defineProperty(this, 'hasPermissions', {
      get() {
        return Object.values(this.permissions).includes(true);
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Circle.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Circle.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Members and invited members
    this.convertToModel('members', 'CircleMember', true);
    this.convertToModel('invited', 'CircleMember', true);

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Circle.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Only ID for members
    json.members = $baseModel.onlyId(json.members);

    //Return json
    return json;
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query circles
   */
  Circle.query = function(filter) {
    return $api.circle.query(filter);
  };

  /**
   * Query own circles
   */
  Circle.own = function(filter) {
    return $api.circle.own(filter);
  };

  /**
   * Get circle if you are club admin or in circle
   */
  Circle.get = function() {
    return $api.circle.get();
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Check if a member exists in this circle
   */
  Circle.prototype.hasMember = function(member) {
    return (
      this.members.some(m => m.id === member.id) ||
      this.invited.some(m => m.id === member.id)
    );
  };

  /**
   * Save
   */
  Circle.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.circle[method](data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Create own
   */
  Circle.prototype.createOwn = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Create own
    return $api.circle
      .createOwn(data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Circle.prototype.delete = function() {
    return $api.circle
      .delete({id: this.id})
      .then(() => this);
  };

  /**************************************************************************
   * Circle member instance methods
   ***/

  /**
   * Invite circle members
   */
  Circle.prototype.invite = function(data) {
    return $api.circle
      .invite({id: this.id}, data);
  };

  /**
   * Accept circle invite
   */
  Circle.prototype.acceptInvite = function() {
    return $api.circle
      .acceptInvite({id: this.id});
  };

  /**
   * Decline circle invite
   */
  Circle.prototype.declineInvite = function() {
    return $api.circle
      .declineInvite({id: this.id});
  };

  /**
   * Delete circle invite
   */
  Circle.prototype.deleteInvite = function(memberId) {
    return $api.circle
      .deleteInvite({id: this.id, memberId: memberId});
  };

  /**
   * Add members directly to a circle
   */
  Circle.prototype.createMember = function(data) {
    return $api.circle
      .createMember({id: this.id}, data);
  };

  /**
   * Update circle members
   */
  Circle.prototype.updateMember = function(memberId, member) {
    return $api.circle
      .updateMember({id: this.id, memberId: memberId}, member);
  };

  /**
   * Remove circle members
   */
  Circle.prototype.deleteMember = function(memberId) {
    return $api.circle
      .deleteMember({id: this.id, memberId: memberId})
      .then(() => {
        const i = this.members.findIndex(member => member.id === memberId);
        if (i !== -1) {
          this.members.splice(i, 1);
        }
      });
  };

  /**
   * Patch own
   */
  Circle.prototype.patchOwn = function(data) {
    return $api.circle.patchOwn({id: this.id}, data);
  };

  /**
   * Remove own
   */
  Circle.prototype.deleteOwn = function(memberId) {
    return $api.circle
      .deleteOwn({id: this.id, memberId: memberId});
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query list
   */
  Circle.query = function(filter) {
    return $api.circle.query(filter);
  };

  /**
   * Query members
   */
  Circle.queryMembers = function(filter) {
    return $api.circle
      .queryMembers(filter)
      .then(data => data.members.map(m => new CircleMember(m)));
  };

  /**
   * Query own members
   */
  Circle.queryOwnMembers = function(filter) {
    return $api.circle
      .queryOwnMembers(filter)
      .then(data => data.members.map(m => new CircleMember(m)));
  };

  /**
   * Query own managed members
   */
  Circle.queryOwnManagedMembers = function(permission) {
    return $api.circle
      .queryOwnManagedMembers({permission})
      .then(data => data.members.map(m => new CircleMember(m)));
  };

  /**
   * Add item
   */
  Circle.add = function(data) {

    //Only ID
    const item = new Circle(data);
    const json = item.toJSON();

    //Add to list
    return $api.circle.add(json);
  };

  //Return
  return Circle;
});
