
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Setup.InviteAdmins.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('inviteAdmins', {
    templateUrl: 'admin/setup/modals/invite-admins.html',
    controller: 'ModalInviteAdminsCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalInviteAdminsCtrl', function($controller, $modalInstance) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Initialize admins
    this.admins = [{}, {}, {}, {}, {}];
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Ensure something to do
    if (this.admins.every(admin => !admin.email)) {
      return;
    }

    //Filter out admins with data only
    const admins = this.admins.filter(admin => !!admin.email);

    //Save
    this.isInviting = true;
    this
      .handler(admins)
      .then(() => $modalInstance.resolve())
      .finally(() => this.isInviting = false);
  };
});
