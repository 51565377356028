
/**
 * Module definition and dependencies
 */
angular.module('Shared.FeeMinMax.Component', [])

/**
 * Min/max fee structure component
 */
.component('feeMinMax', {
  template: `<span>{{$ctrl.parts | join}}&nbsp;</span>`,
  bindings: {
    fee: '<',
    hideEmpty: '<',
  },
  controller($filter) {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Get data
      const {min, max} = this.fee;

      //Prepare parts
      this.parts = [];

      //Add parts
      if (min) {
        const amount = $filter('currency')(min);
        this.parts.push(`min. ${amount}`);
      }
      if (max) {
        const amount = $filter('currency')(max);
        this.parts.push(`max. ${amount}`);
      }
      if (!min && !max && !this.hideEmpty) {
        this.parts.push(`no min/max`);
      }
    };
  },
});
