
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.Kiosk.Card', [])

/**
 * Component
 */
.component('cardClubKiosk', {
  templateUrl: 'admin/club/cards/kiosk.html',
  controller: 'CardClubKioskCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
  },
})

/**
 * Controller
 */
.controller('CardClubKioskCtrl', function($filter, $modal, Kiosk, Modules) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Check if enabled
    this.isEnabled = Kiosk.isEnabled();
    this.defaultPage = Kiosk.getDefaultPage();

    //Pages
    this.pages = [
      {
        value: 'kiosk',
        label: 'Bookings',
      },
      {
        value: 'register.intro',
        label: 'Registration page',
      },
      {
        value: 'checkIn',
        label: `${$filter('ucfirst')(Modules.singular('members'))} check-in`,
      },
      {
        value: 'visitor.intro',
        label: 'Visitor log',
      },
    ];

    //No bookings module enabled
    if (!Modules.has('bookings')) {
      this.pages.shift();
      if (this.defaultPage === 'kiosk') {
        this.setDefaultPage('visitor.intro');
      }
    }
  };

  /**
   * Update default page
   */
  this.setDefaultPage = function(page) {
    this.defaultPage = page;
    Kiosk.setDefaultPage(page);
  };

  /**
   * Enable
   */
  this.enable = function() {

    //Define handler
    const club = this.club;
    const handler = function() {
      return club.enableKioskMode();
    };

    //Open modal
    $modal
      .open('basic', {
        templateUrl: 'admin/club/modals/confirm-kiosk-enable.html',
        locals: {handler},
      })
      .result
      .then(() => {
        this.isEnabled = true;
        Kiosk.setEnabled(true);
      });
  };

  /**
   * Disable
   */
  this.disable = function() {

    //Define handler
    const club = this.club;
    const handler = function() {
      return club.disableKioskMode();
    };

    //Open modal
    $modal
      .open('basic', {
        templateUrl: 'admin/club/modals/confirm-kiosk-disable.html',
        locals: {handler},
      })
      .result
      .then(() => {
        this.isEnabled = false;
        Kiosk.setEnabled(false);
      });
  };
});
