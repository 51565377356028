
/**
 * Module definition and dependencies
 */
angular.module('Shared.EmailTemplateTypes.Options', [])

/**
 * Constant definition
 */
.constant('EmailTemplateTypes', [
  {
    label: 'Birthday email',
    value: 'birthdayEmail',
    canUseTags: true,
  },
  {
    label: 'Booking confirmation',
    value: 'bookingCreatedEmail',
    isCustom: true,
    canUseTags: true,
  },
  {
    label: 'Event attendee confirmation',
    value: 'eventAttendeeEmail',
    isCustom: true,
  },
  {
    label: 'Membership creation confirmation',
    value: 'membershipCreationEmail',
    isCustom: true,
    canUseTags: true,
  },
  {
    label: 'Membership purchase confirmation',
    value: 'membershipPurchaseEmail',
    isCustom: true,
    canUseTags: true,
  },
  {
    label: 'Membership change confirmation',
    value: 'membershipChangeEmail',
    isCustom: true,
    canUseTags: true,
  },
  {
    label: 'Membership renewal confirmation',
    value: 'membershipRenewalEmail',
    isCustom: true,
    canUseTags: true,
  },
  {
    label: 'Membership renewal reminder',
    value: 'membershipRenewalReminderEmail',
    isCustom: true,
    canUseTags: true,
  },
  {
    label: 'Unpaid booking reminder',
    value: 'unpaidBookingReminderEmail',
    isCustom: true,
    canUseTags: true,
  },
  {
    label: 'Unpaid transaction reminder',
    value: 'unpaidTrxReminderEmail',
    isCustom: true,
    canUseTags: true,
  },
]);
