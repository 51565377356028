
/**
 * Module definition and dependencies
 */
angular.module('Shared.Genders.Options', [])

/**
 * Constant definition
 */
.constant('Genders', [
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'diverse',
    label: 'Non-binary',
  },
  {
    value: 'hidden',
    label: 'Rather not say',
  },
  {
    value: 'unknown',
    label: 'Unknown',
  },
]);
