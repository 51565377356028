
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.WelcomeEmails.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('welcomeEmails', {
    templateUrl: 'admin/people/members/modals/welcome-emails.html',
    controller: 'ModalWelcomeEmailsCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalWelcomeEmailsCtrl', function(
  $controller, $modalInstance, $notice, Member, TooManyRequestsError
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Load members
    this.loadMembers();
  };

  /**
   * Get number of recipients
   */
  this.loadMembers = function() {

    //Flag as loading
    this.isLoading = true;

    //Get filter
    const {filter} = this;

    //Count
    Member
      .findForWelcomeEmails(filter)
      .then(members => this.members = members)
      .finally(() => this.isLoading = false);
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Mark as sending
    this.isConfirming = true;
    this.isError = false;

    //Get filter
    const {filter} = this;

    //Send
    Member
      .sendWelcomeEmails(filter)
      .then(() => {
        $notice.show('Welcome emails sent');
        $modalInstance.resolve();
      })
      .catch(error => {

        //Too many requests
        if (error instanceof TooManyRequestsError) {
          const {limit, count, isTrial} = error.data;

          //Set flag and limit/count
          this.isRateLimitError = true;
          this.isTrial = isTrial;
          this.limit = limit;
          this.count = count;
          return;
        }

        //Generic error
        this.isError = true;
      })
      .finally(() => this.isConfirming = false);
  };
});
