
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Edit.Info.Card', [])

/**
 * Component
 */
.component('cardActivityInfo', {
  templateUrl: 'admin/activity/cards/info.html',
  require: {
    card: '^^',
  },
  bindings: {
    activity: '<',
  },
});
