
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Edit', [
  'App.Admin.Event.Edit.Controller',
  'App.Admin.Event.Edit.Notes.Card',
  'App.Admin.Event.Edit.Details.Card',
  'App.Admin.Event.Edit.Club.Card',
  'App.Admin.Event.Edit.DateTime.Card',
  'App.Admin.Event.Edit.Notifications.Card',
  'App.Admin.Event.Edit.System.Card',
  'App.Admin.Event.Edit.Recurrence.Card',
  'App.Admin.Event.Edit.Attendance.Card',
  'App.Admin.Event.Edit.Organisers.Card',
])

/**
 * Config
 */
.config($stateProvider => {

  //Edit
  $stateProvider.state('admin.event.edit', {
    url: '/edit/:eventId',
    component: 'adminEventEditRoute',
    params: {
      showAttendees: {
        value: false,
      },
      showComments: {
        value: false,
      },
      changeBanner: {
        value: false,
      },
    },
    resolve: {
      event(transition, $store) {
        const params = transition.params();
        return $store.events.findById(params.eventId);
      },
      areas($store) {
        return $store.areas.query();
      },
      memberships($store) {
        return $store.memberships.query();
      },
      customFields($store) {
        return $store.customFields.query({model: 'Event'});
      },
      categories($store) {
        return $store.eventCategories.query();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminEventEditRoute', {
  controller: 'AdminEventEditCtrl',
  templateUrl: 'admin/event/edit/edit.html',
  bindings: {
    club: '<',
    user: '<',
    event: '<',
    areas: '<',
    memberships: '<',
    activities: '<',
    customFields: '<',
    categories: '<',
    system: '<',
    page: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({
    to: 'admin.event.edit',
  }, transition => {

    //Get event
    const event = transition.injector().get('event');

    //Set page title and crumb
    Page.setTitle(event.name);
    Page.addCrumb({
      sref: 'admin.event.edit',
      params: {
        eventId: event.id,
      },
    });
  });
});
