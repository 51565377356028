
//isOnline: can be used to make online payments
//isRedirect: uses redirection to external payment portal

/**
 * Module definition and dependencies
 */
angular.module('Shared.PaymentMethods.Options', [])

/**
 * Constant definition
 */
.constant('PaymentMethods', [
  {
    value: 'stripe',
    label: 'Stripe',
    labelAlt: 'Credit card',
    provider: 'Stripe',
    description: 'credit/debit card',
    isOnline: true,
    isStripe: true,
    canStore: true,
    logos: [
      'images/payment/mastercard.png',
      'images/payment/visa.png',
      'images/payment/amex.png',
    ],
  },
  {
    value: 'poli',
    label: 'POLi',
    labelAlt: 'POLi',
    provider: 'POLi',
    description: 'POLi account to account using your internet banking',
    isOnline: true,
    isPoli: true,
    isRedirect: true,
    logos: [
      'images/payment/poli.png',
    ],
  },
  {
    value: 'accountCredit',
    label: 'Account credit',
    labelAlt: 'Account credit',
    isAccountCredit: true,
  },
  {
    value: 'bankTransfer',
    label: 'Bank transfer',
    labelAlt: 'Bank transfer',
  },
  {
    value: 'cash',
    label: 'Cash',
    labelAlt: 'Cash',
  },
  {
    value: 'cheque',
    label: 'Cheque',
    labelAlt: 'Cheque',
  },
  {
    value: 'creditCard',
    label: 'Credit card',
    labelAlt: 'Credit card',
  },
  {
    value: 'directDebit',
    label: 'Direct debit',
    labelAlt: 'Direct debit',
  },
  {
    value: 'eftpos',
    label: 'EFTPOS',
    labelAlt: 'EFTPOS',
  },
  {
    value: 'other',
    label: 'Other',
    labelAlt: 'Other',
  },
]);
