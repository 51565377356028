
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System', [
  'App.Admin.System.Controller',
  'App.Admin.System.EditTag.Modal',
  'App.Admin.System.BulkVaccination.Modal',
  'App.Admin.System.Override.Controller',
  'App.Admin.System.OverrideLights.Modal',
  'App.Admin.System.OverrideDoors.Modal',
  'App.Admin.System.RestrictUsage.Card',
  'App.Admin.System.Maintenance.Card',
  'App.Admin.System.Troubleshooting.Card',
  'App.Admin.System.QueryTag.Card',
  'App.Admin.System.Alerts.Card',
  'App.Admin.System.TagSettings.Card',
  'App.Admin.System.SessionSettings.Card',
  'App.Admin.System.OrderTags.Card',
  'App.Admin.System.PastOrders.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.system', {
    url: '/system',
    component: 'adminSystemRoute',
    data: {
      id: 'system',
    },
  });
})

/**
 * Component
 */
.component('adminSystemRoute', {
  controller: 'AdminSystemCtrl',
  templateUrl: 'admin/system/system.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    module: '<',
  },
})

/**
 * Run logic
 */
.run(($modal, $transitions, Page, Intercom) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.system'}, transition => {

    //Get data
    const {title} = transition.injector().get('module');
    const system = transition.injector().get('system');
    const user = transition.injector().get('user');

    //Determine if can override lights or doors
    const {canOverrideDoors, canOverrideLights} = user;

    //Set title and crumb
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.system'});

    //No system? We shouldn't be here
    if (!system) {
      return;
    }

    //Override lights
    if (canOverrideLights && system.hasLightControl) {
      Page.addOption('overrideLights');
    }

    //Override doors
    if (canOverrideDoors && system.hasDoorControl) {
      Page.addOption('overrideDoors');
    }

    //Track intercom event
    Intercom.event('Viewed system');
  });

  //Pre-defined option to override lights
  Page.defineOption('overrideLights', {
    title: 'Override lights',
    icon: 'wb_sunny',
    click() {
      const {event} = Page.data;
      $modal.open('overrideLights', {locals: {event}});
    },
  });

  //Pre-defined option to override doors
  Page.defineOption('overrideDoors', {
    title: 'Override doors',
    icon: 'lock',
    click() {
      const {event} = Page.data;
      $modal.open('overrideDoors', {locals: {event}});
    },
  });
});
