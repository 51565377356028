
/**
 * Module definition and dependencies
 */
angular.module('Shared.VerifyVaccinationStatus.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('verifyVaccinationStatus', {
    templateUrl: 'modals/verify-vaccination-status.html',
    controller: 'ModalVerifyVaccinationStatusCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalVerifyVaccinationStatusCtrl', function(
  $controller, $modalInstance, $notice, $timeout,
  QrScanner, Upload, KeyDown, VaccinationStatus
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Extract model
    this.model = this.member.extract('vaccination') || {};
    this.VaccinationStatus = VaccinationStatus;

    //Ensure status is unknown by default
    if (!this.model.status) {
      this.model.status = VaccinationStatus.UNKNOWN;
    }
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.reset();
  };

  /**
   * Use camera
   */
  this.useCamera = function() {

    //Set flags
    this.error = null;
    this.isUsingCamera = true;

    //Wrap in timeout to allow video element to show
    $timeout(() => this.captureVideoFeed());
  };

  /**
   * Use USB scanner
   */
  this.useUsbScanner = function() {

    //Set flags
    this.error = null;
    this.isUsingUsbScanner = true;

    //Bind listener
    this.boundQrCodeListener = this.qrCodeListener.bind(this);
    KeyDown.addListener(this.boundQrCodeListener);
  };

  /**
   * Upload
   */
  this.useUpload = function() {

    //Set flags
    this.error = null;
    this.isUsingUpload = true;
  };

  /**
   * Verify manually
   */
  this.verifyManually = function() {

    //Set flags
    this.error = null;
    this.isVerifyingManually = true;
  };

  /**
   * Update status manually
   */
  this.updateStatus = function() {

    //Get data
    const vaccination = this.model;

    //Update
    this.isLoading = true;
    this.member
      .patch({vaccination})
      .then(member => {
        this.reset();
        this.vaccination = member.vaccination;
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Start video feed capture
   */
  this.captureVideoFeed = function() {

    //Get video element
    this.video = document.getElementById('scannerVideo');
    this.video.setAttribute('playsinline', true);
    this.video.addEventListener('playing', () => {
      $timeout(() => {
        this.hasVideo = true;
      });
    });

    //Check if have camera
    this.isLoading = true;
    QrScanner
      .hasCamera()
      .then(hasCamera => {

        //No camera
        if (!hasCamera) {
          $timeout(() => {
            this.error = new Error(`No camera available on device`);
          });
          return;
        }

        //Initialize scanner
        this.scanner = new QrScanner(this.video, data => {
          if (this.isVerifying) {
            return;
          }
          this.verify(data);
        });

        //Start scanning
        this.scanner.start();
      })
      .finally(() => this.isLoading = false);
  };

  /**
   * Process file
   */
  this.processFile = function(file) {

    //Clear error
    this.error = null;

    //No file?
    if (!file) {
      return;
    }
    if (!Upload.isFile(file)) {
      this.error = new Error(`Invalid file selected`);
      return;
    }

    //Process file
    this.isLoading = true;
    Upload
      .base64DataUrl(file)
      .then(url => QrScanner.scanImage(url))
      .then(data => this.verify(data))
      .catch(() => {
        this.isLoading = false;
        this.error = new Error(`Failed to find a valid QR code in the selected image. Please ensure the QR code is clearly visible, or try an image with only the QR code in it.`);
      });
  };

  /**
   * QR code listener
   */
  this.qrCodeListener = function(buffer) {

    //Check buffer for known matches
    const vaccinePassNz = buffer.match(/^NZCP:\/([0-9]+)\/(.*)/);

    //NZ Vaccine Pass
    if (vaccinePassNz && this.club.countryCode === 'NZ') {
      const data = buffer;
      this.verify(data);
      return true;
    }
  };

  /**
   * Update vaccination status
   */
  this.verify = function(data) {

    //Get country code
    const {countryCode} = this.club;

    //Validate
    this.isLoading = true;
    this.isVerifying = true;
    this.member
      .verifyVaccinationStatus({countryCode, data})
      .then(data => {
        document.getElementById('audioCheckIn').play();
        if (this.isOwn) {
          $modalInstance.resolve(data);
          $notice.show(`Vaccination status verified`, {
            classes: 'success',
          });
        }
        else {
          this.reset();
          this.vaccination = data.vaccination;
        }
      })
      .catch(error => this.error = error)
      .finally(() => {
        this.isLoading = false;
        this.isVerifying = false;
      });
  };

  /**
   * Reset
   */
  this.reset = function() {

    //Reset flags and error
    this.error = null;
    this.hasVideo = false;
    this.isUsingUpload = false;
    this.isUsingCamera = false;
    this.isUsingUsbScanner = false;
    this.isVerifyingManually = false;

    //Remove video scanner
    if (this.scanner) {
      this.scanner.destroy();
      this.scanner = null;
    }

    //Remove listener
    if (this.boundQrCodeListener) {
      KeyDown.removeListener(this.boundQrCodeListener);
      this.boundQrCodeListener = null;
    }
  };
});
