/**
 * Module definition and dependencies
 */
angular.module('Shared.SubscriptionStatus.Component', [])

/**
 * Subscription status component
 */
.component('subscriptionStatus', {
  template: `
    <div class="SubscriptionStatus">
      <a ng-if="$ctrl.showLink" ui-sref="{{$ctrl.linkSref}}">
        <span ng-if="!$ctrl.subs && $ctrl.noneLabel">
          {{$ctrl.noneLabel}}
        </span>
        <span ng-if="$ctrl.subs && $ctrl.showFirst">
          {{$ctrl.sub.membership.nameWithSuffix}}
        </span>
        <span ng-if="$ctrl.subs && !$ctrl.showFirst">
          {{$ctrl.numSubs}} memberships
        </span>
      </a>
      <span ng-if="!$ctrl.showLink">
        <span ng-if="!$ctrl.subs && $ctrl.noneLabel">
          {{$ctrl.noneLabel}}
        </span>
        <span ng-if="$ctrl.subs && $ctrl.showFirst">
          {{$ctrl.sub.membership.nameWithSuffix}}
        </span>
        <span ng-if="$ctrl.subs && !$ctrl.showFirst">
          {{$ctrl.numSubs}} memberships
        </span>
      </span>
      <br>
      <small class="text-success" ng-if="$ctrl.isUpcoming">
        starting {{$ctrl.startDate | diffNow}}
      </small>
      <small class="text-success" ng-if="$ctrl.isRenewing">
        renewing {{$ctrl.renewalDate | diffNow}}
      </small>
      <small class="text-success" ng-if="$ctrl.isUnlimited">
        unlimited
      </small>
      <small ng-if="$ctrl.isEnding" ng-class="{'text-muted': !$ctrl.isEndingSoon, 'text-warning': $ctrl.isEndingSoon}">
        ending {{$ctrl.endDate | diffNow}}
      </small>
      <small class="text-danger" ng-if="$ctrl.isPast">
        ended {{$ctrl.endDate | diffNow}}
      </small>
      <small class="text-danger" ng-if="$ctrl.isStopped">
        stopped {{$ctrl.endDate | diffNow}}
      </small>
      <small class="text-warning" ng-if="$ctrl.isStopping">
        stopping {{$ctrl.endDate | diffNow}}
      </small>
    </div>
  `,
  bindings: {
    subscriptions: '<',
    showLink: '<',
    linkSref: '@',
    noneLabel: '@',
  },
  controller(moment) {

    /**
     * Init
     */
    this.$onChanges = function() {

      //Ensure array
      if (!this.subscriptions) {
        this.subscriptions = [];
      }

      //Reset
      this.clearSubs();

      //Only show past subs of up to a month ago
      const t2 = moment().subtract(1, 'month');

      //Filter current and upcoming subs
      const current = this.subscriptions
        .filter(sub => sub.isCurrent);
      const upcoming = this.subscriptions
        .filter(sub => sub.isUpcoming);
      const past = this.subscriptions
        .filter(sub => sub.isPast && sub.endDate.isAfter(t2));

      //Process current
      if (current.length > 0) {
        this.processCurrent(current);
      }
      else if (upcoming.length > 0) {
        this.processUpcoming(upcoming);
      }
      else if (past.length > 0) {
        this.processPast(past);
      }
    };

    /**
     * Process current subs
     */
    this.processCurrent = function(subs) {

      //Set subs
      this.setSubs(subs);

      //Check if unlimited
      if (subs.every(sub => !sub.endDate)) {
        this.isUnlimited = true;
        return;
      }

      //All renewing
      if (subs.every(sub => sub.isRenewed)) {
        this.isRenewing = true;
        this.determineRenewalDate();
        return;
      }

      //All ending
      if (subs.every(sub => !sub.isStopped && !sub.isRenewed && sub.endDate)) {
        this.isEnding = true;
        this.determineEndDate();
        this.isEndingSoon = moment().add(2, 'weeks').isAfter(this.endDate);
        return;
      }

      //All stopping
      if (subs.every(sub => sub.isStopped)) {
        this.isStopping = true;
        this.determineEndDate();
        return;
      }
    };

    /**
     * Process upcoming subs
     */
    this.processUpcoming = function(subs) {

      //Set subs
      this.setSubs(subs);

      //Mark as upcoming and determine start date
      this.isUpcoming = true;
      this.determineStartDate();
    };

    /**
     * Process past subs
     */
    this.processPast = function(subs) {

      //Only the most recent stopped subs
      const last = subs[subs.length - 1];
      subs = subs.filter(sub => sub.endDate.isSame(last.endDate));

      //Set subs
      this.setSubs(subs);

      //All stopped
      if (subs.every(sub => sub.isStopped)) {
        this.isStopped = true;
        this.determineEndDate();
        return;
      }

      //Mark as past and determine end date
      this.isPast = true;
      this.determineEndDate();
    };

    /**
     * Set subs
     */
    this.setSubs = function(subs) {
      this.showFirst = (subs.length === 1);
      this.numSubs = subs.length;
      this.subs = subs;
      this.sub = subs[subs.length - 1];
    };

    /**
     * Clear subs
     */
    this.clearSubs = function() {
      this.numSubs = 0;
      this.subs = null;
      this.isUnlimited = false;
      this.isRenewing = false;
      this.isUpcoming = false;
      this.isEnding = false;
      this.isEndingSoon = false;
      this.isStopped = false;
      this.isStopping = false;
    };

    /**
     * Determine start date
     */
    this.determineStartDate = function() {
      this.startDate = this.subs.reduce((startDate, sub) => {
        if (!startDate || sub.startDate.isBefore(startDate)) {
          return sub.startDate;
        }
        return startDate;
      }, null);
    };

    /**
     * Determine end date
     */
    this.determineEndDate = function() {
      this.endDate = this.subs.reduce((endDate, sub) => {
        if (!endDate || sub.endDate.isAfter(endDate)) {
          return sub.endDate;
        }
        return endDate;
      }, null);
    };

    /**
     * Determine renewal date
     */
    this.determineRenewalDate = function() {
      this.determineEndDate();
      this.renewalDate = this.endDate.clone().add(1, 'day');
    };
  },
});
