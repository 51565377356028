
/**
 * Module definition and dependencies
 */
angular.module('Shared.Modules.Service', [])

/**
 * Service definition
 */
.factory('Modules', function(ModuleDefaults) {

  /**
   * Class
   */
  class Modules {

    /**
     * Constructor
     */
    constructor() {
      this.reset();
    }

    /**
     * Reset modules to defaults
     */
    reset() {
      this.modules = Object.assign({}, ModuleDefaults);
    }

    /**
     * Merge custom modules data
     */
    set(custom) {

      //Reset modules to defaults
      this.reset();

      //Merge in each module
      for (const id in custom) {
        if (custom[id].isDisabled) {
          delete this.modules[id];
        }
        else {
          Object.assign(this.modules[id], custom[id]);
        }
      }

      //Return combined modules data
      return this.modules;
    }

    /**
     * Get all modules
     */
    get() {
      return this.modules;
    }

    /**
     * Check if we have a certain module
     */
    has(id) {
      return (!!this.modules[id]);
    }

    /**
     * Find a specific module
     */
    find(id) {
      return this.modules[id];
    }

    /**
     * Get singular form of a specific module
     */
    singular(id) {
      return this.prop(id, 'singular');
    }

    /**
     * Get plural form of a specific module
     */
    plural(id) {
      return this.prop(id, 'plural');
    }

    /**
     * Get title of a specific module
     */
    title(id) {
      return this.prop(id, 'title');
    }

    /**
     * Get a generic property of a specific module
     */
    prop(id, prop) {
      if (this.modules[id]) {
        return this.modules[id][prop];
      }
      return null;
    }
  }

  //Return instance
  return new Modules();
});
