
/**
 * Module definition and dependencies
 */
angular.module('Shared.TransactionTypes.Options', [])

/**
 * Constant definition
 */
.constant('TransactionTypes', [
  {
    value: 'accountCredit',
    label: 'Account credit',
  },
  {
    value: 'activity',
    label: 'Activity fee',
  },
  {
    value: 'booking',
    label: 'Booking fee',
  },
  {
    value: 'coupon',
    label: 'Coupon purchase',
  },
  {
    value: 'deposit',
    label: 'Deposit',
  },
  {
    value: 'event',
    label: 'Event entry fee',
  },
  {
    value: 'interclub',
    label: 'Interclub fee',
  },
  {
    value: 'subscription',
    label: 'Membership fee',
  },
  {
    value: 'purchase',
    label: 'Purchase',
  },
  {
    value: 'resource',
    label: 'Resource',
  },
  {
    value: 'other',
    label: 'Other',
  },
]);
