
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.CustomFields', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.customFields', {
    url: '/additional-details',
    component: 'registerCustomFieldsRoute',
  });
})

/**
 * Route component
 */
.component('registerCustomFieldsRoute', {
  controller: 'RegisterCustomFieldsCtrl',
  templateUrl: 'portal/register/steps/custom-fields.html',
  bindings: {
    club: '<',
    registration: '<',
    customFields: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterCustomFieldsCtrl', function(
  $controller, matchesMemberConstraint
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('RegisterBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get data
    const {customFields, registration} = this;
    const {members} = registration;

    //Set default values
    for (const field of customFields) {
      const {defaultValue, prop} = field;
      if (typeof defaultValue !== 'undefined') {
        for (const member of members) {
          if (this.isEligibleField(field, member)) {
            member.customFields[prop] = defaultValue;
          }
        }
      }
    }
  };

  /**
   * Update custom field
   */
  this.updateCustomField = function(member, property, value) {
    if (!member.customFields) {
      member.customFields = {};
    }
    member.customFields[property] = value;
  };

  /**
   * Update custom field
   */
  this.isEligibleField = function(field, member) {
    return matchesMemberConstraint(member, field);
  };
});
