
/**
 * Module definition and dependencies
 */
angular.module('Shared.Page.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('page', {
    model: 'CustomPage',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'pages',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('pages', {
    model: 'CustomPage',
    dataKey: 'pages',
  });
})

/**
 * Model definition
 * NOTE: Clashes with Page service name
 */
.factory('CustomPage', function($api, $baseModel, MembershipConstraints) {

  //Defaults
  const defaults = {
    title: '',
    icon: 'grade',
    contents: '',
    constraint: MembershipConstraints.ALL,
  };

  /**
   * Constructor
   */
  function Page(data) {
    $baseModel.call(this, angular.extend({}, defaults, data || {}));
  }

  /**
   * Extend base model
   */
  angular.extend(Page.prototype, $baseModel.prototype);

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Create
   */
  Page.prototype.create = function(data) {
    data = this.toJSON(data);
    return $api.page
      .create(data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  Page.prototype.patch = function(data) {
    const {id} = this;
    return $api.page
      .patch({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Page.prototype.delete = function() {
    return $api.page
      .delete(null, this)
      .then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Page.query = function(filter) {
    return $api.page.query(filter);
  };

  /**
   * Count
   */
  Page.count = function(filter) {
    return $api.page
      .count(filter)
      .then(data => data.total || 0);
  };

  /**
   * Find by ID
   */
  Page.findById = function(id) {
    return $api.page.get({id});
  };

  //Return
  return Page;
});
