
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.CantPurchase.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('cantPurchase', {
    templateUrl: 'subscription/modals/cant-purchase.html',
    controller: 'ModalCantRenewSubscriptionCtrl',
  });
});
