
/**
 * Module definition and dependencies
 */
angular.module('App.Home.ClubDetails.Card', [])

/**
 * Component
 */
.component('cardHomeClubDetails', {
  templateUrl: 'home/cards/club-details.html',
  controller: 'CardHomeClubDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
  },
})

/**
 * Controller
 */
.controller('CardHomeClubDetailsCtrl', function($modal, Text, TextTypes) {

  /**
   * Init
   */
  this.$onInit = function() {

    //Load T&C's
    this.loadTermsAndConditions();
  };

  /**
   * Load T&C's
   */
  this.loadTermsAndConditions = function() {
    Text
      .getText(TextTypes.TERMS_AND_CONDITIONS)
      .then(text => {
        this.termsAndConditions = text;
      });
  };

  /**
   * Show terms and conditions
   */
  this.showTermsAndConditions = function() {

    //No text
    if (!this.termsAndConditions) {
      return;
    }

    //Show modal
    $modal.open('showTerms', {
      locals: {
        title: 'Terms and Conditions',
        terms: this.termsAndConditions,
      },
    });
  };

  /**
   * Invite others
   */
  this.invite = function() {

    //Get data
    const {user, club} = this;

    //Open feedback modal and handle snooze reminder if resolved
    $modal.open('invite', {locals: {user, club}});
  };
});

