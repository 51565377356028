
/**
 * Module definition and dependencies
 */
angular.module('Shared.Tooltip.Directive', [])

/**
 * Directive
 */
.directive('tooltip', function($document) {
  const body = $document.find('body').eq(0);
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      let $tooltip;
      element.on('mousemove', event => {
        if (!attrs.tooltip) {
          return;
        }
        if (!$tooltip) {
          $tooltip = angular.element('<div></div>');
          $tooltip.addClass('Tooltip');
          $tooltip.text(attrs.tooltip);
          body.append($tooltip);
        }
        const width = $tooltip[0].offsetWidth;
        $tooltip.css({
          top: `${event.clientY}px`,
          left: `${event.clientX - (width / 2)}px`,
        });
      });
      element.on('mouseout', () => {
        if ($tooltip) {
          $tooltip.remove();
          $tooltip = null;
        }
      });
      scope.$on('$destroy', function() {
        if ($tooltip) {
          $tooltip.remove();
          $tooltip = null;
        }
      });
    },
  };
});
