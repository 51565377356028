
/**
 * Module definition and dependencies
 */
angular.module('Shared.TimeInput.Component', [])

/**
 * Time input component
 */
.component('timeInput', {
  template: `
    <div class="InputWrapper is-clickable" ng-click="$ctrl.showTimePicker()">
      <input class="Input Input--time {{$ctrl.inputClass}}" type="text" ng-model="$ctrl.timeFormatted" placeholder="..." readonly ng-disabled="$ctrl.isDisabled">
      <span class="Input-icon">
        <i class="Icon">schedule</i>
      </span>
    </div>
  `,
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    time: '<ngModel',
    onChange: '&',
    minTime: '<',
    maxTime: '<',
    zeroIsMidnight: '<',
    format: '<',
    inputClass: '@',
    isDisabled: '<ngDisabled',
  },

  /**
   * Directive controller
   */
  controller($attrs, $modal, $filter, moment) {

    //Helper vars
    let $ctrl = this;

    /**
     * On init
     */
    this.$onInit = function() {

      //If no time present, create one if needed
      if (!this.time && $attrs.currentTime) {
        this.time = moment().getTime();
      }

      //Empty check override in order for ng-required to work properly
      this.ngModel.$isEmpty = function() {
        return !$ctrl.timeFormatted;
      };

      //Add validators for min/max time
      this.ngModel.$validators.min = function(modelValue) {
        if (typeof $ctrl.minTime === 'number') {
          return (modelValue >= $ctrl.minTime);
        }
        return true;
      };
      this.ngModel.$validators.max = function(modelValue) {
        if (typeof $ctrl.maxTime === 'number') {
          return (modelValue <= $ctrl.maxTime);
        }
        return true;
      };
    };

    /**
     * On changes
     */
    this.$onChanges = function() {

      //If no max time is given, use midnight by default
      if (!this.maxTime) {
        this.maxTime = 1440;
      }

      //Update formatted time
      this.updateFormattedTime();

      //Reset validity
      this.ngModel.$validate();
    };

    /**
     * Show time picker
     */
    this.showTimePicker = function() {

      //Not when disabled
      if (this.isDisabled) {
        return;
      }

      //Get time
      const {time, format} = this;

      //Open modal
      $modal
        .open('pickTime', {locals: {time, format}})
        .result
        .then(time => {

          //Zero is midnight
          if (this.zeroIsMidnight && time === 0) {
            time = 1440;
          }

          //Set new values
          this.time = time;
          this.updateFormattedTime();

          //Mark as dirty
          this.ngModel.$setDirty();

          //Call onChange handler
          this.onChange({time});
        });
    };

    /**
     * Update formatted time
     */
    this.updateFormattedTime = function() {
      this.timeFormatted = $filter('time')(this.time);
    };
  },
});
