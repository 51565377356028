
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Login', [
  'App.Portal.Login.Controller',
  'Shared.PortalErrors.Component',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('login', {
    parent: 'portal',
    url: '/login',
    component: 'loginRoute',
    params: {
      error: null,
      provider: null,
      workspace: null,
      reason: null,
      squash: true,
    },
  });
})

/**
 * Route component
 */
.component('loginRoute', {
  controller: 'LoginCtrl',
  templateUrl: 'portal/login/login.html',
  bindings: {
    transition: '<',
    club: '<',
  },
})

/**
 * Run logic
 */
.run(function($transitions, $log, Auth, Page, Kiosk) {

  /**
   * Prevent going to login page if authenticated or if kiosk mode is enabled
   */
  $transitions.onBefore({to: 'login'}, transition => {

    //Already logged in
    if (Auth.isAuthenticated()) {
      $log.log(`Already logged in`);
      return transition.router.stateService.target('home');
    }

    //Kiosk enabled, only allow username/password login
    if (Kiosk.isEnabled()) {
      $log.log(`Not allowed in kiosk mode`);
      return transition.router.stateService.target('loginCredentials');
    }
  });

  /**
   * Success handler
   */
  $transitions.onSuccess({to: 'login'}, () => {
    Page.setTitle('Sign in');
  });
});
