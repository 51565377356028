
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Edit.Info.Card', [])

/**
 * Component
 */
.component('cardMembershipInfo', {
  templateUrl: 'admin/membership/cards/info.html',
  controller: 'CardMembershipInfoCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    membership: '<',
    activities: '<',
  },
})

/**
 * Controller
 */
.controller('CardMembershipInfoCtrl', function(Interface, $notice) {

  /**
   * Copy registration link to clipboard
   */
  this.copyLinkToClipboard = function() {
    const link = this.membership.makeRegistrationUrl(this.club.registrationUrl);
    Interface
      .copyToClipboard(link)
      .then(() => $notice.show(`Registration link copied to clipboard`));
  };
});
