
/**
 * Module definition and dependencies
 */
angular.module('Shared.Plans.Component', [])

/**
 * Plans component
 */
.component('plans', {
  template: `
  <div class="PlanColumns">
    <div class="PlanColumn">
      <h3>Entry</h3>
      <div class="PlanReasoning">
        <small>For small clubs wanting to modernise their member management</small>
      </div>
      <div class="PlanLimit">
        <span>100</span> members
      </div>
      <div class="PlanPrice">
        <small>{{$ctrl.currencyCode}}</small>
        {{$ctrl.planPrice('entry') | currency:$ctrl.currencySymbol}}
        <small ng-if="$ctrl.hasTax">+{{$ctrl.taxName}}</small>
        <small ng-if="!$ctrl.showYearly">per month</small>
        <small ng-if="$ctrl.showYearly">per year</small>
      </div>
      <div class="PlanButton">
        <button type="button" class="Button Button--s Button--success" ng-click="$ctrl.select('entry')">Choose</button>
      </div>
      <ul class="PlanList">
        <li>All basic features, plus</li>
        <li>100 members included</li>
        <li>1 sport or activity</li>
        <li>Up to 2 integrations</li>
        <li>3 months log visibility</li>
        <li>Live chat &amp; email support</li>
        <li>2,500 emails per month</li>
        <li>3 custom fields</li>
        <li>Exporting of data</li>
        <li>Branded login portal</li>
        <li>Dedicated subdomain</li>
      </ul>
    </div>
    <div class="PlanColumn">
      <h3>Standard</h3>
      <div class="PlanReasoning">
        <small>Take full advantage of our integrations and automate your processes</small>
      </div>
      <div class="PlanLimit">
        <span>300</span> members
      </div>
      <div class="PlanPrice">
        <small>{{$ctrl.currencyCode}}</small>
        {{$ctrl.planPrice('standard') | currency:$ctrl.currencySymbol}}
        <small ng-if="$ctrl.hasTax">+{{$ctrl.taxName}}</small>
        <small ng-if="!$ctrl.showYearly">per month</small>
        <small ng-if="$ctrl.showYearly">per year</small>
      </div>
      <div class="PlanButton">
        <button type="button" class="Button Button--s Button--success" ng-click="$ctrl.select('standard')">Choose</button>
      </div>
      <ul class="PlanList">
        <li>Everything in Entry, plus</li>
        <li>300 members included</li>
        <li>3 sports or activities</li>
        <li>Up to 5 integrations</li>
        <li>1 year log visibility</li>
        <li>Onboarding included</li>
        <li>Unlimited emails</li>
        <li>Unlimited custom fields</li>
        <li>Automated renewals</li>
        <li>Automated payments</li>
        <li>Public events</li>
        <li>Public calendar</li>
        <li>Grading module</li>
      </ul>
    </div>
    <div class="PlanColumn">
      <h3>Premium</h3>
      <div class="PlanReasoning">
        <small>A comprehensive member management solution with additional customisation</small>
      </div>
      <div class="PlanLimit">
        <span>1,000</span> members
      </div>
      <div class="PlanPrice">
        <small>{{$ctrl.currencyCode}}</small>
        {{$ctrl.planPrice('premium') | currency:$ctrl.currencySymbol}}
        <small ng-if="$ctrl.hasTax">+{{$ctrl.taxName}}</small>
        <small ng-if="!$ctrl.showYearly">per month</small>
        <small ng-if="$ctrl.showYearly">per year</small>
      </div>
      <div class="PlanButton">
        <button type="button" class="Button Button--s Button--success" ng-click="$ctrl.select('premium')">Choose</button>
      </div>
      <ul class="PlanList">
        <li>Everything in Standard, plus</li>
        <li>1,000 members included</li>
        <li>5 sports or activities</li>
        <li>Up to 10 integrations</li>
        <li>2 years log visibility</li>
        <li>Priority support</li>
        <li>Up to 3 custom pages</li>
        <li>Custom domain name</li>
        <li>Custom branding</li>
      </ul>
    </div>
    <div class="PlanColumn">
      <h3>Business</h3>
      <div class="PlanReasoning">
        <small>Advanced features at scale for businesses and large organisations</small>
      </div>
      <div class="PlanLimit">
        <span>2,500+</span> members
      </div>
      <div class="PlanPrice">
        <small>{{$ctrl.currencyCode}}</small>
        {{$ctrl.planPrice('business') | currency:$ctrl.currencySymbol}}
        <small ng-if="$ctrl.hasTax">+{{$ctrl.taxName}}</small>
        <small ng-if="!$ctrl.showYearly">per month</small>
        <small ng-if="$ctrl.showYearly">per year</small>
      </div>
      <div class="PlanButton">
        <button type="button" class="Button Button--s Button--success" ng-click="$ctrl.select('business')">Choose</button>
      </div>
      <ul class="PlanList">
        <li>Everything in Premium, plus</li>
        <li>2,500 members included</li>
        <li>10 sports or activities</li>
        <li>Unlimited integrations</li>
        <li>3 years log visibility</li>
        <li>Up to 7 custom pages</li>
        <li>API access</li>
      </ul>
    </div>
  </div>
  `,
  bindings: {
    plans: '<',
    currencyCode: '<',
    currencySymbol: '<',
    hasTax: '<',
    taxName: '<',
    isYearly: '<',
    showYearly: '<',
    onSelect: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Select plan
     */
    this.select = function(identifier) {
      this.onSelect({identifier});
    };

    /**
     * Get plan price
     */
    this.planPrice = function(identifier) {
      const plan = this.plans.find(plan => plan.identifier === identifier);
      const type = this.isYearly ? 'yearly' : 'monthly';
      const div = (this.isYearly && !this.showYearly) ? 12 : 1;
      if (plan) {
        return plan.pricing[type] / div;
      }
    };
  },
});
