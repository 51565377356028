
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Visitor.Details', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('visitor.details', {
    url: '/details',
    component: 'visitorDetailsRoute',
    params: {
      profile: null,
      squash: true,
    },
  });
})

/**
 * Route component
 */
.component('visitorDetailsRoute', {
  controller: 'VisitorDetailsCtrl',
  templateUrl: 'portal/visitor/steps/details.html',
  bindings: {
    club: '<',
    user: '<',
    visit: '<',
    isKiosk: '<',
    transition: '<',
  },
})

/**
 * Controller
 */
.controller('VisitorDetailsCtrl', function(
  $controller, moment, Auth, DateFormat
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('VisitorBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base
    $base.$onInit.call(this);

    //Determine next departure time
    const roundedUp = Math.ceil(moment().minute() / 15) * 15;
    const midnight = moment().add(1, 'day').startOf('day');
    const time = moment()
      .minute(roundedUp)
      .startOf('minute');

    //Exit dates
    this.departureDates = [];

    //Create time entries
    while (time.isBefore(midnight)) {
      this.departureDates.push({
        value: time.toJSON(),
        label: time.format(DateFormat.formats.timeOnly),
      });
      time.add(15, 'minutes');
    }

    //Add midnight entry
    this.departureDates.push({
      value: midnight.toJSON(),
      label: midnight.format(DateFormat.formats.timeOnly),
    });
  };

  /**
   * Toggle confirmation
   */
  this.toggleConfirmation = function(hasConfirmed) {
    this.hasConfirmed = hasConfirmed;
    this.isErrorMustConfirm = false;
  };

  /**
   * Validate
   */
  this.validate = function() {

    //Not confirmed?
    if (!this.hasConfirmed) {
      this.isErrorMustConfirm = true;
      return;
    }

    //Clear error and continue
    this.isErrorMustConfirm = false;

    //Parent validation
    return $base.validate.call(this);
  };

  /**
   * Logout
   */
  this.logout = function() {

    //Logout
    Auth.logout();
    Auth.setRedirectState('visitor.intro');
  };
});
