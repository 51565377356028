
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Circles.Overview.Controller', [])

/**
 * Controller
 */
.controller('AdminCirclesOverviewCtrl', function(
  $filter, $modal, $notice, ScrollPosition,
  Pagination, Settings, Intercom, Circle
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Trigger intercom event
    Intercom.event('Viewed circles');

    //Initialize
    this.isLoading = true;
    this.isAdmin = this.user.isAdmin();

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load circles
    this.loadPage();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter} = this;

    //Set filter defaults
    filter.setDefaults({
      search: '',
      member: null,
    });

    //Filter on change handler
    filter.onChange(() => {

      //Reload first page
      this.loadPage(1);
    });
  };

  /**
   * Load circles
   */
  this.loadCircles = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query members
    return Circle
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, circles} = data;

    //Set in scope
    this.circles = circles;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadCircles()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };

  /**
   * Invite to circle
   */
  this.invite = function($event) {

    //Get data and define handler
    const {circle} = $event;
    const {member} = this;
    const asAdmin = true;

    //Show modal
    $modal
      .open('inviteCircleMembers', {
        locals: {circle, member, asAdmin},
      })
      .result
      .then(() => {
        this.loadCircles();
      });
  };

  /**
   * Edit circle
   */
  this.edit = function($event) {

    //Get data and define handler
    const {circle} = $event;
    const isEdit = true;
    const asAdmin = true;
    const handler = data => circle.save(data);

    //Show modal
    $modal
      .open('editCircle', {
        locals: {circle, asAdmin, isEdit, handler},
      })
      .result
      .then(() => {
        $notice.show('Circle updated');
        this.loadCircles();
      });
  };

  /**
   * Delete circle
   */
  this.delete = function($event) {

    //Get data and define handler
    const {circle} = $event;
    const handler = () => circle.delete();

    //Open confirmation dialog
    $modal
    .open('basic', {
      templateUrl: 'admin/people/circles/modals/confirm-delete-circle.html',
      locals: {circle, handler},
    })
    .result
    .then(() => {
      $notice.show(`Circle removed`);
      this.loadCircles();
    });
  };

  /**
   * View member
   */
  this.viewMember = function(member, circle, isInvited) {

    //Show modal
    $modal
    .open('viewCircleMember', {
      locals: {circle, member, asAdmin: true, isInvited},
    });
  };
});
