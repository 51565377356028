
/**
 * Module definition and dependencies
 */
angular.module('Shared.AllowedModes.Options', [])

/**
 * Constant definition
 */
.constant('AllowedModes', [
  {
    value: false,
    label: 'As defined by activity restrictions',
  },
  {
    value: true,
    label: 'Specific for this member',
  },
]);
