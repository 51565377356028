
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Edit.Notes.Card', [])

/**
 * Component
 */
.component('cardEventEditNotes', {
  templateUrl: 'cards/notes.html',
  controller: 'CardEventEditNotesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    item: '<',
    itemType: '@',
    isEdit: '<',
    onSave: '&',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventEditNotesCtrl', function(
  $controller, $modal, CardCloseReasons
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardNotesCtrl');

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Save
   */
  this.save = function(method) {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get data and prepare event
    const {item: event, model, isEdit} = this;
    const {isMultiDay, isRecurring} = event;
    const {startDate} = model;
    const $event = {event, model, method, isEdit};

    //Confirm how to update if this was a multiday or recurring event
    if (isEdit && (isMultiDay || isRecurring) && !method) {
      return $modal
        .open('confirmUpdateEvent', {locals: {event, startDate}})
        .result
        .then(method => this.save(method));
    }

    //Mark as saving
    this.isSaving = true;

    //Persist
    return this
      .onSave({$event})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
