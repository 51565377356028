
/**
 * Module definition and dependencies
 */
angular.module('App.Auth.Token.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config($apiProvider => {

  //Register token endpoint
  $apiProvider.registerEndpoint('auth', {
    url: 'auth',
    actions: {
      token: {
        url: 'token',
        method: 'POST',
        model: false,
        ignore401Intercept: true,
        withCredentials: true, //NOTE: for cross domain refresh token cookie
      },
      revoke: {
        url: 'revoke',
        method: 'POST',
        model: false,
        ignore401Intercept: true,
        withCredentials: true, //NOTE: for cross domain refresh token cookie
      },
      revokeAll: {
        url: 'revoke/all',
        method: 'POST',
        model: false,
        ignore401Intercept: true,
        withCredentials: true, //NOTE: for cross domain refresh token cookie
      },
    },
  });
})

/**
 * Provider definition
 */
.factory('Token', function(
  $q, $api, $convert, $baseModel, $storage, moment, Config
) {

  //Config
  const {clientId} = Config.auth;

  //Token promise
  let tokenPromise = null;

  /**
   * Constructor
   */
  function Token(accessToken) {

    //Get payload
    const payload = Token.decode(accessToken);
    if (!payload) {
      return;
    }

    //Remember raw access token string
    this.accessToken = accessToken;

    //Call parent constructor
    $baseModel.call(this, payload);
  }

  //Extend base model
  angular.extend(Token.prototype, $baseModel.prototype);

  /**
   * From JSON
   */
  Token.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse expiry
    this.exp = moment.unix(this.exp);

    //Return self
    return this;
  };

  /**
   * Check if the token is expired
   */
  Token.prototype.isExpired = function() {
    const now = moment();
    return (this.exp && this.exp.isBefore(now));
  };

  /**
   * Check if the token is expiring
   */
  Token.prototype.isExpiring = function(offset) {
    const reference = moment().add(offset, 'seconds');
    return (this.exp && this.exp.isBefore(reference));
  };

  /**
   * Check how long ago the token expired
   */
  Token.prototype.getExpiredTime = function() {
    const now = moment();
    if (this.exp && this.exp.isBefore(now)) {
      return now.diff(this.exp, 'seconds');
    }
    return null;
  };

  /**
   * Check if the token recently expired
   */
  Token.prototype.hasRecentlyExpired = function() {
    const time = this.getExpiredTime();
    return (time !== null && time < 7200);
  };

  /**************************************************************************
   * Storage handling
   ***/

  /**
   * Store access token
   */
  Token.putInStorage = function(accessToken) {
    const existing = Token.readFromStorage();
    if (existing !== accessToken) {
      $storage.local.set('auth.accessToken', accessToken);
    }
    return accessToken;
  };

  /**
   * Read access token from storage
   */
  Token.readFromStorage = function() {
    return $storage.local.get('auth.accessToken', '');
  };

  /**
   * Clear access token from storage
   */
  Token.clearFromStorage = function() {
    $storage.local.remove('auth.accessToken');
  };

  /**************************************************************************
   * Decoder
   ***/

  /**
   * Decode token and get payload
   */
  Token.decode = function(accessToken) {

    //Nothing?
    if (!accessToken) {
      return null;
    }

    //Split in parts
    const parts = accessToken.split('.');
    if (parts.length !== 3) {
      return null;
    }

    //Get decoded payload
    try {
      const decoded = $convert.string.fromBase64(parts[1]);
      return angular.fromJson(decoded);
    }
    catch (e) {
      return null;
    }
  };

  /**************************************************************************
   * Helpers to obtain tokens
   ***/

  /**
   * Get existing token from local storage
   */
  Token.existing = function() {
    const token = Token.readFromStorage();
    if (!token) {
      return null;
    }
    return new Token(token);
  };

  /**
   * Use a given token
   */
  Token.use = function(token) {
    if (!token) {
      return $q.reject(new Error('No token'));
    }
    Token.putInStorage(token);
    return $q.resolve(new Token(token));
  };

  /**
   * Obtain token from server
   */
  Token.obtain = function(grantType, data) {

    //Already obtaining a token?
    if (tokenPromise) {
      return tokenPromise;
    }

    //Extend data
    data = data || {};
    angular.extend(data, {clientId, grantType});

    //Get token from server
    return tokenPromise = $api.auth
      .token(data)
      .then(auth => auth.access_token || auth.accessToken)
      .then(token => {

        //Must have token
        if (!token) {
          throw new Error('No token');
        }

        //Store token
        Token.putInStorage(token);

        //Return new instance
        return new Token(token);
      })
      .finally(() => tokenPromise = null);
  };

  //Return
  return Token;
});
