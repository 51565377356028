
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Log.Audit', [
  'App.Admin.Log.Audit.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.log.audit', {
    url: '/audit/:model/:id',
    params: {
      model: {
        value: null,
        squash: true,
      },
      id: {
        value: null,
        squash: true,
      },
    },
    component: 'adminLogAuditRoute',
    data: {
      id: 'auditLogs',
    },
    resolve: {
      filter(Filters) {
        return Filters.get('auditLogs');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminLogAuditRoute', {
  controller: 'AdminLogAuditCtrl',
  templateUrl: 'admin/log/audit/audit.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    filter: '<',
    page: '<',
    transition: '<',
  },
});
