
/**
 * Module definition and dependencies
 */
angular.module('Shared.Broadcast.Service', [])

/**
 * Service definition
 */
.factory('Broadcast', function() {
  class Broadcast {

    /**
     * Constructor
     */
    constructor(channel) {

      //Set up channel
      try {
        this.channel = new BroadcastChannel(channel);
      }
      catch (error) {
        //Not supported
      }
    }

    /**
     * Send
     */
    send(data) {

      //Only if we have a channel
      if (!this.channel) {
        return;
      }

      //Parse data
      data = JSON.parse(JSON.stringify(data));

      //Post message
      this.channel.postMessage(data);
    }

    /**
     * Receive
     */
    receive(handler) {

      //Only if we have a channel
      if (!this.channel) {
        return;
      }

      //Bind handler
      this.channel.onmessage = event => {
        handler(event, this.channel);
      };
    }
  }

  //Return class
  return Broadcast;
});
