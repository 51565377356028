
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.ForgotUsername', [
  'App.Portal.ForgotUsername.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('forgotUsername', {
    parent: 'portal',
    url: '/forgot/username',
    component: 'forgotUsernameRoute',
  });
})

/**
 * Route component
 */
.component('forgotUsernameRoute', {
  controller: 'ForgotUsernameCtrl',
  templateUrl: 'portal/forgot-username/forgot-username.html',
  bindings: {
    club: '<',
  },
});
