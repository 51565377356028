
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Circles.CircleOptions.Component', [])

/**
 * Circles options component
 */
.component('circleOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="$ctrl.canManagePermissions">
        <a ng-click="$ctrl.managePermissions()">My permissions</a>
      </li>
      <li ng-if="$ctrl.canInvite">
        <a ng-click="$ctrl.invite()">Invite</a>
      </li>
      <li ng-if="$ctrl.canEdit">
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li ng-if="$ctrl.canDelete" class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-menu>
  `,
  bindings: {
    circle: '<',
    canInvite: '<',
    canManagePermissions: '<',
    canEdit: '<',
    canDelete: '<',
    onManagePermissions: '&',
    onInvite: '&',
    onEdit: '&',
    onDelete: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Manager own permissions
     */
    this.managePermissions = function() {
      const {circle} = this;
      this.onManagePermissions({$event: {circle}});
    };

    /**
     * Invite member
     */
    this.invite = function() {
      const {circle} = this;
      this.onInvite({$event: {circle}});
    };

    /**
     * Edit member
     */
    this.edit = function() {
      const {circle} = this;
      this.onEdit({$event: {circle}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      const {circle} = this;
      this.onDelete({$event: {circle}});
    };
  },
});
