
/**
 * Module definition and dependencies
 */
angular.module('Shared.Club.Model', [
  'BaseModel.Service',
  'Shared.Club.Billing.Model',
  'Shared.Club.JoiningFee.Model',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('club', {
    model: 'Club',
    url: 'club',
    params: null,
    actions: {
      get: {
        url: 'own',
        method: 'GET',
        isModel: true,
        withCredentials: true,
      },
      contact: {
        url: 'own/contact',
        method: 'POST',
      },
      invite: {
        url: 'own/invite',
        method: 'POST',
      },
      patch: {
        url: 'own',
        method: 'PATCH',
      },
      patchModule: {
        url: 'own/module/:id',
        method: 'PATCH',
      },
      agreeToTermsOfService: {
        url: 'own/agreeToTermsOfService',
        method: 'PATCH',
      },
      enableKioskMode: {
        url: 'own/enableKioskMode',
        method: 'PUT',
        withCredentials: true,
      },
      disableKioskMode: {
        url: 'own/disableKioskMode',
        method: 'PUT',
        withCredentials: true,
      },
      deleteLogo: {
        url: 'own/logo',
        method: 'DELETE',
      },
      deleteBackground: {
        url: 'own/background',
        method: 'DELETE',
      },
      deleteLogoHeader: {
        url: 'own/logoHeader',
        method: 'DELETE',
      },

      //Setup routes
      inviteAdmins: {
        url: 'own/setup/inviteAdmins',
        method: 'POST',
      },
      updateSetupStep: {
        url: 'own/setup/:step/:state',
        method: 'PATCH',
      },
      removeDemoData: {
        url: 'own/setup/demoData',
        method: 'DELETE',
      },

      //Billing routes
      extendTrial: {
        url: 'own/billing/extendTrial',
        method: 'PUT',
      },
      checkPromoCode: {
        url: 'own/billing/promoCode',
        method: 'GET',
      },
      createBillingSetupIntent: {
        url: 'own/billing/setupIntent',
        method: 'POST',
      },
      changePlan: {
        url: 'own/billing/changePlan',
        method: 'POST',
      },

      //Signup routes
      initiate: {
        url: 'signUp/initiate',
        method: 'POST',
      },
      create: {
        url: 'signUp',
        method: 'POST',
      },
      checkExists: {
        url: 'signUp/checkExists',
        method: 'POST',
      },
      checkCreated: {
        url: 'signUp/checkCreated/:id',
        method: 'GET',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('club', {
    model: 'Club',
    service: '$instanceStore',
  });
})

/**
 * Model definition
 */
.factory('Club', function(
  $api, $baseModel, $q, $modal, $state, $storage, $window,
  moment, Config, Settings, Confetti, Modules, DateFormat,
  MembershipConstraints
) {

  //Get constraints
  const {
    ALL, WITH, WITHOUT, SPECIFIC, SPECIFIC_WITHOUT,
  } = MembershipConstraints;

  /**
   * Constructor
   */
  function Club(data) {

    //Call base model constructor
    $baseModel.call(this, data);

    //Check if have social media
    Object.defineProperty(this, 'hasSocialMedia', {
      get() {
        if (!this.socialMedia) {
          return false;
        }
        return Object
          .values(this.socialMedia)
          .join('').length > 0;
      },
    });

    //Tax name property
    Object.defineProperty(this, 'taxName', {
      get() {
        if (this.countryCode === 'NZ' || this.countryCode === 'AU') {
          return 'GST';
        }
        return 'VAT';
      },
    });

    //Club hostname
    Object.defineProperty(this, 'hostname', {
      get() {

        //Get data
        const {subdomain, customDomain} = this;
        const {generic, base} = Config.domains;

        //Custom domain
        if (customDomain) {
          return customDomain;
        }
        else if (subdomain) {
          return `${subdomain}.${base}`;
        }
        return generic;
      },
    });

    //Check if we have either a custom or subdomain
    Object.defineProperty(this, 'hasDomain', {
      get() {
        return (this.customDomain || this.subdomain);
      },
    });

    //Default source
    Object.defineProperty(this, 'defaultSource', {
      get() {
        const {stripe} = this;
        if (!stripe || !stripe.sources) {
          return null;
        }
        return stripe.sources.find(s => s.isDefault);
      },
    });

    //Days left on trial
    Object.defineProperty(this, 'trialDaysLeft', {
      get() {
        const {trialEndDate} = this;
        if (!trialEndDate || moment().isAfter(trialEndDate)) {
          return 0;
        }

        //Calculate days left
        return -1 * moment().diff(trialEndDate, 'days');
      },
    });

    //Time left on trial
    Object.defineProperty(this, 'trialTimeLeft', {
      get() {
        const {trialEndDate} = this;
        if (!trialEndDate || moment().isAfter(trialEndDate)) {
          return 'no time';
        }

        //Parse
        const minutes = -1 * moment().diff(trialEndDate, 'minutes');
        const days = Math.floor(minutes / 1440);
        const hours = Math.floor(minutes / 60);

        //Return appropriate time frame
        if (days > 0) {
          return `${days} day${days > 1 ? 's' : ''}`;
        }
        else if (hours > 0) {
          return `${hours} hour${hours > 1 ? 's' : ''}`;
        }
        return `${minutes} minute${minutes > 1 ? 's' : ''}`;
      },
    });

    /**
     * Registration URL
     */
    Object.defineProperty(this, 'registrationUrl', {
      get() {
        return this.makeUrl('register');
      },
    });

    /**
     * Visitor log URL
     */
    Object.defineProperty(this, 'visitorLogUrl', {
      get() {
        return this.makeUrl('visitor');
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Club.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Club.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('billing', 'ClubBilling');
    this.convertToModel('address', 'Address');
    this.convertToModel('joiningFees', 'JoiningFee', true);
    this.convertToModel('termsOfServiceAgreements', null, true);

    //Return self
    return this;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Make url helper
   */
  Club.prototype.makeUrl = function(path) {
    const {appUrl, hasDomain, identifier} = this;
    if (path && path[0] === '/') {
      path = path.substring(1);
    }
    if (hasDomain) {
      return `${appUrl}/${path}`;
    }
    return `${appUrl}/${path}?club=${identifier}`;
  };

  /**
   * Get max booking date (or null if not restricted)
   */
  Club.prototype.getMaxBookingDate = function(user, timezone) {

    //Determine reference date. If we're past 7am, we allow bookings for the
    //next period. This is to prevent people from staying up till midnight to
    //catch the next booking day, for popular clubs.
    const ref = moment().tz(timezone);
    const hour = ref.hour();
    if (hour < 7) {
      ref.subtract(1, 'day');
    }

    //Check if we have the setting. If not, return a default of 1 week
    const maxAhead = Settings.get('booking.maxAhead');
    if (!maxAhead) {
      return ref.add(1, 'week');
    }

    //Determine type and get setting value
    const type = user ? user.getBookAheadType() : 'casual';
    const setting = maxAhead[type];

    //No setting for this type or zero amount? Return a default value of 1 week
    if (!setting || setting.amount === 0) {
      return ref.add(1, 'week');
    }

    //Create moment
    return ref.add(setting.amount, setting.unit);
  };

  /**
   * Find applicable joining fees
   */
  Club.prototype.findJoiningFees = function(member) {

    //Extract info from member object
    const {age, address, memberships} = member;

    //Find eligible fees
    const fees = this.joiningFees
      .filter(fee => fee.appliesToMemberships(memberships))
      .filter(fee => fee.appliesToAge(age))
      .filter(fee => fee.appliesToAddress(address));

    //No fees
    if (fees.length === 0) {
      return [];
    }

    //Now map them by label
    const map = fees.reduce((map, fee) => {
      const {label} = fee;
      if (!map.has(label)) {
        map.set(label, []);
      }
      map.get(label).push(fee);
      return map;
    }, new Map());

    //Get grouped array
    const grouped = Array.from(map.values());

    //Get the best from each type
    return grouped
      .map(fees => this.findBestJoiningFee(fees))
      .filter(fee => fee && fee.amount > 0);
  };

  /**
   * Find best joining fee in a given set
   */
  Club.prototype.findBestJoiningFee = function(fees) {

    //Specificity
    const specificity = [
      [SPECIFIC, SPECIFIC_WITHOUT, WITHOUT],
      [WITH],
      [ALL],
    ];

    //Process in order of specificity
    for (const constraints of specificity) {

      //Find candidate fees
      const candidates = fees
        .filter(fee => constraints.includes(fee.constraint));

      //No candidates found? Check next constraint
      if (candidates.length === 0) {
        continue;
      }

      //Find the best fee of the lot
      return candidates.reduce((best, fee) => {
        if (!best || fee.amount < best.amount) {
          return fee;
        }
        return best;
      }, null);
    }

    //No fee
    return null;
  };

  /**************************************************************************
   * API instance methods
   ***/

  /**
   * Invite someone to join the club
   */
  Club.prototype.invite = function(data) {
    return $api.club.invite(data);
  };

  /**
   * Initiate club sign up
   */
  Club.prototype.initiate = function(isConfirmed) {

    //Get data
    const {isHc, isDirectToFrello, isDirect, contact} = this;
    const {email, username} = contact;

    //Initiate
    return $api.club.initiate({
      isHc,
      isDirect,
      isDirectToFrello,
      isConfirmed,
      contact: {
        email,
        username: username || email,
      },
    });
  };

  /**
   * Check club exists
   */
  Club.prototype.checkExists = function(isConfirmed) {

    //Get data
    const {isHc, isDirectToFrello, name, email, country} = this;

    //Initiate
    return $api.club.checkExists({
      name, email, country, isHc, isDirectToFrello, isConfirmed,
    });
  };

  /**
   * Create club
   */
  Club.prototype.create = function() {

    //Prepare data
    const data = this.toJSON();

    //Remove unneeded data
    delete data.plan;
    delete data.billing;
    delete data.address;
    delete data.modules;
    delete data.joiningFees;
    delete data.termsOfServiceAgreements;

    //Create club data
    return $api.club
      .create(data)
      .then(clubData => this.clubDataId = clubData.id);
  };

  /**
   * Patch
   */
  Club.prototype.patch = function(data, params) {
    return $api.club
      .patch(params, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Contact club
   */
  Club.prototype.contact = function(data) {
    return $api.club
      .contact(data);
  };

  /**
   * Mark step as complete
   */
  Club.prototype.updateSetupStep = function(step, state) {

    //No setup steps
    if (!this.setup.steps) {
      return $q.resolve(false);
    }

    //Check if valid step and if not already in that state
    const {steps} = this.setup;
    if (!steps[step] || steps[step][state] === true) {
      return $q.resolve(false);
    }

    //Make call
    return $api.club
      .updateSetupStep({step, state}, {})
      .then(data => this.fromJSON(data))
      .then(() => true)
      .catch(() => false);
  };

  /**
   * Check if step is complete
   */
  Club.prototype.isStepComplete = function(step) {
    return this.setup.steps[step].isComplete;
  };

  /**
   * Mark step as complete
   */
  Club.prototype.markStepSkipped = function(step) {
    return this.updateSetupStep(step, 'isSkipped');
  };

  /**
   * Mark step as complete
   */
  Club.prototype.markStepComplete = function(step, showModal = false) {

    //Check if doable
    if (!this.setup || !this.setup.steps[step]) {
      return $q.resolve();
    }

    //Get group
    const {group} = this.setup.steps[step];

    //Mark step as complete
    return this
      .updateSetupStep(step, 'isComplete')
      .then(wasCompleted => {

        //Show confetti and modal
        if (wasCompleted && showModal && !$storage.get('skipSetupPrompt')) {

          //Fire up some confetti
          Confetti.fire();

          //Show modal
          return $modal
            .open('basic', {
              templateUrl: 'admin/setup/modals/continue-setup.html',
            })
            .result
            .then(outcome => {
              if (outcome === 'dismiss') {
                $storage.set('skipSetupPrompt', true);
              }
              else {
                $state.go('admin.setup', {openCard: group});
              }
            });
        }
      });
  };

  /**
   * Patch module
   */
  Club.prototype.patchModule = function(id, data) {
    return $api.club
      .patchModule({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Mark demo data steps as complete
   */
  Club.prototype.markDemoDataStepsComplete = function() {
    return $q.all([
      this.markStepComplete('removeDemoData'),
      this.markStepComplete('exploreMembers'),
      this.markStepComplete('exploreMemberships'),
      this.markStepComplete('exploreFinance'),
      this.markStepComplete('exploreReports'),
      this.markStepComplete('exploreHome'),
    ]);
  };

  /**
   * Enable kiosk mode
   */
  Club.prototype.enableKioskMode = function() {
    return $api.club
      .enableKioskMode();
  };

  /**
   * Disable kiosk mode
   */
  Club.prototype.disableKioskMode = function() {
    return $api.club
      .disableKioskMode();
  };

  /**
   * Invite admins
   */
  Club.prototype.inviteAdmins = function(admins) {
    return $api.club
      .inviteAdmins({admins});
  };

  /**
   * Extend trial
   */
  Club.prototype.extendTrial = function() {
    return $api.club
      .extendTrial()
      .then(data => this.fromJSON(data));
  };

  /**
   * Check promo code for billing
   */
  Club.prototype.checkPromoCode = function(code) {
    return $api.club
      .checkPromoCode({code});
  };

  /**
   * Agree to app terms of service
   */
  Club.prototype.agreeToTermsOfService = function(hasAgreed) {
    return $api.club
      .agreeToTermsOfService({hasAgreed});
  };

  /**
   * Delete mock data
   */
  Club.prototype.removeDemoData = function() {
    return $api.club
      .removeDemoData()
      .then(() => this.hasDemoData = false)
      .then(() => this.markDemoDataStepsComplete())
      .finally(() => $window.location.reload());
  };

  /**
   * Delete logo
   */
  Club.prototype.deleteLogo = function() {
    return $api.club
      .deleteLogo()
      .then(() => this.logo = null);
  };

  /**
   * Delete background
   */
  Club.prototype.deleteBackground = function() {
    return $api.club
      .deleteBackground()
      .then(() => this.background = null);
  };

  /**
   * Delete logo header
   */
  Club.prototype.deleteLogoHeader = function() {
    return $api.club
      .deleteLogoHeader()
      .then(() => this.logoHeader = null);
  };

  /**
   * Create billing setup intent
   */
  Club.prototype.createBillingSetupIntent = function() {
    return $api.club
      .createBillingSetupIntent();
  };

  /**
   * Change plan
   */
  Club.prototype.changePlan = function(data) {
    return $api.club
      .changePlan(data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Check if club data with certain ID was created
   */
  Club.prototype.checkCreated = function() {

    //Only if there is an ID
    const id = this.clubDataId;
    if (!id) {
      throw new Error(`No club data ID`);
    }

    //Check if created
    return $api.club.checkCreated({id});
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Get club instance
   */
  Club.get = function() {
    return $api.club
      .get()
      .then(club => {

        //Check if club is migrating to Frello & show modal
        if (club && club.isMigratingToFrello) {
          $modal.open('clubFrozen', {locals: {club}});
        }

        //NOTE: This needs to be here for now, because the app hook that runs
        //to check if a user can see a route depends on the modules, and needs
        //to know if modules have been disabled or not. So we can't wait for the
        //app controller to initialise this, because that's too late. Will want
        //to refactor this nicely somehow in Vue with better guards/methods of
        //loading the modules, perhaps in a separate route.
        if (club) {
          Modules.set(club.modules);
          DateFormat.set(
            club.settings.general.dateFormat,
            club.settings.general.timeFormat
          );
        }

        //Pass on club
        return club;
      });
  };

  //Return
  return Club;
});
