
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.CheckIn', [
  'App.Portal.CheckIn.Controller',
])

/**
 * Config
 */
.config(($stateProvider) => {

  //State definition
  $stateProvider.state('checkIn', {
    parent: 'portal',
    url: '/check-in',
    component: 'checkInRoute',
  });
})

/**
 * Route component
 */
.component('checkInRoute', {
  controller: 'CheckInCtrl',
  templateUrl: 'portal/check-in/check-in.html',
  bindings: {
    transition: '<',
    club: '<',
  },
});
