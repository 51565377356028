
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Staff.Add', [
  'App.Admin.People.Staff.Add.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.staff.add', {
    url: '/add',
    component: 'adminStaffAddRoute',
    data: {
      roles: ['admin'],
    },
    resolve: {
      member(Member) {
        return new Member();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminStaffAddRoute', {
  controller: 'AdminStaffAddCtrl',
  templateUrl: 'admin/people/staff/add/add.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    member: '<',
    activities: '<',
    integrations: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.people.staff.add'}, () => {
    Page.setTitle(`Add staff member`);
    Page.addCrumb({sref: 'admin.people.staff.add'});
  });
});
