
/**
 * Module definition and dependencies
 */
angular.module('Shared.MemberPermissions.Component', [])

/**
 * Fee example component
 */
.component('memberPermissions', {
  template: `
    <ul class="List margin-top">
      <li>Update their profile and personal details</li>
      <li ng-if="!$ctrl.isMember">Perform any administrative tasks they have permissions for</li>
      <li ng-if="modules.bookings && $ctrl.isMember">Make bookings</li>
      <li ng-if="modules.events && $ctrl.isMember">Sign up for events or classes</li>
      <li ng-if="modules.coupons && $ctrl.isMember">Purchase {{modules.coupons.plural}}</li>
      <li ng-if="modules.memberships && !$ctrl.isSuspension && $ctrl.isMember">Renew their membership subscriptions</li>
      <li ng-if="!$ctrl.isSuspension && $ctrl.isMember">Make online payments</li>
      <li ng-if="modules.system">Use their tags</li>
    </ul>
  `,
  bindings: {
    isMember: '<',
    isSuspension: '<',
  },
});
