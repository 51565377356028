
/**
 * Module definition and dependencies
 */
angular.module('Shared.AreaState.Component', [])

/**
 * Area state component
 */
.component('areaState', {
  template: `<span class="{{$ctrl.classes}}">{{$ctrl.label}}</span>`,
  bindings: {
    state: '<',
    mode: '<',
    activities: '<',
    showUnknown: '<',
  },
  controller(AreaStates) {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Empty
      if (this.state === AreaStates.EMPTY) {
        return this.set('Empty', '');
      }

      //Error/offline
      if (this.state === AreaStates.ERROR) {
        return this.set('Offline', 'text-danger');
      }

      //Override
      if (this.state === AreaStates.OVERRIDE) {
        return this.set('Override', 'text-danger');
      }

      //Warm-up
      if (this.state === AreaStates.WARM_UP) {
        return this.set('Warm-up', 'text-warning');
      }

      //Event
      if (this.state === AreaStates.EVENT) {
        return this.set('Event', 'text-warning');
      }

      //Mode
      if (this.state === AreaStates.MODE) {
        const mode = this.findMode(this.mode);
        if (mode) {
          return this.set(mode.name, 'text-success');
        }
        return this.set('In use', 'text-success');
      }

      //Unknown
      this.setUnknown();
    };

    /**
     * Find mode in activities
     */
    this.findMode = function(id) {
      if (!this.activities) {
        return null;
      }
      for (const activity of this.activities) {
        for (const mode of activity.modes) {
          if (mode.id === id) {
            return mode;
          }
        }
      }
    };

    /**
     * Set unknown
     */
    this.setUnknown = function() {
      if (this.showUnknown) {
        this.set('Unknown', 'text-muted');
      }
      else {
        this.set('', '');
      }
    };

    /**
     * Set generic
     */
    this.set = function(label, classes) {
      this.label = label;
      this.classes = classes;
    };
  },
});
