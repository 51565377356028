
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Add.Usage.Card', [])

/**
 * Component
 */
.component('cardCouponTypeAddUsage', {
  templateUrl: 'admin/coupon-type/cards/usage.html',
  controller: 'CardCouponTypeAddUsageCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    couponType: '<',
    activities: '<',
    onSave: '&',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardCouponTypeAddUsageCtrl', function(
  $controller, CouponTypeTypes
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardCouponTypeEditUsageCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark flags
    this.isAdd = true;
    this.isEdit = false;

    //Default type
    if (!this.model.type) {
      this.setType(CouponTypeTypes.SESSION_BASED);
    }
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {couponType, model} = this;
    this.onMerge({$event: {couponType, model}});
    this.form.$setPristine();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previousTab();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next tab
    this.merge();
    this.card.nextTab();
  };
});
