
/**
 * Module definition and dependencies
 */
angular.module('App.Home.ActionButton.Component', [])

/**
 * Alert component
 */
.component('actionButton', {
  template: `
    <a ng-if="$ctrl.isValid" class="Button Button--block" ng-style="{background: $ctrl.color}" ui-sref="{{$ctrl.sref || '-'}}" ng-click="$ctrl.onClick()">
      <i class="Icon">{{$ctrl.icon}}</i> {{$ctrl.title}}
    </a>
  `,
  bindings: {
    button: '<',
    pages: '<',
    defaults: '<',
    hasBranding: '<',
    onAction: '&',
  },

  /**
   * Controller
   */
  controller(Modules) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Get button config
      const {hasBranding, defaults} = this;
      const {option, pageId, color} = this.button;

      //Set color and invalidate initially
      this.color = (color && hasBranding) ? color : defaults.color;
      this.isValid = false;

      //Set option
      this.setOption(option, pageId);

      //Set default if not valid
      if (!this.isValid) {
        this.setOption(defaults.option);
      }
    };

    /**
     * Set option
     */
    this.setOption = function(option, pageId) {

      //Page option
      if (option === 'page') {
        this.setPage(pageId);
        return;
      }

      //Feedback option
      if (option === 'feedback') {
        this.setFeedback();
        return;
      }

      //Invite option
      if (option === 'invite') {
        this.setInvite();
        return;
      }

      //Set module
      this.setModule(option);
    };

    /**
     * Set module
     */
    this.setModule = function(moduleId) {

      //Find module
      const module = Modules.find(moduleId);
      if (!module) {
        return;
      }

      //Set properties
      this.isValid = true;
      this.title = module.title;
      this.icon = module.icon;
      this.sref = module.sref;
    };

    /**
     * Set page
     */
    this.setPage = function(pageId) {

      //Find page
      const page = this.pages.find(page => page.id === pageId);
      if (!page) {
        return;
      }

      //Set properties
      this.isValid = true;
      this.title = page.title;
      this.icon = page.icon;
      this.sref = `page({pageId: '${page.id}', slug: '${page.slug}'})`;
    };

    /**
     * Set feedback action
     */
    this.setFeedback = function() {
      this.isValid = true;
      this.action = 'feedback';
      this.title = 'Feedback';
      this.icon = 'campaign';
    };

    /**
     * Set invite action
     */
    this.setInvite = function() {
      this.isValid = true;
      this.action = 'invite';
      this.title = 'Invite a friend';
      this.icon = 'person_add';
    };

    /**
     * Click handler
     */
    this.onClick = function() {
      const {sref, action} = this;
      if (sref) {
        return;
      }
      this.onAction({action});
    };
  },
});
