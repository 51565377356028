
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Controller', [])

/**
 * Base controller
 */
.controller('AdminMemberCtrl', function(
  $q, $store, $modal, $notice, Intercom, Member, Tag,
  Payment, ReplacementTags
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Load custom fields in the background
    this.loadCustomFields();
  };

  /**
   * Merge
   */
  this.merge = function($event) {

    //Get member and model
    const {member, model} = $event;
    this.isDirty = true;

    //Merge
    member.merge(model);
    return $q.resolve();
  };

  /**
   * Patch
   */
  this.patch = function($event) {

    //Get model
    const {member, model} = $event;
    const {module: {singular}} = this;

    //Patch
    return member
      .patch(model)
      .then(() => {
        $notice.show(`${singular} updated`);
        this.onPatched({member});
      })
      .catch(error => {
        $notice.showError(`Failed to update ${singular}`);
        throw error;
      });
  };

  /**
   * Save
   */
  this.save = function($event) {

    //Get data
    const {member, model, isEdit} = $event;
    const {subscription} = model;
    const {meta, email} = member;
    const {module: {singular}} = this;

    //Remove subscription from model now
    delete model.subscription;

    //Save
    return member
      .save(model)
      .then(async(member) => {

        //Call on saved handler and show notice
        this.onSaved({member});
        if (isEdit) {
          $notice.show(`${singular} updated`);
        }
        else {
          Intercom.event('Added member');
          $notice.show(`${singular} added`);
        }

        //Mark step as complete
        if (member.staff && member.staff.isPublic) {
          this.club.markStepComplete('displayStaff', true);
        }

        //Create subscription
        if (subscription) {
          await this.createSubscription(member, subscription);
        }

        //Editing or no email/meta data, skip rest
        if (isEdit || !meta) {
          return;
        }

        //Get meta data
        const {sendWelcomeEmail, addToMailingList, generatePin} = meta;

        //Add to mailing list
        if (addToMailingList && email) {
          for (const type in addToMailingList) {
            if (addToMailingList[type] === true) {
              this.addToMailingListDirectly({member, type});
            }
          }
        }

        //Send welcome email
        if (sendWelcomeEmail && email) {
          this.sendWelcomeEmailDirectly({member});
        }

        //Generate pin
        if (generatePin) {
          this.generatePin({member});
        }
      })
      .catch(error => {
        if (isEdit) {
          $notice.showError(`Failed to update ${singular}`);
        }
        else {
          $notice.showError(`Failed to add ${singular}`);
        }
        throw error;
      });
  };

  /**
   * Delete
   */
  this.delete = function($event) {

    //Get member and create handler
    const {member} = $event;
    const {module: {singular}} = this;

    const handler = function(model) {
      return member.delete(model);
    };

    //Open confirmation dialog
    $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-delete-member.html',
        locals: {member, handler},
      })
      .result
      .then(() => {
        $notice.show(`${singular} removed`);
        this.onDeleted({member});
      });
  };

  /**
   * Approve single member
   */
  this.approveOne = function($event) {

    //Get member
    const {member} = $event;
    const members = [member];
    const filter = {ids: [member.id]};
    const {singular} = this.module;

    //Use general approval function
    return this
      .approve(members, filter)
      .then(() => {
        $notice.show(`${singular} approved`);
        this.onApproved();
      });
  };

  /**
   * Approve members
   */
  this.approve = async function(members, filter) {

    //Handler
    const handler = () => Member.approveMany(filter);

    //Check payments for each member
    const matches = await $q.all(
      members
        .map(member => Member
          .findByName({name: member.name, includeArchived: true})
          .then(matches => matches[0]))
    );

    //Filter out matched members
    const matchedMembers = matches.filter(match => !!match);

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-approve-many.html',
        locals: {members, matchedMembers, handler},
      })
      .result;
  };

  /**
   * Reject single member
   */
  this.rejectOne = function($event) {

    //Get member
    const {member} = $event;
    const members = [member];
    const filter = {ids: [member.id]};
    const {singular} = this.module;

    //Use general reject function
    return this
      .reject(members, filter)
      .then(() => {
        $notice.show(`${singular} rejected`);
        this.onRejected();
      });
  };

  /**
   * Reject member
   */
  this.reject = async function(members, filter) {

    //Define handler
    const handler = (model) => Member.rejectMany(filter, model);

    //Check payments for each member
    const paymentCounts = await $q.all(
      members.map(member => Payment.count({member: member.id, isPaid: true}))
    );

    //Filter out paid members
    const paidMembers = members
      .map((member, i) => {
        const numPayments = paymentCounts[i];
        return {member, numPayments};
      })
      .filter(item => item.numPayments > 0);

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-reject-many.html',
        locals: {members, paidMembers, handler},
      })
      .result;
  };

  /**
   * Suspend/unsuspend
   */
  this.toggleSuspended = function($event) {

    //Get member and flag
    const {member, isSuspended} = $event;
    const {module: {singular}} = this;
    const method = isSuspended ? 'suspend' : 'unsuspend';

    //Define handler
    const handler = (model) => member[method](model);

    //Show confirmation modal
    return $modal
      .open('basic', {
        templateUrl: `admin/people/members/modals/confirm-toggle-suspended.html`,
        locals: {member, handler},
      })
      .result
      .then(() => {
        $notice.show(isSuspended ? `${singular} suspended` : `${singular} un-suspended`);
        this.onPatched({member, field: 'isSuspended'});
      });
  };

  /**
   * Archive/restore
   */
  this.toggleArchived = function($event) {

    //Get member and flag
    const {member, isArchived} = $event;
    const {module: {singular}} = this;
    const method = isArchived ? 'archive' : 'restore';

    //Define handler
    const handler = () => member[method]();

    //Show confirmation modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-toggle-archived.html',
        locals: {member, handler},
      })
      .result
      .then(() => {
        $notice.show(isArchived ? `${singular} archived` : `${singular} restored`);
        this.onPatched({member, field: 'isArchived'});
      });
  };

  /**
   * Merge members
   */
  this.mergeMembers = function($event) {

    //Get member
    const {member} = $event;

    //Open confirmation dialog
    $modal
        .open('mergeMembers', {
          locals: {member},
        })
        .result
        .then(() => this.onMerged());
  };

  /**
   * Create subscription
   */
  this.createSubscription = function(member, model) {

    //Append member ID
    model.member = member.id;

    //Save sub
    return $store.subscriptions
      .save(model)
      .then(sub => {
        $notice.show('Membership added');
        member.subscriptions.push(sub);
        if (model.meta.createTransaction) {
          member.amountOwing += model.meta.amount;
        }
      })
      .catch(() => {
        $notice.showError('Failed to add membership');
      });
  };

  /**
   * Send welcome email
   */
  this.sendWelcomeEmail = function($event) {

    //Get member and open modal
    const {member} = $event;
    $modal.open('welcomeEmail', {locals: {member}});
  };

  /**
   * Send email to one member
   */
  this.sendEmail = function($event) {

    //Get member and define handler
    const {customFields} = this;
    const {member} = $event;
    const {id, name, email} = member;
    const filter = {ids: [id], hasEmail: true};
    const recipient = `${name} <${email}>`;
    const handler = email => Member.email(filter, email);
    const tags = ReplacementTags.member(customFields);
    const isDirect = true;

    //Open modal
    $modal.open('email', {locals: {handler, recipient, tags, isDirect}});
  };

  /**
   * Send welcome email directly
   */
  this.sendWelcomeEmailDirectly = function($event) {

    //Get member and send welcome email
    const {member} = $event;
    member
      .sendWelcomeEmail()
      .then(() => $notice.show('Welcome email sent'))
      .catch(() => $notice.showError('Failed to send welcome email'));
  };

  /**
   * Generate pin
   */
  this.generatePin = function($event) {

    //Get member and generate pin
    const {member} = $event;
    Tag
      .createPinForMember(member)
      .then(() => $notice.show('Pin number generated'))
      .catch(() => $notice.showError('Failed to generate pin number'));
  };

  /**
   * Add to mailing list
   */
  this.addToMailingList = function($event) {
    return $modal.open('mailingList', {locals: $event});
  };

  /**
   * Add to mailing list directly
   */
  this.addToMailingListDirectly = function($event) {

    //Get data
    const {type, member} = $event;
    const integration = this.integrations.find(i => i.type === type);
    const {name, service, data} = integration;
    const {defaultLists} = data;

    //Add
    return service
      .addMember(member, defaultLists, true)
      .then(() => $notice.show(`Added to ${name}`))
      .catch(() => $notice.showError(`Failed to add to ${name}`));
  };

  /**
   * Download badge
   */
  this.downloadBadge = function($event) {
    const {member} = $event;
    member.downloadBadge();
  };

  /**
   * Load custom fields
   */
  this.loadCustomFields = function() {

    //Already have promise
    if (this.loadCustomFieldsPromise) {
      return this.loadCustomFieldsPromise;
    }

    //Load
    this.loadCustomFieldsPromise = $store.customFields
      .query({model: 'Member'})
      .then(fields => this.customFields = fields);

    //Return promise
    return this.loadCustomFieldsPromise;
  };

  /**
   * No-ops (to extend as needed)
   */
  this.onSaved = function() {};
  this.onPatched = function() {};
  this.onDeleted = function() {};
  this.onApproved = function() {};
  this.onRejected = function() {};
  this.onMerged = function() {};
});
