
/**
 * Define values
 */
const SERIES = 'series';
const INSTANCE = 'instance';
const RANGE = 'range';

/**
 * Define array
 */
const EventSeriesHandling = [
  {
    label: 'The whole event series',
    value: SERIES,
  },
  {
    label: 'This event instance only',
    value: INSTANCE,
  },
  {
    label: 'A given date range',
    value: RANGE,
  },
];

/**
 * Set properties
 */
EventSeriesHandling.SERIES = SERIES;
EventSeriesHandling.INSTANCE = INSTANCE;
EventSeriesHandling.RANGE = RANGE;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.EventSeriesHandling.Options', [])
  .constant('EventSeriesHandling', EventSeriesHandling);
