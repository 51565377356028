
/**
 * Module definition and dependencies
 */
angular.module('Shared.AutoDoorsClose.Options', [])

/**
 * Constant definition
 */
.constant('AutoDoorsCloseOptions', [
  {
    label: '5 minutes after start of event',
    value: '5:startDate',
  },
  {
    label: '10 minutes after start of event',
    value: '10:startDate',
  },
  {
    label: '15 minutes after start of event',
    value: '15:startDate',
  },
  {
    label: '30 minutes after start of event',
    value: '30:startDate',
  },
  {
    label: '45 minutes after start of event',
    value: '45:startDate',
  },
  {
    label: '1 hour after start of event',
    value: '60:startDate',
  },
  {
    label: 'At the end of the event',
    value: '0:endDate',
  },
  {
    label: '5 minutes after the event',
    value: '5:endDate',
  },
  {
    label: '10 minutes after the event',
    value: '10:endDate',
  },
  {
    label: '15 minutes after the event',
    value: '15:endDate',
  },
  {
    label: '30 minutes after the event',
    value: '30:endDate',
  },
  {
    label: '45 minutes after the event',
    value: '45:endDate',
  },
  {
    label: '1 hour after the event',
    value: '60:endDate',
  },
]);
