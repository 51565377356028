
/**
 * Module definition and dependencies
 */
angular.module('App.Public.Calendar', [
  'App.Public.Calendar.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('public.calendar', {
    url: '/calendar/:period/:activity/:date/:list/:category',
    component: 'publicCalendarRoute',
    params: {
      period: {
        value: null,
        squash: true,
        dynamic: true,
      },
      activity: {
        value: null,
        squash: true,
        dynamic: true,
      },
      date: {
        value: null,
        squash: true,
        dynamic: true,
      },
      list: {
        value: null,
        squash: true,
        dynamic: true,
      },
      category: {
        value: null,
        squash: true,
        dynamic: true,
      },
    },
    data: {
      id: 'calendar',
    },
    resolve: {
      filter(Filters) {
        return Filters.get('calendar');
      },
      areas($store) {
        return $store.areas.query();
      },
      activities($store) {
        return $store.activities.query();
      },
    },
  });
})

/**
 * Component
 */
.component('publicCalendarRoute', {
  controller: 'PublicCalendarCtrl',
  templateUrl: 'public/calendar/calendar.html',
  bindings: {
    club: '<',
    page: '<',
    module: '<',
    filter: '<',
    areas: '<',
    activities: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, moment, Page) => {

  //Guard
  $transitions.onEnter({
    to: 'public.calendar',
  }, async(transition) => {

    //Check if enabled
    const club = transition.injector().get('club');
    const hasPermission = club.permissions.publicCalendar;
    const isEnabled = club.settings.event.isPublicCalendarEnabled;
    if (!isEnabled || !hasPermission) {
      return transition.router.stateService.target('calendar', params);
    }

    //Get params
    const params = Object.assign({}, transition.params());
    let hasChanged = false;

    //Set defaults
    if (!params.date) {
      params.date = moment().format('YYYY-MM-DD');
      hasChanged = true;
    }
    if (!params.period) {
      params.period = club.settings.event.defaultCalendarPeriod || 'month';
      hasChanged = true;
    }
    if (!params.activity) {
      params.activity = 'all';
      hasChanged = true;
    }

    //Transition if changed
    if (hasChanged) {
      return transition.router.stateService.target('public.calendar', params);
    }
  });

  //Page setup
  $transitions.onSuccess({
    to: 'public.calendar',
  }, transition => {

    //Set page title
    const {date} = transition.params();
    if (date) {
      const formatted = moment(date, 'YYYY-MM-DD').format('MMMM YYYY');
      Page.setTitle(`Calendar ${formatted}`);
    }
    else {
      Page.setTitle(`Calendar`);
    }
  });
});
