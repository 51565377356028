
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.Edit.Assignment.Card', [])

/**
 * Component
 */
.component('cardResourceEditAssignment', {
  templateUrl: 'admin/resource/cards/assignment.html',
  controller: 'CardResourceEditAssignmentCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    resource: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardResourceEditAssignmentCtrl', function(
  $modal, $notice
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Add assignee
   */
  this.addAssignee = function() {

    //Get resource
    const {resource} = this;

    //Define handler
    const handler = function(model) {
      return resource.createAssignee(model);
    };

    //Toggle flag
    this.isAdding = true;

    //Open modal
    $modal.open('addAssignee', {
      locals: {resource, handler},
    })
    .result
    .then(() => $notice.show(`Assignment added`));
  };

  /**
   * Edit assignee
   */
  this.editAssignee = function($event) {

    //Extract data
    const {resource, assignee} = $event;

    //Define handler
    const handler = function(assigneeId, model) {
      return resource.patchAssignee(assigneeId, model);
    };

    //Show modal
    $modal
      .open('editAssignee', {
        locals: {resource, assignee, handler},
      })
      .result
      .then(() => $notice.show(`Assignment updated`));
  };

  /**
   * Renew assignee
   */
  this.renewAssignee = function($event) {

    //Extract data
    const {resource, assignee} = $event;

    //Define handler
    const handler = function(assigneeId, model) {
      return resource.patchAssignee(assigneeId, model);
    };

    //Show modal
    $modal
      .open('renewAssignee', {
        locals: {resource, assignee, handler},
      })
      .result
      .then(() => $notice.show(`Assignment renewed`));
  };

  /**
   * Toggle if assignee is active or not
   */
  this.toggleIsActive = function($event) {

    //Extract data
    const {resource, assignee} = $event;
    const {id, isActive} = assignee;
    const patch = {isActive: !isActive};

    //Define handler
    const handler = () => resource.patchAssignee(id, patch);

    //Show modal
    $modal
    .open('basic', {
      templateUrl: 'admin/resource/modals/toggle-active-assignee.html',
      locals: {resource, assignee, handler},
    })
    .result
    .then(() => $notice.show(`Assignment updated`));
  };

  /**
   * Delete assignee
   */
  this.deleteAssignee = function($event) {

    //Extract data
    const {resource, assignee} = $event;

    //Define handler
    const handler = function() {
      return resource.deleteAssignee(assignee);
    };

    //Show warning
    return $modal
      .open('basic', {
        templateUrl: `admin/resource/modals/confirm-delete-resource-assignee.html`,
        locals: {resource, assignee, handler},
      });
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };
});
