
/**
 * Module definition and dependencies
 */
angular.module('Shared.OutputStates.Constant', [])

/**
 * Constant definition
 */
.constant('OutputStates', {
  ON: 'on',
  OFF: 'off',
  UNKNOWN: 'unknown',
});
