
/**
 * Module definition and dependencies
 */
angular.module('Shared.Tag.Validity.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('TagValidity', function($baseModel, moment) {

  /**
   * Defaults
   */
  const defaultData = {
    validFrom: null,
    validTill: null,
  };

  /**
   * Constructor
   */
  function TagValidity(data) {

    //Base model constructor
    $baseModel.call(this, angular.extend({}, defaultData, data || {}));

    //Duration property
    Object.defineProperty(this, 'duration', {
      get() {
        if (!this.validFrom || !this.validTill) {
          return 0;
        }
        return this.validTill.diff(this.validFrom, 'minutes');
      },
    });

    //Valid for amount/unit setter
    let validForAmount, validForUnit;
    Object.defineProperty(this, 'validForAmount', {
      get() {
        return validForAmount;
      },
      set(amount) {
        validForAmount = amount;
        if (validForAmount && validForUnit) {
          this.validFrom = moment();
          this.validTill = moment().add(validForAmount, validForUnit);
        }
      },
    });
    Object.defineProperty(this, 'validForUnit', {
      get() {
        return validForUnit;
      },
      set(unit) {
        validForUnit = unit;
        if (validForAmount && validForUnit) {
          this.validFrom = moment();
          this.validTill = moment().add(validForAmount, validForUnit);
        }
      },
    });

    //Valid from time virtual property
    Object.defineProperty(this, 'validFromTime', {
      get() {
        if (!this.validFrom) {
          return null;
        }
        return this.validFrom.getTime();
      },
      set(time) {
        if (this.validFrom) {
          this.validFrom = this.validFrom.clone().setTime(time);
        }
      },
    });

    //Valid till time virtual property
    Object.defineProperty(this, 'validTillTime', {
      get() {
        if (!this.validTill) {
          return null;
        }
        return this.validTill.getTime();
      },
      set(time) {
        if (this.validTill) {
          this.validTill = this.validTill.clone().setTime(time);
        }
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(TagValidity.prototype, $baseModel.prototype);

  //Return
  return TagValidity;
});
