
/**
 * Module definition and dependencies
 */
angular.module('Shared.Duration.Filter', [])

/**
 * Returns duration amount and unit display
 */
.filter('duration', function() {
  return function(duration, labelOnly) {
    if (!duration) {
      return '';
    }
    let label = duration.unit;
    if (duration.amount === 1) {
      switch (label) {
        case 'days':
        case 'weeks':
        case 'months':
        case 'years':
          label = label.substring(0, label.length - 1);
          break;

        //Don't touch the rest
        default:
          break;
      }
    }
    if (labelOnly) {
      return label;
    }
    return duration.amount + ' ' + label;
  };
});
