
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Flow.Confirm.Component', [])

/**
 * Grid component
 */
.component('flowBookingConfirm', {
  templateUrl: 'booking/flow/confirm.html',
  bindings: {
    user: '<',
    club: '<',
    booking: '<',
    meta: '<',
    onPrev: '&',
    onCreated: '&',
    onClose: '&',
  },

  /**
   * Controller
   */
  controller(moment, $storage, $store, $state, FeePolicies, Push) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Set data
      this.hasAgreed = false;
      this.mode = this.booking.mode;
      this.activity = this.booking.activity;

      //Determine total fee
      this.determineTotalFee();

      //Determine refund threshold and reminder options
      this.determineRefundThreshold();
      this.determineReminderOptions();

      //Check fee policy and last reminder value
      this.checkFeePolicy();
      this.checkLastReminder();
    };

    /**
     * Changed agreement
     */
    this.setHasAgreed = function(hasAgreed) {
      this.hasAgreed = hasAgreed;
      this.isErrorAgreement = false;
    };

    /**
     * Changed will pay afterwards agreement
     */
    this.setWillPayAfterwards = function(willPayAfterwards) {
      this.willPayAfterwards = willPayAfterwards;
      this.isErrorPayAfterwards = false;
    };

    /**
     * Set fee waived flag
     */
    this.setFeeWaived = function(isFeeWaived) {
      this.booking.isFeeWaived = isFeeWaived;
    };

    /**
     * Changed reminder email checkbox
     */
    this.setReminderEmail = function(isChecked) {
      this.booking.sendReminderEmail = isChecked;
    };

    /**
     * Changed confirmation email checkbox
     */
    this.setConfirmationEmail = function(isChecked) {
      this.booking.sendConfirmationEmail = isChecked;
    };

    /**
     * Set reminder time
     */
    this.setReminderTime = function(time) {
      this.booking.reminderTime = time;
      this.booking.sendReminderEmail = true;
    };

    /**
     * Check fee policy
     */
    this.checkFeePolicy = function() {

      //Initialize
      this.isRefundable = false;
      this.requiresPayment = false;
      this.isPayableAfterwards = false;
      this.isPayableInAdvance = false;
      this.isPayableWithCredit = false;
      this.hasEnoughCredit = false;

      //Get meta data. If no fee or policy, all good
      const {fee, policy} = this.meta;
      if (!fee || !policy) {
        return;
      }

      //Determine policy
      const isAfterwards = (policy === FeePolicies.AFTERWARDS);
      const isWithCredit = (policy === FeePolicies.WITH_CREDIT);
      const isInAdvance = (policy === FeePolicies.IN_ADVANCE);
      const isInAdvanceRef = (policy === FeePolicies.IN_ADVANCE_REFUNDABLE);

      //Check threshold and credit
      const isBeforeThreshold = moment().isBefore(this.refundThreshold);
      const {forOthers} = this.booking;

      //Payable with credit?
      if (isWithCredit) {
        this.isPayableWithCredit = true;
        if (this.user) {
          this.hasEnoughCredit = (fee <= this.user.accountCredit);
        }
      }

      //Payable afterwards?
      else if (isAfterwards) {
        this.isPayableAfterwards = true;
      }

      //Payable in advance
      else if (isInAdvance || isInAdvanceRef) {
        this.isPayableInAdvance = true;
        this.requiresPayment = !forOthers;
        this.isRefundable = (isInAdvanceRef && isBeforeThreshold);
      }
    };

    /**
     * Check last reminder values
     */
    this.checkLastReminder = function() {

      //Can't be reminded
      if (!this.canBeReminded) {
        return;
      }

      //Get last reminder data
      const last = $storage.local.get('lastReminder');

      //Set relevant option
      if (last && this.reminderOptions.some(o => o.value === last)) {
        this.booking.reminderTime = last;
      }
      else {
        this.booking.reminderTime = this.reminderOptions[0].value;
      }
    };

    /**
     * Determine total fee
     */
    this.determineTotalFee = function() {
      const {fee, dates} = this.meta;
      this.totalFee = fee * dates.length;
    };

    /**
     * Determine refund threshold
     */
    this.determineRefundThreshold = function() {

      //Get refund threshold data
      const {unit, amount} = this.club.settings.booking.refundThreshold;

      //Determine threshold
      this.refundThreshold = this.booking.startDate
        .clone()
        .subtract(amount, unit);
    };

    /**
     * Determine reminder options
     */
    this.determineReminderOptions = function() {

      //Get booking date and determine in how many minutes from now it is
      const today = moment();
      const tomorrow = moment().add(1, 'day');
      const date = this.booking.startDate.clone();
      const diff = date.diff(today, 'minutes');

      //Initialize options
      this.reminderOptions = [];
      this.canBeReminded = false;

      //Add options according to time left
      if (diff >= 45) {
        this.canBeReminded = true;
        this.reminderOptions.push({
          value: 30,
          label: '30 minutes before',
        });
      }
      if (diff >= 75) {
        this.reminderOptions.push({
          value: 60,
          label: '1 hour before',
        });
      }
      if (diff >= 150) {
        this.reminderOptions.push({
          value: 120,
          label: '2 hours before',
        });
      }
      if (!today.isSame(date, 'day')) {
        this.reminderOptions.push({
          value: 24 * 60,
          label: 'on the day',
        });
      }
      if (!today.isSame(date, 'day') && !tomorrow.isSame(date, 'day')) {
        this.reminderOptions.push({
          value: 48 * 60,
          label: 'the day before',
        });
      }
    };

    /**
     * Show fee components
     */
    this.showFeeComponents = function() {
      this.isShowingFeeComponents = true;
    };

    /**
     * Previous step
     */
    this.prev = function() {
      this.onPrev();
    };

    /**
     * Validate
     */
    this.validate = function() {

      //Clear errors
      this.isErrorAgreement = false;
      this.isErrorPayAfterwards = false;

      //Payment validation only if not booking for others
      if (!this.booking.forOthers) {

        //Validate agreement
        if (this.mode.bookingAgreement && !this.hasAgreed) {
          this.isErrorAgreement = true;
          return false;
        }

        //Validate payment afterwards
        if (this.isPayableAfterwards && !this.willPayAfterwards) {
          this.isErrorPayAfterwards = true;
          return false;
        }
      }

      //All good
      return true;
    };

    /**
     * Save booking
     */
    this.save = function() {

      //Validate
      if (!this.validate()) {
        return;
      }

      //Mark as saving in progress and clear error
      this.isSaving = true;
      this.error = null;

      //Get data
      const {user, booking, requiresPayment} = this;
      const {sendReminderEmail, reminderTime} = booking;

      //Save booking
      $store.bookings
        .save(booking)
        .then(booking => {

          //Store reminder setting
          if (user && sendReminderEmail) {
            $storage.local.set('lastReminder', reminderTime);
          }

          //Call created handler
          const needsPayment = (requiresPayment && !booking.isPaid);
          this.onCreated({$event: {booking, needsPayment}});

          //Requires payment and booking not paid yet?
          if (needsPayment) {
            return $state.go('account.booking', {bookingId: booking.id});
          }

          //Ask for push notification
          if (!this.booking.forOthers) {
            Push.ask(user, 'booking');
          }
        })
        .catch(error => {
          if (error.name === 'ValidationError') {
            this.error = error;
          }
          else {
            throw error;
          }
        })
        .finally(() => this.isSaving = false);
    };
  },
});
