
/**
 * Module definition and dependencies
 */
angular.module('Shared.Membership.Model', [
  'BaseModel.Service',
  'Shared.MembershipStore.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('membership', {
    model: 'Membership',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'memberships',
        isArray: true,
        isModel: true,
      },
      list: {
        url: 'list',
        method: 'GET',
        isArray: true,
        isModel: true,
      },
      usage: {
        url: 'usage',
        method: 'GET',
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('memberships', {
    model: 'Membership',
    dataKey: 'memberships',
    service: 'MembershipStore',
    cacheEmpty: true,
  });
})

/**
 * Model definition
 */
.factory('Membership', function(
  $baseModel, $api, $store, $sync, MembershipConstraints, Intercom
) {

  //Extract constants
  const {ALL, WITHOUT, SPECIFIC_WITHOUT, WITH} = MembershipConstraints;

  /**
   * Constructor
   */
  function Membership(data) {

    //Parent constructor
    $baseModel.call(this, data);

    //Has fee
    Object.defineProperty(this, 'hasFee', {
      get() {
        return (this.fee && this.fee > 0);
      },
    });

    //Has linked memberships that aren't archived flag
    Object.defineProperty(this, 'hasNonArchivedLinked', {
      get() {
        return (
          this.linked &&
          this.linked.some(m => !m.isArchived)
        );
      },
    });

    //Ages
    Object.defineProperty(this, 'hasAgeRestriction', {
      get() {
        const {minAge, maxAge} = this;
        return !!(minAge || maxAge);
      },
    });

    //Ages
    Object.defineProperty(this, 'ageRestriction', {
      get() {
        const {minAge, maxAge} = this;
        if (minAge && maxAge) {
          return `between ${minAge} and ${maxAge} years`;
        }
        if (minAge) {
          return `${minAge} years or over`;
        }
        if (maxAge) {
          return `${maxAge} years or under`;
        }
        return '';
      },
    });

    //Ages
    Object.defineProperty(this, 'ages', {
      get() {
        const {minAge, maxAge} = this;
        if (minAge && maxAge) {
          return `${minAge}–${maxAge}`;
        }
        if (minAge) {
          return `${minAge}+`;
        }
        if (maxAge) {
          return `<${maxAge + 1}`;
        }
        return '';
      },
    });

    //Has benefits flag
    Object.defineProperty(this, 'hasBenefits', {
      get() {
        return (
          this.autoAccountCredit ||
          this.canUseForEvents ||
          this.couponTypes && this.couponTypes.length > 0
        );
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Membership.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Membership.prototype.fromJSON = function(json) {

    //Remove usage data for when copying membership
    delete json.numMembers;
    delete json.inUse;

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('activities', null, true);
    this.convertToModel('primary', 'Membership');
    this.convertToModel('linked', 'Membership', true);

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Membership.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Strip data
    json.activities = $baseModel.onlyId(json.activities);

    //Remove term if unlimited
    if (json.isUnlimited) {
      json.term = null;
    }

    //Remove usage data
    delete json.numMembers;
    delete json.inUse;

    //Return json
    return json;
  };

  /**
   * Clone
   */
  Membership.prototype.clone = function(stripId) {
    const clone = $baseModel.prototype.clone.call(this, stripId);

    //Strip unneeded data
    delete clone.club;
    delete clone.nameWithSuffix;
    delete clone.ids;
    delete clone.hasLinked;
    delete clone.hasTermGap;
    delete clone.createdAt;
    delete clone.updatedAt;
    delete clone.isDemo;

    //Return the clone
    return clone;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Check if membership applies to members with specific memberships
   */
  Membership.prototype.appliesToMemberships = function(memberships) {

    //Get data
    const {constraint, memberships: ids} = this;

    //Check
    if (constraint === ALL) {
      return true;
    }
    if (
      (constraint === WITHOUT || constraint === SPECIFIC_WITHOUT) &&
      memberships.length === 0
    ) {
      return true;
    }
    if (constraint === WITH && memberships.length > 0) {
      return true;
    }
    if (memberships.length > 0) {
      return ids.some(id => memberships.some(m => m.id === id));
    }
    return false;
  };

  /**
   * Update from parent membership (for linked memberships)
   */
  Membership.prototype.updateFrom = function(membership) {
    this.activities = angular.copy(membership.activities);
    this.conditions = membership.conditions;
    this.isUnlimited = membership.isUnlimited;
    this.term = membership.term;
  };

  /**
   * Save
   */
  Membership.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.membership[method](data).then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  Membership.prototype.patch = function(data) {
    const {id} = this;
    return $api.membership
      .patch({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Membership.prototype.delete = function() {
    return $api.membership
      .delete(null, this)
      .then(() => {
        $store.memberships.clear();
        return this;
      });
  };

  /**
   * Make registration url
   */
  Membership.prototype.makeRegistrationUrl = function(baseUrl) {
    const {id} = this;
    if (baseUrl.indexOf('?') !== -1) {
      return `${baseUrl}&membership=${id}`;
    }
    return `${baseUrl}?membership=${id}`;
  };

  /**
   * Has activity check
   */
  Membership.prototype.hasActivity = function(activityId) {
    return this.activities.includes(activityId);
  };

  /**
   * Check if membership is valid for given age
   */
  Membership.prototype.isValidForAge = function(age) {
    if (age === null || typeof age === 'undefined') {
      return true;
    }
    if (this.minAge && age < this.minAge) {
      return false;
    }
    if (this.maxAge && age > this.maxAge) {
      return false;
    }
    return true;
  };

  /**
   * Check if membership is reached limit
   */
  Membership.prototype.getNumCurrentSubs = function() {
    return Membership
      .usage(this.id)
      .then(data => data.numMembers[this.id] || 0);
  };

  /**
   * Check if membership is linked to a certain primary membership type
   */
  Membership.prototype.isLinkedTo = function(primary) {
    return (primary && this.primary && this.primary.id === primary.id);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Membership.query = function(filter) {
    return $api.membership.query(filter);
  };

  /**
   * Export
   */
  Membership.export = function(filter) {
    Intercom.event('Exported membership types');
    return $sync.get('membership/export/csv', filter, 'Exporting...');
  };

  /**
   * Find by ID
   */
  Membership.findById = function(id) {
    return $api.membership.get({id});
  };

  /**
   * Query usage
   */
  Membership.usage = function(id) {
    const data = id ? {membership: id} : null;
    return $api.membership.usage(data);
  };

  //Return
  return Membership;
});
