
/**
 * Module definition and dependencies
 */
angular.module('Shared.MembershipConstraintLabels.Options', [])

/**
 * Constant definition
 */
.constant('MembershipConstraintLabels', [
  {
    value: 'all',
    label: 'Everyone',
  },
  {
    value: 'with',
    label: 'Members with any valid membership',
    labelAlt: 'Any membership',
  },
  {
    value: 'specific',
    label: 'Members with a specific membership',
    labelAlt: 'Specific memberships',
  },
  {
    value: 'specificWithout',
    label: 'Casuals and members with a specific membership',
  },
  {
    value: 'without',
    label: 'Casuals without membership',
  },
  {
    value: 'guest',
    label: 'Guests and visitors',
    isGuest: true,
  },
]);
