
/**
 * Module definition and dependencies
 */
angular.module('Shared.DoorLockStates.Constant', [])

/**
 * Constant definition
 */
.constant('DoorLockStates', {
  LOCKED: 'locked',
  UNLOCKED: 'unlocked',
  UNKNOWN: 'unknown',
});
