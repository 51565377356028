
/**
 * Module definition and dependencies
 */
angular.module('Shared.Contact.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('contact', {
    model: 'Contact',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'contacts',
        isArray: true,
        isModel: true,
      },
      list: {
        url: 'list',
        method: 'GET',
        isArray: true,
        isModel: true,
      },
      count: {
        url: 'count',
        method: 'GET',
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        method: 'DELETE',
      },
      removeMany: {
        url: 'removeMany',
        method: 'POST',
      },
      deleteCustomFile: {
        url: 'file/:field/:filename',
        method: 'DELETE',
      },
      addToGroups: {
        url: 'addToGroups',
        method: 'POST',
      },
      removeFromGroups: {
        url: 'removeFromGroups',
        method: 'POST',
      },
      email: {
        url: 'email',
        method: 'POST',
      },
      imports: {
        url: 'import',
        method: 'GET',
        isArray: true,
      },
      removeImport: {
        url: 'import',
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('contacts', {
    model: 'Contact',
    dataKey: 'contacts',
  });
})

/**
 * Model definition
 */
.factory('Contact', function(
  $api, $sync, $baseModel, $httpParamSerializer, Auth, Config
) {

  /**
   * Constructor
   */
  function Contact(data) {
    $baseModel.call(this, data);

    //Is in group flag
    Object.defineProperty(this, 'isInGroups', {
      get() {
        return (this.groups.length > 0);
      },
    });

    //Has contact details
    Object.defineProperty(this, 'hasContactDetails', {
      get() {
        return !!(this.email || this.phone || this.mobile);
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Contact.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Contact.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('address', 'Address');
    this.convertToModel('groups', true);

    //Ensure custom fields are an object
    if (!this.customFields || typeof this.customFields !== 'object') {
      this.customFields = {};
    }
    if (!this.customFiles || typeof this.customFiles !== 'object') {
      this.customFiles = {};
    }

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Contact.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Strip data
    //TODO: When migrating to Vue, this should be made a lot smarter
    if (json.groups) {
      for (const group of json.groups) {
        delete group.club;
        delete group.createdAt;
        delete group.updatedAt;
      }
    }

    //Return
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Save
   */
  Contact.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.contact[method](data).then(data => this.fromJSON(data));
  };

  /**
   * Patch
   */
  Contact.prototype.patch = function(data) {
    const {id} = this;
    return $api.contact
      .patch({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Contact.prototype.delete = function() {
    return $api.contact.delete(null, this).then(() => this);
  };

  /**
   * Delete custom file
   */
  Contact.prototype.deleteCustomFile = function(field, filename) {
    return $api.contact
      .deleteCustomFile({field, filename}, this)
      .then(() => this.customFiles[field] = null);
  };

  /**
   * Get custom file download URL
   */
  Contact.prototype.getCustomFileUrl = function(field) {

    //Get data
    const {id, customFiles} = this;
    const {name} = customFiles[field];
    const token = Auth.getAccessToken();
    const qs = $httpParamSerializer({access_token: token});
    const base = Config.api.baseUrl;

    //Build URL
    return `${base}/contact/${id}/file/${field}/${name}?${qs}`;
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Contact.query = function(filter) {
    return $api.contact.query(filter);
  };

  /**
   * Count
   */
  Contact.count = function(filter) {
    return $api.contact
      .count(filter)
      .then(data => data.total || 0);
  };

  /**
   * Find by ID
   */
  Contact.findById = function(id) {
    return $api.contact.get({id});
  };

  /**
   * Export contacts
   */
  Contact.export = function(filter) {
    return $sync.get('contact/export/csv', filter, 'Exporting...');
  };

  /**
   * Add contacts to groups
   */
  Contact.addToGroups = function(filter, groups) {
    return $api.contact.addToGroups(filter, {groups});
  };

  /**
   * Remove contacts from groups
   */
  Contact.removeFromGroups = function(filter, groups) {
    return $api.contact.removeFromGroups(filter, {groups});
  };

  /**
   * Email
   */
  Contact.email = function(filter, email) {
    return $api.contact.email(filter, email);
  };

  /**
   * Get member imports
   */
  Contact.getImports = function(filter) {
    return $api.contact.imports(filter);
  };

  /**
   * Remove an import
   */
  Contact.removeImport = function(date) {
    return $api.contact.removeImport({date});
  };

  /**
   * Delete many
   */
  Contact.removeMany = function(filter) {
    return $api.contact.removeMany(filter);
  };

  //Return
  return Contact;
});
