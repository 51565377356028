
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Edit.Renewal.Card', [])

/**
 * Component
 */
.component('cardMembershipEditRenewal', {
  templateUrl: 'admin/membership/cards/renewal.html',
  controller: 'CardMembershipEditRenewalCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    membership: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipEditRenewalCtrl', function(
  Durations, AutoRenewalPaymentStrategies) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;
    this.Durations = Durations;
    this.AutoRenewalPaymentStrategies = AutoRenewalPaymentStrategies;

    //Create model subset
    this.model = this.membership.extract([
      'isRenewable', 'sendReminder', 'beforeExpiry', 'afterExpiry',
      'autoRenewal', 'dueThreshold',
    ]);

    //Set invoice due date type
    this.invoiceDueDateType = !this.model.dueThreshold ? 'default' : 'specific';

    //Set up invoice due date options
    this.invoiceDueDateOptions = [
      {
        label: 'Default',
        value: 'default',
      },
      {
        label: 'Specific to this membership type',
        value: 'specific',
      },
    ];
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {

    //Determine target
    target = target || this.model;
    target[property] = value;

    //Set form as dirty
    if (property === 'paymentStrategy') {
      this.form.$setDirty();
    }
  };

  /**
   * Update invoice due date type
   */
  this.updateInvoiceDueDateType = function(value) {
    this.invoiceDueDateType = value;

    if (value === 'default') {
      this.model.dueThreshold = null;
    }
    else {
      this.model.dueThreshold = this.club.settings.payment.dueThreshold;
    }
  };

  /**
   * Validate
   */
  this.validate = function() {

    //Reset error
    this.isErrorAutoRenewalPaymentStrategy = false;

    //Validate payment collection type
    if (!this.model.autoRenewal.paymentStrategy) {
      this.isErrorAutoRenewalPaymentStrategy = true;
    }

    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {membership, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {membership, model, isEdit}})
      .then(() => this.form.$setPristine())
      .finally(() => this.isSaving = false);
  };
});
