
/**
 * Module definition and dependencies
 */
angular.module('Shared.EnterAddress.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('enterAddress', {
    templateUrl: 'modals/input/enter-address.html',
    controller: 'ModalEnterAddressCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalEnterAddressCtrl', function(
  $controller, $modalInstance, Address
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Copy address for model
    if (this.address) {
      const components = Address.components();
      this.model = this.address.extract(components);
    }
    else {
      this.model = {};
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Create new address
    const address = new Address(this.model);
    $modalInstance.resolve(address);
  };
});
