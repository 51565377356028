
/**
 * Module definition and dependencies
 */
angular.module('App.Public.Event.View', [
  'App.Public.Event.View.Controller',
])

/**
 * Config
 */
.config($stateProvider => {

  //View
  $stateProvider.state('public.event.view', {
    url: '/:eventId/:slug',
    component: 'publicEventViewRoute',
    params: {
      eventId: {
        value: null,
      },
      slug: {
        value: '',
        squash: true,
      },
    },
    data: {
      roles: [],
    },
    resolve: {
      event(transition, club, Event) {
        const {eventId} = transition.params();
        return Event.findById(eventId, club);
      },
      attendance(transition, Event) {
        const {eventId} = transition.params();
        return Event.getPublicAttendance(eventId);
      },
    },
  });
})

/**
 * Route component
 */
.component('publicEventViewRoute', {
  controller: 'PublicEventViewCtrl',
  templateUrl: 'public/event/view/view.html',
  bindings: {
    club: '<',
    event: '<',
    page: '<',
    attendance: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, $log, Page) => {

  //Guard
  $transitions.onEnter({
    to: 'public.event.view',
  }, async(transition) => {

    //Get data
    const event = await transition.injector().getAsync('event');

    //Invalid event
    if (!event || event.isHidden) {
      $log.log(`Invalid event`);
      return transition.router.stateService.target('home');
    }

    //Check if we can't be here
    if (!event.isPublic) {
      $log.log(`Not a public event`);
      return transition.router.stateService
        .target('event.view', {eventId: event.id});
    }
  });

  //Page setup
  $transitions.onSuccess({
    to: 'public.event.view',
  }, transition => {

    //Get data
    const event = transition.injector().get('event');

    //Set page title
    Page.setTitle(event.name);
  });
});
