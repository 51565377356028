
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.MemberFields.Constant', [])

/**
 * Member fields constant
 */
.constant('MemberFields', [
  {
    prop: 'number',
    label: 'Number',
    isInt: true,
  },
  {
    prop: 'firstName',
    label: 'First name',
    isRequired: true,
  },
  {
    prop: 'lastName',
    label: 'Last name',
    isRequired: true,
  },
  {
    prop: 'gender',
    label: 'Gender',
    enum: ['Male', 'Female', 'Non-binary', 'Hidden', 'Unknown'],
  },
  {
    prop: 'dob',
    label: 'Date of birth',
    isDate: true,
  },
  {
    prop: 'email',
    label: 'Email',
    isEmail: true,
  },
  {
    prop: 'mobile',
    label: 'Mobile',
    isMobile: true,
  },
  {
    prop: 'phone',
    label: 'Phone',
    isPhone: true,
  },
  {
    prop: 'address.line1',
    label: 'Address line 1',
  },
  {
    prop: 'address.line2',
    label: 'Address line 2',
  },
  {
    prop: 'address.suburb',
    label: 'Suburb',
  },
  {
    prop: 'address.postalCode',
    label: 'Postal code',
  },
  {
    prop: 'address.city',
    label: 'City',
  },
  {
    prop: 'address.state',
    label: 'State',
  },
  {
    prop: 'address.country',
    label: 'Country',
  },
  {
    prop: 'billing.emails',
    label: 'Billing email',
    isEmail: true,
  },
  {
    prop: 'groups',
    label: 'Group(s)',
    isArray: true,
  },
  {
    prop: 'notes',
    label: 'Notes',
    isMultiLine: true,
  },
  {
    prop: 'signUpDate',
    label: 'Member since',
    isDate: true,
  },
  {
    prop: 'isArchived',
    label: 'Archived',
    isBoolean: true,
  },
  {
    prop: 'membership',
    label: 'Membership',
    hint: 'Must match your Membership Type names exactly',
  },
  {
    prop: 'startDate',
    label: 'Membership start date',
    isDate: true,
    linkedFields: ['membership'],
  },
  {
    prop: 'endDate',
    label: 'Membership end date',
    isDate: true,
    linkedFields: ['membership'],
  },
  {
    prop: 'circle',
    label: 'Circle name',
    hint: 'Unique name for circle this member belongs to',
  },
  {
    prop: 'isLead',
    label: 'Circle lead',
    isBoolean: true,
  },
  {
    prop: 'primaryMember',
    label: 'Primary member',
    hint: 'Full name of primary member for bundled memberships',
  },
  {
    prop: 'postalAddress.line1',
    label: 'Postal address line 1',
  },
  {
    prop: 'postalAddress.line2',
    label: 'Postal address line 2',
  },
  {
    prop: 'postalAddress.suburb',
    label: 'Postal address suburb',
  },
  {
    prop: 'postalAddress.postalCode',
    label: 'Postal address postal code',
  },
  {
    prop: 'postalAddress.city',
    label: 'Postal address city',
  },
  {
    prop: 'postalAddress.state',
    label: 'Postal address state',
  },
  {
    prop: 'postalAddress.country',
    label: 'Postal address country',
  },
  {
    prop: 'accountCredit',
    label: 'Account credit balance',
    isPositiveCurrency: true,
  },
  {
    prop: 'amountOwing',
    label: 'Amount owing',
    isPositiveCurrency: true,
  },
]);
