
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.Domain.Card', [])

/**
 * Component
 */
.component('cardClubDomain', {
  templateUrl: 'admin/club/cards/domain.html',
  controller: 'CardClubDomainCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubDomainCtrl', function(
  $modal, $window, ValidationError
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;

    //Create model subset
    this.model = this.club.extract([
      'subdomain',
    ]);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Reset validity
   */
  this.resetValidity = function() {
    this.form.subdomain.$setValidity('exists', true);
    this.form.subdomain.$setValidity('invalid', true);
    this.form.subdomain.$setValidity('reserved', true);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function(isConfirmed) {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Prepare data
    const {model, club} = this;

    //Confirm user understands subdomain change
    if (!isConfirmed) {
      return $modal
        .open('basic', {
          templateUrl: 'admin/club/modals/confirm-subdomain-change.html',
          locals: {model, club},
        })
        .result
        .then(() => this.save(true));
    }

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event: {model}})
      .then(() => {
        this.form.$setPristine();
        this.club.markStepComplete('setupSubdomain', true);
        $window.location.href = `${this.club.appUrl}/admin/club`;
      })
      .catch(error => {
        if (error instanceof ValidationError) {
          return error.setFieldsValidity(this.form);
        }
        throw error;
      })
      .finally(() => this.isSaving = false);
  };
});
