
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Flow.Recurrence.Component', [])

/**
 * Grid component
 */
.component('flowBookingRecurrence', {
  templateUrl: 'booking/flow/recurrence.html',
  bindings: {
    booking: '<',
    maxDate: '<',
    user: '<',
    club: '<',
    onPrev: '&',
    onNext: '&',
    onClose: '&',
  },

  /**
   * Controller
   */
  controller(
    ValidationError, Recurrence, Durations,
    ErrorCodes, $modal
  ) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Set services
      const {DAYS, YEARS} = Durations;
      this.Durations = Durations
        .filter(option => ![YEARS, DAYS].includes(option.value));

      //Always set skip conflicitng bookings flag to false
      this.booking.skipConflictingBookings = false;

      //Create recurrence if none set
      if (!this.booking.recurrence) {
        const firstDate = this.booking.startDate.clone();
        const lastDatePlaceholder = firstDate.clone().add(3, 'weeks');
        const days = [this.booking.startDate.isoWeekday()];
        const ends = 'lastDate';
        const lastDate = this.maxDate.isAfter(lastDatePlaceholder)
          ? lastDatePlaceholder : this.maxDate;

        //Create recurrence
        this.booking.recurrence = new Recurrence({
          firstDate, days, ends, lastDate,
        });
      }

      //Determine day of recurrence labels
      this.determineDayOfRecurrenceLabels();
    };

    /**
     * Validate
     */
    this.validate = function() {
      this.form.$setSubmitted();
      return this.form.$valid;
    };

    /**
     * Update mode
     */
    this.updateModel = function(property, value, target) {
      this.form.$setDirty(); //Needed for ending by
      target = target || this.booking;
      target[property] = value;
      this.determineDayOfRecurrenceLabels();
    };

    /**
     * Determine dynamic labels for day of recurrence
     */
    this.determineDayOfRecurrenceLabels = function() {
      const {recurrence} = this.booking;
      const {interval, firstDate} = recurrence;
      if (interval === 'months') {
        const nth = this.getMonthlyDayOf(firstDate);
        this.dayOfRecurrences = [
          {
            value: 'week',
            label: `On the ${nth} ${firstDate.format('dddd')}`,
          },
          {
            value: 'month',
            label: `On the ${firstDate.format('Do')}`,
          },
        ];
      }
    };

    /**
     * Helper to get the instance of a day of week in a month
     */
    this.getMonthlyDayOf = function(checkDate) {

      //First, find the first date of the month that falls on this weekday
      let dayOfWeek = checkDate.isoWeekday();
      let date = checkDate.clone().startOf('month');
      while (date.isoWeekday() !== dayOfWeek) {
        date.add(1, 'day');
      }

      //Now determine which instance was picked and how many of these days
      //there are in the selected month
      let instance;
      let count = 0;
      while (date.isSame(checkDate, 'month')) {
        count++;
        if (date.isSame(checkDate, 'day')) {
          instance = count;
        }
        date.add(1, 'week');
      }

      //Determine friendly text of instance
      switch (instance) {
        case 1:
          instance = 'first';
          break;
        case 2:
          instance = 'second';
          break;
        case 3:
          instance = 'third';
          break;
        case 4:
          instance = 'fourth';
          break;
        case 5:
          instance = 'fifth';
      }

      //Return
      return instance;
    };

    /**
     * Day of week new date determination
     */
    this.dayOfWeekDate = function() {
      if (this.booking.recurrence.days.length === 0) {
        return null;
      }
      this.booking.recurrence.days.sort();
      let day = this.booking.startDate.isoWeekday();
      let first = this.booking.recurrence.days[0];
      let diff = first - day;
      if (diff < 0) {
        diff += 7;
      }
      return this.booking.startDate.clone().add(diff, 'days');
    };

    /**
     * Ends helper
     */
    this.isEndingBy = function(type) {
      return (this.booking.recurrence.ends === type);
    };

    /**
     * Previous step
     */
    this.prev = function() {
      this.onPrev();
    };

    /**
     * Next step
     */
    this.next = function() {

      //Validate
      if (!this.validate()) {
        return;
      }

      //Next step
      this.isBusy = true;
      this
        .onNext()
        .catch(error => {

          //Check if recurring
          const {isRecurring} = this.booking;

          //If recurring and has a conflicting booking
          if (error.code === ErrorCodes.areaConflict && isRecurring) {
            const {bookings, events} = error.data;
            return $modal
              .open('basic', {
                templateUrl: 'booking/modals/conflicting-bookings.html',
                rejectOnDismissal: true,
                locals: {bookings, events},
              })
              .result
              .then(() => {
                this.booking.skipConflictingBookings = true;
                this.next(true);
              });
          }
          else if (error instanceof ValidationError) {
            return this.error = error;
          }
          throw error;
        })
        .finally(() => this.isBusy = false);
    };
  },
});
