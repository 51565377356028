
/**
 * Module definition and dependencies
 */
angular.module('Shared.OnLoad.Directive', [])

/**
 * Directive
 */
.directive('onLoad', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.bind('load', function() {
        scope.$eval(attrs.onLoad);
      });
    },
  };
});
