
/**
 * Module definition and dependencies
 */
angular.module('Shared.Email.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('email', {
    templateUrl: 'modals/email.html',
    controller: 'ModalEmailCtrl',
    closeOnClick: false,
    resolve: {
      club($store) {
        'ngInject';
        return $store.club.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalEmailCtrl', function(
  $controller, $modal, $modalInstance, $notice, $focus, $storage,
  TooManyRequestsError, Config, Email, Upload
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Create new email model
    this.email = {
      isDirect: this.isDirect,
      attachments: [],
    };

    //Reply to addresses
    this.replyToAddresses = this.club.settings.email.replyToAddresses || [];
    if (this.replyToAddresses && this.replyToAddresses.length > 0) {

      //Get previous reply to address
      const previous = $storage.get('replyToAddress');
      const isValid = (previous && this.replyToAddresses.includes(previous));
      const first = this.replyToAddresses[0];

      //Set default
      this.email.replyTo = isValid ? previous : first;
    }

    //Flags
    this.isSending = false;
    this.isError = false;

    //Load number of recipients if counter given
    if (this.counter) {
      this.countNumRecipients();
    }
  };

  /**
   * Set reply to
   */
  this.setReplyTo = function(replyTo) {
    this.email.replyTo = replyTo;
    $storage.set('replyToAddress', replyTo);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    $focus('subject');
  };

  /**
   * Count number of recipients
   */
  this.countNumRecipients = function() {

    //Flag as loading
    this.isLoading = true;

    //Count
    this
      .counter()
      .then(num => this.numRecipients = num)
      .finally(() => this.isLoading = false);
  };

  /**
   * Upload attachment
   */
  this.uploadAttachment = function() {

    //Get data
    const {baseUrl} = Config.api;
    const title = `Upload attachment`;

    //Upload handler
    const onUpload = file => Upload.upload({
      url: `${baseUrl}/email/attachment`,
      data: {file},
    });

    //Show modal
    $modal
      .open('uploadFile', {
        locals: {title, onUpload, instant: true},
      })
      .result
      .then(file => {
        this.email.attachments.push(file);
        this.checkTotalFileSize();
      });
  };

  /**
   * Remove attachment
   */
  this.removeAttachment = function(i) {

    //Get file
    const file = this.email.attachments[i];
    if (!file) {
      return;
    }

    //Create handler
    const handler = () => {
      return Email
        .deleteAttachment(file.id)
        .then(() => {
          this.email.attachments.splice(i, 1);
          this.checkTotalFileSize();
        });
    };

    //Show modal
    $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-delete-file.html',
        locals: {handler, file},
      });
  };

  /**
   * Check total file size
   */
  this.checkTotalFileSize = function() {

    //Determine total file size
    const {maxFileSize} = Config.fileUpload;
    const totalSize = this.email.attachments
      .reduce((total, file) => total + file.size, 0);

    //Check if exceeded
    this.hasExceededFileSize = (totalSize > maxFileSize);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Send email
   */
  this.send = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as posting and reset error flags
    this.isSending = true;
    this.isError = false;
    this.isRateLimitError = false;

    //Get email
    const {email} = this;
    const data = Object.assign({}, email);

    //Convert attachments to ID's only
    data.attachmentIds = email.attachments.map(a => a.id);
    delete data.attachments;

    //Post now
    this
      .handler(data)
      .then(() => {
        $notice.show('Email sent');
        $modalInstance.close();
      })
      .catch(error => {

        //Too many requests
        if (error instanceof TooManyRequestsError) {
          const {limit, count, isTrial} = error.data;

          //Set flag and limit/count
          this.isRateLimitError = true;
          this.isTrial = isTrial;
          this.limit = limit;
          this.count = count;
          return;
        }

        //Generic error
        this.isError = true;
      })
      .finally(() => this.isSending = false);
  };
});
