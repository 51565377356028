
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.Directory.Card', [])

/**
 * Component
 */
.component('cardMemberEditDirectory', {
  templateUrl: 'admin/people/members/cards/directory.html',
  controller: 'CardMemberEditDirectoryCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    member: '<',
    customFields: '<',
    onSave: '&',
    isOwn: '<',
  },
})

/**
 * Controller
 */
.controller('CardMemberEditDirectoryCtrl', function(CardCloseReasons, Helpers) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;
    this.fields = [];

    //Get settings
    const {fields} = this.club.settings.member;

    //Add fields
    if (fields.email.inDirectory) {
      this.fields.push(
        {key: 'email', label: 'Email address'}
      );
    }
    if (fields.phone.inDirectory) {
      this.fields.push(
        {key: 'phone', label: 'Phone number'},
        {key: 'mobile', label: 'Mobile number'}
      );
    }
    if (fields.address.inDirectory) {
      this.fields.push(
        {key: 'address.line1', label: 'Address'},
        {key: 'address.suburb', label: 'Suburb'},
        {key: 'address.city', label: 'City'}
      );
    }

    //Filter custom fields only those that show in directory
    this.customFields = this.customFields.filter(field => field.inDirectory);

    //Extract model
    this.model = this.member.extract([
      'directory',
    ]);

    //Ensure directory settings exist
    if (!this.model.directory) {
      this.model.directory = {
        isPublic: false,
        fields: {},
      };
    }

    //Ensure fields are set
    if (!this.model.directory.fields) {
      this.model.directory.fields = {};
    }
    if (!this.model.directory.fields.customFields) {
      this.model.directory.fields.customFields = {};
    }

    //Untick all fields which don't exist
    this.fields.forEach(({key}) => {
      if (!Helpers.get(this.member, key)) {
        Helpers.set(this.model.directory.fields, key, false);
      }
    });
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model.directory;
    target[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving and get data
    this.isSaving = true;
    const {member, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {member, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
