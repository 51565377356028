
/**
 * Module definition and dependencies
 */
angular.module('Shared.VisitorLog.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('visitorLog', {
    model: 'VisitorLog',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'visitorLogs',
        isArray: true,
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      countByTime: {
        method: 'GET',
        url: '/countByTime',
      },
    },
  });
})

/**
 * Model definition
 */
.factory('VisitorLog', function($baseModel, $api, $sync, moment) {

  /**
   * Constructor
   */
  function VisitorLog(data) {
    $baseModel.call(this, data);

    //Full name
    Object.defineProperty(this, 'name', {
      get() {
        return String(
          (this.firstName || '') + ' ' + (this.lastName || '')
        ).trim();
      },
    });

    //Whether stil on site according to the dates
    Object.defineProperty(this, 'isOnSite', {
      get() {
        const {entryDate, departureDate} = this;
        if (!entryDate || !departureDate) {
          return false;
        }
        return moment().isBetween(entryDate, departureDate, undefined, '[]');
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(VisitorLog.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  VisitorLog.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('address', 'Address');

    //Return self
    return this;
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Create new log entry
   */
  VisitorLog.create = function(data) {
    return $api.visitorLog
      .create(data)
      .then(data => new VisitorLog(data));
  };

  /**
   * Query
   */
  VisitorLog.query = function(filter) {
    return $api.visitorLog.query(filter);
  };

  /**
   * Count by time
   */
  VisitorLog.countByTime = function(filter) {
    return $api.visitorLog
      .countByTime(filter)
      .then(data => data.total);
  };

  /**
   * Export
   */
  VisitorLog.export = function(filter) {
    return $sync.get('visitorLog/export/csv', filter, 'Exporting...');
  };

  //Return
  return VisitorLog;
});
