
/**
 * Module definition and dependencies
*/
angular.module('Shared.PageIcons.Options', [])

/**
 * Constant definition
 */
.constant('PageIcons', [
  {
    category: 'General',
    icons: [
      'grade',
      'favorite',
      'flash_on',
      'shield',
      'interests',
      'workspaces',
      'flag',
      'push_pin',
      'signpost',
      'lock',
      'question_answer',
      'face',
    ],
  },
  {
    category: 'Health & safety',
    icons: [
      'health_and_safety',
      'masks',
      'vaccines',
      'accessible',
    ],
  },
  {
    category: 'Financial',
    icons: [
      'shopping_cart',
      'attach_money',
      'savings',
      'point_of_sale',
    ],
  },
  {
    category: 'Multimedia',
    icons: [
      'inventory',
      'collections',
      'description',
      'video_library',
    ],
  },
  {
    category: 'Activity',
    icons: [
      'emoji_events',
      'school',
      'construction',
      'how_to_vote',
      'weekend',
      'restaurant',
      'nightlife',
      'spa',
      'drive_eta',
      'pets',
      'local_florist',
      'beach_access',
    ],
  },
  {
    category: 'Sports',
    icons: [
      'sports',
      'sports_score',
      'sports_tennis',
      'golf_course',
      'sports_basketball',
      'sports_volleyball',
      'sports_soccer',
      'sports_baseball',
      'sports_rugby',
      'sports_football',
      'sports_cricket',
      'sports_handball',
      'hiking',
      'directions_run',
      'downhill_skiing',
      'ice_skating',
      'kayaking',
      'rowing',
      'surfing',
      'pool',
      'sailing',
      'directions_boat',
      'sports_motorsports',
      'directions_bike',
      'sports_martial_arts',
      'sports_mma',
      'fitness_center',
      'sports_esports',
    ],
  },
]);
