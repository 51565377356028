
/**
 * Module definition and dependencies
 */
angular.module('Shared.Modal.Ok.Component', [])

/**
 * Ok modal component
 */
.component('modalOk', {
  template: `
    <div class="Modal">
      <div class="Modal-header {{$ctrl.type || 'success'}}">
        <h1>{{$ctrl.header}}</h1>
        <div class="Modal-close" ng-click="$ctrl.modal.close()">
          <i class="Icon">close</i>
        </div>
      </div>
      <div class="Modal-body" ng-transclude ng-class="{'overflowing': $ctrl.isOverflowing}"></div>
      <div class="Modal-alerts" ng-transclude="alerts"></div>
      <div class="Modal-footer">
        <button-bar
          on-cancel="$ctrl.modal.cancel()"
          label-cancel="{{$ctrl.labelCancel || 'Close'}}"
        ></button-bar>
      </div>
    </div>
  `,
  transclude: {
    alerts: '?modalAlerts',
  },
  bindings: {
    modal: '<',
    isOverflowing: '<',
    header: '@',
    type: '@',
    labelCancel: '@',
  },
});
