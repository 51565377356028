
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Edit.AccountCredit.Card', [])

/**
 * Component
 */
.component('cardMembershipEditAccountCredit', {
  templateUrl: 'admin/membership/cards/account-credit.html',
  controller: 'CardMembershipEditAccountCreditCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    membership: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipEditAccountCreditCtrl', function(
  CardCloseReasons
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;

    //Create model subset
    this.model = this.membership.extract([
      'autoAccountCredit',
    ]);

    //Set hasAutoAccountCredit checkbox
    if (!this.membership.autoAccountCredit || this.membership.autoAccountCredit === 0) {
      this.hasAutoAccountCredit = false;
      this.model.autoAccountCredit = 0;
    }
    else {
      this.hasAutoAccountCredit = true;
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Toggle hasAutoAccountCredit checkbox
   */
  this.toggleHasAutoAccountCredit = function(value) {
    this.hasAutoAccountCredit = value;

    if (value === false) {
      this.model.autoAccountCredit = 0;
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {membership, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {membership, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
