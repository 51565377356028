
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.ApiKeys.Overview.Card', [])

/**
 * Component
 */
.component('cardIntegrationsApiKeysOverview', {
  templateUrl: 'admin/integrations/api-keys/overview.html',
  controller: 'CardIntegrationsApiKeysOverviewCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    apiKeys: '<',
    isAvailable: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsApiKeysOverviewCtrl', function(
  $modal, $notice, ApiKey
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Check if we have keys
    this.hasApiKeys = this.apiKeys.length > 0;
  };

  /**
   * On deleted
   */
  this.onDeleted = function(apiKey) {

    //Get index and splice
    const i = this.apiKeys.indexOf(apiKey);
    if (i !== -1) {
      this.apiKeys.splice(i, 1);
    }

    //Check if we have keys
    this.hasApiKeys = this.apiKeys.length > 0;
  };

  /**
   * On created
   */
  this.onCreated = function(apiKey) {

    //Add key
    this.apiKeys.unshift(apiKey);
    this.hasApiKeys = true;
  };

  /**
   * Create
   */
  this.create = function() {

    //Create key
    const apiKey = new ApiKey();
    const handler = function() {
      return apiKey.save();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/integrations/modals/create-api-key.html',
        locals: {apiKey, handler},
      })
      .result
      .then(() => {
        $notice.show('API key created');
        this.onCreated(apiKey);
      });
  };

  /**
   * Delete
   */
  this.delete = function($event) {

    //Get data
    const {apiKey} = $event;
    const handler = function() {
      return apiKey.delete();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/integrations/modals/confirm-delete-api-key.html',
        locals: {apiKey, handler},
      })
      .result
      .then(() => {
        $notice.show('API key removed');
        this.onDeleted(apiKey);
      });
  };

  /**
   * Toggle enabled
   */
  this.toggleEnabled = function(apiKey) {

    //Determine flag
    const isEnabled = !apiKey.isEnabled;

    //Patch
    return apiKey
      .patch({isEnabled})
      .then(() => $notice.show(isEnabled ? `API key enabled` : `API key disabled`));
  };
});
