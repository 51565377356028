
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.AddAssignee.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addAssignee', {
    templateUrl: 'admin/resource/modals/add-assignee.html',
    controller: 'ModalAddAssigneeCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalAddAssigneeCtrl', function(
  $controller, $modalInstance, moment
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //If resource has default term
    if (this.resource.term) {

      //Get term
      const {unit, amount} = this.resource.term;

      //Set term
      this.model = {
        startDate: '',
        endDate: '',
      },
      this.model.startDate = moment().startOf('day');
      this.model.endDate = moment()
        .add(amount, unit)
        .subtract(1, 'day')
        .endOf('day');
    }

    //Model meta data
    this.model.meta = {
      notifyByEmail: true,
      createTransaction: this.resource.fee ? true : false,
      amount: this.resource.fee || '',
    };

    //Set default value
    this.model.isActive = true;
  };

  /**
   * Toggle notification
   */
  this.toggleNotify = function(notifyByEmail) {
    this.model.meta.notifyByEmail = notifyByEmail;
  };

  /**
   * Toggle transaction
   */
  this.toggleTransaction = function(createTransaction) {
    this.model.meta.createTransaction = createTransaction;
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Save
    this.isSaving = true;
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isSaving = false);
  };
});
