
/**
 * Module definition and dependencies
 */
angular.module('Shared.Interface.Service', [])

/**
 * Service definition
 */
.factory('Interface', function($window, $document, $timeout, $q) {

  /**
   * Interface service
   */
  return {

    /**
     * Copy data to clipboard
     */
    copyToClipboard(text) {

      //Support for clipboard
      if (navigator.clipboard) {
        try {
          return navigator.clipboard.writeText(text);
        }
        catch (e) {
          //Fall through to fallback method
        }
      }

      //No support
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed'; //avoid scrolling to bottom
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        return $q.resolve();
      }
      catch (error) {
        return $q.reject(error);
      }
      finally {
        document.body.removeChild(textArea);
      }
    },

    /**
     * Scroll into view
     */
    scrollIntoView(element, align) {

      //Set options
      const options = {
        time: 250,
        align: align || {
          top: 1,
        },
        validTarget(target) {
          return (
            /*parentsScrolled < 2 &&*/ target && target !== window &&
            (typeof target.matches !== 'function' || !target.matches('.ModalWrapper'))
          );
        },
      };

      //String ID
      if (typeof element === 'string') {
        element = $window.document.getElementById(element);
      }

      //No element?
      if (!element) {
        return;
      }

      //jQuery element
      if (typeof element[0] !== 'undefined') {
        element = element[0];
      }

      //HTML DOM element (doesn't support offset)
      // if (element && typeof element.scrollIntoView !== 'undefined') {
      //   element.scrollIntoView({block: 'end', behavior: 'smooth'});
      // }
      scrollIntoView(element, options);
    },

    /**
     * Scroll to top
     */
    scrollToTop(element) {
      $timeout(() => {

        //No element given? Use window
        if (!element) {
          if ('scrollBehavior' in document.documentElement.style) {
            return $window.scrollTo({top: 0});
          }
          return $window.scrollTo(0, 0);
        }

        //String ID
        else if (typeof element === 'string') {
          element = $window.document.getElementById(element);
          if (!element) {
            return;
          }
        }

        //jQuery element
        if (typeof element[0] !== 'undefined') {
          element = element[0];
        }

        //HTML DOM element
        if (element && typeof element.scrollTop !== 'undefined') {
          element.scrollTop = 0;
        }
      });
    },

    /**
     * Scroll to bottom
     */
    scrollToBottom(element) {
      $timeout(() => {

        //No element given? Use window
        if (!element) {
          if ('scrollBehavior' in document.documentElement.style) {
            return $window.scrollTo({top: $document[0].body.scrollHeight});
          }
          return $window.scrollTo(0, $document[0].body.scrollHeight);
        }

        //String ID
        else if (typeof element === 'string') {
          element = $window.document.getElementById(element);
        }

        //jQuery element
        if (typeof element[0] !== 'undefined') {
          element = element[0];
        }

        //HTML DOM element
        if (element && typeof element.scrollTop !== 'undefined') {
          element.scrollTop = element.scrollHeight;
        }
      });
    },

    /**
     * Helper to check if user interacted with an element with specific classes
     */
    isTarget(classes, event) {
      if (!Array.isArray(classes)) {
        classes = [classes];
      }
      let {target} = event;
      while (target && typeof target.className === 'string') {
        if (classes.some(name => target.className.indexOf(name) > -1)) {
          return true;
        }
        target = target.parentNode;
      }
      return false;
    },

    /**
     * Get target with specific classes from event
     */
    getTarget(classes, event) {
      if (!Array.isArray(classes)) {
        classes = [classes];
      }
      let {target} = event;
      while (target && typeof target.className === 'string') {
        if (classes.some(name => target.className.indexOf(name) > -1)) {
          return target;
        }
        target = target.parentNode;
      }
      return null;
    },

    /**
     * Prevent event helper
     */
    preventEvent(event) {
      event.stopPropagation();
      event.preventDefault();
      return false;
    },
  };
});

const COMPLETE = 'complete';
const CANCELED = 'canceled';

function setElementScroll(element, x, y) {
  if (element === window) {
    element.scrollTo(x, y);
  }
  else {
    element.scrollLeft = x;
    element.scrollTop = y;
  }
}

function getTargetScrollLocation(target, parent, align) {
  let targetPosition = target.getBoundingClientRect(),
      parentPosition,
      x,
      y,
      differenceX,
      differenceY,
      targetWidth,
      targetHeight,
      leftAlign = align && align.left !== null ? align.left : 0.5,
      topAlign = align && align.top !== null ? align.top : 0.5,
      leftOffset = align && typeof align.leftOffset === 'number' ? align.leftOffset : 0,
      topOffset = align && typeof align.topOffset === 'number' ? align.topOffset : 0,
      leftScalar = leftAlign,
      topScalar = topAlign;

  if (parent === window) {
    targetWidth = Math.min(targetPosition.width, window.innerWidth);
    targetHeight = Math.min(targetPosition.height, window.innerHeight);
    x = targetPosition.left + window.pageXOffset - window.innerWidth * leftScalar + targetWidth * leftScalar;
    y = targetPosition.top + window.pageYOffset - window.innerHeight * topScalar + targetHeight * topScalar;
    x = Math.max(Math.min(x, document.body.scrollWidth - window.innerWidth * leftScalar), 0);
    y = Math.max(Math.min(y, document.body.scrollHeight - window.innerHeight * topScalar), 0);
    x -= leftOffset;
    y -= topOffset;
    differenceX = x - window.pageXOffset;
    differenceY = y - window.pageYOffset;
  }
  else {
    targetWidth = targetPosition.width;
    targetHeight = targetPosition.height;
    parentPosition = parent.getBoundingClientRect();
    let offsetLeft = targetPosition.left - (parentPosition.left - parent.scrollLeft);
    let offsetTop = targetPosition.top - (parentPosition.top - parent.scrollTop);
    x = offsetLeft + (targetWidth * leftScalar) - parent.clientWidth * leftScalar;
    y = offsetTop + (targetHeight * topScalar) - parent.clientHeight * topScalar;
    x = Math.max(Math.min(x, parent.scrollWidth - parent.clientWidth), 0);
    y = Math.max(Math.min(y, parent.scrollHeight - parent.clientHeight), 0);
    x -= leftOffset;
    y -= topOffset;
    differenceX = x - parent.scrollLeft;
    differenceY = y - parent.scrollTop;
  }

  return {
    x: x,
    y: y,
    differenceX: differenceX,
    differenceY: differenceY,
  };
}

function animate(parent) {
  window.requestAnimationFrame(function() {
    let scrollSettings = parent._scrollSettings;
    if (!scrollSettings) {
      return;
    }

    let location = getTargetScrollLocation(
      scrollSettings.target, parent, scrollSettings.align
    );
    let time = Date.now() - scrollSettings.startTime;
    let timeValue = Math.min(1 / scrollSettings.time * time, 1);

    if (time > scrollSettings.time + 20) {
      setElementScroll(parent, location.x, location.y);
      parent._scrollSettings = null;
      return scrollSettings.end(COMPLETE);
    }

    let easeValue = 1 - scrollSettings.ease(timeValue);
    setElementScroll(parent,
      location.x - location.differenceX * easeValue,
      location.y - location.differenceY * easeValue
    );

    animate(parent);
  });
}
function transitionScrollTo(target, parent, settings, callback) {
  let idle = !parent._scrollSettings,
      lastSettings = parent._scrollSettings,
      now = Date.now(),
      endHandler;

  if (lastSettings) {
    lastSettings.end(CANCELED);
  }

  function end(endType) {
    parent._scrollSettings = null;
    if (parent.parentElement && parent.parentElement._scrollSettings) {
      parent.parentElement._scrollSettings.end(endType);
    }
    callback(endType);
    parent.removeEventListener('touchstart', endHandler);
  }

  parent._scrollSettings = {
    startTime: lastSettings ? lastSettings.startTime : Date.now(),
    target: target,
    time: settings.time + (lastSettings ? now - lastSettings.startTime : 0),
    ease: settings.ease,
    align: settings.align,
    end: end,
  };

  endHandler = end.bind(null, CANCELED);
  parent.addEventListener('touchstart', endHandler);

  if (idle) {
    animate(parent);
  }
}

function isScrollable(element) {
  return (parent === window || (
    element.scrollHeight !== element.clientHeight ||
    element.scrollWidth !== element.clientWidth
  ) && getComputedStyle(element).overflow !== 'hidden');
}

function defaultValidTarget() {
  return true;
}

function scrollIntoView(target, settings, callback) {
  if (!target) {
    return;
  }

  if (typeof settings === 'function') {
    callback = settings;
    settings = null;
  }

  if (!settings) {
    settings = {};
  }

  settings.time = isNaN(settings.time) ? 1000 : settings.time;
  settings.ease = settings.ease || function(v) {
    return 1 - Math.pow(1 - v, v / 2);
  };

  let parent = target.parentElement,
      parents = 0;

  function done(endType) {
    parents--;
    if (!parents) {
      callback && callback(endType);
    }
  }

  let validTarget = settings.validTarget || defaultValidTarget;

  while (parent) {
    if (validTarget(parent, parents) && isScrollable(parent)) {
      parents++;
      transitionScrollTo(target, parent, settings, done);
    }

    parent = parent.parentElement;

    if (!parent) {
      return;
    }

    if (parent.tagName === 'BODY') {
      parent = window;
    }
  }
}
