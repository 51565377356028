
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Circles', [
  'App.Admin.People.Circles.Overview',
  'App.Admin.People.Circles.Controller',
  'App.Admin.People.Circles.CircleOptions.Component',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.circles', {
    url: '/circles',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'circles',
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.people.circles.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.people.circles.overview', title});
  }, {priority: 5});
});
