
/**
 * Define values
 */
const AFTERWARDS = 'afterwards';
const WITH_CREDIT = 'withCredit';
const IN_ADVANCE = 'inAdvance';
const IN_ADVANCE_REFUNDABLE = 'inAdvanceRefundable';

/**
 * Define array
 */
const FeePolicies = [
  {
    value: AFTERWARDS,
    label: `Fee to be paid after session (using account credit if available)`,
  },
  {
    value: WITH_CREDIT,
    label: `Fee to be paid with account credit at the time of the session`,
  },
  {
    value: IN_ADVANCE_REFUNDABLE,
    label: `Fee must be paid in advance when booking, but is refundable if cancelled in time`,
    isInAdvance: true,
  },
  {
    value: IN_ADVANCE,
    label: `Fee must be paid in advance when booking and is non-refundable`,
    isInAdvance: true,
  },
];

/**
 * Set properties
 */
FeePolicies.AFTERWARDS = AFTERWARDS;
FeePolicies.WITH_CREDIT = WITH_CREDIT;
FeePolicies.IN_ADVANCE = IN_ADVANCE;
FeePolicies.IN_ADVANCE_REFUNDABLE = IN_ADVANCE_REFUNDABLE;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.FeePolicies.Options', [])
  .constant('FeePolicies', FeePolicies);
