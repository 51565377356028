
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Overview.Controller', [])

/**
 * Controller
 */
.controller('AdminMembershipOverviewCtrl', function(
  $controller, $filter, $notice, $modal, ScrollPosition, Pagination, Intercom,
  Interface, Settings, Membership
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminMembershipCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Track intercom event
    Intercom.event('Viewed memberships');

    //Initialize
    this.isLoading = true;
    this.canEdit = this.user.isAdmin();

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load memberships and usage
    this.loadPage();
    this.loadUsage();
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter, activities, module} = this;
    const {plural} = module;

    //Set filter defaults
    filter.setDefaults({
      search: '',
      status: 'current',
      isArchived: false,
    });

    //Filter on change handler
    filter.onChange((key, value) => {

      //Status
      if (key === 'status') {
        filter.mapOptions(key, value, 'value');
      }

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      activities,
      isRenewable: [
        {
          label: 'Renewable memberships',
          value: true,
        },
        {
          label: 'Non-renewable memberships',
          value: false,
        },
      ],
      status: [
        {
          label: `Current ${plural}`,
          value: 'current',
          isDefault: true,
          filter: {
            isArchived: false,
          },
        },
        {
          label: `Archived ${plural}`,
          value: 'archived',
          filter: {
            isArchived: true,
          },
        },
      ],
    };
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    this.loadMemberships();
  };

  /**
   * On patched
   */
  this.onPatched = function() {
    this.loadMemberships();
  };

  /**
   * Load memberships
   */
  this.loadMemberships = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query memberships
    return Membership
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Load usage
   */
  this.loadUsage = function() {

    //Query usage
    return Membership
      .usage()
      .then(usage => this.usage = usage)
      .then(() => this.mapUsage());
  };

  /**
   * Map usage to memberships
   */
  this.mapUsage = function() {

    //Get data
    const {usage, memberships} = this;
    if (!usage || !memberships) {
      return;
    }

    //Map usage to memberships
    memberships.forEach(membership => {
      membership.numMembers = usage.numMembers[membership.id] || 0;
      membership.inUse = usage.inUse[membership.id] || false;
    });
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, memberships} = data;

    //Set in scope
    this.memberships = memberships;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);

    //Map usage
    this.mapUsage();
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page) {

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //Remove status meta prop
    delete filter.status;

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadMemberships()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };

  /**
   * Copy registration link to clipboard
   */
  this.copyLinkToClipboard = function(membership) {
    const link = membership.makeRegistrationUrl(this.club.registrationUrl);
    Interface
      .copyToClipboard(link)
      .then(() => $notice.show(`Registration link copied to clipboard`));
  };

  /**
   * Export memberships
   */
  this.export = function() {

    //Get club
    const {club} = this;

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club, action: `Exporting membership types`},
      });
    }

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: 'membership types', action: 'export'},
      });
    }

    //Get filter and export
    const filter = this.makeFilter();
    return Membership.export(filter);
  };
});
