
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.SubscriptionOptions.Component', [])

/**
 * Subscription options component
 */
.component('memberSubscriptionOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="$ctrl.subscription.canBeEditedByAdmin()">
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li ng-if="$ctrl.subscription.canBeRenewedByAdmin()">
        <a ng-click="$ctrl.renew()">Renew</a>
      </li>
      <li ng-if="$ctrl.subscription.canBePausedByAdmin()">
        <a ng-click="$ctrl.pause()">Pause</a>
      </li>
      <li ng-if="$ctrl.subscription.canBeStoppedByAdmin()">
        <a ng-click="$ctrl.stop()">Stop</a>
      </li>
      <li ng-if="$ctrl.canToggleAutoRenewal && $ctrl.subscription.canAutoRenew()">
        <a ng-click="$ctrl.toggleAutoRenewal()" ng-if="!$ctrl.subscription.autoRenews">Enable auto renewal</a>
      </li>
      <li ng-if="$ctrl.canToggleAutoRenewal && $ctrl.subscription.autoRenews">
        <a ng-click="$ctrl.toggleAutoRenewal()" ng-if="$ctrl.subscription.autoRenews">Disable auto renewal</a>
      </li>
      <li>
        <a ng-click="$ctrl.downloadBadge()">Download badge</a>
      </li>
      <li>
        <a ui-sref="admin.log.audit({model: 'Subscription', id: $ctrl.subscription.id})">View audit log</a>
      </li>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Remove</a>
      </li>
    </more-options>
  `,
  bindings: {
    subscription: '<',
    onEdit: '&',
    onDelete: '&',
    onRenew: '&',
    onPause: '&',
    onStop: '&',
    canToggleAutoRenewal: '<',
    onToggleAutoRenewal: '&',
    onDownloadBadge: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Edit
     */
    this.edit = function() {
      const {subscription} = this;
      this.onEdit({$event: {subscription}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      const {subscription} = this;
      this.onDelete({$event: {subscription}});
    };

    /**
     * Stop
     */
    this.stop = function() {
      const {subscription} = this;
      this.onStop({$event: {subscription}});
    };

    /**
     * Pause
     */
    this.pause = function() {
      const {subscription} = this;
      this.onPause({$event: {subscription}});
    };

    /**
     * Toggle auto renewal
     */
    this.toggleAutoRenewal = function() {
      const {subscription} = this;
      this.onToggleAutoRenewal({$event: {subscription}});
    };

    /**
     * Download badge
     */
    this.downloadBadge = function() {
      const {subscription} = this;
      this.onDownloadBadge({$event: {subscription}});
    };

    /**
     * Renew
     */
    this.renew = function() {
      const {subscription} = this;
      this.isBusy = true;
      this
        .onRenew({$event: {subscription}})
        .finally(() => this.isBusy = false);
    };
  },
});
