
/**
 * Module definition and dependencies
 */
angular.module('App.Event.View.Summary.Card', [])

/**
 * Component
 */
.component('cardEventSummary', {
  templateUrl: 'event/cards/summary.html',
  controller: 'CardEventSummaryCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    member: '<',
    event: '<*',
    options: '<',
    attendee: '<',
    interest: '<',
    canSignUp: '<',
    hasOtherMembers: '<',
    showFee: '<',
    showOptions: '<',
    showDescription: '<',
    showInterest: '<',
    showAttendanceNumbers: '<',
    showAttendeeNames: '<',
    showNotes: '<',
    onToggleInterest: '&',
    onCancelAttendance: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventSummaryCtrl', function(
  $timeout, $notice, $modal, Interface, EventAttendee
) {

  /**
   * On changes
   */
  this.$onChanges = function() {

    //Determine fee options
    if (Array.isArray(this.options)) {
      this.feeOptions = this.options
        .filter(option => option.method !== 'dates');

      //All free
      if (this.feeOptions.every(option => option.rulesByType.free)) {
        this.feeOptions = [this.feeOptions[0]];
      }
    }
  };

  /**
   * Toggle interested
   */
  this.toggleInterest = function() {

    //Flag
    this.isTogglingInterested = true;

    //Toggle
    this
      .onToggleInterest()
      .then(() => {
        $timeout(() => {
          if (this.interest) {
            this.event.numInterested++;
          }
          else {
            this.event.numInterested--;
          }
        });
      })
      .finally(() => this.isTogglingInterested = false);
  };

  /**
   * Toggle is singing up
   */
  this.toggleIsSigningUp = function() {
    $timeout(() => this.isSigningUp = true);
  };

  /**
   * Cancel attendance
   */
  this.cancelAttendance = function() {
    this.onCancelAttendance();
  };

  /**
   * View all attendees
   */
  this.viewAllAttendees = function() {

    //Get event
    const {event} = this;

    //Already loading or not allowed for event
    if (this.isLoadingAttendees || !this.showAttendeeNames) {
      return;
    }

    //Toggle flag
    this.isLoadingAttendees = true;

    //Load attendees and view in modal
    EventAttendee
      .query({event: event.id, isRemoved: false})
      .then(({attendees}) => $modal.open('basic', {
        templateUrl: 'event/modals/view-attendees.html',
        locals: {event, attendees},
      }))
      .finally(() => this.isLoadingAttendees = false);
  };

  /**
   * Share link
   */
  this.share = function() {

    //Get data
    const {event, club} = this;
    const {name, publicPath} = event;
    const url = club.makeUrl(publicPath);

    //Share natively
    if (typeof navigator.share === 'function') {
      return navigator
        .share({
          url,
          title: name,
          text: `I thought you might be interested in this event`,
        })
        .catch(() => {});
    }

    //Copy link to clipboard
    Interface
      .copyToClipboard(url)
      .then(() => $notice.show(`Link copied to clipboard`));
  };
});
