
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.Credentials', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.credentials', {
    url: '/credentials',
    component: 'registerCredentialsRoute',
  });
})

/**
 * Route component
 */
.component('registerCredentialsRoute', {
  controller: 'RegisterCredentialsCtrl',
  templateUrl: 'portal/register/steps/credentials.html',
  bindings: {
    club: '<',
    registration: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterCredentialsCtrl', function($controller, Member) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('RegisterBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Validate
   */
  this.validate = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Get data
    const {username} = this.user;

    //Toggle flag
    this.isLoading = true;

    //Check if username exists
    Member
      .exists('username', {username})
      .then(exists => {
        if (exists) {
          this.isErrorUsernameExists = true;
          return;
        }
        this.continue();
      })
      .finally(() => this.isLoading = false);
  };
});
