
/**
 * Module definition and dependencies
 */
angular.module('Shared.Partial.Directive', [])

/**
 * Directive
 */
.directive('partial', function() {
  return {
    restrict: 'E',
    scope: false,
    //eslint-disable-next-line no-unused-vars
    templateUrl(element, attrs) {
      return attrs.url;
    },
  };
});
