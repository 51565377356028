
/**
 * Module definition and dependencies
 */
angular.module('Shared.TextTypes.Constant', [])

/**
 * Constant definition
 */
.constant('TextTypes', {

  //Texts
  PAYMENT_INSTRUCTIONS: 'paymentInstructions',
  REGISTRATION_INTRO: 'registrationIntro',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  VISITOR_INTRO: 'visitorIntro',
  VISITOR_TERMS: 'visitorTerms',
  WELCOME_MESSAGE: 'welcomeMessage',

  //Emails
  ACCOUNT_READY_EMAIL: 'accountReadyEmail',
  BIRTHDAY_EMAIL: 'birthdayEmail',
  BOOKING_CREATED_EMAIL: 'bookingCreatedEmail',
  EVENT_ATTENDEE_EMAIL: 'eventAttendeeEmail',
  UNPAID_BOOKING_REMINDER_EMAIL: 'unpaidBookingReminderEmail',
  UNPAID_TRX_REMINDER_EMAIL: 'unpaidTrxReminderEmail',
  MEMBERSHIP_CREATION_EMAIL: 'membershipCreationEmail',
  MEMBERSHIP_PURCHASE_EMAIL: 'membershipPurchaseEmail',
  MEMBERSHIP_CHANGE_EMAIL: 'membershipChangeEmail',
  MEMBERSHIP_RENEWAL_EMAIL: 'membershipRenewalEmail',
  MEMBERSHIP_RENEWAL_REMINDER_EMAIL: 'membershipRenewalReminderEmail',
  INVITE_EMAIL: 'inviteEmail',
  WELCOME_EMAIL: 'welcomeEmail',

  //System
  SYSTEM_TROUBLESHOOTING: 'systemTroubleshooting',
});
