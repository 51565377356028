
/**
 * Module definition and dependencies
 */
angular.module('Shared.PortalSteps.Component', [])

/**
 * Portal steps component
 */
.component('portalSteps', {
  template: `
    <div class="PortalSteps" ng-if="$ctrl.steps.length > 1">
      <span ng-class="{'is-current': step === $ctrl.currentStep}" ng-repeat="step in $ctrl.steps"></span>
    </div>
  `,
  bindings: {
    numSteps: '<',
    currentStep: '<',
    skip: '<',
  },
  controller() {

    /**
     * Init
     */
    this.$onChanges = function() {

      //Initialize steps
      this.steps = [];

      //Skipping
      if (this.skip) {
        this.numSteps -= this.skip;
      }

      //Determine start/end values
      let start = 1;
      let end = this.numSteps;

      //Skipping
      if (this.skip) {
        start += this.skip;
        end += this.skip;
      }

      //Create steps
      for (let i = start; i <= end; i++) {
        this.steps.push(i);
      }
    };
  },
});
