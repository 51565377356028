
/**
 * Module definition and dependencies
 */
angular.module('App.Account.Summary.Card', [])

/**
 * Component
 */
.component('cardAccountSummary', {
  templateUrl: 'account/cards/summary.html',
  controller: 'CardAccountSummaryCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    isLoading: '<',
    transactions: '<',
    accountCreditBalance: '<',
    members: '<',
    member: '<',
    onPay: '&',
    onTransfer: '&',
    onSelectMember: '&',
  },
})

/**
 * Controller
 */
.controller('CardAccountSummaryCtrl', function($state, $modal) {

  /**
   * On changes
   */
  this.$onChanges = function() {

    //Get last payment date
    this.lastPayment = this.transactions
      .filter(trx => trx.isPaid)
      .map(trx => trx.date)
      .find(() => true);

    //Check amount due
    this.amountDue = this.transactions
      .filter(trx => trx.isDue)
      .map(trx => trx.amount)
      .reduce((total, amount) => total + amount, 0);

    //Total owing among all circle members
    const totalOwing = this.members
      .reduce((total, member) => total + member.amountOwing, 0);

    //Flags
    this.hasPaymentsDue = this.amountDue > 0;
    this.hasUnpaid = this.transactions.some(trx => !trx.isPaid) || totalOwing > 0;
  };

  /**
   * Confirm crediting of account, when we have payments due
   */
  this.confirmCreditAccount = function() {

    //No payments due?
    if (!this.hasPaymentsDue) {
      $state.go('account.credit');
      return;
    }

    //Show warning
    $modal
      .open('basic', {
        templateUrl: 'account/modals/confirm-account-credit.html',
        locals: {amountDue: this.amountDue},
      })
      .result
      .then(action => {
        if (action === 'pay') {
          this.pay();
        }
        else {
          $state.go('account.credit');
        }
      });
  };

  /**
   * Select a circle member
   */
  this.selectMember = function(member) {
    this.onSelectMember({$event: {member}});
  };

  /**
   * Pay
   */
  this.pay = function() {
    this.isPaying = true;
    this
      .onPay({$event: {transaction: null}})
      .finally(() => this.isPaying = false);
  };

  /**
   * Transfer
   */
  this.transfer = function() {
    this.onTransfer();
  };
});
