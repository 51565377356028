
/**
 * Module definition and dependencies
 */
angular.module('Shared.Activity.Grade.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('ActivityGrade', function(
  $baseModel
) {

  /**
   * Defaults
   */
  const defaultData = {
    name: '',
    description: '',
  };

  /**
   * Constructor
   */
  function ActivityGrade(data) {
    $baseModel.call(this, angular.extend({}, defaultData, data || {}));
  }

  /**
   * Extend base model
   */
  angular.extend(ActivityGrade.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  ActivityGrade.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Return self
    return this;
  };

  //Return
  return ActivityGrade;
});
