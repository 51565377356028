
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Questions.Card', [])

/**
 * Component
 */
.component('cardEventQuestions', {
  templateUrl: 'admin/event/cards/questions.html',
  controller: 'CardEventQuestionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    event: '<',
    memberships: '<',
    isEdit: '<',
    onSave: '&',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventQuestionsCtrl', function($modal, EventQuestion) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flags
    this.isSaving = false;
    this.isSuper = this.user.isSuper();
  };

  /**
   * Next
   */
  this.next = function() {
    this.card.nextTab();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.card.previousTab();
  };

  /**
   * Save event on server
   */
  this.save = function(questions, notice = true) {

    //Get data
    const {event, isEdit} = this;
    const model = {questions};

    //When adding, dont save on server
    if (!isEdit) {
      return this.onMerge({$event: {event, model}});
    }

    //Get more data
    const {isRecurring, isMultiDay} = event;
    const method = (isRecurring || isMultiDay) ? 'series' : 'instance';

    //Save event
    return this
      .onSave({$event: {event, method, model, notice, isEdit}});
  };

  /**
   * Add question
   */
  this.add = function() {

    //Create question and define handler
    const question = new EventQuestion();
    const {event, memberships} = this;

    //Create copy of questions and define handler
    const questions = event.extract('questions');
    const handler = model => {
      question.fromJSON(model);
      questions.push(question);
      return this.save(questions);
    };

    //Show modal
    $modal.open('editEventQuestion', {locals: {
      question, event, memberships, handler,
    }});
  };

  /**
   * Edit question
   */
  this.edit = function($event) {

    //Get question
    const {question} = $event;
    const {event, memberships} = this;

    //Find and validate index
    const index = event.questions.findIndex(f => f === question);
    if (index === -1) {
      return;
    }

    //Create copy of questions and define handler
    const questions = event.extract('questions');
    const handler = model => {
      questions[index].fromJSON(model);
      return this.save(questions);
    };

    //Show modal
    $modal.open('editEventQuestion', {locals: {
      question, event, memberships, handler, isEdit: true,
    }});
  };

  /**
   * Delete question
   */
  this.delete = function($event) {

    //Get question
    const {question} = $event;
    const {event} = this;

    //Find and validate index
    const index = event.questions.findIndex(f => f === question);
    if (index === -1) {
      return;
    }

    //Create copy of questions and define handler
    const questions = event.extract('questions');
    const handler = () => {
      questions.splice(index, 1);
      return this.save(questions);
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'admin/event/modals/confirm-delete-question.html',
        locals: {question, event, handler},
      });
  };

  /**
   * Toggle question
   */
  this.toggleQuestion = function(question, prop) {

    //Toggle prop
    question[prop] = !question[prop];
    const {isVisible, isRequired} = question;
    const {questions} = this.event;

    //Can't be required if hidden
    if (prop === 'isVisible' && !isVisible && isRequired) {
      question.isRequired = false;
    }

    //Can't be hidden if required
    if (prop === 'isRequired' && !isVisible && isRequired) {
      question.isVisible = true;
    }

    //Update questions
    this
      .save(questions, false)
      .catch(() => question[prop] = !question[prop]);
  };
});
