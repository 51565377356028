
/**
 * Module definition and dependencies
 */
angular.module('Shared.ChangeThresholds.Options', [])

/**
 * Constant definition
 */
.constant('ChangeThresholds', [
  {
    value: 'minutes',
    label: 'Minutes',
  },
  {
    value: 'hours',
    label: 'Hours',
  },
  {
    value: 'days',
    label: 'Days',
  },
]);
