
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.Edit.Controller', [])

/**
 * Controller
 */
.controller('AdminResourceEditCtrl', function($state, $controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminResourceCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {
    $base.$onInit.call(this);

    //Get params
    const {
      showAssignee,
    } = this.transition.params();

    //Check if we need to open card
    this.openAssignmentCard = showAssignee;

    //Check if can edit
    this.canEdit = this.user.isAdmin();
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    $state.go('admin.resource.overview');
  };
});
