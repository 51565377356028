
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Edit.DateTime.Card', [])

/**
 * Component
 */
.component('cardEventEditDateTime', {
  templateUrl: 'admin/event/cards/date-time.html',
  controller: 'CardEventEditDateTimeCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    event: '<',
    googleCalendars: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventEditDateTimeCtrl', function(
  $modal, moment, EventPart, CardCloseReasons
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;
    this.isEdit = true;

    //Min/max dates
    this.minDate = moment();
    this.maxDate = moment().add(2, 'years');

    //Create model subset
    //NOTE: We're using the EventPart model as a wrapper class for the model,
    //so that the start/end date values are correctly set when the start/end
    //times change. Otherwise we run into issue with toJSON conversion of the
    //event, causing invalid start/end dates to be sent back to the server.
    this.model = new EventPart(this.event.extract([
      'startDate', 'endDate', 'startTime', 'endTime',
      'isMultiDay', 'isRecurring', 'googleCalendar',
    ]));

    //Ensure google calendar is an object
    if (!this.model.googleCalendar) {
      this.model.googleCalendar = {
        calendars: [],
        calendarEvents: [],
      };
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {

    //Update model
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Changed start time
   */
  this.changedStartTime = function(time, part = this.model) {
    part.startTime = time;
    if (!part.endTime ||
      (part.endTime <= part.startTime)) {
      let endTime = part.startTime + 60;
      if (endTime > 1440) {
        endTime = 1440;
      }
      part.endTime = endTime;
    }
  };

  /**
   * Changed end time
   */
  this.changedEndTime = function(time, part = this.model) {
    part.endTime = time;
  };

  /**
   * Changed date handler
   */
  this.changedDate = function(date, part = this.model) {

    //Set data
    part.startDate = date;
    part.endDate = date.clone().setTime(part.endTime);

    //Check if in past
    this.isInPast = moment().isAfter(date);
  };

  /**
   * Get min start time
   */
  this.getMinStartTime = function(part, index = -1) {

    //Validate
    if (!part) {
      return 0;
    }

    //First index
    if (index === 0 && part.startDate) {
      const {startDate, endTime} = this.model;
      if (startDate && startDate.isSame(part.startDate, 'day')) {
        return endTime ? endTime : 0;
      }
    }

    //For multi-day parts, the min time is the end time of the
    //previous part, if they are on the same day
    else if (index > 0 && part.startDate) {
      const previous = this.model.parts[index - 1];
      const {startDate, endTime} = previous;
      if (startDate && startDate.isSame(part.startDate, 'day')) {
        return endTime ? endTime : 0;
      }
    }

    //Otherwise, no min time
    return 0;
  };

  /**
   * Get min end time
   */
  this.getMinEndTime = function(part = this.model) {
    if (!part) {
      return 0;
    }
    return part.startTime;
  };

  /**
   * Add day (for multi-day events)
   */
  this.addDay = function(data) {
    const numParts = this.model.parts.length;
    if (!data && numParts) {
      const {startDate, endDate} = this.model.parts[numParts - 1];
      data = {
        startDate: startDate.clone().add(1, 'day'),
        endDate: endDate.clone().add(1, 'day'),
      };
    }
    this.model.parts.push(new EventPart(data));
  };

  /**
   * Remove day (for multi-day events)
   */
  this.removeDay = function(i) {
    if (this.model.parts.length > 1) {
      this.model.parts.splice(i, 1);
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function(method) {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get data and prepare event
    const {event, isEdit} = this;
    const model = Object.assign({}, this.model);
    const {isMultiDay, isRecurring} = event;
    const {startDate} = model;
    const $event = {event, model, method, isEdit};

    //Multi-day, use instance update
    if (isMultiDay) {
      method = 'instance';
    }

    //Confirm how to update if this was a recurring event
    if (isEdit && isRecurring && !method) {
      return $modal
        .open('confirmUpdateEvent', {locals: {event, startDate}})
        .result
        .then(method => this.save(method));
    }

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .catch(error => {
        if (error.name === 'ValidationError') {
          this.error = error;
        }
      })
      .finally(() => this.isSaving = false);
  };
});
