
/**
 * Module definition and dependencies
 */
angular.module('Shared.EditCustomPage.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editCustomPage', {
    templateUrl: 'modals/edit-custom-page.html',
    controller: 'ModalEditCustomPageCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalEditCustomPageCtrl', function(
  $controller, $modalInstance, MembershipConstraintLabels,
  MembershipConstraints, ExistsError, PageIcons, ReplacementTags
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set flags
    this.isSaving = false;
    this.isError = false;

    //Extract model
    this.model = this.page.extract([
      'icon', 'title', 'contents', 'constraint', 'memberships',
    ]);

    //Services
    this.tags = ReplacementTags.member(this.customFields);
    this.membershipConstraints = MembershipConstraintLabels
      .filter(constraint => !constraint.isGuest);

    //Default constraint & icon
    if (!this.model.constraint) {
      this.model.constraint = MembershipConstraints.ALL;
    }
    if (!this.model.icon) {
      this.model.icon = PageIcons[0].icons[0];
    }
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isErrorExists = false;
    this.isErrorOther = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(error => {
        if (error instanceof ExistsError) {
          this.isErrorExists = true;
        }
        else {
          this.isErrorOther = true;
        }
      })
      .finally(() => this.isSaving = false);
  };
});
