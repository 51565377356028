
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Controller', [])

/**
 * Base controller
 */
.controller('AdminMembershipCtrl', function(
  $q, $modal, $store, $notice, Intercom
) {

  /**
   * Merge
   */
  this.merge = function($event) {

    //Get membership and model
    const {membership, model} = $event;
    this.isDirty = true;

    //Merge
    membership.merge(model);
    return $q.resolve();
  };

  /**
   * Save
   */
  this.save = function($event) {

    //Get membership and model
    const {membership, model, isEdit} = $event;
    const {singular} = this.module;

    //Save
    return $store.memberships
      .save(membership, model)
      .then(membership => {
        if (isEdit) {
          $notice.show(`${singular} updated`);
        }
        else {
          Intercom.event('Created membership');
          $notice.show(`${singular} created`);
        }
        this.onSaved({membership});
        this.club.markStepComplete('setupMembershipTypes', true);
      })
      .catch(error => {
        if (isEdit) {
          $notice.showError(`Failed to update ${singular}`);
        }
        else {
          $notice.showError(`Failed to create ${singular}`);
        }
        throw error;
      });
  };

  /**
   * Delete
   */
  this.delete = function($event) {

    //Get membership and define handler
    const {membership} = $event;
    const {singular} = this.module;
    const handler = () => membership.delete();

    //Open confirmation dialog
    $modal
      .open('basic', {
        templateUrl: `admin/membership/modals/confirm-delete-membership.html`,
        locals: {membership, handler},
      })
      .result
      .then(() => {
        $notice.show(`${singular} removed`);
        this.onDeleted({membership});
      })
      .catch(() => $notice.showError(`Failed to remove ${singular}`));
  };

  /**
   * Archive/restore
   */
  this.toggleArchived = function($event) {

    //Get membership and define handler
    const {membership, isArchived} = $event;
    const {singular} = this.module;
    const handler = () => membership.patch({isArchived});

    //Show confirmation modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/membership/modals/confirm-toggle-archived.html',
        locals: {membership, handler},
      })
      .result
      .then(() => {
        $notice.show(isArchived ? `${singular} archived` : `${singular} restored`);
        this.onPatched({membership, field: 'isArchived'});
      });
  };

  /**
   * No-ops (to extend as needed)
   */
  this.onSaved = function() {};
  this.onPatched = function() {};
  this.onDeleted = function() {};
});
