
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.ConfirmDelete.Modal', [
  'App.Admin.Event.RecurrenceOptions.Constant',
])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('confirmDeleteEvent', {
    templateUrl: 'admin/event/modals/confirm-delete-event.html',
    controller: 'ModalConfirmDeleteEventCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalConfirmDeleteEventCtrl', function(
  $controller, $modalInstance, EventRecurrenceOptions, EventMultiDayOptions
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Init
    this.isConfirming = false;
    this.method = 'instance';
    this.toggleRefund = false;

    //Set options
    this.recurrenceOptions = EventRecurrenceOptions;
    this.multiDayOptions = EventMultiDayOptions;
  };

  /**
   * Update method
   */
  this.updateMethod = function(method) {
    this.method = method;
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Set flag
    this.isConfirming = true;

    //Confirm
    this
      .handler(this.method)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isConfirming = false);
  };
});
