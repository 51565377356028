
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.Controller', [])

/**
 * Base controller
 */
.controller('AdminResourceCtrl', function(
  $q, $store, $modal, $notice, Intercom
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Load custom fields in the background
    this.loadCustomFields();
  };

  /**
   * Load custom fields
   */
  this.loadCustomFields = function() {

    //Already have promise
    if (this.loadCustomFieldsPromise) {
      return this.loadCustomFieldsPromise;
    }

    //Load
    this.loadCustomFieldsPromise = $store.customFields
      .query({model: 'Resource'})
      .then(fields => this.customFields = fields);

    //Return promise
    return this.loadCustomFieldsPromise;
  };

  /**
   * Save
   */
  this.save = function($event) {

    //Get resource and model
    const {resource, model, isEdit} = $event;
    const {module: {singular}} = this;

    //Save
    return resource
      .save(model)
      .then(resource => {

        //Show notice
        if (isEdit) {
          $notice.show(`${singular} updated`);
        }
        else {
          Intercom.event('Created resource');
          $notice.show(`${singular} added`);
        }
        this.onSaved({resource});
      })
      .catch(error => {
        if (isEdit) {
          $notice.showError(`Failed to update ${singular}`);
        }
        else {
          $notice.showError(`Failed to add ${singular}`);
        }
        throw error;
      });
  };

  /**
   * Merge
   */
  this.merge = function($event) {

    //Get resource and model
    const {resource, model} = $event;
    this.isDirty = true;

    //Merge
    resource.merge(model);
    return $q.resolve();
  };

  /**
   * Delete
   */
  this.delete = function($event) {

    //Get resource
    const {resource} = $event;
    const {singular} = this.module;

    //Define handler
    const handler = function() {
      return resource.delete();
    };

    //Open confirmation dialog
    $modal
      .open('basic', {
        templateUrl: `admin/resource/modals/confirm-delete-resource.html`,
        locals: {resource, handler},
      })
      .result
      .then(() => {
        $notice.show(`${singular} removed`);
        this.onDeleted({resource});
      });
  };

  /**
   * No-ops (to extend as needed)
   */
  this.onSaved = function() {};
  this.onPatched = function() {};
  this.onDeleted = function() {};
});
