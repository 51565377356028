
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.EditTag.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editTag', {
    templateUrl: 'admin/system/modals/edit-tag.html',
    controller: 'ModalEditTagCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalEditTagCtrl', function(
  $controller, $modalInstance, $timeout, $modal, $element, moment,
  Tag, TagValidity, Weekdays, TagTypes, TagValidityUnits, TagValidityOptions,
  ValidationError, ExistsError, Settings, KeyDown
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Add USB tag listener if needed
    if (this.system.hasIButton) {
      this.boundUsbTagListener = this.usbTagListener.bind(this);
      KeyDown.addListener(this.boundUsbTagListener);
    }

    //Flags
    this.isEdit = !!this.tag.id;
    this.isReading = false;
    this.isSaving = false;
    this.isErrorSaving = false;

    //Services
    this.Weekdays = Weekdays;
    this.TagTypes = TagTypes;
    this.tagTypes = TagTypes.filter(type => this.system.hasTagType(type.value));
    this.TagValidityUnits = TagValidityUnits;
    this.TagValidityOptions = TagValidityOptions;

    //Create model subset
    this.model = new TagValidity(this.tag.extract([
      'type', 'name', 'number', 'facility', 'doors',
      'canOverrideLights', 'canOverrideDoors', 'validFrom', 'validTill',
    ]));

    //Set default validity
    if (this.model.validFrom && this.model.validTill) {
      this.validity = 'custom';
    }
    else {
      this.validity = 'unlimited';
    }

    //Set default type
    if (!this.model.type) {
      this.model.type = this.system.tagTypes[0];
    }

    //Set default facility number
    if (!this.model.facility) {
      this.model.facility = Settings.get('system.defaultFacilityNumber');
    }
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    if (this.boundUsbTagListener) {
      KeyDown.removeListener(this.boundUsbTagListener);
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.$input = angular.element($element.find('input')[1]);
  };

  /**
   * Change tag validity selection
   */
  this.changeValidity = function(validity) {

    //Set validity
    this.validity = validity;

    //Fixed validity, default values
    if (this.validity === 'fixed') {
      if (!this.model.validForAmount) {
        this.model.validForAmount = 2;
      }
      if (!this.model.validForUnit) {
        this.model.validForUnit = 'hours';
      }
    }

    //Custom validity, default dates
    else if (this.validity === 'custom') {
      if (!this.model.validFrom) {
        this.model.validFrom = moment();
      }
      if (!this.model.validTill) {
        this.model.validTill = moment().add(2, 'hours');
      }
    }

    //Unlimited
    else if (this.validity === 'unlimited') {
      this.model.validFrom = null;
      this.model.validTill = null;
    }
  };

  /**
   * Get min valid till time
   */
  this.getMinValidTillTime = function() {
    const {validFrom, validTill} = this.model;
    if (!validFrom || !validTill) {
      return 0;
    }
    if (validFrom.isSame(validTill, 'day')) {
      return validFrom.getTime() + 1;
    }
    return 0;
  };

  /**
   * USB tag listener
   */
  this.usbTagListener = function(buffer) {

    //Validate data
    const data = Tag.fromReader(buffer);
    if (!data) {
      return;
    }

    //Process data
    if (this.system.isLimited) {
      this.scanEffect(data.short);
    }
    else {
      this.scanEffect(data.number);
    }

    //Return true to stop further processing
    return true;
  };

  /**
   * Scan effect helper
   */
  this.scanEffect = function(number) {
    if (number) {
      this.model.number = '';
      this.addDigit(number);
    }
  };

  /**
   * Add digit
   */
  this.addDigit = function(number) {
    this.model.number += String(number[0]);
    if (number.length > 1) {
      $timeout(() => {
        this.addDigit(number.substring(1));
      }, 25);
    }
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;

    //Type changed
    if (property === 'type') {
      this.model.number = '';
      if (value === TagTypes.PIN) {
        this.generateRandomNumber();
      }
    }
  };

  /**
   * Generate random number
   */
  this.generateRandomNumber = function() {

    //Flag as generating
    this.isGeneratingRandomNumber = true;

    //Generate
    Tag
      .randomNumber()
      .then(number => this.scanEffect(number))
      .finally(() => this.isGeneratingRandomNumber = false);
  };

  /**
   * Reset form validity
   */
  this.resetFormValidity = function() {
    this.form.$setPristine();
    this.isErrorSaving = false;
    if (this.form.tag) {
      this.form.tag.$invalid = false;
      delete this.form.tag.$error.invalid;
    }
    else if (this.form.pin) {
      this.form.pin.$invalid = false;
      delete this.form.pin.$error.invalid;
      delete this.form.pin.$error.max;
    }
    this.form.$invalid = false;
  };

  /**
   * Confirm
   */
  this.confirm = function(overwrite) {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset error
    this.isErrorSaving = false;
    this.isServicePinError = false;

    //Use save handler
    this.isSaving = true;
    this
      .handler(this.model, overwrite)
      .then(result => {
        $modalInstance.resolve(result);

        //Update facility code
        if (this.model.type === 'card') {
          if (!Settings.has('system.defaultFacilityNumber')) {
            Settings.update('system.defaultFacilityNumber', this.model.facility);
          }
        }
      })
      .catch(error => {

        //Tag already exists
        if (error instanceof ExistsError) {
          if (error.data.isServicePin) {
            this.isServicePinError = true;
            return;
          }
          const tag = new Tag(error.data.tag);
          const isOwn = this.member && this.member.isSame(tag.member);
          $modal
            .open('basic', {
              templateUrl: 'modals/confirm/confirm-reassign-tag.html',
              locals: {tag, isOwn},
            })
            .result
            .then(() => this.confirm(true));
          return;
        }

        //Validation error
        if (error instanceof ValidationError) {
          return error.setFieldsValidity(this.form);
        }

        //Generic error
        this.isErrorSaving = true;
      })
      .finally(() => this.isSaving = false);
  };
});
