
/**
 * Module definition and dependencies
 */
angular.module('Shared.MemberSearch.Component', [])

/**
 * Member search component
 */
.component('memberSearch', {
  templateUrl: 'shared/member-search/member-search.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    member: '<ngModel',
    includeStaff: '<',
    includePending: '<',
    includeArchived: '<',
    isDisabled: '<ngDisabled',
    onChange: '&',
  },

  /**
   * Component controller
   */
  controller(Member) {

    /**
     * Search
     */
    this.search = function(name) {
      const {includeStaff, includePending, includeArchived} = this;
      const filter = {name, includeStaff, includePending, includeArchived};
      return Member.findByName(filter);
    };

    /**
     * Set member
     */
    this.setMember = function(member) {
      this.member = member;
      this.onChange({member});
    };
  },
});
