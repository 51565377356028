
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Edit.Attendance.Card', [])

/**
 * Component
 */
.component('cardEventEditAttendance', {
  templateUrl: 'admin/event/cards/attendance.html',
  controller: 'CardEventEditAttendanceCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    event: '<',
    attendees: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventEditAttendanceCtrl', function() {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;
    this.isEdit = true;

    //Create model subset
    this.model = this.event.extract([
      'maxAttendees', 'minAge', 'maxAge', 'allowUnknownAge',
    ]);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get data and prepare event
    const {event, model, isEdit} = this;
    const {isRecurring, isMultiDay} = event;
    const method = (isRecurring || isMultiDay) ? 'series' : 'instance';
    const $event = {event, model, method, isEdit};

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event})
      .then(() => this.form.$setPristine())
      .catch(error => {
        if (error.name === 'ValidationError') {
          this.error = error;
        }
      })
      .finally(() => this.isSaving = false);
  };
});
