
/**
 * Module definition and dependencies
 */
angular.module('Shared.AutoGrow.Directive', [])

/**
 * Directive
 */
.directive('autogrow', function($window) {
  return {
    restrict: 'A',
    controller($element, $attrs, $timeout) {

      //Define properties
      this.offset = 0;
      this.maxHeight = 0;

      /**
       * Auto grow handler
       */
      this.autogrow = function() {

        //Initialize
        let newHeight = 0;

        //Adjust height if needed
        if ($element[0].scrollHeight > this.maxHeight) {
          $element[0].style.overflowY = 'scroll';
          newHeight = this.maxHeight;
        }
        else {
          $element[0].style.overflowY = 'hidden';
          $element[0].style.height = 'auto';
          newHeight = $element[0].scrollHeight + this.diff;
        }
        $element[0].style.height = newHeight + 'px';
      };

      /**
       * Post link
       */
      this.$postLink = function() {
        $timeout(() => {

          //Get settings
          const rows = parseInt($attrs.rows || 1);
          const maxRows = parseInt($attrs.maxRows || 1000);

          //Determine max allowed height and offset
          const style = $window.getComputedStyle($element[0], null);
          const offset =
            parseInt(style.paddingTop) +
            parseInt(style.paddingBottom);
          const diff = $element[0].offsetHeight - $element[0].scrollHeight;
          const lineHeight = ($element[0].offsetHeight - offset) / rows;
          const maxHeight = (lineHeight * maxRows) + offset - diff;

          //Set as properties
          this.offset = offset;
          this.diff = diff;
          this.maxHeight = maxHeight;

          //Create handler
          this.autogrowHandler = this.autogrow.bind(this);

          //Watch input
          // $scope.$watch($attrs.ngModel, this.autogrowHandler);
          $element.on('input', this.autogrowHandler);

          //Auto-resize when there's content on page load
          if ($element[0].value !== '') {
            this.autogrow();
          }
        });
      };

      /**
       * Clean up
       */
      this.$onDestroy = function() {
        $element.off('input', this.autogrowHandler);
      };
    },
  };
});
