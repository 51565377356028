
/**
 * Module definition and dependencies
 */
angular.module('Shared.AuditLog.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('auditLog', {
    model: 'AuditLog',
    url: '/auditLog',
    params: null,
    actions: {
      query: {
        method: 'GET',
        dataKey: 'auditLogs',
        isArray: true,
        isModel: true,
      },
    },
  });
})

/**
 * Model definition
 */
.factory('AuditLog', function($baseModel, $api, $sync) {

  /**
   * Constructor
   */
  function AuditLog(data) {
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(AuditLog.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  AuditLog.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('member', 'Member');

    //Return self
    return this;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Check if has member
   */
  AuditLog.prototype.hasMember = function(memberId) {
    return (this.member && this.member.id === memberId);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  AuditLog.query = function(filter) {
    return $api.auditLog.query(filter);
  };

  /**
   * Export
   */
  AuditLog.export = function(filter) {
    return $sync.get('auditLog/export/csv', filter, 'Exporting...');
  };

  //Return
  return AuditLog;
});
