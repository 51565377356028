
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Add.Details.Card', [])

/**
 * Component
 */
.component('cardMembershipAddDetails', {
  templateUrl: 'admin/membership/cards/details.html',
  controller: 'CardMembershipAddDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    membership: '<',
    activities: '<',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipAddDetailsCtrl', function(
  $controller, $element, $focus, CardCloseReasons
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardMembershipEditDetailsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark as add
    this.isEdit = false;
    this.isAdd = true;

    //If club has only one activity, add it right away
    if (this.activities.length === 1) {
      this.model.activities = [this.activities[0].id];
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {

    //Focus on input
    if (!this.model.name) {
      $focus($element.find('input')[0]);
    }
    else {
      $focus($element.find('input')[1]);
    }
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {membership, model} = this;
    this.onMerge({$event: {membership, model}});
    this.form.$setPristine();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge
    this.merge();

    //Next card
    if (this.membership.isLinked) {
      this.card.open('membership.conditions', CardCloseReasons.OPENED_NEXT);
    }
    else {
      this.card.next();
    }
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previous();
  };
});
