
/**
 * Module definition and dependencies
 */
angular.module('Shared.Roles.Options', [])

/**
 * Constant definition
 */
.constant('Roles', [
  {
    value: 'member',
    label: 'Member',
  },
  {
    value: 'eventManager',
    label: 'Event manager',
  },
  {
    value: 'viewer',
    label: 'Viewer',
  },
  {
    value: 'admin',
    label: 'Administrator',
  },
]);
