
/**
 * Module definition and dependencies
 */
angular.module('Shared.Flow.ImportPreview.Component', [])

/**
 * Grid component
 */
.component('flowImportPreview', {
  templateUrl: 'modals/flow/import-preview.html',
  bindings: {
    help: '<',
    data: '<',
    meta: '<',
    errors: '<',
    fields: '<',
    fileHeaders: '<',
    item: '<',
    requiresConsent: '<',
    isAllowedCustomFields: '<',
    onPrev: '&',
    onConfirm: '&',
    onClose: '&',
  },

  /**
   * Controller
   */
  controller($storage, Helpers, Interface) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Required fields
      this.requiredFields = this.fields
        .filter(field => field.isRequired)
        .map(field => field.label);

      //Extract hints
      this.hints = this.fields.reduce((hints, field) => {
        if (field.hint) {
          hints[field.prop] = field.hint;
        }
        else if (field.isInt) {
          hints[field.prop] = `Use integer numbers, e.g. 1, 2, 3`;
        }
        else if (field.isPositiveCurrency) {
          hints[field.prop] = `Use positive amounts only, e.g. $205.25 or 10.70`;
        }
        else if (field.isDate) {
          hints[field.prop] = `Use date format YYYY-MM-DD or DD-MM-YYYY`;
        }
        else if (field.isBoolean) {
          hints[field.prop] = `Use TRUE or FALSE`;
        }
        else if (field.isEmail) {
          hints[field.prop] = `Use valid email addresses`;
        }
        else if (field.isArray) {
          hints[field.prop] = `Separate multiple values by a comma`;
        }
        else if (field.isMultiLine) {
          hints[field.prop] = `Multi-line text allowed`;
        }
        else if (field.isMobile) {
          hints[field.prop] = `Include mobile prefix`;
        }
        else if (field.isPhone) {
          hints[field.prop] = `Include regional prefix`;
        }
        else if (field.enum) {
          hints[field.prop] = `One of ${field.enum.join(', ')}`;
        }
        else if (field.isRequired) {
          hints[field.prop] = `Required`;
        }
        return hints;
      }, {});

      //Initialize headers
      this.lastHeaders = $storage.get(`importHeaders.${this.item}`);

      //Auto detect based on file headers
      if (this.fileHeaders) {
        this.autoHeaders();
      }

      //Set last used headers if we have any
      else if (this.lastHeaders) {
        this.useLastHeaders();
      }

      //Or use blank
      else {
        this.resetHeaders();
      }
    };

    /**
     * Toggle consent flag
     */
    this.toggleConsent = function(hasConsent) {
      this.hasConsent = hasConsent;
      this.isErrorConsent = false;
    };

    /**
     * Make column map
     */
    this.makeColumnMap = function() {

      //Initialize columns map
      this.columnMap = this.headers.reduce((map, prop, i) => {
        map[i] = prop;
        return map;
      }, {});
    };

    /**
     * Determine available fields
     */
    this.determineAvailableFields = function() {

      //Initialize available fields
      this.availableFields = {};

      //Get selected values
      const selected = Object.values(this.columnMap);

      //Filter out ones that haven't been selected yet
      for (let i = 0; i < this.data[0].length; i++) {
        this.availableFields[i] = this.fields
          .filter(field => (
            this.columnMap[i] === field.prop || !selected.includes(field.prop)
          ));
      }
    };

    /**
     * Set column
     */
    this.setColumn = function(index, prop) {
      this.columnMap[index] = prop;
      this.determineAvailableFields();
    };

    /**
     * Confirm headers and upload
     */
    this.confirmHeaders = function() {

      //Clear errors
      this.fieldErrors = [];

      //Create headers from map values
      const headers = Object.values(this.columnMap);

      //Go over field and check requirements
      for (const field of this.fields) {

        //Check if we have this field
        const hasField = headers.includes(field.prop);

        //Required
        if (field.isRequired && !hasField) {
          this.fieldErrors.push(
            `The field <strong>${field.label}</strong> is required`
          );
        }

        //Linked fields
        if (field.linkedFields && hasField) {
          for (const linked of field.linkedFields) {
            if (!headers.includes(linked)) {
              const {label} = this.fields.find(field => field.prop === linked);
              this.fieldErrors.push(
                `The field <strong>${label}</strong> is required if importing <strong>${field.label}</strong>`
              );
            }
          }
        }
      }

      //Check if any errors present
      if (this.fieldErrors.length > 0) {
        return;
      }

      //Store for future use
      $storage.set(`importHeaders.${this.item}`, headers);

      //Handle consent
      if (this.requiresConsent && !this.hasConsent) {
        this.isErrorConsent = true;
        Interface.scrollIntoView('ModalBottom', {top: 1, topOffset: -16});
        return;
      }

      //Onwards
      this.onConfirm({$event: {headers}});
    };

    /**
     * Auto detect headers
     */
    this.autoHeaders = function() {

      //Must have file headers
      if (!Array.isArray(this.fileHeaders)) {
        return;
      }

      //Reset headers
      this.headers = [];

      //Get copy of available fields
      const fields = this.fields.slice(0);

      //Loop headers
      for (const header of this.fileHeaders) {

        //Build regex and find field
        const prop = Helpers.escapeRegex(header).replace(/ /g, '\\s?');
        const lower = prop.toLowerCase();
        const regex = new RegExp(prop, 'i');
        const i = fields
          .findIndex(({prop, label}) => (
            lower === prop.toLowerCase() ||
            lower === label.toLowerCase() ||
            prop.match(regex) ||
            label.match(regex)
          ));

        //Add to headers if found and if not already in headers
        if (i >= 0 && !this.headers.includes(fields[i].prop)) {
          this.headers.push(fields[i].prop);
          fields.splice(i, 0);
        }
        else {
          this.headers.push(null);
        }
      }

      //Remake map and determine available fields
      this.makeColumnMap();
      this.determineAvailableFields();
    };

    /**
     * Use last headers
     */
    this.useLastHeaders = function() {

      //Use last headers
      this.headers = this.lastHeaders.map(h => h);

      //Remake map and determine available fields
      this.makeColumnMap();
      this.determineAvailableFields();
    };

    /**
     * Reset headers
     */
    this.resetHeaders = function() {

      //Reset headers
      this.headers = [];

      //Remake map and determine available fields
      this.makeColumnMap();
      this.determineAvailableFields();
    };
  },
});
