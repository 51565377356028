
/**
 * Module definition and dependencies
 */
angular.module('Shared.Event.Question.Model', [
  'BaseModel.Service',
])

/**
 * Model definition
 */
.factory('EventQuestion', function(CustomField, CustomFieldTypes) {

  /**
   * Default data
   */
  const defaultData = {
    type: CustomFieldTypes.TEXT,
    options: '',
    isVisible: true,
    isRequired: false,
  };

  /**
   * Constructor
   */
  function EventQuestion(data, event) {
    CustomField.call(this, angular.extend({}, defaultData, data || {}), event);
  }

  /**
   * Extend base model
   */
  angular.extend(EventQuestion.prototype, CustomField.prototype);

  /**
   * To JSON converter
   */
  EventQuestion.prototype.toJSON = function(data) {

    //Call parent method
    const json = CustomField.prototype.toJSON.call(this, data);

    //Strip data
    delete json.isEditable;

    //Return
    return json;
  };

  //Return
  return EventQuestion;
});
