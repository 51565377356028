
/**
 * Module definition and dependencies
 */
angular.module('App.Auth.Service', [])

/**
 * Service definition
 */
.factory('Auth', function(
  $q, $state, $store, $modal, $window, $log, moment,
  $httpParamSerializer, Token, Config, NotAuthenticatedError, Filters,
  Kiosk, Intercom, Broadcast
) {

  //Helper vars
  let refreshPromise = null;

  /**
   * Service class
   */
  const Auth = {

    //Properties
    token: null,
    redirectState: null,
    lastState: null,
    listeners: {},
    broadcast: new Broadcast('auth'),

    /**
     * Check if we're currently authenticated
     */
    isAuthenticated() {
      return !!this.token;
    },

    /**
     * Check if token has expired
     */
    isExpired() {
      return (this.token && this.token.isExpired());
    },

    /**
     * Check if token is about to expire
     */
    isExpiring(offset = 60) {
      return (this.token && this.token.isExpiring(offset));
    },

    /**
     * Check if we have recently re authenticated
     */
    hasRecentlyReAuthenticated() {
      const {lastReAuthenticated: last} = this;
      const now = moment();
      const {reAuthenticationThreshold: threshold} = Config.auth;
      return (last && last.add(threshold, 'seconds').isAfter(now));
    },

    /**
     * Ensure we recently authenticated
     */
    ensureRecentlyReAuthenticated() {

      //Check if authenticated and recently re authenticated
      if (this.isAuthenticated() && this.hasRecentlyReAuthenticated()) {
        return $q.resolve();
      }

      //Open re authentication modal
      return $modal
        .open('reAuthenticate')
        .result;
    },

    /**
     * Ensure not expiring within a certain amount of time
     */
    ensureNotExpiring(offset) {

      //No offset provided, all good
      if (!offset) {
        return $q.resolve();
      }

      //If not expiring, all good
      if (!this.isExpiring(offset)) {
        return $q.resolve();
      }

      //Try to refresh, and if that fails, ask for re-authentication
      return this
        .refresh()
        .catch(() => {
          return $modal
            .open('reAuthenticate')
            .result;
        });
    },

    /**************************************************************************
     * Access token management
     ***/

    /**
     * Get raw access token string
     */
    getAccessToken() {
      if (this.token) {
        return this.token.accessToken;
      }
      return '';
    },

    /**
     * Clear underlying access token
     */
    clearAccessToken() {
      if (this.token) {
        this.token.accessToken = null;
      }
    },

    /**************************************************************************
     * State management
     ***/

    /**
     * Set redirect state
     */
    setRedirectState(state) {
      this.redirectState = state || null;
    },

    /**
     * Go to login state
     */
    goToLoginState(redirectState, loginParams) {

      //Already there
      if ($state.current.name.match(/^login/)) {
        return $q.resolve();
      }

      //Already going
      if (this.isGoingToLoginState) {
        return $q.resolve();
      }

      //Flag as going
      this.isGoingToLoginState = true;

      //Remember redirect state if given
      if (redirectState) {
        this.setRedirectState(redirectState);
      }

      //Already authenticated? Go straight to redirect state
      if (this.isAuthenticated()) {
        return this
          .goToPostLoginState()
          .finally(() => this.isGoingToLoginState = false);
      }

      //Redirect to login state
      return $state
        .go('login', loginParams || null)
        .finally(() => this.isGoingToLoginState = false);
    },

    /**
     * Go to post login state
     */
    goToPostLoginState() {

      //Ignored states
      const ignoreStates = [
        'login', 'loginCredentials',
        'oAuthCallback', 'resetPassword', 'forgotPassword', 'forgotUsername',
        'signupClub.creating',
      ];

      //Stay where you are
      if (!ignoreStates.includes($state.current.name)) {
        return $q.resolve();
      }

      //Get redirect state or last state
      const redirect = this.redirectState || this.lastState || 'home';
      let name, params;

      //String given?
      if (typeof redirect === 'string') {
        name = redirect;
        params = {};
      }
      else if (
        typeof redirect === 'object' &&
        typeof redirect.name === 'string'
      ) {
        name = redirect.name;
        params = redirect.params;
      }
      else {
        name = redirect.name();
        params = redirect.params();
      }

      //Redirect
      return $state
        .go(name, params)
        .catch(error => {
          if (name !== 'home') {
            $log.log(`Going to home state due to error`);
            return $state.go('home');
          }
          throw error;
        })
        .then(() => this.redirectState = null);
    },

    /**
     * Set last successful target state
     */
    setLastState(state) {

      //No state given
      if (!state) {
        return this.lastState = null;
      }

      //Ignore some states
      const name = state.name();
      if (name.match(/^(login|error|forgot|reset|register|welcome|oAuthCallback)/)) {
        return;
      }

      //Set last state
      this.lastState = state;
    },

    /**************************************************************************
     * Login and logout
     ***/

    /**
     * Login with credentials
     */
    loginWithCredentials(credentials) {

      //Clear any existing token
      this.clearAccessToken();

      //Clear stores (to avoid caching unauthenticated data)
      this.clearStores();

      //Obtain new one
      return Token
        .obtain('password', credentials)
        .then(token => this.onAuthenticated(token))
        .then(() => this.goToPostLoginState());
    },

    /**
     * Login with token
     */
    loginWithToken(token) {
      return Token
        .use(token)
        .then(token => this.onAuthenticated(token))
        .then(() => this.goToPostLoginState());
    },

    /**
     * Login with oAuth provider
     */
    loginWithOAuthProvider(provider, action = 'login', state = {}) {

      //If object, get ID
      if (typeof provider === 'object') {
        provider = provider.id;
      }

      //Always append action to state
      state.action = action;

      //Determine path
      const {baseUrl} = Config.api;
      const path = this.getOAuthPath(action);
      const qs = state ? ('?' + $httpParamSerializer(state)) : '';

      //Wrap in promise
      return $q
        .resolve()
        .then(() => {
          $window.location.href = `${baseUrl}/${path}/${provider}${qs}`;
        });
    },

    /**
     * Get oAuth path for given action
     */
    getOAuthPath(action) {
      switch (action) {
        case 'register':
          return 'user/register';
        case 'welcome':
          return 'user/welcome';
        case 'signup':
          return 'user/signup';
        case 'login':
        case 'connect':
        default:
          return 'auth';
      }
    },

    /**
     * Logout manually
     */
    logout(silent = false) {

      //Not authenticated
      if (!this.isAuthenticated()) {
        return;
      }

      //Clear access token from storage
      Token.clearFromStorage();

      //Broadcast event
      if (!silent) {
        this.broadcast.send({action: 'logout'});
      }

      //Unauthenticated handler
      this.onUnauthenticated();
    },

    /**
     * Logout automatically
     */
    logoutAutomatically(silent = false) {

      //Not authenticated
      if (!this.isAuthenticated()) {
        return;
      }

      //Clear access token from storage
      Token.clearFromStorage();

      //Broadcast event
      if (!silent) {
        this.broadcast.send({action: 'logout', isAutomatic: true});
      }

      //Unauthenticated handler
      this.onUnauthenticated(true);
    },

    /**
     * Clear token to logout passively without redirection
     */
    logoutPassively() {

      //Clear token model
      this.token = null;

      //Clear access token from storage
      Token.clearFromStorage();
    },

    /**
     * Refresh
     */
    refresh() {

      //Already refreshing?
      if (refreshPromise) {
        return refreshPromise;
      }

      //Don't have an access token?
      if (!this.token) {
        return $q.reject(new NotAuthenticatedError());
      }

      //Obtain token from server
      return refreshPromise = Token
        .obtain('refresh_token')
        .then(token => this.onAuthenticated(token))
        .finally(() => refreshPromise = null);
    },

    /**
     * Re-authenticate
     */
    reAuthenticate(credentials) {

      //Obtain new token
      return Token
        .obtain('password', credentials)
        .then(token => this.onAuthenticated(token))
        .then(token => {

          //Set last authenticated flag
          this.lastReAuthenticated = moment();
          return token;
        });
    },

    /**************************************************************************
     * Auth status change handlers
     ***/

    /**
     * On authenticated
     */
    onAuthenticated(token) {

      //Set token model
      this.token = token;

      //Refresh user/club data
      $store.club.get(true);
      $store.user.get(true);

      //Broadcast event
      const {accessToken} = token;
      this.broadcast.send({action: 'authenticated', accessToken});

      //Return the token
      return this.token;
    },

    /**
     * On unauthenticated
     */
    onUnauthenticated(isAutomatic) {

      //Check how long ago token has expired
      const {token} = this;
      const isExpired = (isAutomatic && token && token.hasRecentlyExpired());

      //Clear token model
      this.token = null;

      //If this was a manual logout, clear any stored last state
      if (!isAutomatic) {
        this.lastState = null;
      }

      //Clean up
      $modal.closeAll();
      Intercom.shutdown();

      //Reset filters
      Filters.reset();

      //If kiosk mode is enabled, go there
      if (Kiosk.isEnabled()) {
        const state = Kiosk.getDefaultPage();
        $state.go(state).then(() => this.clearStores());
        return;
      }

      //Go to login state
      this
        .goToLoginState(this.lastState, {
          error: isExpired ? 'sessionExpired' : null,
        })
        .then(() => {
          this.clearStores();
          // NOTE: Often getting errors where people are seemingly still logged in,
          // but there is no club data available. I suspect it has to do with us
          // clearing this data. Will comment out for now, as it doesn't have a large
          // impact, only that the generic login screen will show club details, which
          // is not the end of the world. Later, if the login screen is a separate app,
          // this won't be an issue either.
          // if (Domain.isGeneric) {
          //   this.clearData();
          // }
        });

      //Broadcast event
      this.broadcast.send({action: 'unauthenticated', isExpired, isAutomatic});
    },

    /**
     * Clear data
     */
    // clearData() {

    //   //Clear global scope and settings
    //   GlobalScope.clear('background', 'settings', 'modules', 'integrations');
    //   Settings.clear();

    //   //Clear club
    //   $store.club.clear();
    // },

    /**
     * Clear stores
     */
    clearStores() {

      //Clear stores
      for (const key in $store) {
        if (typeof $store[key].clear === 'function' && key !== 'club') {
          $store[key].clear();
        }
      }
    },

    /**************************************************************************
     * Init
     ***/

    /**
     * Initialize
     */
    init() {

      //Find existing access token and validate it. If invalid, clear.
      const token = Token.existing();
      if (!token) {
        return;
      }

      //Authenticated
      this.onAuthenticated(token);
    },
  };

  //Init
  Auth.init();

  //Handle broadcast events
  Auth.broadcast.receive(event => {

    //Get data
    const {data} = event;

    //Logout
    if (data.action === 'logout') {
      if (Auth.isAuthenticated()) {
        if (data.isAutomatic) {
          Auth.logoutAutomatically(true);
        }
        else {
          Auth.logout(true);
        }
      }
      return;
    }

    //Authenticated with new access token
    if (data.action === 'authenticated') {
      const newAccessToken = data.accessToken;
      const oldAccessToken = Auth.getAccessToken();
      if (newAccessToken && newAccessToken !== oldAccessToken) {
        Auth.init();
        Auth.goToPostLoginState();
      }
      return;
    }
  });

  //Return
  return Auth;
});
