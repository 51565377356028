
/**
 * Module definition and dependencies
 */
angular.module('Shared.CheckInLog.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('checkInLog', {
    model: 'CheckInLog',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'checkInLogs',
        isArray: true,
        isModel: true,
      },
      create: {
        method: 'POST',
        withCredentials: true, //NOTE: for cross domain kiosk cookie
      },
    },
  });
})

/**
 * Model definition
 */
.factory('CheckInLog', function($baseModel, $api, $sync) {

  /**
   * Constructor
   */
  function CheckInLog(data) {
    $baseModel.call(this, data);

    //Unknown property
    Object.defineProperty(this, 'isUnknown', {
      get() {
        return (!this.member && !this.tag);
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(CheckInLog.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  CheckInLog.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('tag', 'Tag', false);
    this.convertToModel('member', 'Member', false);

    //Return self
    return this;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Check if has member
   */
  CheckInLog.prototype.hasMember = function(memberId) {
    return (this.member && this.member.id === memberId);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  CheckInLog.query = function(filter) {
    return $api.checkInLog.query(filter);
  };

  /**
   * Create new log entry
   */
  CheckInLog.create = function(data) {
    return $api.checkInLog.create(data);
  };

  /**
   * Export
   */
  CheckInLog.export = function(filter) {
    return $sync.get('checkInLog/export/csv', filter, 'Exporting...');
  };

  //Return
  return CheckInLog;
});
