
/**
 * Module definition and dependencies
 */
angular.module('Shared.IconLabel.Component', [])

/**
 * Icon label component
 */
.component('iconLabel', {
  templateUrl: 'shared/icon-label/icon-label.html',
  transclude: true,
  bindings: {
    member: '<',
    icon: '@',
  },

  /**
   * Controller
   */
  controller($element, $attrs) {

    /**
     * Initialization
     */
    this.$onInit = function() {

      //Propagate classes
      $element[0].className += ' IconLabelComponent';
      this.classes = '';
      if ($attrs.ngClick) {
        this.classes = 'pointer';
      }
    };
  },
});
