
/**
 * Module definition and dependencies
 */
angular.module('App.Public.Event.View.Controller', [])

/**
 * Controller
 */
.controller('PublicEventViewCtrl', function(
  $notice, Interface, OpenGraph, Config
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Data
    this.defaultBanner = `https://${Config.domains.images}/${Config.app.defaultBanner}`;
    this.options = this.attendance.options;
    this.canSignUp = this.options.length > 0;
    this.feeOptions = this.options
      .filter(option => option.method !== 'dates');

    //All free
    if (this.feeOptions.every(option => option.rulesByType.free)) {
      this.feeOptions = [this.feeOptions[0]];
    }

    //Set open graph tags
    this.setOpenGraphTags();
  };

  /**
   * Set open graph tags
   */
  this.setOpenGraphTags = function() {

    //Get data
    const {club, event} = this;

    //Set tags
    OpenGraph.setTitle(event.name);
    OpenGraph.setDescription(`Event hosted by ${club.name}`);
    OpenGraph.setUrl(club.makeUrl(event.publicPath));
    OpenGraph.setImage(event.banner, '?h=600&w=1200&auto=format');
  };

  /**
   * Share link
   */
  this.share = function() {

    //Get data
    const {event, club} = this;
    const {name, publicPath} = event;
    const url = club.makeUrl(publicPath);

    //Share natively
    if (typeof navigator.share === 'function') {
      return navigator
        .share({
          url,
          title: name,
          text: `I thought you might be interested in this event`,
        })
        .catch(() => {});
    }

    //Copy link to clipboard
    Interface
      .copyToClipboard(url)
      .then(() => $notice.show(`Link copied to clipboard`));
  };
});
