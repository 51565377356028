
/**
 * Module definition and dependencies
 */
angular.module('Shared.Text.MarkMatches.Filter', [])

/**
 * Filter definitions
 */
.filter('markmatches', function($sce) {
  return function(text, match, tag) {
    let regex;
    if (typeof match === 'object') {
      regex = match;
    }
    else {
      match = match.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      regex = new RegExp('(?:^|\\b)(' + match + ')', 'i');
    }
    tag = tag || 'em';
    text = String(text).replace(regex, '<' + tag + '>$1</' + tag + '>');
    return $sce.trustAsHtml(text);
  };
});
