
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area', [
  'App.Admin.Area.Add',
  'App.Admin.Area.Edit',
  'App.Admin.Area.Overview',
  'App.Admin.Area.Controller',
  'App.Admin.Area.Times.Card',
  'App.Admin.Area.AreaOptions.Component',
  'App.Admin.Area.EditAreaTime.Modal',
  'Shared.TimeRangeOptions.Component',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.area', {
    url: '/areas',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'areas',
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.area.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.area.overview', title});
  }, {priority: 10});
});
