
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.Grid.Footer.Component', [])

/**
 * Booking header component
 */
.component('bookingGridFooter', {
  template: `
    <footer class="BookingGridFooter text-danger">
      <span ng-if="$ctrl.isToday">No more booking slots available for today</span>
      <span ng-if="!$ctrl.isToday">No booking slots available for {{$ctrl.date | moment: 'D MMMM'}}</span>
    </footer>
  `,
  bindings: {
    date: '<',
    isToday: '<',
  },
});
