
/**
 * Module definition and dependencies
 */
angular.module('App.Public.Event.Signup', [
  'App.Public.Event.Signup.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('public.event.signUp', {
    url: '/signup/:eventId',
    params: {
      eventId: {
        value: null,
      },
      slug: {
        value: '',
        squash: true,
      },
    },
    data: {
      roles: [],
    },
    component: 'publicEventSignupRoute',
    resolve: {
      payment(PaymentFlow, PaymentTypes) {
        return new PaymentFlow(PaymentTypes.EVENT);
      },
      outcome(PaymentOutcome) {
        return PaymentOutcome.check();
      },
      event(transition, $store) {
        const {eventId} = transition.params();
        return $store.events.findById(eventId);
      },
      attendance(transition, Event) {
        const {eventId} = transition.params();
        return Event.getPublicAttendance(eventId);
      },
    },
  });
})

/**
 * Component
 */
.component('publicEventSignupRoute', {
  controller: 'PublicEventSignupCtrl',
  templateUrl: 'public/event/signup/signup.html',
  bindings: {
    club: '<',
    module: '<',
    event: '<',
    attendance: '<',
    payment: '<',
    outcome: '<',
  },
})

/**
 * Run logic
 */
.run((Page, Auth, $transitions) => {

  //Guard
  $transitions.onEnter({
    to: 'public.event.signUp',
  }, transition => {

    //Authenticated, send to member sign up flow
    if (Auth.isAuthenticated()) {
      const {eventId} = transition.params();
      return transition.router.stateService
        .target('event.signUp', {eventId});
    }
  });

  //Page setup
  $transitions.onSuccess({to: 'public.event.signUp'}, () => {

    //Get transition details
    // const params = transition.params();
    // const event = transition.injector().get('event');

    //Setup page
    Page.setTitle('Sign up for event');
  });
});
