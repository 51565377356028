
/**
 * Module definition and dependencies
 */
angular.module('Shared.DurationFromSeconds.Filter', [])

/**
 * Parse duration expressed in seconds
 */
.filter('durationFromSeconds', function() {
  return function(seconds, roundToMinutesDiff = 0, limit = false) {
    if (seconds === null || seconds === undefined) {
      return '';
    }

    //Round to minutes?
    if (roundToMinutesDiff) {
      const minutes = Math.round(seconds / 60);
      const diff = Math.abs((minutes * 60) - seconds);
      if (diff <= roundToMinutesDiff) {
        seconds = minutes * 60;
      }
    }

    //Parse
    seconds = Math.round(Number(seconds));

    //Determine days
    const days = Math.floor(seconds / 86400);
    seconds -= days * 86400;

    //Determine hours
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;

    //Determine minutes
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    //Return string
    if (days > 0) {
      if (limit) {
        return `${days}d ${hours}h`;
      }
      return `${days}d ${hours}h ${minutes}m`;
    }
    if (hours > 0) {
      if (limit) {
        return `${hours}h ${minutes}m`;
      }
      return `${hours}h ${minutes}m ${seconds}s`;
    }
    if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    }
    return `${seconds}s`;
  };
});
