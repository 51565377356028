
/**
 * Module definition and dependencies
 */
angular.module('Shared.DayOfRecurrences.Options', [])

/**
 * Constant definition
 */
.constant('DayOfRecurrences', [
  {
    value: 'month',
    label: 'Day of the month',
  },
  {
    value: 'week',
    label: 'Weekday',
  },
]);
