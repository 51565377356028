
/**
 * Module definition and dependencies
 */
angular.module('App.Portal', [
  'App.Portal.Controller',
  'App.Portal.Login',
  'App.Portal.LoginCredentials',
  'App.Portal.Welcome',
  'App.Portal.Visitor',
  'App.Portal.Register',
  'App.Portal.CheckIn',
  'App.Portal.SignupClub',
  'App.Portal.VerifyEmail',
  'App.Portal.Unsubscribe',
  'App.Portal.ForgotUsername',
  'App.Portal.XeroSignup',
  'App.Portal.ForgotPassword',
  'App.Portal.ResetPassword',
  'App.Portal.ApproveTransactions',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('portal', {
    url: '',
    abstract: true,
    component: 'portalRoute',
    data: {
      auth: false,
    },
    resolve: {
      club($store) {
        return $store.club.get();
      },
    },
  });
})

/**
 * Route component
 */
.component('portalRoute', {
  templateUrl: 'portal/portal.html',
  controller: 'PortalCtrl',
  bindings: {
    club: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, $timeout, Page, Intercom, OpenGraph) => {

  /**
   * Success hook for transitions
   */
  $transitions.onSuccess({}, transition => {

    //Get club from transition
    try {
      const club = transition.injector().get('club');
      const to = transition.to();
      if (club && to.name !== 'public.event.view') {
        $timeout(() => {
          OpenGraph.setTitle(Page.getFullTitle());
          OpenGraph.setImage(club.background, '?h=600&w=1200&auto=format');
          OpenGraph.setUrl();
        }, 100);
      }
    }
    catch (error) {
      //Fall through
    }

    //Update intercom
    Intercom.update();
  }, {priority: 100});
});
