
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.System.SessionSettings.Card', [])

/**
 * Component
 */
.component('cardSystemSessionSettings', {
  templateUrl: 'admin/system/cards/session-settings.html',
  controller: 'CardSystemSessionSettingsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    memberships: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardSystemSessionSettingsCtrl', function(
  $controller, $filter, MembershipConstraintLabels,
  LightControlModes, $modal
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardSettingsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Set type
    this.type = 'system';

    //Set services
    this.MembershipConstraintLabels = MembershipConstraintLabels
      .filter(item => !item.isGuest);
    this.LightControlModes = LightControlModes;

    //Distribute memberships
    this.memberships = $filter('distribute')(this.memberships, 3);

    //Call parent init
    $base.$onInit.call(this);

    //Store current mode
    if (this.model.lightControlMode) {
      this.previousLightControlMode = this.model.lightControlMode;
    }
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Show light control mode warning
   */
  this.confirmLightControlMode = function() {

    //Get setting
    const {lightControlMode: newMode} = this.model;

    //Open confirmation dialog
    return $modal
      .open('basic', {
        templateUrl: 'admin/system/modals/confirm-light-control-mode.html',
        locals: {newMode},
      })
      .result;
  };

  /**
   * Save and back to status tab
   */
  this.save = function() {

    //Get data
    const {previousLightControlMode, model: {lightControlMode}} = this;

    //Has light control mode changed from flexible?
    if (previousLightControlMode === LightControlModes.FLEXIBLE &&
        lightControlMode !== LightControlModes.FLEXIBLE) {
      return this
        .confirmLightControlMode()
        .then(() => $base.save.call(this));
    }
    else {
      return $base.save.call(this);
    }
  };
});
