
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.AddGrade.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addGrade', {
    templateUrl: 'admin/people/members/modals/add-grade.html',
    controller: 'ModalAddGradeCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalAddGradeCtrl', function($controller, $modalInstance) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Initialize flags
    this.isSaved = false;
    this.isSaving = false;
    this.hasNoGrades = this.activities.length === 0;

    //Create model subset
    this.model = this.grade.extract([
      'id', 'name', 'color', 'achievedOn',
      'notes', 'comments', 'activity',
    ]);

    //Activity has grades
    if (!this.hasNoGrades) {
      if (this.isEdit) {
        const activity = this.activities
          .find(a => a.id === this.model.activity.id);
        this.gradeOptions = activity.grades;
      }
      else {
        this.updateActivity(this.activities[0]);
      }
    }
  };

  /**
   * Update model
   */
  this.updateActivity = function(activity) {
    const {id, name, grades} = activity;
    this.model.activity = {id, name};
    this.gradeOptions = grades;
    this.updateGrade(grades[0]);
  };

  /**
   * Update grade
   */
  this.updateGrade = function(grade) {
    const {id, name, color} = grade;
    Object.assign(this.model, {id, name, color});
  };

  /**
   * Update model
   */
  this.updateModel = function(prop, value) {
    this.model[prop] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Save
    this.isSaving = true;
    this
      .handler(this.model, this.activity)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isSaving = false);
  };
});
