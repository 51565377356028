
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.Controller', [])

/**
 * Controller
 */
.controller('AdminIntegrationsCtrl', function(
  $location, $modal, Integration, ApiKey, Intercom
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flag
    this.isSuper = this.user.isSuper();

    //Start open with specific card
    const {openCard, openTab} = this.transition.params();
    this.openCard = openCard;
    this.openTab = openTab;

    //Create integrations map
    this.integrationsMap = this.integrations.reduce((map, integration) => {
      map[integration.type] = integration;
      return map;
    }, {});

    //Get data from query params
    const {type, status, error} = $location.search();

    //Handle status
    if (type && status && this.integrationsMap[type]) {
      const integration = this.integrationsMap[type];

      //Start card open
      this.openCard = type;

      //Show connection modal
      if (status === 'connected') {
        Intercom.event(`Connected ${type} integration`);

        //Part of setup?
        if (type === 'stripe' && !this.club.isStepComplete('connectStripe')) {
          this.club.markStepComplete('connectStripe', true);
        }
        else {
          $modal.open('basic', {
            templateUrl: 'admin/integrations/modals/connect-success.html',
            locals: {integration},
          });
        }
      }
      else if (status === 'error') {
        $modal.open('basic', {
          templateUrl: 'admin/integrations/modals/connect-error.html',
          locals: {integration, error},
        });
      }
    }

    //Clear query params
    $location.search('type', null);
    $location.search('status', null);
    $location.search('error', null);
  };

  /**
   * Load an integration
   */
  this.load = function(type) {
    return Integration
      .findByType(type)
      .then(integration => this.integrationsMap[type] = integration);
  };

  /**
   * Load API keys
   */
  this.loadApiKeys = function() {
    return ApiKey
      .query()
      .then(data => this.apiKeys = data.apiKeys);
  };
});
