
/**
 * Define values
 */
const AUTOMATIC = 'automatic';
const LINKED = 'linked';
const FLEXIBLE = 'flexible';

/**
 * Constant definition
 */
const LightControlModes = {};

/**
 * Set properties
 */
LightControlModes.AUTOMATIC = AUTOMATIC;
LightControlModes.LINKED = LINKED;
LightControlModes.FLEXIBLE = FLEXIBLE;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.LightControlModes.Options', [])
  .constant('LightControlModes', LightControlModes);
