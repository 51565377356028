
/**
 * Module definition and dependencies
 */
angular.module('App.Event.Signup.Controller', [
  'App.Account.Base.Controller',
])

/**
 * Controller
 */
.controller('EventSignupCtrl', function(
  $controller, $state, $store, $modal, $q,
  DateFormat, EventAttendee, EventRuleTypes,
  Event, CouponTypeUses
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AccountBaseCtrl', {});

  //Constants
  const {FEE, FREE, COUPON, MEMBERSHIP} = EventRuleTypes;

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = function() {

    //Initialize
    this.answers = {};
    this.usableCoupons = [];
    this.usableSubscriptions = [];
    this.coupon = null;
    this.subscription = null;
    this.isMembershipFlow = ($state.params.ruleType === MEMBERSHIP);

    //Extract attendance data
    this.attendee = this.attendance.attendee;
    this.interest = this.attendance.interest;
    this.options = this.attendance.options;

    //Create list of available members
    this.members = [this.user].concat(this.circleMembers);

    //Set initial member
    this.setInitialMember();

    //Parent init
    $base.$onInit.call(this);
  };

  /**
   * Set member
   */
  this.setMember = function(member) {
    this.member = member;
    this.loadData();
  };

  /**
   * Set initial member
   */
  this.setInitialMember = function() {

    //Check if valid circle member ID given in params
    const {circleMemberId} = this.transition.params();
    if (circleMemberId) {
      const member = this.circleMembers.find(m => m.id === circleMemberId);
      if (member) {
        return this.setMember(member);
      }
    }

    //Set ourselves
    this.setMember(this.user);
  };

  /**
   * Setup
   */
  this.setup = function() {

    //Get data
    const {event, options} = this;

    //Nothing to do?
    if (!event.hasSpacesLeft || options.length === 0) {
      return this.goBack();
    }

    //Set initial step and option
    this.stepDetails();
    this.setOption(this.options[0]);
  };

  /**
   * Set option
   */
  this.setOption = function(option) {

    //Set option
    this.option = option;
    this.dates = [];

    //Check coupons and subscriptions
    this.checkCoupons();
    this.checkSubscriptions();

    //Reset if no options
    if (!option) {
      this.type = null;
      this.subscription = null;
      this.coupon = null;
      this.rule = null;
      return;
    }

    //Set first rule type
    this.setType(option.types[0]);
  };

  /**
   * Set type
   */
  this.setType = function(type) {

    //Set type
    this.type = type;

    //Reset coupon/subscription
    this.subscription = null;
    this.coupon = null;
    this.rule = null;

    //Fee or free, use best rule
    if (type === FEE || type === FREE) {
      this.rule = this.option.bestByType[type];
    }

    //Select first coupon (which will determine rule)
    else if (type === COUPON && this.usableCoupons.length > 0) {
      this.selectCoupon(this.usableCoupons[0]);
    }

    //Select first subscription (which will determine rule)
    else if (type === MEMBERSHIP && this.usableSubscriptions.length > 0) {
      this.selectSubscription(this.usableSubscriptions[0]);
    }
  };

  /**
   * Check route is valid
   */
  this.checkRouteValid = function() {

    //Get data
    const {event, user} = this;

    //Nothing to do?
    if (!event) {
      return this.goBack();
    }

    //If user is suspended, can't sign up to event
    if (user && user.isSuspended) {
      return $modal
        .open('basic', {
          templateUrl: 'modals/suspended.html',
          locals: {user},
        })
        .closed
        .then(() => this.goBack());
    }

    //Already attending and we didn't just pay for this?
    if (!this.outcome && this.attendee) {
      return this.onPaymentSuccess();
    }
  };

  /**
   * Check coupons
   */
  this.checkCoupons = function() {

    //Initialize
    this.usableCoupons = [];

    //Return if no options
    if (!this.option) {
      return;
    }

    //Get relevant rules
    const rules = this.option.rulesByType[COUPON];
    if (!rules || rules.length === 0) {
      return;
    }

    //Get number of dates
    const {dates} = this;
    const numSessions = Math.max(dates.length, 1);

    //Set number of sessions required in scope
    this.numCouponSessions = numSessions;

    //Filter coupons that we can use to pay for these rules
    this.usableCoupons = this.coupons
      .filter(coupon => coupon.usableFor.includes(CouponTypeUses.EVENTS))
      .filter(coupon => coupon.numSessionsLeft >= numSessions &&
                        coupon.isActive && !coupon.isExpired)
      .filter(coupon => rules
        .some(rule => rule.couponTypes.includes(coupon.type)));
  };

  /**
   * Check subscriptions
   */
  this.checkSubscriptions = function() {

    //Initialize
    this.usableSubscriptions = [];

    //Return if no options
    if (!this.option) {
      return;
    }

    //Get relevant rules
    const rules = this.option.rulesByType[MEMBERSHIP];
    if (!rules || rules.length === 0) {
      return;
    }

    //Filter subscriptions that we can use to pay for these rules
    this.usableSubscriptions = this.subscriptions
      .filter(sub => sub.isCurrentForDate(this.event.startDate))
      .filter(sub => rules
        .some(rule => rule.memberships.includes(sub.membership.id)));
  };

  /**
   * Select date
   */
  this.selectDate = function(date) {

    //Select date
    const i = this.dates.indexOf(date);
    if (i === -1) {
      this.dates.push(date);
    }
    else {
      this.dates.splice(i, 1);
    }

    //Check coupons and subscriptions
    this.checkCoupons();
    this.checkSubscriptions();
  };

  /**
   * Select coupon to use
   */
  this.selectCoupon = function(coupon) {

    //Set the coupon
    this.coupon = coupon;

    //Select appropriate rule (first one that matches)
    this.rule = this.option.rulesByType[COUPON]
      .find(rule => rule.couponTypes.includes(coupon.type));
  };

  /**
   * Select subscription to use
   */
  this.selectSubscription = function(subscription) {

    //Set the subscription
    this.subscription = subscription;

    //Select appropriate rule (first one that matches)
    this.rule = this.option.rulesByType[MEMBERSHIP]
      .find(rule => rule.memberships.includes(subscription.membership.id));
  };

  /**
   * Prepare payment
   */
  this.preparePayment = function() {

    //Get data
    const {rule, event, dates, option} = this;
    const {name} = event;
    const amount = rule.fee;

    //Selection of multiple dates
    if (option.method === 'dates') {
      for (const date of dates) {
        const label = `${name} – ${date.format(DateFormat.formats.withTime)}`;
        this.payment.addLineItem({label, amount});
      }
    }

    //Instance or series
    else {
      const label = name;
      this.payment.setLineItems([{label, amount}]);
    }

    //Make line items and set extra data
    this.payment.setExtraData({eventId: event.id});
    this.payment.setRedirectPath(`events/signup/${event.id}`);
  };

  /**
   * Before payment
   */
  this.beforePayment = function() {
    return this
      .createAttendee()
      .then(data => {

        //Set payment data
        const transactionIds = data.transactions.map(trx => trx.id);
        this.payment.setExtraData({transactionIds});
      });
  };

  /**
   * Pay later
   */
  this.payLater = function() {

    //Get data
    const {club, rule, option, dates} = this;
    const payingFor = `event entry fee`;
    let amount = rule.fee;

    //Adjust amount if selecting multiple dates
    if (option.method === 'dates') {
      amount *= dates.length;
    }

    //Confirm
    return $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-pay-later.html',
        locals: {club, payingFor, amount},
        rejectOnDismissal: true,
      })
      .result
      .then(() => this.signUp(true))
      .catch(() => {});
  };

  /**
   * Sign up without payment
   */
  this.signUp = function(isPayingLater) {

    //Get data
    const {rule, coupon, option, dates} = this;

    //No rule
    if (!rule) {
      return $q.reject();
    }

    //No dates
    if (option.method === 'dates' && dates.length === 0) {
      return $q.reject();
    }

    //Must use coupon
    if (rule.type === COUPON && !coupon) {
      return $q.reject();
    }

    //Create attendee
    return this
      .createAttendee()
      .then(() => $store.events.clear())
      .then(() => this.onSignedUp(isPayingLater));
  };

  /**
   * Create attendee
   */
  this.createAttendee = function() {

    //Get data
    const {event, subscription, coupon, rule, dates, answers, member} = this;

    //Make attendee
    const attendee = new EventAttendee({
      event: event.id,
      rule: rule.id,
      dates,
      coupon: coupon ? coupon.id : null,
      subscription: subscription ? subscription.id : null,
    });

    //Set answers
    attendee.setAnswersMap(answers);

    //Create attendance
    return attendee.createOwn({circleMemberId: member.id});
  };

  /**************************************************************************
   * Navigation
   ***/

  /**
   * Details step
   */
  this.stepDetails = function() {
    this.step = 'details';
  };

  /**
   * Questions step
   */
  this.stepQuestions = function() {
    this.step = 'questions';
  };

  /**
   * Next from view event details
   */
  this.nextFromDetails = function() {

    //No dates
    if (this.option.method === 'dates' && this.dates.length === 0) {
      return;
    }

    //Do we have any questions to answer
    if (this.event.hasPublicQuestions) {
      return this.stepQuestions();
    }

    //Onwards
    this.nextFromQuestions();
  };

  /**
   * Next from questions
   */
  this.nextFromQuestions = async function() {

    //Prepare payment
    this.preparePayment();

    //Check if can pay with credit
    if (this.payment.canUseAccountCredit) {
      return this.stepUseCredit();
    }

    //Onwards
    this.nextFromUseCredit();
  };

  /**
   * Prev from questions
   */
  this.prevFromQuestions = function() {
    this.stepDetails();
  };

  /**
   * Prev from use account credit
   */
  this.prevFromUseCredit = function() {

    //Do we have any questions to answer
    if (this.event.hasPublicQuestions) {
      return this.stepQuestions();
    }

    //Backwards
    this.prevFromQuestions();
  };

  /**
   * Get event params
   */
  this.getEventParams = function() {
    const eventId = this.event.id;
    return {eventId};
  };

  /**
   * Signed up without payment
   */
  this.onSignedUp = function(isPayingLater) {

    //Get data
    const {event, rule, club} = this;

    //Show confirmation
    $modal
      .open('basic', {
        templateUrl: 'event/modals/confirm-attendance.html',
        locals: {event, isPayingLater, rule, club},
      })
      .closed
      .then(() => this.goBack());
  };

  /**
   * Payment success handler
   */
  this.onPaymentSuccess = function() {
    this.goBack();
  };

  /**
   * Payment failed handler
   */
  this.onPaymentFailed = function() {
    $state.go('account.overview');
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.goBack();
  };

  /**
   * Go back, either to view event, or to subscription overview
   */
  this.goBack = function() {
    if (this.isMembershipFlow) {
      $state.go('subscription.overview');
    }
    else {
      const params = this.getEventParams();
      $state.go('event.view', params);
    }
  };

  /**************************************************************************
   * Circles handling
   ***/

  /**
   * Load data
   */
  this.loadData = function() {

    //Flag as loading
    this.isLoading = true;

    //Load
    $q
      .all([
        this.loadAttendance(),
        this.setCoupons(),
        this.setSubscriptions(),
      ])
      .then(() => this.setOption(this.options[0]))
      .finally(() => this.isLoading = false);
  };

  /**
   * Load the selected members attendance
   */
  this.loadAttendance = function() {

    //Clear
    this.attendance = {};

    //Get own attendance
    return Event
      .getOwnAttendance(this.event.id, this.member.id)
      .then(attendance => this.attendance = attendance)
      .then(() => this.setAttendanceData());
  };

  /**
   * Set the selected members subscriptions
   */
  this.setSubscriptions = function() {

    //Set all subscriptions
    this.subscriptions = this.member.subscriptions;

    //Set subscription
    const {subId} = this.transition.params();
    if (subId) {
      this.subscription = this.subscriptions.filter(sub => sub.id === subId);
    }
  };

  /**
   * Set the selected members coupons
   */
  this.setCoupons = function() {

    //Members to process
    const {user, member} = this;
    const members = [user];
    if (member !== user) {
      members.push(member);
    }

    //Set all coupons
    this.coupons = members.reduce((all, member) => {
      return all
        .concat(member.coupons
          .map(coupon => Object.assign(coupon, {member})));
    }, []);
  };

  /**
   * Set flag and extract attenance data
   */
  this.setAttendanceData = function() {
    this.isAlreadyAttending = this.attendance.attendee ? true : false;
    Object.assign(this, this.attendance);
  };
});
