
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.Grading.Card', [])

/**
 * Component
 */
.component('cardMemberEditGrading', {
  templateUrl: 'admin/people/members/cards/grades.html',
  controller: 'CardMemberEditGradingCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    member: '<',
    activities: '<',
    isOwn: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberEditGradingCtrl', function(
  $modal, $notice, Modules, MemberGrade
) {

  /**
   * Init
   */
  this.$onInit = function() {

    //Extract model
    this.model = this.member.extract(['grades']);

    //Organise grades
    this.organiseGrades();
  };

  /**
   * Determine grades
   */
  this.organiseGrades = function() {

    //Activities with grades
    this.gradedActivities = this.activities
      .filter(activity =>
        activity.hasGrades && activity.grades && activity.grades.length > 0
      );

    //Map grades to activities
    this.memberActivities = [];
    this.model.grades
      .forEach(grade => {

        //Find existing activity
        const activity = this.memberActivities
          .find(a => a.id === grade.activity.id);

        //If activity is not already in array, then push activity
        if (activity) {
          return activity.grades.push(grade);
        }

        //Create new entry
        this.memberActivities
          .push({
            name: grade.activity.name,
            id: grade.activity.id,
            grades: [grade],
          });
      });

    //Sort grades
    this.memberActivities
      .forEach(activity => activity.grades
        .sort((a, b) => b.achievedOn - a.achievedOn)
      );
  };

  /**
   * View grade
   */
  this.view = function(grade) {

    //Get full activity
    const activity = this.activities
      .find(activity => activity.id === grade.activity.id);

    //Open modal
    $modal.open('viewGrade', {locals: {grade, activity}});
  };

  /**
   * Add grade
   */
  this.add = function() {

    //Set up new grade
    const grade = new MemberGrade();

    //Get data
    const {gradedActivities, member, model} = this;
    const {singular} = Modules.find('grades');

    //Define handler
    const handler = (data) => {
      model.grades.push(data);
      return this
        .onSave({$event: {member, model, isEdit: true}})
        .then(() => this.model = this.member.extract(['grades']));
    };

    //Show edit activity modal
    $modal
      .open('addGrade', {
        locals: {activities: gradedActivities, grade, handler},
      })
      .result
      .then(() => {
        $notice.show(`${singular} added`);
        this.organiseGrades();
      });
  };

  /**
   * Edit grade
   */
  this.edit = function($event) {

    //Get data
    const {grade} = $event;
    const {gradedActivities, member, model} = this;

    //Define handler
    const handler = (data) => {
      Object.assign(grade, data);
      return this
        .onSave({$event: {member, model, isEdit: true}})
        .then(() => this.model = this.member.extract(['grades']));
    };

    //Show edit activity modal
    return $modal
      .open('addGrade', {
        locals: {
          activities: gradedActivities, grade, isEdit: true, handler,
        },
      })
      .result
      .then(() => {
        this.organiseGrades();
      });
  };

  /**
   * Remove grade
   */
  this.remove = function($event) {

    //Get data
    const {grade} = $event;
    const {model, member} = this;
    const {singular} = Modules.find('grades');

    //Define handler
    const handler = () => {

      //Find relevant grade and remove
      model.grades = model.grades.filter(g => g !== grade);
      return this
        .onSave({$event: {member, model, isEdit: true}})
        .then(() => this.model = this.member.extract(['grades']));
    };

    //Show edit activity modal
    $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-delete-grade.html',
        locals: {grade, handler},
      })
      .result
      .then(() => {
        $notice.show(`${singular} removed`);
        this.organiseGrades();
      });
  };
});

