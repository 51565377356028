
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Edit.Registration.Card', [])

/**
 * Component
 */
.component('cardMembershipEditRegistration', {
  templateUrl: 'admin/membership/cards/registration.html',
  controller: 'CardMembershipEditRegistrationCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    memberships: '<',
    membership: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMembershipEditRegistrationCtrl', function(
  CardCloseReasons, MembershipConstraintLabels, Settings,
  ApprovalOptions, PaymentOptions
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;

    //Set services
    this.MembershipConstraintLabels = MembershipConstraintLabels
      .filter(item => !item.isGuest);
    this.ApprovalOptions = ApprovalOptions;
    this.PaymentOptions = PaymentOptions;

    //Filter out current membership
    this.memberships = this.memberships.filter(m => m.id !== this.membership.id);

    //Create model subset
    this.model = this.membership.extract([
      'isDefault', 'canSelectWhenRegistering', 'onlyViaDirectLink',
      'requiresApproval', 'registrationPayment',
    ]);

    //Get global requires approval setting
    this.globalRequiresApproval
      = Settings.extract('registration.requiresApproval');

    //Use global setting to set default
    if (!this.model.requiresApproval) {
      this.model.requiresApproval = this.globalRequiresApproval;
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;

    //Public events can't be default
    if (property === 'canSelectWhenRegistering' && !value) {
      this.model.isDefault = false;
      this.model.onlyViaDirectLink = false;
    }

    //If membership must be paid after approval, then approval is always required
    if (property === 'registrationPayment') {
      if (value === PaymentOptions.AFTER_APPROVAL) {
        this.model.requiresApproval = ApprovalOptions.ALWAYS;
      }
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {membership, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {membership, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
