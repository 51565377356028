
/**
 * Module definition and dependencies
 */
angular.module('App.Event.View', [
  'App.Event.View.Controller',
  'App.Event.View.Summary.Card',
])

/**
 * Config
 */
.config($stateProvider => {

  //View
  $stateProvider.state('event.view', {
    url: '/view/:eventId',
    component: 'eventViewRoute',
    data: {
      roles: [],
    },
    resolve: {
      event(transition, club, Event) {
        const {eventId} = transition.params();
        return Event.findById(eventId, club);
      },
      attendance(transition, Event) {
        const {eventId} = transition.params();
        return Event.getOwnAttendance(eventId);
      },
      circleMembers(Circle) {
        return Circle
          .queryOwnManagedMembers('events');
      },
      previousState($state) {
        if (!$state || !$state.current) {
          return null;
        }
        return {
          name: $state.current.name,
          params: Object.assign({}, $state.params),
        };
      },
    },
  });
})

/**
 * Route component
 */
.component('eventViewRoute', {
  controller: 'EventViewCtrl',
  templateUrl: 'event/view/view.html',
  bindings: {
    club: '<',
    user: '<',
    event: '<',
    attendance: '<',
    circleMembers: '<',
    system: '<',
    page: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, $log, Page) => {

  //Hidden events guard
  $transitions.onEnter({
    to: 'event.view',
  }, async transition => {

    //Get data
    const event = await transition.injector().getAsync('event');
    const user = await transition.injector().getAsync('user');
    const isAdmin = user && user.hasRole('admin', 'eventManager', 'viewer');

    //Hidden event and can't see?
    if (event.isHidden && !isAdmin) {
      $log.log(`Hidden event`);
      return transition.router.stateService.target('home');
    }
  });

  //Page setup
  $transitions.onSuccess({
    to: 'event.view',
  }, transition => {

    //Get data
    const event = transition.injector().get('event');
    const user = transition.injector().get('user');
    const club = transition.injector().get('club');
    const system = transition.injector().get('system');
    const previous = transition.injector().get('previousState');

    //Set page title and crumb
    Page.setTitle(event.name);
    Page.setData({event});
    Page.clearCrumbs();

    //Maintain calendar state
    if (previous && previous.name === 'calendar') {
      Page.addCrumb({
        sref: 'calendar',
        title: 'Calendar',
        params: previous.params,
      });
    }
    else {
      Page.addCrumb({
        sref: 'calendar',
        title: 'Calendar',
      });
    }

    //Event itself
    Page.addCrumb({
      sref: 'event.view',
      params: {
        eventId: event.id,
      },
    });

    //No user or system?
    if (!user || !system) {
      return;
    }

    //Check if can override doors/lights
    const isAuth = user.hasRole('admin', 'viewer', 'eventManager');
    const isOrganiser = event.isOrganiser(user);
    const {canOverrideLights, canOverrideDoors} = user;

    //Get settings
    const {
      organiserLights: lights,
      organiserDoors: doors,
    } = club.settings.event;

    //Check if event is on for the purpose of controlling lights/doors
    const isOnLights = event.isOn(lights.before, lights.after);
    const isOnDoors = event.isOn(doors.before, doors.after);

    //Check if can toggle lights
    if (system.hasLightControl && lights.canControl && isOnLights) {
      if (isAuth || isOrganiser || canOverrideLights) {
        Page.addOption('overrideLights');
      }
    }

    //Check if can toggle doors
    if (system.hasDoorControl && doors.canControl && isOnDoors) {
      if (isAuth || isOrganiser || canOverrideDoors) {
        Page.addOption('overrideDoors');
      }
    }
  });
});
