
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.Intro', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.intro', {
    url: '',
    component: 'registerIntroRoute',
  });
})

/**
 * Route component
 */
.component('registerIntroRoute', {
  controller: 'RegisterIntroCtrl',
  templateUrl: 'portal/register/steps/intro.html',
  bindings: {
    club: '<',
    registration: '<',
    isKiosk: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterIntroCtrl', function($controller, Text, TextTypes) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('RegisterBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flag as loading
    this.isLoading = true;

    //Load intro text
    Text
      .getText(TextTypes.REGISTRATION_INTRO)
      .then(text => this.intro = text)
      .finally(() => this.isLoading = false);
  };
});
