
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Area.Edit.Controller', [])

/**
 * Controller
 */
.controller('AdminAreaEditCtrl', function(
  $controller, $state, $modal, $q, Config, Upload
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminAreaCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Get params
    const {changeSponsor} = this.transition.params();
    const {isOnSystem} = this.area;

    //Check if can edit
    this.isSuper = this.user.isSuper();
    this.canEdit = this.user.isAdmin();
    this.canDelete = this.canEdit && (!isOnSystem || this.user.isSuper());
    this.openSponsorCard = changeSponsor;
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    $state.go('admin.area.overview');
  };

  /**
   * On saved
   */
  this.onSaved = function($event) {

    //Get area
    const {area} = $event;

    //Update page title
    this.page.setTitle(area.name);
  };

  /**
   * Update sponsor
   */
  this.updateSponsor = function($event) {

    //Get data from event
    const {data} = $event;

    //Set new sponsor
    this.area.sponsor = data.sponsor;
    return $q.resolve();
  };

  /**
   * Upload sponsor
   */
  this.uploadSponsor = function($event) {

    //Get file
    const {file} = $event;
    const {area} = this;
    const {baseUrl} = Config.api;

    //Upload
    return Upload.upload({
      url: `${baseUrl}/area/${area.id}/sponsor/`,
      data: {
        sponsor: file,
      },
    });
  };

  /**
   * Delete sponsor
   */
  this.deleteSponsor = function() {

    //Define handler
    const {area} = this;
    const handler = function() {
      return area.deleteSponsorLogo();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/area/modals/confirm-delete-sponsor.html',
        locals: {area, handler},
      })
      .result;
  };
});
