
/**
 * Module definition and dependencies
 */
angular.module('Shared.Booking.Model', [
  'BaseModel.Service',
  'Shared.BookingStore.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('booking', {
    model: 'Booking',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'bookings',
        isArray: true,
        isModel: true,
        withCredentials: true,
      },
      own: {
        url: 'own',
        method: 'GET',
        dataKey: 'bookings',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      getRecentMembers: {
        url: 'recentMembers',
        method: 'GET',
        isModel: true,
        isArray: true,
        model: 'Member',
        withCredentials: true, //To send kiosk cookie
      },
      getTags: {
        url: 'tags',
        method: 'GET',
        model: 'Tag',
        dataKey: 'tags',
        isModel: true,
        isArray: true,
      },
      validate: {
        url: 'validate',
        method: 'POST',
        withCredentials: true,
      },
      preValidate: {
        url: 'preValidate',
        method: 'POST',
      },
      create: {
        method: 'POST',
        withCredentials: true,
      },
      validateChange: {
        url: 'validateChange',
        method: 'POST',
      },
      patch: {
        method: 'PATCH',
      },
      delete: {
        url: ':method',
        method: 'DELETE',
      },
      useCouponsOwn: {
        url: 'useCoupons/own',
        method: 'POST',
      },
      useCoupons: {
        url: 'useCoupons',
        method: 'POST',
      },
      markPaid: {
        url: 'markPaid',
        method: 'POST',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('bookings', {
    model: 'Booking',
    dataKey: 'bookings',
    service: 'BookingStore',
  });
})

/**
 * Model definition
 */
.factory('Booking', function(
  $api, $baseModel, $sync, Intercom, moment, FeePolicies
) {

  //Get constants
  const {IN_ADVANCE_REFUNDABLE} = FeePolicies;

  /**
   * Constructor
   */
  function Booking(data) {
    $baseModel.call(this, angular.extend({}, data || {}));

    //Duration
    Object.defineProperty(this, 'duration', {
      get() {
        if (!this.startDate || !this.endDate) {
          return 0;
        }
        return this.endDate.diff(this.startDate, 'minutes');
      },
      set(duration) {
        if (this.startDate) {
          this.endDate = this.startDate.clone().add(duration, 'minutes');
        }
      },
    });

    //Start time virtual property
    Object.defineProperty(this, 'startTime', {
      get() {
        if (!this.startDate) {
          return null;
        }
        return this.startDate.getTime();
      },
      set(time) {
        if (this.startDate) {
          this.startDate.setTime(time);
        }
      },
    });

    //End time virtual property
    Object.defineProperty(this, 'endTime', {
      get() {
        if (!this.endDate) {
          return null;
        }
        return this.endDate.getTime(true);
      },
      set(time) {
        if (this.startDate) {
          this.endDate = this.startDate.clone().setTime(time);
        }
      },
    });

    //Weekday of this booking
    Object.defineProperty(this, 'weekday', {
      get() {
        if (!this.startDate) {
          return null;
        }
        return this.startDate.isoWeekday();
      },
    });

    //Number of people in the booking
    Object.defineProperty(this, 'numPeople', {
      get() {
        return (this.members.length + this.visitors.length);
      },
    });

    //Number of members in the booking
    Object.defineProperty(this, 'numMembers', {
      get() {
        return this.members.length;
      },
    });

    //Number of visitors in the booking
    Object.defineProperty(this, 'numVisitors', {
      get() {
        return this.visitors.length;
      },
    });

    //People in the booking
    let people;
    Object.defineProperty(this, 'people', {
      get() {
        if (people) {
          return people;
        }
        people = [];
        let unnamed = 0;
        for (const member of this.members) {
          people.push(member);
        }
        for (const visitor of this.visitors) {
          if (visitor.name) {
            people.push(visitor);
          }
          else {
            unnamed++;
          }
        }
        if (unnamed === 1) {
          people.push({name: `Visitor`});
        }
        else if (unnamed > 1) {
          people.push({name: `${unnamed} visitors`});
        }
        return people;
      },
    });

    //Fee refundable
    Object.defineProperty(this, 'isRefundable', {
      set() {},
      get() {
        return (
          !this.isUsedForSession &&
          this.hasFee &&
          this.fee.policy === IN_ADVANCE_REFUNDABLE &&
          moment().isBefore(this.fee.refundThreshold)
        );
      },
    });

    //Has refundable fee
    Object.defineProperty(this, 'hasRefundableFee', {
      get() {
        const {hasFee, isPaid, isRefundable} = this;
        return (hasFee && isPaid && isRefundable);
      },
    });

    //Has non-refundable fee
    Object.defineProperty(this, 'hasNonRefundableFee', {
      get() {
        const {hasFee, isPaid, isRefundable} = this;
        return (hasFee && isPaid && !isRefundable);
      },
    });

    //Is paid with coupons
    Object.defineProperty(this, 'isPaidWithCoupons', {
      get() {
        return (this.isPaid && this.coupons && this.coupons.used.length > 0);
      },
    });

    //Is current
    Object.defineProperty(this, 'isCurrent', {
      get() {
        const now = moment();
        return (
          now.isAfter(this.startDate) &&
          now.isBefore(this.endDate)
        );
      },
    });

    //Is past
    Object.defineProperty(this, 'isPast', {
      get() {
        return moment().isAfter(this.endDate);
      },
    });

    //Is start date past
    Object.defineProperty(this, 'isStartDatePast', {
      get() {
        return moment().isAfter(this.startDate);
      },
    });

    //Ui-router sref data
    Object.defineProperty(this, 'srefData', {
      get() {
        const date = this.startDate.format('YYYY-MM-DD');
        const activity = this.activity.identifier;
        const bookingId = this.id;
        return {date, activity, bookingId};
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Booking.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Booking.prototype.fromJSON = function(json) {

    //Activity not given? Get from area
    if (!json.activity && json.area) {
      json.activity = json.area.activity;
    }

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('mode', 'Mode');
    this.convertToModel('owner', 'Member');
    this.convertToModel('removedBy', 'Member');
    this.convertToModel('recurrence', 'Recurrence');
    this.convertToModel('members', 'Member', true);
    this.convertToModel('visitors', null, true);
    this.convertToModel('tags', null, true);

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Booking.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Get activity from area if not set
    if (!json.activity && json.area) {
      json.activity = json.area.activity;
    }

    //Strip data
    json.activity = $baseModel.onlyId(json.activity);
    json.mode = $baseModel.onlyId(json.mode);
    json.area = $baseModel.onlyId(json.area);
    json.members = $baseModel.onlyId(json.members);

    //Remove unneeded data
    delete json.owner;
    delete json.removedBy;
    delete json.tags;

    //Return json
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Check if a given user is the owner of this booking
   */
  Booking.prototype.isOwner = function(user) {
    return (this.owner && user && user.id === this.owner.id);
  };

  /**
   * Check if booking is after a given threshold
   */
  Booking.prototype.isAfterThreshold = function(threshold) {
    const {unit, amount} = threshold;
    const {startDate} = this;
    return moment().add(amount, unit).isAfter(startDate);
  };

  /**
   * Check if booking clashes with given time
   */
  Booking.prototype.isClashing = function(time) {
    return (this.startTime <= time && this.endTime > time);
  };

  /**
   * Check if we are on a specific area
   */
  Booking.prototype.hasArea = function(area) {
    return area.isSame(this.area);
  };

  /**
   * Check if this booking involves a certain member
   */
  Booking.prototype.hasMember = function(member) {
    if (!member || this.members.length === 0) {
      return false;
    }
    return this.members.some(m => m.id === member.id);
  };

  /**
   * Check if this booking has enough people
   */
  Booking.prototype.hasEnoughPeople = function() {
    if (!this.mode) {
      return false;
    }
    return (this.numPeople >= this.mode.minPeople);
  };

  /**
   * Check if this booking has enough visitors
   */
  Booking.prototype.hasEnoughVisitors = function() {
    if (!this.mode) {
      return false;
    }
    return (this.numVisitors >= this.mode.minVisitors);
  };

  /**
   * Check if this booking has the maximum number of people
   */
  Booking.prototype.hasMaxPeople = function() {
    if (!this.mode) {
      return false;
    }
    return (this.numPeople >= this.mode.maxPeople);
  };

  /**
   * Check if this booking has the maximum number of visitors
   */
  Booking.prototype.hasMaxVisitors = function() {
    if (!this.mode) {
      return false;
    }
    return (this.numVisitors >= this.mode.maxVisitors);
  };

  /**
   * Check if this booking has other members from the given user
   */
  Booking.prototype.hasOtherMembers = function(user) {
    if (!user || this.members.length === 0) {
      return false;
    }
    return this.members.some(member => member.id !== user.id);
  };

  /**
   * Check if this booking involves a certain member and has an opponent
   */
  Booking.prototype.hasMemberAndOpponent = function(user) {
    if (!user || this.members.length < 2) {
      return false;
    }
    return (this.hasMember(user) && this.hasOtherMembers(user));
  };

  /**
   * Get opponent of a given user for this booking
   */
  Booking.prototype.getOpponent = function(user) {
    return this.members.find(member => member.id !== user.id);
  };

  /**
   * Get members which aren't the given user for this booking
   */
  Booking.prototype.getOtherMembers = function(user) {
    return this.members.filter(member => member.id !== user.id);
  };

  /**
   * Set members
   */
  Booking.prototype.setMembers = function(...members) {
    this.members = members.filter(member => !!member);
  };

  /**
   * Clear members
   */
  Booking.prototype.clearMembers = function() {
    this.members = [];
  };

  /**
   * Clear visitors
   */
  Booking.prototype.clearVisitors = function() {
    this.visitors = [];
  };

  /**
   * Add member
   */
  Booking.prototype.addMember = function(member) {
    if (this.numPeople >= this.mode.maxPeople) {
      return false;
    }
    if (this.hasMember(member)) {
      return false;
    }
    this.members.push(member);
    return true;
  };

  /**
   * Add visitor
   */
  Booking.prototype.addVisitor = function(name = '') {
    this.visitors.push({name});
  };

  /**
   * Remove particular visitor
   */
  Booking.prototype.removeVisitor = function(i) {
    if (this.visitors[i]) {
      this.visitors.splice(i, 1);
    }
  };

  /**
   * Get member identifier string
   */
  Booking.prototype.getMembersIdentifier = function(user) {

    //Many people
    if (this.members.length > 2) {
      return 'everyone';
    }

    //List of names
    const names = [];

    //User is in the booking?
    if (this.hasMember(user)) {
      names.push('yourself');
      if (this.members.length === 2) {
        const opponent = this.getOpponent(user);
        names.push(opponent.name);
      }
    }
    else {
      this.members.forEach(member => names.push(member.name));
    }

    //Join
    return names.join(' and ');
  };

  /**************************************************************************
   * Instance API methods
   ***/

  /**
   * Validate on the server
   */
  Booking.prototype.validate = function(includeRecurrence = false) {
    const json = this.toJSON();
    const {
      activity, area, members, mode, startDate, endDate, visitors,
      forOthers, isRecurring, recurrence, skipConflictingBookings,
    } = json;
    const data = {
      activity, area, members, mode, startDate, endDate, visitors,
      forOthers, isRecurring, recurrence, skipConflictingBookings,
    };
    if (isRecurring && !includeRecurrence) {
      delete data.recurrence;
    }
    return $api.booking.validate(data);
  };

  /**
   * Pre-validate when making booking for others
   */
  Booking.prototype.preValidate = function() {
    const json = this.toJSON();
    const {
      activity, area, members, mode, startDate, endDate, visitors,
      forOthers, isRecurring, recurrence,
    } = json;
    const data = {
      activity, area, members, mode, startDate, endDate, visitors,
      forOthers, isRecurring, recurrence,
    };
    return $api.booking.preValidate(data);
  };

  /**
   * Save
   */
  Booking.prototype.save = function(data) {

    //Can only use save method for new bookings
    if (this.id) {
      throw new Error(`Cannot use save to update bookings. Use .patch() instead.`);
    }

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Create booking
    return $api.booking
      .create(data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Validate patch on the server
   */
  Booking.prototype.validateChange = function(data) {

    //Extract only patchable data
    const {area, startDate, endDate, members, visitors, notes} = data;
    const patch = {area, startDate, endDate, members, visitors, notes};
    const {id} = this;

    //Validate booking patch
    return $api.booking.validateChange({id}, patch);
  };

  /**
   * Patch
   */
  Booking.prototype.patch = function(data) {

    //Extract only patchable data
    const {area, startDate, endDate, members, visitors, notes} = data;
    const patch = {area, startDate, endDate, members, visitors, notes};
    const {id} = this;

    //Create booking
    return $api.booking
      .patch({id}, patch)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Booking.prototype.delete = function(data) {
    const {method, reason, sendConfirmationEmail} = data;
    const params = {
      id: this.id,
      method: method || 'instance',
    };
    if (reason) {
      params.reason = reason;
    }
    if (sendConfirmationEmail) {
      params.sendConfirmationEmail = sendConfirmationEmail;
    }
    return $api.booking
      .delete(params)
      .then(() => this);
  };

  /**
   * Get tags
   */
  Booking.prototype.getTags = function() {
    const params = {id: this.id};
    return $api.booking
      .getTags(params)
      .then(data => data.tags);
  };

  /**
   * Use coupons to pay for this booking (own booking)
   */
  Booking.prototype.useCouponsOwn = function(coupons) {
    const params = {id: this.id};
    return $api.booking
      .useCouponsOwn(params, {coupons})
      .then(data => this.fromJSON(data));
  };

  /**
   * Use coupons to pay for this booking (as admin)
   */
  Booking.prototype.useCoupons = function(coupons) {
    const params = {id: this.id};
    return $api.booking
      .useCoupons(params, {coupons})
      .then(data => this.fromJSON(data));
  };

  /**
   * Mark as paid
   */
  Booking.prototype.markPaid = function(data) {
    return $api.booking
      .markPaid({id: this.id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Make sure there are enough sessions left on coupons
   */
  Booking.prototype.enoughCouponSessions = function(coupons) {

    //Get data
    const {coupons: {sessionsNeeded}, activity} = this;
    let numSessionsNeeded = sessionsNeeded;

    //Loop coupons
    for (const coupon of coupons) {

      //Get sessions left and check if anything to do
      const numSessionsLeft = coupon.getSessionsLeftForActivity(activity);
      if (numSessionsLeft > 0) {

        //Deduct
        const numUsed = Math.min(numSessionsNeeded, numSessionsLeft);
        numSessionsNeeded -= numUsed;
      }

      //No more sessions needed?
      if (numSessionsNeeded === 0) {
        continue;
      }
    }

    //Check if anything left
    return (numSessionsNeeded === 0);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query bookings
   */
  Booking.query = function(filter) {
    return $api.booking.query(filter);
  };

  /**
   * Get own bookings
   */
  Booking.own = function(filter) {
    return $api.booking
      .own(filter)
      .then(data => data.bookings);
  };

  /**
   * Find by ID
   */
  Booking.findById = function(id) {
    return $api.booking.get({id});
  };

  /**
   * Get recent members for a booking for given member and activity
   */
  Booking.getRecentMembers = function(memberId, activityId) {
    return $api.booking.getRecentMembers({memberId, activityId});
  };

  /**
   * Export bookings
   */
  Booking.export = function(filter) {
    Intercom.event('Exported bookings');
    return $sync.get('booking/export/csv', filter, 'Exporting...');
  };

  //Return
  return Booking;
});
