
/**
 * Module definition and dependencies
 */
angular.module('Shared.Categories.Card', [
  'Shared.CategoryOptions.Component',
])

/**
 * Component
 */
.component('cardCategories', {
  templateUrl: 'cards/categories.html',
  controller: 'CardCategoriesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    singular: '@',
    plural: '@',
    model: '@',
    items: '<',
  },
})

/**
 * Controller
 */
.controller('CardCategoriesCtrl', function(
  $injector, $modal, BaseColors
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set flags
    this.hasItems = this.items.length > 0;
    this.colors = BaseColors;
    this.choosingColorId = null;
    this.Model = $injector.get(this.model);
  };

  /**
   * On added handler
   */
  this.onAdded = function($event) {

    //Get new item
    const {item} = $event;

    //Add to array
    this.items.push(item);
    this.hasItems = true;
  };

  /**
   * On deleted handler
   */
  this.onDeleted = function($event) {

    //Get item and find the index
    const {item} = $event;
    const {id} = item;
    const index = this.items.findIndex(f => f.id === id);

    //Splice
    if (index > -1) {
      this.items.splice(index, 1);
      this.hasItems = this.items.length > 0;
    }
  };

  /**
   * Add item
   */
  this.add = function(copy) {

    //Prepare data
    const {Model, model: type, singular} = this;
    const isEdit = false;
    const color = BaseColors.random();

    //Get new item and define handler
    const item = copy || new Model({color});
    const handler = data => item.save(data);

    //Open modal
    $modal
      .open('editCategory', {
        locals: {singular, item, isEdit, type, handler},
      })
      .result
      .then(() => this.onAdded({item}));
  };

  /**
   * Edit item
   */
  this.edit = function($event) {

    //Get item
    const {singular, model: type} = this;
    const {item} = $event;
    const isEdit = true;

    //Define handler
    const handler = data => item.patch(data);

    //Open modal
    $modal
      .open('editCategory', {
        locals: {singular, item, isEdit, type, handler},
      });
  };

  /**
   * Delete item
   */
  this.delete = function($event) {

    //Get item
    const {singular} = this;
    const {item} = $event;
    const {name} = item;

    //Define handler
    const handler = () => item.delete();

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-remove-generic.html',
        locals: {name, singular, handler},
      })
      .result
      .then(() => this.onDeleted({item}));
  };

  /**
   * Edit rules
   */
  this.editRules = function($event) {

    //Get item
    const {item: group} = $event;

    //Define handler
    const handler = (model) => {
      const ruleSets = model.ruleSets
        .map(ruleSet => ({
          rules: ruleSet.rules
            .map(
              ({type, condition, value, prop}) => ({type, condition, value, prop})
            ),
        }));
      return group.patch({ruleSets});
    };

    //Show confirmation
    $modal
      .open('editDynamicGroupRules', {
        locals: {group, handler},
      });
  };

  /**
   * Choose color
   */
  this.toggleChooseColor = function(item) {
    if (this.choosingColorId === item.id) {
      this.choosingColorId = null;
    }
    else {
      this.choosingColorId = item.id;
    }
  };

  /**
   * Set color
   */
  this.setColor = function(item, color) {

    //Check if changed
    if (!color || item.color === color) {
      this.choosingColorId = null;
      return;
    }

    //Update locally
    this.choosingColorId = null;
    item.color = color;

    //Save
    item.patch({color});
  };

  /**
   * Copy category
   */
  this.copy = function($event) {

    //Get category
    const {item} = $event;

    //Create copy
    const copy = item.copy();
    this.add(copy);
  };

});
