
/**
 * Module definition and dependencies
 */
angular.module('Shared.MemberExists.Directive', [])

/**
 * Directive
 */
.directive('memberExists', function(Member, $q, $cacheFactory, $timeout) {
  return {
    require: 'ngModel',
    scope: {
      isChecking: '=',
      ignore: '<',
      noCache: '<',
    },
    //eslint-disable-next-line no-unused-vars
    link(scope, element, attrs, ngModel) {

      //Field to check and value to ignore
      const type = attrs.name;

      //Get or create cache for this type
      const cacheId = 'memberExists.' + type;
      const cache = $cacheFactory.get(cacheId) || $cacheFactory(cacheId);

      //Pending validation
      let pendingValidation = null;

      //Add asynchronous validator
      ngModel.$asyncValidators.exists = function(modelValue, viewValue) {

        //Cancel pending validation
        if (pendingValidation) {
          $timeout.cancel(pendingValidation);
        }

        //Helper to get the validation promise
        function getValidationPromise() {

          //Get value to check
          let value = modelValue || viewValue;

          //Ignore value?
          if (!value || scope.ignore && scope.ignore === value) {
            return $q.resolve();
          }

          //Check cache
          if (!scope.noCache) {
            let exists = cache.get(value);
            if (exists !== undefined) {
              if (exists) {
                return $q.reject(new Error('Exists'));
              }
              else {
                return $q.resolve();
              }
            }
          }

          //Create new model with data to check
          let data = {};
          data[type] = value;

          //Mark as checking
          scope.isChecking = true;

          //Check for existence
          return Member
            .exists(type, data)
            .then(exists => {
              cache.put(value, exists);
              if (exists) {
                throw new Error('Exists');
              }
              else {
                return $q.resolve();
              }
            })
            .finally(() => scope.isChecking = false);
        }

        //Create pending validation promise
        pendingValidation = $timeout(() => {
          pendingValidation = null;
          return getValidationPromise();
        }, 500);

        //Return it
        return pendingValidation;
      };
    },
  };
});
