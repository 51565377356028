
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Edit.SignInDetails.Card', [])

/**
 * Component
 */
.component('cardMemberSignInDetails', {
  templateUrl: 'admin/people/members/cards/sign-in-details.html',
  controller: 'CardMemberSignInDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    member: '<',
    isOwn: '<',
    isEdit: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberSignInDetailsCtrl', function(
  CardCloseReasons, OAuthProviders, Auth, ValidationError, Helpers
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data
    this.isSaving = false;
    const {club} = this;
    const {workspace} = club.settings.signIn.providers.google;

    //Available oAuth providers
    this.providers = OAuthProviders
      .filter(provider => club.settings.signIn.providers[provider.id].isEnabled);

    //Workspace email pattern
    if (workspace) {
      this.workspaceEmailPattern = new RegExp('^[a-zA-Z0-9-_\\.]+@' +
        Helpers.escapeRegex(workspace) + '$');
    }

    //Create model subset
    this.model = this.member.extract([
      'username', 'workspaceEmail',
    ]);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;
  };

  /**
   * Connect to new provider
   */
  this.connect = function(provider) {

    //Set provider and flag
    this.provider = provider;
    this.isConnecting = true;

    //Get member id and club identifier
    const club = this.club.identifier;
    const {id} = this.member;

    //Login
    Auth.loginWithOAuthProvider(provider, 'connect', {id, club});
  };

  /**
   * Disconnect profile
   */
  this.disconnect = function(profile) {

    //Set flag
    this.isDisconnecting = true;

    //Disconnect profile
    this.member
      .disconnectProfile(profile)
      .finally(() => this.isDisconnecting = false);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Extract data
    const {member, model} = this;
    const $event = {member, model, isEdit: true};

    //Save
    this
      .onSave({$event})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .catch(error => {
        if (error instanceof ValidationError) {
          return error.setFieldsValidity(this.form);
        }
        throw error;
      })
      .finally(() => this.isSaving = false);
  };
});
