
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Add', [
  'App.Admin.Event.Add.Controller',
  'App.Admin.Event.Add.Details.Card',
  'App.Admin.Event.Add.Club.Card',
  'App.Admin.Event.Add.DateTime.Card',
  'App.Admin.Event.Add.Notifications.Card',
  'App.Admin.Event.Add.System.Card',
  'App.Admin.Event.Add.Recurrence.Card',
  'App.Admin.Event.Add.Attendance.Card',
  'App.Admin.Event.Add.Organisers.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.event.add', {
    url: '/create/:eventId',
    params: {
      eventId: null,
      squash: true,
    },
    component: 'adminEventAddRoute',
    data: {
      roles: ['admin', 'eventManager'],
    },
    resolve: {
      areas($store) {
        return $store.areas.query();
      },
      event(transition, $store, Event) {
        const params = transition.params();
        if (!params.eventId) {
          return new Event();
        }
        return $store.events
          .findById(params.eventId)
          .then(event => event.clone(true));
      },
      customFields($store) {
        return $store.customFields.query({model: 'Event'});
      },
      categories($store) {
        return $store.eventCategories.query();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminEventAddRoute', {
  controller: 'AdminEventAddCtrl',
  templateUrl: 'admin/event/add/add.html',
  bindings: {
    club: '<',
    user: '<',
    event: '<',
    areas: '<',
    system: '<',
    activities: '<',
    customFields: '<',
    categories: '<',
    transition: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({
    to: 'admin.event.add',
  }, () => {
    Page.setTitle('Create event');
    Page.addCrumb({sref: 'admin.event.add'});
  });
});
