
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Overview', [
  'App.Admin.Event.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.event.overview', {
    url: '',
    component: 'adminEventOverviewRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('events');
      },
      areas($store) {
        return $store.areas.query();
      },
    },
  });
})

/**
 * Route component
 */
.component('adminEventOverviewRoute', {
  controller: 'AdminEventOverviewCtrl',
  templateUrl: 'admin/event/overview/overview.html',
  bindings: {
    filter: '<',
    page: '<',
    club: '<',
    user: '<',
    areas: '<',
    activities: '<',
  },
});
