
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Subscriptions.Card', [
  'App.Admin.People.Members.SubscriptionOptions.Component',
])

/**
 * Component
 */
.component('cardMemberSubscriptions', {
  templateUrl: 'admin/people/members/cards/subscriptions.html',
  controller: 'CardMemberSubscriptionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    user: '<',
    member: '<',
    activities: '<',
    subscriptions: '<',
    onReload: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberSubscriptionsCtrl', function(
  $q, $modal, $notice, $store, moment, Subscription, Membership,
  AutoRenewalPaymentStrategies
) {

  /**
   * On changes
   */
  this.$onChanges = function() {
    this.checkSubscriptions();
  };

  /**
   * Check subscriptions
   */
  this.checkSubscriptions = function() {
    this.hasSubscriptions = this.subscriptions.length > 0;
    this.sortSubscriptions();
  };

  /**
   * Sort subscriptions
   */
  this.sortSubscriptions = function() {
    this.subscriptions.sort((a, b) => {
      if (!b.endDate && a.endDate) {
        return 1;
      }
      if (!a.endDate && b.endDate) {
        return -1;
      }
      if (!a.endDate && !b.endDate) {
        return 0;
      }
      if (a.endDate.isBefore(b.startDate)) {
        return 1;
      }
      if (a.startDate.isAfter(b.endDate)) {
        return -1;
      }
      return 0;
    });
  };

  /**
   * Add subscription
   */
  this.add = function() {

    //Initialize start date
    const startDate = moment().startOf('day');

    //Prepare new subscription and filter only non archived memberships
    const {member} = this;
    const subscription = new Subscription({startDate, member});

    //Define handler
    const handler = function(model) {
      return $store.subscriptions
        .save(subscription, model)
        .then(() => {
          if (model.meta.createTransaction) {
            member.amountOwing += model.meta.amount;
          }
        });
    };

    //Toggle flag
    this.isAdding = true;

    //Open modal
    const modal = $modal
      .open('addSubscription', {
        locals: {member, subscription, handler},
      });

    //Open handler
    modal.opened
      .finally(() => this.isAdding = false);

    //Handle result
    modal.result
      .then(() => {
        $notice.show('Membership added');
        this.onReload();
      });
  };

  /**
   * Renew a subscription
   */
  this.renew = function($event) {

    //Get subscription
    const {subscription} = $event;
    const {member} = this;

    //Get data
    return $q.all([
      subscription.getRenewData(),
      Membership.findById(subscription.membership.id),
    ])
      .then(([data, membership]) => {

        //Define handler
        const handler = function(model) {
          return subscription
            .renew(model)
            .then(subscription => {
              if (model.meta.createTransaction) {
                member.amountOwing += model.meta.amount;
              }
              return subscription;
            });
        };

        //Open modal
        $modal
          .open('renewSubscriptionAdmin', {locals: {
            subscription, membership, member, handler, data,
          }})
          .result
          .then(() => {
            $notice.show('Membership renewed');
            this.onReload();
          });
      });
  };

  /**
   * Edit a subscription
   */
  this.edit = function($event) {

    //Get subscription
    const {subscription} = $event;
    const {member} = this;

    //Define handler
    const handler = function(data) {
      return subscription.update(data);
    };

    //Open modal
    $modal
      .open('editSubscription', {locals: {subscription, member, handler}})
      .result
      .then(() => $notice.show('Membership updated'));
  };

  /**
   * Pause a subscription
   */
  this.pause = function($event) {

    //Get subscription
    const {subscription} = $event;

    //Define handler
    const handler = (endDate, startDate) => subscription
      .pause(endDate, startDate);

    //Open modal
    $modal
      .open('pauseSubscription', {locals: {subscription, handler}})
      .result
      .then(() => {
        $notice.show('Membership paused');
        this.onReload();
      });
  };

  /**
   * Stop a subscription
   */
  this.stop = function($event) {

    //Get subscription
    const {subscription} = $event;

    //Define handler
    const handler = (endDate) => subscription.stop(endDate);

    //Open modal
    $modal
      .open('stopSubscription', {locals: {subscription, handler}})
      .result
      .then(() => {
        $notice.show('Membership stopped');
        this.onReload();
      });
  };

  /**
   * Remove subscription
   */
  this.delete = function($event) {

    //Get subscription
    const {subscription} = $event;

    //Define handler
    const handler = function() {
      return subscription.delete();
    };

    //Open modal
    $modal
      .open('basic', {
        templateUrl: `admin/people/members/modals/confirm-delete-subscription.html`,
        locals: {subscription, handler},
      })
      .result
      .then(() => {
        $notice.show('Membership removed');
        this.onReload();
      });
  };

  /**
   * Toggle auto renewal
   */
  this.toggleAutoRenewal = function($event) {

    //Get subscription
    const {subscription} = $event;

    //Define handler
    const handler = () => subscription.toggleAutoRenew();

    //Open modal
    $modal
      .open('basic', {
        templateUrl: `admin/people/members/modals/confirm-toggle-auto-renewal.html`,
        locals: {subscription, AutoRenewalPaymentStrategies, handler},
      })
      .result
      .then(() => {
        $notice.show(`Auto renewal ${subscription.autoRenews ? 'enabled' : 'disabled'}`);
      });
  };

  /**
   * View details
   */
  this.view = function(subscription) {

    //Check if can view members
    const canViewMembers = this.user.hasRole('admin', 'viewer');

    //Show loading notice
    $notice.showLoading();

    //Load full subscription with full populated membership
    $store.subscriptions
      .findById(subscription.id, true)
      .then(subscription => {
        $modal.open('viewMembership', {locals: {subscription, canViewMembers}});
      })
      .finally(() => $notice.hideLoading());
  };

  /**
   * Download badge
   */
  this.downloadBadge = function($event) {
    const {subscription} = $event;
    const {member} = this;
    member.downloadBadge(subscription.id);
  };
});
