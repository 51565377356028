
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity.Modes.Card', [])

/**
 * Component
 */
.component('cardActivityModes', {
  templateUrl: 'admin/activity/cards/modes.html',
  controller: 'CardActivityModesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    activity: '<',
    isSuper: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardActivityModesCtrl', function($modal, $store, $notice, Mode) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flags
    this.isSaving = false;
    this.hasModes = this.activity.modes.length > 0;
  };

  /**
   * On added handler
   */
  this.onAdded = function($event) {

    //Get new mode
    const {mode} = $event;

    //Add to array
    this.activity.modes.push(mode);
    this.hasModes = this.activity.modes.length > 0;
  };

  /**
   * On deleted handler
   */
  this.onDeleted = function($event) {

    //Get mode and find the index
    const {mode} = $event;
    const index = this.activity.modes.findIndex(m => m.id === mode.id);

    //Splice
    if (index > -1) {
      this.activity.modes.splice(index, 1);
      this.hasModes = this.activity.modes.length > 0;
    }

    //Remove mode from restrictions in activity
    this.activity.playingTimes.forEach(r => r.removeMode(mode.id));
    this.activity.bookingTimes.forEach(r => r.removeMode(mode.id));
    this.activity.peakTimes.forEach(r => r.removeMode(mode.id));
  };

  /**
   * Add mode
   */
  this.add = function() {

    //Create mode and define handler
    const {activity} = this;
    const mode = new Mode({activity: activity.id});

    //Define handler
    const handler = function(model) {
      return $store.modes.save(mode, model);
    };

    //Show modal
    $modal
      .open('editMode', {locals: {mode, activity, handler}})
      .result
      .then(mode => {
        $notice.show('Mode created');
        this.onAdded({mode});
      });
  };

  /**
   * Edit mode
   */
  this.edit = function($event) {

    //Get mode
    const {mode} = $event;
    const {activity} = this;

    //Define handler
    const handler = function(model) {
      return $store.modes.save(mode, model);
    };

    //Show modal
    $modal
      .open('editMode', {
        locals: {mode, activity, handler, isEdit: true},
      })
      .result
      .then(() => {
        $notice.show('Mode updated');
      });
  };

  /**
   * Delete mode
   */
  this.delete = function($event) {

    //Get mode
    const {mode} = $event;
    const {activity} = this;

    //Define handler
    const handler = function() {
      return mode.delete();
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'admin/activity/modals/confirm-delete-mode.html',
        locals: {mode, activity, handler},
      })
      .result
      .then(mode => {
        $notice.show('Mode removed');
        this.onDeleted({mode});
      });
  };
});
