
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.MailingListBulk.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('mailingListBulk', {
    templateUrl: 'admin/people/members/modals/mailing-list-bulk.html',
    controller: 'ModalMailingListBulkCtrl',
    resolve: {
      integrations($store) {
        'ngInject';
        return $store.integrations.query();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalMailingListBulkCtrl', function(
  $controller, $modalInstance, $notice, Member
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalMailingListCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Count members
    this.countMembers();
  };

  /**
   * Get filter
   */
  this.makeFilter = function() {
    return Object.assign({
      hasEmail: true,
    }, this.filter);
  };

  /**
   * Count members
   */
  this.countMembers = function() {

    //Get filter
    const filter = this.makeFilter();

    //Mark as loading
    this.isLoadingMembers = true;

    //Count members
    Member
      .count(filter)
      .then(num => this.numMembers = num)
      .finally(() => this.isLoadingMembers = false);
  };

  /**
   * Confirm
   */
  this.confirm = function() {

    //Make filter
    const filter = this.makeFilter();
    const {integration, lists, numMembers} = this;

    //Set status map and flags
    this.isAdding = true;
    this.isError = false;

    //Add
    integration.service
      .addMembers(filter, lists)
      .then(() => {
        $modalInstance.close();
        $notice.show(`${numMembers} members will be added to ${integration.name}`);
      })
      .catch(() => this.isError = true)
      .finally(() => this.isAdding = false);
  };
});
