
/**
 * Module definition and dependencies
 */
angular.module('Shared.Settings.Service', [])

/**
 * Service definition
 */
.factory('Settings', function(Helpers) {

  //Settings cache
  let settings = {};

  /**
   * Service interface
   */
  return {

    /**
     * Set settings
     */
    set(obj) {
      settings = obj || {};
    },

    /**
     * Clear settings
     */
    clear() {
      settings = {};
    },

    /**
     * Extract a copy of a path
     */
    extract(path) {
      return angular.copy(Helpers.get(settings, path));
    },

    /**
     * Check if we have a setting
     */
    has(path) {
      return !!Helpers.get(settings, path);
    },

    /**
     * Get a value
     */
    get(path, defaultValue = null) {
      return Helpers.get(settings, path) || defaultValue;
    },

    /**
     * Update a value
     */
    update(path, value) {
      Helpers.setPath(settings, path, value);
    },
  };
});
