
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Add.Restrictions.Card', [])

/**
 * Component
 */
.component('cardCouponTypeAddRestrictions', {
  templateUrl: 'admin/coupon-type/cards/restrictions.html',
  controller: 'CardCouponTypeAddRestrictionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    couponType: '<',
    activities: '<',
    memberships: '<',
    onSave: '&',
    onMerge: '&',
  },
})

/**
 * Controller
 */
.controller('CardCouponTypeAddRestrictionsCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardCouponTypeEditRestrictionsCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Call parent init
    $base.$onInit.call(this);

    //Mark flags
    this.isAdd = true;
    this.isEdit = false;

    //Set can purchase checkbox
    if (typeof this.model.canPurchase === 'undefined') {
      this.model.canPurchase = true;
    }
    if (!this.model.constraint) {
      this.model.constraint = 'all';
    }
  };

  /**
   * Merge
   */
  this.merge = function() {
    const {couponType, model} = this;
    this.onMerge({$event: {couponType, model}});
    this.form.$setPristine();
  };

  /**
   * Previous step
   */
  this.previous = function() {
    this.merge();
    this.card.previousTab();
  };

  /**
   * Next step
   */
  this.next = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Merge and open next tab
    this.merge();
    this.card.nextTab();
  };
});
