
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Staff.Overview', [
  'App.Admin.People.Staff.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.people.staff.overview', {
    url: '',
    component: 'adminStaffOverviewRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('staff');
      },
    },
  });
})

/**
 * Route component
 */
.component('adminStaffOverviewRoute', {
  controller: 'AdminStaffOverviewCtrl',
  templateUrl: 'admin/people/staff/overview/overview.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    page: '<',
    filter: '<',
    integrations: '<',
  },
});
