
/**
 * Module definition and dependencies
 */
angular.module('Shared.MembershipLimitations.Options', [])

/**
 * Constant definition
 */
.constant('MembershipLimitations', [
  {
    value: false,
    label: 'Fixed duration',
  },
  {
    value: true,
    label: 'Unlimited',
  },
]);
