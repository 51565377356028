
/**
 * Module definition and dependencies
 */
angular.module('Shared.MemberFinances.Component', [])

/**
 * Member finances component
 */
.component('memberFinances', {
  template: `
    <div class="MemberFinances">
      <div class="MemberFinances-item">
        <span class="MemberFinances-label">Amount owing</span>
        <span class="MemberFinances-amount" ng-class="{'text-danger': $ctrl.member.amountOwing > 0, 'text-success': $ctrl.member.amountOwing <= 0}">{{$ctrl.member.amountOwing | currency}}</span>
      </div>
      <div class="MemberFinances-item">
        <span class="MemberFinances-label">Account credit</span>
        <span class="MemberFinances-amount" ng-class="{'text-success': $ctrl.member.accountCredit > 0, 'text-danger': $ctrl.member.accountCredit < 0}">{{$ctrl.member.accountCredit | currency}}</span>
      </div>
      <div class="MemberFinances-item">
        <span class="MemberFinances-label">Last payment</span>
        <span class="MemberFinances-amount" ng-if="$ctrl.lastPayment">{{$ctrl.lastPayment.date | moment}}</span>
        <span class="MemberFinances-amount" ng-if="!$ctrl.lastPayment">Never</span>
      </div>
    </div>
  `,
  bindings: {
    member: '<',
    payments: '<',
  },
  controller() {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Filter only debit payments
      this.payments = this.payments.filter(payment => !payment.isCredit);

      //Find last payment
      if (this.payments && this.payments.length > 0) {
        this.lastPayment = this.payments[0];
      }
      else {
        this.lastPayment = null;
      }
    };
  },
});
