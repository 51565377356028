
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.EditSubscription.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editSubscription', {
    templateUrl: 'admin/people/members/modals/edit-subscription.html',
    controller: 'ModalEditSubscriptionCtrl',
    closeOnClick: false,
    resolve: {
      memberships(Membership) {
        'ngInject';
        return Membership
          .query()
          .then(data => data.memberships
            .filter(membership => !membership.isLinked));
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalEditSubscriptionCtrl', function(
  $controller, $modalInstance, $timeout
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Initialize flags
    this.isSaved = false;
    this.isSaving = false;

    //Create model subset
    this.model = this.subscription.extract([
      'membership', 'startDate', 'endDate',
    ]);

    //Set membership
    this.membership = this.memberships
      .find(m => m.id === this.model.membership.id);

    //No idea why
    $timeout(() => {
      this.form.$setPristine();
    }, 50);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;
    this.isErrorEndDate = false;
  };

  /**
   * Changed membership
   */
  this.changedMembership = function(membership) {

    //Set selection in sub
    this.model.membership = membership;
    this.membership = membership ?
      this.memberships.find(m => m.isSame(membership)) : null;

    //Reset age warning flags
    this.showMinAgeWarning = false;
    this.showMaxAgeWarning = false;

    //Check if age warning needs to display
    let age = this.member.age;
    if (age !== null) {
      if (this.membership.minAge && age < this.membership.minAge) {
        this.showMinAgeWarning = true;
      }
      if (this.membership.maxAge && age > this.membership.maxAge) {
        this.showMaxAgeWarning = true;
      }
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Validate end date
    if (this.model.startDate.isAfter(this.model.endDate)) {
      this.isErrorEndDate = true;
      return;
    }

    //Save
    this.isSaving = true;
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isSaving = false);
  };
});
