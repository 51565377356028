
/**
 * Module definition and dependencies
 */
angular.module('Shared.Pagination.Service', [])

/**
 * Service definition
 */
.factory('Pagination', function(Interface) {

  //Service
  const Pagination = {

    /**
     * Current pages
     */
    currentPages: {},

    /**
     * Set state
     */
    setState(state) {
      this.state = state;
    },

    /**
     * Set current page
     */
    setCurrentPage(page) {
      if (this.state) {
        if (this.currentPages[this.state] !== page) {
          Interface.scrollToTop();
        }
        this.currentPages[this.state] = page;
      }
    },

    /**
     * Get current page
     */
    getCurrentPage() {
      if (this.state && this.currentPages[this.state]) {
        return this.currentPages[this.state];
      }
      return 1;
    },
  };

  //Return service
  return Pagination;
});
