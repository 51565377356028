
/**
 * Module definition and dependencies
 */
angular.module('Shared.EditCategory.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editCategory', {
    templateUrl: 'modals/edit-category.html',
    controller: 'ModalEditCategoryCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalEditCategoryCtrl', function(
  $controller, $modalInstance, $focus
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Create model subset
    this.model = this.item.extract([
      'name', 'isDynamic',
    ]);

    //Focus on name
    $focus('name');
  };
});
