
/**
 * Module definition and dependencies
 */
angular.module('Shared.User.Model', [
  'Shared.Member.Model',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('user', {
    model: 'User',
    url: 'user',
    params: null,
    actions: {

      //Public routes
      register: {
        method: 'POST',
        url: 'register',
      },
      forgotPassword: {
        method: 'POST',
        url: 'forgotPassword',
      },
      forgotUsername: {
        method: 'POST',
        url: 'forgotUsername',
      },
      resetPassword: {
        method: 'POST',
        url: 'resetPassword',
      },
      verifyEmail: {
        method: 'POST',
        url: 'verifyEmail',
      },
      findByToken: {
        method: 'GET',
        url: 'findByToken',
        isModel: true,
      },
      setup: {
        method: 'POST',
        url: 'setup',
      },

      //Authenticated routes
      me: {
        method: 'GET',
        url: 'me',
        isModel: true,
        withCredentials: true,
      },
      token: {
        method: 'GET',
        url: 'token/:type',
      },
      patch: {
        method: 'PATCH',
      },
      changeCredentials: {
        method: 'PATCH',
        url: 'credentials',
      },
      agreeToTermsAndConditions: {
        method: 'POST',
        url: 'agreeToTermsAndConditions',
      },
      snoozeReminder: {
        method: 'POST',
        url: 'snoozeReminder',
      },
      sendVerificationEmail: {
        method: 'GET',
        url: 'verifyEmail',
      },
      verifyVaccinationStatus: {
        method: 'PUT',
        url: 'vaccination',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('user', {
    model: 'User',
    service: '$instanceStore',
  });
})

/**
 * Model definition
 */
.factory('User', function($api, moment, Member) {

  /**
   * User constructor
   */
  function User(data) {
    Member.call(this, data);
  }

  /**
   * Extend prototype
   */
  angular.extend(User.prototype, Member.prototype);

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Patch user
   */
  User.prototype.patch = function(data) {

    //Convert DOB
    if (data.dob && moment.isMoment(data.dob)) {
      data.dob = moment(data.dob).toDob();
    }

    //Unmutable data by user
    delete data.number;
    delete data.groups;
    delete data.signUpDate;
    delete data.vaccination;

    //Patch
    return $api.user
      .patch(null, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Validate vaccination status
   */
  User.prototype.verifyVaccinationStatus = function(data) {
    return $api.user
      .verifyVaccinationStatus(data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Agree to club terms and conditions
   */
  User.prototype.agreeToTermsAndConditions = function(hasAgreed) {
    return $api.user
      .agreeToTermsAndConditions({hasAgreed})
      .then(data => Object.assign(this, data));
  };

  /**
   * Check if we need a reminder
   */
  User.prototype.needsReminder = function(type, condition) {

    //Get reminder
    const reminder = this.reminders[type];

    //Never reminded or snoozed
    if (!reminder) {
      return true;
    }

    //If suppressed, check condition
    if (reminder.suppressed) {

      //If condition given, show if different from remembered condition
      if (typeof condition !== 'undefined') {
        return (reminder.condition !== condition);
      }

      //No condition, don't show
      return false;
    }

    //If snoozed, check timestamp
    if (reminder.snoozed) {
      const [num, unit] = reminder.snoozed;
      const threshold = moment(reminder.timestamp).add(num, unit);
      return moment().isAfter(threshold);
    }

    //No need to remind
    return false;
  };

  /**
   * Snooze a reminder
   */
  User.prototype.snoozeReminder = function(type, num, unit, condition) {

    //Array given?
    if (Array.isArray(num)) {
      [num, unit] = num;
    }

    //Snooze
    return $api.user
      .snoozeReminder({type, condition, snoozed: [num, unit]})
      .then(data => this.reminders[type] = data);
  };

  /**
   * Suppress a reminder
   */
  User.prototype.suppressReminder = function(type, condition) {
    return $api.user
      .snoozeReminder({type, condition, suppressed: true})
      .then(data => this.reminders[type] = data);
  };

  /**
   * Send verification mail
   */
  User.prototype.sendVerificationEmail = function() {
    return $api.user.sendVerificationEmail();
  };

  /**
   * Change credentials
   */
  User.prototype.changeCredentials = function(credentials) {
    return $api.user
      .changeCredentials(credentials)
      .then(data => this.fromJSON(data));
  };

  /**
   * Refresh account credit
   */
  User.prototype.refreshAccountCredit = function() {
    return $api.accountCredit
      .balanceOwn()
      .then(({accountCredit}) => this.accountCredit = accountCredit);
  };

  /**
   * Setup account in welcome flow
   */
  User.prototype.setup = function(token) {

    //Make JSON data
    const data = this.toJSON({token});

    //TODO: Filtering only allowed data, change this in Vue to pass in model
    //data differently, step by step, building up a non-user model
    const {
      firstName, lastName, gender, dob,
      email, phone, mobile, address, postalAddress, customFields,
      profiles, listInDirectory,
      username, password,
    } = data;

    //Create patch
    const patch = {
      firstName, lastName, gender, dob,
      email, phone, mobile, address, postalAddress, customFields,
      profiles, listInDirectory, token,
      username, password,
    };

    //Setup
    return $api.user
      .setup(patch)
      .then(data => {
        this.fromJSON(data.user);
        return data;
      });
  };

  /**
   * Refresh user data
   */
  User.prototype.refresh = function() {
    return $api.user
      .me()
      .then(data => this.fromJSON(data));
  };

  /**
   * Get user token of given type
   */
  User.prototype.getToken = function(type) {
    return $api.user
      .token({type})
      .then(data => data.token);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Get logged in user instance
   */
  User.get = function() {
    return $api.user.me();
  };

  /**
   * Forgot password
   */
  User.forgotPassword = function(credentials) {
    return $api.user.forgotPassword(credentials);
  };

  /**
   * Forgot username
   */
  User.forgotUsername = function(credentials) {
    return $api.user.forgotUsername(credentials);
  };

  /**
   * Reset password
   */
  User.resetPassword = function(credentials) {
    return $api.user.resetPassword(credentials);
  };

  /**
   * Verify email
   */
  User.verifyEmail = function(token) {
    return $api.user.verifyEmail({token});
  };

  /**
   * Find by token
   */
  User.findByToken = function(token) {
    return $api.user.findByToken({token});
  };

  //Return
  return User;
});
