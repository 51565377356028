
/**
 * Module definition and dependencies
 */
angular.module('App.Directory.Overview', [
  'App.Directory.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('directory.overview', {
    url: '',
    component: 'directoryOverviewRoute',
    resolve: {
      filter(Filters) {
        return Filters.get('directory');
      },
    },
  });
})

/**
 * Route component
 */
.component('directoryOverviewRoute', {
  controller: 'DirectoryOverviewCtrl',
  templateUrl: 'directory/overview/overview.html',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    page: '<',
    filter: '<',
    module: '<',
    integrations: '<',
  },
});
