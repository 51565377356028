
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Edit.Club.Card', [])

/**
 * Component
 */
.component('cardEventEditClub', {
  templateUrl: 'admin/event/cards/club.html',
  controller: 'CardEventEditClubCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    event: '<',
    customFields: '<',
    categories: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventEditClubCtrl', function(
  $modal, $store, CardCloseReasons, EventTypes, EventCategory,
  Config, Upload
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;
    this.isEdit = true;
    this.EventTypes = EventTypes;

    //Replace event type label
    this.EventTypes[0].label = this.club.name;

    //Create model subset
    this.model = this.event.extract([
      'customFields', 'categories',
    ]);

    //Custom fields object
    if (!this.model.customFields) {
      this.model.customFields = {};
    }

    //All custom fields must be defined if not set
    this.customFields.forEach(field => {
      const {prop} = field;
      if (typeof this.model.customFields[prop] === 'undefined') {
        this.model.customFields[prop] = '';
      }
    });

    //Split in top/bottom fields
    this.customFieldsTop = this.customFields
      .filter(field => !field.isParagraph);
    this.customFieldsBottom = this.customFields
      .filter(field => field.isParagraph);

    //Load all the event categories
    this.loadCategories();
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Load categories
   */
  this.loadCategories = function() {
    return $store.eventCategories
      .query(true)
      .then(categories => this.categories = categories);
  };

  /**
   * Manage groups
   */
  this.manageCategories = function() {

    //Get model & categories
    const Model = EventCategory;
    const singular = 'category';
    const plural = 'categories';

    //Open modal
    $modal
      .open('manageCategories', {locals: {
        Model, singular, plural,
      }})
      .result
      .then(categories => {

        //Call reloader
        if (this.reloader) {
          this.reloader();
        }

        //Update categories and initiate model if needed
        this.categories = categories;
        this.model.categories = this.model.categories || [];

        //Remove removed categories from model
        this.model.categories = this.model.categories
          .filter(id => this.categories.some(category => category.id === id));
      })
      .then(() => this.loadCategories());
  };

  /**
   * Upload file
   */
  this.uploadFile = function(field) {

    //Get data
    const {event} = this;
    const {id} = event;
    const {baseUrl} = Config.api;
    const {prop, label} = field;
    const title = `Upload ${label}`;

    //Upload handler
    const onUpload = file => Upload.upload({
      url: `${baseUrl}/event/${id}/file/${prop}`,
      data: {file},
    });

    //Show modal
    $modal
      .open('uploadFile', {
        locals: {title, onUpload},
      })
      .result
      .then(data => this.event.customFiles[prop] = data.file);
  };

  /**
   * Delete file
   */
  this.deleteFile = function(field) {

    //Get data
    const {event} = this;
    const {prop} = field;
    const file = event.customFiles[prop];

    //Create handler
    const handler = () => event.deleteCustomFile(prop, file.name);

    //Show modal
    $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-delete-file.html',
        locals: {handler, file},
      });
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function(method) {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get data and prepare event
    const {event, model, isEdit} = this;
    const $event = {event, model, method, isEdit};

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
