
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Contacts.ContactFields.Constant', [])

/**
 * Contact fields constant
 */
.constant('ContactFields', [
  {
    prop: 'name',
    label: 'Name',
    isRequired: true,
  },
  {
    prop: 'organisation',
    label: 'Organisation',
  },
  {
    prop: 'email',
    label: 'Email',
    isEmail: true,
  },
  {
    prop: 'mobile',
    label: 'Mobile',
    isMobile: true,
  },
  {
    prop: 'phone',
    label: 'Phone',
    isPhone: true,
  },
  {
    prop: 'address.line1',
    label: 'Address line 1',
  },
  {
    prop: 'address.line2',
    label: 'Address line 2',
  },
  {
    prop: 'address.suburb',
    label: 'Suburb',
  },
  {
    prop: 'address.postalCode',
    label: 'Postal code',
  },
  {
    prop: 'address.city',
    label: 'City',
  },
  {
    prop: 'address.state',
    label: 'State',
  },
  {
    prop: 'address.country',
    label: 'Country',
  },
  {
    prop: 'groups',
    label: 'Group(s)',
    isArray: true,
  },
  {
    prop: 'notes',
    label: 'Notes',
    isMultiLine: true,
  },
]);
