
/**
 * Module definition and dependencies
 */
angular.module('Shared.PhoneFormatOptions.Options', [])

/**
 * Constant definition
 */
.constant('PhoneFormatOptions', [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: 'North American',
    value: 'northAmerican',
  },
  {
    label: 'International',
    value: 'international',
  },
]);
