
/**
 * Module definition and dependencies
 */
angular.module('Shared.Pwa.Service', [])

/**
 * Service
 */
.factory('Pwa', function($window, $q, $state, $timeout, Push) {

  /**
   * Define class
   */
  class Pwa {

    /**
     * Constructor
     */
    constructor(url, options = {}) {

      //Store URL and options
      this.url = url;
      this.options = options;

      //Set up listeners
      this.listeners = {};
      this.registration = null;
    }

    /**
     * Setup progressive web app
     */
    setup() {

      //Check if we have service worker available to us
      if ('serviceWorker' in navigator) {
        this.addServiceWorkerListener();
        this.addBeforeInstallPrompListener();
        this.addAppInstalledListener();
        this.addMessageListener();
      }
    }

    /**
     * Add service worker listener
     */
    addServiceWorkerListener() {
      this.registerValidServiceWorker();
    }

    /**
     * Add listener for before install prompt
     */
    addBeforeInstallPrompListener() {
      $window.addEventListener('beforeinstallprompt', event => {
        event.preventDefault();
        this.deferredInstallPrompt = event;
        this.emit('beforeinstallprompt', event);
      });
    }

    /**
     * Add listener for app installed event
     */
    addAppInstalledListener() {
      $window.addEventListener('appinstalled', () => {
        this.emit('appinstalled');
      });
    }

    /**
     * Add message listener
     */
    addMessageListener() {
      navigator.serviceWorker.addEventListener('message', event => {

        //No data
        if (!event.data) {
          return;
        }

        //Extract event data
        const {action, data} = event.data;

        //Cancel booking
        if (action === 'cancelBooking') {
          const {activity, date, bookingId} = data;
          $state.go('bookings', {activity, date, bookingId}, {reload: true});
        }

        //Go to bookings page
        if (action === 'bookings') {
          const {activity, date} = data;
          $state.go('bookings', {activity, date}, {reload: true});
        }

        //Go to account
        if (action === 'account') {
          $state.go('account.overview');
        }

        //Go to subscriptions page
        if (action === 'subscription') {
          $state.go('subscription.overview');
        }
      });
    }

    /**
     * Prompt to add to homescreen
     */
    promptAddToHomeScreen() {

      //No deferred prompt saved
      if (!this.deferredInstallPrompt) {
        return;
      }

      //Prompt
      this.deferredInstallPrompt.prompt();

      //Await user choice
      this.deferredInstallPrompt.userChoice
        .then(result => this.emit('afterinstallprompt', result))
        .finally(() => this.deferredInstallPrompt = null);
    }

    /**
     * Unregister service worker
     */
    unregister() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
          .then(registration => registration.unregister());
      }
    }

    /**
     * Register a valid service worker
     */
    registerValidServiceWorker() {

      //Get options
      const {options, url} = this;

      //Register
      navigator.serviceWorker
        .register(url, options)
        .then(registration => {

          //Store registration
          this.registration = registration;

          //Emit registered event
          this.emit('registered', registration);

          //Check if update found
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  //At this point, the old content will have been purged and
                  //the fresh content will have been added to the cache.
                  this.emit('updated', registration);
                }
              }
            };
          };
        })
        .catch(error => {
          this.emit('error', error);
        });
    }

    /**
     * Check if a valid servie worker exists
     */
    checkValidServiceWorker() {

      //Get URL
      const {url} = this;

      //Check if valid
      fetch(url)
        .then(response => {

          //Get response data
          const {status, headers} = response;

          //Ensure service worker exists, and that we really are getting a JS file.
          if (status === 404) {
            this.emit('error', new Error(`Service worker not found at ${url}`));
            this.unregister();
            return;
          }

          //Check if expected content received
          if (headers.get('content-type').indexOf('javascript') === -1) {
            this.emit('error', new Error(
              `Expected ${url} to have javascript content-type, ` +
              `but received ${headers.get('content-type')}`));
            this.unregister();
            return;
          }

          //Service worker found. Proceed as normal.
          this.registerValidServiceWorker();
        })
        .catch(error => {
          if (!navigator.onLine) {
            this.emit('offline');
          }
          else {
            this.emit('error', error);
          }
        });
    }

    /**************************************************************************
     * Push notifications
     ***/

    /**
     * Check if we should ask permissions for push notifications
     */
    shouldAskPermissionForPush() {
      return (Push.isSupported() && !Push.hasPermission());
    }

    /**
     * Subscribe to push notifications
     */
    async subscribeToPushNotifications() {

      //Not supported or already have permission?
      if (!Push.isSupported() || Push.hasPermission()) {
        return;
      }

      //Get service worker registration
      const {registration} = this;

      //Get permission and subscribe
      try {
        await Push.requestPermission();
        await Push.subscribe(registration);
        this.emit('pushpermission', true);
      }
      catch (error) {
        this.emit('pushpermission', false);
      }
    }

    /**************************************************************************
     * Helpers
     ***/

    /**
     * Register listener
     */
    on(event, listener) {

      //Initialize
      if (!Array.isArray(this.listeners[event])) {
        this.listeners[event] = [];
      }

      //Push listener
      this.listeners[event].push(listener);
    }

    /**
     * Remove listener
     */
    off(event, listener) {
      if (Array.isArray(this.listeners[event])) {
        const i = this.listeners[event].indexOf(listener);
        if (i > -1) {
          this.listeners[event].splice(i, 1);
        }
      }
    }

    /**
     * Emit event to listeners
     */
    emit(event, ...args) {
      if (Array.isArray(this.listeners[event])) {
        $timeout(() => {
          this.listeners[event]
            .forEach(listener => listener.apply(this, args));
        });
      }
    }

    /**
     * Check if running on localhost
     */
    static isLocalhost() {
      return Boolean(
        $window.location.hostname === 'localhost' ||
        $window.location.hostname === '[::1]' ||
        $window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
      );
    }

    /**
     * Unregister all service workers
     */
    static unregisterAll() {
      if ('serviceWorker' in navigator) {
        return navigator.serviceWorker
          .getRegistrations()
          .then(registrations => {
            for (const registration of registrations) {
              registration.unregister();
            }
          });
      }
      else {
        return $q.resolve();
      }
    }
  }

  //Return class constructor
  return Pwa;
});
