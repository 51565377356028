
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Membership.Add.Controller', [])

/**
 * Controller
 */
.controller('AdminMembershipAddCtrl', function(
  $controller, $state, CardCloseReasons
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminMembershipCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Get primary memberships
    this.primaryMemberships = this.memberships.filter(membership => {
      return !membership.isLinked;
    });

    //Determine system modes
    if (this.activities.length > 0) {
      this.systemModes = this.activities[0].modes;
    }
  };

  /**
   * Exit handler
   */
  this.exit = function($event) {

    //Get reason
    const {reason} = $event;

    //If card was closed, go back to overview
    if (reason === CardCloseReasons.CLOSE) {
      $state.go('admin.membership.overview');
    }
    else if (reason === CardCloseReasons.SAVED) {
      $state.go('admin.membership.edit', {membershipId: this.membership.id});
    }
  };
});
