
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.SignupClub.Activity', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('signupClub.activity', {
    url: '/activity',
    component: 'signupActivitiesRoute',
  });
})

/**
 * Route component
 */
.component('signupActivitiesRoute', {
  controller: 'SignupClubActivitiesCtrl',
  templateUrl: 'portal/signup-club/steps/activity.html',
  bindings: {
    club: '<',
  },
})

/**
 * Controller
 */
.controller('SignupClubActivitiesCtrl', function(
  $state, $timeout, AreaTypes
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Activity and area types
    this.AreaTypes = AreaTypes;

    //Area amounts to choose from
    this.areaAmounts = [
      1, 2, 3, 4, 5,
      6, 7, 8, 9, 10,
      11, 12, 13, 14, 15,
      16, 17, 18, 19, 20,
      21, 22, 23, 24, 25,
    ];

    //Start faded
    this.fade = true;
    $timeout(() => this.fade = false);
  };

  /**
   * Update club
   */
  this.updateClub = function(property, value) {
    this.club[property] = value;
  };

  /**
   * Update activity
   */
  this.updateActivity = function(property, value) {

    //Disable bookings if needed
    const check = ['numAreas', 'areaSingular'];
    if (!value && check.includes(property)) {
      return this.disableBookings();
    }

    //Update property
    this.club.activity[property] = value;

    //Set plural form
    if (property === 'areaSingular') {
      const area = AreaTypes.find(area => area.areaSingular === value);
      if (area) {
        this.club.activity.areaPlural = area.areaPlural;
      }
    }
  };

  /**
   * Helper to disable bookings
   */
  this.disableBookings = function() {
    this.club.hasBookings = false;
    this.club.activity.numAreas = 0;
  };

  /**
   * Continue
   */
  this.continue = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Flags
    this.isBusy = true;
    this.fade = true;

    //Go to next step
    $timeout(() => {
      $state.go('signupClub.interests');
    }, 200);
  };

  /**
   * Back to previous step
   */
  this.back = function() {
    this.fade = true;
    $timeout(() => {
      $state.go('signupClub.details');
    }, 200);
  };
});
