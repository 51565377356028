
/**
 * Module definition and dependencies
 */
angular.module('Shared.Transaction.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($storeProvider, $apiProvider) => {

  //Register endpoint
  $apiProvider.registerEndpoint('transaction', {
    model: 'Transaction',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'transactions',
        isArray: true,
        isModel: true,
      },
      own: {
        url: 'own',
        method: 'GET',
        dataKey: 'transactions',
        isArray: true,
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      createMany: {
        url: 'many',
        method: 'POST',
        dataKey: 'transactions',
        isArray: true,
        isModel: true,
      },
      split: {
        url: 'split',
        method: 'POST',
        dataKey: 'transactions',
        isArray: true,
        isModel: true,
      },
      update: {
        method: 'PUT',
      },
      delete: {
        method: 'DELETE',
      },
      notifyByEmail: {
        url: 'notifyByEmail',
        method: 'POST',
      },
      markPaid: {
        url: 'markPaid',
        method: 'POST',
      },
      markNotPaid: {
        url: 'markNotPaid',
        method: 'POST',
      },
      mute: {
        url: 'mute',
        method: 'POST',
      },
      requestApproval: {
        url: 'requestApproval',
        method: 'POST',
      },
      approve: {
        url: 'approve',
        method: 'POST',
      },
      payWithSource: {
        url: 'payment/:method/:source',
        method: 'POST',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('transactions', {
    model: 'Transaction',
    dataKey: 'transactions',
  });
})

/**
 * Model definition
 */
.factory('Transaction', function(
  $baseModel, $api, $q, $sync, moment, Settings, Intercom
) {

  /**
   * Default data
   */
  const defaultData = {
    isCredit: false,
  };

  /**
   * Constructor
   */
  function Transaction(data) {
    $baseModel.call(this, angular.extend({}, defaultData, data || {}));

    //Is due property
    Object.defineProperty(this, 'isDue', {
      get() {

        //If paid, not due
        if (this.isPaid || !this.dueDate) {
          return false;
        }

        //Check if due date reached
        return this.dueDate.isBefore(moment());
      },
    });

    //Is overdue property
    Object.defineProperty(this, 'isOverdue', {
      get() {

        //If paid, not overdue
        if (this.isPaid || !this.dueDate) {
          return false;
        }

        //Get setting
        const overdue = Settings.get('payment.overdueThreshold');
        if (!overdue) {
          return false;
        }

        //Determine threshold
        const {amount, unit} = overdue;
        const threshold = moment().subtract(amount, unit);

        //Check if threshold reached
        return this.dueDate.isBefore(threshold);
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Transaction.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Transaction.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('member', 'Member');
    this.convertToModel('guest', 'Guest');
    this.convertToModel('payment', 'Payment');

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Transaction.prototype.toJSON = function(data) {

    //Call parent method
    let json = $baseModel.prototype.toJSON.call(this, data);

    //Strip data to IDs only
    if (json.member) {
      json.member = $baseModel.onlyId(json.member);
    }
    else if (json.meta && json.meta.members) {
      json.meta.members = $baseModel.onlyId(json.meta.members);
    }

    //Return JSON
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Download invoice
   */
  Transaction.prototype.downloadInvoice = function() {
    return $sync
      .download(`transaction/${this.id}/invoice`);
  };

  /**
   * Save
   */
  Transaction.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Remove unneeded data for creating
    delete data.payment;

    //Log in intercom
    if (!this.id) {
      Intercom.event('Created transaction');
    }

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.transaction[method](data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Save many
   */
  Transaction.prototype.saveMany = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Remove unneeded data for creating
    delete data.member;
    delete data.guest;
    delete data.payment;

    //Log in intercom
    if (!this.id) {
      Intercom.event('Created transaction');
    }

    //For multiple members?
    return $api.transaction.createMany(data);
  };

  /**
   * Delete
   */
  Transaction.prototype.delete = function() {
    return $api.transaction
      .delete(null, this)
      .then(() => this);
  };

  /**
   * Notify by email
   */
  Transaction.prototype.notifyByEmail = function() {
    const {id} = this;
    return $api.transaction
      .notifyByEmail({id});
  };

  /**
   * Mark as paid
   */
  Transaction.prototype.markPaid = function(data) {
    const {id} = this;
    return $api.transaction
      .markPaid({id}, data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Mark as not paid
   */
  Transaction.prototype.markNotPaid = function() {
    const {id} = this;
    return $api.transaction
      .markNotPaid({id})
      .then(data => this.fromJSON(data));
  };

  /**
   * Pay with default source
   */
  Transaction.prototype.payWithSource = function(method, source) {
    const {id} = this;
    return $api.transaction
      .payWithSource({id, method, source}, {});
  };

  /**
   * Split
   */
  Transaction.prototype.split = function(parts) {
    const {id} = this;
    return $api.transaction
      .split({id}, {parts})
      .then(data => data.transactions);
  };

  /**
   * Update payment
   */
  Transaction.prototype.updatePayment = function(data) {

    //Must have linked payment
    if (!this.payment) {
      return $q.resolve();
    }

    //Update
    return this.payment.update(data);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query transactions
   */
  Transaction.query = function(filter) {
    return $api.transaction.query(filter);
  };

  /**
   * Query own transactions
   */
  Transaction.own = function(filter) {
    return $api.transaction
      .own(filter)
      .then(data => data.transactions);
  };

  /**
   * Export transactions
   */
  Transaction.export = function(filter) {
    Intercom.event('Exported transactions');
    return $sync.get('transaction/export/csv', filter, 'Exporting...');
  };

  /**
   * Mark many transactions as paid
   */
  Transaction.markPaid = function(transactions, data) {
    const transactionIds = transactions.map(trx => trx.id);
    return $api.transaction.markPaid({transactionIds}, data);
  };

  /**
   * Mute transactions
   */
  Transaction.mute = function(transactions) {
    const transactionIds = transactions.map(trx => trx.id);
    return $api.transaction.mute({transactionIds});
  };

  /**
   * Request approval for transactions
   */
  Transaction.requestApproval = function(transactions, name, email) {
    const transactionIds = transactions.map(trx => trx.id);
    return $api.transaction.requestApproval({transactionIds, name, email});
  };

  /**
   * Approve transactions
   */
  Transaction.approve = function(token, name) {
    return $api.transaction.approve({token, name});
  };

  //Return
  return Transaction;
});
