
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Login.Controller', [])

/**
 * Controller
 */
.controller('LoginCtrl', function(
  $state, Auth, Kiosk, OAuthProviders
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Get params
    this.params = this.transition.params();

    //Set flag
    this.isKiosk = Kiosk.isEnabled();

    //Get club
    const {club} = this;

    //Check login capabilities
    this.canUseCredentials = (
      !club || !club.settings ||
      club.settings.signIn.canUseCredentials
    );
    this.providers = OAuthProviders
      .filter(provider => (
        !club || !club.settings ||
        club.settings.signIn.providers[provider.id].isEnabled
      ));

    //No providers
    if (this.providers.length === 0) {
      return $state.go(`loginCredentials`);
    }
  };

  /**
   * Login with oAuth provider
   */
  this.login = function(provider) {

    //Set provider and flag
    this.provider = provider;
    this.isLoggingIn = true;

    //Login
    Auth.loginWithOAuthProvider(provider);
  };
});
