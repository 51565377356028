
/**
 * Module definition and dependencies
 */
angular.module('Shared.VisitorFlow.Service', [])

/**
 * Service definition
 */
.factory('VisitorFlow', function(
  $state, $timeout, $storage, VisitorLog, Address
) {

  /**
   * Visitor flow class
   */
  class VisitorFlow {

    /**
     * Constructor
     */
    constructor(club, member, isKiosk) {

      //Set references
      this.club = club;
      this.member = member;
      this.isKiosk = isKiosk;
      this.hasTermsAndConditions = false;

      //Initialize data and determine steps
      this.initializeData();
      this.determineSteps();
    }

    /**
     * Numbered step
     */
    get numberedStep() {
      return this.steps.indexOf(this.step) + 1;
    }

    /**
     * Is at first step
     */
    get isAtFirstStep() {
      return (this.steps.indexOf(this.step) === 0);
    }

    /**
     * Is at last step
     */
    get isAtLastStep() {
      return (this.steps.indexOf(this.step) + 1 === this.steps.length);
    }

    /**************************************************************************
     * Data management
     ***/

    /**
     * Initialize data
     */
    initializeData() {

      //Don't initialize on kiosk
      if (this.isKiosk) {
        this.data = {};
        return;
      }

      //Initialize data
      this.data = $storage.get('visitorLog.data') || {};

      //Convert address to model
      if (this.data.address) {
        this.data.address = new Address(this.data.address);
      }

      //Append member details
      if (this.member) {
        const {firstName, lastName, email, phone, mobile, address} = this.member;
        const member = this.member.id;
        Object.assign(this.data, {
          member, firstName, lastName, email, phone, mobile, address,
        });
      }
    }

    /**
     * Store data
     */
    storeData() {

      //Don't store on kiosk
      if (this.isKiosk) {
        return;
      }

      //Initialize data to store
      const {member, reason} = this.data;
      const data = {reason};

      //Store more data if guest
      if (!member) {
        const {firstName, lastName, phone, email, address} = this.data;
        Object.assign(data, {firstName, lastName, phone, email, address});
      }

      //Member, only store reason
      $storage.set('visitorLog.data', data);
    }

    /**
     * Log visit
     */
    logVisit() {

      //Get data
      const {data} = this;

      //Log
      return VisitorLog.create(data);
    }

    /**************************************************************************
     * Step management
     ***/

    /**
     * Set terms and conditions flag
     */
    setTermsAndConditions(hasTermsAndConditions) {
      this.hasTermsAndConditions = hasTermsAndConditions;
    }

    /**
     * Set the current step (without going there)
     */
    setStep(step) {
      this.step = step;
    }

    /**
     * Go to a particular step
     */
    goToStep(step) {

      //Already on this step
      if ($state.current.name === `visitor.${step}`) {
        return;
      }

      //Go to step
      $timeout(() => $state.go(`visitor.${step}`), 200);
    }

    /**
     * Go to next step
     */
    goToNextStep() {

      //Re-determine steps
      this.determineSteps();

      //Determine next step
      const i = this.steps.indexOf(this.step) + 1;
      if (i >= this.steps.length) {
        return;
      }

      //Get next step
      const step = this.steps[i];
      this.goToStep(step);
    }

    /**
     * Go to previous step
     */
    goToPreviousStep() {

      //Re-determine steps
      this.determineSteps();

      //Determine previous step
      const i = this.steps.indexOf(this.step) - 1;

      if (i < 0) {
        return;
      }

      //Get previous step
      const step = this.steps[i];
      this.goToStep(step);
    }

    /**
     * Finish registration
     */
    finish() {
      this.goToStep('done');
    }

    /**
     * Determine steps
     */
    determineSteps() {

      //Initialize steps
      this.steps = [];

      //Introduction
      this.steps.push('intro');

      //Conditions
      if (this.hasTermsAndConditions) {
        this.steps.push('conditions');
      }

      //Details
      this.steps.push('details');
    }
  }

  //Return class
  return VisitorFlow;
});

