
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Finance.EditTransaction.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editTransaction', {
    templateUrl: 'admin/finance/modals/edit-transaction.html',
    controller: 'ModalEditTransactionCtrl',
    closeOnClick: false,
    resolve: {
      club($store) {
        'ngInject';
        return $store.club.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalEditTransactionCtrl', function(
  $controller, $modalInstance, $notice, TransactionTypes
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set flags
    this.isSaving = false;

    //Set services
    this.TransactionTypes = TransactionTypes;

    //Create model subset
    this.model = this.transaction.extract([
      'dueDate', 'type', 'details', 'amount', 'members',
      'autoCollect', 'tax',
    ]);

    //Multiline flag
    this.isMultiLine = this.transaction.isMultiLine;
  };

  /**
   * Toggle details multiline
   */
  this.toggleMultiLine = function() {
    this.isMultiLine = !this.isMultiLine;
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => $notice.showError('Failed to save transaction'))
      .finally(() => this.isSaving = false);
  };
});
