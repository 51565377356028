
/**
 * Module definition and dependencies
 */
angular.module('App.Account.Credit', [
  'App.Account.Credit.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('account.credit', {
    url: '/credit',
    component: 'accountCreditRoute',
    resolve: {
      payment(PaymentFlow, PaymentTypes, user) {
        return new PaymentFlow(PaymentTypes.ACCOUNT_CREDIT, user);
      },
      outcome(PaymentOutcome) {
        return PaymentOutcome.check();
      },
    },
  });
})

/**
 * Component
 */
.component('accountCreditRoute', {
  controller: 'AccountCreditCtrl',
  templateUrl: 'account/credit/credit.html',
  bindings: {
    club: '<',
    user: '<',
    transition: '<',
    payment: '<',
    outcome: '<',
  },
})

/**
 * Run logic
 */
.run((Page, $transitions) => {

  //Page setup
  $transitions.onSuccess({to: 'account.credit'}, transition => {
    const params = transition.params();
    Page.setTitle('Credit account');
    Page.addCrumb({sref: 'account.credit', params});
  });
});
