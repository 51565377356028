
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.JoiningFees.Card', [])

/**
 * Component
 */
.component('cardClubJoiningFees', {
  templateUrl: 'admin/club/cards/joining-fees.html',
  controller: 'CardClubJoiningFeesCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    memberships: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubJoiningFeesCtrl', function($modal, JoiningFee) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Flags
    this.isSaving = false;
  };

  /**
   * Save joining fees on server
   */
  this.save = function(joiningFees) {

    //Get data
    const model = {joiningFees};

    //Save event
    return this
      .onSave({$event: {model, isEdit: true}});
  };

  /**
   * Add fee
   */
  this.add = function() {

    //Create fee and define handler
    const fee = new JoiningFee();
    const {club} = this;
    const $ctrl = this;

    //Create copy of fees and define handler
    const fees = club.extract('joiningFees');
    const label = (fees.length > 0) ? fees[fees.length - 1].label : `Joining fee`;
    const handler = model => {
      fee.fromJSON(model);
      fees.push(fee);
      return $ctrl.save(fees);
    };

    //Show modal
    $modal.open('editJoiningFee', {locals: {fee, label, handler}});
  };

  /**
   * Edit fee
   */
  this.edit = function($event) {

    //Get fee
    const {fee} = $event;
    const {club} = this;

    //Find and validate index
    const index = club.joiningFees.findIndex(f => f === fee);
    if (index === -1) {
      return;
    }

    //Create copy of fees and define handler
    const fees = club.extract('joiningFees');
    const handler = (model) => {
      fees[index].fromJSON(model);
      return this.save(fees);
    };

    //Show modal
    $modal.open('editJoiningFee', {locals: {fee, handler, isEdit: true}});
  };

  /**
   * Delete fee
   */
  this.delete = function($event) {

    //Get fee
    const {fee} = $event;
    const {club, memberships} = this;

    //Find and validate index
    const index = club.joiningFees.findIndex(f => f === fee);
    if (index === -1) {
      return;
    }

    //Create copy of fees and define handler
    const fees = club.extract('joiningFees');
    const handler = () => {
      fees.splice(index, 1);
      return this.save(fees);
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'admin/club/modals/confirm-delete-joining-fee.html',
        locals: {fee, memberships, handler},
      });
  };
});
