
/**
 * Module definition and dependencies
 */
angular.module('Shared.Lowercase.Directive', [])

/**
 * Directive
 */
.directive('lowercase', function() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link($scope, elem, attrs, ngModelCtrl) {

      /**
       * Lowercasing function
       */
      function lowercase(inputValue) {

        //Guard against undefined
        if (typeof inputValue === 'undefined') {
          inputValue = '';
        }

        //Convert
        const lowercased = inputValue.toLowerCase();

        //Compare with input value and update view value if needed
        if (lowercased !== inputValue) {
          ngModelCtrl.$setViewValue(lowercased);
          ngModelCtrl.$render();
        }

        //Return lowercased
        return lowercased;
      }

      //Add parser
      ngModelCtrl.$parsers.unshift(lowercase);

      //Apply on initial value
      lowercase($scope[attrs.ngModel]);
    },
  };
});
