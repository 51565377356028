
/**
 * Card opener component to display the card contents of openable cards
 */

/**
 * Module definition and dependencies
 */
angular.module('Shared.Cards.CardOpener.Component', [])

/**
 * Component
 */
.component('cardOpener', {
  template: `
    <div class="CardOpener" ng-click="$ctrl.card.open()">
      <section>
        <h3 ng-transclude="title" class="CardOpener-title"></h3>
        <small class="text-muted {{$ctrl.textClass}}" ng-transclude="text"></small>
        <div class="CardOpener-imageContainer" ng-if="$ctrl.image">
          <img ng-src="{{$ctrl.image}}" class="CardOpener-image">
        </div>
        <i class="Icon CardOpener-icon {{$ctrl.iconClass}}" ng-if="$ctrl.icon">{{$ctrl.icon}}</i>
      </section>
    </div>
  `,
  transclude: {
    title: 'tTitle',
    text: 'tText',
  },
  require: {
    card: '^^',
  },
  bindings: {
    image: '@',
    icon: '@',
    iconClass: '@',
    textClass: '@',
  },
});
