
/**
 * Module definition and dependencies
 */
angular.module('App.Booking.RemoveBooking.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('removeBooking', {
    templateUrl: 'booking/modals/remove-booking.html',
    controller: 'ModalRemoveBookingCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalRemoveBookingCtrl', function(
  $controller, $modalInstance, Settings
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Get data
    const changeThreshold = Settings.get('booking.changeThreshold');
    const isEditable = !this.booking.isAfterThreshold(changeThreshold);

    //Flags
    this.isConfirming = false;
    this.sendConfirmationEmail = true;
    this.isOwn = this.booking.hasMember(this.user);
    this.canManageBookings = this.user.hasRole('admin', 'eventManager');
    this.canRemove = (isEditable || this.canManageBookings);
  };

  /**
   * Changed confirmation email checkbox
   */
  this.setConfirmationEmail = function(isChecked) {
    this.sendConfirmationEmail = isChecked;
  };

  /**
   * Confirm
   */
  this.confirm = function() {
    this.isConfirming = true;
    const method = this.removeTail ? 'tail' : 'instance';
    const {reason, sendConfirmationEmail} = this;
    this
      .handler({method, reason, sendConfirmationEmail})
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isConfirming = false);
  };

  /**
   * Set remove tail flag
   */
  this.setRemoveTail = function(removeTail) {
    this.removeTail = removeTail;
  };
});
