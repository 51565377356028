
/**
 * Module definition and dependencies
 */
angular.module('Shared.Tags.Card', [
  'Shared.TagOptions.Component',
])

/**
 * Component
 */
.component('cardTags', {
  templateUrl: 'cards/tags.html',
  controller: 'CardTagsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    item: '@',
    tags: '<',
    member: '<',
    system: '<',
    isOwn: '<',
  },
})

/**
 * Controller
 */
.controller('CardTagsCtrl', function($modal, $notice, Tag) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize flags
    this.hasTags = this.tags.length > 0;
    this.isForMember = (this.member && !this.isOwn);
    this.isForClub = (!this.member && !this.isOwn);
  };

  /**
   * On added handler
   */
  this.onAdded = function($event) {

    //Call edited handler
    this.onEdited($event);

    //Get new tag
    const {tag} = $event;

    //Add to array
    this.tags.push(tag);
    this.hasTags = true;
  };

  /**
   * On edited handler
   */
  this.onEdited = function($event) {

    //Get new tag
    const {tag} = $event;
    const index = this.tags
      .findIndex(
        k => k.number === tag.number &&
        k.facility === tag.facility &&
        k.type === tag.type &&
        k.id !== tag.id
      );

    //Remove if existing tag already present with this number
    if (index > -1) {
      this.tags.splice(index, 1);
    }
  };

  /**
   * On deleted handler
   */
  this.onDeleted = function($event) {

    //Get tag and find the index
    const {tag} = $event;
    const index = this.tags.findIndex(k => k.id === tag.id);

    //Splice
    if (index > -1) {
      this.tags.splice(index, 1);
      this.hasTags = this.tags.length > 0;
    }
  };

  /**
   * Add tag
   */
  this.add = function() {

    //Prepare data
    const data = {};
    const {member, system, isForMember} = this;

    //Member or service tag
    if (isForMember) {
      data.member = member.id;
      data.name = member.name;
    }
    else {
      data.isService = true;
    }

    //Get new tag
    const tag = new Tag(data);

    //Define handler
    const handler = function(data, overwrite) {
      return tag.save(data, overwrite);
    };

    //Open modal
    $modal
      .open('editTag', {locals: {tag, member, system, handler}})
      .result
      .then(() => {
        $notice.show('Tag added');
        this.onAdded({tag});
      });
  };

  /**
   * Edit tag
   */
  this.edit = function($event) {

    //Get tag
    const {tag} = $event;
    const {member, system} = this;

    //Define handler
    const handler = function(data, overwrite) {
      return tag.save(data, overwrite);
    };

    //Open modal
    $modal
      .open('editTag', {locals: {tag, member, system, handler}})
      .result
      .then(() => {
        $notice.show('Tag updated');
        this.onEdited({tag});
      });
  };

  /**
   * Delete tag
   */
  this.delete = function($event) {

    //Get tag and member
    const {tag} = $event;
    const {member, system} = this;

    //Define handler
    const handler = function() {
      return tag.delete();
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-delete-tag.html',
        locals: {tag, member, system, handler},
      })
      .result
      .then(() => {
        $notice.show('Tag removed');
        this.onDeleted({tag});
      });
  };

  /**
   * Enable tag
   */
  this.enable = function($event) {

    //Get tag
    const {tag} = $event;
    const isEnabled = true;

    //Enable right away
    return tag
      .patch({isEnabled})
      .then(() => $notice.show('Tag enabled'));
  };

  /**
   * Disable tag
   */
  this.disable = function($event) {

    //Get tag and member
    const {tag} = $event;
    const {member, system} = this;
    const isEnabled = false;

    //Define handler
    const handler = () => tag.patch({isEnabled});

    //Show confirmation modal
    return $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-disable-tag.html',
        locals: {tag, member, system, handler},
      })
      .result
      .then(() => $notice.show('Tag disabled'));
  };
});
