
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.AddSubscription.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('addSubscription', {
    templateUrl: 'admin/people/members/modals/add-subscription.html',
    controller: 'ModalAddSubscriptionCtrl',
    closeOnClick: false,
    resolve: {
      memberships(Membership) {
        'ngInject';
        return Membership
          .query({isArchived: false})
          .then(data => data.memberships);
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalAddSubscriptionCtrl', function(
  $controller, $modalInstance, moment, Subscription, RefSubscription
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Initialize flags
    this.isSaved = false;
    this.isSaving = false;
    this.showMinAgeWarning = false;
    this.showMaxAgeWarning = false;
    this.showSubLimit = false;
    this.showLinkedSubLimit = false;
    this.numberOfLinkedSubs = 0;
    this.hasManuallyChangedStartDate = false;
    this.hasManuallyChangedEndDate = false;
    this.hasNoMemberships = (this.memberships.length === 0);

    //Create model subset
    this.model = this.subscription.extract([
      'membership', 'startDate', 'endDate', 'primary',
    ]);

    //Model meta data
    this.model.meta = {
      notifyByEmail: !!this.member.email,
      createTransaction: true,
      amount: '',
    };

    //Set data
    this.membership = null;

    //Set membership if subscription ID present
    if (this.subscription.id) {
      this.changedMembership(this.subscription.membership.id);
    }
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;
    if (property === 'startDate') {
      this.hasManuallyChangedStartDate = true;
      this.loadSubscriptionData();
      this.checkPastNonRenewed();
    }
    if (property === 'endDate') {
      this.hasManuallyChangedEndDate = true;
      this.loadSubscriptionData();
      this.checkEndDateInPast();
    }
  };

  /**
   * Changed membership
   */
  this.changedMembership = function(membership) {

    //Set selection in sub
    this.model.membership = membership;
    this.membership = membership ?
      this.memberships.find(m => m.isSame(membership)) : null;

    //Reset primary subs, age warning flags & linked member flags
    this.model.primary = null;
    this.primarySub = null;
    this.showMinAgeWarning = false;
    this.showMaxAgeWarning = false;
    this.showSubLimit = false;
    this.showLinkedSubLimit = false;
    this.numberOfLinkedSubs = 0;

    //Check if anything else to do
    if (!this.membership) {
      return;
    }

    //Check if past subscription warnings needs to show
    this.checkPastNonRenewed();

    //Check if age warning needs to display
    this.checkAge();

    //Check if constraint warning needs to display
    this.checkContraint();

    //Check membership limit
    if (this.membership.limit > 0) {
      this.checkMembershipLimit();
    }

    //Linked membership? Reset dates
    if (this.membership.isLinked) {
      this.model.startDate = this.model.endDate = null;
    }

    //Load subscription data
    this.loadSubscriptionData();
  };

  /**
   * Changed primary subscription
   */
  this.changedPrimarySub = function(sub) {

    //Create primary subscription object if needed
    if (!this.model.primary) {
      this.model.primary = new RefSubscription();
    }

    //Set primary membership holder for bundle limit warning check
    this.primarySub = sub;

    //No sub
    if (!sub) {
      return;
    }

    //Append primary subscription ID
    this.model.primary.id = sub.id;

    //Clone dates
    this.model.startDate = sub.startDate ? sub.startDate.clone() : null;
    this.model.endDate = sub.endDate ? sub.endDate.clone() : null;

    //Check if limit warning is needed
    this.checkBundleLimit();
  };

  /**
   * Check past subscription warning
   */
  this.checkPastNonRenewed = function() {

    //Test date
    const testDate = this.model.startDate
      .clone()
      .subtract(1, 'day')
      .endOf('day');

    //Check existing sub of the same membership type
    this.pastSubToRenew = this.member.subscriptions
      .filter(sub => sub.membership.id === this.membership.id)
      .filter(sub => sub.canBeRenewedByAdmin())
      .find(sub => sub.endDate && sub.endDate.isSame(testDate));
  };

  /**
   * Check if end date is in the past
   */
  this.checkEndDateInPast = function() {
    const now = moment();
    this.endDateInPast = (
      this.model.endDate &&
      this.model.endDate.isBefore(now)
    );
  };

  /**
   * Check age
   */
  this.checkAge = function() {
    const age = this.member.age;
    if (age !== null) {
      if (this.membership.minAge && age < this.membership.minAge) {
        this.showMinAgeWarning = true;
      }
      if (this.membership.maxAge && age > this.membership.maxAge) {
        this.showMaxAgeWarning = true;
      }
    }
  };

  /**
   * Check constraint
   */
  this.checkContraint = function() {
    const {member, membership} = this;
    const memberships = member.getCurrentMemberships();
    this.showConstraintWarning = !membership.appliesToMemberships(memberships);
  };

  /**
   * Check membership limit
   */
  this.checkMembershipLimit = async function() {

    //Get data
    const {membership} = this;

    //Get current number of subscriptions
    const num = await membership.getNumCurrentSubs();
    this.showSubLimit = (num >= membership.limit);
    this.numberOfSubs = num;
  };

  /**
   * Check bundle limit incase warning is needed
   */
  this.checkBundleLimit = function() {

    //Clear warning
    this.showLinkedSubLimit = false;
    this.numberOfLinkedSubs = 0;

    //No primary sub or membership
    if (!this.primarySub || !this.membership) {
      return;
    }

    //Find matching linked subs
    const {bundleLimit} = this.membership;
    const matchingLinkedSubs = this.primarySub.linked
      .filter(sub => (
        sub.membership &&
        sub.membership.id === this.membership.id
      ));

    //Check limit
    if (bundleLimit && bundleLimit <= matchingLinkedSubs.length) {
      this.showLinkedSubLimit = true;
      this.numberOfLinkedSubs = matchingLinkedSubs.length + 1;
    }
  };

  /**
   * Load subscription data
   */
  this.loadSubscriptionData = function() {

    //No membership?
    if (!this.membership) {
      return;
    }

    //Get start/end dates
    let startDate, endDate;
    if (this.hasManuallyChangedStartDate) {
      startDate = this.model.startDate;
    }
    if (this.hasManuallyChangedEndDate) {
      endDate = this.model.endDate;
    }

    //Load data
    this.isLoading = true;
    Subscription
      .getCreateData(this.membership.id, this.member.id, startDate, endDate)
      .then(data => this.parseSubscriptionData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Parse subscription data
   */
  this.parseSubscriptionData = function(data) {

    //Get meta data
    const {meta} = data;

    //Set dates
    this.model.startDate = moment(meta.startDate);
    this.model.endDate = moment(meta.endDate);

    //Set fee
    this.model.meta.amount = meta.totalFee;
    this.model.meta.createTransaction = meta.hasFee;

    //Set discounts
    this.discounts = meta.discounts;
  };

  /**
   * Toggle include past
   */
  this.toggleIncludePast = function(includePast) {
    this.includePast = includePast;
  };

  /**
   * Toggle transaction
   */
  this.toggleTransaction = function(createTransaction) {
    this.model.meta.createTransaction = createTransaction;
  };

  /**
   * Toggle notification
   */
  this.toggleNotify = function(notifyByEmail) {
    this.model.meta.notifyByEmail = notifyByEmail;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Save
    this.isSaving = true;
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isSaving = false);
  };
});
