
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.Summary.Card', [])

/**
 * Component
 */
.component('cardSubscriptionSummary', {
  templateUrl: 'subscription/cards/summary.html',
  controller: 'CardSubscriptionSummaryCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    isLoading: '<',
    member: '<',
    members: '<',
    subscriptions: '<',
    onPurchase: '&',
    onSelectMember: '&',
  },
})

/**
 * Controller
 */
.controller('CardSubscriptionSummaryCtrl', function() {

  /**
   * On changes
   */
  this.$onChanges = function() {

    //Filter subs
    this.current = this.subscriptions.filter(sub => sub.isCurrent);
    this.past = this.subscriptions.filter(sub => sub.isPast);
    this.upcoming = this.subscriptions.filter(sub => sub.isUpcoming);
  };

  /**
   * Select a circle member
   */
  this.selectMember = function(member) {
    this.onSelectMember({$event: {member}});
  };

  /**
   * Purchase new subscription
   */
  this.purchase = function() {
    this.isPurchasing = true;
    this
      .onPurchase()
      .finally(() => this.isPurchasing = false);
  };
});
