
/**
 * Module definition and dependencies
 */
angular.module('Shared.ActivityFee.Component', [])

/**
 * Activity fee structure component
 */
.component('activityFee', {
  template: `<span>{{$ctrl.parts | join}}</span>`,
  bindings: {
    type: '<',
    amount: '<',
    min: '<',
    max: '<',
    policy: '<',
    duration: '<',
    area: '<',
    multiplier: '<',
  },
  controller($filter, FeePolicies, FeeTypes) {

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Get data
      const {type, amount, min, max, policy, duration, area, multiplier} = this;

      //Prepare parts
      this.parts = [];

      //No amount?
      if (!amount) {
        this.parts.push('No fee');
        return;
      }

      //Add multiplier
      if (multiplier && multiplier > 1) {
        this.parts.push(`${multiplier}x`);
      }

      //Per person or fixed
      if (type === FeeTypes.PER_PERSON) {
        const amountPerPerson = $filter('currency')(amount);
        if (duration) {
          this.parts.push(`${amountPerPerson} per person, per ${duration} minutes`);
        }
        else {
          this.parts.push(`${amountPerPerson} per person`);
        }
      }
      else if (type === FeeTypes.PER_AREA) {
        const amountPerArea = $filter('currency')(amount);
        if (duration) {
          if (area) {
            this.parts.push(`${amountPerArea} per ${area}, per ${duration} minutes`);
          }
          else {
            this.parts.push(`${amountPerArea} per ${duration} minutes`);
          }
        }
        else if (area) {
          this.parts.push(`${amountPerArea} per ${area}`);
        }
        else {
          this.parts.push(`${amountPerArea}`);
        }
      }
      else {
        const amountGeneric = $filter('currency')(amount);
        this.parts.push(`${amountGeneric}`);
        return;
      }

      //Min max
      if (min) {
        const amount = $filter('currency')(min);
        this.parts.push(`min. ${amount}`);
      }
      if (max) {
        const amount = $filter('currency')(max);
        this.parts.push(`max. ${amount}`);
      }

      //Policy
      if (policy) {
        switch (policy) {
          case FeePolicies.AFTERWARDS:
            this.parts.push('payable after session');
            break;
          case FeePolicies.WITH_CREDIT:
            this.parts.push('payable with account credit at time of session');
            break;
          case FeePolicies.IN_ADVANCE:
            this.parts.push('payable in advance, non-refundable');
            break;
          case FeePolicies.IN_ADVANCE_REFUNDABLE:
            this.parts.push('payable in advance, refundable');
            break;
        }
      }
    };
  },
});
