
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.NoPayment.Card', [])

/**
 * Component
 */
.component('cardCouponNoPayment', {
  templateUrl: 'coupon/cards/no-payment.html',
  controller: 'CardCouponNoPaymentCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    onConfirm: '&',
    onPrev: '&',
    onCancel: '&',
  },
})

/**
 * Controller
 */
.controller('CardCouponNoPaymentCtrl', function() {

  /**
   * Confirm
   */
  this.confirm = function() {

    //Flag as confirming
    this.isConfirming = true;

    //Renew
    return this
      .onConfirm()
      .finally(() => this.isConfirming = false);
  };

  /**
   * Go back
   */
  this.prev = function() {
    this.onPrev();
  };

  /**
   * Cancel flow
   */
  this.cancel = function() {
    this.onCancel();
  };
});
