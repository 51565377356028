
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Welcome.CustomFields', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('welcome.customFields', {
    url: '/additional-details',
    component: 'welcomeCustomFieldsRoute',
  });
})

/**
 * Route component
 */
.component('welcomeCustomFieldsRoute', {
  controller: 'WelcomeCustomFieldsCtrl',
  templateUrl: 'portal/welcome/steps/custom-fields.html',
  bindings: {
    club: '<',
    welcome: '<',
    customFields: '<',
  },
})

/**
 * Controller
 */
.controller('WelcomeCustomFieldsCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('WelcomeBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Update custom field
   */
  this.updateCustomField = function(property, value) {
    this.user.customFields[property] = value;
  };
});
