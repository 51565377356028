
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.EditNotification.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editEventNotification', {
    templateUrl: 'admin/event/modals/edit-notification.html',
    controller: 'ModalEditEventNotificationCtrl',
    closeOnClick: false,
    resolve: {
    },
  });
})

/**
 * Controller
 */
.controller('ModalEditEventNotificationCtrl', function(
  $controller, $modalInstance, moment, EventNotificationTypes, ReplacementTags,
  EventAudiences, EventNotificationDurations, NotificationThresholdTypes,
  Modules
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Constants
  const {PROMOTION, REMINDER, POST_EVENT} = EventNotificationTypes;
  const {MEMBERS, GROUPS, NO_SHOWS, ORGANISERS} = EventAudiences;
  const {AFTER, BEFORE} = NotificationThresholdTypes;

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set initial step
    this.setStep('basics');

    //Set data and flags
    this.isSaving = false;
    this.tags = ReplacementTags.eventAttendee();

    //Set options
    this.EventNotificationTypes = EventNotificationTypes;
    this.EventNotificationDurations = EventNotificationDurations;

    //Create model subset
    this.model = this.notification.extract([
      'isEnabled', 'type', 'audience', 'groups',
      'text', 'subject', 'thresholdType', 'threshold',
    ]);

    //Determine placeholder and audience options
    this.determinePlaceholder();
    this.determineAudienceOptions();
    this.determineNotificationDueDate();
  };

  /**
   * Determine placeholder
   */
  this.determinePlaceholder = function() {
    const {type} = this.model;
    if (type === REMINDER) {
      this.placeholder = `Hi [FIRST_NAME], this is a friendly reminder that there’s an event coming up at [CLUB_NAME].`;
    }
    else if (type === POST_EVENT) {
      this.placeholder = `Hi [FIRST_NAME], thank you for attending the event [EVENT_NAME] at [CLUB_NAME]!`;
    }
    else {
      this.placeholder = `Hi [FIRST_NAME], there’s a new event coming up at [CLUB_NAME] that we thought you might be interested in.`;
    }
  };

  /**
   * Determine audience options
   */
  this.determineAudienceOptions = function() {
    const {type} = this.model;
    this.eventAudiences = EventAudiences
      .filter(option => {
        const {value, label} = option;
        if (type === PROMOTION) {
          if (value !== MEMBERS && value !== GROUPS && value !== ORGANISERS) {
            return false;
          }
        }
        else if (type === REMINDER) {
          if (value === NO_SHOWS) {
            return false;
          }
        }
        return {value, label};
      });

    //Get module names & replace if needed
    const {singular, plural} = Modules.find('members');
    if (singular !== 'member' || plural !== 'members') {
      this.eventAudiences = this.eventAudiences
        .map(option => {
          if (option.label.match('member')) {
            option.label.replace('member', singular);
          }
          if (option.label.match('members')) {
            option.label.replace('members', plural);
          }
          return option;
        });
    }
  };

  /**
   * Update notification type
   */
  this.setNotificationType = function(value) {

    //Set type and threshold type
    this.model.type = value;
    this.model.thresholdType = (value === POST_EVENT ? AFTER : BEFORE);

    //Set placeholder and audience
    this.determinePlaceholder();
    this.determineAudienceOptions();
    this.determineNotificationDueDate();
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {

    //Update target
    target = target || this.model;
    target[property] = value;

    //Determine notification due date
    this.determineNotificationDueDate();
  };

  /**
   * Determine notification due date
   */
  this.determineNotificationDueDate = function() {

    //Get data
    const {startDate, endDate} = this.event;
    const {threshold, thresholdType} = this.model;
    const {amount, unit} = threshold;

    //Determine due date
    if (thresholdType === BEFORE) {
      this.dueDate = startDate.clone().subtract(amount, unit);
    }
    else {
      this.dueDate = endDate.clone().add(amount, unit);
    }

    //Check flags
    this.isInPast = moment().isAfter(this.dueDate);
    this.willBeSent = moment().subtract(3, 'hours').isBefore(this.dueDate);
  };

  /**
   * Next step
   */
  this.next = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Set pristine and go to next step
    this.form.$setPristine();
    this.setStep('text');
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => this.isError = true)
      .finally(() => this.isSaving = false);
  };
});
