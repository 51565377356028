
/**
 * Module definition and dependencies
 */
angular.module('Shared.Country.Model', [
  'BaseModel.Service',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('country', {
    model: 'Country',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'countries',
        isArray: true,
        isModel: true,
      },
      own: {
        url: '/own',
        method: 'GET',
        isModel: true,
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('countries', {
    model: 'Country',
    dataKey: 'countries',
  });
})

/**
 * Model definition
 */
.factory('Country', function($api, $baseModel) {

  /**
   * Constructor
   */
  function Country(data) {

    //Base constructor
    $baseModel.call(this, data);

    //Property to check if we have online payments in this country
    Object.defineProperty(this, 'hasOnlinePayments', {
      enum: false,
      get() {
        return (this.stripe || this.poli);
      },
    });

    //Mock ID property so the store doesn't complain
    Object.defineProperty(this, 'id', {
      enum: false,
      get() {
        return this.code;
      },
    });
  }

  /**
   * Extend base model
   */
  angular.extend(Country.prototype, $baseModel.prototype);

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Country.query = function(filter) {
    return $api.country.query(filter);
  };

  /**
   * Get own country details
   */
  Country.own = function() {
    return $api.country.own();
  };

  //Return
  return Country;
});
