
/**
 * Module definition and dependencies
 */
angular.module('Shared.AccountCreditAlerts.Component', [])

/**
 * Alert component
 */
.component('accountCreditAlerts', {
  template: `
    <alert type="info" ng-if="$ctrl.payment.hasAddedAccountCredit">
      <p>
        This will deduct <strong>{{$ctrl.payment.accountCreditAdjustment | currency}}</strong> from the {{modules.members.singular}}’s account credit.
      </p>
    </alert>
    <alert type="info" ng-if="$ctrl.payment.hasDeductedAccountCredit">
      <p>
        This will add <strong>{{$ctrl.payment.accountCreditAdjustment | abs | currency}}</strong> to the {{modules.members.singular}}’s account credit.
      </p>
    </alert>
  `,
  bindings: {
    payment: '<',
  },
});
