
/**
 * Module definition and dependencies
 */
angular.module('Shared.Image.Card', [])

/**
 * Component
 */
.component('cardImage', {
  templateUrl: 'cards/image.html',
  controller: 'CardImageCtrl',
  transclude: true,
  require: {
    card: '^^',
  },
  bindings: {
    current: '<',
    onUpload: '&',
    onChange: '&',
    onDelete: '&',
    quality: '@',
    accept: '@',
    pattern: '@',
    maxWidth: '@',
    maxHeight: '@',
    fixedRatio: '<',
    centerCrop: '<',
    itemTitle: '@',
  },
})

/**
 * Controller
 */
.controller('CardImageCtrl', function(Upload) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set vars
    this.error = null;
    this.file = null;
    this.progress = 0;

    //Flags
    this.isViewingCurrent = true;
    this.isPreviewing = false;
    this.isSelecting = false;
    this.isUploading = false;
    this.hasErrorImageType = false;
    this.hasErrorUploading = false;

    //Default pattern and accept parameters
    if (!this.accept) {
      this.accept = 'image/*';
    }
    if (!this.pattern) {
      this.pattern = '.jpg,.jpeg,.png,.gif,.svg';
    }

    //Apply dirty check (formless)
    this.card.dirtyCheck();

    //If no image, go straight to selecting
    if (!this.current) {
      this.isSelecting = true;
      this.isViewingCurrent = false;
    }
  };

  /**
   * Change image
   */
  this.changeImage = function() {
    this.card.setDirty(false);
    this.isViewingCurrent = false;
    this.isPreviewing = false;
    this.isSelecting = true;
    this.file = null;
  };

  /**
   * Preview image
   */
  this.previewImage = function() {
    this.card.setDirty(true);
    this.isSelecting = false;
    this.isPreviewing = true;
  };

  /**
   * View current image
   */
  this.viewCurrent = function() {
    this.card.setDirty(false);
    this.isSelecting = false;
    this.isPreviewing = false;
    this.isViewingCurrent = true;
  };

  /**
   * Delete image
   */
  this.deleteImage = function() {
    this
      .onDelete({$event: {}})
      .then(() => this.changeImage());
  };

  /**
   * Upload
   */
  this.uploadImage = function() {

    //Mark as uploading in progress
    this.isPreviewing = false;
    this.isUploading = true;
    this.error = null;

    //Upload
    this
      .onUpload({$event: {file: this.file}})
      .then(
        response => {
          this.file = null;
          this.onChange({$event: {data: response.data}});
          this.viewCurrent();
        },
        error => {
          this.error = error;
          this.previewImage();
        },
        event => {
          this.progress = parseInt(100.0 * event.loaded / event.total);
        }
      )
      .catch(() => {})
      .finally(() => this.isUploading = false);
  };

  /**
   * Resize image
   */
  // this.resizeImage = function(file) {
  //   return Upload.resize(file, {
  //     height: this.maxHeight,
  //     width: this.maxWidth,
  //     quality: this.quality,
  //     type: 'image/jpeg',
  //     centerCrop: this.centerCrop,
  //     restoreExif: true,
  //   });
  // };

  /**
   * Fix image ratio
   */
  // this.fixRatio = function(file) {
  //   return Upload.resize(file, {
  //     ratio: '1:1',
  //     quality: this.quality,
  //     type: 'image/jpeg',
  //     centerCrop: this.centerCrop,
  //     restoreExif: true,
  //   });
  // };

  // /**
  //  * Fix orientation
  //  */
  // this.fixOrientation = function(file) {
  //   return Upload.applyExifRotation(file);
  // };

  /**
   * Selected image
   */
  this.selectedImage = function(file) {

    //Reset error flags
    this.hasErrorImageType = false;
    this.hasErrorUploading = false;

    //No file?
    if (!file || !Upload.isFile(file)) {
      this.hasErrorImageType = true;
      return;
    }

    //Set file and preview image
    this.file = file;
    this.previewImage();

    //Determine image dimensions
    // Upload
    //   .imageDimensions(file)
    //   .then(size => {

    //     //Only resize JPG images, as PNG and GIF images will lose transparency
    //     if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
    //       if (size.width > this.maxWidth || size.height > this.maxHeight) {
    //         return this.resizeImage(file);
    //       }
    //       if (this.fixedRatio && size.width !== size.height) {
    //         return this.fixRatio(file);
    //       }
    //     }

    //     //Return as is
    //     return file;
    //   })
    //   .then(file => this.fixOrientation(file))
    //   .then(file => {
    //     this.file = file;
    //     this.previewImage();
    //   });
  };

  /**
   * Cancel button
   */
  this.cancel = function() {

    //Go back to selecting
    if (this.isPreviewing) {
      return this.changeImage();
    }

    //Go back to view
    this.viewCurrent();
  };
});
