
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.EditActivity.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editMemberActivity', {
    templateUrl: 'admin/people/members/modals/edit-activity.html',
    controller: 'ModalEditMemberActivityCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalEditMemberActivityCtrl', function(
  $controller, $modalInstance, $notice, AllowedModes
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Options
    this.AllowedModes = AllowedModes;

    //Init
    this.isSaving = false;
    this.model = this.activity.extract([
      'hasAllowedModes', 'allowedModes',
    ]);
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;
    this.isError = false;

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(() => $notice.showError(`Failed to update activity`))
      .finally(() => this.isSaving = false);
  };
});
