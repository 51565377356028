
/**
 * Module definition and dependencies
 */
angular.module('App.Account.PaymentsDue.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('paymentsDue', {
    templateUrl: 'account/modals/payments-due.html',
    controller: 'ModalPaymentsDueCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalPaymentsDueCtrl', function(
  $controller, $modalInstance, $state, $modal, Transaction
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.isMuting = false;

    //Determine warning level
    this.level = 'warning';
    if (this.transactions.some(trx => trx.isOverdue)) {
      this.level = 'danger';
    }
  };

  /**
   * Go to account
   */
  this.goToAccount = function() {
    $modalInstance.close('cancel');
    $state.go('account.overview');
  };

  /**
   * Snooze
   */
  this.snooze = function() {
    $modalInstance.resolve([3, 'days']);
  };

  /**
   * Mute
   */
  this.mute = function() {

    //Mark as muting
    this.isMuting = true;
    const transactions = this.transactions;

    //Mute
    Transaction
      .mute(transactions)
      .then(() => {
        $modalInstance.close('muting');
        $modal.open('basic', {
          templateUrl: 'account/modals/payments-due-muted.html',
          locals: {transactions},
        });
      })
      .finally(() => this.isMuting = false);
  };
});
