
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.CouponType.Edit.Restrictions.Card', [])

/**
 * Component
 */
.component('cardCouponTypeEditRestrictions', {
  templateUrl: 'admin/coupon-type/cards/restrictions.html',
  controller: 'CardCouponTypeEditRestrictionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    couponType: '<',
    activities: '<',
    memberships: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardCouponTypeEditRestrictionsCtrl', function(
  MembershipConstraintLabels, CardCloseReasons, CouponTypeTypes
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isEdit = true;
    this.isSaving = false;
    this.MembershipConstraintLabels = MembershipConstraintLabels;
    this.isSessionBased = (
      this.couponType.type === CouponTypeTypes.SESSION_BASED
    );

    //Create model subset
    this.model = this.couponType.extract([
      'canPurchase', 'constraint', 'memberships',
    ]);
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get data
    const {couponType, model, isEdit} = this;

    //Save
    this
      .onSave({$event: {couponType, model, isEdit}})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .finally(() => this.isSaving = false);
  };
});
