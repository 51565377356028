
/**
 * Module definition and dependencies
 */
angular.module('Shared.Discounts.Card', [
  'Shared.DiscountOptions.Component',
])

/**
 * Component
 */
.component('cardDiscounts', {
  templateUrl: 'cards/discounts.html',
  controller: 'CardDiscountsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    model: '@',
    discounts: '<',
    memberships: '<',
  },
})

/**
 * Controller
 */
.controller('CardDiscountsCtrl', function($notice, $modal, Discount) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize flags
    this.hasDiscounts = this.discounts.length > 0;
  };

  /**
   * On added handler
   */
  this.onAdded = function($event) {

    //Get new discount
    const {discount} = $event;

    //Add to array
    this.discounts.push(discount);
    this.hasDiscounts = true;
  };

  /**
   * On deleted handler
   */
  this.onDeleted = function($event) {

    //Get discount and find the index
    const {discount} = $event;
    const index = this.discounts.findIndex(f => f.id === discount.id);

    //Splice
    if (index > -1) {
      this.discounts.splice(index, 1);
      this.hasDiscounts = this.discounts.length > 0;
    }
  };

  /**
   * Add discount
   */
  this.add = function() {

    //Prepare data
    const {model} = this;
    const data = {model};
    const isEdit = false;

    //Get new discount and define handler
    const discount = new Discount(data);
    const handler = data => discount.save(data);

    //Open modal
    $modal
      .open('editDiscount', {
        locals: {discount, isEdit, handler},
      })
      .result
      .then(() => {
        $notice.show('Discount created');
        this.onAdded({discount});
      });
  };

  /**
   * Edit discount
   */
  this.edit = function($event) {

    //Get discount
    const {discount} = $event;
    const isEdit = true;

    //Define handler
    const handler = data => discount.save(data);

    //Open modal
    $modal
      .open('editDiscount', {
        locals: {discount, isEdit, handler},
      })
      .result
      .then(() => $notice.show('Discount updated'));
  };

  /**
   * Delete discount
   */
  this.delete = function($event) {

    //Get discount
    const {discount} = $event;

    //Define handler
    const handler = function() {
      return discount.delete();
    };

    //Show confirmation
    return $modal
      .open('basic', {
        templateUrl: 'modals/confirm/confirm-delete-discount.html',
        locals: {discount, handler},
      })
      .result
      .then(() => {
        $notice.show('Discount removed');
        this.onDeleted({discount});
      });
  };
});
