
/**
 * The card contents component is used to wrap contents in an openable card
 */

/**
 * Module definition and dependencies
 */
angular.module('Shared.Cards.CardContents.Component', [])

/**
 * Component
 */
.component('cardContents', {
  template: `
    <div class="CardContents" ng-transclude ng-if="$ctrl.shouldShow()"></div>
  `,
  transclude: true,
  require: {
    card: '^^',
  },
  bindings: {
    tab: '@',
  },
  controller() {

    /**
     * Check if we should show this contents
     */
    this.shouldShow = function() {

      //Card not open and not static?
      if (!this.card.isOpen && !this.card.isStatic) {
        return false;
      }

      //Tab not showing
      if (this.tab && !this.card.isTabActive(this.tab)) {
        return false;
      }

      //Ok to show
      return true;
    };
  },
});
