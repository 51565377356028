
/**
 * Module definition and dependencies
 */
angular.module('Shared.Month.Filter', [])

/**
 * Month
 */
.filter('month', function(moment) {
  return function(num) {
    if (!num) {
      return '';
    }
    return moment.months()[num - 1];
  };
});
