
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.InterestedOptions.Component', [])

/**
 * Interested options component
 */
.component('eventInterestedOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li>
        <a ng-click="$ctrl.email()">Email</a>
      </li>
      <li>
        <a ng-click="$ctrl.addToGroups()">Add {{modules.members.plural}} to group</a>
      </li>
      <li>
        <a ng-click="$ctrl.export()">Export CSV</a>
      </li>
    </more-options>
  `,
  bindings: {
    onEmail: '&',
    onAddToGroups: '&',
    onExport: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Send email
     */
    this.email = function() {
      this.onEmail();
    };

    /**
     * Add attendees to group
     */
    this.addToGroups = function() {
      this.onAddToGroups();
    };

    /**
     * Export CSV
     */
    this.export = function() {
      this.onExport();
    };
  },
});
