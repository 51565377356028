
/**
 * Module definition and dependencies
 */
angular.module('Shared.ClubTypes.Options', [])

/**
 * Constant definition
 */
.constant('ClubTypes', [
  {
    value: 'club',
    label: 'Club',
  },
  {
    value: 'academy',
    label: 'Academy',
  },
  {
    value: 'association',
    label: 'Association',
  },
  {
    value: 'centre',
    label: 'Centre',
  },
  {
    value: 'charity',
    label: 'Charity',
    background: 'backgrounds/default/community.jpg',
  },
  {
    value: 'church',
    label: 'Church',
    background: 'backgrounds/default/church.jpg',
  },
  {
    value: 'council',
    label: 'Council',
    background: 'backgrounds/default/community.jpg',
  },
  {
    value: 'class',
    label: 'Class',
  },
  {
    value: 'community',
    label: 'Community',
    background: 'backgrounds/default/community.jpg',
  },
  {
    value: 'company',
    label: 'Company',
  },
  {
    value: 'cooperative',
    label: 'Cooperative',
    background: 'backgrounds/default/community.jpg',
  },
  {
    value: 'event centre',
    label: 'Event centre',
  },
  {
    value: 'federation',
    label: 'Federation',
  },
  {
    value: 'fund',
    label: 'Fund',
    background: 'backgrounds/default/community.jpg',
  },
  {
    value: 'group',
    label: 'Group',
    background: 'backgrounds/default/community.jpg',
  },
  {
    value: 'gym',
    label: 'Gym',
    background: 'backgrounds/default/gym.jpg',
  },
  {
    value: 'library',
    label: 'Library',
    background: 'backgrounds/default/books.jpg',
  },
  {
    value: 'organisation',
    label: 'Organisation',
  },
  {
    value: 'school',
    label: 'School',
    background: 'backgrounds/default/school.jpg',
  },
  {
    value: 'society',
    label: 'Society',
    background: 'backgrounds/default/community.jpg',
  },
  {
    value: 'studio',
    label: 'Studio',
  },
  {
    value: 'team',
    label: 'Team',
  },
  {
    value: 'trust',
    label: 'Trust',
  },
  {
    value: 'venue',
    label: 'Venue',
    background: 'backgrounds/default/venue.jpg',
  },
]);
