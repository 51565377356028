
/**
 * Define values
 */
const FREE = 'free';
const FEE = 'fee';
const COUPON = 'coupon';
const MEMBERSHIP = 'membership';

/**
 * Define array
 */
const EventRuleTypes = [
  {
    label: 'Free',
    value: FREE,
  },
  {
    label: 'Fee',
    value: FEE,
  },
  {
    label: 'Coupons',
    value: COUPON,
  },
  {
    label: 'Membership benefit',
    value: MEMBERSHIP,
  },
];

/**
 * Set properties
 */
EventRuleTypes.FREE = FREE;
EventRuleTypes.FEE = FEE;
EventRuleTypes.COUPON = COUPON;
EventRuleTypes.MEMBERSHIP = MEMBERSHIP;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.EventRuleTypes.Options', [])
  .constant('EventRuleTypes', EventRuleTypes);
