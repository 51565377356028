
/**
 * Module definition and dependencies
 */
angular.module('Shared.Activity.Model', [
  'BaseModel.Service',
  'Shared.Activity.Fee.Model',
  'Shared.Activity.Restriction.Model',
  'Shared.Activity.Grade.Model',
])

/**
 * Config
 */
.config(($apiProvider, $storeProvider) => {

  //Register API endpoint
  $apiProvider.registerEndpoint('activity', {
    model: 'Activity',
    actions: {
      query: {
        method: 'GET',
        dataKey: 'activities',
        isArray: true,
        isModel: true,
      },
      get: {
        method: 'GET',
        isModel: true,
      },
      create: {
        method: 'POST',
      },
      update: {
        method: 'PUT',
      },
      delete: {
        method: 'DELETE',
      },
    },
  });

  //Register data store
  $storeProvider.registerStore('activities', {
    model: 'Activity',
    dataKey: 'activities',
    cacheEmpty: true,
  });
})

/**
 * Model definition
 */
.factory('Activity', function(
  $baseModel, $api, MembershipConstraints
) {

  /**
   * Constructor
   */
  function Activity(data) {
    $baseModel.call(this, data);
  }

  /**
   * Extend base model
   */
  angular.extend(Activity.prototype, $baseModel.prototype);

  /**
   * From JSON converter
   */
  Activity.prototype.fromJSON = function(json) {

    //Call parent method
    $baseModel.prototype.fromJSON.call(this, json);

    //Parse properties
    this.convertToModel('modes', 'Mode', true);
    this.convertToModel('fees', 'ActivityFee', true);
    this.convertToModel('playingTimes', 'Restriction', true);
    this.convertToModel('bookingTimes', 'Restriction', true);
    this.convertToModel('peakTimes', 'Restriction', true);
    this.convertToModel('grades', 'ActivityGrade', true);

    //Return self
    return this;
  };

  /**
   * To JSON converter
   */
  Activity.prototype.toJSON = function(data) {

    //Call parent method
    const json = $baseModel.prototype.toJSON.call(this, data);

    //Remove unneeded data
    delete json.modes;

    //Strip data
    json.club = $baseModel.onlyId(json.club);

    //Return JSON
    return json;
  };

  /**************************************************************************
   * Instance methods
   ***/

  /**
   * Check if we contain a certain mode
   */
  Activity.prototype.hasMode = function(modeId) {
    return !!this.modes.find(mode => mode.isSame(modeId));
  };

  /**
   * Get mode by ID
   */
  Activity.prototype.getMode = function(modeId) {
    return this.modes.find(mode => mode.isSame(modeId));
  };

  /**
   * Find restrictions of a certain type for a certain member
   */
  Activity.prototype.findRestrictions = function(type, memberships) {

    //No array given
    if (!Array.isArray(memberships)) {
      memberships = [];
    }

    //Get restrictions
    const restrictions = this[type];
    const {
      ALL, WITH, WITHOUT, SPECIFIC, SPECIFIC_WITHOUT,
    } = MembershipConstraints;

    //Without membership
    if (memberships.length === 0) {
      const withoutMembership = restrictions
        .filter(r => (
          r.constraint === WITHOUT || r.constraint === SPECIFIC_WITHOUT
        ));
      if (withoutMembership.length > 0) {
        return withoutMembership;
      }
    }

    //Member with membership
    else {

      //Get membership ID's
      const ids = memberships.map(membership => membership.id);

      //Check specific memberships
      const specific = restrictions
        .filter(r => r.constraint === SPECIFIC || r.constraint === SPECIFIC_WITHOUT)
        .filter(r => r.memberships.some(id => ids.includes(id)));

      //Check general with membership
      const withMembership = restrictions
        .filter(r => r.constraint === WITH);

      //Combine
      const combined = specific.concat(withMembership);

      //Anything?
      if (combined.length > 0) {
        return combined;
      }
    }

    //Nothing found so far, check for all
    return restrictions
      .filter(r => r.constraint === ALL);
  };

  /**
   * To bare activity
   */
  Activity.prototype.toBare = function() {
    const {id, name} = this;
    return {id, name};
  };

  /**
   * Save
   */
  Activity.prototype.save = function(data) {

    //Extend instance data with optionally given data
    data = this.toJSON(data);

    //Determine method and call API
    const method = this.id ? 'update' : 'create';
    return $api.activity[method](data)
      .then(data => this.fromJSON(data));
  };

  /**
   * Delete
   */
  Activity.prototype.delete = function() {
    return $api.activity
      .delete(null, this)
      .then(() => this);
  };

  /**************************************************************************
   * Static methods
   ***/

  /**
   * Query
   */
  Activity.query = function() {
    return $api.activity.query();
  };

  /**
   * Find by ID
   */
  Activity.findById = function(id) {
    return $api.activity.get({id});
  };

  //Return
  return Activity;
});
