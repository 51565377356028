
/**
 * Define values
 */
const FEE_METHOD_RULES = 'rules';
const FEE_METHOD_FIXED = 'fixed';
const FEE_METHOD_NONE = 'none';

/**
 * Define array
 */
const FeeMethods = [
  {
    value: FEE_METHOD_RULES,
    label: 'As defined by attendance rules',
  },
  {
    value: FEE_METHOD_FIXED,
    label: 'Fixed fee',
  },
  {
    value: FEE_METHOD_NONE,
    label: 'None',
  },
];

/**
 * Set properties
 */
FeeMethods.RULES = FEE_METHOD_RULES;
FeeMethods.FIXED = FEE_METHOD_FIXED;
FeeMethods.NONE = FEE_METHOD_NONE;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.FeeMethods.Options', [])
  .constant('FeeMethods', FeeMethods);
