
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club.Financial.Card', [])

/**
 * Component
 */
.component('cardClubFinancial', {
  templateUrl: 'admin/club/cards/financial.html',
  controller: 'CardClubFinancialCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    texts: '<',
    country: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardClubFinancialCtrl', function(
  TextTypes, TaxTypes, TaxNumberTypes
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;
    this.TaxTypes = TaxTypes;
    this.TaxNumberTypes = TaxNumberTypes;

    //Create model subset
    this.model = this.club.extract([
      'tax',
    ]);

    //Find relevant text
    this.paymentInstructions = this.texts
      .find(text => text.type === TextTypes.PAYMENT_INSTRUCTIONS);

    //Reset its model
    if (this.paymentInstructions) {
      this.paymentInstructions.resetModel();
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {

    //Set new value
    target = target || this.model;
    target[property] = value;

    //If none given for tax type or tax number type, clear the values
    if (property === 'type' && value === TaxTypes.NONE) {
      this.model.tax.percentage = '';
    }
    if (property === 'numberType' && value === TaxNumberTypes.NONE) {
      this.model.tax.number = '';
    }
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Mark as saving
    this.isSaving = true;

    //Get models
    const {model, texts} = this;

    //Save
    this
      .onSave({$event: {model, texts}})
      .then(() => {
        this.form.$setPristine();
        this.club.markStepComplete('checkFinancialDetails', true);
      })
      .finally(() => this.isSaving = false);
  };
});
