
/**
 * Define values
 */
const CUSTOM_FIELD_TYPE_TEXT = 'text';
const CUSTOM_FIELD_TYPE_PARAGRAPH = 'paragraph';
const CUSTOM_FIELD_TYPE_DROPDOWN = 'dropdown';
const CUSTOM_FIELD_TYPE_CHECKBOXES = 'checkboxes';
const CUSTOM_FIELD_TYPE_BOOLEAN = 'boolean';
const CUSTOM_FIELD_TYPE_URL = 'url';
const CUSTOM_FIELD_TYPE_DATE = 'date';
const CUSTOM_FIELD_TYPE_FILE = 'file';

/**
 * Define array
 */
const CustomFieldTypes = [
  {
    value: CUSTOM_FIELD_TYPE_TEXT,
    label: 'Text',
  },
  {
    value: CUSTOM_FIELD_TYPE_PARAGRAPH,
    label: 'Paragraph',
  },
  {
    value: CUSTOM_FIELD_TYPE_DROPDOWN,
    label: 'Dropdown',
  },
  {
    value: CUSTOM_FIELD_TYPE_CHECKBOXES,
    label: 'Multiple choice',
  },
  {
    value: CUSTOM_FIELD_TYPE_BOOLEAN,
    label: 'Yes / No',
  },
  {
    value: CUSTOM_FIELD_TYPE_URL,
    label: 'Link',
  },
  {
    value: CUSTOM_FIELD_TYPE_DATE,
    label: 'Date',
  },
  {
    value: CUSTOM_FIELD_TYPE_FILE,
    label: 'File',
  },
];

/**
 * Set properties
 */
CustomFieldTypes.TEXT = CUSTOM_FIELD_TYPE_TEXT;
CustomFieldTypes.PARAGRAPH = CUSTOM_FIELD_TYPE_PARAGRAPH;
CustomFieldTypes.DROPDOWN = CUSTOM_FIELD_TYPE_DROPDOWN;
CustomFieldTypes.CHECKBOXES = CUSTOM_FIELD_TYPE_CHECKBOXES;
CustomFieldTypes.BOOLEAN = CUSTOM_FIELD_TYPE_BOOLEAN;
CustomFieldTypes.URL = CUSTOM_FIELD_TYPE_URL;
CustomFieldTypes.DATE = CUSTOM_FIELD_TYPE_DATE;
CustomFieldTypes.FILE = CUSTOM_FIELD_TYPE_FILE;

/**
 * Module definition and dependencies
 */
angular
  .module('Shared.CustomFieldTypes.Options', [])
  .constant('CustomFieldTypes', CustomFieldTypes);
