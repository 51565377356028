
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.EditCircle.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editCircle', {
    templateUrl: 'admin/people/members/modals/edit-circle.html',
    controller: 'ModalEditCircleCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalEditCircleCtrl', function(
  $controller, $modalInstance, $focus, ExistsError
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Create model subset
    this.model = this.circle.extract([
      'name', 'permissions',
    ]);

    //Add member if creating as admin
    if (this.asAdmin) {
      if (!this.isEdit) {
        this.model.member = this.member.id;
        this.model.addDirectly = true;
      }
    }

    //Set data and flags
    if (!this.model.members) {
      this.model.members = [];
    }
    if (!this.model.permissions) {
      this.model.permissions = {
        details: true,
        finances: true,
        memberships: true,
        bookings: true,
        events: true,
      };
    }
  };

  /**
   * Focus
   */
  this.$postLink = function() {
    if (!this.isEdit) {
      $focus('name');
    }
  };

  /**
   * Update permissions
   */
  this.updatePermissions = function(property, value) {
    this.model.permissions[property] = value;
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Reset flags
    this.isErrorExists = false;
    this.isErrorOther = false;
    this.isSaving = true;

    //Save
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .catch(error => {
        if (error instanceof ExistsError) {
          this.isErrorExists = true;
        }
        else {
          this.isErrorOther = true;
        }
      })
      .finally(() => this.isSaving = false);
  };
});
