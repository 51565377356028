
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.RenewalDetails.Card', [])

/**
 * Component
 */
.component('cardSubscriptionRenewalDetails', {
  templateUrl: 'subscription/cards/renewal-details.html',
  require: {
    card: '^^',
  },
  bindings: {
    isChecking: '<',
    meta: '<',
    membership: '<',
    subscription: '<',
    old: '<',
    onNext: '&',
    onCancel: '&',
    onChange: '&',
    onStop: '&',
  },
  controller() {

    /**
     * Next step
     */
    this.next = function() {
      this.onNext();
    };

    /**
     * Cancel flow
     */
    this.cancel = function() {
      this.onCancel();
    };

    /**
     * Change membership
     */
    this.change = function() {
      this.onChange();
    };

    /**
     * Stop subscription
     */
    this.stop = function() {
      this.onStop();
    };
  },
});
