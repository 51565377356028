
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Integrations.POLi.Account.Card', [])

/**
 * Component
 */
.component('cardIntegrationsPoliAccount', {
  templateUrl: 'admin/integrations/poli/account.html',
  controller: 'CardIntegrationsPoliAccountCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    type: '@',
    isAvailable: '<',
    integration: '<',
  },
})

/**
 * Controller
 */
.controller('CardIntegrationsPoliAccountCtrl', function(
  $controller, $notice
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('CardIntegrationsBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Check if we have a strategy
    this.hasStrategy = !!this.data.strategy;

    //Clear fields if not connected
    if (!this.isConnected) {
      this.data.merchantCode = '';
      this.data.authCode = '';
    }
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Set flag
    this.isSaving = true;

    //Get name and data
    const {name} = this.integration;
    const {merchantCode, authCode} = this.data;

    //Update
    return this.integration
      .connect({merchantCode, authCode})
      .then(() => {
        $notice.show(`${name} integration saved`);
        this.onSaved();
      })
      .finally(() => this.isSaving = false);
  };

  /**
   * On saved handler
   */
  this.onSaved = function() {
    if (this.hasStrategy) {
      this.card.showTab('status');
    }
    else {
      this.card.showTab('strategy');
    }
  };
});
