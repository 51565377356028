
/**
 * Module definition and dependencies
 */
angular.module('Shared.Sync.Service', [])

/**
 * Service definition
 */
.factory('$sync', function(
  $httpParamSerializer, $window, $notice, /*$q, $interval, $timeout,*/
  Config, Auth
) {

  //Get base url
  const {baseUrl} = Config.api;

  //Helper to create a hidden input
  function createInput(name, value) {
    return angular
      .element('<input/>')
      .attr('type', 'hidden')
      .attr('name', name)
      .val(value);
  }

  //Helper to get cookie value
  // function getCookie() {
  //   const parts = document.cookie.split('downloadToken=');
  //   if (parts.length === 2) {
  //     return parts.pop().split(';').shift();
  //   }
  // }

  //Expire cookie
  // function expireCookie() {
  //   const expires = new Date(0).toUTCString();
  //   document.cookie = `downloadToken=; expires=${expires}`;
  // }

  //Return service
  return {

    /**
     * Download request
     */
    download(path, params/*, token*/) {

      //Create new promise
      // const deferred = $q.defer();

      //Use GET handler, but check for download completion
      return this
        .get(path, params, 'Downloading...');

      //NOTE: Only works on same domain
      // .then(() => {
      //
      //   //Create timeout
      //   const timeout = $timeout(() => {
      //     deferred.reject();
      //   }, 30000);
      //
      //   //Check for download completion
      //   const interval = $interval(() => {
      //     const cookie = getCookie();
      //     if (token === cookie) {
      //       expireCookie();
      //       $interval.cancel(interval);
      //       $timeout.cancel(timeout);
      //       deferred.resolve(token);
      //     }
      //   }, 250);
      //
      //   //Return deferred promise
      //   return deferred.promise;
      // });
    },

    /**
     * Get request to our own server
     */
    get(path, params, notice, tokenLife = 45, appendParams = true) {

      //Ensure params are an object
      params = params || {};

      //Ensure we're not expiring
      return Auth
        .ensureNotExpiring(tokenLife)
        .then(() => {

          //Append params
          if (appendParams) {
            Object.assign(params || {}, {
              access_token: Auth.getAccessToken(),
            });
          }

          //Serialize and create url
          const serialized = $httpParamSerializer(params);
          const query = serialized ? `?${serialized}` : '';
          const url = `${baseUrl}/${path}${query}`;

          //Show notice
          if (notice) {
            $notice.show(notice);
          }

          //Go to URL
          $window.location.href = url;
          // window.open(url, '_blank'); NOTE: this shows popup blocked -.-
        })
        .catch(() => {});
    },

    /**
     * Post data
     */
    post(path, data, params, notice, tokenLife = 45, appendParams = true) {

      //Ensure data and params are an object
      data = data || {};
      params = params || {};

      //Ensure we're not expiring
      return Auth
        .ensureNotExpiring(tokenLife)
        .then(() => {

          //Append params
          if (appendParams) {
            Object.assign(params || {}, {
              access_token: Auth.getAccessToken(),
            });
          }

          //Serialize and create url
          const serialized = $httpParamSerializer(params);
          const query = serialized ? `?${serialized}` : '';
          const url = `${baseUrl}/${path}${query}`;

          //Create form
          const $form = angular
            .element('<form/>')
            .attr('action', url)
            .attr('enctype', 'application/x-www-form-urlencoded')
            .attr('method', 'post');

          //Create hidden input data
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const value = data[key];
              if (Array.isArray(value)) {
                for (const val of value) {
                  const $input = createInput(`${key}[]`, val);
                  $form.append($input);
                }
              }
              else {
                const $input = createInput(key, value);
                $form.append($input);
              }
            }
          }

          //Show notice
          if (notice) {
            $notice.show(notice);
          }

          //Append form to body and submit
          angular.element(document).find('body').append($form);
          $form[0].submit();
          $form.remove();
        })
        .catch(() => {});
    },

    /**
     * Redirect to external URL
     */
    redirect(url, notice = 'Redirecting...', tokenLife = 0) {
      if (!url) {
        throw new Error(`No URL specified`);
      }
      return Auth
        .ensureNotExpiring(tokenLife)
        .then(() => {
          $notice.show(notice);
          $window.location.href = url;
        })
        .catch(() => {});
    },
  };
});
