
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.ConfirmUpdate.Modal', [
  'App.Admin.Event.RecurrenceOptions.Constant',
  'App.Admin.Event.MultiDayOptions.Constant',
])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('confirmUpdateEvent', {
    templateUrl: 'admin/event/modals/confirm-update-event.html',
    controller: 'ModalConfirmUpdateEventCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalConfirmUpdateEventCtrl', function(
  $controller, $modalInstance, EventRecurrenceOptions, EventMultiDayOptions
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Init model
    this.model = {
      method: 'instance',
    };

    //Set options
    this.recurrenceOptions = EventRecurrenceOptions;
    this.multiDayOptions = EventMultiDayOptions;

    //Cannot update event only if we're changing recurrence
    if (this.isChangingRecurrence) {
      this.recurrenceOptions = EventRecurrenceOptions.slice(1);
      this.model.method = 'tail';
    }

    //Check if date is being changed
    this.isDateModified = (
      this.startDate && !this.startDate.isSame(this.event.startDate, 'day')
    );
  };

  /**
   * Update method
   */
  this.updateMethod = function(method) {
    this.model.method = method;
  };

  /**
   * Confirm
   */
  this.confirm = function() {
    if (this.model.method) {
      $modalInstance.resolve(this.model.method);
    }
  };
});
