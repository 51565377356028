
/**
 * Module definition and dependencies
 */
angular.module('App.Auth.AuthInterceptor.Service', [])

/**
 * Interceptor service
 */
.factory('AuthInterceptor', function($injector, Request) {

  //Service placeholders
  let Auth;

  /**
   * Interceptor object
   */
  return {

    /**
     * Append access token header to request
     */
    request(request) {

      //Don't append to template requests
      if (Request.isTemplate(request)) {
        return request;
      }

      //Don't append if configured explicitly
      if (request.skipAccessToken) {
        return request;
      }

      //Append access token to headers
      return Request.appendAccessToken(request);
    },

    /**
     * Intercept 401 responses
     */
    responseError(response) {

      //Not a 401 or ignoring interception?
      if (response.status !== 401 || response.config.ignore401Intercept) {
        throw response;
      }

      //Get auth service
      Auth = Auth || $injector.get('Auth');

      //Don't refresh if access token was still valid
      //This is to prevent a login from another tab into another club from
      //triggering a refresh call in this tab, which would subsequently needlessly
      //refresh the access token in the other tab
      if (!Auth.isAuthenticated() || !Auth.isExpired()) {
        if (Auth.isAuthenticated()) {
          Auth.logoutAutomatically(true);
        }
        else {
          Auth.goToLoginState();
        }
        throw response;
      }

      //Try to obtain a new authentication token
      return Auth
        .refresh()
        .then(() => {

          //Ensure this only happens once, to avoid infinite loops if the user
          //is logged in to one club, then tries to access another.
          response.config.ignore401Intercept = true;
          return Request.retry(response.config);
        })
        .catch(error => {
          if (error.status === 401) {
            if (Auth.isAuthenticated()) {
              Auth.logoutAutomatically();
            }
            else {
              Auth.goToLoginState();
            }
          }
          throw error;
        });
    },
  };
});
