
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.EditAttendee.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('editAttendee', {
    templateUrl: 'admin/event/modals/edit-attendee.html',
    controller: 'ModalEditAttendeeCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalEditAttendeeCtrl', function(
  $controller, $modalInstance, $store, $q, EventAttendee,
  MembershipConstraints, Member, matchesMemberConstraint
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Extract model and get answers map copy
    this.model = this.attendee.extract(['guest']);
    this.answers = this.attendee.getAnswersMap();

    //Load data
    $q
      .all([
        this.loadEvent(),
        this.loadMember(),
      ])
      .then(() => {
        this.questions = this.event.questions
          .filter(question => this.isEligibleQuestion(question));
      });
  };

  /**
   * Load member
   */
  this.loadMember = function() {

    //No member to load or already loaded
    if (!this.attendee.member || this.member) {
      return;
    }

    //Get ID
    const {id} = this.attendee.member;
    this.isLoadingMember = true;

    //Load
    return Member
      .findById(id)
      .then(member => this.member = member)
      .finally(() => this.isLoadingMember = false);
  };

  /**
   * Load event
   */
  this.loadEvent = function() {

    //Already loaded/passed to modal
    if (this.event) {
      return $q.resolve();
    }

    //Get ID
    const {id} = this.attendee.event;
    this.isLoadingEvent = true;

    //Load
    return $store.events
      .findById(id)
      .then(event => this.event = event)
      .finally(() => this.isLoadingEvent = false);
  };

  /**
   * Filter questions
   */
  this.isEligibleQuestion = function(question) {

    //Is a member
    if (this.member) {
      return matchesMemberConstraint(this.member, question);
    }

    //Is a guest
    return (
      question.constraint === MembershipConstraints.ALL ||
      question.constraint === MembershipConstraints.GUEST
    );
  };

  /**
   * Update answer
   */
  this.updateAnswer = function(questionId, answer) {
    this.answers[questionId] = answer;
  };

  /**
   * Save
   */
  this.save = function() {

    //Flag form as submitted and validate
    this.form.$setSubmitted();
    if (this.form.$invalid) {
      return;
    }

    //Reset flags
    this.isSaving = true;

    //Append answers to model
    this.model.answers = EventAttendee.convertAnswersMap(this.answers);

    //Use save handler
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isSaving = false);
  };
});
