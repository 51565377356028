
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.StopSubscription.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('stopSubscription', {
    templateUrl: 'admin/people/members/modals/stop-subscription.html',
    controller: 'ModalStopSubscriptionCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalStopSubscriptionCtrl', function(
  $controller, $modalInstance, moment
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.isConfirming = false;
    this.isConfirmed = false;
    this.endDate = this.subscription.endDate || moment().endOf('day');

    //Min and max dates
    this.minDate = this.subscription.startDate;
    this.maxDate = this.subscription.endDate || null;
  };

  /**
   * Confirm
   */
  this.confirm = function() {
    this.isConfirming = true;
    this
      .handler(this.endDate)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isConfirming = false);
  };

  /**
   * Set confirmed
   */
  this.setConfirmed = function(isConfirmed) {
    this.isConfirmed = isConfirmed;
  };

  /**
   * Set end date
   */
  this.setEndDate = function(endDate) {
    this.endDate = endDate;
  };
});
