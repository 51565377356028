
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Resource.Settings.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('resourceSettings', {
    templateUrl: 'admin/resource/modals/resource-settings.html',
    controller: 'ModalResourceSettingsCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalResourceSettingsCtrl', function(
  $controller, $modalInstance, Settings
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Extract model
    this.model = Settings.extract(this.type);

    //Order options
    this.orderOptions = [
      {
        label: 'Rows',
        value: 'row',
      },
      {
        label: 'Columns',
        value: 'col',
      },
    ];
  };

  /**
   * Save
   */
  this.save = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Make settings
    const {type, model} = this;
    const settings = {[type]: model};

    //Save
    this.isSaving = true;
    this
      .handler(settings)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isSaving = false);
  };
});
