
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.RecurrenceOptions.Constant', [])

/**
 * Constant definition
 */
.constant('EventRecurrenceOptions', [
  {
    value: 'instance',
    label: 'This event only',
  },
  {
    value: 'tail',
    label: 'This and all following events in the recurring series',
  },
  {
    value: 'series',
    label: 'All events in the recurring series (including past events)',
  },
]);
