
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.AttendeeOptions.Component', [])

/**
 * Attendee options component
 */
.component('eventAttendeeOptions', {
  template: `
    <more-options is-busy="$ctrl.isBusy">
      <li ng-if="$ctrl.canView">
        <a ng-click="$ctrl.view()">View</a>
      </li>
      <li ng-if="$ctrl.canEdit && ($ctrl.attendee.isGuest || $ctrl.event.questions.length > 0)">
        <a ng-click="$ctrl.edit()">Edit</a>
      </li>
      <li ng-if="$ctrl.canEditEvent">
        <a ui-sref="admin.event.edit({eventId: $ctrl.attendee.event.id, showAttendees: true})">Edit event</a>
      </li>
      <li ng-if="$ctrl.attendee.hasAttended">
        <a ng-click="$ctrl.toggleAttended()">Mark as going</a>
      </li>
      <li ng-if="!$ctrl.attendee.hasAttended">
        <a ng-click="$ctrl.toggleAttended()">Mark as attended</a>
      </li>
      <li ng-if="modules.couponTypes && $ctrl.attendee.needsPayment && !$ctrl.attendee.isGuest">
        <a ng-click="$ctrl.payWithCoupons()">Pay with {{modules.coupons.plural}}</a>
      </li>
      <li ng-if="$ctrl.attendee.needsPayment && !$ctrl.attendee.isGuest">
        <a ng-click="$ctrl.payWithMembership()">Pay with membership</a>
      </li>
      <li ng-if="$ctrl.attendee.needsPayment && !$ctrl.attendee.isGuest">
        <a ng-click="$ctrl.payWithAccountCredit()">Pay with account credit</a>
      </li>
      <li ng-if="$ctrl.attendee.needsPayment">
        <a ng-click="$ctrl.markPaid()">Mark as paid</a>
      </li>
      <li class="Dropdown-item--danger">
        <a ng-click="$ctrl.delete()">Cancel attendance</a>
      </li>
    </more-options>
  `,
  bindings: {
    attendee: '<',
    event: '<',
    canView: '<',
    canEdit: '<',
    canEditEvent: '<',
    onView: '&',
    onEdit: '&',
    onDelete: '&',
    onMarkPaid: '&',
    onPayWithCoupons: '&',
    onPayWithMembership: '&',
    onPayWithAccountCredit: '&',
    onToggleAttended: '&',
  },

  /**
   * Controller
   */
  controller() {

    /**
     * Toggle attended
     */
    this.toggleAttended = function() {
      const {attendee} = this;
      this.isBusy = true;
      this
        .onToggleAttended({$event: {attendee}})
        .finally(() => this.isBusy = false);
    };

    /**
     * Mark paid
     */
    this.markPaid = function() {
      const {attendee} = this;
      this.onMarkPaid({$event: {attendee}});
    };

    /**
     * Pay with coupons
     */
    this.payWithCoupons = function() {
      const {attendee} = this;
      this.onPayWithCoupons({$event: {attendee}});
    };

    /**
     * Pay with membership
     */
    this.payWithMembership = function() {
      const {attendee} = this;
      this.onPayWithMembership({$event: {attendee}});
    };

    /**
     * Pay with account credit
     */
    this.payWithAccountCredit = function() {
      const {attendee} = this;
      this.onPayWithAccountCredit({$event: {attendee}});
    };

    /**
     * View
     */
    this.view = function() {
      const {attendee} = this;
      this.onView({$event: {attendee}});
    };

    /**
     * Edit
     */
    this.edit = function() {
      const {attendee} = this;
      this.onEdit({$event: {attendee}});
    };

    /**
     * Delete
     */
    this.delete = function() {
      const {attendee} = this;
      this.onDelete({$event: {attendee}});
    };
  },
});
