
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Overview.Controller', [])

/**
 * Controller
 */
.controller('AdminMemberOverviewCtrl', function(
  $controller, $store, $filter, $modal, $timeout, $notice, moment, Genders,
  CouponType, ScrollPosition, Pagination, Member, MemberGroup, Intercom,
  Modules, MemberFields, Settings, CouponTypeTypes, ReplacementTags,
  VaccinationStatus
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('AdminMemberCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Trigger intercom event
    Intercom.event('Viewed members');

    //Initialize
    this.isLoading = true;
    this.isAdmin = this.user.isAdmin();

    //Filter mailing integrations
    this.mailingIntegrations = this.integrations
      .filter(int => int.isConnected && int.category === 'mailing');

    //Setup filter and page
    this.setupFilter();
    this.setupPage();

    //Load members, memberships, coupon types and count pending members
    this.loadPage();
    this.loadGroups();
    this.loadMemberships();
    this.loadCouponTypes();
    this.loadMembers();
    this.loadActivities();
    this.countPendingMembers();
    this.loadLastImport();
    this.checkLimits();

    //Check if we need to do an action
    const {action} = this.transition.params();
    if (action === 'import') {
      this
        .loadCustomFields()
        .then(() => this.import());
    }
    else if (action === 'welcomeEmail') {
      this.sendWelcomeEmails();
    }

    //Check custom range flags
    this.setCustomRangeFlags();
  };

  /**
   * Check and set custom range flags
   */
  this.setCustomRangeFlags = function() {

    //Get data
    const {filter} = this;
    const i = filter.signedUp;
    const j = filter.active;

    //Set the flags
    if (filter.signedUp && filter.options.signedUp[i].value === 'custom') {
      this.showSignedUpDates = true;
    }
    if (filter.active && filter.options.active[j].value === 'custom') {
      this.showLastActiveDates = true;
    }
  };

  /**
   * On destroy
   */
  this.$onDestroy = function() {
    this.filter.offChange();
  };

  /**
   * Setup page
   */
  this.setupPage = function() {

    //Get page and filter
    const {page, filter} = this;

    //Enable search and set filters
    page.enableSearch();
    page.setFilters(filter);

    //Filter
    page.addOption('filter');
  };

  /**
   * Setup filter
   */
  this.setupFilter = function() {

    //Get filter
    const {filter, module, system} = this;
    const {plural} = module;
    const {nameSortOption} = Settings.get('member');

    //Initialize selection
    this.selection = new Set();

    //Set defaults
    filter.setDefaults({
      search: '',
      isArchived: false,
      isPending: false,
      status: 'current',
      subscription: 0,
      other: 0,
      sort: nameSortOption,
    });

    //Filter on change handler
    filter.onChange((key, value) => {

      //Status
      if (key === 'status') {
        filter.mapOptions(key, value, 'value');
        this.selection.clear();
      }

      //Index based merge
      if (key === 'other' || key === 'subscription') {
        filter.mapOptions(key, value);
      }

      //Clear number of pending members
      if (key === 'status' && value === 'pending') {
        this.numPending = 0;
      }

      //Membership
      if (key === 'memberships') {
        if (!filter.subscription) {
          filter.update('subscription', 1, true);
          filter.mapOptions('subscription', 1);
        }
      }

      //Reload first page
      this.loadPage(1);
    });

    //Set filter options
    filter.options = {
      subscription: [
        {
          label: '...',
          isDefault: true,
          filter: {
            withCurrentSubscription: null,
            withoutCurrentSubscription: null,
            onlyWithCurrentSubscription: null,
            withPastSubscription: null,
            withoutPastSubscription: null,
            onlyWithPastSubscription: null,
            withUpcomingSubscription: null,
            withoutUpcomingSubscription: null,
            onlyWithUpcomingSubscription: null,
            subscriptionRenewed: null,
            subscriptionStopping: null,
            subscriptionStopped: null,
            subscriptionEnding: null,
            subscriptionEnded: null,
          },
        },
        {
          label: 'With a current membership',
          filter: {
            withCurrentSubscription: true,
          },
        },
        {
          label: 'Without current membership',
          filter: {
            withoutCurrentSubscription: true,
          },
        },
        {
          label: 'Only with current membership',
          filter: {
            onlyWithCurrentSubscription: true,
          },
        },
        {
          label: 'With past membership',
          filter: {
            withPastSubscription: true,
          },
        },
        {
          label: 'Without past membership',
          filter: {
            withoutPastSubscription: true,
          },
        },
        {
          label: 'Only with past membership',
          filter: {
            onlyWithPastSubscription: true,
          },
        },
        {
          label: 'With upcoming membership',
          filter: {
            withUpcomingSubscription: true,
          },
        },
        {
          label: 'Without upcoming membership',
          filter: {
            withoutUpcomingSubscription: true,
          },
        },
        {
          label: 'Only with upcoming membership',
          filter: {
            onlyWithUpcomingSubscription: true,
          },
        },
        {
          label: 'Ending in a week',
          filter: {
            subscriptionEnding: 'week',
          },
        },
        {
          label: 'Ending in a month',
          filter: {
            subscriptionEnding: 'month',
          },
        },
        {
          label: 'Ended in the past week',
          filter: {
            subscriptionEnded: 'week',
          },
        },
        {
          label: 'Ended in the past month',
          filter: {
            subscriptionEnded: 'month',
          },
        },
        {
          label: 'Stopping in a week',
          filter: {
            subscriptionStopping: 'week',
          },
        },
        {
          label: 'Stopping in a month',
          filter: {
            subscriptionStopping: 'month',
          },
        },
        {
          label: 'Stopped in the past week',
          filter: {
            subscriptionStopped: 'week',
          },
        },
        {
          label: 'Stopped in the past month',
          filter: {
            subscriptionStopped: 'month',
          },
        },
        {
          label: 'Renewed in the past week',
          filter: {
            subscriptionRenewed: 'week',
          },
        },
        {
          label: 'Renewed in the past month',
          filter: {
            subscriptionRenewed: 'month',
          },
        },
      ],
      other: [
        {
          label: '...',
          isDefault: true,
          filter: {
            hasEmail: null,
            hasAddress: null,
            isSuspended: null,
            isEmailVerified: null,
            isUnsubscribed: null,
            hasMoneyOwing: null,
            hasAccountCredit: null,
            hasAllowedModes: null,
            hasBookings: null,
            canOverrideLights: null,
            canOverrideDoors: null,
            hasTags: null,
            hasSpecificAccess: null,
            hasCardSaved: null,
            tagType: null,
            age: null,
          },
        },
        {
          label: 'Has money owing',
          filter: {
            hasMoneyOwing: true,
          },
        },
        {
          label: 'Has account credit',
          filter: {
            hasAccountCredit: true,
          },
        },
        {
          label: 'Doesn’t have account credit',
          filter: {
            hasAccountCredit: false,
          },
        },
        {
          label: 'Has card on file',
          filter: {
            hasCardSaved: true,
          },
        },
        {
          label: 'Doesn’t have card on file',
          filter: {
            hasCardSaved: false,
          },
        },
        {
          label: 'Suspended',
          filter: {
            isSuspended: true,
          },
        },
        {
          label: 'Without email',
          filter: {
            hasEmail: false,
          },
        },
        {
          label: 'Without address',
          filter: {
            hasAddress: false,
          },
        },
        {
          label: 'Unsubscribed from email',
          filter: {
            isUnsubscribed: true,
          },
        },
        {
          label: 'Not unsubscribed from email',
          filter: {
            isUnsubscribed: false,
          },
        },
        {
          label: 'Unknown age',
          filter: {
            age: 'unknown',
          },
        },
        {
          label: 'Has current resource assigned',
          filter: {
            hasResources: true,
          },
        },
      ],
      gender: Genders,
      vaccinationStatus: VaccinationStatus,
      status: [
        {
          label: `Current ${plural}`,
          value: 'current',
          isDefault: true,
          filter: {
            isArchived: false,
            isPending: false,
          },
        },
        {
          label: `Pending ${plural}`,
          value: 'pending',
          filter: {
            isArchived: false,
            isPending: true,
          },
        },
        {
          label: `Archived ${plural}`,
          value: 'archived',
          filter: {
            isArchived: true,
            isPending: null,
          },
        },
      ],
      active: [
        {
          label: 'Last day',
          value: moment().subtract(1, 'day'),
        },
        {
          label: 'Last week',
          value: moment().subtract(1, 'week'),
        },
        {
          label: 'Last month',
          value: moment().subtract(1, 'month'),
        },
        {
          label: 'Last three months',
          value: moment().subtract(3, 'months'),
        },
        {
          label: 'Last year',
          value: moment().subtract(1, 'year'),
        },
        {
          label: 'Custom range',
          value: 'custom',
        },
        {
          label: 'Never',
          value: 'never',
        },
      ],
      inactive: [
        {
          label: 'More than a week',
          value: moment().subtract(1, 'week'),
        },
        {
          label: 'More than two weeks',
          value: moment().subtract(2, 'weeks'),
        },
        {
          label: 'More than a month',
          value: moment().subtract(1, 'month'),
        },
        {
          label: 'More than three months',
          value: moment().subtract(3, 'months'),
        },
        {
          label: 'More than six months',
          value: moment().subtract(6, 'months'),
        },
        {
          label: 'More than a year',
          value: moment().subtract(1, 'year'),
        },
      ],
      signedUp: [
        {
          label: 'Last week',
          value: moment().subtract(1, 'week'),
        },
        {
          label: 'Last month',
          value: moment().subtract(1, 'month'),
        },
        {
          label: 'Last three months',
          value: moment().subtract(3, 'months'),
        },
        {
          label: 'Last year',
          value: moment().subtract(1, 'year'),
        },
        {
          label: 'Custom range',
          value: 'custom',
        },
      ],
      lastActiveToDate: null,
      lastActiveFromDate: null,
      signUpToDate: null,
      signUpFromDate: null,
      memberships: [],
      couponType: [],
      activities: [],
      grades: [],
    };

    //Conditional options for bookings module
    if (Modules.has('bookings')) {
      filter.options.other.push({
        label: 'Has bookings',
        filter: {
          hasBookings: true,
        },
      });
      filter.options.other.push({
        label: 'Doesn’t have bookings',
        filter: {
          hasBookings: false,
        },
      });
    }

    //Conditional options for activities module
    if (Modules.has('activities')) {
      filter.options.other.push({
        label: 'Has specific activity modes',
        filter: {
          hasAllowedModes: true,
        },
      });
    }

    //System modules
    if (Modules.has('system')) {
      filter.options.other.push({
        label: 'Can override lights',
        filter: {
          canOverrideLights: true,
        },
      });
      filter.options.other.push({
        label: 'Can override doors',
        filter: {
          canOverrideDoors: true,
        },
      });
      filter.options.other.push({
        label: 'Has specific access rules',
        filter: {
          hasSpecificAccess: true,
        },
      });
      filter.options.other.push({
        label: 'Has one or more tags assigned',
        filter: {
          hasTags: true,
        },
      });
      filter.options.other.push({
        label: 'Doesn’t have tags assigned',
        filter: {
          hasTags: false,
        },
      });
      if (system && system.tagTypes) {
        for (const tagType of system.tagTypes) {
          filter.options.other.push({
            label: `Has ` + $filter('label')(tagType, 'TagTypes') + ` assigned`,
            filter: {
              tagType,
            },
          });
        }
      }
    }
  };

  /**
   * Update inactive since
   */
  this.updateInactiveSince = function(i) {

    //Get filter
    const {filter} = this;

    //Clear last active filter values
    filter.update('active', null, true);
    filter.update('lastActive', null, true);
    filter.update('lastActiveFromDate', null, true);
    filter.update('lastActiveToDate', null, true);

    //Set value of index
    filter.update('inactive', i, true);

    //Null value
    if (i === null) {
      filter.update('inactiveSince', null);
      return;
    }

    //Get value
    const {value} = filter.options.inactive[i];

    //Set value
    filter.update('inactiveSince', value);
  };

  /**
   * Update last active
   */
  this.updateLastActive = function(i) {

    //Get filter
    const {filter} = this;

    //Reset flag
    this.showLastActiveDates = false;

    //Clear inactive filter values
    filter.update('inactive', null, true);
    filter.update('inactiveSince', null, true);

    //Update own value silently
    filter.update('active', i, true);
    filter.update('lastActive', null, true);

    //Null value
    if (i === null) {
      this.setLastActiveDates(null, null);
      return;
    }

    //Get value from the options
    let {value: fromDate} = filter.options.active[i];
    const toDate = moment().endOf('day');
    const isCustom = (fromDate === 'custom');
    const isNever = (fromDate === 'never');

    //Never active
    if (isNever) {
      filter.update('lastActive', 'never', true);
      this.setLastActiveDates(null, null);
      return;
    }

    //Custom, and no dates set yet
    if (isCustom) {
      fromDate = filter.options.active[1].value;
      this.showLastActiveDates = true;
    }

    //Not custom, use value from option
    this.setLastActiveDates(fromDate, toDate);
  },

  /**
   * Update filter for signed up dates
   */
  this.setLastActiveDates = function(fromDate, toDate) {
    this.filter.update('lastActiveFromDate', fromDate, true);
    this.filter.update('lastActiveToDate', toDate, false, true);
  };

  /**
   * Update activities
   */
  this.updateActivity = function(activityId) {

    //Get filter
    const {filter} = this;

    //Update own value silently
    filter.update('activity', activityId, true);

    //Get the full activity
    const fullActivity = this.filter.options.activities
      .find(a => a.id === activityId);

    //Set grade options
    this.filter.options.grades = fullActivity.grades;
  };

  /**
   * Update signed up
   */
  this.updateSignedUp = function(i) {

    //Get filter
    const {filter} = this;

    //Reset flag
    this.showSignedUpDates = false;

    //Update own value silently
    filter.update('signedUp', i, true);

    //Null value
    if (i === null) {
      this.setSignedUpDates(null, null);
      return;
    }

    //Get value from the options
    let {value: fromDate} = filter.options.signedUp[i];
    const toDate = moment().endOf('day');
    const isCustom = (fromDate === 'custom');

    //Custom
    if (isCustom) {
      fromDate = filter.options.signedUp[0].value;
      this.showSignedUpDates = true;
    }

    //Not custom, use value from option
    this.setSignedUpDates(fromDate, toDate);
  };

  /**
   * Update filter for signed up dates
   */
  this.setSignedUpDates = function(fromDate, toDate) {
    this.filter.update('signUpFromDate', fromDate, true);
    this.filter.update('signUpToDate', toDate, false, true);
  };

  /**
   * Load groups
   */
  this.loadGroups = function() {
    $store.memberGroups
      .query(true)
      .then(groups => {
        this.groups = groups;
        this.showGroupsFilter = (groups.length <= 5);
      });
  };

  /**
   * Query memberships for filter options if needed
   */
  this.loadMemberships = function() {

    //Already loaded
    if (this.filter.options.memberships.length > 0) {
      return;
    }

    //Load memberships
    $store.memberships
      .query({isArchived: false})
      .then(memberships => {
        this.filter.options.memberships = memberships;
        this.showMembershipTypeFilter = (memberships.length <= 5);
      });
  };

  /**
   * Load coupon types
   */
  this.loadCouponTypes = function() {

    //Already loaded
    if (this.filter.options.couponType.length > 0) {
      return;
    }

    //Load coupon types
    CouponType
      .query({type: CouponTypeTypes.SESSION_BASED, fields: 'name'})
      .then(data => {
        this.filter.options.couponType = [
          {
            name: 'None',
            id: 'none',
          },
          {
            name: 'Any',
            id: 'any',
          },
        ].concat(data.couponTypes);
      });
  };

  /**
   * Load activities
   */
  this.loadActivities = function() {

    //Already loaded
    if (this.filter.options.activities.length > 0) {
      return;
    }

    //Query activities
    $store.activities
      .query()
      .then(activities => {

        //Filter out activities without grades
        this.filter.options.activities = activities
          .filter(activity => activity.hasGrades && activity.grades.length > 0);

        //Set grade options to default
        if (this.filter.options.activities.length > 0) {
          this.filter.options.grades = this.filter.options.activities[0].grades;
        }
      });
  };

  /**
   * Count pending members
   */
  this.countPendingMembers = function() {
    Member
      .count({isPending: true, isArchived: false})
      .then(count => this.numPending = count);
  };

  /**
   * Load last import
   */
  this.loadLastImport = function() {
    Member
      .getImports({isRemovable: true})
      .then(imports => {
        if (imports.length) {
          this.lastImport = imports.pop();
        }
        else {
          this.lastImport = null;
        }
      });
  };

  /**
   * Check limits
   */
  this.checkLimits = function() {
    Member
      .getLimits()
      .then(({hasReachedSoftLimit, hasReachedHardLimit}) => {
        this.hasReachedSoftLimit = hasReachedSoftLimit;
        this.hasReachedHardLimit = hasReachedHardLimit;
      });
  };

  /**
   * On deleted
   */
  this.onDeleted = function() {
    this.loadMembers();
  };

  /**
   * On patched
   */
  this.onPatched = function() {
    this.loadMembers();
  };

  /**
   * Load members
   */
  this.loadMembers = function() {

    //Reset flags
    this.isLoading = true;
    this.hasAny = false;

    //Get filter
    const page = Pagination.getCurrentPage();
    const filter = this.makeFilter(page);

    //Query members
    return Member
      .query(filter)
      .then(data => this.processData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Import members
   */
  this.import = function() {

    //Initialize fields available for importing
    const fields = MemberFields.slice(0);
    const {plural} = this.module;
    const isAllowedCustomFields = (this.club.permissions.customFieldLimit !== 0);
    const requiresConsent = true;

    //Do we have mock data?
    if (this.club.hasDemoData) {
      return this.removeDemoData();
    }

    //Add custom fields
    for (const field of this.customFields) {
      const {prop, label, type} = field;
      if (type === 'file') {
        continue;
      }
      fields.push({
        prop: `customFields.${prop}`,
        label,
        isMultiLine: field.isParagraph,
        isDate: field.isDate,
        isArray: field.isCheckboxes,
      });
    }

    //Open import dialog
    $modal
      .open('import', {
        locals: {
          path: 'member/import',
          item: plural,
          icon: 'group_add',
          help: 'https://help.helloclub.com/en/articles/4244484-importing-members',
          fields, isAllowedCustomFields, requiresConsent,
        },
      })
      .result
      .then(() => {
        this.loadPage(1);
        this.loadGroups();
        this.loadLastImport();
        this.club.markStepComplete('importMembers', true);
        Intercom.event('Imported members');
      });
  };

  /**
   * Export members
   */
  this.export = function() {

    //Get plural
    const {plural} = this.module;
    const {club} = this;

    //Not enabled?
    if (!this.club.permissions.exportData) {
      return $modal.open('basic', {
        templateUrl: 'modals/feature-no-permission.html',
        locals: {club, action: `Exporting ${plural}`},
      });
    }

    //Check if anything to export
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: plural, action: 'export'},
      });
    }

    //Get filter and export
    const filter = this.makeFilter();
    return Member.export(filter);
  };

  /**
   * Remove mock data
   */
  this.removeDemoData = function() {

    //Create handler
    const handler = () => this.club.removeDemoData();

    //Open modal
    $modal.open('basic', {
      templateUrl: 'admin/setup/modals/confirm-delete-mock-data.html',
      locals: {handler, isImporting: true},
    });
  };

  /**
   * Add members to groups
   */
  this.addToGroups = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: this.module.plural, action: 'add to group'},
      });
    }

    //Get filter
    const {groups, module} = this;
    const plural = module.plural;
    const singular = module.singular;
    const filter = this.makeFilter();
    const Model = MemberGroup;

    //Define handlers and counter
    const handler = (groups) => Member.addToGroups(filter, groups);
    const counter = () => Member.count(filter);
    const reloader = () => {
      this.loadGroups();
      this.loadMembers();
    };

    //Open modal
    $modal
      .open('addToGroups', {locals: {
        Model, plural, singular, groups, handler, counter, reloader,
      }})
      .result
      .then(() => {
        $notice.show(`Added ${plural} to groups`);
        this.loadMembers();
      });
  };

  /**
   * Remove contacts from groups
   */
  this.removeFromGroups = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: this.module.plural, action: 'add to group'},
      });
    }

    //Get filter
    const {groups, module} = this;
    const plural = module.plural;
    const singular = module.singular;
    const filter = this.makeFilter();
    const Model = MemberGroup;

    //Define handler
    const handler = groups => Member.removeFromGroups(filter, groups);
    const counter = () => Member.count(filter);
    const reloader = () => {
      this.loadGroups();
      this.loadMembers();
    };

    //Open modal
    $modal
      .open('removeFromGroups', {locals: {
        Model, plural, singular, groups, handler, counter, reloader,
      }})
      .result
      .then(() => {
        $notice.show(`Removed ${plural} from groups`);
        this.loadMembers();
      });
  };

  /**
   * Archive multiple members
   */
  this.archiveMany = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: this.module.plural, action: 'archive'},
      });
    }

    //Get data
    const {module: {plural}} = this;
    const numMembers = this.selection.size || this.numItems;

    //Get filter and define handler
    const filter = this.makeFilter();
    const handler = () => Member.archiveMany(filter);

    //Show confirmation modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-archive-many.html',
        locals: {numMembers, handler},
      })
      .result
      .then(() => {
        $notice.show(`${plural} archived`);
        this.selection.clear();
        this.loadMembers();
      });
  };

  /**
   * Restore multiple members
   */
  this.restoreMany = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: this.module.plural, action: 'restore'},
      });
    }

    //Get data
    const {module: {plural}} = this;
    const numMembers = this.selection.size || this.numItems;

    //Get filter and define handler
    const filter = this.makeFilter();
    const handler = () => Member.restoreMany(filter);

    //Show confirmation modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-restore-many.html',
        locals: {numMembers, handler},
      })
      .result
      .then(() => {
        $notice.show(`${plural} restored`);
        this.selection.clear();
        this.loadMembers();
      });
  };

  /**
   * On approved
   */
  this.onApproved = function() {
    this.selection.clear();
    this.loadMembers();
  };

  /**
   * On rejected
   */
  this.onRejected = function() {
    this.selection.clear();
    this.loadMembers();
  };

  /**
   * On mereged
   */
  this.onMerged = function() {
    this.selection.clear();
    $timeout(() => {
      this.loadMembers();
    }, 3000);
  };

  /**
   * Approve multiple members
   */
  this.approveMany = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: this.module.plural, action: 'approve'},
      });
    }

    //Get data
    const {module: {plural}} = this;
    const members = this.members
      .filter(member => this.selection.has(member.id));

    //Make filter
    const filter = this.makeFilter();

    //Approve
    this
      .approve(members, filter)
      .then(() => {
        $notice.show(`${plural} approved`);
        this.onApproved();
      });
  };

  /**
   * Reject multiple members
   */
  this.rejectMany = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: this.module.plural, action: 'reject'},
      });
    }

    //Get data
    const {module: {plural}} = this;
    const members = this.members
      .filter(member => this.selection.has(member.id));

    //Get filter and define handler
    const filter = this.makeFilter();

    //Reject
    this
      .reject(members, filter)
      .then(() => {
        $notice.show(`${plural} rejected`);
        this.onRejected();
      });
  };

  /**
   * Email members
   */
  this.email = function() {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: this.module.plural, action: 'email'},
      });
    }

    //Define handlers
    const {customFields} = this;
    const filter = this.makeFilter(null, {
      hasEmail: true,
      isUnsubscribed: false,
    });
    const handler = email => Member.email(filter, email);
    const counter = () => Member.count(filter);
    const tags = ReplacementTags.member(customFields);

    //Open modal
    $modal.open('email', {locals: {handler, counter, tags}});
  };

  /**
   * Bulk mailing
   */
  this.bulkMailing = function(type) {

    //Check if anything to do
    if (!this.hasAny) {
      return $modal.open('basic', {
        templateUrl: 'modals/no-items.html',
        locals: {items: this.module.plural, action: 'add to the mailing list'},
      });
    }

    //Make filter and open modal
    const filter = this.makeFilter();
    $modal.open('mailingListBulk', {locals: {type, filter}});
  };

  /**
   * Send welcome emails
   */
  this.sendWelcomeEmails = function() {
    const filter = this.makeFilter();
    $modal
      .open('welcomeEmails', {locals: {filter}})
      .result
      .then(() => this.club.markStepComplete('sendWelcomeEmails', true));
  };

  /**
   * Remove import
   */
  this.removeImport = function(item) {

    //Get data
    const {count, date} = item;

    //Create handler
    const handler = () => Member.removeImport(date);

    //Show confirmation modal
    $modal
      .open('basic', {
        templateUrl: 'admin/people/members/modals/confirm-remove-import.html',
        locals: {count, date, handler},
      })
      .result
      .then(() => {
        this.loadPage(1);
        this.loadLastImport();
      });
  };

  /**
   * Process data
   */
  this.processData = function(data) {

    //Extract data
    const {meta, members} = data;

    //Set in scope
    this.members = members;
    this.numItems = meta.total;
    this.numPages = $filter('numPages')(meta.total);
    this.hasAny = (meta.total > 0);
  };

  /**
   * Toggle
   */
  this.toggle = function(id) {
    if (this.selection.has(id)) {
      this.selection.delete(id);
    }
    else {
      this.selection.add(id);
    }
  };

  /**
   * Select all
   */
  this.selectAll = function() {
    for (const member of this.members) {
      const {id} = member;
      if (!this.selection.has(id)) {
        this.selection.add(id);
      }
    }
  };

  /**
   * Unselect all
   */
  this.unselectAll = function() {
    return this.selection.clear();
  };

  /**
   * Make filter
   */
  this.makeFilter = function(page, extra) {

    //Selection active? Use as filter unless page specified
    if (!page && this.selection.size > 0) {
      return {ids: Array.from(this.selection.values())};
    }

    //Get filter
    const filter = this.filter.toJSON();
    const itemsPerPage = Settings.get('general.itemsPerPage');

    //Remove unnecessary filter properties
    delete filter.status;
    delete filter.other;
    delete filter.subscription;
    delete filter.active;
    delete filter.inactive;
    delete filter.signedUp;
    delete filter.activity;

    //No search
    if (!filter.search) {
      delete filter.search;
    }

    //Append limit and offset if page given
    if (page && page !== 'All') {
      filter.limit = itemsPerPage;
      filter.offset = (page - 1) * itemsPerPage;
    }

    //Extra data to append
    if (extra) {
      Object.assign(filter, extra);
    }

    //Return filter
    return filter;
  };

  /**
   * Set new page
   */
  this.setPage = function(page) {
    page = page || Pagination.getCurrentPage();
    Pagination.setCurrentPage(this.currentPage = page);
  };

  /**
   * Load page of items
   */
  this.loadPage = function(page) {

    //Check if this is the initial request
    const isInitial = !page;

    //Set the page
    this.setPage(page);

    //Load items and restore scroll position if initial load
    this
      .loadMembers()
      .then(() => isInitial ? ScrollPosition.restore() : null);
  };

  /**
   * Show pending members
   */
  this.showPending = function() {
    this.filter.update('status', 'pending');
  };
});
