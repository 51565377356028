
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Setup.Controller', [])

/**
 * Controller
 */
.controller('AdminSetupCtrl', function(
  $modal, $state, $q, $timeout, Emitter, Confetti
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Get data
    const {isFirstVisit, openCard} = this.transition.params();

    //Set steps
    this.setSteps();

    //Set cards
    this.openCard = openCard || (isFirstVisit ? 'first' : ''),
    this.isFirstVisit = isFirstVisit;
  };

  /**
   * Set steps
   */
  this.setSteps = function() {
    if (this.club && this.club.setup) {
      this.steps = this.club.setup.steps;
      this.groups = this.club.setup.groups;
    }
  };

  /**
   * How to get help
   */
  this.howToGetHelp = function() {

    //Get club
    const {club} = this;

    //Emit to header
    $timeout(() => {
      Emitter
        .get('header')
        .emit('showHelp', {withOverlay: true});
    }, 250);

    //Mark as complete
    return club
      .markStepComplete('howToGetHelp')
      .then(() => this.setSteps());
  };

  /**
   * Invite admins
   */
  this.inviteAdmins = function() {

    //Get club and make handler
    const {club} = this;
    const handler = admins => club.inviteAdmins(admins);

    //Open modal
    return $modal
      .open('inviteAdmins', {
        locals: {handler},
      })
      .result
      .then(() => club.markStepComplete('inviteAdmins'))
      .then(() => this.setSteps());
  };

  /**
   * Remove demo data
   */
  this.removeDemoData = function() {

    //Already removed
    if (!this.club.hasDemoData) {
      return $modal
        .open('basic', {
          templateUrl: 'admin/setup/modals/no-mock-data.html',
        })
        .closed
        .then(() => this.club.markDemoDataStepsComplete())
        .then(() => this.setSteps());
    }

    //Create handler
    const handler = () => this.club.removeDemoData();

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: 'admin/setup/modals/confirm-delete-mock-data.html',
        locals: {handler},
      }).result;
  };

  /**
   * Generic step handler
   */
  this.doStep = function(step, options = {}) {

    //Get data
    const {club} = this;
    const {state, params, method, instant, requires, help} = options;

    //Check if required other step is done
    const notReady = (requires && !club.setup.steps[requires].isComplete);

    //Create handler
    const handler = outcome => {

      //Not ready
      if (notReady) {
        return $q.resolve();
      }

      //Process directly
      if (outcome === 'skip') {
        return club
          .markStepSkipped(step)
          .then(() => this.setSteps());
      }
      if (method) {
        this[step]();
        return $q.resolve();
      }
      if (!state) {
        return club
          .markStepComplete(step)
          .then(() => {
            this.setSteps();
            Confetti.fire();
          });
      }

      //Go to state
      $state.go(state, params);

      //Mark as completed instantly
      if (instant) {
        club
          .markStepComplete(step)
          .then(() => this.setSteps());
      }

      //Resolve
      return $q.resolve();
    };

    //Open modal
    $modal.open('basic', {
      templateUrl: `admin/setup/modals/setup-step.html`,
      locals: {step, handler, club, notReady, state, method, help},
    });
  };
});
