
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Club', [
  'App.Admin.Club.Controller',
  'App.Admin.Club.Info.Card',
  'App.Admin.Club.ChoosePlan.Card',
  'App.Admin.Club.ChangePlan.Card',
  'App.Admin.Club.AccountOwner.Card',
  'App.Admin.Club.Billing.Card',
  'App.Admin.Club.Details.Card',
  'App.Admin.Club.Social.Card',
  'App.Admin.Club.Financial.Card',
  'App.Admin.Club.Domain.Card',
  'App.Admin.Club.Privacy.Card',
  'App.Admin.Club.WelcomeText.Card',
  'App.Admin.Club.RegistrationSettings.Card',
  'App.Admin.Club.RegistrationText.Card',
  'App.Admin.Club.VisitorLogSettings.Card',
  'App.Admin.Club.VisitorLogText.Card',
  'App.Admin.Club.JoiningFees.Card',
  'App.Admin.Club.Terms.Card',
  'App.Admin.Club.Alerts.Card',
  'App.Admin.Club.Kiosk.Card',
  'App.Admin.Club.TrialEnded.Modal',
  'App.Admin.Club.PlanExpired.Modal',
  'App.Admin.Club.PlanExpiringSoon.Modal',
  'App.Admin.Club.EditJoiningFee.Modal',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.club', {
    url: '/club/:openCard/:openTab',
    component: 'adminClubRoute',
    params: {
      openCard: {
        value: null,
        squash: true,
      },
      openTab: {
        value: null,
        squash: true,
      },
      showModal: {
        value: null,
      },
    },
    data: {
      id: 'club',
    },
  });
})

/**
 * Component
 */
.component('adminClubRoute', {
  controller: 'AdminClubCtrl',
  templateUrl: 'admin/club/club.html',
  bindings: {
    club: '<',
    user: '<',
    module: '<',
    transition: '<',
    integrations: '<',
  },
})

/**
 * Run logic
 */
.run(($transitions, Page, Intercom) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.club'}, transition => {

    //Get title
    const {title} = transition.injector().get('module');

    //Set page title and crumb
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.club', title});

    //Track intercom event
    Intercom.event('Viewed club');
  });
});
