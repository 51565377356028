
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Activity', [
  'App.Admin.Activity.Add',
  'App.Admin.Activity.Edit',
  'App.Admin.Activity.Overview',
  'App.Admin.Activity.Controller',
  'App.Admin.Activity.ModeOptions.Component',
  'App.Admin.Activity.ActivityOptions.Component',
  'App.Admin.Activity.RestrictionOptions.Component',
  'App.Admin.Activity.EditMode.Modal',
  'App.Admin.Activity.EditFee.Modal',
  'App.Admin.Activity.EditRestriction.Modal',
  'App.Admin.Activity.EditGrade.Modal',
  'App.Admin.Activity.Restrictions.Card',
  'App.Admin.Activity.Modes.Card',
  'App.Admin.Activity.Fees.Card',
  'App.Admin.Activity.Grades.Card',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('admin.activity', {
    url: '/activities',
    abstract: true,
    template: '<ui-view/>',
    data: {
      id: 'activities',
    },
  });
})

/**
 * Run logic
 */
.run(($transitions, Page) => {

  //Page setup
  $transitions.onSuccess({to: 'admin.activity.**'}, transition => {
    const {title} = transition.injector().get('module');
    Page.setTitle(title);
    Page.addCrumb({sref: 'admin.activity.overview', title});
  }, {priority: 10});
});
