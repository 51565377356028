
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Event.Edit.Details.Card', [])

/**
 * Component
 */
.component('cardEventEditDetails', {
  templateUrl: 'admin/event/cards/details.html',
  controller: 'CardEventEditDetailsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    club: '<',
    event: '<',
    areas: '<',
    activities: '<',
    onSave: '&',
  },
})

/**
 * Controller
 */
.controller('CardEventEditDetailsCtrl', function(
  $modal, CardCloseReasons, EventTypes, Modules
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data and flags
    this.isSaving = false;
    this.isEdit = true;
    this.hasAreas = (this.areas.length > 0);
    this.hasActivities = (this.activities.length > 0);
    this.hasMultipleActivities = (this.activities.length > 1);
    this.EventTypes = EventTypes;

    //Replace event type label
    this.EventTypes[0].label = this.club.name;

    //Create model subset
    this.model = this.event.extract([
      'name', 'description', 'notes', 'type', 'venue', 'address',
      'activity', 'areas', 'meetingUrl',
      'isHidden', 'isPublic', 'isInterestEnabled',
      'showOnHomepage', 'showAttendeeNames', 'showAttendanceNumbers',
    ]);

    //Default activity
    if (!this.model.activity && this.hasActivities && Modules.has('activities')) {
      const activity = this.activities.find(activity => activity.isDefault);
      if (activity) {
        this.updateActivity(activity);
      }
    }

    //Filter available areas
    this.updateActivityAreas();

    //Unmark all areas & clear activities if the modules are disabled
    if (!Modules.has('areas')) {
      this.model.areas = [];
    }
    if (!Modules.has('activities')) {
      this.model.activity = null;
    }
  };

  /**
   * Post link
   */
  this.$postLink = function() {
    this.card.dirtyCheck(this.form);
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value, target) {
    target = target || this.model;
    target[property] = value;
    if (property === 'isHidden' && value) {
      target.isInterestEnabled = false;
      target.showAttendeeNames = false;
      target.showAttendanceNumbers = false;
    }
  };

  /**
   * Update activity
   */
  this.updateActivity = function(activity) {
    this.model.activity = activity.toBare();
    this.model.areas = [];
    this.updateActivityAreas();
    this.markAllAreas();
  };

  /**
   * Update type
   */
  this.updateType = function(type) {
    this.model.type = type;
    if (type === 'external') {
      this.model.areas = [];
    }
    else if (type === 'local') {
      this.markAllAreas();
      this.model.venue = '';
      this.model.address = null;
    }
  };

  /**
   * Update activity areas
   */
  this.updateActivityAreas = function() {

    //No areas or activities?
    if (!this.hasActivities || !this.hasAreas) {
      return;
    }

    //Get activity areas
    this.activityAreas = this.areas.filter(area =>
      this.model.activity && area.activity === this.model.activity.id
    );
  };

  /**
   * Mark all areas
   */
  this.markAllAreas = function() {

    //No areas or activities?
    if (!this.hasActivities || !this.hasAreas) {
      return;
    }

    //Too many areas? Prefer to manually tick
    if (this.activityAreas.length > 4) {
      return;
    }

    //Mark all areas
    this.model.areas = this.activityAreas.map(area => area.id);
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Save
   */
  this.save = function(method) {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Get data and prepare event
    const {event, model, isEdit} = this;
    const {isMultiDay, isRecurring} = event;
    const {startDate} = model;
    const $event = {event, model, method, isEdit};

    //Confirm how to update if this was a multiday or recurring event
    if ((isMultiDay || isRecurring) && !method) {
      return $modal
        .open('confirmUpdateEvent', {locals: {event, startDate}})
        .result
        .then(method => this.save(method));
    }

    //Mark as saving
    this.isSaving = true;

    //Save
    this
      .onSave({$event})
      .then(() => {
        this.form.$setPristine();
        this.card.close(CardCloseReasons.SAVED);
      })
      .catch(error => {
        if (error.name === 'ValidationError') {
          this.error = error;
        }
      })
      .finally(() => this.isSaving = false);
  };
});
