
/**
 * Module definition and dependencies
 */
angular.module('App.Coupon.UpdateCouponExpiry.Modal', [])

 /**
  * Config
  */
 .config($modalProvider => {
   $modalProvider.modal('updateCouponExpiry', {
     templateUrl: 'coupon/modals/update-coupon-expiry.html',
     controller: 'ModalupdateCouponExpiryCtrl',
   });
 })

 /**
  * Controller
  */
 .controller('ModalupdateCouponExpiryCtrl', function(
   $controller, $modalInstance
 ) {

   //Get controllers
   const $ctrl = this;
   const $base = $controller('ModalCtrl', {$modalInstance});

   //Extend
   angular.extend($ctrl, $base);

   /**
   * On init
   */
   this.$onInit = function() {

     //Extract model
     this.model = this.coupon.extract([
       'expiryDate',
     ]);
   };

   /**
   * Validate
   */
   this.validate = function() {
     this.form.$setSubmitted();
     return this.form.$valid;
   };

   /**
   * Save
   */
   this.save = function() {

     //Validate
     if (!this.validate()) {
       return;
     }

     //Save
     this.isSaving = true;
     this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isSaving = false);
   };

 });
