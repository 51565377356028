
/**
 * Module definition and dependencies
 */
angular.module('Shared.AutoLightsOff.Options', [])

/**
 * Constant definition
 */
.constant('AutoLightsOffOptions', [
  {
    label: '1 hour before end of event',
    value: '-60:endDate',
  },
  {
    label: '30 minutes before end of event',
    value: '-30:endDate',
  },
  {
    label: '15 minutes before end of event',
    value: '-15:endDate',
  },
  {
    label: '10 minutes before end of event',
    value: '-10:endDate',
  },
  {
    label: '5 minutes before end of event',
    value: '-5:endDate',
  },
  {
    label: 'At the end of the event',
    value: '0:endDate',
  },
  {
    label: '5 minutes after the event',
    value: '5:endDate',
  },
  {
    label: '10 minutes after the event',
    value: '10:endDate',
  },
  {
    label: '15 minutes after the event',
    value: '15:endDate',
  },
  {
    label: '30 minutes after the event',
    value: '30:endDate',
  },
  {
    label: '45 minutes after the event',
    value: '45:endDate',
  },
  {
    label: '1 hour after the event',
    value: '60:endDate',
  },
]);
