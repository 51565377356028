
/**
 * Module definition and dependencies
 */
angular.module('Shared.WelcomeFlow.Service', [])

/**
 * Service definition
 */
.factory('WelcomeFlow', function(
  $state, $timeout, $q, $log, moment, Auth, Sentry, OAuthProviders
) {

  /**
   * Welcome flow class
   */
  class WelcomeFlow {

    /**
     * Constructor
     */
    constructor(club, user, token, customFields) {

      //Initialize
      this.hasFinished = false;
      this.hasSkippedOAuth = false;
      this.user = user;
      this.token = token;
      this.avatar = null;
      this.customFields = customFields;
      this.canUseCredentials = club.settings.signIn.canUseCredentials;

      //Available oAuth providers
      this.providers = OAuthProviders
      .filter(provider => club.settings.signIn.providers[provider.id].isEnabled);

      //Error loading user?
      if (user instanceof Error) {
        this.user = null;
        this.processError(user);
      }

      //Determine steps
      this.determineSteps();
    }

    /**
     * Numbered step
     */
    get numberedStep() {
      return this.steps.indexOf(this.step) + 1;
    }

    /**
     * Is at first step
     */
    get isAtFirstStep() {
      return (this.steps.indexOf(this.step) === 0);
    }

    /**
     * Is at last step
     */
    get isAtLastStep() {
      return (this.steps.indexOf(this.step) + 1 === this.steps.length);
    }

    /**
     * Custom fields check
     */
    get hasCustomFields() {
      return (this.customFields.length > 0);
    }

    /**************************************************************************
     * User management
     ***/

    /**
     * Process error
     */
    processError(error) {

      //Invalid token?
      if (error.code === 'EXPIRED_TOKEN' || error.code === 'INVALID_TOKEN') {
        this.isErrorInvalid = true;
        return;
      }

      //Generic error
      this.isErrorGeneric = true;

      //Process with Sentry and log
      Sentry.captureException(error);
      $log.error(error);
    }

    /**
     * Skip oAuth flow
     */
    skipOAuth() {
      this.hasSkippedOAuth = true;
    }

    /**
     * Apply user profile
     */
    applyUserProfile(profile) {

      //Get data from profile
      const {user} = this;
      const {id, provider, firstName, lastName, email, gender, photo} = profile;
      const dob = profile.dob ? moment(profile.dob, 'YYYY-MM-DD') : null;

      //Link profile and assign email
      user.linkProfile({provider, id, email});
      user.email = email;

      //Set user avatar for later upload
      this.avatar = photo;

      //Only assign what wasn't known yet
      if (!user.firstName) {
        user.firstName = firstName;
      }
      if (!user.lastName) {
        user.lastName = lastName;
      }
      if (!user.gender) {
        user.gender = gender;
      }
      if (!user.dob) {
        user.dob = dob;
      }

      //Re-determine steps
      this.determineSteps();
    }

    /**
     * Setup user
     */
    setup() {

      //Already setup?
      if (this.hasFinished) {
        return $q.resolve();
      }

      //Get data
      const {user, token} = this;

      //Register
      return user
        .setup(token)
        .then(async({auth}) => {

          //Flag as registered, so we don't try to do it again when a user
          //navigates back in the flow
          this.hasFinished = true;

          //Log user in and setup avatar
          await Auth.loginWithToken(auth.access_token || auth.accessToken);
          await this.setupAvatar();
        });
    }

    /**
     * Setup avatar
     */
    setupAvatar() {

      //Get user data and upload avatar
      const {avatar, user} = this;
      if (avatar && !user.avatar) {
        return user.uploadAvatarFromWeb(avatar);
      }
    }

    /**************************************************************************
     * Step management
     ***/

    /**
     * Set the current step (without going there)
     */
    setStep(step) {
      this.step = step;
    }

    /**
     * Go to a particular step
     */
    goToStep(step) {

      //Already on this step
      if ($state.current.name === `welcome.${step}`) {
        return;
      }

      //Go to step
      $timeout(() => $state.go(`welcome.${step}`), 200);
    }

    /**
     * Go to next step
     */
    goToNextStep() {

      //Re-determine steps
      this.determineSteps();

      //Determine next step
      const i = this.steps.indexOf(this.step) + 1;
      if (i >= this.steps.length) {
        return;
      }

      //Get next step
      const step = this.steps[i];
      this.goToStep(step);
    }

    /**
     * Go to previous step
     */
    goToPreviousStep() {

      //Re-determine steps
      this.determineSteps();

      //Determine previous step
      const i = this.steps.indexOf(this.step) - 1;

      if (i < 0) {
        return;
      }

      //Get previous step
      const step = this.steps[i];
      this.goToStep(step);
    }

    /**
     * Finish registration
     */
    finish() {
      this.goToStep('done');
    }

    /**
     * Determine steps
     */
    determineSteps() {

      //Initialize steps
      this.steps = [];

      //Must have user
      if (!this.user) {
        return;
      }

      //OAuth
      if (this.providers.length > 0) {
        this.steps.push('oauth');
      }

      //Details
      this.steps.push('details');

      //Custom fields
      if (this.hasCustomFields && this.user.isMember) {
        this.steps.push('customFields');
      }

      //Login credentials
      if (this.canUseCredentials) {
        if (!this.user.hasProfiles || this.hasSkippedOAuth) {
          this.steps.push('credentials');
        }
      }
    }
  }

  //Return class
  return WelcomeFlow;
});

