
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.RenewSubscription.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('renewSubscriptionAdmin', {
    templateUrl: 'admin/people/members/modals/renew-subscription.html',
    controller: 'ModalRenewSubscriptionAdminCtrl',
  });
})

/**
 * Controller
 */
.controller('ModalRenewSubscriptionAdminCtrl', function(
  $controller, $modalInstance, moment
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Flags
    this.isRenewing = false;
    this.showMinAgeWarning = false;
    this.showMaxAgeWarning = false;

    //Initialize model
    this.model = {
      meta: {
        notifyByEmail: !!this.member.email,
        createTransaction: this.subscription.membership.fee > 0,
        amount: this.subscription.membership.fee,
      },
    };

    //Parse initial given subscription data
    if (this.data) {
      this.parseSubscriptionData(this.data);
    }

    //Load subscription data
    this.loadSubscriptionData();

    //Check membership limit
    if (this.membership.limit > 0) {
      this.checkMembershipLimit();
    }

    //Check if age warning needs to display
    this.checkAge();
  };

  /**
   * Check age
   */
  this.checkAge = function() {
    const age = this.member.age;
    if (age !== null) {
      if (this.membership.minAge && age < this.membership.minAge) {
        this.showMinAgeWarning = true;
      }
      if (this.membership.maxAge && age > this.membership.maxAge) {
        this.showMaxAgeWarning = true;
      }
    }
  };

  /**
   * Update model
   */
  this.updateModel = function(property, value) {
    this.model[property] = value;
    if (property === 'startDate') {
      this.hasManuallyChangedStartDate = true;
      this.loadSubscriptionData();
    }
    if (property === 'endDate') {
      this.hasManuallyChangedEndDate = true;
      this.loadSubscriptionData();
    }
  };

  /**
   * Load subscription data
   */
  this.loadSubscriptionData = function() {

    //Get start/end dates
    let startDate, endDate;
    if (this.hasManuallyChangedStartDate) {
      startDate = this.model.startDate;
    }
    if (this.hasManuallyChangedEndDate) {
      endDate = this.model.endDate;
    }

    //Load data
    this.isLoading = true;
    this.subscription
      .getRenewData(startDate, endDate)
      .then(data => this.parseSubscriptionData(data))
      .finally(() => this.isLoading = false);
  };

  /**
   * Parse subscription data
   */
  this.parseSubscriptionData = function(data) {

    //Extract data
    const {meta} = data;

    //Set dates
    this.model.startDate = moment(meta.startDate);
    this.model.endDate = moment(meta.endDate);

    //Set fee
    this.model.meta.createTransaction = meta.hasFee;
    this.model.meta.amount = meta.totalFee;

    //Set discounts
    this.discounts = meta.discounts;
  };

  /**
   * Validate
   */
  this.validate = function() {
    this.form.$setSubmitted();
    return this.form.$valid;
  };

  /**
   * Renew
   */
  this.renew = function() {

    //Validate
    if (!this.validate()) {
      return;
    }

    //Toggle flag and start renewing
    this.isRenewing = true;
    this
      .handler(this.model)
      .then(result => $modalInstance.resolve(result))
      .finally(() => this.isRenewing = false);
  };

  /**
   * Toggle transaction
   */
  this.toggleTransaction = function(createTransaction) {
    this.model.meta.createTransaction = createTransaction;
  };

  /**
   * Toggle notify
   */
  this.toggleNotify = function(notifyByEmail) {
    this.model.meta.notifyByEmail = notifyByEmail;
  };

  /**
   * Check membership limit
   */
  this.checkMembershipLimit = async function() {

    //Get data
    const {membership} = this;

    //Get current number of subscriptions
    const num = await membership.getNumCurrentSubs();
    this.showSubLimit = (num >= membership.limit);
    this.numberOfSubs = num;
  };
});
