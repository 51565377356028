
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Circles.ViewCircleMember.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('viewCircleMember', {
    templateUrl: 'admin/people/circles/modals/view-circle-member.html',
    controller: 'ModalViewCircleMemberCtrl',
    closeOnClick: false,
  });
})

/**
 * Controller
 */
.controller('ModalViewCircleMemberCtrl', function(
  $controller, $modalInstance, $notice, Member
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Base init
    $base.$onInit.call(this);

    //Set the circle member
    this.circleMember = this.member;

    //Create model subset
    this.model = this.circle.extract([
      'members',
    ]);

    //Only load member if user is an admin
    if (this.asAdmin) {
      this.loadMember();
    }

    //Check who is circle lead
    this.checkCircle();

    //Set flags for disabling inputs
    if (this.member.isLead && this.circleLeads.length === 1) {
      this.disableToggleLead = true;
    }
    if ((this.member.isLead && $ctrl.circleLeads.length > 1) ||
    (!this.member.isLead) && ($ctrl.asAdmin || $ctrl.circle.isLead)) {
      this.disableRemoving = true;
    }
  };

  /**
   * Load member
   */
  this.loadMember = function() {

    //No member to load
    if (!this.member) {
      return;
    }

    //Get ID
    const {id} = this.member;
    this.isLoadingMember = true;

    //Load
    Member
      .findById(id)
      .then(member => this.member = member)
      .finally(() => this.isLoadingMember = false);
  };

  /**
   * Check circle
   */
  this.checkCircle = function() {
    this.circleLeads = this.circle.members
      .filter(member => member.isLead);
  };

  /**
   * Update permissions
   */
  this.updatePermissions = function(property, value) {
    this.circleMember.permissions[property] = value;
  };

  /**
   * Toggle is lead
   */
  this.toggleIsLead = function(value) {
    this.circleMember.isLead = value;
  };

  /**
   * Remove member from circle
   */
  this.delete = function() {

    //Clear error
    this.error = null;

    //Define handler
    this.handler = function() {
      if (this.isInvited) {
        return this.circle.deleteInvite(this.circleMember.id);
      }
      else {
        return this.circle.deleteMember(this.circleMember.id);
      }
    };

    //Delete
    this.isRemoving = true;
    this
      .handler(this.circleMember.id)
      .then(result => {
        $modalInstance.resolve(result);
        $notice.show('Circle member removed');
      })
      .catch(error => this.error = error)
      .finally(() => this.isRemoving = false);
  };

  /**
   * Save
   */
  this.save = function() {

    //Clear error
    this.error = null;

    //Define handler
    this.handler = () => this.circle
      .updateMember(this.circleMember.id, this.circleMember);

    //Save
    this.isSaving = true;
    this
      .handler(this.circleMember.id, this.circleMember)
      .then(result => {
        $modalInstance.resolve(result);
        $notice.show('Circle member updated');

        //Sanitise circle permissions if needed (also done on server)
        for (const type in this.circleMember.permissions) {
          if (this.circleMember.permissions[type]) {
            this.circle.permissions[type] = true;
          }
        }
      })
      .catch(error => this.error = error)
      .finally(() => this.isSaving = false);
  };
});
