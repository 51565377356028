
/**
 * Module definition and dependencies
 */
angular.module('App.Subscription.Overview', [
  'App.Subscription.Overview.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('subscription.overview', {
    url: '',
    component: 'subscriptionOverviewRoute',
    resolve: {
      circleMembers(Circle) {
        return Circle
          .queryOwnManagedMembers('memberships');
      },
    },
  });
})

/**
 * Component
 */
.component('subscriptionOverviewRoute', {
  controller: 'SubscriptionOverviewCtrl',
  templateUrl: 'subscription/overview/overview.html',
  bindings: {
    club: '<',
    user: '<',
    module: '<',
    circleMembers: '<',
    activities: '<',
    subscriptions: '<',
    transition: '<',
  },
});
