
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.ApproveTransactions', [
  'App.Portal.ApproveTransactions.Controller',
])

/**
 * Config
 */
.config(($stateProvider, $transitionsProvider) => {

  //State definition
  $stateProvider.state('approveTransactions', {
    parent: 'portal',
    url: '/transactions/approve/:token',
    component: 'approveTransactionsRoute',
  });

  //Transition hook
  $transitionsProvider.onBefore({
    to: 'approveTransactions',
  }, transition => {

    //Get params
    const params = transition.params();

    //Must have token param
    if (!params.token) {
      return transition.router.stateService.target('error', {
        type: 'page-not-found',
      });
    }
  });
})

/**
 * Route component
 */
.component('approveTransactionsRoute', {
  controller: 'ApproveTransactionsCtrl',
  templateUrl: 'portal/approve-transactions/approve-transactions.html',
  bindings: {
    transition: '<',
    club: '<',
  },
});
