
/**
 * Module definition and dependencies
 */
angular.module('App.Admin.People.Members.Transactions.Card', [
  'App.Admin.People.Members.TransactionOptions.Component',
])

/**
 * Component
 */
.component('cardMemberTransactions', {
  templateUrl: 'admin/people/members/cards/transactions.html',
  controller: 'CardMemberTransactionsCtrl',
  require: {
    card: '^^',
  },
  bindings: {
    isOwn: '<',
    user: '<',
    member: '<',
    limit: '<',
    transactions: '<',
    canPayWithCard: '<',
    canLoadMore: '<',
    onReload: '&',
    onLoadMore: '&',
    onPay: '&',
  },
})

/**
 * Controller
 */
.controller('CardMemberTransactionsCtrl', function(
  $modal, $notice, $filter, Transaction, Payment, Member
) {

  /**
   * On init
   */
  this.$onInit = function() {
    this.isSuper = (this.user && this.user.isSuper());
  };

  /**
   * On changes
   */
  this.$onChanges = function() {
    this.hasTransactions = this.transactions.length > 0;
    this.hasMore = this.transactions.length > this.limit;
  };

  /**
   * Show all transactions
   */
  this.showAll = function() {
    this.hasMore = false;
    this.limit = undefined;
  };

  /**
   * Load more
   */
  this.loadMore = function() {
    this.isLoadingMore = true;
    this.limit = undefined;
    this
      .onLoadMore()
      .finally(() => this.isLoadingMore = false);
  };

  /**
   * Create transaction
   */
  this.createTransaction = function() {

    //Get member and name, create transaction
    const {member} = this;
    const transaction = new Transaction({member});
    const handler = (data) => transaction.save(data);

    //Open modal
    $modal
      .open('createTransaction', {locals: {
        transaction, handler,
        isForMultipleMembers: false,
      }})
      .result
      .then(() => {
        $notice.show('Transaction created');
        this.onReload();
      });
  };

  /**
   * Add account credit
   */
  this.addAccountCredit = function() {

    //Get member, create transaction
    const member = this.member;
    const transaction = new Transaction({member});

    //Define handler
    const handler = data => transaction.save(data);

    //Open modal
    $modal
      .open('addAccountCredit', {locals: {member, transaction, handler}})
      .result
      .then(() => {
        $notice.show('Account credit added');
        this.onReload();
      });
  };

  /**
   * Notify by email
   */
  this.notifyByEmail = function($event) {

    //Get transaction
    const {transaction} = $event;

    //Notify by email
    return transaction
      .notifyByEmail()
      .then(() => $notice.show('Email sent'))
      .catch(() => $notice.showError('Email not sent'));
  };

  /**
   * Mark transaction paid
   */
  this.markPaid = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const handler = function(data) {
      return transaction.markPaid(data);
    };

    //Create new payment
    const payment = new Payment();

    //Open modal
    return $modal
      .open('editPayment', {locals: {transaction, payment, handler}})
      .result
      .then(() => {
        $notice.show('Transaction marked as paid');
        this.onReload();
      });
  };

  /**
   * Mark transaction as not paid
   */
  this.markNotPaid = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const handler = function() {
      return transaction.markNotPaid();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: `admin/people/members/modals/confirm-not-paid-transaction.html`,
        locals: {transaction, handler},
      })
      .result
      .then(() => {
        $notice.show('Transaction marked as not paid');
        this.onReload();
      });
  };

  /**
   * Pay with account credit
   */
  this.payWithAccountCredit = function($event) {

    //Get transaction
    const {transaction} = $event;
    const {member, amount, details} = transaction;
    const type = $filter('label')(transaction.type, 'TransactionTypes');
    const label = `${type} (${details})`;

    //Define handler
    const handler = data => transaction.markPaid(data);

    //Open modal
    return $modal
      .open('payWithAccountCredit', {
        locals: {label, amount, member, handler},
      })
      .result
      .then(payment => {
        transaction.paymentDate = payment.date;
        $notice.show('Transaction paid with account credit');
        this.onReload();
      });
  };

  /**
   * Pay with card
   */
  this.payWithDefaultSource = function($event) {

    //Get transaction
    const {transaction} = $event;
    const {member} = transaction;
    const method = 'stripe';

    //No Stripe
    if (!this.canPayWithCard) {
      return $modal
        .open('basic', {
          templateUrl: 'admin/people/members/modals/no-stripe-integration.html',
        })
        .opened;
    }

    //Load default payment source
    return Member
      .getDefaultPaymentSource(member.id, method)
      .then(source => {

        //No source
        if (!source) {
          return $modal
            .open('basic', {
              templateUrl: 'admin/people/members/modals/no-default-source.html',
              locals: {member},
            });
        }

        //Define handler
        const handler = () => transaction.payWithSource(method, source.id);

        //Confirm
        $modal
          .open('basic', {
            templateUrl: `admin/people/members/modals/confirm-pay-with-source.html`,
            locals: {member, source, transaction, handler},
          })
          .result
          .then(payment => {
            transaction.paymentDate = payment.date;
            $notice.show('Transaction paid with card');
            this.onReload();
          });
      });
  };

  /**
   * Edit transaction payment
   */
  this.editPayment = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const {payment} = transaction;
    const handler = function(data) {
      return transaction.updatePayment(data);
    };

    //Open modal
    $modal
      .open('editPayment', {locals: {transaction, payment, handler}})
      .result
      .then(() => {
        $notice.show('Payment updated');
        this.onReload();
      });
  };

  /**
   * View payment
   */
  this.viewPayment = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const {payment} = transaction;
    const {isSuper, isOwn} = this;

    //Show notice
    $notice.showLoading();

    //Load payment
    Payment
      .findById(payment.id)
      .then(payment => {
        $modal.open('basic', {
          templateUrl: 'admin/finance/modals/view-payment.html',
          locals: {payment, isOwn, isSuper},
        });
      })
      .finally(() => $notice.hideLoading());
  };

  /**
   * Edit transaction
   */
  this.edit = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const handler = function(data) {
      return transaction.save(data);
    };

    //Open modal
    return $modal
      .open('editTransaction', {locals: {transaction, handler}})
      .result
      .then(() => {
        $notice.show('Transaction updated');
        this.onReload();
      });
  };

  /**
   * Split transaction
   */
  this.split = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const handler = parts => transaction.split(parts);

    //Can't split
    if (!transaction.canSplit) {
      return $modal.open('basic', {
        templateUrl: 'admin/finance/modals/cant-split-transaction.html',
      });
    }

    //Open modal
    $modal
      .open('splitTransaction', {locals: {transaction, handler}})
      .result
      .then(() => {
        $notice.show('Transaction split');
        this.onReload();
      });
  };

  /**
   * Remove transaction
   */
  this.delete = function($event) {

    //Get transaction and define handler
    const {transaction} = $event;
    const handler = function() {
      return transaction.delete();
    };

    //Open modal
    return $modal
      .open('basic', {
        templateUrl: `admin/people/members/modals/confirm-delete-transaction.html`,
        locals: {transaction, handler},
      })
      .result
      .then(() => {
        $notice.show('Transaction removed');
        this.onReload();
      });
  };

  /**
   * View transaction
   */
  this.view = function($event) {

    //Get data
    const {transaction} = $event;
    const {isOwn, isSuper} = this;

    //Open modal
    $modal.open('basic', {
      templateUrl: 'admin/finance/modals/view-transaction.html',
      locals: {transaction, isOwn, isSuper},
    });
  };

  /**
   * Pay
   */
  this.pay = function(transaction) {
    transaction.isBusy = true;
    this
      .onPay({$event: {transaction}})
      .finally(() => transaction.isBusy = false);
  };

  /**
   * Download receipt
   */
  this.receipt = function(transaction) {
    transaction.isBusy = true;
    transaction.payment
      .downloadReceipt()
      .finally(() => transaction.isBusy = false);
  };

  /**
   * Download invoice
   */
  this.invoice = function(transaction) {
    transaction.isDownloadingInvoice = true;
    transaction
      .downloadInvoice()
      .finally(() => transaction.isDownloadingInvoice = false);
  };
});
