
/**
 * Module definition and dependencies
 */
angular.module('Shared.DateInput.Component', [])

/**
 * Date input component
 */
.component('dateInput', {
  template: `
    <div class="InputWrapper is-clickable" ng-click="$ctrl.showDatePicker()">
      <input class="Input Input--date {{$ctrl.classes}}" type="text" ng-value="$ctrl.dateFormatted" placeholder="..." readonly ng-disabled="$ctrl.isDisabled">
      <span class="Input-icon">
        <i class="Icon">date_range</i>
      </span>
    </div>
  `,
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    date: '<ngModel',
    minDate: '<',
    maxDate: '<',
    isDisabled: '<ngDisabled',
    validate: '<',
    dateFormat: '@',
    canClear: '@',
    onChange: '&',
    startOf: '@',
    endOf: '@',
    classes: '@',
  },

  /**
   * Directive controller
   */
  controller($modal, moment, DateFormat) {

    /**
     * On init
     */
    this.$onInit = function() {

      //Check if can clear
      this.canClear = (this.canClear === 'true');
    };

    /**
     * On changes
     */
    this.$onChanges = function() {

      //Check if date valid
      if (this.date && !moment.isMoment(this.date)) {
        this.date = null;
      }

      //Default date format
      if (!this.dateFormat) {
        this.dateFormat = DateFormat.formats.standard;
      }

      //Validate min/max dates
      if (this.date && this.validate) {
        if (this.minDate && this.date.isBefore(this.minDate)) {
          const date = this.minDate.clone();
          this.confirmDate(date);
        }
        if (this.maxDate && this.date.isAfter(this.maxDate)) {
          const date = this.maxDate.clone();
          this.confirmDate(date);
        }
      }

      //Formatted date
      this.dateFormatted = this.date ?
        this.date.format(this.dateFormat) : '';
    };

    /**
     * Confirm date
     */
    this.confirmDate = function(date) {

      //Modify if needed
      if (date !== null) {
        if (this.startOf) {
          date.startOf(this.startOf);
        }
        else if (this.endOf) {
          date.endOf(this.endOf);
        }
      }

      //Call onChange handler
      this.onChange({date});
    };

    /**
     * Show date picker
     */
    this.showDatePicker = function() {

      //Not when disabled
      if (this.isDisabled) {
        return;
      }

      //Get data
      const {date, canClear, minDate, maxDate} = this;

      //Open modal
      $modal
        .open('pickDate', {locals: {date, canClear, minDate, maxDate}})
        .result
        .then(date => {
          this.ngModel.$setDirty();
          this.confirmDate(date);
        });
    };
  },
});
